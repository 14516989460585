import React, { Component } from 'react';
import styles from './TickWithoutBG.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TickWithoutBG extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="tick-mark-component">
				<svg width="100%" height="100%" viewBox="0 0 14 14">
					<path fill="none" fillRule="evenodd" stroke="#4A90E2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12.625 1.375L5.079 12.153a1.1 1.1 0 0 1-1.784.03l-1.92-2.56" opacity=".653" />
				</svg>
			</div>
		);
	}
}



export default TickWithoutBG;
