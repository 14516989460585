/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './NextActivityLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class NextActivityLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<ContentLoader
				height={200}
				width={1280}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '100%' }}
			>
				<rect x={0} y={50} rx="0" ry="0" width={700} height={25} />
				<rect x={0} y={130} rx="0" ry="0" width={1800} height={45} />
			</ContentLoader>
		);
	}
}

export default NextActivityLoader;
