/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: items.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * items reducer is responsible for items related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrUpdateObjectInList, mergeListsBasedOnKey } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    itemsList: [],
    userItemsList: [],
    activeItemId: -1,
    metricsList: [],
    itemsListAdditionalDetails: {},
    userItemsListAdditionalDetails: {},
    productLaunchDetails: {},
    isProductLaunchDetailsAvailable: false,
    isProductLaunchWindowOpen: false,
    itemIdFromRoute: -1,
    isFeedbackSubmitted: false,
    moduleCourseItems: null,
    moduleCourseItemsList: [],
    isUserItemsFetched: false,
    itemProgresses: []
});

const items = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_ITEMS_LIST'):
            return state.set(
                'itemsList',
                mergeListsBasedOnKey(state.get('itemsList'), action.payload.itemsList, 'id')
            ).set(
                'itemsListAdditionalDetails',
                Immutable.fromJS(action.payload.itemsListAdditionalDetails)
            );
        case actionConsts.get('SET_USER_ITEMS_LIST'):
            return state.set(
                'userItemsList',
                mergeListsBasedOnKey(
                    state.get('userItemsList'), action.payload.userItemsList, 'id'
                )
            ).set(
                'userItemsListAdditionalDetails',
                Immutable.fromJS(action.payload.userItemsListAdditionalDetails)
            ).set(
                'activeItemId',
                Immutable.fromJS(action.payload.activeItemId)
            ).set(
                'isUserItemsFetched',
                true
            );
        case actionConsts.get('SET_ACTIVE_ITEM_ID'):
            return state.set(
                'activeItemId', action.activeItemId
            );
        case actionConsts.get('UPDATE_ITEM_DETAILED_DATA'):
            return state.set(
                'itemsList',
                setOrUpdateObjectInList(
                    state.get('itemsList'), action.payload, 'id', 'id'
                )
            );
        case actionConsts.get('UPDATE_ITEMS_METRICS_LIST'):
            return state.set(
                'metricsList',
                state.get('metricsList').concat(Immutable.fromJS(action.metricsList))
            );
        case actionConsts.get('SET_PRODUCT_LAUNCH_DETAILS'):
            return state.set('productLaunchDetails', Immutable.fromJS(action.productLaunchDetails))
                .set('isProductLaunchDetailsAvailable', true);
        case actionConsts.get('RESET_PRODUCT_LAUNCH_DETAILS'):
            return state.set('productLaunchDetails', Immutable.fromJS({}))
                .set('isProductLaunchDetailsAvailable', false);
        case actionConsts.get('OPEN_PRODUCT_LAUNCH_WINDOW'):
            return state.set('isProductLaunchWindowOpen', action.flag);
        case actionConsts.get('UPDATE_ITEM_FROM_ROUTE'):
            return state.set('itemIdFromRoute', +action.itemIdFromRoute);
        case actionConsts.get('SUBMIT_FEEDBACK'):
            return state.set('isFeedbackSubmitted', action.flag);
        case actionConsts.get('UPDATE_USER_PHASE_DETAILED_DATA'):
            if (action.payload.activeItemId) {
                return state.set('activeItemId', action.payload.activeItemId);
            }
            return state;
        case actionConsts.get('SET_LOADED_MODULE'):
            // return state.set('moduleCourseItems', Immutable.fromJS(action.module));
            // console.log("In set loaded module", state.get('moduleCourseItemsList'));
            let courseItemList = [];
            courseItemList.push(action.module);
            // console.log("courseItemList", courseItemList);
            return state.set(
                'moduleCourseItemsList',
                mergeListsBasedOnKey(state.get('moduleCourseItemsList'), courseItemList, 'id')
            ).set('moduleCourseItems', Immutable.fromJS(action.module));

        case actionConsts.get('UPDATE_ITEM_PROGRESS_IN_LIST'):
            return state.set(
                'itemProgresses',
                setOrUpdateObjectInList(state.get('itemProgresses'), action.progressDetails, 'itemId', 'itemId')
            );
        default:
            return state;
    }
};

export default items;