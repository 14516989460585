import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		timelineLine: {
			backgroundColor: skin.get('greyColor5')
		},
		itemSelected: {
			border: `4px solid ${skin.get('greyColor5')}`
		},
		// completedBackground: {
		// 	backgroundColor: skin.get('darkSkyBlue')
		// },
		// pendingBackground: {
		// 	backgroundColor: skin.get('greyColor4')
		// },
		// startedBackground: {
		// 	backgroundColor: hexToRgbA(skin.get('darkSkyBlue')),
		// },
		// selectedBackground: {
		// 	backgroundColor: skin.get('secondaryColor')
		// },
		individualPhaseItem: {
			borderRadius: '4px',
			backgroundColor: skin.get('white'),
			boxShadow: `0 4px 16px 0 ${hexToRgbA(skin.get('black'), 0.06)}`,
			border: `solid 1px ${hexToRgbA(skin.get('greyColor4'), 0.27)}`
		},
		reportHeading: {
			...myTypography.h6,
			color: hexToRgbA(skin.get('greyColor1'), 0.85)
		},
		reportSubHeading: {
			...myTypography.overline,
			color: hexToRgbA(skin.get('greyColor1'), 0.8)
		},
		reportButton: {
			...myTypography.button,
			color: skin.get('secondaryColor'),
			backgroundColor: skin.get('white'),
			borderRadius: '4px',
			border: `1px solid ${skin.get('secondaryColor')}`,
			':hover': {
				color: skin.get('white'),
				backgroundColor: skin.get('secondaryColor')
			}
		},
		likeToHearLabel: {
			...myTypography.overline,
			color: skin.get('secondaryColor'),
			fontWeight: 600
		},
		likeToHearLabelDisabled: {
			...myTypography.overline,
			color: '#9a9eaf',
			fontWeight: 600
		},
		thanksForFeedbackLabel: {
			...myTypography.overline,
			color: skin.get('greyColor3'),
			fontWeight: 600
		}
	});
}

export default getSkin;