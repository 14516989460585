import React, { Component } from 'react';
import styles from './scormsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ScormsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			// <div >
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
				<g fill="none" fillRule="evenodd" stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".833">
					<path d="M7.571 10.904a.292.292 0 0 0-.446.247v1.867a.292.292 0 0 0 .446.247l1.492-.933a.292.292 0 0 0 0-.495l-1.492-.933z" />
					<path d="M1.875 14.708a.583.583 0 0 1-.583-.583V1.875c0-.322.26-.583.583-.583h12.25c.322 0 .583.26.583.583v12.25a.583.583 0 0 1-.583.583H1.875z" />
					<path d="M12.083 11.5l.875.875-.875.875M10.625 11.5l.875.875-.875.875M3.917 13.25l-.875-.875.875-.875M5.375 13.25l-.875-.875.875-.875" />
					<g>
						<path d="M3.042 9.458V3.042h9.916v6.416z" />
					</g>
				</g>
			</svg>
			// </div>
		);
	}
}

export default ScormsIcon;
