import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './statsComponent.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import { throws } from 'assert';
import UserBgLogo from 'images/analytics/score_card_bg.svg'
import BookBg from 'images/analytics/book_bg.svg'
import ClockSvg from 'svgComponents/analytics/clockSvg/ClockSvg';
import UserRank from 'svgComponents/analytics/userRank';
import BookSvg from 'svgComponents/analytics/bookSvg/BookSvg';
import ProgressBar from 'svgComponents/analytics/progressBar';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import { checkIfPresent } from 'utils/utilFunctions';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@CSSModules(styles, { allowMultiple: true })

class StatsComponent extends React.Component {

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchStatsData(+batchId, +userId);
    }
    
    renderSvgComponent = (iconName, fillColor) => {
        if (iconName === 'clock') {
            return <ClockSvg fillColor={fillColor}/>
                        
        } else if (iconName === 'rank') {
            return <UserRank fillColor={fillColor}/>
        } else if (iconName === 'book'){
            return <BookSvg fillColor={fillColor}/>
        }
    }

    displayTitle = (title, hasIcon=false, iconName, fillColor) => {
        if (hasIcon) {
            return (
                <div styleName="title-container">
                    <div styleName="icon">
                        {this.renderSvgComponent(iconName, fillColor)}
                    </div>
                    <div styleName="title">{title}</div>
                    <div styleName="title-separator"></div>
                </div>
            );
        }
        return (
            <div styleName="title-container">
                <div styleName="title">{title}</div>
                <div styleName="title-separator"></div>
            </div>
        );
    }

    displayBottomTitle = (title) => {
        return (
            <div styleName="title-container">
                <div styleName="title-separator"></div>
                <div styleName="content-text">{title}</div>
            </div>
        );
    }

    boldTextContent = (number, text, color, columnWise=false) => {
        const style = columnWise ? "content-bold-container" : "";
        const boldStyle = columnWise ? "module-content-bold"  : "content-bold";
        return (
            <span styleName={style}>
                <span styleName={boldStyle} style={{color: `${color}`}}>{number}</span>
                {text}
            </span>
        );
    }

    renderProgressSvg = (fillColor) => {
        
        return fillColor.map((fill) => {
            return (
                <div key={Math.random()} styleName="active-days-progress-bar">
                    <ProgressBar fillColor={fill}/>
                </div>
            )
        })
    }

    renderProgressBars = (allGraphsData) => {

        let fillColor = [];

        allGraphsData.get('days').map((dayActiveValue, i) => {
            if (dayActiveValue == 1) {
                fillColor[i] = "#4969e2";   
            } else {
                fillColor[i] = "rgba(213, 217, 231, 0.1)"
            }
        });

        return this.renderProgressSvg(fillColor);
    }

    renderContent = (contentObject) => {
        return(
            <div styleName="content-container">
                <div styleName="content">
                    {this.boldTextContent(contentObject.value1, contentObject.value1Title, contentObject.value1Color)}
                </div>
                <div styleName="content">
                    {this.boldTextContent(contentObject.value2, contentObject.value2Title, contentObject.value2Color)}
                </div>
            </div>
        )
    }

    renderUserStats = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('batchUserStatus');
        
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        const contentObject = {
            value1: allGraphsData.get('enrolledUsers'),
            value1Title: strings.get('usersEnrolledLabel'),
            value1Color: '#ff0264',
            value2: allGraphsData.get('activeUsers'),
            value2Title: strings.get('usersActiveLabel'),
            value2Color: '#ff0264',
        }
        return(
            <div styleName="user-stats">
                {this.displayTitle(strings.get('userStatsLabel'), true, 'rank', '#FF026A')}
                {this.renderContent(contentObject)}
                <div styleName="bg-container">
                    <img src={UserBgLogo} styleName="bg-logo"/>
                </div>
            </div>
        )
    }

    renderJourneyStats = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('journeyDetails');

        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="journey-stats">
                {this.displayTitle(strings.get('journeyLabel'), true, 'book', '#FF026A')}
                <div styleName="content-container">
                    <div styleName="content content-text">
                        {strings.get('assessmentPhaseLabel')}
                    </div>
                    {checkIfPresent(allGraphsData.get('courses')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('assessments'), strings.get('assessmentsLabel'), '#ff0264')}
                    </div>}
                    <div styleName="content content-text">
                        {strings.get('developmentPhaseLabel')}
                    </div>
                    {checkIfPresent(allGraphsData.get('courses')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('courses'), strings.get('coursesLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('sections')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('sections'), strings.get('sectionsLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('activities')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('activities'), strings.get('activitiesLabel'), '#ff0264')}
                    </div>}
                </div>
                <div styleName="bg-container">
                    <img src={BookBg} styleName="bg-logo" />
                </div>
            </div>
        )
    }

    renderStatsSection = () => {
        return(
            <div styleName="stats-inner-container">
                {this.renderUserStats()}
                {this.renderJourneyStats()}
            </div>
        )
    }

    getTimeSpentData = (phaseType, unit) => {
        const allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('timeSpent');
        if (checkIfPresent(allGraphsData.get(phaseType))) {
            return checkIfPresent(allGraphsData.get(phaseType).get(unit)) ? allGraphsData.get(phaseType).get(unit) : 0;
        }
        return 0;
    }

    renderTimeSpentByUser = () => {
        const strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="time-spent">
                <div styleName="time-spent-overall">
                    {this.displayTitle(strings.get('timeSpentOverallLabel'), true, 'clock', '#ffb448')}
                    <div styleName="content-container">
                        <div styleName="content">
                            {this.boldTextContent(this.getTimeSpentData('overAll', 'days'), strings.get('daysLabel'), '#feb926', true)} 
                            :{this.boldTextContent(this.getTimeSpentData('overAll', 'hours'), strings.get('hoursLabel'), '#feb926', true)} 
                            :{this.boldTextContent(this.getTimeSpentData('overAll', 'minutes'), strings.get('minutesLabel'), '#feb926', true)}
                        </div>
                        <div styleName="content-text">
                            {strings.get('timeSpentOverallDesc')}
                        </div>
                    </div>
                </div>
                <div styleName="vertical-separator"></div>
                <div styleName="time-spent-phase">
                    {this.displayTitle(strings.get('timeSpentAssessmentLabel'), true, 'clock', '#00fa9e')}
                    <div styleName="content-container">
                        <div styleName="content">
                            {this.boldTextContent(this.getTimeSpentData('assessment', 'days'), strings.get('daysLabel'), '#26fa96')} 
                            :{this.boldTextContent(this.getTimeSpentData('assessment', 'hours'), strings.get('hoursLabel'), '#26fa96')} 
                            :{this.boldTextContent(this.getTimeSpentData('assessment', 'minutes'), strings.get('minutesLabel'), '#26fa96')}
                        </div>
                    </div>
                    {this.displayTitle(strings.get('timeSpentDevelopmentLabel'), true, 'clock', '#00fa9e')}
                    <div styleName="content-container">
                        <div styleName="content">
                            {this.boldTextContent(this.getTimeSpentData('development', 'days'), strings.get('daysLabel'), '#26fa96')} 
                            :{this.boldTextContent(this.getTimeSpentData('development', 'hours'), strings.get('hoursLabel'), '#26fa96')} 
                            :{this.boldTextContent(this.getTimeSpentData('development', 'minutes'), strings.get('minutesLabel'), '#26fa96')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderModuleFinished = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('userCompletedTasks');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="finished">
                {this.displayTitle(strings.get('finishedLabel'))}
                <div styleName="content-container">
                    {checkIfPresent(allGraphsData.get('assessments')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('assessments'), strings.get('assessmentsLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('courses')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('courses'), strings.get('coursesLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('sections')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('sections'), strings.get('sectionsLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('activities')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('activities'), strings.get('activitiesLabel'), '#ff0264')}
                    </div>}
                </div>
                <div styleName="bg-container">
                    <img src={BookBg} styleName="bg-logo" />
                </div>
            </div>
        )
    }

    renderModuleOverDue = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('userOverdueTasks');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="overdue">
                {this.displayTitle(strings.get('overdueLabel'))}
                <div styleName="content-container">
                    {checkIfPresent(allGraphsData.get('assessments')) &&<div styleName="content">
                        {this.boldTextContent(allGraphsData.get('assessments'), strings.get('assessmentsLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('courses')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('courses'), strings.get('coursesLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('sections')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('sections'), strings.get('sectionsLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('activities')) && <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('activities'), strings.get('activitiesLabel'), '#ff0264')}
                    </div>}
                </div>
                <div styleName="bg-container">
                    <img src={BookBg} styleName="bg-logo" />
                </div>
            </div>
        )
    }

    renderModuleFinishedAndOverDue = () => {
        return(
            <div styleName="finished-overdue">
                {this.renderModuleFinished()}
                {this.renderModuleOverDue()}
            </div>
        )
    }

    renderTimeSpentAndModuleCompletionSection = () => {
        return(
            <div styleName="time-spent-container">
                {this.renderTimeSpentByUser()}
                {this.renderModuleFinishedAndOverDue()}
            </div>
        )
    }

    renderDaysProgress = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('lastNDaysActivity');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');
        return(
            <div styleName="active-days">
                <div styleName="active-day-progress-container">
                    {allGraphsData.get('overAllActiveDays')} days
                    <div styleName="active-days-progress-graph-container">
                        {this.renderProgressBars(allGraphsData)}
                    </div>
                </div>
                {this.displayBottomTitle(strings.get('activeDaysLabel'))}
            </div>
        )
    }

    getHourData = (parentKey, childKey) => {
        const allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('mostActiveTimeSpan');
        if (checkIfPresent(allGraphsData.get(parentKey))) {
            return checkIfPresent(allGraphsData.get(parentKey).get(childKey)) ? allGraphsData.get(parentKey).get(childKey) : 0;
        }
        return 0;
    }

    renderUsersActiveTime = () => {
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="active-time">
                <div styleName="module-content-container">
                    <div styleName="content">
                        {this.boldTextContent(this.getHourData('startHour', 'hour'), this.getHourData('startHour', 'zone'), '#4a69e2', true)} 
                        {strings.get('toLabel')}
                        {this.boldTextContent(this.getHourData('endHour', 'hour'), this.getHourData('endHour', 'zone'), '#4a69e2', true)}
                    </div>
                </div>
                {this.displayBottomTitle(strings.get('mostActiveTimeLabel'))}
            </div>
        )
    }

    renderModuleWithExceptionalScore = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="module">
                <div styleName="module-content-container">
                    <div styleName="module-content">
                        {allGraphsData.get('mostActiveModule').get('moduleName')}
                    </div>
                </div>
                {this.displayBottomTitle(strings.get('maxSpentModuleLabel'))}
            </div>
        )
    }

    renderUserProgressAndOutputSection = () => {
        return(
            <div styleName="active-days-container">
                {this.renderDaysProgress()}
                {this.renderUsersActiveTime()}
                {this.renderModuleWithExceptionalScore()}
            </div>
        )
    }

    renderStatsComponent = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        if(this.props.apiErrors.get('fetchStatsDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchStatsDataError')}
                retryHandler={()=>{
                    this.props.fetchStatsData(+batchId, +userId);
				}}
            />
        }

        let allGraphsData = this.props.allGraphsData.get('activity');
        if (allGraphsData.get('isStatsDataFetched')) {
            return(
                <div styleName="main-container">
                    {this.renderStatsSection()}
                    {this.renderTimeSpentAndModuleCompletionSection()}
                    {this.renderUserProgressAndOutputSection()}
                </div> 
            )
        }
        return <LoaderComponent />
    }

    render(){        
        return(
            <div styleName="container-class">
                <LegendComponent title={this.props.strings.get('statsKey')} {...this.props}/>
                <div styleName="outer-container">
                    <div styleName="inner-container">
                        {this.renderStatsComponent()}
                    </div>
                </div>         
            </div>
        )
    }
}


export default StatsComponent;