import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        giveFeedbackMessage: {
            ...myTypography.body1,
            fontWeight: 'normal',
            color: skin.get('secondaryColor')
        }
    });
}

export default getSkin;