import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { updateUserBatchDetailedData } from 'actions/batches/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import { setActiveBatchAndPhaseId } from 'actions/batches/actionCreators';
import setUserState from 'actions/userState/actionCreators';

class FetchUserBatchDetails extends NetPackWrapper {

    constructor() {
        super("GET");
	}

	prepareUrl = (apiInfoObject = {}) => urls.get('PLATFORM').get('GET_USER_BATCH_DETAILS').replace(
		'BATCH_ID',
		apiInfoObject.batchId
	)
	
	shouldAjaxCall = (apiInfoObject) => {
        return true;
	}

    successCall = (response) => {
        this.dispatch(updateUserBatchDetailedData(response));

        const payload = {
            activeBatchId: response.batchId,
            activePhaseId: response.activePhaseId
        };

        /* let currentPhaseId = this.getState().get('userState').get('currentPhaseId');
        if (currentPhaseId === null) {
            currentPhaseId = response.activePhaseId;
        }  *//*Need to understand why this was  being done */

        this.dispatch(setUserState({
            currentBatchId: response.batchId,
            currentPhaseId: response.activePhaseId
        }));

        window.localStorage.setItem('BATCH_ID', response.batchId);

        this.dispatch(setActiveBatchAndPhaseId(payload));
        return response;
	}
	
	onError = (response) => {

        const errorObj = response.errorObject.response;
        const apiObj = response.errorObject.ajaxCallInfo;
        this.dispatch(setUserState({
            userError: {
                message: errorObj.message,
                error_code: errorObj.error_code,
                data: errorObj.data,
                status_code: apiObj.status
            }
        }));

        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		sendUserToInvalidPage();
    }

}

export default new FetchUserBatchDetails();
