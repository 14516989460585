import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		error: {
			backgroundColor: skin.get('scarlet')
		},
		success: {
			backgroundColor: skin.get('appleGreen')
		},
		notification: {
			backgroundColor: skin.get('darkSkyBlue')
		},
		warning: {
			backgroundColor: skin.get('orangeyYellow')
		},
		heading: {
			...myTypography.h6,
			fontSize: '18px',
			color: skin.get('greyColor2'),
			lineHeight: 1
		},
		message: {
			...myTypography.caption,
			fontSize: '14px',
			fontWeight: 400,
			color: skin.get('greyColor2')
		}
	});
}

export default getSkin;