import Immutable from 'immutable';
import environment from 'utils/environment';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';

var apiURL = '';
let externalURL = '';
if (environment.REACT_APP_ENV === 'local') {
	apiURL = getApiUrl().analyticsUrl;
	externalURL = getApiUrl().analyticsExternalUrl;
} else {
	apiURL = getDomainUrl() + '/analytics';
	externalURL = getDomainUrl() + '/external/analytics';
}

const analyticsUrls = () => {
	return Immutable.Map({
		GET_OVERALL_PROGRESS_DATA: `${apiURL}/overAllProgress/batch/BATCH_ID?userId=USER_ID`,
		GET_DAY_WISE_TIME_SPENT_DATA: `${apiURL}/dayWiseTimeSpent/batch/BATCH_ID?userId=USER_ID`,
		GET_PLATFORM_WISE_TIME_SPENT_DATA: `${apiURL}/platformWiseTimeSpent/batch/BATCH_ID?userId=USER_ID`,

		GET_TIME_SPENT_PERIODICALLY: `${apiURL}/timeSpentPeriodically/batch/BATCH_ID?userId=USER_ID`,
		GET_PROGRESS_PERIODICALLY: `${apiURL}/progressPeriodically/batch/BATCH_ID?userId=USER_ID`,

		GET_STATS_DATA: `${apiURL}/stats/batch/BATCH_ID?userId=USER_ID`,

		GET_ASSESSMENT_SCORE_DATA: `${apiURL}/assessment/score/batch/BATCH_ID/phase/PHASE_ID/?userId=USER_ID`,
		GET_ASSESSMENT_PROGRESS_DATA: `${apiURL}/assessment/progress/batch/BATCH_ID/phase/PHASE_ID/?userId=USER_ID`,
		GET_ASSESSMENT_TIME_SPENT_DATA: `${apiURL}/assessment/timeSpent/batch/BATCH_ID/phase/PHASE_ID/?userId=USER_ID`,
		GET_ASSESSMENT_MEASURES_DATA: `${apiURL}/assessment/measures/batch/BATCH_ID/phase/PHASE_ID/?userId=USER_ID`,


		GET_DEVELOPMENT_SCORE_DATA: `${apiURL}/development/score/v2/batch/BATCH_ID/phase/PHASE_ID/?userId=USER_ID`,
		GET_DEVELOPMENT_PROGRESS_DATA: `${apiURL}/development/progress/v2/batch/BATCH_ID/phase/PHASE_ID/?userId=USER_ID`,
		GET_DEVELOPMENT_TIME_SPENT_DATA: `${apiURL}/development/timeSpent/v2/batch/BATCH_ID/phase/PHASE_ID/?userId=USER_ID`,
		GET_DEVELOPMENT_MEASURES_DATA: `${apiURL}/development/measures/batch/BATCH_ID/phase/PHASE_ID/?userId=USER_ID`,

		GET_LEADERBOARD_DATA: `${apiURL}/leaderboard/v2/batch/BATCH_ID/?userId=USER_ID`,
		GET_OVERALL_PERFORMANCE_DATA: `${apiURL}/overAllPerformance/batch/BATCH_ID/?userId=USER_ID`,

		GET_NEEDLE_MOVEMENT_DATA: `${apiURL}/needleMovement/batch/BATCH_ID/?userId=USER_ID`,

		GET_WIDGET_ANALYTICS_DATA: `${apiURL}/widgets/batch/BATCH_ID/phase/PHASE_ID?userId=USER_ID`,
		GET_ANALYTICS_BUTTON_DATA: `${apiURL}/analyticsStatus/batch/BATCH_ID?userId=USER_ID`,

		GET_GENERIC_ANALYTICS_DATA: `${apiURL}/batch/BATCH_ID?userId=USER_ID`,

		GET_JOURNEY_WISE_TIME_SPENT: `${apiURL}/timeSpent/user/USER_ID/organization/ORGANIZATION_ID/journeys`,

		GET_MS_TEAMS_LEADERBOARD_DATA: `${externalURL}/admin/phaseWiseLeaderboard`

	});

};

export default analyticsUrls;