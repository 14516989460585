import React, { Component } from 'react';
import styles from './journeyexp.module.sass';
import CSSModules from 'react-css-modules';
import AnalyticsCard from './AnalyticsCard';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import JourneyExpLoader from 'commonComponents/loaders/journeyExpLoader';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyExp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cards: [],
            currentCard: 0
        }
    }

    componentDidMount() {
        this.setCards(this.props.journeyDetails)
        setTimeout(() => {
            this.renderNextCard();
        }, 5000);
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.journeyDetails && nextProps.journeyDetails) {
            this.setCards(nextProps.journeyDetails)
        }
    }

    setCards = (journeyDetails) => {
        if (journeyDetails) {
            this.setState({
                cards: journeyDetails.get('interestingFacts').toJS()
            });
        }
    }

    renderNextCard = () => {
        this.setState({
            currentCard: (this.state.currentCard + 1) % this.state.cards.length
        }, () => {
            setTimeout(() => { this.renderNextCard(); }, 5000)
        });
    }

    getCurrentCardDetails = () => {
        // const currentCardDetails = this.state.cards.filter((eachCard) => {
        //     return (eachCard.id === this.state.currentCard)
        // });
        // if(currentCardDetails[0] !== null) {
        //     return currentCardDetails[0];
        // }
        // return null;
        if (this.state.cards.length > 0 && this.state.cards[this.state.currentCard] !== null) {
            return this.state.cards[this.state.currentCard]
        }
        return null;
    }

    renderCardDots = (myStyles) => {
        const allCards = this.state.cards;
        const dotArray = allCards.map((eachCard, index) => {
            return (
                <div
                    key={`fact-card-${index}`}
                    styleName="single-dot"
                    style={{ width: `${90 / allCards.length}%` }}
                    className={index === this.state.currentCard ? css(myStyles.selectedCardDot) : css(myStyles.normalCardDot)}
                    onClick={() => {
                        this.setState({
                            currentCard: index
                        });
                    }}
                ></div>
            );
        });
        return (
            <div styleName="card-dots-container">
                {dotArray}
            </div>
        );
    }

    renderLoader() {
        return (
            <div ref={this.props.divRef} id='benefitsAndInsights' styleName="exp-container">
                <div styleName="exp-content-container">
                    <JourneyExpLoader />
                </div>
            </div>
        );
    }

    render() {
        if (!this.props.isBatchDetailsAvailable || !this.props.isBatchLabelsAvailable) {
            return this.renderLoader();
        }

        const cardDetails = this.getCurrentCardDetails();

        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div ref={this.props.divRef} id='benefitsAndInsights' styleName="exp-container">
                <div styleName="exp-content-container">
                    <div styleName={`exp-content ${this.props.isInOverlay || !cardDetails ? 'exp-content-full-width' : ''}`}>
                        <div
                            className={css(myStyles.expContentTitle)}
                            styleName="exp-content-title"
                        >
                            {this.props.getLabel('journey_intro_why_label')}
                        </div>
                        <div
                            className={css(myStyles.expContentDesc)}
                            styleName="exp-content-desc"
                            dangerouslySetInnerHTML={{
                                __html: this.props.journeyDetails.get('aimOfJourney')
                            }}
                        >
                        </div>
                    </div>
                    {
                        cardDetails ?                   
                            <div styleName="analytics-card-container">
                                {
                                    cardDetails !== null
                                        ? <AnalyticsCard
                                            cardDetails={cardDetails}
                                        />
                                        : null //can have empty state for facts card
                                }
                                {
                                    this.state.cards.length !== 0
                                        ? this.renderCardDots(myStyles)
                                        : null
                                }
                            
                            </div>
                        : null
                    }
                </div>
            </div>
        );
    }
}

export default JourneyExp;
