import React, { Component } from 'react';

class SessionActiveIcon extends Component {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" >
				<circle fill="#79E08A" stroke="none" cx="6" cy="6" r="6">
					<animate attributeName="opacity" dur="1.7s" values="0;1;0" repeatCount="indefinite" begin="0.1" />
				</circle>
			</svg>
		);
	}
}



export default SessionActiveIcon;
