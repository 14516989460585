/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: OfflineBar.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * OfflineBar component of the application
 *
 **/

import React from 'react';
import PropTypes from 'prop-types';
import styles from './offlineBar.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin';
import { StyleSheet, css } from 'aphrodite/no-important';
import Offline from 'svgComponents/offline';
import toastConstants from 'constants/toasts';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class OfflineBar extends React.Component {

	constructor(props) {
		super(props)
		{
			this.state = {
				message: "Reconnecting to the network in",
				time: 20,
				triedNow: false
			}
		}
	}

	componentDidMount = () => {
		this.connectionIsLostMessage();
		setInterval(() => {
			let newTime = this.state.time - 1;
			this.setState({
				time: newTime
			})

			if (newTime == 0) {
				this.tryNow()
			}

		}, 1000);

	}

	connectionIsLostMessage = () => {
		const { addToast } = this.props;
		addToast({
			id: toastConstants.get('ID_NET_CONNECTION_LOST'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Internet Connection is lost',
			message: 'Your internet is down',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		});
	}

	internetBackToastMessage = () => {
		const { addToast } = this.props;
		addToast({
			id: toastConstants.get('ID_NET_CONNECTION_BACK'),
			type: toastConstants.get('TYPE_SUCCESS'),
			heading: 'Internet Connection is back',
			message: 'Your internet connection is back',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		});
	}

	getInternetDownMessage = () => {
		this.setState({
			message: "Internet is still down.",
			time: null,
			triedNow: true
		})

		setTimeout(() => {
			this.setState({
				message: "Reconnecting to the network in",
				time: 20,
				triedNow: false

			})
		}, 1000);
	}

	tryNow = () => {
		const isUserOnline = window.navigator.onLine;
		if (isUserOnline) {
			const { setUiState } = this.props;
			this.internetBackToastMessage();
			setUiState({ showInternetConnectivityBar: false });
		}
		else {
			this.getInternetDownMessage()

		}

	}


	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div className={css(myStyles.offlineBarContainer)} styleName="offlineBar-container">
				<div styleName="offlineBar-content">
					<div styleName="offlineBar-left-content">
						<div styleName="offline-image">
							<Offline />
						</div>
						<div styleName="content" className={css(myStyles.offlineBarContent, myStyles.white)}>You’re not connected to the network. Try reconnecting now!</div>
					</div>
					<div styleName="offlineBar-right-content">
						<div styleName="content" className={css(myStyles.offlineBarContent, myStyles.whiteOpacity)}>
							{(!this.state.triedNow) ?
								this.state.message + ' ' + this.state.time + ' seconds'
								:
								this.state.message
							}
						</div>
						<div styleName="try-now-button" className={css(myStyles.tryNowButton)} onClick={this.tryNow}>TRY NOW</div>
					</div>
				</div>
			</div>
		);
	}
}

export default OfflineBar;
