import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './routeButtons.module.sass';
import BackButton from 'images/analytics/next_button.svg';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import ForwardButton from '../../../svgComponents/analytics/forwardButton';
import {checkIfPresent} from 'utils/utilFunctions';

@CSSModules(styles, { allowMultiple: true })

class RouteButtons extends React.Component {   

    constructor(props) {
        super(props);
        this.state = {
            buttonColor: {
                fill: '#FBFBFF',
                fillOpacity: '.042'
            }
        };
    }

    onHover = () => {
        this.setState({
            buttonColor: {
                fill: '#FBFBFF',
                fillOpacity: '0.5'
            }
        })
    }

    onLeave = () => {
        this.setState({
            buttonColor: {
                fill: '#FBFBFF',
                fillOpacity: '.042'
            }
        })
    }

    render(){

        const myStyles = getSkin(this.props.skinGuide);
        const buttonEnabled = checkIfPresent(this.props.enabled) ? this.props.enabled : false;

        return(
            <div 
                onMouseOver={buttonEnabled ? this.onHover : null}
                onMouseLeave={buttonEnabled ? this.onLeave : null}
                onClick={buttonEnabled ? this.props.clickHandler : null} 
                className={buttonEnabled ? css(myStyles.buttonStyle) : css(myStyles.buttonStyleWithOutHover)} 
                styleName="card-container">
                <div className={buttonEnabled ? css(myStyles.buttonFontStyle) : css(myStyles.buttonFontStyleDisabled)} styleName="card-title">
                    <div styleName="logo-container">
                        <img src={this.props.logo} />
                    </div>
                    {this.props.buttonTitle}
                    <div styleName="back-button-container">
                        <ForwardButton 
                            buttonStyle = {this.state.buttonColor}
                        /> 
                    </div>
                </div>
            </div>
        )
    }
}


export default RouteButtons;