import actionConstants from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
	userOrganizationDataLoading: false,
	userOrganization: {},
	userOrganizationError: {}
});

const userOrganizationData = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_USER_ORGANIZATION_LOADING'):
			return state.set(
				'userOrganizationDataLoading',
				action.flag
			)
		case actionConstants.get('SET_USER_ORGANIZATION_DATA'):
			return state.set(
				'userOrganization',
				Immutable.fromJS(action.userOrganization)
			).set(
				'userOrganizationDataLoading',
				false
			)
		case actionConstants.get('SET_USER_ORGANIZATION_ERROR'):
			return state.set(
				'userOrganizationError',
				Immutable.fromJS(action.userOrganizationError)
			).set(
				'userOrganizationDataLoading',
				false
			)
		default:
			return state
	}
}

export default userOrganizationData;