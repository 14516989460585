import Immutable from 'immutable';
import actionConstants from 'constants/actions';
import { setOrUpdateObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS([]);

const quizSubmissions = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_QUIZ_SUBMISSIONS'):
			if (action.quizSubmissions && action.quizSubmissions.quiz_submissions)
				return Immutable.fromJS(action.quizSubmissions.quiz_submissions);
			else
				return state;
		case actionConstants.get('UPDATE_SUBMISSION_ATTEMPT'):
			return state
		case actionConstants.get('UNSET_QUIZ_SUBMISSIONS'):
			return initialState
		default:
			return state
	}
}

export default quizSubmissions