import React, { Component } from 'react';
import styles from './rightArrowCircle.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class RightArrowCircle extends Component {

	constructor(props) {
		super(props);
		this.state={
			onHover: false
		}
	}

	setOnHoverTrue = () => {
		this.setState({
			onHover: true
		});
	}

	setOnHoverFalse = () => {
		this.setState({
			onHover: false
		});
	}

	render() {

		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="left-arrow-component" onMouseEnter={this.setOnHoverTrue} onMouseLeave={this.setOnHoverFalse}>
				<svg width="100%" height="100%" viewBox="0 0 40 40">
					<path
						fill="#FFF"
						fillRule="nonzero"
						d="M0 20A20 20 0 0 1 20 0c11.046 0 20 8.954 20 20a20 20 0 0 1-40 0zm4 0c0 8.837 7.163 16 16 16s16-7.163 16-16S28.837 4 20 4A16 16 0 0 0 4 20zm12-10l10 10-10 10V10z"
						opacity={this.state.onHover ? 1 : 0.5}/>
				</svg>
			</div>
		);
	}
}



export default RightArrowCircle;
