/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: labels.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * labels reducer is responsible for labels related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrUpdateObjectInMap } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    isLabelsFetched: false,
    areOrgLabelsSet: false
});

const labels = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_BATCH_LABELS'):
            return setOrUpdateObjectInMap(
                state, action.payload.batchId, action.payload.labels
            ).set(
                'isLabelsFetched', true
            );
        case actionConsts.get('SET_ORG_LABELS'):
            return setOrUpdateObjectInMap(
                state, action.payload.orgId, action.payload.labels
            ).set(
                'isLabelsFetched', true
            ).set('areOrgLabelsSet', true);
        default:
            return state;
    }
};

export default labels;