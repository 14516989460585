import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        entityName: {
            ...myTypography.h5,
            fontSize: '22px',
            color: skin.get('greyColor3')
        }
    });
}

export default getSkin;