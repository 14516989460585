import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './webSession.module.sass';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import { convertDateWithMonth, getDayForDate, getTimeIn12HrFormatFromDateString, getStarComponentForRating, openLinkInNewTab } from 'utils/utilFunctions';
import InvertedModuleItemTypes from '../../../svgComponents/invertedModuleItemTypes'
import AdditionalInformation from '../additionalInformation';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import DownloadIcon from 'svgComponents/downloadIcon';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class WebSession extends React.Component {
	
	onJoinSessionClick = () => {
		
		openLinkInNewTab(this.props.moduleItem.getIn(['contentDetails', 'al_data', 'session_details', 'url']));

		this.props.markModuleItemDone({...this.props.match.params, moduleItemType: 'webSession'});

	}

	getResourceSizeInMB = (sizeInBytes) => {
		return (sizeInBytes / (1024 * 1024)).toFixed(2)
	}

	getSessionDetailsElement = () => {
		let styles = getSkin(this.props.skinGuide);
		let { moduleItem, isSidebarOpen, getLabel } = this.props;
		const sessionDetails = moduleItem.getIn(['contentDetails', 'al_data', 'session_details']);
		let timIn12hrs = sessionDetails && sessionDetails.get('date') ? getTimeIn12HrFormatFromDateString(sessionDetails.get('date')) : null;

		return (
			<div styleName={`session-details-cnt${isSidebarOpen ? ' sidebar-open' : ''}`}>

				<div style={{ minWidth: '200px' }}>
					<div className={css(styles.sessionDetailsHeader)}>
						{getLabel('web_session_date_time_heading_label')}
					</div>
					<div className={css(styles.sessionDetailsTitles)} styleName="date-time-cnt">
						<div styleName="column">
							{convertDateWithMonth(sessionDetails.get('date'))}
						</div>
						<div styleName="time column">
							{ timIn12hrs ? <React.Fragment>{timIn12hrs.time} <span className={css(styles.sessionDetailsContent)}>{timIn12hrs.ampm}</span></React.Fragment> : null}
						</div>
					</div>
					<div styleName="day-duration-cnt">
						<div className={css(styles.sessionDetailsContent)} styleName="column">
							{getDayForDate(sessionDetails.get('date'))}
						</div>
						<div className={css(styles.sessionDetailsContent)} styleName="duration column">
							{sessionDetails.get('duration')} {sessionDetails.get('duration_unit')}
						</div>
					</div>
				</div>
				<div styleName="add-to-calendar-cnt">

				</div>
				<div styleName="button">
					<PrimaryButton text={getLabel('web_session_join_link_label')} clickFunction={this.onJoinSessionClick} />
				</div>
			</div>
		)
	}

	getResourcesList = (resources) => {
		let resourcesList = [];
		let styles = getSkin(this.props.skinGuide);

		resourcesList = resources.map(resource =>
			<div key={`resource_${resource.get('id')}`} className={css(styles.eachResource)} styleName="each-resource-cnt">
				<div styleName="file-icon-cnt">
					<InvertedModuleItemTypes itemType={resource.get('content_type') || 'default'} />
				</div>
				<div className={css(styles.resourceName)} styleName="resource-name">
					<div>{resource.get('name')}</div>
					<div className={css(styles.resourceSize)}>{this.getResourceSizeInMB(resource.get('size'))} MB</div>
				</div>
				<div>
					<a href={resource.get('url')} download target="_blank">
						<div styleName="download-icon"><DownloadIcon /></div>
					</a>
				</div>
			</div>
		);
		return resourcesList;
	}

	getFacilitatorsList = (facilitators) => {
		let facilitatorsList = [];
		let styles = getSkin(this.props.skinGuide);
		facilitatorsList = facilitators.map((facilitator, index) =>
			<div key={`facilitator_${index}`} styleName="facilitator-details">
				<div className={css(styles.facilitatorProfile)} styleName="facilitator-profile">
					<div styleName="facilitator-avatar">
						<img src={facilitator.get('image_url')} alt="Facilitator" />
					</div>
					<div styleName="column">
						<div className={css(styles.facilitatorName)}>{facilitator.get('name')}</div>
						<div className={css(styles.facilitatorDesignation)}>{facilitator.get('designation')}</div>
						<div key={`star_comp_${index}`} styleName="row rating">{getStarComponentForRating(facilitator.get('rating'), facilitator.get('max_rating'), `facilitator_${index}`)}
						<span className={css(styles.facilitatorRating)} styleName="facilitator_rating">{facilitator.get('rating')}/{facilitator.get('max_rating')}</span></div>
					</div>
				</div>
				<div className={css(styles.facilitatorBio)} styleName="facilitator-bio">
					{facilitator.get('description') || "No information available"}
				</div>
			</div>
		);
		return facilitatorsList;
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		let { moduleItem, isSidebarOpen, getLabel } = this.props;

		return (
			<div styleName={`content-cnt${isSidebarOpen ? ' sidebar-open' : ''}`}>
				{
					moduleItem.getIn(['contentDetails', 'description']) &&
					<div styleName={`content-left-side${isSidebarOpen ? ' sidebar-open' : ''}`}>
						<AdditionalInformation title="Details" body={moduleItem.get('contentDetails').get('description')} />
					</div>
				}
				<div styleName={`content-right-side${isSidebarOpen ? ' sidebar-open' : ''}`}
					style={moduleItem.getIn(['contentDetails', 'description']) ? {} : { width: '100%' }}
				>
					{
						this.getSessionDetailsElement()
					}
				</div>
				<div styleName={`content-left-side${isSidebarOpen ? ' sidebar-open' : ''}`}>
					{
						moduleItem.getIn(['contentDetails', 'al_data', 'resources']) &&
						moduleItem.getIn(['contentDetails', 'al_data', 'resources']).size !== 0 &&
						<div styleName="resources-cnt">
							<div className={css(styles.containerHeader)} styleName="resources-cnt-header">
								{getLabel('web_session_resources_label')}
							</div>
							<div className={css(styles.resourcesCnt)} styleName="resources-list">
								{this.getResourcesList(moduleItem.get('contentDetails').get('al_data').get('resources'))}
							</div>
						</div>
					}
					{
						moduleItem.getIn(['contentDetails', 'al_data', 'facilitators']) &&
						moduleItem.getIn(['contentDetails', 'al_data', 'facilitators']).size !== 0 &&
						<div styleName="facilitator-cnt">
							<div className={css(styles.containerHeader)} styleName="facilitator-cnt-header">
								{getLabel('web_session_facilitators_label')}
							</div>
							{this.getFacilitatorsList(moduleItem.get('contentDetails').get('al_data').get('facilitators'))}
						</div>
					}
				</div>
			</div>
		)
	}
}

export default WebSession;