import React, { Component } from 'react';
import styles from './performanceComponent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import UserDetails from 'components/analytics/activityComponent/userDetails';
import SummaryComponent from './summaryComponent';
import withSizes from 'react-sizes';
import ScoreComponent from './scoreComponent';
import ActivityHeader from 'components/analytics/activityHeader';
import LeaderboardWithFilter from './leaderboardWithFilter/LeaderboardWithFilter';
import { getJourneyType, hasDevelopmentPhaseStarted } from 'utils/analytics/utilFunctions';
import Footer from 'commonComponents/footer';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class PerformanceComponent extends Component {

    renderSections = (strings) => {
        const { phases } = this.props;
        const phasesList = phases.get('phasesList');
        const journeyType = getJourneyType(phasesList);
        const developmentPhaseIndex = journeyType.indexOf('D');
        const hasDevelopmentPhase = developmentPhaseIndex >= 0;

        if (hasDevelopmentPhase) {
            if (hasDevelopmentPhaseStarted(phases)) {
                return (
                    [<SummaryComponent genericStrings={strings} {...this.props} key="SummaryComponent"/>,
                    <LeaderboardWithFilter genericStrings={strings} {...this.props} key="LeaderboardWithFilter"/>,
                    <ScoreComponent genericStrings={strings} {...this.props} key="ScoreComponent"/>,]
                );
            }
            return (
                [<SummaryComponent locked={true} genericStrings={strings} {...this.props} key="SummaryComponent"/>,
                <LeaderboardWithFilter locked={true} genericStrings={strings} {...this.props} key="LeaderboardWithFilter"/>,
                <ScoreComponent genericStrings={strings} {...this.props} key="ScoreComponent"/>,]
            );
        }
        else {
            return (
                <ScoreComponent genericStrings={strings} {...this.props} />
            );
        }
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);

        const strings = this.props.allGraphsData.get('genericData').get('analyticsData').get('strings');
        const userName = this.props.user.get('firstName');
        const greetingMessage = strings.get('userAddessingMessageKey').replace('PLACEHOLDER', userName);

        return (
            [<ActivityHeader title='Performance' key="ActivityHeader"/>,
            <div key="ActivityBody" className={css(myStyles.containerStyle)} styleName="container-class">
                <UserDetails 
                     greeting={greetingMessage}
                     infoMessage={strings.get('graphDelayKey')}
                    {...this.props} />
                {this.renderSections(strings)}
                <Footer />
            </div>]
        );
    }
}

const mapSizeToProps = function(sizes) {
    const props = {
        size: sizes
    }

    return props;
}


export default withSizes(mapSizeToProps)(PerformanceComponent);
