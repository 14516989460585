/**
 * @author: sindhu
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Dashboard container is responsible for displaying Dashboard pages and
 * displayed on dashboard route of the application
 *
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchItemData, fetchProductLaunchData, setItemWorkflowStatus, fetchItemProgress, fetchNextPhaseItemDetailsAndProgress, refreshPhasesAndPhaseItems } from 'actions/items/apiActions';
import {
    toggleProductLaunchWindow,
    setExitPopup,
    setSelectedItemId,
    updateItemIdFromRoute,
    submitFeedback,
    resetProductLaunchDetails
} from 'actions/items/actionCreators';
import setUserState from 'actions/userState/actionCreators';
import { setSideBarFlag, setFullScreen } from 'actions/assesments/actionObjects';
import {
    renderComponent,
    getActiveItemDetails,
    getNextItemDetails,
    getActiveUserItem,
    getActiveToolFAQs,
    getActivePhase,
    getItemsListInActivePhase,
    getActiveItemProgress
} from './containerHelpers/assessmentContainerHelper';
import GenericErrorBoundary from 'components/errorBoundaries';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import {
    startTracking,
    endTracking
} from 'utils/mixpanelTracking';


import { updateFetchUserItemsListStatusForFeedbackScreen } from 'actions/loaders/actionCreators';
import { setPhaseWorkflowStatus } from 'actions/phases/apiActions';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { getJourneyFirebaseDatabase } from 'utils/firebase';
import enumConstants from 'constants/enums';


class AssesmentsContainer extends Component {

    firebaseRef = null;

    componentDidMount() {
        const { batchId, itemId } = this.props.match.params;

        startTracking(true, {
            batchId: this.props.match.params.batchId,
            phaseId: this.props.selectedItem.get('phaseId'),
            phaseItemId: this.props.match.params.itemId,
            component: 'AssessmentsContainer'
        }, 'AssessmentItem');

        this.props.setUserState({
            currentItemId: parseInt(itemId),
            showStickyHeader: false
        });
        this.props.fetchItemData(batchId, +itemId);
        this.props.updateItemIdFromRoute(+itemId);
        let toggleProductLaunchWindow = this.props.toggleProductLaunchWindow;

        if (!window.adCloseWindow) {
            window.adCloseWindow = function () {
                console.log('assessment close window');
                toggleProductLaunchWindow(false);
            }
        }

        this.props.fetchItemProgress(+itemId, +batchId);
        this.setFirebaseListener(batchId, itemId, this.props.selectedUserItem);

    }

    componentWillReceiveProps(nextProps) {
        const { batchId, itemId } = nextProps.match.params;
        const isThisANewItem = this.props.match.params.itemId != itemId
        const wasProductWindowClosed = this.props.items.get('isProductLaunchWindowOpen') && !nextProps.items.get('isProductLaunchWindowOpen');
        let shouldFetchBaseData = false;
        
        if (isThisANewItem || wasProductWindowClosed) {
            if(wasProductWindowClosed) shouldFetchBaseData = true;          

            this.props.fetchItemData(batchId, +itemId, shouldFetchBaseData);
        
            this.props.updateItemIdFromRoute(+itemId);
            this.props.fetchItemProgress(+itemId, +batchId);
            this.props.fetchNextPhaseItemDetailsAndProgress(nextProps.selectedItem, +batchId);
            this.setFirebaseListener(batchId, itemId, nextProps.selectedUserItem)
        }

        /* only for mixpanel*/
        if (this.props.match.params.itemId != itemId) {
            endTracking();
            startTracking(true, {
                batchId: nextProps.match.params.batchId,
                phaseId: nextProps.selectedItem.get('phaseId'),
                phaseItemId: nextProps.match.params.itemId
            }, 'AssessmentItem');
        }

        let userState = {
            currentItemId: parseInt(itemId)
        };
        const currentPhaseId = nextProps.selectedItem.get('phaseId');
        if (currentPhaseId) {
            userState = {
                ...userState,
                currentPhaseId
            }
        }
        this.props.setUserState(userState);
        if (
            !nextProps.loaders.get('isFetchingItemData')
            && this.props.loaders.get('isFetchingItemData')
        ) {
            document.title = `${nextProps.selectedItem.get('itemType')} - ${nextProps.selectedItem.get('name')} - Aktivlearn Plus`;
            this.sendSegmentData(nextProps);
        }
        
        if(
            nextProps.selectedItem.get('type') === enumConstants.get('SIMULATION')
             || nextProps.selectedItem.get('type') === enumConstants.get('HARRISON_ASSESSMENTS')
             || nextProps.selectedItem.get('type') === enumConstants.get('GAME')
        ){
            this.checkStatusAndMoveToFeedback(nextProps.selectedUserItem);
        }
    }

    setFirebaseListener = (batchId, itemId, selectedUserItem) => {
        console.log('debugfb: setFirebaseListener', batchId, itemId);

        if(selectedUserItem.get('workflowStatus') === 'COMPLETED'){//don't listen to events if item already completed
            return;
        }

        const { user } = this.props;
        const journeyFirebaseDB = getJourneyFirebaseDatabase();
        if(this.firebaseRef){
            this.firebaseRef.off('value');
        }
        let refString = `scormResults/user_id${user.get('userID')}_batch_id${batchId}_journey_item_id${itemId}`;
        this.firebaseRef = journeyFirebaseDB.ref(refString);
        this.firebaseRef.on('value', (snapshot) => {
            const result = snapshot.val();
            if (result && result.workflowState === 'COMPLETED') {
                console.log('debugfb item completed, refreshing status');
                
                this.props.refreshPhasesAndPhaseItems(batchId);
			}
        })
    }

    sendSegmentData = (nextProps) => {
        let segmentData = {
            ...(nextProps.match.params),
            url: window.location.href,
            pathHash: window.location.hash,
            itemType: nextProps.selectedItem.get('itemType'),
            workflowStatus: nextProps.selectedUserItem.get('workflowStatus'),
            percentageCompletion: nextProps.selectedUserItem.get('percentageCompletion')
        };
        window.analytics.page(
            `Item Page Opened - ${nextProps.selectedItem.get('itemType')}`,
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    checkStatusAndMoveToFeedback = (nextSelectedUserItem) => {
        if (
            !nextSelectedUserItem.isEmpty()
            && nextSelectedUserItem.get('workflowStatus') === 'COMPLETED'
        ) {
            const { match } = this.props;
            const route = routes.get('BATCH_ITEM_FEEDBACK');
            const params = [
                { 'ITEM_ID': nextSelectedUserItem.get('id') },
                { 'BATCH_ID': match.params.batchId }
            ];
            updateRoute({ route, params });
        }
    }

    componentWillUnmount() {
        endTracking();
        if(this.firebaseRef){
            this.firebaseRef.off('value');
        }
    }

    render() {


        return (
            <GenericErrorBoundary>
                {renderComponent(this.props)}
            </GenericErrorBoundary>
        );
    }
}



const mapStateToProps = state => {
    return ({
        selectedItem: getActiveItemDetails(state),
        selectedUserItem: getActiveUserItem(state),
        nextItem: getNextItemDetails(state),
        faqsList: state.get('tools').get('faqsList'),
        activePhaseDetails: getActivePhase(state),
        itemsListInActivePhase: getItemsListInActivePhase(state),
        isSideBarOpen: state.get('ui').get('isSideBarOpen'),
        isFullScreen: state.get('ui').get('isFullScreen'),
        isExitPopupOpen: state.get('ui').get('isExitPopupOpen'),
        batches: state.get('batches'),
        phases: state.get('phases'),
        items: state.get('items'),
        selectedItemProgress: getActiveItemProgress(state),
        attachmentsList: state.get('attachments').get('list'),
        tools: state.get('tools'),
        userState: state.get('userState'),
        widgetData: state.get('allGraphsData').get('activity').get('widgetData'),
        isWidgetDataFetched: state.get('allGraphsData').get('activity').get('isWidgetDataFetched'),
        loaders: state.get('loaders'),
        apiErrors: state.get('apiErrors'),
        feedbackStatuses: state.get('feedbackStatuses'),
        user: state.get('user')
    })
};

const mapDispatchToProps = dispatch => ({
    fetchItemData: (batchId, itemId, shouldFetchBaseData) => {
        dispatch(fetchItemData(batchId, itemId, shouldFetchBaseData));
    },
    fetchProductLaunchData: (isEmbeddedLaunch = false) => {
        dispatch(fetchProductLaunchData(isEmbeddedLaunch));
    },
    toggleProductLaunchWindow: (flag) => {
        dispatch(toggleProductLaunchWindow(flag));
    },
    setExitPopup: (flag) => {
        dispatch(setExitPopup(flag))
    },
    setSideBarFlag: (flag) => {
        dispatch(setSideBarFlag(flag))
    },
    setFullScreen: (flag = false) => {
        dispatch(setFullScreen(flag))
    },
    setSelectedItemId: (id) => {
        dispatch(setSelectedItemId(id))
    },
    updateItemIdFromRoute: (itemIdFromRoute) => {
        dispatch(updateItemIdFromRoute(itemIdFromRoute));
    },
    submitFeedback: (flag) => {
        dispatch(submitFeedback(flag))
    },
    setUserState: (payload) => {
        dispatch(setUserState(payload))
    },
    setItemWorkflowStatus: (workflowstatus) => {
        dispatch(setItemWorkflowStatus(workflowstatus))
    },
    resetProductLaunchDetails: () => {
        dispatch(resetProductLaunchDetails())
    },
    updateFetchUserItemsListStatusForFeedbackScreen: (flag) => {
        dispatch(updateFetchUserItemsListStatusForFeedbackScreen(flag))
    },
    setPhaseWorkflowStatus: (batchId, phaseId, workflowStatus, routeObj) => {
        dispatch(setPhaseWorkflowStatus(batchId, phaseId, workflowStatus, routeObj))
    },
    fetchItemProgress: (itemId, batchId) => {
        fetchItemProgress(itemId, batchId);
    }, 
    fetchNextPhaseItemDetailsAndProgress: (currentItem, batchId) => {
        fetchNextPhaseItemDetailsAndProgress(currentItem, batchId);
    },
    refreshPhasesAndPhaseItems: (batchId) => {
        refreshPhasesAndPhaseItems(batchId);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(AssesmentsContainer);
