import React, { Component } from 'react';
import styles from './content.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import PhaseContent from './phaseContent';
import DevelopmentPhaseContent from './developmentPhaseContent';
import DevPhaseModuleItemOnlyContent from './devPhaseModuleItemOnlyContent';
import DevelopmentPhaseModuleContent from './developmentPhaseModuleContent';

import enumConstants from 'constants/enums';
import PhaseCompleted from 'svgComponents/phaseCompleted';
import PhaseExpired from 'svgComponents/phaseExpired';
import LockIcon from 'svgComponents/lockIcon';

import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import ComponentErrorBoundary from 'components/errorBoundaries/componentErrorBoundary';
import { replaceDatePlaceholderAndFormat } from 'utils/locks';
import Immutable from 'immutable';
import { logComponentErrorToSentry } from 'utils/sentry';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import PhaseTabs from 'components/journeys/journeyDashboard/phaseTabs';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Content extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentPhase: this.props.activePhaseId
		}
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.phases.get('activePhaseId') !== nextProps.phases.get('activePhaseId')) {
			this.setPhase(nextProps.phases.get('activePhaseId'));
		}
	}

	setPhase = (id) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			phaseId: id
		}
		window.analytics.track(
			`Phase Tab Clicked - ${id}`,
			segmentData,
			getAmplitudeSessionDetails()
		)

		this.setState({
			currentPhase: id
		});
	}

	getPhaseStatusIcon = (phase, userPhases) => {

		let userPhase = userPhases.find((userPhasesListItem) => {
			return (userPhasesListItem.get('id') === phase.get('id'));
		});

		if (phase.get('isLocked')) {
			return <LockIcon svgColor="#9C9FA8"/>;
		}
		else if (
			userPhase && userPhase.get('workflowState')
			&& userPhase.get('workflowState') === enumConstants.get('COMPLETED')
		) {
			return <PhaseCompleted />;
		}
		else if (phase.get('isExpired')) {
			return <PhaseExpired />
		}

		return null;
	}

	renderPhaseTabs = (phases) => {
		
		// eslint-disable-next-line eqeqeq
		const phasesList = phases.get('phasesList').filter(phase => phase.get('batchId') == this.props.match.params.batchId);

		if (phasesList.size <= 1) return null; //Render nothing if only one phase in journey
		
		const tabsList = phasesList.map(phase => {
			return {
				label: phase.get('name'),
				value: phase.get('id'),
				statusIcon: this.getPhaseStatusIcon(phase, phases.get('userPhasesList'))
			}
		})

		return (
			<div styleName="tabs-container">
				<div styleName="tabs-content">
					<PhaseTabs
						tabsList={tabsList && tabsList.toJS()}
						tabClickHandler={(phaseId)=> this.setPhase(phaseId)}
						selectedTabValue={this.state.currentPhase}
					/>
				</div>
			</div>
		);
	}

	raiseErrorForSkippedCourseIds = (skippedCourseIds) => {
		if (skippedCourseIds.length > 0) {
			console.log('skipped course ids', skippedCourseIds);
			const errorMessage = `Data mismatch for course ids: ${skippedCourseIds.join(', ')}`;
			logComponentErrorToSentry(new Error(errorMessage));
		}
	}

	getDevPhaseItems = (phaseItems, phaseCourses, currentPhase) => {
		if (!phaseCourses || phaseCourses.size === 0) return null;

		const courses = phaseCourses.find(phase => phase.get('id') == currentPhase.get('id'));

		if (!courses) return null;

		if (!courses.get('isCoursesListAvailable')) return courses;

		if (courses.get('coursesList') && courses.get('coursesList').size == 0) {
			let skippedCourseIds = phaseItems.map(phaseItem => phaseItem.get('toolId'));
			this.raiseErrorForSkippedCourseIds(skippedCourseIds.toJS());
			return Immutable.fromJS([]);
		}

		let skippedCourseIds = [];

		let coursesWithPhaseItemData = phaseItems.map((phaseItem) => {
			if (phaseItem.get('type') === enumConstants.get('COURSE')) {

				let course = courses.get('coursesList').filter((course) => {
					return (course.get('id') === phaseItem.get('toolId'))
				}).get(0);

				if (!course) {
					skippedCourseIds.push(phaseItem.get('toolId'));
					return null;
				}

				return course.set('phaseItemData', phaseItem);
			}
		});

		this.raiseErrorForSkippedCourseIds(skippedCourseIds);

		coursesWithPhaseItemData = courses.set('coursesList', coursesWithPhaseItemData);
		return coursesWithPhaseItemData;
	}

	renderPhaseContent = (phases, items, myStyles) => {

		const { activeItemId, loaders, fetchDevPhaseItems, fetchModuleItemsForSingleModuleCourse, match, courses, fetchModulesForDashboard, coursesModulesList, modulesItemsList } = this.props;

		const currentPhase = phases.get('phasesList').filter((eachPhase) => {
			return (eachPhase.get('id') === this.state.currentPhase)
		}).get(0);

		const currentUserPhase = phases.get('userPhasesList').filter((userPhase) => {
			return (userPhase.get('id') === this.state.currentPhase)
		});

		const currentPhaseItems = items.get('itemsList').filter((eachItem) => {
			return (eachItem.get('phaseId') === this.state.currentPhase)
		});

		if (!loaders.get('isFetchingBatchDashboardData') && (currentUserPhase.size == 0 || currentPhaseItems.size == 0)) {
			return <div styleName="no-items">
				<center>
					{this.props.getLabelWithDefaults('phase_no_items_label', 'There are no items in this phase')}
				</center>
			</div>;
		}

		const devPhaseItems = this.getDevPhaseItems(currentPhaseItems, this.props.phaseCourses, currentPhase);

		const phaseLockMessage = currentPhase ? replaceDatePlaceholderAndFormat(currentPhase.get('isLocked') || currentPhase.get('isExpired'), currentPhase.get('lockExplanation'), currentPhase.get('lockDate')) : null;

		if (
			typeof currentPhase === 'undefined'
			|| currentPhase.get('type') === enumConstants.get('ASSESSMENT')
			|| currentPhase.get('type') === enumConstants.get('LEARNING')
		) {
			return (
				<ComponentErrorBoundary>
					<div styleName="phase-lock-message" className={css(myStyles.phaseLockMessage)}>{phaseLockMessage}</div>
					<PhaseContent
						userPhase={currentUserPhase}
						currentPhase={currentPhase}
						location={this.props.location}
						phaseItems={currentPhaseItems}
						attachmentsList={this.props.attachmentsList}
						userItems={items.get('userItemsList')}
						activeItemId={activeItemId}
						loaders={loaders}
						setUserState={this.props.setUserState}
						setFirstTimeFlag={this.setFirstTimeFlag}
						setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
						phases={this.props.phases}
						toggleDialog={this.props.toggleDialog}
						feedbackStatuses={this.props.feedbackStatuses}
					/>
				</ComponentErrorBoundary>
			);
		}

		if (currentPhase.get('type') === enumConstants.get('DEVELOPMENT')) {

			if (
				currentPhase.get('settings') &&
				currentPhase.get('settings').get('developmentItemType')) {

				if (currentPhase.get('settings').get('developmentItemType') === 'MODULE_ITEMS') {
					if (currentPhaseItems.size > 0) {
						return (
							<ComponentErrorBoundary>
								<div styleName="phase-lock-message" className={css(myStyles.phaseLockMessage)}>{phaseLockMessage}</div>
								<DevPhaseModuleItemOnlyContent
									phaseItems={currentPhaseItems}
									location={this.props.location}
									userItems={items.get('userItemsList')}
									setUserState={this.props.setUserState}
									setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
									fetchDevPhaseItems={fetchDevPhaseItems}
									fetchModuleItemsForSingleModuleCourse={fetchModuleItemsForSingleModuleCourse}
									userPhase={currentPhase}
									key={currentUserPhase}
									match={match}
									activeItemId={activeItemId}
									phases={this.props.phases}
									courses={courses}
									setItemWorkflowStatus={this.props.setItemWorkflowStatus}
									toggleDialog={this.props.toggleDialog}
									apiErrors={this.props.apiErrors}
									coursesModulesList={coursesModulesList}
									modulesItemsList={modulesItemsList}
									feedbackStatuses={this.props.feedbackStatuses}
								/>
							</ComponentErrorBoundary>
						);
					}
				} else if (currentPhase.get('settings').get('developmentItemType') === 'MODULE') {
					if (currentPhaseItems.size > 0) {
						return <ComponentErrorBoundary>
							<div styleName="phase-lock-message" className={css(myStyles.phaseLockMessage)}>{phaseLockMessage}</div>
							<DevelopmentPhaseModuleContent
								phaseItems={currentPhaseItems}
								userItems={items.get('userItemsList')}
								setUserState={this.props.setUserState}
								setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
								fetchModulesForDashboard={fetchModulesForDashboard}
								userPhase={currentPhase}
								key={currentUserPhase}
								match={match}
								activeItemId={activeItemId}
								phases={this.props.phases}
								setItemWorkflowStatus={this.props.setItemWorkflowStatus}
								apiErrors={this.props.apiErrors}
								coursesModulesList={coursesModulesList}
								feedbackStatuses={this.props.feedbackStatuses}
							/>
						</ComponentErrorBoundary>
							;
					}
				}
			}

			return (
				<ComponentErrorBoundary>
					<div styleName="phase-lock-message" className={css(myStyles.phaseLockMessage)}>{phaseLockMessage}</div>
					<DevelopmentPhaseContent
						phaseItems={devPhaseItems}
						coursesModulesList={coursesModulesList}
						modulesItemsList={modulesItemsList}
						location={this.props.location}
						userItems={items.get('userItemsList')}
						setUserState={this.props.setUserState}
						setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
						fetchDevPhaseItems={fetchDevPhaseItems}
						userPhase={currentPhase}
						key={currentUserPhase}
						match={match}
						activeItemId={activeItemId}
						phases={this.props.phases}
						setItemWorkflowStatus={this.props.setItemWorkflowStatus}
						toggleDialog={this.props.toggleDialog}
						userState={this.props.userState}
						apiErrors={this.props.apiErrors}
						fetchBatchDetails={this.props.fetchBatchDetails}
						fetchModulesForDashboard={fetchModulesForDashboard}
						fetchModuleItemsForSingleModuleCourse={fetchModuleItemsForSingleModuleCourse}
						feedbackStatuses={this.props.feedbackStatuses}
						user={this.props.user}
					/>
				</ComponentErrorBoundary>
			);

		}
	}

	render() {
		const { phases, items, skinGuide } = this.props;
		const myStyles = getSkin(skinGuide);

		// eslint-disable-next-line eqeqeq
		const phasesList = phases.get('phasesList').filter(phase => phase.get('batchId') == this.props.match.params.batchId);

		return (
			<div
				styleName="content-container"
				style={phasesList.size === 1 ? { paddingTop: '56px' } : {}}
			>
				<div className={css(myStyles.phaseContentContainer)}
					styleName="phase-content-container"
				>
					{this.renderPhaseTabs(phases)}

					<APIErrorBoundary
						key={this.props.match.params.batchId}
						apiError={this.props.apiErrors.get('fetchPhaseItemsError') || this.props.apiErrors.get('fetchUserPhaseItemsError')}
						retryHandler={() => { this.props.fetchBatchDetails(this.props.match.params.batchId) }}>

						{this.renderPhaseContent(phases, items, myStyles)}

					</APIErrorBoundary>

				</div>
			</div>
		);
	}
}

export default Content;
