import React, { Component } from 'react';
import styles from './backArrow.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class BackArrow extends Component {

	render() {
		return (
				<svg width="100%" height="100%" viewBox="0 0 24 24">
					<g fill="none" fillRule="evenodd" stroke={this.props.color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
						<path d="M1.878 12.552h21.12M7.158 19.885l-5.28-7.333 5.165-7.333" />
					</g>
				</svg>
		);
	}
}



export default BackArrow;
