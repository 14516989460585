import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        buttonStyle: {
            borderRadius: '4px',
            border: 'solid 1px rgba(212, 73, 128, 0.8)',
            backgroundColor: skin.get('secondaryColor'),
            color: skin.get('white'),
            ':hover': {
                backgroundColor: skin.get('secondaryVariant'),
                color: skin.get('white'),
            }
        },

        buttonText: {
            // color: skin.get('secondaryColor'),
            ...myTypography.button,
            fontSize: '16px'
            // ':hover': {
            //     color: skin.get('white')
            // }
        }

    });
}

export default getSkin;