import React from 'react';
import { connect } from 'react-redux';
import ReviewQuiz from 'components/moduleItems/reviewQuiz';
import GenericErrorBoundary from 'components/errorBoundaries';
import { getQuizAttemptReviewData, markQuizAttemptSeen} from 'actions/quiz/apiActions';
import Immutable from 'immutable';

import {
    startTracking,
    endTracking
} from 'utils/mixpanelTracking';

class ReviewQuizContainer extends React.Component {
	componentDidMount() {
		this.props.getQuizAttemptReviewData(this.props.match.params);
		startTracking(true, this.props.match.params, 'ModuleItem');
	}

	componentWillReceiveProps(nextProps) {
		if(this.props.match.params.moduleItemId != nextProps.match.params.moduleItemId){
			endTracking();
			startTracking(true, nextProps.match.params, 'ModuleItem');
		}
	}

	componentWillUnmount(){
		endTracking();
	}

	render() {
		return (
			<GenericErrorBoundary>
				<ReviewQuiz {...this.props}/>
			</GenericErrorBoundary>
		);
	}
}

ReviewQuizContainer.propTypes = {};

ReviewQuizContainer.defaultProps = {};


const mapStateToProps = (state) => {
	return {
		moduleItem: Immutable.fromJS(state.get('moduleItem')),
		quiz: Immutable.fromJS(state.get('quiz'))
	}
};

const mapDispatchToProps = (dispatch) => ({
	getQuizAttemptReviewData: (params) => {
		dispatch(getQuizAttemptReviewData(params));
	},
	markQuizAttemptSeen: (params) => {
		dispatch(markQuizAttemptSeen(params));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewQuizContainer);