import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import NextActivityImage from 'images/dashboard/nextBackground.png'

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        progressBarActive: {
            backgroundColor: skin.get('secondaryColor')
        },
        progressText:{
            ...myTypography.caption,
            color: hexToRgbA('#5b5c61', 0.6)
        },
        outerBar: {
			backgroundColor: skin.get('greyColor5')
        },
        mainContainer:{
            borderTop: `7px solid ${skin.get('secondaryColor')}`
        },
        successMessage:{
            ...myTypography.button,
            fontSize: '18px',
            fontWeight: 'bold',
            color: hexToRgbA('#4E535F', 0.9)
        },
        ie11Warning:{
            ...myTypography.caption,
            background: skin.get('primaryColor'),        
            color: skin.get('white')        
        },
        welcomeBg: {
			background: `linear-gradient(135deg, ${hexToRgbA(skin.get('primaryColor'), 0.1)} , ${hexToRgbA(skin.get('secondaryColor'), 0.1)})`
		},
		welcomeBgImg: {
			backgroundImage: `url(${NextActivityImage})`,
			backgroundSize: "100%",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
		}
	});
}

export default getSkin;