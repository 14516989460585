/**
 * @author: sourabh p
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: header.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Single point source for header, exports all the header
 *
 **/

import Immutable from 'immutable';

const header = Immutable.Map({
	COMPANY_NAME: 'Knolskape',
	JOURNEY_NAME: 'FTM Journey',
	USERNAME: 'Hello there',
	JOURNEY_SWITCHER_PROPS: {},
	PROFILE_DROPDOWN_PROPS: {},
	NOTIFICATION_PROPS: {},
	IS_DATA_AVAILABLE: true
});

export default header;
