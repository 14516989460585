import React from 'react';
import { connect } from 'react-redux';
import GenericErrorBoundary from 'components/errorBoundaries';
import {
    getActiveItemDetails,
} from './containerHelpers/assessmentContainerHelper';
import {
    fetchQuizResponse,
    getQuizInitialData,
    updateQuizResponse,
    submitQuiz
} from 'actions/surveyQuiz/apiActions';
import { fetchingQuizResponsesFlag } from 'actions/surveyQuiz/actionCreators';
import TakeQuiz from 'components/surveyQuiz/takeQuiz';
import TakeQuizLoader from 'components/moduleItems/takeQuiz/takeQuizLoader';
import {
    startTracking,
    endTracking
} from 'utils/mixpanelTracking';
class TakeSurveyQuizContainer extends React.Component {

    componentDidMount() {
        this.props.fetchingQuizResponsesFlag(true);
        const { match } = this.props;
        const { batchId, phaseItemId } = match.params;
        this.props.getQuizInitialData(batchId, phaseItemId);
        startTracking(true, this.props.match.params, 'phaseItem');
    }

    componentWillUnmount() {
        endTracking();
    }

    isQuizStoreDataInValidBasedOnRouteParams = () => {
        const { quizData, quizResponseData, match } = this.props;
        const { batchId, phaseItemId } = match.params;
        if (
            quizData.get('quizId') === undefined
            || quizResponseData.getIn(['quizResultData', 'quizId']) === undefined
            || quizResponseData.getIn(['quizResultData', 'quizId']) !== quizData.get('quizId')
        ) {
            return true;
        }
        return false;
    }

    render() {
        const { quizData, quizResponseData, fetchingQuizResponses, hashedQuestions, updateQuizResponse, match, submitQuiz, selectedItem } = this.props;
        const { batchId, phaseItemId } = match.params;
        if (fetchingQuizResponses || this.isQuizStoreDataInValidBasedOnRouteParams()) {
            return (<div>
                <TakeQuizLoader />
            </div>);
        }
        return (
            <GenericErrorBoundary>
                <TakeQuiz quizData={quizData}
                    quizResponseData={quizResponseData}
                    hashedQuestions={hashedQuestions}
                    updateQuizResponse={updateQuizResponse}
                    batchId={batchId}
                    phaseItemId={phaseItemId}
                    submitQuiz={submitQuiz}
                    selectedItem={selectedItem}
                />
            </GenericErrorBoundary>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        selectedItem: getActiveItemDetails(state),
        quizData: state.get('surveyQuiz').get('quizData'),
        hashedQuestions: state.get('surveyQuiz').get('hashedQuizQuestions'),
        quizResponseData: state.get('surveyQuiz').get('quizResponseData'),
        fetchingQuizResponses: state.get('surveyQuiz').get('fetchingQuizResponses')
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchQuizResponse: (quizId) => {
        fetchQuizResponse(quizId);
    },
    getQuizInitialData: (batchId, phaseItemId) => {
        dispatch(getQuizInitialData(batchId, phaseItemId));
    },
    fetchingQuizResponsesFlag: (isFetching) => {
        dispatch(fetchingQuizResponsesFlag(isFetching))
    },
    updateQuizResponse: (payload, batchId, phaseItemId) => {
        updateQuizResponse(payload, batchId, phaseItemId)
    },
    submitQuiz: (payload, batchId, phaseItemId) => {
        submitQuiz(payload, batchId, phaseItemId)
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(TakeSurveyQuizContainer);
