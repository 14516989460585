import React, { Component } from 'react';
import styles from './circularLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CircularLoader extends Component {

    render() {
        return (
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 38 38" stroke="#d44980">
				<g fill="none" fillRule="evenodd">
					<g transform="translate(1 1)" strokeWidth="2">
						<circle stroke="e8eaf2" strokeOpacity=".1" cx="18" cy="18" r="18"></circle>
						<path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(144.032 18 18)">
							<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite"></animateTransform>
						</path>
					</g>
				</g>
			</svg>
        );
    }
}



export default CircularLoader;
