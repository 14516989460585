import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules({ allowMultiple: true })
class SelectedTag extends Component {

    render() {

        const skinHex = this.props.skinGuide.get('skinHex');
        return (
            <svg width="100%" height="100%" viewBox="0 0 13 12">
                <g fill="none" fill-rule="evenodd" transform="translate(.313)">
                    <rect width="12" height="12" fill="#2C4BB7" rx="6" />
                    <path d="M2.25 2.25h7.5v7.5h-7.5z" />
                    <path fill="#FFF" fill-rule="nonzero" d="M8.974 3.854a.616.616 0 0 0-.43-.098.588.588 0 0 0-.378.21L5.424 7.393 3.681 6.108a.614.614 0 0 0-.804.09.504.504 0 0 0 .08.742l2.214 1.63a.622.622 0 0 0 .827-.1L9.1 4.598a.5.5 0 0 0 .105-.396.528.528 0 0 0-.23-.348z" />
                </g>
            </svg>
        );
    }
}



export default SelectedTag;
