import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules( { allowMultiple: true })
class Analytics extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (

            <svg width="100%" height="100%" viewBox="0 0 12 13">
                <g className={css(myStyles.fillColor)} fillRule="nonzero">
                    <path d="M.625 11h1.75a.124.124 0 0 0 .125-.125v-1a.5.5 0 0 0-.5-.5H1a.5.5 0 0 0-.5.5v1A.125.125 0 0 0 .625 11zM4 6.875a.5.5 0 0 0-.5.5v3.5a.125.125 0 0 0 .125.125h1.75a.124.124 0 0 0 .125-.125v-3.5a.5.5 0 0 0-.5-.5H4zM7 7.875a.5.5 0 0 0-.5.5v2.5a.125.125 0 0 0 .125.125h1.75a.124.124 0 0 0 .125-.125v-2.5a.5.5 0 0 0-.5-.5H7zM10 4.375a.5.5 0 0 0-.5.5v6a.125.125 0 0 0 .125.125h1.75a.124.124 0 0 0 .125-.125v-6a.5.5 0 0 0-.5-.5h-1zM10.5.375a1 1 0 0 0-.973 1.23.127.127 0 0 1-.027.11l-1.629 1.9a.127.127 0 0 1-.126.04 1 1 0 0 0-.953.265.124.124 0 0 1-.129.03l-1.089-.362a.126.126 0 0 1-.084-.1 1 1 0 0 0-1.99.137c0 .078.01.156.028.232a.125.125 0 0 1-.042.125l-1.45 1.21a.128.128 0 0 1-.131.019 1 1 0 1 0 .595.914c0-.078-.01-.156-.027-.231a.125.125 0 0 1 .042-.125l1.45-1.21a.125.125 0 0 1 .13-.019 1 1 0 0 0 1.112-.21.125.125 0 0 1 .129-.03l1.088.362a.126.126 0 0 1 .084.1A1 1 0 0 0 8.5 4.625a.988.988 0 0 0-.08-.39.126.126 0 0 1 .02-.131l1.522-1.776a.125.125 0 0 1 .144-.034A1 1 0 1 0 10.5.375zM.5 12.625h11a.5.5 0 1 0 0-1H.5a.5.5 0 1 0 0 1z" />
                </g>
            </svg>
        );
    }
}



export default Analytics;
