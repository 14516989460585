import React, { PureComponent } from 'react';
import {css} from 'aphrodite/no-important';
import FullScreen from 'svgComponents/fullScreen';
import Prev from 'svgComponents/prev';
import Next from 'svgComponents/next';
import ZoomOut from 'svgComponents/zoomOut';
import ZoomIn from 'svgComponents/zoomIn';
import CircularLoader from 'svgComponents/circularLoader';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import {doesBrowserHaveRangeRequestProblems} from 'utils/browser';

import './documentPreview.css';
import getSkin from './skin.js';

import GenericFileIcon from 'images/icons/file-generic.svg';
import PDFIcon from 'images/icons/file-pdf.svg';
import PresentationIcon from 'images/icons/file-presentation.svg';

import ToggleView from './toggleView';
import { isIOS } from 'react-device-detect';

const PDFJS_TEXT_LAYER_MODE = {
    DISABLE: 0, // Should match TextLayerMode enum in pdf_viewer.js
    ENABLE: 1,
    ENABLE_ENHANCE: 2,
};

const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.25;
const MAX_SCALE = 10.0;
const CLASS_INVISIBLE = 'invisible-page';
const PADDING_OFFSET = 30;
const RANGE_CHUNK_SIZE = 1048576; // 1MB
const RANGE_REQUEST_MINIMUM_SIZE = 6291456; // 6MB 26214400; // 25MB

@applyLabel
@applySkin
class DocumentViewerInternal extends PureComponent{

    constructor(props){
        super(props);
        this.state = {
            pageStatus: 1,
            showPageNumberInput: false,
            presentationMode: this.props.isPresentationByDefault            
        };

        if (document.addEventListener) {
			document.addEventListener('webkitfullscreenchange', this.onFullscreenChange, false);
			document.addEventListener('mozfullscreenchange', this.onFullscreenChange, false);
			document.addEventListener('fullscreenchange', this.onFullscreenChange, false);
			document.addEventListener('MSFullscreenChange', this.onFullscreenChange, false);
        }
        
        this.pageInput = React.createRef();
    }


    checkIfRangeDisabled = (fileSize) => {
        if(fileSize <= RANGE_REQUEST_MINIMUM_SIZE) return true; //disabling for files of sizes <= 6 MBs
        if(doesBrowserHaveRangeRequestProblems()) return true; //disabling for some browsers with range request issues
        return false;
    }

    componentDidUpdate(){
        this.focusOnTheDoc();
    }

	componentDidMount(){
		this.pdfjsLib = window.pdfjsLib;
        this.pdfjsViewer = window.pdfjsViewer;

        this.containerEl = document.getElementById('document-container');
        this.docEl = document.getElementById('document');

        if(this.props.isPresentationByDefault){
            this.docEl.classList.add('overflow-hidden');
        }

        this.focusOnTheDoc();

        this.pdfEventBus = new this.pdfjsViewer.EventBus();
        
		this.pdfEventBus.on('pagesinit', this.pagesinitHandler);

		this.pdfLinkService = new this.pdfjsViewer.PDFLinkService({
			eventBus: this.pdfEventBus,
			externalLinkRel: 'noopener noreferrer nofollow', // Prevent referrer hijacking
			externalLinkTarget: this.pdfjsLib.LinkTarget.BLANK, // Open links in new tab
		});

		this.pdfFindController = new this.pdfjsViewer.PDFFindController({
			eventBus: this.pdfEventBus,
			linkService: this.pdfLinkService,
		});

		// Initialize pdf.js in container
        this.pdfViewer = this.initPdfViewer();
        this.originalScrollPageIntoView = this.pdfViewer.scrollPageIntoView;
        this.original_getVisiblePages = this.pdfViewer._getVisiblePages;
        this.pdfLinkService.setViewer(this.pdfViewer);
        
        if(this.props.isPresentationByDefault){
            this.overwritePdfViewerBehavior();
        }

        const disableRange = this.checkIfRangeDisabled(this.props.fileSize);
        console.log('debug chunk', disableRange, this.props.fileSize)
        		
		this.pdfLoadingTask = this.pdfjsLib.getDocument({
            cMapPacked: false,
            url: this.props.fileUrl,
            disableRange,
            disableCreateObjectURL:true,
            disableStream: true,
            rangeChunkSize: RANGE_CHUNK_SIZE
        });

        return this.pdfLoadingTask.promise
            .then(doc => {
                this.pdfLinkService.setDocument(doc, this.props.fileUrl);
                this.pdfViewer.setDocument(doc);
                if(this.props.onStart) this.props.onStart();
                console.log('doc loaded');
            })
            .catch(err => {
                console.log('task err', err); // eslint-disable-line
            });

    }
    
    componentWillUnmount(){

        document.removeEventListener('keydown', this.onKeydownInViewer);

        // Clean up PDF network requests
        if (this.pdfLoadingTask) {
            try {
                this.pdfLoadingTask.destroy();
            } catch (e) {
                // Ignore these errors
            }
        }

        // Clean up viewer and PDF document object
        if (this.pdfViewer) {
            this.pdfViewer.cleanup();

            if (this.pdfViewer.pdfDocument) {
                this.pdfViewer.pdfDocument.destroy();
            }
        }
    }
	
	initPdfViewer = () => {
        return this.initPdfViewerClass(this.pdfjsViewer.PDFViewer);
    }

    
    initPdfViewerClass = (PdfViewerClass) => {
        const textLayerMode = PDFJS_TEXT_LAYER_MODE.ENABLE_ENHANCE;
        return new PdfViewerClass({
            container: document.getElementById('document'),
            eventBus: this.pdfEventBus,
           // findController: this.pdfFindController,
            //imageResourcesPath: null,
            linkService: this.pdfLinkService,
            //maxCanvasPixels: -1,
            textLayerMode: textLayerMode
        });

    }

    zoomIn = () => {
        let newScale = this.pdfViewer.currentScale;
        newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(MAX_SCALE, newScale);       
        this.pdfViewer.currentScaleValue = newScale;
    }

    zoomOut = () => {
        let newScale = this.pdfViewer.currentScale;
        newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(MIN_SCALE, newScale);      
        this.pdfViewer.currentScaleValue = newScale;
    }

    setPageForPresentation = (pageNumber) => {        

        this.checkOverflow();

        this.pdfViewer.currentPageNumber = pageNumber;
        
        const pages = this.docEl.querySelectorAll('.page');
        [].forEach.call(pages, pageEl => {
            pageEl.classList.add(CLASS_INVISIBLE);
        });
        // Show page we are navigating to
        const pageEl = this.docEl.querySelector(`[data-page-number="${this.pdfViewer.currentPageNumber}"]`);
        console.log('pagEl', pageEl);
        pageEl.classList.remove(CLASS_INVISIBLE);

        // Force page to be rendered - this is needed because the presentation
        // DOM layout can trick pdf.js into thinking that this page is not visible
        this.pdfViewer.update();
    }

    setPage = (pageNumber) => {
        if(pageNumber < 1 || pageNumber > this.pdfViewer.pagesCount) return;

        if(this.state.presentationMode){
            return this.setPageForPresentation(pageNumber);
        }

        this.pdfViewer.currentPageNumber = pageNumber;
    }

    previousPage = () => {
        this.setPage(this.pdfViewer.currentPageNumber - 1);
    }

    nextPage = () => {
       this.setPage(this.pdfViewer.currentPageNumber + 1);
    }

    requestFullscreen = (elem) => {
		if (!document.fullscreenElement &&    // alternative standard method
			!document.mozFullScreenElement && !document.webkitFullscreenElement) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
            }
            
		}
    }
    
    exitFullscreen = () => {
		if (document.fullscreenElement ||    // alternative standard method
			document.mozFullScreenElement || document.webkitFullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) { /* Firefox */
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE/Edge */
				document.msExitFullscreen();
			}
		}
    }
    
	onFullscreenChange = () => {
		if (!document.fullscreenElement &&    // alternative standard method
			!document.mozFullScreenElement && !document.webkitFullscreenElement) { /*exiting fullscreen*/
            this.pdfViewer.currentScaleValue = 'auto';
        } else{
            if(this.state.presentationMode){
                this.pdfViewer.currentScaleValue = 'page-fit';
            }else{
                this.pdfViewer.currentScaleValue = 'auto';   
            }
        }
        this.focusOnTheDoc();
	}

    toggleFullscreen = () => {
        if (document.fullscreenElement ||    // alternative standard method
			document.mozFullScreenElement || document.webkitFullscreenElement) {
            this.exitFullscreen();
        } else{
            this.requestFullscreen(this.containerEl);
        }
    }

    focusOnTheDoc = () => {
        if(this.docEl){
            this.docEl.focus();
        }
    }

    onKeydownInViewer = (event) => {
        const keyCode = event.which || event.keyCode; 

        switch (keyCode) {
            case 37:
                this.previousPage();
                break;
            case 39:
                this.nextPage();
                break;
            default:
                return false;
        }

        return true;
    }

    getLastSavedPageNumber = () => {
        let lastSavedPageNumber = 1;

        if(this.props.progress){
            lastSavedPageNumber = parseInt(this.props.progress, 10);
            if(lastSavedPageNumber > this.pdfViewer.pagesCount){
                lastSavedPageNumber = 1;
            }
        }

        return lastSavedPageNumber;
    }

    pagesinitHandler = (event) => {

        this.pdfViewer.currentScaleValue = 'auto';        
        
        let currentPage = 1;

        this.pdfEventBus.on('pagechanging', this.pageChangeHandler);

        document.addEventListener('keydown', this.onKeydownInViewer);
        
        if(this.props.isPresentationByDefault){
            this.pagesinitHandlerForPresentations();
        }

        if(this.props.progress){
            currentPage = this.getLastSavedPageNumber();
            this.setPage(currentPage);
        }
        
        this.setState({pageStatus:currentPage, pagesCount: this.pdfViewer.pagesCount});

        if(this.pdfViewer.pagesCount == 1){
			this.props.onUpdateProgress(1, 1, true);
		}
    }

    pagesinitHandlerForPresentations = (force = false) => {
        // We implement presentation mode by hiding other pages except for the first page
        const pageEls = [].slice.call(document.getElementById('document').querySelectorAll('.pdfViewer .page'), 0);
        console.log('pageEls', pageEls);
        
        pageEls.forEach(pageEl => {
            
            if(force){
                if (pageEl.getAttribute('data-page-number') === `${this.pdfViewer.currentPageNumber}`) {
                    return;
                }
            } else {
                if (pageEl.getAttribute('data-page-number') === '1') {
                    return;
                }
            }

            pageEl.classList.add(CLASS_INVISIBLE);
        });

        //super.pagesinitHandler();

        // Initially scale the page to fit. This will change to auto on resize events.
        this.pdfViewer.currentScaleValue = 'page-fit';
    }

    undoPagesinitHandlerForPresentations = () => {
        const pageEls = [].slice.call(document.getElementById('document').querySelectorAll('.pdfViewer .page'), 0);
        
        pageEls.forEach(pageEl => {
            pageEl.classList.remove(CLASS_INVISIBLE);
        });

        this.pdfViewer.currentScaleValue = 'auto';
    }

    overwritePdfViewerBehavior = () => {
        // Overwrite scrollPageIntoView for presentations since we have custom pagination behavior
        // This override is needed to allow PDF.js to change pages when clicking on links in a presentation that
        // navigate to other pages
        this.pdfViewer.scrollPageIntoView = pageObj => {
            let pageNum = pageObj;
            if (typeof pageNum !== 'number') {
                pageNum = pageObj.pageNumber || 1;
            }

            this.setPage(pageNum);
        };
        // Overwrite _getVisiblePages for presentations to always calculate instead of fetching visible
        // elements since we lay out presentations differently
        this.pdfViewer._getVisiblePages = () => {
            const currentPageObj = this.pdfViewer._pages[this.pdfViewer._currentPageNumber - 1];
            const visible = [
                {
                    id: currentPageObj.id,
                    view: currentPageObj,
                },
            ];

            return {
                first: currentPageObj,
                last: currentPageObj,
                views: visible,
            };
        };
    }

    restorePdfViewerBehavior = () => {
        this.pdfViewer.scrollPageIntoView = this.originalScrollPageIntoView;
        this.pdfViewer._getVisiblePages = this.original_getVisiblePages;
    }

    switchToPresentationMode = () => {
        const currentPageNumber = this.pdfViewer.currentPageNumber;
        this.setState({
            presentationMode: true
        }, () => {
            this.pagesinitHandlerForPresentations(true);
            this.docEl.classList.add('overflow-hidden');
            this.overwritePdfViewerBehavior();
            this.setPage(currentPageNumber);
        });
    }

    switchToVertialScrollMode = () => {
        const currentPageNumber = this.pdfViewer.currentPageNumber;
        this.setState({
            presentationMode: false
        }, () => {
            this.undoPagesinitHandlerForPresentations();
            this.docEl.classList.remove('overflow-hidden');
            this.restorePdfViewerBehavior();
            this.setPage(currentPageNumber);
        });
    }

    onToggleView = () => {
        if(this.state.presentationMode){
            this.switchToVertialScrollMode();
        } else{
            this.switchToPresentationMode();
        }
    }
 
    checkOverflow = () => {
        const doc = this.docEl;
        // Getting the page element to compare to the doc height/width
        const page = this.docEl.querySelector(`[data-page-number="${this.pdfViewer.currentPageNumber}"]`);
        const hasXOverflow = page.clientWidth > doc.clientWidth;
        const hasYOverflow = page.clientHeight - PADDING_OFFSET > doc.clientHeight;

        doc.classList.remove('overflow-x');
        doc.classList.remove('overflow-y');

        if (hasXOverflow) {
            doc.classList.add('overflow-x');
        }

        if (hasYOverflow) {
            doc.classList.add('overflow-y');
        }

        return hasXOverflow || hasYOverflow;
    }

    pageChangeHandler = (event) => { 
        const totalPages = this.state.pagesCount;
        this.setState({pageStatus: event.pageNumber});
        if(totalPages > 1){
            // eslint-disable-next-line eqeqeq
            if(event.pageNumber == totalPages){
                this.props.onUpdateProgress(event.pageNumber, totalPages, true);
            } else{
                this.props.onUpdateProgress(event.pageNumber, totalPages);
            }
        }
    }

    showPageNumberInput = () => {
        this.setState({showPageNumberInput: true}, () => {
            this.pageInput.current.focus();
            this.pageInput.current.select();
            this.pageInput.current.addEventListener('blur', this.pageNumInputBlurHandler);
            this.pageInput.current.addEventListener('keydown', this.pageNumInputKeydownHandler);
        });
    }

    hidePageNumberInput = () => {
        this.pageInput.current.removeEventListener('blur', this.pageNumInputBlurHandler);
        this.pageInput.current.removeEventListener('keydown', this.pageNumInputKeydownHandler);
        this.setState({showPageNumberInput: false});
    }

    isNatural = (s) => {
        var n = parseInt(s, 10);        
        return n >= 0 && n.toString() === s;
    }

    pageNumInputBlurHandler = (event) => {
        if(this.isNatural(event.target.value)){
            const page = parseInt(event.target.value, 10);
            this.setPage(page);
        }
        this.hidePageNumberInput();
    }

    pageNumInputKeydownHandler = (event) => {
        const key = event.keyCode;

        switch (key) {
            case 13:   //Enter pressed             
                event.target.blur();
                event.stopPropagation();
                event.preventDefault();
                break;

            case 27: // ESC key 
                this.hidePageNumberInput();
                event.stopPropagation();
                event.preventDefault();
                break;

            default:
                break;
        }
    }

    getFileIcon = () => {
        switch(this.props.type){
            case 'Presentation': return PresentationIcon;
            case 'PDF': return PDFIcon;
            default: return GenericFileIcon;
        }
    }

    renderLoader = () => {
        const styles = getSkin(this.props.skinGuide);
        return <div className="loading-container">
            <img src={this.getFileIcon()} className="loading-icon" alt="File Loading Icon"/>
            <div className={css(styles.loadingText)}>Loading document...</div>
        </div>;
    }

	render(){
        const styles = getSkin(this.props.skinGuide);
        
		return (
            <span>
            {!this.state.pagesCount && this.renderLoader()}
			<div id="document-container" className={`${this.state.presentationMode ? 'presentation' : null}`}>
                <div id="document" tabIndex="0">         
                    <div id="viewer" className="pdfViewer">
                    </div>
                </div>
                {this.state.pagesCount && <div style={{width: this.props.viewerWidth}} className="progress-container">
                    <div style={{
                            width: `${(this.state.pageStatus/this.state.pagesCount)*100}%`
                        }}
                        className={css(styles.progress)}
                    >
                    </div>
                </div>}
                <div className="toolbar">
                    {this.state.pagesCount && <React.Fragment>
                        <div className="zoom-controls">
                            <span onClick={this.zoomOut} className="zoom-out"><ZoomOut/></span>
                            <span onClick={this.zoomIn}><ZoomIn/></span>
                        </div>
                        <div className="page-nav">                        
                            <div onClick={this.previousPage} className="page-nav-arrow-container"> <Prev/> </div>
                            
                            <div className="page-status-container">
                                {this.state.showPageNumberInput ? <input type="text"  defaultValue={this.state.pageStatus} ref={this.pageInput}/>  : <div className="page-status-text" onClick={this.showPageNumberInput}>
                                    {this.state.pageStatus} of {this.state.pagesCount}
                                </div>}
                            </div>
                            
                            <div onClick={this.nextPage} className="page-nav-arrow-container"> <Next/>  </div>
                        </div>
                        <div className="right-controls">
                            <ToggleView onToggle={this.onToggleView} label={this.props.getLabel('presentation_view_label')} on={this.state.presentationMode}/>
                            
                            {!isIOS && <div onClick={this.toggleFullscreen} className="fullscreen"><FullScreen/></div>}
                        </div>
                    </React.Fragment>}
                </div>
            </div>
            </span>
		);
	}

}

function loadExternalScript(url){
	return new Promise(function(resolve, reject){
		var script = document.createElement('script');
		script.type = 'text/javascript';
		script.addEventListener('load', function(){
			//this.removeEventListener('load', arguments.callee)
			resolve(script);
        });
        script.onError = function(error){
            console.log('error', error);
			//this.removeEventListener('load', arguments.callee)
			reject(script);
        };
		script.src = url;
		document.body.appendChild(script)
	})
}


function loadExternalStyleSheet(url){
	return new Promise(function(resolve, reject){
        var sheetNode = document.createElement('link');
        sheetNode.addEventListener('load', function(){
			//this.removeEventListener('load', arguments.callee)
			resolve(sheetNode);
        });
        
        sheetNode.onError = function(){
			//this.removeEventListener('load', arguments.callee)
			reject(sheetNode);
        };
        
        sheetNode.setAttribute('rel', 'stylesheet');
        sheetNode.setAttribute('href', url);
        sheetNode.setAttribute('data-href', url);
        document.body.appendChild(sheetNode);
	})
}

export default class DocumentPreview extends PureComponent {
    
    constructor(props){
        super(props);
        this.state = {libraryLoaded: window.docPreviewLibsLoaded || false };
    }
    
    loadScripts = async () => {
        try{
            await loadExternalStyleSheet(`${window.location.origin}/pdf.js/es5/pdf_viewer.css`);
            await loadExternalScript(`${window.location.origin}/pdf.js/es5/pdf.min.js`);
            await loadExternalScript(`${window.location.origin}/pdf.js/es5/pdf_viewer.js`);
            window.pdfjsLib.GlobalWorkerOptions.workerSrc = `${window.location.origin}/pdf.js/es5/pdf.worker.min.js`;
            window.docPreviewLibsLoaded = true;
            this.setState({libraryLoaded: true});
        } catch(error){
            console.log('error', error);
        }
    }

    componentDidMount(){
        if(!this.state.libraryLoaded){
            this.loadScripts(); //load only once
        }
    }

    render(){
        if(!this.state.libraryLoaded || !this.props.fileUrl){
            return <div className="library-loader-cnt"><CircularLoader/></div>;
        }
        return <DocumentViewerInternal{...this.props}/>;
    }
}
