import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setQuizResponse, fetchingQuizResponsesFlag } from 'actions/surveyQuiz/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
import { isEmptyObject } from 'utils/objectUtils'
class StartQuiz extends NetPackWrapper {

    constructor() {
        super("POST");
    }

    prepareUrl = (apiInfoObject) => {
        const url = urls.get('PLATFORM').get('START_QUIZ');
        return appendQueryParameters(url, apiInfoObject.queryParameters);
    }

    successCall = (response) => {
        this.dispatch(setQuizResponse(response));
        this.dispatch(fetchingQuizResponsesFlag(false));
        return response;
    }

    onError = (error) => {

        let errorMessage = 'Error occured';
        if (!isEmptyObject(error.errorObject.response) && error.errorObject.response.message !== "") {
            errorMessage = errorMessage + " : " + error.errorObject.response.message
        }
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: errorMessage,
            isDisplayed: false,
            autoCloseTime: 10000
        }));
    }

}

export default new StartQuiz();
