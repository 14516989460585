/**
 * @author: ujjwal
 * @since: Thu Sep 27 2018 09:20:31 GMT+0530 (IST)
 * @file: assessment.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { getFormattedPayloadDataOneLevel, getHierarchicalFormattedMeasuresData } from 'utils/analytics/transformationFunctions';



const initialState = Immutable.fromJS({
   
    assesmentScoreData: [],
    isAssesmentScoreFetched: false,

    assesmentProgressData: [],
    isAssesmentProgressFetched: false,

    assesmentTimeSpentData: [],
    isAssesmentTimeSpentFetched: false,

    assesmentMeasuresData: [],
    isAssesmentMeasuresFetched: false

});

const assessment = (state = initialState, action = {}) => {
    switch (action.type) {
        /*Assesment score section data */
        
        case actionConsts.get('ASSESSMENT_SCORE_DATA'):

        const scoreDataAssessments = getFormattedPayloadDataOneLevel(action.payload, 'data');

            return state.set(
                'assesmentScoreData',
                Immutable.fromJS(scoreDataAssessments)
            ).set(
                'isAssesmentScoreFetched',
                true
            );
        
        /*Assesment progress section data */
        
        case actionConsts.get('ASSESSMENT_PROGRESS_DATA'):
            
            const progressDataAssessments = getFormattedPayloadDataOneLevel(action.payload, 'data');
            
            return state.set(
                'assesmentProgressData',
                Immutable.fromJS(progressDataAssessments)
            ).set(
                'isAssesmentProgressFetched',
                true
            );
        
        /*Assesment time spent section data */
        
        case actionConsts.get('ASSESSMENT_TIME_SPENT_DATA'):

            const timeSpentDataAssessments = getFormattedPayloadDataOneLevel(action.payload, 'data', true);

            return state.set(
                'assesmentTimeSpentData',
                Immutable.fromJS(timeSpentDataAssessments)
            ).set(
                'isAssesmentTimeSpentFetched',
                true
            );

        /*Assesment measures section data */
        
        case actionConsts.get('ASSESSMENT_MEASURES_DATA'):

            const assessmentMeasuresData = getHierarchicalFormattedMeasuresData(action.payload);
            
            return state.set(
                'assesmentMeasuresData',
                Immutable.fromJS(assessmentMeasuresData)
            ).set(
                'isAssesmentMeasuresFetched',
                true
            );
            
        default:
            return state;
    }
};

export default assessment;