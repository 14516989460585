import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { updateAttachmentInList } from 'actions/attachments/actionCreators';

class FetchAttachmentDetail extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject) => urls.get('PLATFORM').get('GET_ATTACHMENT_DETAIL').replace(
        'ATTACHMENT_ID',
        apiInfoObject.attachmentId
    );

    successCall = (response) => {
        this.dispatch(updateAttachmentInList(response));
        return response;
    }

    onError = (response) => {
        console.log("response", response);
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong while loading the file. Please refresh the page.',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new FetchAttachmentDetail();
