import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        buttonStyle: {
            backgroundColor: skin.get('primaryColor'),
            borderRadius: '6px',
            boxShadow: `0 14px 16px 0 rgba(0, 0, 0, 0.19)`,
            ':hover': {
                backgroundColor: '#223b92'
            }

        },

        buttonText: {
            ...myTypography.button,
            color: skin.get('white')
        }
    });
}

export default getSkin;