import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreCardComponent.module.sass';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import RankLogo from 'images/analytics/rank.svg'
import CardBg from 'images/analytics/score_card_bg.svg'
import UserRank from '../../../svgComponents/analytics/userRank';

@CSSModules(styles, { allowMultiple: true })

class ScoreCardComponent extends React.Component {

    renderSubScore = (fontStyleSubscore) => {
        if (this.props.cardData.showSubscore) {
            return (
                <div className={fontStyleSubscore} styleName="sub-score-content">
                    {this.props.strings.get('ofLabel')} {this.props.cardData.value.data.total}
                </div>
            )
        }
        return;
    }

    renderScoreVariation = (myStyles) => {

        if (this.props.cardData.value.data.lastValue > 0) {
            if (this.props.cardData.showSubscore) {
                const scoreVariation = this.props.cardData.value.data.value - this.props.cardData.value.data.lastValue
                const getClassNameForArrow = scoreVariation > 0 ? css(myStyles.positiveRankIncreaseStyle) : css(myStyles.negativeRankIncreaseStyle);
                const getStyleNameForArrow = scoreVariation > 0 ? 'arrow-up' : 'arrow-down';
                return (
                    <div className={css(myStyles.fontStyleUpdate)} styleName="card-week-update">
                        <div className={getClassNameForArrow} styleName={getStyleNameForArrow}>
                        </div>
                        <span className={css(myStyles.rankFontStyle)}>
                            {Math.abs(scoreVariation)}
                        </span>
                        {this.props.strings.get('sinceWeeksMessage')}
                    </div>
                )
            } else {
                const scoreVariation = this.props.cardData.value.data.value - this.props.cardData.value.data.lastValue
                const getClassNameForArrow = scoreVariation < 0 ? css(myStyles.positiveRankIncreaseStyle) : css(myStyles.negativeRankIncreaseStyle);
                const getStyleNameForArrow = scoreVariation < 0 ? 'arrow-up' : 'arrow-down';
                return (
                    <div className={css(myStyles.fontStyleUpdate)} styleName="card-week-update">
                        <div className={getClassNameForArrow} styleName={getStyleNameForArrow}>
                        </div>
                        <span className={css(myStyles.rankFontStyle)}>
                            {Math.abs(scoreVariation)}
                        </span>
                        {this.props.strings.get('sinceWeeksMessage')}
                    </div>
                )
            }
        }
        return;
    }

    renderCardValue = (myStyles) => {
        if (this.props.cardData.isUserScoreAvailable == 1) {
            return (
                <div styleName="card-details">
                    <div className={css(myStyles.fontStyleScore)} styleName="card-score-container">
                        <div styleName="score-content">
                            {
                                this.props.cardData.value.data.value !== null
                                    && this.props.cardData.value.data.value !== undefined ?
                                    Math.round(this.props.cardData.value.data.value * 100) / 100
                                    :
                                    this.props.cardData.value.data.value
                            }
                            {this.renderSubScore(css(myStyles.fontStyleSubscore))}
                        </div>
                    </div>
                    {/* this.renderScoreVariation(myStyles) */}
                    <div styleName="bg-container">
                        <img src={CardBg} />
                    </div>
                </div>
            )
        }

        return (
            <div styleName="card-details">
                <div className={css(myStyles.fontStyleScore)} styleName="card-score-container">
                    <div styleName="score-content">
                        NA
                        </div>
                </div>
                <div styleName="bg-container">
                    <img src={CardBg} />
                </div>
            </div>
        )
    }

    render() {

        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div styleName="card-container">
                <div className={css(myStyles.cardTitleStyle)} styleName="card-title">
                    <div styleName="rank-logo-container">
                        <UserRank fillColor="#575E79" />
                    </div>
                    {this.props.cardData.cardTitle}
                </div>
                {this.renderCardValue(myStyles)}
            </div>
        )
    }
}


export default ScoreCardComponent;