import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './overallGraphs.module.sass';
import OverallActivityWithInfo from './overallActivityWithInfo';
import OverallPerformanceWithInfo from './overallPerformanceWithInfo';

import { getPhaseIdForPhaseType } from 'utils/analytics/utilFunctions';

import RadarGraph from 'commonComponents/graphs/radarGraph';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent/InfoWithoutFilterComponent';

import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import {checkIfPresent} from 'utils/utilFunctions';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import analyticGroupColors from 'constants/analyticGroupColors';

@CSSModules(styles, { allowMultiple: true })

class OverallGraphs extends React.Component {

	componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
		const phaseList = this.props.phases.get('phasesList');
		const phaseId = getPhaseIdForPhaseType(phaseList, "Assessment");
        this.props.fetchAssessmentMeasuresData(+batchId, +userId, +phaseId);
	}

	renderMeasuresGraph = () => {

		const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
		const phaseList = this.props.phases.get('phasesList');
		const phaseId = getPhaseIdForPhaseType(phaseList, "Assessment");
        if(this.props.apiErrors.get('fetchAssessmentMeasuresDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchAssessmentMeasuresDataError')}
                retryHandler={()=>{
					this.props.fetchAssessmentMeasuresData(+batchId, +userId, +phaseId);
				}}
            />
        }

		const allGraphsData = this.props.allGraphsData.get('assessment');

        if (allGraphsData.get('isAssesmentMeasuresFetched')) { 

			const graphData = allGraphsData.get('assesmentMeasuresData');
			const strings = graphData.get('strings');
			const competencies = graphData.get('competencies').toJS();
			const compRadarStyling = {
				fromUser: '#ffce23',
				toUser: '#fea429',
				userStroke: '#feaa28',
				fromUserLegend: '#ffce23',
				toUserLegend: '#fea429',
				// fromGroup: '#ff0264',
				// toGroup: '#ff71ab',
				// groupStroke: '#ff0264',
				// fromGroupLegend: '#ff0264',
				// toGroupLegend: '#ff71ab',
				...analyticGroupColors,
				gridValue: 10,
				componentName: 'assess-competency-score'
			};
	
			const graphFormatCompetency = {
				valueName: strings.get('competencyLabel')
			};
	
			return (
				<div styleName="content">
					<InfoWithoutFilterComponent
						{...this.props}
						title={strings.get('competencyGraphTitle')}
                    	subtitle={strings.get('competencyGraphDesc')}
					/>
					<RadarGraph
						graphFormat={graphFormatCompetency}
						{...this.props}
						// data={this.props.data.radarData}
						barStyling={compRadarStyling}
						graphData={competencies}
						strings={strings.toJS()}
					/>
				</div>
			)			
		}
		return <LoaderComponent />;
    }

	renderPerformanceOrMeasures = (showPerformanceFlag) => {
		// if (this.props.isAssessmentOnlyJourney) {
		// 	return this.renderMeasuresGraph()
		// } 
		// return(
		// 	<div styleName="content">
		// 		<OverallPerformanceWithInfo gridValue={10} {...this.props} />
		// 	</div>
		// )
		if(showPerformanceFlag) {
			return(
				<div styleName="content">
					<OverallPerformanceWithInfo gridValue={10} {...this.props} />
				</div>
			)
		} else if (this.props.isAssessmentOnlyJourney) {
			return this.renderMeasuresGraph();
		}
	}

    render(){
		// const journeyType = getJourneyType(this.props.phases.get('phasesList'));
		// const isAssessmentOnlyJourney = journeyType === 'A' ? true : false;

		const gridValue = 9.5;
        return(
            <div styleName="overall-graph-container">
				<div styleName="single-graph-content">
					<div styleName="content">
						<OverallActivityWithInfo gridValue={gridValue} {...this.props} />
					</div>
				</div>
				<div styleName="single-graph-content">
					{this.renderPerformanceOrMeasures(this.props.showPerformanceFlag)}
				</div>
			</div>
        );
    }
}

export default OverallGraphs;