import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const NavigationListener = () => {
  const history = useHistory();

  useEffect(() => {
    // This will be triggered on every route change
    const unlisten = history.listen((location, action) => {
      console.log(`Navigation detected to ${location.pathname}, action: ${action}`);
      window.parent.postMessage('trigger', '*');
    });

    // Cleanup listener on component unmount
    return () => {
      unlisten();
    };
  }, [history]);

  return null; // This component doesn't render anything
};

export default NavigationListener;
