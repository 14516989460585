import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		nextPlanContainer: {
			backgroundColor: skin.get('white'),
			boxShadow: `0 -1px 0 0 rgba(136, 136, 136, 0.09), 0 -1px 4px 0 ${hexToRgbA(skin.get('black'), 0.21)}`
		},
		title: {
			...myTypography.h4,
			color: hexToRgbA(skin.get('greyColor1'), 0.85)
		},
		subTitle: {
			...myTypography.overline,
			color: hexToRgbA(skin.get('greyColor1'), 0.85 * 0.6),
			textTransform: 'uppercase'
		},
		button: {
            boxShadow: `0 14px 16px 0 ${hexToRgbA(skin.get('black'), 0.19)}`,
            backgroundColor: skin.get('primaryColor'),
            borderRadius: '4px',
            textTransform: 'uppercase',
            ...myTypography.button,
            color: skin.get('white'),
            cursor: 'pointer'
        }
	});
}

export default getSkin;