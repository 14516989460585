import React, { Component } from 'react';
import styles from './exitConfirmation.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ExitConfirmation extends Component {

    onClickOfCancel = () => {
        const { setExitPopup } = this.props;
        setExitPopup(false);
    }

    sendSegmentData = () => {
        const { selectedItem } = this.props;
        window.analytics.track(
            'Simulation closed',
            {
                batchId: selectedItem.get('batchId'),
                journeyId: selectedItem.get('journeyId'),
                itemId: selectedItem.get('id')
            },
            getAmplitudeSessionDetails()
        )
    }

    onClickOfExit = () => {
        const { toggleProductLaunchWindow, setExitPopup } = this.props;
        this.sendSegmentData();
        toggleProductLaunchWindow(false);
        setExitPopup(false);
    }

    render() {
        const { skinGuide, getLabelWithDefaults } = this.props;
        const myStyles = getSkin(skinGuide);

        return (
            <div styleName="exit-container">
                <div
                    className={css(myStyles.exitText)}
                    styleName="exit-message-container">
                    {getLabelWithDefaults('exit_confirmation_message', 'Are you sure you want to exit?')}
                </div>
                <div styleName="buttons-holder">
                    <div styleName="button-end">
                        <div className={css(myStyles.yesButton)}
                            onClick={this.onClickOfExit}>
                            {getLabelWithDefaults('exit_confirmation_yes_label', 'YES, EXIT')}
                        </div>
                        <div
                            className={css(myStyles.cancelButton)}
                            onClick={this.onClickOfCancel}>
                            {getLabelWithDefaults('cancel_label', 'Cancel')}
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ExitConfirmation;
