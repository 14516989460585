import React, { Component } from 'react';
import styles from './questionText.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class QuestionText extends Component {

	render() {
		const { question } = this.props;
		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div
				className={css(myStyles.questionText)}
				styleName="question-text"
				dangerouslySetInnerHTML={{ __html: question.get('text') }}
			>
			</div>
		);
	}
}

export default QuestionText;
