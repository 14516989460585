import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';
import {isMSTeams} from 'utils/msTeamsUI';
import {isLaunchedFromExternalIntegration} from 'utils/externalIntegrations';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        headerContainer: {
            backgroundColor: skin.get('white'),
            boxShadow: `0 1px 0 0 rgba(196, 196, 196, 0.63)`
        },
		logoName: {
            ...myTypography.h5,
            fontWeight: 700,
            color: skin.get('primaryColor')
        },
        individualItemContainer: {
            borderLeft: `2px solid ${hexToRgbA('#D6D6D6', 0.4)}`
        },
        firstIndividualItemContainer: {
            borderLeft: isMSTeams() || isLaunchedFromExternalIntegration() ? 'none' : `2px solid ${hexToRgbA('#D6D6D6', 0.4)}`
        },
        individualItemName: {
            ...myTypography.overline,
            fontWeight: 600,
            color: hexToRgbA('#353535', 0.8)
        },
        addCursor: {
            cursor: 'pointer'
        },
        addRelative: {
            position: 'relative'
        }
	});
}

export default getSkin;