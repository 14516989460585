import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import styles from './batchFeedbackStatusList.module.sass';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import reports from 'constants/reports';
import ReportDownloadContainer from 'containers/ReportDownloadContainer';
import PrimaryButtonMini from 'commonComponents/buttons/primaryButtonMini';
import CircularLoader from 'svgComponents/circularLoader'

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class BatchFeedbackStatusList extends Component {

    componentDidMount() {
		this.props.fetchBatchFeedbackStatus(+this.props.batchId);
	}

    getEntityLabel = (entity) => {
        const { batchDetails, getLabel } = this.props;

        if(batchDetails.get('totalItems') === 1)
            return getLabel('course_feedback_label');

        if(entity.get('scenario') === 'ASSESSMENT_PHASE')
            return getLabel('assessment_phase_feedback_label');
            
        return entity.get('entityName')
    }

    getEntityNameStyle = (entity, styles, firstIncompleteEntityId) => {
        if(entity.get('completed')){
            return styles.completedEntityName;
        }
        if(entity.get('entityId') === firstIncompleteEntityId){
            return styles.activeEntityName;
        }
        return styles.entityName;
    }

    getConnectorCircleStyle = (entity, styles, firstIncompleteEntityId) => {
        if(entity.get('completed')){
            return styles.completedConnectorCircle;
        }
        if(entity.get('entityId') === firstIncompleteEntityId){
            return styles.activeConnectorCircle;
        }
        return styles.connectorCircle;
    }

    renderList = () => {
        const { feedbackStatus, onSelectEntity, skinGuide, getLabel } = this.props;
        const styles = getSkin(skinGuide);
        const numberOfRows = feedbackStatus.getIn(['status', 'data']).size;
        let firstIncompleteEntityId = null;

        return <div styleName="list-inner-container">
            <div style={{ height: `${96 * (numberOfRows - 1)}px`}} className={css(styles.stepperConnector)} styleName="stepper-connector"></div>
            {feedbackStatus.getIn(['status', 'data']).map(entity => {

                if(entity.get('completed') === false && firstIncompleteEntityId === null){
                    firstIncompleteEntityId = entity.get('entityId');
                }

                return <div key={entity.get('entityId')} styleName="entity-row" className={css(styles.entityRow)}>
                    <div styleName="entity-name-container">
                        <div styleName="connector-circle" className={css(this.getConnectorCircleStyle(entity, styles, firstIncompleteEntityId))}></div>
                        <div className={css(this.getEntityNameStyle(entity, styles, firstIncompleteEntityId))}>
                            {this.getEntityLabel(entity)}
                        </div>
                    </div>
                    <div>{
                        entity.get('completed') ? <div>
                            <div onClick={() => onSelectEntity(entity)} className={css(styles.editFeedbackButton)}>
                                {getLabel('feedback_list_edit_feedback_button_label')}
                            </div>
                        </div>: <div>                        
                            <PrimaryButtonMini
                                text={getLabel('feedback_list_add_feedback_button_label')}
                                clickFunction={() => onSelectEntity(entity)}
                            />               
                        </div>                        
                       }
                    </div>
                </div>;
            })}
        </div>;
    }

    renderLoader = () => {
		return <div style={{ height: '100%', width: '43px', margin: 'auto' }}>
			<CircularLoader />
		</div>
	}

    render() {
        const { batchDetails, feedbackStatus, skinGuide, getLabel } = this.props;
        const styles = getSkin(skinGuide);

        if(feedbackStatus.get('loading')){
            return this.renderLoader();
        }

        return (
            <div styleName="container">
                <div className={css(styles.journeyFeedbackHeading)} styleName="journey-feedback-heading">   
                   {batchDetails.get('journey').get('name')} Feedback
                </div>
                <div styleName="list-outer-container">
                    <div styleName="stats">
                        <div styleName="stat">
                            <span className={css(styles.statLabel)}>{getLabel('feedback_list_total_items_heading_label')}: </span>
                            <span className={css(styles.statValue)}>{feedbackStatus.getIn(['status','total'])}</span>
                        </div>
                        <div styleName="stat">
                            <span className={css(styles.statLabel)}>{getLabel('feedback_list_items_feedback_pending_label')}: </span>
                            <span className={css(styles.statValue)}>{feedbackStatus.getIn(['status','totalFeedbackPendingCount'])}</span>
                        </div>
                    </div>
                    {this.renderList()}
                </div>

                <div styleName="bottom-actions-container">
                    <div styleName="download-btn-container"
                        style={{
                            opacity: feedbackStatus.getIn(['status','totalFeedbackPendingCount']) ? 0.5 : 1,
                            pointerEvents: feedbackStatus.getIn(['status','totalFeedbackPendingCount']) ? 'none' : 'auto'
                        }}
                    >
                        <ReportDownloadContainer
                            reportType={reports.get('certificate')}
                            reportOutputType="pdf"
                            metadata={{ batchId: +batchDetails.get('id') }}
                            downloadButtonText={this.props.getLabelWithDefaults('download_certificate_label', 'Download Certificate')}
                        />
                    </div>
                </div>

            </div>
        )
    }
}

export default BatchFeedbackStatusList;
