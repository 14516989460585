import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		sessionTooltipContainer: {
			background: skinHex.get('white'),
			border: `1px solid ${skinHex.get('secondaryColor')}`,
			boxShadow: '2px 3px 4px rgba(0, 0, 0, 0.25)',
			...myTypography.caption,
			fontWeight: 400,
			color: '#5F5E5E'
		},
		sessionTitle: {
			...myTypography.caption,
			fontWeight: 700,
			color: skinHex.get('secondaryColor')
		},
		timingBold: {
			fontWeight: 700
		}

	});
}

export default getSkin;