import React, { Component } from 'react';
import styles from './menuFooter.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import Home from 'svgComponents/home';
import Analytics from 'svgComponents/analytics';
import Performance from 'svgComponents/performance';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import { hasAssessmentPhaseStarted, hasDevelopmentPhaseStarted } from 'utils/analytics/utilFunctions';
import { shouldShowAnalytics, checkIfPresent } from 'utils/utilFunctions';
import environment from 'utils/environment';
import TextTooltip from 'commonComponents/textTooltip';
import {getActiveBatchDetailsWithId} from 'utils/batchUtils';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class MenuFooter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showActivityTooltip: false,
            showPerformanceTooltip: false
        }
    }

    onClickOfBackToHome = () => {
        const { batchId } = this.props;
        const route = routes.get('BATCH');
        const params = [{ 'BATCH_ID': batchId }]
        updateRoute({ route, params });
    }

    moveToActivty = () => {
        const { batchId } = this.props;
        const route = routes.get('BATCH_ACTIVITY');
        const params = [{ 'BATCH_ID': batchId }]
        updateRoute({ route, params });
    }

    moveToPerformance = () => {
        const { batchId } = this.props;
        const route = routes.get('BATCH_PERFORMANCE');
        const params = [{ 'BATCH_ID': batchId }]
        updateRoute({ route, params });
    }

    renderActivity = (myStyles, renderFlag) => {
        return (
            <div
                key="user-activity"
                className={renderFlag ? css(myStyles.seperationLine) : css(myStyles.seperationLine, myStyles.disableButton)}
                styleName="icon-and-text-container"
                onClick={renderFlag ? this.moveToActivty : null}
                onMouseEnter={!renderFlag ? () => {
                    this.setState({ showActivityTooltip: true });
                } : null}
                onMouseLeave={!renderFlag ? () => {
                    this.setState({ showActivityTooltip: false });
                } : null}
            >
                <div styleName="icon-open">
                    <Analytics />
                </div>
                <div className={css(myStyles.text)} styleName="text">
                    {this.props.getLabel('activity_label')}
                </div>
                {
                    this.state.showActivityTooltip && !renderFlag
                        ? <span styleName="tooltip">
                            <div className={css(myStyles.tooltipContent)} styleName="tooltip-content">
                                {this.props.getLabel('analytics_activity_disabled_tooltip')}
                            </div>
                            <div styleName="tooltip-arrow-container">
                                <div className={css(myStyles.tooltipArrow)}></div>
                            </div>
                        </span>
                        : null
                }
            </div>
        );
    }

    renderPerformance = (myStyles, renderFlag) => {
        const performancePageAvailable = checkIfPresent(this.props.userState.get('isPerformancePageAvailable'))
            ? this.props.userState.get('isPerformancePageAvailable')
            : 0;
        return (
            <div
                key="user-performance"
                className={renderFlag ? null : css(myStyles.seperationLine, myStyles.disableButton)}
                styleName="icon-and-text-container"
                onClick={renderFlag ? this.moveToPerformance : null}
                onMouseEnter={!renderFlag ? () => {
                    this.setState({ showPerformanceTooltip: true });
                } : null}
                onMouseLeave={!renderFlag ? () => {
                    this.setState({ showPerformanceTooltip: false });
                } : null}
            >
                <div styleName="icon-open">
                    <Performance />
                </div>
                <div className={css(myStyles.text)} styleName="text">
                    {this.props.getLabel('performance_label')}
                </div>
                {
                    this.state.showPerformanceTooltip && !renderFlag
                        ? <span styleName="tooltip">
                            <div className={css(myStyles.tooltipContent)} styleName="tooltip-content">
                                {
                                    performancePageAvailable
                                        ? this.props.getLabel('analytics_performance_disabled_tooltip')
                                        : this.props.getLabel('analytics_performance_not_available_tooltip')
                                }
                            </div>
                            <div styleName="tooltip-arrow-container">
                                <div className={css(myStyles.tooltipArrow)}></div>
                            </div>
                        </span>
                        : null
                }
            </div>
        );
    }

    renderEmptyDiv = (myStyles, shouldRenderActivity, shouldRenderPerformance) => {
        if (!shouldRenderActivity && !shouldRenderPerformance) {
            return (
                <div
                    styleName="menu-footer-container-no-elements"
                    className={css(myStyles.emptyDiv)}
                    style={this.props.forDevPhase ? {
                        width: '340px',
                        height: '48px'
                    } : null}
                >
                </div>
            );
        }
    }

    getStyleForMenuFooter = (shouldRenderActivity, shouldRenderPerformance) => {
        if (!shouldRenderActivity && !shouldRenderPerformance) {
            return ({
                backgroundColor: ' #f6f7f9',
                boxShadown: 'none'
            });
        }
        else {
            return ({});
        }
    }

    renderFooterItems = (myStyles, shouldRenderActivity, shouldRenderPerformance) => {
        return [
            this.renderActivity(myStyles, shouldRenderActivity),
            this.renderPerformance(myStyles, shouldRenderPerformance)
            // this.renderEmptyDiv(myStyles, shouldRenderActivity, shouldRenderPerformance)
        ];
    }

    shouldRenderAnalyticsSection = (batchDetails) => {
        if(!shouldShowAnalytics()) return false;

        const analyticsEnabledForBatch = checkIfPresent(batchDetails.get('journey'))
        && checkIfPresent(batchDetails.get('journey').get('settings'))
        && checkIfPresent(batchDetails.get('journey').get('settings').get('showAnalytics')) && (batchDetails.get('journey').get('settings').get('showAnalytics') == 'true');

        return analyticsEnabledForBatch;
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const { isSideBarOpen, getLabel, phases, batchId } = this.props;
        // const shouldRenderActivity = hasAssessmentPhaseStarted(phases);
        // const shouldRenderPerformance = hasDevelopmentPhaseStarted(phases);
        const batchDetails = getActiveBatchDetailsWithId(+batchId);

        const shouldRenderActivity = checkIfPresent(this.props.userState.get('showActivityPage'))
            ? this.props.userState.get('showActivityPage')
            : 0;

        const shouldRenderPerformance = checkIfPresent(this.props.userState.get('showPerformancePage'))
            ? this.props.userState.get('showPerformancePage')
            : 0;
        return (
            <div>
                {
                    (isSideBarOpen)
                        ?
                        <div styleName="menu-footer-container" className={css(myStyles.container)}
                            style={this.props.forDevPhase ? {
                                width: '340px',
                                height: '48px'
                            } : null}

                        >
                            {/* <div styleName="icon-and-text-container"
                                onClick={this.onClickOfBackToHome}>
                                <div styleName="icon-open">
                                    <Home />
                                </div>
                                <div className={css(myStyles.text)} styleName="text">
                                    {this.props.getLabel('home_label')}
                                </div>
                            </div> */}
                            {/* <div styleName="line" /> */}
                            {
                                (this.shouldRenderAnalyticsSection(batchDetails))
                                    ? this.renderFooterItems(myStyles, shouldRenderActivity, shouldRenderPerformance)
                                    : null
                            }
                            {/* {this.renderFooterItems(myStyles, shouldRenderActivity, shouldRenderPerformance)} */}
                            {/* {this.renderActivity(myStyles, shouldRenderActivity)}
                            {this.renderPerformance(myStyles, shouldRenderPerformance)}
                            {this.renderEmptyDiv(myStyles, shouldRenderActivity, shouldRenderPerformance)} */}

                        </div>
                        :
                        <div styleName="menu-footer-container-closed" onClick={this.onClickOfBackToHome}>
                            <div styleName="icon-closed">
                                <Home />
                            </div>
                            {/* <div styleName="icon-border" />
                            <div styleName="icon-closed" onClick={this.moveToActivty}>
                                <Analytics />
                            </div>
                            <div styleName="icon-border" />

                            <div styleName="icon-closed" onClick={this.moveToPerformance}>
                                <Performance />
                            </div> */}
                        </div>

                }
            </div>
        );
    }
}

export default MenuFooter;
