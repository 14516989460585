/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import congratulationWithoutCertificateStyles from './congratulationWithoutCertificateStyles.module.sass';
import getSkin from './skin';
import { getUserName } from 'utils/user';

const CongratulationsWithoutCertificate = ({getLabel, skinGuide}) => {
  const styles = getSkin(skinGuide);
  return (
    <div styleName='container'>
      <div>
        <img
          styleName='celebration-image'
          src='https://webassets.knolskape.com/misc/amul_garg/2023/11/20/6/congratulations.svg'
          alt='Celebrations Image'
          className={css(styles.celebrationImage)}       
        />
      </div>
      <div className={css(styles.congratsNoCertificate)}>
        {getLabel('congratulations_label')}
        , <span className={css(styles.congratsNoCertificateName)}>{getUserName()}
        </span>
      </div>
      <div className={css(styles.greetingsNoCertificate)}>
        {getLabel('journey_completed_greetings')}
      </div>
    </div>
  )
}

export default applySkin(applyLabel(CSSModules(CongratulationsWithoutCertificate, congratulationWithoutCertificateStyles)));

