import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import styles from './mandatoryFeedback.module.sass';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

import PrimaryButton from 'commonComponents/buttons/primaryButton';
import SimpleDialog from 'commonComponents/simpleDialog';
import MandatoryFeedbackIcon from 'svgComponents/mandatoryFeedbackIcon';
import BatchFeedbackStatusListContainer from 'containers/BatchFeedbackStatusListContainer';
import { isMobileOnly, isTablet } from 'react-device-detect';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class MandatoryFeedback extends Component {

    constructor(props){
        super(props);
        this.state = { isStatusDialogOpen: false }
    }

    onGiveFeedbackButtonClick = () => {
        this.setState({ isStatusDialogOpen: true });
    }

    onCloseStatusDialog = () => {
        this.props.updateFeedbackStatus();
        this.setState({ isStatusDialogOpen: false });
    }

    getContentContainerStyle = (skinGuide) => {

        if(isMobileOnly){
            return {"width":"290px","minWidth":"250px","padding":"20px","height":"550px","maxHeight":"550px","background":skinGuide.get('skin').get('greyColor6')} 
        }

        if(isTablet){
            return {"width":"90%","minWidth":"90%","padding":"20px","height":"650px","maxHeight":"650px","background":skinGuide.get('skin').get('greyColor6')} 
        }

        return {
            width: '1049px',
            minWidth: '1049px',
            padding: '20px',
            height: '600px',
            maxHeight: '600px',
            background: skinGuide.get('skin').get('greyColor6')
        }
    }

    render() {
        const { getLabel, skinGuide, batchDetails, match } = this.props;
        const styles = getSkin(skinGuide);

        return (
            <div styleName="container">
                <div styleName="give-feedback-button">
                    <PrimaryButton
                        text={
                            <span><MandatoryFeedbackIcon/>{getLabel('mandatory_give_feedback_button_label')}</span>
                        }
                        clickFunction={this.onGiveFeedbackButtonClick}
                    />
                </div>
                <div styleName="give-feedback-message" className={css(styles.giveFeedbackMessage)}>
                    {getLabel('mandatory_feedback_button_sidetext_label')}
                </div>
                { this.state.isStatusDialogOpen &&  <SimpleDialog onCloseDialog={this.onCloseStatusDialog}
                    contentContainerStyle={this.getContentContainerStyle(skinGuide)}                
                >
                    <BatchFeedbackStatusListContainer batchDetails={batchDetails} match={match}/>
                </SimpleDialog> }
            </div>
        )
    }
}

export default MandatoryFeedback;