
import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import FeedbackPattern from 'images/feedback_pattern.png';
import FeedbackBackground from 'images/feedback_pattern.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        
        feedbackBoxContent: {

        },
        mainFeedbackBackground: {
            backgroundImage: `linear-gradient(107deg, #5782dc, ${skin.get('primaryColor')})`
        },
        mainFeedbackImage: {
            backgroundImage: `url(${FeedbackPattern})`,
            backgroundSize: 'contain'
        },
        showDetailsButton: {
            border: `1px solid ${skin.get('secondaryColor')}`,
            cursor: 'pointer',
            color: skin.get('secondaryColor'),
            ...myTypography.button,
            ':hover': {
                background: skin.get('secondaryColor'),
                color: skin.get('white')
            }
        },
        feedbackMainHeading: {
            ...myTypography.h6,
            color: skin.get('white')
        },
        assessmentResultText: {
            ...myTypography.h6,
            fontSize: '18px',
            fontWeight: 400,
            color: skin.get('white')
        },
        completedTitleContainer: {
            background: `linear-gradient(267deg, #3d486f , ${hexToRgbA(skin.get('greyColor2'), 1)})`
        },
        completedTitleImage: {
            backgroundImage: `url(${FeedbackBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: "top"
        },
        completedContent: {
            color: skin.get('white'),
            ...myTypography.h6
        },
        thankYouNote: {
            ...myTypography.button,
            fontWeight: 'normal',
            lineHeight: 'normal',
            color: '#787878',
        }
    });
}

export default getSkin;