import { AxisBottom, AxisLeft } from '@vx/axis';
import { Group } from '@vx/group';
import { scaleLinear } from '@vx/scale';
import { HeatmapRect } from '@vx/heatmap';
import { LinearGradient } from '@vx/gradient';
import { Bar } from '@vx/shape';
import { Spring } from 'react-spring'
import { localPoint } from "@vx/event";
import { Tooltip, withTooltip } from "@vx/tooltip";
import { TimingAnimation, Easing } from 'react-spring/dist/addons'
import { Text } from '@vx/text';
import InfoComponent from 'commonComponents/analytics/infoComponent';
import React from 'react';
import { PropTypes } from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './heatmap.module.sass';

@CSSModules(styles, { allowMultiple: true })
class HeatMap extends React.Component {

    height =  'height' in this.props ? this.props.height : 400;
    margin = { top: 20, left: 100, right: 0, bottom: 100 };

    getDay = d => d.get('day');
    getTimeSlotData = d => d.get('times');

    getTimeSlot = d => d.get('time');
    getTimeSpent = d => d.get('timeSpent');
    getTooltip = d => d.get('timeSpentTooltip');

    constructor(props) {
        super(props);
        this.state = {
            toggle: true
        };

        this.handleTooltip = this.handleTooltip.bind(this);
    }

    getData = () => {
        const graphData = this.props.graphData;
        const data = graphData.get('data');
        const metadata = graphData.get('metadata');
        const strings = graphData.get('strings');
        return {
            data: data,
            metadata: metadata,
            strings: strings
        }
    }

    handleTooltip = (event, data, scales) => {
        const { showTooltip } = this.props;
        const { x, y } = localPoint(event.target.ownerSVGElement, event);

        const day = data.datum.bin;
        const timeSlot = data.bin.bin;
        const timeSpent = data.bin.count;
        const tooltip = data.bin.tooltip;

        const tooltipData = {
            day: day,
            timeSlot: timeSlot,
            timeSpent: timeSpent,
            timeSpentTooltip: tooltip,
            color: scales.color(timeSpent)
        };

        showTooltip({
            tooltipData: tooltipData,
            tooltipLeft: x,
            tooltipTop: y,
        });
    }

    toggle = () => this.setState(state => ({ toggle: !state.toggle }))

    getWidth = () => {
        const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
        const width = calcWidth < 250 ? 250 : calcWidth;
        return width;
    }

    getXMax = () => (
        this.getWidth() > this.margin.left + this.margin.right ? this.getWidth() - this.margin.left - this.margin.right : this.getWidth()
    );

    getYMax = () => ( this.height - this.margin.bottom - this.margin.top );

    getXScale = () => {
        const { data } = this.getData();
        return scaleLinear({
            range: [0, this.getXMax()],
            domain: [0, data.size]
        })
    };
    getYScale = () => (
        scaleLinear({
            range: [0, this.getYMax()],
            domain: [0, this.getBucketCount()]
        })
    );

    getBucketCount = () => ( this.getTimeSlotData(this.getData().data.get('0')).size );
    getBucketBounds = () => ({
        width: this.getXMax() / this.getData().data.size,
        height: this.getYMax() / this.getBucketCount()
    });

    renderGraphComponent = () => {
        const { data, strings } = this.getData();
        if (data.size == 0) {
            return <InfoComponent 
                        {...this.props} 
                        infoMessage="This graph does not have data"
                    />
        }

        const legendStyling = {
            fontFamily: 'Open Sans',
            fontSize: '12',
            fill: '#979eb7'
        };

        const width = this.getWidth();
        const height = this.height;

        const initialCount = (i, n) => {
            return 0;
        };

        const defaultBin = (i, n) => {
        return i * 150;
        };

        // const fromInterpolate = genBins(7, 5, defaultBin, initialCount);
        // const toInterpolate = genBins(7, 5, defaultBin, finalCount);

        // const fromInterpolate = Array(35).fill(min(data, d => min(this.getTimeSlotData(d), this.getTimeSlotData)));
        // const toInterpolate = Array(35).fill(max(data, d => max(this.getTimeSlotData(d), this.getTimeSlotData)));

        const fromInterpolate = Array(35).fill(0);
        let toInterpolate = [];

        data.map((d, i) => (
            this.getTimeSlotData(d).map((timeSlot) => (
                toInterpolate.push(this.getTimeSpent(timeSlot))
            ))
        ));

        const xMax = this.getXMax();
        const yMax = this.getYMax();
        const margin = this.margin;

        const bWidth = this.getBucketBounds().width;
        const bHeight = this.getBucketBounds().height;

        const xLabels = data.map((val, i) => this.getDay(val)).toJS();
        const yLabels = this.getTimeSlotData(data.get('0')).map((val, i) => this.getTimeSlot(val)).toJS();

        // scales
        const xScale = this.getXScale();
        const yScale = this.getYScale();

        return (
            <div style={{ width: width, height: height, position: 'relative', display: 'flex' }}>
                <svg width={width} height={height}>
                    <LinearGradient
                        id="legend-color-scale"
                        from='#ffd142'
                        to='#ff4c4c'
                        vertical={false}
                    />
                    <Group top={margin.top} left={margin.left}>
                        <AxisBottom
                            hideAxisLine={true}
                            hideTicks={true}
                            hideZero={true}
                            scale={xScale}
                            top={yMax}
                            left={-bWidth*0.5}
                            axisClassName="axis-bottom"
                            numTicks={7}
                            tickFormat={(value, index) => ( `${xLabels[index - 1].slice(0,3)}` )}
                            tickLabelProps={() => ({
                                fill: "#979eb7",
                                textAnchor: "middle",
                                fontSize: 14,
                            })}
                        />
                        <AxisLeft
                            hideAxisLine={true}
                            hideTicks={true}
                            hideZero={true}
                            scale={yScale}
                            top={-bHeight*0.5}
                            left={0}
                            axisClassName="axis-left"
                            numTicks={5}
                            tickFormat={(value, index) => ( `${yLabels[index - 1].toLowerCase()}` )}
                            tickLabelProps={() => ({
                                fill: "#979eb7",
                                textAnchor: "end",
                                fontSize: 14
                            })}
                        />
                        {/* <Spring 
                            from={{ bins: fromInterpolate }}
                            to={{bins: toInterpolate}}
                            // impl={TimingAnimation}
                            config={{ easing: Easing.linear }}
                            data={data}
                            children={Graph} /> */}
                        {/* <Graph data={data} bins={toInterpolate}/> */}
                        {this.renderGraph({data:data, bins:toInterpolate})}
                        <Group className="legend-container">
                            <Bar
                                x={bWidth*1.5}
                                y={yMax + bHeight}
                                width={bWidth*4}
                                height={10}
                                fill={`url(#legend-color-scale)`}
                            />
                            <Text
                                x={bWidth*1.5}
                                y={yMax + bHeight + 15}
                                verticalAnchor='start'
                                style={legendStyling}
                                textAnchor='start'
                            >
                            {strings.get('lowLabel')}
                            </Text>
                            <Text
                                x={bWidth*1.5 + bWidth*4}
                                y={yMax + bHeight + 15}
                                verticalAnchor='start'
                                style={legendStyling}
                                textAnchor='end'
                            >
                            {strings.get('highLabel')}
                            </Text>
                        </Group>
                    </Group>
                </svg>
                {this.renderTooltip()}
            </div>
    )
    }

    renderGraph = ({bins, data}) => {
        const { hideTooltip } = this.props;

        const { metadata } = this.getData();

        const colorMax = metadata.get('max');
        const colorMin = metadata.get('min');

        const xScale = this.getXScale();
        const yScale = this.getYScale();
        const colorScale = scaleLinear({
            range: ['#ffd042', '#fe4d4c'],
            domain: [colorMin, colorMax]
        });

        const bWidth = this.getBucketBounds().width;
        const bHeight = this.getBucketBounds().height;

        let index = 0;
        const formattedData = data.map((val, i) => (
            {
                bin: this.getDay(val),
                bins: this.getTimeSlotData(val).map((cu) => ({
                    bin: this.getTimeSlot(cu),
                    count: bins[index++],
                    tooltip: this.getTooltip(cu)
                })).toJS()
            }
        )).toJS();

        return (
            <Group className="graph">
                <HeatmapRect
                    data={formattedData}
                    xScale={xScale}
                    yScale={yScale}
                    colorScale={colorScale}
                    binWidth={bWidth}
                    binHeight={bHeight}
                    gap={1}
                    onMouseEnter={data => event =>
                    {
                        this.handleTooltip(
                            event,
                            data,
                            { x: xScale, y: yScale, color: colorScale }
                        );
                    }}
                    onMouseLeave={data => event => hideTooltip()}
                    onMouseOut={data => event => hideTooltip()}
                />
            </Group>
        )
    }

    renderTooltip = () => {
        const { tooltipOpen, tooltipData, tooltipTop, tooltipLeft } = this.props;
        
        if (!tooltipOpen)
            return

        const { strings } = this.getData();

        const tooltipStyling = {
            fontFamily: 'Open Sans',
            fontSize: '12px',
            color: 'rgba(9, 16, 39, 0.85)',
            width: '180px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.5)'
        };
        return (
            <Tooltip
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft}
                style={{
                    ...tooltipStyling,
                    backgroundColor: tooltipData.color,
                }}
            >
                <div styleName="tooltip-header">{strings.get('timeSpentLabel')}</div>
                <div styleName="tooltip-content">{tooltipData.timeSpentTooltip}</div>
            </Tooltip>
        )
    }

    render() {
        return (this.renderGraphComponent());
    }
}

HeatMap.propTypes = {
    size: PropTypes.object,
    graphData: PropTypes.object.isRequired
};

HeatMap.defaultProps = {
    size: {
        width: 500,
        height: 500
    }
};

export default withTooltip(HeatMap);