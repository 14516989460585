import React, { Component } from 'react';
import styles from './classroomsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ClassroomsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<g fill="#828593" fillRule="nonzero">
						<path d="M13.44.98a.84.84 0 0 0-.84-.84H.84a.84.84 0 0 0-.12 1.671c.07.01.12.07.12.139v8.144a.14.14 0 0 1-.112.137.56.56 0 0 0 .112 1.109h5.32a.14.14 0 0 1 .14.14v.176a.14.14 0 0 1-.06.112.84.84 0 1 0 .96 0 .14.14 0 0 1-.06-.112v-.176a.14.14 0 0 1 .14-.14h5.32a.56.56 0 0 0 .112-1.109.14.14 0 0 1-.112-.137V1.95a.14.14 0 0 1 .12-.139.84.84 0 0 0 .72-.831zm-1.96 9.1a.14.14 0 0 1-.14.14H2.1a.14.14 0 0 1-.14-.14V1.96a.14.14 0 0 1 .14-.14h9.24a.14.14 0 0 1 .14.14v8.12z"/>
						<path d="M8.687 5.46a.59.59 0 0 0-.473-.343.556.556 0 0 0-.492.288l-.002.005L6.8 6.98a.14.14 0 0 1-.22.027l-.948-.96a.51.51 0 0 0-.78.155l-.003.006-1.367 2.335a.14.14 0 0 0 .121.21h6.528a.14.14 0 0 0 .124-.203l-1.57-3.09z"/>
						<circle cx="4.486" cy="4.34" r="1"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default ClassroomsIcon;
