import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setDayWiseTimeSpentData } from 'actions/analytics/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchDayWiseTimeSpentData extends NetPackWrapper {

    constructor() {
        super("GET");        
	}

    prepareUrl = (apiInfoObject) => {        
        return urls.get('ANALYTICS').get('GET_DAY_WISE_TIME_SPENT_DATA')
        .replace('BATCH_ID', apiInfoObject.batchId)
        .replace('USER_ID', '' + apiInfoObject.userId)
    }

    successCall = (response) => {
        this.dispatch(setDayWiseTimeSpentData(response));
        this.dispatch(setApiError('fetchDayWiseTimeSpentDataError', null));
        return response;
    }
    
    onError = (errorObj) => {
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchDayWiseTimeSpentDataError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchDayWiseTimeSpentData();
