import Immutable from 'immutable';

const loaderQuotes = Immutable.fromJS({
	quotes: [
		{
			text: "Success is no accident. It is hard work, perseverance, learning, studying, sacrifice and most of all, love of what you are doing or learning to do.",
			author: "Pele"
		},
		{
			text: "Whatever you do, do with all your might",
			author: "Marcus Tullius Cicero"
		},
		{
			text: "Go forth on your path, as it exists only through your walking.",
			author: ""
		}
	]
});

export default loaderQuotes;