import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({

        cardTitleStyle: {
            borderBottom: 'solid 1px #2e344b',
            fontSize: '16px',
            fontWeight: 'normal',
            color: '#575e79'
        },

        fontStyleScore: {
            fontSize: '60px',
            color: '#fff',
            fontWeight: 600
        },

        fontStyleUpdate: {
            fontSize: '20px',
            color: 'rgba(255, 255, 255, 0.5)',
            fontWeight: 400
        },
        
        fontStyleSubscore: {
            fontSize: '16px',
            color: 'rgba(255, 255, 255, 0.5)',
            fontWeight: 400
        },

        rankFontStyle: {
            color: '#fff', 
            paddingLeft: '10.1px',
            paddingRight: '10.1px'
        },

        positiveRankIncreaseStyle: {
            borderBottom: '8.6px solid #7ed321'
        },

        negativeRankIncreaseStyle: {
            borderTop: '8.6px solid #d0021b'
        }

    });
}

export default getSkin;