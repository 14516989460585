import React, { Component } from 'react';
import styles from './videosIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class VideosIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			// <div >
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
				<g fill="none" fillRule="evenodd" stroke="#828593" strokeLinecap="round" strokeLinejoin="round">
					<path d="M14.667 2A.667.667 0 0 0 14 1.333H2A.667.667 0 0 0 1.333 2v12c0 .368.299.667.667.667h12a.667.667 0 0 0 .667-.667V2z" />
					<path d="M6.533 10.515A.36.36 0 0 1 6 10.2V5.69a.36.36 0 0 1 .538-.311l3.948 2.256a.36.36 0 0 1 0 .624l-3.953 2.256z" />
				</g>
			</svg>
			// </div>
		);
	}
}

export default VideosIcon;
