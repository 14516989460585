import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setResetFailed } from 'actions/batches/actionCreators';

class ResetBatchProgressForUser extends NetPackWrapper {

	constructor() {
		super("PUT");
	}

	prepareUrl = (apiInfoObject = {}) => (
		urls.getIn(['PLATFORM', 'RESET_BATCH_PROGRESS_FOR_USER'])
			.replace('BATCH_ID', apiInfoObject.batchId)
			.replace('USER_ID', apiInfoObject.userId)
	)

	beforeCall = (apiInfoObject = {}) => {
		this.dispatch(setResetFailed(false))
		return apiInfoObject;
	}

	shouldAjaxCall = (apiInfoObject) => {
		return true;
	}

	getFailuraToastObject = () => ({
		id: toastConstants.get('ID_ERROR'),
		type: toastConstants.get('TYPE_ERROR'),
		heading: 'Something went wrong',
		message: 'Your request to reset journey progress has failed. Please try again!',
		isDisplayed: false,
		autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
	})

	onError = (response) => {
		this.dispatch(addToast(this.getFailuraToastObject()));
		this.dispatch(setResetFailed(true))
	}
}

export default new ResetBatchProgressForUser();
