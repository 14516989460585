/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Header.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Header component of the application
 *
 **/

import React from 'react';

import Header from 'commonComponents/header';
import getHeaderConfig from 'config/header/getHeaderConfig';

import styles from './desktopHeader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { checkIfPresent } from 'utils/utilFunctions';
import { getActiveBatchDetails } from 'utils/batchUtils';
import getSkin from './skin.js';
import environment from 'utils/environment';
import defaults from 'constants/defaults';
import { css } from 'aphrodite';
import SupportIcon from 'svgComponents/supportIcon';
import { isLaunchedFromExternalIntegration } from 'utils/externalIntegrations';
import { isMobile } from 'react-device-detect';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DesktopHeader extends React.Component {

    componentWillReceiveProps(nextProps) {
        const batchDetails = getActiveBatchDetails(nextProps.batches);
        if (
            environment.REACT_APP_ENABLE_FRESHDESK === 'true'
            && !isLaunchedFromExternalIntegration()
            && nextProps.user.get('isUserDataAvailable')
        ) {
            var newFreshdeskID = nextProps.userOrganization.getIn(['userOrganization', 'freshDeskId']);

            if (
                window.location.hash.indexOf("batch") > -1
                && nextProps.batches.get('isActiveBatchFetched')
                && checkIfPresent(batchDetails.get('freshDeskId'))
            )
                newFreshdeskID = batchDetails.get('freshDeskId');

            var freshdeskInitialized = false;
            const currentWindowUrl = window.location.origin + window.location.pathname;
            if (
                (nextProps.userState.get('freshdeskInitializedURL') !== currentWindowUrl)
                || (newFreshdeskID !== nextProps.userState.get('freshdeskInitializedID'))
            ) {
                freshdeskInitialized = this.initializeFreshDesk(nextProps, newFreshdeskID);
                if (window.FreshWidget) {
                    window.FreshWidget.update('');
                    nextProps.setUserState({
                        freshdeskInitialized: freshdeskInitialized,
                        freshdeskInitializedID: newFreshdeskID,
                        freshdeskInitializedURL: currentWindowUrl
                    });
                }
            }

        }
    }

    initializeFreshDesk = (nextProps, freshDeskId) => {
        try {
            var emailID = checkIfPresent(nextProps.user.get('emailID'))
                ? nextProps.user.get('emailID')
                : defaults.get('DEFAULT_FRESHDESK_USER_EMAIL');

            const buttonColor = checkIfPresent(this.props.skinGuide.get('skinHex').get('greyColor3'))
                ? this.props.skinGuide.get('skinHex').get('greyColor3')
                : defaults.get('DEFAULT_FRESHDESK_BUTTON_COLOR');

            const textColor = checkIfPresent(this.props.skinGuide.get('skinHex').get('white'))
                ? this.props.skinGuide.get('skinHex').get('white')
                : defaults.get('DEFAULT_FRESHDESK_BUTTON_TEXT_COLOR');

            const referrer = window.location.origin + window.location.pathname;

            var stringQ = '';
            if (checkIfPresent(freshDeskId)) {
                stringQ = ''
                    + ('&widgetType=popup'
                        + '&submitThanks=Thank+you.+Our+Agent+will+contact+you+in+less+than+48+hours'
                        + '&helpdesk_ticket[requester]=')
                    + emailID     //+ validEmail
                    + '&disable[requester]=true'
                    + '&helpdesk_ticket[subject]='
                    + ('&helpdesk_ticket[product]='
                        + freshDeskId
                        + '&helpdesk_ticket[custom_field][simulation_104707]='
                        + defaults.get('DEFAULT_FRESHDESK_PRODUCT_NAME')
                        + '&disable[custom_field][simulation_104707]')
                    + '&disable[product_id]=true'
                    + '&helpdesk_ticket[custom_field][cf_location_url_104707]='
                    + referrer
                    + '&disable[custom_field][cf_location_url_104707]'
                    + '&searchArea=no';
            } else {
                stringQ = ''
                    + ('&widgetType=popup'
                        + '&submitThanks=Thank+you.+Our+Agent+will+contact+you+in+less+than+48+hours'
                        + '&helpdesk_ticket[requester]=')
                    + emailID     //+ validEmail
                    + '&disable[requester]=true'
                    + '&helpdesk_ticket[subject]='
                    + '&helpdesk_ticket[custom_field][simulation_104707]='
                    + defaults.get('DEFAULT_FRESHDESK_PRODUCT_NAME')
                    + '&disable[custom_field][simulation_104707]'
                    + '&disable[product_id]=true'
                    + '&helpdesk_ticket[custom_field][cf_location_url_104707]='
                    + referrer
                    + '&disable[custom_field][cf_location_url_104707]'
                    + '&searchArea=no';
            }

            var freshdeskObj = {
                loadOnEvent: 'immediate',
                queryString: stringQ,
                utf8: 'âœ“',
                widgetType: "popup",
                buttonType: 'text',
                buttonText: nextProps.getLabel('freshdesk_support_label'),
                buttonColor: textColor,
                buttonBg: buttonColor,
                alignment: "2",
                offset: "-1500px",
                formHeight: "600px",
                url: defaults.get('DEFAULT_FRESHDESK_EMAIL')
            };
            window.FreshWidget.init('', freshdeskObj);
            document.getElementById('freshwidget-button').querySelector('a').style.display = "none"
            return true;
        } catch (error) {
            console.log('Error: ', error);
        };
        return false;
    }

    showSupportWidget = () => {
        window.FreshWidget.show()

    }

    render() {
        const headerConfig = getHeaderConfig(this.props);
        const { skinGuide } = this.props;

        let myStyles = getSkin(skinGuide);

        const showSupportBtn = environment.REACT_APP_ENABLE_FRESHDESK === 'true'
            && !isLaunchedFromExternalIntegration()
            && this.props.userState.get('freshdeskInitialized')
            && this.props.user.get('isUserDataAvailable')

        return (
            [
                <div key="application header" styleName={`${this.props.isHidden ? 'hidden' : ''}`}>
                    <Header
                        headerConfig={headerConfig}
                        setUiState={this.props.setUiState}
                        uiState={this.props.uiState}
                        batches={this.props.batches}
                        match={this.props.match}
                        userOrganization={this.props.userOrganization}
                    />
                </div>,

                <div
                    key="application-freshdesk-widget"
                    id="adp-freshdesk-support-btn"
                    className={css(myStyles.supportBtn)}
                    styleName="support-btn-cnt right"
                    onClick={this.showSupportWidget}
                    style={showSupportBtn ? { display: 'flex' } : {}}
                >
                    <div styleName="support-icon-cnt">
                        <SupportIcon fill={skinGuide.getIn(['skinHex', 'secondaryColor'])}/>
                    </div>
                    {isMobile ? null : this.props.getLabelWithDefaults('freshdesk_support_label', 'support')}
                </div>

            ]
        );
    }
}

DesktopHeader.propTypes = {};
DesktopHeader.defaultProps = {};

export default DesktopHeader;