import React, { Component } from 'react';
import styles from './errorTemplate.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css, StyleSheet } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import Footer from 'commonComponents/footer';
import ErrorNotFound from 'svgComponents/errorNotFound';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import environment from 'utils/environment';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ContentNotSupportedErrorTemplate extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div styleName="complete-container" style={{
				height: 'calc(100vh - 500px)'
			}}>
				<div styleName="error-container">
					<div styleName="error-content">
						<div styleName="error-image-container">
							<div styleName="error-image">
								<ErrorNotFound />
							</div>
						</div>
						<div styleName="error-message-container">
							<div className={css(myStyles.errorHeading)} styleName="error-heading">Oops!</div>
							<div className={css(myStyles.errorDesc)} styleName="error-sub-desc">{environment.REACT_APP_PRODUCT_NAME || 'Aktivlearn Plus'} doesn't support this content type, yet.</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ContentNotSupportedErrorTemplate;
