import Immutable from 'immutable';
import konsoleUrls from './konsoleUrls';
import journeyUrls from './journeyUrls';
import toolUrls from './toolUrls';
import feedbackUrls from './feedbackUrls';
import attachmentUrls from './attachmentUrls';
import pageAndQuizUrls from './pageAndQuizUrls';


const platformUrls = () => {
    return Immutable.Map({
        ...konsoleUrls().toJS(),
        ...journeyUrls().toJS(),
        ...toolUrls().toJS(),
        ...feedbackUrls().toJS(),
        ...attachmentUrls().toJS(),
        ...pageAndQuizUrls().toJS()
    });

};

export default platformUrls;