import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import CircularLoader from 'svgComponents/circularLoader'
import styles from './courseFeedback.module.sass';

@CSSModules(styles, { allowMultiple: true })
class NextItem extends Component {

    constructor(props){
        super(props);
        this.state = {
            nextItem: null,
            loading: true
        }
    }

    componentDidMount(){
        this.getNextItemDetails();
    }

    getNextItemDetails = async () => {
        const { fetchNextItemRouteParams, batchId, phaseId, currentPhaseItemId } = this.props;
        const nextItem = await fetchNextItemRouteParams(batchId, phaseId, currentPhaseItemId);
        this.setState({
            nextItem,
            loading: false
        })
    }

    getALCourseRouteParams = (batchId, nextItem) => {
        const route = routes.get('BATCH_ITEM_MODULE');
        const params = [
            { 'BATCH_ID': batchId },
            { '_PHASE_ID_': nextItem.phaseId },
            { 'COURSE_ID': nextItem.courseId },
            { '_PHASEITEMID_': nextItem.phaseItemId },
            { 'MODULE_ID': nextItem.moduleId },
            { 'ITEM_ID': nextItem.moduleItemId }
        ];
        return { route, params };
    }

    getPhaseItemRouteParams = (batchId, nextItem) => {
        const route = routes.get('BATCH_ITEM')
        const params = [
            { 'BATCH_ID': batchId },
            { 'ITEM_ID': nextItem.phaseItemId }
        ];
        return { route, params };
    }

    getNextItemRouteParams = (batchId, nextItem) => {
        if(nextItem.type === 'COURSE')
            return this.getALCourseRouteParams(batchId, nextItem);
        return this.getPhaseItemRouteParams(batchId, nextItem);
    }

    goToNextItem = () => {    
        const { batchId } = this.props;
        const { nextItem } = this.state;   
        let routeObj = this.getNextItemRouteParams(batchId, nextItem);
        updateRoute(routeObj);
    }

    render() {
        const { nextItem, loading } = this.state;

        if(loading) return <div styleName="next-item-btn-cnt"><CircularLoader/></div>;

        if(!nextItem || nextItem.locked) return null;

        return (
            <div styleName="next-item-btn-cnt">
                <div styleName="continue-learning-btn">
                    <PrimaryButton
                        text={'CONTINUE LEARNING'}
                        clickFunction={this.goToNextItem}
                    />
                </div>
            </div>
        )
    }
}

export default NextItem;