/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: user.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * user reducer is responsible for user related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    isInvalid: false
});

const errors = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_INVALID_FLAG'):
            return state.set('isInvalid', action.flag);
        default:
            return state;
    }
};

export default errors;