import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './wikiPage.module.sass';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import { Waypoint } from 'react-waypoint';
import CompletedIcon from 'svgComponents/completedIcon';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class WikiPage extends Component {

	markPageRead = () => {
		console.log('debug: bottom reached');
		this.props.markRead();
	}

	onWayPointLeave = () => {
		console.log('debug: not at bottom anymore');
	}

	renderMarkCompleteSection = () => {
		const { moduleItem, skinGuide, getLabelWithDefaults } = this.props;
		const moduleItemDetails = moduleItem.get('moduleItem');
		const styles = getSkin(skinGuide);
		const skinHex = skinGuide.get('skinHex');

		if(moduleItemDetails.getIn(['completion_requirement', 'completed'])){
			return <div className={css(styles.completedText)} styleName="completed-cnt">
				<CompletedIcon strokeColor={skinHex.get('appleGreen')}/>
				{getLabelWithDefaults('page_complete_label', 'Complete')}
			</div>;
		} 

		return <div className={css(styles.markCompleteButton)} onClick={this.markPageRead} styleName="mark-complete-btn">
			{getLabelWithDefaults('page_mark_complete_label', 'Mark Complete')}
		</div>;
	}

	componentDidMount(){
		/*polyfill for IE11*/
		if (window.NodeList && !NodeList.prototype.forEach) {
			NodeList.prototype.forEach = Array.prototype.forEach;
		}
		
		let linkList = document.querySelectorAll('.pageContainer a');
		linkList.forEach(link => link.setAttribute('target', "_blank"))
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		let { moduleItem } = this.props;

		return (
			<div className="pageContainer">
				<div styleName="page-content-cnt" className={css(styles.body)}>
					<div dangerouslySetInnerHTML={{ __html: moduleItem.getIn(['contentDetails', 'body']) }}/>
				</div>
				<Waypoint
					onEnter={this.markPageRead}
					onLeave={this.onWayPointLeave}
					bottomOffset="-30%"
				/>
				<div styleName="mark-complete-cnt">
					{this.renderMarkCompleteSection()}
				</div>
			</div>
		)
	}
}

export default WikiPage;