import React, { Component } from 'react';
import styles from './infoIcon.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class InfoIcon extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 16 16">
					<g fill="none" fill-rule="evenodd" stroke="#979EB7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" transform="translate(1 1)">
						<path d="M8.313 9.625h-.438A.875.875 0 0 1 7 8.75V6.562a.437.437 0 0 0-.438-.437h-.437M6.781 3.938a.219.219 0 1 0 0 .437.219.219 0 0 0 0-.438"/>
						<circle cx="7" cy="7" r="6.563"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default InfoIcon;
