import React from 'react';
import { connect } from 'react-redux';
import setUserState from 'actions/userState/actionCreators';
import SystemCheck from 'components/systemCheck';
import IE11Warning from 'components/systemCheck/ie11Warning';
import SystemCheckErrorBoundary from 'components/errorBoundaries/SystemCheckErrorBoundary';
import { saveUserSystemCheckResult, fetchUserWhitelistingRequirements } from 'actions/users/apiActions';
import CircularLoader from 'svgComponents/circularLoader';

class SystemCheckContainer extends React.Component {

    componentDidMount(){
        if(this.props.context === "home")
            fetchUserWhitelistingRequirements();
    }

    getMergedPayloadWithPreviousResult = (payload) => {
        try{
            if(window.localStorage.getItem('latestSystemCheckResult')){
                const lastResult = JSON.parse(window.localStorage.getItem('latestSystemCheckResult'));
                const updatedLinkGroupsChecked = Array.from(new Set(lastResult.linkGroupsChecked.concat(payload.systemCheckResult.linkGroupsChecked)));
                return { 
                    systemCheckResult: { 
                        ...payload.systemCheckResult, 
                        linkGroupsChecked: updatedLinkGroupsChecked
                    }
                };
            }
        } catch(error){
            console.log('error', error);
        }
        return payload;
    }
    
    markSystemCheckDone = (payload) => {
        const mergedPayload = this.getMergedPayloadWithPreviousResult(payload);
        saveUserSystemCheckResult(mergedPayload);
        this.props.setUserState({systemCheckDone: "true", batchToRunSystemCheckFor: null});
        window.localStorage.setItem('initialSystemCheckDone', true);
        window.localStorage.setItem('latestSystemCheckResult', JSON.stringify(mergedPayload.systemCheckResult));
    }

    renderIE11Warning = () => {
        if(this.props.userState.get('ie11WarningAcknowledged')=="false"){
            return <IE11Warning setUserState={this.props.setUserState}/>;
        }
        return null;
    }

    render(){
        if(this.props.userWhitelistingRequirements.size === 0)
            return <div style={{width: '50px', margin: '30vh auto 0'}}><CircularLoader/></div>;
        // if the check has never happened show a fullscreen loader... in subsequent checks, the experience might be a bit shaky
       
        return <span>
                {this.renderIE11Warning()}
                <SystemCheckErrorBoundary continueAction={this.markSystemCheckDone}>
                <SystemCheck
                    loadedInsideDialog={this.props.loadedInsideDialog || false}
                    markSystemCheckDone={this.markSystemCheckDone}
                    userWhitelistingRequirements={this.props.userWhitelistingRequirements}
                    context={this.props.context}
                />
                </SystemCheckErrorBoundary>
            </span>
    }
}

const mapStateToProps = state => ({
    userState: state.get('userState'),
    userWhitelistingRequirements: state.get('userWhitelistingRequirements')
});

const mapDispatchToProps = dispatch => ({
    setUserState: (payload) => {
        dispatch(setUserState(payload));
    }
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemCheckContainer);
