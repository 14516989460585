import React, { Component } from 'react';
import styles from './buildAPlatform.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DigitalToolHeader from '../digitalToolHeader';
import DigitalToolGuide from '../digitalToolGuide';
import ToolItemList from '../toolItemList';
import InformationIcon from 'svgComponents/informationIcon';
import Checkmark from 'svgComponents/checkmark';
import { getDigitalToolkitFirebaseDatabase } from 'utils/firebase';
import { sortObjectOfObjectsBasedOnOrder } from 'utils/utilFunctions';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class BuildAPlatform extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuide: false
		}
	}

	toggleShowGuide = () => {
		this.setState({
			showGuide: !this.state.showGuide
		});
	}

	submitDataStrategy = () => {
		this.props.markModuleItemDone(this.props.match.params);
	}

	downloadDataStrategy = () => {
		alert("feature not available!");
	}

	addOrRemoveRole = async (selectedRoleId) => {
		const { match, currentUserDetails, moduleItem } = this.props;
		let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])}/data/selectedRoleIds`;

		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 
		
		const paletteRef = digitalToolkitDb.ref(refString);
		paletteRef.set({ [selectedRoleId]: selectedRoleId });
	}

	getToolItemListComp = (dataKey) => {
		const { toolData, toolUserData, colorPalette } = this.props;
		const selectedCP = toolUserData && toolUserData.data && toolUserData.data.selectedColorPalette ? toolUserData.data.selectedColorPalette[dataKey] : ""
		return (
			<ToolItemList
				type={dataKey}
				key={`key_${dataKey}`}
				userData={toolUserData && toolUserData.data && toolUserData.data.cards ? toolUserData.data.cards[dataKey] : {}}
				addNewUserDataRecord={this.props.addNewUserDataRecord}
				deleteRecord={this.props.deleteRecord}
				colorPalette={colorPalette}
				selectedColorPalette={selectedCP}
				setColorPalette={this.props.setColorPalette}
				cardStyles={{
					height: '240px',
					maxHeight: '240px',
					justifyContent: 'space-between',
					borderRadius: '6px 0 6px 6px',
					backgroundImage: `${selectedCP && colorPalette[selectedCP].cardBackground ? '' : 'linear-gradient(132deg, #477bc0, #4a53ae)'}`
				}}
				instructions={toolData.cardExamples[dataKey]}
			/>
		)
	}

	getRolesComponent = () => {
		const { toolData, toolUserData } = this.props;
		let myStyles = getSkin(this.props.skinGuide);
		let rolesComponent = [];
		for (let role in toolData.roles) {
			const isRoleSelected = toolUserData && toolUserData.data && toolUserData.data.selectedRoleIds && toolUserData.data.selectedRoleIds[toolData.roles[role].id] ? true : false;

			rolesComponent.push(
				<div key={`role_${role}`} styleName={`individual-role-cnt ${isRoleSelected ? 'selected' : ''}`} onClick={() => { this.addOrRemoveRole(toolData.roles[role].id) }}>
					<div styleName="info-icon-cnt">
						<InformationIcon fillColor={isRoleSelected ? 'white' : null} />
						<div
							className={css(myStyles.infoText)}
							styleName="capsule-info-card"
							dangerouslySetInnerHTML={{ __html: toolData.roles[role].infoText }}
						>
						</div>
					</div>
					<div className={css(isRoleSelected ? myStyles.selectedroleText : myStyles.roleText)}>{toolData.roles[role].name}</div>
					{
						isRoleSelected ?
							<div styleName="checkmark-icon-cnt">
								<Checkmark fillColor={isRoleSelected ? 'white' : null} />
							</div>
							:
							<div styleName="radio-button-cnt"></div>
					}
				</div>
			)
		}
		return rolesComponent;
	}

	renderBuildAPlatformContent = () => {
		let myStyles = getSkin(this.props.skinGuide);
		const { toolData } = this.props;
		let cardsComponent = [];
		let orderedActors = sortObjectOfObjectsBasedOnOrder(toolData.actors);
		for (let actor in orderedActors) {
			let dataKey = `actor_${orderedActors[actor].key}`;
			cardsComponent.push(
				<div key={dataKey} styleName="individual-card-cnt">
					<div className={css(myStyles.actorText)} styleName="header">
						{orderedActors[actor].name}
					</div>
					<div styleName="connector-line"></div>
					<div styleName="tool-items-list-card-cnt">
						{this.getToolItemListComp(dataKey)}
					</div>
				</div>
			)
		}
		return (
			<div styleName="build-a-platform-content-cnt">
				<div styleName="build-a-platform-content">
					<div className={css(myStyles.stepHeaderText)} styleName="step-header-text">
						{toolData.labels.step1_label || "Step 1: Select any role from below"}
					</div>
					<div styleName="roles-cnt">
						{this.getRolesComponent()}
					</div>
				</div>
				<div styleName="build-a-platform-content">
					<div className={css(myStyles.stepHeaderText)} styleName="step-header-text">
						{toolData.labels.step2_label || "Step 2: Fill the Card below"}
					</div>
					<div styleName="cards-outer-cnt">
						{cardsComponent}
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { toolData } = this.props;
		return (
			<div styleName="build-a-platform-cnt">
				<DigitalToolHeader
					toggleShowGuide={this.toggleShowGuide}
					submitAction={this.submitDataStrategy}
					downloadAction={this.downloadDataStrategy}
					toolData={toolData}
				/>
				{
					this.renderBuildAPlatformContent()
				}
				{
					this.props.toolData && this.state.showGuide &&
					<DigitalToolGuide
						closeGuide={this.toggleShowGuide}
						guideData={this.props.toolData.guide}
						labels={this.props.toolData.labels}
					/>
				}
			</div>
		);
	}
}

export default BuildAPlatform;