import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({

        tabStyle: {
            borderBottom: 'solid 1px rgba(255,255,255,0.5)'
        },

        tabFontStyle: {
            fontSize: '22px',
            fontWeight: 600,
            color: 'rgba(255,255,255,0.5)',
        },

        tabFontStyleSelected: {
            fontSize: '22px',
            fontWeight: 600,
            color: '#ffffff',
            borderBottom: 'solid 2px #ffffff',
            cursor: 'default'
        },

    });
}

export default getSkin;