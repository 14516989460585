import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {
	setUserOrganization,
	setUserOrganizationError
} from 'actions/home/actionCreators';
import { setBatchSkin } from 'actions/skin/actionCreators';
import { setUserOrganizationLoading } from 'actions/home/actionCreators';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import setUserState from 'actions/userState/actionCreators';

class GetUserOrganization extends NetPackWrapper {

	constructor() {
		super("GET", true);
	}

	beforeCall = () => {
		this.dispatch(setUserOrganizationLoading(true))
	}

	prepareUrl = () => urls.get('PLATFORM').get('GET_USER_ORGANIZATION')

	setSegmentDetails = (response) => {
		const user = this.getState().get('user');
		let segmentIdentifyPayload = {};
		let segmentGroupPayload = {};
		let userObjectKeys = ['firstName', 'lastName', 'email', 'gender', 'userId', 'designation', 'dateOfBirth', 'country', 'city', 'industry', 'highestEducation', 'userCategory', 'profilePicUrl'];

		let organizationObjectKeys = ['name', 'activeDealId', 'isPassport', 'platformSettings', 'skinId', 'dealExpiryTime']

		userObjectKeys.forEach(key => {
			segmentIdentifyPayload[key] = user.get(key);
		});

		segmentIdentifyPayload['organizationId'] = response.id;
		segmentIdentifyPayload['organizationName'] = response.name;
		segmentIdentifyPayload['type'] = response.name === "All SPC access" || response.name === "Inside sales" ? "trial" : "paid";

		segmentIdentifyPayload['segment'] = true;
		organizationObjectKeys.forEach(key => {
			segmentGroupPayload[key] = response[key]
		});


		if (+window.localStorage.getItem('ajs_user_id') !== +user.get('userId')) {
			if (segmentIdentifyPayload.type === 'paid') {
				window.analytics.identify(
					user.get('userID'),
					segmentIdentifyPayload,
					{
						integrations: {
							'HubSpot': false,
							...getAmplitudeSessionDetails().integrations
						}
					})
			} else {
				window.analytics.identify(
					user.get('userID'),
					segmentIdentifyPayload,
					getAmplitudeSessionDetails()
				);
			}
		}

		if (+window.localStorage.getItem('ajs_group_id') !== +response.id)
			window.analytics.group(response.id, segmentGroupPayload, getAmplitudeSessionDetails());

		if (
			+window.localStorage.getItem('ajs_user_id') !== +user.get('userId')
			|| +window.localStorage.getItem('ajs_group_id') !== +response.id
		)
			window.analytics.track('User data loaded', {}, getAmplitudeSessionDetails());
	}

	successCall = (response) => {
		this.setSegmentDetails(response);
		if (response.error_code) {
			this.dispatch(setUserOrganizationError(response))
			window.location = '#/';
		} else {
			response.platformSettings.exploreCourses = (response.platformSettings.exploreCourses === 'true');
			response.platformSettings.autoEnrollToSelfPacedJourney = (response.platformSettings.autoEnrollToSelfPacedJourney === 'true');
			this.dispatch(setUserOrganization(response));
			this.dispatch(setBatchSkin({ skin: response.skin }));
		}
		return response;
	}

	onError = (response) => {
		this.dispatch(setUserOrganizationError(response));
		const errorObj = response.errorObject.response;
		const apiObj = response.errorObject.ajaxCallInfo;
		this.dispatch(setUserState({
			userError: {
						message: errorObj.message,
						error_code: errorObj.error_code,
						data: errorObj.data,
						status_code: apiObj.status
				}
		}));
		return response;
	}

}

export default new GetUserOrganization();
