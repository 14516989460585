import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './infoWithoutFilterComponent.module.sass';
import InfoIcon from 'images/analytics/info_icon.svg'

import { css } from 'aphrodite/no-important';
import getSkin from './skin';

@CSSModules(styles, { allowMultiple: true })

class InfoWithoutFilterComponent extends React.Component {

    render(){

        const {title, subtitle} = this.props;

        const myStyles = getSkin(this.props.skinGuide);

        return(
            <div styleName="info-filters-container">
                <div styleName="info-content">
                    <div className={css(myStyles.fontStylingTitle)} styleName="title-content">
                        {title}
                    </div>
                    <div className={css(myStyles.fontStylingSubTitle)} styleName="subtitle-content">
                        <div styleName="logo-container">
                            <img src={InfoIcon} />
                        </div>
                        {subtitle}
                    </div>
                </div>
            </div>
        )
    }
}


export default InfoWithoutFilterComponent;