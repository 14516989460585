/**
* @author: girish
* @since: Mon Oct 24 2016 15:15:46 GMT+0530 (IST)
* @file: ajax.js
*
* @copyright: KNOLSKAPE Solutions Pvt Ltd
**/

/**
*
* ajax is helper to perform fetch request
* Purpose of having it as helper is
* 1. Fetch isn't supported in all browers so we need to use polyfill
* 2. Single place to handle generic errors like network issues
*
**/

import fetch from 'isomorphic-fetch';
//import { polyfill } from 'es6-promise';
import PromiseDefer from 'promise-defer';
import environment from 'utils/environment';

//polyfill();

class NetPack {

	constructor(handlers, defaultOptions = {
		credentials: 'same-origin',
		headers: {
			Accept: 'application/json'
		}
	}) {
		if (environment.REACT_APP_ENV == 'local') {
			defaultOptions.headers = {
				...defaultOptions.headers,
				'Authorization': environment.REACT_APP_AUTH_HEADER
			};
		}

		this.networkErrorHandler = handlers.networkErrorHandler;
		this.backendErrorHandler = handlers.backendErrorHandler;
		this.parseErrorHandler = handlers.parseErrorHandler;
		this.internetConnectivityHandler = handlers.internetConnectivityHandler;
		this.defaultOptions = defaultOptions;
	}

	/**
	* [sleep description]
	* @param  {Number} time  time out time
	* @return {Object}      Promise for sleep
	*/
	sleep = (time = 1000) => new Promise((resolve) => setTimeout(resolve, time))

	/**
	* definition of parseHandler function
	* @param  {Object} response       response object
	* @param  {Object} parseError     parseError object
	* @param  {Object} Promise  		Promise of the fetch to be resolved or rejected
	*/
	parseHandler = (response, parseError, promiseForFetch) => {
		const infoObject = {
			url: response.url,
			httpCode: response.status,
			ajaxCallInfo: {
				ok: response.ok,
				redirected: response.redirected,
				status: response.status,
				statusText: response.statusText,
				type: response.type				
			},
			response: parseError
		};
		// Parsing errors are caught and handled here
		this.parseErrorHandler(infoObject);
		promiseForFetch.reject(infoObject);
	}

	/**
	* definition of responseHandler function
	* @param  {Object} response       response object
	* @param  {Object} Promise  		Promise of the fetch to be resolved or rejected
	*/
	responseHandler = (response, promiseForFetch) => {
		if (response.ok) {
			//Successful responses are handled here
			const contentType = response.headers.get("content-type");
			if (contentType && contentType.indexOf("text/plain") !== -1) {
				response.text().then((text) => {
					promiseForFetch.resolve({
						textResponse: text
					});
				}).catch((parseError) => {
					this.parseHandler(response, parseError, promiseForFetch);
				});
			} else {
				response.json().then((responseJSON) => {
					//Successful responses is resolved from here
					promiseForFetch.resolve(responseJSON);
				}).catch((parseError) => {
					//Successful responses in non-json format are handled here
					this.parseHandler(response, parseError, promiseForFetch);
				});
			}
		} else {
			// Backend errors are caught and handled here

			const contentType = response.headers.get("content-type");
			console.log('debug: Error response', response);
			console.log('debug: URL of error response', response.url);
			console.log('debug: status of error response', response.status);
			console.log('debug: statusText of error response', response.statusText);
			console.log('debug: ContentType of error response:', contentType);
			if (contentType && contentType.indexOf("application/json") !== -1) {
				response.json().then(responseJSON => {
					console.log('debug: responseJSON', responseJSON);
					const infoObject = {
						url: response.url,
						httpCode: response.status,
						ajaxCallInfo: {
							ok: response.ok,
							redirected: response.redirected,
							status: response.status,
							statusText: response.statusText,
							type: response.type,
							url: response.url
						},
						response: responseJSON
					};
					this.backendErrorHandler(infoObject);
					promiseForFetch.reject(infoObject);
				});
			} else {
				//Backend errors in non-json format are handled here
				response.text().then(text => {
					console.log('debug: text', text);
					this.parseHandler(response, text, promiseForFetch);
				})
			}
		}
	}

	/**
	* definition of core function
	*
	* @param  {String} url          URL to acess the API
	* @param  {Object} initOptions  Request body and options to be sent
	* @param  {Object} Promise  		Promise of the fetch to be resolved or rejected
	* @return {Object} options     extra options
	*/
	core = (url, initOptions, promiseForFetch, options) => {
		fetch(url, initOptions).then((response) => {
			//Responses are analyzed and handled here
			this.internetConnectivityHandler(true);
			this.responseHandler(response, promiseForFetch);
		})
			.catch((networkError) => {
				// Network errors are caught and handled here
				this.internetConnectivityHandler(false);

				const infoObject = {
					errorInfo: networkError
				};
				this.networkErrorHandler(infoObject);

				if (options.shouldRetry !== undefined && !options.shouldRetry) {
					promiseForFetch.reject(networkError);
				}
				else {
					// Re fetch the failed api once again in an exponential time frame
					const sleepTime = options.sleepTime || 1000;
					this.sleep(sleepTime).then(() => {
						const updatedOptions = { ...options, sleepTime: sleepTime * 2 };
						this.core(url, initOptions, promiseForFetch, updatedOptions);
					});
				}
			});
	}

	/**
	* definition of coreUtil function
	*
	* @param  {String} url          URL to acess the API
	* @param  {Object} [options={}] Options that are used by the API
	* @return {Object}              response from the API
	*/
	coreUtil = (type, url, options = {}, isFilePayload = false) => {
		const promiseForFetch = new PromiseDefer();
		options = {
			...options,
			body: (options.requestType == "file_upload" || isFilePayload) ? options.body : JSON.stringify(options.body)
		};
		let initOptions = {
			method: type,
			...this.defaultOptions,
			...options,
			headers: {
				...this.defaultOptions.headers,
				...options.headers
			}
		};

		if (initOptions.headers && !initOptions.headers.Authorization) {
			delete initOptions.headers.Authorization;
		}

		this.core(url, initOptions, promiseForFetch, options);
		return promiseForFetch.promise;
	}

	/**
	* ajax is adapter pattern for it's functionality
	* @param  {[type]} url takes API url as parameter
	* @return {[type]}     promise for fetch
	*/
	ajax = (url) => ({
		get: (options) => this.coreUtil('GET', url, options),
		post: (options) => this.coreUtil('POST', url, options),
		put: (options) => this.coreUtil('PUT', url, options),
		delete: (options) => this.coreUtil('DELETE', url, options)
	})
}

export default NetPack;
