/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './PreRequisiteLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class PreRequisiteLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<ContentLoader
				height={350}
				width={950}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '100%' }}
			>
				<circle cx="480" cy="46" r="46" />
				<rect x="287" y="167" rx="0" ry="0" width="386" height="24" />
				<rect x="367" y="237" rx="0" ry="0" width="226" height="16" />
				<rect x="70" y="237" rx="0" ry="0" width="226" height="16" />
				<rect x="664" y="237" rx="0" ry="0" width="226" height="16" />
			</ContentLoader>
		);
	}
}

export default PreRequisiteLoader;
