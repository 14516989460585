import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
	journeyCategories: [],
	isJourneyCategoriesFetching: false
});

const journeyCategories = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_JOURNEY_CATEGORIES'):
			return state.set(
				'journeyCategories',
				Immutable.fromJS(action.categories)
			).set(
				'isJourneyCategoriesFetching',
				false
			)
		default:
			return state;
	}
}

export default journeyCategories;