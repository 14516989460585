import actionConsts from 'constants/actions';

const setUiState = (payload) => {
     
     
    return {
        type: actionConsts.get('SET_UI_STATE'),
        payload
    };
};

export default setUiState;
