import React, { Component } from 'react';
import styles from './dialogBubble.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DialogBubble extends Component {

	render() {

		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="dialog-bubble-component">
				<svg width="100%" height="100%" viewBox="0 0 446 129">
					<path fill="none" fillRule="evenodd" stroke="#AFAFAF" strokeWidth="1.5" d="M24.088 126.95l17.84-14.7H442a3.25 3.25 0 0 0 3.25-3.25V4A3.25 3.25 0 0 0 442 .75H4A3.25 3.25 0 0 0 .75 4v105A3.25 3.25 0 0 0 4 112.25h20.088v14.7z"/>
				</svg>
			</div>
		);
	}
}



export default DialogBubble;
