import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
        infoIcon: {
            color: skin.get('greyColor2'),
            textDecorationColor: skin.get('greyColor2'),
            ...myTypography.h6,
            fontWeight: 400,
            fontSize: '18px',
            ':hover': {
                fontWeight: 600,
            }
        },
		tooltipContent: {
            backgroundColor: skin.get('greyColor2'),
            color: skin.get('white'),
            boxShadow: `5px 5px 10px 0 ${hexToRgbA(skin.get('black'), 0.5)}`,
            ...myTypography.caption
        },
        tooltipArrow: {
            borderRight: `7px solid ${skin.get('greyColor2')}`,
            borderBottom: `7px solid transparent`
        }
	});
}

export default getSkin;