import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setWidgetAnalyticsData } from 'actions/analytics/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchWidgetAnalytics extends NetPackWrapper {

    constructor() {
        super("GET");        
	}

    prepareUrl = (apiInfoObject) => {    
        
        let url =  urls.get('ANALYTICS').get('GET_WIDGET_ANALYTICS_DATA');
        let condition = [true, false][Math.floor(Math.random() * Math.floor(2))];
        if(false){
			url =  urls.get('DEVELOPMENT_PHASE').get('URL_WITH_ERROR');
        }

        return url.replace('BATCH_ID', apiInfoObject.batchId)
        .replace('USER_ID', '' + apiInfoObject.userId)
        .replace('PHASE_ID',+ apiInfoObject.phaseId )
    }

    successCall = (response) => {
        this.dispatch(setWidgetAnalyticsData(response));
        this.dispatch(setApiError('fetchWidgetAnalyticsError', null));
        return response;
    }

    onError = (errorObj) => {
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchWidgetAnalyticsError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchWidgetAnalytics();
