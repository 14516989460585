import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './FAQItem.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import FAQPlus from 'svgComponents/faqPlus';
import FAQMinus from 'svgComponents/faqMinus';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class FAQItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnswerViewable: false
        }
    }

    onClickOfViewAnswer = () => {
        const { isAnswerViewable } = this.state;
        this.setState({
            isAnswerViewable: !isAnswerViewable
        });
    }


    render() {
        const { isAnswerViewable } = this.state;
        const answerStyle = isAnswerViewable ? 'answer-visible' : 'answer-hidden';
        const icon = isAnswerViewable ? <FAQMinus /> : <FAQPlus />;
        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div styleName="q-a-container" onClick={this.onClickOfViewAnswer}>
                <div styleName="question-container">
                    <div className={css(myStyles.question)}
                        styleName="question"
                        dangerouslySetInnerHTML={{ __html: this.props.item.question }}
                    >
                    </div>
                    <div styleName="icon">{icon}</div>
                </div>
                <div
                    className={css(myStyles.answer)}
                    styleName={answerStyle}
                    dangerouslySetInnerHTML={{ __html: this.props.item.answer }}
                ></div>
            </div>
        );
    }
}

export default FAQItem;
