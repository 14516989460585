import FetchMSTeamsLeaderboard from 'actions/serverCalls/msTeams/FetchMSTeamsLeaderboard';
import FetchBatchBasicDetails from 'actions/serverCalls/batches/FetchBatchBasicDetails';
import CheckIfBatchExists from 'actions/serverCalls/batches/CheckIfBatchExists';
import SetTeamTabLinking from 'actions/serverCalls/msTeams/SetTeamTabLinking';
import DeleteTeamTabLinking from 'actions/serverCalls/msTeams/DeleteTeamTabLinking';

const fetchMSTeamsLeaderboardDataThunk = (dispatch, getState, batchId) =>
    FetchBatchBasicDetails.call({ 
        batchId,
        queryParameters: { batchId },
        headers: {
            'apikey': 'djehuirefjhsdvcshiqwuehoqwihdo'
        }
    }).then((batchDetails) => {
        FetchMSTeamsLeaderboard.call({ 
            queryParameters: { batchId, journeyName: batchDetails.journeyName },
            headers: {
                'x-api-key': 'abc',
                'apikey': 'djehuirefjhsdvcshiqwuehoqwihdo'
            }
        });
    });


const fetchMSTeamsLeaderboardData = (batchId) => (dispatch, getState) =>
    fetchMSTeamsLeaderboardDataThunk(dispatch, getState, batchId);


const checkIfBatchExists = (batchId, callback) => {
    CheckIfBatchExists.call({
        batchId,
        headers: {
            'apikey': 'djehuirefjhsdvcshiqwuehoqwihdo',
            'Content-type': 'application/json'
        }
    }).then((response) => {
        callback(response.exists ? true: false);
    }).catch((error) => {
        callback(false);
    });
}

const setTeamTabLinking = (payload, successCallback, failureCallback) => {
    payload = {
        body: payload,
        headers: {
            'apikey': 'djehuirefjhsdvcshiqwuehoqwihdo',
            'Content-type': 'application/json'
        }
    };
    SetTeamTabLinking.call(payload)
        .then(response => successCallback())
        .catch((error) => failureCallback(error ? error.message : null));
}

const deleteTeamTabLinking = (payload, successCallback, failureCallback) => {
    payload = {
        body: payload,
        headers: {
            'apikey': 'djehuirefjhsdvcshiqwuehoqwihdo',
            'Content-type': 'application/json'
        }
    };
    DeleteTeamTabLinking.call(payload)
    .then(response => successCallback())
    .catch((error) => failureCallback(error ? error.message : null));
}

export {
    fetchMSTeamsLeaderboardData,
    checkIfBatchExists,
    setTeamTabLinking,
    deleteTeamTabLinking
}