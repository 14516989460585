import React, { Component } from 'react';
import styles from './calendarSvg.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CalendarSvg extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="calendar-component">
				<svg width="100%" height="100%" viewBox="0 0 32 28">
					<g fill="none" fillRule="evenodd" stroke="#979EB7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
						<rect width="30" height="22.75" x="1" y="4.375" rx="1.5"/>
						<path d="M9 7V.875M23 7V.875M7 16.625h6v5.25H7zM19 11.375v10.5M13 11.375h12v10.5H13zM13 16.625h12"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default CalendarSvg;
