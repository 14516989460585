
const dataGen = (userCount, maxScore) => {

	const userIndex = Math.ceil(Math.random() * Math.floor(userCount));
	const userName = [
		'Gertude Loch',
		'Taina Gilmour',
		'Jennie Westendorf',
		'Agatha Hubbell',
		'Anissa Poynter',
		'Doyle Winfree',
		'Dave Furlough',
		'Alana Koren',
		'Malka Ryce',
		'Mellie Kos',
		'Klara Simmon',
		'Pa Buttars',
		'Adelaide Greb',
		'Lilia Arceneaux',
		'Augustina Hatt',
		'Quentin Kasten',
		'Dario Verrier',
		'Vannesa Mungo',
		'Manuel Jaffe',
		'Sean Lagrange'
	];

	let leaderboard = {
		userRank: {
			userId: userIndex,
			rank: userIndex
		},
		data: []
	};

	let i = 0;
	let maxNames = userName.length; 

	for (let index = 0; index < userCount; index++) {
		if(i > maxNames) {
			i = 0;
		}
		leaderboard.data.push({
			userId: index+1,
			rank: index+1,
			userName: userName[i],
			score: Math.floor(Math.random() * Math.floor(maxScore)),
			preRank: Math.random() * 2 > 1 ? -1 : 1
		});
		i++;
	}

	leaderboard.data.sort((a, b) => {
		return (a.score > b.score) ? -1 : ((a.score < b.score) ? 1 : 0);
	});

	leaderboard.data.map((val, index) => {
		if ((index+1) == userIndex) {
			val.userName = 'You';
		}
		val.rank = index + 1;
		return val;
	});

	return leaderboard;
};

export default dataGen;