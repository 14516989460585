import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './horizontalBarsWithInfo.module.sass';
import HorizontalBars from 'commonComponents/graphs/horizontalBars';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import analyticGroupColors from 'constants/analyticGroupColors';

@CSSModules(styles, { allowMultiple: true })

class HorizontalBarsWithInfo extends React.Component {

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchOverallProgressData(+batchId, +userId);
    }

    renderHorizontalBar = (barStyling) => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        if(this.props.apiErrors.get('fetchOverallProgressDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchOverallProgressDataError')}
                retryHandler={()=>{
                    this.props.fetchOverallProgressData(+batchId, +userId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('activity');        

        if (allGraphsData.get('isOverallProgressDataFetched')) {

            const graphData = allGraphsData.get('overallProgressGraphData');
            const strings = graphData.get('strings');

            const graphFormat = {
                xLabel: strings.get('xAxisLabel')
            };

            return(
                [<InfoWithoutFilterComponent 
                    {...this.props}
                    title={strings.get('graphTitle')}
                    subtitle={strings.get('graphDesc')}
                    key="InfoWithoutFilterComponent"
                />,
                <HorizontalBars
                    graphData={graphData}
                    barStyling={barStyling}
                    graphFormat={graphFormat}
                    {...this.props}
                    key="HorizontalBars"
                />]
            );
        }
        return <LoaderComponent />;
        
    }

    render() {
        const barStyling = {
            fromUser: '#26fa96',
            toUser: '#6cffba',
            userStroke: '#79e071',
            userRotation: 269,
            fromGroup: analyticGroupColors.fromGroup,
            toGroup: analyticGroupColors.toGroup,
            groupStroke: analyticGroupColors.groupStroke,
            groupRotation: 269,
            gridValue: 10,
            componentName: 'summary-progress'
        }

        return(
            <div styleName="container-class">
                <div styleName="area-graph-container">
                    <div styleName="area-graph">
                        {this.renderHorizontalBar(barStyling)}
                    </div>
                </div>
            </div>
        )
    }
}


export default HorizontalBarsWithInfo;