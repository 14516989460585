import React, { Component } from 'react';
import InnerListItem from 'commonComponents/collapsibleSidebar/innerListItem';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import CircularLoader from 'svgComponents/circularLoader';

export default class ModuleItemsList extends Component {

    componentWillMount(){
        this.props.fetchModuleItemsList({...this.props.routeParams, moduleId: this.props.module.get('id')});
    }

    getModuleDetails = () => {
        const {module, modulesItemsList} = this.props;
        if(module){
            const moduleDetails = modulesItemsList.find(listItem => listItem.get('id') == module.get('id'));
            return moduleDetails;
        }
        return null;
    }

    onItemClick = (courseId, moduleId, itemId) => {
		const route = routes.get('BATCH_ITEM_MODULE');
		const params = [{ 'BATCH_ID': this.props.routeParams.batchId },
		{ 'COURSE_ID': courseId },
		{ 'MODULE_ID': moduleId },
		{ 'ITEM_ID': itemId },
		{ '_PHASE_ID_': this.props.routeParams.phaseId },
		{ '_PHASEITEMID_': this.props.routeParams.phaseItemId }
		]
		updateRoute({ route, params });
	}

    render(){
        const { module, isSidebarOpen, routeParams, phaseItemData} = this.props;
        const moduleDetails = this.getModuleDetails();
        
        if(moduleDetails && moduleDetails.get('isModuleItemsListAvailable')){
            const items = moduleDetails.get('moduleItemsList');
            return items.map((moduleItem, index) => {
                return <InnerListItem
                    key={`sidebar_module_item_${index}`}
                    moduleItem={moduleItem}
                    isSidebarOpen={isSidebarOpen}
                    itemsListLength={items.size}
                    currentElementIndex={index}
                    status={routeParams.moduleItemId == moduleItem.get('id') ? 'active' : 'other'}
                    onItemClick={() => { this.onItemClick(module.get('course_id'), module.get('id'), moduleItem.get('id')) }}
                    phaseItemData={phaseItemData}
                />
            });
        }        
        return <div style={{height: '30px', padding: '10px'}}><CircularLoader/></div>;
    }

}