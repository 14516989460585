import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		container:{
			opacity: '0.85'
		},

		containerOnOverlay:{
			opacity: '1'
		},

		title:{
			...myTypography.h6,
			color: 'white'

		}

	});
}

export default getSkin;