import React, { Component } from 'react';
import styles from './success.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Success extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="main-component" onMouseEnter={this.setHoverTrue} onMouseLeave={this.setHoverFalse}>
				<svg width="100%" height="100%" viewBox="0 0 20 20">
					<path fill="#6CBC14" fillRule="nonzero" d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.994 4.48 15.52.006 10 0zm5.773 6.833l-5.705 7.741a.843.843 0 0 1-1.191.157l-4.074-3.257a.834.834 0 0 1 1.042-1.302l3.397 2.718 5.189-7.043a.833.833 0 1 1 1.342.986z"/>
				</svg>
			</div>
		);
	}
}



export default Success;
