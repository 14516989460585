/**
 * @author: Amul
 * @since: Mon Jan 16 2020 15:18:31 GMT+0530 (IST)
 * @file: coursesActiveSections.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
  Keep active module ids here
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrReplaceObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS([]);

const coursesActiveSections = (state = initialState, action = {}) => {
    switch (action.type) {

        case actionConsts.get('SET_COURSE_SIDEBAR_ACTIVE_MODULE'):
            const updatedCourseActiveSection = {
                id: action.courseId,
                phaseItemId: action.phaseItemId,
                activeSectionId: action.moduleId
            };
            return setOrReplaceObjectInList(
                state, updatedCourseActiveSection, 'id', 'id'
            );      
        case actionConsts.get('UNSET_COURSE_SIDEBAR_ACTIVE_MODULE'):
            return initialState;
        default:
            return state;
    }
};

export default coursesActiveSections;