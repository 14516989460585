import React, { Component } from 'react';
import VideoPlayer from 'commonComponents/contentPlayers/videoPlayer';
import AdditionalInformation from 'components/moduleItems/additionalInformation';
import CSSModules from 'react-css-modules';
import styles from './video.module.sass';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@CSSModules(styles, { allowMultiple: true })
class Video extends Component {

	getVideoPlayerConfig = (moduleItem) => {
		return {
			kalturaEntryId: moduleItem.get('moduleItem').get('kaltura_entry_id')
		};
	}

	updateVideoStatus = (progress, total=0, is_completed = false) => {
		this.props.updateMediaFileStatus({
			progress,
			is_completed,
			...this.props.match.params
		});
		if (is_completed) {
			this.props.markRead();
		}
	};

	render() {
		let { moduleItem, getLabelWithDefaults } = this.props;
		let videoPlayerConfig = this.getVideoPlayerConfig(moduleItem);

		return (
			<div>
				<div styleName="video-container">
					<span>
						<VideoPlayer
							key={`module_item_video_${moduleItem.get('moduleItem').get('id')}`}
							config={videoPlayerConfig}
							playerWidth="100%"
							playerHeight="100%"
							onUpdateProgress={this.updateVideoStatus}
							videoPlayerTargetId='video-player-container'
							progress={moduleItem.get('moduleItem').get('file_progress').toJS()}
							isVideoCompleted={moduleItem.getIn(['moduleItem', 'completion_requirement', 'completed'])}
						/>
					</span>
				</div>


				<AdditionalInformation
					title={getLabelWithDefaults('video_additional_information_label', "Additional Information")}
					body={moduleItem.getIn(['moduleItem', 'description'])}
				/>
				
			</div>
		)
	}
}

export default Video;