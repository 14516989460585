/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './ScoreCardSvg.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class ScoreCardSvg extends React.Component {

	render() {
		return (
			<svg width="310" height="212" viewBox="0 0 310 212">
				<defs>
					<rect id="a" width="302" height="204" rx="4" />
					<filter id="b" width="104.6%" height="106.9%" x="-2.3%" y="-2.5%" filterUnits="objectBoundingBox">
						<feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
						<feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
						<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.163722826 0" />
					</filter>
				</defs>
				<g fill="none" fillRule="evenodd">
					<g transform="translate(4 2)">
						<mask id="c" fill="#fff">
							<use href="#a" />
						</mask>
						<use fill="#000" filter="url(#b)" href="#a" />
						<use fill="#FFF" href="#a" />
						<path fill="#D5D9E7" d="M-5 0h310v51H-5z" mask="url(#c)" />
					</g>
					<g fill="#979EB7" opacity=".252" transform="translate(61 13)">
						<circle cx="15" cy="15" r="15" />
						<path d="M44 6h138v16H44z" />
					</g>
				</g>
			</svg>
		);
	}
}

export default ScoreCardSvg;
