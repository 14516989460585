import actionConsts from 'constants/actions';

const setCoursesList = (coursesList, apiInfoObject) => ({
    type: actionConsts.get('SET_COURSES_LIST'),
    coursesList,
    phaseId: apiInfoObject.phaseId
});

const setCoursesListProgress = (coursesListProgress, apiInfoObject) => ({
    type: actionConsts.get('SET_COURSES_PROGRESS_LIST'),
    coursesListProgress,
    phaseId: apiInfoObject.phaseId
});

const setLoadedModule = module => ({
    type: actionConsts.get('SET_LOADED_MODULE'),
    module
});

const setLoadedModuleAvailabilityStatus = status => ({
    type: actionConsts.get('SET_LOADED_MODULE_AVAILABILITY'),
    status
});

const setCourse = modules => {
    return {
        type: actionConsts.get('SET_COURSE'),
        course: {
            id: modules[0].course_id,
            name: modules[0].course_name,
            modules: modules
        }
    }
};

const setCourseModulesList = (courseModulesList, apiInfoObject) => {
    return {
        type: actionConsts.get('SET_COURSES_MODULES_LIST'),
        courseId: +apiInfoObject.courseId,
        phaseItemId: +apiInfoObject.phaseItemId,
        courseModulesList: courseModulesList 
    }
}

const setModuleItemsList = (moduleItemsList, apiInfoObject) => {
    return {
        type: actionConsts.get('SET_MODULES_ITEMS_LIST'),
        moduleId: +apiInfoObject.moduleId,
        moduleItemsList: moduleItemsList 
    }
}

const setCourseActiveSection = (courseId, phaseItemId, moduleId) => {
    return {
        type: actionConsts.get('SET_COURSE_SIDEBAR_ACTIVE_MODULE'),
        courseId: +courseId,
        phaseItemId: +phaseItemId,
        moduleId: +moduleId
    }
}

const unsetCourses = () => ({
    type: actionConsts.get('UNSET_JOURNEY_COURSES')
});

const setActiveCourseIdInSidebar = (courseId) => ({
    type: actionConsts.get('SET_ACTIVE_COURSE_ID_SIDEBAR'),
    courseId
})

const setCourseAvailablity = (status) => ({
    type: actionConsts.get('SET_COURSE_AVAILABILITY'),
    status
});

const setCourseForNextActivity = (course) => ({
    type: actionConsts.get('SET_COURSE_FOR_NEXT_ACTIVITY'),
    course
})

const unsetCourseForNextActivity = (course) => ({
    type: actionConsts.get('UNSET_COURSE_FOR_NEXT_ACTIVITY'),
    course
})

const setNextModuleItemAvailability = (status) => ({
    type: 'SET_NEXT_ACTIVITY_ITEM_AVAILABILITY',
    status
});

export {
    setCoursesList,
    setLoadedModule,
    setLoadedModuleAvailabilityStatus,
    setCourse,
    setCourseModulesList,
    setModuleItemsList,
    setCourseActiveSection,
    unsetCourses,
    setActiveCourseIdInSidebar,
    setCourseAvailablity,
    setCourseForNextActivity,
    unsetCourseForNextActivity,
    setCoursesListProgress,
    setNextModuleItemAvailability
};
