import React, { Component } from 'react';
import styles from './rankWidget.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import RankLogo from 'images/analytics/rank_widget.svg';
import { checkIfPresent } from 'utils/utilFunctions';


@applySkin
@CSSModules(styles, { allowMultiple: true })
class RankWidget extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    renderInsightForWidget = (styles, insightPresent) => {
        if (insightPresent) {
            return(
                <div className={css(styles.widgetDescription)} styleName="widget-description">
                    Your average completion rate in assessments is way below your group average. 
                    You need to complete all assessments soon 
                </div>
            )
        }
        return '';
    }

    renderLastVariation = (styles, widgetDataArray) => {
        const arrayVal = widgetDataArray.get('widgetName') == 'Score' 
                            ? widgetDataArray.get('widgetData').get('userScore').get('data')
                            : widgetDataArray.get('widgetData').get('userRank').get('data')
        
        let getClassName = css(styles.negativeRankIncreaseStyle);
        
        let getStyleName = "arrow-down";

        if (arrayVal.get('lastValue') >= 0) {

            if(arrayVal.get('value') - arrayVal.get('lastValue') < 0) {
                getClassName =  css(styles.positiveRankIncreaseStyle);
                getStyleName = "arrow-up";
            }

            return(
                <div className={css(styles.widgetContentMainTitle)} styleName="widget-rank-increase">
                    <div className={getClassName} styleName={getStyleName}>
                    </div> {Math.abs(arrayVal.get('value') - arrayVal.get('lastValue'))} 
                    {/* <span className={css(styles.widgetContentSubMain)}> 
                        {widgetDataArray.get('widgetData').get('strings').get('sinceWeeksMessage')}
                    </span> */}
                </div>
            )
        }
        return null;
    }

    renderScoreData = (widgetDataArray) => {
        let score = "-";

        try{
            score = widgetDataArray.get('widgetData').get('userScore').get('data').get('value').toFixed(2)
        }catch(e){
            console.log('e', e);
        }

        return (
            [
                <div styleName="score-rank-container">
                   { score }
                    <div styleName="score-total-container">
                        {widgetDataArray.get('widgetData').get('strings').get('ofLabel') + ' ' + widgetDataArray.get('widgetData').get('userScore').get('data').get('total')}
                    </div>
                </div>,
                
            ]
        )
    }
  
    render() {
        const styles = getSkin(this.props.skinGuide);

        const widgetDataArray = this.props.widgetArray;

        const insightPresent = checkIfPresent(widgetDataArray.insight);
        
        return (
            <div className={css(styles.widgetContainerStyle)} styleName="widget-container-class">
                <div className={css(styles.widgetTitle)} styleName="widget-title">
                    {widgetDataArray.get('title')}
                </div>
                <div styleName="widget-content">
                    <div styleName="widget-main-content">
                        <div className={css(styles.widgetContentMain)} >
                            {widgetDataArray.get('widgetName') == 'Score' 
                            ? this.renderScoreData(widgetDataArray)
                            : widgetDataArray.get('widgetData').get('userRank').get('data').get('value')}
                        </div>
                        {this.renderLastVariation(styles, widgetDataArray)}
                    </div>
                </div>
                {this.renderInsightForWidget(styles, insightPresent)}
                <div styleName="bg-logo">
                    <img src={RankLogo} />
                </div>
            </div>
        );
    }
  }
  
  RankWidget.defaultProps = {
  };

  export default RankWidget;