import React, { Component } from 'react';
import styles from './journeyItemIntroduction.module.sass';
import { css } from 'aphrodite/no-important';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import DurationImg from 'images/calendar.png';
import ClockImg from 'images/clock.svg';
import applyLabel from 'utils/LabelProvider';
import StarComponent from 'svgComponents/starComponent';
import JourneyItemIntruductionLoader from 'commonComponents/loaders/journeyItemIntruductionLoader';
import enumConstants from 'constants/enums';
import { checkIfPresent } from 'utils/utilFunctions';
import LockIcon from 'svgComponents/lockIcon';
import { getLockMessage } from 'utils/locks';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyItemIntroduction extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showReadMore: true
		}
	}

	enableReadMore = () => {
		if (!this.state.showReadMore) return false;
		window.requestAnimationFrame(() => {
			let descComponent = document.getElementById('journey-desc-element');
			let readMoreComponent = document.getElementById('read-more-link');
			if (descComponent && descComponent.scrollHeight >= 130) {
				readMoreComponent.style.display = "block";
			}
		});
	}

	componentDidMount() {
		this.enableReadMore();
	}

	componentDidUpdate() {
		this.enableReadMore();
	}

	sendSegmentData = (event, options = {}) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			journeyName: this.props.journeyDetails.getIn(['name']),
			journeyId: this.props.journeyDetails.getIn(['id']),
			...options
		}
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	hideReadMore = (e) => {
		this.sendSegmentData('Read More(journey descripion) clicked');
		document.getElementById('read-more-link').style.display = 'none';
		document.getElementById('journey-desc-element').style.maxHeight = 'unset';
		this.setState({ showReadMore: false })
	}

	getPhasesString() {
		let phasesString = '';

		if (this.props.phasesList.isEmpty()) {
			return phasesString;
		}

		this.props.phasesList.map((phase) => {
			phasesString += `${this.props.getLabel(phase.get('name'))}, `;
		});

		//remove last , and space
		phasesString = phasesString.substring(0, phasesString.length - 2);

		//change second last , to &
		const lastIndexOfComma = phasesString.lastIndexOf(',');
		if (lastIndexOfComma !== -1) {
			phasesString = `
				${phasesString.substring(0, lastIndexOfComma)}
				&
				${phasesString.substring(lastIndexOfComma + 1)}
			`;
		}

		return phasesString;
	}

	updateWorkflowStatus = () => {
		this.sendSegmentData(
			'Journey Invitation Accepted',
			{ batchId: this.props.userActiveBatchDetails.get('batchId') }
		);
		this.props.updateWorkflowStatus(enumConstants.get('ACCEPTED'));

		this.navigateToBatchPage();
	}

	renderPhasesNames(myStyles) {
		return (
			<div className={css(myStyles.journeySubTitle)} styleName="journey-sub-title">
				{this.getPhasesString()}
			</div>
		);
	}

	renderFeedbackRating = (score, scale) => {
		let starArray = [];
		for (let i = 0; i < scale; i++) {
			if (score < 0) {
				starArray.push(
					<StarComponent id={i + 1} starWidthPerc={0} />
				);
			} else if (score < 1) {
				starArray.push(
					<StarComponent id={i + 1} starWidthPerc={score * 100} />
				);
			} else {
				starArray.push(
					<StarComponent id={i + 1} starWidthPerc={100} />
				);
			}
			score -= 1;
		}
		return starArray;
	}

	renderRatings(myStyles) {
		const scale = 5;
		const rating = this.props.journeyDetails.get('rating');
		if (!rating) {
			return null;
		}
		return (
			<div styleName="rating">
				<div styleName="rating-stars">
					{this.renderFeedbackRating(rating, scale)}
				</div>
				<div styleName="rating-value" className={css(myStyles.rating)}>
					{rating}/{scale}
				</div>
			</div>
		);
	}

	renderUserName() {
		const firstName = this.props.user.get('firstName');
		const lastName = this.props.user.get('lastName');

		if(firstName && firstName.includes("@lsk.pe")) return '!';

		return <span>&nbsp;
			{firstName}&nbsp;
			{lastName},
		</span>;
	}

	renderUserGreeting = (myStyles) => {
		if (this.props.renderingInOverlay) {
			return null;
		}
		return (
			<div className={css(myStyles.userGreetings)} styleName="user-greetings">
				{this.props.getLabel('journey_intro_hello')}
				{this.renderUserName()}
			</div>
		);
	}

	renderGreetingLine = (myStyles) => {
		if (this.props.renderingInOverlay) {
			return null;
		}
		return <div className={css(myStyles.userGreetingsLine)} styleName="user-greetings-line"></div>
	}

	renderJourneyInfo(myStyles) {
		let desc = this.props.journeyDetails.get('description');

		return (
			<div styleName="journey-info">
				{this.renderUserGreeting(myStyles)}
				{this.renderGreetingLine(myStyles)}
				<div className={css(myStyles.getStarted)} styleName="get-started" style={
					this.props.renderingInOverlay ? { display: 'none' } : {}
				}>
					{this.props.getLabel('journey_get_started_with')}
				</div>
				<div className={css(myStyles.journeyTitle)} styleName="journey-title">
					{this.props.getLabel(this.props.journeyDetails.get('name'))}
				</div>
				{this.renderRatings(myStyles)}

				{
					checkIfPresent(desc) &&
					<div
						styleName="journey-desc"
						id="journey-desc-element"
						className={css(myStyles.rating)}
						dangerouslySetInnerHTML={{
							__html: desc
						}}
					/>
				}
				{
					checkIfPresent(desc) &&
					<div
						id="read-more-link"
						className={css(myStyles.rating)}
						styleName="read-more-link"
						onClick={this.hideReadMore}
					>
						{this.props.getLabel('read_more_text_label')}
					</div>
				}
			</div>
		);
	}

	renderDurationInfo(myStyles) {
		if (!checkIfPresent(this.props.journeyDetails.get('duration')) &&
			!checkIfPresent(this.props.journeyDetails.get('effort')))
			return null;
		return (
			<div styleName="duration-container">
				{
					checkIfPresent(this.props.journeyDetails.get('duration')) &&
					<div styleName="duration">
						<div styleName="duration-content">
							<div styleName="image">
								<img src={DurationImg} width="100%" height="100%" alt="Duration" />
							</div>
							<div className={css(myStyles.value)} styleName="duration-value">
								{this.props.journeyDetails.get('duration')}
							</div>
						</div>
						<div className={css(myStyles.title)} styleName="title-content">
							{this.props.journeyDetails.get('durationLabel')}
						</div>
						{/* <div styleName="image">
							<img src={DurationImg} width="100%" height="100%" />
						</div>
						<div className={css(myStyles.title)}>
							{this.props.getLabel('duration_label')}
						</div>
						<div className={css(myStyles.value)}>
							{this.props.journeyDetails.get('duration')}
						</div> */}
					</div>
				}
				{
					checkIfPresent(this.props.journeyDetails.get('duration')) &&
					checkIfPresent(this.props.journeyDetails.get('effort')) &&
					<div className={css(myStyles.durationStudyLine)} styleName="duration-study-line"></div>
				}
				{
					checkIfPresent(this.props.journeyDetails.get('effort')) &&
					<div styleName="study">
						<div styleName="duration-content">
							<div styleName="image">
								<img src={ClockImg} width="100%" height="100%" alt="Duration" />
							</div>
							<div className={css(myStyles.value)} styleName="duration-value">
								{this.props.journeyDetails.get('effort')}
							</div>
						</div>
						<div className={css(myStyles.title)} styleName="title-content">
							{this.props.journeyDetails.get('effortsNeededLabel')}
						</div>
						{/* <div styleName="image">
						<img src={ClockImg} width="100%" height="100%" />
					</div>
					<div className={css(myStyles.title)}>
						{this.props.getLabel('study_label')}
					</div>
					<div className={css(myStyles.value)}>
						{this.props.journeyDetails.get('effort')}
					</div> */}
					</div>
				}
			</div>
		);
	}

	renderLoader(myStyles) {
		return (
			<div styleName="intro-container" className={css(myStyles.loaderBackground)}>
				<div styleName="intro-container-content">
					<JourneyItemIntruductionLoader />
				</div>
			</div>
		);
	}

	showVideoOverlay = () => {
		const { setUiState } = this.props;
		this.sendSegmentData('Journey video opened');
		setUiState({ showVideoOverlay: true });
	}

	navigateToBatchPage = () => {
		const route = routes.get('BATCH');
		const params = [{ 'BATCH_ID': this.props.userActiveBatchDetails.get('batchId') }];
		updateRoute({ route, params });
	}

	getStartButton = (myStyles) => {
		const { userActiveBatchDetails } = this.props;
		let className = css(myStyles.acceptInvitation);
		let startAction = userActiveBatchDetails.get('workflowState') === 'ACCEPTED' ? this.navigateToBatchPage : this.updateWorkflowStatus;
		let lockIcon = null;
		const lockDetails = userActiveBatchDetails.get('lock_details');
		let isLocked = lockDetails && lockDetails.get('locked');

		const isBatchWorkflowStateCompleted = userActiveBatchDetails.get('workflowState') === 'COMPLETED';
		const isBatchExpired = lockDetails && lockDetails.get('lock_reason') === 'expired';

		const isBatchUpcoming = isLocked && lockDetails.get('lock_reason') === "not_started";

		let lockExplanation;
		let buttonLabel = 'accept_invitation_label';

		let isButtonLocked = false;

		if(isBatchWorkflowStateCompleted && isBatchExpired) {
			buttonLabel = 'view_journey_label';
		} else if(isBatchExpired) {
			buttonLabel = 'view_journey_label';
		} else if(isBatchWorkflowStateCompleted && !isLocked) {
			buttonLabel = 'view_journey_label';
		} else if(isBatchUpcoming) {
			buttonLabel = 'accept_invitation_label';
			lockExplanation = getLockMessage(lockDetails);
			isButtonLocked = true;
		} else if(userActiveBatchDetails.get('workflowState') === 'ACCEPTED') {
			buttonLabel = 'continue_learning_label';
		} 

		if (isButtonLocked) {
			className = css(myStyles.acceptInvitationDisabled);
			lockIcon = <div styleName="locked-icon">
				<LockIcon />
			</div>;
			startAction = () => { };
		}

		return <div styleName="single-button-outer-container">
			<div
				className={className}
				styleName="button"
				onClick={startAction}
			>
				{lockIcon}
				{this.props.getLabel(buttonLabel)}
			</div>
			<div className={css(myStyles.lockExplanation)} styleName="lock-explanation">
				{lockExplanation}
			</div>
		</div>
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);

		const isBackdropAvailable = checkIfPresent(this.props.journeyDetails.get('backdropImage'));

		const acceptInivitationStyle = (this.props.journeyDetails.get('videoLink') == null || this.props.journeyDetails.get('videoLink') == '') ? "single-button-container-center" : "single-button-container-right";
		return (
			<div ref={this.props.stickyRef} styleName="intro-container">
				<div
					className={css(myStyles.boxBackground)}
					styleName="intro-container-background"
					style={{
						backgroundImage: isBackdropAvailable ? `url("${this.props.journeyDetails.get('backdropImage')}")` : `url("https://webassets.knolskape.com/misc/amul_garg/2020/02/12/56/ftm1.jpg")`
					}}
				>
				</div>
				<div
					styleName="intro-container-gradient"
					className={css(myStyles.boxGradient)}
				>
				</div>
				<div styleName="intro-container-content">
					{this.renderJourneyInfo(myStyles)}
					{this.renderDurationInfo(myStyles)}
					{
						(checkIfPresent(this.props.journeyDetails.get('videoLink'))
							|| !this.props.renderingInOverlay) &&
						<div styleName="buttons-container">
							{
								checkIfPresent(this.props.journeyDetails.get('videoLink'))
									? <div styleName="single-button-container-center">
										<div className={css(myStyles.watchVideo)} styleName="button" onClick={this.showVideoOverlay}>
											{this.props.getLabel('journey_video_label')}
										</div>
									</div>
									: null
							}
							{
								(!this.props.renderingInOverlay)
									? <div styleName={"single-button-container-center"}>

										{this.getStartButton(myStyles)}
									</div>
									: null
							}
						</div>
					}
				</div>
			</div>
		);
	}
}

export default JourneyItemIntroduction;
