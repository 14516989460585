import Immutable from 'immutable';
import actionConstants from 'constants/actions';
import { setOrUpdateObjectInMap } from 'utils/objectUtils';

const initialState = Immutable.fromJS({});

const reports = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.get('REPORT_TASK_STARTED'):
			return setOrUpdateObjectInMap(
				state,
				`batch_${action.batchId}_${action.reportType}`,
				{
					reportType: action.reportType,
					task: action.task
				}
			);
		case actionConstants.get('REPORT_TASK_UPDATED'):
			return setOrUpdateObjectInMap(
				state,
				`batch_${action.batchId}_${action.reportType}`,
				{
					...state.getIn([`batch_${action.batchId}_${action.reportType}`]),
					task: {
						...state.getIn([`batch_${action.batchId}_${action.reportType}`, 'reportType', 'task']),
						...action.task
					}
				}
			);
		case actionConstants.get('REPORT_TASK_COMPLETED'):
			return state.delete(`batch_${action.batchId}_${action.reportType}`);

		case actionConstants.get('SHARING_CERTIFICATE_TASK_STARTED'):
			return setOrUpdateObjectInMap(
				state,
				`linked_in_share_certificate_batch_${action.batchId}`,
				{
					reportType: action.reportType,
					task: action.task
				}
			);
		case actionConstants.get('SHARING_CERTIFICATE_TASK_UPDATED'):
			return setOrUpdateObjectInMap(
				state,
				`linked_in_share_certificate_batch_${action.batchId}`,
				{
					...state.getIn([`linked_in_share_certificate_batch_${action.batchId}`]),
					task: {
						...state.getIn([`linked_in_share_certificate_batch_${action.batchId}`, 'reportType', 'task']),
						...action.task
					}
				}
			);
		case actionConstants.get('SHARING_CERTIFICATE_TASK_COMPLETED'):
			return state.delete(`linked_in_share_certificate_batch_${action.batchId}`);
	}

	return state;
};

export default reports;
