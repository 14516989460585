import React, { Component } from 'react';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
class Recommended extends Component {

    render() {

        const myStyles = getSkin(this.props.skinGuide);
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 17">
                <g fill="none" fillRule="evenodd" className={css(myStyles.fillColor)} strokeLinecap="round" strokeLinejoin="round">
                    <path d="M8.296 5.25l.881 1.844h1.496a.41.41 0 0 1 .385.249c.065.153.03.33-.09.447L9.593 9.006l.761 1.736a.41.41 0 0 1-.109.469.416.416 0 0 1-.481.046l-1.84-1.028-1.84 1.028a.416.416 0 0 1-.481-.046.41.41 0 0 1-.109-.469l.761-1.736L4.88 7.79a.405.405 0 0 1-.09-.447.41.41 0 0 1 .385-.25h1.496l.881-1.843a.419.419 0 0 1 .744 0z"/>
                    <path d="M8.286 1.633l1.182 1.35a.483.483 0 0 0 .47.153l1.756-.404a.484.484 0 0 1 .587.424l.157 1.783a.476.476 0 0 0 .291.397l1.659.7a.478.478 0 0 1 .224.687l-.928 1.534a.475.475 0 0 0 0 .491l.928 1.534a.474.474 0 0 1-.224.687l-1.659.7a.476.476 0 0 0-.29.397l-.158 1.783a.484.484 0 0 1-.587.424l-1.756-.404a.483.483 0 0 0-.47.152l-1.182 1.352a.483.483 0 0 1-.725 0l-1.182-1.357a.483.483 0 0 0-.471-.152l-1.756.404a.484.484 0 0 1-.587-.424l-.157-1.781a.476.476 0 0 0-.29-.398l-1.66-.7a.478.478 0 0 1-.224-.688l.928-1.534a.472.472 0 0 0 0-.491L1.234 6.72a.474.474 0 0 1 .224-.688l1.66-.697a.476.476 0 0 0 .29-.398l.157-1.783a.484.484 0 0 1 .587-.424l1.756.403a.483.483 0 0 0 .47-.151l1.183-1.351a.482.482 0 0 1 .725.001z"/>
                </g>
            </svg>

        );
    }
}



export default Recommended;
