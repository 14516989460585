/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: user.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * user reducer is responsible for user related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    isUserDataAvailable: false,
    userLogoutURL: ''
});

const user = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_DETAILS'):
            return state.mergeDeep(
                Immutable.fromJS(action.userDetails)
            ).set('isUserDataAvailable', true);
        case actionConsts.get('SET_LOGOUT_URL'):
            return state.set('userLogoutURL', action.url);
        default:
            return state;
    }
};

export default user;