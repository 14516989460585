import Immutable from 'immutable';
import actionConstants from 'constants/actions';
import { setOrUpdateObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
	phaseFeedbackStatuses: [],
	phaseItemFeedbackStatuses: []
});

const feedbackStatuses = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.get('SET_PHASE_FEEDBACK_STATUSES'):
			return state.set(
				'phaseFeedbackStatuses',
				setOrUpdateObjectInList(
					state.get('phaseFeedbackStatuses'),
					action.phaseFeedbackStatus,
					'id',
					'id'
				)
			)
		case actionConstants.get('SET_ITEM_FEEDBACK_STATUSES'):
			return state.set(
				'phaseItemFeedbackStatuses',
				setOrUpdateObjectInList(
					state.get('phaseItemFeedbackStatuses'),
					action.itemFeedbackStatus,
					'id',
					'id'
				)
			)
		default:
			return state;
	}
};

export default feedbackStatuses;