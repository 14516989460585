import React, { Component } from 'react';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import CSSModules from 'react-css-modules';
import styles from './collapsibleSidebar.module.sass';

@applySkin
@CSSModules(styles, {allowMultiple: true})
class CollapsibleSidebar extends Component {
  render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<span>
				{this.props.children}
			</span>
		)
  }
}

export default CollapsibleSidebar;
