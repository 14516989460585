import React, { Component } from 'react';
import styles from './leaderboardWithFilter.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import LeaderboardComponent from 'commonComponents/analytics/leaderboardComponent';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import LockComponent from 'commonComponents/analytics/lockComponent';
import LeaderboardFilter from 'commonComponents/analytics/leaderboardFilter';
import { checkIfPresent } from 'utils/utilFunctions';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';


@applySkin
@CSSModules(styles, { allowMultiple: true })
class LeaderboardWithFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFilters: false,
            courseId: -1,
            sectionId: -1,
            activityId: -1,
            leaderboardData: null
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(state.leaderboardData !== null) {
            return null;
        }
        const allGraphsData = props.allGraphsData.get('performance');
        if (allGraphsData.get('isLeaderboardFetched')) {
            const leaderboardData = allGraphsData.get('leaderboardData').get('data').get('users').toJS();
            return (
                {
                    leaderboardData: leaderboardData
                }
            )
        }
        return null;
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
    //         return false;
    //     }
    //     else{
    //         return true;
    //     }
    // }

    componentDidMount() {
        const batchId = this.props.user.get('userID') ? this.props.match.params.batchId : 1;
        const userId = this.props.user.get('userID') ? this.props.user.get('userID') : 1;
		this.props.fetchLeaderboardData(+batchId, +userId);
    }

    onCourseFilterClicked = (courseId) => {
        this.setState({
            courseId: +courseId
        });
    }

    onSectionFilterClicked = (sectionId) => {
        this.setState({
            sectionId: +sectionId
        });
    }

    onActivityFilterClicked = (activityId) => {
        this.setState({
            activityId: +activityId
        });
    }

    applyFilter = () => {
        const allGraphsData = this.props.allGraphsData.get('performance');
        const leaderboardData = allGraphsData.get('leaderboardData').get('data');
        let users = leaderboardData.get('users').toJS()
        const { courseId, sectionId, activityId } = this.state;

        if (courseId !== -1) {
            const courseData = leaderboardData.get('courses').filter((course, index) => course.get('id') === courseId).get(0);
            users = checkIfPresent(courseData) && checkIfPresent(courseData.get('users')) ?  courseData.get('users').toJS() : {};
        }

        if (sectionId !== -1) {
            let sectionData;
            if (courseId !== -1) {
                sectionData = leaderboardData.get('sections').filter((section, index) => section.get('courseId') === courseId && section.get('id') === sectionId).get(0);
            }
            else {
                sectionData = leaderboardData.get('sections').filter((section, index) => section.get('id') === sectionId).get(0);
            }
            users = checkIfPresent(sectionData) && checkIfPresent(sectionData.get('users')) ? sectionData.get('users').toJS() : {};
        }

        if (activityId !== -1) {
            let activityData = leaderboardData.get('activities').filter((activity, index) => activity.get('id') === activityId).get(0);;
            if (courseId !== -1) {
                activityData = leaderboardData.get('activities').filter((activity, index) => activity.get('courseId') === courseId && activity.get('id') === activityId).get(0);
            }
            if (sectionId !== -1) {
                activityData = leaderboardData.get('activities').filter((activity, index) => activity.get('sectionId') === sectionId && activity.get('id') === activityId).get(0);
            }
            if (courseId !== -1 && sectionId !== -1) {
                activityData = leaderboardData.get('activities').filter((activity, index) => activity.get('courseId') === courseId && activity.get('sectionId') === sectionId && activity.get('id') === activityId).get(0);
            }
            users = checkIfPresent(activityData) && checkIfPresent(activityData.get('users')) ? activityData.get('users').toJS() : {};
        }
        this.setState({
            leaderboardData: users
        })
    }

    showFilterDropdowns = () => {
        this.setState({
            showFilters: !this.state.showFilters
        });
    }

    getSections = () => {
        const allGraphsData = this.props.allGraphsData.get('performance');
        const sections = allGraphsData.get('leaderboardData').get('data').get('sections').toJS();
        const { courseId } = this.state;
        if (courseId === -1) {
            return sections;
        }
        return sections.filter((section, index) => section.courseId === courseId);
    }

    getActivities = () => {
        const allGraphsData = this.props.allGraphsData.get('performance');
        const activities = allGraphsData.get('leaderboardData').get('data').get('activities').toJS();
        const { courseId, sectionId } = this.state;
        if (courseId === -1 && sectionId === -1) {
            return activities;
        }
        if (courseId === -1) {
            return activities.filter((activity, index) => activity.sectionId === sectionId);
        }
        if (sectionId === -1) {
            return activities.filter((activity, index) => activity.courseId === courseId);
        }
        return activities.filter((activity, index) => activity.courseId === courseId && activity.sectionId === sectionId);
    }

    renderFilterDropDowns = (myStyles, strings) => {
        const allGraphsData = this.props.allGraphsData.get('performance');

        const isCoursesAvailable = allGraphsData.get('leaderboardData').get('isCoursesAvailable');
        const isSectionsAvailable = allGraphsData.get('leaderboardData').get('isSectionsAvailable');
        const isActivitiesAvailable = allGraphsData.get('leaderboardData').get('isActivitiesAvailable');

        let courses, sections, activities;

        if (isCoursesAvailable) {
            courses = allGraphsData.get('leaderboardData').get('data').get('courses').toJS();
        }
        if (isSectionsAvailable) {
            sections = this.getSections();
        }
        if (isActivitiesAvailable) {
            activities = this.getActivities();
        }

        const { courseId, sectionId, activityId } = this.state;

        if(this.state.showFilters) {
            return(
                <div className={css(myStyles.dropDownContainerStyle)} styleName="drop-down-container">
                    {isCoursesAvailable && <LeaderboardFilter 
                            handleOnClick={this.onCourseFilterClicked} 
                            list={courses} 
                            showAllOption={true} 
                            selectedId={courseId} 
                            allLabel={strings.get('allLabel')}
                            filterText={strings.get('filterByCourseLabel')} />}
                   {isSectionsAvailable && <LeaderboardFilter 
                            handleOnClick={this.onSectionFilterClicked} 
                            list={sections} 
                            showAllOption={true} 
                            selectedId={sectionId} 
                            allLabel={strings.get('allLabel')}
                            filterText={strings.get('filterBySectionLabel')} />}
                    {isActivitiesAvailable && <LeaderboardFilter 
                            handleOnClick={this.onActivityFilterClicked} 
                            list={activities} 
                            showAllOption={true} 
                            selectedId={activityId} 
                            allLabel={strings.get('allLabel')}
                            filterText={strings.get('filterByActivityLabel')} />}
                    <div styleName="filter-submit-button">
                        <div styleName="filter-submit-text" onClick={this.applyFilter}>
                            {strings.get('applyFilterLabel')}
                        </div>
                    </div>
                </div>
            )
        }
        return;
    }

    renderLeaderboardFilter = (strings) => {
        const myStyles = getSkin(this.props.skinGuide);

        const allGraphsData = this.props.allGraphsData.get('performance');
        const isCoursesAvailable = allGraphsData.get('leaderboardData').get('isCoursesAvailable');
        const isSectionsAvailable = allGraphsData.get('leaderboardData').get('isSectionsAvailable');
        const isActivitiesAvailable = allGraphsData.get('leaderboardData').get('isActivitiesAvailable');
        
        if(isCoursesAvailable || isSectionsAvailable || isActivitiesAvailable) {
            return(
                <div styleName="filter-click-button" key="leaderboardFilter">
                    <div
                        styleName="filter-title"
                        className={css(myStyles.filterTitleStyle)}
                    >
                    <span styleName="cursor-pointer" onClick={this.showFilterDropdowns}>
                        {strings.get('applyFilterLabel')}
                    </span>
                    </div>
                    {this.renderFilterDropDowns(myStyles, strings)}
                </div>
            );
        }
        return;
    }

    renderLeaderboardComponent = () => {

        const batchId = this.props.user.get('userID') ? this.props.match.params.batchId : 1;
        const userId = this.props.user.get('userID') ? this.props.user.get('userID') : 1;
        if(this.props.apiErrors.get('fetchLeaderboardDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchLeaderboardDataError')}
                retryHandler={()=>{
                    this.props.fetchLeaderboardData(+batchId, +userId);
				}}
            />
        }

        const isComponentLocked = 'locked' in this.props ? this.props.locked : false;
        if (isComponentLocked) {
            return (
                <div styleName="lock-container">
                    <LockComponent 
                        {...this.props}
                        unlockMessageHint={this.props.genericStrings.get('scoreDataInavailableKey')}
                    />
                </div>
            );
        }

        const allGraphsData = this.props.allGraphsData.get('performance');
        if (allGraphsData.get('isLeaderboardFetched') && this.state.leaderboardData) {
            const leaderboardData = this.state.leaderboardData;
            const strings = this.props.allGraphsData.get('performance').get('leaderboardData').get('strings');

            return(
                [this.renderLeaderboardFilter(strings),
                <LeaderboardComponent
                    {...this.props}
                    leaderboardData={leaderboardData}
                    key="leaderboardComponent"
                />]
            )
        }

        return <LoaderComponent />;
    }

    render() {
    
        return (
            <div styleName="container-class">
                <LegendComponent title={this.props.genericStrings.get('leaderboardKey')} {...this.props}/>
                <div styleName="content-container">
                    <div styleName="outer-content">
                        {this.renderLeaderboardComponent()}
                    </div>
                </div>
            </div>
        );
    }
}

export default LeaderboardWithFilter;
