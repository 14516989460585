import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        errorIcon: {
            backgroundColor: '#EB3131',
            color: 'white',
            fontWeight: 'bold'
        },
        title: {
            ...myTypography.overline,
            fontSize: '18px',
            color: '#4E535F',
            fontWeight: '600'
        },
        details: {
            ...myTypography.overline,
            color: '#4E535F'
        },
        oldSafariMessage: {
            ...myTypography.overline,
            color: '#5b5c61'
        }
	});
}

export default getSkin;