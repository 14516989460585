import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import Feedback from 'images/feedback_pattern.png';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		backgroundStyle: {
			backgroundImage: 'linear-gradient(104deg, #5782dc, #2c4bb7)'
		},

		innerBackground: {
			background: `url(${Feedback})`,
			backgroundSize: 'contain',
		},

		titleStyle: {
			...myTypography.h5,
			fontSize: '20px',
			fontWeight: 600,
			color: skin.get('white')
		},

		subTitleStyle: {
			...myTypography.h5,
			fontSize: '18px',
			fontWeight: 600,
			color: hexToRgbA('#FFF', 0.7)
		}
	});
}

export default getSkin;