import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import {get_OS_Browser_Resolution_Errors, checkWhitelistingForCategoryUrls, systemDetails, checkIfTrackingIsBlocked} from 'utils/preRequisiteCheck';
import ResultSection from './resultSection';
import getSkin from './skin';
import applySkin from 'utils/SkinProvider';
import styles from './systemCheck.module.sass';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import AdblockerWarning from './AdblockerWarning';
import Dialog from 'commonComponents/dialog';
import SuccessImage from './SuccessImage.svg';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SystemCheck extends Component {

    constructor(props){
        super(props);
        this.state = {
            percentage: 0,
            noErrors: true,
            componentError: null,
            showConfirmationPopup: false
        };
    }

    componentDidMount(){
        this.checkRequirements();
    }

    getOSBrowserResolutionResult = (userWhitelistingRequirements) => {
        let noErrors = true;
        const osBrowserResolutionResult = get_OS_Browser_Resolution_Errors(userWhitelistingRequirements, this.props.getLabelWithDefaults);          
        if(osBrowserResolutionResult.hasErrors) noErrors = false;
        this.setState({osBrowserResolutionResult: osBrowserResolutionResult, percentage: 10});
        return noErrors;
    }

    getUrlsResult = async (userWhitelistingRequirements, noErrors, context) => {
        const categoryCount = userWhitelistingRequirements.get('categories').size;
        const urlsResult = {};

        const promisesArray = userWhitelistingRequirements.get('categories').entrySeq().map(async (category) => {
            const result = await checkWhitelistingForCategoryUrls(userWhitelistingRequirements, category[1], context, this.props.getLabel);
            if(result.hasErrors) noErrors = false;
            const currentPercentage = this.state.percentage;
            this.setState({
                percentage: currentPercentage + parseInt(70/categoryCount, 10)
            })
            urlsResult[category[0]] = result;
        });

        await Promise.all(promisesArray);

        const miscUrlsResult = await checkWhitelistingForCategoryUrls(userWhitelistingRequirements, null, context, this.props.getLabel);
        if(miscUrlsResult.hasErrors) noErrors = false;

        urlsResult['misc'] = miscUrlsResult;

        this.setState({ urlsResult, percentage: 85 });

        return noErrors;
    }

    getTrackingBlockingResult = async (noErrors) => {
        try{
            const trackingBlockingErrorsResult = await checkIfTrackingIsBlocked(this.props.getLabelsWithDefaults);
            if(trackingBlockingErrorsResult.hasErrors) noErrors = false;

            this.setState({trackingBlockingErrorsResult: trackingBlockingErrorsResult, noErrors , percentage: 90});
        } catch(error){
            console.log('error', error);
        }
        return noErrors;
    }    
    
    checkRequirements = async () => {
        const { userWhitelistingRequirements, context } = this.props;
        try{
            let noErrors = true;

            if(context === 'batch'){
                noErrors = await this.getUrlsResult(userWhitelistingRequirements, noErrors, context);
                this.setState({noErrors, percentage: 100});

            } else {
                noErrors = this.getOSBrowserResolutionResult(userWhitelistingRequirements);       
                noErrors = await this.getUrlsResult(userWhitelistingRequirements, noErrors, context);                
                noErrors = await this.getTrackingBlockingResult(noErrors);    
    
                window.addEventListener("message", this.receiveMessage, false);
    
                window.setTimeout( this.checkIfTPCCheckDone, 180000);
            }            
            
        } catch(error){
            this.setState({componentError: error});
        }        

    }

    checkIfTPCCheckDone = () => {
        if(!this.state.tpcResult){
            this.onTpcCheckFailed();
        }
    }

    onTpcCheckFailed = () => {
        window.removeEventListener('message', this.receiveMessage, false)
        const {getLabel} = this.props;

        const tpcResult = {
            hasErrors: true,
            title: getLabel('tpc_check_warning_heading_label'),
            description: getLabel('tpc_check_warning_description_label'),
            moreDetailsChildren: null,
            moreDetailsProps: null,
            resolution: getLabel('tpc_check_warning_resolution_label')
        }

        console.log('tpcResult', tpcResult);

        this.setState({noErrors: false, tpcResult, percentage: 100});
    }

    receiveMessage = (evt) => {
        const { getLabel } = this.props;
        console.log('evt, state', evt, this.state);
        let hasErrors = false;
        let noErrors = this.state.noErrors;
		if (evt.data === 'MM:3PCunsupported') {
            console.log('TPC not supported');
            hasErrors = true;
            noErrors = false;
        } else if (evt.data === 'MM:3PCsupported') {
            console.log('TPC supported');
        }
        
        window.removeEventListener('message', this.receiveMessage, false)

        const tpcResult = {
            hasErrors: hasErrors,
            title: getLabel('tpc_warning_heading_label'),
            description: getLabel('tpc_warning_description_label'),
            moreDetailsChildren: null,
            moreDetails: null,
            resolution: getLabel('tpc_warning_resolution_label')
        }

        console.log('tpcResult', tpcResult);

        this.setState({noErrors, tpcResult, percentage: 100});

	};

    renderResultSection = (result, key) => {
        if(result && result.hasErrors){
            return <ResultSection {...result} key={key} />
        }
        return null;
    }
    
    renderLogo = () => {
        return <div styleName="logo-container">
            <img styleName="logo" src="https://webassets.knolskape.com/misc/amul_garg/2019/05/28/16/alplus_logo.png" alt="Aktivlearn Plus Logo"/>
        </div>;
    }
    
    componentDidUpdate(){
        if(!this.props.loadedInsideDialog){
            if(this.state.percentage == 100 && this.state.noErrors){
                window.setTimeout(() => {this.markSystemCheckDone()}, 3000);
            }
        }
    }

    componentWillUnmount(){
        if(this.props.loadedInsideDialog){
            if(this.state.percentage == 100){
               this.markSystemCheckDone();
            }
        }
        window.removeEventListener('message', this.receiveMessage, false);
    }

    renderWelcomeBG = () => {

        if(this.props.loadedInsideDialog){
            return null;
        }
        
        const styles = getSkin(this.props.skinGuide);
        return <div className={css(styles.welcomeBg)} styleName="welcome-user-bg">
            <div className={css(styles.welcomeBgImg)}>
            </div>
        </div>
    }

    toggleConfirmationPopup = () => {
        this.setState({showConfirmationPopup: !this.state.showConfirmationPopup});
    }

    renderConfirmationPopup = () => {
        if(this.state.showConfirmationPopup){
            return <Dialog
                key="pre-req-confirmation-dialog"
                dialogMessage={this.props.getLabel('system_check_continue_warning_label')}
                okAction={this.markSystemCheckDone}
                cancelAction={this.toggleConfirmationPopup}
                okButtonText={"Continue"}
                isComponentLoading={false}
            />;
        }
        return null; 
    }

    getListOfLinkGroupsChecked = () => {
        try{
            return this.props.userWhitelistingRequirements.get('linkGroups').keySeq().toArray();
        }catch(error){
            return [];
        }
    }

    formPayload = () => {
        let errors = {};
        const keysToUse = ['osBrowserResolutionResult', 'tpcResult', 'trackingBlockingErrorsResult'];
        
        keysToUse.forEach((keyToAdd) => {
            let details = this.state[keyToAdd];
            if(details){
                let { hasErrors, title, description, moreDetails, resolution } = details;//todo add loop for urls result
                errors[keyToAdd] = { hasErrors, title, description, moreDetails, resolution };
            }
        });

        errors['urlsResult'] = {};

        for(const [key, value] of Object.entries(this.state.urlsResult)){
            let details = value;
            let { hasErrors, title, description, moreDetails, resolution } = details;//todo add loop for urls result
            errors['urlsResult'][key] = { hasErrors, title, description, moreDetails, resolution };
        }

        const payload = {
            systemCheckResult: {
                ...systemDetails(),
                noErrors: this.state.noErrors,
                errors: errors,
                linkGroupsChecked: this.getListOfLinkGroupsChecked()
            }
        }

        return payload;
    }

    markSystemCheckDone = () => {
        const payload = this.formPayload();
        this.props.markSystemCheckDone(payload);
    }

    renderUrlResults = (urlsResult) => {
        return Object.keys(urlsResult).map((resultKey) => {
            return this.renderResultSection(urlsResult[resultKey], resultKey);
        });
    }

    render() {

        if(this.state.componentError){
            throw this.state.componentError;
        }

        const styles = getSkin(this.props.skinGuide);

        if(this.state.percentage !== 100){
            return <div styleName="main-container" className={css(styles.mainContainer)}>
                 {this.renderWelcomeBG()}
                <div styleName="loading-container"> 
                    {this.renderLogo()}
                    <div styleName="progress-container">
                        <div className={css(styles.outerBar)} styleName="outer-bar">
                            <div style={{
                                width: `${this.state.percentage}%`,
                            }}
                                className={css(styles.progressBarActive)}
                                styleName="inner-bar"></div>
                        </div>
                    </div>
                    <div styleName="progress-text" className={css(styles.progressText)}>
                        <span styleName="progress-wait-text"> {this.props.getLabel('checking_system_message_label')} </span>
                        <span> {`${this.state.percentage}%`} </span>
                    </div>
                </div>
                {this.state.percentage === 90 && <iframe src={`https://prereq-checker.knolskape.com/tpc/start.html?ts=${Date.now()}`}  style={{display:'none'}} title="TPC iframe"></iframe> }
            </div>
        }

        if(this.state.noErrors){
            return <div styleName="main-container" className={css(styles.mainContainer)}>
                 {this.renderWelcomeBG()}
                <div styleName="success-main-container"> 
                    <img src={SuccessImage} styleName="success-image" alt="Successful!"/>
                    {this.renderLogo()}
                    <div styleName="success-container">
                        <div className={css(styles.successMessage)}>{this.props.getLabel('system_check_complete_message_label')}</div>
                    </div>
                </div>
            </div>
        }

        return (
            <div styleName="main-container" className={css(styles.mainContainer)}>
                {this.renderWelcomeBG()}
                <div styleName="error-results-container">
                    {this.renderLogo()}
                    {this.renderUrlResults(this.state.urlsResult)}
                    {this.renderResultSection(this.state.osBrowserResolutionResult, 'os')}
                    {this.renderResultSection(this.state.tpcResult, 'tpc')}
                    {this.renderResultSection(this.state.trackingBlockingErrorsResult, 'trackingBlocking')}

                    <span className={css(styles.progressText)}>
                        <AdblockerWarning/>
                    </span>
                        
                    {this.props.loadedInsideDialog ? null : <div styleName="actions-cnt">
                        <PrimaryButton text="Continue" clickFunction={this.toggleConfirmationPopup}/>
                    </div>}

                    {this.renderConfirmationPopup()}
                </div>
            </div>
        )
    }
}

export default SystemCheck;
