import { Legend } from '@vx/legend';

import PropTypes from 'prop-types';
import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './graphLegendComponent.module.sass';

@CSSModules(styles, { allowMultiple: true })

class GraphLegendComponent extends React.Component {

    render() {
        const {
            style,
            shape,
            size,
            componentName
        } = this.props;
        const labelFormat = this.props.labelFormat ? this.props.labelFormat : (data) => data;
        return (
            <div className="graph-legend-container" styleName="text-align graph-legend-container">
                <Legend
                    direction={style.direction}
                    itemDirection={style.itemDirection}
                    style={style}
                    labelMargin={style.labelMargin}
                    shapeMargin={style.shapeMargin}
                    itemMargin={style.itemMargin}
                    scale={size}
                    shapeWidth={style.shapeWidth}
                    shapeHeight={style.shapeHeight}
                    fill={({ index }) => `url(#legend-${index}-${componentName})`}
                    shape=
                    {props => {
                        return (
                            <svg width={props.width} height={props.height}>
                                {!React.isValidElement(
                                    shape(props.label.datum),
                                ) &&
                                    React.createElement(
                                        shape(props.label.datum),
                                        {
                                            ...props,
                                        },
                                    )}
                                {React.isValidElement(
                                    shape(props.label.datum),
                                ) &&
                                    React.cloneElement(shape(props.label.datum))}
                            </svg>
                        );
                    }}
                    labelFormat={labelFormat}
                />
            </div>
        )
    }
}

GraphLegendComponent.propTypes = {
    style: PropTypes.object,
    shape: PropTypes.func,
    size: PropTypes.func,
    componentName: PropTypes.string
};

GraphLegendComponent.defaultProps = {

};

export default GraphLegendComponent;