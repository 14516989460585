import React, { Component } from 'react';
import styles from './analytics.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import applyLabel from 'utils/LabelProvider';
import OverlappingBarsWidget from 'commonComponents/analytics/widgets/overlappingBarsWidget';
import AreaWithLineWidget from 'commonComponents/analytics/widgets/areaWithLineWidget/AreaWithLineWidget';
import RankWidget from 'commonComponents/analytics/widgets/rankWidget/';
import ActiveTimeWidget from 'commonComponents/analytics/widgets/activeTimeWidget';
import ActiveDayWidget from 'commonComponents/analytics/widgets/activeDayWidget';
import InverselyProportionalWidget from 'commonComponents/analytics/widgets/inverselyProportionalWidget/InverselyProportionalWidget';
import { filterObjectFromArray } from 'utils/objectUtils';
import AnalyticsLoader from 'commonComponents/loaders/analyticsLoader';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Analytics extends Component {

    updateActivityRoute = () => {
        const batchId = this.props.batchDetails.get('id');
        const route = routes.get('BATCH_ACTIVITY');
        const params = [{ 'BATCH_ID': batchId }];
        updateRoute({ route, params });
    }

    updatePerformanceRoute = () => {
        const batchId = this.props.batchDetails.get('id');
        const route = routes.get('BATCH_PERFORMANCE');
        const params = [{ 'BATCH_ID': batchId }];
        updateRoute({ route, params });
    }

    renderActivityWidget = (widgetData) => {
        switch (true) {
            case widgetData.get('graphType') == 'Text' && widgetData.get('widgetName') == 'MostActiveDay':
                return <ActiveDayWidget widgetArray={widgetData} {...this.props} />;
            case widgetData.get('graphType') == 'Text' && widgetData.get('widgetName') == 'MostActiveTime':
                return <ActiveTimeWidget widgetArray={widgetData} {...this.props} />;
            case widgetData.get('graphType') == 'Area':
                return <AreaWithLineWidget widgetArray={widgetData} {...this.props} graphData={widgetData.get('widgetData').toJS()} />
            case widgetData.get('graphType') == 'Bar':
                return <OverlappingBarsWidget widgetArray={widgetData} {...this.props} graphData={widgetData.get('widgetData').toJS()} />
        }
    }

    renderPerformanceWidget = (widgetData) => {
        switch (true) {
            case widgetData.get('graphType') == 'Text' && widgetData.get('widgetName') == 'Rank':
                return <RankWidget widgetArray={widgetData} {...this.props} />;
            case widgetData.get('graphType') == 'Text' && widgetData.get('widgetName') == 'Score':
                return <RankWidget widgetArray={widgetData} {...this.props} />;
            case widgetData.get('graphType') == 'Bar':
                return <OverlappingBarsWidget widgetArray={widgetData} {...this.props} graphData={widgetData.get('widgetData').toJS()} />
            case widgetData.get('graphType') == 'InverseArea':
                return <InverselyProportionalWidget widgetArray={widgetData} {...this.props} graphData={widgetData.get('widgetData').toJS()} />
        }
    }

    renderWidgets = (shouldShowSubTitle) => {
        const { widgetData } = this.props;
        // const performanceWidgetCount = filterObjectFromArray(widgetData, 'type', 'PERFORMANCE').size;
        // const shouldShowSubTitle = performanceWidgetCount == 0 ? false : true;
        return widgetData.sortBy((val) => val.get('type')).map((data, index) => {
            const renderWidget = data.get('type') === 'ACTIVITY' ? this.renderActivityWidget : this.renderPerformanceWidget;

            // if (data.get('graphType') == 'Area' && data.get('widgetData').get('data').size == 1) {
            //     return;
            // }

            return (
                <div styleName={`single-container child-${index + 1}`}>
                    <div>
                        {shouldShowSubTitle ? this.renderTitle(data.get('type')) : null}
                        {renderWidget(data)}
                    </div>
                </div>
            );
        });
    }

    renderTitle = (titleType) => {
        const { getLabel, skinGuide } = this.props;
        const myStyles = getSkin(skinGuide);
        const label = titleType === 'ACTIVITY' ? 'activity_label' : 'performance_label';
        const clickHandler = titleType === 'ACTIVITY' ? this.updateActivityRoute : this.updatePerformanceRoute;
        const viewLabel = titleType === 'ACTIVITY' ? 'view_activity_label' : 'view_performance_label';
        return (
            <div styleName="title-container">
                <div className={css(myStyles.title)}>{getLabel(label)}</div>
                <div className={css(myStyles.viewMoreButton)} onClick={clickHandler}>
                    {getLabel(viewLabel)}
                </div>
            </div>
        );
    }

    renderTitleFromOutside = (titleType, count) => {
        const { getLabel, skinGuide } = this.props;
        const myStyles = getSkin(skinGuide);
        const label = titleType === 'ACTIVITY' ? 'activity_label' : 'performance_label';
        const clickHandler = titleType === 'ACTIVITY' ? this.updateActivityRoute : this.updatePerformanceRoute;
        const viewLabel = titleType === 'ACTIVITY' ? 'view_activity_label' : 'view_performance_label';
        return (
            <div style={{ width: '100%' }}>
                <div styleName={count === 1 ? "title-container-half" : "title-container"}>
                    <div className={css(myStyles.title)}>{getLabel(label)}</div>
                    <div className={css(myStyles.viewMoreButton)} onClick={clickHandler}>
                        {getLabel(viewLabel)}
                    </div>
                </div>
            </div>
        );
    }

    renderTitleBarForWidgets = (activityWidgetCount, performanceWidgetCount) => {
        const size = performanceWidgetCount + activityWidgetCount;
        if (activityWidgetCount === 0) {
            return this.renderTitleFromOutside('PERFORMANCE', size);
        } else if (performanceWidgetCount === 0) {
            return this.renderTitleFromOutside('ACTIVITY', size);
        } else {
            return null;
        }
    }

    getCount = (widgets, type) => {
        var count = 0;
        widgets.map((widget) => {
            if (widget.get('type') === type) {
                count++;
            }
        });
        return count;
    }

    renderLoader = () => {
        return (
            <div styleName="content-container">
                <div styleName="boxes-container loader-content">
                    <AnalyticsLoader />
                </div>
            </div>
        );
    }

    render() {
        const widgetData = this.props.widgetData;
        const performanceWidgetCount = this.getCount(widgetData, 'PERFORMANCE');
        const activityWidgetCount = this.getCount(widgetData, 'ACTIVITY');
        const shouldShowSubTitle = performanceWidgetCount !== 0 && activityWidgetCount !== 0 ? true : false;

        if (this.props.apiErrors.get('fetchWidgetAnalyticsError')) {
            return <APIErrorBoundary
                key={this.props.match.params.moduleItemId}
                apiError={this.props.apiErrors.get('fetchWidgetAnalyticsError')}
                retryHandler={this.props.retryFetchWidgetAnalyticsData}
            />
        }

        if (!this.props.isWidgetDataFetched) {
            return this.renderLoader();
        }

        if (this.props.widgetData.size === 0) {
            return;
        }
        return (
            <div styleName="content-container">
                <div styleName="boxes-container">
                    <div styleName="single-row-container">
                        {this.renderTitleBarForWidgets(activityWidgetCount, performanceWidgetCount)}
                        {this.renderWidgets(shouldShowSubTitle)}
                    </div>
                </div>
            </div>
        );
    }
}

export default Analytics;
