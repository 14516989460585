import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        questionText: {
            ...myTypography.overline,
            opacity: 0.8
        },

        questionDesc: {
            ...myTypography.overline,
            opacity: 0.5
        }
    });
}

export default getSkin;