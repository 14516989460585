import React, { Component } from 'react';
import styles from './externalUrlsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ExternalUrlsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="icon-cnt">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<g fill="none" fillRule="evenodd" stroke="#828593" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".875">
						<path d="M12.693 4.814v-3.5a.875.875 0 0 0-.875-.875h-10.5a.875.875 0 0 0-.875.875v9.625c0 .483.392.875.875.875h3.5M.443 3.064h12.25M10.071 11.306c.057.411-.08.825-.373 1.12l-.753.754a1.41 1.41 0 0 1-1.992-1.992l.753-.753a1.328 1.328 0 0 1 1.096-.376M11.31 10.067c.41.057.825-.08 1.12-.373l.753-.753a1.41 1.41 0 0 0-1.991-1.992l-.75.754a1.325 1.325 0 0 0-.376 1.095M8.756 11.377l2.625-2.625"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default ExternalUrlsIcon;
