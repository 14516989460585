import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import styles from './certificate.module.sass';
import getSkin from './skin';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import reports from 'constants/reports';

import CircularLoader from 'svgComponents/circularLoader';
import ReportDownloadContainer from 'containers/ReportDownloadContainer';
import LinkedInShareCertificateContainer from 'containers/LinkedInShareCertificateContainer';
import MandatoryFeedback from 'components/feedback/mandatoryFeedback';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Certificate extends Component {

	renderLoader = () => {
		return <div style={{ height: '43px', width: '43px', margin: 'auto' }}>
			<CircularLoader />
		</div>
	}

	renderDownloadButtons = () => {
		const { match, batchDetails, getLabelWithDefaults } = this.props;
		const showLinkedinShareButton = batchDetails.getIn(['journey', 'settings', 'showLinkedInShare']) === 'true';
		return <div styleName="action-btn-cnt">
			<ReportDownloadContainer
				reportType={reports.get('certificate')}
				reportOutputType="pdf"
				metadata={{ batchId: +match.params.batchId }}
				downloadButtonText={this.props.getLabelWithDefaults('download_certificate_label', 'Download Certificate')}
			/>
			{showLinkedinShareButton && <LinkedInShareCertificateContainer
				reportType={reports.get('certificate')}
				reportOutputType="pdf"
				metadata={{ batchId: +match.params.batchId }}
				downloadButtonText={getLabelWithDefaults('download_certificate_label', 'Download Certificate')}
			/>}
		</div>;
	}

	render() {

		const myStyles = getSkin(this.props.skinGuide);
		const { getLabel, batchDetails, match, batchesFeedbackStatusFlags, updateFeedbackStatus, isFeedbackMandatory } = this.props;
		let componentToRender = this.renderLoader();
		let congratsText = getLabel('certificate_congrats_message');

		if(!isFeedbackMandatory){
			componentToRender = this.renderDownloadButtons();
		} else if(!batchesFeedbackStatusFlags.get('loading')){
			const feedbackComplete = batchesFeedbackStatusFlags.getIn(['statusFlags','completedBatchIds']).includes(+match.params.batchId);
			if(feedbackComplete){
				componentToRender = this.renderDownloadButtons();
			} else {
				componentToRender = <MandatoryFeedback batchDetails={batchDetails} match={match} updateFeedbackStatus={updateFeedbackStatus}/>;
				congratsText = getLabel('certificate_congrats_message_feedback_incomplete');
			}
		}

		return (
			<div styleName="congratulations-message-container">
				<div styleName="congratulations-message-inner">
					<div className={css(myStyles.innerContent)} styleName="inner-content">
						<div className={css(myStyles.innerCeleb)} styleName="inner-celebrations"></div>
						<div styleName="inner-main-content">
							<div styleName="certificate-image" className={css(myStyles.certificateImage)}></div>
							<div styleName="certificate-content-box">
								<div styleName="certificate-content">
									<div styleName="title-content" className={css(myStyles.titleStyle)}>{getLabel('certificate_heading_label')}</div>
									<div styleName="title-desc" className={css(myStyles.titleDesc)}>{congratsText}</div>								
									{ componentToRender }
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Certificate;