/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './SkillsCoveredLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class SkillsCoveredLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<ContentLoader
				height={400}
				width={310}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '100%' }}
			>
				<circle cx="153" cy="66" r="32" />
				<rect x="71" y="186" rx="0" ry="0" width="167" height="20" />
				<rect x="51" y="246" rx="0" ry="0" width="207" height="12" />
				<rect x="51" y="274" rx="0" ry="0" width="207" height="12" />
				<rect x="51" y="302" rx="0" ry="0" width="207" height="12" />
				<rect x="51" y="330" rx="0" ry="0" width="207" height="12" />
				<rect x="71" y="358" rx="0" ry="0" width="167" height="12" />
			</ContentLoader>
		);
	}
}

export default SkillsCoveredLoader;
