import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		content: {
            background: '#2C4BB7',
			borderRadius: '4px',
			boxShadow: 'rgb(0 0 0 / 20%) 0px 20px 25px 0px, rgb(0 0 0 / 4%) 0px 10px 10px 0px'
        },
		activeSessionText: {
			...myTypography.overline,
			fontSize: '17px',
			fontWeight: 700,
			color: skinHex.get('white')
		},
		webSessionLabel: {
			...myTypography.labelSmall,
			color: skinHex.get('white')
		},
		sessionTitle: {
			...myTypography.h5,
			color: skinHex.get('white')
		},
		otherUpcomingSessions: {
			...myTypography.labelSmall,
			fontWeight: '600',
			color: skinHex.get('white'),
			textDecoration: 'underline'
		}
	});
}

export default getSkin;