import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const colors = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		rateContentLabel: {
			...myTypography.button,
			fontWeight: 'normal',
			color: 'rgba(9, 16, 39, 0.425)',
			'@media only screen and (max-width: 480px)': {
				fontSize: '12px'
			}
		}
	});
}

export default getSkin;