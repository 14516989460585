import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './dropDown.module.sass';
import InfoIcon from 'images/analytics/info_icon.svg'

import { css } from 'aphrodite/no-important';
import getSkin from './skin';

@CSSModules(styles, { allowMultiple: true })

class DropDown extends React.Component {

    constructor(props){
        super(props);
    }

    handleOnClick = (event) => {
        this.props.handleOnClick(event.target.value);
    }

    render(){
        const { list, showAllOption, selectedId } = this.props
        const allLabel = 'allLabel' in this.props ? this.props.allLabel : 'All';
        return (        
            <select onChange={this.handleOnClick} styleName="drop-down" defaultValue={selectedId ? selectedId : -1}>
                { showAllOption &&
                    <option styleName="drop-down-option" key={-1} value={-1}>{allLabel}</option>
                }
                {list.map((item) => (
                    <option styleName="drop-down-option" key={item.id} value={item.id}>{item.itemName}</option>
                ))}
            </select>     
        )
    }
}


export default DropDown;