import React, { Component } from 'react';
import styles from './assignmentsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class AssignmentsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="assingment-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 15 16">
					<g fill="none" fillRule="evenodd">
						<path stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" d="M3.625 8.708h5.542M3.625 4.208L6.5 4.21M3.625 6.5h4.083M3.625 11h2.333M5.958 14.708H1.875a.583.583 0 0 1-.583-.583V1.875c0-.322.26-.583.583-.583h7.754c.155 0 .303.061.413.17l2.162 2.163c.11.11.171.258.171.412v1.921"/>
						<path fill="#7E818F" d="M10.158 14.008l-2.45.7.7-2.45 4.188-4.187a1.237 1.237 0 0 1 1.75 1.75l-4.188 4.187z"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default AssignmentsIcon;
