import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import styles from './quizScore.module.sass';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import ScoreComponent from 'commonComponents/scoreComponent';
import { hexToRgbA } from 'utils/styleUtil';
import Select from 'react-select'
import QuizScorePieChart from '../quizScorePieChart';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class QuizScore extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentWillUnmount() {
        this.setState({
            selectedAttempt: null
        });
    }


    getLatestAttemptsScoreElements = () => {
        const styles = getSkin(this.props.skinGuide);
        let { quizResponseData, quizData, getLabelWithDefaults } = this.props;
        let subs = quizResponseData.get('latestAttemptsScores');
        let content = [];
        if (subs && subs.size > 0) {
            for (let i = 0; i < 3 && i < subs.size; i++) {
                content.push(
                    <div key={`attempt_${subs.get(i).get('attempt')}_score`}>
                        <div className={css(styles.attemptNumberLabel)}>
                            {
                                getLabelWithDefaults(
                                    'quiz_attempt_number_label',
                                    `Attempt ${subs.get(i).get('attempt')}`,
                                    { attempt_number: subs.get(i).get('attempt') }
                                )
                            }
                        </div>
                        <div style={{ transform: 'scale(0.75)' }}>
                            <ScoreComponent
                                score={subs.get(i).get('score')}
                                scoreColor={this.props.skinGuide.get('skinHex').get('white')}
                                totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.5)}
                                circleStrokeColor={this.props.skinGuide.get('skinHex').get('white')}
                                fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.2)}
                                maxPoints={quizData.get('maxScore')}
                            />
                        </div>
                    </div>
                )
            }
        }
        return content;
    }

    getLastAttemptsLabel = () => {
        let { quizResponseData, getLabel } = this.props;
        let subs = quizResponseData.get('attemptsAlreadyTaken');
        if (subs && subs.size > 1) return getLabel('quiz_your_last_attempts_label').replace('NUMBER_OF_ATTEMPTS', subs.size >= 3 ? 3 : subs.size);
        return getLabel('quiz_your_last_attempt_label')
    }

    handleAttemptChange = (attempt) => {
        this.setState({
            selectedAttempt: attempt
        })
    }

    showQuizAttemptAnalytics = () => {
        const styles = getSkin(this.props.skinGuide);
        const { submissions } = this.props;
        const subs = submissions.size;
        let { selectedAttempt } = this.state;
        let attemptOptions = [];
        selectedAttempt = selectedAttempt || { value: subs, label: `Attempt ${subs}` }


        for (let i = subs; i >= 1; i--) {
            attemptOptions.push({
                value: i,
                label: this.props.getLabelWithDefaults(
                    'quiz_attempt_number_label',
                    `Attempt ${i}`,
                    { attempt_number: i }
                )
            })
        }
        if (subs) {
            return (
                <div styleName="quiz-review-cnt">
                    {
                        subs === 1 ?
                            <span className={css(styles.resultsLabel)}>
                                {
                                    this.props.getLabelWithDefaults(
                                        'quiz_score_results_label',
                                        'RESULTS'
                                    )
                                }
                            </span>
                            :
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <span className={css(styles.resultsLabel)}>
                                    {
                                        this.props.getLabelWithDefaults(
                                            'quiz_result_for_label',
                                            'RESULT FOR'
                                        )
                                    }
                                </span>
                                <div style={{ minWidth: '250px', marginLeft: '20px' }}>
                                    <Select
                                        value={selectedAttempt}
                                        options={attemptOptions}
                                        onChange={this.handleAttemptChange}
                                        isSearchable={false}
                                    />
                                </div>
                            </div>
                    }
                    {
                        this.showAnalytics(selectedAttempt)
                    }
                </div>
            )
        }
    }

    showAnalytics = (selectedAttempt) => {
        const { submissions } = this.props;
        const { value: attemptNo } = selectedAttempt;
        const subsSize = submissions.size;
        const subResult = submissions.get(subsSize - attemptNo);
        const correctAnsweredCount = subResult.get('correctAnsweredCount');
        const wrongAnsweredCount = subResult.get('wrongAnsweredCount');
        const unansweredCount = subResult.get('unansweredCount');
        return (
            <div styleName={`quiz-analytics-charts-cnt`}>
                <div styleName="right-wrong-pie-chart-cnt">
                    <QuizScorePieChart correctAnsweredCount={correctAnsweredCount}
                        wrongAnsweredCount={wrongAnsweredCount}
                        unansweredCount={unansweredCount}
                    />
                </div>
            </div>
        )
    }

    render() {
        const styles = getSkin(this.props.skinGuide);
        let { quizData, quizResponseData, getLabelWithDefaults } = this.props;
        let allowedAttempts = quizData.get('maxScore');
        let keptScoreLabel = getLabelWithDefaults('quiz_kept_score_highest', 'Highest Score');


        return (
            <div styleName="quiz-score-analytics-cnt">
                <div className={css(styles.quizScoreCnt)} styleName="quiz-score-cnt">
                    <div className={css(styles.bestScoreCnt)} styleName="quiz-best-score-cnt" style={allowedAttempts === 1 ? { width: '100%', border: 'none' } : {}}>
                        <ScoreComponent
                            score={quizResponseData.get('userMaxScore')}
                            scoreColor={this.props.skinGuide.get('skinHex').get('white')}
                            totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.5)}
                            circleStrokeColor={this.props.skinGuide.get('skinHex').get('white')}
                            fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.2)}
                            maxPoints={quizData.get('maxScore')}
                        />
                        <div className={css(styles.bestScoreLabel)} styleName="best-score-label" style={{ color: 'white' }}>{allowedAttempts === 1 ? 'Your Score' : keptScoreLabel}</div>
                    </div>
                    {
                        (allowedAttempts != 1 && allowedAttempts != 0) &&
                        <div styleName="last-attempts-score-cnt">
                            <div style={{ color: 'white' }}>
                                {this.getLastAttemptsLabel()}
                            </div>
                            <div styleName="last-attempts-scores">
                                {this.getLatestAttemptsScoreElements()}
                            </div>
                        </div>
                    }
                </div>


                {this.showQuizAttemptAnalytics()}
            </div>
        )
    }
}

export default QuizScore;