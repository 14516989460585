const getFormDataForImage = (payload) => {
    let data = {};
    data = new FormData();
    data.append("image", payload);
    return data;
}

const getFormDataForCSV = (payload) => {
   let data = {};
    data = new FormData();
    data.append("users", payload);
    return data;
}

const escapeScriptTags = (str) => {
  return str.replace(/<script/gi, "&lt;script").replace(/<\/script>/gi, "&lt;/script&gt;");
}

export {
    getFormDataForImage,
    getFormDataForCSV,
    escapeScriptTags
}