import store from 'store/store';

const getUserId = () => {
   return store.getState().getIn(['user', 'userId']);
}

const getUserName = () => {
   const firstName = store.getState().getIn(['user', 'firstName']);
   const lastName = store.getState().getIn(['user', 'lastName']);
   return firstName || lastName;
}

const getFullName = () => {
   const firstName = store.getState().getIn(['user', 'firstName']);
   const lastName = store.getState().getIn(['user', 'lastName']);
   return { firstName, lastName };
}

const getUserEmail = () => {
   const email = store.getState().getIn(["user", "email"]);
   return email;
}

export { getUserId, getUserName, getUserEmail, getFullName };