import actionConstants from 'constants/actions';
import Immutable from 'immutable';


const initialState = Immutable.fromJS({
    pageData: {}
});

const page = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConstants.get('SET_PAGE_DATA'):
            return state.set(
                'pageData',
                Immutable.fromJS(action.pageData)
            );
        default:
            return state;
    }
};

export default page;