import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		overallHeading: {
			...myTypography.h5,
			fontWeight: 600,
			color: skin.get('contentColor')
		},
		overallContent: {
			borderRadius: '4px',
			boxShadow: `0 2px 4px 0 ${hexToRgbA(skin.get('black'), 0.1)}, 0 14px 15px 0 ${hexToRgbA(skin.get('black'), 0.04)}`,
			backgroundImage: `linear-gradient(261deg, #3d486f, ${skin.get('greyColor2')})`,
			border: `solid 1px rgba(151, 151, 151, 0.02)`
		},
		descTitle: {
			color: skin.get('white'),
			...myTypography.h4
		},
		descSubTitle: {
			color: skin.get('white'),
			...myTypography.overline
		}
	});
}

export default getSkin;