import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		individualPhaseItem: {
			backgroundColor: skin.get('white'),

		},
		title: {
			...myTypography.h6
		},
		desc: {
			...myTypography.caption,
			color: skin.get('greyColor4')
		},
		completed: {
			color: '#9a9eaf'
		},
		pending: {
			color: '#797e94'
		},
		aktiv: {
			color: skin.get('secondaryColor')
		},
		completedBackground: {
			backgroundColor: hexToRgbA(skin.get('darkSkyBlue'), 0.5)
		},
		pendingBackground: {
			backgroundColor: skin.get('greyColor4')
		},
		aktivBackground: {
			backgroundColor: skin.get('secondaryColor')
		},
		outerBar: {
			backgroundColor: skin.get('greyColor5')
		},
		bottomText: {
			...myTypography.button,
			color: hexToRgbA(skin.get('greyColor3'), 0.8)
		},
		completionStatus: {
			...myTypography.button,
			lineHeight: 'normal',
			background: skin.get('appleGreen'),
			color: skin.get('white')
		},
		feedbackLinkText: {
			...myTypography.overline,
			fontWeight: 600
		}
	});
}

export default getSkin;