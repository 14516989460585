import actionConstants from 'constants/actions';

export const setModuleItem = (payload) => ({
    type: actionConstants.get('SET_MODULE_ITEM'),
    ...payload
})

export const setModuleItemFromCache = (payload) => ({
    type: actionConstants.get('SET_MODULE_ITEM_FROM_CACHE'),
    ...payload
})

export const setModuleItemContentDetails = (moduleItemContentDetails) => ({
    type: actionConstants.get('SET_MODULE_ITEM_CONTENT_DETAILS'),
    moduleItemContentDetails
});

export const pushToCache = (moduleItem) => ({
    type: actionConstants.get('PUSH_LOADED_MODULE_ITEM_TO_CACHE'),
    moduleItem
})

export const setProductLaunch = (productLaunch) => ({
    type: actionConstants.get('SET_PRODUCT_LAUNCH'),
    productLaunch
});

export const setModuleItemAvailabilityStatus = (status) => {
    return {
        type: actionConstants.get('SET_MODULE_ITEM_AVAILABILITY'),
        status
    }
};

export const unsetProductLaunch = () => ({
    type: actionConstants.get('UNSET_PRODUCT_LAUNCH')
});

export const setAssignmentSubmitted = (newSubmission) => ({
    type: actionConstants.get('SET_ASSIGNMENT_SUBMITTED'),
    newSubmission
})

export const setNextActivityItem = (nextActivityItem) => ({
    type: actionConstants.get('SET_NEXT_ACTIVITY_ITEM'),
    nextActivityItem
});


export const setModuleItemCompletionStatus = (status) => ({
    type: actionConstants.get('SET_MODULE_ITEM_COMPLETION_STATUS'),
    status
});

export const setModuleItemFileProgress = (apiResponse) => ({
    type: actionConstants.get('SET_MODULE_ITEM_FILE_PROGRESS_IN_CACHE'),
    moduleItemId: apiResponse.user_media_file_status.context_module_item_id,
    progress: apiResponse.user_media_file_status.progress,
    is_completed: apiResponse.user_media_file_status.is_completed
})

export const setNextModuleItemLockStatus = (status) => ({
    type: actionConstants.get('SET_NEXT_MODULE_LOCK_STATUS'),
    status
});

export const pushModuleItemToCache = (moduleItem, contentDetails) => ({
    type: actionConstants.get('PUSH_LOADED_MODULE_ITEM_TO_CACHE'),
    moduleItem,
    contentDetails  
});

export const addUploadedFileToList = (file) => ({
    type: actionConstants.get('ADD_UPLOADED_FILE_TO_LIST'),
    file
});

export const unsetModuleItem = () => ({
    type: actionConstants.get('UNSET_MODULE_ITEM')
});

export const unsetUploadedFiles = () => ({
    type: actionConstants.get('UNSET_UPLOADED_FILES')
})

export const setAssignmentSubmittingStatus = (status) => ({
    type: actionConstants.get('SET_ASSIGNMENT_SUBMITTING'),
    status
});

export const setFileUploadInProgress = (status) => ({
    type: actionConstants.get('SET_FILE_UPLOAD_IN_PROGRESS'),
    status
})

export const removeFile = (id) => ({
    type: actionConstants.get('REMOVE_FILE'),
    id
});