import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		label: {
			...myTypography.overline,
			fontWeight: 'normal',
			color: 'rgba(9, 16, 39, 0.85)'
		}
	});
}

export default getSkin;