import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setSelectedJourneyInstanceDetails } from 'actions/journeys/actionCreators';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import { appendQueryParameters } from 'utils/utilFunctions';

class FetchJourneyInstanceDetails extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	prepareUrl = (apiInfoObject = {}) => appendQueryParameters(
		urls.get('PLATFORM').get('GET_JOURNEY_INSTANCE_DETAILS').replace('JOURNEY_INSTANCE_ID', apiInfoObject.journeyInstanceId),
		apiInfoObject.queryParameters
	);

	successCall = (response) => {
		this.dispatch(setSelectedJourneyInstanceDetails(response));
		return response;
	}

	onError = () => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, something went wrong. Please refresh the page!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		sendUserToInvalidPage();
	}
}

export default new FetchJourneyInstanceDetails();