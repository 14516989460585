import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { appendQueryParameters } from 'utils/utilFunctions';
import { filterObjectFromArray } from 'utils/objectUtils';

class SetItemWorkflowStatus extends NetPackWrapper {

	constructor() {
		super("PUT");
	}

	prepareUrl = (apiInfoObject = {}) => appendQueryParameters(
		urls.get('PLATFORM').get('SET_ITEM_WORKFLOW_STATUS').replace(
			'ITEM_ID',
			apiInfoObject.itemId
		),
		apiInfoObject.queryParameters
	)

	shouldAjaxCall = (apiInfoObject) => {
		const userItemsList = this.getState().get('items').get('userItemsList');
		const currentUserItem = filterObjectFromArray(userItemsList, 'id', apiInfoObject.itemId);
		const currentItemStatus = currentUserItem.get('workflowState');
		if (currentItemStatus && currentItemStatus != apiInfoObject.body.workflowState) {
			return true;
		}
		return false;
	}

	successCall = (response) => {
		return response;
	}

}

export default new SetItemWorkflowStatus();
