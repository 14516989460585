import React from 'react';
import { connect } from 'react-redux';
import TakeQuiz from 'components/moduleItems/takeQuiz';
import GenericErrorBoundary from 'components/errorBoundaries';
import {
	getQuizSubmissions,
	getQuizInitialData,
	completeQuiz,
	setQuizAnswers,
	flagQuestion,
	unflagQuestion
} from 'actions/quiz/apiActions';
import {
	setQuizAttemptsExhaustedWhileTakingQuiz
} from 'actions/quiz/actionCreators';
import { setQuizQuestionAnswer } from 'actions/quiz/actionCreators';
import Immutable from 'immutable';
import { fetchModuleItem } from 'actions/moduleItems/apiActions';

import {
	startTracking,
	endTracking
} from 'utils/mixpanelTracking';

class TakeQuizContainer extends React.Component {
	componentDidMount() {
		this.props.getQuizInitialData(this.props.match.params);
		startTracking(true, this.props.match.params, 'ModuleItem');
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.match.params.moduleItemId != nextProps.match.params.moduleItemId) {
			endTracking();
			startTracking(true, nextProps.match.params, 'ModuleItem');
		}
	}

	componentWillUnmount() {
		this.props.setQuizAttemptsExhaustedWhileTakingQuiz(false)
		endTracking();
	}

	render() {
		return (
			<GenericErrorBoundary>
				<TakeQuiz {...this.props} />
			</GenericErrorBoundary>
		);
	}
}

TakeQuizContainer.propTypes = {};

TakeQuizContainer.defaultProps = {};


const mapStateToProps = (state) => {
	return {
		moduleItem: Immutable.fromJS(state.get('moduleItem')),
		quiz: Immutable.fromJS(state.get('quiz')),
		quizAnswerSelected: Immutable.fromJS(state.get('quizAnswerSelected'))
	}
};

const mapDispatchToProps = (dispatch) => ({
	getQuizSubmissions: () => {
		dispatch(getQuizSubmissions());
	},
	fetchModuleItem: (params) => {
		dispatch(fetchModuleItem(params));
	},
	getQuizInitialData: (params) => {
		dispatch(getQuizInitialData(params));
	},
	setQuizQuestionAnswer: (payload) => {
		dispatch(setQuizQuestionAnswer(payload));
	},
	completeQuiz: (payload) => {
		dispatch(completeQuiz(payload));
	},
	setQuizAnswers: (payload) => {
		setQuizAnswers(payload);
	},
	flagQuestion: (payload) => {
		dispatch(flagQuestion(payload));
	},
	unflagQuestion: (payload) => {
		dispatch(unflagQuestion(payload));
	},
	setQuizAttemptsExhaustedWhileTakingQuiz: (flag) => {
		dispatch(setQuizAttemptsExhaustedWhileTakingQuiz(flag))
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(TakeQuizContainer);
