import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		individualPhaseItem: {
			backgroundColor: skin.get('white'),

		},
		title: {
			...myTypography.h6
		},
		desc: {
			...myTypography.caption,
			color: skin.get('greyColor4')
		},
		completed: {
			color: '#9a9eaf'
		},
		pending: {
			color: '#091027'
		},
		locked: {
			color: '#797e94'
		},
		aktiv: {
			color: skin.get('secondaryColor')
		},
		completedBackground: {
			backgroundColor: hexToRgbA(skin.get('darkSkyBlue'), 0.5)
		},
		pendingBackground: {
			backgroundColor: skin.get('greyColor4')
		},
		aktivBackground: {
			backgroundColor: skin.get('secondaryColor')
		},
		outerBar: {
			backgroundColor: skin.get('greyColor5')
		},
		lockedBackground: {
			backgroundColor: skin.get('greyColor4')
		},
		bottomText: {
			...myTypography.button,
			fontSize: '12px',
			color: hexToRgbA(skin.get('greyColor3'), 0.8)
		},
		expired: {
			color: hexToRgbA(skin.get('scarlet'), 0.6)
		},
		completedTag: {
			backgroundColor: skin.get('appleGreen'),
			color: skin.get('white'),
			...myTypography.button
		},
		expiredTag: {
			backgroundColor: skin.get('scarlet'),
			color: skin.get('white'),
			...myTypography.button
		},
		lockedTag: {
			backgroundColor: skin.get('greyColor4'),
			color: skin.get('white'),
			...myTypography.button
		},
		lockedDiv: {
			backgroundImage: `radial-gradient(circle at 50% 45%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.63))`
		}
	});
}

export default getSkin;
