import actionConsts from 'constants/actions';

const setApiError = (errorKey, errorMessage) => ({
	type: actionConsts.get('SET_API_ERROR'),
    errorKey,
    errorMessage
});

export {
	setApiError
}