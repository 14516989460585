import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setBatchLabels, setOrgLabels } from 'actions/labels/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';

class FetchLabels extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject = {}) => appendQueryParameters(
        urls.get('PLATFORM').get('GET_LABELS'),
        apiInfoObject.queryParameters
    )
	
	/* shouldAjaxCall = () => {
        if (this.getState().get('labels').get('isLabelsFetched')) {
            return false;
        }
        return true;
    } */

    successCall = (response, apiInfoObject) => {
        if(apiInfoObject.areOrgLabels){
            const payload = {
                orgId: `org_${apiInfoObject.queryParameters.orgId}`,
                labels: response
            };
            this.dispatch(setOrgLabels(payload));
        } else{
            const payload = {
                batchId: `${apiInfoObject.queryParameters.batchId}`,
                labels: response
            };
            this.dispatch(setBatchLabels(payload));
        }
        return response;
    }

    onError = () => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR_LABEL'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Unable to fetch labels.',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
        sendUserToInvalidPage();
    }

}

export default new FetchLabels();
