import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        collapsed:{
            backgroundColor: skin.get('white')
        },
        expanded:{
            backgroundColor:' #f6f7f9',
            boxShadow: '1px 0 0 0 #d5d8e2'
        }
    });
}

export default getSkin;