import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';

import enumConstants from 'constants/enums';

import FeedbackQuestions from 'components/feedbackQuestions';
//import FeedbackCompletion from 'components/feedback/feedbackCompletion';
import CloseCircle from 'svgComponents/closeCircle';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import CompletedFeedbackIcon from 'svgComponents/completedFeedbackIcon';
import TickMarkWithGreenFill from 'svgComponents/tickMarkWithGreenFill';

import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import styles from './phaseItemFeedback.module.sass';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PhaseItemFeedback extends Component {

    constructor(props){
        super(props);
        this.state = {
            openFeedback: false,
            itemCompleted: props.selectedUserItem.get('workflowState') === enumConstants.get('COMPLETED')
        }
    }

    toggleOpenFeedback = () => {
        this.setState({
            openFeedback: !this.state.openFeedback
        })
    }

    static getDerivedStateFromProps(nextProps, prevState){
        const { selectedUserItem } = nextProps;

        console.log('debugfb: ', selectedUserItem.toJS(), prevState);
        if(
            !prevState.itemCompleted
            && selectedUserItem.get('workflowState') === enumConstants.get('COMPLETED')
        ){
            if(!prevState.openFeedback){
                return {
                    openFeedback: true,
                    itemCompleted: true
                }
            } else{
                return {
                    itemCompleted: true
                }
            }
        }
        //something coming up here
        return null;
    }

    shouldShowFeedback = (item) => {
        if (!item) return false;
        return item.getIn(['settings', 'feedbackScenarioId']) &&
            item.getIn(['settings', 'isFeedbackEnabled']) &&
            item.getIn(['settings', 'isFeedbackEnabled']) === 'true';
    }

    render(){
        const {            
            loaders,
            selectedItem,
            itemFeedback            
        } = this.props;
        
        const { openFeedback } = this.state;
        const myStyles = getSkin(this.props.skinGuide);


        if(!this.shouldShowFeedback(selectedItem)) return null;

        const isFeedbackCompleted = itemFeedback.get('isFeedbackCompleted');

        return <div styleName="feedback-questions-outer-cnt">
            {
                loaders.get('isFetchingFeedback') ?
                    null
                    :
                    openFeedback && itemFeedback.get('isFeedbackQuestionsFetched') ?
                        [
                            <div styleName="feedback-inner-cnt">
                                <FeedbackQuestions {...this.props} setShowThankyouToTrue={this.setShowThankyouToTrue} />
                                {
                                    isFeedbackCompleted &&
                                    <div styleName="thank-you-note-cnt">
                                        <div styleName="tickmark-cnt">
                                            <TickMarkWithGreenFill />
                                        </div>
                                        <div className={css(myStyles.thankYouNote)}>
                                            {this.props.getLabelWithDefaults('thank_you_note_after_feedback', "Thanks for your feedback")}
                                        </div>
                                    </div>
                                }
                            </div>,

                            <div styleName="close-feedback-button" onClick={this.toggleOpenFeedback}>
                                <CloseCircle circleFillOpacity={0.3} strokeColor="white" />
                            </div>
                        ]
                        :
                        <div styleName="minimized-feedback" onClick={this.toggleOpenFeedback}>
                            <div styleName="feedback-icon-cnt">
                                {
                                    isFeedbackCompleted ?
                                        <CompletedFeedbackIcon />
                                        :
                                        <FeedbackIcon />
                                }
                            </div>
                        </div>
            }
        </div>
    }
}

export default PhaseItemFeedback;