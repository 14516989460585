import React from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { getFormattedSessionDate } from 'utils/webSessions';
import SessionActiveIcon from 'svgComponents/sessionActiveIcon';
import styles from './upcomingWebSessionsModal.module.sass';
import getSkin from './skin.js';

const UpcomingWebSessionsModal = ({upcomingWebSessions, batchDetails, activeWebSession, onClickCloseUpcomingWebSessionsModal, getLabel, skinGuide}) => {
  const myStyles = getSkin(skinGuide);

  const openSessionLink = (webSession) => {
    window.open(webSession.get('link'));
  }

  const renderJoinSessionButton = (webSession) => {
    return <div styleName='join-session-button' className={css(myStyles.joinSession)} onClick={() => openSessionLink(webSession)}>
        <div styleName="websession-active-icon-cnt"><SessionActiveIcon/></div>
        <div className={css(myStyles.joinText)}>{getLabel('websession_join_label_big')}</div>
    </div>;
  };
  
  const renderList = () => {
    return upcomingWebSessions.map( session => {
        const isSessionActive = activeWebSession && session.get('id') === activeWebSession.get('id');
        return <div styleName={`session-list-item ${isSessionActive ? "active-item" : ''}`} key={session.get('id')} className={isSessionActive ? css(myStyles.activeSessionListItem) : ''}>
            <div className={css(myStyles.sessionTitle)} styleName="session-title">{session.get('title')}</div>
            <div className={css(myStyles.sessionDateTime)} styleName="session-date">
                <span className={css(myStyles.labelBold)}>{getLabel('websession_dialog_starts_label')}: </span>
                {getFormattedSessionDate(session.get('startDate'))}
            </div>
            <div className={css(myStyles.sessionDateTime)} styleName='session-time'>
                <span className={css(myStyles.labelBold)}>{getLabel('websession_dialog_ends_label')}: </span>
                {getFormattedSessionDate(session.get('endDate'))}                           
            </div>
            <div className={css(myStyles.facilitator)} styleName="facilitator">
                <span className={css(myStyles.labelBold)}>{getLabel('websession_led_by_label')}: </span>      
                {session.get('facilitatorName')}
            </div>
            <div className={css(myStyles.sessionDescription)} styleName="description">{session.get('description')}</div>
            {
                isSessionActive && <div styleName="active-websession-join-button-cnt" className={css(myStyles.joinSessionButtonCnt)}>
                    {renderJoinSessionButton(session)}       
                </div>
            }
        </div>
    })
  }

  return <div styleName='main'>
    <div styleName='overlay' onClick={onClickCloseUpcomingWebSessionsModal}/>
    <div styleName='container'>
        <div styleName='header' className={css(myStyles.header)}>
            <div styleName="close-icon" className={css(myStyles.closeIcon)} onClick={onClickCloseUpcomingWebSessionsModal}>x</div>
            <div className={css(myStyles.courseName)}>{batchDetails.getIn(['journey', 'name'])}</div>
            <div styleName='modal-heading' className={css(myStyles.modalHeading)}>{getLabel('journey_upcoming_sessions_modal_heading')}</div>
        </div>
        <div styleName='list-container'>
            {renderList()}
        </div>
    </div>
  </div>
}

export default applySkin(applyLabel(CSSModules(UpcomingWebSessionsModal, styles, { allowMultiple: true })));