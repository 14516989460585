import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import styles from './lastSubmission.module.sass';
import applyLabel from 'utils/LabelProvider';
import Checkmark from 'svgComponents/checkmark';
import { convertDateWithYear } from 'utils/utilFunctions';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import CircularLoader from 'svgComponents/circularLoader'
import ModuleItemUtil from 'utils/moduleItem/moduleItem';

@applySkin
@applyLabel
@CSSModules(styles, {allowMultiple: true})
class LastSubmission extends Component {

    showEvaluationState = (workflowState) => {
        const {moduleItem} = this.props;
        const maxScore = moduleItem.get('moduleItem').get('content_details') ? moduleItem.get('moduleItem').get('content_details').get('points_possible') : null;

        if(!maxScore) return null;

        const styles = getSkin(this.props.skinGuide);
        if(workflowState === 'submitted'){
            return this.renderEvaluationPendingComponent();
        } else if(workflowState === 'graded'){
            return <div>
                <div className={css(styles.statusCircleEvaluated)} styleName="status-circle"></div>
                <span className={css(styles.evaluationStatus)}>
                &nbsp;{this.props.getLabelWithDefaults('assignment_evaluation_complete_label', 'Evaluation Complete')}
                </span>
            </div>
        }
        return null;
    }

    renderEvaluationPendingComponent = () => {
        const { moduleItem} = this.props;
        const assignment = moduleItem.get('contentDetails');
        const isNLPAssignment = assignment.getIn(['al_data', 'nlp']) || false;
        return <div>
            {isNLPAssignment ? <div style={{height: '30px', padding: '10px'}}><CircularLoader/></div> :  <div className={css(styles.statusCirclePending)} styleName="status-circle"/>}
            <span className={css(styles.evaluationStatus)}>
            &nbsp;{this.props.getLabelWithDefaults('assignment_evaluation_pending_label', 'Evaluation Pending')}
            </span>
        </div>
    }

    renderAttachments = (submission) => {
        const styles = getSkin(this.props.skinGuide);
        let thumbnailUrl = null;
        const attachments = submission.get('attachments').map((attachment)=>{
            thumbnailUrl= attachment.get('thumbnail_url') || "https://webassets.knolskape.com/misc/amul_garg/2019/03/24/23/default-thumbnail.png";
            return <a styleName="file-card" href={attachment.get('url')} target="_blank" rel="noreferrer">
                <img styleName="file-card-image" className={css(styles.fileCardNoPreview)} alt='File Preview Thumbnail' src={thumbnailUrl}/>
                <div styleName="file-card-filename" className={css(styles.fileCardFileName)}>{attachment.get('filename')}</div>
            </a>
        });
        return attachments;
    }

    renderResponse = (submission) => {
        const styles = getSkin(this.props.skinGuide);
        switch(submission.get('submission_type')){
            case 'online_text_entry': 
                return <div className={css(styles.responseBody)}>{submission.get('body')}</div>;
            case 'online_url':
                return <div className={css(styles.responseBody)}>
                    <a href={submission.get('url')} target="blank">{submission.get('url')}</a>
                </div>;
            case 'online_upload':
                return <div className={css(styles.responseBody)} styleName="file-cards-cnt">
                    {this.renderAttachments(submission)}
                </div>;
            default:
                return null;
        }
    }

    render() {
        const { moduleItem, getLabelWithDefaults, onClickResubmit, lockDetails } = this.props;
        const assignment = moduleItem.get('contentDetails');
        const isNLPAssignment = ModuleItemUtil.isNLPAssignment(moduleItem);
        const submission = assignment.get('submission');
        const styles = getSkin(this.props.skinGuide);
        const canReSubmit = !lockDetails.expired && !isNLPAssignment;

        return (
            <div>                
                <div className={css(styles.heading)} styleName="heading">
                    {getLabelWithDefaults('assignment_your_submission_heading_label', 'Your submission')} 
                </div>

                {
                    moduleItem.get('assignmentSubmitted') && 
                        <div className={css(styles.successMessage)} styleName="success-msg-cnt">
                            <div styleName="checkmark">
                                <Checkmark viewBoxParam={-2} fillColor={'white'}/>
                            </div>
                            <div>
                                {getLabelWithDefaults('assignment_submitted_label', 'Your assignment has been submitted successfully.')}
                            </div>
                        </div>
                }

                <div styleName="submission-details-section" className={css(styles.submissionDetailsSection)}>
                    <div className={css(styles.submittedOn)}>
                        {getLabelWithDefaults('assignment_submitted_on_label', 'Submitted on')}:&nbsp;              
                        <span className={css(styles.date)}>
                            {convertDateWithYear(new Date(submission.get('submitted_at')))}
                        </span>
                    </div>
                    <div className={css(styles.submittedOn)}>
                        {this.showEvaluationState(submission.get('workflow_state'))}
                    </div>
                </div>

                
                <div styleName="response-container" className={css(styles.responseContainer)}>
                    {this.renderResponse(submission)}
                </div>

                { canReSubmit ? <div styleName="resubmit-section">
                    <div className={css(styles.resubmitHeading)}>
                        {getLabelWithDefaults('assignment_resubmit_heading_label', 'You can re-submit your assignment to replace the existing response')}
                    </div>
                    <div styleName="resubmit-btn-cnt">
                        <PrimaryButton 
                            text={getLabelWithDefaults('resubmit_assignment_label', "Re-submit")}
                            clickFunction={onClickResubmit}
                            style={{width: '200px'}}
                        />
                    </div>
                </div> : 
                <div styleName="resubmit-section">
                    <span className={css(styles.resubmitHeading)}>{lockDetails.explanation}</span>
                </div> }                
            </div>
        )
    }

}

export default LastSubmission;
