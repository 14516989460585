import FetchModuleItem from 'actions/serverCalls/moduleItems/FetchModuleItem';
import FetchModuleItemContentDetails from 'actions/serverCalls/moduleItems/FetchModuleItemContentDetails';
import { fetchBatchDetailsForItem } from 'actions/batches/newApiActions';
import { setModuleItemAvailabilityStatus } from 'actions/moduleItems/actionCreators';
import FetchToolFAQs from 'actions/serverCalls/tools/FetchToolFAQs';
import FetchProductLaunch from 'actions/serverCalls/moduleItems/FetchProductLaunch';
import MarkModuleItemRead from 'actions/serverCalls/moduleItems/MarkModuleItemRead';
import MarkModuleItemDone from 'actions/serverCalls/moduleItems/MarkModuleItemDone';

import UpdateMediaFileStatus from 'actions/serverCalls/moduleItems/UpdateMediaFileStatus';
import FetchCourseModulesList from 'actions/serverCalls/courses/FetchCourseModulesList';
import FetchModuleItemsList from 'actions/serverCalls/courses/FetchModuleItemsList';
import SubmitAssignment from 'actions/serverCalls/moduleItems/SubmitAssignment';
import FetchNextActivityItem from 'actions/serverCalls/moduleItems/FetchNextActivityItem';

import FetchNextModuleItemContentDetails from 'actions/serverCalls/moduleItems/FetchNextModuleItemContentDetails';


import FetchItemFeedback from 'actions/serverCalls/itemFeedback/FetchItemFeedback';
import FetchUserItemFeedback from 'actions/serverCalls/itemFeedback/FetchUserItemFeedback';
import SubmitAnswer from 'actions/serverCalls/itemFeedback/SubmitAnswer';

import UploadFile from 'actions/serverCalls/moduleItems/UploadFile';

import environment from 'utils/environment';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import { getUserActiveBatchDetails } from 'utils/batchUtils';
import store from 'store/store';
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import {setAssignmentSubmittingStatus} from 'actions/moduleItems/actionCreators';

import {
    isBrowser,
    isMobile
} from 'react-device-detect';

import {
    fetchItemFeedback,
    fetchUserItemFeedback
} from 'actions/itemFeedback/apiActions';

import { filterObjectFromArray } from 'utils/objectUtils';
import { updateFetchFeedbackStatus } from 'actions/loaders/actionCreators';
import enumConstants from 'constants/enums';

const getDeviceType = () => {
    let device = "WEBBROWSER"
    if (isMobile) {
        device = 'WEBMOBILE';
    }
    return device;
}

const checkIfLockedAndRedirectToDashboard = (params) => {
    const batches = store.getState().get('batches');
    const userActiveBatchDetails = getUserActiveBatchDetails(batches);
    const { items } = setAvailabilityFlags(
        store.getState().get('phases'),
        store.getState().get('items'),
        userActiveBatchDetails
    );

    const phaseItem = items.get('itemsList').find((item) => {
        return (item.get('id') == params.phaseItemId);
    });

    const userPhaseItemData = filterObjectFromArray(items.get('userItemsList'), 'id', +params.phaseItemId);

    const isPhaseItemCompleted = userPhaseItemData.get('workflowStatus') == enumConstants.get('COMPLETED');

    if (phaseItem && phaseItem.size) {
        if (phaseItem.get('isLocked') || (phaseItem.get('isExpired') && !isPhaseItemCompleted )) {
            updateRoute({
                route: routes.get('BATCH'),
                params: [{ 'BATCH_ID': params.batchId }]
            });
        }
    }
}


const fetchModuleItem = function (params, shouldFetchBaseData = true, shouldFetchModules = true) {
    return function (dispatch, getState) {

        if (shouldFetchModules) {
            dispatch(setModuleItemAvailabilityStatus(false));
        }

        fetchBatchDetailsForItem(dispatch, params.batchId, shouldFetchBaseData).then(() => {

            checkIfLockedAndRedirectToDashboard(params);


            return Promise.all([

                shouldFetchModules ? FetchCourseModulesList.call(params) : null,

                FetchModuleItem.call(params).then((response) => {

                    FetchModuleItemContentDetails.call(params).then((contentResponse) => {
                        // const toolId = (response.type_label == 'Simulation') && contentResponse && contentResponse.al_data ? +contentResponse.al_data.productId : null
                        // if(toolId){
                        //     FetchToolFAQs.call({toolId, lang: 'en_US'});
                        // }
                    });

                    /* fetch and cache next item's content-details for faster loading */
                    FetchNextModuleItemContentDetails.call(params);
                })

            ]);

        });

    }
};

const fetchCourseFeedbackData = function (params, scenario, entityType, shouldFetchBaseData = true, shouldFetchModules = true) {
    return function (dispatch, getState) {
        dispatch(updateFetchFeedbackStatus(true));

        if (shouldFetchModules) {
            dispatch(setModuleItemAvailabilityStatus(false));
        }

        fetchBatchDetailsForItem(dispatch, params.batchId, shouldFetchBaseData, false).then(() => {

            let langID = 'en_US';

            let feedbackScenario = scenario;
            const batchesList = getState().get('batches').get('batchesList');

            const phaseItemDataForCurrentCourse = getState().getIn(['items', 'itemsList']).find(item => item.get('id') === +params.phaseItemId)

            if (phaseItemDataForCurrentCourse && phaseItemDataForCurrentCourse.getIn(['settings', 'feedbackScenarioId'])) {
                feedbackScenario = phaseItemDataForCurrentCourse.getIn(['settings', 'feedbackScenarioId'])
            }

            const activeBatchDetails = filterObjectFromArray(batchesList, 'id', +params.batchId);

            if (activeBatchDetails && activeBatchDetails.size) {
                langID = activeBatchDetails.getIn(['journey', 'lang']);
            }

            const orgLang = getState().getIn(['userOrganization', 'userOrganization', 'lang']) ;

            if(getState().getIn(['userOrganization', 'userOrganization', 'lang']) !== 'en_US'){
                langID = orgLang; /*if org lang other than English (US), override */
            }

            const apiInfoObj = {
                queryParameters: {
                    device: getDeviceType(),
                    scenario: feedbackScenario,
                    lang: langID,
                    ...localUserdata()
                }
            };
            return Promise.all([
                shouldFetchModules ? FetchCourseModulesList.call(params) : null,
                FetchItemFeedback.call(apiInfoObj).then(() => {
                    dispatch(fetchUserItemFeedback(params.batchId, params.phaseItemId, entityType, feedbackScenario));
                })
            ]);

        });

    }
};

const fetchModules = (params) => {
    return function (dispatch, getState) {
        FetchCourseModulesList.call(params);
    };
}

const refreshModuleItem = (params) => {

    const coursesActiveSections = store.getState().get('coursesActiveSections');
    const courseDetails = coursesActiveSections.find(course => course.get('id') == +params.courseId);
    if(courseDetails && courseDetails.get('activeSectionId')){
        FetchModuleItemsList.call({...params, moduleId: courseDetails.get('activeSectionId')});
    }
    
    return Promise.all([
        FetchCourseModulesList.call(params),
        FetchModuleItem.call(params).then((response) => {
            FetchModuleItemContentDetails.call(params).then((contentResponse) => {
                // const toolId = (response.type_label == 'Simulation') && contentResponse && contentResponse.al_data ? +contentResponse.al_data.productId : null
                // if(toolId){
                //     FetchToolFAQs.call({toolId, lang: 'en_US'});
                // }
            })
        })
    ]);
};

const fetchModuleItemContentDetails = (params) => {
    FetchModuleItemContentDetails.call(params);
}

const markModuleItemRead = (params) => {
    MarkModuleItemRead.call(params).then(() => {
        refreshModuleItem(params);
    });
}


const markModuleItemDone = (params) => {
    MarkModuleItemDone.call(params).then(() => {
        refreshModuleItem(params);
    });
}

const updateMediaFileStatus = (params) => {
    let payloadForAnswering = {
        ...params,
        body: params,
        headers: {
            'Content-type': 'application/json'
        }
    }
    UpdateMediaFileStatus.call(payloadForAnswering);
}

const fetchProductLaunch = (params) => {
    FetchProductLaunch.call(params);
}

const submitAssignment = (urlParams, payload) => (dispatch, getState) => {

    dispatch(setAssignmentSubmittingStatus(true));

    if (payload.submission.submission_type == 'online_upload') {
        payload.submission.file_ids = getState().get('moduleItem').get('uploadedFiles').map(file => file.get('id'));
    }

    payload = {
        ...urlParams,
        body: payload,
        headers: {
            'Content-type': 'application/json'
        }
    };

    SubmitAssignment.call(payload).then((response) => {
        refreshModuleItem(urlParams).then(()=>{
            dispatch(setAssignmentSubmittingStatus(false));
        });
    });
}

const fetchNextActivityItem = (params) => {
    FetchNextActivityItem.call(params);
}

const localUserdata = () => {
    if (environment.REACT_APP_ENV == 'local') {
        return {
            'x-userid': 162709,
            userId: 162709,
        }
    } else {
        return {}
    }
}


const fetchModuleItemFeedback = (batchId, itemId, entityType, scenario) => (dispatch, getState) => {


    let langID = 'en_US';

    const batchesList = getState().get('batches').get('batchesList');
    const batchId = getState().get('userState').get('currentBatchId');
    const activeBatchDetails = filterObjectFromArray(batchesList, 'id', batchId);
    
    if (activeBatchDetails && activeBatchDetails.size) {
        langID = activeBatchDetails.get('journey').get('lang');
    }

    const orgLang = getState().getIn(['userOrganization', 'userOrganization', 'lang']) ;

    if(getState().getIn(['userOrganization', 'userOrganization', 'lang']) !== 'en_US'){
        langID = orgLang; /*if org lang other than English (US), override */
    }

    const apiInfoObj = {
        queryParameters: {
            device: getDeviceType(),
            scenario: scenario,
            lang: langID,
            toolId: itemId,
            ...localUserdata()
        }
    };

    return FetchItemFeedback.call(apiInfoObj).then(() => {
        dispatch(fetchUserItemFeedback(batchId, itemId, entityType, scenario));
    });
}

const uploadFile = (params, file) => (dispatch, getState) => {
    let form = new FormData();
    form.append('file', file);

    params = {
        ...params,
        body: form,
        fileName: file.name,
        requestType: 'file_upload'
    };

    return UploadFile.call(params);
}

export {
    fetchModuleItem,
    fetchModuleItemContentDetails,
    markModuleItemRead,
    markModuleItemDone,
    fetchProductLaunch,
    updateMediaFileStatus,
    submitAssignment,
    fetchNextActivityItem,
    refreshModuleItem,
    fetchModuleItemFeedback,
    fetchModules,
    fetchCourseFeedbackData,
    uploadFile
};