import firebase from 'firebase/app';
import 'firebase/auth';

export const signOutFromFirebase = (callback) => {
    firebase.auth().signOut().then(function() {
        console.log('sign out successful');
        callback();
    }).catch(function(error) {
        throw error;
    });
}