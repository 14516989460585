/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './JourneyLearningsPhaseLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyLearningsPhaseLoader extends React.Component {

	renderPhaseItemContentLoader = (loadersDefaultconfig) => {
		return (
			<div
				styleName="item-column"
			>
				<ContentLoader
					height={180}
					width={1000}
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '100%' }}
				>
					<rect x="0" y="0" rx="0" ry="0" width="110" height="24" />
					<rect x="0" y="62" rx="0" ry="0" width="939" height="12" />
					<rect x="0" y="86" rx="0" ry="0" width="659" height="12" />
				</ContentLoader>
			</div>
		);
	}

	renderPhaseContentLoader = (loadersDefaultconfig) => {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div className={css(myStyles.phaseBackground)} styleName="single-phase-content">
				<div styleName="phase-column">
					<ContentLoader
						height={220}
						width={1000}
						speed={loadersDefaultconfig.get('speed')}
						primaryColor={loadersDefaultconfig.get('primaryColor')}
						secondaryColor={loadersDefaultconfig.get('secondaryColor')}
						style={{ height: '100%' }}
					>
						<rect x="0" y="0" rx="0" ry="0" width="219" height="24" />
						<rect x="0" y="62" rx="0" ry="0" width="939" height="12" />
						<rect x="0" y="86" rx="0" ry="0" width="939" height="12" />
						<rect x="0" y="110" rx="0" ry="0" width="659" height="12" />
					</ContentLoader>
				</div>
			</div>
		);
	}

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<div styleName="loader-container">
				<div styleName="single-phase-container">
					{this.renderPhaseContentLoader(loadersDefaultconfig)}
					{this.renderPhaseItemContentLoader(loadersDefaultconfig)}
					{this.renderPhaseItemContentLoader(loadersDefaultconfig)}
				</div>
			</div>
		);
	}
}

export default JourneyLearningsPhaseLoader;
