import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { appendQueryParameters } from 'utils/utilFunctions';
import { filterObjectFromArray } from 'utils/objectUtils';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import updateRoute, { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';

class SetPhaseWorkflowStatus extends NetPackWrapper {

	constructor() {
		super("PUT");
	}

	prepareUrl = (apiInfoObject = {}) => appendQueryParameters(
		urls.get('PLATFORM').get('SET_PHASE_WORKFLOW_STATUS').replace(
			'PHASE_ID',
			apiInfoObject.phaseId
		),
		apiInfoObject.queryParameters
	)

	shouldAjaxCall = (apiInfoObject) => {
		const userPhasesList = this.getState().get('phases').get('userPhasesList');
		const currentUserPhase = filterObjectFromArray(userPhasesList, 'id', apiInfoObject.phaseId);
		const currentPhaseStatus = currentUserPhase.get('workflowState');
		if (currentPhaseStatus && currentPhaseStatus != apiInfoObject.body.workflowState) {
			return true;
		}
		return true;
	}

	successCall = (response, apiInfoObject) => {
		if (apiInfoObject.routeObj) {
			updateRoute(apiInfoObject.routeObj);
		}
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, unable to update phase status. Please try again!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		if (errorObj.apiInfoObject.failureRouteObj) {
			updateRoute(errorObj.apiInfoObject.failureRouteObj);
		}
		// sendUserToInvalidPage();
	}

}

export default new SetPhaseWorkflowStatus();
