import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { css } from 'aphrodite/no-important';
import styles from './moduleItemHeader.module.sass';
import { convertDateWithYear } from 'utils/utilFunctions';
import applyLabel from 'utils/LabelProvider';
import ModuleItemTypes from 'svgComponents/moduleItemTypes';
import Recommended from 'svgComponents/recommended';
import CalendarSvg from 'svgComponents/calendarSvg';
import getSkin from './skin.js';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import Prev from 'svgComponents/prev';
import { checkIfPresent } from 'utils/utilFunctions';
import { getDurationStringUsingUnits } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, {allowMultiple: true})

class ModuleItemHeader extends React.Component {

	onClickOfBackToHome = () => {
        const { batchId } = this.props;
        const route = routes.get('BATCH');
        const params = [{ 'BATCH_ID': batchId }]
        updateRoute({ route, params });
    }

	render(){
		let {moduleItem, getLabelWithDefaults, formLabelForModuleItem, isScormCourse} = this.props;
		const styles = getSkin(this.props.skinGuide);
		return(
			<div styleName="module-item-header">
				<div styleName="item-type-cnt">					

					{
						isScormCourse ? 
							<div onClick={this.onClickOfBackToHome} className={css(styles.goToHome)} styleName="go-to-home">
								<Prev 
									fill={this.props.skinGuide.get('skinHex').get('secondaryColor')}
									height={14}
									width={12}
								/>
								{'\u00A0'}{'\u00A0'}Back to Home
							</div> : 
							
							<div styleName="text" className={css(styles.text)}>
								<div styleName="module-item-type-icon-cnt">
									<ModuleItemTypes 
										itemType={moduleItem.get('type_label').toLowerCase()}
										itemSubType={moduleItem.get('sub_type').toLowerCase()}
									/>
								</div>
								<div styleName="type-label">
									{
										getLabelWithDefaults(
											formLabelForModuleItem(
												moduleItem.get('type_label'),
												moduleItem.get('sub_type')
											), moduleItem.get('type_label')
										)
									}
								</div>
								{
									checkIfPresent(moduleItem.get('duration')) &&
									<div styleName="item-duration-cnt">
										{getDurationStringUsingUnits(moduleItem.get('duration'), moduleItem.get('duration_unit'))}
									</div>
								}
							</div>
					}
					
					{moduleItem.get('content_details') && moduleItem.get('content_details').get('due_at') ? 
						<div styleName="item-due-at-cnt">
							<div styleName="module-item-type-icon-cnt">
								<CalendarSvg/>
							</div>
							<div styleName="text"  className={css(styles.text)}>
								{getLabelWithDefaults('due_on_label', 'Due on %{date}', {date: convertDateWithYear(moduleItem.get('content_details').get('due_at'))})}
							</div>
						</div>
						: null 
					}

					{
						moduleItem.get('recommend') ?
							<div styleName="recommended-cnt">
								<div styleName="recommended-icon-cnt">
									<Recommended />
								</div>
								<div className={css(styles.recommendedText)}>
									{
										getLabelWithDefaults('recommended_for_you_label', 'Recommended for you')
									}
								</div>
							</div>
						: null
					}
					
				</div>
			</div>
		)
	}
}

export default ModuleItemHeader;