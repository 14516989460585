import React, { Component } from 'react';
import styles from './searchIcon.module.sass';
import CSSModules from 'react-css-modules';

@CSSModules(styles, { allowMultiple: true })
class SearchIcon extends Component {

	render() {
		return (
			<svg width="100%" height="100%" viewBox="0 0 15 15">
				<path fill={this.props.color || "#D5D9E7"} fillRule="nonzero" d="M14.413 13.314L10.357 9.26a5.767 5.767 0 1 0-1.098 1.098l4.055 4.056a.786.786 0 0 0 1.099 0 .777.777 0 0 0 0-1.099zM1.595 5.786a4.193 4.193 0 1 1 8.386 0 4.193 4.193 0 0 1-8.386 0z" />
			</svg>
		);
	}
}



export default SearchIcon;
