import React from 'react';
import { connect } from 'react-redux';
import {
	fetchUserProfile,
	syncUser,
	changePassword,
	uploadAvatarImage,
	updateNotificationSubscription
} from 'actions/userProfile/apiActions';
import UserProfile from 'components/userProfile';
import ResetPassword from 'components/userProfile/ResetPassword';

class UserProfileContainer extends React.Component {

	componentDidMount() {
		this.props.fetchUserProfile();
	}

	render() {
		if (this.props.resetPassword) {
			return <ResetPassword
				userProfile={this.props.userProfile}
				changePassword={this.props.changePassword}
			/>
		} else {
			return <UserProfile
				{...this.props}
			/>
		}
	}
}

const mapStateToProps = state => {
	return {
		user: state.get('user'),
		userProfile: state.get('userProfile')
	}
};

const mapDispatchToProps = dispatch => ({
	fetchUserProfile: () => {
		dispatch(fetchUserProfile())
	},
	syncUser: (userReferences = {}) => {
		dispatch(syncUser(userReferences))
	},
	changePassword: (passwordPayload) => {
		dispatch(changePassword(passwordPayload))
	},
	uploadAvatarImage: (image) => {
		dispatch(uploadAvatarImage(image))
	},
	updateNotificationSubscription: (flag = false) => {
		dispatch(updateNotificationSubscription(flag))
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileContainer);
