import React, { Component } from 'react';
import styles from './simulationsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

// @applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SimulationsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<g fill="#828593" fillRule="nonzero">
						<path d="M2.917 1.167h2.625c.483 0 .875.391.875.875v.875a.583.583 0 0 0 1.166 0v-.875A2.042 2.042 0 0 0 5.542 0H2.917a.583.583 0 1 0 0 1.167zM11.375 4.375h-8.75A2.625 2.625 0 0 0 0 7v5.787a1.2 1.2 0 0 0 .359.86c.23.227.542.354.866.353h.017c1.137-.015 1.311-.35 2.445-2.544l.422-.813a1.47 1.47 0 0 1 1.263-.726h3.255c.512 0 .988.268 1.251.708.187.358.35.677.498.96C11.429 13.63 11.619 14 12.75 14A1.243 1.243 0 0 0 14 12.763V7a2.625 2.625 0 0 0-2.625-2.625zm-7.146 3.5h-.437a.292.292 0 0 0-.292.292v.437a.438.438 0 0 1-.875 0v-.437a.292.292 0 0 0-.292-.292h-.437a.438.438 0 0 1 0-.875h.437c.161 0 .292-.13.292-.292v-.437a.438.438 0 0 1 .875 0v.437c0 .161.13.292.292.292h.437a.438.438 0 0 1 0 .875zm5.104 0a.583.583 0 1 1 0-1.167.583.583 0 0 1 0 1.167zm1.459 1.167a.583.583 0 1 1 0-1.167.583.583 0 0 1 0 1.167zm0-2.334a.583.583 0 1 1 0-1.166.583.583 0 0 1 0 1.166zm1.458 1.167a.583.583 0 1 1 0-1.167.583.583 0 0 1 0 1.167z"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default SimulationsIcon;
