import {
	setSelectedJourneyInstanceLoading,
	setAvailableJourneysLoading
} from './actionCreators';
import FetcJourneyInstanceDetails from 'actions/serverCalls/journeys/FetcJourneyInstanceDetails';
import FetchOrganizationJourneys from 'actions/serverCalls/batches/FetchRequestedJourneys';
import FetchCorpDeckJourneys from '../serverCalls/batches/FetchCorpDeckJourneys';
import FetchJourneyDetails from '../serverCalls/journeys/FetchJourneyDetails';

const fetchJourneyInstanceDetails = (journeyInstanceId, isExpressInterestEnabled = false) => (dispatch, getState) => {
	dispatch(setSelectedJourneyInstanceLoading(true));
	let queryParameters = {}

	if(isExpressInterestEnabled) {
		queryParameters['include'] = "INTERESTED";
	}

	FetcJourneyInstanceDetails.call({ journeyInstanceId, queryParameters: queryParameters });
}

const fetchJourneyCatalogueData = (removeInclude = false, searchText = "") => (dispatch, getState) => {
	dispatch(setAvailableJourneysLoading(true));
	let queryParameters = {
		orgId: getState().getIn(['userOrganization', 'userOrganization', 'id']),
		search: searchText,
	}
	if (!removeInclude) {
		queryParameters['include'] = "NOT_REQUESTED"
	}
	FetchOrganizationJourneys.call({
		queryParameters: queryParameters
	});
}

const fetchCorpDeckJourneyCatalogueData = (searchText = "") => (dispatch, getState) => {
	dispatch(setAvailableJourneysLoading(true));
	let queryParameters = {
		orgId: getState().getIn(['userOrganization', 'userOrganization', 'id']),
		search: searchText,
		categories:"COURSES,BUNDLES,JOURNEYS"
	}

	FetchCorpDeckJourneys.call({queryParameters});
}

const fetchJourneyDetails = (journeyId) => (dispatch, getState) => {
	dispatch(setSelectedJourneyInstanceLoading(true));
	FetchJourneyDetails.call({ journeyId });
}

export {
	fetchJourneyInstanceDetails,
	fetchJourneyCatalogueData,
	fetchCorpDeckJourneyCatalogueData,
	fetchJourneyDetails
};