import ContentLoader from "react-content-loader"
import React, { Component } from 'react';
import applySkin from 'utils/SkinProvider';
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
class DigitalToolLoader extends Component {
	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));

		return (
			<ContentLoader
				height={500}
				width={800}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				{...this.props}
			>
				<rect x="100" y="30" rx="5" ry="5" width="160" height="14" />
				<rect x="270" y="30" rx="5" ry="5" width="160" height="14" />
				<rect x="440" y="30" rx="5" ry="5" width="160" height="14" />
				<rect x="100" y="65" rx="5" ry="5" width="150" height="20" />
				<rect x="150" y="105" rx="0" ry="0" width="400" height="166" />
				<rect x="150" y="290" rx="0" ry="0" width="195" height="110" />
				<rect x="355" y="290" rx="0" ry="0" width="195" height="110" />
				<rect x="100" y="420" rx="5" ry="5" width="491" height="10" />
				<rect x="100" y="445" rx="5" ry="5" width="491" height="10" />
			</ContentLoader>
		)
	}
}

export default DigitalToolLoader;