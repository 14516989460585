import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setBatchesList } from 'actions/batches/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';

class FetchBatches extends NetPackWrapper {

    constructor() {
        super("GET", true);
    }

    getToBeFetchedBatchIds = (apiInfoObject) => {
        const { batchIdsArray } = apiInfoObject;
        const batchesList = this.getState().get('batches').get('batchesList');
        const currentBatchIdsList = batchesList.map((batch) => batch.get('id'));
        const currentBatchIdsArray = currentBatchIdsList.toJS();
        return batchIdsArray.filter((batchId) => {
            if (currentBatchIdsArray.indexOf(batchId) === -1) {
                return batchId;
            }
        });
    }

	prepareUrl = (apiInfoObject) => {
        const toBeFetchedBatchIds = this.getToBeFetchedBatchIds(apiInfoObject);
        const batchIds = toBeFetchedBatchIds.join(',');
        const queryParameters = {
            ...apiInfoObject.queryParameters,
            batchIds
        };
        return appendQueryParameters(
            urls.get('PLATFORM').get('GET_BATCHES'),
            queryParameters
        );
    }
	
	shouldAjaxCall = (apiInfoObject) => {
        const toBeFetchedBatchIds = this.getToBeFetchedBatchIds(apiInfoObject);
        
        if (toBeFetchedBatchIds.length === 0) {   //logic need to be changed to handle pagination
            return false;
        }
        return true;
    }

    successCall = (response) => {
        const myResponse = { ...response };

        const batchesList = myResponse.batches;
        delete myResponse['batches'];

        const batchesListAdditionalDetails = myResponse;

        const payload = {
            batchesList,
            batchesListAdditionalDetails
        };
        
        this.dispatch(setBatchesList(payload));
        return response;
    }

    onError = (errorObj) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }
}

export default new FetchBatches();
