/**
 * 
 *
 * @param {*} competencyCount
 * @param {*} max
 * @returns []
 */
const dataGen = (competencyCount, max) => {

	var completeData = [];
	var competencyArray = ['Business Acumen', 'Customer Orientation', 'Drive for Results', 'Learning Agility', 'Problem Solving'];
	for (let index = 1; index < competencyCount+1; index++) {
		const startUser = Math.floor(Math.random() * Math.floor(max));
		const startGroup = Math.floor(Math.random() * Math.floor(max));
		let endUser = Math.floor(Math.random() * Math.floor(max));
		endUser = (endUser <= startUser) ? startUser + 2 : endUser;
		let endGroup = Math.floor(Math.random() * Math.floor(max));
		endGroup = (endGroup <= startGroup) ? startGroup + 1 : endGroup;
		completeData.push(
			{
				measureId: index,
				measureName: competencyArray[index-1],
				USER: {
					PRE: endUser,
					POST: startUser
				},
				OVERALL: {
					PRE: startGroup,
					POST: endGroup
				}
			}
		);
	}
	return completeData;

};

export default dataGen;