import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		boxGradient: {
			backgroundImage: `linear-gradient(to bottom, ${hexToRgbA(skinHex.get('primaryColor'), 0.5)}, #000824)`,
		},
		journeyName: {
			...myTypography.h4,
			color: skinHex.get('white'),
			minHeight: '46px',
			lineHeight: 'normal'
		},
		journeyCategory: {
			...myTypography.body1,
			color: '#979EB7',
			fontWeight: 'normal',
			minHeight: '30px',
			lineHeight: 'normal'
		},
		journeyDescription: {
			...myTypography.body2,
			color: skinHex.get('white'),
			fontWeight: 'normal',
			lineHeight: 'normal'
		},
		durationLabel: {
			...myTypography.labelSmall,
			color: '#979EB7',
			fontWeight: 'normal',
			lineHeight: 'normal',
			textTransform: 'uppercase',
			height: '20px',
			display: 'flex',
			alignItems: 'center'
		},
		durationValue: {
			...myTypography.body2,
			color: '#979EB7',
			fontWeight: 'normal',
			lineHeight: 'normal'
		},
		expContentTitle: {
			...myTypography.h4,
			lineHeight: 1,
			color: 'rgba(9, 16, 39, 0.85)'
		},
		expContentDesc: {
			...myTypography.body1,
			lineHeight: 1.56,
			color: skinHex.get('greyColor1')
		},
		detailTitle: {
			...myTypography.overline,
			fontWeight: 'normal',
			lineHeight: 'normal',
			color: 'rgba(9, 16, 39, 0.595)',
			textTransform: 'uppercase'
		},
		infoText: {
			...myTypography.overline,
			fontWeight: 'normal',
			lineHeight: 'normal',
			color: 'rgba(9, 16, 39, 0.85)'
		},
		statusButtonText: {
			...myTypography.overline,
			fontWeight: 'normal',
			lineHeight: 'normal'
		},
		statusText: {
			...myTypography.caption,
			fontWeight: 'normal',
			lineHeight: 'normal',
			color: '#6D717E',
			margin: '10px 0 20px 0'
		}
	});
}

export default getSkin;