/**
 * @author: Sourabh P
 * @since: Thu Sep 11 2018 18:20:31 GMT+0530 (IST)
 * @file: index.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * FILE DESCRIPTION
 *
 **/

import SkillsCoveredLoader from './SkillsCoveredLoader.jsx';

export default SkillsCoveredLoader;
