import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import environment from 'utils/environment';
import FetchFirebaseCustomToken from 'actions/serverCalls/users/FetchFirebaseCustomToken';
import store from 'store/store'

const initializeFirebaseApp = () => {

    var fireBaseConfig = {
        apiKey: environment.REACT_APP_FIREBASE_API_KEY,
        authDomain: environment.REACT_APP_FIREBASE_AUTHDOMAIN,
        databaseURL: environment.REACT_APP_FIREBASE_DATABASE_URL,
        projectId: environment.REACT_APP_FIREBASE_PROJECT_ID
    };
    firebase.initializeApp(fireBaseConfig);

    console.log('fb: initialized');

}

const authenticateOnFirebase = () => {
    return new Promise((resolve, reject) => {
        const userId = store.getState().get('user').get('userID');
        FetchFirebaseCustomToken.call({userId}).then((response)=>{
            console.log('fb: response', JSON.stringify(response));
            firebase.auth().signInWithCustomToken(response.token).then(()=>{
                console.log('fb: authenticated');
                resolve({success: response.success});
            }).catch((error) => {
                console.log('fb: firebase authentication error', error);
                reject(error);
            });
        }).catch(error => {
            console.log('Error while generating firebase token');
            reject(error);
        });
    });
}

const getDefaultFirebaseDatabase = () => {
    return new Promise((resolve, reject) => {
        if(firebase.auth().currentUser){
            console.log('fb: already authenticated: currentUser');
            resolve(firebase.database());    
        } else {
            const unsubscribe = firebase.auth().onAuthStateChanged(function(user) {                
                unsubscribe();
                if (user) {
                    console.log('fb: already authenticated: observer');
                    resolve(firebase.database());                
                } else {
                    authenticateOnFirebase().then((response)=>{
                        resolve(firebase.database()); 
                    }).catch(error => reject(error));
                }
            });
        }
    });
}

const getDigitalToolkitFirebaseDatabase = () => {
    return getFirebaseDatabase(environment.REACT_APP_FIREBASE_DIGITAL_TOOLKIT_DATABASE_URL);
}

const getJourneyFirebaseDatabase = () => {
    //return getFirebaseDatabase(environment.REACT_APP_FIREBASE_JOURNEY_DATABASE_URL);
    return firebase.app().database(environment.REACT_APP_FIREBASE_JOURNEY_DATABASE_URL);
}

const getFirebaseDatabase = (databaseUrl) => {
    return new Promise((resolve, reject) => {
        if(firebase.auth().currentUser){
            console.log('fb: already authenticated: currentUser');
            resolve(firebase.app().database(databaseUrl));   
        } else {
            const unsubscribe = firebase.auth().onAuthStateChanged(function(user) {                
                unsubscribe();
                if (user) {
                    console.log('fb: already authenticated: observer');
                    resolve(firebase.app().database(databaseUrl));      
                } else {
                    authenticateOnFirebase().then((response)=>{
                        resolve(firebase.app().database(databaseUrl));
                    }).catch(error => reject(error));
                }
            });
        }
    });
}

export { initializeFirebaseApp, getDefaultFirebaseDatabase, getDigitalToolkitFirebaseDatabase, getJourneyFirebaseDatabase }; 