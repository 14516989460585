import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './phaseTabs.module.sass';
import { TweenMax } from 'gsap';
import applySkin from 'utils/SkinProvider'
import { css } from 'aphrodite/no-important';
import getSkin from './skin';
import LeftArrow from 'svgComponents/leftArrow';
import RightArrow from 'svgComponents/rightArrow';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class PhaseTabs extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tabsContainerRef: React.createRef(),
			scrollWidth: 300,
			selectedTabValue: null,
			showLeftArrow: true,
			showRightArrow: true
		}
	}

	componentDidMount() {
		let selectedTabValue = this.props.tabsList[0].value;
		if (this.props.selectedTabValue) {
			selectedTabValue = this.props.selectedTabValue;
		}
		this.updateSelectedTabValue(selectedTabValue);
		this.checkAndSetArrowVisibility();
	}

	componentWillReceiveProps(nextProps) {
		let selectedTabValue = this.state.selectedTabValue;
		if (nextProps.selectedTabValue) {
			selectedTabValue = nextProps.selectedTabValue;
		}
		this.updateSelectedTabValue(selectedTabValue);
		this.checkAndSetArrowVisibility();
	}

	checkAndSetArrowVisibility = () => {
		const currentDiv = this.state.tabsContainerRef.current;
		let updatedState = {
			showLeftArrow: true,
			showRightArrow: true
		};

		if (currentDiv.scrollLeft === 0) {
			updatedState.showLeftArrow = false;
		}

		if (Math.abs((currentDiv.scrollLeft + currentDiv.clientWidth) - currentDiv.scrollWidth) < 20){
			updatedState.showRightArrow = false;
		}

		this.setState(updatedState);
	}

	updateSelectedTabValue = (selectedTabValue) => {
		this.setState({ selectedTabValue });
	}

	onClickArrow = (arrowType = 'left') => {
		const currentDiv = this.state.tabsContainerRef.current;
		let scrollLeft = currentDiv.scrollLeft + this.state.scrollWidth;
		if (arrowType === 'left') {
			scrollLeft = currentDiv.scrollLeft - this.state.scrollWidth
		}

		TweenMax.to(currentDiv, 1, {
			scrollLeft,
			onUpdate: () => this.checkAndSetArrowVisibility()
		});
	}

	centerTab = (clickedTab, index) => {
		const currentDiv = this.state.tabsContainerRef.current;
		let scrollLeft;
		if(index === 0){
			scrollLeft = 0;
		} else if(index === this.props.tabsList.length - 1){
			scrollLeft = currentDiv.scrollLeft + clickedTab.offsetLeft + clickedTab.scrollWidth;
		} else {
			scrollLeft = clickedTab.offsetLeft - currentDiv.offsetLeft - 200;
		}
		TweenMax.to(currentDiv, 0.8, {
			scrollLeft,
			onUpdate: () => this.checkAndSetArrowVisibility()
		});
	}


	onTabClick = (event, selectedTabValue, index) => {
		if(this.state.showLeftArrow || this.state.showRightArrow) this.centerTab(event.target, index);
		this.updateSelectedTabValue(selectedTabValue);
		this.props.tabClickHandler(selectedTabValue);
	}

	renderTabs = (myStyles) => {
		const tabStyles = this.props.tabStyles;
		return this.props.tabsList.map((tab, index) => {
			let currentTabStyles = tabStyles;
			if(tab.label.length <= 28){
				currentTabStyles = {fontSize: '16px'};
			}

			const tabContainerStyle = tab.value === this.state.selectedTabValue
				? "tab-container"
				: "tab-container unselected-tabs";
			const tabContainerSkinStyle = tab.value === this.state.selectedTabValue ? `${css(myStyles.tabContainer)} ${css(myStyles.activeTabContainer)}`: css(myStyles.tabContainer);
			return (
				<div
					styleName={tabContainerStyle}
					style={currentTabStyles}
					key={tab.value}
					className={tabContainerSkinStyle}
					onClick={(e) => this.onTabClick(e, tab.value, index)}
					title={tab.label}
				>
					<span styleName="tab-content" className="phase-tab-content">
						{tab.statusIcon && <div styleName="phase-status-icon-cnt">
							{tab.statusIcon}
						</div>}
						<div styleName="tab-label" className="phase-tab-label" style={
							tab.statusIcon ? {maxWidth: '153px'} : {}
						}>
							{tab.label}
						</div>
					</span>
				</div>
			);
		});
	}

	renderLeftArrow = () => {
		const myStyles = getSkin(this.props.skinGuide);

		if (!this.state.showLeftArrow) {
			return null;
		}
		return (
			<div styleName="arrow-container arrow-container-left" onClick={this.onClickArrow.bind(this, 'left')} className={css(myStyles.arrowContainer)}>
				<div styleName="arrow-content">
					<LeftArrow fillColor="#D44980"/>
				</div>
			</div>
		);
	}

	renderRightArrow = () => {
		const myStyles = getSkin(this.props.skinGuide);

		if (!this.state.showRightArrow) {
			return null;
		}
		return (
			<div styleName="arrow-container arrow-container-right" onClick={this.onClickArrow.bind(this, 'right')} className={css(myStyles.arrowContainer)}>
				<div styleName="arrow-content">
					<RightArrow fillColor="#D44980"/>
				</div>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="container">
				{this.renderLeftArrow()}
				<div styleName="tabs-container" ref={this.state.tabsContainerRef}>
					{this.renderTabs(myStyles)}
				</div>
				{this.renderRightArrow()}
			</div>
		);
	}
}

PhaseTabs.defaultProps = {
	tabsList: [
		{
			label: 'Dummy Tab 1',
			value: 1
		},
		{
			label: 'Dummy Tab 2',
			value: 2
		},
		{
			label: 'Dummy Tab 3',
			value: 3
		},
		{
			label: 'Dummy Tab 4',
			value: 4
		},
		{
			label: 'Dummy Tab 5',
			value: 5
		}
	],
	selectedTabValue: null,
	tabClickHandler: () => null
};

export default PhaseTabs;