import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setUserBatchesList } from 'actions/batches/actionCreators';
import setUserState from 'actions/userState/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import actionConstants from 'constants/actions';
import { appendQueryParameters } from 'utils/utilFunctions';
import {
	setActiveUserBatchesData,
	setUpcomingUserBatchesData,
	setCompletedUserBatchesData,
	setExpiredUserBatchesData
	// setRequestedUserBatchesData
} from 'actions/userJourneys/actionCreators';

class FetchUserBatches extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	prepareUrl = (apiInfoObject) => {
		return appendQueryParameters(
			urls.get('PLATFORM').get('GET_USER_BATCHES'),
			{ ...apiInfoObject }
		);
	}
	// shouldAjaxCall = () => {
	// 	// if (this.getState().get('batches').get('isUserbatchesListFetched')) {
	// 	//     return false;
	// 	// }
	// 	return true;
	// }

	// raiseErrorIfBatchesListEmpty = () => {
	// 	let errorObj = {
	// 		"message": "User is not present in the batch",
	// 		"error_code": "USER_NOT_FOUND_IN_BATCH",
	// 		"data": {
	// 			"batchId": null
	// 		},
	// 		"stackTrace": null
	// 	};

	// 	console.log('errorobj', errorObj);

	// 	this.dispatch(setUserState({
	// 		userError: {
	// 			message: errorObj.message,
	// 			error_code: errorObj.error_code,
	// 			data: errorObj.data,
	// 			status_code: 400
	// 		}
	// 	}));

	// 	sendUserToInvalidPage();
	// }

	successCall = (response, apiInfoObject) => {
		const myResponse = { ...response };

		const activeBatchId = myResponse.activeBatchId;
		delete myResponse['activeBatchId'];

		const userBatchesList = myResponse.batches;

		// if (userBatchesList && userBatchesList.length == 0) {
		// 	this.raiseErrorIfBatchesListEmpty();
		// }

		delete myResponse['batches'];

		const userBatchesListAdditionalDetails = myResponse;

		const payload = {
			activeBatchId,
			userBatchesList,
			userBatchesListAdditionalDetails
		};

		this.dispatch(setUserBatchesList(payload));
		switch (apiInfoObject.durationStatus) {
			case 'ONGOING':
				this.dispatch(setActiveUserBatchesData(payload));
				break;
			case 'UPCOMING':
				this.dispatch(setUpcomingUserBatchesData(payload));
				break;
			case 'COMPLETED':
				this.dispatch(setCompletedUserBatchesData(payload));
				break;
			case 'EXPIRED':
				this.dispatch(setExpiredUserBatchesData(payload));
				break;
			// case 'REQUESTED', 'REJECTED':
			// 	this.dispatch(setRequestedUserBatchesData(payload));
		}
		this.dispatch(setUserState({
			currentBatchId: activeBatchId
		}))
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, something went wrong. Please refresh the page!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		if (errorObj.apiInfoObject.showErrorPage) {
			sendUserToInvalidPage();
		}
	}

}

export default new FetchUserBatches();
