import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {setQuizSubmissions, setActivAttempt} from 'actions/quiz/actionCreators'
import {setApiError} from 'actions/apiErrors/actionCreators';

class GetQuizSubmissions extends NetPackWrapper {
	constructor() {
		super('GET', true);
	}

	prepareUrl = apiInfoObject => {
		//return urls.get('DEVELOPMENT_PHASE').get('URL_WITH_ERROR');

		// console.log(apiInfoObject)
		return urls.get('DEVELOPMENT_PHASE').get('QUIZ_SUBMISSIONS')
		.replace('_COURSE_ID_', apiInfoObject.courseId)
		.replace('_QUIZ_ID_', apiInfoObject.quizId)
		.replace('_ACCESS_TOKEN_',this.getAccessTokenForDevPhase())
		.replace('_BATCH_ID_', apiInfoObject.batchId)
		.replace('_PHASE_ID_', apiInfoObject.phaseId)
		.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	};

	successCall = (response, apiInfoObject) => {
		this.dispatch(setQuizSubmissions(response));
		this.dispatch(setActivAttempt(response));
		return response;
	}

	onError = (error) => {
		console.log(error);
		this.dispatch(setApiError(
            'fetchQuizError',
            'There was a problem while fetching the content.'
        ));
	}
}

export default new GetQuizSubmissions();