import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './ProfileDropDown.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import UserProfile from 'images/header/user-profile.svg';
import applyLabel from 'utils/LabelProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import { isLaunchedFromExternalIntegration } from 'utils/externalIntegrations';
import { signOutFromALPlus } from 'utils/utilFunctions';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { getOrganizationId } from 'utils/orgUtils';
import { isMobile } from 'react-device-detect';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ProfileDropDown extends React.Component {

    openSupport = () => {
        window.FreshWidget.show();
    }

    sendSegmentData = (event) => {
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash
        };
        window.analytics.track(
            event,
            segmentData,
            getAmplitudeSessionDetails()
        );
    }

    redirectToProfilePage = () => {
        this.sendSegmentData('View Profile clicked');
        const route = routes.get('USER_PROFILE');;
        updateRoute({ route });
    }

    redirectToDownloadsPage = () => {
        this.sendSegmentData('Downloads clicked');
        const route = routes.get('USER_DOWNLOADS');;
        updateRoute({ route });
    }

    doNothing = () => {
        this.props.setUiState({
            showOverlay: false,
            overlayOpacity: 0
        });
    }

    openFAQsLink = () => {
        this.sendSegmentData('FAQs clicked');
        window.open('https://www.knolskape.com/faqs/', '_blank');
    }

    renderProfileOptions(myStyles, options) {
        if(isMobile){
            options = options.filter(option => option.enabledForMobile);
        }
        return options.map((option, index) => {
            return (
                <div
                    key={index}
                    className={css(myStyles.individualItem)}
                    styleName="individual-profile-section"
                    onClick={option.clickHandler}>
                    <div className={css(myStyles.individualItemText)}>
                        {option.label}
                    </div>
                </div>
            );
        });
    }

    logout = () => signOutFromALPlus();

    addLogoutLabel = (optionArray) => {
        optionArray.push({ label: this.props.getLabel('logout_label'), clickHandler: this.logout, enabledForMobile: true });
        return optionArray;
    }

    addSupportLabel = (optionArray) => {
        if (this.props.freshdeskInitialized) {
            optionArray.push({ label: this.props.getLabel('support_label'), clickHandler: this.openSupport });
        }
        return optionArray;
    }

    openSystemCheck = () => {
        this.props.setUiState({
            showSystemCheckDialog: true
        });
    }

    openOrganizationsDialog = () => {
        this.props.setUiState({
            showOrganizationsDialog: true
        });
    }

    render() {
        const { getLabel, skinGuide } = this.props;
        const myStyles = getSkin(skinGuide);
        let options = [
            { label: getLabel('view_profile_label'), clickHandler: this.redirectToProfilePage, enabledForMobile: true },
            { label: getLabel('organizations_list_label'), clickHandler: this.openOrganizationsDialog, enabledForMobile: false },
            { label: getLabel('downloads_label'), clickHandler: this.redirectToDownloadsPage, enabledForMobile: false },
            { label: getLabel('system_check_label'), clickHandler: this.openSystemCheck, enabledForMobile: false }
        ];

        if(getOrganizationId() != '1057'){
            options.push({ label: getLabel('knolskape_faqs_label'), clickHandler: this.openFAQsLink, enabledForMobile: true });
        }

        if (!isLaunchedFromExternalIntegration()) {
            options = this.addLogoutLabel(options);
        }

        return (
            <div styleName="user-profile-container" onClick={this.doNothing}>
                <div styleName="arrow-container">
                    <div className={css(myStyles.arrowUp)} styleName="arrow-up"></div>
                </div>
                <div
                    styleName="user-profile-and-options"
                    className={css(myStyles.profileAndOptionsContainer)}
                >
                    <div
                        styleName="user-profile-section"
                        className={css(myStyles.userProfileContainer)}
                    >
                        <div styleName="user-profile-image-container">
                            <img styleName="user-profile-image" src={this.props.profilePicUrl || UserProfile} />
                        </div>
                        <div styleName="user-profile-details-container">
                            <p className={css(myStyles.userName)} styleName="user-profile-username">
                                {this.props.userName}
                            </p>
                            <p className={css(myStyles.userEmail)} styleName="user-profile-email">
                                {this.props.email}
                            </p>
                        </div>
                    </div>
                    {this.renderProfileOptions(myStyles, options)}
                </div>
            </div>
        );
    }
}

ProfileDropDown.propTypes = {};
ProfileDropDown.defaultProps = {};

export default ProfileDropDown;