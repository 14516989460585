import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        fontStyling: {
            fontFamily: theme.get('fontFamily').get('headingsFamily'),
            fontSize: '24px',
            color: '#d5d9e7',
            fontWeight: 300
        }
    });
}

export default getSkin;