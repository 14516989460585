/**
 * @author: Amul
 * @since: Thu Aug 24 2018 17:22:31 GMT+0530 (IST)
 * @file: moduleItem.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * moduleItem reducer is responsible for moduleItem related actions
 *
 **/

import Immutable from 'immutable';
import actionConstants from 'constants/actions';
import { setOrUpdateObjectInMap, removeObjectFromList } from 'utils/objectUtils';
const initialState = Immutable.fromJS({
    moduleItem: {},
    isModuleItemDataAvailable: false,
    nextModuleItem: {},
    areContentDetailsAvailable: false,
    contentDetails: {},
    productLaunch: null,
    assignmentSubmitted: false,
    fileUploadInProgress: false,
    uploadedFiles: new Immutable.List(),
    uploadedFileNames: new Immutable.List(),
    assignmentSubmitting: false
});

const updateAssignmentSubmission = (state, newSubmission) => {
    const contentDetails = state.get('contentDetails').set('submission', Immutable.fromJS(newSubmission));
    return state.set('assignmentSubmitted', true)
        .set('contentDetails', contentDetails)
        .set('uploadedFiles', new Immutable.List())
        .set('uploadedFileNames', new Immutable.List());
}

const moduleItem = (state = initialState, action) => {
    switch (action.type) {
        case actionConstants.get('SET_MODULE_ITEM'):             
            return state.set(
                'moduleItem',
                Immutable.fromJS(action.moduleItem)
            ).set(
                'nextModuleItem',
                Immutable.fromJS(action.nextModuleItem)
            ).set(
                'isModuleItemDataAvailable',
                true
            );
        case actionConstants.get('SET_MODULE_ITEM_FROM_CACHE'):             
            return state.set(
                'moduleItem',
                Immutable.fromJS(action.moduleItem)
            ).set(
                'nextModuleItem',
                Immutable.fromJS(action.nextModuleItem)
            ).set(
                'contentDetails',
                Immutable.fromJS(action.contentDetails)
            )
            .set(
                'isModuleItemDataAvailable',
                true
            ).set(
                'areContentDetailsAvailable',
                true
            );
        case actionConstants.get('SET_MODULE_ITEM_CONTENT_DETAILS'):
            return state.set('contentDetails', Immutable.fromJS(action.moduleItemContentDetails)).set('areContentDetailsAvailable', true);
        case actionConstants.get('SET_PRODUCT_LAUNCH'):
            return state.set('productLaunch', Immutable.fromJS(action.productLaunch));
        case actionConstants.get('UNSET_PRODUCT_LAUNCH'):
            return state.set('productLaunch', null);
        case actionConstants.get('SET_MODULE_ITEM_AVAILABILITY'):
            return state.set('areContentDetailsAvailable', action.status);          
        case actionConstants.get('SET_MODULE_ITEM_FROM_CACHE'):
            return action.cachedModuleItem;

        case actionConstants.get('SET_MODULE_ITEM_COMPLETION_STATUS'):
            return state.get('moduleItem').get('completion_requirement').set('completed', action.status);

        case actionConstants.get('SET_NEXT_MODULE_LOCK_STATUS'):
            return state.set('nextModuleItem', 
                setOrUpdateObjectInMap(state.get('nextModuleItem'), 'content_details', {locked_for_user: action.status})
            );
        case actionConstants.get('SET_ASSIGNMENT_SUBMITTED'):
            return updateAssignmentSubmission(state, action.newSubmission);  
        case actionConstants.get('ADD_UPLOADED_FILE_TO_LIST'):
            return state.set('uploadedFiles', state.get('uploadedFiles').push(Immutable.fromJS(action.file))).set('fileUploadInProgress', false);
        // case actionConstants.get('ADD_UPLOADED_FILENAME_TO_LIST'):
        //     return state.set('uploadedFileNames', state.get('uploadedFileNames').push(action.name));
        case actionConstants.get('REMOVE_FILE'):
            return state.set(
                'uploadedFiles', removeObjectFromList(state.get('uploadedFiles'), 'id', action.id)
            );
        case actionConstants.get('SET_ASSIGNMENT_SUBMITTING'):
            return state.set('assignmentSubmitting', action.status);
        case actionConstants.get('UNSET_UPLOADED_FILES'):
            return state.set('uploadedFiles', new Immutable.List()).set('uploadedFileNames', new Immutable.List());
        case actionConstants.get('SET_FILE_UPLOAD_IN_PROGRESS'):
            return state.set('fileUploadInProgress', action.status);
        case actionConstants.get('UNSET_MODULE_ITEM'):
            return initialState;
        default:
            return state;
    }
}

export default moduleItem;