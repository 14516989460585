import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setProductLaunchDetails, toggleProductLaunchWindow } from 'actions/items/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';

class FetchItemLaunchDetails extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject = {}) => {
        return appendQueryParameters(
            urls.get('PLATFORM').get('GET_PRODUCT_LAUNCH_DETAILS').replace(
                'ITEM_ID',
                apiInfoObject.itemId
            ),
            apiInfoObject.queryParameters
        );
    }

    shouldAjaxCall = () => {
        // if (this.getState().get('items').get('isItemsListAvailable')) {
        //     return false;
        // }
        //add condition to call accordingly
        return true;
    }

    beforeCall = (apiInfoObject) => {
        if(!apiInfoObject.isEmbeddedLaunch){
            this.dispatch(toggleProductLaunchWindow(true));
        }
        return apiInfoObject;
    }

    successCall = (response) => {
        this.dispatch(setProductLaunchDetails(response));
        return response;
    }

}

export default new FetchItemLaunchDetails();
