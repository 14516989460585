import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { render } from "react-dom";
import { Group } from '@vx/group';
import { scaleLinear, scaleOrdinal } from '@vx/scale';
import { Point } from '@vx/point';
import { Line, LineRadial } from '@vx/shape';
import { max, min } from 'd3-array';
import { LinearGradient } from '@vx/gradient';
import { Text } from '@vx/text';
import { localPoint } from "@vx/event";
import { withTooltip, Tooltip } from "@vx/tooltip";
import * as Curve from '@vx/curve';
import { GlyphDot } from '@vx/glyph';
import { textTruncate } from 'utils/utilFunctions';
import { getGradientBounds, getRoundedRect, getFlexDirectionForGraphLegend } from 'utils/analytics/utilFunctions';
import GraphLegendComponent from '../../analytics/graphLegendComponent';
import CSSModules from 'react-css-modules';
import styles from './radarGraph.module.sass';
import InfoComponent from 'commonComponents/analytics/infoComponent';
import { checkMetadata } from 'utils/analytics/utilFunctions';

@CSSModules(styles, { allowMultiple: true })
class RadarGraph extends Component {

    ANG = 360;
    margin = { top: 50, left: 0, right: 0, bottom: 80 };
    height = 'height' in this.props ? this.props.height : 500;

    getId = d => d.id;
    getUserData = d => d.userValue;
    getGroupData = d => d.groupValue;
    getItemName = d => d.itemName;

    constructor(props) {
        super(props);
        this.handleTooltip = this.handleTooltip.bind(this);
        this.state = {
            showLabelTooltip: false,
            labelTooltipData: null
        }
    }

    getData = () => {
        const graphData = this.props.graphData ? this.props.graphData : this.props.data;
        const data = graphData.data;
        const metadata = this.props.graphData ? this.props.graphData.metadata : {};
        const strings = this.props.strings;
        return {
            data: data,
            metadata: metadata,
            strings: strings
        };
    };

    handleTooltip = (event, data) => {
        const { showTooltip } = this.props;
        const { x, y } = localPoint(event.target.ownerSVGElement, event);
        const graphData = this.getData().data;
        const radius = min([this.getXMax(), this.getYMax()]) / 2;
        const points = this.calcPoints(graphData.length, radius);

        let dataIndex;
        graphData.map((val, i) => { if (this.getId(val) == this.getId(data.data)) dataIndex = i; })
        dataIndex = (dataIndex < points.length - 1) ? dataIndex + 1 : 0;

        const xMax = this.getXMax();
        const yMax = this.getYMax();
        let leftOffset = 0, topOffset = 0;

        if (Math.round(points[dataIndex].x) < 0) {
            leftOffset = 100;
        }
        if (Math.round(points[dataIndex].y) < 0) {
            topOffset = 40;
        }
        if (Math.round(points[dataIndex].x) == 0) {
            leftOffset = 50;
        }
        if (Math.round(points[dataIndex].y) == 0) {
            topOffset = 20;
        }

        const tooltipData = {
            ...data,
            data: {
                ...data.data,
                value: data.isUserMetric ? data.data.userValue : data.data.groupValue
            }
        };

        showTooltip({
            tooltipLeft: points[dataIndex].x - leftOffset,
            tooltipTop: points[dataIndex].y - topOffset,
            tooltipData: tooltipData
        });
    }

    getTextAnchorValue = (points, i) => {
        if (Math.round(points.x) > 0) {
            return "start"
        }
        if (Math.round(points.x) == 0) {
            return "middle"
        }
        if (Math.round(points.x) < 0) {
            return "end"
        }
    }

    getWidth = () => {
        const calcWidth = (this.props.barStyling.gridValue / 12) * this.props.size.width;
        let width = calcWidth < 250 ? 250 : calcWidth;
        if (this.props.legendPosition == 'left' || this.props.legendPosition == 'right') {
            width = width * 0.8;
        }
        return width;
    }

    calcAxis = (length) => {
        if (!length) return [];
        return new Array(length + 1)
            .fill(0)
            .map((v, i) => ({ angle: i * (this.ANG / length) }));
    };

    calcPoints = (length, radius) => {
        const step = Math.PI * 2 / length;
        return new Array(length).fill(0).map((v, i) => {
            return {
                x: radius * Math.sin(i * step),
                y: -1 * radius * Math.cos(i * step),
            };
        });
    };

    calcCoordinates = (data, scale, access) => {
        const step = Math.PI * 2 / data.length;
        const points = new Array(data.length).fill({});
        const pointStr = new Array(data.length + 1)
            .fill('')
            .reduce((res, v, i) => {
                if (i > data.length) return res;
                const x = scale(access(data[i - 1])) * Math.sin(i * step);
                const y = scale(access(data[i - 1])) * -1 * Math.cos(i * step);
                points[i - 1] = { x, y };
                return (res += `${x},${y} `);
            });
        points.str = pointStr;

        return points;
    }

    getPolyPoints = (data, access) => (
        this.calcCoordinates(data, this.getYScale(), access)
    )

    getXMax = () => (
        (this.getWidth() - this.margin.left - this.margin.right)
    );

    getYMax = () => {
        let height = this.height;
        if (this.props.legendPosition == 'top' || this.props.legendPosition == 'bottom')
            height = this.height * 0.85;
        return height - this.margin.top - this.margin.bottom;
    };

    getRScale = () => (
        scaleLinear({
            range: [0, Math.PI * 2],
            domain: [this.ANG, 0]
        })
    );

    getYScale = () => {
        const radius = min([this.getXMax(), this.getYMax()]) / 2;
        const { data, metadata } = this.getData();
        // const maxVal = max(data, this.getUserData) > max(data, this.getGroupData) ? max(data, this.getUserData) : max(data, this.getGroupData);
        const maxVal = metadata.max;
        return (
            scaleLinear({
                range: [0, radius],
                domain: [0, maxVal]
            })
        );
    }

    renderPolygon = (polyPoints, fillId) => (
        <polygon
            points={polyPoints.str}
            fill={`url(#${fillId})`}
            fillOpacity="1"
            stroke={`url(#${fillId}`}
            strokeWidth={1}
        />
    );

    renderPolygonPoints = (polyPoints, fill, toolTipFill, isUserMetric = false) => {
        const data = this.getData().data;
        const hideTooltip = this.props.hideTooltip;
        return polyPoints.map((v, j) => {
            const nextIndex = (j < polyPoints.length - 1) ? j + 1 : 0;
            const nextPoint = polyPoints[nextIndex];
            return (
                <Group key={Math.random()}>
                    <Line
                        from={v}
                        to={nextPoint}
                        stroke={fill}
                        strokeWidth={1}
                    />
                    <GlyphDot
                        key={`point-${isUserMetric ? 'user' : 'group'}-${j}`}
                        cx={v.x}
                        cy={v.y}
                        r={isUserMetric ? 5 : 3}
                        fill={fill}
                    />
                    <GlyphDot
                        key={`point-${isUserMetric ? 'user' : 'group'}-${j}-hidden`}
                        cx={v.x}
                        cy={v.y}
                        r={isUserMetric ? 6 : 3}
                        onMouseOut={event => { hideTooltip() }}
                        onMouseLeave={event => { hideTooltip() }}
                        onMouseEnter={event => {
                            this.handleTooltip(
                                event,
                                {
                                    data: data[j],
                                    tooltipFillValue: toolTipFill,
                                    labelFillValue: fill,
                                    isUserMetric: isUserMetric
                                })
                        }}
                        fill='transparent'
                    />
                </Group>
            )
        });
    }

    renderGraph = () => {
        const { barStyling } = this.props;
        const userId = "user-gradient" + barStyling.componentName;
        const groupId = "group-avg-gradient" + barStyling.componentName;

        const { data } = this.getData();
        const userPolyPoints = this.getPolyPoints(data, this.getUserData);
        const groupPolyPoints = this.getPolyPoints(data, this.getGroupData);

        const tooltipUserLegendFillValue = `linear-gradient(286deg, ${barStyling.fromUser}, ${barStyling.toUser})`;
        const tooltipGroupLegendFillValue = `linear-gradient(286deg, ${barStyling.fromGroup}, ${barStyling.toGroup})`;

        return (
            <Group className="graph">
                {this.renderPolygon(groupPolyPoints, groupId)}
                {this.renderPolygon(userPolyPoints, userId)}
                {this.renderPolygonPoints(userPolyPoints, barStyling.fromUser, tooltipUserLegendFillValue, true)}
                {this.renderPolygonPoints(groupPolyPoints, barStyling.fromGroup, tooltipGroupLegendFillValue, false)}
            </Group>
        );
    }

    renderTooltip = () => {
        const { tooltipOpen, tooltipTop, tooltipLeft, tooltipData, graphFormat } = this.props;
        if (!tooltipOpen)
            return;

        const { strings } = this.getData();
        const xMax = this.getXMax();
        const yMax = this.getYMax();
        const tooltipStyling = {
            fontFamily: 'Open Sans',
            fontSize: '12px',
            color: 'rgba(9, 16, 39, 0.85)',
            width: '100px',
            height: '40px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.5)'
        };

        return (
            <Tooltip
                key={Math.random()}
                top={tooltipTop + yMax / 2 + this.margin.top}
                left={tooltipLeft + xMax / 2 + this.margin.left}
                style={{
                    ...tooltipStyling,
                    background: tooltipData.tooltipFillValue,
                }}
            >
                <div styleName="tooltip-header">{this.getItemName(tooltipData.data)}</div>
                <div styleName="tooltip-content">{strings.pointsLabel.replace('PLACEHOLDER', tooltipData.data.value)}</div>
            </Tooltip>
        );
    }

    renderGraphComponent = () => {
        const legendFontSize = this.props.legendPosition == 'left' || this.props.legendPosition == 'right' ? '12px' : '16px';
        const legendLabelDirection = this.props.legendPosition == 'left' || this.props.legendPosition == 'right' ? 'column' : 'row';
        const legendItemMargin = this.props.legendPosition == 'left' || this.props.legendPosition == 'right' ? "0 0 9px 0" : "0 24px 0 0";
        const legendStyling = {
            display: 'flex',
            justifyContent: 'center',
            fontSize: legendFontSize,
            fontFamily: 'Open Sans',
            color: '#979eb7',
            direction: legendLabelDirection,
            itemDirection: "row",
            labelMargin: "0",
            shapeMargin: "0 8px 0 0",
            itemMargin: legendItemMargin,
            shapeWidth: 18,
            shapeHeight: 18
        };

        const {
            tooltipData,
            tooltipOpen,
            barStyling,
            graphFormat
        } = this.props;

        const { data, metadata, strings } = this.getData();
        const { showLabelTooltip, labelTooltipData } = this.state;
        const width = this.getWidth();
        const height = this.height;

        // const maxVal = max(data, this.getUserData) > max(data, this.getGroupData) ? max(data, this.getUserData) : max(data, this.getGroupData);
        const levels = metadata.max;

        const xMax = this.getXMax();
        const yMax = this.getYMax();

        const webs = this.calcAxis(data.length);
        const radius = min([xMax, yMax]) / 2;

        const points = this.calcPoints(data.length, radius);

        const legendLabels = [strings.yourScoreLabel, strings.groupScoreLabel];
        const legendSize = scaleOrdinal({
            domain: legendLabels,
            range: [18]
        });

        const legendShape = scaleOrdinal({
            domain: legendLabels,
            range: [
                props => {
                    const { width, height, size, fill } = props;
                    return (<GlyphDot
                        r={size / 2}
                        top={width / 2}
                        left={height / 2}
                        fill={fill}
                    />)
                }
            ],
        });

        const rScale = this.getRScale();
        const yScale = this.getYScale();

        const polarToX = (angle, distance) => Math.cos(angle - Math.PI / 2) * distance;
        const polarToY = (angle, distance) => Math.sin(angle - Math.PI / 2) * distance;

        const userId = "user-gradient" + barStyling.componentName;
        const groupId = "group-avg-gradient" + barStyling.componentName;

        const userGradientBounds = getGradientBounds(barStyling.userRotation ? barStyling.userRotation : 0);
        const groupGradientBounds = getGradientBounds(barStyling.groupRotation ? barStyling.groupRotation : 0);

        const flexDirection = getFlexDirectionForGraphLegend(this.props.legendPosition);
        return (
            <div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '50px', flexDirection: flexDirection }}>
                <svg width={width} height={height}>
                    <LinearGradient
                        from={barStyling.fromUser ? barStyling.fromUser : 'green'}
                        to={barStyling.toUser}
                        fromOpacity={0.6}
                        toOpacity={0.6}
                        {...userGradientBounds}
                        id={userId}
                    />
                    <LinearGradient
                        from={barStyling.fromGroup}
                        to={barStyling.toGroup}
                        fromOpacity={0.8}
                        toOpacity={0.8}
                        {...groupGradientBounds}
                        id={groupId}
                    />
                    <LinearGradient
                        from={barStyling.fromUser ? barStyling.fromUser : 'green'}
                        to={barStyling.toUser}
                        id={`legend-0-${barStyling.componentName}`}
                    />
                    <LinearGradient
                        from={barStyling.fromGroup}
                        to={barStyling.toGroup}
                        id={`legend-1-${barStyling.componentName}`}
                    />

                    <Group top={yMax / 2 + this.margin.top} left={xMax / 2 + this.margin.left}>
                        {[...new Array(levels)].map((v, i) => (
                            <LineRadial
                                data={webs}
                                key={`web-${i}`}
                                angle={d => rScale(d.angle)}
                                radius={(i + 1) * radius / levels}
                                fill="rgba(255, 255, 255, 0.045)"
                                stroke="rgba(211, 211, 211, 0.4)"
                                strokeDasharray="1,3"
                                strokeWidth={1}
                                strokeOpacity={0.8}
                                strokeLinecap="round"
                            // curve={Curve.curveNatural}
                            />
                        ))}
                        {data.map((radarData, i) => {
                            const nextIndex = (i < data.length - 1) ? i + 1 : 0;
                            return (
                                <Group key={Math.random()}>
                                    <Line
                                        key={`line-${i}`}
                                        from={{ x: 0, y: 0 }}
                                        to={points[nextIndex]}
                                        stroke={tooltipOpen && this.getId(tooltipData.data) == this.getId(radarData) ? tooltipData.labelFillValue : "rgba(211, 211, 211, 0.4)"}
                                        strokeDasharray={tooltipOpen && this.getId(tooltipData.data) == this.getId(radarData) ? "" : "1,3"}
                                        strokeWidth={tooltipOpen && this.getId(tooltipData.data) == this.getId(radarData) ? "2" : "1"}
                                    />
                                    <Text
                                        fill={tooltipOpen && this.getId(tooltipData.data) == this.getId(radarData) ? tooltipData.labelFillValue : 'white'}
                                        x={points[nextIndex].x * 1.1}
                                        y={points[nextIndex].y * 1.1}
                                        textAnchor={this.getTextAnchorValue(points[nextIndex], i)}
                                        // x={polarToX(i, (450 / 2) * 0.95).toFixed(1)}
                                        // y={polarToY(i, (450 / 2) * 0.95).toFixed(1)}
                                        dy={5}
                                        onMouseOver={(event) => {
                                            const { x, y } = localPoint(event.target.ownerSVGElement, event);
                                            this.setState({
                                                showLabelTooltip: true,
                                                labelTooltipData: {
                                                    x: x,
                                                    y: y,
                                                    text: this.getItemName(radarData)
                                                }
                                            })
                                        }}
                                        onMouseOut={(event) => {
                                            this.setState({
                                                showLabelTooltip: false,
                                                labelTooltipData: null
                                            })
                                        }}
                                    >
                                        {textTruncate(this.getItemName(radarData), 10)}
                                    </Text>
                                </Group>
                            )
                        })}
                        {this.renderGraph()}
                    </Group>
                </svg>
                <GraphLegendComponent
                    style={legendStyling}
                    shape={legendShape}
                    size={legendSize}
                    componentName={barStyling.componentName}
                />
                {this.renderTooltip()}
                {showLabelTooltip && (
                    <Tooltip
                        key={Math.random()}
                        top={yMax / 2 + this.margin.top + labelTooltipData.y}
                        left={labelTooltipData.x + xMax / 2 + this.margin.left}
                        style={{
                            color: '#31384B',
                            background: '#979eb7',
                        }}
                    >
                        {labelTooltipData.text}
                    </Tooltip>
                )}
            </div>
        );
    }

    validateMetadata = () => {
        const { metadata } = this.getData();

        if (!checkMetadata(metadata)) {
            return false;
        }

        if (metadata.min % 1 !== 0 || metadata.max % 1 !== 0) {
            return false;
        }

        return true;
    }

    render() {
        const { data } = this.getData();
        if (data.length <= 2 || !this.validateMetadata()) {
            return <InfoComponent
                {...this.props}
                infoMessage="This graph does not have data"
            />
        }
        return (this.renderGraphComponent());
    }
}

RadarGraph.propTypes = {
    size: PropTypes.object,
    data: PropTypes.object,
    barStyling: PropTypes.object,
    graphFormat: PropTypes.object,
    legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
}

RadarGraph.defaultProps = {
    size: {
        width: 500,
        height: 500
    },
    legendPosition: 'bottom'
}

export default withTooltip(RadarGraph);
