import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    
	return StyleSheet.create({
        infoContainerStyle: {
            color: '#979eb7'
        },

        textStyle: {
            fontFamily: theme.get('fontFamily').get('headingsFamily'),
            fontSize: '16px'
        }
    });
}

export default getSkin;