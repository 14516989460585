import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        dialogText: {
            ...myTypography.body2,
            color: 'rgba(9, 16, 39, 0.85)',
            lineHeight: 'normal'
        },
        okayButton: {
            ...myTypography.button,
            color: skin.get('secondaryColor')
        },
        cancelButton: {
            ...myTypography.button,
            color: skin.get('primaryColor')
        },
        loadingText: {
            ...myTypography.button,
            color: '#abadb5',
            lineHeight: 'normal'
        }
    });
}

export default getSkin;