import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = false;

const isPlatformDataAvailableForDevelopmentPhase = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_PLATFORM_DATA_FOR_DEV_PHASE_AVAILABILITY'):
            return action.status;
        default:
            return state;
    }
};

export default isPlatformDataAvailableForDevelopmentPhase;