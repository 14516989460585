import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import {isIE11} from 'utils/browser';
import getSkin from './skin';
import applySkin from 'utils/SkinProvider';
import styles from './ie11Warning.module.sass';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class IE11Warning extends Component {

    renderCloseIcon = () => {
        return <span onClick={this.closeIE11Warning} className="cursor-pointer"><svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.9579 5.07369C5.9482 5.06401 5.9405 5.05252 5.93525 5.03986C5.93 5.02721 5.9273 5.01364 5.9273 4.99994C5.9273 4.98624 5.93 4.97267 5.93525 4.96002C5.9405 4.94736 5.9482 4.93587 5.9579 4.92619L9.81717 1.06718C9.93435 0.949826 10.0001 0.790727 10 0.624881C9.99988 0.459035 9.93389 0.300028 9.81654 0.18284C9.69919 0.0656526 9.5401 -0.000117047 9.37426 1.56378e-07C9.20842 0.00011736 9.04942 0.0661118 8.93224 0.183465L5.07339 4.04081C5.06371 4.05051 5.05222 4.05821 5.03956 4.06346C5.02691 4.06871 5.01334 4.07141 4.99964 4.07141C4.98594 4.07141 4.97238 4.06871 4.95972 4.06346C4.94707 4.05821 4.93558 4.05051 4.9259 4.04081L1.06705 0.183465C1.00903 0.125412 0.940137 0.079357 0.864316 0.0479286C0.788494 0.0165001 0.707226 0.000314108 0.625149 0.000294763C0.459388 0.000255695 0.300401 0.0660692 0.183163 0.183257C0.0659248 0.300445 3.90843e-05 0.459408 1.73834e-08 0.625175C-3.90495e-05 0.790943 0.0657717 0.949937 0.182955 1.06718L4.04139 4.92619C4.05109 4.93587 4.05878 4.94736 4.06404 4.96002C4.06929 4.97267 4.07199 4.98624 4.07199 4.99994C4.07199 5.01364 4.06929 5.02721 4.06404 5.03986C4.05878 5.05252 4.05109 5.06401 4.04139 5.07369L0.182955 8.93312C0.124932 8.99117 0.0789105 9.06008 0.0475191 9.13592C0.0161277 9.21176 -1.93266e-05 9.29304 1.73834e-08 9.37512C3.90843e-05 9.54089 0.0659248 9.69985 0.183163 9.81704C0.241213 9.87506 0.310124 9.92109 0.38596 9.95248C0.461796 9.98387 0.543073 10 0.625149 10C0.79091 9.99996 0.949866 9.93407 1.06705 9.81683L4.9259 5.9574C4.93558 5.9477 4.94707 5.94 4.95972 5.93475C4.97238 5.9295 4.98594 5.9268 4.99964 5.9268C5.01334 5.9268 5.02691 5.9295 5.03956 5.93475C5.05222 5.94 5.06371 5.9477 5.07339 5.9574L8.93224 9.81683C9.04942 9.93407 9.20838 9.99996 9.37414 10C9.5399 10 9.69889 9.93423 9.81612 9.81704C9.93336 9.69985 9.99925 9.54089 9.99929 9.37512C9.99933 9.20935 9.93352 9.05036 9.81633 8.93312L5.9579 5.07369Z" fill="white"/>
        </svg></span>;
    }


    getOldBrowserIcon = () => {
        return <svg styleName="old-browser-icon" width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.8">
                <path d="M10.975 15.25C13.336 15.25 15.25 13.336 15.25 10.975C15.25 8.61393 13.336 6.69995 10.975 6.69995C8.61398 6.69995 6.7 8.61393 6.7 10.975C6.7 13.336 8.61398 15.25 10.975 15.25Z" stroke="#ffffff" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.94321 12.4H15.0068" stroke="#ffffff" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.94321 9.55005H15.0068" stroke="#ffffff" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.8971 6.80005C12.232 8.00479 12.4012 9.24956 12.4 10.5C12.4027 12.1054 12.124 13.6989 11.5767 15.2082M10.0529 6.80005C9.28572 9.56841 9.3986 12.5068 10.3759 15.2082L10.0529 6.80005Z" stroke="#ffffff" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M14.3 5.275V2.02347C14.2993 1.75234 14.1914 1.4925 13.9997 1.30073C13.8081 1.10895 13.5483 1.00084 13.2772 1H2.01777C1.7481 1.00084 1.48971 1.10833 1.29902 1.29902C1.10833 1.48971 1.00084 1.7481 1 2.01777V11.8522C1.00084 12.1232 1.10887 12.3829 1.3005 12.5745C1.49214 12.7661 1.75182 12.8742 2.02283 12.875H4.8" stroke="#ffffff" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1 3.84998H14.3" stroke="#ffffff" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M4.80014 1V3.85" stroke="#ffffff" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.65015 1V3.85" stroke="#ffffff" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
      </svg>
    }
    
    renderIE11Warning = () => {
        const styles = getSkin(this.props.skinGuide);
        return isIE11() ?  <div styleName="ie11-warning" className={css(styles.ie11Warning)}>
            {this.getOldBrowserIcon()} We’ve detected that you are using IE 11. Please use Google Chrome or Firefox for a better experience.
        </div> : null;
    }

    closeIE11Warning = () => {
        this.props.setUserState({ie11WarningAcknowledged: "true"});
        window.localStorage.setItem('ie11warningack', true);
    }

    render() {
        const styles = getSkin(this.props.skinGuide);
        
        if(isIE11()){
            return <div styleName="ie11-warning" className={css(styles.ie11Warning)}>
                <span>{this.getOldBrowserIcon()} We’ve detected that you are using IE 11. Please use Google Chrome or Firefox for a better experience.</span>
                {this.renderCloseIcon()}
            </div>;
        }

        return null;
    }
}

export default IE11Warning;