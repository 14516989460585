import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css, StyleSheet } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import ErrorSomethingWrong from 'svgComponents/errorSomethingWrong';
import getSkin from './skin';
import styles from './apiErrorBoundary.module.sass';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class APIErrorBoundary extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const styles = getSkin(this.props.skinGuide);
        const {getLabelWithDefaults, apiError, retryHandler} = this.props;
        const propsStyle = this.props.style || {};

        const hasError = this.props.apiError;
        const errorMessage = apiError || "Something went wrong"  
        if (hasError) {
            return <div style={{
                    margin: '20px auto',
                    textAlign: 'center',
                    ...propsStyle
                }}>                    
                    
                    <div styleName="error-image-cnt">
                        <ErrorSomethingWrong/>
                    </div>
                    
                    <div className={css(styles.errorHeading)} styleName="error-heading"> 
                        {getLabelWithDefaults('error_uh_oh_label', 'Uh, oh!')}
                    </div>
                    
                    <div className={css(styles.errorMessage)} styleName="error-message">
                        {getLabelWithDefaults('component_error_message_label', 'Something went wrong')}
                    </div>
                    
                    <a className={css(styles.retryLink)} styleName="retry-link" onClick={retryHandler}>
                        {getLabelWithDefaults('component_try_again_label', 'Try again')}
                    </a>
            </div>
        }
        return this.props.children;
    }
}

export default APIErrorBoundary;