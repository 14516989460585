import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setUserMeasuresList } from 'actions/measures/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchUserMeasures extends NetPackWrapper {

    constructor() {
        super("GET", true);
    }

    prepareUrl = (apiInfoObject = {}) => {
        let url =  urls.get('PLATFORM').get('GET_USER_MEASURES');
        let condition = [true, false][Math.floor(Math.random() * Math.floor(2))];

        if(false){
			url =  urls.get('DEVELOPMENT_PHASE').get('URL_WITH_ERROR');
        }
        
        return appendQueryParameters(
            url,
            apiInfoObject.queryParameters
        );
    }

    successCall = (response, apiInfoObject) => {
        const myResponse = { ...response };

        const userMeasuresList = myResponse.measures;
        delete myResponse['measures'];

        const userLowScoresList = myResponse.low;
        delete myResponse['low'];

        const userHighScoresList = myResponse.high;
        delete myResponse['high'];

        const userMeasuresListAdditionalDetails = myResponse;

        const payload = {
            phaseId: +apiInfoObject.queryParameters.phaseId,
            userMeasuresList,
            userMeasuresListAdditionalDetails,
            userLowScoresList,
            userHighScoresList
        };
        
        this.dispatch(setUserMeasuresList(payload));
        this.dispatch(setApiError('fetchUserMeasuresError', null));
        return response;
    }

    onError = (errorObj) => {
        // this.dispatch(addToast({
        //     id: toastConstants.get('ID_ERROR'),
        //     type: toastConstants.get('TYPE_ERROR'),
        //     heading: 'Something went wrong',
        //     message: 'Sorry, something went wrong. Please refresh the page!',
        //     isDisplayed: false,
        //     autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        // }));
        // sendUserToInvalidPage();
        this.dispatch(setApiError(
            'fetchUserMeasuresError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchUserMeasures();
