import React, { Component } from 'react';
import styles from './phaseContent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import PhaseItem from './phaseItem';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import PhaseItemCardLoader from 'commonComponents/loaders/phaseItemCardLoader';
import CircleTick from 'svgComponents/circleTick';
import getDotColor from 'utils/dotColor';
import routes from 'utils/webAppRoutes/routeConstants';
import enumConstants from 'constants/enums';
import { filterObjectFromArray } from 'utils/objectUtils';
import { checkIfPresent } from 'utils/utilFunctions';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import LockIcon from 'svgComponents/lockIcon';
import CompletedFeedbackIcon from 'svgComponents/completedFeedbackIcon';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PhaseContent extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showAssessmentReportBar: false
		};
	}

	componentDidMount() {
		this.setAssessmentReportBarStatus();
	}

	componentWillReceiveProps() {
		this.setAssessmentReportBarStatus();
	}

	setAssessmentReportBarStatus = () => {
		if (
			this.props.userPhase.size !== 0
			&& this.props.userPhase.get(0).get('workflowState') == enumConstants.get('COMPLETED')
			&& this.props.currentPhase
			&& this.props.currentPhase.get('type') !== enumConstants.get('LEARNING')
		) {
			this.setState({
				showAssessmentReportBar: true
			});
			this.props.setUserState({
				makeReportAvailable: true
			});
		}
	}

	getUserItem = (userItems, item) => {
		let myUserItem = userItems.find((eachItem) => {
			return (eachItem.get('id') === item.get('id'));
		});
		if (myUserItem) {
			return {
				percentageCompletion: myUserItem.get('percentageCompletion'),
				workflowStatus: myUserItem.get('workflowStatus'),
				isSelected: this.checkIfItemIsSelected(item.get('id'))
			};
		}
		return {
			percentageCompletion: 0,
			workflowStatus: enumConstants.get('PENDING'),
			isSelected: this.checkIfItemIsSelected(item.get('id'))
		};
	}

	checkIfItemIsSelected = (id) => {
		const { activeItemId } = this.props;
		if (id == activeItemId) {
			return true;
		} else {
			return false;
		}
	}

	getDotClassName = (userItems, item, myStyles) => {
		const id = item.get('id');
		const { activeItemId } = this.props;
		const statusObj = this.getUserItem(userItems, item);

		return css(getDotColor(
			statusObj.isSelected,
			item.get('isLocked') || item.get('isExpired'),
			statusObj.workflowStatus,
			this.props.skinGuide
		));


	}

	isSelectedItem = (userItems, item) => {
		const statusObj = this.getUserItem(userItems, item);
		if (statusObj.isSelected) {
			return true;
		}
		return false;
	}

	renderPhaseTimeline = (phaseItems, userItems, myStyles) => {
		return phaseItems.map((eachItem, index) => {
			return (
				<div key={`phaseItemDot-${index}`} styleName="individual-phase-timeline-container">
					<div styleName="individual-timeline-content">
						{
							this.isSelectedItem(userItems, eachItem)
								? <div className={css(myStyles.itemSelected)} styleName="individual-timeline-item-selected">
									<div
										className={this.getDotClassName(userItems, eachItem, myStyles)}
										styleName="individual-timeline-item">
									</div>
								</div>
								: <div
									className={this.getDotClassName(userItems, eachItem, myStyles)}
									styleName="individual-timeline-item">
								</div>
						}
					</div>
				</div>
			);
		});
	}

	isThePhaseLocked = () => {
		const phaseId = this.props.userPhase.get(0).get('id');
		const phaseDetails = filterObjectFromArray(
			this.props.phases.get('phasesList'), 'id', phaseId
		);

		try {
			if (phaseDetails.get('isLocked') || (phaseDetails.get('isExpired') && this.props.userPhase.get(0).get('workflowState') !== enumConstants.get('COMPLETED'))) {
				return true;
			}
		} catch (e) {

		}
		return false;
	}

	shouldEnableFeedback = () => {

		if (this.isThePhaseLocked()) {
			return false;
		}

		let allItemsCompleted = true;
		const { phaseItems, userItems } = this.props;

		for (let i = 0; i < phaseItems.size; i++) {
			const statusObj = this.getUserItem(userItems, phaseItems.get(i))
			if (statusObj.workflowStatus !== enumConstants.get('COMPLETED')) {
				allItemsCompleted = false;
				break;
			}
		}

		return allItemsCompleted;
	}

	getFeebackTimelineItem = () => {
		const phaseId = this.props.userPhase.get(0).get('id');
		const phaseDetails = filterObjectFromArray(
			this.props.phases.get('phasesList'), 'id', phaseId
		);
		const { feedbackStatuses } = this.props;
		if (
			phaseDetails
			&& phaseDetails.getIn(['settings', 'feedbackScenarioId'])
			&& phaseDetails.getIn(['settings', 'isFeedbackEnabled']) === 'true'
		) {
			const phaseFeedbackStatus = feedbackStatuses.get('phaseFeedbackStatuses').find(phaseFeedback => phaseFeedback.get('id') === phaseDetails.get('id'))

			let dotClass = css(getDotColor(
				phaseFeedbackStatus && phaseFeedbackStatus.get('status') ? false : this.shouldEnableFeedback(),
				false,
				phaseFeedbackStatus && phaseFeedbackStatus.get('status') ? 'COMPLETED' : null,
				this.props.skinGuide
			))
			return (
				<div key={`phaseItemDot-feedback`} styleName="individual-phase-timeline-container feedback">
					<div styleName="individual-timeline-content">
						<div
							className={dotClass}
							styleName="individual-timeline-item"
						>
						</div>
					</div>
				</div>
			);
		}
		return null
	}

	getItemPhaseStatus = (phaseId) => {
		const userPhase = filterObjectFromArray(
			this.props.phases.get('userPhasesList'), 'id', phaseId
		);
		return userPhase.get('workflowState');
	}

	goToItemPage = (path, item) => {
		this.props.setUserState({
			currentItemId: item.get('id'),
			currentPhaseId: item.get('phaseId')
		});

		const route = routes.get('ITEM_FROM_PATH');
		const params = [{ 'PATH': path }, { 'ITEM_ID': item.get('id') }]
		const routeObj = { route, params };
		const itemPhaseStatus = this.getItemPhaseStatus(item.get('phaseId'));
		if (itemPhaseStatus && itemPhaseStatus == enumConstants.get('NOT_STARTED')) {
			this.props.setPhaseWorkflowStatus(
				item.get('batchId'), item.get('phaseId'), enumConstants.get('STARTED'), routeObj
			);
		}
		else {
			updateRoute(routeObj);
		}
	}

	goToItemFeedback = (path, item) => {
		this.props.setUserState({
			currentItemId: item.get('id'),
			currentPhaseId: item.get('phaseId')
		});

		const route = routes.get('ITEM_FEEDBACK');
		console.log('debug: course dashboard, navigating to item feedback');
		const params = [{ 'PATH': path }, { 'ITEM_ID': item.get('id') }]
		updateRoute({ route, params });
	}

	goToPhaseFeedback = (isFeedbackEnabled) => {
		if (isFeedbackEnabled) {
			const phaseId = this.props.userPhase.get(0).get('id');
			const phaseDetails = filterObjectFromArray(
				this.props.phases.get('phasesList'), 'id', phaseId
			);

			if (phaseDetails) {
				const batchId = phaseDetails.get('batchId');
				const route = routes.get('PHASE_FEEDBACK');
				const params = [{ 'BATCH_ID': batchId }, { 'PHASE_ID': phaseId }];
				const routeObj = { route, params };
				updateRoute(routeObj);
			} else {
				console.log("phaseDetails not available")
			}
		}
	}

	sendSegmentData = (item, statusObj) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			itemName: item.get('name'),
			itemId: item.get('id'),
			itemType: item.get('itemType'),
			phaseId: item.get('phaseId'),
			journeyId: item.get('journeyId'),
			batchId: item.get('batchId'),
			itemLocked: item.get('isLocked'),
			itemExpired: item.get('isExpired'),
			itemCompleted: statusObj.workflowStatus == enumConstants.get('COMPLETED'),
		}
		window.analytics.track(
			'Phase Item Clicked - Type: Assessment',
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	checkAndLaunchItem = (userItems, item) => {
		// logic to handle item completed status 
		const path = this.props.location.pathname;
		const statusObj = this.getUserItem(userItems, item);

		this.sendSegmentData(item, statusObj)

		if (item.get('isLocked')
			|| (item.get('isExpired') && statusObj.workflowStatus !== enumConstants.get('COMPLETED'))) {
			return;
		}

		if (item.get('type') === 'ATTACHMENT' || item.get('type') === 'PAGE' || item.get('type') === 'QUIZ') {
			return this.goToItemPage(path, item);
		}

		if (statusObj.workflowStatus == enumConstants.get('COMPLETED')) {
			this.goToItemFeedback(path, item);
			return;
		}

		// logic to handle item expired status
		// if (item.get('isExpired')) {
		// 	this.goToItemPage(path, item);
		// 	return;
		// }

		//move to item page
		this.goToItemPage(path, item);
	}

	getAttachmentDetails = (phaseItem, attachmentsList) => {
		if (phaseItem.get('type') === 'ATTACHMENT') {
			return attachmentsList.find(attachment => attachment.get('uuid') === phaseItem.get('attachmentId'))
		}
		return null;
	}

	renderPhaseItems = (phaseItems, userItems, myStyles) => {
		const { attachmentsList } = this.props;

		return phaseItems.map((eachItem, index) => {
			return (
				<div
					key={`individual-phase-item-container-${index}`}
					styleName="individual-phase-item-container"
					onClick={() => this.checkAndLaunchItem(userItems, eachItem)}>
					<PhaseItem
						details={eachItem}
						attachmentDetails={this.getAttachmentDetails(eachItem, attachmentsList)}
						{...this.getUserItem(userItems, eachItem)}
					/>
				</div>
			);
		});
	}

	getFeedbackCard = (shouldEnableFeedback) => {
		const { feedbackStatuses } = this.props;
		const phaseId = this.props.userPhase.get(0).get('id');
		const phaseDetails = filterObjectFromArray(
			this.props.phases.get('phasesList'), 'id', phaseId
		);
		if (
			phaseDetails
			&& phaseDetails.getIn(['settings', 'feedbackScenarioId'])
			&& phaseDetails.getIn(['settings', 'isFeedbackEnabled']) === 'true'
		) {
			const phaseFeedbackStatus = feedbackStatuses.get('phaseFeedbackStatuses').find(phaseFeedback => phaseFeedback.get('id') === phaseDetails.get('id'))
			const myStyles = getSkin(this.props.skinGuide);
			return (
				<div
					key={`individual-phase-item-container-feedback`}
					styleName="individual-phase-item-container feedbabck"
					onClick={() => this.goToPhaseFeedback(shouldEnableFeedback)}
					style={shouldEnableFeedback ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
				>
					<div styleName="phase-feedback-link-cnt">
						<div styleName="feedback-icon-cnt">
							{
								shouldEnableFeedback ?
									(
										phaseFeedbackStatus && phaseFeedbackStatus.get('status') ?
											<CompletedFeedbackIcon />
											:
											<FeedbackIcon />
									)
									:
									<LockIcon svgColor={this.props.skinGuide.getIn(['skinHex', 'greyColor4']) || '#979eb7'} />
							}
						</div>

						{
							phaseFeedbackStatus && phaseFeedbackStatus.get('status') ?
								<div
									className={css(myStyles.thanksForFeedbackLabel)
									}
								>
									{this.props.getLabel(
										'thank_you_note_after_feedback'
									)}
								</div>
								:
								<div
									className={
										shouldEnableFeedback ?
											css(myStyles.likeToHearLabel)
											: css(myStyles.likeToHearLabelDisabled)
									}
								>
									{this.props.getLabelWithDefaults(
										'like_to_hear_from_you_label',
										'We’d like to hear from you'
									)}
								</div>
						}
					</div>
				</div>
			)
		}
		return null;
	}

	renderLeftSide = (phaseItems, userItems, myStyles) => {
		let timelineLineStyleObj = {
			top: '105px',
			left: 'calc(50% - 2px)',
			height: `${(phaseItems.size - 1) * 209}px`, //130 added for feedback dot
		}
		const phaseId = this.props.userPhase.get(0).get('id');
		const phaseDetails = filterObjectFromArray(
			this.props.phases.get('phasesList'), 'id', phaseId
		);
		if (
			phaseDetails
			&& phaseDetails.getIn(['settings', 'feedbackScenarioId'])
			&& phaseDetails.getIn(['settings', 'isFeedbackEnabled']) === 'true'
		) {
			timelineLineStyleObj = {
				top: '105px',
				left: 'calc(50% - 2px)',
				height: `${(phaseItems.size - 1) * 209 + 130}px`, //130 added for feedback dot
			}
		}
		const timelineDotHeightObj = {
			height: `${(phaseItems.size) * 209 + 74}px`,
			marginTop: this.state.showAssessmentReportBar ? '151px' : 0
		}
		return (
			<div style={timelineDotHeightObj} styleName="left-side">
				<div
					className={css(myStyles.timelineLine)}
					style={timelineLineStyleObj}
					styleName="timeline-line"
				>
				</div>
				<div styleName="timeline-left-container">
					{this.renderPhaseTimeline(phaseItems, userItems, myStyles)}
					{this.getFeebackTimelineItem()}
				</div>
			</div>
		);
	}

	moveToPhaseSummary = () => {
		const phaseId = this.props.userPhase.get(0).get('id');
		const phaseDetails = filterObjectFromArray(
			this.props.phases.get('phasesList'), 'id', phaseId
		);

		if (phaseDetails) {
			const batchId = phaseDetails.get('batchId');
			const route = routes.get('PHASE_MEASURES');
			const params = [{ 'BATCH_ID': batchId }, { 'PHASE_ID': phaseId }];
			const routeObj = { route, params };
			updateRoute(routeObj);
		}
	}

	renderRightSide = (phaseItems, userItems, myStyles) => {
		const phaseId = this.props.userPhase.get(0).get('id');
		const phaseDetails = filterObjectFromArray(
			this.props.phases.get('phasesList'), 'id', phaseId
		);
		let isReportAvailable = true;
		if (
			checkIfPresent(phaseDetails.get('settings'))
			&& checkIfPresent(phaseDetails.get('settings').get('isReportScreenAvailable'))
			&& phaseDetails.get('settings').get('isReportScreenAvailable') == "false"
		) {
			isReportAvailable = false;
		}

		let shouldEnableFeedback = this.shouldEnableFeedback()

		return (
			<div styleName="right-side">
				{
					this.state.showAssessmentReportBar && !this.isThePhaseLocked()
						? <div styleName="individual-phase-item-container-report">
							<div className={css(myStyles.individualPhaseItem)} styleName="individual-phase-item">
								<div styleName="image-content-container">
									<div styleName="image-container"><CircleTick /></div>
									<div styleName="content-container">
										<div className={css(myStyles.reportHeading)}>
											{this.props.getLabel('phase_completion_message').replace(
												'PHASE_NAME',
												phaseDetails.get('name')
											)}
										</div>
										{
											isReportAvailable
												? <div className={css(myStyles.reportSubHeading)}>
													{this.props.getLabel('access_report_label')}
												</div>
												: null
										}
									</div>
								</div>
								{
									isReportAvailable
										? <div
											className={css(myStyles.reportButton)}
											styleName="button-container"
											onClick={this.moveToPhaseSummary}
										>
											{`${phaseDetails.get('name')} ${this.props.getLabel('report_label')}`}
										</div>
										: null
								}
							</div>
						</div>
						: null
				}
				{this.renderPhaseItems(phaseItems, userItems, myStyles)}

				{
					//render feedback card

					this.getFeedbackCard(shouldEnableFeedback)
				}
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const { phaseItems, userItems, loaders } = this.props;

		if (loaders.get('isFetchingBatchDashboardData') || !this.props.isBatchLabelsAvailable) {
			return (
				<div styleName="single-phase-container">
					<div styleName="individual-phase-item-container">
						<PhaseItemCardLoader />
						<PhaseItemCardLoader />
						<PhaseItemCardLoader />
					</div>
				</div>
			);
		}

		return (
			<div styleName="single-phase-container">
				{this.renderLeftSide(phaseItems, userItems, myStyles)}
				{this.renderRightSide(phaseItems, userItems, myStyles)}
			</div>
		);
	}
}

export default PhaseContent;
