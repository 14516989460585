import actionConsts from 'constants/actions';

const setBatchSkin = payload => ({
    type: actionConsts.get('SET_BATCH_SKIN'),
    payload
});

export {
    setBatchSkin
};
