import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import NextActivityImage from 'images/dashboard/nextBackground.png';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		stickyContainer: {
			backgroundColor: skin.get('white')
		},
		acceptInvitationContainer: {
            borderTop: `7px solid ${skin.get('secondaryColor')}`,
            background: `linear-gradient(135deg, ${hexToRgbA(skin.get('primaryColor'), 0.1)} , ${hexToRgbA(skin.get('secondaryColor'), 0.1)})`,
            // backgroundImage: `url(${NextActivityImage})`,
            // backgroundSize: "100%",
			// backgroundRepeat: 'no-repeat',
			// backgroundPosition: "top"
        },
        acceptInvitationImage: {
            backgroundImage: `url(${NextActivityImage})`,
            backgroundSize: "100%",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
        },
		title: {
            ...myTypography.h4, 
            color: hexToRgbA(skin.get('greyColor1'), 0.85),
            lineHeight: 1.2
        },
        buttonInvitation: {
            boxShadow: `0 14px 16px 0 ${hexToRgbA(skin.get('black'), 0.19)}`,
            backgroundColor: skin.get('primaryColor'),
            borderRadius: '4px',
            textTransform: 'uppercase',
            ...myTypography.button,
            color: skin.get('white'),
            cursor: 'pointer',
            ':hover':{
                backgroundColor: skin.get('primaryVariantColor'),
            }
        },
        acceptInvitationDisabled: {
			...myTypography.button,
			color: skin.get('white'),
			borderRadius: '4px',
			border: `1px solid ${skin.get('greyColor4')}`,
			backgroundColor: skin.get('greyColor4'),
			textTransform: 'uppercase',
			cursor: 'pointer',
			':hover': {
				border: `1px solid ${skin.get('greyColor4')}`,
				backgroundColor: skin.get('greyColor4')
			}
        },
        lockExplanation: {
			...myTypography.caption,
			fontWeight: 'normal',
			color: hexToRgbA(skin.get('greyColor1'), 0.85)
		}
	});
}

export default getSkin;