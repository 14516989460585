import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { appendQueryParameters } from 'utils/utilFunctions';
import { setFeedbackCompletedTemp } from 'actions/itemFeedback/actionCreators';
import {
    setPhaseFeedbackStatuses,
    setItemFeedbackStatuses
} from 'actions/itemFeedback/actionCreators';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

class SubmitFeedback extends NetPackWrapper {

    constructor() {
        super("POST");
    }

    prepareUrl = (apiInfoObject) => {
        return appendQueryParameters(
            urls.get('PLATFORM').get('SUBMIT_FEEDBACK'),
            apiInfoObject.queryParameters)
    }

    shouldAjaxCall = (apiInfoObject) => {
        return true;
    }

    sendSegmentData = (apiInfoObject) => {
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            entityId: apiInfoObject.queryParameters.entityId,
            entityType: apiInfoObject.queryParameters.entityType,
            groupId: apiInfoObject.queryParameters.groupId,
            scenario: apiInfoObject.queryParameters.scenario
        }
        window.analytics.track(
            'Feedback submitted',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    successCall = (response, apiInfoObject) => {
        this.sendSegmentData(apiInfoObject);
        this.dispatch(setFeedbackCompletedTemp(true));
        if (apiInfoObject.queryParameters && apiInfoObject.queryParameters.entityType === 'PHASE')
            this.dispatch(setPhaseFeedbackStatuses({
                id: +apiInfoObject.queryParameters.entityId,
                status: true
            }));
        if (apiInfoObject.queryParameters && apiInfoObject.queryParameters.entityType === 'PHASEITEM')
            this.dispatch(setItemFeedbackStatuses({
                id: +apiInfoObject.queryParameters.entityId,
                status: true
            }));
        return response;
    }

    onError = (errorObj) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR_SET_BATCH'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to submit feedback. Please try again!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new SubmitFeedback();
