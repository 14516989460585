import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		sessionDetailsContent: {
			...myTypography.body2,
			color: '#5b5c61'
		},
		sessionDetailsTitles: {
			...myTypography.h5,
			color: '#5b5c61',
			fontWeight: 600
		},
		sessionDetailsHeader: {
			...myTypography.button,
			color: hexToRgbA('#5b5c61', 0.8),
			opacity: 0.6,
			borderBottom: `solid 1px ${hexToRgbA('#7c7d81', 0.6)}`
		},
		resourcesCnt: {
			backgroundColor: hexToRgbA(skinHex.get('greyColor5'), 0.3)
		},
		eachResource: {
			backgroundColor: skinHex.get('white')
		},
		containerHeader:{
			...myTypography.h5,
			fontFamily: skin.get('fontFamily').get('bodyFamily2'),
			color: skinHex.get('greyColor2'),
			fontWeight: 300
		},
		facilitatorProfile: {
			backgroundColor: hexToRgbA(skinHex.get('greyColor6'), 0.5)
		},
		facilitatorName:{
			...myTypography.h5,
			color: skinHex.get('greyColor2'),
			fontWeight: 'normal'
		},
		facilitatorDesignation:{
			...myTypography.button,
			fontWeight: 'normal',
			color: hexToRgbA(skinHex.get('greyColor2'), 0.5)
		},
		facilitatorRating: {
			...myTypography.button,
			color: '#5b5c61',
			fontWeight: 'bold',
			lineHeight: 1.8
		},
		facilitatorBio: {
			...myTypography.body2,
			color: hexToRgbA(skinHex.get('greyColor2'), 0.8),
			lineHeight: 1.59
		},
		resourceName:{
			...myTypography.overline
		}
	});
}

export default getSkin;