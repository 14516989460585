import React from 'react';
import styles from './noJourneyErrorComponent.module.sass';
import CSSModules from 'react-css-modules';
import NoJourneysErrorIcon from 'svgComponents/noJourneysErrorIcon';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class NoJourneyErrorComponent extends React.Component {
	render() {
		const myStyles = getSkin(this.props.skinGuide);
		let msgLabelKey = this.props.msgLabelKey || 'no_journeys_found_label';
		return (
			<div styleName="no-journey-error-comp">
				<div styleName="image-cnt">
					<NoJourneysErrorIcon />
				</div>
				<div className={css(myStyles.noJourneyText)} styleName="no-journey-label">
					{this.props.getLabel(msgLabelKey)}
				</div>
			</div>
		);
	}
}

export default NoJourneyErrorComponent;
