import React, { Component } from 'react';
import styles from './userJourneyStats.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { checkIfPresent } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class UserJourneyStats extends Component {

	render() {
		const {
			getLabel,
			organizationLevelUsersStats,
			userOrganization,
			skinGuide
		} = this.props;
		const myStyles = getSkin(skinGuide);
		let showStats = false;

		organizationLevelUsersStats.get('organizationLevelUsersStats').entrySeq().forEach(([key, value]) => {
			if (key !== 'requestedJourneys' && value !== 0) {
				showStats = true;
				return;
			}
		})

		if (!showStats) {
			return null;
		}


		let isItMultiStat = organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'workshopsAttended']) && organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'simulationsPlayed']) ? true : false;

		return (
			<div styleName="user-stats" className={css(myStyles.userStatsBackground)}>
				<div styleName='user-stats-inner'>
					<div styleName="individual-stats">
						<div styleName="stat-label" className={css(myStyles.statCount)}>
							{organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'activeJourneys'])}
						</div>
						<div className={css(myStyles.statLabel)}>
							{getLabel('active_journeys_label')}
						</div>
					</div>
					{
						organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'upcomingJourneys']) !== 0 &&
						<div styleName="individual-stats">
							<div styleName="stat-label" className={css(myStyles.statCount)}>
								{organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'upcomingJourneys'])}
							</div>
							<div className={css(myStyles.statLabel)}>
								{getLabel('upcoming_journeys_label')}
							</div>
						</div>
					}
					{
						organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'completedJourneys']) !== 0 &&
						<div styleName="individual-stats">
							<div styleName="stat-label" className={css(myStyles.statCount)}>
								{organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'completedJourneys'])}
							</div>
							<div className={css(myStyles.statLabel)}>
								{getLabel('completed_journeys_label')}
							</div>
						</div>
					}
					{
						organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'certificatesCompleted']) !== 0 &&
						<div styleName="individual-stats">
							<div styleName="stat-label" className={css(myStyles.statCount)}>
								{organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'certificatesCompleted'])}
							</div>
							<div className={css(myStyles.statLabel)}>
								{getLabel('certificates_recieved_label')}
							</div>
						</div>
					}
					{
						(organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'simulationsPlayed']) || organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'workshopsAttended'])) &&
						<div styleName="multi-stat-cnt">
							{
								checkIfPresent(organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'simulationsPlayed'])) &&
								<div styleName="stat-cnt">
									<div styleName="stat-label" className={css(isItMultiStat ? myStyles.multiStats : myStyles.statCount)}>
										{
											organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'simulationsPlayed'])
										}
									</div>
									<div className={css(isItMultiStat ? myStyles.statLabelNoWidth : myStyles.statLabel)}>{getLabel('simulations_played_label')}</div>
								</div>
							}
							{
								checkIfPresent(organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'workshopsAttended'])) &&
								<div styleName="stat-cnt">
									<div styleName="stat-label" className={css(isItMultiStat ? myStyles.multiStats : myStyles.statCount)}>
										{
											organizationLevelUsersStats.getIn(['organizationLevelUsersStats', 'workshopsAttended'])
										}
									</div>
									<div className={css(isItMultiStat ? myStyles.statLabelNoWidth : myStyles.statLabel)}>{getLabel('workshops_attended_label')}</div>
								</div>
							}
						</div>
					}
				</div>
			</div>
		);
	}
}

export default UserJourneyStats;
