import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './statsComponentAorD.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import UserBgLogo from 'images/analytics/score_card_bg.svg'
import BookBg from 'images/analytics/book_bg.svg'
import ClockSvg from 'svgComponents/analytics/clockSvg/ClockSvg';
import UserRank from 'svgComponents/analytics/userRank';
import BookSvg from 'svgComponents/analytics/bookSvg/BookSvg';
import ProgressBar from 'svgComponents/analytics/progressBar';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import { checkIfPresent } from 'utils/utilFunctions';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@CSSModules(styles, { allowMultiple: true })

class StatsComponentAorD extends React.Component {

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchStatsData(+batchId, +userId);
    }
    
    renderSvgComponent = (iconName, fillColor) => {
        if (iconName === 'clock') {
            return <ClockSvg fillColor={fillColor}/>
                        
        } else if (iconName === 'rank') {
            return <UserRank fillColor={fillColor}/>
        } else if (iconName === 'book'){
            return <BookSvg fillColor={fillColor}/>
        }
    }

    displayTitle = (title, hasIcon=false, iconName, fillColor) => {
        if (hasIcon) {
            return (
                <div styleName="title-container">
                    <div styleName="icon">
                        {this.renderSvgComponent(iconName, fillColor)}
                    </div>
                    <div styleName="title">{title}</div>
                    <div styleName="title-separator"></div>
                </div>
            );
        }
        return (
            <div styleName="title-container">
                <div styleName="title">{title}</div>
                <div styleName="title-separator"></div>
            </div>
        );
    }

    displayBottomTitle = (title) => {
        return (
            <div styleName="title-container">
                <div styleName="title-separator"></div>
                <div styleName="content-text">{title}</div>
            </div>
        );
    }

    boldTextContent = (number, text, color, columnWise=false) => {
        const style = columnWise ? "content-bold-container" : "";
        const boldStyle = columnWise ? "module-content-bold"  : "content-bold";
        return (
            <span styleName={style}>
                <span styleName={boldStyle} style={{color: `${color}`}}>{number}</span>
                {text}
            </span>
        );
    }

    boldTextContentHorizontal = (number, text, color, columnWise=false) => {
        const style = columnWise ? "content-bold-container" : "content-bold-container-mod";
        const boldStyle = columnWise ? "module-content-bold"  : "content-bold-mod";
        return (
            <span styleName={style}>
                <span styleName={boldStyle} style={{color: `${color}`}}>{number}</span>
                {text}
            </span>
        );
    }

    renderProgressSvg = (fillColor) => {
        
        return fillColor.map((fill) => {
            return (
                <div styleName="active-days-progress-bar">
                    <ProgressBar fillColor={fill}/>
                </div>
            )
        })
    }

    renderProgressBars = (allGraphsData) => {

        let fillColor = [];

        allGraphsData.get('days').map((dayActiveValue, i) => {
            if (dayActiveValue == 1) {
                fillColor[i] = "#4969e2";   
            } else {
                fillColor[i] = "rgba(213, 217, 231, 0.1)"
            }
        });

        return this.renderProgressSvg(fillColor);
    }

    renderContent = (contentObject) => {
        return(
            <div styleName="content-container">
                <div styleName="content">
                    {this.boldTextContent(contentObject.value1, contentObject.value1Title, contentObject.value1Color)}
                </div>
                <div styleName="content">
                    {this.boldTextContent(contentObject.value2, contentObject.value2Title, contentObject.value2Color)}
                </div>
            </div>
        )
    }

    renderUserStats = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('batchUserStatus');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        const contentObject = {
            value1: allGraphsData.get('enrolledUsers'),
            value1Title: strings.get('usersEnrolledLabel'),
            value1Color: '#ff0264',
            value2: allGraphsData.get('activeUsers'),
            value2Title: strings.get('usersActiveLabel'),
            value2Color: '#ff0264',
        }
        return(
            <div styleName="user-stats">
                {this.displayTitle(strings.get('userStatsLabel'), true, 'rank', '#FF026A')}
                {this.renderContent(contentObject)}
                <div styleName="bg-container">
                    <img src={UserBgLogo} styleName="bg-logo"/>
                </div>
            </div>
        )
    }

    renderJourneyAssessmentOrDevelopment = (allGraphsData) => {

        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        if (this.props.isAssessmentOnly) {
            return(
                <div styleName="content">
                    {this.boldTextContent(allGraphsData.get('assessments'), strings.get('assessmentsLabel'), '#ff0264')}
                </div>
            )
        } else {
            return(
                <div>
                    {checkIfPresent(allGraphsData.get('courses')) && <div style={{padding: 0}} styleName="content">
                        {this.boldTextContent(allGraphsData.get('courses'), strings.get('coursesLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('sections')) && <div style={{padding: 0}} styleName="content">
                        {this.boldTextContent(allGraphsData.get('sections'), strings.get('sectionsLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('activities')) && <div style={{padding: 0}} styleName="content">
                        {this.boldTextContent(allGraphsData.get('activities'), strings.get('activitiesLabel'), '#ff0264')}
                    </div>}
                </div> 
            )
        }
    }

    renderJourneyStats = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('journeyDetails');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');
        const style = this.props.isAssessmentOnly ? 'journey-stats-ass' : 'journey-stats-dev';

        return(
            <div styleName={style}>
                {this.displayTitle(strings.get('journeyLabel'), true, 'book', '#FF026A')}
                <div styleName="content-container">
                    {this.renderJourneyAssessmentOrDevelopment(allGraphsData)}
                </div>
                <div styleName="bg-container">
                    <img src={BookBg} styleName="bg-logo" />
                </div>
            </div>
        )
    }

    renderStatsSection = () => {
        return(
            <div styleName="stats-inner-container">
                {this.renderUserStats()}
                {this.renderJourneyStats()}
            </div>
        )
    }

    renderTimeSpentByUser = () => {
        
        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('timeSpent');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="time-spent">
                <div styleName="time-spent-overall">
                    {this.displayTitle(strings.get('timeSpentOverallLabel'), true, 'clock', '#ffb448')}
                    <div styleName="content-container">
                        <div styleName="content">
                            {this.boldTextContentHorizontal(allGraphsData.get('overAll').get('days'), strings.get('daysLabel'), '#feb926', false)} 
                            :{this.boldTextContentHorizontal(allGraphsData.get('overAll').get('hours'), strings.get('hoursLabel'), '#feb926', false)} 
                            :{this.boldTextContentHorizontal(allGraphsData.get('overAll').get('minutes'), strings.get('minutesLabel'), '#feb926', false)}
                        </div>
                        <div styleName="content-text">
                            {strings.get('timeSpentOverallDesc')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderModuleAssessmentOrDevelopment = (allGraphsData) => {

        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        if (this.props.isAssessmentOnly) {
            return(
                <div styleName="content-container">
                    <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('assessments'), strings.get('assessmentsLabel'), '#ff0264')}
                    </div>
                </div>
            )
        } else {
            return(
                <div styleName="content-container">
                    {checkIfPresent(allGraphsData.get('courses')) && <div style={{padding: 0}} styleName="content">
                        {this.boldTextContent(allGraphsData.get('courses'), strings.get('coursesLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('sections')) && <div style={{padding: 0}} styleName="content">
                        {this.boldTextContent(allGraphsData.get('sections'), strings.get('sectionsLabel'), '#ff0264')}
                    </div>}
                    {checkIfPresent(allGraphsData.get('activities')) && <div style={{padding: 0}} styleName="content">
                        {this.boldTextContent(allGraphsData.get('activities'), strings.get('activitiesLabel'), '#ff0264')}
                    </div>}
                </div>
            )
            
        }
    }

    renderModuleFinished = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('userCompletedTasks');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');
        const style = this.props.isAssessmentOnly ? 'finish-overdue-ass' : 'finish-overdue-dev';
        return(
            <div styleName={style}>
                {this.displayTitle(strings.get('finishedLabel'))}
                {this.renderModuleAssessmentOrDevelopment(allGraphsData)}
                <div styleName="bg-container">
                    <img src={BookBg} styleName="bg-logo" />
                </div>
            </div>
        )
    }

    renderModuleOverDue = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('userOverdueTasks');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');
        const style = this.props.isAssessmentOnly ? 'finish-overdue-ass' : 'finish-overdue-dev';

        return(
            <div styleName={style}>
                {this.displayTitle(strings.get('overdueLabel'))}
                {this.renderModuleAssessmentOrDevelopment(allGraphsData)}
                <div styleName="bg-container">
                    <img src={BookBg} styleName="bg-logo" />
                </div>
            </div>
        )
    }

    renderModuleFinishedAndOverDue = () => {
        return(
            <div styleName="finished-overdue">
                {this.renderModuleFinished()}
                {this.renderModuleOverDue()}
            </div>
        )
    }

    renderTimeSpentAndModuleCompletionSection = () => {
        return(
            <div styleName="time-spent-container">
                {this.renderTimeSpentByUser()}
                {this.renderModuleFinishedAndOverDue()}
            </div>
        )
    }

    renderDaysProgress = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('lastNDaysActivity');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="active-days">
                <div styleName="active-day-progress-container">
                    {allGraphsData.get('overAllActiveDays')} days
                    <div styleName="active-days-progress-graph-container">
                        {this.renderProgressBars(allGraphsData)}
                    </div>
                </div>
                {this.displayBottomTitle(strings.get('activeDaysLabel'))}
            </div>
        )
    }

    renderUsersActiveTime = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData').get('mostActiveTimeSpan');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');

        return(
            <div styleName="active-time">
                <div styleName="module-content-container">
                    <div styleName="content">
                        {this.boldTextContent(allGraphsData.get('startHour').get('hour'), allGraphsData.get('startHour').get('zone'), '#4a69e2', true)} 
                        {strings.get('toLabel')}
                        {this.boldTextContent(allGraphsData.get('endHour').get('hour'), allGraphsData.get('endHour').get('zone'), '#4a69e2', true)}
                    </div>
                </div>
                {this.displayBottomTitle(strings.get('mostActiveTimeLabel'))}
            </div>
        )
    }

    renderModuleWithExceptionalScore = () => {

        let allGraphsData = this.props.allGraphsData.get('activity').get('statsData');
        let strings = this.props.allGraphsData.get('activity').get('statsData').get('strings');
        const style = this.props.isAssessmentOnly ? 'module-ass' : 'module-dev';

        return(
            <div styleName={style}>
                <div styleName="module-content-container">
                    <div styleName="module-content">
                        {allGraphsData.get('mostActiveModule').get('moduleName')}
                    </div>
                </div>
                {this.displayBottomTitle(strings.get('maxSpentModuleLabel'))}
            </div>
        )
    }

    renderUserProgressAndOutputSection = () => {
        return(
            <div styleName="active-days-container">
                {this.renderDaysProgress()}
                {this.renderUsersActiveTime()}
                {this.renderModuleWithExceptionalScore()}
            </div>
        )
    }

    renderStatsComponentAorD = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        if(this.props.apiErrors.get('fetchStatsDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchStatsDataError')}
                retryHandler={()=>{
                    this.props.fetchStatsData(+batchId, +userId);
				}}
            />
        }

        let allGraphsData = this.props.allGraphsData.get('activity');
        if (allGraphsData.get('isStatsDataFetched')) {            
            return(
                <div styleName="main-container">
                    {this.renderStatsSection()}
                    {this.renderTimeSpentAndModuleCompletionSection()}
                    {this.renderUserProgressAndOutputSection()}
                </div> 
            )
            return "hello";
        }
        return <LoaderComponent />
    }

    render(){        
        return(
            <div styleName="container-class">
                <LegendComponent title={this.props.strings.get('statsKey')} {...this.props}/>
                <div styleName="outer-container">
                    <div styleName="inner-container">
                        {this.renderStatsComponentAorD()}
                    </div>
                </div>         
            </div>
        )
    }
}


export default StatsComponentAorD;