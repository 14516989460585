import React, { Component } from 'react';
import styles from './defaultLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { checkIfPresent } from 'utils/utilFunctions';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DefaultLoader extends Component {
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="loader-container" style={this.props.styleObject}>
                <div styleName="loader-content" >
                    <svg width="38" height="38" style={this.props.loaderIconStyle} viewBox="0 0 38 38" stroke="#d44980">
                        <g fill="none" fillRule="evenodd">
                            <g transform="translate(1 1)" strokeWidth="3">
                                <circle stroke="e8eaf2" strokeOpacity=".1" cx="18" cy="18" r="18" />
                                <path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(313.068 18 18)">
                                    <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
                                </path>
                            </g>
                        </g>
                    </svg>
                </div>
                {
                    checkIfPresent(this.props.loaderText) &&
                    <div className={css(myStyles.loadingText)}>
                        {this.props.loaderText}
                    </div>
                }
            </div>
        );
    }
}

export default DefaultLoader;