import React, { Component } from 'react';
import styles from './nextPlan.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import {  getNextPhase } from 'utils/phaseUtils';
import routes from 'utils/webAppRoutes/routeConstants';
import enumConstants from 'constants/enums';
import { filterObjectFromArray } from 'utils/objectUtils';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class NextPlan extends Component {

	redirectToHome = (batchId) => {
		const route = routes.get('BATCH_HOME');
		const params = [{ 'BATCH_ID': batchId }]
		updateRoute({ route, params });
	}

	render() {
		const { batchId } = this.props.match.params;

		let onClickCallback = () => this.redirectToHome(batchId);

		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div className={css(myStyles.nextPlanContainer)} styleName="next-plan-container">
				<div styleName="next-plan-content">
					
					<div styleName="center-content">
						<div
							className={css(myStyles.button)}
							styleName="button"
							onClick={onClickCallback}
						>
							{this.props.getLabel('go_to_dashboard_label')}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default NextPlan;
