import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {
	setUserFeedbackData,
	setFeedbackCompletionStatus,
	setPhaseFeedbackStatuses,
	setItemFeedbackStatuses,
	setUserFeedbackStatus
} from 'actions/itemFeedback/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import { appendQueryParameters } from 'utils/utilFunctions';
import { setApiError } from 'actions/apiErrors/actionCreators';

import { updateFetchFeedbackStatus } from 'actions/loaders/actionCreators';

class FetchUserItemFeedback extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	beforeCall = (apiInfoObject = {}) => {
		this.dispatch(setUserFeedbackStatus(true));
		return apiInfoObject;
	}


	prepareUrl = (apiInfoObject = {}) => {
		let condition = [true, false][Math.floor(Math.random() * Math.floor(2))];
		let url = urls.get('PLATFORM').get('GET_USER_ITEM_FEEDBACK');
		if (false) {
			url = urls.get('DEVELOPMENT_PHASE').get('URL_WITH_ERROR');
		}
		return appendQueryParameters(
			url,
			apiInfoObject.queryParameters
		);
	}

	shouldAjaxCall = (apiInfoObject) => {
		return true;
	}

	successCall = (response, apiInfoObject = {}) => {
		this.dispatch(setUserFeedbackData(response));
		if (apiInfoObject.queryParameters && apiInfoObject.queryParameters.entityType === 'PHASE')
			this.dispatch(setPhaseFeedbackStatuses({
				id: apiInfoObject.queryParameters.entityId,
				status: response.isFeedbackSubmitted
			}));
		if (apiInfoObject.queryParameters && apiInfoObject.queryParameters.entityType === 'PHASEITEM')
			this.dispatch(setItemFeedbackStatuses({
				id: apiInfoObject.queryParameters.entityId,
				status: response.isFeedbackSubmitted
			}));
		this.dispatch(setFeedbackCompletionStatus(response));
		this.dispatch(updateFetchFeedbackStatus(false));
		this.dispatch(setApiError('fetchUserItemFeedbackError', null));
		this.dispatch(setUserFeedbackStatus(false))

		return response;
	}

	onError = (errorObj) => {
		// this.dispatch(addToast({
		// 	id: toastConstants.get('ID_ERROR'),
		// 	type: toastConstants.get('TYPE_ERROR'),
		// 	heading: 'Something went wrong',
		// 	message: 'Sorry, unable to fetch user feedback. Please try again!',
		// 	isDisplayed: false,
		// 	autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		// }));
		// sendUserToInvalidPage();
		this.dispatch(updateFetchFeedbackStatus(false));
		this.dispatch(setApiError('fetchUserItemFeedbackError', 'There was a problem while fetching the content.'));
		this.dispatch(setUserFeedbackStatus(false))

	}

}

export default new FetchUserItemFeedback();
