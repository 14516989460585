/**
 * @author: sindhu
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Dashboard container is responsible for displaying Dashboard pages and
 * displayed on dashboard route of the application
 *
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchItemDataForFeedback } from 'actions/items/apiActions';
import { updateItemIdFromRoute, setSelectedItemId } from 'actions/items/actionCreators';
import { setSideBarFlag } from 'actions/assesments/actionObjects';
import setUserState from 'actions/userState/actionCreators';
import Feedback from 'components/feedback';
import {
    getActiveItemDetails,
    getNextItemDetails,
    getActiveUserItem,
    getActivePhase,
    getItemsListInActivePhase
} from './containerHelpers/assessmentContainerHelper';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import {
    fetchItemFeedback,
    fetchUserItemFeedback,
    submitAnswer,
    submitFeedback
} from 'actions/itemFeedback/apiActions';
import {
    setFeedbackCompletionStatus,
    setResponseToQuestion,
    setFeedbackCompletedTemp,
    resetUserData,
    resetFeedbackData
} from 'actions/itemFeedback/actionCreators';
import routes from 'utils/webAppRoutes/routeConstants';
import { setPhaseWorkflowStatus } from 'actions/phases/apiActions';
import { setAssessmentShowDetails } from 'actions/items/actionCreators';

import enumConstants from 'constants/enums';

import {
    startTracking,
    endTracking
} from 'utils/mixpanelTracking';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

class FeedbackContainer extends Component {

    componentDidMount() {
        const { batchId, itemId } = this.props.match.params;

        startTracking(true, {
            batchId: this.props.match.params.batchId,
            phaseId: this.props.selectedItem.get('phaseId'),
            phaseItemId: this.props.match.params.itemId
        }, 'AssessmentItem');

        this.props.setUserState({
            currentItemId: parseInt(itemId)
        });


        this.props.fetchItemDataForFeedback(batchId, +itemId);
        this.props.updateItemIdFromRoute(+itemId);
    }

    componentWillReceiveProps(nextProps) {
        const { batchId, itemId } = nextProps.match.params;
        const { itemFeedback } = nextProps;

        if (this.props.match.params.itemId != itemId) {
            /* MIXPANEL  start*/
            endTracking();
            startTracking(true, {
                batchId: nextProps.match.params.batchId,
                phaseId: nextProps.selectedItem.get('phaseId'),
                phaseItemId: nextProps.match.params.itemId,
                component: 'FeedbackContainer'
            }, 'AssessmentItem');
            /* MIXPANEL  end*/

            this.props.fetchItemDataForFeedback(batchId, +itemId);
            this.props.updateItemIdFromRoute(+itemId);
        }
        //TODO check logic
        // if (
        //     itemFeedback.get('isFeedbackQuestionsFetched') &&
        //     !itemFeedback.get('isFeedbackCompleted') &&
        //     itemFeedback.get('questionsList').size === 1 &&
        //     itemFeedback.getIn(['questionsList', 0, 'completed'])
        // ) {
        //     this.props.submitFeedback({ isSubmitted: true })
        // }

        let userState = {
            currentItemId: parseInt(itemId)
        };
        const currentPhaseId = nextProps.selectedItem.get('phaseId');
        if (currentPhaseId) {
            userState = {
                ...userState,
                currentPhaseId
            }
        }
        this.props.setUserState(userState);
        if (
            !nextProps.loaders.get('isFetchingFeedback')
            && this.props.loaders.get('isFetchingFeedback')
        ) {
            document.title = `${nextProps.selectedItem.get('itemType')} - ${nextProps.selectedItem.get('name')} - Aktivlearn Plus`;
            this.sendSegmentData(nextProps);
        }

        this.checkStatusAndMoveToItem(nextProps.selectedUserItem);
    }

    sendSegmentData = (nextProps) => {
        let segmentData = {
            ...(nextProps.match.params),
            title: 'Item Feedback',
            url: window.location.href,
            pathHash: window.location.hash,
            itemType: nextProps.selectedItem.get('itemType'),
            workflowStatus: nextProps.selectedUserItem.get('workflowStatus'),
            percentageCompletion: nextProps.selectedUserItem.get('percentageCompletion')
        };
        window.analytics.page(
            `Item Page Opened - ${nextProps.selectedItem.get('itemType')}`,
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    componentWillUnmount() {
        // this.props.resetUserData(true);
        this.props.resetFeedbackData();

        endTracking();
    }

    checkStatusAndMoveToItem = (nextSelectedUserItem) => {
        if (
            !nextSelectedUserItem.isEmpty()
            && nextSelectedUserItem.get('workflowStatus') !== 'COMPLETED'
        ) {
            const { userState } = this.props;
            const route = routes.get('BATCH_ITEM');
            const params = [
                { 'ITEM_ID': nextSelectedUserItem.get('id') },
                { 'BATCH_ID': userState.get('currentBatchId') }
            ];
            updateRoute({ route, params });
        }
    }

    render() {
        return (
            <Feedback {...this.props} />
        );
    }
}

const mapStateToProps = state => ({
    selectedItem: getActiveItemDetails(state),
    selectedUserItem: getActiveUserItem(state),
    nextItem: getNextItemDetails(state),
    isSideBarOpen: state.get('ui').get('isSideBarOpen'),
    batches: state.get('batches'),
    phases: state.get('phases'),
    items: state.get('items'),
    attachmentsList: state.get('attachments').get('list'),
    tools: state.get('tools'),
    activePhaseDetails: getActivePhase(state),
    itemsListInActivePhase: getItemsListInActivePhase(state),
    userState: state.get('userState'),
    itemFeedback: state.get('itemFeedback'),
    ui: state.get('ui'),
    itemId: state.get('userState').get('currentItemId'),
    loaders: state.get('loaders'),
    faqsList: state.get('tools').get('faqsList'),
    apiErrors: state.get('apiErrors'),
    feedbackStatuses: state.get('feedbackStatuses'),
    user: state.get('user')
});

const submitFeedbackFromContainer = (payload) => {
    return (dispatch, getState) => {
        const itemId = getState().get('userState').get('currentItemId');
        const entityType = enumConstants.get('ENTITY_TYPE_PHASE_ITEM');
        const scenario = enumConstants.get('SCENARIO_TOOLS');
        dispatch(submitFeedback(payload, itemId, entityType, scenario));
    }
}

const submitAnswerFromContainer = (payload, questionId) => {
    return (dispatch, getState) => {
        const itemId = getState().get('userState').get('currentItemId');
        const entityType = enumConstants.get('ENTITY_TYPE_PHASE_ITEM');
        const scenario = enumConstants.get('SCENARIO_TOOLS');
        dispatch(submitAnswer(payload, questionId, itemId, entityType, scenario));
    }
}

const mapDispatchToProps = dispatch => ({
    updateItemIdFromRoute: (itemIdFromRoute) => {
        dispatch(updateItemIdFromRoute(itemIdFromRoute));
    },
    setSelectedItemId: (id) => {
        dispatch(setSelectedItemId(id))
    },
    setSideBarFlag: (flag) => {
        dispatch(setSideBarFlag(flag))
    },
    setUserState: (payload) => {
        dispatch(setUserState(payload))
    },
    setPhaseWorkflowStatus: (batchId, phaseId, workflowStatus, routeObj) => {
        dispatch(setPhaseWorkflowStatus(batchId, phaseId, workflowStatus, routeObj))
    },
    fetchItemDataForFeedback: (batchId, itemId, scenario = 'TOOLS', entityType) => {
        dispatch(fetchItemDataForFeedback(batchId, itemId, scenario, entityType = "PHASEITEM"))
    },
    setFeedbackCompletionStatus: (flag) => {
        dispatch(setFeedbackCompletionStatus(flag))
    },
    setAssessmentShowDetails: (flag) => {
        dispatch(setAssessmentShowDetails(flag))
    },
    setResponseToQuestion: (payload, questionId) => {
        dispatch(setResponseToQuestion(payload, questionId))
    },
    setFeedbackCompletedTemp: (flag) => {
        dispatch(setFeedbackCompletedTemp(flag))
    },
    submitAnswer: (payload, questionId) => {
        dispatch(submitAnswerFromContainer(payload, questionId))
    },
    submitFeedback: (payload) => {
        dispatch(submitFeedbackFromContainer(payload))
    },
    resetUserData: (flag) => {
        dispatch(resetUserData(flag))
    },
    resetFeedbackData: () => {
        dispatch(resetFeedbackData())
    }

});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContainer);
