/**
 * 
 *
 * @param {*} weeks
 * @param {*} max
 * @returns []
 */
const dataGen = (weeks, max) => {
	let data = [];
	const min = max/(weeks + 1);
	for (let index = 1; index < weeks+1; index++) {
		data.push(
			{
				id: index,
				userRank: Math.ceil(Math.random() * 2) + (weeks - index + 1),
				userValue: Math.floor(Math.random() * min) + min*index,
				groupValue: Math.floor(Math.random() * min) + min*index
			}
		);
	}
	return data;

};

export default dataGen;