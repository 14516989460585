import React, { Component } from 'react';
import styles from './linkedInLogo.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LinkedInLogo extends Component {

	render() {
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 24 24" fill="none">
					<path d="M22.2691 0H1.7309C0.774902 0 0 0.774902 0 1.7309V22.2691C0 23.2251 0.774902 24 1.7309 24H22.2691C23.2251 24 24 23.2251 24 22.2691V1.7309C24 0.774902 23.2251 0 22.2691 0V0ZM8.51294 18.1406H5.59039V9.34808H8.51294V18.1406ZM7.05176 8.14746H7.03271C6.052 8.14746 5.41772 7.47235 5.41772 6.6286C5.41772 5.76581 6.07141 5.10938 7.07117 5.10938C8.07092 5.10938 8.68616 5.76581 8.7052 6.6286C8.7052 7.47235 8.07092 8.14746 7.05176 8.14746ZM19.051 18.1406H16.1288V13.4368C16.1288 12.2547 15.7057 11.4485 14.6483 11.4485C13.8409 11.4485 13.3601 11.9923 13.1488 12.5173C13.0715 12.7051 13.0527 12.9677 13.0527 13.2305V18.1406H10.1303C10.1303 18.1406 10.1686 10.173 10.1303 9.34808H13.0527V10.593C13.441 9.9939 14.1359 9.14172 15.6865 9.14172C17.6093 9.14172 19.051 10.3984 19.051 13.099V18.1406Z" fill="white" />
				</svg>
			</div>
		);
	}
}

export default LinkedInLogo;