/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './AssessmentItemDetailsLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class AssessmentItemDetailsLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<ContentLoader
				height={160}
				width={570}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '100%' }}
			>
				<rect x="1" y="56" rx="2" ry="2" width="568" height="12" />
				<rect x="1" y="120" rx="2" ry="2" width="441" height="12" />
				<rect x="1" y="88" rx="2" ry="2" width="379" height="12" />
				<rect x="0" y="0" rx="2" ry="2" width="231" height="16" />
			</ContentLoader>
		);
	}
}

export default AssessmentItemDetailsLoader;
