import React, { Component } from 'react';
import styles from './preRequisite.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PreRequisite extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="pre-requisite-component">
				<svg width="100%" height="100%" viewBox="0 0 67 95">
					<defs>
						<filter id="preRequisite" width="109.5%" height="185.7%" x="-4.8%" y="-42.9%" filterUnits="objectBoundingBox">
							<feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
						</filter>
					</defs>
					<g fill="none" fillRule="evenodd" transform="translate(1 -2)">
						<g stroke={skinHex.get('primaryColor')} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
							<rect width="60" height="44" x="2" y="16" rx="1.5"/>
							<path d="M14 30h36M14 46h36M40.912 6.733A4 4 0 0 0 37.117 4H26.883a4 4 0 0 0-3.795 2.733L20 16h24l-3.088-9.267zM10 16v-4M54 16v-4"/>
						</g>
						<ellipse cx="32.5" cy="91.5" fill="#4A4A4A" filter="url(#preRequisite)" opacity=".1" rx="31.5" ry="3.5"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default PreRequisite;
