import React, { Component } from 'react';
import styles from './journeyLearnings.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { convertDateWithYear } from 'utils/utilFunctions';
import SinglePhase from './singlePhase';
import JourneyLearningsPhaseLoader from 'commonComponents/loaders/journeyLearningsPhaseLoader';
import { checkIfPresent } from 'utils/utilFunctions';
import enumConstants from 'constants/enums';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyLearnings extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showMoreItemNumber: 3
		}
	}

	getAttachmentLabel = (item) => {
		const { attachmentsList } = this.props;
		const attachment = attachmentsList.find(attachment => attachment.get('uuid') === item.get('attachmentId'));
		return attachment.get('label')
	}

	getPhaseItemsTypes(items) {
		const itemsTypes = [];

		const itemsTypesCount = {};
		items.map((item) => {
			if (typeof itemsTypesCount[item.typeKey] === 'undefined') {
				itemsTypesCount[item.typeKey] = {
					count: 0,
					typeName: item.typeName
				};
			}
			itemsTypesCount[item.typeKey] = {
				count: itemsTypesCount[item.typeKey]['count'] + 1,
				typeName: item.typeName
			};
		})

		for (const typeKey in itemsTypesCount) {
			if (itemsTypesCount.hasOwnProperty(typeKey)) {
				const count = itemsTypesCount[typeKey]['count'];
				// itemsTypes.push(`${count} ${this.props.getLabel(typeKey)}`);
				itemsTypes.push({
					count,
					typeKey,
					typeName: itemsTypesCount[typeKey]['typeName']
				});
			}
		}


		return itemsTypes;
	}

	getTypeNameWithLocale = (item) => {
		const labelForAttachmentType = item.get('type') === 'ATTACHMENT' ? this.getAttachmentLabel(item) : null;
		const type = item.get('type') === 'ATTACHMENT'? labelForAttachmentType : item.get('type');
		try{
			return this.props.getLabel(`${type.toLowerCase()}_label`);
		} catch (e){
			return item.get('typeName');
		}
	}

	getPhaseItems(phaseId) {
		const phaseItems = [];
		this.props.itemsList.map((item) => {
			const labelForAttachmentType = item.get('type') === 'ATTACHMENT' ? this.getAttachmentLabel(item) : null;
			if (item.get('phaseId') === phaseId) {
				phaseItems.push({
					name: item.get('name'),
					description: item.get('description'),
					typeKey: item.get('type') === 'ATTACHMENT' ? labelForAttachmentType : item.get('type'),
					typeName: this.getTypeNameWithLocale(item)
				});
			}
		});

		return phaseItems;
	}

	getModuleItemsList(moduleItemsList) {
		const phaseItems = [];
		moduleItemsList.map((item) => {
			phaseItems.push({
				name: item.get('title'),
				description: item.get('subtitle'),
				typeKey: item.get('type'),
				typeName: item.get('type_label'),
				duration: item.get('duration'),
				duration_unit: item.get('duration_unit')
			});
		});

		return phaseItems;
	}

	getModulesList(courseModulesList) {
		const phaseItems = [];
		
		courseModulesList.map((module) => {
			phaseItems.push({
				name: module.get('name'),
				description: module.get('description'),
				typeKey: null,
				typeName: null,
				duration: null,
				duration_unit: null
			});
		});

		return phaseItems;
	}

	getModulesForPhaseItem = (phaseItem) => {
		const {coursesModulesList} = this.props;
        const course = coursesModulesList.find(course => course.get('id') == phaseItem.get('toolId'));
        if (course && course.get('isCourseModulesListAvailable')) {
            return course.get('courseModulesList');
        }
        return null;
    }
    
    getModuleDetails = (phaseItem) => {
		const modules = this.getModulesForPhaseItem(phaseItem);
        if(modules){
            const module = modules.first();
            if(module){
                const {modulesItemsList} = this.props;
                const moduleDetails = modulesItemsList.find(listItem => listItem.get('id') == module.get('id'));
                return moduleDetails;
            }
        }
        return null;
    }

	getUpdatedPhasesList() {
		let updatePhases = [];
		this.props.phasesList.map((phase) => {
			if (phase.get('type') == enumConstants.get('DEVELOPMENT')
				&& checkIfPresent(phase.get('settings'))
				&& phase.get('settings').get('developmentItemType') == enumConstants.get('MODULE_ITEMS')
			) {
				let phaseItem = null;
				this.props.items.get('itemsList').map((eachItem) => {
					if(eachItem.get('phaseId') == phase.get('id')) {
						phaseItem = eachItem;
					}					
				});

				const moduleDetails = this.getModuleDetails(phaseItem);

				updatePhases.push({
					...phase.toJS(),
					name: this.props.getLabel(phase.get('name')),
					description: this.props.getLabel(phase.get('description')),
					startTime: convertDateWithYear(phase.get('startTimestamp')),
					endTime: convertDateWithYear(phase.get('endTimestamp')),
					items: moduleDetails && moduleDetails.get('isModuleItemsListAvailable')
					? this.getModuleItemsList(moduleDetails.get('moduleItemsList')) : [],
					itemTypes: [],
					isModuleItemPhase: true,
					isPreview: checkIfPresent(phase.get('settings').get('isPreview')) ? phase.get('settings').get('isPreview') : false,
					itemsLoaded: true
				});
				
			} else if (phase.get('type') == enumConstants.get('DEVELOPMENT')
				&& checkIfPresent(phase.get('settings'))
				&& phase.get('settings').get('developmentItemType') == enumConstants.get('MODULE')
			) {
				let phaseItem = null;
				this.props.items.get('itemsList').map((eachItem) => {
					if(eachItem.get('phaseId') == phase.get('id')) {
						phaseItem = eachItem;
					}					
				});
				
				let courseDetails = null;
				if (phaseItem){
					courseDetails = this.props.coursesModulesList.find(course => course.get('id') == phaseItem.get('toolId'));
				}

				updatePhases.push({
					...phase.toJS(),
					name: this.props.getLabel(phase.get('name')),
					description: this.props.getLabel(phase.get('description')),
					startTime: convertDateWithYear(phase.get('startTimestamp')),
					endTime: convertDateWithYear(phase.get('endTimestamp')),
					items: courseDetails && courseDetails.get('isCourseModulesListAvailable') ? this.getModulesList(courseDetails.get('courseModulesList')) : [],
					itemTypes: [],
					isModuleItemPhase: true,
					isPreview: checkIfPresent(phase.get('settings').get('isPreview')) ? phase.get('settings').get('isPreview') : false,
					itemsLoaded: true
				});

			} else {
				const items = this.getPhaseItems(phase.get('id'));
				const itemsTypes = this.getPhaseItemsTypes(items);
				updatePhases.push({
					...phase.toJS(),
					name: this.props.getLabel(phase.get('name')),
					description: this.props.getLabel(phase.get('description')),
					startTime: convertDateWithYear(phase.get('startTimestamp')),
					endTime: convertDateWithYear(phase.get('endTimestamp')),
					items,
					itemsTypes,
					isModuleItemPhase: false,
					isPreview: checkIfPresent(phase.get('settings').get('isPreview')) ? phase.get('settings').get('isPreview') : false,
					itemsLoaded: true
				});
			}
		});

		return updatePhases;
	}

	renderPhases(phases) {
		return phases.map((eachPhase, index) => {
			var lastPhase = index === (phases.length - 1) ? true : false;
			if(eachPhase.isPreview == "true") {
				return null;
			}
			return (
				<SinglePhase
					itemsList={this.props.itemsList}
					phasesList={this.props.phasesList}
					eachPhase={eachPhase}
					index={index}
					key={`singlePhaseItem-${index}`}
					lastPhase={lastPhase}
					showMoreItemNumber={this.state.showMoreItemNumber}
					attachmentsList={this.props.attachmentsList}
				/>
			);
		});
	}

	renderLoader() {
		return (
			<div styleName="journey-learnings-container">
				<div styleName="journey-learnings-content">
					<JourneyLearningsPhaseLoader />
				</div>
			</div>
		);
	}

	render() {
		if (
			this.props.loaders.get('isFetchingBatchDashboardData')
			|| !this.props.isBatchLabelsAvailable
		) {
			return this.renderLoader();
		}

		if (this.props.phasesList.isEmpty()) {
			return <div styleName="no-phases">
				<center>
					{this.props.getLabelWithDefaults('journey_no_phases_label', 'There are no phases in this journey')}
				</center>
			</div>;
		}

		const updatedPhasesList = this.getUpdatedPhasesList();

		return (
			<div styleName="journey-learnings-container">
				<div styleName="journey-learnings-content">
					{this.renderPhases(updatedPhasesList)}
				</div>
			</div>
		)
	}
}

export default JourneyLearnings;
