import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
        text: {
            ...myTypography.overline,
            color: skinHex.get('greyColor2'),
            fontWeight: 300
        },
        recommendedText: {
            ...myTypography.overline,
            color: hexToRgbA(skinHex.get('greyColor2'), 0.51),
            fontWeight: 600,
            marginLeft: '5px',
            lineHeigh: 'normal'
        },
        goToHome: {
            ...myTypography.button,
            color: skinHex.get('secondaryColor'),
            borderColor: skinHex.get('secondaryColor')
        }
	});
}

export default getSkin;