import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setGenericAnalyticsData } from 'actions/analytics/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchGenericData extends NetPackWrapper {

    constructor() {
        super("GET");        
	}

    prepareUrl = (apiInfoObject) => {
        let url =  urls.get('ANALYTICS').get('GET_GENERIC_ANALYTICS_DATA');
        return url.replace('BATCH_ID', apiInfoObject.batchId).replace('USER_ID', '' + apiInfoObject.userId)
    }

    successCall = (response) => {
        this.dispatch(setGenericAnalyticsData(response));
        this.dispatch(setApiError('fetchGenericAnalyticsDataError', null));
        return response;
    }

    onError = (errorObj) => {
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchGenericAnalyticsDataError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchGenericData();
