import React, { Component } from 'react';
import styles from './lockBox.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import FirstTimeDashboard from 'svgComponents/firstTimeDashboard';
import Close from 'svgComponents/close';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LockBox extends Component {

    renderSubHeading = (myStyles) => {
        if (this.props.subHeading !== '') {
            return (
                <div className={css(myStyles.description)} styleName="description-container"
                    dangerouslySetInnerHTML={{__html: this.props.subHeading}}
                ></div>
            );
        }
        return null;
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const contentContainerStyle = this.props.subHeading !== ''
            ? 'content-container'
            : 'content-container-only-heading';
        return (
            <div className={css(myStyles.contentContainer)} styleName={contentContainerStyle}>
                <div styleName="main-content">
                    <div className={css(myStyles.title)} styleName="title-container">
                        {this.props.heading}
                    </div>
                    {this.renderSubHeading(myStyles)}
                </div>
            </div>
        );
    }
}

export default LockBox;
