import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './reviewQuizNavigator.module.sass';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class ReviewQuizNavigator extends React.Component {

	scrollToQuestion = (questionId) => {
		let { setCurrentQuestion } = this.props;
		setCurrentQuestion(questionId)
	}

	getQuestionCircles = () => {
		let styles = getSkin(this.props.skinGuide);
		let { showCorrectAnswers } = this.props;
		return this.props.questions.map((question, index) => {
			let bubbleClass = ""
			if (question.user_answers.length > 0) {
				if (showCorrectAnswers) {
					if (question.correct === true) {
						bubbleClass = "correct-answered"
					} else {
						bubbleClass = "wrong-answered"
					}
				} else {
					bubbleClass = 'user-selected'
				}
			}
			return (
				<div key={`question_${question.id}_navigator`} styleName={`individual-question-bubble ${bubbleClass}`} onClick={() => this.scrollToQuestion(question.id)}>
					{index + 1}
					{
						// question.flagged &&
						// <div styleName="flagged-bubble"></div>
					}
				</div>
			)
		})
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		return (
			<div styleName="questions-navigator-cnt">
				<div styleName="questions-navigator-header">
					{this.props.getLabelWithDefaults('questions_label', 'QUESTIONS')}
				</div>
				<div styleName="quiz-question-bubbles-cnt">
					{this.getQuestionCircles()}
				</div>
			</div>
		)
	}
}

export default ReviewQuizNavigator;