import React, { Component } from 'react';
import styles from './leftArrow.module.sass';
import CSSModules from 'react-css-modules';

@CSSModules(styles, { allowMultiple: true })
class LeftArrow extends Component {

	render() {
		return (
			<svg width="100%" height="100%" viewBox="0 0 8 11">
				<path fill={this.props.fillColor || "#000"} fillOpacity={this.props.fillOpacity || ".8"} fillRule="nonzero" d="M7.142 10.74a.604.604 0 0 1-.374-.131L.974 5.949a.598.598 0 0 1 0-.932L6.744.381a.597.597 0 0 1 .747.93L2.3 5.484l5.216 4.195a.597.597 0 0 1-.375 1.062z" />
			</svg>
		);
	}
}



export default LeftArrow;
