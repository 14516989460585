import React, { Component } from 'react';
import styles from './singlePhase.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import CalendarSvg from 'svgComponents/calendarSvg';
import ModuleItemTypes from 'svgComponents/moduleItemTypes';
import { TweenMax } from 'gsap';
import { checkIfPresent } from 'utils/utilFunctions';
import enumConstants from 'constants/enums';
import NextActivityLoader from 'commonComponents/loaders/nextActivityLoader';
import { isMobile, isTablet } from 'react-device-detect';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SinglePhase extends Component {

	constructor(props) {
		super(props);
		this.state = {
			eachPhaseDiv: React.createRef(),
			showMoreOptionAvailable: (isTablet || isMobile) ? false: true
		}
	}

	openShowMore = () => {
		this.setState({
			showMoreOptionAvailable: false
		});
	}

	getMainContainerStyle = (length) => {
		if (this.state.showMoreOptionAvailable) {
			return ({
				height: 325 + (this.props.showMoreItemNumber * 272)
			});
		} else {
			const currentDiv = this.state.eachPhaseDiv.current;
			TweenMax.to(currentDiv, 1, { height: 325 + (length * 272) });
			return ({
				// maxHeight: 'none'
			});
		}
	}

	renderPhaseItemsTypes = (itemsTypes, myStyles) => {
		const items = itemsTypes.map((itemType, index) => {
			let itemTypeLabel = itemType.typeName.toLowerCase();
			//TODO: fix pluralization
			/* if (itemType.count > 1) {
				itemTypeLabel = `${itemTypeLabel}s`;
			} */
			return (
				<div key={index} styleName="single-item-type">
					<div styleName="single-item-image">
						<ModuleItemTypes itemType={itemType.typeKey.toLowerCase()} />
					</div>
					<div className={css(myStyles.itemTypeText)} styleName="single-item-text">
						{itemType.count} {itemTypeLabel}
					</div>
				</div>
			);
		});
		return items;
	}

	renderIntelligentPerformanceSection = (myStyles, eachPhase) => {
		// return (
		// 	<div styleName="intelligent-rec-container">
		// 		<div styleName="intel-rec-image"><LikeChat /></div>
		// 		<div className={css(myStyles.intelRecDesc)} styleName="intel-rec-desc">
		// 			{this.props.getLabel('intelligent_performance_label')}
		// 		</div>
		// 	</div>
		// );
		return null;
	}

	renderDuration = (duration, duration_unit, lastItemStyle, showMoreLastItemPaddingRight) => {
		const myStyles = getSkin(this.props.skinGuide);
		if (checkIfPresent(duration) && checkIfPresent(duration_unit)) {
			return (
				<div styleName="sub-phase-box-duration" style={{ ...lastItemStyle, ...showMoreLastItemPaddingRight }}>
					<div className={css(myStyles.subBoxDuration)} styleName="title">{duration} {duration_unit}</div>
				</div>
			);
		}
		return null;
	}

	renderDurationLabel = (phase) => {

		if (phase.startTime && !phase.endTime) {
			return `Starts on ${phase.startTime}`
		}

		if (!phase.startTime && phase.endTime) {
			return `Ends on ${phase.endTime}`
		}

		return `${phase.startTime} - ${phase.endTime}`;
	}

	renderSinglePhase(eachPhase, index, lastPhase, myStyles) {
		var phaseItems = null;
		if (!eachPhase.itemsLoaded) {
			phaseItems = <div key={`singleSubPhaseItem-${eachPhase.id}`} styleName="single-phase-item-container">
				<div styleName="left-side">
					<div className={css(myStyles.leftLine)} styleName="left-side-line" style={{ paddingBottom: '0', height: '100px' }}>
						<div className={css(myStyles.subDot)} styleName="sub-phase-dot"></div>

					</div>
				</div>
				<div styleName="right-side" style={{ paddingBottom: '100px', height: '100px' }}>
					<div styleName="sub-phase-box-loader">
						<NextActivityLoader />
					</div>
				</div>
			</div>
		} else {

			phaseItems = eachPhase.items.map((eachItem, index) => {
				if (index >= this.props.showMoreItemNumber && this.state.showMoreOptionAvailable) {
					return null;
				}
				var lastItemStyle = {};
				if (index === eachPhase.items.length - 1) {
					lastItemStyle = {
						paddingBottom: "50px"
					}
				}
				// var lastItemDotStyle = {"display": "none"};
				// if(lastPhase && (index == (eachPhase.items.length - 1))) {
				// 	lastItemDotStyle = {};
				// }
				var lastItemLineStyle = {};
				if (lastPhase && (index === (eachPhase.items.length - 1))) {

					lastItemLineStyle = {
						"height": "50px",
						"paddingBottom": 0
					};
				}
				var showMoreLastItemPaddingLeft = {};
				var showMoreLastItemPaddingRight = {};
				if (index === (this.props.showMoreItemNumber - 1) && this.state.showMoreOptionAvailable) {
					showMoreLastItemPaddingLeft = { "paddingBottom": "60px" };
					showMoreLastItemPaddingRight = { "paddingBottom": "100px" };
				}
				return (
					<div key={`singleSubPhaseItem-${index}`} styleName="single-phase-item-container">
						<div styleName="left-side">
							<div className={css(myStyles.leftLine)} styleName="left-side-line" style={{ ...showMoreLastItemPaddingLeft, ...lastItemLineStyle }}>
								<div className={css(myStyles.subDot)} styleName="sub-phase-dot"></div>
								{/* <div className={css(myStyles.lastDot)} styleName="sub-phase-last-dot" style={lastItemDotStyle}></div> */}
							</div>
						</div>
						<div styleName="right-side">
							{
								eachPhase.isModuleItemPhase
									? [
										<div
											styleName={
												(checkIfPresent(eachItem.duration) && checkIfPresent(eachItem.duration_unit))
													? "sub-phase-box-desc-with-duration"
													: "sub-phase-box"
											}
											style={{ ...lastItemStyle, ...showMoreLastItemPaddingRight }}>
											<div className={css(myStyles.subBoxTitle)} styleName="title">{eachItem.name}</div>
											<div className={css(myStyles.subBoxDesc)} dangerouslySetInnerHTML={{ __html: eachItem.description }}></div>
										</div>,
										this.renderDuration(eachItem.duration, eachItem.duration_unit, lastItemStyle, showMoreLastItemPaddingRight)
									]
									: <div styleName="sub-phase-box" style={{ ...lastItemStyle, ...showMoreLastItemPaddingRight }}>
										<div className={css(myStyles.subBoxTitle)} styleName="title">{eachItem.name}</div>
										<div className={css(myStyles.subBoxDesc)} dangerouslySetInnerHTML={{ __html: eachItem.description }}></div>
									</div>
							}
						</div>
					</div>
				);
			});
			if (eachPhase.items.length > this.props.showMoreItemNumber && this.state.showMoreOptionAvailable) {
				phaseItems.push(
					<div key={`showMore-${index}`} styleName="journey-show-container">
						<div className={css(myStyles.journeyShowLine)} styleName="journey-show-line"></div>
						<div styleName="journey-show-button-container">
							<div
								className={css(myStyles.journeyShowButton)}
								styleName="journey-show-button"
								onClick={() => { this.openShowMore() }}>
								{this.props.getLabel('show_more_label')}
							</div>
						</div>
						<div className={css(myStyles.journeyShowLine)} styleName="journey-show-line"></div>
					</div>
				);
			}

		}

		var leftSideStyle = {};
		if (index === 0) {
			leftSideStyle = {
				"marginTop": "55px"
			};
		}
		var mainDotStyle = {};
		var mainDotOutsideStyle = {};
		if (index !== 0) {
			mainDotStyle = {
				"top": "46px"
			};
			mainDotOutsideStyle = {
				"top": "39px"
			}
		}

		return (
			<div ref={this.state.eachPhaseDiv} key={this.props.key} styleName="single-phase-container">
				<div
					className={css(myStyles.mainBackground)}
					styleName="single-phase-item-container"
				>
					<div styleName="left-side">
						<div className={css(myStyles.leftLine)} style={leftSideStyle} styleName="left-side-line">
							<div className={css(myStyles.mainDot)} styleName="main-phase-dot" style={mainDotStyle}></div>
							<div className={css(myStyles.mainDotOutside)} styleName="main-phase-dot-outside" style={mainDotOutsideStyle}></div>
						</div>
					</div>
					<div styleName="right-side">
						<div styleName="main-phase-box">
							<div styleName="title-container">
								<div
									className={css(myStyles.mainBoxTitle)}
									dangerouslySetInnerHTML={{ __html: eachPhase.name }}
								></div>
								{
									(eachPhase.startTime || eachPhase.endTime) &&
									<div styleName="duration-container">
										<div styleName="calendar-image"><CalendarSvg /></div>
										<div styleName="timeline-content">
											<div className={css(myStyles.durationText)}>{this.props.getLabel('duration_label')}</div>
											<div className={css(myStyles.periodText)}>
												{this.renderDurationLabel(eachPhase)}
											</div>
										</div>
									</div>
								}
							</div>
							{
								checkIfPresent(eachPhase.description) &&
								<div className={css(myStyles.mainBoxDesc)} styleName="desc" dangerouslySetInnerHTML={{ __html: eachPhase.description }}></div>
							}
							<div className={css(myStyles.mainBoxAddDetails)} styleName="add-details">
								{eachPhase.isModuleItemPhase ? null : this.renderPhaseItemsTypes(eachPhase.itemsTypes, myStyles)}
							</div>
							{
								eachPhase.type == enumConstants.get('DEVELOPMENT')
									? this.renderIntelligentPerformanceSection(myStyles, eachPhase)
									: null
							}
							{/* {this.renderIntelligentPerformanceSection(myStyles, eachPhase)} */}
						</div>
					</div>
				</div>

				{phaseItems}
			</div>
		);
	}

	render() {

		const { eachPhase, index, lastPhase } = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		return this.renderSinglePhase(eachPhase, index, lastPhase, myStyles);
	}
}

export default SinglePhase;
