import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './question.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite/no-important';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Question extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const {question} = this.props;

        return (
            <div styleName="question-container">
                <div styleName="question-text" className={css(myStyles.questionText)}
                    dangerouslySetInnerHTML={{__html: question.get('text')}}
                >
                    {/* {question.get('text')} */}
                </div>
                <div styleName="question-text" className={css(myStyles.questionDesc)}
                    dangerouslySetInnerHTML={{__html: question.get('desc')}}
                >
                    {/* {question.get('desc')} */}
                </div>
            </div>
        );
    }
}

export default Question;
