import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './summaryComponent.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import AreaGraphWithInfo from './areaGraphWithInfo';
import OverLappingBarsWithInfo from './overLappingBarsWithInfo';
import HorizontalBarsWithInfo from './horizontalBarsWithInfo/HorizontalBarsWithInfo';


@CSSModules(styles, { allowMultiple: true })

class SummaryComponent extends React.Component {


    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props)){
            return false;
        }
        else{
            return true;
        }
    }

    render(){
        
        return(
            <div styleName="container-class">
                <LegendComponent title={this.props.strings.get('summaryKey')} {...this.props}/>
                <HorizontalBarsWithInfo {...this.props} />
                <AreaGraphWithInfo {...this.props} />
                <OverLappingBarsWithInfo {...this.props} />
            </div>
        )
    }
}


export default SummaryComponent;