import React, { Component } from 'react';
import styles from './journeyDetails.module.sass';
import CSSModules from 'react-css-modules';
import JourneyLearnings from './journeyLearnings';

@CSSModules(styles, { allowMultiple: true })
class JourneyDetails extends Component {
    render() {
        return (
            <div ref={this.props.divRef} styleName="complete-journey-details" id='aboutIndex'>
                <JourneyLearnings
                    phasesList={this.props.phasesList}
                    itemsList={this.props.itemsList}
                    items={this.props.items}
                    loaders={this.props.loaders}
                    course={this.props.course}
                    coursesModulesList={this.props.coursesModulesList}
                    modulesItemsList={this.props.modulesItemsList}
                    attachmentsList={this.props.attachmentsList}
                />
            </div>
        )
    }
}

export default JourneyDetails;
