import React, { Component } from 'react';
import styles from './publicJourneysCatalogue.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import JourneyCatalogueContainer from 'containers/JourneyCatalogueContainer';
import InfoComponent from 'commonComponents/analytics/infoComponent';
import getSkin from './skin.js';
import JourneysLoader from 'commonComponents/loaders/journeysLoader';
import CatalogueHeader from './catalogueHeader';
import Footer from 'commonComponents/footer';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PublicJourneyCatalogue extends Component {

	redirectToSignup = () => {
		let orgHash = "";
		let userHash = "";
		let urlPathTokens = window.location.pathname.split('/');
		orgHash = urlPathTokens[2];
		if (urlPathTokens.indexOf("user") > 0) {
			userHash = urlPathTokens[4];
		}
		if (userHash !== "")
			window.location = `/organization-user/${userHash}/signup`;
		else
			window.location = `/organization/${orgHash}/signup`;
	}

	getJourneyListComponent = () => {
		const { userOrganization, getLabel } = this.props;
		if (userOrganization.getIn(['userOrganizationDataLoading']))
			return <JourneysLoader />

		if (!userOrganization.get('userOrganization') || userOrganization.get('userOrganization').size === 0)
			return <InfoComponent {...this.props} infoMessage={getLabel('wrong_org_code_entered_label')} />

		return <JourneyCatalogueContainer
			alwaysSignUp={true}
			orgCode={userOrganization.getIn(['userOrganization', 'code'])}
			fromPublicCatalog={true}
			match={this.props.match}
		/>
	}

	render() {
		const { getLabel } = this.props;
		const { userOrganization, skinGuide } = this.props;
		const myStyles = getSkin(skinGuide);


		// return  (

		// 	<JourneyCatalogueContainer alwaysSignUp={true} orgCode={userOrganization.getIn(['userOrganization', 'code'])} />
		// )

		return (
			<div styleName="journeys-cnt" className>
				<CatalogueHeader organization={userOrganization} />

				<div className={css(myStyles.welcomeCntBorder)}>
					<div styleName="signup-header-cnt">
						<div styleName="label-cnt">
							<div className={css(myStyles.signUpHeaderText)}>
								{getLabel('catalog_sign_up_to_request_label')}
							</div>
							<span
								className={css(myStyles.signupNowText)}
								onClick={this.redirectToSignup}
							>
								{getLabel('signup_now_label')}
							</span>
						</div>
					</div>
					<div className={css(myStyles.welcomeBg)} styleName="welcome-user-bg">
						<div styleName="welcome-bg" className={css(myStyles.welcomeBgImg)}></div>
						<div className={css(myStyles.backToHome)} styleName="label-cnt">
							{getLabel("journey_catalogue_label")}
						</div>
					</div>
				</div>
				<div styleName="catalogue-content">
					{
						this.getJourneyListComponent()
					}
				</div>
				<Footer />
			</div>
		)
	}
}

export default PublicJourneyCatalogue;