import React, { Component } from 'react';
import styles from './externalUrlsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ExternalUrlsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="icon-cnt">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<g fill="none" fillRule="evenodd">
						<path stroke="#828593" strokeLinecap="round" strokeLinejoin="round" d="M13.693 5.814v-3.5a.875.875 0 0 0-.875-.875h-10.5a.875.875 0 0 0-.875.875v9.625c0 .483.392.875.875.875h3.5"/>
						<path fill="#828593" d="M2 1h11a1 1 0 0 1 1 1v2H1V2a1 1 0 0 1 1-1zm1 2a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm1.5 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM6 3a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
						<path stroke="#828593" strokeLinecap="round" strokeLinejoin="round" d="M11.071 12.306c.057.411-.08.825-.373 1.12l-.753.754a1.41 1.41 0 0 1-1.992-1.992l.753-.753a1.328 1.328 0 0 1 1.096-.376M12.31 11.067c.41.057.825-.08 1.12-.373l.753-.753a1.41 1.41 0 0 0-1.991-1.992l-.75.754a1.325 1.325 0 0 0-.376 1.095M9.756 12.377l2.625-2.625"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default ExternalUrlsIcon;
