import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules( { allowMultiple: true })
class ForwardButton extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <svg width="100%" height="100%" viewBox="0 0 76 69">
                <path fill={this.props.buttonStyle.fill} fill-opacity={this.props.buttonStyle.fillOpacity} fill-rule="nonzero" d="M0 44c0 24.3 19.7 44 44 44s44-19.7 44-44S68.3 0 44 0C19.711.028.028 19.711 0 44zm50.259-19.1l16.5 16.5a3.667 3.667 0 0 1 0 5.185l-16.5 16.5a3.667 3.667 0 0 1-5.185-5.185l8.676-8.679a.917.917 0 0 0-.65-1.554H22a3.667 3.667 0 1 1 0-7.334h31.1a.917.917 0 0 0 .65-1.565l-8.676-8.683A3.667 3.667 0 0 1 50.26 24.9z"/>
            </svg>

        );
    }
}



export default ForwardButton;
