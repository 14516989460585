import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (skinGuide) => {
	const myTypography = typography(skinGuide);

	return StyleSheet.create({
		tabContainer: {
			...myTypography.header,
			fontWeight: 600,
			fontSize: '20px',
			color: '#2e344b',
			borderBottom: `solid 4px #2e344b`
		}
	});
}

export default getSkin;