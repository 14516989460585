import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setUserSubmissions, fetchingUserQuizSubmissionsFlag } from 'actions/surveyQuiz/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';

class FetchQuizResponse extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject) => {
        const url = urls.get('PLATFORM').get('GET_USER_QUIZ_SUBMISSIONS');
        return appendQueryParameters(url, apiInfoObject.queryParameters);
    }

    beforeCall = (apiInfoObject = {}) => {
        this.dispatch(fetchingUserQuizSubmissionsFlag(true));
        return apiInfoObject;
    }

    successCall = (response) => {
        this.dispatch(setUserSubmissions(response));
        this.dispatch(fetchingUserQuizSubmissionsFlag(false));
        return response;
    }

    onError = (response) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong while loading the quiz response. Please refresh the page.',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
        this.dispatch(fetchingUserQuizSubmissionsFlag(false));

    }

}

export default new FetchQuizResponse();
