import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './itemStatus.module.sass';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import Footer from 'commonComponents/footer';
import applyLabel from 'utils/LabelProvider';
import routes from 'utils/webAppRoutes/routeConstants';
import SelectedTag from 'svgComponents/selectedTag';
import { capitalizeFirstLetter, convertDateWithYear } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ItemStatus extends Component {

    getFinishedTimestamp = () => {
        if (this.props.selectedUserItem.get('updatedTimestamp')) {
            const date = convertDateWithYear('2018-10-23T16:55:39.000Z')
            return 'Finished on ' + date
        }
        return null
    }

    render() {
        const {
            skinGuide,
            activePhaseDetails,
            selectedItem,
            buttonText,
            isBatchLabelsAvailable
        } = this.props;
        const myStyles = getSkin(skinGuide);

        if (!selectedItem.get('name')) {
            return null
        }

        return (
            <div className={css(myStyles.completionNote)} styleName="completion-note">
                {this.props.getLabelWithDefaults(
                    'completion_note',
                    `You’ve completed ${selectedItem.get('name')}`,
                    {entity_name: selectedItem.get('name')}
                )}
            </div>
        )

        return (
            <div styleName="status-details">
                {/* <div className={css(myStyles.timestamp)}> 
                    {this.getFinishedTimestamp()}
                </div> */}
                <div styleName='item-status'>
                    <div styleName="icon">
                        <SelectedTag />
                    </div>
                    <div styleName="completed" className={css(myStyles.completed)}>
                        {capitalizeFirstLetter(this.props.getLabel('completed_label'))}
                    </div>
                </div>
            </div>
        );
    }
}

export default ItemStatus;
