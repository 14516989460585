import React, { Component } from 'react';
import styles from './digitalToolHeader.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DownloadIcon from 'svgComponents/downloadIcon';
import HelpIcon from 'svgComponents/helpIcon';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class DigitalToolHeader extends Component {

	render() {
		const { skinGuide, toggleShowGuide, submitAction, downloadAction } = this.props;
		const myStyles = getSkin(skinGuide);

		return (
			<div styleName="digital-tool-header">
				<div className={css(myStyles.headerTitle)}>
					{this.props.toolData.toolName}
				</div>
				<div styleName="header-actions">
					<div styleName="download-cnt">
						<div
							styleName="innner-cnt"
							style={{ cursor: 'not-allowed' }}
							title="Coming Soon..."
							onClick={downloadAction}
						>
							<div styleName="download-icon-cnt">
								<DownloadIcon fillColor={'#ffffff'} />
							</div>
							<div className={css(myStyles.iconText)}>Download</div>
						</div>
					</div>
					<div styleName="guide-cnt">
						<div styleName="innner-cnt" onClick={toggleShowGuide}>
							<div styleName="guide-icon-cnt">
								<HelpIcon fillColor={'#ffffff'} />
							</div>
							<div className={css(myStyles.iconText)}>
								Guide
							</div>
						</div>
					</div>
					<div
						styleName="submit-cnt"
						className={css(myStyles.submitBtnText)}
						onClick={submitAction}
					>
						Submit
					</div>
				</div>
			</div>
		);
	}
}

export default DigitalToolHeader;