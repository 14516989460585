import React, { Component } from 'react';
import styles from './toolItemList.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import CloseCircle from 'svgComponents/closeCircle'
import uuidv4 from 'uuid/v4'
import ToolItemListPopup from '../toolItemListPopup';
import FullScreenIcon from 'svgComponents/fullScreenIcon';
import { checkIfPresent } from 'utils/utilFunctions';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ToolItemList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentInputItem: '',
			clickedItemUUID: null,
			openItemListPopup: false
		}
		this.sortedItemsList = [];
		this.listEnd = React.createRef();
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.userData && this.props.userData && Object.keys(prevProps.userData).length !== Object.keys(this.props.userData).length)
			this.scrollToBottom()
	}

	scrollToBottom = () => {
		this.listEnd.current.scrollTop = this.listEnd.current.scrollHeight + 35;
	}

	openItemListPopup = (selectedItemUuid) => {
		this.setState({
			clickedItemUUID: selectedItemUuid,
			openItemListPopup: true
		})
	}

	closeItemListPopup = () => {
		this.setState({
			clickedItemUUID: null,
			openItemListPopup: false
		})
	}

	addValueToItemList = (event) => {
		let tempUUID = uuidv4();
		if (event.keyCode == 13 && this.state.currentInputItem !== '') {
			let newOrder = 1;
			if (this.sortedItemsList.length) {
				newOrder = this.sortedItemsList[this.sortedItemsList.length - 1].order + 1
			}

			let itemsList = {
				...this.props.userData,
				[`${tempUUID}`]: {
					text: this.state.currentInputItem,
					uuid: tempUUID,
					order: newOrder
				}
			}
			this.props.addNewUserDataRecord(itemsList, this.props.type)
			this.setState({
				currentInputItem: ''
			});
		}
	}

	handleChange = (event) => {
		this.setState({
			currentInputItem: event.target.value
		})
	}

	onDeleteClick = (uuid, type, event) => {
		event.stopPropagation();
		event.preventDefault();
		this.props.deleteRecord(uuid, type);
	}

	renderItems = (list) => {
		const myStyles = getSkin(this.props.skinGuide);
		const { type } = this.props;

		return list.map((item) => {
			return (
				<div
					key={`item_${item.uuid}`}
					styleName="individual-item"
					onClick={() => this.openItemListPopup(item.uuid)}
				>
					<div className={css(myStyles.itemText)} styleName="text">
						{item.text}
					</div>
					<div
						styleName="close-btn-cnt"
						onClick={(e) => this.onDeleteClick(item.uuid, type, e)}
					>
						<CloseCircle
							circleFill="white"
							circleFillOpacity={1}
							strokeColor="#4a53ae"
							strokeWidth={3}
						/>
					</div>
				</div>
			)
		});
	}

	itemsList = () => {
		const { userData } = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		let userDataArray = userData ? Object.values(userData) : [];

		if (!Object.values(userDataArray).length && checkIfPresent(this.props.instructions)) {
			return (
				<div className={css(myStyles.instructionsText)}>
					{this.props.instructions}
				</div>
			)
		}

		let listComponent = [];

		let userDataWithoutOrder = userDataArray.filter(item => !item.order);
		this.sortedItemsList = userDataArray.filter(item => item.order);

		this.sortedItemsList.sort((a, b) => a.order - b.order);

		listComponent.push(this.renderItems(userDataWithoutOrder));
		listComponent.push(this.renderItems(this.sortedItemsList));
		return listComponent;
	}

	render() {
		const { selectedColorPalette, colorPalette, cardStyles } = this.props;
		let cardBackgroundColor = 'rgba(255, 255, 255, 0.1)';
		if (selectedColorPalette && colorPalette[selectedColorPalette] && colorPalette[selectedColorPalette].cardBackground) {
			cardBackgroundColor = colorPalette[selectedColorPalette].cardBackground;
		}
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div
				styleName="tool-items-cnt"
				style={cardStyles ? { ...cardStyles, backgroundColor: cardBackgroundColor } : { backgroundColor: cardBackgroundColor }}
			>
				<div
					styleName="items-list-scrollable-cnt"
					style={{ maxHeight: cardStyles ? `calc(${cardStyles.maxHeight} - 56px)` : '110px' }}
					ref={this.listEnd}
				>
					{this.itemsList()}
				</div>
				<div styleName="input-box-cnt">
					<input
						type="text"
						value={this.state.currentInputItem}
						className={css(myStyles.inputText)}
						onChange={this.handleChange}
						onKeyDown={this.addValueToItemList}
						styleName="input-box"
						placeholder="Type here"
					/>
				</div>
				{
					this.state.openItemListPopup &&
					<ToolItemListPopup
						uuid={this.state.clickedItemUUID}
						itemsList={this.props.userData}
						addNewUserDataRecord={this.props.addNewUserDataRecord}
						deleteRecord={this.props.deleteRecord}
						type={this.props.type}
						closeItemListPopup={this.closeItemListPopup}
						colorPalette={colorPalette}
						selectedColorPalette={selectedColorPalette}
						setColorPalette={this.props.setColorPalette}
					/>
				}
				<div
					styleName="full-screen-icon-cnt"
					onClick={() => this.openItemListPopup(null)}
					style={{ backgroundColor: cardBackgroundColor, backgroundImage: `${cardStyles ? cardStyles.backgroundImage : ''}` }}
				>
					<FullScreenIcon />
				</div>
			</div>
		);
	}
}

export default ToolItemList;