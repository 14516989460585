import FetchBatchFeedbackStatus from 'actions/serverCalls/batchFeedbackStatus/FetchBatchFeedbackStatus';
import FetchBatchesFeedbackStatusFlags from 'actions/serverCalls/batchFeedbackStatus/FetchBatchesFeedbackStatusFlags';


import { setBatchFeedbackStatus, setBatchesFeedbackStatusFlags } from 'actions/batchFeedbackStatus/actionCreators';

const fetchBatchFeedbackStatusThunk = async (batchId, dispatch) => {
    const status = await FetchBatchFeedbackStatus.call({batchId});
    dispatch(setBatchFeedbackStatus(status));
}

export const fetchBatchFeedbackStatus = (batchId) => (dispatch, getState) =>  {
    fetchBatchFeedbackStatusThunk(batchId, dispatch);
}

const fetchBatchesFeedbackStatusFlagsThunk = async (batchIds, dispatch) => {
    const statusFlags = await FetchBatchesFeedbackStatusFlags.call({
        queryParameters: {
            batchIds: batchIds.join(',')
        }
    });
    dispatch(setBatchesFeedbackStatusFlags(statusFlags));
}

export const fetchBatchesFeedbackStatusFlags = (batchIds) => (dispatch, getState) =>  {
    fetchBatchesFeedbackStatusFlagsThunk(batchIds, dispatch);
}