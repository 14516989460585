import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');

    return StyleSheet.create({
        phaseBackground: {
            backgroundColor: skin.get('greyColor6')
        }
    });
}

export default getSkin;