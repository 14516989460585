import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		/* categoryName: {
			...myTypography.caption,
			color: 'rgba(87,94,121,0.8)',
			// fontWeight: 500,
			textTransform: 'capitalize'
		}, */
		mainContainer: {
            background: skinHex.get('white')
        },
        heading: {
            ...myTypography.h6,
            fontSize: '18px',
            fontWeight: 700,
            color: '#767D98'
        },
        courseTitle: {
            ...myTypography.h6,
            fontSize: '18px',
            fontWeight: 700,
            color: '#5C606C',
            lineHeight: '25px'
        },
        sessionTitle: {
            ...myTypography.h6,
            fontSize: '18px',
            fontWeight: 700,
            color: skinHex.get('secondaryColor'),
            lineHeight: '25px'
        },
        sessionDateTime: {
            ...myTypography.caption,
            fontSize: '14px',
            fontWeight: 400,
            color: '#5F5E5E',
            lineHeight: '19px'
        },
        facilitator: {
            ...myTypography.caption,
            fontSize: '13px',
            fontWeight: 400,
            color: '#5F5E5E',
            lineHeight: '18px'
        },
        sessionDescription: {
            ...myTypography.caption,
            fontSize: '12px',
            fontWeight: 400,
            color: '#5F5E5E',
            lineHeight: '16px'
        },
        labelBold: {
            fontWeight: 700
        },
        formatName: {
			...myTypography.caption,
			color: 'white',
			backgroundColor: '#551972',
			textTransform: 'uppercase'
		},
        joinSessionButtonCnt: {
			...myTypography.caption,
			color: skinHex.get('secondaryColor'),
			fontWeight: 700
		},
		activeSessionText: {
			...myTypography.caption,
			fontWeight: 700,
			color: '#5F5E5E'
		},
		joinSession: {
			backgroundColor: skinHex.get('secondaryColor')
		},
		joinText: {
			...myTypography.caption,
			fontWeight: 700,
			color: skinHex.get('white')
		}
	});
}

export default getSkin;