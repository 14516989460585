import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme, completionRequirement, isCurrentItem, onlyPhaseItem, isItemLocked = false) => {
	const colors = theme.get('skinHex');
	const myTypography = typography(theme);

	let moduleItemMarker = {
		backgroundColor: colors.get('greyColor4')
	}

	let itemTitle = {
		...myTypography.h6,
		lineHeight: 1.35,
		color: '#384062'
	};

	// if (onlyPhaseItem && (onlyPhaseItem.get('isLocked') || onlyPhaseItem.get('isExpired'))) {
	if (isItemLocked) {
		moduleItemMarker = {
			backgroundColor: colors.get('greyColor4')
		}
		itemTitle = {
			...itemTitle,
			color: '#9a9eaf'
		}
	} else {
		if (completionRequirement && completionRequirement.get('completed')) {
			moduleItemMarker = {
				backgroundColor: 'rgb(74, 144, 226)'
			}
			itemTitle = {
				...itemTitle,
				color: '#9a9eaf'
			}
		}

		if (isCurrentItem) {
			moduleItemMarker = {
				backgroundColor: colors.get('secondaryColor'),
				border: 'solid 2px #bfc3d2'
			}
			itemTitle = {
				...itemTitle,
				color: colors.get('secondaryColor')
			}
		}
	}

	return StyleSheet.create({
		moduleItemCardCnt: {
			border: `solid 1px ${hexToRgbA(colors.get('greyColor4'), 0.27)}`,
			boxShadow: `0 4px 16px 0 ${hexToRgbA(colors.get('black'), 0.06)}`,
			backgroundColor: colors.get('white')
		},
		completionStatus: {
			...myTypography.button,
			lineHeight: 'normal'
		},
		itemMetadata: {
			...myTypography.caption,
			lineHeight: '39px',
			//borderBottom: `solid 1px ${hexToRgbA('#9a9fb0', 0.1)}`,
			color: '#9a9fb0',
			fontWeight: 'normal'
		},
		bottomBorder: {
			background: hexToRgbA('#9a9fb0', 0.1)
		},
		itemTitle: itemTitle,
		itemSubTitle: {
			...myTypography.button,
			lineHeight: 1.35,
			color: '#9a9eaf',
			fontWeight: 'normal'
		},
		competencyCapsule: {
			...myTypography.button,
			lineHeight: 1.4,
			color: `${hexToRgbA('#9a9eaf', 0.9)}`,
			fontWeight: 'normal',
			backgroundColor: `${hexToRgbA('#f4f5f7', 0.5)}`
		},
		moduleItemMarker: moduleItemMarker,
		recommendedText: {
			...myTypography.overline,
			fontWeight: '600',
			fontSize: '12px',
			lineHeight: '40px',
			marginLeft: '5px'
		},
		lockedDiv: {
			backgroundImage: `radial-gradient(circle at 50% 45%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.63))`
		},
		expiredTag: {
			backgroundColor: colors.get('scarlet'),
			color: colors.get('white'),
			...myTypography.button
		},
	});
}

export default getSkin;