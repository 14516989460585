import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateItemIdFromRoute, setSelectedItemId } from 'actions/items/actionCreators';
import { setSideBarFlag } from 'actions/assesments/actionObjects';
import setUserState from 'actions/userState/actionCreators';
import PhaseFeedback from 'components/phaseFeedback';

import {
	getActiveItemDetails,
	getNextItemDetails,
	getActiveUserItem,
	getActivePhase,
	getItemsListInActivePhase
} from './containerHelpers/assessmentContainerHelper';
import {
	setFeedbackCompletionStatus,
	setResponseToQuestion,
	setFeedbackCompletedTemp,
	resetFeedbackData
} from 'actions/itemFeedback/actionCreators';
import {
	setPhaseWorkflowStatus,
	submitAnswerForPhase,
	submitFeedbackForPhase,
	fetchPhaseFeedbackData
} from 'actions/phases/apiActions';
import { setAssessmentShowDetails } from 'actions/items/actionCreators';
import { setActivePhaseId } from 'actions/phases/actionCreators';

import enumConstants from 'constants/enums';

import {
	startTracking,
	endTracking
} from 'utils/mixpanelTracking';

class FeedbackContainer extends Component {

	componentDidMount() {
		const { batchId, phaseId } = this.props.match.params;
		startTracking(true, this.props.match.params, 'PhaseFeedbackScreen');
		this.props.updateActivePhase(+phaseId);
		this.props.fetchPhaseFeedbackData(+batchId, +phaseId);
	}

	componentWillReceiveProps(nextProps) {
		if (this.props.match.params.phaseId != nextProps.match.params.phaseId) {
			endTracking();
			startTracking(true, nextProps.match.params, 'PhaseFeedbackScreen');
		}
	}

	componentWillUnmount() {
		endTracking();
		this.props.resetFeedbackData();
	}

	render() {
		return (
			<PhaseFeedback {...this.props} />
		);
	}
}

const mapStateToProps = state => ({
	selectedItem: getActiveItemDetails(state),
	selectedUserItem: getActiveUserItem(state),
	nextItem: getNextItemDetails(state),
	isSideBarOpen: state.get('ui').get('isSideBarOpen'),
	batches: state.get('batches'),
	phases: state.get('phases'),
	items: state.get('items'),
	attachmentsList: state.get('attachments').get('list'),
	tools: state.get('tools'),
	activePhaseDetails: getActivePhase(state),
	itemsListInActivePhase: getItemsListInActivePhase(state),
	userState: state.get('userState'),
	phaseFeedback: state.get('itemFeedback'),
	ui: state.get('ui'),
	itemId: state.get('userState').get('currentItemId'),
	loaders: state.get('loaders'),
	// faqsList: state.get('tools').get('faqsList'),
	apiErrors: state.get('apiErrors'),
	feedbackStatuses: state.get('feedbackStatuses')
});

const submitFeedbackFromContainer = (phaseId, payload) => {
	return (dispatch, getState) => {
		const entityType = enumConstants.get('ENTITY_TYPE_ASSESSMENT_PHASE');
		const scenario = enumConstants.get('SCENARIO_ASSESSMENT_PHASE');
		dispatch(submitFeedbackForPhase(payload, phaseId, entityType, scenario));
	}
}

const submitAnswerForPhaseFromContainer = (payload, questionId, phaseId) => {
	return (dispatch, getState) => {
		;
		const entityType = enumConstants.get('ENTITY_TYPE_ASSESSMENT_PHASE');
		const scenario = enumConstants.get('SCENARIO_ASSESSMENT_PHASE');
		dispatch(submitAnswerForPhase(payload, questionId, phaseId, entityType, scenario));
	}
}

const mapDispatchToProps = dispatch => ({
	updateItemIdFromRoute: (itemIdFromRoute) => {
		dispatch(updateItemIdFromRoute(itemIdFromRoute));
	},
	updateActivePhase: (phaseId) => {
		dispatch(setActivePhaseId(phaseId));
	},
	setSelectedItemId: (id) => {
		dispatch(setSelectedItemId(id))
	},
	setSideBarFlag: (flag) => {
		dispatch(setSideBarFlag(flag))
	},
	setUserState: (payload) => {
		dispatch(setUserState(payload))
	},
	setPhaseWorkflowStatus: (batchId, phaseId, workflowStatus, routeObj) => {
		dispatch(setPhaseWorkflowStatus(batchId, phaseId, workflowStatus, routeObj))
	},
	fetchPhaseFeedbackData: (batchId, phaseId, scenario = 'ASSESSMENT_PHASE', entityType = "PHASE") => {
		dispatch(fetchPhaseFeedbackData(batchId, phaseId, scenario, entityType))
	},
	setFeedbackCompletionStatus: (flag) => {
		dispatch(setFeedbackCompletionStatus(flag))
	},
	setAssessmentShowDetails: (flag) => {
		dispatch(setAssessmentShowDetails(flag))
	},
	setResponseToQuestion: (payload, questionId) => {
		dispatch(setResponseToQuestion(payload, questionId))
	},
	setFeedbackCompletedTemp: (flag) => {
		dispatch(setFeedbackCompletedTemp(flag))
	},
	submitAnswerForPhase: (payload, questionId, phaseId) => {
		dispatch(submitAnswerForPhaseFromContainer(payload, questionId, phaseId))
	},
	submitFeedback: (phaseId, payload) => {
		dispatch(submitFeedbackFromContainer(phaseId, payload))
	},
	resetFeedbackData: () => {
		dispatch(resetFeedbackData())
	}

});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackContainer);
