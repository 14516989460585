import enums from 'constants/enums';
import store from 'store/store';

const checkMetadata = (metadata) => {
    const unitEnum = ['SECONDS', 'NUMBER', 'PERCENTAGE', 'MINUTES', 'HOURS', 'DAYS'];
    if (!('min' in metadata && 'max' in metadata)) {
        console.log('min and max values are not present in metadata');
        return false
    }
    if (!('units' in metadata)) {
        console.log('units in not present in metadata');
        return false;
    }
    if (!unitEnum.includes(metadata.units)) {
        console.log('units is not of correct type');
        return false;
    }
    return true;
}

const getActiveBatchId = () => {
    return store.getState().get('batches').get('activeBatchId');
}

const getPhasesListForActiveBatch = (phasesList) => {
    return phasesList.filter(phase => phase.get('batchId') == getActiveBatchId());
}

const checkIfAssessmentOnlyJourney = (phasesList) => {
    let isAOnly = true;
    const phasesListForActiveBatch = getPhasesListForActiveBatch(phasesList);
    
    phasesListForActiveBatch.map((phase, index) => {
        if (phase.get('type') !== enums.get('ASSESSMENT'))
            isAOnly = false;
    });

    return isAOnly;
}

const checkIfDevelopmentOnlyJourney = (phasesList) => {

    let isDOnly = true;
    const phasesListForActiveBatch = getPhasesListForActiveBatch(phasesList);
    
    phasesListForActiveBatch.map((phase, index) => {
        if (phase.get('type') !== enums.get('DEVELOPMENT'))
            isDOnly = false;
    });
    return isDOnly;

}

const getGradientBounds = (angle) => {
    const pi = angle * (Math.PI / 180);
    return {
        'x1': Math.round(50 + Math.sin(pi) * 50) + '%',
        'y1': Math.round(50 + Math.cos(pi) * 50) + '%',
        'x2': Math.round(50 + Math.sin(pi + Math.PI) * 50) + '%',
        'y2': Math.round(50 + Math.cos(pi + Math.PI) * 50) + '%',
    }
}

const getRoundedRect = (x, y, width, height, radius, side='top') => {

    if (width <= 0) {
        radius /= 2;
        x = x - radius;
        width = radius;
    }

    if (height <= 0) {
        radius /= 2;
        y = y - radius;
        height = radius;
    }

    let d = "";
    let t_w = width;
    let b_w = width;
    let h = height;
    let Y = y;

    if (side == 'top') {
        t_w = width - 2*radius;
        b_w = width;
        h = height - radius;
        Y = y + radius;
    }
    else if (side == 'bottom') {
        t_w = width;
        b_w = width - 2*radius;
        h = height - radius;
    }
    else if (side == 'left') {
        t_w = width - radius;
        b_w = width - radius;
        Y = y + radius;
    }
    else if (side == 'right') {
        t_w = width - radius;
        b_w = width - radius;
        h = height - 2*radius;
    }

    d += "M" + x + "," + Y;
    if (side == 'top' || side == 'left') {
        d += "a" + radius + "," + radius + " 0 0 1 " + radius + "," + -radius;
    }
    d += "h" + t_w;
    if (side == 'top' || side == 'right') {
        d += "a" + radius + "," + radius + " 0 0 1 " + radius + "," + radius;
    }
    d += "v" + h;
    if (side =='bottom' || side == 'right') {
        d += "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + radius;
    }
    d += "h" + -b_w;
    if (side == 'bottom' || side == 'left') {
        d += "a" + radius + "," + radius + " 0 0 1 " + -radius + "," + -radius;
    }
    d += "z";

    return d;
};

const getFlexDirectionForGraphLegend = (legendPosition) => {
    let flexDirection = 'column';
    if (legendPosition == 'left')
        flexDirection = 'row-reverse';
    else if (legendPosition == 'right')
        flexDirection = 'row'
    else if (legendPosition == 'top')
        flexDirection = 'column-reverse'
    return flexDirection;
}

const getJourneyType = (phasesList) => {
    let type = "";
    const phasesListForActiveBatch = getPhasesListForActiveBatch(phasesList);

    phasesListForActiveBatch.map((phase, index) => {
        type += phase.get('type').slice(0,1);
    });
    return type;
}

const getShortDataFormat = (timeFormat) => {
    switch(timeFormat) {
        case 'SECONDS':
            return 's'
        case 'MINUTES':
            return 'mins'
        case 'HOURS':
            return 'hrs'
        case 'DAYS':
            return 'days'
        case 'PERCENTAGE':
            return '%'
        case 'NUMBER':
            return '';
    }
    return null;
}

const getPhaseIdForPhaseType = (phasesList, type) => {
    let phaseId = null;
    const phasesListForActiveBatch = getPhasesListForActiveBatch(phasesList);

    phasesListForActiveBatch.map((phase, index) => {
        if (phase.get('type').trim() === type) {
            phaseId = phase.get('id');
        }
    })
    return phaseId;
}

const hasAssessmentPhaseStarted = (phases) => {
    const phasesList = phases.get('phasesList');
    const phasesListForActiveBatch = getPhasesListForActiveBatch(phasesList);

	const userPhasesList = phases.get('userPhasesList');

	var assessmentStarted = false;

	phasesListForActiveBatch.map((phase, index) => {
		var phaseType = phase.get('type');
		if(phaseType == enums.get('ASSESSMENT')){
			var userPhase = userPhasesList.find((userPhaseEntity) => {
				return userPhaseEntity.get('id') == phase.get('id');
			});
			if(userPhase && (userPhase.get('workflowState') != enums.get('NOT_STARTED'))){
				var ifOneItemHasCompleted = (userPhase.get('completedItems') >= 1);
				if(ifOneItemHasCompleted){
					assessmentStarted = true;
				}
			}
		}
	});
	return assessmentStarted;
}

const hasDevelopmentPhaseStarted = (phases) => {
    const phasesList = phases.get('phasesList');
    const phasesListForActiveBatch = getPhasesListForActiveBatch(phasesList);

	const userPhasesList = phases.get('userPhasesList');

	var developmentStarted = false;

	phasesListForActiveBatch.map((phase, index) => {
		var phaseType = phase.get('type');
		if(phaseType == enums.get('DEVELOPMENT')){
			var userPhase = userPhasesList.find((userPhaseEntity) => {
				return userPhaseEntity.get('id') == phase.get('id');
			});
			if(userPhase && (userPhase.get('workflowState') != enums.get('NOT_STARTED'))){
                // var ifOneItemHasCompleted = (userPhase.get('completedItems') >= 1);
				// if(ifOneItemHasCompleted){
                developmentStarted = true;
				// }
			}
		}
	});
	return developmentStarted;
}

export {
    checkMetadata,
    checkIfAssessmentOnlyJourney,
    checkIfDevelopmentOnlyJourney,
    getGradientBounds,
    getRoundedRect,
    getFlexDirectionForGraphLegend,
    getJourneyType,
    getShortDataFormat,
    getPhaseIdForPhaseType,
	hasAssessmentPhaseStarted,
	hasDevelopmentPhaseStarted
}