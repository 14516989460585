import React , {Component} from 'react';
import styles from './downloadSimReportButton.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class DownloadSimReportButton extends Component {

	render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
        <div className={css(myStyles.buttonStyle)} styleName="button-style">
            <div className={css(myStyles.buttonText)} styleName="button-text">{this.props.text}</div>
        </div>
		);
	}
}

export default DownloadSimReportButton;
