import React, { Component } from 'react';
import styles from './scoreComponent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css, StyleSheet } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ScoreComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radius: 47
        };
    }

    getRadian = (degree) => {
        return ((degree * Math.PI) / 180);
    }

    getX = (angle) => {
        return 50 + (this.state.radius * Math.cos(angle));
    }

    getY = (angle) => {
        return 50 + (this.state.radius * Math.sin(angle));
    }

    divideString = (startValue, endValue, number, type = "normal") => {
        var myString = "";
        const diff = (endValue - startValue) / number;
        for (var i = 0; i < number; i++) {
            if (type === "X") {
                myString += `${this.getX(startValue + (i * diff))};`;
            } else if (type === "Y") {
                myString += `${this.getY(startValue + (i * diff))};`;
            } else {
                myString += `${startValue + (i * diff)};`;
            }
        }
        if (type === "X") {
            myString += `${this.getX(endValue)}`;
        } else if (type === "Y") {
            myString += `${this.getY(endValue)}`;
        } else {
            myString += `${endValue}`;
        }

        return myString;
    }

    render() {
        const propStyles = StyleSheet.create({
            scoreValue: {
                color: this.props.scoreColor
            },
            scoreBy: {
                backgroundColor: this.props.totalColor
            },
            scoreTotal: {
                color: this.props.totalColor
            }
        });

        const circlePerc = 0.7;
        const value = (this.props.score / (this.props.maxPoints || 5)) * 100;
        const radius = this.state.radius;
        const circumference = 2 * 3.14 * radius;
        const fillValue = value * (circumference / 100) * circlePerc;
        const strokeValue = `${fillValue},${circumference}`;
        const endAngle = (360 * circlePerc) * (value / 100);
        const angle = this.getRadian(endAngle);
        // const x = 50 + (radius * Math.cos(angle));
        // const y = 50 + (radius * Math.sin(angle));
        const x = this.getX(angle);
        const y = this.getY(angle);
        var valuesArrayX = this.divideString(0, angle, 10, "X");
        var valuesArrayY = this.divideString(0, angle, 10, "Y");
        var keyTimesArray = this.divideString(0, 1, 10);

        /*Added by Amul to use this for Development phase */
        const myStyles = getSkin(this.props.skinGuide);
        const skinHex = this.props.skinGuide.get('skinHex');
        const circleStrokeColor = this.props.circleStrokeColor || this.props.scoreColor
        const fullCircleStrokeColor = this.props.fullCircleStrokeColor || skinHex.get('greyColor4');


        return (
            <div styleName="score-container">
                <div styleName="score-content" style={{ top: this.props.text ? 0 : '-17px' }}>
                    <div className={css(myStyles.scoreValue, propStyles.scoreValue)} styleName="score-value">{this.props.score != null && this.props.score != undefined ? this.props.score.toFixed(2) : ""}</div>
                    <div className={css(myStyles.scoreBy, propStyles.scoreBy)} styleName="score-by"></div>
                    <div className={css(myStyles.scoreTotal, propStyles.scoreTotal)} styleName="score-total">{this.props.getLabel('on_label')} {this.props.maxPoints}</div>
                    {this.props.text && <div style={{ backgroundColor: this.props.textBackground }} className={css(myStyles.scoreCompetency)} styleName="score-competency">{this.props.text}</div>}
                </div>
                <div styleName="score-progressbar">
                    <svg width="100%" height="100%" viewBox="0 0 100 100" style={{ "WebkitTransform": "rotate(143deg)", "transform": "rotate(143deg)" }}>
                        <g>
                            <circle r={radius} cy="50" cx="50"
                                strokeWidth="4px" stroke={fullCircleStrokeColor} fill="none" strokeLinecap="round" strokeDasharray={circumference * circlePerc}></circle>
                            <circle id="#myScorePath" r={radius} cy="50" cx="50"
                                strokeWidth="4px" stroke={circleStrokeColor} fill="none" strokeLinecap="round" strokeDasharray={strokeValue}
                            >
                                <animate
                                    attributeType="CSS" attributeName="strokeDasharray"
                                    from={`0,${circumference}`} to={`${fillValue},${circumference}`} dur="2s" repeatCount="1"
                                />
                            </circle>
                            <circle r="4" cy={y} cx={x} fill={circleStrokeColor} >
                                <animate
                                    attributeType="CSS" attributeName="cy"
                                    from={50 + (radius * Math.sin(0))} to={50 + (radius * Math.sin(angle))} dur="2s" repeatCount="1"
                                    values={valuesArrayY}
                                    keyTimes={keyTimesArray}
                                />
                                <animate
                                    attributeType="CSS" attributeName="cx"
                                    from={50 + (radius * Math.cos(0))} to={50 + (radius * Math.cos(angle))} dur="2s" repeatCount="1"
                                    values={valuesArrayX}
                                    keyTimes={keyTimesArray}
                                />
                            </circle>
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}

export default ScoreComponent;
