/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: user.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * theme reducer is responsible for platform theme related actions
 *
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

// initialState of the user reducer
const initialState = Immutable.fromJS({
	"fontFamily": {
		"headingsFamily": "Open Sans",
		"bodyFamily": "Mukta"
	},
	"skin": {
		"primaryColor": "rgba(44, 75, 183, 1)", // #02C4BB7
		"primaryVariantColor": "rgba(34, 59, 146, 1)", // #223B92
		"secondaryColor": "rgba(212, 73, 128, 1)", // #D44980
		"secondaryVariantColor": "rgba(196, 65, 117, 1)", // #C44175
		"greyColor1": "rgba(9, 16, 39, 1)", // #091027
		"greyColor2": "rgba(46, 52, 75, 1)", // #2E344B
		"greyColor3": "rgba(87, 94, 121, 1)", // #575E79
		"greyColor4": "rgba(151, 158, 183, 1)", // #979EB7
		"greyColor5": "rgba(213, 217, 231, 1)", // #D5D9E7
		"white": "rgba(255, 255, 255, 1)", // #FFFFFF
		"black": "rgba(0, 0, 0, 1)" // #000000
	},
	"skinHex": {
		"primaryColor": "#2C4BB7",
		"primaryVariantColor": "#223B92",
		"secondaryColor": "#D44980",
		"secondaryVariantColor": "#C44175",
		"greyColor1": "#091027",
		"greyColor2": "#2E344B",
		"greyColor3": "#575E79",
		"greyColor4": "#979EB7",
		"greyColor5": "#D5D9E7",
		"greyColor6": "#f2f3f7",
		"white": "#FFFFFF",
		"black": "#000000",
		"contentColor": "#282828",
		"contentBackground": "#F2F3F7",
		"contentBoxShadow": "#DADBDE" 
	}
});

const theme = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.get('SET_STYLES'):
			// on receive init data
			return Immutable.fromJS({
				primaryColor: 'black',
				secondaryColor: 'white'
			});

		default:
			return state;
	}
};

export default theme;
