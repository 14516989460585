import GetQuizSubmissions from 'actions/serverCalls/quiz/GetQuizSubmissions';
import CreateQuizSubmission from 'actions/serverCalls/quiz/CreateQuizSubmission';
import FetchModuleItem from 'actions/serverCalls/moduleItems/FetchModuleItem';
import GetQuizQuestions from 'actions/serverCalls/quiz/GetQuizQuestions';
import CompleteQuizSubmission from 'actions/serverCalls/quiz/CompleteQuizSubmission';
import AnswerQuizQuestions from 'actions/serverCalls/quiz/AnswerQuizQuestions';
import FlagQuestion from 'actions/serverCalls/quiz/FlagQuestion';
import UnflagQuestion from 'actions/serverCalls/quiz/UnflagQuestion';
import GetQuizAttemptUserResponses from 'actions/serverCalls/quiz/GetQuizAttemptUserResponses';
import MarkQuizAttemptSeen from 'actions/serverCalls/quiz/MarkQuizAttemptSeen';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import { setQuizQuestionsLoading, setQuizAttemptReviewDataLaoding, setQuizAttemptsExhaustedWhileTakingQuiz } from './actionCreators'
import { setQuizQuestionAnswer } from 'actions/quiz/actionCreators';
import { setOrReplaceObjectInList } from 'utils/objectUtils';
import Immutable from 'immutable';
import FetchModuleItemContentDetails from 'actions/serverCalls/moduleItems/FetchModuleItemContentDetails';
import { fetchBatchDetailsForItem } from 'actions/batches/newApiActions';


const getQuizSubmissions = (params) => () => {
	GetQuizSubmissions.call(params)
}

const getQuizInitialData = (params) => (dispatch, getState) => {
	dispatch(setQuizQuestionsLoading(true))
	fetchBatchDetailsForItem(dispatch, params.batchId).then(() => {
		return Promise.all([
			FetchModuleItem.call(params).then((response) => {
				FetchModuleItemContentDetails.call(params).then((nextResponse) => {
					GetQuizSubmissions.call({ ...params, quizId: response.content_id }).then((submissionsResponse) => {
						let activeAttempt = null;
						activeAttempt = submissionsResponse.quiz_submissions.find(sub =>
							sub.workflow_state === "untaken"
						)
						if (activeAttempt) {
							GetQuizQuestions.call({ ...params, submissionId: activeAttempt.id })
						} else {
							if (
								submissionsResponse.quiz_submissions &&
								nextResponse.allowed_attempts === submissionsResponse.quiz_submissions.length) {
								dispatch(setQuizAttemptsExhaustedWhileTakingQuiz(true));
							} else {
								CreateQuizSubmission.call({ ...params, quizId: response.content_id, headers: { 'Content-type': 'application/json' } }).then((response) => {
									let currentSubmission = response.quiz_submissions[0];
									GetQuizQuestions.call({ ...params, submissionId: currentSubmission.id })
								})
							}
						}
					})
				})
			})
		]);
	});
}

const completeQuiz = (payload) => () => {
	payload = {
		...payload,
		headers: {
			'Content-type': 'application/json'
		}
	}
	CompleteQuizSubmission.call(payload).then((completionResponse) => {
		const route = routes.get('BATCH_ITEM_MODULE');
		const params = [
			{ 'BATCH_ID': payload.match.params.batchId },
			{ 'COURSE_ID': payload.courseId },
			{ 'MODULE_ID': payload.match.params.moduleId },
			{ 'ITEM_ID': payload.match.params.moduleItemId },
			{ '_PHASE_ID_': payload.match.params.phaseId },
			{ '_PHASEITEMID_': payload.match.params.phaseItemId }
		]
		updateRoute({ route, params });
	});
}

const setQuizAnswers = (payload) => {

	let quiz_questions = payload.quizQuestions;
	quiz_questions = setOrReplaceObjectInList(Immutable.fromJS(quiz_questions), payload.newQuizAnswers, 'id', 'id')

	let payloadForAnswering = {
		...payload,
		body: {
			...payload.body,
			quiz_questions: quiz_questions
		},
		headers: {
			'Content-type': 'application/json'
		}
	}

	AnswerQuizQuestions.call(payloadForAnswering)
}

const getQuizAttemptReviewData = (params) => (dispatch, getState) => {
	fetchBatchDetailsForItem(dispatch, params.batchId).then(() => {
		dispatch(setQuizAttemptReviewDataLaoding(true))
		return Promise.all([
			FetchModuleItem.call(params).then((response) => {
				FetchModuleItemContentDetails.call(params);
				GetQuizSubmissions.call({ ...params, quizId: response.content_id }).then((submissionsResponse) => {
					let activeAttempt = null;
					activeAttempt = submissionsResponse.quiz_submissions.find(sub =>
						sub.attempt == params.quizSubmissionAttempt
					)
					if (activeAttempt) {
						GetQuizAttemptUserResponses.call({ ...params, submissionId: activeAttempt.id })
					}
				})
			})
		]);
	});
}

const flagQuestion = (payload) => (dispatch, getState) => {
	let payloadForFlagging = {
		...payload,
		body: {
			...payload.body
		},
		headers: {
			'Content-type': 'application/json'
		}
	}

	FlagQuestion.call(payloadForFlagging)
}

const unflagQuestion = (payload) => (dispatch, getState) => {
	let payloadForUnflagging = {
		...payload,
		body: {
			...payload.body
		},
		headers: {
			'Content-type': 'application/json'
		}
	}

	UnflagQuestion.call(payloadForUnflagging)
}


const markQuizAttemptSeen = (params) => () => {
	MarkQuizAttemptSeen.call(params)
}

export {
	getQuizSubmissions,
	getQuizInitialData,
	completeQuiz,
	setQuizAnswers,
	flagQuestion,
	unflagQuestion,
	getQuizAttemptReviewData,
	markQuizAttemptSeen
	// createQuizSubmission
};