import React, { Component } from 'react';
import styles from './activeDayWidget.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DayLogo from 'images/analytics/active_day.svg';
import { checkIfPresent } from 'utils/utilFunctions';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class ActiveDayWidget extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    renderInsightForWidget = (styles, insightPresent) => {
        if (insightPresent) {
            return(
                <div className={css(styles.widgetDescription)} styleName="widget-description">
                    Your average completion rate in assessments is way below your group average. 
                    You need to complete all assessments soon
                </div>
            )
        }
    }
  
    render() {
        const styles = getSkin(this.props.skinGuide);

        const widgetDataArray = this.props.widgetArray;
        
        const insightPresent = checkIfPresent(widgetDataArray.insight);
        
        return (
            <div className={css(styles.widgetContainerStyle)} styleName="widget-container-class">
                <div className={css(styles.widgetTitle)} styleName="widget-title">
                    {widgetDataArray.get('title')}
                </div>
                <div className={css(styles.widgetContentMain)} styleName="widget-content">
                    {widgetDataArray.get('widgetData').get('dayKey')}
                </div>
                {this.renderInsightForWidget(styles, insightPresent)}
                <div styleName="bg-logo">
                    <img src={DayLogo} />
                </div>
            </div>
        );
    }
  }
  
  ActiveDayWidget.defaultProps = {
  };

  export default ActiveDayWidget;