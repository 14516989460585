import React, { Component } from 'react';
import styles from './designExperienceJourney.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DigitalToolHeader from '../digitalToolHeader';
import DigitalToolGuide from '../digitalToolGuide';
import ToolItemList from '../toolItemList';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class DesignExperienceJourney extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuide: false
		}
	}

	toggleShowGuide = () => {
		this.setState({
			showGuide: !this.state.showGuide
		});
	}

	getToolItemListComp = (dataKey) => {
		const { toolData, toolUserData, colorPalette } = this.props;
		const selectedCP = toolUserData && toolUserData.data && toolUserData.data.selectedColorPalette ? toolUserData.data.selectedColorPalette[dataKey] : ""
		return (
			<ToolItemList
				type={dataKey}
				key={`key_${dataKey}`}
				userData={toolUserData && toolUserData.data && toolUserData.data.cards ? toolUserData.data.cards[dataKey] : {}}
				addNewUserDataRecord={this.props.addNewUserDataRecord}
				deleteRecord={this.props.deleteRecord}
				colorPalette={colorPalette}
				selectedColorPalette={selectedCP}
				setColorPalette={this.props.setColorPalette}
				cardStyles={{
					height: '240px',
					maxHeight: '240px',
					justifyContent: 'space-between',
					borderRadius: '6px 0 6px 6px',
					backgroundImage: `${selectedCP && colorPalette[selectedCP].cardBackground ? '' : 'linear-gradient(132deg, #477bc0, #4a53ae)'}`
				}}
				instructions={toolData.cardExamples[dataKey]}
			/>
		)
	}

	submitDataStrategy = () => {
		this.props.markModuleItemDone(this.props.match.params);
	}

	downloadDataStrategy = () => {
		alert("feature not available!");
	}

	getEcosystemEntitiesCards = () => {
		const { toolData, skinGuide } = this.props;
		let myStyles = getSkin(skinGuide);
		let actorBasedCardsComponent = [];
		let cardsColumnHeader = [];
		for (let actor in toolData.actors) {
			cardsColumnHeader.push(
				<div key={`header_${toolData.actors[actor].toLowerCase()}`} styleName="individual-cards-header-cnt">
					<div className={css(myStyles.cardHeaderText)} styleName="header-text">
						{toolData.actors[actor]}
					</div>
					<div styleName="connector"></div>
				</div>
			)
		}
		for (let entity in toolData.ecosystemEntities) {
			let cardsComponent = [];
			for (let actor in toolData.actors) {
				let dataKey = `${toolData.actors[actor].toLowerCase()}_${toolData.ecosystemEntities[entity].key}`
				cardsComponent.push(
					<div key={dataKey} styleName="individual-role-based-card-cnt">
						{this.getToolItemListComp(dataKey)}
					</div>
				)
			};
			actorBasedCardsComponent.push(
				<div key={`${toolData.ecosystemEntities[entity].key}`} styleName="individual-role-based-cards">
					<div styleName="role-text-cnt">
						<div className={css(myStyles.roleText)} styleName="role-text">
							{toolData.ecosystemEntities[entity].name}
						</div>
					</div>
					<div styleName="cards-cnt">
						{cardsComponent}
					</div>
				</div>
			)
		};


		return (
			[
				<div key="cards-header-cnt" styleName="cards-header-cnt">
					{cardsColumnHeader}
				</div>,
				<div key="cards-cnt" styleName="role-based-cards-cnt">
					{actorBasedCardsComponent}
				</div>
			]
		);
	}

	renderDesignExperienceJourneyContent = () => {
		let myStyles = getSkin(this.props.skinGuide);
		const { toolData } = this.props;
		return (
			<div styleName="design-experience-journey-content-cnt">
				<div styleName="design-experience-journey-content">
					<div className={css(myStyles.fillCardText)} styleName="fill-card-header">
						{toolData.labels.stepHeading || "Fill the cards given below"}
					</div>
					<div styleName="cards-outer-cnt">
						{this.getEcosystemEntitiesCards()}
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { toolData } = this.props;
		return (
			<div styleName="design-experience-journey-cnt">
				<DigitalToolHeader
					toggleShowGuide={this.toggleShowGuide}
					submitAction={this.submitDataStrategy}
					downloadAction={this.downloadDataStrategy}
					toolData={toolData}
				/>
				{
					this.renderDesignExperienceJourneyContent()
				}
				{
					this.props.toolData && this.state.showGuide &&
					<DigitalToolGuide
						closeGuide={this.toggleShowGuide}
						guideData={this.props.toolData.guide}
						labels={this.props.toolData.labels}
					/>
				}
			</div>
		);
	}
}

export default DesignExperienceJourney;