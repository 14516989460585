import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setQuizSubmissions, setActivAttempt } from 'actions/quiz/actionCreators'

class CreateQuizSubmission extends NetPackWrapper {
	constructor() {
		super('POST', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('QUIZ_SUBMISSIONS')
			.replace('_COURSE_ID_', apiInfoObject.courseId)
			.replace('_QUIZ_ID_', apiInfoObject.quizId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	};

	successCall = (response, apiInfoObject) => {
		this.dispatch(setQuizSubmissions(response));
		this.dispatch(setActivAttempt(response));
		return response;
	}
}

export default new CreateQuizSubmission();