import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './lockComponent.module.sass';
import LockIcon from 'svgComponents/lockIcon';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';

@CSSModules(styles, { allowMultiple: true })

class LockComponent extends React.Component {

    render(){

        const myStyles = getSkin(this.props.skinGuide);

        return(
            <div className={css(myStyles.lockContainerStyle)} styleName="lock-container">
                <div styleName="lock">
                    <div styleName="lock-image">
                        <LockIcon svgColor="#979eb7"/>
                    </div>
                </div>
                <div className={css(myStyles.textStyle)} styleName="lock-text">
                    {this.props.unlockMessageHint}
                </div>
            </div>
        )
    }
}


export default LockComponent;