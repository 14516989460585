import { StyleSheet } from 'aphrodite';
import {hexToRgbA} from 'utils/styleUtil';
import enumConstants from 'constants/enums';

const getTextColor = (isSelected, isLocked, workflowStatus, isExpired, theme) => {
    const skin = theme.get('skinHex');
    
    if(isLocked) {
        return getStyleOnType(enumConstants.get('LOCKED'), skin);
    } else if(isSelected) {
        return getStyleOnType(enumConstants.get('SELECTED'), skin);        
    } else if(workflowStatus === enumConstants.get('STARTED')) {
        return getStyleOnType(enumConstants.get('STARTED'), skin);
    } else if(workflowStatus === enumConstants.get('COMPLETED')) {
        return getStyleOnType(enumConstants.get('COMPLETED'), skin);
    } else if(isExpired){
        return getStyleOnType(enumConstants.get('LOCKED'), skin);
    } else {
        return getStyleOnType(enumConstants.get('PENDING'), skin);
    }
}

const getStyleOnType = (statusLabel, skin) => {

    const myStyleSheetColor = StyleSheet.create({
        selected: {
            color: skin.get('secondaryColor')
        },
        started: {
            color: skin.get('greyColor1')
        },
        pending: {
            color: skin.get('greyColor1')
        },
        completed: {
            color: '#797e94'
        },
        locked: {
            color: '#797e94'
        }
    });

    switch(statusLabel) {
        case enumConstants.get('SELECTED'):
            return myStyleSheetColor.selected
        case enumConstants.get('STARTED'):
            return myStyleSheetColor.started
        case enumConstants.get('PENDING'):
            return myStyleSheetColor.pending
        case enumConstants.get('COMPLETED'):
            return myStyleSheetColor.completed
        case enumConstants.get('LOCKED'):
            return myStyleSheetColor.locked
        default:
            return myStyleSheetColor.pending
    }
}

export default getTextColor;