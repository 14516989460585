import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './progressComponentAssesment.module.sass';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent/InfoWithoutFilterComponent';
import OverlappingBars from 'commonComponents/graphs/overlappingBars';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@CSSModules(styles, { allowMultiple: true })
class ProgressComponentAssesment extends React.Component {

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props)){
            return false;
        }
        else{
            return true;
        }
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        this.props.fetchAssessmentProgressData(+batchId, +userId, +phaseId);
    }

    renderProgressOverlappingBars = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');

        if(this.props.apiErrors.get('fetchAssessmentProgressDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchAssessmentProgressDataError')}
                retryHandler={()=>{
                    this.props.fetchAssessmentProgressData(+batchId, +userId, +phaseId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('assessment');

        if (allGraphsData.get('isAssesmentProgressFetched')) {
            const graphData = allGraphsData.get('assesmentProgressData');
            const metadata = graphData.get('metadata');
            const strings = graphData.get('strings');

            const dataFormat = '';

            const barStyling = {
                fromUser: '#26fa96',
                toUser: '#52f9aa',
                userRotation: 0,
                fromGroup: '#ff0264',
                toGroup: '#ff71ab',
                groupRotation: 356,
                gridValue: 6,
                componentName: 'progress'
            }

            const graphFormat = {
                xLabel: strings.get('xAxisLabel'),
                yLabel: strings.get('yAxisLabel'),
                valueFormat: dataFormat
            };

            return(
                <div styleName="progress-container">      
                    <InfoWithoutFilterComponent
                        {...this.props}
                        title={strings.get('graphTitle')}
                        subtitle={strings.get('graphDesc')}
                    />
                    <OverlappingBars
                        isUserDataAvailable={graphData.get('isUserDataAvailable')}
                        skinGuide={this.props.skinGuide}
                        barStyling={barStyling}
                        graphData={graphData.toJS()}
                        // data={this.props.data.itemsData}
                        size={this.props.size}
                        graphFormat={graphFormat}
                        hasContinuousAverage={false}
                        strings={strings.toJS()}
                    />
                </div>
            )
        }
        return <LoaderComponent />;
    }

    render() {
        return(
            <div styleName="graph-content">
                {this.renderProgressOverlappingBars()}
            </div>
        )
    }
}


export default ProgressComponentAssesment;