import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import Dialog from 'commonComponents/dialog/Dialog';
import applyLabel from 'utils/LabelProvider';
import getSkin from 'components/moduleItems/moduleItem/skin.js';
import applySkin from 'utils/SkinProvider';
import styles from './moduleItemScreen.module.sass';

import ModuleItem from 'components/moduleItems/moduleItem';
import CollapsibleSidebar from 'commonComponents/collapsibleSidebar/CollapsibleSidebar';
import ModulesSidebarContainer from 'containers/ModulesSidebarContainer';
import ComponentErrorBoundary from 'components/errorBoundaries/componentErrorBoundary';

import { isMobile } from 'react-device-detect';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ModuleItemScreen extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isSidebarOpen: isMobile ? false : true, //TODO: make it resolution specific and move state to reducer
			showDialog: false
		};
	}

	toggleSidebar = (event) => {
		this.setState({
			isSidebarOpen: !this.state.isSidebarOpen
		});
	}

	toggleDialog = (message = null) => {
		const showDialog = !this.state.showDialog;
		const dialogMessage = showDialog ? message : null;

		this.setState({
			showDialog: !this.state.showDialog,
			dialogMessage: dialogMessage
		});
	}

	renderDialog = () => {
		if (!this.state.showDialog) {
			return null;
		}

		const popupMessage = this.state.dialogMessage || this.props.getLabel('item_unavailable_label');
		const popupOkButtonText = this.props.getLabel('okay_label');;
		return (
			<Dialog
				key="item-status-dialog"
				dialogMessage={popupMessage}
				okAction={this.toggleDialog}
				okButtonText={popupOkButtonText}
			/>
		);
	}


	render() {
		let { moduleItem, match, phaseItemData, phase } = this.props;
		const styles = getSkin(this.props.skinGuide);
		
		let moduleItemStyle = ""
		if (this.state.isSidebarOpen) {
			moduleItemStyle = "sidebar-open";
		}

		if (!phaseItemData)
			phaseItemData = null

		return (

			moduleItem.get('moduleItem') ?
				<div styleName={`${moduleItemStyle} module-item-outer-cnt`}>

					<div styleName="menu-container" className={css(styles.sidebarContainer)}>
						<ComponentErrorBoundary>
							<CollapsibleSidebar
								isSidebarOpen={this.state.isSidebarOpen}
							>
								<ModulesSidebarContainer
									isSidebarOpen={this.state.isSidebarOpen}
									onToggleSidebar={this.toggleSidebar}
									routeParams={match.params}
									phaseItemData={phaseItemData}
									phase={phase}
									key={phaseItemData && phaseItemData.get('id')}
								/>
							</CollapsibleSidebar>
						</ComponentErrorBoundary>
					</div>
					<ComponentErrorBoundary>
						<ModuleItem
							key={match.params.moduleItemId}
							{...this.props}
							isSidebarOpen={this.state.isSidebarOpen}
							toggleDialog={this.toggleDialog.bind(this)}
							renderDialog={this.renderDialog.bind(this)}
							phaseItemData={phaseItemData}
						/>
					</ComponentErrorBoundary>
				</div>

				: <div styleName="module-item-loading">
					Content not found
			</div>


		)
	}
}


export default ModuleItemScreen;