import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import Clock from 'images/icons/clock.svg';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		ratingNumber: {
			...myTypography.body1,
			color: `${hexToRgbA('#313131', 0.8)}`,
			lineHeight: 1.73,
			'@media only screen and (max-width: 480px)': {
				fontSize: '14px'
			}
		},
		questionText: {
			...myTypography.body1,
			color: skinHex.get('greyColor2'),
			lineHeight: 1.42,
			'@media only screen and (max-width: 480px)': {
				fontSize: '16px',
				lineHeight: 1.3
			}
		},
		minMaxLabel: {
			...myTypography.body2,
			color: hexToRgbA('#313131', 0.5)
		}
	});
}

export default getSkin;