import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import AuthorPattern from 'images/author-pattern.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        skillsContainer: {
            borderBottom: '1px solid #eaebef'
        },
        skillsBackground1: {
            backgroundColor: skin.get('primaryColor')
        },
        skillsBackground2: {
            backgroundColor: skin.get('white')
        },
        skillsTitle: {
            ...myTypography.h4,
            color: hexToRgbA(skin.get('white'), 0.85)
        },
        skillCardLoader: {
            borderRadius: '4px',
            backgroundColor: skin.get('white'),
            border: `solid 1px ${skin.get('greyColor5')}`
        },
        skillsLoaderBackground1: {
            backgroundColor: skin.get('greyColor6')
        },
        mobileSkillItem: {
            ...myTypography.overline,
			fontWeight: 'normal',
			lineHeight: 'normal',
			color: skin.get('white')
        }
    });
}

export default getSkin;