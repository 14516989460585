import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import FeedbackPattern from 'images/feedback_pattern.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        mainFeedbackBackground: {
            backgroundImage: `linear-gradient(107deg, #5782dc, ${skin.get('primaryColor')})`
        },
        mainFeedbackImage: {
            backgroundImage: `url(${FeedbackPattern})`,
            backgroundSize: 'contain'
        },
        text:{
            ...myTypography.h6,
            color: 'white'
        }
    });
}

export default getSkin;