/**
 * @author: Amul
 * @since: Thu Oct 5 2018 15:22:31 GMT+0530 (IST)
 * @file: moduleItem.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * moduleItemsCache reducer is responsible for storing already loaded module items
 *
 **/

import Immutable from 'immutable';
import actionConstants from 'constants/actions';

import {setOrUpdateObjectInList} from 'utils/objectUtils';

const initialState = Immutable.fromJS([
    // {
    //     moduleItem: Immutable.Map
    //     contentDetails: Immutable.Map
    // }
]);

const cacheModuleItem = (list, moduleItem, contentDetails) => {
    return setOrUpdateObjectInList(list, {
                id: moduleItem.id,
                moduleItem: {
                    ...moduleItem,
                    isCached: true
                },
                contentDetails
            }, 'id', 'id');
};

const updateCachedModuleItemFileProgress = (list, moduleItemId, progress, is_completed) => {
    return setOrUpdateObjectInList(list, {
        id: moduleItemId,
        moduleItem: {
            file_progress: {
                progress,
                is_completed
            }
        }
    }, 'id', 'id');
}

const moduleItemsCache = (state = initialState, action) => {
    switch(action.type){
        case actionConstants.get('PUSH_LOADED_MODULE_ITEM_TO_CACHE'):
            return cacheModuleItem(state, action.moduleItem, action.contentDetails);

        case actionConstants.get('SET_MODULE_ITEM_FILE_PROGRESS_IN_CACHE'): 
            return updateCachedModuleItemFileProgress(state, action.moduleItemId, action.progress, action.is_completed);
        
        default:
            return state;
    }
}

export default moduleItemsCache;
