import { getNextPhaseId } from 'utils/phaseUtils';

const getOrderOfPhaseItem = (phaseItemId, phaseItemsList) => {
	const filteredPhaseItem = phaseItemsList.filter(
		phaseItem => phaseItem.get('id') === phaseItemId
	).get(0);
	if (filteredPhaseItem) {
		return filteredPhaseItem.get('order');
	}
	return -100;
}

const getNextPhaseItem = (phaseId, phaseItemId, phasesList, phaseItemsList) => { 
	const orderOfCurrentPhaseItem = getOrderOfPhaseItem(phaseItemId, phaseItemsList);

	const orderOfNextPhaseItem = orderOfCurrentPhaseItem + 1;
	
	const nextPhaseItemInCurrentPhase = phaseItemsList.filter(
		phaseItem => (phaseItem.get('order') === orderOfNextPhaseItem && phaseItem.get('phaseId') === phaseId)
	).get(0);

	let nextPhaseItem = nextPhaseItemInCurrentPhase;

	if(!nextPhaseItem){//current item was the last one in this phase
		// pick up first item in next phase
		const nextPhaseId = getNextPhaseId(phaseId, phasesList);
		nextPhaseItem = phaseItemsList.find(phaseItem => phaseItem.get('phaseId') === nextPhaseId);
	}

	return nextPhaseItem;
}

export {
	getNextPhaseItem
}