import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css, StyleSheet } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import ErrorSomethingWrong from 'svgComponents/errorSomethingWrong';
import getSkin from './skin';
import styles from './componentErrorBoundary.module.sass';
import { logComponentErrorToSentry } from 'utils/sentry';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ComponentErrorBoundary extends Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
		this.setState({ hasError: true });
		
		logComponentErrorToSentry(error);
	}

    render() {
        const styles = getSkin(this.props.skinGuide);
        const {getLabelWithDefaults} = this.props;
        const propsStyle = this.props.style || {};

        if (this.state.hasError) {
            return <div style={{
                    margin: '20px auto',
                    textAlign: 'center',
                    ...propsStyle
                }}>                    
                    
                    <div styleName="error-image-cnt">
                        <ErrorSomethingWrong/>
                    </div>
                    
                    <div className={css(styles.errorHeading)} styleName="error-heading"> 
                        {getLabelWithDefaults('error_uh_oh_label', 'Uh, oh!')}
                    </div>
                    
                    <div className={css(styles.errorMessage)} styleName="error-message">
                        {getLabelWithDefaults('component_error_message_label', 'Something went wrong')}
                    </div>
                    
                    <a className={css(styles.retryLink)} styleName="retry-link" onClick={window.FreshWidget.show}>
                        {getLabelWithDefaults('get_support_label', 'Get Support')}
                    </a>
            </div>
        }
        return this.props.children;
    }
}

export default ComponentErrorBoundary;