import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		tabsLine: {
			backgroundColor: skin.get('greyColor5')
		},
		eachTabFocus: {
			...myTypography.h6,
			fontSize: '22px',
			fontWeight: 600,
			color: skin.get('greyColor2'),
			cursor: 'pointer'
		},
		eachTab: {
			...myTypography.h6,
			fontSize: '22px',
			color: hexToRgbA(skin.get('greyColor2')),
			// cursor: 'pointer'
		},
		eachTabLineFocus: {
			backgroundColor: skin.get('greyColor2')
		},
		eachTabLine: {
			backgroundColor: skin.get('greyColor5')
		},
		rightTitle: {
			...myTypography.h5,
			fontSize: '22px',
			fontWeight: 600,
			color: skin.get('greyColor2')
		},
		rightDesc: {
			...myTypography.overline,
			fontSize: '18px',
			lineHeight: 1.78,
			color: skin.get('greyColor2')
		},
		competencyBox: {
			borderRadius: '4px',
			backgroundColor: skin.get('white'),
			boxShadow: `0 2px 4px 0 ${hexToRgbA(skin.get('black'), 0.16)}`
		},
		competencyHeader: {
			backgroundColor: skin.get('greyColor5')
		},
		competencyIcon: {
			backgroundImage: `url()`
		},
		competencyName: {
			...myTypography.h6,
			fontSize: '18px',
			fontWeight: 400,
			color: skin.get('greyColor2')
		},
		competencyInfo: {
			backgroundImage: `url()`
		},
	});
}

export default getSkin;