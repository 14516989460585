import React, { Component } from 'react';
import styles from './genericFilesIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class GenericFilesIcon extends Component {

	render() {
		return (
			<div styleName="icon-cnt">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 15 14">
					<g fill="none" fillRule="evenodd" stroke="#828593" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".875" transform="translate(1)">
						<path d="M13 12.688c0 .483-.416.874-.929.874H.93c-.513 0-.929-.391-.929-.874V1.312C0 .83.416.438.929.438h9.313c.243 0 .475.09.649.249l1.829 1.682c.179.164.28.39.28.626v9.693z"/>
						<ellipse cx="7.83" cy="8.75" rx="2.216" ry="2.188"/>
						<path d="M6.798 6.814l-1.83-3.252-2.457 4.75H5.66"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default GenericFilesIcon;
