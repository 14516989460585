/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: loaders.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * loaders reducer is responsible for loaders related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    isFetchingBatchesList: false,
    isFetchingUserItemsList: false,
    isFetchingBatchDashboardBasicData: true,
    isFetchingBatchDashboardData: false,
    isFetchingBatchInvitationData: false,
    isFetchingToolFAQs: false,
    isFetchingItemData: false,
    isFetchingUserItemsListForFeedbackScreen: false,
    isFetchingFeedback: false,
    isFetchingUserFeedback: false
});

const loaders = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('FETCH_BATCHES_LIST_STATUS'):
            return state.set('isFetchingBatchesList', action.status);
        case actionConsts.get('FETCH_USER_ITEMS_LIST_STATUS'):
            return state.set('isFetchingUserItemsList', action.status);
        case actionConsts.get('FETCH_USER_ITEMS_LIST_STATUS_FOR_FEEDBACK_SCREEN'):
            return state.set('isFetchingUserItemsListForFeedbackScreen', action.status); // fetchusers list - separate flag is added to show a different loader
        case actionConsts.get('FETCH_BATCH_DASHBOARD_BASIC_DATA_STATUS'):
            return state.set('isFetchingBatchDashboardBasicData', action.status);
        case actionConsts.get('FETCH_BATCH_DASHBOARD_STATUS'):
            return state.set('isFetchingBatchDashboardData', action.status);
        case actionConsts.get('FETCH_BATCH_INVITATION_STATUS'):
            return state.set('isFetchingBatchInvitationData', action.status);
        case actionConsts.get('FETCH_TOOL_FAQS_STATUS'):
            return state.set('isFetchingToolFAQs', action.status);
        case actionConsts.get('FETCH_ITEM_DATA_STATUS'):
            return state.set('isFetchingItemData', action.status);
        case actionConsts.get('FETCH_FEEDBACK_STATUS'):
            return state.set('isFetchingFeedback', action.status);
        case actionConsts.get('FETCH_USER_FEEDBACK_STATUS'):
            return state.set('isFetchingUserFeedback', action.status);
        default:
            return state;
    }
};

export default loaders;