/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './VideoPlayerLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import CameraIcon from 'images/loaders/camera-studio.png';
import { css } from 'aphrodite';
import getSkin from './skin';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class VideoPlayerLoader extends React.Component {

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div
				className={css(myStyles.container)}
				styleName="video-player-loader-container"
			>
				<img src={CameraIcon} styleName="camera-img" />
				<div className={css(myStyles.text)} styleName="loading-text">{this.props.text || "Loading Video..."}</div>
			</div>
		);
	}
}

export default VideoPlayerLoader;
