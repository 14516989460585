/**
 * @author: Amul
 * @since: Thu Jan 07 2021 11:50:20 GMT+0530 (India Standard Time)
 * @file: userWhitelistingRequirements.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({});

const userWhitelistingRequirements = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_WHITELISTING_REQUIREMENTS'):
            return Immutable.fromJS(action.userWhitelistingRequirements);
        default:
            return state;
    }
};

export default userWhitelistingRequirements;