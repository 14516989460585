/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: actions.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Exports route related constants
 *
 **/

import Immutable from 'immutable';

const routes = Immutable.Map({
    'HOME': '/',
    'BATCH_ANALYTICS': '/batch/BATCH_ID/analytics',
    'BATCH_ACTIVITY': '/batch/BATCH_ID/analytics/activity',
    'BATCH_PERFORMANCE': '/batch/BATCH_ID/analytics/performance',
    'BATCH_ITEM': '/batch/BATCH_ID/item/ITEM_ID',
    'BATCH_ITEM_FEEDBACK': '/batch/BATCH_ID/item/ITEM_ID',
    'ITEM': '/item/ITEM_ID',
    'ITEM_FEEDBACK': 'PATH/item/ITEM_ID/feedback',
    'ITEM_FEEDBACK_FROM_PATH': 'PATH/feedback',
    'ITEM_FROM_PATH': 'PATH/item/ITEM_ID',
    'DEV_PHASE_ITEM_FROM_PATH': 'PATH/item/_PHASEITEMID_/COURSE_ID/PHASE_ID',
    'BATCH': '/batch/BATCH_ID',
    'PHASE': '/phase/PHASE_ID',
    'JOURNEY': '/journey/JOURNEY_ID',
    'PHASE_MEASURES': '/batch/BATCH_ID/phase/PHASE_ID/measures',
    'PHASE_FEEDBACK': '/batch/BATCH_ID/phase/PHASE_ID/feedback',
    'BATCH_MODULE': '/batch/BATCH_ID/item/_PHASEITEMID_/COURSE_ID/_PHASE_ID_/modules/MODULE_ID',
    'BATCH_ITEM_MODULE': '/batch/BATCH_ID/item/_PHASEITEMID_/COURSE_ID/_PHASE_ID_/modules/MODULE_ID/items/ITEM_ID',
    'SCORM_COURSE': '/batch/BATCH_ID/item/_PHASEITEMID_/COURSE_ID/_PHASE_ID_/modules/MODULE_ID/sc_content/MODULE_ITEM_ID',
    'BATCH_QUIZ_TAKE': '/batch/BATCH_ID/item/_PHASEITEMID_/COURSE_ID/_PHASE_ID_/modules/MODULE_ID/items/MODULE_ITEM_ID/take',
    'BATCH_QUIZ_REVIEW': '/batch/BATCH_ID/item/_PHASEITEMID_/COURSE_ID/_PHASE_ID_/modules/MODULE_ID/items/MODULE_ITEM_ID/review/_QUIZ_SUBMISSION_ATTEMPT_',
    'IFRAME_AUTO_CLOSE': 'item/iframe-close',
    'BATCH_HOME': '/batch/BATCH_ID',
    'BATCH_ITEM_FEEDBACK': '/batch/BATCH_ID/item/ITEM_ID/feedback',
    'BATCH_ITEM_COURSE_FEEDBACK': '/batch/BATCH_ID/item/_PHASEITEMID_/COURSE_ID/_PHASE_ID_/course_feedback',
    'SCORM_COURSE_FEEDBACK': '/batch/BATCH_ID/item/_PHASEITEMID_/COURSE_ID/_PHASE_ID_/scorm_course_feedback',
    'JOURNEY_CATALOG': '/journeys/catalogue',
    'MY_JOURNEY': '/home',
    'USER_HOME': '/home',
    'SIGN_UP_URL': '/organization/ORGANIZATION_ID/signup?appstate=ENCODED_JSON',
    'SIGN_IN_URL': '/signin',
    'USER_PROFILE': '/profile',
    'CHANGE_PASSWORD_URL': 'change_password',
    'USER_DOWNLOADS': '/downloads',
    'BATCH_SURVEY_QUIZ_TAKE': '/batch/BATCH_ID/item/_PHASEITEMID_/takeQuiz',

});

export default routes;