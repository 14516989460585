import React, { PureComponent } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import { isMobile } from 'react-device-detect';
import updateRoute from 'utils/webAppRoutes/updateRoute';

import CollapsibleSidebar from 'commonComponents/collapsibleSidebar/CollapsibleSidebar';

import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import styles from './courseFeedback.module.sass';

import routes from 'utils/webAppRoutes/routeConstants';

import ModulesSidebarContainer from 'containers/ModulesSidebarContainer';
import Footer from 'commonComponents/footer';
import Dialog from 'commonComponents/dialog/Dialog';

import ModuleItemLoader from 'components/moduleItems/moduleItem/ModuleItemLoader.jsx';
import TickMark from 'svgComponents/tickMark';

import CourseFeedbackMain from 'components/courseFeedbackMain'
import LessThanTypeArrow from 'svgComponents/lessThanTypeArrow';
import FeedbackItemIcon from 'svgComponents/feedbackItemIcon'

import NextItem from './NextItem';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CourseFeedback extends PureComponent {

	constructor(props) {
		super(props);
		this.state = {
			isSidebarOpen: isMobile ? false : true, //TODO: make it resolution specific and move state to reducer
			showDialog: false
		};
	}

	onToggleSidebar = () => {
		this.setState({
			isSidebarOpen: !this.state.isSidebarOpen
		});
	}

	toggleDialog = (message = null) => {
		const showDialog = !this.state.showDialog;
		const dialogMessage = showDialog ? message : null;

		this.setState({
			showDialog: !this.state.showDialog,
			dialogMessage: dialogMessage
		});
	}

	onSkipFeedback = () => {
		const { match } = this.props;
		const route = routes.get('BATCH');
		const params = [{ 'BATCH_ID': +match.params.batchId }]
		updateRoute({ route, params });
	}

	getPhaseItemName = () => {
		const {match, items} = this.props;
		let phaseItemData = items.get('itemsList').find(item => +item.get('toolId') === +match.params.courseId);
		if (!phaseItemData) {phaseItemData = null};
		const phaseItemName = phaseItemData && phaseItemData.get('name') ? phaseItemData.get('name') : 'Phase Item';
		return phaseItemName;
	}

	getFeedbackHeader = () => {
		const { course, getLabelWithDefaults } = this.props;
		const myStyles = getSkin(this.props.skinGuide);

		const phaseItemName = this.getPhaseItemName();
		
		return (
			<div styleName="feedback-header-cnt">
				<div className={css(myStyles.feedbackHeader)} styleName="feedback-header">
					{
						course ?
							getLabelWithDefaults(
								'course_feedback_header',
								`Feedback for ${phaseItemName}`,
								{ phaseItemName: phaseItemName }
							)
							:
							'COURSE FEEDBACK'
					}
				</div>
				
			</div>
		)
	}

	goToJourneyDashboard = () => {
		const { match } = this.props;
		const batchId = +match.params.batchId;
		const route = routes.get('BATCH_HOME');
		const params = [{ 'BATCH_ID': batchId }];
		const routeObj = { route, params };
		updateRoute(routeObj);
	}

	getCourseCompletionComponent = () => {
		const { course, getLabelWithDefaults, match, phaseItemData } = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="course-completion-action-cnt">
				<div styleName="course-completion-msg-cnt">
					{ phaseItemData.get('isCompleted') && 
						<React.Fragment>
							<div styleName="tick-mark">
								<TickMark fillColor="#4A90E2" />
							</div>
							<div className={css(myStyles.courseCompletionNote)}>
								{
									getLabelWithDefaults(
										'course_completion_note',
										`You've completed ${course.getIn(['course', 'name']) || 'course_name'} Course`,
										{course_name: this.getPhaseItemName() || ''}
									)
								}
							</div>
						</React.Fragment> }
				</div>

				<NextItem
					fetchNextItemRouteParams={this.props.fetchNextItemRouteParams}
					batchId={+match.params.batchId}
					phaseId={+match.params.phaseId}
					currentPhaseItemId={+match.params.phaseItemId}
				/>
				
				<div styleName="course-completion-cnt-action">
					<div
						className={css(myStyles.skipButton)}
						styleName="go-to-dashboard-btn"
						onClick={this.goToJourneyDashboard}
					>
						{getLabelWithDefaults('go_to_dashboard_label', 'GO TO DASHBOARD')}
					</div>
				</div>
			</div>
		)
	}

	getCourseHeader = () => {
		const { getLabelWithDefaults, isScormCourse } = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="scorm-feedback-header">
				{
					isScormCourse &&
					<div styleName="back-to-home-button" onClick={this.goToJourneyDashboard}>
						<div styleName="back-icon-cnt">
							<LessThanTypeArrow />
						</div>
						<div className={css(myStyles.backButtonText)}>
							{getLabelWithDefaults('back_to_home_label', 'Back To Home')}
						</div>
					</div>
				}
				<div styleName="feedback-name-duration-cnt">
					<div styleName="feedback-icon-cnt">
						<FeedbackItemIcon />
					</div>
					<div className={css(myStyles.feedbackMetadata)} styleName="feedback-name-cnt">
						{`${getLabelWithDefaults('feedback_item_type', 'Feedback')}`}
					</div>
				</div>
			</div>
		)
	}


	renderDialog = () => {
		if (!this.state.showDialog) {
			return null;
		}

		const popupMessage = this.state.dialogMessage || this.props.getLabel('item_unavailable_label');
		const popupOkButtonText = this.props.getLabel('okay_label');;
		return (
			<Dialog
				key="item-status-dialog"
				dialogMessage={popupMessage}
				okAction={this.toggleDialog}
				okButtonText={popupOkButtonText}
			/>
		);
	}

	render() {
		let {
			match,
			course,
			loaders,
			isScormCourse,
			phaseItemData,
			phase,
			courseFeedback
		} = this.props;
		let moduleItemStyle = ""
		if (this.state.isSidebarOpen) {
			moduleItemStyle = "sidebar-open";
		}

		const myStyles = getSkin(this.props.skinGuide);

		return (

			<div styleName={`${moduleItemStyle} module-item-outer-cnt`}>

				{
					!isScormCourse &&
					<div styleName="menu-container" className={css(myStyles.sidebarContainer)}>
						<CollapsibleSidebar
							isSidebarOpen={this.state.isSidebarOpen}
						>
							<ModulesSidebarContainer
								isSidebarOpen={this.state.isSidebarOpen}
								onToggleSidebar={this.onToggleSidebar.bind(this)}
								routeParams={match.params}
								course={course}
								isFromFeedback={true}
								phaseItemData={phaseItemData}
								phase={phase}
							/>
						</CollapsibleSidebar>
					</div>

				}

				<div
					styleName="module-item-inner-cnt"
					style={
						isScormCourse ?
							{ margin: 'auto', padding: 'unset' }
							: {}
					}
				>
					{
						loaders.get('isFetchingFeedback') ?
							<div styleName="loader-cnt">
								<ModuleItemLoader />
							</div>
							:
							<div styleName="all-content-row">
								{this.getCourseHeader()}
								{this.getFeedbackHeader()}
								<CourseFeedbackMain
									{...this.props}
									onSkipFeedback={this.onSkipFeedback}									
								/>
								{ courseFeedback.get('isFeedbackCompleted') && this.getCourseCompletionComponent() }
							</div>
					}
				</div>
			
				<div styleName="bottom">
					{this.renderDialog()}
					<Footer />
				</div>

			</div>

		)
	}
}

export default CourseFeedback;