import React, { Component } from 'react';
import styles from './confirmationPopup.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ConfirmationPopup extends Component {

    onClickOfCancel = () => {
        this.props.cancelClickHandler(false);
    }

    onClickOfYes = () => {
        this.props.yesClickHandler();
    }

    render() {
        const { skinGuide } = this.props;
        const myStyles = getSkin(skinGuide);
        return (
            <div styleName="overlay">
                <div styleName="exit-container">
                    <div
                        className={css(myStyles.exitText)}
                        styleName="exit-message-container">
                        {this.props.confirmationText}
                    </div>
                    <div styleName="buttons-holder">
                        <div styleName="button-end">
                            <div styleName="button" className={css(myStyles.yesButton)}
                                onClick={this.onClickOfYes}>
                                {this.props.yesText}
                            </div>
                            <div
                                styleName="button"
                                className={css(myStyles.cancelButton)}
                                onClick={this.onClickOfCancel}>
                                {this.props.cancelText}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default ConfirmationPopup;
