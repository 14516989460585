/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import certificateSectionStyles from './certificateSection.module.sass';
import CertificateButtons from 'components/certificate/certificateButtons/CertificateButtons';
import MandatoryFeedback from 'components/feedback/mandatoryFeedback';
import CongratulationsWithoutCertificate from './CongratulationsWithoutCertificate';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin';
import { getUserName } from 'utils/user';
import CongratulationsImage from 'svgComponents/congratulationsImage';


const CertificateSection = ({ batchId, batchDetails, userActiveBatchDetails, isCertificateEnabled, isLinkedInShareEnabled, isSimReportEnabled, getLabel, isFeedbackMandatory, updateFeedbackStatus, downloadSimReport, batchesFeedbackStatusFlags, skinGuide, match, isSimOnlyCourse }) => {
  
  const isFeedbackComplete = () => {
    return batchesFeedbackStatusFlags.getIn(['statusFlags','completedBatchIds']).includes(batchId);
  }
  
  const getCongratulationsSubtext = () => {
    if(isCertificateEnabled){
      if(isFeedbackMandatory){
        if(batchesFeedbackStatusFlags.get('loading')) return '';
        if(isFeedbackComplete()){
          return getLabel('certificate_congrats_message');
        } else {
          return getLabel('certificate_congrats_message_feedback_incomplete');
        }
      }
      return getLabel('certificate_congrats_message');
    }
    return getLabel('journey_completed_greetings');
  }
  
  const myStyles = getSkin(skinGuide);

  const renderButtonsAccordingToFeedbackSettingAndStatus = () => {
    let defaultComponentToRender = <CertificateButtons
      isCertificateEnabled={isCertificateEnabled}
      isLinkedInShareEnabled={isLinkedInShareEnabled}
      isSimReportEnabled={isSimReportEnabled}
      downloadSimReport={downloadSimReport}
      batchId={batchId}
      getLabel={getLabel}
    />;

    let componentToRender;

    if(!isFeedbackMandatory || isSimOnlyCourse){
      componentToRender = defaultComponentToRender;
    } else if(!batchesFeedbackStatusFlags.get('loading')){
      if(isFeedbackComplete()){
        componentToRender = defaultComponentToRender;
      } else {
        componentToRender = <MandatoryFeedback batchDetails={batchDetails} match={match} updateFeedbackStatus={updateFeedbackStatus}/>;
      }
    }

    return componentToRender;
  }

  if(!isCertificateEnabled){    
    return <div styleName='container'>
      <div styleName={`container-inner ${(isSimOnlyCourse && isSimReportEnabled) ? 'flex-direction-column' : ''}`}>
        <CongratulationsWithoutCertificate/>
        {isSimOnlyCourse && isSimReportEnabled && <div styleName='simonly-no-certificate-buttons-wrapper'>     {renderButtonsAccordingToFeedbackSettingAndStatus()}</div>}
      </div>
    </div>
  }

  return (
    <div styleName='container'>
      <div styleName={isSimOnlyCourse ? 'container-inner-sim-course': 'container-inner'}>
        <div styleName='congrats-message-section'>
          <div className={css(myStyles.congratulationsWithName)}>
            {getLabel('congratulations_with_name_label', { name: getUserName() })}
          </div>
          <div className={css(myStyles.congratsMessage)} styleName='congrats-message'>
            {getCongratulationsSubtext()}
          </div>
          <div styleName='congrats-image-container'>
            <CongratulationsImage/>
          </div>
        </div>
        <div styleName='certificate-image-and-button-section'>
          {isCertificateEnabled ? <div styleName='certificate-image-container'></div> : <div styleName='certificate-no-image-container'/>}
          <div styleName='button-container'>
            {renderButtonsAccordingToFeedbackSettingAndStatus()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default applySkin(applyLabel(CSSModules(CertificateSection, certificateSectionStyles, {allowMultiple: true})));
