import localesMap from 'constants/locales';
import store from 'store/store';

const getLocaleForDAPI = () => {
    try{
        let lang = 'en_US';
        const orgLang = store.getState().getIn(['userOrganization', 'userOrganization', 'lang']) ;
        if(orgLang !== 'en_US'){
            lang = orgLang; /*if org lang other than English (US), override */
        }
        
        return localesMap.get(lang.toLocaleLowerCase());
    } catch(error){
        return 'en';
    }
}

export {
    getLocaleForDAPI
}
   