import actionConstants from 'constants/actions';

export const setMixpanelTracking = (payload, screenName, intervalId, startTimestamp) => ({
    type: actionConstants.get('SET_MIXPANEL_TRACKING'),
    payload,
    screenName,
    intervalId,
    startTimestamp
});

export const unsetMixpanelTracking = () => ({
    type: actionConstants.get('UNSET_MIXPANEL_TRACKING')
});

export const setMixpanelTrackingStatus = (isActive, intervalId) => ({
    type: actionConstants.get('SET_MIXPANEL_TRACKING_ACTIVE_STATUS'),
    intervalId,
    isActive
})



