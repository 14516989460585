import React, { Component } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import CSSModules from 'react-css-modules';
import styles from './configure.module.sass';
import getSkin from './skin';
import applySkin from 'utils/SkinProvider';
import { css } from 'aphrodite/no-important';
import {checkIfBatchExists, setTeamTabLinking} from 'actions/msTeams/apiActions';
import _ from 'lodash';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class Configure extends Component {

    constructor(props){
        super(props);
        this.state = {
            batchHash: '',
            isInputValid: false
        };
        this.checkIfBatchInputValidDebounced = _.debounce(this.checkIfBatchInputValid, 400);
    }
    
    componentDidMount(){
        microsoftTeams.settings.setValidityState(true);
        //Handler for clicking on Save of Configure Dialog
        microsoftTeams.settings.registerOnSaveHandler((saveEvent) => {
            microsoftTeams.settings.setSettings({
                contentUrl: this.createTabUrl(), //URL of the team tab
                entityId: this.createEntityId(),
                removeUrl: this.createRemoveUrl()
            });

            //call notif service api and then notify success
            const successCallBack = () => saveEvent.notifySuccess();
            const failureCallBack = (error) => saveEvent.notifyFailure(error);
            this.setTeamTabLinking(successCallBack, failureCallBack);
        });   
    }

    setTeamTabLinking = (successCallBack, failureCallBack) => {
        microsoftTeams.getContext((context) => {
            console.log('msteams: context:', context);
            const payload = {
                teamId: context.teamId,
                tabId: this.createEntityId(),
                batchId: this.state.batchHash
            }
            setTeamTabLinking(payload, successCallBack, failureCallBack);
        });
        
    }

    checkIfBatchInputValid = (batchHash) => {
        if(batchHash!=''){
            checkIfBatchExists(
                batchHash, 
                (status) => {
                    console.log('callback with status:', status);
                    this.setState({isInputValid: status});
                    microsoftTeams.settings.setValidityState(status)
                } //callback
            );
        }
    }

    createEntityId = () => {
        return `alplus_batch_${this.state.batchHash}_leaderboard`;
    }

    createTabUrl = () => {
        return `${window.location.origin}/msteams_leaderboard#/batch/${this.state.batchHash}/msteams_leaderboard`;
    }

    createRemoveUrl = () => {
        return `${window.location.origin}/msteams_leaderboard_configure#/batch/${this.state.batchHash}/msteams_leaderboard_remove`;
    }

    onTextInput = (event) => {
        microsoftTeams.settings.setValidityState(false)
        const batchHash = event.target.value;
        this.setState({ batchHash });
        this.checkIfBatchInputValidDebounced(batchHash);
    }   

    render() {
        const {isInputValid, batchHash} = this.state;
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div style={{boxSizing: 'border-box', 'padding': '0 48px', marginTop: '100px'}}>
                {/* <h1>Just one last step!</h1><br/> */}
                <label for="batchHash" className={css(myStyles.configureInputInstruction)}>
                    Please enter a batch code to see the corresponding leaderboard
                </label>&nbsp;
                <div styleName="hash-container" className={css(myStyles.configureInputContainer)}>
                    <input type="number"
                        name="batchHash"
                        id="hash"
                        styleName="hash"
                        onChange={this.onTextInput}
                        className={css(myStyles.configureInput)}
                        placeholder="Enter the code"
                        autoFocus
                    />
                    
                    {batchHash!='' ? 
                        <div styleName="batchInputStatus"> 
                            { isInputValid ? 
                                <span className={css(myStyles.batchInputSuccessMessage)}>You're all set</span> : 
                                <span className={css(myStyles.batchInputErrorMessage)}>The batch code doesn't seem to be correct.</span>
                            }                            
                        </div> : null}
                
                </div>
            </div>
        )
    }
}

export default Configure;
