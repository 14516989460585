import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

class CompleteQuizSubmission extends NetPackWrapper {
	constructor() {
		super('POST', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('COMPLETE_QUIZ_SUBMISSION')
			.replace('_COURSE_ID_', apiInfoObject.courseId)
			.replace('_QUIZ_ID_', apiInfoObject.quizId)
			.replace('_QUIZ_SUBMISSION_ID_', apiInfoObject.submissionId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	};

	onError = (error) => {
		window.history.back();
		let errorMsg = 'Sorry, something went wrong. Please refresh the page!';
		if (
			error.errorObject
			&& error.errorObject.response
			&& error.errorObject.response.message
		) {
			errorMsg = error.errorObject.response.message
		}
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: errorMsg,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));

	}
}

export default new CompleteQuizSubmission();