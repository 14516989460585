import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setNextActivityItem } from 'actions/moduleItems/actionCreators';

class FetchNextActivityItem extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
        return urls.get('DEVELOPMENT_PHASE').get('NEXT_ACTIVITY_ITEM')
        .replace('_COURSE_ID_', apiInfoObject.courseId)
        .replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
        .replace('_BATCH_ID_', apiInfoObject.batchId)
        .replace('_PHASE_ID_', apiInfoObject.phaseId)
        .replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
    }

    beforeCall = apiInfoObject => {
        this.dispatch({
            type: 'SET_NEXT_ACTIVITY_ITEM_FETCHING',
            status: true
        })
        return apiInfoObject;
    }
	
    successCall = (response) => {
        this.dispatch(setNextActivityItem(response));
        return response;
    }

}

export default new FetchNextActivityItem();