import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setLinkedInSharingTask, removeLinkedInSharingTaskOnCompletion } from 'actions/reports/actionCreators';
import defaultLabels from 'constants/labels';

class GenerateSharableReportLink extends NetPackWrapper {

	constructor() {
		super('POST', true);
	}

	prepareUrl = () => {
		return urls.getIn(['REPORT', 'GENERATE_SHARABLE_REPORT_LINK']);
	};

	beforeCall = apiInfoObject => {
		this.dispatch(setLinkedInSharingTask(
			apiInfoObject.body.reportType,
			apiInfoObject.body.metaData.batchId,
			{
				taskId: null,
				completionPercentage: 0
			}
		));
		return apiInfoObject;
	}

	successCall = (response, apiInfoObject) => {
		this.dispatch(setLinkedInSharingTask(
			apiInfoObject.body.reportType,
			apiInfoObject.body.metaData.batchId,
			response
		));
		if (response.certificateLink) {
			let certificateLink = response.certificateLink;

			let link = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(`${certificateLink}?t=${new Date().valueOf()}`)}&title=${"Aktivlearn Plus"}&summary=${"Aktivlearn Plus"}&source=`;

			let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=500,height=500,left=0,top=0`;
			let newWindow = window.open(link, 'Aktivlearn Plus', params);
			if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
				alert(defaultLabels.get('new_window_blocked_message'));
			}
		}
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(removeLinkedInSharingTaskOnCompletion(
			errorObj.apiInfoObject.body.reportType,
			errorObj.apiInfoObject.body.metaData.batchId
		));
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, unable to generate your report/certificate. Please try again!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new GenerateSharableReportLink();
