import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {
	setUserFeedbackStatus,
	setPhaseFeedbackStatuses,
	setItemFeedbackStatuses,
} from 'actions/itemFeedback/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';

import { updateFetchFeedbackStatus } from 'actions/loaders/actionCreators';

class FetchUserFeedbacksForItems extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	beforeCall = (apiInfoObject = {}) => {
		this.dispatch(setUserFeedbackStatus(true));
		return apiInfoObject;
	}


	prepareUrl = (apiInfoObject = {}) => {
		let url = urls.getIn(['PLATFORM', 'GET_USER_ITEM_FEEDBACK']);
		return appendQueryParameters(
			url,
			apiInfoObject.queryParameters
		);
	}

	successCall = (response, apiInfoObject = {}) => {
		if (apiInfoObject.queryParameters && apiInfoObject.queryParameters.entityType === 'PHASE')
			this.dispatch(setPhaseFeedbackStatuses({
				id: apiInfoObject.queryParameters.entityId,
				status: response.isFeedbackSubmitted
			}));
		if (apiInfoObject.queryParameters && apiInfoObject.queryParameters.entityType === 'PHASEITEM')
			this.dispatch(setItemFeedbackStatuses({
				id: apiInfoObject.queryParameters.entityId,
				status: response.isFeedbackSubmitted
			}));
		this.dispatch(updateFetchFeedbackStatus(false));
		this.dispatch(setUserFeedbackStatus(false))
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(updateFetchFeedbackStatus(false));
		this.dispatch(setUserFeedbackStatus(false));
	}

}

export default new FetchUserFeedbacksForItems();