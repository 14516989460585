
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './itemProgress.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import enumConstants from 'constants/enums';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ItemProgress extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const { isSideBarOpen } = this.props;
        const containerStyle = (isSideBarOpen) ? 'progress-container-open' : 'progress-container-closed';

        //if (this.props.selectedUserItem.get('workflowStatus') == enumConstants.get('STARTED')) {
        return (
            <div styleName={containerStyle}>
                <div styleName="items-container">
                    <div styleName="progress-items-container" className={css(myStyles.progressItem)}>
                        <div className={css(myStyles.progressText)}>{this.props.getLabel('assessment_loading_after_exit_label')}</div>
                    </div>
                    <div styleName="border" className={css(myStyles.border)}>
                        <div className={css(myStyles.borderMoving)} styleName="border-moving progress"></div>
                    </div>
                </div>
            </div>
        );
        //}
        //return null;
    }
}

export default ItemProgress;
