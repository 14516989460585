import React, { Component } from 'react';
import styles from './activityComponent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import UserDetails from './userDetails/UserDetails';
import SummaryComponent from './summaryComponent';
import withSizes from 'react-sizes';
import TimeSpentComponent from './timeSpentComponent';
import ProgressComponent from './progressComponent/ProgressComponent';
import ProgressTimeSpentComponent from './progressTimeSpentComponent';
import ActivityHeader from 'components/analytics/activityHeader';
import StatsComponent from './statsComponent';
import StatsComponentAorD from './statsComponentAorD';


import { filterObjectFromArray } from 'utils/objectUtils';
import Footer from 'commonComponents/footer';
import { checkIfAssessmentOnlyJourney } from 'utils/analytics/utilFunctions';
import { checkIfDevelopmentOnlyJourney } from '../../../util/analytics/utilFunctions';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class ActivityComponent extends Component {

    constructor(props) {
        super(props);
        const phasesList = props.phases.get('phasesList');
        const batchPhasesList = phasesList.filter(phase => phase.get('batchId') == this.props.match.params.batchId);

        const userPhasesList = props.phases.get('userPhasesList');
        const selectedPhase = filterObjectFromArray(batchPhasesList, 'order', 1);
        const selectedUserPhase = filterObjectFromArray(userPhasesList, 'id', selectedPhase.get('id'));
        this.state = {
            selectedPhase: selectedPhase,
            selectedUserPhase: selectedUserPhase
        };
    }

    onTabClicked = (event, phase) => {
        const userPhasesList = this.props.phases.get('userPhasesList');
        const selectedUserPhase = filterObjectFromArray(userPhasesList, 'id', phase.get('id'));
        this.setState({
            selectedPhase: phase,
            selectedUserPhase: selectedUserPhase
        });
    }

    renderPhaseTabs = () => {
        const { phases } = this.props;
        const phasesList = phases.get('phasesList'); //filtered
        const batchPhasesList = phasesList.filter(phase => phase.get('batchId') == this.props.match.params.batchId);

        const myStyles = getSkin(this.props.skinGuide);

        return batchPhasesList.map((phase, index) => {
            const getSelectedPhaseStyle = this.state.selectedPhase.get('name') === phase.get('name') ? 'tabFontStyleSelected' : 'tabFontStyle';
            return (
                <div key={phase.get('id')} onClick={(e) => this.onTabClicked(e,phase)} className={css(myStyles[getSelectedPhaseStyle])} styleName="tab-button">{phase.get('name')}</div>
            );
        })
    }

    renderStatsComponent = (strings) => {

        // const journeyType = getJourneyType(this.props.phases.get('phasesList'));
        // const journeyType = "AD";
       
        const isAssessmentOnly = checkIfAssessmentOnlyJourney(this.props.phases.get('phasesList'));
        const isDevelopmentOnly = checkIfDevelopmentOnlyJourney(this.props.phases.get('phasesList'));

        const isAorDOnlyJourney =   isAssessmentOnly || isDevelopmentOnly ? true : false;

        if (isAorDOnlyJourney) {
            return <StatsComponentAorD 
                        isAssessmentOnly={isAssessmentOnly} 
                        isDevelopmentOnly={isDevelopmentOnly} 
                        strings={strings}
                        {...this.props} /> 
        } else {
            return <StatsComponent strings={strings} {...this.props} />
        }
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);

        const strings = this.props.allGraphsData.get('genericData').get('analyticsData').get('strings');
        const userName = this.props.user.get('firstName');
        const greetingMessage = strings.get('userAddessingMessageKey').replace('PLACEHOLDER', userName);

        return (
            [<ActivityHeader key="ActivityHeader" title='Activity'/>,
            <div key="ActivityBody" className={css(myStyles.containerStyle)} styleName="container-class">
                <UserDetails 
                    greeting={greetingMessage}
                    infoMessage={strings.get('graphDelayKey')}
                    {...this.props} />
                <SummaryComponent strings={strings} {...this.props}/>
                <TimeSpentComponent strings={strings} {...this.props} />
                {this.renderStatsComponent(strings)}
                <div className={css(myStyles.tabStyle)} styleName="tabs-container">
                    {this.renderPhaseTabs()}
                </div>
                <ProgressComponent
                    strings={strings}
                    phase={this.state.selectedPhase}
                    userPhase={this.state.selectedUserPhase}
                    {...this.props} 
                />
                <ProgressTimeSpentComponent
                    strings={strings}
                    phase={this.state.selectedPhase}
                    userPhase={this.state.selectedUserPhase}
                    {...this.props} 
                />
                <Footer />
            </div>]
        );
    }
}

const mapSizeToProps = function(sizes) {
    const props = {
        size: sizes
    }

    return props;
}

export default withSizes(mapSizeToProps)(ActivityComponent);
