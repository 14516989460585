import React from 'react';
import { Link } from 'react-router-dom';
import routes from 'utils/webAppRoutes/routeConstants';

class PhasesList extends React.Component {

    renderPhases = () => (
        this.props.phasesList.map((phaseListItem) => (
            <div key={phaseListItem.id}>
                <Link to={routes.get('PHASE').replace('PHASE_ID', phaseListItem.id)}>{phaseListItem.name}</Link>
                <br/><br/>
            </div>
        ))
    )

    render() {
        return (
            <div>
                Phases list:
                <br/><br/>
                {this.renderPhases()}
            </div>
        );    
    }
}

export default PhasesList;