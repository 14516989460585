import Immutable from 'immutable';
import environment from 'utils/environment';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';

var apiURL = '';
if (environment.REACT_APP_ENV === 'local') {
	apiURL = getApiUrl().attachmentsUrl;
} else {
	apiURL = `${getDomainUrl()}/attachments/api`;
}

const attachmentUrls = () => {
	return Immutable.Map({
		GET_ATTACHMENT_DETAIL: `${apiURL}/attachment/ATTACHMENT_ID`,
        GET_ATTACHMENTS_LIST: `${apiURL}/attachment/list`
	});

};

export default attachmentUrls;
