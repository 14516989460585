import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './leaderboardFilter.module.sass';
import DropDown from 'commonComponents/analytics/dropDown';

import { css } from 'aphrodite/no-important';
import getSkin from './skin';

@CSSModules(styles, { allowMultiple: true })

class LeaderboardFilter extends React.Component {

    handleOnClick = (event) => {
        this.props.handleOnClick(event.target.value);
    }

    render(){
        const { filterText } = this.props

        return (        
            <div styleName="filter-container">
                <div styleName="filter-info">{filterText.toUpperCase()}</div>
                <div styleName="filter-drop-down">
                    <DropDown {...this.props} />
                </div>
            </div>
        )
    }
}


export default LeaderboardFilter;