import React, { Component } from 'react';
import { DocumentViewer } from 'router/loadableUtils';
import DocumentPreview from 'commonComponents/contentPlayers/documentPreview';
import AdditionalInformation from 'components/moduleItems/additionalInformation';
import CSSModules from 'react-css-modules';
import styles from './document.module.sass';

@CSSModules(styles, { allowMultiple: true })
class Document extends Component {

	getDocumentViewerConfig = (moduleItem) => {
		return {
			boxFileId: moduleItem.getIn(['moduleItem', 'box_file_id']),
			boxAccessToken: moduleItem.getIn(['moduleItem', 'box_access_token', 'access_token'])
		};
	}

	updateDocumentStatus = (progress, total=0, is_completed = false) => {
		const { moduleItem, updateMediaFileStatus, markModuleItemRead, match } = this.props;
		const isModuleItemAlreadyCompleted = moduleItem.getIn(['moduleItem', 'completion_requirement', 'completed']);

		updateMediaFileStatus({
			progress,
			is_completed,
			...match.params
		});

		if (!isModuleItemAlreadyCompleted) {
			if (is_completed) {
				markModuleItemRead(match.params);
			}
		}
	};

	getFileDetails = (moduleItem) => {
		const typeLabel = moduleItem.get('moduleItem').get('type_label');
		
		if(moduleItem.getIn(['contentDetails', 'needs_verification_by_cs_team'])){
			return {
				shouldRenderPDFJSViewer: false
			}
		}

		if(typeLabel === 'PDF'){
			return {
				type: 'PDF',
				shouldRenderPDFJSViewer: true,
				isPresentationByDefault: false,
				size: moduleItem.getIn(['contentDetails', 'size']),
				url: moduleItem.getIn(['contentDetails', 'url'])
			}
		}

		if(moduleItem.getIn(['contentDetails', 'converted_file_details'])){
			return {
				shouldRenderPDFJSViewer: true,
				isPresentationByDefault: typeLabel === 'Presentation',
				type: typeLabel,
				size: moduleItem.getIn(['contentDetails', 'converted_file_details', 'size']),
				url: moduleItem.getIn(['contentDetails', 'converted_file_details', 'url'])
			}
		}
		
		return {
			shouldRenderPDFJSViewer: false
		}
	}



	render() {
		const { moduleItem } = this.props;
		console.log('moduleitem', moduleItem.toJS());
		const { shouldRenderPDFJSViewer, isPresentationByDefault, size, url, type } = this.getFileDetails(moduleItem);

		return (
			<div>
				<div styleName="document-container div">
					{ shouldRenderPDFJSViewer ? 
						<DocumentPreview
							isPresentationByDefault={isPresentationByDefault}
							key={`file_${moduleItem.getIn(['moduleItem', 'id'])}`}
							viewerWidth="100%"
							viewerWidthHeight="100%"
							onUpdateProgress={this.updateDocumentStatus}
							progress={moduleItem.getIn(['moduleItem', 'file_progress', 'progress'])}
							fileSize={size}
							fileUrl={url}
							type={type}
						/>

					: 	<DocumentViewer
							config={this.getDocumentViewerConfig(moduleItem)}
							viewerWidth="100%"
							viewerWidthHeight="100%"
							onUpdateProgress={this.updateDocumentStatus}
							progress={moduleItem.get('moduleItem').get('file_progress').toJS()}
						/>
					}
				</div>
				{/*TODO: remove Box DocumentViewer*/}
				<AdditionalInformation body={moduleItem.getIn(['moduleItem', 'description'])} />
			</div>
		)
	}
}

export default Document;