import React, { Component } from 'react';
import styles from './congratulations.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Congratulations extends Component {

	render() {

		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div styleName="congratulations-message-container">
                <div styleName="congratulations-message-inner">
                    <div className={css(myStyles.backgroundStyle)} styleName="inner-content">
                        <div className={css(myStyles.innerBackground)} styleName="inner-background">
							<div className={css(myStyles.titleStyle)} styleName="title-content">
								{this.props.getLabel('congratulations_label')}
							</div>
							<div className={css(myStyles.subTitleStyle)} styleName="subtitle-content">
								{this.props.getLabel('journey_completed_greetings')}
							</div>
						</div>
                    </div>
                </div>
            </div>
		);
	}
}

export default Congratulations;
