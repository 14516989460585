import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = () => {
    
	return StyleSheet.create({

        leaderboardListStyle: {
            '::-webkit-scrollbar-track': {
                backgroundColor: '#2e344b',
            },
            '::-webkit-scrollbar-thumb': {
                backgroundColor: '#979eb7',
                opcaity: '0.4'
            }
        },
        listItemStyle: {
            backgroundColor: '#353c54',
            fontSize: '20px',
            color: 'rgba(255, 255, 255, 0.85)'
        },

        listUserItemStyle: {
            fontSize: '20px',
            color: 'rgba(255, 255, 255, 0.85)',
            backgroundColor: 'rgba(151, 158, 183, 0.5)'

        },

        listLegendStyle: {
            color: '#979eb7',
            fontSize: '14px'
        },

        listHeadingStyle: {
            backgroundColor: '#353c54',
            fontSize: '14px',
            color: '#979eb7'
        },

        viewMoreStyle: {
            fontSize: '18px',
            fontWeight: 600,
            color: '#d44980'
        },

        positiveRankIncreaseStyle: {
            borderBottom: '6.1px solid #7ed321'
        },

        negativeRankIncreaseStyle: {
            borderTop: '6.1px solid #d0021b'
        }

    });
}

export default getSkin;