import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
		contentContainer: {
			background: '#797e94'
		},
        title: {
			...myTypography.h5,
			color: skin.get('white')
		},
		description: {
			...myTypography.overline,
			fontSize: '18px',
			color: skin.get('white')
		},
		attentionBox: {
			borderRadius: '4px',
			boxShadow: `0 2px 4px 0 ${hexToRgbA(skin.get('black'), 0.1)}, 0 14px 15px 0 ${hexToRgbA(skin.get('black'), 0.04)}`,
			backgroundImage: `linear-gradient(263deg, #3d486f, ${skin.get('greyColor2')})`,
			border: `solid 1px rgba(151, 151, 151, 0.02)`,
			color: skin.get('white'),
			...myTypography.caption
		}
    });
}

export default getSkin;