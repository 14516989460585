import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import { batch, connect } from 'react-redux';

import styles from './userJourneys.module.sass';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import Tabs from 'commonComponents/tabs';
import JourneyCard from 'commonComponents/journeyCard';
import JourneysLoader from 'commonComponents/loaders/journeysLoader';
import JourneyIntroductionPopupContainer from 'containers/JourneyIntroductionPopupContainer';
import JourneyCatalogueContainer from 'containers/JourneyCatalogueContainer';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import Footer from 'commonComponents/footer';
import NoJourneyErrorComponent from 'commonComponents/noJourneyErrorComponent';
import UserJourneyStats from 'components/userJourneyStats';
import WebSessionDialog from 'components/webSessionDialog';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { findTodaysSessionForBatch } from 'utils/webSessions';
import Select from "react-select";
import Option from 'commonComponents/customizedSelectOption';
import { isMobile } from 'react-device-detect';
import { checkIfValueIsTrue } from "utils/utilFunctions";

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class UserJourneys extends Component {

	constructor(props) {
		super(props);
		let showJourneyIntroductionPopup = false;
		let selectedJourneyInstanceId = null;
		let localStorageAppState = window.localStorage.getItem('appState');
		if (localStorageAppState) {
			let appState = JSON.parse(localStorageAppState);
			window.localStorage.removeItem("appState")
			if (appState.journeyInstanceId) {
				showJourneyIntroductionPopup = true;
				selectedJourneyInstanceId = appState.journeyInstanceId;
			}
		}
		document.title = 'Home - Aktivlearn Plus'
		this.state = {
			selectedTab: props.selectedTab || 'my_journeys',
			showJourneyIntroductionPopup: showJourneyIntroductionPopup,
			selectedJourneyInstanceId: selectedJourneyInstanceId,
			selectedBatchId: null,
			refreshTime: null,
			selectedFormats: [],
			searchText: "",
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedTab !== this.props.selectedTab)
			if (nextProps.selectedTab === 'my_journeys') {
				this.props.fetchUserJourneys(nextProps.userOrganization.getIn(['userOrganization', 'id']));
			}
		let anyJourney = false;

		if (
			!nextProps.organizationLevelUsersStats.get('organizationLevelUsersStatsLoading')
			&& this.props.organizationLevelUsersStats.get('organizationLevelUsersStatsLoading')
		) {
			nextProps.organizationLevelUsersStats.get('organizationLevelUsersStats').entrySeq().forEach(([key, value]) => {
				if (value !== 0) {
					anyJourney = true;
				}
			})
			if (!anyJourney && checkIfValueIsTrue(nextProps.userOrganization.getIn(['userOrganization', 'platformSettings', 'exploreCourses']))) {
				this.updateSelectedTab('journey_catalogue');
			}
		}
	}

	updateSelectedTab = (selectedTab) => {
		this.sendSegmentData(selectedTab);

		if (selectedTab === 'my_journeys') {
			updateRoute({ route: routes.get('MY_JOURNEY') });
		} else {
      this.handleSearch("");
			this.addOrRemoveFilterValue("selectedFormats", []);
			updateRoute({ route: routes.get('JOURNEY_CATALOG') });
		}
	}

	sendSegmentTrackEvent = (event, options = {}) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			...options
		};
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		);
	}

	sendSegmentData = (selectedTab) => {
		this.sendSegmentTrackEvent(
			'Home tab clicked',
			{ clickedTab: selectedTab === 'my_journeys' ? 'My Journeys' : 'Explore Journeys' }
		);
		if (selectedTab === 'my_journeys') {
			window.analytics.page(
				'User Journeys',
				{
					...(this.props.match.params),
					url: window.location.href,
					pathHash: window.location.hash
				},
				getAmplitudeSessionDetails()
			)
		}
	}

	setJourneyIntroductionPopup = (selectedJourneyInstanceId, selectedBatchId = null) => {
		this.setState({
			showJourneyIntroductionPopup: true,
			selectedJourneyInstanceId: selectedJourneyInstanceId,
			selectedBatchId: selectedBatchId
		});
	}

	unsetJourneyIntroductionPopup = () => {
		this.setState({
			showJourneyIntroductionPopup: false,
			selectedJourneyInstanceId: null,
			selectedBatchId: null
		});
	}

	renderTabs = () => {
		const { userOrganization, getLabel, organizationLevelUsersStats } = this.props;
		let anyJourney = false;

		
		organizationLevelUsersStats.get('organizationLevelUsersStats').entrySeq().forEach(([key, value]) => {
			if (value !== 0) {
				anyJourney = true;
			}
		})
		

		let userJourneyTabs = [{
			label: getLabel("my_journeys_label"),
			value: "my_journeys"
		}];


		if(userOrganization.getIn(['userOrganization', 'platformSettings', 'exploreCourses'])){
			userJourneyTabs.push({
				label: getLabel("journey_catalogue_label"),
				value: "journey_catalogue"
			});
		}

		return <Tabs
			tabsList={userJourneyTabs}
			tabStyles={{ fontSize: '18px', borderBottom: 'solid 2px #575656', maxWidth: '250px' }}
			tabsStyles={{ width: '100%' }}
			selectedTabValue={this.props.selectedTab}
			tabClickHandler={this.updateSelectedTab}
		/>;
	}

	onEnrollNewJourneyCardClick = () => {
		this.sendSegmentTrackEvent('Enroll Another Journey card clicked');
		updateRoute({ route: routes.get('JOURNEY_CATALOG') });
	}

	addOrRemoveFilterValue = (filter, value) => {
		let updatedFilter = value.map(obj => obj.value)
		this.setState({
			[filter]: updatedFilter
		});
	}

	handleSearch = (value) => {
		this.setState({
			searchText: value
		});
	}

	getFilterComponent = (allValues, myStyles, keyName, valueName, filterName, stateFilterName, prefix) => {
		let filterOptions = [];
		const selfPacedOption = allValues.filter(value => value.get("key") === "SELF_PACED");
		const restOptions = allValues.filter(value => value.get("key") !== "SELF_PACED");
		selfPacedOption.forEach(value => {
			filterOptions.push(
				{
					value: value.get(keyName),
					label: this.props.getLabel(`${prefix}_${value.get(keyName)}_label`)
				}
			)
		});

		filterOptions.push(
			{
				value: restOptions.reduce((acc, value) => acc + value.get(keyName) + ",", ""),
				label: restOptions.reduce((acc, value) => {
					return acc + this.props.getLabel(`${prefix}_${value.get(keyName)}_label`) + "/";
				}, "").slice(0, -1)
			}
		);

		const customStyles = {
			option: (provided, state) => ({
				...provided,
				margin: '2px 0',
				backgroundColor: 'white',
				padding: '5px 10px'
			})
		}

		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<div className={css(myStyles.filterName)}>{filterName}:</div>
				<div style={ isMobile ? { maxWidth: '200px', minWidth: '200px', marginLeft: '10px' } : { maxWidth: '250px', minWidth: '250px', marginLeft: '15px' }}>
					<Select
						closeMenuOnSelect={false}
						isMulti
						components={{ Option }}
						options={filterOptions}
						hideSelectedOptions={false}
						backspaceRemovesValue={false}
						onChange={e => this.addOrRemoveFilterValue(stateFilterName, e)}
						placeholder={this.props.getLabel('filters_all_selected_label')}
						styles={customStyles}
					/>
				</div>
			</div>
		)
	}

	getFilterAndSearchComponent = () => {
		const {
			journeyFormats,
			getLabel,
			skinGuide
		} = this.props;

		const {
			searchText
		} = this.state;

		const myStyles = getSkin(skinGuide);

		let journeyFormatFilterComponent = this.getFilterComponent(journeyFormats, myStyles, "key", "value", getLabel('journey_formats_label'), "selectedFormats", "format");

		return (
			<div styleName="journey-filters-and-search-cnt">
				<div styleName="journey-search-cnt">
					<input
						value={searchText}
						placeholder={getLabel('search_journeys_placeholder_label')}
						onChange={e => this.handleSearch(e.target.value)}
					/>
				</div>
				{journeyFormatFilterComponent}
			</div>
		)
	}

	verifyBatchWithSearchAndFormat = (batchDetails) => {
		const { searchText, selectedFormats } = this.state;
		
		if(batchDetails) {
			if(searchText.length > 0) {
				if(!batchDetails.getIn(["journey", "name"]).toLowerCase().includes(searchText.toLowerCase())) {
					return undefined;
				}
			}

			if(selectedFormats.length > 0) {
				const batchFormat = batchDetails.getIn(["journey", "format"]);
				
				if(!batchFormat) {
					return undefined;
				} else {
					let doesBatchHasSelectedFormat = false;
					for(let i = 0; i < selectedFormats.length; i++) {
						if(selectedFormats[i].includes(batchFormat.get("key"))) {
							doesBatchHasSelectedFormat = true;
						}
					}

					if(!doesBatchHasSelectedFormat) {
						return undefined;
					}
				}
			}
		}

		return batchDetails;
	}

	getMyJourneys = (myStyles) => {
		const {
			ongoingUserBatches,
			upcomingUserBatches,
			completedUserBatches,
			requestedUserBatches,
			expiredUserBatches,
			batches,
			userOrganization,
			getLabel,
			setBatchSwitching,
			activeBatchId
		} = this.props;

		let activeJournyesComponent = [];
		let upcomingJournyesComponent = [];
		let completedJournyesComponent = [];
		let expiredJournyesComponent = [];
		let requestedJournyesComponent = [];

		ongoingUserBatches.forEach(batch => {
			let batchDetails = this.verifyBatchWithSearchAndFormat(batches.find(b => b.get('id') === batch.get('batchId')));
			if (batchDetails) {
				activeJournyesComponent.push(
					<JourneyCard
						key={`active_batch_${batch.get('batchId')}`}
						setJourneyIntroductionPopup={this.setJourneyIntroductionPopup}
						userBatch={batch}
						isActive={true}
						batchDetails={batchDetails}
						setBatchSwitching={setBatchSwitching}
						activeBatchId={activeBatchId}
						webSessionDetails={this.getWebSessionDetailsForJourneyCard(batchDetails)}
						onSessionFinish={this.onSessionFinish}
						refreshTime={this.state.refreshTime}
					/>
				)
			}
		});

		upcomingUserBatches.forEach(batch => {
			let batchDetails = this.verifyBatchWithSearchAndFormat(batches.find(b => b.get('id') === batch.get('batchId')));
			if (batchDetails) {
				upcomingJournyesComponent.push(
					<JourneyCard
						key={`upcoming_batch_${batch.get('batchId')}`}
						setJourneyIntroductionPopup={this.setJourneyIntroductionPopup}
						userBatch={batch}
						batchDetails={batchDetails}
						setBatchSwitching={setBatchSwitching}
					/>
				)
			}
		});

		completedUserBatches.forEach(batch => {
			let batchDetails = this.verifyBatchWithSearchAndFormat(batches.find(b => b.get('id') === batch.get('batchId')));
			if (batchDetails) {
				completedJournyesComponent.push(
					<JourneyCard
						key={`completed_batch_${batch.get('batchId')}`}
						setJourneyIntroductionPopup={this.setJourneyIntroductionPopup}
						userBatch={batch}
						batchDetails={batchDetails}
						setBatchSwitching={setBatchSwitching}
						activeBatchId={activeBatchId}
						isCompleted={true}
					/>
				)
			}
		});

		expiredUserBatches.forEach(batch => {
			let batchDetails = this.verifyBatchWithSearchAndFormat(batches.find(b => b.get('id') === batch.get('batchId')));
			if (batchDetails) {
				expiredJournyesComponent.push(
					<JourneyCard
						key={`expired_batch_${batch.get('batchId')}`}
						setJourneyIntroductionPopup={this.setJourneyIntroductionPopup}
						userBatch={batch}
						batchDetails={batchDetails}
						isExpired={true}
					/>
				)
			}
		});

		requestedUserBatches.forEach(batch => {
			let batchDetails = this.verifyBatchWithSearchAndFormat(batch);
			if (batchDetails) {
				requestedJournyesComponent.push(
					<JourneyCard
						key={`requested_batch_${batch.get('id')}`}
						setJourneyIntroductionPopup={this.setJourneyIntroductionPopup}
						batchDetails={batch}
						isRequested={true}
						userBatch={batch}
					/>
				)
			}
		});

    const isAnyJourneyAvailable = ongoingUserBatches.size > 0 || upcomingUserBatches.size > 0 ||
                                  requestedUserBatches.size > 0 || completedUserBatches.size > 0 ||
                                  expiredUserBatches.size > 0;
    
    const isExploreCoursesEnabled = userOrganization.getIn(['userOrganization', 'platformSettings', 'exploreCourses']);

		return (
			<div styleName="journey-list-cnt">
				<div styleName="inner-cnt">
        { !isAnyJourneyAvailable && !isExploreCoursesEnabled &&
					<div styleName="not-enrolled-msg">{getLabel("not_enrolled_in_any_journey_msg")}</div>
				}
					<div styleName="journeys-list">
						<UserJourneyStats
							userOrganization={userOrganization}
							organizationLevelUsersStats={this.props.organizationLevelUsersStats}
						/>
					</div>
          {
						isAnyJourneyAvailable ? 
							this.getFilterAndSearchComponent()
							: null
					}
          {
            !isAnyJourneyAvailable && <NoJourneyErrorComponent />
          }
					{ 
            activeJournyesComponent.length > 0 &&
              <div styleName="journeys-list">
					  	  <div styleName="list-header">
					  	  	{getLabel('active_journeys_label')}
					  	  </div>
					  	<div styleName="list-content">
					  		{activeJournyesComponent}
					  		{
                  isExploreCoursesEnabled &&
					  			<div
					  				className={css(myStyles.requestCourseLabel)}
					  				styleName="request-new-journey"
					  				onClick={this.onEnrollNewJourneyCardClick}
					  			>
					  				{`+${getLabel('enroll_for_new_journey_label')}`}
					  			</div>
					  		}
					  	</div>
					  </div>
          }
					{
						upcomingJournyesComponent.length > 0 &&
						<div styleName="journeys-list">
							<div styleName="list-header">
								{getLabel('upcoming_journeys_label')}
							</div>
							<div styleName="list-content">
								{upcomingJournyesComponent}
							</div>
						</div>
					}
					{
						requestedJournyesComponent.length > 0 &&
						<div styleName="journeys-list">
							<div styleName="list-header">
								{getLabel('requested_journeys_label')}
							</div>
							<div styleName="list-content">
								{requestedJournyesComponent}
							</div>
						</div>
					}
					{
						completedJournyesComponent.length > 0 &&
						<div styleName="journeys-list">
							<div styleName="list-header">
								{getLabel('completed_journeys_label')}
							</div>
							<div styleName="list-content">
								{completedJournyesComponent}
							</div>
						</div>
					}
					{
						expiredJournyesComponent.length > 0 &&
						<div styleName="journeys-list">
							<div styleName="list-header">
								{getLabel('expired_journeys_label')}
							</div>
							<div styleName="list-content">
								{expiredJournyesComponent}
							</div>
						</div>
					}
				</div>
			</div>
		)
	}

	getTabContent = (myStyles) => {
		const { userOrganization, selectedTab } = this.props;
		switch (selectedTab) {
			case 'my_journeys':
				return this.getMyJourneys(myStyles);
			case 'journey_catalogue':
				if (!userOrganization.getIn(['userOrganization', 'platformSettings', 'exploreCourses']))
					return this.getMyJourneys(myStyles);
				return <JourneyCatalogueContainer
					orgCode={userOrganization.getIn(['userOrganization', 'code'])}
					match={this.props.match}
				/>;
			default:
				return null;
		}
	}

	goBackToHome = () => {
		const route = routes.get('USER_HOME');
		updateRoute({ route });
	}

	findTodaysSessionForOngoingBatch = (batch, searchForActive = false) => {
		const { ongoingUserBatches } = this.props;
		if(ongoingUserBatches && ongoingUserBatches.size < 0) return null;

		const ongoingBatchIds = ongoingUserBatches.map( batch => batch.get('batchId')).toJS();
		
		if(ongoingBatchIds.includes(batch.get('id'))){
			return findTodaysSessionForBatch(batch, searchForActive);
		}
		return false;
	}

	getWebSessionDetailsForJourneyCard = (batch) => {
		const activeSessionDetails = this.findTodaysSessionForOngoingBatch(batch, true);
		if(activeSessionDetails.hasActiveSession){
			return activeSessionDetails;
		}
		const upcomingSessionDetails = this.findTodaysSessionForOngoingBatch(batch, false);
		if(upcomingSessionDetails.hasUpcomingSessionToday){
			return upcomingSessionDetails;
		}
		return {
			hasActiveSession: false,
			hasUpcomingSessionToday: false,
			session: null
		};
	}

	getDetailsForWebSession = () => {
		const { batches } = this.props;
		let activeSessionDetailsForComponent = null;
		
		const batchWithActiveSession = batches.find(batch => {
			const activeSessionDetails = this.findTodaysSessionForOngoingBatch(batch, true);
			if(activeSessionDetails.hasActiveSession){
				activeSessionDetailsForComponent = activeSessionDetails;
				return true;
			}
			return false;
		});

		console.log('debugws: getBatch batchWithActiveSession', batchWithActiveSession && batchWithActiveSession.toJS());


		if(batchWithActiveSession && batchWithActiveSession.size > 0){
			return activeSessionDetailsForComponent;
		}

		let upcomingSessionDetailsForComponent = null;

		const batchWithSessionUpcomingToday = batches.find(batch => { 
			const upcomingSessionDetails = this.findTodaysSessionForOngoingBatch(batch, false);
			if(upcomingSessionDetails.hasUpcomingSessionToday){
				upcomingSessionDetailsForComponent = upcomingSessionDetails;
				return true;
			}
			return false;
		});

		console.log('debugws: getBatch batchWithSessionUpcomingToday', batchWithSessionUpcomingToday && batchWithSessionUpcomingToday.toJS());

		if(batchWithSessionUpcomingToday && batchWithSessionUpcomingToday.size > 0){
			return upcomingSessionDetailsForComponent;
		}
		
		return null;

	}

	onSessionFinish = (refreshTime) => {
		this.setState({ refreshTime });
	}

	render() {
		const {
			user,
			userOrganization,
			skinGuide,
			getLabel
		} = this.props;
		const {
			showJourneyIntroductionPopup,
			selectedJourneyInstanceId,
			selectedBatchId
		} = this.state;
		const myStyles = getSkin(skinGuide);

		if (userOrganization.get('userOrganizationDataLoading')) {
			return (
				<div styleName="user-journeys-cnt" className>
					<JourneysLoader skinGuide={skinGuide} />
				</div>
			)
		}

		if (userOrganization.get('userOrganization').size === 0) {
			return null
		}

		let welcomeMessage = getLabel('welcome_user_note')
		.replace('USER_NAME', user.get('firstName'));

		if(user.get('firstName') && user.get('firstName').includes("@lsk.pe")){
			welcomeMessage = "Welcome!";
		}

		const webSessionDetails = this.getDetailsForWebSession();

		return (
			<div styleName="user-journeys-cnt">
				<div className={css(myStyles.welcomeBg)} styleName="welcome-user-bg">
					<div className={css(myStyles.welcomeBgImg)}></div>
					<div styleName="back-to-home" className={css(myStyles.headerCnt)}>
						<div styleName="user-welcome-note">
							<span className={css(myStyles.backToHome)}>
								{welcomeMessage}
							</span>
						</div>
					</div>
				</div>

				<div styleName="user-journeys-content">
					<div styleName="tabs-cnt">
						{this.renderTabs()}
					</div>
					{this.getTabContent(myStyles)}
				</div>
				{
					showJourneyIntroductionPopup &&
					<JourneyIntroductionPopupContainer
						journeyInstanceId={selectedJourneyInstanceId}
						selectedBatchId={selectedBatchId}
						closePopup={this.unsetJourneyIntroductionPopup}
						isRenderedInsideMyCourses={true}
					/>
				}
				{webSessionDetails && <WebSessionDialog sessionDetails={webSessionDetails} 
						refreshTime={this.state.refreshTime}/>}
				<Footer />
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		journeyFormats: state.getIn(['journeyFormats', 'journeyFormats']),
	}
};

export default connect(mapStateToProps)(UserJourneys);