import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrReplaceObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
	journeyInstanceDetails: {},
	isJourneyInstanceLoading: false
});

const selectedJourneyInstance = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.get('SET_JOURNEY_INSTANCE'):
			return state.set(
				'journeyInstanceDetails',
				Immutable.fromJS(action.journeyInstance)
			).set('isJourneyInstanceLoading', false);
		case actionConsts.get('SET_FETCHING_JOURNEY_INSTANCE_FLAG'):
			return state.set('isJourneyInstanceLoading', action.flag);
		case actionConsts.get('UPDATE_SELECTED_JOURNEY_INSTANCE'):
			return state.setIn(
				['journeyInstanceDetails', 'status'],
				'REQUESTED'
			)
		default:
			return state;
	}
};

export default selectedJourneyInstance;