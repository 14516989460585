import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setUserItemsList } from 'actions/items/actionCreators';
import { updateFetchUserItemsListStatus, updateFetchUserItemsListStatusForFeedbackScreen } from 'actions/loaders/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import {setApiError} from 'actions/apiErrors/actionCreators';


class FetchUserItems extends NetPackWrapper {

    constructor() {
        super("GET", true);
    }

    prepareUrl = (apiInfoObject = {}) => {
        let url =  urls.get('PLATFORM').get('GET_USER_ITEMS');        
        return appendQueryParameters(
            url,
            apiInfoObject.queryParameters
        );
    }

    successCall = (response) => {
        const myResponse = { ...response };

        const activeItemId = myResponse.activeItemId;
        delete myResponse['activeItemId'];

        const userItemsList = myResponse.items;
        delete myResponse['items'];

        const userItemsListAdditionalDetails = myResponse;

        const payload = {
            activeItemId,
            userItemsList,
            userItemsListAdditionalDetails
        };

        this.dispatch(setUserItemsList(payload));
        this.dispatch(setApiError('fetchUserPhaseItemsError', null));
        return response;
    }

    beforeCall = (apiInfoObject = {}) => {
        this.dispatch(updateFetchUserItemsListStatus(true));
        return apiInfoObject;
    }

    afterCall = (response, apiInfoObject) => {
        this.dispatch(updateFetchUserItemsListStatus(false));
        this.dispatch(updateFetchUserItemsListStatusForFeedbackScreen(false));
        return response;
    }

    onError = (errorObj) => {
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchUserPhaseItemsError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchUserItems();
