import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const myTypography = typography(theme);
	const skin = theme.get('skinHex');

	return StyleSheet.create({
		tabContainer: {
			...myTypography.button,
			fontWeight: 600,
			fontSize: '13px',
			color: '#091027'
		},
		activeTabContainer: {
			borderBottom: `solid 4px ${skin.get('secondaryColor')}`,
		},
		arrowContainer: {
			border: `0.2px solid ${skin.get('secondaryColor')}`,
			':hover': {
                backgroundColor: skin.get('secondaryColor')
            }
		}
	});
}

export default getSkin;