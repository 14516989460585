import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setNotificationSubscriptionDetails } from 'actions/userProfile/actionCreators';

class GetNotificationSubscriptioinDetails extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	prepareUrl = (apiInfoObject = {}) => `${urls.getIn(['NOTIFICATION', 'GET_SUBSCRIPTION'])}?emailIds=${apiInfoObject.email}`;

	successCall = (response) => {
		this.dispatch(setNotificationSubscriptionDetails(
			response[0] || { isActive: true }
		));
		return response;
	}

	onError = (error) => {
		return error;
	}
}

export default new GetNotificationSubscriptioinDetails();