import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setUserWhitelistingRequirements } from 'actions/users/actionCreators';
import setUserState from 'actions/userState/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { appendQueryParameters } from 'utils/utilFunctions';

class FetchUserWhitelistingRequirements extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject) => {
      
      let params = {
        organizationId: this.getState().get('userOrganization').get('userOrganization').get('id')
      };
      
      if(apiInfoObject.batchId){
        params.batchIds = apiInfoObject.batchId;
      }

      return appendQueryParameters(
        urls.get('PLATFORM').get('GET_USER_WHITELISTING_REQUIREMENTS'),
        params
      );
    }      

    checkIfWhitelistingCheckNeededForLinkGroups = (systemRequirementsResponse) => {
      try{
        if(window.localStorage.getItem('latestSystemCheckResult')){
          const lastResult = JSON.parse(window.localStorage.getItem('latestSystemCheckResult'));
          const areAllLinkGroupsAlreadyChecked = Object.keys(systemRequirementsResponse.linkGroups).every(linkGroup => lastResult.linkGroupsChecked.includes(linkGroup));
          return !areAllLinkGroupsAlreadyChecked;
        }
      }catch(error){
        console.log('error', error);
      }
      return true;
    }

    successCall = (response, apiInfoObject) => {       
        this.dispatch(setUserWhitelistingRequirements(response));
        if(apiInfoObject.batchId && this.checkIfWhitelistingCheckNeededForLinkGroups(response)){
          this.dispatch(setUserState({batchToRunSystemCheckFor: +apiInfoObject.batchId })); 
        }        
    }

    onError = () => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new FetchUserWhitelistingRequirements();
