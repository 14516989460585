/**
 * @author: sourabh p
 * @since: Thu Aug 06 2018 18:20:31 GMT+0530 (IST)
 * @file: netpack.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * netpack reducer is responsible for netpack related actions
 *
 **/

import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.Map({
    error: Immutable.Map({}),
    isInternetConnectivityPresent: true
});

const netpack = (state = initialState, action = {}) => {
    //  
    switch (action.type) {
        case actionConstants.get('ERROR'):
            return state.set(
                'error',
                Immutable.Map(action.error)
            );
        case actionConstants.get('INTERNET_CONNECTIVITY_STATUS'):
            return state.set('isInternetConnectivityPresent', action.status);
        default:
            return Immutable.Map(state)
    }
}

export default netpack;
