import actionConstants from 'constants/actions';

export const setQuizSubmissions = (quizSubmissions) => ({
    type: actionConstants.get('SET_QUIZ_SUBMISSIONS'),
    quizSubmissions
});

export const unsetQuizSubmissions = () => ({
    type: actionConstants.get('UNSET_QUIZ_SUBMISSIONS')
});

export const setActivAttempt = (quizSubmissions) => ({
    type: actionConstants.get('SET_ACTIVE_QUIZ_ATTEMPT'),
    quizSubmissions
})

export const setQuizQuestions = (questions) => ({
    type: actionConstants.get('SET_QUIZ_QUESTIONS'),
    questions
})

export const setQuizQuestionAnswer = (payload) => ({
    type: actionConstants.get('SET_QUESTION_ANSWER'),
    payload
})

export const prefillPreviousAnsweredQuestions = (payload) => ({
    type: actionConstants.get('PREFILL_QUESTION_ANSWER'),
    payload
})

export const setQuizQuestionsLoading = (status) => ({
    type: actionConstants.get('SET_QUIZ_QUESTIONS_LOADING'),
    status
})

export const setQuizAttemptReviewQuestionsWithUserResponses = (payload) => ({
    type: actionConstants.get('SET_QUIZ_ATTEMPT_REVIEW_DATA'),
    payload
})

export const setQuizAttemptReviewDataLaoding = (flag) => ({
    type: actionConstants.get('SET_QUIZ_ATTEMPT_REVIEW_DATA_LOADING'),
    flag
})

export const updateQuestion = (payload) => ({
    type: actionConstants.get('UPDATE_QUESTION'),
    payload
})

export const updateSubmissionAttempt = (payload) => ({
    type: actionConstants.get('UPDATE_SUBMISSION_ATTEMPT'),
    payload
})

export const setQuizAttemptsExhaustedWhileTakingQuiz = (flag) => ({
    type: actionConstants.get('SET_QUIZ_ATTEMPT_EXPIRED'),
    flag
})