import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		headerText: {
			...myTypography.body2,
			fontWeight: 500
		},
		itemText: {
			...myTypography.button,
			color: 'white',
			fontWeight: 'normal'
		},
		inputText: {
			...myTypography.button,
			fontWeight: 'normal',
			color: hexToRgbA('#000000', 0.6)
		},
		individualNote: {
			...myTypography.labelSmall
		},
		currenInputNote: {
			...myTypography.button,
			fontWeight: 'normal',
			color: hexToRgbA('#000000', 0.4)
		}
	});
}

export default getSkin;