import React, { Component } from 'react';
import styles from './statBulb.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class StatBulb extends Component {

    render() {

        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="bulb-component">
                <svg width="18" height="19" viewBox="0 0 18 19">
                    <g fill="none" fillRule="evenodd" className={css(myStyles.strokeColor)} strokeLinecap="round" strokeLinejoin="round">
                        <path d="M9 17v1.09M11.182 13.57v1.975C11.182 16.583 10.309 17 9 17c-1.31 0-2.182-.417-2.182-1.455v-1.974M9 13.507v-3.052L7.545 9" />
                        <path d="M10.818 13.727a4.608 4.608 0 0 0 2.91-4.363A4.814 4.814 0 0 0 9 4.636a4.814 4.814 0 0 0-4.727 4.728 4.608 4.608 0 0 0 2.909 4.363h3.636zM6.818 15.182h4.364M9 1.364v1.454M1 8.636h1.665M2.818 3.182l1.455 1.454M17 8.636h-1.665M15.182 3.182l-1.455 1.454M9 10.455L10.455 9" />
                    </g>
                </svg>
            </div>
        );
    }
}



export default StatBulb;
