import React, { Component } from 'react';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import CSSModules from 'react-css-modules';
import styles from './sidebarLoader.module.sass';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, {allowMultiple: true})

class SidebarLoader extends Component {
	getModuleLoaderComponents = (instances) => {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));

		let loaderContent = [];
		for(let i = 0; i< instances; i++){
			loaderContent.push(
				<div key={`loader_${i}`} style={{position: 'relative'}}>
					<ContentLoader
							height={98}
							width={320}
							speed={loadersDefaultconfig.get('speed')}
							primaryColor={loadersDefaultconfig.get('primaryColor')}
							secondaryColor={loadersDefaultconfig.get('secondaryColor')}
							style={{height: '100%', width: '100%'}}
						>
							<circle cx="16" cy="48" r="8" /> 
							<rect x="45" y="13" rx="5" ry="5" width="48" height="13" /> 
							<rect x="253" y="15" rx="5" ry="5" width="48" height="13" /> 
							<rect x="45" y="39" rx="2" ry="2" width="207" height="16" /> 
							<rect x="45" y="66" rx="2" ry="2" width="117" height="12" /> 
					</ContentLoader>
					<div styleName="partial-border-for-module"></div>
				</div>
			)
		}
		return loaderContent;
	}


	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));

		return (
			<div styleName="siderbar-loader-loader-cnt">
				<div style={{width: '100%'}}>
					<div styleName="course-name-loader">
						<ContentLoader
								height={62}
								width={320}
								speed={loadersDefaultconfig.get('speed')}
								primaryColor={loadersDefaultconfig.get('primaryColor')}
								secondaryColor={loadersDefaultconfig.get('secondaryColor')}
								style={{height: '100%', width: '100%'}}
							>
								<rect x="60" y="25" rx="2" ry="2" width="194" height="20" />
						</ContentLoader>
					</div>
					<div>
						<ContentLoader
								height={62}
								width={320}
								speed={loadersDefaultconfig.get('speed')}
								primaryColor={loadersDefaultconfig.get('primaryColor')}
								secondaryColor={loadersDefaultconfig.get('secondaryColor')}
								style={{height: '100%', width: '100%'}}
							>
								<rect x="20" y="13" rx="2" ry="2" width="97" height="16" /> 
								<rect x="20" y="38" rx="8" ry="8" width="194" height="12" />
						</ContentLoader>
					</div>
				</div>
				<div div styleName="module-loader-cnt">
					<div styleName="module-content-header-cnt"></div>
					{this.getModuleLoaderComponents(4)}
				</div>
			</div>
		)
  }
}

export default SidebarLoader;
