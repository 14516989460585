import React, { Component } from 'react';
import styles from './skillscovered.module.sass';
import CSSModules from 'react-css-modules';
import SkillCard from './skillCard';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import LeftArrowCircle from 'svgComponents/leftArrowCircle';
import RightArrowCircle from 'svgComponents/rightArrowCircle';
import { TweenMax } from 'gsap';
import SkillsCoveredLoader from 'commonComponents/loaders/skillsCoveredLoader';
import { isMobile } from 'react-device-detect';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SkillsCovered extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cardsContainer: React.createRef(),
            scrollWidth: 354,
            cardArray: [],
            enableLeftArrow: true,
			enableRightArrow: true
        };
    }

    componentDidMount(){
        this.checkAndSetArrowVisibility();
    }

    componentDidUpdate(prevProps){
        const wasLoadingBefore = !prevProps.isBatchDetailsAvailable || !prevProps.isBatchLabelsAvailable;
        const isStillLoading = !this.props.isBatchDetailsAvailable || !this.props.isBatchLabelsAvailable;
        if(wasLoadingBefore && !isStillLoading){
            this.checkAndSetArrowVisibility();
        }
    }

    onClickArrow = (arrowType = 'left') => {
        const currentDiv = this.state.cardsContainer.current;        
		let scrollLeft = currentDiv.scrollLeft + this.state.scrollWidth;
		if (arrowType === 'left') {
			scrollLeft = currentDiv.scrollLeft - this.state.scrollWidth
		}

		TweenMax.to(currentDiv, 1, {
			scrollLeft,
			onUpdate: () => this.checkAndSetArrowVisibility()
		});
	}

    checkAndSetArrowVisibility = () => {
        if(isMobile) return;
		const currentDiv = this.state.cardsContainer.current;
		let updatedState = {
			enableLeftArrow: true,
			enableRightArrow: true
        };
        
		if (currentDiv.scrollLeft === 0) {
			updatedState.enableLeftArrow = false;
		}
		if (Math.abs((currentDiv.scrollLeft + currentDiv.clientWidth) - currentDiv.scrollWidth) <=10) {
			updatedState.enableRightArrow = false;
		}

		this.setState(updatedState);
	}

    renderLoader() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="skill-card-container skill-loader-container" className={css(myStyles.skillCardLoader)}>
                <SkillsCoveredLoader />
            </div>
        );
    }

    renderSkillCards(competencies) {
        if (!this.props.isBatchDetailsAvailable || !this.props.isBatchLabelsAvailable) {
            return [
                this.renderLoader(),
                this.renderLoader(),
                this.renderLoader()
            ];
        }

        return competencies.map((eachSkill, index) =>
            <div styleName="skill-card-container">
                <SkillCard
                    id={index}
                    key={`skillCard-${index}`}
                    skillData={eachSkill}
                />
            </div>
        );
    }

    renderMobileSkillsList = (competencies) => {
        if (!this.props.isBatchDetailsAvailable || !this.props.isBatchLabelsAvailable) {
            return this.renderLoader();
        }

        const myStyles = getSkin(this.props.skinGuide);

        return <div styleName='mobile-skills-list-cnt'>{competencies.map((eachSkill, index) => {
            return <div key={`skill_${index}`} className={css(myStyles.mobileSkillItem)} styleName='mobile-skill-item'>{this.props.getLabel(eachSkill.get('name'))}</div>
        })}</div>;
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const skillsBackground1Style = !this.props.isBatchDetailsAvailable
            ? css(myStyles.skillsLoaderBackground1)
            : css(myStyles.skillsBackground1);
        let competencies = this.props.journeyDetails.get('competencies');

        return (
            <div className={css(myStyles.skillsContainer)} styleName="skills-container">
                <div styleName="skills-background">
                    <div
                        className={skillsBackground1Style}
                        styleName="skills-background-1"
                    ></div>
                    <div
                        className={css(myStyles.skillsBackground2)}
                        styleName="skills-background-2"
                    ></div>
                </div>
                <div styleName="skills-content">
                    <div className={css(myStyles.skillsTitle)} styleName="skills-title">
                        {this.props.getLabel('journey_skill_covered_label')}
                    </div>
                    {
                        isMobile ? this.renderMobileSkillsList(competencies) : <div styleName="cards-navigation-container">
                            <div styleName="navigation-box-left">
                                {
                                    competencies.size === 1
                                    ?   null
                                    :   <div styleName={this.state.enableLeftArrow ? "arrow" : "arrow arrow-disabled"} onClick={(e) => {
                                            this.onClickArrow('left');
                                        }}><LeftArrowCircle /></div>
                                }
                                
                            </div>
                            <div styleName="cards-container">
                                {
                                    competencies.size === 1
                                    ?   <div ref={this.state.cardsContainer} styleName="all-cards" style={{justifyContent: 'center'}}>
                                            {this.renderSkillCards(competencies)}
                                        </div>
                                    :   <div ref={this.state.cardsContainer} styleName="all-cards" onScroll={(e) => { e.stopPropagation() }}>
                                        {this.renderSkillCards(competencies)}
                                    </div>
                                }
                            </div>
                            <div styleName="navigation-box-right">
                                {
                                    competencies.size === 1
                                    ?   null
                                    :   <div styleName={this.state.enableRightArrow ? "arrow" : "arrow arrow-disabled"} onClick={(e) => {
                                        this.onClickArrow('right');
                                    }}><RightArrowCircle /></div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }
}

export default SkillsCovered;
