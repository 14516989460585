import {
    isItLaterTodayOrTomorrow,
    isCurrentDateTimeInRange,
    isLater,
    isItTodayOrTomorrow,
    getRelativeDate,
    getFormattedDate,
    doTheseDatesHaveTheSameDay
} from 'utils/dates';

const findTodaysSessionForBatch = (batch, searchForActive = false) => {
    console.log('debugws: getBatch comes here1', batch.toJS());
    const webSessions = batch.get('webSessions');
    if(webSessions && webSessions.size > 0){
        console.log('debugws: getBatch comes here2');
        if(searchForActive){
            const activeSession = webSessions.find(session => isCurrentDateTimeInRange(session.get('startDate'), session.get('endDate'), 120))
            console.log('debugws: getBatch activeSession', activeSession && activeSession.toJS());
            const hasActiveSession = activeSession && activeSession.size > 0;
            console.log('debugws: getBatch comes here3', 'web session active?', hasActiveSession);
            return {
                hasActiveSession,
                batch,
                session: activeSession
            }
        } else {
            const sessionLaterToday = webSessions.find(session => isItLaterTodayOrTomorrow(session.get('startDate'), session.get('endDate')))
            console.log('debugws: getBatch sessionLaterToday', sessionLaterToday && sessionLaterToday.toJS());
            return {
                hasUpcomingSessionToday: (sessionLaterToday && sessionLaterToday.size) ? true : false,
                batch,
                session: sessionLaterToday
            }
        }
    }
    return false;
}

const findSessionsFromNowOnwardsForBatch = (batch) => {
    const webSessions = batch.get('webSessions');
    if(webSessions && webSessions.size > 0){
        return webSessions.filter( session => {
            const isActive = isCurrentDateTimeInRange(session.get('startDate'), session.get('endDate'), 120);
            if(isActive)
                return true;
            return isLater(session.get('startDate'));
        });
    }
    return null;
}


const getFormattedSessionDate = (date, referenceDate = null) => { 
    if(isItTodayOrTomorrow(date)){
        if(referenceDate){
            if(doTheseDatesHaveTheSameDay(date, referenceDate)){
                // console.log('debugws: doTheseDatesHaveTheSameDay', doTheseDatesHaveTheSameDay(date, referenceDate));
                return getRelativeDate(date, true);
            }
        }
        return getRelativeDate(date);
    }
    return getFormattedDate(date, "short");
}

const WEB_SESSION_END_TIMEOUT_BUFFER = 90000; // 1 and a half minutes


export { findTodaysSessionForBatch, findSessionsFromNowOnwardsForBatch, getFormattedSessionDate, WEB_SESSION_END_TIMEOUT_BUFFER };

