import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreRadarGraphsWithInfo.module.sass';
import RadarGraph from 'commonComponents/graphs/radarGraph';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent/InfoWithoutFilterComponent';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import DropDown from 'commonComponents/analytics/dropDown';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class ScoreRadarGraphsWithInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            competencyId: -1
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
            return false;
        }
        else{
            return true;
        }
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        this.props.fetchAssessmentMeasuresData(batchId, userId, phaseId);
    }

    onCompetencyFilterChanged = (competencyId) => {
        this.setState({
            competencyId: +competencyId
        });
    }

    getSubCompetencies = (competencyId) => {
        const allGraphsData = this.props.allGraphsData.get('assessment');
        const competencies = allGraphsData.get('assesmentMeasuresData').get('competencies').get('data').toJS();
        const metadata = allGraphsData.get('assesmentMeasuresData').get('competencies').get('metadata').toJS();
        // const subCompetencies = allGraphsData.get('assesmentMeasuresData').get('subCompetencies').toJS();

        let data = [];
        if (competencyId === -1) {
            competencies.map(competency => {
                if ('subCompetencies' in competency) {
                    data.push(competency.subCompetencies.data);
                }
            })
        }
        else {
            const competency = competencies.filter(competency => competency.id === competencyId)[0];
            data = 'subCompetencies' in competency ? competency.subCompetencies.data : null;
        }

        return {
            data: [].concat.apply([], data),
            metadata: metadata
        };
    }

    renderCompetencyRadarGraph = () => {
        const allGraphsData = this.props.allGraphsData.get('assessment');
        const graphData = allGraphsData.get('assesmentMeasuresData');
        const strings = graphData.get('strings');
        const competencies = graphData.get('competencies').toJS();
        const compRadarStyling = {
            fromUser: '#ffce23',
            toUser: '#fea429',
            userStroke: '#feaa28',
            fromUserLegend: '#ffce23',
            toUserLegend: '#fea429',
            // fromGroup: '#ff0264',
            // toGroup: '#ff71ab',
            // groupStroke: '#ff0264',
            // fromGroupLegend: '#ff0264',
            // toGroupLegend: '#ff71ab',
            ...analyticGroupColors,
            gridValue: 5,
            componentName: 'assess-competency-score'
        };

        const graphFormatCompetency = {
            valueName: strings.get('competencyLabel')
        };
        
        return (
            <div styleName="radar-graph-container" key="competencyRadarGraph">
                <InfoWithoutFilterComponent
                    {...this.props}
                    title={strings.get('competencyGraphTitle')}
                    subtitle={strings.get('competencyGraphDesc')}
                />
                <RadarGraph
                    graphFormat={graphFormatCompetency}
                    {...this.props}
                    // data={this.props.data.radarData}
                    barStyling={compRadarStyling}
                    graphData={competencies}
                    strings={strings.toJS()}
                />
            </div>
        );
    }

    renderSubCompetencyRadarGraph = () => {
        const allGraphsData = this.props.allGraphsData.get('assessment');
        const graphData = allGraphsData.get('assesmentMeasuresData');
        const strings = graphData.get('strings');
        const competencies = graphData.get('competencies').toJS();
        const subCompetencies = this.getSubCompetencies(this.state.competencyId);
        const subCompRadarStyling = {
            fromUser: '#26fa96',
            toUser: '#52f9aa',
            userStroke: '#79e071',
            fromUserLegend: '#26fa96',
            toUserLegend: '#52f9aa',
            // fromGroup: '#00faee',
            // toGroup: '#72fbf5',
            // groupStroke: '#71c9e0',
            // fromGroupLegend: '#00faee',
            // toGroupLegend: '#72fbf5',
            ...analyticGroupColors,
            gridValue: 5,
            componentName: 'assess-sub-competency-score'
        };

        const graphFormatSubCompetency = {
            valueName: strings.get('subCompetencyLabel')
        };

        return (
            <div styleName="radar-graph-container" key="subCompetencyRadarGraph">
                <div styleName="title-header">
                    <InfoWithoutFilterComponent
                        {...this.props}
                        title={strings.get('subCompetencyGraphTitle')}
                        subtitle={strings.get('subCompetencyGraphDesc')}
                    />
                    <DropDown handleOnClick={this.onCompetencyFilterChanged} allLabel={strings.get('allLabel')} showAllOption={true} list={competencies.data} />
                </div>
                <RadarGraph
                    {...this.props}
                    graphFormat={graphFormatSubCompetency}
                    // data={this.props.data.radarData}
                    barStyling={subCompRadarStyling}
                    graphData={subCompetencies}
                    strings={strings.toJS()}
                />
            </div>
        );
    }

    renderCompetencyDetails = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        
        if(this.props.apiErrors.get('fetchAssessmentMeasuresDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchAssessmentMeasuresDataError')}
                retryHandler={()=>{
					this.props.fetchAssessmentMeasuresData(+batchId, +userId, +phaseId);
				}}
            />
        }


        const allGraphsData = this.props.allGraphsData.get('assessment');

        if (allGraphsData.get('isAssesmentMeasuresFetched')) {
            return (
                [this.renderCompetencyRadarGraph(),
                this.renderSubCompetencyRadarGraph()]
            )
        }

        return <LoaderComponent />;
    }
    render() {

        return (
            <div styleName="outer-graph-container">
                <div styleName="graph-container">
                    <div styleName="graph-content">
                        {this.renderCompetencyDetails()}
                    </div>
                </div>
            </div>
        )
    }
}


export default ScoreRadarGraphsWithInfo;