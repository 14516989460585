import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import defaultLabels from 'constants/labels';

class MarkModuleItemDone extends NetPackWrapper {
	constructor() {
		super('PUT', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('MARK_MODULE_ITEM_DONE')
			.replace('_COURSE_ID_', apiInfoObject.courseId)
			.replace('_MODULE_ID_', apiInfoObject.moduleId)
			.replace('_ITEM_ID_', apiInfoObject.moduleItemId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	};

	successCall = (response, apiInfoObject) => {

		if(apiInfoObject.moduleItemType === 'webSession'){
			return;
		}

		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let msgHeader = defaultLabels.get('done_toast_msg');
		let msg = defaultLabels.get('input_submitted_success_toast_msg');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			msgHeader = labels.get('done_toast_msg');
			msg = labels.get('input_submitted_success_toast_msg');
		}

		this.dispatch(addToast({
			id: toastConstants.get('ID_SUCCESS'),
			type: toastConstants.get('TYPE_SUCCESS'),
			heading: msgHeader,
			message: msg,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}

	onError = (error) => {
		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let msgHeader = defaultLabels.get('something_went_wrong_toast_msg');
		let msg = defaultLabels.get('input_submitted_error_toast_msg');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			msgHeader = labels.get('something_went_wrong_toast_msg');
			msg = labels.get('input_submitted_error_toast_msg');
		}

		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: msgHeader,
			message: msg,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new MarkModuleItemDone();
