import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setDevelopmentMeasuresData } from 'actions/analytics/actionCreators';

class FetchDevelopmentMeasuresData extends NetPackWrapper {

    constructor() {
        super("GET");        
	}

    prepareUrl = (apiInfoObject) => {        
        return urls.get('ANALYTICS').get('GET_DEVELOPMENT_MEASURES_DATA')
        .replace('BATCH_ID', apiInfoObject.batchId)
        .replace('USER_ID', '' + apiInfoObject.userId)
        .replace('PHASE_ID', '' + apiInfoObject.phaseId)
    }

    successCall = (response) => {
        this.dispatch(setDevelopmentMeasuresData(response));
        return response;
    }

}

export default new FetchDevelopmentMeasuresData();
