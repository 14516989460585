import React, { Component } from 'react';
import styles from './locationIcon.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LocationIcon extends Component {

	render() {
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 12 17">
					<g fill="none" fillRule="evenodd" stroke="#8C8C90" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" transform="translate(1 1)">
						<circle cx="5" cy="5.455" r="2.182"/>
						<path d="M5 .545a4.91 4.91 0 0 1 4.91 4.91c0 2.364-3.733 7.656-4.689 8.972a.274.274 0 0 1-.442 0C3.823 13.11.09 7.819.09 5.455A4.91 4.91 0 0 1 5 .545z"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default LocationIcon;
