import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import { pushModuleItemToCache } from 'actions/moduleItems/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
import { replaceWithDevPhaseAPIUrl } from 'utils/apiUrl';

class FetchNextModuleItemContentDetails extends NetPackWrapper {
  constructor() {
    super('GET', true);
  }

  prepareUrl = apiInfoObject => {
    return replaceWithDevPhaseAPIUrl(appendQueryParameters(this.getState().get('moduleItem').get('nextModuleItem').get('url'), {
        access_token: this.getAccessTokenForDevPhase(),
        include: 'submission',
        batchId: apiInfoObject.batchId,
        phaseId: apiInfoObject.phaseId,
        phaseItemId: apiInfoObject.phaseItemId
    }));
  };

  shouldAjaxCall = (apiInfoObject) => {
    if(!this.getState().get('moduleItem').get('nextModuleItem')){
      return false;
    }

    /* if there is no url to fetch content details */
    if(!this.getState().get('moduleItem').get('nextModuleItem').get('url')){
      this.pushModuleItemToCacheAfterFetch({});
      return false;
    }

    return true;
  };

  afterCall = (contentDetailsResponse, apiInfoObject) => {
    /*push it to module item cache */
    this.pushModuleItemToCacheAfterFetch(contentDetailsResponse);

    return contentDetailsResponse;
  }

  pushModuleItemToCacheAfterFetch = (contentDetailsResponse) => {
    console.log('debug: pushModuleItemToCacheAfterFetch');
    console.log(JSON.stringify(this.getState().get('moduleItem').toJS()));
    try{
      this.dispatch(
        pushModuleItemToCache(this.getState().get('moduleItem').get('nextModuleItem').toJS(), contentDetailsResponse)
      );
    }catch(error){
      console.log("Error: can't cache item");
    }
  }
}

export default new FetchNextModuleItemContentDetails();
