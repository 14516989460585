/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: actions.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Exports action related constants
 *
 **/

import Immutable from 'immutable';

const actions = Immutable.Map({
    RECEIVE_INIT_DATA: 'RECEIVE_INIT_DATA',
    RECEIVE_REPORT_URL: 'RECEIVE_REPORT_URL',
    ERROR: 'ERROR',
    EMPTY_ERROR: 'EMPTY_ERROR',
    UPDATE_GENERATING_USER_REPORT_FLAG: 'UPDATE_GENERATING_USER_REPORT_FLAG',
    UPDATE_EMAIL_ADDRESS: 'UPDATE_EMAIL_ADDRESS',
    SET_STYLES: 'SET_STYLES',
    INTERNET_CONNECTIVITY_STATUS: 'INTERNET_CONNECTIVITY_STATUS',

    GET_MODULES: 'GET_MODULES',
    SET_MODULES: 'SET_MODULES',
    SET_SELECTED_MODULE: 'SET_SELECTED_MODULE',
    SET_COURSES_LIST: 'SET_COURSES_LIST',
    SET_COURSES_PROGRESS_LIST: 'SET_COURSES_PROGRESS_LIST',

    SET_COURSES_MODULES_LIST: 'SET_COURSES_MODULES_LIST',
    UNSET_COURSES_MODULES_LIST: 'UNSET_COURSES_MODULES_LIST',

    SET_MODULES_ITEMS_LIST: 'SET_MODULES_ITEMS_LIST',
    UNSET_MODULES_ITEMS_LIST: 'UNSET_MODULES_ITEMS_LIST',

    SET_COURSE_SIDEBAR_ACTIVE_MODULE: 'SET_COURSE_SIDEBAR_ACTIVE_MODULE',
    UNSET_COURSE_SIDEBAR_ACTIVE_MODULE: 'UNSET_COURSE_SIDEBAR_ACTIVE_MODULE',

    SET_LOADED_MODULE: 'SET_LOADED_MODULE',
    SET_LOADED_MODULE_AVAILABILITY: 'SET_LOADED_MODULE_AVAILABILITY',
    SET_MODULE_ITEM: 'SET_MODULE_ITEM',
    SET_MODULE_ITEM_CONTENT_DETAILS: 'SET_MODULE_ITEM_DETAILS',
    SET_MODULE_ITEM_AVAILABILITY: 'SET_MODULE_ITEM_AVAILABILITY',
    SET_COURSE: 'SET_COURSE',
    SET_COURSE_AVAILABILITY: 'SET_COURSE_AVAILABILITY',
    SET_PRODUCT_LAUNCH: 'SET_PRODUCT_LAUNCH',
    UNSET_PRODUCT_LAUNCH: 'UNSET_PRODUCT_LAUNCH',
    UNSET_JOURNEY_COURSES: 'UNSET_JOURNEY_COURSES',
    SET_ACTIVE_COURSE_ID_SIDEBAR: 'SET_ACTIVE_COURSE_ID_SIDEBAR',
    SET_NEXT_ACTIVITY_ITEM: 'SET_NEXT_ACTIVITY_ITEM',
    SET_NEXT_ACTIVITY_ITEM_AVAILABILITY: 'SET_NEXT_ACTIVITY_ITEM_AVAILABILITY',
    SET_NEXT_ACTIVITY_ITEM_FETCHING: 'SET_NEXT_ACTIVITY_ITEM_FETCHING',

    PUSH_LOADED_MODULE_ITEM_TO_CACHE: 'PUSH_LOADED_MODULE_ITEM_TO_CACHE',
    SET_MODULE_ITEM_FROM_CACHE: 'SET_MODULE_ITEM_FROM_CACHE',
    SET_PLATFORM_DATA_FOR_DEV_PHASE_AVAILABILITY: 'SET_PLATFORM_DATA_FOR_DEV_PHASE_AVAILABILITY',
    SET_MODULE_ITEM_COMPLETION_STATUS: 'SET_MODULE_ITEM_COMPLETION_STATUS',
    SET_MODULE_ITEM_FILE_PROGRESS_IN_CACHE: 'SET_MODULE_ITEM_FILE_PROGRESS_IN_CACHE',
    SET_NEXT_MODULE_LOCK_STATUS: 'SET_NEXT_MODULE_LOCK_STATUS',
    SET_COURSE_FOR_NEXT_ACTIVITY: 'SET_COURSE_FOR_NEXT_ACTIVITY',
    UNSET_COURSE_FOR_NEXT_ACTIVITY: 'UNSET_COURSE_FOR_NEXT_ACTIVITY',

    // Constants for AL quiz
    SET_QUIZ_QUESTIONS_LOADING: 'SET_QUIZ_QUESTIONS_LOADING',
    SET_QUIZ_SUBMISSIONS: 'SET_QUIZ_SUBMISSIONS',
    UNSET_QUIZ_SUBMISSIONS: 'UNSET_QUIZ_SUBMISSIONS',
    SET_ACTIVE_QUIZ_ATTEMPT: 'SET_ACTIVE_QUIZ_ATTEMPT',
    SET_QUIZ_QUESTIONS: 'SET_QUIZ_QUESTIONS',
    SET_QUESTION_ANSWER: 'SET_QUESTION_ANSWER',
    PREFILL_QUESTION_ANSWER: 'PREFILL_QUESTION_ANSWER',
    SET_ASSIGNMENT_SUBMITTED: 'SET_ASSIGNMENT_SUBMITTED',
    SET_QUIZ_ATTEMPT_REVIEW_DATA: 'SET_QUIZ_ATTEMPT_REVIEW_DATA',
    SET_QUIZ_ATTEMPT_REVIEW_DATA_LOADING: 'SET_QUIZ_ATTEMPT_REVIEW_DATA_LOADING',
    UPDATE_QUESTION: 'UPDATE_QUESTION',
    UPDATE_SUBMISSION_ATTEMPT: 'UPDATE_SUBMISSION_ATTEMPT',
    SET_QUIZ_ATTEMPT_EXPIRED: 'SET_QUIZ_ATTEMPT_EXPIRED',

    UPDATE_JOURNEYS: 'UPDATE_JOURNEYS',
    UPDATE_PHASES: 'UPDATE_PHASES',
    UPDATE_JOURNEY_ITEMS: 'UPDATE_JOURNEY_ITEMS',

    GET_ASSESMENTS_LIST: 'GET_ASSESMENTS_LIST',
    SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',
    GET_SELCETD_ITEM: 'GET_SELECTED_ITEM',
    GET_SELECTED_MODULE: 'GET_SELECTED_MODULE',
    SET_MODULE: 'SET_MODULE',

    SET_USER_DETAILS: 'SET_USER_DETAILS',
    SET_BATCH_LABELS: 'SET_BATCH_LABELS',
    SET_BATCH_SKIN: 'SET_BATCH_SKIN',
    SET_USER_REFERENCES: 'SET_USER_REFERENCES',
    SET_USER_REFERENCES_LOADING: 'SET_USER_REFERENCES_LOADING',
    SET_DEMOGRAPHICS_DATA: 'SET_DEMOGRAPHICS_DATA',
    SET_DEMOGRAPHICS_DATA_LOADING: 'SET_DEMOGRAPHICS_DATA_LOADING',
    SET_PASSWORD_UPDATING: 'SET_PASSWORD_UPDATING',
    SET_PASSWORD_UPDATING_ERROR: 'SET_PASSWORD_UPDATING_ERROR',
    SET_NOTIFICATION_SUBSCRIPTION_DETAILS: 'SET_NOTIFICATION_SUBSCRIPTION_DETAILS',
    SET_PROFILE_UPDATING: 'SET_PROFILE_UPDATING',

    SET_BATCH_SWITCHING: 'SET_BATCH_SWITCHING',

    SET_BATCHES_LIST: 'SET_BATCHES_LIST',
    SET_USER_BATCHES_LIST: 'SET_USER_BATCHES_LIST',
    SET_ACTIVE_BATCH_ID: 'SET_ACTIVE_BATCH_ID',
    UPDATE_WORKFLOW_STATUS: 'UPDATE_WORKFLOW_STATUS',
    UPDATE_BATCH_DETAILED_DATA: 'UPDATE_BATCH_DETAILED_DATA',
    UPDATE_USER_BATCH_DETAILED_DATA: 'UPDATE_USER_BATCH_DETAILED_DATA',

    SET_ONGOING_USER_BATCHES: 'SET_ONGOING_USER_BATCHES',
    SET_UPCOMING_USER_BATCHES: 'SET_UPCOMING_USER_BATCHES',
    SET_COMPLETED_USER_BATCHES: 'SET_COMPLETED_USER_BATCHES',
    SET_EXPIRED_USER_BATCHES: 'SET_EXPIRED_USER_BATCHES',
    SET_REQUESTED_USER_BATCHES: 'SET_REQUESTED_USER_BATCHES',
    SET_FETCHING_FILTERED_BATCHES_FLAG: 'SET_FETCHING_FILTERED_BATCHES_FLAG',
    UPDATE_REQUESTED_JOURNEY: 'UPDATE_REQUESTED_JOURNEY',
    REMOVE_REQUESTED_JOURNEY: 'REMOVE_REQUESTED_JOURNEY',
    UPDATE_SELECTED_JOURNEY_INSTANCE: 'UPDATE_SELECTED_JOURNEY_INSTANCE',

    SET_JOURNEY_CATEGORIES: 'SET_JOURNEY_CATEGORIES',
    SET_JOURNEY_FORMATS: 'SET_JOURNEY_FORMATS',
    SET_JOURNEY_TAGS: 'SET_JOURNEY_TAGS',
    SET_AVAILABLE_JOURNEYS: 'SET_AVAILABLE_JOURNEYS',
    SET_AVAILABLE_JOURNEYS_LOADING: 'SET_AVAILABLE_JOURNEYS_LOADING',

    SET_AVAILABLE_CORP_DECK_JOURNEYS: 'SET_AVAILABLE_CORP_DECK_JOURNEYS',
    SET_AVAILABLE_CORP_DECK_JOURNEYS_LOADING: 'SET_AVAILABLE_CORP_DECK_JOURNEYS_LOADING',

    SET_JOURNEY_INSTANCE: 'SET_JOURNEY_INSTANCE',
    SET_FETCHING_JOURNEY_INSTANCE_FLAG: 'SET_FETCHING_JOURNEY_INSTANCE_FLAG',
    SET_REQUESTING_JOURNEY_ACCESS: 'SET_REQUESTING_JOURNEY_ACCESS',

    SET_PHASES_LIST: 'SET_PHASES_LIST',
    SET_USER_PHASES_LIST: 'SET_USER_PHASES_LIST',
    SET_ACTIVE_PHASE_ID: 'SET_ACTIVE_PHASE_ID',
    UPDATE_PHASE_DETAILED_DATA: 'UPDATE_PHASE_DETAILED_DATA',
    UPDATE_USER_PHASE_DETAILED_DATA: 'UPDATE_USER_PHASE_DETAILED_DATA',

    SET_ITEMS_LIST: 'SET_ITEMS_LIST',
    SET_USER_ITEMS_LIST: 'SET_USER_ITEMS_LIST',
    SET_ACTIVE_ITEM_ID: 'SET_ACTIVE_ITEM_ID',
    UPDATE_ITEM_DETAILED_DATA: 'UPDATE_ITEM_DETAILED_DATA',
    UPDATE_ITEMS_METRICS_LIST: 'UPDATE_ITEMS_METRICS_LIST',

    UPDATE_BATCH_FROM_ROUTE: 'UPDATE_BATCH_FROM_ROUTE',
    SET_ACTIVE_BATCH_AND_PHASE_ID: 'SET_ACTIVE_BATCH_AND_PHASE_ID',

    SET_SIDEBAR: 'SET_SIDEBAR',

    SET_PRODUCT_LAUNCH_DETAILS: 'SET_PRODUCT_LAUNCH_DETAILS',
    OPEN_PRODUCT_LAUNCH_WINDOW: 'OPEN_PRODUCT_LAUNCH_WINDOW',
    RESET_PRODUCT_LAUNCH_DETAILS: 'RESET_PRODUCT_LAUNCH_DETAILS',

    SET_LOGOUT_URL: 'SET_LOGOUT_URL',


    UPDATE_TOOLS_FAQS_LIST: 'UPDATE_TOOLS_FAQS_LIST',
    UPDATE_ITEM_FROM_ROUTE: 'UPDATE_ITEM_FROM_ROUTE',
    SUBMIT_FEEDBACK: 'SUBMIT_FEEDBACK',

    SET_MEASURES_LIST: 'SET_MEASURES_LIST',
    SET_USER_MEASURES_LIST: 'SET_USER_MEASURES_LIST',

    SET_UI_STATE: 'SET_UI_STATE',
    SET_USER_STATE: 'SET_USER_STATE',

    FETCH_BATCHES_LIST_STATUS: 'FETCH_BATCHES_LIST_STATUS',
    FETCH_USER_ITEMS_LIST_STATUS: 'FETCH_USER_ITEMS_LIST_STATUS',
    FETCH_BATCH_DASHBOARD_BASIC_DATA_STATUS: 'FETCH_BATCH_DASHBOARD_BASIC_DATA_STATUS',
    FETCH_BATCH_DASHBOARD_STATUS: 'FETCH_BATCH_DASHBOARD_STATUS',
    FETCH_BATCH_INVITATION_STATUS: 'FETCH_BATCH_INVITATION_STATUS',
    FETCH_TOOL_FAQS_STATUS: 'FETCH_TOOL_FAQS_STATUS',
    FETCH_ITEM_DATA_STATUS: 'FETCH_ITEM_DATA_STATUS',

    SET_COURSELIST: 'SET_COURSELIST',

    SET_FULLSCREEN: 'SET_FULLSCREEN',
    SET_EXIT_POPUP: 'SET_EXIT_POPUP',
    SET_ASSESSMENT_SHOW_DETAILS: 'SET_ASSESSMENT_SHOW_DETAILS',

    ANALYTICS: 'ANALYTICS',
    ACTIVITY: 'ACTIVITY',
    PERFORMANCE: 'PERFORMANCE',
    OVERALL_PROGRESS_DATA: 'OVERALL_PROGRESS_DATA',
    DAY_WISE_TIME_SPENT_DATA: 'DAY_WISE_TIME_SPENT_DATA',
    PLATFORM_WISE_TIME_SPENT_DATA: 'PLATFORM_WISE_TIME_SPENT_DATA',
    STATS_DATA: 'STATS_DATA',
    TIME_SPENT_PERIODICALLY_DATA: 'TIME_SPENT_PERIODICALLY_DATA',
    PROGRESS_PERIODICALLY_DATA: 'PROGRESS_PERIODICALLY_DATA',
    SET_WIDGET_ANALYTICS_DATA: 'SET_WIDGET_ANALYTICS_DATA',
    SET_ANALYTICS_BUTTON_DATA: 'SET_ANALYTICS_BUTTON_DATA',

    ASSESSMENT_SCORE_DATA: 'ASSESSMENT_SCORE_DATA',
    ASSESSMENT_PROGRESS_DATA: 'ASSESSMENT_PROGRESS_DATA',
    ASSESSMENT_TIME_SPENT_DATA: 'ASSESSMENT_TIME_SPENT_DATA',
    ASSESSMENT_MEASURES_DATA: 'ASSESSMENT_MEASURES_DATA',

    DEVELOPMENT_SCORE_DATA: 'DEVELOPMENT_SCORE_DATA',
    DEVELOPMENT_PROGRESS_DATA: 'DEVELOPMENT_PROGRESS_DATA',
    DEVELOPMENT_TIME_SPENT_DATA: 'DEVELOPMENT_TIME_SPENT_DATA',
    DEVELOPMENT_MEASURES_DATA: 'DEVELOPMENT_MEASURES_DATA',

    LEADERBOARD_DATA: 'LEADERBOARD_DATA',

    SET_GENERIC_ANALYTICS_DATA: 'SET_GENERIC_ANALYTICS_DATA',

    OVERALL_PERFORMANCE_DATA: 'OVERALL_PERFORMANCE_DATA',

    NEEDLE_MOVEMENT_DATA: 'NEEDLE_MOVEMENT_DATA',

    SET_USER_ONLINE_STATUS: 'SET_USER_ONLINE_STATUS',

    ADD_TOAST: 'ADD_TOAST',
    UPDATE_TOAST_DISPLAYED_STATUS: 'UPDATE_TOAST_DISPLAYED_STATUS',
    REMOVE_TOAST: 'REMOVE_TOAST',

    SET_INVALID_FLAG: 'SET_INVALID_FLAG',

    SET_FEEDBACK_DATA: 'SET_FEEDBACK_DATA',
    SET_FEEDBACK_COMPLETION_STATUS: 'SET_FEEDBACK_COMPLETION_STATUS',
    SET_USER_FEEDBACK_DATA: 'SET_USER_FEEDBACK_DATA',
    SET_FEEDBACK_QUESTION_RESPONSE: 'SET_FEEDBACK_QUESTION_RESPONSE',
    SET_COMPLETION_STATUS_TEMP: 'SET_COMPLETION_STATUS_TEMP',

    FETCH_FEEDBACK_STATUS: 'FETCH_FEEDBACK_STATUS',
    FETCH_USER_FEEDBACK_STATUS: 'FETCH_USER_FEEDBACK_STATUS',

    SET_PHASE_FEEDBACK_STATUSES: 'SET_PHASE_FEEDBACK_STATUSES',
    SET_ITEM_FEEDBACK_STATUSES: 'SET_ITEM_FEEDBACK_STATUSES',
    SET_BATCH_FEEDBACK_STATUS: 'SET_BATCH_FEEDBACK_STATUS',
    UNSET_BATCH_FEEDBACK_STATUS: 'UNSET_BATCH_FEEDBACK_STATUS',
    SET_BATCHES_FEEDBACK_STATUS_FLAGS: 'SET_BATCHES_FEEDBACK_STATUS_FLAGS',
    UNSET_BATCHES_FEEDBACK_STATUS_FLAGS: 'UNSET_BATCHES_FEEDBACK_STATUS_FLAGS',

    RESET_USER_FEEDBACK_DATA: 'RESET_USER_FEEDBACK_DATA',
    RESET_FEEDBACK_DATA: 'RESET_FEEDBACK_DATA',

    FETCH_USER_ITEMS_LIST_STATUS_FOR_FEEDBACK_SCREEN: 'FETCH_USER_ITEMS_LIST_STATUS_FOR_FEEDBACK_SCREEN',

    LOGOUT_CLICKED: 'LOGOUT_CLICKED',

    'SET_API_ERROR': 'SET_API_ERROR',
    ADD_UPLOADED_FILE_TO_LIST: 'ADD_UPLOADED_FILE_TO_LIST',

    ADD_UPLOADED_FILENAME_TO_LIST: 'ADD_UPLOADED_FILENAME_TO_LIST',
    UNSET_MODULE_ITEM: 'UNSET_MODULE_ITEM',
    UNSET_UPLOADED_FILES: 'UNSET_UPLOADED_FILES',
    SET_ASSIGNMENT_SUBMITTING: 'SET_ASSIGNMENT_SUBMITTING',
    SET_FILE_UPLOAD_IN_PROGRESS: 'SET_FILE_UPLOAD_IN_PROGRESS',
    REMOVE_FILE: 'REMOVE_FILE',

    REPORT_TASK_STARTED: 'REPORT_TASK_STARTED',
    REPORT_TASK_UPDATED: 'REPORT_TASK_UPDATED',
    REPORT_TASK_COMPLETED: 'REPORT_TASK_COMPLETED',
    SHARING_CERTIFICATE_TASK_STARTED: 'SHARING_CERTIFICATE_TASK_STARTED',
    SHARING_CERTIFICATE_TASK_UPDATED: 'SHARING_CERTIFICATE_TASK_UPDATED',
    SHARING_CERTIFICATE_TASK_COMPLETED: 'SHARING_CERTIFICATE_TASK_COMPLETED',

    //HOME PAGE CONSTANTS
    SET_USER_ORGANIZATION_LOADING: 'SET_USER_ORGANIZATION_LOADING',
    SET_USER_ORGANIZATION_DATA: 'SET_USER_ORGANIZATION_DATA',
    SET_ORGANIZATION_LEVEL_USER_STATS_LOADING: 'SET_ORGANIZATION_LEVEL_USER_STATS_LOADING',
    SET_ORGANIZATION_LEVEL_USER_STATS: 'SET_ORGANIZATION_LEVEL_USER_STATS',
    SET_JOURNEY_WISE_TIME_SPENT: 'SET_JOURNEY_WISE_TIME_SPENT',
    SET_JOURNEY_WISE_TIME_SPENT_LOADING: 'SET_JOURNEY_WISE_TIME_SPENT_LOADING',
    SET_USER_ORGANIZATION_ERROR: 'SET_USER_ORGANIZATION_ERROR',
    //ms teams
    SET_MSTEAMS_BATCH_LEADERBOARD: 'SET_MSTEAMS_BATCH_LEADERBOARD',
    SET_MSTEAMS_BATCH_LEADERBOARD_FETCHING_DATA_FLAG: 'SET_MSTEAMS_BATCH_LEADERBOARD_FETCHING_DATA_FLAG',

    /*MIXPANEL*/
    SET_MIXPANEL_TRACKING: 'SET_MIXPANEL_TRACKING',
    SET_MIXPANEL_TRACKING_ACTIVE_STATUS: 'SET_MIXPANEL_TRACKING_ACTIVE_STATUS',
    UNSET_MIXPANEL_TRACKING: 'UNSET_MIXPANEL_TRACKING',

    SET_RESET_BATCH_PROGRESS_FAILED: 'SET_RESET_BATCH_PROGRESS_FAILED',
    SET_BATCH_LOADED_WITH_ALL_DATA: 'SET_BATCH_LOADED_WITH_ALL_DATA',

    SET_USER_WHITELISTING_REQUIREMENTS: 'SET_USER_WHITELISTING_REQUIREMENTS',

    PUSH_TO_ATTACHMENTS_LIST: 'PUSH_TO_ATTACHMENTS_LIST',
    UPDATE_ATTACHMENT_IN_LIST: 'UPDATE_ATTACHMENT_IN_LIST',

    UPDATE_ITEM_PROGRESS_IN_LIST: 'UPDATE_ITEM_PROGRESS_IN_LIST',
    SET_ORG_LABELS: "SET_ORG_LABELS",
    SET_PAGE_DATA: "SET_PAGE_DATA",
    SET_QUIZ_DATA: "SET_QUIZ_DATA",
    SET_USER_QUIZ_RESPONSES: "SET_USER_QUIZ_RESPONSES",
    SET_FETCH_USER_QUIZ_RESPONSES_FLAG: "SET_FETCH_USER_QUIZ_RESPONSES_FLAG",
    SET_FETCH_SURVEY_QUIZ_FLAG: "SET_FETCH_SURVEY_QUIZ_FLAG",
    SET_FETCH_USER_QUIZ_SUBMISSIONS_FLAG: "SET_FETCH_USER_QUIZ_SUBMISSIONS_FLAG",
    SET_USER_QUIZ_SUBMISSIONS: "SET_USER_QUIZ_SUBMISSIONS"
});

export default actions;
