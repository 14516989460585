import React, { Component } from 'react';
import CircularLoader from 'svgComponents/circularLoader';

export default class SCORMEmbedded extends Component {

    componentDidMount(){
        console.log('SCORMEmbedded componentDidMount')
        this.props.fetchProductLaunchData(true);
    }

    render() {
        const { productLaunchDetails, isProductLaunchDetailsAvailable } = this.props;

        if(!isProductLaunchDetailsAvailable){
            return <div style={{ height: '100%', width: '43px', margin: 'auto' }}>
                <CircularLoader />
            </div>;
        }

        return (
            <iframe src={productLaunchDetails.get('webLaunchUrl')} title="Embedded SCORM Content" style={{width: '100%', height: "100%", border: 0, background: '#f2f3f7'}}/>
        )
    }
}
