import actionConsts from 'constants/actions';

const setOverallProgressData = (payload) => ({
    type: actionConsts.get('OVERALL_PROGRESS_DATA'),
    payload
});

const setDayWiseTimeSpentData = (payload) => ({
    type: actionConsts.get('DAY_WISE_TIME_SPENT_DATA'),
    payload
});

const setPlatformWiseTimeSpent = (payload) => ({
    type: actionConsts.get('PLATFORM_WISE_TIME_SPENT_DATA'),
    payload
});

const setStatsData = (payload) => ({
    type: actionConsts.get('STATS_DATA'),
    payload
});

const setAssessmentScoreData = (payload) => ({
    type: actionConsts.get('ASSESSMENT_SCORE_DATA'),
    payload
});

const setAssessmentProgressData = (payload) => ({
    type: actionConsts.get('ASSESSMENT_PROGRESS_DATA'),
    payload
});

const setAssessmentTimeSpentData = (payload) => ({
    type: actionConsts.get('ASSESSMENT_TIME_SPENT_DATA'),
    payload
});

const setAssessmentMeasuresData = (payload) => ({
    type: actionConsts.get('ASSESSMENT_MEASURES_DATA'),
    payload
});

const setDevelopmentScoreData = (payload) => ({
    type: actionConsts.get('DEVELOPMENT_SCORE_DATA'),
    payload
});

const setDevelopmentProgressData = (payload) => ({
    type: actionConsts.get('DEVELOPMENT_PROGRESS_DATA'),
    payload
});

const setDevelopmentTimeSpentData = (payload) => ({
    type: actionConsts.get('DEVELOPMENT_TIME_SPENT_DATA'),
    payload
});

const setDevelopmentMeasuresData = (payload) => ({
    type: actionConsts.get('DEVELOPMENT_MEASURES_DATA'),
    payload
}); 

const setTimeSpentPeriodicallyData = (payload) => ({
    type: actionConsts.get('TIME_SPENT_PERIODICALLY_DATA'),
    payload
});

const setProgressPeriodicallyData = (payload) => ({
    type: actionConsts.get('PROGRESS_PERIODICALLY_DATA'),
    payload
}); 

const setLeaderboardData = (payload) => ({
    type: actionConsts.get('LEADERBOARD_DATA'),
    payload
}); 

const setOverallPerformanceData = (payload) => ({
    type: actionConsts.get('OVERALL_PERFORMANCE_DATA'),
    payload
});

const setNeedleMovementData = (payload) => ({
    type: actionConsts.get('NEEDLE_MOVEMENT_DATA'),
    payload
});

const setWidgetAnalyticsData = (payload) => ({
    type: actionConsts.get('SET_WIDGET_ANALYTICS_DATA'),
    payload
})

const setAnalyticsButtonData = (payload) => ({
    type: actionConsts.get('SET_ANALYTICS_BUTTON_DATA'),
    payload
})

const setGenericAnalyticsData = (payload) => ({
    type: actionConsts.get('SET_GENERIC_ANALYTICS_DATA'),
    payload
})

export {
    setOverallProgressData,
    setDayWiseTimeSpentData,
    setPlatformWiseTimeSpent,
    setStatsData,
    setAssessmentScoreData,
    setAssessmentProgressData,
    setAssessmentTimeSpentData,
    setAssessmentMeasuresData,
    setDevelopmentScoreData,
    setDevelopmentProgressData,
    setDevelopmentTimeSpentData,
    setDevelopmentMeasuresData,
    setTimeSpentPeriodicallyData,
    setProgressPeriodicallyData,
    setLeaderboardData,
    setOverallPerformanceData,
    setNeedleMovementData,
    setWidgetAnalyticsData,
    setAnalyticsButtonData,
    setGenericAnalyticsData
};
