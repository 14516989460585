import React, { Component } from 'react';

class AdblockerWarning extends Component {
        
    constructor(props){
        super(props);
        this.state = {adblockerOn: false};            
        let testAd = document.createElement('div');
        testAd.innerHTML = '&nbsp;';
        testAd.className = 'adsbox';
        document.body.appendChild(testAd);
        window.setTimeout(() => this.checkIfFakeAdWasRemoved(testAd), 100);
    }

    checkIfFakeAdWasRemoved = (testAd) => {
        let adBlockEnabled = false;
        if (testAd.offsetHeight === 0) {
            adBlockEnabled = true;
            this.setState({adblockerOn: true});
        }
        var node = document.getElementsByClassName('adsbox')[0];
        node.parentNode.removeChild(node);
        console.log('AdBlock Enabled? ', adBlockEnabled)
    }

    render(){
        if(!this.state.adblockerOn) return null;

        return <div className="adblocker-warning"> <b> Warning: </b>
            Your browser seems to have an ad-blocking tool installed. It might have affected the results this system check. Please disable it from running on this page. After that, reload this page and run this check again.
        </div>
    }


}

export default AdblockerWarning;