import Immutable from 'immutable';
import environment from 'utils/environment';
import {getApiUrl, getDomainUrl} from 'utils/apiUrl';

var apiURL = '';
if(environment.REACT_APP_ENV === 'local') {
	apiURL = getApiUrl().toolsUrl + '/api';
} else {
	apiURL = getDomainUrl() + '/api';
}

const toolUrls = () => {
    return Immutable.Map({
        GET_TOOL_FAQS: `${apiURL}/tool/TOOL_ID/faq`
    });
    
};

export default toolUrls;