import Immutable from 'immutable';
import environment from 'utils/environment';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';

var apiURL = '';
if (environment.REACT_APP_ENV === 'local') {
	apiURL = getApiUrl().konsoleUrl;
} else {
	apiURL = getDomainUrl();
}

const konsoleUrls = () => {
	return Immutable.Map({
		GET_USER: `${apiURL}/api/me`,
		GET_LOGOUT_URL: `${apiURL}/logout`,
		GET_USER_REFERENCES: `${apiURL}/cdb/api/fetch-user/USER_ID/references`,
		GET_DEMOGRAPHICS_DATA: `${apiURL}/cdb/api/demographics-entities-and-options`,
		SYNC_USER: `${apiURL}/cdb/api/user/sync-one`,
		CHANGE_PASSWORD: `${apiURL}/cdb/api/change-password`,
		UPLOAD_IMAGE: `${apiURL}/image/upload`,
	});

};

export default konsoleUrls;
