import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		textArea: {
			...myTypography.overline,
			opacity: 0.8
		}
	});
}

export default getSkin;