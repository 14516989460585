import React from 'react';
import { connect } from 'react-redux';
import GenericErrorBoundary from 'components/errorBoundaries';

import {
    fetchQuiz,
    fetchQuizResponse,
    startQuiz,
    submitQuiz
} from 'actions/surveyQuiz/apiActions';

import QuizDashboard from 'components/surveyQuiz';
import CircularLoader from 'svgComponents/circularLoader'

class SurveyQuizContainer extends React.Component {

    componentDidMount() {
        this.props.fetchQuiz(this.props.quizId);
    }


    render() {
        const { batchId, itemId, onProgress, progress, isAlreadyCompleted, selectedUserItem, onStart, hashedQuestions, submitQuiz, fetchingQuiz, fetchingUserQuizSubmissions, submissions, instructions, isSideBarOpen, lockDetails } = this.props;
        const quizId = this.props.quizId
        const isSelectedQuizItemFetched = this.props.quizData.get('quizId') === quizId ? true : false;
        const isQuizResponseFetching = this.props.fetchingQuizResponses;
        if (!isSelectedQuizItemFetched || fetchingQuiz || isQuizResponseFetching || !progress) {
            return (<div style={{ height: 'calc(100vh - 400px)', width: '43px', margin: 'auto' }}>
                <CircularLoader />
            </div>);
        }

        return (
            <GenericErrorBoundary>
                <QuizDashboard
                    quizData={this.props.quizData}
                    quizResponseData={this.props.quizResponseData}
                    onProgress={onProgress}
                    progress={progress}
                    isAlreadyCompleted={isAlreadyCompleted}
                    selectedUserItem={selectedUserItem}
                    batchId={batchId}
                    phaseItemId={itemId}
                    fetchQuizResponse={this.props.fetchQuizResponse}
                    startQuiz={this.props.startQuiz}
                    quizId={this.props.quizId}
                    onSetItemWorkFlowStateStart={onStart}
                    hashedQuestions={hashedQuestions}
                    submitQuiz={submitQuiz}
                    fetchingUserQuizSubmissions={fetchingUserQuizSubmissions}
                    submissions={submissions}
                    instructions={instructions}
                    isSidebarOpen={isSideBarOpen}
                    lockDetails={lockDetails}
                />
            </GenericErrorBoundary>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        quizData: state.get('surveyQuiz').get('quizData'),
        quizResponseData: state.get('surveyQuiz').get('quizResponseData'),
        fetchingUserQuizSubmissions: state.get('surveyQuiz').get('fetchingUserQuizSubmissions'),
        submissions: state.get('surveyQuiz').get('submissions'),
        fetchingQuizResponses: state.get('surveyQuiz').get('fetchingQuizResponses'),
        fetchingQuiz: state.get('surveyQuiz').get('fetchingQuiz'),
        hashedQuestions: state.get('surveyQuiz').get('hashedQuizQuestions'),
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchQuiz: (quizId) => {
        fetchQuiz(quizId);
    },
    fetchQuizResponse: (quizId) => {
        fetchQuizResponse(quizId);
    },
    submitQuiz: (payload, batchId, phaseItemId, shouldUpdateRoute = false) => {
        submitQuiz(payload, batchId, phaseItemId, shouldUpdateRoute)
    },
    startQuiz: (phaseItemId, batchId, quizId) => startQuiz(phaseItemId, batchId, quizId)

});

export default connect(mapStateToProps, mapDispatchToProps)(SurveyQuizContainer);
