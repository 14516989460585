import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setToolFAQs } from 'actions/tools/actionCreators';
import { updateFetchToolFAQsStatus } from 'actions/loaders/actionCreators';

class FetchToolFAQs extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    isToolFAQsAvailable = (apiInfoObject) => {
        let faqsList = this.getState().get('tools').get('faqsList');

        faqsList = faqsList.filter((faq) => faq.get('toolId') === apiInfoObject.toolId);

        if (faqsList.size > 0) {
            return true;
        }

        return false;
    }

	prepareUrl = (apiInfoObject) => urls.get('PLATFORM').get('GET_TOOL_FAQS').replace(
        'TOOL_ID',
        apiInfoObject.toolId
    )+`?lang=${apiInfoObject.lang}`
	
	shouldAjaxCall = (apiInfoObject) => {
        if (this.isToolFAQsAvailable(apiInfoObject)) {
            return false;
        }
        return true;
    }

    beforeCall = (apiInfoObject) => {
        this.dispatch(updateFetchToolFAQsStatus(true));
        return apiInfoObject;
    }

    afterCall = (response, apiInfoObject) => {
        this.dispatch(updateFetchToolFAQsStatus(false));
        return response;
    }

    successCall = (response) => {
        this.dispatch(setToolFAQs(response));
        return response;
    }

}

export default new FetchToolFAQs();
