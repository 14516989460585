import React, { Component } from 'react';
import styles from './leftArrowCircle.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LeftArrowCircle extends Component {

	constructor(props) {
		super(props);
		this.state={
			onHover: false
		}
	}

	setOnHoverTrue = () => {
		this.setState({
			onHover: true
		});
	}

	setOnHoverFalse = () => {
		this.setState({
			onHover: false
		});
	}

	render() {

		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="left-arrow-component" onMouseEnter={this.setOnHoverTrue} onMouseLeave={this.setOnHoverFalse}>
				<svg width="100%" height="100%" viewBox="0 0 40 40">
					<path
						fill="#FFFFFF"
						fillRule="nonzero"
						d="M40 20A20 20 0 0 0 20 0C8.954 0 0 8.954 0 20a20 20 0 0 0 40 0zm-4 0c0 8.837-7.163 16-16 16S4 28.837 4 20 11.163 4 20 4a16 16 0 0 1 16 16zM24 10L14 20l10 10V10z"
						opacity={this.state.onHover ? 1 : 0.5}/>
				</svg>
			</div>
		);
	}
}



export default LeftArrowCircle;
