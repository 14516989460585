import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {
	uploadImageSuccess
} from 'actions/images/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

class UploadImage extends NetPackWrapper {

	constructor() {
		super("POST", true, true);
	}

	beforeCall = (apiInfoObject) => {
		return apiInfoObject;
	}

	prepareUrl = () => {
		return urls.get('PLATFORM').get('UPLOAD_IMAGE')
	}

	successCall = (response) => {
		return response;
	}

	onError = (error) => {
		console.log('error', error);
		
		if (error.errorObject && error.errorObject.response ){
			let errorResponse = error.errorObject.response;
			if(typeof errorResponse === 'string'){
				errorResponse = JSON.parse(errorResponse);
			}
			this.dispatch(addToast({
				id: toastConstants.get('ID_ERROR'),
				type: toastConstants.get('TYPE_ERROR'),
				heading: 'Upload Failed',
				message: errorResponse.error.message,
				isDisplayed: false,
				autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
			}));
		}
	}
}

export default new UploadImage();