import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';

class SetTeamTabLinking extends NetPackWrapper {

    constructor() {
        super("POST");
    }

	prepareUrl = () => urls.get('NOTIFICATION').get('MSTEAMS_TAB_LINKING')

    successCall = (response) => {
        console.log('SetTeamTabLinking: response', response);        
        return response;
    }

    onError = (response) => {
        console.log("error", response);        
    }

}

export default new SetTeamTabLinking();
