import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = () => {
    
	return StyleSheet.create({

        fontStylingTitle: {
            fontSize: '20px',
            color: '#979eb7',
            fontWeight: 600
        },

        fontStylingSubTitle: {
            fontSize: '14px',
            color: '#979eb7',
            fontWeight: 400
        },

        borderStyling: {
            border: 'solid 1px #979eb7'
        },

        filterSelected: {
            border: 'none',
            backgroundColor: '#575e79',
            color: '#2e344b',
            letterSpacing: '-0.1px',
            fontSize: '13px',
            color: '#2e344b',
            fontWeight: 400
        },

        fontStyling: {
            letterSpacing: '-0.1px',
            fontSize: '13px',
            color: '#979eb7',
            fontWeight: 400

        },

    });
}

export default getSkin;