import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './tag.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite/no-important';
import FeedbackCompCircle from 'svgComponents/feedbackCompCircle';
import SelectedTag from 'svgComponents/selectedTag';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Tag extends Component {

    toggleSelected = (event) => {
        if (this.props.isSelected) {
            event.stopPropagation();
            this.props.removeOption(this.props.option.id);
        }
        else {
            event.stopPropagation();
            this.props.addOption(this.props.option.id);
        }
    }

    renderTickType = (myStyles) => {
        const { option, isSelected } = this.props;

        if (!isSelected) {
            return (
                <div styleName="tag-box" onClick={this.toggleSelected}>
                    <FeedbackCompCircle />
                    <div className={css(myStyles.tagText)} styleName="tag-text">
                        {option.name}
                    </div>
                </div>
            );
        }
        return (
            <div styleName="tag-box" onClick={this.toggleSelected} >
                <div styleName="icon"><SelectedTag /></div>
                <div className={css(myStyles.tagSelectedText)} styleName="tag-text">
                    {option.name}
                </div>
            </div>
        );
    }


    renderTagType = (myStyles) => {
        const { option, isSelected } = this.props;

        if (!isSelected) {
            return (
                <div styleName="tag-box" onClick={this.toggleSelected}>
                    <div className={css(myStyles.tagText)} styleName="tag-text" dangerouslySetInnerHTML={{__html: option.name}}>
                        {/* {option.name} */}
                    </div>
                </div>
            );
        }
        return (
            <div className={css(myStyles.tagTypeSelected)}  styleName="tag-box" onClick={this.toggleSelected} dangerouslySetInnerHTML={{__html: option.name}}>
                <div styleName="tag-text">
                    {/* {option.name} */}
                </div>
            </div>
        );
    }


    render() {
        const myStyles = getSkin(this.props.skinGuide);
        if(this.props.showTick){
            return this.renderTickType(myStyles);
        }
        return this.renderTagType(myStyles);
    }
}

export default Tag;
