import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './reviewQuizHeader.module.sass';
import getSkin from './skin.js';
import DownArrow from 'svgComponents/downArrow';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import Dialog from 'commonComponents/dialog'
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ReviewQuizHeader extends React.Component {

	render() {
		let styles = getSkin(this.props.skinGuide);
		const { moduleItem, activeAttemptSubmission, getLabelWithDefaults } = this.props;

		var quizTitleElement, quizScoreElement, quizActionsElement;

		quizTitleElement = quizScoreElement = quizActionsElement = null

		if (moduleItem.get('isModuleItemDataAvailable')) {
			quizTitleElement = (
				<div
					key="quiz-title"
					className={css(styles.quizTitle)}
					styleName="quiz-title"
					onClick={this.toggleInstructions}
				>
					<div
						styleName="title"
						title={moduleItem.get('moduleItem').get('title')}
					>
						{moduleItem.get('moduleItem').get('title')}
					</div>
					<div className={css(styles.reviewQuizLabel)}>
						{getLabelWithDefaults(
							'quiz_review_answers_label',
							'REVIEW ANSWERS'
						)}
					</div>
				</div>
			)

			quizScoreElement = (
				<div
					key="timer-cnt"
					className={css(styles.timerCnt)}
					styleName="timer-cnt"
				>
					{
						activeAttemptSubmission && activeAttemptSubmission.get('score') != undefined &&
						[
							<div key="quiz-results" className={css(styles.quizAttemptScore)} >
								{activeAttemptSubmission.get('score')}/{activeAttemptSubmission.get('quiz_points_possible')}
							</div>,
							<div key="quiz-attempt" className={css(styles.questionsAnswered)}>
								{getLabelWithDefaults(
									'quiz_review_attempt_score_heading',
									`Attempt ${activeAttemptSubmission.get('attempt')} score`,
									{ attempt_number: activeAttemptSubmission.get('attempt') }
								)}
							</div>
						]
					}
				</div>
			)

			quizActionsElement = (
				<div key="quiz-actions-cnt" styleName="quiz-actions-cnt">
					<div
						className={css(styles.exitBtn)}
						styleName="exit-btn"
						onClick={this.props.exitAction}
					>
						{getLabelWithDefaults('exit_label', 'EXIT')}
					</div>
				</div>
			)

		}
		return (
			<div className={css(styles.quizHeaderCnt)} styleName="quiz-header-cnt">
				{
					moduleItem.get('isModuleItemDataAvailable') ?
						[quizTitleElement, quizScoreElement, quizActionsElement]
						:
						<div>
							{getLabelWithDefaults('quiz_loading_label', 'Loading Quiz Content...')}
						</div>
				}

			</div>
		)
	}
}

export default ReviewQuizHeader;