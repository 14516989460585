import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './takeQuiz.module.sass';
import getSkin from './skin.js';
import TakeQuizHeader from './takeQuizHeader';
import QuestionList from './questionsList';
import QuizNavigator from './quizNavigator';
import TakeQuizLoader from './takeQuizLoader';
import Dialog from 'commonComponents/dialog'
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class TakeQuiz extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			quiz_questions: [],
			currentQuestion: 'question',
			showConfirmationPopup: false,
			popupMessage: ''
		}
	}

	//Disabling this function to avoid unneccessary stringifying entire prop object which is time consuming and need to be done at 2 places

	// shouldComponentUpdate(nextProps, nextState){		
	//     if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {			
	//         return false;
	//     }
	//     return true;
	// }

	componentWillReceiveProps(nextProps) {
		if (nextProps.quiz.get('quizAttemptExhausted')) {
			this.setState({
				showConfirmationPopup: true,
				popupMessage: this.props.getLabel('quiz_attempt_exhausted_label')
			}, () => {
				setTimeout(() => this.returnToQuizDetails(), 5000);
			})
		}
	}

	componentWillUnmount() {
		this.setState({
			showConfirmationPopup: false,
			popupMessage: ''
		})
	}

	setCurrentQuestion = (questionId) => {
		this.setState({
			currentQuestion: `question_${questionId}`
		})
	}

	setQuizQuestionAnswer = (questionId, answerId) => {
		const payload =
		{
			id: questionId,
			answer: answerId
		};
		let { quiz, moduleItem, match } = this.props;
		let payloadForAnswering = {
			submissionId: quiz.get('activeAttempt').get('id'),
			courseId: this.props.match.params.courseId,
			quizId: moduleItem.get('moduleItem').get('content_id'),
			quizQuestions: quiz.get('quizAnswerSelected').toJS(),
			body: {
				attempt: quiz.get('activeAttempt').get('attempt'),
				validation_token: quiz.get('activeAttempt').get('validation_token')
			},
			match: match,
			batchId: match.params.batchId,
			phaseId: match.params.phaseId,
			phaseItemId: match.params.phaseItemId,
			newQuizAnswers: payload
		}
		this.props.setQuizAnswers(payloadForAnswering)
		this.props.setQuizQuestionAnswer(payload)
	}

	flagQuestion = (question) => {
		console.log("calling flag")
		let { quiz, match } = this.props;
		let payload = {
			submissionId: quiz.get('activeAttempt').get('id'),
			questionId: question.get('id'),
			body: {
				attempt: quiz.get('activeAttempt').get('attempt'),
				validation_token: quiz.get('activeAttempt').get('validation_token')
			},
			match: match,
			batchId: match.params.batchId,
			phaseId: match.params.phaseId,
			phaseItemId: match.params.phaseItemId
		}
		console.log("api payload", payload);
		console.log(this.props);
		if (question.get('flagged')) {
			this.props.unflagQuestion(payload);
		} else {
			this.props.flagQuestion(payload)
		}
	}

	sendSegmentData = () => {
		let { quiz, moduleItem, match } = this.props;
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			batchId: match.params.batchId,
			phaseId: match.params.phaseId,
			phaseItemId: match.params.phaseItemId,
			submissionId: quiz.get('activeAttempt').get('id'),
			courseId: match.params.courseId,
			quizId: moduleItem.get('moduleItem').get('content_id'),
			quizAttempt: quiz.get('activeAttempt').get('attempt')
		}
		window.analytics.track(
			'Quiz Submitted',
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	submitQuiz = () => {
		let { quiz, moduleItem, match } = this.props;

		this.sendSegmentData();

		let payload = {
			submissionId: quiz.get('activeAttempt').get('id'),
			courseId: this.props.match.params.courseId,
			quizId: moduleItem.get('moduleItem').get('content_id'),
			body: {
				attempt: quiz.get('activeAttempt').get('attempt'),
				validation_token: quiz.get('activeAttempt').get('validation_token')
			},
			match: match,
			batchId: match.params.batchId,
			phaseId: match.params.phaseId,
			phaseItemId: match.params.phaseItemId
		}
		this.props.completeQuiz(payload)
	}

	returnToQuizDetails = () => {
		const { match } = this.props;
		const batchId = match.params.batchId;
		const courseId = match.params.courseId;
		const moduleId = match.params.moduleId;
		const moduleItemId = match.params.moduleItemId;
		const phaseId = match.params.phaseId;
		const phaseItemId = match.params.phaseItemId;
		const route = routes.get('BATCH_ITEM_MODULE');
		const params = [
			{ 'BATCH_ID': batchId },
			{ 'COURSE_ID': courseId },
			{ 'MODULE_ID': moduleId },
			{ 'ITEM_ID': moduleItemId },
			{ '_PHASE_ID_': phaseId },
			{ '_PHASEITEMID_': phaseItemId }
		]
		updateRoute({ route, params });
	}

	render() {
		let { moduleItem, quiz, getLabelWithDefaults } = this.props;
		let {
			currentQuestion,
			showConfirmationPopup,
			popupMessage
		} = this.state;
		let styles = getSkin(this.props.skinGuide);

		return (
			<div className={css(styles.quizQuestionsOuterCnt)} styleName="quiz-question-outer-cnt">

				{
					quiz.get('quizQuestionsLoading') ?
						<TakeQuizLoader />
						:
						quiz.get('quizQuestions') && quiz.get('quizQuestions').size > 0 ?
							[
								<TakeQuizHeader
									key="quiz-header"
									moduleItem={moduleItem}
									quiz={quiz}
									submitQuiz={this.submitQuiz}
									match={this.props.match}
								/>,
								<div key="quiz-questions" className={css(styles.quizDataCnt)} styleName="quiz-questions-data">
									<div className={css(styles.questionListCnt)} styleName="question-list-cnt" id="scrollable-questions-cnt">
										<QuestionList
											setQuizQuestionAnswer={this.setQuizQuestionAnswer}
											questions={quiz.get('quizQuestions')}
											selectedAnswers={quiz.get('quizAnswerSelected')}
											currentQuestion={currentQuestion}
											flagQuestion={this.flagQuestion}
										/>
									</div>
									<div className={css(styles.questionNavigatorCnt)} styleName="question-navigator-cnt">
										<QuizNavigator
											questions={quiz.get('quizQuestions')}
											selectedAnswers={quiz.get('quizAnswerSelected')}
											setCurrentQuestion={this.setCurrentQuestion}
										/>
									</div>
								</div>
							]
							:
							<div>
								{getLabelWithDefaults('quiz_no_questions_label', 'Oops... There are no questions assigned to this quiz')}
							</div>
				}

				{
					showConfirmationPopup &&
					<Dialog
						key="quiz-confirmation-dialog"
						dialogMessage={popupMessage}
						okAction={null}
						cancelAction={null}
						okButtonText={null}
						isComponentLoading={true}
					/>
				}
			</div>
		)
	}
}

export default TakeQuiz;