import React, { Component } from 'react';
import styles from './arrowConnectorForDT.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ArrowConnectorForDT extends Component {

	render() {
		return (
			<div styleName="component-cnt">
				<svg width="100%" height="100%" viewBox="0 0 57 9">
					<g fill="none" fillRule="evenodd">
						<path stroke="#4A5EB3" strokeLinecap="square" strokeWidth="2" d="M4.304 4.48h49.035" />
						<path fill="#4A5EB3" d="M5 0v9.023L.488 4.512zM52.488 9.023V0L57 4.512z" />
					</g>
				</svg>
			</div>
		);
	}
}



export default ArrowConnectorForDT;