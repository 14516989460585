import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme, hideTopBorder = false) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        nextActivityContainer: {
        },
        nextActivityImage: {
        },
        nextActivityContent: {
            boxShadow: `0 20px 25px 0 rgba(0, 0, 0, 0.2), 0 10px 10px 0 rgba(0, 0, 0, 0.04)`,
            border: `solid 1px rgba(151, 158, 183, 0.27)`,
            backgroundImage: `linear-gradient(95deg, ${skin.get('greyColor2')} 0%, #3b456b 100%)`,
            borderRadius: '4px'
        },
        itemType: {
            ...myTypography.labelSmall,
            color: hexToRgbA(skin.get('white'), 0.7)
        },
        tagLine: {
            ...myTypography.labelSmall,
            color: hexToRgbA(skin.get('white'), 0.7),
            textTransform: 'uppercase',
            borderBottom: `solid 1px ${hexToRgbA('#979797', 0.1)}`
        },
        title: {
            ...myTypography.h5,
            color: skin.get('white'),
            '@media screen and (max-width: 480px)': {
                fontSize: '22px'
            }
        },
        titleSticky: {
            ...myTypography.h5,
            color: skin.get('white')
        },
        stats: {
            ...myTypography.caption,
            color: hexToRgbA('#2C2C2C', 0.6),
            fontWeight: 400
        },
        buttonContinue: {
            boxShadow: `0 14px 16px 0 ${hexToRgbA(skin.get('black'), 0.19)}`,
            backgroundColor: skin.get('primaryColor'),
            borderRadius: '4px',
            textTransform: 'uppercase',
            ...myTypography.button,
            color: skin.get('white'),
            cursor: 'pointer',
            ':hover': {
                backgroundColor: skin.get('primaryVariantColor')
            }
        },
        buttonContinueSticky: {
            backgroundColor: skin.get('primaryColor'),
            borderRadius: '4px',
            textTransform: 'uppercase',
            ...myTypography.button,
            color: skin.get('white'),
            cursor: 'pointer',
            ':hover': {
                backgroundColor: skin.get('primaryVariantColor')
            }
        }
    });
}

export default getSkin;