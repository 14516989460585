/**
 * @author: amul
 * @since: Mon Jul 05 2021 12:17:42 GMT+0530 (India Standard Time)
 * @file: AttachmentContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Attachment container is responsible for rendering different players for attachments (files)
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import GenericErrorBoundary from 'components/errorBoundaries';

import {
    fetchPage
} from 'actions/page/apiActions';

import Page from 'components/page/Page';
import CircularLoader from 'svgComponents/circularLoader'

class PageContainer extends React.Component {

    componentDidMount() {
        this.props.fetchPage(this.props.pageId);
    }


    render() {
        const { onStart, onProgress, progress, isAlreadyCompleted, selectedUserItem } = this.props;
        const pageId = this.props.pageId
        const isSelectedPageDataFetched = this.props.page.get('id') === pageId ? true : false;

        if (!isSelectedPageDataFetched || !progress) return (<div style={{ height: 'calc(100vh - 400px)', width: '43px', margin: 'auto' }}>
            <CircularLoader />
        </div>);

        return (
            <GenericErrorBoundary>
                <Page
                    pageData={this.props.page}
                    onStart={onStart}
                    onProgress={onProgress}
                    progress={progress}
                    isAlreadyCompleted={isAlreadyCompleted}
                    selectedUserItem={selectedUserItem}
                />
            </GenericErrorBoundary>

        );
    }

}

const mapStateToProps = (state) => {
    return {
        page: state.get('page').get('pageData')
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchPage: (pageId) => {
        fetchPage(pageId);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(PageContainer);
