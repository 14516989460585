import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({

		filterTitleStyle: {
			fontSize: '14px',
			fontWeight: 400,
			color: '#d44980'
		},

		dropDownContainerStyle: {
			backgroundColor: '#282d42',
			border: 'solid 1px rgba(255, 255, 255, 0.1)',
		}

    })
}

export default getSkin;