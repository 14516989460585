import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            backgroundColor: skin.get('white'),
            borderBottom: `1px solid ${skin.get('greyColor5')}`
        },
        progressBarOuter: {
			backgroundColor: skin.get('greyColor5')
        },
        startedBackground: {
			backgroundColor: hexToRgbA(skin.get('darkSkyBlue'), 0.6),
        },
        selected: {
            backgroundColor: skin.get('secondaryColor')
        },
        name: {
            ...myTypography.overline,
            color: skin.get('greyColor1'),
            fontWeight: 600
        },
        completedCount:{
            ...myTypography.caption,
            color: skin.get('grey3'),
            fontWeight: 'normal',
            opacity: 0.6
        },
        moduleCompletedCount:{
            ...myTypography.caption,
            color: skin.get('grey3'),
            fontWeight: 'normal',
            opacity: 0.6,
            fontSize: '10px'
        },
        courseProgressBar: {
            background: skin.get('secondaryColor')
        },
        tooltipContent: {
            backgroundColor: skin.get('greyColor2'),
            color: skin.get('white'),
            boxShadow: `5px 5px 10px 0 ${hexToRgbA(skin.get('black'), 0.5)}`,
            ...myTypography.caption
        },
        tooltipArrow: {
            borderRight: `7px solid ${skin.get('greyColor2')}`,
            borderBottom: `7px solid transparent`
        },
        date:{
            ...myTypography.button,
            color: hexToRgbA(skin.get('greyColor3'), 0.6),
            fontWeight: 'normal'
        },
    });
}

export default getSkin;