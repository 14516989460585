import getBreakpoint from 'utils/breakpoints';

export default (width) => {
	const breakpoint = getBreakpoint(width);
	let points = 5;
	
	switch(breakpoint) {
		case 'lg':
			points += 3
		case 'md':
			points += 2
		case 'sm':
			points += 2
		case 'xs':
			points += 3
			break;
		case 'xxs':
			points = 5
	}
	// if (breakpoint == 'xs') {
	// 	points = 4;
	// }
	// else if (breakpoint == 'sm') {
	// 	points = 10;
	// }
	// else if (breakpoint == 'md') {
	// 	points = 16;
	// }
	// else if (breakpoint == 'lg') {
	// 	points = 20;
	// }
	
	return {
		points: points,
	};
}