import React, { Component } from 'react';
import styles from './wikiPagesIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class WikiPagesIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<path fill="#828593" fillRule="nonzero" d="M12.643 0H1.446C.696 0 .09.608.09 1.357v11.197c0 .75.608 1.357 1.357 1.357h11.197c.75 0 1.357-.608 1.357-1.357V1.357C14 .607 13.392 0 12.643 0zm-5.09 10.179a.34.34 0 0 1-.339.339H3.821a.34.34 0 0 1-.339-.34V9.5a.34.34 0 0 1 .34-.34h3.392a.34.34 0 0 1 .34.34v.679zm3.393-3.054a.34.34 0 0 1-.339.34H3.821a.34.34 0 0 1-.339-.34v-.679a.34.34 0 0 1 .34-.339h6.785a.34.34 0 0 1 .34.34v.678zm0-3.054a.34.34 0 0 1-.339.34H3.821a.34.34 0 0 1-.339-.34v-.678a.34.34 0 0 1 .34-.34h6.785a.34.34 0 0 1 .34.34v.678z"/>
				</svg>
			</div>
		);
	}
}



export default WikiPagesIcon;
