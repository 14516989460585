import { fetchAppBaseData } from 'actions/init/apiActions';
import FetchBatchDetails from 'actions/serverCalls/batches/FetchBatchDetails';
import FetchUserBatchDetails from 'actions/serverCalls/batches/FetchUserBatchDetails';
import FetchLabels from 'actions/serverCalls/labels/FetchLabels';
import FetchPhases from 'actions/serverCalls/phases/FetchPhases';
import FetchItems from 'actions/serverCalls/items/FetchItems';
import FetchUserPhases from 'actions/serverCalls/phases/FetchUserPhases';
import FetchUserItems from 'actions/serverCalls/items/FetchUserItems';
import { updateFetchBatchDashboardBasicDataStatus, updateFetchBatchDashboardStatus } from 'actions/loaders/actionCreators';
import { addBatchToListAfterAllDataLoaded } from 'actions/batches/actionCreators';
import { fetchCourseModulesList, fetchModuleItemsForSingleModuleCourse } from 'actions/courses/apiActions';
import FetchUserFeedbacksForItems from 'actions/serverCalls/itemFeedback/FetchUserFeedbacksForItems';

import { checkIfPresent } from 'utils/utilFunctions';
import { filterObjectFromArray } from 'utils/objectUtils';
import environment from 'utils/environment';
import { getUserActiveBatchDetails } from 'utils/batchUtils';
import { setActiveBatchAndPhaseId } from 'actions/batches/actionCreators';
import setUserState from 'actions/userState/actionCreators';
import { fetchUserWhitelistingRequirements } from 'actions/users/apiActions';

import { fetchAttachmentsList } from 'actions/attachments/apiActions';

const fetchBatchLabels = (batchId, batchDetails) => {
    const lang = batchDetails.journey.lang;
    const _batch_cache_version_ = batchDetails._cache_version_;
    const labelsApiInfoObject = { queryParameters: { lang, batchId, _batch_cache_version_ } };
    return FetchLabels.call(labelsApiInfoObject)
}

const fetchBatchPhases = (batchId, batchDetails) => {
    const _batch_cache_version_ = batchDetails._cache_version_;
    const phasesApiInfoObject = {
        showErrorPage: true,
        queryParameters: { batchId, _batch_cache_version_ }
    }
    return FetchPhases.call(phasesApiInfoObject);
}

const fetchBatchPhaseItems = (batchId, batchDetails) => {
    const _batch_cache_version_ = batchDetails._cache_version_;
    const itemsApiInfoObject = {
        showErrorPage: true,
        queryParameters: { batchId, _batch_cache_version_ }
    }
    return FetchItems.call(itemsApiInfoObject);
}

const fetchAktivLearnData = (batchId, phasesResponse, phaseItemsResponse) => {

    const getPhaseParams = (phaseItem) => {
        const phase = phasesResponse.phases.find(phase => phase.id == phaseItem.phaseId);
        let developmentItemType = null;
        if (phase.type === "Development") {
            if (phase.settings.developmentItemType === "MODULE_ITEMS" || phase.settings.developmentItemType === "MODULE") {
                developmentItemType = phase.settings.developmentItemType
            }
        }

        return {
            batchId: batchId,
            phaseId: phase.id,
            developmentItemType
        };
    };


    if (checkIfPresent(phaseItemsResponse.items)) {
        phaseItemsResponse.items.forEach((phaseItem) => {
            let params = {};
            const itemParams = {
                courseId: phaseItem.toolId,
                phaseItemId: phaseItem.id,
            };
            const phaseParams = getPhaseParams(phaseItem);
            params = { ...phaseParams, ...itemParams };
            if (phaseParams.developmentItemType === "MODULE_ITEMS") {
                // fetch module items for the first module
                fetchModuleItemsForSingleModuleCourse(params);
            } else if (phaseParams.developmentItemType === "MODULE") {
                fetchCourseModulesList(params);
            }
        })
    }
}

const fetchAttachmentsIfAny = (phaseItemsResponse) => {
    const attachmentIdsList = [];
    
    phaseItemsResponse.items.forEach( phaseItem => {
        if(phaseItem.type === 'ATTACHMENT'){
            attachmentIdsList.push(phaseItem.attachmentId)
        }
    });

    if(attachmentIdsList.length === 0) return Promise.resolve();

    return fetchAttachmentsList(attachmentIdsList)
}

const setFetchingFlags = (dispatch, getState, batchId) => {
    let flag = true;
    const listOfBatchIdsWithAllDataAvailable = getState().get('batches').get('listOfBatchIdsWithAllDataAvailable');

    if (listOfBatchIdsWithAllDataAvailable.includes(batchId) || listOfBatchIdsWithAllDataAvailable.includes(`${batchId}`)) flag = false;

    dispatch(updateFetchBatchDashboardBasicDataStatus(flag));
    dispatch(updateFetchBatchDashboardStatus(flag));
}

const setActiveBatchAndPhase = (dispatch, getState, batchId) => {
    let batchAlreadyLoaded = false;

    const listOfBatchIdsWithAllDataAvailable = getState().get('batches').get('listOfBatchIdsWithAllDataAvailable');

    if (listOfBatchIdsWithAllDataAvailable.includes(batchId)) batchAlreadyLoaded = true;

    if (batchAlreadyLoaded) {
        const userBatchDetails = getUserActiveBatchDetails(getState().get('batches'), +batchId);

        dispatch(setUserState({
            currentBatchId: +batchId,
            currentPhaseId: +userBatchDetails.get('activePhaseId')
        }));

        dispatch(setActiveBatchAndPhaseId({
            activeBatchId: +batchId,
            activePhaseId: +userBatchDetails.get('activePhaseId')
        }));
    }
}

const fetchBatchDetails = (batchId, shouldFetchFeedbackStatuses = true) => {
    return async (dispatch, getState) => {
        try {
            setFetchingFlags(dispatch, getState, batchId);//to show loaders
            setActiveBatchAndPhase(dispatch, getState, batchId);//to show correct data immediately

            await dispatch(fetchAppBaseData()); //fetch user and user's org

            await fetchUserWhitelistingRequirements(batchId); //fetch whitlisitng requirements for the batch

            await FetchUserBatchDetails.call({ batchId });
            const batchDetails = await FetchBatchDetails.call({ batchId }); //cached by sw

            await fetchBatchLabels(batchId, batchDetails); //cached by sw
            dispatch(updateFetchBatchDashboardBasicDataStatus(false));

            //render the top part of the invitation
            //eliminate isBatchLabelsavailable and other logic like that
            await FetchUserPhases.call({ batchId });
            await FetchUserItems.call({ queryParameters: { batchId } });
            const phasesResponse = await fetchBatchPhases(batchId, batchDetails); //cached by sw
            const phaseItemsResponse = await fetchBatchPhaseItems(batchId, batchDetails); //cached by sw
            //fetch list of attachments if any
            await fetchAttachmentsIfAny(phaseItemsResponse);
            dispatch(updateFetchBatchDashboardStatus(false));

            //save batchId to list of batches whose whole data has been loaded
            dispatch(addBatchToListAfterAllDataLoaded(batchId));

            fetchAktivLearnData(batchId, phasesResponse, phaseItemsResponse);
            dispatch(fetchAdditionalDashboardData(batchId, shouldFetchFeedbackStatuses));
            return Promise.resolve(batchDetails);
        } catch (error) {
            dispatch(updateFetchBatchDashboardBasicDataStatus(false));
            dispatch(updateFetchBatchDashboardStatus(false));
            return Promise.reject(error);
        }
    }
}

const fetchBatchDetailsForItem = (dispatch, batchId, shouldFetchBaseData = true, shouldFetchFeedbackStatuses = true) => {
    if (shouldFetchBaseData) {
        return dispatch(fetchBatchDetails(batchId, shouldFetchFeedbackStatuses));
    }
    return Promise.resolve();
}

//== additional data

const fetchAndSetPhaseFeedbackStatuses = (dispatch, getState, batchId) => {
    const phasesList = getState().getIn(['phases', 'phasesList']);
    const nonDevPhases = phasesList.filter(phase => (phase.get('type') !== 'Development') && (phase.get('batchId') == batchId));
    nonDevPhases.forEach(phase => {
        if (
            phase.getIn(['settings', 'isFeedbackEnabled']) === 'true'
            && phase.getIn(['settings', 'feedbackScenarioId']) !== ''
        ) {
            fetchUserItemFeedback(dispatch, getState, batchId, phase.get('id'), "PHASE", phase.getIn(['settings', 'feedbackScenarioId']));
        }
    })
}

const fetchAndSetItemsFeedbackStatuses = (dispatch, getState, batchId) => {
    const phasesList = getState().getIn(['phases', 'phasesList']);
    const itemsList = getState().getIn(['items', 'itemsList']);
    const devPhases = phasesList.filter(phase => (phase.get('type') === 'Development') && (phase.get('batchId') == batchId));
    const devPhaseIds = devPhases.map(phase => phase.get('id'));

    const devPhaseItems = itemsList.filter(item => devPhaseIds.indexOf(item.get('phaseId')) > -1)

    devPhaseItems.forEach(item => {
        if (
            item.getIn(['settings', 'isFeedbackEnabled']) === 'true'
            && item.getIn(['settings', 'feedbackScenarioId']) !== ''
        ) {
            fetchUserItemFeedback(dispatch, getState, batchId, item.get('id'), "PHASEITEM", item.getIn(['settings', 'feedbackScenarioId']));
        }
    })
}

const fetchUserItemFeedback = (dispatch, getState, batchId, entityId, entityType, scenario) => {
    let langID = 'en_US';

    const batchesList = getState().getIn(['batches', 'batchesList']);
    const activeBatchDetails = filterObjectFromArray(batchesList, 'id', batchId);
    if (activeBatchDetails > 0) {
        langID = activeBatchDetails.getIn(['journey', 'lang']);
    }

    const apiInfoObj = {
        queryParameters: {
            device: 'WEBBROWSER',
            scenario: scenario,
            entityType: entityType,
            entityId: entityId,
            lang: langID,
            groupId: batchId,
            serviceName: 'ADPlatform',
            env: environment.REACT_APP_FEEDBACK_ENV
        }
    };
    return FetchUserFeedbacksForItems.call(apiInfoObj)
};


const fetchAdditionalDashboardData = (batchId, shouldFetchFeedbackStatuses = true) => {

    if (!shouldFetchFeedbackStatuses) {
        return (dispatch, getState) => { };
    }

    return (dispatch, getState) => {
        fetchAndSetPhaseFeedbackStatuses(dispatch, getState, batchId);
        fetchAndSetItemsFeedbackStatuses(dispatch, getState, batchId);
    };
}

export {
    fetchBatchDetails,
    fetchBatchDetailsForItem
};