import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './productInstruction.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ProductIntstruction extends Component {
	render() {
		const { getLabel, selectedItem, skinGuide, isLearningPhaseItem } = this.props;
		const myStyles = getSkin(skinGuide);

		return (
			<div styleName="inst-container">
				{
					isLearningPhaseItem &&
					<div className={css(myStyles.title)}>
						{getLabel('instructions_label')}
					</div>
				}
				<div
					className={css(myStyles.instText)}
					styleName="inst-text">
					<div dangerouslySetInnerHTML={{ __html: selectedItem.get('playInstructions') }} />
				</div>
			</div>
		);
	}
}

export default ProductIntstruction;
