import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './feedbackCompletion.module.sass';
import { getUserActiveBatchDetails } from 'utils/batchUtils';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import Thankyou from 'components/feedback/thankyou';
import ItemStatus from 'components/feedback/itemStatus';
import NextItem from 'commonComponents/nextItem';
import ItemDetails from './../showItemDetails';
import ToolFAQ from 'commonComponents/toolFAQ';
import { getFAQsForItem } from 'containers/containerHelpers/assessmentContainerHelper.js';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class FeedbackCompletion extends Component {

    renderItemDetails = () => {
        const itemId = +this.props.match.params.itemId;
        const { faqsList, loaders, items } = this.props;
        return (<div styleName="item-details-holder">
            <ItemDetails {...this.props} showDetails={true} />
            <ToolFAQ
                faqsList={getFAQsForItem(faqsList, itemId, items)}
                isFromFeedbackScreen={true}
                loaders={loaders}
            />
        </div>)
    }

    renderThankyouMessage = () => {
        const { isSideBarOpen,
            setSelectedItemId,
            nextItem,
            selectedUserItem,
            setSideBarFlag,
            selectedItem,
            activePhaseDetails,
            itemsListInActivePhase,
            skinGuide,
            location,
            setPhaseWorkflowStatus,
            itemFeedback
        } = this.props;

        const myStyles = getSkin(skinGuide);
        const isFeedbackScreen = true;

        return [
            <Thankyou />,
            <div styleName="next-item">
                <NextItem {...this.props}
                    nextItem={nextItem}
                    activePhaseDetails={activePhaseDetails}
                    setSelectedItemId={setSelectedItemId}
                    isFeedbackScreen={isFeedbackScreen}
                    setUserState={this.props.setUserState}
                    setPhaseWorkflowStatus={setPhaseWorkflowStatus}
                    toggleDialog={this.props.toggleDialog}
                    isFromFeedbackCompletion={true}
                />
            </div>
        ];
    }

    render() {
        const { isSideBarOpen,
            skinGuide
        } = this.props;

        const userActiveBatchDetails = getUserActiveBatchDetails(this.props.batches);

        const { phases, items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails);
        const userItemsList = items.get('userItemsList');

        let styleSideBar = 'side-bar-collapsed';
        let assessmentStyle = 'assesment-items-collapsed';
        if (isSideBarOpen) {
            styleSideBar = 'side-bar-expanded';
            assessmentStyle = 'assesment-items-expanded';
        }


        return (
            <div styleName="feedback-row">
                <div styleName="feedback-col">
                    <div styleName="feedback-box-container">
                        <ItemStatus {...this.props} />
                        {
                            (this.props.isShowThankyouClicked)
                                ?
                                this.renderThankyouMessage()
                                :
                                this.renderItemDetails()
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default FeedbackCompletion;
