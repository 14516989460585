import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		sectionText: {
			...myTypography.h5,
			fontSize: '24px',
			fontWeight: 'normal',
			color: skinHex.get('white')
		},
		sectionHeader: {
			backgroundImage: `linear-gradient(to top, #3d486f, ${skinHex.get('greyColor2')})`
		}
	});
}

export default getSkin;