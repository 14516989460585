import Immutable from 'immutable';

const toasts = Immutable.fromJS({
	TYPE_SUCCESS: 'SUCCESS',
	TYPE_WARNING: 'WARNING',
	TYPE_NOTIFICATION: 'NOTIFICATION',
	TYPE_ERROR: 'ERROR',

	AUTO_CLOSE_TIME: 7000,
	AUTO_CLOSE_TIME_SHORT: 2000,

	ID_SUCCESS: 'success_toast',
	ID_WARNING: 'warning_toast',
	ID_NOTIFICATION: 'notification_toast',
	ID_ERROR: 'error_toast',
	ID_ERROR_LABEL: 'error_toast_label',

	ID_NET_CONNECTION_LOST: 'error_internet',
	ID_NET_CONNECTION_BACK: 'success_internet',
	ID_ERROR_SET_BATCH: 'error_toast_set_batch',
	ID_ERROR_METRICS: 'error_toast_metrics',
	ID_ERROR_PHASE_LIST: 'error_toast_phase_list',
	ID_ERROR_ITEM_LIST: 'error_toast_item_list',
	ID_ERROR_USER_PHASE_LIST: 'error_toast_user_phase_list',
	ID_ERROR_USER_PHASE_DETAILS: 'error_toast_user_phase_details',

	UPLOAD_FILE_ERROR: 'error_upload_file'
});

export default toasts;