import React, { Component } from 'react';
import styles from './developmentPhaseContent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import DevelopmentPhaseItem from './developmentPhaseItem';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { convertDateWithYear } from 'utils/utilFunctions';
import PhaseItemCardLoader from 'commonComponents/loaders/phaseItemCardLoader';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DevelopmentPhaseContent extends Component {

	componentWillMount() {
		this.props.fetchDevPhaseItems({
			batchId: this.props.match.params.batchId,
			phaseId: this.props.userPhase.get('id')
		})
	}

	renderPhaseItems = (courses, myStyles) => {
		const { userPhase, match, location, userItems, activeItemId, phases, fetchDevPhaseItems, coursesModulesList } = this.props;

		
		return courses.map((course, index) => {
			const isAktivCourse = course.getIn(['phaseItemData','id']) === activeItemId;
			let hideConnector = false;
			if (index === courses.size - 1) {
				hideConnector = true
			}

			if (!course || (course && course.isEmpty())) return null;

			// eslint-disable-next-line eqeqeq
			let courseModulesDetails = coursesModulesList.find(listItem => listItem.get('id') == course.get('id'));

			const phaseItem = (<DevelopmentPhaseItem
				name={course.get("name")}
				description={course.get("description")}
				dueDate={convertDateWithYear(course.get('due_at'))}
				userItems={userItems}
				course={course}
				courseModulesDetails={courseModulesDetails}
				modulesItemsList={this.props.modulesItemsList}
				key={course.get('id')}
				courseID={course.get('id')}
				phaseID={userPhase.get('id')}
				batchID={match.params.batchId}
				hideConnector={hideConnector}
				isAktivCourse={isAktivCourse}
				location={location}
				setUserState={this.props.setUserState}
				setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
				activeItemId={activeItemId}
				phases={phases}
				setItemWorkflowStatus={this.props.setItemWorkflowStatus}
				toggleDialog={this.props.toggleDialog}
				userState={this.props.userState}
				match={match}
				userPhase={userPhase}
				fetchDevPhaseItems={fetchDevPhaseItems}
				fetchBatchDetails={this.props.fetchBatchDetails}
				fetchModulesForDashboard={this.props.fetchModulesForDashboard}
				fetchModuleItemsForSingleModuleCourse={this.props.fetchModuleItemsForSingleModuleCourse}
				feedbackStatuses={this.props.feedbackStatuses}
				user={this.props.user}
			/>)

			// if(!moduleId){
			// 	return phaseItem;
			// }

			return (
				<div key={`course_${course.get('id')}_card`}>
					{phaseItem}
				</div>
			);
		});
	}
	renderRightSide = (courses, myStyles) => {
		if (courses.get('coursesList') && courses.get('coursesList').size > 0) {
			return (
				<div styleName="right-side">
					{this.renderPhaseItems(courses.get('coursesList'), myStyles)}
				</div>
			);
		} else {
			return <div styleName="right-side">
				{this.props.getLabelWithDefaults('no_courses_assigned_label', 'No courses assigned to user')}
			</div>
		}
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const { phaseItems, apiErrors, fetchDevPhaseItems, match, userPhase } = this.props;
		const phaseId = userPhase.get('id');

		if (phaseItems && phaseItems.size === 0) return <div>No items</div>;

		return (
			<APIErrorBoundary
				key={phaseId}
				apiError={apiErrors.get('fetchCoursesError')}
				retryHandler={() => fetchDevPhaseItems({
					batchId: match.params.batchId,
					phaseId: phaseId
				})}
			>
				<div styleName="single-phase-container">
					{
						phaseItems && phaseItems.get('isCoursesListAvailable') ?
							this.renderRightSide(phaseItems, myStyles) :
							<div>
								<PhaseItemCardLoader />
								<PhaseItemCardLoader />
								<PhaseItemCardLoader />
							</div>
					}
				</div>
			</APIErrorBoundary>
		);
	}
}

export default DevelopmentPhaseContent;
