import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import QUIZ_SCORE_BG from 'images/development/quiz_score_bg.png';

const getSkin = (theme) => {
    const skinHex = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
		quizScoreCnt:{
			backgroundImage: `url(${QUIZ_SCORE_BG})`,
			backgroundSize: 'cover'
		},
		bestScoreCnt:{
			borderRight: `solid 1px ${hexToRgbA('#d5d8e2', 0.3)}`
		},
		bestScoreLabel:{
			...myTypography.labelSmall,
			backgroundColor: skinHex.get('secondaryColor'),
			color: skinHex.get('white'),
			fontWeight: 600,
			lineHeight: 'normal'
		},
		incompleteSubmissionScoreCnt:{
			borderBottom: `solid 1px #d5d8e2`,
			color: 'rgba(9, 16, 39, 0.85)',
			fontSize: '21px',
			lineHeight: '48px'
		},
		attemptNumberLabel: {
			...myTypography.button,
			color: skinHex.get('white'),
			lineHeight: 'normal',
			marginBottom: '5px'
		},
		resultsLabel: {
			...myTypography.overline,
			lineHeight: 1.43,
			fontWeight: 600,
			color: `rgba(9, 16, 39, 0.595)`
		}
    });
}

export default getSkin;