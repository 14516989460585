import React, { Component } from 'react';
import styles from './error.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Error extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="main-component" onMouseEnter={this.setHoverTrue} onMouseLeave={this.setHoverFalse}>
				<svg width="100%" height="100%" viewBox="0 0 20 20">
					<path fill={skinHex.get('scarlet')} fillRule="nonzero" d="M10.931 9.986l2.395-2.394a.8.8 0 0 0-1.132-1.132L9.8 8.855 7.406 6.46a.8.8 0 0 0-1.132 1.132L8.67 9.986 6.274 12.38a.8.8 0 1 0 1.132 1.132L9.8 11.117l2.394 2.395a.8.8 0 0 0 1.132-1.132L10.93 9.986zM10 0c5.52.006 9.994 4.48 10 10 0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0z"/>
				</svg>
			</div>
		);
	}
}



export default Error;
