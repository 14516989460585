import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
		containerStyle: {
            backgroundColor: "#2e344b",
            width: '100%',
            boxSizing: 'border-box'
        },

        tabStyle: {
            borderBottom: 'solid 1px rgba(255,255,255,0.5)'
        },

        tabFontStyle: {
            fontSize: '22px',
            fontWeight: 600,
            color: 'rgba(255,255,255,0.5)',
        },

        tabFontStyleSelected: {
            fontSize: '22px',
            fontWeight: 600,
            color: '#ffffff',
            borderBottom: 'solid 2px #ffffff',
            cursor: 'default'
        },
        title: {
			...myTypography.h5,
			fontWeight: 600,
			color: skin.get('contentColor')
		},
		viewMoreButton: {
			...myTypography.button,
			color: skin.get('secondaryColor'),
			textTransform: 'uppercase',
			cursor: 'pointer',
			':hover': {
				textDecoration: 'underline'
			}
		},
		eachBox: {
			boxShadow: `0 2px 4px 0 ${hexToRgbA(skin.get('black'), 0.16)}`,
			backgroundImage: `linear-gradient(to bottom, ${skin.get('white')}, #fafaff)`,
			border: `solid 1px rgba(151, 151, 151, 0.16)`,
			color: skin.get('greyColor2'),
			...myTypography.caption
		}


    });
}

export default getSkin;