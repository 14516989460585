import React, { Component } from 'react';
import styles from './rightArrow.module.sass';
import CSSModules from 'react-css-modules';

@CSSModules(styles, { allowMultiple: true })
class RightArrow extends Component {

	render() {
		return (
			<svg width="100%" height="100%" viewBox="0 0 8 11">
 			   <path fill={this.props.fillColor || "#000"} fillOpacity={this.props.fillOpacity || ".8"} fillRule="nonzero" d="M1.348.25c.132 0 .264.043.375.13l5.794 4.661a.598.598 0 0 1 0 .931l-5.77 4.636A.597.597 0 0 1 1 9.678l5.19-4.172L.974 1.312A.597.597 0 0 1 1.348.249z"/>
			</svg>
		);
	}
}



export default RightArrow;
