import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import defaults from 'constants/defaults';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		errorHeading: {
            ...myTypography.h5,
            fontSize: '26px',
            fontWeight: 'normal',
			color: skin.get('greyColor3')
		},
		errorMessage: {
            ...myTypography.overline,
            fontWeight: 'normal',
            lineHeight: '1.63',
			color: skin.get('greyColor3')
		},
		retryLink: {
			...myTypography.overline,
            fontWeight: 'normal',
            lineHeight: '1.63',
            color: skin.get('darkSkyBlue')      
		}
	});
}

export default getSkin;