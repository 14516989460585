import ContentLoader from "react-content-loader"
import React, { Component } from 'react';
import applySkin from 'utils/SkinProvider';
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
class ModuleItemLoader extends Component {
  render() {
    const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));

    return (
      <ContentLoader
          height={500}
          width={750}
          speed={loadersDefaultconfig.get('speed')}
          primaryColor={loadersDefaultconfig.get('primaryColor')}
          secondaryColor={loadersDefaultconfig.get('secondaryColor')}
          {...this.props}
        >
            <rect x="100" y="22.27" rx="0" ry="0" width="429" height="35" /> 
            <rect x="100" y="67.27" rx="0" ry="0" width="457" height="20" /> 
            <rect x="100" y="153.27" rx="0" ry="0" width="344" height="17" /> 
            <rect x="100" y="194.27" rx="0" ry="0" width="604" height="23.52" /> 
            <rect x="100" y="220.27" rx="0" ry="0" width="609" height="15" /> 
            <rect x="100" y="241.27" rx="0" ry="0" width="433" height="16" /> 
            <rect x="100" y="262.27" rx="0" ry="0" width="213" height="19" /> 
            <rect x="100" y="313.27" rx="0" ry="0" width="615" height="182" /> 
            <rect x="100" y="207.27" rx="0" ry="0" width="3" height="0" /> 
            <rect x="100" y="214.27" rx="0" ry="0" width="17" height="0" /> 
            <rect x="100" y="205.27" rx="0" ry="0" width="0" height="0" /> 
            <rect x="100" y="205.27" rx="0" ry="0" width="0" height="0" /> 
            <rect x="100" y="207.27" rx="0" ry="0" width="9" height="0" /> 
            <rect x="100" y="231.27" rx="0" ry="0" width="0" height="0" /> 
            <rect x="100" y="214.27" rx="0" ry="0" width="0" height="0" /> 
            <rect x="100" y="109.27" rx="0" ry="0" width="610" height="20" /> 
            <rect x="100" y="131.27" rx="0" ry="0" width="610" height="19" />
        </ContentLoader>
    )
  }
}

export default ModuleItemLoader;