import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import NextActivityImage from 'images/dashboard/nextBackground.png'

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		userProfileContainer: {
			borderTop: `7px solid ${skinHex.get('secondaryColor')}`
		},
		headerBg: {
			background: `linear-gradient(135deg, ${hexToRgbA(skinHex.get('primaryColor'), 0.1)} , ${hexToRgbA(skinHex.get('secondaryColor'), 0.1)})`
		},
		headerBgImg: {
			backgroundImage: `url(${NextActivityImage})`,
			backgroundSize: "100%",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top",
			position: 'absolute',
			top: 0,
			left: 0,
			height: '100%',
			width: '100%'
		},
		contentText: {
			...myTypography.h4,
			color: hexToRgbA(skinHex.get('greyColor1'), 0.85)
		},
		headerText: {
			...myTypography.overline,
			color: skinHex.get('contentColor'),
			fontWeight: 600
		},
		breadcrumbsText: {
			...myTypography.caption,
			color: hexToRgbA(skinHex.get('greyColor4'), 0.7),
			cursor: 'pointer'
		},
		journeyName: {
			...myTypography.body1,
			color: '#4E535F',
			lineHeight: 1.5,
			fontWeight: 500
		}
	});
}

export default getSkin;