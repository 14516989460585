import React, { Component } from 'react';
import styles from './journeyInformationComponent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import CircularProgressBar from 'commonComponents/circularProgress';
import { checkIfPresent } from 'utils/utilFunctions';
import UpArrow from 'svgComponents/upArrow';
import DownArrow from 'svgComponents/downArrow';
import Dialog from 'commonComponents/dialog'
import { getFormattedDate } from 'utils/dates';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import InfoIcon from 'svgComponents/infoIcon';
import WebSessionInfoTooltip from 'components/journeys/journeyDashboard/webSessionInfoTooltip'
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyInformationComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showActions: false,
			showSessionTooltip: false
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.isResetBatchProgressFailed && !this.props.isResetBatchProgressFailed) {
			this.onClickCancelResetJourney();
		}
	}

	sendSegmentData = (event, options = {}) => {
		const { userActiveBatchDetails, batchDetails } = this.props;
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			batchId: userActiveBatchDetails.get('batchId'),
			journeyName: batchDetails.getIn(['journey', 'name']),
			journeyId: batchDetails.getIn(['journey', 'id']),
			...options
		}
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	toggleShowAction = () => {
		this.sendSegmentData('Show about/reset journey actions toggled', { visible: !this.state.showActions });
		this.setState({
			showActions: !this.state.showActions
		})
	}

	onClickAboutJourney = () => {
		this.sendSegmentData('About Journey clicked');

		const route = `${routes.get('BATCH')}/about`;
		const params = [{ 'BATCH_ID': this.props.batchDetails.get('id') }];

		updateRoute({ route, params });
	}

	onClickOkResetJourney = () => {
		this.sendSegmentData('Journey reset');
		this.setState({
			resetPopupMessage: "Resetting journey data...",
			resetPopupOkAction: null,
			resetPopupCancelAction: null
		}, () => {
			this.props.resetBatchProgressForUser(this.props.userActiveBatchDetails.get('batchId'), this.props.userActiveBatchDetails.get('userId'));
		})
	}

	onClickCancelResetJourney = () => {
		this.sendSegmentData('Reset Journey cancelled');
		this.setState({
			showResetConfirmationPopup: false,
			resetPopupMessage: '',
			resetPopupOkAction: null,
			resetPopupCancelAction: null
		})
	}

	onClickResetJourney = () => {
		this.sendSegmentData('Reset Journey clicked');
		this.setState({
			showResetConfirmationPopup: true,
			resetPopupMessage: this.props.getLabel('reset_journey_confirmation_message'),
			resetPopupOkAction: this.onClickOkResetJourney,
			resetPopupCancelAction: this.onClickCancelResetJourney
		})
	}

	showTooltip = () => {
		this.setState({
			showSessionTooltip: true
		});
	}

	hideTooltip = () => {
		this.setState({
			showSessionTooltip: false
		});
	}

	render() {
		const {
			userActiveBatchDetails,
			batchDetails,
			webSessionDetails,
			onClickOtherSessions,
			skinGuide,
			getLabel
		} = this.props;

		const {
			showActions,
			showResetConfirmationPopup,
			resetPopupMessage,
			resetPopupOkAction,
			resetPopupCancelAction
		} = this.state;
		const myStyles = getSkin(skinGuide);

		const percentage = userActiveBatchDetails.get('percentageCompletion') ? Math.round(userActiveBatchDetails.get('percentageCompletion') * 100) / 100 : 0;

		return (
			<div className={css(myStyles.journeyinfoContainer)} styleName="journey-info-component-cnt">
				<div styleName="journey-info-content-cnt">
				<div styleName='journey-info-content-outer-cnt'>
					<div styleName="journey-info-content-inner-cnt">
						<div className={css(myStyles.journeyName)} styleName="journey-name-cnt">
							{batchDetails.getIn(['journey', 'name'])}
						</div>
						</div>
						<div styleName="journey-progress-cnt">
							<div
								className={css(myStyles.journeyProgressLabel)}
								style={{ display: 'flex', alignItems: 'center', gap: '5px', marginRight: '5px', textWrap: 'nowrap' }}
							>
								{
									getLabel('journey_completion_label').replace(
										'COMPLETION_PERCENTAGE',
										percentage
									)
								}
								<CircularProgressBar
									percentage={checkIfPresent(userActiveBatchDetails.get('percentageCompletion')) ? userActiveBatchDetails.get('percentageCompletion') : 0}
									strokeWidth={2}
									sqSize={13}
								/>
							</div>
							{
								checkIfPresent(batchDetails.get('endTimestamp')) &&
								<div
									className={css(myStyles.journeyProgressLabel)}
									styleName="expiry-date-cnt"
								>
									{`${getLabel('expires_on_label')}: ${getFormattedDate(batchDetails.get('endTimestamp'))}`}
								</div>
							}
						</div>
					</div>
					<div styleName="journey-action-cnt">
						<div
							className={css(myStyles.secondaryButton)}
							onClick={this.onClickAboutJourney}
						>
							{this.props.getLabel('about_journey_label')}
						</div>
						{
							batchDetails.getIn(['journey', 'settings', 'isResetEnabled']) === true
							|| batchDetails.getIn(['journey', 'settings', 'isResetEnabled']) === 'true' &&
							<div
								styleName="btn-cnt"
								onClick={this.onClickResetJourney}
								className={css(myStyles.secondaryButton)}
							>
								{this.props.getLabel('reset_journey_label')}
							</div>
						}
						</div>
					{ webSessionDetails && webSessionDetails.hasUpcomingSessionToday && <div styleName='journey-upcoming-web-sessions-component'>
						<div styleName="upcoming-websession-message" className={css(myStyles.upcomingWebSessionMessage)}>
							{getLabel('journey_info_web_session_upcoming_label')}
							<div styleName='info-icon-cnt'
								onMouseEnter={this.showTooltip}
								onMouseLeave={this.hideTooltip}
							>
								<InfoIcon iconOpacity="1"/>
							</div>
						</div>
						<div styleName="upcoming-websession-title" className={css(myStyles.upcomingWebSessionTitle)}>
							{webSessionDetails.session.get('title')}
						</div>
						<div styleName="other-upcoming-websessions" className={css(myStyles.otherUpcomingSessions)} onClick={onClickOtherSessions}>
							{getLabel('journey_info_web_session_other_sessions_label')}
						</div>
						{ this.state.showSessionTooltip && <div styleName="session-tooltip-outer">
							<WebSessionInfoTooltip session={webSessionDetails.session}/>
						</div>}
					</div>}
				</div>
				{
					showResetConfirmationPopup &&
					<Dialog
						dialogMessage={resetPopupMessage}
						okAction={resetPopupOkAction}
						cancelAction={resetPopupCancelAction}
						okButtonText={this.props.getLabel('reset_journey_yes_label', 'Yes, reset')}
						cancelButtonText={this.props.getLabel('reset_journey_cancel_label', 'No')}
					/>
				}
			</div>
		)
	}
}

export default JourneyInformationComponent;