import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setOrganizationLevelUserStats } from 'actions/home/actionCreators';

class GetOrganizationLevelUserStats extends NetPackWrapper {

	constructor() {
		super("GET", true);
	}

	prepareUrl = () => urls.get('PLATFORM').get('GET_ORGANIZATION_LEVEL_USER_STATS')

	successCall = (response) => {
		this.dispatch(setOrganizationLevelUserStats(response));
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR_METRICS'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Encountered an error while fetching your overall statistics.',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}

}

export default new GetOrganizationLevelUserStats();
