import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        topicsHeader:{
			...myTypography.body1,
			fontWeight: 600,
			lineHeight: 1.56,
			color: hexToRgbA(skin.get('greyColor2'), 0.7),
			marginTop: '40px'
		},
		topicCapsule:{
			...myTypography.button,
			lineHeight: 2,
			color: `${hexToRgbA('#9a9eaf', 0.9)}`,
			fontWeight: 'normal',
			backgroundColor: skin.get('white')
		}

    });
}

export default getSkin;