/**
 * @author: Amul
 * @since: Mon Apr 08 14:52:31 GMT+0530 (IST)
 * @file: phaseCourses.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
  Handles part of the start for courses list and module screen (dev phase)
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrUpdateObjectInList, mergeListsBasedOnKey } from 'utils/objectUtils';

const initialState = Immutable.fromJS([]);
    
// "1": {
//     isCoursesListAvailable: false,
//     coursesList: null,
//     isLoadedModuleAvailable: false,
//     loadedModule: null,
//     activeCourseIdInSidebar: null,
//     coursesListProgress:[]
// };

const phaseCourses = (state = initialState, action = {}) => {
    switch (action.type) {

        case actionConsts.get('SET_COURSES_LIST'):
            const updatedCoursesInfo = {
                id: action.phaseId,
                isCoursesListAvailable: true,
                coursesList: Immutable.fromJS(action.coursesList)
            };
            return setOrUpdateObjectInList(
                state, updatedCoursesInfo, 'id', 'id'
            );      
        case actionConsts.get('SET_COURSES_PROGRESS_LIST'):
            const updatedCoursesProgressInfo = {
                id: action.phaseId,
                coursesListProgress:  Immutable.fromJS(action.coursesListProgress)
            };
            return setOrUpdateObjectInList(
                state, updatedCoursesProgressInfo, 'id', 'id'
            );
        case actionConsts.get('UNSET_JOURNEY_COURSES'):
            return initialState;
        default:
            return state;
    }
};

export default phaseCourses;