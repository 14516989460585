import { filterObjectFromArray } from 'utils/objectUtils';
import defaultHeaderConfig from './defaultHeaderConfig';

const getDefaultConfigParameters = (props) => {
	const { user, batches, fetchBatchesList, getLabel, setUiState, setUserState, loaders, setLogoutClicked, userState, resetBatchProgressForUser } = props;

	if (!user) {
		return { isDataAvailable: false };
	}

	const activeBatchId = batches.get('activeBatchId');
	const batchesList = batches.get('batchesList');
	const userBatchesList = batches.get('userBatchesList');
	const batchDetails = filterObjectFromArray(batchesList, 'id', activeBatchId);
	const journeyDetails = batchDetails.get('journey');

	if (!user.get('isUserDataAvailable')) {
		return { isDataAvailable: false };
	}

	const companyName = user.get('organizationName');
	const journeyName = journeyDetails ? getLabel(journeyDetails.get('name')) : "";
	const userName = `${user.get('firstName')} ${user.get('lastName')}`;
	const email = `${user.get('emailID')}`;
	const freshdeskInitialized = userState.get('freshdeskInitialized');
	const userId = user.get('id');
	const profilePicUrl = user.get('profilePicUrl');
	const journeySwitcherProps = {
		activeBatchId,
		batchesList,
		userBatchesList,
		fetchBatchesList,
		setUiState,
		setUserState,
		loaders,
		resetBatchProgressForUser,
		userId
	};
	const profileDropDownProps = {
		userName,
		email,
		profilePicUrl,
		setUiState,
		setLogoutClicked,
		freshdeskInitialized
	};
	const notificationProps = {
		userName
	};

	return { companyName, journeyName, userName, journeySwitcherProps, profileDropDownProps, notificationProps };
}

const getHeaderConfig = (props) => {
	//write logic to switch config

	const configParams = getDefaultConfigParameters(props);

	//default
	return defaultHeaderConfig(configParams);
}

export default getHeaderConfig;