/*
    helper for module items 
*/
import { convertDateWithYear } from 'utils/utilFunctions';
import Immutable from 'immutable';

import ContextModuleProgression from 'utils/moduleItem/contextModuleProgression';
import { getLockMessage, replaceDatePlaceholderAndFormat } from 'utils/locks';

const checkIfLocked = (moduleItem) => {
    if(moduleItem && moduleItem.get('lock_details')){
        return moduleItem.get('lock_details').get('locked');
    }
    return false;    
};

const getPhaseItemLockMessage = (phaseItem) => {
    if(phaseItem.get('isLocked') || phaseItem.get('isExpired')){
        return replaceDatePlaceholderAndFormat(true, phaseItem.get('lockExplanation'), phaseItem.get('lockDate'))
    }
    return null;
};

const getModuleItemLockDetails = (moduleItem, phaseItem = Immutable.fromJS({})) => {
    let completed = false,
        locked = false,
        expired = false,
        explanation = '';

    if(!moduleItem || !phaseItem) return {locked, completed, expired, explanation};
            
    completed = moduleItem.getIn(['completion_requirement', 'completed']);
    
    if (phaseItem.get('isLocked')) {
        locked = true;
        expired = false;
    } else if (phaseItem.get('isExpired')) {
        locked = false;
        expired = true;
    } else {
        if(moduleItem.get('lock_details')){
            locked = moduleItem.get('lock_details').get('lock_reason') == 'locked'
            || moduleItem.get('lock_details').get('lock_reason') == 'not_started'
            || moduleItem.get('lock_details').get('lock_reason') == 'prerequisites'
            || moduleItem.get('lock_details').get('lock_reason') == 'sequential_progress';

            expired = moduleItem.get('lock_details').get('lock_reason') == 'expired';
            explanation = getLockMessage(moduleItem.get('lock_details'), "short");
        }
        
    }

    const phaseItemLockMessage = getPhaseItemLockMessage(phaseItem);

    explanation = phaseItemLockMessage || explanation;

    return {locked, completed, expired, explanation};
}

const getLockExplanation = (moduleItem) => {
    if(moduleItem && moduleItem.get('lock_details')){
        return moduleItem.get('lock_details').get('lock_explanation');
    }
    return false;    
};

const getDateText = (moduleItem, getLabelWithDefaults, completedLabel, lockDetails) => {
    let dateText = null;
        
    if(lockDetails.locked){
        return lockDetails.explanation;
    } else if(lockDetails.completed){
        return completedLabel;
    } else if(lockDetails.expired) {
        return lockDetails.explanation;
    }

    if(moduleItem.get('content_details') && moduleItem.get('content_details').get('due_at')){
        dateText = `${getLabelWithDefaults('due_date', 'Due Date')}: ${convertDateWithYear(moduleItem.get('content_details').get('due_at'))}`
    }

    return dateText;
}

const isNLPAssignment = (moduleItem) => {
    const assignment = moduleItem.get('contentDetails');
    return  assignment.getIn(['al_data', 'nlp']) || false;    
}

const getAssignmentBodyFromLocalStorage = (submissionType, moduleItem) => {
    if (submissionType === "online_text_entry") {
        const assignmentId = moduleItem.get('moduleItem').get('content_id');
        const localStorageValue = window.localStorage.getItem(`assignment_${assignmentId}_text`);
            if(localStorageValue) return localStorageValue;
    }
    return null;
}

const ModuleItemUtil = {
    checkIfLocked,
    getDateText,
    getLockExplanation,
    getModuleItemLockDetails,
    isNLPAssignment,
    getAssignmentBodyFromLocalStorage
}


export default ModuleItemUtil;