import React, { Component } from 'react'
import styles from './forwardArrow.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class ForwardArrow extends Component {
	render() {
		let fillColor = this.props.fillColor || "#D44980";
		return (
			<div styleName="svg-cnt">
				<svg width="24" height="16" viewBox="0 0 24 16">
					<path fill={fillColor} fillRule="evenodd" stroke="#D44980" strokeWidth="0.7" d="M15.804 0L23.5 7.881l-7.696 7.882-1.36-1.392 5.37-5.522H1.1V6.914h18.713l-5.368-5.522z" />
				</svg>
			</div>
		)
	}
}

export default ForwardArrow;