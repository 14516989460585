import actionConsts from 'constants/actions';

const setFeedbackData = payload => ({
    type: actionConsts.get('SET_FEEDBACK_DATA'),
    payload
});

const setUserFeedbackData = payload => ({
    type: actionConsts.get('SET_USER_FEEDBACK_DATA'),
    payload
});

const setFeedbackCompletionStatus = payload => ({
    type: actionConsts.get('SET_FEEDBACK_COMPLETION_STATUS'),
    payload
});

const setUserFeedbackStatus = status => ({
    type: actionConsts.get('FETCH_USER_FEEDBACK_STATUS'),
    status
});

const setResponseToQuestion = (payload, questionId) => {
    const data = { payload, questionId };
    return {
        type: actionConsts.get('SET_FEEDBACK_QUESTION_RESPONSE'),
        data
    }
}

const setFeedbackCompletedTemp = (flag) => {
    return {
        type: actionConsts.get('SET_COMPLETION_STATUS_TEMP'),
        flag

    }
}

const resetUserData = (flag) => {
    return {
        type: actionConsts.get('RESET_USER_FEEDBACK_DATA'),
        flag
    }
}

const resetFeedbackData = () => {
    return {
        type: actionConsts.get('RESET_FEEDBACK_DATA')
    }
}

const setPhaseFeedbackStatuses = (phaseFeedbackStatus) => {
    return {
        type: actionConsts.get('SET_PHASE_FEEDBACK_STATUSES'),
        phaseFeedbackStatus
    }
}

const setItemFeedbackStatuses = (itemFeedbackStatus) => {
    return {
        type: actionConsts.get('SET_ITEM_FEEDBACK_STATUSES'),
        itemFeedbackStatus
    }
}

export {
    setFeedbackData,
    setFeedbackCompletionStatus,
    setUserFeedbackData,
    setResponseToQuestion,
    setFeedbackCompletedTemp,
    resetUserData,
    resetFeedbackData,
    setPhaseFeedbackStatuses,
    setItemFeedbackStatuses,
    setUserFeedbackStatus
};
