/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Footer.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Footer component of the application
 *
 **/

import React from 'react';
import PropTypes from 'prop-types';
import styles from './footer.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin';
import LogoDark from 'images/header/logo_dark.png';
import { css } from 'aphrodite/no-important';
import environment from 'utils/environment';
import {isMSTeams} from 'utils/msTeamsUI';
import {isLaunchedFromExternalIntegration} from 'utils/externalIntegrations';
import {
  LinkedinIcon,
  TwitterXIcon,
  YoutubeIcon,
  FBIcon,
  InstagramIcon
} from 'svgComponents/socialMediaIcons/SocialMediaIcons';
import { isMobile, isTablet } from 'react-device-detect';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class Footer extends React.Component {


	render() {
		const myStyles = getSkin(this.props.skinGuide);

		if(isMSTeams() || isLaunchedFromExternalIntegration()){
			return <span></span>;
		}
		return (
			<div className={`${css(myStyles.footerContainer)} footer-cnt`}styleName="footer-container">
				<div styleName='footer-container-inner'>
					<div styleName='copyright-and-logo'>
						<div className={css(myStyles.footerImage)} styleName="footer-image">
							<img src={environment.REACT_APP_LOGO_URL_DARK || LogoDark} alt="Logo" height="20px" styleName="image" />
						</div>
						<div className={css(myStyles.footerContent)} styleName="footer-content">
								| <span styleName='tagline'>&nbsp;To experience is to learn |</span> < a className={css(myStyles.footerContent)} target="_blank" styleName="footer-content knolskape-link" href="http://knolskape.com/" rel="noreferrer" >KNOLSKAPE</a>© {(new Date()).getFullYear()}
						</div>
					</div>
					{(!isMobile && !isTablet) && <div styleName='social-media-links'>
						<a className={css(myStyles.socialIconContainer)} target='_blank' href='https://www.linkedin.com/company/knolskape/' rel='noreferrer'>
							<LinkedinIcon/>
						</a>
						<a className={css(myStyles.socialIconContainer)} target='_blank' href='https://x.com/knolskape' rel='noreferrer'>
							<TwitterXIcon/>
						</a>
						<a className={css(myStyles.socialIconContainer)} target='_blank' href='https://www.youtube.com/@KNOLSKAPE' rel='noreferrer'>
							<YoutubeIcon/>
						</a>
						<a className={css(myStyles.socialIconContainer)} target='_blank' href='https://www.facebook.com/KNOLSKAPE/' rel='noreferrer'>
							<FBIcon/>
						</a>
						<a className={css(myStyles.socialIconContainer)} target='_blank' href='https://www.instagram.com/knolskape/?hl=en' rel='noreferrer'>
							<InstagramIcon/>
						</a>
					</div>}
				</div>
			</div>
		);
	}
}

export default Footer;
