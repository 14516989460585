import React, { Component } from 'react';
import styles from './starComponent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class StarComponent extends Component {

	render() {
		let strokeWidth = 1.5;
		if (this.props.strokeWidth) {
			strokeWidth = this.props.strokeWidth;
		}

		let strokeColor = "#FFCA56";
		if (this.props.strokeColor) {
			strokeColor = this.props.strokeColor;
		}

		let starWidthPerc = 24;
		if (this.props.starWidthPerc !== null) {
			starWidthPerc = this.props.starWidthPerc * 0.24;
			starWidthPerc = Math.round(starWidthPerc);
		}

		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="star-component">
				<svg viewBox="0 0 24 24" width="100%" height="100%">
					<defs>
						<clipPath id={`left-to-right-${this.props.id}`}>
							<rect x="0" y="0" width={starWidthPerc} height="24" >
								{
									!this.props.doNotAnimate &&
									<animate attributeName="width" values={`0;${starWidthPerc}`} dur="3s" fill="freeze" />
								}
							</rect>
						</clipPath>
					</defs>
					<path
						clipPath={`url(#left-to-right-${this.props.id})`}
						fill={this.props.fill || "#FFCA56"}
						fillRule="evenodd"
						stroke={strokeColor}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={strokeWidth}
						d="M12.445 1.426l3.197 6.334 6.154.61a.77.77 0 0 1 .478 1.313l-5.065 5.02 1.878 6.82a.777.777 0 0 1-1.1.894l-6.239-3.09-6.23 3.086a.777.777 0 0 1-1.1-.894l1.878-6.82-5.068-5.02a.77.77 0 0 1 .478-1.313l6.153-.61 3.193-6.33a.783.783 0 0 1 1.393 0z"
					/>
					<path
						fill="transparent"
						fillRule="evenodd"
						stroke={strokeColor}
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth={strokeWidth}
						d="M12.445 1.426l3.197 6.334 6.154.61a.77.77 0 0 1 .478 1.313l-5.065 5.02 1.878 6.82a.777.777 0 0 1-1.1.894l-6.239-3.09-6.23 3.086a.777.777 0 0 1-1.1-.894l1.878-6.82-5.068-5.02a.77.77 0 0 1 .478-1.313l6.153-.61 3.193-6.33a.783.783 0 0 1 1.393 0z"
					/>
				</svg>
			</div>
		);
	}
}



export default StarComponent;
