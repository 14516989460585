import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import Close from 'images/icons/close.svg';
import Shrink from 'images/icons/shrink.svg';
import Menu from 'images/icons/menu.svg';


const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        closeIcon: {
            backgroundImage: `url(${Close})`,
			height: '16px',
            width: '16px',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
        },
        shrinkIcon: {
            backgroundImage: `url(${Shrink})`,
			height: '18px',
            width: '11.3px',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
        },
        menuIcon: {
            backgroundImage: `url(${Menu})`,
			height: '16px',
            width: '19px',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
        },
        text:{
            ...myTypography.overline,
            fontWeight: 600,
            color: skin.get('greyColor3')
        },
        container: {
            borderBottom: `1px solid ${hexToRgbA(skin.get("greyColor4"), 0.31)}`
        }
    });
}

export default getSkin;