import React, { Component } from 'react';
import styles from './linkInfo.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LinkInfo extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false
		}
	}

	openTooltip = () => {
		if (this.props.tooltipText && this.props.tooltipText != '') {
			this.setState({
				showTooltip: true
			});
		}
	}

	closeTooltip = () => {
		this.setState({
			showTooltip: false
		});
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="info-container">
				<div
					className={css(myStyles.infoIcon)}
					styleName={this.state.showTooltip ? 'info-icon help-cursor' : 'info-icon'}
					onMouseEnter={() => {this.openTooltip()}}
					onMouseLeave={() => {this.closeTooltip()}}
				>
					{this.props.linkName}
				</div>
				{
					(this.state.showTooltip)
					?	<div styleName="tooltip-container">
							<div className={css(myStyles.tooltipArrow)} styleName="tooltip-arrow"></div>
							<div className={css(myStyles.tooltipContent)} styleName="tooltip-content">{this.props.tooltipText}</div>
						</div>
					:	null
				}
			</div>
		);
	}
}

export default LinkInfo;
