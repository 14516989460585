import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { updateSubmissionAttempt } from 'actions/quiz/actionCreators'

class MarkQuizAttemptSeen extends NetPackWrapper {
	constructor() {
		super('PUT', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('QUIZ_ATTEMPT_SEEN')
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_COURSE_ID_', apiInfoObject.courseId)
			.replace('_QUIZ_ID_', apiInfoObject.quizId)
			.replace('_QUIZ_SUBMISSION_ID_', apiInfoObject.submissionId)
			.replace('_QUIZ_SUBMISSION_ATTEMPT_', apiInfoObject.quizSubmissionAttempt)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
	};

	successCall = (response, apiInfoObject) => {
		this.dispatch(updateSubmissionAttempt(apiInfoObject));
		// console.log(response);
	}
}

export default new MarkQuizAttemptSeen();