import React, { Component } from 'react';
import styles from './scormCourseCard.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { textTruncate, checkIfPresent } from 'utils/utilFunctions';
import enumConstants from 'constants/enums';
import LockIcon from 'svgComponents/lockIcon';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ScormCourseCard extends Component {

	getItemStatusLabelAndStyles = (phaseItem) => {
		const myStyles = getSkin(this.props.skinGuide);
		const { userItem } = this.props;
		const statusObj = userItem;

		if (phaseItem.get('isLocked')) {
			return null
		} else if (
			statusObj.workflowStatus === enumConstants.get('COMPLETED')
			|| this.props.percentageCompletion === 100
		) {
			return {
				label: this.props.getLabel('completed_label'),
				styles: css(myStyles.completedStyes)
			};
		} else if (phaseItem.get('isExpired')) {
			return {
				label: this.props.getLabel('expired_label'),
				styles: css(myStyles.expiredStyles)
			}
		} else if (
			statusObj.workflowStatus === enumConstants.get('UNOPENED')
			|| statusObj.workflowStatus === enumConstants.get('OPENED')
			|| statusObj.workflowStatus === enumConstants.get('NOT_STARTED')
		) {
			return null
		} else if (
			statusObj.workflowStatus === enumConstants.get('STARTED')
		) {
			return {
				label: this.props.getLabel('in_progress_label'),
				styles: css(myStyles.inProgressStyles)
			}
		} else {
			return null
		}
	}

	renderLock = () => {
		const myStyles = getSkin(this.props.skinGuide);
		let { isCourseLocked } = this.props;

		if (isCourseLocked) {
			return <div className={css(myStyles.lockedDiv)} styleName="locked-div" style={{ 'top': 0 }}>
				<div styleName="locked-icon"><LockIcon /></div>
			</div>;
		}

		return null;
	};

	goToCourseFeedback = (e) => {
		e.stopPropagation();
		const { batchId, phaseId, phaseItemData, course } = this.props;
		const route = routes.get('SCORM_COURSE_FEEDBACK');
		const params = [
			{ 'BATCH_ID': batchId },
			{ 'COURSE_ID': course.get('id') },
			{ '_PHASE_ID_': phaseId },
			{ '_PHASEITEMID_': phaseItemData.get('id') }
		];

		const routeObj = { route, params };
		// this.props.setPhaseWorkflowStatus(batchId, phaseId, enumConstants.get('COMPLETED'));
		updateRoute(routeObj)
	}

	getImageDiv = (url) => {
        return <div styleName="image-container">
            <img src={url} width="100%" height="100%" alt="Course Thumbnail"/>
        </div>
    }

	getImageThumbnail = () => {
        const { course, phaseItemData } = this.props;
        const imageIcon = phaseItemData.get('icon');
        const courseImageUrl = course.get('cover_image_url');
        
        if(imageIcon && imageIcon!==""){
            return this.getImageDiv(imageIcon);
        }

        if(courseImageUrl && courseImageUrl!==""){
            return this.getImageDiv(courseImageUrl);
        }

        return null;
	}
	
	shouldShowCourseFeedback = () => {
		const {course, isCourseLocked, isCourseExpired, isCourseCompleted} = this.props;

		if(isCourseLocked){
			return false;
		}

		const enabledInSettings = course.getIn(['phaseItemData', 'settings', 'feedbackScenarioId']) && course.getIn(['phaseItemData', 'settings', 'isFeedbackEnabled']) === 'true';

		if(enabledInSettings){
			if (isCourseExpired && !isCourseCompleted){
				return false;
			}
		}

		return enabledInSettings;
	}


	render() {
		const {
			course,
			itemDateLabel,
			isCourseCompleted,
			isAktivCourse,
			getClassNameForTitle,
			phaseItemData,
			timeLineCntStyles,
			courseOuterCircleStyles,
			courseInnerCircleStyles,
			heightStyles,
			hideConnector,
			getLabelWithDefaults,
			goToCourse
		} = this.props;

		const myStyles = getSkin(this.props.skinGuide);

		let itemStatus = this.getItemStatusLabelAndStyles(phaseItemData);

		return (
			<div styleName="individual-phase-item-cnt" onClick={goToCourse}>
				<div styleName="module-sub-cnt header">
					<div
						className={css(myStyles.lockMsg)}
						styleName="module-index-cnt"
					>
						{
							itemDateLabel
						}
					</div>
					{
						itemStatus &&
						<div styleName="completion-status-cnt">
							<div
								className={itemStatus.styles}
								styleName="completion-status">
								{itemStatus.label}
							</div>
						</div>
					}
				</div>

				<div styleName="module-sub-cnt content">
					<div
						styleName="content-container"
						className={css(myStyles.moduleContentCnt)}
					>
						<div
							className={getClassNameForTitle(
								myStyles,
								isCourseCompleted,
								isAktivCourse,
								phaseItemData
							)}
						>
							{phaseItemData.get('name')}
						</div>
						{
							checkIfPresent(course.get('public_description')) &&
							<div
								className={css(myStyles.desc)}
								styleName="desc"
							>
								{textTruncate(this.props.getLabel(phaseItemData.get('description')), 250)}
							</div>
						}
					</div>
					{this.getImageThumbnail()}

				</div>

				{
					this.shouldShowCourseFeedback() &&
					<div styleName="module-sub-cnt feedback" onClick={(e) => this.goToCourseFeedback(e)}>
						<div styleName="course-feedback-line">
							<div styleName="feedback-icon-cnt">
								<FeedbackIcon />
							</div>
							<div className={css(myStyles.likeToHearLabel)}>
								{
									getLabelWithDefaults(
										'like_to_hear_from_you_label',
										'We’d like to hear from you'
									)
								}
							</div>
						</div>
					</div>
				}

				<div styleName="dev-phase-timeline-cnt" style={timeLineCntStyles}>
					<div styleName="dev-phase-timeline-outer-circle" onClick={this.goToCourse} style={courseOuterCircleStyles}>
						<div styleName="dev-phase-timeline-inner-circle" style={courseInnerCircleStyles}>
						</div>
					</div>
					{
						!hideConnector &&
						<div styleName="dev-phase-timeline-connector" style={heightStyles}>
						</div>
					}
				</div>

				{
					checkIfPresent(course.getIn(['mobile_launch_data', 'max_score'])) ?
						<div
							className={css(myStyles.scoreCnt)}
							styleName="score-cnt"
							dangerouslySetInnerHTML={{ __html: `${getLabelWithDefaults('score_label', 'Score')}: &nbsp; <b>${course.get('assignment_score')}</b>/${course.getIn(['mobile_launch_data', 'max_score'])}` }}
						/>
						: null
				}
			</div>
		);
	}
}

export default ScormCourseCard;