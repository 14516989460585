// import analyticsUrls from "../constants/urls/analyticsUrls";
import environment from 'utils/environment';

const getDomainUrl = () => {
    let domain = window.location.hostname;
    let port = window.location.port;
    let protocol = window.location.protocol;
    if (port) {
        port = ":" + port;
    }
    const url = protocol + "//" + domain + port;

    return url;
}

const getApiUrl = () => {
    return {
        journeyUrl: environment.REACT_APP_API_URL,
        organizationBaseUrl: environment.REACT_APP_ORGANIZATION_API_URL,
        toolsUrl: environment.REACT_APP_TOOLS_URL,
        measuresUrl: environment.REACT_APP_API_URL,
        analyticsUrl: environment.REACT_APP_ANALYTICS_GATEWAY_URL,
        feedbackUrl: environment.REACT_APP_FEEDBACK_URL,
        reportUrl: environment.REACT_APP_REPORT_API_URL,
        analyticsExternalUrl: environment.REACT_APP_ANALYTICS_EXTERNAL_URL,
        journeyExternalUrl: environment.REACT_APP_JOURNEY_EXTERNAL_URL,
        notificationExternalUrl: environment.REACT_APP_NOTIFICATIONS_EXTERNAL_URL,
        notificationBaseUrl: environment.REACT_APP_NOTIFICATION_BASE_URL,
        konsoleUrl: environment.REACT_APP_KONSOLE_URL,
        attachmentsUrl: environment.REACT_APP_ATTACHMENTS_URL,
        pageUrl: environment.REACT_APP_PAGE_URL
    };
}

const getDevPhaseAPIUrl = () => {
    let apiURL = `${window.location.origin}/dapi`;

    if (environment.REACT_APP_DEV_ENV === 'local') {
        //apiURL =  'https://staging.aktivlearn.com/api/v1';
        apiURL = environment.REACT_APP_DAPI_URL;
    }

    return apiURL;
}

const replaceWithDevPhaseAPIUrl = (url) => {
    let apiURL = getDevPhaseAPIUrl();
    let orgUrlSplit = url.split("dapi");
    if (environment.REACT_APP_DEV_ENV === 'local') {
        return `${apiURL}${orgUrlSplit[1]}`;
    } else { // remove this code once production is updated with backend url change
        if (url.indexOf('api/v1') != -1) {
            orgUrlSplit = url.split("v1");
            return `${apiURL}${orgUrlSplit[1]}`;
        }

        if (url.indexOf('dapi') != -1) {
            orgUrlSplit = url.split("dapi");
            return `${apiURL}${orgUrlSplit[1]}`;
        }

    }
    return url;
}

const getUrlForFiles = (url) => {
    let orgUrlSplit = url.split('/files/');
    return `${environment.REACT_APP_DEVELOPMENT_SERVICE_URL}/files/${orgUrlSplit[1]}`;
};

export {
    getDomainUrl,
    getApiUrl,
    getDevPhaseAPIUrl,
    replaceWithDevPhaseAPIUrl,
    getUrlForFiles
}