import React, { Component } from 'react';
import styles from './notification.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Notification extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="main-component" onMouseEnter={this.setHoverTrue} onMouseLeave={this.setHoverFalse}>
				<svg width="100%" height="100%" viewBox="0 0 20 20">
					<path fill={skinHex.get('darkSkyBlue')} fillRule="nonzero" d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.994 4.48 15.52.006 10 0zm.208 4.167a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5zm1.875 11.25H8.75a.833.833 0 0 1 0-1.667h.625a.208.208 0 0 0 .208-.208v-3.75a.208.208 0 0 0-.208-.209H8.75a.833.833 0 1 1 0-1.666h.833c.92 0 1.667.746 1.667 1.666v3.959c0 .115.093.208.208.208h.625a.833.833 0 1 1 0 1.667z"/>
				</svg>
			</div>
		);
	}
}



export default Notification;
