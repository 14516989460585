import * as microsoftTeams from "@microsoft/teams-js";

function addParameterToURL(_url, param){
    _url += (_url.split('?')[1] ? '&':'?') + param;
    return _url;
}

export const initializeMSTeams = () => {
    console.log('msteams: initializing');
    microsoftTeams.initialize();
}

const setInfoInLocalStorage = () => {
    window.localStorage.setItem("isMSTeams", true);
    const urlParams = new URLSearchParams(window.location.search);
    window.localStorage.setItem("MSTeamsOrgId", urlParams.get('orgId'));
}

export const getContext = () => {
    console.log('msteams: getting context');
    setInfoInLocalStorage();
    let isThereAnyRedirection = false;
    const urlParams = new URLSearchParams(window.location.search);
    let defaultRedirectUrl = addParameterToURL(window.location.origin, "referrer=msteams");

    if(urlParams.get('orgId')){
        defaultRedirectUrl = addParameterToURL(defaultRedirectUrl, `orgId=${urlParams.get('orgId')}`);
    }

    try {
        console.log('msteams: just before getContext callback');
        const start = new Date().getTime();

        microsoftTeams.getContext(function (context) {
            const end = new Date().getTime();
            console.log('msteams: context:', JSON.stringify(context));
            if(context.subEntityId && context.subEntityId != "") {
                console.log('msteams: deeplink navigating:', context.subEntityId.type, context.subEntityId.path);

                if(context.subEntityId.type == "relative") {
                    let redirectUrl = window.location.origin + context.subEntityId.path;
                    redirectUrl = addParameterToURL(redirectUrl, "referrer=msteams");

                    if(urlParams.get('orgId')){
                        redirectUrl = addParameterToURL(redirectUrl, `orgId=${urlParams.get('orgId')}`);
                    }
                    
                    console.log('msteams: redirecting to (relative): ', redirectUrl);
                    window.location.href = redirectUrl;
                    isThereAnyRedirection = true;
                    // updateRoute({route: context.subEntityId.path});
                }

                if(context.subEntityId.type == "absolute") {
                    let finalUrl = addParameterToURL(context.subEntityId.path, "referrer=msteams");
                    if(urlParams.get('orgId')){
                        finalUrl = addParameterToURL(finalUrl, `orgId=${urlParams.get('orgId')}`);
                    }
                    console.log('msteams: redirecting to: ', finalUrl);
                    window.location = finalUrl;                    
                    isThereAnyRedirection = true;
                }

            }

            if(!isThereAnyRedirection) {
                console.log('redirecting to (no subentity in context): ', defaultRedirectUrl);
                window.location.href = defaultRedirectUrl;
            }
        });
    } catch (e) {
        console.log('msteam error');
        console.log(e);
    }

}

