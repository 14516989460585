import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		title: {
			...myTypography.h5,
			fontWeight: 600,
			color: skin.get('contentColor')
		},
		phaseContentContainer: {
			backgroundColor: skin.get('greyColor6'),
			boxShadow: `inset 0 1px 0 0 ${skin.get('contentBoxShadow')}`
		},
		individualTabSelected: {
			// borderRadius: '4px',
			// boxShadow: `inset 0 1px 0 0 ${skin.get('contentBoxShadow')}`,
			// backgroundColor: skin.get('greyColor6'),
			borderBottom: `solid 4px ${skin.get('secondaryColor')}`,
			cursor: 'unset'
		},
		individualTabNotSelected: {
			opacity: 0.5,
			cursor: 'pointer'
		},
		tabTitle: {
			...myTypography.h5,
			color: skin.get('greyColor1')
		},
		singleTabTitle: {
			...myTypography.h5,
			fontWeight: 600,
			opacity: 1,
			cursor: 'default',
			color: skin.get('contentColor')
		},
		phaseLockMessage: {
			...myTypography.overline,
			color: skin.get('greyColor3')
		}
	});
}

export default getSkin;