import React, { Component } from 'react';
import styles from './activityHeader.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import BackArrow from 'svgComponents/backArrow';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import { goBackOnePage } from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ActivityHeader extends Component {

    onClickOfBackButton = () => {
        goBackOnePage()
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div className={css(myStyles.productTitleContainer)} styleName={'product-title-container'}>
                <div className={css(myStyles.productTitleImage)} styleName="product-title-image" ></div>
                <div styleName="content">
                    <div styleName="title-action">
                        <div styleName="icon" onClick={this.onClickOfBackButton}>
                            <BackArrow color={'black'} />
                        </div>
                        <div className={css(myStyles.title)} styleName="title">
                            {'Your ' + this.props.title}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ActivityHeader;
