import Immutable from 'immutable';

const filterObjectFromArray = (list, key, id) => {
    const filteredList = list.filter((element) => element.get(key) === id);

    if (filteredList.size === 0) {
        return Immutable.fromJS({});
    }

    return filteredList.get(0);
}

const isEmptyObject = (obj = {}) => {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const getIndexOfValueInList = (list, key, value) => {
    return list.findIndex(
        listItem => listItem.get(key) === value
    );
}

const setOrUpdateObjectInList = (list, object, listKey, objectKey) => {    
    const toBeUpdatedListItemIndex = getIndexOfValueInList(list, listKey, object[objectKey]);

    if (toBeUpdatedListItemIndex === -1) {
        return list.push(Immutable.fromJS(object));
    }

    return list.update(
        toBeUpdatedListItemIndex,
        (oldListItem) => oldListItem.mergeDeep(Immutable.fromJS(object))
    );
}

const setOrReplaceObjectInList = (list, object, listKey, objectKey) => {    
    const toBeUpdatedListItemIndex = getIndexOfValueInList(list, listKey, object[objectKey]);

    if (toBeUpdatedListItemIndex === -1) {
        return list.push(Immutable.fromJS(object));
    }

    return list.set(toBeUpdatedListItemIndex,(Immutable.fromJS(object)));
}

const setOrUpdateObjectInMap = (inputMap, key, object) => {
    if (inputMap.has(key)) {
        return inputMap.set(
            key,
            inputMap.get(key).merge(Immutable.fromJS(object))
        );
    }
    return inputMap.set(key, Immutable.fromJS(object));
}

const mergeListsBasedOnKey = (oldList, newArray, key) => {
    let updatedList = oldList;

    newArray.map((arrayItem) => {
        updatedList = setOrUpdateObjectInList(updatedList, arrayItem, key, key)
    });
    
    return updatedList;
}

const extend = (defaultObject, overrideObject) => {
    for (var key in overrideObject) {
        if (defaultObject.hasOwnProperty(key) && ((overrideObject[key] != ''))) {
            defaultObject[key] = overrideObject[key];
        }
    }
    return defaultObject;
}

const removeObjectFromList = (list, key, value) => {
    const toBeDeletedListItemIndex = getIndexOfValueInList(list, key, value);

    if (toBeDeletedListItemIndex === -1) {
        return list;
    }

    return list.delete(toBeDeletedListItemIndex);
}

const searchObjectsInArray = (inputArray, key, value) => {
    return inputArray.filter(
        object => object[key] && object[key].toLowerCase().includes(value.toLowerCase())
    );
}

const sortArrayOfObjects = (inputArray, key, type = "INCREMENT") => {
    return inputArray.sort((currentObj, nextObj) => {
        switch (type) {
            case "DECREMENT":
                if (currentObj[key] === "NA" && nextObj[key] !== "NA") {
                    return -1;
                }
                if (nextObj[key] === "NA") {
                    return 1;
                }
                return (nextObj[key] - currentObj[key]);
            default:
                if (currentObj[key] === "NA" && nextObj[key] !== "NA") {
                    return 1;
                }
                if (nextObj[key] === "NA") {
                    return -1;
                }
                return (currentObj[key] - nextObj[key]);
        }
    });
}

export {
    filterObjectFromArray,
	isEmptyObject,
    setOrUpdateObjectInList,
    setOrUpdateObjectInMap,
    mergeListsBasedOnKey,
    setOrReplaceObjectInList,
    removeObjectFromList,
    extend,
    getIndexOfValueInList,
    searchObjectsInArray,
    sortArrayOfObjects
};