import React, { Component } from 'react';
import styles from './downloadIcon.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DownloadIcon extends Component {
	render() {
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 18 18">
					<g fill={this.props.fillColor || "#B6BBCD"} fillRule="nonzero">
						<path d="M6.462 1.306c0-.602.488-1.09 1.09-1.09h2.545c.601 0 1.089.488 1.09 1.09v6.17c0 .104.083.188.187.188h2.175a.854.854 0 0 1 .62 1.5L9.594 13.74a1.117 1.117 0 0 1-1.542 0L3.477 9.164a.853.853 0 0 1 .62-1.5h2.175a.187.187 0 0 0 .188-.187l.002-6.171z"/>
						<path d="M0 14.758a3.03 3.03 0 0 0 3.026 3.026h11.948A3.03 3.03 0 0 0 18 14.758V13.35a.919.919 0 0 0-1.837 0v1.405c0 .657-.533 1.19-1.19 1.19H3.027a1.191 1.191 0 0 1-1.19-1.19V13.35a.919.919 0 0 0-1.836 0v1.408z"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default DownloadIcon;