import React, { Component } from 'react';
import styles from './starRating.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import RatingStar from 'svgComponents/ratingStar';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class StarRating extends Component {

	updateRating = (score) => {
		const { feedbackItemId } = this.props;
		const payload = {
			value: score,
		};
		const questionId = this.props.question.get('id');
		this.props.submitAnswer(payload, questionId, feedbackItemId);
	}

	renderStars = (maxRating) => {
		const ratingValue = this.props.question.get('value');
		const { skinGuide } = this.props;
		let starComponents = [];
		for (let i = 1; i <= maxRating; i++) {
			starComponents.push(
				<div key={`rating-star-${i}`} styleName="individual-star" onClick={() => this.updateRating(i)}>
					<RatingStar
						id={i}
						color={
							ratingValue >= i ?
								skinGuide.getIn(['skinHex', 'primaryColor']) || '#2c4bb7'
								: skinGuide.getIn(['skinHex', 'white']) || 'white'
						}
						strokeColor={
							ratingValue >= i ?
								skinGuide.getIn(['skinHex', 'primaryColor']) || '#2c4bb7'
								: null
						}
					/>
				</div>
			)
		}
		return starComponents;
	}

	render() {
		const { question } = this.props;
		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div styleName="star-rating-question-cnt">
				<div className={css(myStyles.questionText)} styleName="question-text">
					{question.get('text')}
				</div>
				<div styleName="rating-stars-cnt">
					{this.renderStars(question.get('max'))}
				</div>
			</div>
		);
	}
}

export default StarRating;
