import React, { Component } from 'react';
import styles from './moduleCard.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { textTruncate, getDurationString } from 'utils/utilFunctions';
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import enumConstants from 'constants/enums';
import { filterObjectFromArray } from 'utils/objectUtils';
import LockIcon from 'svgComponents/lockIcon';
import { getLockMessage, replaceDatePlaceholderAndFormat } from 'utils/locks';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ModuleCard extends Component {

    constructor(props) {
        super(props);
        this.isModuleCompleted = false;
        this.isModuleLocked = false;
        this.isModuleExpired = false;
        this.inaccessibleMessage = '';
        this.setLockValues(props);
    }

    setLockValues = (props) => {
        let { onlyPhaseItem, module } = props;
        let phaseItemLockMessage = null;

        if (onlyPhaseItem) {
            phaseItemLockMessage = this.getPhaseItemLockMessage(onlyPhaseItem);
            if (onlyPhaseItem.get('isLocked')) {
                this.isModuleLocked = true;
                this.isModuleExpired = false;
            } else if (onlyPhaseItem.get('isExpired')) {
                if (module && module.get('items_completed_count') === module.get('required_items_count'))

                    this.isModuleLocked = false;
                this.isModuleExpired = true;
            } else {
                this.isModuleLocked = module.get('lock_details').get('lock_reason') === 'locked'
                    || module.get('lock_details').get('lock_reason') === 'not_started'
                    || module.get('lock_details').get('lock_reason') === 'prerequisites';

                this.isModuleExpired = module.get('lock_details').get('lock_reason') === 'expired' || onlyPhaseItem.get('isExpired');
            }

            this.isModuleCompleted = this.checkIfModuleCompleted();

            if (this.isModuleLocked) {
                this.inaccessibleMessage = phaseItemLockMessage || getLockMessage(module.get('lock_details'), "short");
            } else if (this.isModuleCompleted) {
                this.inaccessibleMessage = null;
            } else if (this.isModuleExpired) {
                this.inaccessibleMessage = phaseItemLockMessage || getLockMessage(module.get('lock_details'), "short");
            }
        }
    }




    getPhaseItemLockMessage = (phaseItem) => {
        const lockExplanation = replaceDatePlaceholderAndFormat(
            phaseItem.get('isExpired') || phaseItem.get('isLocked'),
            phaseItem.get('lockExplanation'),
            phaseItem.get('lockDate')
        );
        const styles = getSkin(this.props.skinGuide);

        if (lockExplanation) {
            if (phaseItem.get('isExpired')) {
                return <span className={css(styles.expired)}> {lockExplanation} </span>;
            }
            return lockExplanation;
        }
        return null;
    };

    componentWillReceiveProps(nextProps) {
        this.setLockValues(nextProps);
    }

    getClassNameForTitle = (myStyles, isCourseCompleted, isActiveModule, phaseItemData) => {
        if (this.isModuleLocked)
            return css(myStyles.title, myStyles.locked);
        else if (this.isModuleCompleted)
            return css(myStyles.title, myStyles.completed);
        else if (this.isModuleExpired)
            return css(myStyles.title, myStyles.locked);
        else if (isActiveModule)
            return css(myStyles.title, myStyles.aktiv);
        else
            return css(myStyles.title, myStyles.pending);
    }

    getClassNameForProgressBar = (myStyles, isCourseCompleted, isActiveModule, phaseItemData) => {
        if (this.isModuleLocked)
            return css(myStyles.title, myStyles.locked);
        else if (this.isModuleCompleted)
            return css(myStyles.title, myStyles.completedBackground);
        else if (this.isModuleExpired)
            return css(myStyles.title, myStyles.locked);
        else if (isActiveModule)
            return css(myStyles.title, myStyles.aktivBackground);
        else
            return css(myStyles.title, myStyles.pendingBackground);
    }

    checkIfItemIsSelected = (id) => {
        const { activeItemId } = this.props;
        // eslint-disable-next-line eqeqeq
        if (id == activeItemId) {
            return true;
        } else {
            return false;
        }
    }

    sendSegmentData = () => {
        let { module, phaseId, batchId, phaseItemId } = this.props;
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            moduleName: module.get('name'),
            moduleId: module.get('id'),
            courseId: module.get('course_id'),
            itemType: 'module',
            itemLocked: this.isModuleLocked,
            itemExpired: this.isModuleExpired,
            itemCompleted: this.isModuleCompleted,
            phaseId,
            batchId,
            phaseItemId,
        }
        window.analytics.track(
            'Phase Item Clicked - Type: Module',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    goToModuleItem = () => {
        let { module, phaseId, batchId, phaseItemId } = this.props;
        this.sendSegmentData();

        if (this.isModuleLocked)
            return;

        if (this.isModuleExpired) {
            if (!this.isModuleCompleted) {
                return;
            }
        }

        const currentModuleItemId = module.get('current_item_details') && module.get('current_item_details').get('current_item_id');

        if (!currentModuleItemId) {
            return;
        }

        const route = routes.get('BATCH_ITEM_MODULE');
        const params = [
            { 'BATCH_ID': batchId },
            { '_PHASE_ID_': phaseId },
            { 'COURSE_ID': module.get('course_id') },
            { '_PHASEITEMID_': phaseItemId },
            { 'MODULE_ID': module.get('id') },
            { 'ITEM_ID': currentModuleItemId }
        ];
        let routeObj = { route, params };
        this.checkAndSetPhaseAndItemStates(routeObj);
    }

    getUserItem = (userItems, item) => {
        let myUserItem = userItems.find((eachItem) => {
            return (eachItem.get('id') === item.get('id'));
        });
        if (myUserItem) {
            return {
                percentageCompletion: myUserItem.get('percentageCompletion'),
                workflowStatus: myUserItem.get('workflowStatus')
            };
        }
        return {
            percentageCompletion: 0,
            workflowStatus: enumConstants.get('PENDING')
        };
    }

    getItemPhaseStatus = (phaseId) => {
        const userPhase = filterObjectFromArray(
            this.props.phases.get('userPhasesList'), 'id', phaseId
        );
        return userPhase.get('workflowState');
    }

    checkAndSetPhaseAndItemStates = (routeObj) => {

        let { onlyPhaseItem, userItems } = this.props;

        this.props.setUserState({
            currentItemId: onlyPhaseItem.get('id'),
            currentPhaseId: onlyPhaseItem.get('phaseId')
        });

        const statusObj = this.getUserItem(userItems, onlyPhaseItem);

        if (statusObj.workflowStatus === enumConstants.get('UNOPENED')) {
            this.props.setItemWorkflowStatus(
                enumConstants.get('STARTED')
            );
        }

        const itemPhaseStatus = this.getItemPhaseStatus(onlyPhaseItem.get('phaseId'));

        if (itemPhaseStatus && itemPhaseStatus === enumConstants.get('NOT_STARTED')) {
            this.props.setPhaseWorkflowStatus(
                onlyPhaseItem.get('batchId'), onlyPhaseItem.get('phaseId'), enumConstants.get('STARTED'), routeObj
            );
        }
        else {
            if (routeObj) {
                updateRoute(routeObj);
            }
        }
    }

    renderLock = () => {
        let { onlyPhaseItem, module } = this.props;
        const myStyles = getSkin(this.props.skinGuide);
        let isLocked = false;
        let isExpired = false;

        if (onlyPhaseItem.get('isLocked')) {
            isLocked = true;
            isExpired = false;
        } else if (onlyPhaseItem.get('isExpired')) {
            isLocked = false;
            isExpired = true;
        } else {
            isLocked = module.get('lock_details').get('lock_reason') === 'locked'
                || module.get('lock_details').get('lock_reason') === 'not_started' || module.get('lock_details').get('lock_reason') === 'prerequisites';
            isExpired = module.get('lock_details').get('lock_reason') === 'expired' || onlyPhaseItem.get('isExpired');
        }

        if (isLocked) {
            return <div className={css(myStyles.lockedDiv)} styleName="locked-div" style={{ 'top': 0 }}>
                <div styleName="locked-icon"><LockIcon /></div>
            </div>;
        } else if (isExpired) {
            return (
                <div className={css(myStyles.expiredTag)} styleName="completion-tag">
                    {enumConstants.get('EXPIRED')}
                </div>
            );
        }

        return null;
    };

    getLockLabel = () => {
        const { getLabelWithDefaults, skinGuide } = this.props;
        const styles = getSkin(skinGuide);

        if (this.isModuleLocked) {
            return getLabelWithDefaults('locked_label', `Locked`);
        }

        if (this.isModuleCompleted) return null;

        if (this.isModuleExpired) {
            return <span className={css(styles.expired)}>
                {getLabelWithDefaults('expired_label', `Expired`)}
            </span>;
        }

        return null
    }

    checkIfModuleCompleted = () => {
        const { module } = this.props;

        if (module.get('items_count') !== 0) {

            if (module.get('required_items_count') === 0) {/*all items in the module are optional */
                return module.get('optional_items_completed_count') === module.get('optional_items_count');
            }

            return module.get('items_completed_count') === module.get('required_items_count');
        }

        return false;

    }

    getModuleCompletionPercentage = (module) => {

        if (module.get('items_count') !== 0) {

            if (module.get('required_items_count') === 0) {/*all items in the module are optional */
                return module.get('optional_items_completed_count') * 100 / module.get('optional_items_count');
            }

            return module.get('items_completed_count') * 100 / module.get('required_items_count');
        }

        return 0;

    }

    getProgressBarCountLabel = () => {
        const { module, getLabelWithDefaults } = this.props;
        let mandatoryItemsCompletedLabel = null;
        let optionalItemsLabel = null;

        if (module.get('items_count') === 0) {
            return getLabelWithDefaults('module_no_items_label', `This module has no items`);
        }

        if (module.get('required_items_count') !== 0) {
            mandatoryItemsCompletedLabel = getLabelWithDefaults(
                'count_items_completed_label',
                `${module.get('items_completed_count')} / ${module.get('required_items_count')} completed`, {
                completed_count: module.get('items_completed_count'),
                total_count: module.get('required_items_count')
            }
            );
        }

        if (module.get('optional_items_count') !== 0) {
			optionalItemsLabel = getLabelWithDefaults(
				'optional_module_items_count',
				`${module.get('optional_items_count')} optional item(s)`,
				{total_module_items: module.get('optional_items_count')}
			);
		}

        console.log('DTODO: duration of module: ', getDurationString(module.get('duration')));


        if (mandatoryItemsCompletedLabel) {
            if (optionalItemsLabel) {
                return <span>{mandatoryItemsCompletedLabel}{optionalItemsLabel && ','}<br />
                    <span styleName="optional-activities-count">{optionalItemsLabel}</span>
                </span>;
            }
            return mandatoryItemsCompletedLabel;
        }

        if (optionalItemsLabel) {
            return optionalItemsLabel;
        }

        return null;

    }

    renderDurationLabelIfSet = () => {
        const { module, skinGuide } = this.props;
        if(module.get('duration')){
            const styles = getSkin(skinGuide, false, false, false, true);
            return <div styleName={`duration-label ${this.inaccessibleMessage === '' ? 'no-lock-message-duration-label' : ''}`} className={css(styles.durationLabel)}>
                {getDurationString(module.get('duration'), 'short')}
            </div>;
        }
        return null;
    }

    render() {
        const skinHex = this.props.skinGuide.get('skinHex');
        const { module, getLabelWithDefaults, hideConnector, isActiveModule, onlyPhaseItem, index } = this.props;
        const isViewMoreClicked = false;
        const myStyles = getSkin(this.props.skinGuide, this.isModuleLocked, this.isModuleCompleted, this.isModuleExpired);
        const moduleImage = module.get('thumbnail_url');
        const cardHeight = 177;

        let completionPercentage = this.getModuleCompletionPercentage(module);

        let heightForConnector = cardHeight + 40 - 20;
        if (isViewMoreClicked) {
            heightForConnector = heightForConnector + (module.get('items').size * 54)
        }
        let heightStyles = {
            height: `${heightForConnector}px`
        }
        let courseInnerCircleStyles = {}
        let courseOuterCircleStyles = {}
        let timeLineCntStyles = {}

        let isModuleCompleted = this.checkIfModuleCompleted();

        if (onlyPhaseItem.get('isLocked') || onlyPhaseItem.get('isExpired')) {
            courseOuterCircleStyles = {
                borderColor: 'rgb(213, 217, 231)'
            }
            courseInnerCircleStyles = {
                backgroundColor: skinHex.get('greyColor4')
            }
        } else if (isActiveModule) {
            courseOuterCircleStyles = {
                borderColor: 'rgb(213, 217, 231)'
            }
            courseInnerCircleStyles = {
                backgroundColor: '#d44980'
            }
        } else {
            if (isModuleCompleted) {
                courseInnerCircleStyles = {
                    backgroundColor: skinHex.get('darkSkyBlue')
                }
            }
            courseOuterCircleStyles = {
                border: 'none'
            }
            timeLineCntStyles = {
                left: '-69px'
            }
        }

        return (
            <div styleName="individual-phase-item-cnt" onClick={this.goToModuleItem}>
                <div styleName="module-sub-cnt header">
                    <div
                        className={css(myStyles.lockMsg)}
                        styleName="module-index-cnt"
                    >
                        <div>
                            {
                                getLabelWithDefaults(
                                    'section_tile_index',
                                    `Section ${index + 1}`,
                                    { index: index + 1 }
                                )
                            }
                        </div>

                        <div styleName="lock-msg-cnt">
                            {this.renderDurationLabelIfSet()}
                            <div
                                className={css(myStyles.lockLabel)}
                                styleName="locked-label"
                            >
                                {this.getLockLabel()}
                            </div>
                            <div styleName="locked-msg">
                                {this.inaccessibleMessage}
                            </div>
                        </div>

                    </div>
                    {
                        (this.isModuleCompleted && !this.isModuleLocked) &&
                        <div styleName="completion-status-cnt">
                            <div className={css(myStyles.completionStatus)} styleName="completion-status">
                                {this.props.getLabelWithDefaults('completed_label', 'Completed')}
                            </div>
                        </div>
                    }
                </div>

                <div styleName="module-sub-cnt content">
                    <div
                        styleName="content-container"
                        className={css(myStyles.moduleContentCnt)}
                    >
                        <div
                            className={this.getClassNameForTitle(
                                myStyles,
                                isModuleCompleted,
                                isActiveModule,
                                onlyPhaseItem
                            )}
                        >
                            {module.get('name')}
                        </div>
                        {
                            module.get('description') &&
                            <div
                                className={css(myStyles.desc)}
                                styleName="desc"
                            >
                                {textTruncate(module.get('description'), 250)}
                            </div>
                        }
                    </div>
                    {
                        moduleImage &&
                        <div styleName="image-container">
                            <img src={moduleImage} alt={module.get('name')}/>
                            {this.renderLock()}
                        </div>
                    }

                </div>

                <div styleName="module-sub-cnt footer">
                    <div styleName="progress-container">
                        <div className={css(myStyles.outerBar)} styleName="outer-bar">
                            <div style={{
                                width: `${completionPercentage}%`,
                            }}
                                className={this.getClassNameForProgressBar(myStyles, isModuleCompleted, isActiveModule, onlyPhaseItem)}
                                styleName="inner-bar"></div>
                        </div>


                        <div
                            className={css(myStyles.bottomText)}
                            styleName="progress-content"
                        >
                            {this.getProgressBarCountLabel()}
                        </div>


                    </div>
                </div>

                <div styleName="dev-phase-timeline-cnt" style={timeLineCntStyles}>
                    <div styleName="dev-phase-timeline-outer-circle" onClick={this.goToModuleItem} style={courseOuterCircleStyles}>
                        <div styleName="dev-phase-timeline-inner-circle" style={courseInnerCircleStyles}>
                        </div>
                    </div>
                    {
                        !hideConnector &&
                        <div styleName="dev-phase-timeline-connector" style={heightStyles}>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default ModuleCard;
