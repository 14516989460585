import actionConstants from 'constants/actions';
import Immutable from 'immutable';
import { hashCreator } from 'utils/utilFunctions';


const initialState = Immutable.fromJS({
    quizData: {},
    hashedQuizQuestions: {},
    quizResponseData: {},
    submissions: [],
    fetchingUserQuizSubmissions: false,
    fetchingQuizResponses: false,
    fetchingQuiz: false
});

const surveyQuiz = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConstants.get('SET_QUIZ_DATA'):
            let questionsList = action.quizData.dataWithoutAnswers.elements;
            return state.set(
                'quizData',
                Immutable.fromJS(action.quizData)
            ).set('hashedQuizQuestions', Immutable.fromJS(hashCreator(questionsList, 'name')))
                .set('fetchingQuiz', false);
        case actionConstants.get('SET_USER_QUIZ_RESPONSES'):
            return state.set(
                'quizResponseData',
                Immutable.fromJS(action.quizResponseData)
            ).set('fetchingQuizResponses', false)
        case actionConstants.get('SET_USER_QUIZ_SUBMISSIONS'):
            return state.set(
                'submissions',
                Immutable.fromJS(action.submissions)
            ).set('fetchingUserQuizSubmissions', false)
        case actionConstants.get('SET_FETCH_USER_QUIZ_RESPONSES_FLAG'):
            return state.set(
                'fetchingQuizResponses',
                Immutable.fromJS(action.status)
            )
        case actionConstants.get('SET_FETCH_SURVEY_QUIZ_FLAG'):
            return state.set(
                'fetchingQuiz',
                Immutable.fromJS(action.status)
            )
        case actionConstants.get('SET_FETCH_USER_QUIZ_SUBMISSIONS_FLAG'):
            return state.set(
                'fetchingUserQuizSubmissions',
                Immutable.fromJS(action.status)
            )

        default:
            return state;
    }
};

export default surveyQuiz;