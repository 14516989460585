import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { appendQueryParameters } from 'utils/utilFunctions';
import { setResponseToQuestion } from 'actions/itemFeedback/actionCreators';

class SubmitAnswer extends NetPackWrapper {

    constructor() {
        super("POST");
    }

    prepareUrl = (apiInfoObject) => {
        return appendQueryParameters(
            urls.get('PLATFORM').get('SUBMIT_USER_ITEM_FEEDBACK_EACH_QUESTION')
                .replace('QUESTION_ID', apiInfoObject.questionId),
            apiInfoObject.queryParameters)
    }

    shouldAjaxCall = (apiInfoObject) => {
        return true;
    }

    successCall = (response, apiInfoObject) => {
        this.dispatch(setResponseToQuestion(apiInfoObject.body, apiInfoObject.questionId));
        return response;
    }

    onError = (errorObj) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR_SET_BATCH'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to submit answer. Please try again!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new SubmitAnswer();
