import React, { Component } from 'react';
import styles from './userJourneyStatsStyleA.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { checkIfPresent } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class UserJourneyStatsStyleA extends Component {

	render() {
		const {
			getLabel,
			incompleteUserBatchesCount,
			skinGuide
		} = this.props;
		const myStyles = getSkin(skinGuide);


		return (
			<div styleName="user-stats" className={css(myStyles.userStatsBackground)}>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', width: '100%' }}>

					{
						incompleteUserBatchesCount > 0 ? 
							<div styleName="individual-stats">
								<div styleName="stat-label" className={css(myStyles.statCount)}>
									{incompleteUserBatchesCount > 0 && incompleteUserBatchesCount}
								</div>
								<div className={css(myStyles.statLabel)}>
									{getLabel('courses_to_go_label')}
								</div>
							</div> :
							<div styleName="individual-stats">
								<div className={css(myStyles.statLabelNoWidth)}>
									{getLabel('all_courses_are_completed')}
								</div>
							</div>
					}
					
				</div>
			</div>
		);
	}
}

export default UserJourneyStatsStyleA;
