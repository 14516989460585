/**
 * @author: ujjwal
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: analytics.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * App reducer is a high level reducer which combines all the sub reducers using
 * default redux "combineReducers" functionality
 *
 **/

import { combineReducers } from 'redux-immutable';
import assessment from './assessment';
import activity from './activity';
import performance from './performance';
import development from './development';
import endOfJourney from './endOfJourney';
import genericData from './genericData';

const analytics = combineReducers({
    genericData: genericData,
    assessment: assessment,
    activity: activity,
    performance: performance,
    development: development,
    endOfJourney: endOfJourney
});

export default analytics;