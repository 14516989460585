import React, { Component } from 'react';
import styles from './scoreSnapshot.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { getCompetencyArray, getCompetencyBackground, getCompetencyColorArray } from 'utils/utilFunctions';
import TargetArrow from 'svgComponents/targetArrow';
import InfoTooltip from 'commonComponents/infoTooltip';
import UserDot from 'commonComponents/userDot';
import enumConstants from 'constants/enums';
import {isEmptyObject} from 'utils/objectUtils';
import {renderNumber} from 'utils/utilFunctions';
import { checkIfPresent } from '../../../../util/utilFunctions';
import defaults from 'constants/defaults';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ScoreSnapshot extends Component {

	renderHeadingDiv = (competencyValueArray, label, myStyles) => {
		const headings = competencyValueArray.map((eachValue) => {
			return (
				<div
					styleName="table-right-item"
					style={{ width: `${100 / competencyValueArray.length}%` }}
				>
					{this.props.getLabel(eachValue.text)}
					<div styleName="heading-info">
						<InfoTooltip tooltipText={this.props.getLabel(eachValue.desc)} />
					</div>
				</div>
			);
		});
		return (
			<div className={css(myStyles.tableHeading)} styleName="table-row">
				<div styleName="table-left">{label}</div>
				<div styleName="table-right">{headings}</div>
			</div>
		);
	}

	renderOtherRows = (measuresList, userMeasuresList, competencyValueArray, myStyles) => {
		//  
		// const rows = userMeasuresList.filter((userMeasure) => {
		// 	return measuresList.filter((eachMeasure) => {
		// 		return (eachMeasure.get('id') === userMeasure.get('id'));
		// 	});
		// });
		//  


		const rows = userMeasuresList.map((userMeasure) => {
			let finalMeasure = measuresList.filter((eachMeasure) => {
				return (eachMeasure.get('id') === userMeasure.get('id'));
			});
			finalMeasure = finalMeasure.toJS();
			if (finalMeasure[0] === undefined || isEmptyObject(finalMeasure[0])) {
				return null;
			}
			const userScore = userMeasure.get('score') > 5 ? 5 : userMeasure.get('score');
			const rightItems = competencyValueArray.map((eachValue, index) => {
				return (
					<div
						className={(index == competencyValueArray.length - 1) ? null : css(myStyles.tableItemBorder)}
						styleName="table-right-item"
						style={{ width: `${100 / competencyValueArray.length}%` }}
					>
						{
							(userScore > eachValue.min) && (userScore <= eachValue.max)
								? <UserDot
									dotBackground={getCompetencyBackground(userScore)}
									tooltipText={`${userScore.toFixed(2)} on 5`} />
								: (userScore === eachValue.min && userScore === 0)
								? <UserDot
									dotBackground={getCompetencyBackground(userScore)}
									tooltipText={`${userScore.toFixed(2)} on 5`} />
								: null
						}
					</div>
				);
			});
			return (
				<div className={css(myStyles.mainRow)} styleName="table-row main-row">
					<div className={css(myStyles.mainLeft)} styleName="table-left">
						<div className={css(myStyles.leftIcon)} styleName="left-icon"><TargetArrow /></div>
						<div className={css(myStyles.leftName)} styleName="left-name">{finalMeasure[0].name}</div>
						<div className={css(myStyles.leftInfo)} styleName="left-info">
							<InfoTooltip tooltipText={finalMeasure[0].description} />
						</div>
					</div>
					<div styleName="main-table-right">{rightItems}</div>
				</div>
			);
		});
		return rows;
	}

	renderDesirableRangeLine = (myStyles) => {
		const activePhaseDetails = this.props.activePhaseDetails;
		const minRating = 0;
		const maxRating = 5;
		let minIdealValue = defaults.get('DEFAULT_MIN_DESIRABLE_RANGE');
		let maxIdealValue = defaults.get('DEFAULT_MAX_DESIRABLE_RANGE');
		if(checkIfPresent(activePhaseDetails)) {
			if(
				checkIfPresent(activePhaseDetails.minIdealRange)
				&& checkIfPresent(activePhaseDetails.maxIdealRange)
				&& typeof activePhaseDetails.minIdealRange == 'number'
				&& typeof activePhaseDetails.maxIdealRange == 'number'
				&& (activePhaseDetails.minIdealRange <= activePhaseDetails.maxIdealRange)
			) {
				minIdealValue = activePhaseDetails.minIdealRange;
				maxIdealValue = activePhaseDetails.maxIdealRange;
			}
		}
		console.log("minIdealValue = ", minIdealValue, "maxIdealValue = ", maxIdealValue);
		const leftPerc = ((minIdealValue - minRating)*100) / maxRating;
		const rightPerc = ((maxRating - maxIdealValue)*100) / maxRating;
		const desirablePerc = ((maxIdealValue - minIdealValue)*100) / maxRating;
		return (
			<div styleName="table-row">
				<div styleName="table-left"></div>
				<div styleName="table-right">
					<div style={{width: `${leftPerc}%`}}></div>
					<div style={{width: `${desirablePerc}%`}}>
						<div className={css(myStyles.desirableText)} styleName="desirable-range-text">
							{this.props.getLabel('desirable_range_label')}
							&nbsp;({minIdealValue}-{maxIdealValue})
						</div>
						<div styleName="desirable-range-line">
							<div className={css(myStyles.leftArrow)} styleName="left-arrow"></div>
							<div className={css(myStyles.mainLine)} styleName="main-line"></div>
							<div className={css(myStyles.rightArrow)} styleName="right-arrow"></div>
						</div>
					</div>
					<div style={{width: `${rightPerc}%`}}></div>
				</div>
			</div>
		);
	}

	renderTable = (measuresList, label, userMeasuresList, competencyValueArray, myStyles) => {
		const headingDiv = this.renderHeadingDiv(competencyValueArray, label, myStyles);
		const desirableRange = this.renderDesirableRangeLine(myStyles);
		const otherRows = this.renderOtherRows(measuresList, userMeasuresList, competencyValueArray, myStyles);
		return (
			<div styleName="table">
				{desirableRange}
				{headingDiv}
				{otherRows}
			</div>
		);

	}

	renderLegend = (myStyles) => {
		const competencyColorArray = getCompetencyColorArray();
		const legendArray = competencyColorArray.map((eachCompetencyColor) => {
			return(
				<div styleName="competency-single-legend">
					<div style={{backgroundColor:eachCompetencyColor.color}} styleName="competency-legend-dot"></div>
					<div className={css(myStyles.legendText)} styleName="competency-legend-text">
						{`${this.props.getLabel(eachCompetencyColor.text)} (${renderNumber(eachCompetencyColor.min)} - ${renderNumber(eachCompetencyColor.max)})`}
					</div>
				</div>
			);
		});
		// legendArray.push(
		// 	<div styleName="competency-single-legend">
		// 		<div className={css(myStyles.desirableBar)} styleName="competency-legend-dot"></div>
		// 		<div className={css(myStyles.legendText)} styleName="competency-legend-text">
		// 			{this.props.getLabel('desirable_range_label')}
		// 		</div>
		// 	</div>
		// );
		return legendArray;
	}

	render() {
		const { measuresList, userMeasuresList } = this.props;
		const competencyValueArray = getCompetencyArray();

		const competencies = measuresList.filter((eachMeasure) => {
			return (eachMeasure.get('typeId') === enumConstants.get('COMPETENCY'));
		});

		const values = measuresList.filter((eachMeasure) => {
			return (eachMeasure.get('typeId') === enumConstants.get('VALUES'));
		});

		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="score-snapshot-container">
				<div className={css(myStyles.scoreTitle)} styleName="score-title">
					{this.props.getLabel('score_snapshot_label')}
				</div>
				<div className={css(myStyles.scoreSubTitle)} styleName="score-sub-title">{this.props.getLabel('measure_competency_score_label')}</div>
				<div styleName="competencies-color-legend-container">
					{this.renderLegend(myStyles)}
				</div>
				<div styleName="score-content">
					{(competencies.size > 0) ? this.renderTable(competencies, competencies.get(0).get('typeName'), userMeasuresList, competencyValueArray, myStyles) : null}
					{(values.size > 0) ? this.renderTable(values, values.get(0).get('typeName'), userMeasuresList, competencyValueArray, myStyles) : null}
				</div>
			</div>
		);
	}
}

export default ScoreSnapshot;