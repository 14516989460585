import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		tooltipArrow: {
			borderBottom: `10px solid ${skin.get('greyColor1')}`,
			borderLeft: `10px solid transparent`,
			borderRight: `10px solid transparent`
		},
		tooltipContent: {
			...myTypography.caption,
			color: skin.get('greyColor5')
		}
	});
}

export default getSkin;