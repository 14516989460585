import React, { Component } from 'react';
import styles from './analyticsComponent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import UserDetails from 'components/analytics/activityComponent/userDetails';
import withSizes from 'react-sizes';
import SummaryComponent from './summaryComponent/SummaryComponent';
import ViewMoreComponent from './viewMoreComponent';
import ActivityHeader from 'components/analytics/activityHeader';
import Footer from 'commonComponents/footer';


@applySkin
@CSSModules(styles, { allowMultiple: true })
class AnalyticsComponent extends Component {



    render(){
        const myStyles = getSkin(this.props.skinGuide);
        const strings = this.props.allGraphsData.get('genericData').get('analyticsData').get('strings');
        const userName = this.props.user.get('firstName');
        const greetingMessage = strings.get('userAddessingMessageKey').replace('PLACEHOLDER', userName);
        return (

            [<ActivityHeader title='Analytics' key="ActivityHeader"/>,
            <div key="ActivityBody" className={css(myStyles.containerStyle)} styleName="container-class">
                <UserDetails 
                     greeting={greetingMessage}
                     infoMessage={strings.get('graphDelayKey')}
                    {...this.props} />
                <SummaryComponent genericStrings={strings} {...this.props} />
                <ViewMoreComponent strings={strings} {...this.props} />
                <Footer />
            </div>]
        )
    }
}

const mapSizeToProps = function(sizes) {
    const props = {
        size: sizes
    }

    return props;
}

export default withSizes(mapSizeToProps)(AnalyticsComponent);
