import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setAssessmentScoreData } from 'actions/analytics/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchAssessmentScoreData extends NetPackWrapper {

    constructor() {
        super("GET");        
	}

    prepareUrl = (apiInfoObject) => {        
        return urls.get('ANALYTICS').get('GET_ASSESSMENT_SCORE_DATA')
        .replace('BATCH_ID', apiInfoObject.batchId)
        .replace('USER_ID', '' + apiInfoObject.userId)
        .replace('PHASE_ID', '' + apiInfoObject.phaseId)

    }

    successCall = (response) => {
        this.dispatch(setAssessmentScoreData(response));
        this.dispatch(setApiError('fetchAssessmentScoreDataError', null));
        return response;
    }

    onError = (errorObj) => {
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchAssessmentScoreDataError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchAssessmentScoreData();
