import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setAssessmentMeasuresData } from 'actions/analytics/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchAssessmentMeasuresData extends NetPackWrapper {

    constructor() {
        super("GET");        
	}

    prepareUrl = (apiInfoObject) => {        
        return urls.get('ANALYTICS').get('GET_ASSESSMENT_MEASURES_DATA')
        .replace('BATCH_ID', apiInfoObject.batchId)
        .replace('USER_ID', '' + apiInfoObject.userId)
        .replace('PHASE_ID', '' + apiInfoObject.phaseId)
    }

    successCall = (response) => {
        this.dispatch(setAssessmentMeasuresData(response));
        this.dispatch(setApiError('fetchAssessmentMeasuresDataError', null));

        return response;
    }

    onError = (errorObj) => {
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchAssessmentMeasuresDataError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchAssessmentMeasuresData();
