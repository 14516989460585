import $ from 'jquery';
import React from 'react';
import MoreDetailsRow from 'components/systemCheck/moreDetailsRow';
import requirements from 'constants/systemRequirements';//TODO: this will now come from the API

import {browser, os} from 'utils/browser';
import { isMobile } from 'react-device-detect';

console.log('browser', browser);
console.log('os', os);

const browserName = browser.name.toUpperCase();
const browserVersion = parseFloat(browser.version);

const checkIfAdblockerActive = async () => {
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    let testAd = document.createElement('div');
    testAd.innerHTML = '&nbsp;';
    testAd.className = 'adsbox';
    document.body.appendChild(testAd);

    await sleep(100);
    return new Promise((resolve, reject) => {
        let adBlockEnabled = false;
        if (testAd.offsetHeight === 0) {
            adBlockEnabled = true;
        }
        var node = document.getElementsByClassName('adsbox')[0];
        node.parentNode.removeChild(node);
        console.log('system info: AdBlock Enabled? ', adBlockEnabled)
        if(adBlockEnabled){
            return resolve(true)
        } else {
            return reject(false);
        }
    });
}



const isPrivateMode = async () => {
    return new Promise(function detect(resolve) {
        var yes = function() { resolve(true); }; // is in private mode
        var not = function() { resolve(false); }; // not in private mode

        function detectChromeOpera() {
            // https://developers.google.com/web/updates/2017/08/estimating-available-storage-space
            var isChromeOpera = /(?=.*(opera|chrome)).*/i.test(navigator.userAgent) && navigator.storage && navigator.storage.estimate;
            if (isChromeOpera) {
                navigator.storage.estimate().then(function(data) {
                    return data.quota < 120000000 ? yes() : not();
                });
            }
            return !!isChromeOpera;
        }
  
        function detectFirefox() {
            var isMozillaFirefox = 'MozAppearance' in document.documentElement.style;
            if (isMozillaFirefox) {
                if (indexedDB == null) yes();
                else {
                    var db = indexedDB.open('inPrivate');
                    db.onsuccess = not;
                    db.onerror = yes;
                }
            }
            return isMozillaFirefox;
        }
  
        function detectSafari() {
            var isSafari = navigator.userAgent.match(/Version\/([0-9\._]+).*Safari/);
            if (isSafari) {
                var testLocalStorage = function() {
                    try {
                        if (localStorage.length) not();
                        else {
                            localStorage.setItem('inPrivate', '0');
                            localStorage.removeItem('inPrivate');
                            not();
                        }
                    } catch (_) {
                        // Safari only enables cookie in private mode
                        // if cookie is disabled, then all client side storage is disabled
                        // if all client side storage is disabled, then there is no point
                        // in using private mode
                        navigator.cookieEnabled ? yes() : not();
                    }
                    return true;
                };

                var version = parseInt(isSafari[1], 10);
                if (version < 11) return testLocalStorage();
                try {
                    window.openDatabase(null, null, null, null);
                    not();
                } catch (_) {
                    yes();
                }
            }
            return !!isSafari;
        }
  
        function detectEdgeIE10() {
            var isEdgeIE10 = !window.indexedDB && (window.PointerEvent || window.MSPointerEvent);
            if (isEdgeIE10) yes();
            return !!isEdgeIE10;
        }
  
        // when a browser is detected, it runs tests for that browser
        // and skips pointless testing for other browsers.
        if (detectChromeOpera()) return;
        if (detectFirefox()) return;
        if (detectSafari()) return;
        if (detectEdgeIE10()) return;

        // default navigation mode
        return not();
    });
  }

export const checkIfTrackingIsBlocked = async (getLabelsWithDefaults) => {
    let adblockerActive = false;
    let isPrivateOrIncognitoMode = false;
    let isFirefoxPrivateMode = false;
    let errors = [];

    try{
        adblockerActive = await checkIfAdblockerActive();
        if(adblockerActive){
            errors.push({
                key: 'adblocker',
                name: getLabelsWithDefaults('adblocker_warning_heading_label'),
                tooltipText: getLabelsWithDefaults('adblocker_warning_description_label'),
                resolution: getLabelsWithDefaults('adblocker_warning_resolution_label')
            });
        }
    
    } catch(error){
        console.log('system info: adblockerActive', adblockerActive, error);
    }

    try{
        isPrivateOrIncognitoMode = await isPrivateMode();
        isFirefoxPrivateMode = isPrivateOrIncognitoMode && browserName == 'FIREFOX';
        if(isFirefoxPrivateMode){
            errors.push({
                key: 'isFirefoxPrivateMode',
                name: 'Firefox is being used in Private Mode',
                value: `Firefox in Private Mode blocks some of our data collection which leads to errors in time spent tracking`,
                tooltipText: `Firefox in Private Mode blocks some of our data collection which leads to errors in time spent tracking`,
                resolution: `We use this data to calculate the time you spend on each activity and the same shows up in your reports. To ensure those values are correct, we recommend switching to normal mode on Firefox or Google Chrome browser.`
            });
        }
    } catch(error){
        console.log('system info: isPrivateOrIncognitoMode', isPrivateOrIncognitoMode, error);
        console.log('system info: isFirefoxPrivateMode', isFirefoxPrivateMode);
    }

    const moreDetailsProps = errors.map(error => {
        return <MoreDetailsRow
            key={error.key}
            detailText={<span className="capitalize">{error.name}</span>}
            infoButtonText="More Details"
            tooltipText={error.tooltipText}
        />
    });

    let resolution = '<span></span>';
    const moreDetails = errors.map(error => {
        resolution = `${resolution}<br/>${error.resolution}`;
        return {
            detailText: `<span style=\"text-transform: capitalize\">${error.name})</em></span>`,
            infoButtonText: "More Details",
            tooltipText: error.tooltipText
        }
    });

    return {
        hasErrors: errors.length > 0,
        title: `Some browser settings are blocking time-spent tracking`,
        description: 'Some of the settings of your browser might affect the time spent values in your reports',
        moreDetailsChildren: <span> {moreDetailsProps} </span>,
        moreDetails: moreDetails,
        resolution: resolution
    };
}



const checkOS = (osSupported, getLabelWithDefaults) => {
    const osInSupportedList = osSupported.findIndex(osItem => os.name.toLowerCase() === osItem.toLowerCase());

    return {
        key: 'os',
        name: 'Operating System',
        supported: osInSupportedList === -1 ? false : true,
        value: os.name,
        errorHeading: getLabelWithDefaults('os_warning_heading_label'),
        errorMessage: getLabelWithDefaults('os_warning_description_label'),
        tooltipText: getLabelWithDefaults('os_warning_supported_os_label',
            `Supported Operating Systems: ${osSupported.join(', ')}`,
            {
                OS_NAMES: osSupported.join(', ')
            }
        )
    };
}

const checkBrowser = (browsersSupported, getLabelWithDefaults) => {

    const browserInSupportedListIndex = browsersSupported.findIndex( browser => browser.name.toLowerCase() === browserName.toLowerCase());
    const browserInSupportedList = browserInSupportedListIndex !== -1;
    let browserVersionSupported = false;

    if(browserInSupportedList){
        if(browserVersion >= browsersSupported[browserInSupportedListIndex].version){
            browserVersionSupported = true;
        }
    }

    const supportedBrowserStrings = browsersSupported.map(browser => {
        return `${browser.name} ${browser.version}+`;
    });

    return {
        key: 'browser',
        name: 'Browser',
        supported: browserInSupportedList && browserVersionSupported,
        value: `${browser.name} ${browser.version}`,
        errorHeading: getLabelWithDefaults('browser_warning_heading_label'),
        errorMessage: getLabelWithDefaults('browser_warning_description_label'),
        tooltipText: getLabelWithDefaults('browser_warning_supported_browsers_label', 
            `Supported Browsers: ${supportedBrowserStrings.join(', ')}`,
            {
                BROWSER_NAMES: supportedBrowserStrings.join(', ')
            }
        )
    };
}


const checkScreenResolution = (minimumScreenResolution, getLabelWithDefaults) => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    let resolutionToCheck = {width: minimumScreenResolution.width, height: minimumScreenResolution.height};

    if(isMobile){
        resolutionToCheck = { width: minimumScreenResolution.mobile.width, height: minimumScreenResolution.height };
    }

    return {
        key: 'resolution',
        name: 'Screen Resolution',
        supported: screenWidth >= resolutionToCheck.width && screenHeight >= resolutionToCheck.height,
        value: `${screenWidth}x${screenHeight}`,
        errorHeading: getLabelWithDefaults('screen_resolution_warning_heading_label'),
        errorMessage: getLabelWithDefaults('screen_resolution_warning_description_label'),
        tooltipText: `Minimum Supported Resolution: ${resolutionToCheck.width}x${resolutionToCheck.height}`
    };
}

const checkIfLinkIsAccessible = (link) => {
    const {url, type, headers} = link;
    return new Promise((resolve, reject) => {

        if(/^https?:\/\/([a-zA-Z\d-]+\.){0,}freshdesk\.com(\/.*)?$/.test(url)){
            if (window.FreshWidget) {
                resolve();
                return;
            }
        }

        $.ajax({
            url: url,
            dataType: type,
            headers: headers,
        }).then(function(data){
            resolve(data);
        }, function(error){         
            reject(error); 
        });
    });
}

const getCategoryURLErrorMessages = (category, getLabelWithDefaults) => {

    if(!category){
        return {
            title: getLabelWithDefaults('category_others_heading_label'),
            description: getLabelWithDefaults('category_others_description_label')
        };
    }

    return {
        title: category.get('errorTitle'),
        description: category.get('errorDescription')
    };

}

const getDomainName = (url) => {
    const urlElement = document.createElement('a');
    urlElement.setAttribute('href', url);
    return urlElement.hostname;
}

const getLinkGroupsAlreadyChecked = () => {
    try{
        if(window.localStorage.getItem('latestSystemCheckResult')){
            const latestSystemCheckResult = JSON.parse(window.localStorage.getItem('latestSystemCheckResult'));
            if(latestSystemCheckResult.linkGroupsChecked)
                return latestSystemCheckResult.linkGroupsChecked;
        }
        return [];
    } catch(error){
        return [];
    }
}

const shouldCheckLinkGroup = (allLinkGroups, linkGroupKey, context="home") => {
    const linkGroupsAlreadyChecked = getLinkGroupsAlreadyChecked();

    if(allLinkGroups[linkGroupKey]){
        if(context === "home")
            return true;
        else{
            return !linkGroupsAlreadyChecked.includes(linkGroupKey)
        }
    }
    return false;
}

export const checkWhitelistingForCategoryUrls = async (userWhitelistingRequirements, category = null, context="home", getLabelWithDefaults) => {
    let links = [];
    const allLinkGroups = userWhitelistingRequirements.get('linkGroups').toJS();
    
    if(!category){
        let linkGroupsInCategories = []; 
        
        userWhitelistingRequirements.get('categories').valueSeq().forEach(categoryValue => {
            linkGroupsInCategories = linkGroupsInCategories.concat(categoryValue.get('linkGroups').toJS());
        });

        Object.keys(allLinkGroups).forEach((linkGroupKey) => {
            if(linkGroupsInCategories.indexOf(linkGroupKey) === -1){
                if(shouldCheckLinkGroup(allLinkGroups, linkGroupKey, context))
                    links = links.concat(allLinkGroups[linkGroupKey]);
            }
        });
    } else {    
        category.get('linkGroups').forEach((linkGroupKey) => {
            if(shouldCheckLinkGroup(allLinkGroups, linkGroupKey, context))
                links = links.concat(allLinkGroups[linkGroupKey]);
        });
    }

    const moreDetailsProps = [];
    const moreDetails = [];
    let link = null;
    for(let index = 0; index<= links.length-1; index++){
        link = links[index];
        try{
            await checkIfLinkIsAccessible(link);
        }catch(error){
            if(error.status !== 200){/*TODO: add more status codes*/
                moreDetailsProps.push(
                    <MoreDetailsRow
                        key={link.url}
                        detailText={getDomainName(link.url)}
                        infoButtonText={getLabelWithDefaults('system_check_result_why_label')}
                        tooltipText={link.reason}
                    />
                );

                moreDetails.push({
                    detailText: getDomainName(link.url),
                    infoButtonText: getLabelWithDefaults('system_check_result_why_label'),
                    tooltipText: link.reason
                });
            }
        }
    }
    return {
        hasErrors: moreDetailsProps.length > 0,
        warningsOnly: (category && category.get('warningsOnly')) || false,
        moreDetailsChildren: <span> {moreDetailsProps} </span>,
        moreDetails: moreDetails,
        resolution: getLabelWithDefaults('whitelisting_solution_label'),
       ...getCategoryURLErrorMessages(category, getLabelWithDefaults)
    }
}


export const get_OS_Browser_Resolution_Errors = (userWhitelistingRequirements, getLabelWithDefaults) => {
    const resultsArray = [
        checkOS(userWhitelistingRequirements.get('osSupported').toJS(), getLabelWithDefaults),
        checkBrowser(userWhitelistingRequirements.get('browsersSupported').toJS(), getLabelWithDefaults),
        checkScreenResolution(userWhitelistingRequirements.get('minimumScreenResolution').toJS(), getLabelWithDefaults)
    ];
    const actualErrors = resultsArray.filter(result => !result.supported);
    //const resultTitle = actualErrors.map(error => error.name).join(', ');
    const resultTitle = actualErrors.map(error => error.errorHeading).join(', ');
    const resultDescription = actualErrors.map(error => error.errorMessage).join(', ');
    const moreDetailsProps = actualErrors.map(error => {
        return <MoreDetailsRow
            key={error.key}
            detailText={<span className="capitalize">{error.value} <em>({error.name})</em></span>}
            infoButtonText={getLabelWithDefaults('requirements_label')}
            tooltipText={error.tooltipText}
        />
    });

    const moreDetails = actualErrors.map(error => {
        return {
            detailText: `<span style=\"text-transform: capitalize\">${error.value} <em>(${error.name})</em></span>`,
            infoButtonText: getLabelWithDefaults('requirements_label'),
            tooltipText: error.tooltipText
        }
    });

    return {
        hasErrors: actualErrors.length > 0,
        title: resultTitle,
        description: resultDescription,
        moreDetailsChildren: <span> {moreDetailsProps} </span>,
        moreDetails: moreDetails
    };
}

export const systemDetails = () => {
    return {
        browser: `${browserName} ${browserVersion}`,
        os: `${os.name} ${os.version}`,
        screenResolution: `${window.screen.width}x${window.screen.height}`,
    }
}

