import React, { Component } from 'react';
import styles from './colorPaletteIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class ColorPaletteIcon extends Component {

	render() {
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 22 22">
					<path fill={this.props.fillColor || "#FFF"} fillRule="nonzero" stroke={this.props.fillColor || "#FFF"} d="M11 1c5.556 0 10 4 10 8.889 0 3.111-2.444 5.555-5.556 5.555h-2c-.888 0-1.666.778-1.666 1.667 0 .445.222.778.444 1.111.334.334.445.667.445 1.111 0 .89-.778 1.667-1.667 1.667-5.556 0-10-4.444-10-10S5.444 1 11 1zm6.111 10c.889 0 1.667-.778 1.667-1.667S18 7.667 17.11 7.667s-1.667.777-1.667 1.666c0 .89.778 1.667 1.667 1.667zM4.89 11c.889 0 1.667-.778 1.667-1.667s-.778-1.666-1.667-1.666-1.667.777-1.667 1.666C3.222 10.223 4 11 4.89 11zm3.333-4.444c.89 0 1.667-.778 1.667-1.667S9.11 3.222 8.222 3.222 6.556 4 6.556 4.89s.777 1.667 1.666 1.667zm5.556 0c.889 0 1.666-.778 1.666-1.667s-.777-1.667-1.666-1.667c-.89 0-1.667.778-1.667 1.667s.778 1.667 1.667 1.667z" />
				</svg>
			</div>
		);
	}
}



export default ColorPaletteIcon;
