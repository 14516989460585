import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import {
	setPasswordUpdating,
	setPasswordError
} from 'actions/userProfile/actionCreators';
import defaultLabels from 'constants/labels';

class ChangePassword extends NetPackWrapper {

	constructor() {
		super("POST");
	}

	prepareUrl = () => urls.get('PLATFORM').get('CHANGE_PASSWORD');

	beforeCall = (apiInfoObject) => {
		this.dispatch(setPasswordError(null));
		this.dispatch(setPasswordUpdating(true));
		return apiInfoObject;
	}

	successCall = (response) => {
		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let msgHeader = defaultLabels.get('success_label');
		let msg = defaultLabels.get('password_updated_success_toast_msg');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			msgHeader = labels.get('success_label');
			msg = labels.get('password_updated_success_toast_msg');
		}

		this.dispatch(addToast({
			id: toastConstants.get('ID_SUCCESS'),
			type: toastConstants.get('TYPE_SUCCESS'),
			heading: msgHeader,
			message: msg,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		this.dispatch(setPasswordUpdating(false));
		return response;
	}

	onError = (error) => {
		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let errorMsg = defaultLabels.get('password_updated_error_toast_msg');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			errorMsg = labels.get('password_updated_error_toast_msg');
		}

		if (error.errorObject && error.errorObject.response && error.errorObject.response.error && error.errorObject.response.error.message)
			errorMsg = error.errorObject.response.error.message;
		this.dispatch(setPasswordError(errorMsg));
		this.dispatch(setPasswordUpdating(false));
		return error;
	}
}

export default new ChangePassword();
