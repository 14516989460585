import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import styles from './quizNavigator.module.sass';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class QuizNavigator extends React.Component {


    scrollToQuestion = (questionId) => {
        let { handleScrollTo, survey } = this.props;
        handleScrollTo(questionId, survey)
    }

    getQuestionCircles = () => {
        const { questions, answeredQuestion } = this.props;
        return questions.map((question, index) => {
            let isAnswererd = false;
            if (answeredQuestion[question.get('name')]) {
                isAnswererd = true;
            }

            let bubbleStyles = {};
            if (isAnswererd) {
                bubbleStyles = {
                    color: 'blue',
                    backgroundColor: 'rgba(34,59,146,0.1)'
                }
            };
            return (
                <div key={`question_${question.get('name')}_navigator`} styleName="individual-question-bubble" style={bubbleStyles} onClick={() => this.scrollToQuestion(question.get('name'))}>
                    {index + 1}
                    {
                        question.get('flagged') &&
                        <div styleName="flagged-bubble"></div>
                    }
                </div>
            )
        })
    }

    render() {
        return (
            <div styleName="questions-navigator-cnt">
                <div styleName="questions-navigator-header">
                    {this.props.getLabelWithDefaults('questions_label', 'QUESTIONS')}
                </div>
                <div styleName="quiz-question-bubbles-cnt">
                    {this.getQuestionCircles()}
                </div>
            </div>
        )
    }
}

export default QuizNavigator;