import React, { Component } from 'react';
import { connect } from 'react-redux';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import ModuleItemFeedback from 'components/moduleItemFeedback';
import {
    fetchItemFeedback,
    fetchUserItemFeedback,
    submitAnswer,
    submitFeedback
} from 'actions/itemFeedback/apiActions';

import { resetFeedbackData } from 'actions/itemFeedback/actionCreators';
import { setResponseToQuestion } from 'actions/itemFeedback/actionCreators';
import { fetchModuleItemFeedback } from 'actions/moduleItems/apiActions';

class ModuleItemFeedbackContainer extends Component {

    componentDidMount() {
        const { moduleItemId, batchId } = this.props.match.params;
        this.props.fetchModuleItemFeedback(+batchId, +moduleItemId)
    }

    componentWillUnmount() {
        this.props.resetFeedbackData();
    }

    render() {
        return (
            <ModuleItemFeedback {...this.props} />
        );
    }
}

const submitFeedbackFromContainer = (payload) => {
    // const { moduleItemType} = this.props;
    return (dispatch, getState) => {
        const itemId = getState().getIn(['moduleItem', 'moduleItem', 'id']);
        const entityType = 'MODULEITEM';
        let scenario = 'CONTENT';
        dispatch(submitFeedback(payload, itemId, entityType, scenario));
    }
}

const submitAnswerFromContainer = (payload, questionId) => {
    // const { moduleItemId} = this.props.match.params;
    return (dispatch, getState) => {
        const itemId = getState().getIn(['moduleItem', 'moduleItem', 'id']);
        const entityType = 'MODULEITEM';
        let scenario = 'CONTENT';
        dispatch(submitAnswer(payload, questionId, itemId, entityType, scenario));
    }
}

const mapStateToProps = state => ({
    itemFeedback: state.get('itemFeedback')
})

const mapDispatchToProps = dispatch => ({
    fetchItemFeedback: (moduleItemId, scenario = 'CONTENT') => {
        dispatch(fetchItemFeedback(moduleItemId, scenario));
    },
    fetchUserItemFeedback: (batchId, moduleItemId, entityType = 'MODULEITEM', scenario = 'CONTENT') => {
        dispatch(fetchUserItemFeedback(batchId, moduleItemId, entityType, scenario))
    },
    submitAnswer: (payload, questionId) => {
        dispatch(submitAnswerFromContainer(payload, questionId))
    },
    submitFeedback: (payload) => {
        dispatch(submitFeedbackFromContainer(payload))
    },
    setResponseToQuestion: (payload, questionId) => {
        dispatch(setResponseToQuestion(payload, questionId))
    },
    fetchModuleItemFeedback: (batchId, itemId, entityType = 'MODULEITEM', scenario = 'CONTENT') => {
        dispatch(fetchModuleItemFeedback(batchId, itemId, entityType, scenario))
    },
    resetFeedbackData: () => {
        dispatch(resetFeedbackData())
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(ModuleItemFeedbackContainer);