import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import defaultLabels from 'constants/labels';

class SetBatchWorkflowStatus extends NetPackWrapper {

	constructor() {
		super("PUT");
	}

	prepareUrl = (apiInfoObject = {}) => (urls.get('PLATFORM').get('SET_BATCH_WORKFLOW_STATUS')
		.replace(
			'BATCH_ID',
			apiInfoObject.batchId
		)
	)

	shouldAjaxCall = (apiInfoObject) => {
		return true;
	}

	// getSuccessToastObject = () => ({
	// 	id: toastConstants.get('ID_SUCCESS'),
	// 	type: toastConstants.get('TYPE_SUCCESS'),
	// 	heading: defaultLabels.get('accepted_invitation_success_toast_msg_heading'),
	// 	message: defaultLabels.get('accepted_invitation_success_toast_msg'),
	// 	isDisplayed: false,
	// 	autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
	// })

	getFailuraToastObject = (msgHeader, msg) => ({
		id: toastConstants.get('ID_ERROR'),
		type: toastConstants.get('TYPE_ERROR'),
		heading: msgHeader,
		message: msg,
		isDisplayed: false,
		autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
	})

	afterCall = (response) => {
		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let msg = defaultLabels.get('accepted_invitation_error_toast_msg');
		let msgHeader = defaultLabels.get('something_went_wrong_toast_msg');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			msg = labels.get('accepted_invitation_error_toast_msg');
			msgHeader = labels.get('something_went_wrong_toast_msg');
		}

		if (!response.success) {
			// this.dispatch(addToast(this.getSuccessToastObject()));
			this.dispatch(addToast(this.getFailuraToastObject(msgHeader, msg)));
		}
		return response;
	}

	successCall = (response) => {
		return response;
	}

}

export default new SetBatchWorkflowStatus();
