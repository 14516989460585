import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';

import AdditionalInformation from 'components/moduleItems/additionalInformation';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import styles from './assignment.module.sass';
import applyLabel from 'utils/LabelProvider';
import Submission from './Submission';

import WriteIcon from 'svgComponents/assignmentIcons/WriteIcon';
import ShareLinkIcon from 'svgComponents/assignmentIcons/ShareLinkIcon';
import UploadIcon from 'svgComponents/assignmentIcons/UploadIcon';

import LastSubmission from 'components/moduleItems/assignment/lastSubmission';
import _ from 'lodash';
import CircularLoader from 'svgComponents/circularLoader';
import ModuleItemUtil from 'utils/moduleItem/moduleItem';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil.js';
import { getTimeInMinutes } from 'utils/dates';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })

class Assignment extends Component {

    componentWillMount() {
        this.setState({
            submissionType: this.autoSelectSubmissionTypeIfOnlyOnePresent(this.props.moduleItem),
            resubmitting: false
        });
    }

    componentDidMount() {
        let completion_requirement = this.props.moduleItem.get('moduleItem').get('completion_requirement');
        if (completion_requirement && completion_requirement.size && completion_requirement.get('type') === 'must_view') {
            this.props.markRead();
        }
    }

    checkIfOnlyOneSubmissionType = (moduleItem) => {
        const assignment = moduleItem.get('contentDetails');
        if (assignment.get('submission_types') && assignment.get('submission_types').size === 1) {
            return true;
        }
        return false;
    }

    autoSelectSubmissionTypeIfOnlyOnePresent = (moduleItem) => {
        console.log('debugconditions: ', this.props.savedBody, ModuleItemUtil.isNLPAssignment(moduleItem)  )
        if(ModuleItemUtil.isNLPAssignment(moduleItem)){
            const savedBody = this.getAssignmentBodyFromLocalStorage();
            if(!savedBody){
                return null;
            }
        }

        const assignment = moduleItem.get('contentDetails');
        if (this.checkIfOnlyOneSubmissionType(moduleItem)) {
            return assignment.get('submission_types').get(0);
        }
        return null;
    }

    setSubmissionType(submissionType) {
        submissionType = submissionType || this.autoSelectSubmissionTypeIfOnlyOnePresent(this.props.moduleItem);
        this.setState({ submissionType });
    }

    resetSubmissionType = () => {
        console.log("reseting submission type")
        this.setState({
            submissionType: this.autoSelectSubmissionTypeIfOnlyOnePresent(this.props.moduleItem),
            resubmitting: false
        });
    }

    getLabelAndIcon = (submissionType) => {
        let { getLabelWithDefaults } = this.props;
        let icon = <span></span>;
        let key = ""
        let defaultValue = ""
        switch (submissionType) {
            case 'online_text_entry':
                key = 'assignment_submission_write_it_label';
                defaultValue = 'Write It'
                icon = <WriteIcon />;
                break;
            case 'online_url':
                key = 'assignment_submission_share_link_label';
                defaultValue = 'Share Link';
                icon = <ShareLinkIcon />;
                break;
            case 'online_upload':
                key = 'assignment_submission_upload_file_label';
                defaultValue = 'Upload file';
                icon = <UploadIcon />;
                break;
            default:
                break;
        }
        return {
            icon: icon,
            label: getLabelWithDefaults(key, defaultValue)
        }
    }

    getSubmissionTypeSelections = () => {
        let { moduleItem } = this.props;
        const styles = getSkin(this.props.skinGuide);
        let submissionTypesList = moduleItem.get('contentDetails').get('submission_types');
        let submissionTypes = submissionTypesList.map((submissionType, index) => {
            let labelAndIcon = this.getLabelAndIcon(submissionType);
            return <div styleName={
                `submission-type-cnt ${submissionTypesList.size === 1 ? 'submission-type-cnt-single' : ''}`}
                className={css(styles.submissionTypeCnt)} onClick={() => { this.setSubmissionType(submissionType) }} key={submissionType}>
                {labelAndIcon.icon}
                <div styleName="submission-type-label" className={css(styles.submissionTypeLabel)}>
                    {labelAndIcon.label}
                </div>
            </div>
        });
        return submissionTypes;
    };

    getTimerSetting = () => {
        const { moduleItem } = this.props;
        const assignment = moduleItem.get('contentDetails');
        return parseInt(assignment.getIn(['al_data', 'time']) || 0, 10);  
    }

    renderNLPSubmissionInstruction = () => {
        const { getLabelWithDefaults } = this.props;

        const submissionInstruction = this.props.getLabelWithDefaults('assignment_nlp_submission_choose_instruction_label', `Please make sure you have read the scenario(s) above. After that, click on Start Writing. The ${getTimeInMinutes(this.getTimerSetting()*1000)}-minute timer starts when you click on the button. The assignment auto-submits after the timer runs out.`);

        const submissionType = 'online_text_entry'; 
        
        return <div styleName="nlp-submission-instruction">
            <div className={css(styles.assignmentNLPInstructionLabel)}>
                <strong>Note</strong>: { submissionInstruction }
            </div>
            <div styleName="submission-types-container">
                <PrimaryButton
                    text={getLabelWithDefaults('assignment_nlp_start_writing_label', "Start Writing")}
                    clickFunction={() => { this.setSubmissionType(submissionType) }} key={submissionType}
                />
            </div>
        </div>;
    }

    getAssignmentBodyFromLocalStorage = () => {
        const {moduleItem} = this.props;
        return ModuleItemUtil.getAssignmentBodyFromLocalStorage('online_text_entry', moduleItem);
    }

    renderSubmissionInstruction = () => {        
        const { moduleItem, getLabelWithDefaults } = this.props;
        
        if(ModuleItemUtil.isNLPAssignment(moduleItem)){
            return this.renderNLPSubmissionInstruction();
        }

        const styles = getSkin(this.props.skinGuide);

        let submissionInstruction = getLabelWithDefaults('assignment_submission_choose_instruction_label', 'To submit this assignment, please select one of the options below');
        
        let submissionTypesList = moduleItem.get('contentDetails').get('submission_types');
        if (submissionTypesList && submissionTypesList.size === 1) {
            submissionInstruction = getLabelWithDefaults('assignment_submission_choose_instruction_label_one_type', 'Click on the option below to start with your assignment');
        }

        if (_.isEqual(submissionTypesList.toJS(), ["none"])) {
            return <span></span>;
        }

        return <div>
            <div className={css(styles.assignmentSubmissionChooseInstructionLabel)}>
                {
                    submissionInstruction
                }:
            </div>
            <div styleName="submission-types-container">
                {this.getSubmissionTypeSelections()}
            </div>
        </div>;
    }

    renderNewSubmissionSection = () => {
        const { moduleItem } = this.props;
        const submissionType = this.state.submissionType;
        
        return submissionType ?
            <Submission
                {...this.props}
                submissionType={submissionType}
                setSubmissionType={(value) => this.setSubmissionType(value)}
                resetSubmissionType={this.resetSubmissionType}
                onlyOneSubmissionType={this.checkIfOnlyOneSubmissionType(moduleItem)}
            />
            : this.renderSubmissionInstruction()
            
    }

    sendSegmentData = () => {
        const { moduleItem, match } = this.props;
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            ...match.params,
            assignmentId: moduleItem.getIn(['moduleItem', 'content_id'])
        }
        window.analytics.track(
            'Assignment resumission started',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onClickResubmit = () => {
        this.sendSegmentData();

        this.setState({
            resubmitting: true
        });
    }

    renderMainSection = () => {
        const { moduleItem, lockDetails, getLabelWithDefaults } = this.props;
        const assignment = moduleItem.get('contentDetails');
        const submission = assignment.get('submission');
        const resubmitting = this.state.resubmitting;
        const hasSubmitted = submission && submission.size > 0 && submission.get('workflow_state') !== 'unsubmitted';
        const styles = getSkin(this.props.skinGuide);


        if (moduleItem.get('assignmentSubmitting')) {
            return <div><div styleName="submitting-message-container">
                <div style={{ height: '43px', width: '43px', margin: 'auto' }}>
                    <CircularLoader />
                </div>
            </div>
                <div styleName="loading-msg-cnt">
                    <div className={css(styles.loadingText)}>
                        {getLabelWithDefaults('assignment_submitting_label', 'Please wait. Submitting your assignment...')}
                    </div>
                </div></div>
        }

        if (hasSubmitted && !resubmitting) {
            return <LastSubmission moduleItem={moduleItem} onClickResubmit={this.onClickResubmit} lockDetails={lockDetails} />;
        }

        return this.renderNewSubmissionSection();
    }

    render() {
        const { moduleItem, getLabelWithDefaults } = this.props;

        return (
            <div>
                <AdditionalInformation
                    title={getLabelWithDefaults('assignment_additional_information_label', 'Additional Information')}
                    body={moduleItem.get('moduleItem').get('description')}
                />
                {
                    this.renderMainSection()
                }
            </div>
        )
    }
}

export default Assignment;
