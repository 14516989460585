import React, { Component } from 'react';
import styles from './FAQPlus.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class FAQPlus extends Component {

    render() {

        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="plus-component">
                <svg width="14px" height="14px" viewBox="0 0 24 24">
                    <g id="Interface-Essential-/-Remove/Add-/-add" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g id="Group" className={css(myStyles.strokeColor)} strokeWidth="1.5">
                            <g id="add">
                                <path d="M0.75,12 L23.25,12" id="Shape" />
                                <path d="M12,0.75 L12,23.25" id="Shape" />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        );
    }
}



export default FAQPlus;
