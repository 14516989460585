import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';
import { hexToRgbA } from '../../../util/styleUtil';

const getSkin = (theme) => {
	const myTypography = typography(theme);
	return StyleSheet.create({
        errorIcon: {
            backgroundColor: '#EB3131',
            color: 'white',
            fontWeight: 'bold'
        },
        title: {
            ...myTypography.overline,
            fontSize: '18px',
            color: '#4E535F',
            fontWeight: '600',
            '@media only screen and (max-width: 480px)': {
                fontSize: '16px'
            }
        },
        details: {
            ...myTypography.overline,
            color: '#4E535F',
            '@media only screen and (max-width: 480px)': {
                fontSize: '14px'
            }
        },
        resolution:{
            backgroundColor: 'rgba(242, 243, 247, 0.4)'
        },
        resolutionTitle:{
            ...myTypography.button,
            color: '#4E535F'
        },
        resolutionDetails:{
            ...myTypography.button,
            color: '#4E535F',
            fontWeight: '400'
        }
	});
}

export default getSkin;