import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { isMSTeams } from 'utils/msTeamsUI';

const getSkin = (theme, hideTopBorder = false) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		journeyinfoContainer: {
			borderTop: hideTopBorder || isMSTeams() ? 'none' : `8px solid ${skinHex.get('secondaryColor')}`
		},
		journeyName: {
			...myTypography.overline,
			fontSize: '22px',
			color: skinHex.get('contentColor'),
		},
		journeyProgressLabel: {
			...myTypography.caption,
			color: hexToRgbA('#2C2C2C', 0.6)
		},
		secondaryButton: {
			...myTypography.button,
			color: skinHex.get('secondaryColor'),
			border: `1px solid ${skinHex.get('secondaryColor')}`,
			borderRadius: '4px',
			cursor: 'pointer',
			textTransform: 'uppercase',
			padding: '5px 10px',
			boxSizing: 'border-box',
			':hover': {
				backgroundColor: skinHex.get('secondaryColor'),
				color: skinHex.get('white')
			}
		},
		upcomingWebSessionMessage: {
			...myTypography.caption,
			color: '#5F5E5E',
			opacity: 0.57,
			lineHeight: '16px'
		},
		upcomingWebSessionTitle: {
			...myTypography.caption,
			color: '#5F5E5E',
			fontSize: '14px',
			fontWeight: 700,
			lineHeight: '19px'
		},
		otherUpcomingSessions: {
			...myTypography.labelSmall,
			fontWeight: '600',
			color: skinHex.get('secondaryColor'),
			textDecoration: 'underline'
		}
	});
}

export default getSkin;