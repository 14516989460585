import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({

		guideHeadersText: {
			...myTypography.button,
			fontWeight: 600,
			color: '#000000CC'
		}
	});
}

export default getSkin;