import React, { Component } from 'react';
import styles from './measuresHeading.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import BackArrow from 'svgComponents/backArrow';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class MeasuresHeading extends Component {

	onClickBackButton = () => {
		const { batchId } = this.props.match.params;
		const route = routes.get('BATCH_HOME');
		const params = [{ BATCH_ID: batchId }];
		updateRoute({ route, params });
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div className={css(myStyles.headingContainer)} styleName="heading-container">
				<div className={css(myStyles.headingImage)} styleName="heading-image" ></div>
				<div styleName="back-button-container">
					<div styleName="back-button-svg" onClick={this.onClickBackButton}>
						<BackArrow color={'rgba(45, 51, 70, 1)'} />
					</div>
				</div>
				<div styleName="heading-content">
					<div className={css(myStyles.headingTitle)} styleName="heading-sub-title">
						{/* {`${this.props.activePhaseDetails.name} ${this.props.getLabel('results_label')}`} */}
						{this.props.getLabel('assessment_results_label')}
					</div>
					{/* <div className={css(myStyles.headingTitle)} styleName="heading-title">
						
					</div> */}
				</div>
			</div>
		);
	}
}

export default MeasuresHeading;
