import Immutable from 'immutable';
import Logo from 'svgComponents/logo';
import JourneySwitcher from 'images/header/journey-switcher.svg';
import UserProfile from 'images/header/user-profile.svg';
import headerConstants from 'constants/header';
import JourneySwitcherComponent from 'commonComponents/journeySwitcher';
import ProfileDropDown from 'commonComponents/profileDropDown';
import MyJourneyLinkComponent from 'commonComponents/myJourneyLinkComponent';

const defaultHeaderConfig = (otherConfigs) => (
	Immutable.fromJS({
		showHeader: true,
		showLoader: true,
		isDataAvailable: otherConfigs.isDataAvailable !== undefined
			? otherConfigs.isDataAvailable
			: headerConstants.get('IS_DATA_AVAILABLE'),
		showLogo: true,
		showLeftComponents: true,
		showMiddleComponents: true,
		showRightComponents: true,
		logo: {
			imagePresent: true,
			imagePath: Logo, //TODO: change it to client and logo branding as well
			showName: true,
			name: otherConfigs.companyName
				? otherConfigs.companyName
				: headerConstants.get('COMPANY_NAME'),
			dropDownPresent: false
		},
		headerComponents: {
			leftComponents: [],
			middleComponents: [],
			rightComponents: [
				{
					imagePresent: false,
					imagePath: JourneySwitcher,
					showName: true,
					name: otherConfigs.journeyName
						? otherConfigs.journeyName
						: headerConstants.get('JOURNEY_NAME'),
					downArrowPresent: true,
					dropDownPresent: true,
					dropDownComponent: JourneySwitcherComponent,
					dropDownComponentProps: otherConfigs.journeySwitcherProps
						? otherConfigs.journeySwitcherProps
						: headerConstants.get('JOURNEY_SWITCHER_PROPS')
				},
				{
					imagePresent: true,
					imagePath: otherConfigs.profileDropDownProps
						? otherConfigs.profileDropDownProps.profilePicUrl || UserProfile
						: UserProfile,
					showName: true,
					name: otherConfigs.userName
						? otherConfigs.userName
						: headerConstants.get('USERNAME'),
					downArrowPresent: true,
					dropDownPresent: true,
					dropDownComponent: ProfileDropDown,
					dropDownComponentProps: otherConfigs.profileDropDownProps
						? otherConfigs.profileDropDownProps
						: headerConstants.get('PROFILE_DROPDOWN_PROPS')
				}
			],
			newHeaderComponents: {
				leftComponents: [],
				middleComponents: [],
				rightComponents: [
					{
						imagePresent: false,
						imagePath: '',
						showName: false,
						name: 'My Journeys',
						downArrowPresent: false,
						dropDownPresent: false,
						dropDownComponent: null,
						dropDownComponentProps: null,
						directComponent: MyJourneyLinkComponent,
						directComponentProps: {}
					},
					{
						imagePresent: true,
						imagePath: otherConfigs.profileDropDownProps
							? otherConfigs.profileDropDownProps.profilePicUrl || UserProfile
							: UserProfile,
						showName: true,
						name: otherConfigs.userName
							? otherConfigs.userName
							: headerConstants.get('USERNAME'),
						downArrowPresent: true,
						dropDownPresent: true,
						dropDownComponent: ProfileDropDown,
						dropDownComponentProps: otherConfigs.profileDropDownProps
							? otherConfigs.profileDropDownProps
							: headerConstants.get('PROFILE_DROPDOWN_PROPS')
					}
				]
			},
			msTeamsHeaderComponents: [
				/* {
					imagePresent: false,
					imagePath: JourneySwitcher,
					showName: true,
					name: otherConfigs.journeyName
						? otherConfigs.journeyName
						: headerConstants.get('JOURNEY_NAME'),
					downArrowPresent: true,
					dropDownPresent: true,
					dropDownComponent: JourneySwitcherComponent,
					dropDownComponentProps: otherConfigs.journeySwitcherProps
						? otherConfigs.journeySwitcherProps
						: headerConstants.get('JOURNEY_SWITCHER_PROPS')
				}, */
				{
					imagePresent: false,
					imagePath: '',
					showName: false,
					name: 'My Journeys',
					downArrowPresent: false,
					dropDownPresent: false,
					dropDownComponent: null,
					dropDownComponentProps: null,
					directComponent: MyJourneyLinkComponent,
					directComponentProps: {}
				},
				{
					imagePresent: true,
					imagePath: otherConfigs.profileDropDownProps
						? otherConfigs.profileDropDownProps.profilePicUrl || UserProfile
						: UserProfile,
					showName: false,
					name: otherConfigs.userName
						? otherConfigs.userName
						: headerConstants.get('USERNAME'),
					downArrowPresent: true,
					dropDownPresent: true,
					dropDownComponent: ProfileDropDown,
					dropDownComponentProps: otherConfigs.profileDropDownProps
						? otherConfigs.profileDropDownProps
						: headerConstants.get('PROFILE_DROPDOWN_PROPS')
				}
			],
			externalIntegrationComponents: [
				/* {
					imagePresent: true,
					imagePath: otherConfigs.profileDropDownProps
						? otherConfigs.profileDropDownProps.profilePicUrl || UserProfile
						: UserProfile,
					showName: false,
					name: otherConfigs.userName
						? otherConfigs.userName
						: headerConstants.get('USERNAME'),
					downArrowPresent: true,
					dropDownPresent: true,
					dropDownComponent: ProfileDropDown,
					dropDownComponentProps: otherConfigs.profileDropDownProps
						? otherConfigs.profileDropDownProps
						: headerConstants.get('PROFILE_DROPDOWN_PROPS')
				} */
			]

		}
	})
)

export default defaultHeaderConfig;