import Immutable from 'immutable';

const monthNames = Immutable.fromJS([
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec"
]);

export default monthNames;