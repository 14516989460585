import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		itemText: {
			...myTypography.caption,
			color: 'white',
			fontWeight: 'normal'
		},
		inputText: {
			...myTypography.button,
			fontWeight: 'normal',
			color: hexToRgbA('#000000', 0.6)
		},
		instructionsText: {
			...myTypography.caption,
			fontWeight: 'normal',
			color: '#ffffffCC',
			padding: '10px 1px 0 10px'
		}
	});
}

export default getSkin;