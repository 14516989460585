/**
 * @author: Amul
 * @since: Thu Sep 5 2018 18:22:31 GMT+0530 (IST)
 * @file: courses.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * course reducer is responsible for the sidebar with course and its modules
 *
 **/

import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
    courseList: [],
    isCourseistAvailable: false
});

const courseList = (state = initialState, action) => {
    switch (action.type) {
        case actionConstants.get('SET_COURSELIST'):
         
            return state.set(
                'courseList',
                Immutable.fromJS(action.payload)
            ).set(
                'isCourseistAvailable',
                true
            );
        case actionConstants.get('SET_COURSELIST_AVAILABILITY'):
            return state.set('isCourseListAvailable', action.status);
        default:
            return state;
    }
}

export default courseList;