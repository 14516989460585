import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		questionText: {
			...myTypography.body1,
			color: skinHex.get('greyColor2'),
			lineHeight: 1.42,
			'@media only screen and (max-width: 480px)': {
				fontSize: '16px',
				lineHeight: 1.1
			}
		}
	});
}

export default getSkin;