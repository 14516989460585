import React, { Component } from 'react';
import styles from './arrowConnectorForDT.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ArrowConnectorForDT extends Component {

	render() {
		return (
			<div styleName="component-cnt">
				<svg width="100%" height="100%" viewBox="0 0 764 49">
					<g fill="none" fillRule="evenodd">
						<path stroke="#4A5EB3" strokeLinecap="square" strokeWidth="2" d="M3.5 1.5h757-757zm1 0v43.196V1.5zm755 0v43.196V1.5z" />
						<path fill="#4A5EB3" d="M754.969 44.019h9.023l-4.511 4.511zM-.031 44.019h9.023L4.481 48.53z" />
					</g>
				</svg>
			</div>
		);
	}
}



export default ArrowConnectorForDT;