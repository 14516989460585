import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './descriptiveQues.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import Question from './../question';
import TextArea from './textArea';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DescriptiveQues extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const {question, submitAnswer} = this.props;
        return (
            <div styleName="question-and-tags-container">
                <Question question={question}/>
                <TextArea question={question} 
                           submitAnswer={submitAnswer} 
                           questionId ={question.get('id')}/>
            </div>
        );
    }
}

export default DescriptiveQues;
