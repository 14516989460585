import React from 'react';
import PropTypes from 'prop-types';
import { Bar, Line } from '@vx/shape';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { LinearGradient } from '@vx/gradient';
import { extent, max } from 'd3-array';import GradientGrid from '../gradientGrid';
import { Text } from '@vx/text';
import { Point } from '@vx/point';
import { getGradientBounds, getRoundedRect } from 'utils/analytics/utilFunctions';
import InfoComponent from 'commonComponents/analytics/infoComponent';
class HorizontalBars extends React.Component {

	height = 'height' in this.props ? this.props.height : 250;
	margin = { top: 50, bottom: 60, left: 150, right: 70 };

	getUserData = d => d.get('userProgress');
	getGroupData = d => d.get('groupAvgProgress');

	getData = () => {
		const graphData = this.props.graphData;
		const data = graphData.get('data');
		const metadata = graphData.get('metadata');
		const strings = graphData.get('strings');
		return {
			data: data,
			metadata: metadata,
			strings: strings
		}
	}

	getWidth = () => {
        const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
        const width = calcWidth < 400 ? 400: calcWidth;
        return width;
	}
	
	getXMax = () => {
		const calcXMax = this.getWidth() - this.margin.left - this.margin.right;
		return (calcXMax < 0 ? this.getWidth() : calcXMax);
	}

	getYMax = () => ( this.height - this.margin.top - this.margin.bottom );

	getXScale = () => {
		const { metadata } = this.getData();
		return scaleLinear({
			range: [0, this.getXMax()],
			domain: [0, metadata.get('max')],
		})
	};

	getYScale = () => (
		scaleBand({
			range: [this.getYMax(), 0],
			domain: [1],
			padding: 0.5,
		})
	);

	renderGraph = () => {
		const { barStyling } = this.props;
		const { data, strings } = this.getData();

		const xMax = this.getXMax();
		const yMax = this.getYMax();

		// scales
		const yScale = this.getYScale();
		const xScale = this.getXScale();

		const userId = "user-gradient" + barStyling.componentName;
		const groupId = "group-avg-gradient" + barStyling.componentName;

		const yourProgressText = strings.get('yAxisLabel2');
		const groupProgressText = strings.get('yAxisLabel1');

		const userBarWidth = xScale(this.getUserData(data));
		const avgBarWidth = xScale(this.getGroupData(data));

		const barDiffWidth = Math.abs(userBarWidth - avgBarWidth);
		const barDiffX = (userBarWidth < avgBarWidth) ? userBarWidth : avgBarWidth;
		const barDiffValue = Math.round(this.getUserData(data) - this.getGroupData(data));

		const barDiffColor = barDiffValue >= 0 ? '#76cb1a' : '#d0021b';
		const barDiffText = barDiffValue > 0 ? '+' + barDiffValue + '%' : barDiffValue + '%';
		let barDiffMsg;
		if (barDiffValue > 0) {
			barDiffMsg = strings.get('aheadTooltip');
		}
		else if (barDiffValue == 0) {
			barDiffMsg = strings.get('equalTooltip');
		}
		else {
			barDiffMsg = strings.get('behindTooltip');
		}

		return (
			<Group className="graph">
				<Line
					from={{x: userBarWidth, y: 10}}
					to={{x: userBarWidth, y: yMax}}
					strokeDasharray={"3,2"}
					stroke={barDiffColor}
					strokeWidth={0.5}
				/>
				<Line
					from={{x: avgBarWidth, y: 10}}
					to={{x: avgBarWidth, y: yMax}}
					strokeDasharray={"3,2"}
					stroke={barDiffColor}
					strokeWidth={0.5}
				/>
				<Bar
					width={barDiffWidth}
					height={yMax-10}
					x={barDiffX}
					y={10}
					fill={barDiffColor}
					fillOpacity={0.1}
					rx={4}
				/>
				<Text
					x={ barDiffX }
					y={ 20 }
					width={ barDiffWidth }
					textAnchor="start"
					verticalAnchor="middle"
					fontSize={14}
					fontFamily={"Open Sans"}
					fill={barDiffColor}
					fontWeight={'bold'}
				>
				{`${barDiffText}`}
				</Text>
				<Text
					x={ barDiffX + 75 }
					y={ 20 }
					width={xMax - (barDiffX + 75)}
					textAnchor="start"
					verticalAnchor="middle"
					fontSize={14}
					fontFamily={"Open Sans"}
					fill={'#ffffff'}
				>
				{`${barDiffMsg}`}
				</Text>

				<path
					d={getRoundedRect(0, yScale(1), userBarWidth, 40, 4, 'right')}
					style={{ fill: `url(#${userId})` }}
				/>
				<Text
					x={ -25 }
					y={ yScale(1) + 20 }
					textAnchor="end"
					verticalAnchor="middle"
					fontSize={14}
					fontFamily={"Open Sans"}
					fill={"#979eb7"}
				>
				{`${yourProgressText}`}
				</Text>
				<Text
					x={ (userBarWidth - 30) >= 0 ? userBarWidth - 5 : 25 }
					y={ yScale(1) + 20 }
					textAnchor="end"
					verticalAnchor="middle"
					fontSize={16}
					fontFamily={"Open Sans"}
					fill={"#ffffff"}
					fontWeight={'bold'}
				>
					{`${this.getUserData(data)}%`}
				</Text>

				<path
					d={getRoundedRect(0, yScale(1)+50, avgBarWidth, 20, 4, 'right')}
					style={{ fill: `url(#${groupId})` }}
				/>
				<Text
					x={ -25 }
					y={ yScale(1) + 60 }
					textAnchor="end"
					verticalAnchor="middle"
					fontSize={14}
					fontFamily={"Open Sans"}
					fill={"#979eb7"}
				>
				{`${groupProgressText}`}
				</Text>
				<Text
					x={ (avgBarWidth - 30) >= 0 ? avgBarWidth - 5 : 25 }
					y={ yScale(1) + 60 }
					textAnchor="end"
					verticalAnchor="middle"
					fontSize={14}
					fontFamily={"Open Sans"}
					fill={"#ffffff"}
					fontWeight={'bold'}
				>
				{`${this.getGroupData(data)}%`}
				</Text>
			</Group>
		);
	}

    renderGraphComponent = () => {
		const bottomAxisLabelStyling = {
			fill: "#979eb7",
			textAnchor: "middle",
			fontSize: 14,
			fontFamily: 'Open Sans'
		};

		const labelStyling = {
            fontFamily: 'Open Sans',
            fontSize: 15,
            fill: '#979eb7',
            fillOpacity: 0.7,
            textAnchor: 'middle'
        };

		const { barStyling, graphFormat } = this.props;

        const width = this.getWidth();
		const height = this.height;
		const margin = this.margin;

		const xMax = this.getXMax();
		const yMax = this.getYMax();

		// scales
		const yScale = this.getYScale();
		const xScale = this.getXScale();

		const userId = "user-gradient" + barStyling.componentName;
		const groupId = "group-avg-gradient" + barStyling.componentName;

		const userGradientBounds = getGradientBounds(barStyling.userRotation);
		const groupGradientBounds = getGradientBounds(barStyling.groupRotation);

		return(
			<div style={{ width: width, height: height, position: 'relative' }}>
				<svg width={width} height={height}>
					<LinearGradient
						from={barStyling.fromUser}
						to={barStyling.toUser}
						{...userGradientBounds}
						id={userId}
					/>
					<LinearGradient
						from={barStyling.fromGroup}
						to={barStyling.toGroup}
						{...groupGradientBounds}
						id={groupId}
					/>

					<Group top={margin.top} left={margin.left}>
						<GradientGrid scale={xScale} xMax={xMax} yMax={yMax} numTicks={10} hasHorizontalGrids={true}/>
						<AxisLeft
							scale={yScale}
							top={0}
							stroke={'#1b1a1e'}
							tickTextFill={'#1b1a1e'}
							hideTicks={true}
							hideZero={true}
							tickFormat={() => '' }
							stroke={'white'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
						/>
						<AxisBottom
							scale={xScale}
							top={yMax}
							stroke={'#1b1a1e'}
							hideTicks={true}
							hideZero={true}
							tickLabelProps={() => bottomAxisLabelStyling}
							labelProps={labelStyling}
							stroke={'white'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
							label={graphFormat.xLabel}
						/>
						{this.renderGraph()}
					</Group>
				</svg>
			</div>
        );
	}

	render() {
		const { data } = this.getData();
        if (data.size == 0) {
			return <InfoComponent 
						{...this.props} 
						infoMessage="This graph does not have data"/>
        }
		return (this.renderGraphComponent());
	}
}

HorizontalBars.propTypes = {
	graphData: PropTypes.object.isRequired,
	barStyling: PropTypes.object
}

HorizontalBars.defaultProps = {
	
}

export default HorizontalBars;