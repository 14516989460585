import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setUserReferences } from 'actions/userProfile/actionCreators';
import { setUserDetails } from 'actions/users/actionCreators';
import defaultLabels from 'constants/labels';

class SyncUser extends NetPackWrapper {

	constructor() {
		super("PUT");
	}

	prepareUrl = () => urls.get('PLATFORM').get('JOURNEY_SYNC_USER');

	successCall = (response, apiInfoObject) => {
		this.dispatch(setUserReferences(apiInfoObject.body));
		this.dispatch(setUserDetails({
			firstName: apiInfoObject.body.firstName,
			lastName: apiInfoObject.body.lastName,
			profilePicUrl: apiInfoObject.body.profilePicUrl
		}))

		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let msgHeader = defaultLabels.get('done_toast_msg');
		let msg = defaultLabels.get('profile_changes_saved_success_toast_msg');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			msgHeader = labels.get('done_toast_msg');
			msg = labels.get('profile_changes_saved_success_toast_msg');
		}

		this.dispatch(addToast({
			id: toastConstants.get('ID_SUCCESS'),
			type: toastConstants.get('TYPE_SUCCESS'),
			heading: msgHeader,
			message: msg,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));

		return response;
	}

	onError = (error) => {
		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let msgHeader = defaultLabels.get('something_went_wrong_toast_msg');
		let msg = defaultLabels.get('profile_changes_saved_error_toast_msg');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			msgHeader = labels.get('something_went_wrong_toast_msg');
			msg = labels.get('profile_changes_saved_error_toast_msg');
		}

		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: msgHeader,
			message: msg,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		return error;
	}
}

export default new SyncUser();
