import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        buttonStyle: {
            backgroundColor: skin.get('white'),
            color: skin.get('primaryColor'),
            border: `1px solid ${skin.get('primaryColor')}`,
            ':hover': {
                backgroundColor: skin.get('primaryVariantColor'),
                color: skin.get('white')
            }
        },
        buttonStyleWithoutShadow: {
            backgroundColor: skin.get('white'),
            color: skin.get('primaryColor'),
            borderRadius: '6px'
        },
        buttonText: {
            ...myTypography.button,
            fontSize: '16px',
            textTransform: 'unset'
        }

    });
}

export default getSkin;