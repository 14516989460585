import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules({ allowMultiple: true })
class RatingStar extends Component {

    render() {
        const skinHex = this.props.skinGuide.get('skinHex');
        const strokeColor = this.props.strokeColor || '#DCDCDC'
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25">
                <path fill={this.props.color} fillRule="evenodd" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M13.532 1.446l3.422 6.629 6.587.638a.81.81 0 0 1 .752.869.798.798 0 0 1-.24.505l-5.421 5.253 2.01 7.138a.811.811 0 0 1-.591.994.849.849 0 0 1-.587-.06l-6.677-3.232-6.669 3.229a.84.84 0 0 1-1.116-.361.797.797 0 0 1-.061-.574l2.01-7.138-5.425-5.253a.792.792 0 0 1-.005-1.139.831.831 0 0 1 .516-.235l6.587-.638 3.417-6.625a.847.847 0 0 1 1.491 0z" />
            </svg>
        );
    }
}



export default RatingStar;
