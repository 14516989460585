import React, { Component } from 'react';
import styles from './wikiPagesIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class WikiPagesIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			// <div>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 14">
				<g fill="none" fillRule="evenodd" stroke="#828593" strokeLinecap="round" strokeLinejoin="round">
					<path d="M14.667 1.4c0-.344-.299-.622-.667-.622H2c-.368 0-.667.278-.667.622v11.2c0 .344.299.622.667.622h12c.368 0 .667-.278.667-.622V1.4zM3.997 3.265h8M3.997 5.754h8M3.997 8.243h8M3.997 10.731h3.67" />
				</g>
			</svg>
			// </div>
		);
	}
}



export default WikiPagesIcon;
