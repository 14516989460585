import actionConsts from 'constants/actions';

const setPhasesList = payload => ({
    type: actionConsts.get('SET_PHASES_LIST'),
    payload
});

const setUserPhasesList = (payload) => {
     
    return {
        type: actionConsts.get('SET_USER_PHASES_LIST'),
        payload
    };
};

const updatePhaseDetailedData = payload => ({
    type: actionConsts.get('UPDATE_PHASE_DETAILED_DATA'),
    payload
})

const updateUserPhaseDetailedData = payload => ({
    type: actionConsts.get('UPDATE_USER_PHASE_DETAILED_DATA'),
    payload
})

const setActivePhaseId = activePhaseId => ({
    type: actionConsts.get('SET_ACTIVE_PHASE_ID'),
    activePhaseId
})

export {
    setPhasesList,
    setUserPhasesList,
    updatePhaseDetailedData,
    setActivePhaseId,
    updateUserPhaseDetailedData
};