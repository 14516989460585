import React, { Component } from 'react';
import styles from './toastComponent.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import Close from 'svgComponents/close';
import {
	Error,
	Warning,
	Notification,
	Success
} from 'svgComponents/toastIcons';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ToastComponent extends Component {

	constructor(props) {
		super(props);
	}

	getLeftBorderColor = (myStyles) => {
		switch(this.props.type) {
			case "ERROR":
				return css(myStyles.error);
			case "SUCCESS":
				return css(myStyles.success);
			case "NOTIFICATION":
				return css(myStyles.notification);
			case "WARNING":
				return css(myStyles.warning);
			default:
				return css(myStyles.notification);
		}
	}

	getToastIcon = () => {
		switch(this.props.type) {
			case "ERROR":
				return <Error />
			case "SUCCESS":
				return <Success />
			case "NOTIFICATION":
				return <Notification />
			case "WARNING":
				return <Warning />
			default:
				return <Notification />
		}
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="toast-container">
				<div styleName="toast-close">
					<Close color={this.props.skinGuide.get('skinHex').get('greyColor3')}/>
				</div>
				<div styleName="toast-content-container">
					<div className={this.getLeftBorderColor(myStyles)} styleName="toast-border-left"></div>
					<div styleName="toast-content">
						<div styleName="toast-content-heading">
							<div styleName="toast-heading-icon">
								{this.getToastIcon()}
							</div>
							<div className={css(myStyles.heading)} styleName="toast-heading-text">
								{this.props.heading}
							</div>
						</div>
						<div className={css(myStyles.message)} styleName="toast-content-message">
							{this.props.message}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ToastComponent;