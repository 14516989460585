import React, { useEffect, useState } from 'react';
import CSSModules from 'react-css-modules';
import ShareCertificateOnLinkedin from 'actions/serverCalls/reports/ShareCertificateOnLinkedin';
import CircularLoader from 'svgComponents/circularLoader';
import returnFromLinkedinStyles from './returnFromLinkedin.module.sass';
import reports from 'constants/reports';
import applyLabel from 'utils/LabelProvider';

const ReturnFromLinkedin = ({ getLabel }) => {
  const [postingCertificateOnLinkedIn, setPostingCertificateOnLinkedIn] = useState(true);
  const [successfullyPosted, setSuccessfullyPosted] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState(null);
  
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');
    const code = params.get('code');
    const state = params.get('state');

    if(error){
      window.close();
    } else if(code) {
      const postCertificateOnLinkedIn = async () => {
        const shareResult = await ShareCertificateOnLinkedin.call({
          body: {
            "reportType": reports.get('shareCertificateOnLinkedin'),
            "reportOutputType": "linkedIn",
            "metaData": {
                "batchId": +window.localStorage.getItem('BATCH_ID'),
                "userId":  +window.localStorage.getItem('USER_ID')
            }
          },
          queryParameters: { code, state },
          headers: {
              'Content-type': 'application/json'
          }
        });
        setPostingCertificateOnLinkedIn(false);
        setLinkedinUrl(`https://linkedin.com/feed/update/${shareResult.postId}`)
        setSuccessfullyPosted(true);
      }
  
      if (code) {
        postCertificateOnLinkedIn();
      }
    }

  }, []);

  if(postingCertificateOnLinkedIn){
    return (
      <div styleName='outer'>
        <div style={{ height: '100%', width: '43px', margin: 'auto' }}>
          <CircularLoader/>
        </div>
      </div>
    )
  }
  
  if(successfullyPosted){
    return <div styleName='outer'>
      <div styleName='success-text'>        
        {getLabel('linkedin_certificate_update_label')}<br/>
        <a href={linkedinUrl} target='_blank' rel='noreferrer' onClick={window.close}>{getLabel('linkedin_click_to_see_label')}</a></div>
    </div>
  }

  return <>{getLabel('linkedin_certificate_post_fail_label')}</>
  
}

export default applyLabel(CSSModules(ReturnFromLinkedin, returnFromLinkedinStyles));