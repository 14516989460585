import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            borderRadius: '4px',
            border: `solid 1px ${skin.get('greyColor5')}`
        },
        text: {
            ...myTypography.overline,
            color: skin.get('greyColor4'),
            fontWeight: 600
        }
    });
}

export default getSkin;