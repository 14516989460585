import actionConsts from 'constants/actions';

const setUserReferences = (userData) => ({
	type: actionConsts.get('SET_USER_REFERENCES'),
	userData
})

const setUserReferencesLoading = (flag) => ({
	type: actionConsts.get('SET_USER_REFERENCES_LOADING'),
	flag
})

const setDemographicsData = (userData) => ({
	type: actionConsts.get('SET_DEMOGRAPHICS_DATA'),
	userData
})

const setDemographicsDataLoading = (flag) => ({
	type: actionConsts.get('SET_DEMOGRAPHICS_DATA_LOADING'),
	flag
})

const setPasswordUpdating = (flag) => ({
	type: actionConsts.get('SET_PASSWORD_UPDATING'),
	flag
})

const setPasswordError = (message) => ({
	type: actionConsts.get('SET_PASSWORD_UPDATING_ERROR'),
	message
})

const setNotificationSubscriptionDetails = (subscriptionDetails) => ({
	type: actionConsts.get('SET_NOTIFICATION_SUBSCRIPTION_DETAILS'),
	subscriptionDetails
})

const setProfileUpdating = (flag) => ({
	type: actionConsts.get('SET_PROFILE_UPDATING'),
	flag
})

export {
	setUserReferences,
	setUserReferencesLoading,
	setDemographicsData,
	setDemographicsDataLoading,
	setPasswordUpdating,
	setPasswordError,
	setNotificationSubscriptionDetails,
	setProfileUpdating,
};
