const setHue = function (primary, value){
	primary.h = primary.h + value;
	return primary;
}

const setSaturation = function (primary, value){
	primary.s = primary.s + value;
	return primary;
}

const setBrightness = function (primary, value){
	primary.l = primary.l + value;
	return primary;
}

// var getColorFromHslCode = function (hslObject){
// 	var string = hslObject.h + "," + hslObject.s + "%," + hslObject.l + "%"; 
// 	return Color('hsl(' + string + ')');
// }

const deriveColor = (primaryColorObj, h = -2, s = 1, l = -2) => {
    setHue(primaryColorObj, h);
    setSaturation(primaryColorObj, s);
    setBrightness(primaryColorObj, l);
    return primaryColorObj;
}

//  

//  

// var derivedColor = getColorFromHslCode(primary);


function componentToHex(c) {
    var hex = Math.round(c).toString(16);
    return hex.length == 1 ? "0" + hex : hex;
}

function rgbToHex(rgbObj) {
    return "#" + componentToHex(rgbObj.r) + componentToHex(rgbObj.g) + componentToHex(rgbObj.b);
}

const hexToRgb = (hex) => {
    if(hex == undefined) {
        return;
    }
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        // return "rgba("+[(c>>16)&255, (c>>8)&255, c&255].join(",")+","+opacity+")";
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")})`;
    }
    console.error('Bad Hex', hex);
}

const hexToRgbA = (hex, opacity = 1) => {
    if(hex == undefined) {
        return;
    }
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        // return "rgba("+[(c>>16)&255, (c>>8)&255, c&255].join(",")+","+opacity+")";
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},${opacity})`;
    }
    console.error('Bad Hex', hex);
}

const typography = (skin) => {
    return {
        h1: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '96px',
            fontWeight: 300,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 0.29,
            letterSpacing: 'normal'
        },
        h2: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '60px',
            fontWeight: 300,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 0.47,
            letterSpacing: 'normal'
        },
        h3: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '48px',
            fontWeight: 400,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 0.58,
            letterSpacing: 'normal'
        },
        h4: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '34px',
            fontWeight: 400,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 0.82,
            letterSpacing: 'normal'
        },
        h5: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '24px',
            fontWeight: 400,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.17,
            letterSpacing: 'normal'
        },
        h6: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '20px',
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.4,
            letterSpacing: 'normal'
        },
        overline: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '16px',
            fontWeight: 400,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.5,
            letterSpacing: 'normal'
        },
        body1: {
            fontFamily: skin.get('fontFamily').get('bodyFamily'),
            fontSize: '18px',
            fontWeight: 300,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.22,
            letterSpacing: 'normal'
        },
        body2: {
            fontFamily: skin.get('fontFamily').get('bodyFamily'),
            fontSize: '16px',
            fontWeight: 300,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.38,
            letterSpacing: 'normal'
        },
        button: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '14px',
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.43,
            letterSpacing: 'normal'
        },
        caption: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '12px',
            fontWeight: 600,
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 1.67,
            letterSpacing: 'normal'
        },
        labelSmall: {
            fontFamily: skin.get('fontFamily').get('headingsFamily'),
            fontSize: '10px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            lineHeight: 2.4,
            letterSpacing: 'normal'
        }
    }
}

export {
    rgbToHex,
    hexToRgb,
    hexToRgbA,
    typography,
    deriveColor
};