import React, { useEffect, useState, useRef } from 'react';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import CSSModules from 'react-css-modules';
import SessionActiveIcon from 'svgComponents/sessionActiveIcon';
import CloseCircle from 'svgComponents/closeCircle';
import { css } from 'aphrodite/no-important';
import styles from './webSessionDialog.module.sass';
import getSkin from './skin.js';
import { getEndOfToday } from 'utils/dates';
import { getFormattedSessionDate, WEB_SESSION_END_TIMEOUT_BUFFER } from 'utils/webSessions';
import { setLocalStorageItemWithExpiry, getLocalStorageItemWithExpiry } from 'utils/localStorage';

const WebSessionDialog = ({sessionDetails, refreshTime, skinGuide, getLabel}) => {
    const { hasActiveSession, hasUpcomingSessionToday, session, batch } = sessionDetails;    
    const upcomingWebSessionDialogSeen = getLocalStorageItemWithExpiry('upcomingWebSessionDialogSeen');
    const activeWebSessionDialogSeen = getLocalStorageItemWithExpiry('activeWebSessionDialogSeen');
    console.log('debugws: upcomingWebSessionDialogSeen', upcomingWebSessionDialogSeen);
    console.log('debugws: activeWebSessionDialogSeen', activeWebSessionDialogSeen);
    
    const [isActive, setIsActive] = useState(hasActiveSession);
    const [isDialogOpen, setDialogOpen] = useState((hasActiveSession && !activeWebSessionDialogSeen) || (hasUpcomingSessionToday && !upcomingWebSessionDialogSeen ));
    
    const setActiveTimeout = useRef(null);
    const setFinishedTimeout = useRef(null);

    useEffect(() => {
        console.log('debugws WebSessionDialog useEffect');
        const twoMinutesInMilliseconds = 120000;
        if(!hasActiveSession || hasUpcomingSessionToday){
            const timeToGoForTheSession = Math.abs(new Date(session.get('startDate')) - new Date());
            console.log('debugws WebSessionDialog timeToGoForTheSession', timeToGoForTheSession);
            if(timeToGoForTheSession <= twoMinutesInMilliseconds){ //if less than or equal to 2 mins to go
                console.log('debugws WebSessionDialog setDialogOpen', true);
                setDialogOpen(true);
                setIsActive(true);    
            } else {
                console.log('debugws WebSessionDialog setTimeout', timeToGoForTheSession - WEB_SESSION_END_TIMEOUT_BUFFER);
                setActiveTimeout.current = window.setTimeout(() => {
                    console.log('debugws WebSessionDialog setTimeout executing');
                    setDialogOpen(true)
                    setIsActive(true);
                }, timeToGoForTheSession - WEB_SESSION_END_TIMEOUT_BUFFER);
            }
        }

        const timeToGoForTheSessionEnd = Math.abs(new Date(session.get('endDate')) - new Date());
        console.log('debugws: timeToGoForTheSessionEnd', timeToGoForTheSessionEnd)
        setFinishedTimeout.current = window.setTimeout(() => {
            console.log('debugws WebSessionDialog session finished timeout executing');
            closeDialog(isActive);
        }, timeToGoForTheSessionEnd - twoMinutesInMilliseconds);

        return () => { // on unmount
            console.log('debugws: WebSessionDialog unmounting')
            window.clearTimeout(setActiveTimeout.current)
            window.clearTimeout(setFinishedTimeout.current);
        }

    }, []);

    const myStyles = getSkin(skinGuide);
    const course = batch.get('journey');
    let imageSrc = batch.getIn(['journey', 'catalogImageUrl']);
    if (!imageSrc)
    imageSrc = "https://webassets.knolskape.com/misc/ajinkya_wankhade/2019/11/25/52/videoimage.png";

    const openSessionLink = (webSession) => {
        window.open(session.get('link'));
    }

    const renderJoinSessionButton = (webSession) => {
        return <div styleName='join-session-button' className={css(myStyles.joinSession)} onClick={() => openSessionLink(webSession)}>
            <div styleName="websession-active-icon-cnt"><SessionActiveIcon/></div>
            <div className={css(myStyles.joinText)}>{getLabel('websession_join_label_big')}</div>
        </div>;
    };

    const closeDialog = (isActive) => {
        console.log('debugws: close dialog clicked', isActive);
        const flag = isActive ? 'activeWebSessionDialogSeen': 'upcomingWebSessionDialogSeen';
        console.log('debugws: setting session seen flag', flag, getEndOfToday());
        setLocalStorageItemWithExpiry(flag, true, getEndOfToday());
        setDialogOpen(false);
    }

    if(!isDialogOpen) return null;

    return <div styleName='main'>
        <div styleName='overlay' onClick={() => closeDialog(isActive)}/>
        <div styleName='main-container' className={css(myStyles.mainContainer)}>
            <div className={css(myStyles.heading)} styleName='heading'>{
                isActive ? getLabel('websession_dialog_started_label') : getLabel('websession_dialog_upcoming_label')
            }
            </div>
            <div styleName="popup-close-icon" onClick={() => closeDialog(isActive)}>
                <CloseCircle circleFill="#575E79" circleFillOpacity="1" />
            </div>
            <div styleName='details-container'>
                <img src={imageSrc} alt='Course Thumbnail' styleName='course-thumbnail'/>
                <div styleName='textual-details'>
                    <div styleName='course-title-cnt'>
                        <div className={css(myStyles.courseTitle)} styleName='course-title'>{course.get('name')}</div>
                        {
                            batch.getIn(['journey', 'format', 'key']) &&
                            <div className={css(myStyles.formatName)} styleName="format-tag">
                                {getLabel(`format_${batch.getIn(['journey', 'format', 'key'])}_label`)}
                            </div>
                        }
                    </div>
                    <div styleName='session-details'>
                        <div className={css(myStyles.sessionTitle)} styleName="session-title">{session.get('title')}</div>
                        <div className={css(myStyles.sessionDateTime)} styleName="session-date">
                            <span className={css(myStyles.labelBold)}>{getLabel('websession_dialog_starts_label')}: </span>
                            {getFormattedSessionDate(session.get('startDate'))}
                        </div>
                        <div className={css(myStyles.sessionDateTime)} styleName='session-time'>
                            <span className={css(myStyles.labelBold)}>{getLabel('websession_dialog_ends_label')}: </span>                           
                            {getFormattedSessionDate(session.get('endDate'))}
                        </div>
                        <div className={css(myStyles.facilitator)} styleName="facilitator">
                            <span className={css(myStyles.labelBold)}>{getLabel('websession_led_by_label')}: </span>      
                            {session.get('facilitatorName')}
                        </div>
                        <div className={css(myStyles.sessionDescription)} styleName="description">{session.get('description')}</div>
                    </div>
                </div>
            </div>
            {
                isActive && <div styleName="active-websession-join-button-cnt" className={css(myStyles.joinSessionButtonCnt)}>
                    {renderJoinSessionButton(session)}       
                </div>
            }
            <div style={{display: 'none'}}>{refreshTime}</div>
        </div>
    </div>;
}

export default applySkin(applyLabel(CSSModules(WebSessionDialog, styles)));