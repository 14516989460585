import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules({ allowMultiple: true })
class Performance extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (

            <svg width="100%" height="100%" viewBox="0 0 13 13">
                <g className={css(myStyles.fillColor)} fillRule="nonzero">
                    <path d="M6.5 0A6.5 6.5 0 1 0 13 6.5 6.507 6.507 0 0 0 6.5 0zm0 11.917a5.417 5.417 0 1 1 0-10.834 5.417 5.417 0 0 1 0 10.834z" />
                    <path d="M9.255 2.463a.542.542 0 0 0-.75.148L6.661 5.362a.135.135 0 0 1-.126.06.343.343 0 0 0-.041-.005 1.219 1.219 0 1 0 1.218 1.218 1.2 1.2 0 0 0-.14-.56.135.135 0 0 1 .007-.138l1.824-2.722a.542.542 0 0 0-.149-.752zM2.438 6.224h-.542a.406.406 0 1 0 0 .813h.542a.406.406 0 1 0 0-.813zM3.196 4.258l-.47-.27a.406.406 0 1 0-.405.703l.469.271a.406.406 0 0 0 .406-.704zM4.479 3.542a.405.405 0 0 0 .351-.609l-.27-.468a.406.406 0 0 0-.705.406l.27.469a.405.405 0 0 0 .354.203zM6.504 3.01a.406.406 0 0 0 .407-.406v-.541a.406.406 0 1 0-.813 0v.541c0 .225.182.407.406.407zM10.67 4.727a.406.406 0 1 0-.405-.705l-.47.271a.406.406 0 0 0 .406.704l.47-.27zM11.072 6.265h-.542a.406.406 0 1 0 0 .812h.542a.406.406 0 0 0 0-.812z" />
                </g>
            </svg>

        );
    }
}



export default Performance;
