import Immutable from 'immutable';
import { connect } from 'react-redux';

const fontFamilyObj = {
    "headingsFamily": "Open Sans",
    "bodyFamily": "Mukta",
    "bodyFamily2": "Merriweather"
};

const skinHex = {
    "primaryColor": "#2C4BB7",
    "primaryVariantColor": "#223B92",
    "secondaryColor": "#D44980",
    "secondaryVariantColor": "#C44175",
    "greyColor1": "#091027",
    "greyColor2": "#2E344B",
    "greyColor3": "#575E79",
    "greyColor4": "#979EB7",
    "greyColor5": "#D5D9E7",
    "greyColor6": "#f2f3f7",
    "black": "#000000",
    "white": "#ffffff",
    "darkSkyBlue": "#4a90e2",
    "pinkRed": "#ff0365",
    "appleGreen": "#76ca1a",
    "scarlet": "#d0021b",
    "orangeyYellow": "#f8ac1c",
    "contentColor": "#282828"
};

const skinRGBA = {
    "greyColor4": "rgba(151, 158, 183, 1)", // #979EB7
    "greyColor5": "rgba(213, 217, 231, 1)", // #979EB7
    "greyColor6": "rgba(242, 243, 247, 1)", // #f2f3f7
    "white": "rgba(255, 255, 255, 1)", // #FFFFFF
    "black": "rgba(0, 0, 0, 1)" // #000000
};

const defaultSkinGuide = Immutable.fromJS({
    fontFamily: fontFamilyObj,
    skinHex: skinHex,
    skin: skinRGBA
});

const mapStateToProps = ((state) => {
    // const activeBatchId = state.get('batches').get('activeBatchId');
    const skin = state.get('skin');

    let skinGuide = defaultSkinGuide;
    if (skin.size !== 0) {
        skinGuide = skinGuide.set('skinHex', skinGuide.get('skinHex').merge(skin));

    }
    return {
        skinGuide
    };
});

const mapDispatchToProps = () => ({})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps
})

const applySkin = (WrappedComponent) => connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(WrappedComponent);

export default applySkin;