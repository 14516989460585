import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { appendQueryParameters } from 'utils/utilFunctions';

import { updateItemProgressInList } from 'actions/items/actionCreators'
class FetchItemProgress extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject) => {
        const url = urls.get('PLATFORM').get('GET_ITEM_PROGRESS').replace('ITEM_ID', apiInfoObject.itemId);
        return appendQueryParameters(url, apiInfoObject.queryParameters);
    }

    successCall = (response, apiInfoObject) => {
        this.dispatch(updateItemProgressInList({
            ...response,
            itemId: apiInfoObject.itemId
        }))
    }

    onError = (error) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong while saving progress.',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new FetchItemProgress();
