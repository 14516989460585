import React, { Component } from 'react';
import styles from './feedbackIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class FeedbackIcon extends Component {

	render() {
		const fillColor = this.props.svgColor || "#979EB7";

		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 14 13">
					<g fill={fillColor} fillRule="nonzero">
						<path d="M12.861 0H1.14C.51 0 0 .517 0 1.153v8.442c0 .637.51 1.153 1.139 1.154h2.75v1.66c0 .24.142.454.36.546.218.091.469.04.636-.128l2.05-2.078h5.926C13.49 10.748 14 10.232 14 9.595V1.153C14 .517 13.49.001 12.861 0zm-.028 9.271c0 .164-.13.296-.291.296H6.694a.58.58 0 0 0-.412.173l-1.225 1.24v-.826a.587.587 0 0 0-.583-.591l-3.016.03a.29.29 0 0 1-.208-.086.297.297 0 0 1-.086-.21l-.024-7.82c0-.163.13-.295.292-.295h11.11c.16 0 .291.132.291.295v7.794z" />
						<path d="M3.764 7.682h.583a.268.268 0 0 0 .292-.237V4.373a.268.268 0 0 0-.292-.237h-.583a.268.268 0 0 0-.292.237v3.072c.018.146.147.251.292.237zM5.394 7.292l1.373.63c.22.097.459.147.7.146h1.561c.338 0 .639-.219.745-.544l.658-2a.805.805 0 0 0-.106-.717 1.003 1.003 0 0 0-.728-.375h-.875V2.807a.734.734 0 0 0-.73-.739c-.402 0-.728.33-.728.739v.148c0 .94-.835 1.272-1.549 1.272h-.201a.294.294 0 0 0-.292.296v2.5c0 .116.067.221.171.269z" />
					</g>
				</svg>

			</div>
		);
	}
}



export default FeedbackIcon;
