import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        sidebarContainer:{
            borderRight: `solid 1px ${skin.get('greyColor5')}`
        }

    });
}

export default getSkin;