import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import NextActivityImage from 'images/dashboard/nextBackground.png'

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		catalogueText: {
			...myTypography.body1,
			color: skinHex.get('secondaryColor'),
			fontWeight: 600,
			margin: '20px 0'
		},
		categoryName: {
			...myTypography.h5,
			lineHeight: 'normal',
			color: '#282828'
		},
		filterName: {
			...myTypography.body1,
			color: 'rgba(9, 16, 39, 0.85)',
			fontWeight: '400'
		},
		noJourneyText: {
			...myTypography.body1,
			fontWeight: 600,
			color: 'rgba(0, 0, 0, 0.65)'
		}
	});
}

export default getSkin;