import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setQuiz, fetchingQuizFlag } from 'actions/surveyQuiz/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
class FetchQuiz extends NetPackWrapper {

  constructor() {
    super("GET");
  }

  prepareUrl = (apiInfoObject) => {
    const url = urls.get('PLATFORM').get('GET_QUIZ').replace(
      'QUIZ_ID',
      apiInfoObject.quizId);
    console.log('fetch url', url)
    return appendQueryParameters(url, apiInfoObject.queryParameters);
  }

  beforeCall = (apiInfoObject = {}) => {
    this.dispatch(fetchingQuizFlag(true));
    return apiInfoObject;
  }


  successCall = (response) => {
    this.dispatch(setQuiz(response));
    return response;
  }

  onError = (response) => {
    this.dispatch(addToast({
      id: toastConstants.get('ID_ERROR'),
      type: toastConstants.get('TYPE_ERROR'),
      heading: 'Something went wrong',
      message: 'Sorry, something went wrong while loading the quiz. Please refresh the page.',
      isDisplayed: false,
      autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
    }));
  }

}

export default new FetchQuiz();
