import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setCoursesList } from 'actions/courses/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';


class FetchCourses extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
        return urls.get('DEVELOPMENT_PHASE').get('COURSES_LIST')
        .replace('_BATCH_ID_', apiInfoObject.batchId)
        .replace('_PHASE_ID_', apiInfoObject.phaseId)
    };

    successCall = (response, apiInfoObject) => {
        this.dispatch(setCoursesList(response, apiInfoObject));
        this.dispatch(setApiError(
            'fetchCoursesError',
            null
        ));  
        return response;
    }

    onError = (error) => {
        this.dispatch(setApiError(
            'fetchCoursesError',
            'Something went wrong'
        ));
    }
    

}

export default new FetchCourses();
