import React, { Component } from 'react';
import styles from './textBased.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import QuestionText from '../questionText';
import { debounce } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TextBased extends Component {

	constructor(props) {
		super(props);
		this.state = {
			value: props.question.get('textValue') || ''
		}
	}

	saveAnswer = debounce(() => {
		const { feedbackItemId, question, submitAnswer } = this.props;
		const payload = {
			textValue: this.state.value
		};
		submitAnswer(payload, question.get('id'), feedbackItemId);
	}, 2000)

	handleValueChange = (event) => {
		this.setState({
			value: event.target.value
		}, () => {
			this.saveAnswer()
		})
	}

	render() {
		const { question, getLabelWithDefaults } = this.props;
		const { value } = this.state;
		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div styleName="text-based-question-cnt">
				<QuestionText question={question} />
				<div styleName="text-area-cnt">
					<textarea
						placeholder={getLabelWithDefaults('tell_us_more_label', "Tell us more...")}
						className={css(myStyles.textArea)}
						onScroll={e => e.stopPropagation()}
						styleName="text-area"
						name="body"
						onChange={this.handleValueChange}
						value={value || ''}
						style={value ? { backgroundColor: 'white' } : {}}
					/>
				</div>
			</div>
		);
	}
}

export default TextBased;
