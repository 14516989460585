import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		orgListItem: {
			...myTypography.button,
			color: hexToRgbA(skinHex.get('greyColor2'), 0.8),
		},
		setActiveButton: {
			color: skinHex.get('secondaryColor'),
			cursor: 'pointer'
        },
        headerText: {
			...myTypography.overline,
			color: skinHex.get('contentColor'),
			fontWeight: 600
		},
		expiredOrg: {
			color: skinHex.get('scarlet')
		},
		lockedOrg: {
			color: hexToRgbA(skinHex.get('greyColor3'))
		}
	});
}

export default getSkin;