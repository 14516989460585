import React, { Component } from 'react';
import styles from './innerListItem.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import ModuleItemUtil from 'utils/moduleItem/moduleItem';
import CompletedIcon from 'svgComponents/completedIcon';
import Recommended from 'svgComponents/recommended';
import { checkIfPresent, getDurationStringUsingUnits } from 'utils/utilFunctions';
import { getLockIcon } from 'utils/locks';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil'

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class InnerListItem extends Component {

    scrollIntoView = () => {
        try {
            this.el.scrollIntoView({ behavior: "smooth", block: "center" });
        } catch (error) {
            this.el.scrollIntoView();
        }
    }

    componentDidMount() {
        if (this.props.status == 'active' && this.el) {
            this.scrollIntoView();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.status != 'active') {
            if (this.props.status == 'active' && this.el) {
                this.scrollIntoView();
            }
        }
    }

    getStyleForDotAndName = () => {
        const { status, module, moduleItem } = this.props;
        const myStyles = getSkin(this.props.skinGuide);

        if (status == 'active') {
            return {
                name: css(myStyles.selectedName),
                dot: css(myStyles.selected)
            }
        }

        if (module) {
            if (parseInt(module.get('items_completed_count')) == parseInt(module.get('required_items_count'))) {
                return {
                    name: css(myStyles.completedName),
                    dot: css(myStyles.completed)
                }
            }
        }

        if (moduleItem) {
            if (moduleItem.getIn(['completion_requirement', 'completed'])) {
                return {
                    name: css(myStyles.completedName),
                    dot: css(myStyles.completed)
                }
            }
        }

        return {
            name: css(myStyles.completedName),
            dot: css(myStyles.disabled)
        }
    }

    getStyleForLine = () => {
        const { itemsListLength, currentElementIndex, module } = this.props;
        const myStyles = getSkin(this.props.skinGuide);

        if (itemsListLength <= 1) {
            return css(myStyles.noLine);
        }

        if (currentElementIndex === (itemsListLength - 1)) {
            if (module) {
                return css(myStyles.lastLineModule);
            }
            return css(myStyles.lastLine);
        } else if (currentElementIndex == 0) {
            if (module) {
                return css(myStyles.firstLineModule);
            }
            return css(myStyles.firstLine);
        }
        else {
            return css(myStyles.line);
        }
    }

    getPercentageCompletion = (module) => {
        if (parseInt(module.get('required_items_count')) == 0) {
            return 0;
        }
        return (parseInt(module.get('items_completed_count')) / parseInt(module.get('required_items_count'))) * 100;
    }

    getDateElement = (moduleItem, phaseItemData) => {
        const { getLabelWithDefaults } = this.props;
        const myStyles = getSkin(this.props.skinGuide);
        const completedLabel = <span styleName="completed-label"> <CompletedIcon /> {getLabelWithDefaults('completed_label', 'Completed')} </span>;
        const lockDetails = ModuleItemUtil.getModuleItemLockDetails(moduleItem, phaseItemData);

        let dateText = ModuleItemUtil.getDateText(moduleItem, getLabelWithDefaults, completedLabel, lockDetails);

        if (dateText) {
            let style = css(myStyles.date);
            if (lockDetails.expired && !lockDetails.completed) {
                style = css(myStyles.expiredDate);
            }

            return <div className={style} styleName="name date">
                {dateText}
            </div>;
        }

        return null;
    }

    getItemSeperator = () => {
        const { currentElementIndex, itemsListLength } = this.props;
        if (currentElementIndex != itemsListLength - 1) {
            return <div styleName="item-divider"></div>
        }
        return null;
    }


    getLockedElement = (moduleItem, phaseItemData) => {
        const skinHex = this.props.skinGuide.get('skinHex');
        const lockDetails = ModuleItemUtil.getModuleItemLockDetails(moduleItem, phaseItemData);
        return <div styleName="lock-icon-cnt">
            {getLockIcon(lockDetails.expired && !lockDetails.completed, true, skinHex.get('greyColor4'))}
        </div>;
    }

    onItemClick = (isLocked, lockDetails) => {
        const { moduleItem, phaseItemData } = this.props;
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            selectedItemName: moduleItem.get('title'),
            selectedItemId: moduleItem.get('id'),
            batchId: phaseItemData.get('batchId'),
            alCourseId: phaseItemData.get('toolId'),
            journeyId: phaseItemData.get('journeyId'),
            phaseItemId: phaseItemData.get('id'),
            itemType: moduleItem.get('type_label'),
            itemLocked: isLocked,
            itemExpired: lockDetails.expired,
            itemCompleted: lockDetails.completed
        }
        window.analytics.track(
            `Sidebar Module Item Clicked - ${moduleItem.get('type_label')}`,
            segmentData,
            getAmplitudeSessionDetails()
        )
        if (isLocked) return;
        this.props.onItemClick();
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        let {  moduleItem, isSidebarOpen, getLabelWithDefaults, formLabelForModuleItem, phaseItemData } = this.props;
        const style = this.getStyleForDotAndName();
        const dotStyle = style.dot;
        const nameStyle = style.name;
        const lineStyle = this.getStyleForLine();
        const lockDetails = ModuleItemUtil.getModuleItemLockDetails(moduleItem, phaseItemData);
        const isLocked = lockDetails.locked || (lockDetails.expired && !lockDetails.completed);
        let dateText = this.getDateElement(moduleItem, phaseItemData)
        
        return (
            <div styleName={`outer ${dateText ? '' : 'without-date'}`} ref={el => { this.el = el }}
                onMouseEnter={this.showLockTooltip}
                onMouseLeave={this.removeLockTooltip}
            >

                {
                    (isSidebarOpen)
                        ?
                        <div
                            styleName={`main-container ${isLocked ? 'locked-item' : ''}`}
                            onClick={(e) => { e.stopPropagation(); this.onItemClick(isLocked, lockDetails) }}
                            className={css(myStyles.mainContainer)}
                        >
                            <div styleName="status-container">
                                <div styleName="column-container">
                                    <div styleName="dot" className={dotStyle} />
                                    <div styleName="line" className={lineStyle} />
                                </div>
                                <div styleName="column-container text-container">
                                    <div className={css(myStyles.type)} styleName="name type">
                                        <div styleName="type-duration">
                                            <div>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    {
                                                        getLabelWithDefaults(
                                                            formLabelForModuleItem(
                                                                moduleItem.get('type_label'),
                                                                moduleItem.get('sub_type')
                                                            ),
                                                            moduleItem.get('type_label'))
                                                    }
                                                    {
                                                        moduleItem.get('recommend') &&
                                                        <div styleName="recommended-cnt">
                                                            <div styleName="recommended-icon-cnt"><Recommended /></div>
                                                            <div style={{ lineHeight: 'unset', marginLeft: '3px' }}>Recommended</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                            {
                                                checkIfPresent(moduleItem.get('duration')) &&
                                                <div>
                                                    {getDurationStringUsingUnits(moduleItem.get('duration'), moduleItem.get('duration_unit'))}
                                                </div>
                                            }

                                        </div>
                                    </div>

                                    <div styleName="name module-item-title" className={nameStyle}>
                                        <span styleName="title-inner" title={moduleItem.get('title')}>
                                            {isLocked ? this.getLockedElement(moduleItem, phaseItemData) : null}
                                            {moduleItem.get('is_optional') && <span>({getLabelWithDefaults('optional_label')}) </span>}
                                            {moduleItem.get('title')}
                                        </span>
                                    </div>

                                    {dateText}

                                    {this.getItemSeperator()}

                                </div>
                            </div>
                        </div>


                        :
                        <div styleName="collapsed-container">
                            <div styleName="dot" className={dotStyle} />
                            <div styleName="line" className={lineStyle} />
                        </div>
                }

            </div>
        );
    }
}

export default InnerListItem;
