import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreComponentDevelopment.module.sass';
import ScoreWithInfoBarsDevelopment from './scoreWithInfoBarsDevelopment/ScoreWithInfoBarsDevelopment';
import ScoreWithRadarGraphsDevelopment from './scoreWithRadarGraphsDevelopment/ScoreWithRadarGraphsDevelopment';


@CSSModules(styles, { allowMultiple: true })

class ScoreComponentDevelopment extends React.Component {

    render(){
          
        return(
            <div styleName="outer-dev-graph-container">
                <ScoreWithInfoBarsDevelopment {...this.props} />
                <ScoreWithRadarGraphsDevelopment {...this.props} />
            </div>
        )
    }
}
 

export default ScoreComponentDevelopment;