import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        footerContainer: {
            backgroundImage: `linear-gradient(to bottom, ${skin.get('white')}, #fafaff)`,
            borderTop: `1px solid rgba(151, 151, 151, 0.16)`
        },
        footerContent: {
            ...myTypography.caption,
            color: hexToRgbA('#5b5c61', 0.6),
            fontStyle: 'normal',
        },
        footerImage: {
            mixBlendMode: 'luminosity',
            opacity: 0.5,
            filter: 'grayscale(100)'
        },
        socialIconContainer: {
            background: '#4268C5',
            height: '20px',
            width: '20px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginRight: '10px',
            textDecoration: 'none'
        }
	});
}

export default getSkin;