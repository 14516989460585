import { StyleSheet } from 'aphrodite';
import { hexToRgb, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		titleStyle: {
			...myTypography.h5,
			fontSize: '24px',
			fontWeight: 400,
			color: '#282828'
		},

		subTitleStyle: {
			...myTypography.h5,
			fontSize: '18px',
			fontWeight: 300,
			color: '#979eb7'
		},

		getButtonStyle: {
			...myTypography.h5,
			border: `solid 1px ${skin.get('secondaryColor')}`,
			color: skin.get('secondaryColor'),
			fontSize: '16px',
			cursor: 'pointer',
			fontWeight: 600,
			':hover': {
				color: skin.get('white'),
				backgroundColor: skin.get('secondaryVariantColor')
			}
		}
	});
}

export default getSkin;