import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './overLappingBarsWithInfo.module.sass';
import InfoAndDaysFilterComponent from 'commonComponents/analytics/infoAndDaysFilterComponent';
import OverlappingBars from 'commonComponents/graphs/overlappingBars';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class OverLappingBarsWithInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterBy: ''
        };
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchProgressPeriodicallyData(+batchId, +userId);
    }

    handleOnClickFilter = (event, filter) => {
        this.setState({
            filterBy: filter
        });
    }

    renderOverlappingBarsWithInfo = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        if(this.props.apiErrors.get('fetchProgressPeriodicallyDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchProgressPeriodicallyDataError')}
                retryHandler={()=>{
                    this.props.fetchTimeSpentPeriodicallyData(+batchId, +userId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('activity');

        if (allGraphsData.get('isProgressPeriodicallyDataFetched')) {
            const graphData = allGraphsData.get('progressPeriodicallyData');
            const strings = graphData.get('strings');
            const filterBy = this.state.filterBy ? this.state.filterBy : graphData.get('filtersEnabledObj').get('selectedFilter').toLowerCase();
           
            let filteredGraphData;
            
            if(graphData.get('isUserDataAvailable')) {
                filteredGraphData = graphData.get(filterBy).toJS();
            } else {
                filteredGraphData = graphData.get('week').toJS();
            }

            let xLabel, valueName, timeFormat = '';
            if (filterBy === 'day') {
                xLabel = strings.get('days');
                valueName = strings.get('day');
            }
            else if (filterBy === 'week') {
                xLabel = strings.get('weeks');
                valueName = strings.get('week');
            }
            else if (filterBy === 'month') {
                xLabel = strings.get('months');
                valueName = strings.get('month');
            }

            const graphFormat = {
                xLabel: xLabel,
                yLabel: strings.get('yAxisLabel'),
                valuePrefix: '',
                valueFormat: timeFormat,
                valueName: valueName
            };

            const barStyling = {
                fromUser: '#ffce23',
                toUser: '#fea429',
                userRotation: 176,
                fromGroup: analyticGroupColors.fromGroup,
                toGroup: analyticGroupColors.toGroup,
                groupRotation: 356,
                gridValue: 9.5,
                componentName: 'items'
            }

            return(
                [<InfoAndDaysFilterComponent
                    filterObject = {graphData.get('filtersEnabledObj')}
                    {...this.props}
                    title={strings.get('graphTitle')}
                    subtitle={strings.get('graphDesc')}
                    clickHandler={this.handleOnClickFilter}
                    strings={strings}
                    key="InfoAndDaysFilterComponent"
                />,
                <OverlappingBars
                    isUserDataAvailable={graphData.get('isUserDataAvailable')}
                    skinGuide={this.props.skinGuide}
                    barStyling={barStyling}
                    // data={this.props.data.itemsData}
                    size={this.props.size}
                    graphFormat={graphFormat}
                    graphData={filteredGraphData}
                    hasAverageLines={false}
                    strings={strings.toJS()}
                    key="OverlappingBars"
                />]
            );
        }

        return <LoaderComponent />;
    }

    render() {
        return(
            <div styleName="container-class">
                <div styleName="area-graph-container">
                    <div styleName="area-graph">
                        {this.renderOverlappingBarsWithInfo()}
                    </div>
                </div>
            </div>
        )
    }
}

export default OverLappingBarsWithInfo;