/**
 * @author: Amul
 * @since: Mon Jul 05 2021 17:22:03 GMT+0530 (India Standard Time)
 * @file: attachments.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

 import actionConstants from 'constants/actions';
 import Immutable from 'immutable';
 import { setOrUpdateObjectInList, mergeListsBasedOnKey } from 'utils/objectUtils';

 
 const initialState = Immutable.fromJS({
    list: [],
    listWithDetails: [],
    loading: true
 });
 
 const attachments = (state = initialState, action = {}) => {
     switch (action.type) {
        case actionConstants.get('PUSH_TO_ATTACHMENTS_LIST'):
            return state.set(
                'list',
                mergeListsBasedOnKey(state.get('list'), action.list, 'uuid')
            );
        case actionConstants.get('UPDATE_ATTACHMENT_IN_LIST'):
            return state.set(
                'listWithDetails',
                setOrUpdateObjectInList(state.get('listWithDetails'), action.attachmentDetails, 'uuid', 'uuid')
            );
        default:
            return state;
     }
 };
 
 export default attachments;