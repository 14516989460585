import React, { Component } from 'react';
import styles from './primaryButtonMini.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class PrimaryButtonMini extends Component {

    onClickOfPrimaryButton = () => {
        const { disabled, clickFunction } = this.props;
        if (disabled) {
            return;
        } else {
            clickFunction();
        }
    }

    render() {
        var buttonShadow = true;
        if (this.props.showButtonShadow !== undefined && this.props.showButtonShadow !== null) {
            buttonShadow = this.props.showButtonShadow;
        }
        const { disabled } = this.props;
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div
                className={buttonShadow ? css(myStyles.buttonStyle) : css(myStyles.buttonStyleWithoutShadow)}
                styleName="button-style"
                style={disabled ? { cursor: 'not-allowed', opacity: '0.7' } : {}}
                onClick={this.onClickOfPrimaryButton}
            >
                <div className={css(myStyles.buttonText)} styleName="button-text">{this.props.text}</div>
            </div>
        );
    }
}

export default PrimaryButtonMini;
