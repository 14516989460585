/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: batches.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * batches reducer is responsible for batches related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrUpdateObjectInList, mergeListsBasedOnKey } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    batchesList: [],
    userBatchesList: [],
    activeBatchId: -1,
    batchesListAdditionalDetails: {},
    userBatchesListAdditionalDetails: {},
    batchIdFromRoute: 1,
    isUserbatchesListFetched: false,
    isBatchesListFetched: false,
    isActiveBatchFetched: false,
    ongoingUserBatches: [],
    upcomingUserBatches: [],
    completedUserBatches: [],
    expiredUserBatches: [],
    requestedUserBatches: [],
    ongoingBatchesListAdditionalDetails: {},
    upcomingBatchesListAdditionalDetails: {},
    completedBatchesListAdditionalDetails: {},
    expiredBatchesListAdditionalDetails: {},
    requestedBatchesListAdditionalDetails: {},
    isOngoingBatchesFetching: false,
    isUpcomingBatchesFetching: false,
    isCompletedBatchesFetching: false,
    isExpiredBatchesFetching: false,
    isRequestedBatchesFetching: false,
    isRequestingJourneyAccess: false,
    isSwitchingBatch: false,
    isResetBatchProgressFailed: false,
    listOfBatchIdsWithAllDataAvailable: []
});

const batches = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_BATCHES_LIST'):
            return state.set(
                'batchesList',
                mergeListsBasedOnKey(state.get('batchesList'), action.payload.batchesList, 'id')
            ).set(
                'batchesListAdditionalDetails',
                Immutable.fromJS(action.payload.batchesListAdditionalDetails)
            ).set(
                'isBatchesListFetched',
                true
            );
        case actionConsts.get('SET_USER_BATCHES_LIST'):
            return state.set(
                'userBatchesList',
                mergeListsBasedOnKey(
                    state.get('userBatchesList'), action.payload.userBatchesList, 'batchId'
                )
            ).set(
                'userBatchesListAdditionalDetails',
                Immutable.fromJS(action.payload.userBatchesListAdditionalDetails)
            ).set(
                'isUserbatchesListFetched',
                true
            );
        case actionConsts.get('SET_ACTIVE_BATCH_ID'):
            return state.set(
                'activeBatchId', action.activeBatchId
            );
        case actionConsts.get('SET_ACTIVE_BATCH_AND_PHASE_ID'):
            return state.set(
                'activeBatchId', action.payload.activeBatchId
            ).set('isActiveBatchFetched', true);
        case actionConsts.get('UPDATE_BATCH_DETAILED_DATA'):
            return state.set(
                'batchesList',
                setOrUpdateObjectInList(
                    state.get('batchesList'), action.payload, 'id', 'id'
                )
            );
        case actionConsts.get('UPDATE_USER_BATCH_DETAILED_DATA'):
            return state.set(
                'userBatchesList',
                setOrUpdateObjectInList(
                    state.get('userBatchesList'), action.payload, 'batchId', 'batchId'
                )
            );
        case actionConsts.get('UPDATE_BATCH_FROM_ROUTE'):
            return state.set('batchIdFromRoute', +action.batchIdFromRoute);
        /* case actionConsts.get('SET_BATCH_LABELS'):
            const updatedLabelsDetailsFlagForBatch = {
                id: action.payload.batchId,
                isLabelsDataAvailable: true
            };
            return state.set(
                'batchesList',
                setOrUpdateObjectInList(
                    state.get('batchesList'), updatedLabelsDetailsFlagForBatch, 'id', 'id'
                )
            ); */
        // case actionConsts.get('SET_BATCH_SKIN'):
        //     const updatedSkinDetailsFlagForBatch = {
        //         id: action.payload.batchId,
        //         isSkinDataAvailable: true
        //     };
        //     return state.set(
        //         'batchesList',
        //         setOrUpdateObjectInList(
        //             state.get('batchesList'), updatedSkinDetailsFlagForBatch, 'id', 'id'
        //         )
        //     );
        case actionConsts.get('SET_ONGOING_USER_BATCHES'):
            return state.set(
                'ongoingUserBatches',
                Immutable.fromJS(action.payload.userBatchesList)
            ).set(
                'isOngoingBatchesFetching',
                false
            );
        case actionConsts.get('SET_UPCOMING_USER_BATCHES'):
            return state.set(
                'upcomingUserBatches',
                Immutable.fromJS(action.payload.userBatchesList)
            ).set(
                'isUpcomingBatchesFetching',
                false
            );
        case actionConsts.get('SET_COMPLETED_USER_BATCHES'):
            return state.set(
                'completedUserBatches',
                Immutable.fromJS(action.payload.userBatchesList)
            ).set(
                'isCompletedBatchesFetching',
                false
            );
        case actionConsts.get('SET_EXPIRED_USER_BATCHES'):
            return state.set(
                'expiredUserBatches',
                Immutable.fromJS(action.payload.userBatchesList)
            ).set(
                'isExpiredBatchesFetching',
                false
            );
        case actionConsts.get('SET_REQUESTED_USER_BATCHES'):
            return state.set(
                'requestedUserBatches',
                Immutable.fromJS(action.payload.userBatchesList)
            ).set(
                'isRequestedBatchesFetching',
                false
            );
        case actionConsts.get('SET_FETCHING_FILTERED_BATCHES_FLAG'):
            switch (action.flagName) {
                case "ONGOING":
                    return state.set(
                        'isOngoingBatchesFetching',
                        true
                    )
                case "UPCOMING":
                    return state.set(
                        'isUpcomingBatchesFetching',
                        true
                    )
                case "COMPLETED":
                    return state.set(
                        'isCompletedBatchesFetching',
                        true
                    )
                case "EXPIRED":
                    return state.set(
                        'isExpiredBatchesFetching',
                        true
                    )
            }
        case 'SET_REQUESTING_JOURNEY_ACCESS':
            return state.set(
                'isRequestingJourneyAccess',
                action.flag
            )
        case 'SET_BATCH_SWITCHING':
            return state.set(
                'isSwitchingBatch',
                action.flag
            )
        case 'SET_RESET_BATCH_PROGRESS_FAILED':
            return state.set(
                'isResetBatchProgressFailed',
                action.flag
            )

        case actionConsts.get('SET_BATCH_LOADED_WITH_ALL_DATA'):
            return state.update('listOfBatchIdsWithAllDataAvailable', list => list.push(action.batchId));
        default:
            return state;
    }
};

export default batches;