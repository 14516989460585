import React from 'react';
import { connect } from 'react-redux';
import { fetchBatchDetails } from 'actions/batches/newApiActions';

import { updateBatchIdFromRoute } from 'actions/batches/actionCreators';
import { setAnalyticsData } from 'actions/users/actionCreators';

import AnalyticsComponent from 'components/analytics/analyticsComponent';
import { fetchTimeSpentPeriodicallyData, 
		fetchOverallPerformanceData, 
		fetchNeedleMovementData, 
		fetchAssessmentMeasuresData, 
		fetchGenericAnalyticsData } from 'actions/analytics/apiActions';
import { filterObjectFromArray } from 'utils/objectUtils';
import JourneyLearningsPhaseLoader from 'commonComponents/loaders/journeyLearningsPhaseLoader';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import {
	startTracking,
	endTracking
} from 'utils/mixpanelTracking';

import { shouldShowBatchAnalytics } from 'utils/utilFunctions';

import NotAvailable from 'commonComponents/notAvailable/NotAvailable';

class AnalyticsContainer extends React.Component {

	componentDidMount() {
		const { batchId } = this.props.match.params;
		const userId = this.props.user.get('userID');
		this.props.fetchBatchDetails(batchId);
		this.props.fetchGenericAnalyticsData(+batchId, +userId);
		// this.props.fetchNeedleMovementData(batchId);
		startTracking(true, {
            batchId: this.props.match.params.batchId
        }, 'AnalyticsDashboard');
	}

	componentWillReceiveProps(nextProps) {
		const { batchId } = nextProps.match.params;
		if (this.props.match.params.batchId != batchId) {
			this.props.fetchBatchDetails(batchId);
			endTracking();
			startTracking(true, {
				batchId: nextProps.match.params.batchId
			}, 'AnalyticsDashboard');
		}
	}

	componentWillUnmount(){
		endTracking();
	}

	render() {
		const userState = this.props.userState;
		const { batches, apiErrors } = this.props;

		const activeBatchId = batches.get('activeBatchId');
		const batchesList = batches.get('batchesList');
		const userBatchesList = batches.get('userBatchesList');
		const activeUserBatch = filterObjectFromArray(userBatchesList, 'batchId', activeBatchId);
		const isGenericDataFetched = this.props.allGraphsData.get('genericData').get('isGenericDataFetched');		
		const { user } = this.props;

		if(apiErrors.get('fetchGenericAnalyticsDataError')){
            return <APIErrorBoundary 
                key={activeBatchId} 
                apiError={apiErrors.get('fetchGenericAnalyticsDataError')}
                retryHandler={()=>{
					this.props.fetchGenericAnalyticsData(+activeBatchId, +user.get('userID'));
				}}
            />
        }

		if (activeBatchId === -1 || activeUserBatch.isEmpty() || batchesList.isEmpty() || !user.get('isUserDataAvailable') || !isGenericDataFetched ) {
			return <JourneyLearningsPhaseLoader />
		}

		if(!shouldShowBatchAnalytics(batches)){
			return <NotAvailable>Analytics are not enabled for this batch</NotAvailable>
		}

		return (
			<AnalyticsComponent data={userState.toJS()} {...this.props} />
		);
	}
}

const mapStateToProps = (state) => ({
	batches: state.get('batches'),
	userState: state.get('userState'),
	allGraphsData: state.get('allGraphsData'),
	phases: state.get('phases'),
	user: state.get('user'),
	apiErrors: state.get('apiErrors')
});

const mapDispatchToProps = (dispatch) => ({
	fetchBatchDetails: (batchId) => {
		dispatch(fetchBatchDetails(batchId));
	},
	updateBatchIdFromRoute: (batchIdFromRoute) => {
		dispatch(updateBatchIdFromRoute(batchIdFromRoute));
	},
	setAnalyticsData: () => {
		dispatch(setAnalyticsData());
	},
	fetchNeedleMovementData: (batchId, userId) => {
		dispatch(fetchNeedleMovementData(batchId, userId))
	},
	fetchTimeSpentPeriodicallyData: (batchId, userId) => {
		dispatch(fetchTimeSpentPeriodicallyData(batchId, userId))
	},
	fetchOverallPerformanceData: (batchId, userId) => {
		dispatch(fetchOverallPerformanceData(batchId, userId))
	},
	fetchAssessmentMeasuresData: (batchId, userId, phaseId) => {
		dispatch(fetchAssessmentMeasuresData(batchId, userId, phaseId))
	},
	fetchGenericAnalyticsData: (batchId, userId) => {
		dispatch(fetchGenericAnalyticsData(batchId, userId))
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsContainer);