import React, { Component } from 'react';
import styles from './skillSvg.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SkillSvg extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="skill-component">
				<svg width="100%" height="100%" viewBox="0 0 67 97">
					<defs>
						<filter id="skillCovered" width="109.5%" height="185.7%" x="-4.8%" y="-42.9%" filterUnits="objectBoundingBox">
							<feGaussianBlur in="SourceGraphic" stdDeviation="1"/>
						</filter>
					</defs>
					<g fill="none" fillRule="evenodd" transform="translate(1)">
						<g stroke={skinHex.get('primaryColor')} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
							<path d="M48 14L33.413 28.587a1.997 1.997 0 0 1-2.826 0L26 24M12.579 35.072L2 48l10 2 4 12 10.64-16.885M51.421 35.072L62 48l-10 2-4 12-10.64-16.885"/>
							<path d="M52 25.432l2.25 5.91a1.997 1.997 0 0 1-.773 2.386l-5.296 3.467-1.653 6.106a2 2 0 0 1-2.03 1.475l-6.317-.315-4.925 3.968c-.734.59-1.778.59-2.512 0l-4.925-3.976-6.318.315a2 2 0 0 1-2.029-1.475l-1.653-6.106-5.296-3.467a1.997 1.997 0 0 1-.774-2.387L12 25.432 9.75 19.52a2 2 0 0 1 .773-2.4l5.296-3.467 1.653-6.106a2 2 0 0 1 2.03-1.475l6.317.328 4.925-3.957a2.005 2.005 0 0 1 2.512 0L38.181 6.4"/>
						</g>
						<ellipse cx="32.5" cy="91.5" fill="#4A4A4A" filter="url(#skillCovered)" opacity=".1" rx="31.5" ry="3.5"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default SkillSvg;
