import actionConstants from 'constants/actions';

const parseErrorHandler = (parseError) => ({
    type: actionConstants.get('ERROR'),
    error: parseError
})

const backendErrorHandler = (backendError) => ({
    type: actionConstants.get('ERROR'),
    error: backendError
})

const networkErrorHandler = (networkError) => ({
    type: actionConstants.get('ERROR'),
    error: networkError
})

export {
    parseErrorHandler,
    backendErrorHandler,
    networkErrorHandler
};
