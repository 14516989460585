import React, { Component } from 'react';
import styles from './itemDetails.module.sass';
import CSSModules from 'react-css-modules';
import ProductInstruction from "./productInstruction";
import ProductDuration from './productDuration';
import ProductVideo from './productVideo';
import AssessmentItemDetailsLoader from 'commonComponents/loaders/assessmentItemDetailsLoader';
import applyLabel from 'utils/LabelProvider';
import enumConstants from 'constants/enums';
import VideoPlayerLoader from 'commonComponents/loaders/videoPlayerLoader';
import AttachmentContainer from 'containers/AttachmentContainer';
import PageContainer from 'containers/PageContainer';
import SurveyQuizContainer from 'containers/SurveyQuizContainer';
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ItemDetails extends Component {

    shouldComponentUpdate(nextProps) {
        if (
            JSON.stringify(nextProps.selectedItem) === JSON.stringify(this.props.selectedItem)
            && JSON.stringify(nextProps.isSideBarOpen) === JSON.stringify(this.props.isSideBarOpen)
            && JSON.stringify(nextProps.productLaunchDetails) === JSON.stringify(this.props.productLaunchDetails)
        ) {
            return false;
        }
        return true;
    }

    renderProductDuration = () => {
        const { selectedItem, isSideBarOpen, selectedItemProgress } = this.props;
        return <ProductDuration selectedItem={selectedItem} isSideBarOpen={isSideBarOpen} selectedItemProgress={selectedItemProgress} />;
    }

    renderProductVideo = () => {
        const { selectedItem } = this.props;
        return (
            <ProductVideo selectedItem={selectedItem} />
        );
    }

    renderProductInstruction = () => {
        const { selectedItem, activePhaseDetails } = this.props;
        return <ProductInstruction
            selectedItem={selectedItem}
            isLearningPhaseItem={
                activePhaseDetails &&
                activePhaseDetails.type === 'Learning'}
        />;
    }

    renderAttachmentPlayer = () => {
        const { selectedItem, setItemWorkflowStatus, selectedItemProgress, productLaunchDetails, fetchProductLaunchData, isProductLaunchDetailsAvailable } = this.props;
        const isAttachmentItem = selectedItem.get('type') === enumConstants.get('ATTACHMENT');
        const isFullscreenItem = selectedItem.getIn(['settings', 'launchInFullScreen']) === "true";
        if (isAttachmentItem && !isFullscreenItem) {
            return <div styleName="attachment-container">
                <AttachmentContainer
                    key={`attachment_phase_item_${selectedItem.get('id')}`}
                    attachmentId={selectedItem.get('attachmentId')}
                    onStart={() => setItemWorkflowStatus(enumConstants.get('STARTED'))}
                    onProgress={this.props.saveItemProgress}
                    progress={selectedItemProgress}
                    isAlreadyCompleted={selectedItemProgress && (selectedItemProgress.get('workflowState') === enumConstants.get('COMPLETED'))}

                    productLaunchDetails={productLaunchDetails}
                    fetchProductLaunchData={fetchProductLaunchData}
                    isProductLaunchDetailsAvailable={isProductLaunchDetailsAvailable}
                />
            </div>
        }
        return null;
    }

    renderPage = () => {
        const { selectedItem, setItemWorkflowStatus, selectedItemProgress, selectedUserItem } = this.props;
        return <div styleName="page-container">
            <PageContainer
                key={`page_phase_item_${selectedItem.get('id')}`}
                pageId={selectedItem.get('toolId')}
                onStart={() => setItemWorkflowStatus(enumConstants.get('STARTED'))}
                onProgress={this.props.saveItemProgress}
                progress={selectedItemProgress}
                selectedUserItem={selectedUserItem}
                isAlreadyCompleted={selectedItemProgress && (selectedItemProgress.get('workflowState') === enumConstants.get('COMPLETED'))}
            />
        </div>
    }

    renderQuiz = () => {
        const { selectedItem, setItemWorkflowStatus, selectedItemProgress, selectedUserItem, isSideBarOpen } = this.props;
        return <div styleName="quiz-container">
            <SurveyQuizContainer
                key={`quiz_phase_item_${selectedItem.get('id')}`}
                quizId={selectedItem.get('toolId')}
                onStart={() => setItemWorkflowStatus(enumConstants.get('STARTED'))}
                onProgress={this.props.saveItemProgress}
                progress={selectedItemProgress}
                selectedUserItem={selectedUserItem}
                itemId={selectedItem.get('id')}
                batchId={selectedItem.get('batchId')}
                isAlreadyCompleted={selectedItemProgress && (selectedItemProgress.get('workflowState') === enumConstants.get('COMPLETED'))}
                instructions={selectedItem.get('playInstructions')}
                isSideBarOpen={isSideBarOpen}
                lockDetails={selectedUserItem.get('lock_details')}
            />
        </div>
    }

    renderItemDetailsAndDuration = (contentStyle) => {
        const { selectedItem } = this.props;
        const isPageItem = selectedItem.get('type') === enumConstants.get('PAGE');
        const isQuizItem = selectedItem.get('type') === enumConstants.get('QUIZ');
        const isPageOrQuizItem = isPageItem || isQuizItem;
        return (
            <div styleName="product-and-duration">
                <div styleName={contentStyle}>
                    {this.renderAttachmentPlayer()}
                    {isPageItem ? this.renderPage() : null}
                    {isQuizItem ? this.renderQuiz() : null}
                    {!isPageOrQuizItem ? this.renderProductInstruction() : null}
                    {
                        !isPageOrQuizItem && this.props.isSideBarOpen
                            ? <div styleName="duration-container-row">
                                {this.renderProductDuration()}
                            </div>
                            : null
                    }
                    {!isPageOrQuizItem ? this.renderProductVideo() : null}
                </div>
                {
                    isPageOrQuizItem || this.props.isSideBarOpen
                        ? null
                        : <div styleName="duration-container">
                            {this.renderProductDuration()}
                        </div>
                }
            </div>
        );
    }

    renderItemDetails = () => {
        if (!this.props.selectedItem || !this.props.isBatchLabelsAvailable) {
            return (
                <div>
                    <div styleName="product-content-closed loader-container">
                        <AssessmentItemDetailsLoader />
                    </div>
                    <div styleName="video-loader-content">
                        <VideoPlayerLoader />
                    </div>
                </div>
            );
        }

        let contentStyle = 'product-content-closed';
        if (this.props.isSideBarOpen) {
            contentStyle = 'product-content-open';
        }
        return this.renderItemDetailsAndDuration(contentStyle);
    }

    render() {
        return (
            <div styleName="product-exp-container">
                <div styleName="product-items-container">
                    {this.renderItemDetails()}
                </div>
            </div>
        );
    }
}

export default ItemDetails;
