import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setOverallPerformanceData } from 'actions/analytics/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchOverallPerformanceData extends NetPackWrapper {

    constructor() {
        super("GET");        
	}

    prepareUrl = (apiInfoObject) => {        
        return urls.get('ANALYTICS').get('GET_OVERALL_PERFORMANCE_DATA')
        .replace('BATCH_ID', apiInfoObject.batchId)
        .replace('USER_ID', '' + apiInfoObject.userId)
    }

    successCall = (response) => {
        this.dispatch(setOverallPerformanceData(response));
        this.dispatch(setApiError('fetchOverallPerformanceDataError',null));
        return response;
    }

    onError = (errorObj) => {
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchOverallPerformanceDataError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchOverallPerformanceData();
