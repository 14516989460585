import React, { Component } from 'react';
import styles from './developmentPhaseItem.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { textTruncate, convertDateWithYear, getDurationString } from 'utils/utilFunctions';
import ModuleLine from './moduleLine'
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import enumConstants from 'constants/enums';
import { filterObjectFromArray } from 'utils/objectUtils';
import ScormCourse from 'components/moduleItems/scormContent/ScormCourse';
import ScormCourseCard from './scormCourseCard';
import LockIcon from 'svgComponents/lockIcon';
import { replaceDatePlaceholderAndFormat } from 'utils/locks';
import { getDefaultFirebaseDatabase } from 'utils/firebase';

import {
    startTracking,
    endTracking
} from 'utils/mixpanelTracking';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

const isBatchLoadingForTheFirstTime = (batchId) => {
    let batchesLoaded = window.localStorage.getItem('batchesLoaded')
    if(batchesLoaded){
        batchesLoaded = JSON.parse(batchesLoaded);
        if(batchesLoaded.includes(+batchId)){
            return false;
        }
    }

    window.localStorage.setItem('batchesLoaded', JSON.stringify([+batchId]));
    return true;
}

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PhaseItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isViewMoreClicked: props.isAktivCourse,
            submissionRef: false
        }       
    }

    componentWillReceiveProps(nextProps){
        if(!this.props.isAktivCourse && nextProps.isAktivCourse){
            this.setState({isViewMoreClicked: true});
            this.fetchCourseModules();
        }   
    }

    componentDidMount() {
        const { course, match } = this.props;

        if (this.isScorm(course)) {
            this.setFirebaseRef(course);
        }

        window.adScormCourseCloseWindow =  () => {
            this.onExitScormCourse();
        };

        this.fetchCourseModules();
        
        if(this.props.isAktivCourse){
            if(!isBatchLoadingForTheFirstTime(match.params.batchId))
            this.scrollIntoView();
        }
    }

    scrollIntoView = () => {
        try {
            this.el.scrollIntoView({ behavior: "smooth", block: "center" });
        } catch (error) {
            this.el.scrollIntoView();
        }
    }

    initFirebaseAndListenToEvents = () => {
        const { match, fetchBatchDetails } = this.props;
        this.state.submissionRef.on('value', function (snapshot) {
            let submission = snapshot.val();
            console.log('snapshot', submission);
            if (submission && submission.isCompleted) {
                fetchBatchDetails(match.params.batchId);
            }
        });
    }

    setFirebaseRef = async (course) => {
        const firebaseDbPromise = getDefaultFirebaseDatabase();
        const firebaseDb = await firebaseDbPromise;
        const userId = this.props.user.get('userID');
        let submission_string = `submissions/user_${userId}_assignment_${course.get('mobile_launch_data').get('assignment_id')}`;
        let submissionRef = firebaseDb.ref(submission_string);
        this.setState({
            submissionRef: submissionRef
        }, () => this.initFirebaseAndListenToEvents());
    }

    toggleViewMoreClicked = () => {
        this.setState({
            isViewMoreClicked: !this.state.isViewMoreClicked
        });
        //this.fetchCourseModules();
    }

    fetchCourseModules = () => {
        const { course, match } = this.props;
        const item = course.get('phaseItemData');
        const params = {
            batchId: match.params.batchId,
            phaseId: item.get('phaseId'),
            courseId: course.get('id'),
            phaseItemId: item.get('id')
        };

        if(course.get('modules_count') === 1){ // show module items directly in this case
            this.props.fetchModuleItemsForSingleModuleCourse(params);
        } else {
            this.props.fetchModulesForDashboard(params);
        }
    }

    getClassNameForTitle = (myStyles, isCourseCompleted, isAktivCourse, phaseItemData) => {

        if (phaseItemData.get('isLocked') || phaseItemData.get('isExpired')) {
            return css(myStyles.title, myStyles.locked);
        } else if (isAktivCourse) {
            return css(myStyles.title, myStyles.aktiv);
        } else if (isCourseCompleted) {
            return css(myStyles.title, myStyles.completed);
        } else {
            return css(myStyles.title, myStyles.pending);
        }
    }

    getClassNameForProgressBar = (myStyles, isCourseCompleted, isAktivCourse, phaseItemData) => {
        if (phaseItemData.get('isLocked') || phaseItemData.get('isExpired')) {
            return css(myStyles.title, myStyles.lockedBackground);
        } else if (isAktivCourse) {
            return css(myStyles.title, myStyles.aktivBackground);
        } else if (isCourseCompleted) {
            return css(myStyles.title, myStyles.completedBackground);
        } else {
            return css(myStyles.title, myStyles.pendingBackground);
        }
    }

    getUserItem = (userItems, item) => {
        let myUserItem = userItems.find((eachItem) => {
            return (eachItem.get('id') === item.get('id'));
        });
        if (myUserItem) {
            return {
                percentageCompletion: myUserItem.get('percentageCompletion'),
                workflowStatus: myUserItem.get('workflowStatus'),
                isSelected: this.checkIfItemIsSelected(item.get('id'))
            };
        }
        return {
            percentageCompletion: 0,
            workflowStatus: enumConstants.get('PENDING'),
            isSelected: this.checkIfItemIsSelected(item.get('id'))
        };
    }

    checkIfItemIsSelected = (id) => {
        const { activeItemId } = this.props;
        // eslint-disable-next-line eqeqeq
        if (id == activeItemId) {
            return true;
        } else {
            return false;
        }
    }

    checkAndSetPhaseAndItemStates = (routeObj) => {

        let { course, userItems } = this.props;
        const hasModules = course.get('modules_count') > 0;
        const item = course.get('phaseItemData');

        if (!hasModules) {
            return;
        }

        this.props.setUserState({
            currentItemId: item.get('id'),
            currentPhaseId: item.get('phaseId')
        });

        const statusObj = this.getUserItem(userItems, item);

        if (statusObj.workflowStatus === enumConstants.get('UNOPENED')) {
            this.props.setItemWorkflowStatus(
                enumConstants.get('STARTED')
            );
        }

        const itemPhaseStatus = this.getItemPhaseStatus(item.get('phaseId'));
        if (itemPhaseStatus && itemPhaseStatus === enumConstants.get('NOT_STARTED')) {
            this.props.setPhaseWorkflowStatus(
                item.get('batchId'), item.get('phaseId'), enumConstants.get('STARTED'), routeObj
            );
        }
        else {
            if (routeObj) {
                updateRoute(routeObj);
            }
        }

    }

    sendSegmentData = (isCourseCompleted) => {
        let { phaseID, batchID, course } = this.props;
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            itemName: course.get('name'),
            itemId: course.get('id'),
            phaseItemId: course.getIn(['phaseItemData', 'id']),
            itemType: 'course',
            itemLocked: course.getIn(['phaseItemData', 'isLocked']),
            itemExpired: course.getIn(['phaseItemData', 'isExpired']),
            itemCompleted: isCourseCompleted,
            phaseID,
            batchID
        }
        window.analytics.track(
            'Phase Item Clicked - Type: Course',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    goToCourse = () => {
        let { courseID, phaseID, batchID, course } = this.props;

        const isCourseCompleted = this.checkIfCourseCompleted();

        this.sendSegmentData(isCourseCompleted);

        if (course.get('phaseItemData').get('isLocked'))
            return;

        if (course.get('phaseItemData').get('isExpired')) {
            if (!isCourseCompleted) {
                return;
            }
        }

        if (course.get('course_type') === 'scorm') {
            return this.goToScormCourse();
        }

        const currentModuleItemDetails = course.get('current_item_details');

        if (currentModuleItemDetails && currentModuleItemDetails.size > 0) {
            const route = routes.get('BATCH_ITEM_MODULE');
            const params = [
                { 'BATCH_ID': batchID },
                { '_PHASE_ID_': phaseID },
                { 'COURSE_ID': courseID },
                { '_PHASEITEMID_': course.get('phaseItemData').get('id') },
                { 'MODULE_ID': currentModuleItemDetails.get('current_module_id') },
                { 'ITEM_ID': currentModuleItemDetails.get('current_item_id') }
            ];
            const routeObj = { route, params };
            this.checkAndSetPhaseAndItemStates(routeObj);
        }
    }


    goToScormCourse = () => {
        this.startMixpanelTracking();

        this.props.setUserState({
            scormLaunched: this.props.course.get('id')
        });

        this.setState({ scormLaunched: true });
        this.checkAndSetPhaseAndItemStates();
    }

    startMixpanelTracking = () => {
        let { phaseID, batchID, course } = this.props;
        endTracking();// end tracking for BatchDashboard
        startTracking(true, {
            batchId: batchID,
            phaseId: phaseID,
            phaseItemId: course.get('phaseItemData').get('id'),
            courseId: course.get('id'),
            moduleId: course.get('mobile_launch_data').get('module_id'),
            moduleItemId: course.get('mobile_launch_data').get('module_item_id')
        },
            'ModuleItem'
        ); // start module item tracking
    };

    endMixpanelTracking = () => {
        let { batchID } = this.props;
        endTracking(); //end module item tracking
        startTracking(true, {
            batchId: batchID
        }, 'BatchDashboard'); //resume batchdashboard tracking
    };

    onExitScormCourse = () => {
        this.props.setUserState({
            scormLaunched: null
        });
        this.endMixpanelTracking();
    }

    getItemPhaseStatus = (phaseId) => {
        const userPhase = filterObjectFromArray(
            this.props.phases.get('userPhasesList'), 'id', phaseId
        );
        return userPhase.get('workflowState');
    }


    isScorm = (course) => {
        return course.get('course_type') === 'scorm';
    };

    getItemStatusLabelAndStyles = (phaseItem) => {
        const myStyles = getSkin(this.props.skinGuide);
        const statusObj = this.getUserItem(this.props.userItems, phaseItem);
        if (phaseItem.get('isLocked')) {
            return {
                label: this.props.getLabel('locked_label'),
                styles: css(myStyles.lockedStyles)
            }
        } else if (
            statusObj.workflowStatus === enumConstants.get('COMPLETED')
            || statusObj.percentageCompletion === 100
        ) {
            return {
                label: this.props.getLabel('completed_label'),
                styles: css(myStyles.completedTag)
            };
        } else if (phaseItem.get('isExpired')) {
            return {
                label: this.props.getLabel('expired_label'),
                styles: css(myStyles.expiredStyles)
            }
        } else if (
            statusObj.workflowStatus === enumConstants.get('UNOPENED')
            || statusObj.workflowStatus === enumConstants.get('OPENED')
            || statusObj.workflowStatus === enumConstants.get('NOT_STARTED')
        ) {
            return {
                label: this.props.getLabel('not_started_label'),
                styles: css(myStyles.notStartedStyles)
            }
        } else if (
            statusObj.workflowStatus === enumConstants.get('STARTED')
        ) {
            return {
                label: this.props.getLabel('in_progress_label'),
                styles: css(myStyles.inProgressStyles)
            }
        } else {
            return null
        }
    }

    getImageIcon = (myStyles) => {
        const { course } = this.props;
        const details = course.get('phaseItemData');
        const isCourseCompleted = this.checkIfCourseCompleted();

        if (details.get('isLocked')) {
            if (this.getImageThumbnail()) {
                return (
                    <div className={css(myStyles.lockedDiv)} styleName="locked-div">
                        <div styleName="locked-icon"><LockIcon /></div>
                    </div>
                );
            } else {
                return (
                    <div className={css(myStyles.lockedTag)} styleName="locked-tag">
                        {this.props.getLabelWithDefaults('locked_label', 'Locked')}
                    </div>
                )
            }
        } else if (isCourseCompleted) {
            return (
                <div className={css(myStyles.completedTag)} styleName="completion-tag">
                    {this.props.getLabelWithDefaults('completed_label', 'Completed')}
                </div>
            );
        } else if (details.get('isExpired')) {
            return (
                <div className={css(myStyles.expiredTag)} styleName="completion-tag">
                    {enumConstants.get('EXPIRED')}
                </div>
            );
        }
        return null;
    }

    getDaysReplacementLabel = (days = 0) => {
        if (days > 1) {
            return `${days} ${this.props.getLabel('days_label')}`;
        }
        return `${days} ${this.props.getLabel('day_label')}`;
    }

    getWeeksReplacementLabel = (weeks = 0) => {
        if (weeks > 1) {
            return `${weeks} ${this.props.getLabel('weeks_label')}`;
        }
        return `${weeks} ${this.props.getLabel('week_label')}`;
    }

    getHoursReplacementLabel = (timeDiff) => {
        return `${timeDiff['hours']} ${this.props.getLabel('hours_label')} 
        ${timeDiff['minutes']} ${this.props.getLabel('minutes_label')} 
        ${timeDiff['seconds']} ${this.props.getLabel('seconds_label')}`;
    }

    getItemDateLabel = () => {

        const { course } = this.props;
        const details = course.get('phaseItemData');

        const styles = getSkin(this.props.skinGuide);

        const showLockMessage = details.get('isLocked') || details.get('isExpired')

        if (showLockMessage) {
            const lockExplanation = replaceDatePlaceholderAndFormat(true, details.get('lockExplanation'), details.get('lockDate'), "short");

            if (details.get('isExpired')) {
                return <span className={css(styles.expired)}>{lockExplanation}</span>;
            }

            return lockExplanation;
        }

        if (!details.get('endTimestamp')) {
            return null;
        }

        return (
            <span>
                <span styleName='text-bold'>{this.props.getLabel('item_due_on_label')} </span>
                <span>{convertDateWithYear(new Date(details.get('endTimestamp')))}</span>
            </span>
        );
    }

    getImageDiv = (url) => {
        return <div styleName="image-container">
            <img src={url} width="100%" height="100%" alt="Course" />
        </div>
    }

    getImageThumbnail = () => {
        const { course } = this.props;
        const details = course.get('phaseItemData');
        const imageIcon = details.get('icon');
        const courseImageUrl = course.get('cover_image_url');

        if (imageIcon && imageIcon !== "") {
            return this.getImageDiv(imageIcon);
        }

        if (courseImageUrl && courseImageUrl !== "") {
            return this.getImageDiv(courseImageUrl);
        }

        return null;
    }

    getItemCountLabelInsteadForSingleModuleCourse = () => {
        const { courseModulesDetails, getLabelWithDefaults } = this.props;

        if(courseModulesDetails && courseModulesDetails.get('courseModulesList').size === 1){
            const firstModule = courseModulesDetails.get('courseModulesList').first();
            return getLabelWithDefaults(
				'count_items_completed_label',
				`${firstModule.get('items_completed_count')} / ${firstModule.get('required_items_count')} completed`, {
				completed_count: firstModule.get('items_completed_count'),
				total_count: firstModule.get('required_items_count')
			});
        } else {
            return getLabelWithDefaults('loading_with_ellipsis_label', 'Loading...');
        }
    }

    getOptionalItemCountLabelInsteadForSingleModuleCourse = () => {
        const { courseModulesDetails, getLabelWithDefaults } = this.props;      

        if(courseModulesDetails && courseModulesDetails.get('courseModulesList').size === 1){
            const firstModule = courseModulesDetails.get('courseModulesList').first();
            if (firstModule.get('optional_items_count') !== 0) {
                return getLabelWithDefaults(
                    'optional_module_items_count',
                    `${firstModule.get('optional_items_count')} optional item(s)`,
                    {total_module_items: firstModule.get('optional_items_count')}
                    );
            }
        } else {
            return null;
        }
    }

    getProgressBarCountLabel = () => {
        const { course, getLabelWithDefaults } = this.props;
        const labels = [];
        let mandatoryModulesCompletedLabel = null;
        let optionalModulesLabel = null;
        let durationLabel = null;

        if (course.get('modules_count') === 0) {
            return getLabelWithDefaults('module_no_items_label', `This course has no modules`);
        }

        if(course.get('modules_count') === 1){
            const mandatoryItemsCompletedLabel = this.getItemCountLabelInsteadForSingleModuleCourse();
            labels.push(mandatoryItemsCompletedLabel);

            const optionalItemsLabel = this.getOptionalItemCountLabelInsteadForSingleModuleCourse();
            if(optionalItemsLabel) labels.push(optionalItemsLabel);
        }

        if (course.get('required_modules_count') > 1) {
            mandatoryModulesCompletedLabel = getLabelWithDefaults(
                'count_modules_completed_label',
                `${course.get('modules_completed_count')} / ${course.get('required_modules_count')} completed`, {
                completed_count: course.get('modules_completed_count'),
                total_count: course.get('required_modules_count')
            }
            );
            labels.push(mandatoryModulesCompletedLabel);
        }

        if (course.get('optional_modules_count') !== 0) {
			optionalModulesLabel = getLabelWithDefaults(
                'optional_course_modules_count',
				`${course.get('optional_modules_count')} optional section(s)`,
				{total_optional_course_modules: course.get('optional_modules_count')}
                );
            labels.push(optionalModulesLabel);
        }

        if(course.get('duration')){
            durationLabel = getDurationString(course.get('duration'));
            labels.push(durationLabel);
        }

        if(labels.length > 0){
            return <span>{labels.join(' | ')}</span>
        }        

        return null;

    }

    checkIfCourseCompleted = () => {
        const statusObj = this.getUserItem(this.props.userItems, this.props.course.get('phaseItemData'));
        return statusObj.workflowStatus === enumConstants.get('COMPLETED') || statusObj.percentageCompletion === 100;
    }

    getConnectorHeight = (isViewMoreClicked, course, courseModulesDetails, modulesItemsList) => {
        let heightForConnector = 177 + 38 + 32 - 20;
        if (isViewMoreClicked) {
            const numberOfModules = (courseModulesDetails && courseModulesDetails.get('courseModulesList').size) || 0;
            let entityCount = numberOfModules;

            if(numberOfModules === 1){
                const firstModule = courseModulesDetails.get('courseModulesList').first();
			    let moduleItemsDetails = modulesItemsList.find(listItem => listItem.get('id') === firstModule.get('id'));
                entityCount = (moduleItemsDetails && moduleItemsDetails.get('moduleItemsList') && moduleItemsDetails.get('moduleItemsList').size) || 0;
            }

            if (course.getIn(['phaseItemData', 'settings', 'feedbackScenarioId']) &&
                course.getIn(['phaseItemData', 'settings', 'isFeedbackEnabled']) === 'true') {
                heightForConnector = heightForConnector + ((entityCount) * 54) + 54
            } else {
                heightForConnector = heightForConnector + ((entityCount - 1) * 54) + 54
            }
        }
        return heightForConnector;
    }

    render() {
        const skinHex = this.props.skinGuide.get('skinHex');
        const myStyles = getSkin(this.props.skinGuide);
        const { course, courseModulesDetails, getLabelWithDefaults, hideConnector, isAktivCourse, userState, batchID, phaseID, userItems, modulesItemsList } = this.props;
        let { isViewMoreClicked } = this.state;
        let showProgressBar = true;
        
        const isScorm = this.isScorm(course);
        const hasModules = course.get('modules_count') > 0;
        
        const phaseItemData = course.get('phaseItemData');


        const statusObj = this.getUserItem(userItems, phaseItemData);

        let heightStyles = {
            height: `${this.getConnectorHeight(isViewMoreClicked, course, courseModulesDetails, modulesItemsList)}px`
        }
        let courseInnerCircleStyles = {}
        let courseOuterCircleStyles = {}
        let timeLineCntStyles = {}

        let isCourseCompleted = this.checkIfCourseCompleted();

        if (phaseItemData.get('isLocked') || phaseItemData.get('isExpired')) {
            courseOuterCircleStyles = {
                borderColor: 'rgb(213, 217, 231)'
            }
            courseInnerCircleStyles = {
                backgroundColor: skinHex.get('greyColor4')
            }
        } else if (isAktivCourse) {
            courseOuterCircleStyles = {
                borderColor: 'rgb(213, 217, 231)'
            }
            courseInnerCircleStyles = {
                backgroundColor: '#d44980'
            }
        } else {
            if (isCourseCompleted) {
                courseInnerCircleStyles = {
                    backgroundColor: skinHex.get('darkSkyBlue')
                }
            }
            courseOuterCircleStyles = {
                border: 'none'
            }
            timeLineCntStyles = {
                left: '-69px'
            }
        }

        if (isScorm) {
            if (userState.get('scormLaunched') === course.get('id')) {
                let showPlatformExitButton = course.get('mobile_launch_data').get('show_platform_exit_button');

                if(typeof showPlatformExitButton === "undefined") showPlatformExitButton = true;
                return <ScormCourse
                    title={phaseItemData.get('name')}
                    src={course.get('mobile_launch_data').get('mobile_launch_url')}
                    onExit={this.onExitScormCourse.bind(this)}
                    showPlatformExitButton={showPlatformExitButton}
                    ref={el => { this.el = el }}
                />;
            }
            showProgressBar = false;

            return <span ref={el => { this.el = el }}><ScormCourseCard
                course={course}
                userItem={this.getUserItem(this.props.userItems, phaseItemData)}
                itemDateLabel={this.getItemDateLabel(myStyles)}
                itemStatusLabelAndStyles={this.getItemStatusLabelAndStyles(phaseItemData)}
                isCourseCompleted={isCourseCompleted}
                isCourseLocked={phaseItemData.get('isLocked')}
                isCourseExpired={phaseItemData.get('isExpired')}
                isAktivCourse={isAktivCourse}
                getClassNameForTitle={this.getClassNameForTitle}
                phaseItemData={phaseItemData}
                timeLineCntStyles={timeLineCntStyles}
                courseOuterCircleStyles={courseOuterCircleStyles}
                courseInnerCircleStyles={courseInnerCircleStyles}
                heightStyles={{ height: '251px' }}
                hideConnector={hideConnector}
                goToCourse={this.goToCourse}
                batchId={batchID}
                phaseId={phaseID}
            /></span>
        }

        return (
            <div styleName="individual-phase-item-cnt" ref={el => { this.el = el }}>
                <div className={css(myStyles.individualPhaseItem)} styleName="individual-phase-item" onClick={this.goToCourse}>
                    <div styleName="content-container">
                        <div
                            className={this.getClassNameForTitle(myStyles, isCourseCompleted, isAktivCourse, phaseItemData)}
                            styleName="title"
                        >
                            {phaseItemData.get('name')}
                        </div>
                        <div className={css(myStyles.desc)} styleName="desc">
                            {textTruncate(this.props.getLabel(phaseItemData.get('description')), 250)}
                        </div>

                        <div styleName="progress-container">

                            {showProgressBar &&
                                <div className={css(myStyles.outerBar)} styleName="outer-bar">
                                    <div style={{
                                        width: `${statusObj.percentageCompletion}%`,
                                    }}
                                        className={this.getClassNameForProgressBar(myStyles, isCourseCompleted, isAktivCourse, phaseItemData)}
                                        styleName="inner-bar"></div>
                                </div>
                            }

                            {
                                hasModules ?
                                    <div styleName="progress-content">
                                        <div className={css(myStyles.bottomText)}>
                                            {this.getItemDateLabel(myStyles)}
                                        </div>
                                        <div className={css(myStyles.bottomText)}>
                                            {isScorm ? this.getItemStatusLabelAndStyles(phaseItemData).label :       this.getProgressBarCountLabel()}
                                        </div>
                                    </div>
                                    : <div styleName="progress-content">
                                        <div className={css(myStyles.bottomText)}>
                                            {getLabelWithDefaults('course_no_modules_label', 'This course has no modules')}
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                    {this.getImageThumbnail()}
                    {this.getImageIcon(myStyles)}
                </div>

                <ModuleLine
                    areModulesLoaded={courseModulesDetails && courseModulesDetails.get('isCourseModulesListAvailable')}
                    modules={courseModulesDetails && courseModulesDetails.get('courseModulesList')}
                    modulesItemsList={modulesItemsList}
                    batchID={this.props.batchID}
                    phaseID={this.props.phaseID}
                    courseID={this.props.courseID}
                    toggleViewMoreClicked={this.toggleViewMoreClicked}
                    phaseItemId={course.get('phaseItemData').get('id')}
                    checkAndSetPhaseAndItemStates={this.checkAndSetPhaseAndItemStates}
                    course={course}
                    toggleDialog={this.props.toggleDialog}
                    feedbackStatuses={this.props.feedbackStatuses}
                    isAktivCourse={isAktivCourse}
                    isViewMoreClicked={isViewMoreClicked}
                    isSingleModuleCourse={course.get('modules_count') === 1}
                />

                <div styleName="dev-phase-timeline-cnt" style={timeLineCntStyles}>
                    <div styleName="dev-phase-timeline-outer-circle" onClick={this.goToCourse} style={courseOuterCircleStyles}>
                        <div styleName="dev-phase-timeline-inner-circle" style={courseInnerCircleStyles}>
                        </div>
                    </div>
                    {
                        !hideConnector &&
                        <div styleName="dev-phase-timeline-connector" style={heightStyles}>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default PhaseItem;
