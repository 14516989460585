import React, { Component } from 'react';
import styles from './userProfile.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import DefaultLoader from 'commonComponents/loaders/defaultLoader';
import UserProfileImage from 'images/header/user-profile.svg';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import UploadImage from 'commonComponents/fileUpload/uploadImage';
import { checkIfPresent } from 'utils/utilFunctions';
import Select from "react-select";
import DemographicsOption from './DemographicsOption.jsx';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userProfileData: {
				email: '',
				firstName: '',
				lastName: '',
				designation: '',
				nationality: '',
				gender: '',
				userCategory: '',
				experienceLevel: '',
				industry: '',
				highestEducation: '',
				workExperience: '',
				managingExperience: '',
				dateOfBirth: '',
				city: '',
				country: '',
				primaryPhoneNumber: '',
				...props.userProfile.get('userReferences').toJS()
			},
			editBasicDetails: false,
			editDemographics: false,
			editDemographics: false
		}
	}

	componentDidMount() {
		document.title = 'User Profile - Aktivlearn Plus'
		let segmentData = {
			url: window.location.href,
			pathHash: window.location.hash
		};
		window.analytics.page(
			"User Profile",
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	toggleEditMode = (section, needToFocusComponent = false, callback = () => { }) => {
		if (!this.state[section] && needToFocusComponent)
			document.getElementById(section).focus()
		this.setState({
			[section]: !this.state[section]
		}, callback)
	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.userProfile.get('userReferences').equals(this.props.userProfile.get('userReferences'))) {
			this.setState({
				...this.state,
				userProfileData: {
					...this.state.userProfileData,
					...nextProps.userProfile.get('userReferences').toJS()
				}
			})
		}
	}

	sendSegmentData = () => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash
		};
		window.analytics.track(
			'Change Password clicked',
			segmentData,
			getAmplitudeSessionDetails()
		);
	}

	redirectToChangePassword = () => {
		this.sendSegmentData();
		const route = routes.get('CHANGE_PASSWORD_URL');;
		updateRoute({ route });
	}

	clearTemporaryChanges = () => {
		this.setState({
			userProfileData: {
				email: '',
				firstName: '',
				lastName: '',
				designation: '',
				nationality: '',
				gender: '',
				userCategory: '',
				experienceLevel: '',
				industry: '',
				highestEducation: '',
				workExperience: '',
				managingExperience: '',
				dateOfBirth: '',
				city: '',
				country: '',
				primaryPhoneNumber: '',
				...this.props.userProfile.get('userReferences').toJS()
			}
		})
	}

	goToHome = () => {
		const route = routes.get('USER_HOME');;
		updateRoute({ route });
	}

	updateBasicInfo = (keyToUpdate, value) => {
		if (keyToUpdate === 'primaryPhoneNumber') {
			if (!Number(value) && value !== '')
				return;
		}
		this.setState({
			userProfileData: {
				...this.state.userProfileData,
				[keyToUpdate]: value
			}
		})
	}

	onClickSaveBasicInfo = (section) => {
		this.toggleEditMode(section, false, this.props.syncUser(this.state.userProfileData));
	}

	onUploadAssigneeImage = (imageFile = null) => {
		this.props.uploadAvatarImage(imageFile);
	}

	onResetAssigneeImage = () => {
		this.props.resetImage("");
		this.props.onChangeHandler('imageUrl', "", 'messageByAssignee');
	}

	toggleSubscription = () => {
		this.props.updateNotificationSubscription(
			!this.props.userProfile.getIn([
				'notificationSubscriptionDetails',
				'isActive'
			])
		)
	}

	getUserProfileDetails = (myStyles) => {
		const { userProfile, getLabel } = this.props;
		return (
			<div styleName="details-box profile">
				<UploadImage
					imageKey="user-avatar"
					uploadIcon={userProfile.getIn(['userReferences', 'profilePicUrl']) || UserProfileImage}
					uploadImageHandler={this.onUploadAssigneeImage}
					resetImageHandler={this.onResetAssigneeImage}
					isUploading={false}
					isDisabled={false}
					hideReset={true}
				/>
				<div styleName="user-details">
					<div className={css(myStyles.userName)}>
						{`${userProfile.getIn(['userReferences', 'firstName'])} ${userProfile.getIn(['userReferences', 'lastName'])}`}
					</div>
					<div className={css(myStyles.userEmail)}>
						{userProfile.getIn(['userReferences', 'email'])}
					</div>
					<span
						className={css(myStyles.changePassword)}
						onClick={this.redirectToChangePassword}
					>
						{getLabel('change_password_label')}
					</span>
				</div>
			</div>
		)

	}

	getDateObject = (dateString) => {
		const dateMomentObject = moment(dateString, "DD-MM-YYYY");
		return dateMomentObject.toDate(); // convert moment.js object to Date object
	}

	updateDOB = (date) => {
		const dateString = date.toISOString();
		const formattedDateString = moment(dateString).format("DD-MM-YYYY");
		this.updateBasicInfo('dateOfBirth', formattedDateString);
	}

	getDesignation = (designation) => {
		if(designation === 'student' || designation === 'Student'){
			return 'Participant';
		}
		return designation;
	}

	getUserBasicDetails = (myStyles) => {
		const { getLabel } = this.props;

		return (
			<div styleName="details-box">
				<div styleName="details-header">
					<div className={css(myStyles.headerText)}>
						{getLabel('basic_info_label')}
					</div>

					<div
						styleName="header-actions-cnt"
					>
						{
							this.state.editBasicDetails &&
							<div
								className={css(myStyles.saveLink)}
								onClick={() => {
									this.clearTemporaryChanges();
									this.toggleEditMode('editBasicDetails', true);
								}}
							>
								{
									getLabel('cancel_label')
								}
							</div>
						}
						<div
							className={css(myStyles.saveLink)}
							onClick={
								this.state.editBasicDetails ?
									() => this.onClickSaveBasicInfo('editBasicDetails', true)
									:
									() => this.toggleEditMode('editBasicDetails', true)
							}
						>
							{
								this.state.editBasicDetails ?
									getLabel('save_label')
									:
									getLabel('edit_label')
							}
						</div>
					</div>
				</div>
				<div styleName={`details-content${this.state.editBasicDetails ? '' : ' disabled'}`}>
					<div
						styleName="basic-details-item-cnt"
					>
						<div className={css(myStyles.individualHeaderText)}>
							{getLabel('first_name_label')}
						</div>
						<input
							style={this.state.editBasicDetails ? {} : { border: 'none' }}
							id="editBasicDetails"
							className={css(myStyles.detailInput)}
							value={this.state.userProfileData.firstName}
							onChange={(e) => this.updateBasicInfo('firstName', e.target.value)}
							placeholder={this.state.editBasicDetails ? '' : '-'}
						/>
					</div>
					<div
						styleName="basic-details-item-cnt"
					>
						<div className={css(myStyles.individualHeaderText)}>
							{getLabel('last_name_label')}
						</div>
						<input
							style={this.state.editBasicDetails ? {} : { border: 'none' }}
							className={css(myStyles.detailInput)}
							value={this.state.userProfileData.lastName}
							onChange={(e) => this.updateBasicInfo('lastName', e.target.value)}
							placeholder={this.state.editBasicDetails ? '' : '-'}
						/>
					</div>
				</div>
			</div>
		)
	}

	getSettingsDetails = (myStyles) => {
		const { getLabel, userProfile } = this.props;
		return (
			<div styleName="details-box">
				<div styleName="details-header">
					<div className={css(myStyles.headerText)}>
						{getLabel('settings_label')}
					</div>
				</div>
				<div styleName="details-content subscribe-option">
					<div className={css(myStyles.subscribeLabel)}>
						{getLabel('subscribe_notification_label')}
					</div>
					<input
						type="checkbox"
						checked={userProfile.getIn(['notificationSubscriptionDetails', 'isActive'])}
						onChange={this.toggleSubscription}
					/>
				</div>
			</div>
		)
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const { user, userProfile, getLabel } = this.props

		return (
			<div className={css(myStyles.userProfileContainer)} styleName="content-cnt">
				<div className={css(myStyles.headerBg)} styleName="content-header">
					<div className={css(myStyles.headerBgImg)}></div>
					<div styleName="breacrumbs-cnt" style={{ padding: '11px' }}>
						<span
							className={css(myStyles.breadcrumbsText)}
							onClick={this.goToHome}
						>
							{getLabel('home_label')}
						</span>
						<span
							className={css(myStyles.breadcrumbsText)}
							style={{ cursor: 'unset' }}
						>
							{getLabel('profile_label')}
						</span>
					</div>
					<div
						className={css(myStyles.contentText)}
						styleName="content"
						style={{ padding: '11px' }}
					>
						{getLabel('profile_label')}
					</div>
				</div>
				{
					!user.get('isUserDataAvailable')
						|| userProfile.get('isUserReferencesLoading') ?
						<DefaultLoader />
						:
						<div styleName="content">
							{this.getUserProfileDetails(myStyles)}
							{this.getUserBasicDetails(myStyles)}
							{this.getSettingsDetails(myStyles)}
						</div>
				}

				{
					userProfile.get('profileUpdating') &&
					<div styleName="overlay"><DefaultLoader /></div>
				}
			</div>
		);
	}
}

export default UserProfile;