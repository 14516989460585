import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        question: {
            ...myTypography.overline,
            opacity: "0.75",
            color: "#000000",
            fontWeight: "600"
        },

        answer: {
            ...myTypography.body1,
            opacity: "0.7"
        }
    });
}

export default getSkin;