import React, { Component } from 'react'

export default class FeedbackCompCircle extends Component {
  render() {
    return (
      <svg width="13" height="12" viewBox="0 0 13 12">
        <rect width="11" height="11" x=".5" y=".5" fill="#FFF" fillRule="evenodd" stroke="#D5D8E2" rx="5.5" transform="translate(.633)" />
      </svg>
    )
  }
}
