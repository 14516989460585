import React, { Component } from 'react';
import { connect } from 'react-redux';
import DigitalTool from 'components/moduleItems/digitalTool';
import { getDigitalToolkitFirebaseDatabase } from 'utils/firebase';
import { markModuleItemDone } from 'actions/moduleItems/apiActions';

class DigitalToolContainer extends Component {
	_isMounted = false;

	constructor(props) {
		super(props);
		this.state = {
			toolDataLoaded: false,
			colorPaletteLoaded: false,
			toolUserDataLoaded: false,
			toolData: null,
			colorPalette: null,
			toolUserData: null,
			toolRef: null,
			colorPaletteRef: null,
			toolUserDataRef: null
		}
	}

	componentDidMount() {
		this._isMounted = true;
		if (this._isMounted)
			this.setFirebaseRef();
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	initFirebaseAndListenToEvents = () => {
		const {
			toolRef,
			colorPaletteRef,
			toolUserDataRef
		} = this.state;
		let toolData, toolDataLoaded, toolUserData, toolUserDataLoaded, colorPalette, colorPaletteLoaded;
		toolRef.once('value').then((snapshot) => {
			toolData = snapshot.val();
			toolDataLoaded = true;
			colorPaletteRef.once('value').then((snapshot) => {
				colorPalette = snapshot.val();
				colorPaletteLoaded = true;
				this.setState({
					toolData: toolData,
					toolDataLoaded: toolDataLoaded,
					colorPalette: colorPalette,
					colorPaletteLoaded: colorPaletteLoaded
				})
			})
		});

		toolUserDataRef.on('value', (snapshot) => {
			toolUserData = snapshot.val();
			toolUserDataLoaded = true;
			this.setState({
				toolUserData: toolUserData,
				toolUserDataLoaded: toolUserDataLoaded
			})
		});
	}

	setFirebaseRef = async () => {
		const { currentUserDetails, match, moduleItem } = this.props;
		let toolString = `digitalToolkit/tools/${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])}`;
		let colorPaletteString = `digitalToolkit/colourPalette`;
		let toolUserDataString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])}`
		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 

		const toolRef = digitalToolkitDb.ref(toolString);
		const colorPaletteRef = digitalToolkitDb.ref(colorPaletteString);
		const toolUserDataRef = digitalToolkitDb.ref(toolUserDataString);

		this.setState({
			toolRef: toolRef,
			colorPaletteRef: colorPaletteRef,
			toolUserDataRef: toolUserDataRef
		}, () => this.initFirebaseAndListenToEvents());
	}

	addNewUserDataRecord = async (data, type) => {
		const { match, currentUserDetails, moduleItem } = this.props;
		const { toolUserDataRef } = this.state;

		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 

		toolUserDataRef.once('value', (snapshot) => {
			if (snapshot.exists()) {
				let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])}/data/cards/${type}`

				const dataRef = digitalToolkitDb.ref(refString)
				dataRef.update(data);
			} else {
				let dataTemp = {
					cards: {}
				};
				dataTemp.cards[type] = data;
				let dataToSave = {
					...(match.params),
					userId: currentUserDetails.get('userID'),
					toolId: moduleItem.getIn(['contentDetails', 'al_data', 'tool_id']),
					data: dataTemp
				}
				toolUserDataRef.update(dataToSave);
			}
		})
	}

	deleteRecord = async (uuid, type) => {
		const { match, currentUserDetails, moduleItem } = this.props;

		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 

		let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id']) || 'empathyMapEnglish'}/data/cards/${type}/${uuid}`;
		const dataRef = digitalToolkitDb.ref(refString);
		dataRef.remove();
	}

	setColorPalette = async (type, selectedCombo = "combo1") => {
		const { match, currentUserDetails, moduleItem } = this.props;

		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 

		let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])}/data/selectedColorPalette/${type}`;
		const paletteRef = digitalToolkitDb.ref(refString);
		paletteRef.set(selectedCombo);
	}

	render() {
		return (
			<DigitalTool
				{...this.state}
				{...this.props}
				addNewUserDataRecord={this.addNewUserDataRecord}
				deleteRecord={this.deleteRecord}
				setColorPalette={this.setColorPalette}
			/>
		);
	}
}

const mapStateToProps = state => ({
	currentUserDetails: state.get('user')
});

const mapDispatchToProps = dispatch => ({
	markModuleItemDone: (params) => {
		markModuleItemDone(params);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(DigitalToolContainer);
