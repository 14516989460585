import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import styles from './uploadImage.module.sass';
import DefaultLoader from 'commonComponents/loaders/defaultLoader';
import SecondaryButton from 'commonComponents/buttons/secondaryButton';
import { css } from 'aphrodite';
import CameraIcon from 'svgComponents/cameraIcon';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class UploadImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uploadedImageFile: null,
            imagePreviewUrl: null,
            errorMessage: null,
            showTooltip: false
        }
    }

    handleImageChange = (e) => {
        this.setState({ errorMessage: null });

        e.stopPropagation();
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState(
                {
                    uploadedImageFile: file,
                    imagePreviewUrl: reader.result
                },
                () => {
                    if (!this.state.errorMessage) {
                        this.props.uploadImageHandler(this.state.uploadedImageFile);
                    }
                }
            );
        };
        reader.readAsDataURL(file);

        //resetting the value to enable onChange for same file.
        e.target.value = null
    }

    resetImage = (event) => {
        this.setState({
            uploadedImageFile: null,
            imagePreviewUrl: null,
            errorMessage: null
        },
            () => {
                if (!this.props.hideReset && this.props.resetImageHandler) {
                    this.props.resetImageHandler();
                }
            }
        );
    }

    onUploadImage = (event) => {
        this.setState({ errorMessage: null });
        document.getElementById('uploadimagefile' + this.props.imageKey).click();
    }

    getImagePreview = () => {
        if (this.props.isUploading) {
            return <DefaultLoader />;
        }

        let imagePreviewUrl = this.state.imagePreviewUrl;

        let imagePreview = null;
        if (imagePreviewUrl) {
            imagePreview = (
                <img
                    src={imagePreviewUrl}
                    styleName="image-preview"
                    alt="preview"
                />

            );
            return imagePreview;
        }
        return null;
    }

    getDefaultUploadIcon = () => {

        if (this.props.uploadIcon && this.props.uploadIcon['type'] === 'div') {
            return this.props.uploadIcon;
        }
        return (
            <img
                src={this.props.uploadIcon}
                alt="uploadIcon"
                styleName="image-preview"
            />
        );

    }

    getUploadButtonText = () => {
        if (this.props.isUploading) {
            return this.props.isUploadingText ? this.props.isUploadingText : "Uploading...";
        }
        else {
            return this.props.uploadText ? this.props.uploadText : "Upload";
        }
    }

    showTooltipMessage = (value) => {
        this.setState({
            showTooltip: true
        });
    }

    hideTooltipMessage = (value) => {
        this.setState({
            showTooltip: false
        });
    }

    renderTooltip() {
        return (
            <div styleName="tooltip-text">
                {this.props.getLabel('permission_denied')}
            </div>
        )
    }

    render() {

        return (
            <div styleName="upload-image-container">
                <div styleName="upload-image-icon" onClick={this.onUploadImage}>
                    {this.state.imagePreviewUrl === null
                        && this.state.uploadedImageFile === null ? this.getDefaultUploadIcon() : this.getImagePreview()}
                    <div styleName="camera-icon-cnt">
                        <div style={{ width: '12px', height: '9px', margin: 'auto' }}>
                            <CameraIcon />
                        </div>
                    </div>
                </div>
                <div styleName="image-info-action-container">
                    {/* <div styleName="image-info-container">
                        <div styleName="image-info-text">
                            {this.props.imageInfoText}
                        </div>
                        <div styleName="image-error-text">
                            {this.state.errorMessage ? this.state.errorMessage : ""}
                        </div>
                    </div> */}
                    <div styleName="tooltip-container" onMouseEnter={this.props.isDisabled ? () => this.showTooltipMessage() : () => { }}
                        onMouseLeave={this.props.isDisabled ? () => this.hideTooltipMessage() : () => { }}
                    >
                        <div styleName={this.props.isDisabled ? "upload-buttons-container disable-css" : "upload-buttons-container"}>
                            <input
                                ref="fileUpload"
                                id={"uploadimagefile" + this.props.imageKey}
                                type="file"
                                accept='image/*'
                                onChange={this.handleImageChange}
                                hidden
                            />

                            {!this.props.hideReset ?
                                <div styleName="reset-button" onClick={this.resetImage}>
                                    {this.props.getLabel('reset_filter_label')}
                                </div>
                                : null

                            }
                        </div>
                        {this.state.showTooltip ? this.renderTooltip() : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default UploadImage;
