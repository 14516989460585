/**
 * @author: varun
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: uiState.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    showOverlay: false,
    openDropDown: false,
    showAboutJourney: false,
    overlayOpacity: 0.5,
    showVideoOverlay: false,
    showInternetConnectivityBar: false,
    isLogoutClicked: false,
    showSystemCheckDialog: false,
    showOrganizationsDialog: false
});

const uiState = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_UI_STATE'):
            return state.merge(
                Immutable.fromJS(action.payload)
            );
        case actionConsts.get('LOGOUT_CLICKED'):
            return state.set('isLogoutClicked', action.flag);
        default:
            return state;
    }
};

export default uiState;