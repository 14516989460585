import actionConsts from 'constants/actions';

export const setSelectedItem = (payload) => ({
	type: actionConsts.get('SET_SELECTED_ITEM'),
	payload
});

export const setSideBarFlag = (flag) => ({
	type: actionConsts.get('SET_SIDEBAR'),
	flag
});

export const setFullScreen = (flag) =>({
	type: actionConsts.get('SET_FULLSCREEN'),
	flag
})