import React from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

import styles from './webSessionInfoTooltip.module.sass';
import getSkin from './skin.js';

import { getFormattedSessionDate } from 'utils/webSessions';

const WebSessionInfoTooltip = ({session, getLabel, skinGuide}) => {
  const myStyles = getSkin(skinGuide);

  return <div styleName='session-tooltip-container' className={css(myStyles.sessionTooltipContainer)}>
    <div className={css(myStyles.sessionTitle)}>{session.get('title')}</div>
    <div styleName='session-timing'>
        Session Time: <span className={css(myStyles.timingBold)}>
            {getFormattedSessionDate(session.get('startDate'))}&nbsp;-&nbsp;
            {getFormattedSessionDate(session.get('endDate'), session.get('startDate'))}
        </span>
    </div>
    <div styleName='session-facilitator'>
        Led by:  {session.get('facilitatorName')}
    </div>
    <div styleName='session-description'>
        {session.get('description')}
    </div>
  </div>;
}

export default applySkin(applyLabel(CSSModules(WebSessionInfoTooltip, styles)));