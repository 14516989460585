/**
 * @author: Sourabh P
 * @since: Thu Sep 9 2018 18:20:31 GMT+0530 (IST)
 * @file: index.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './BatchesListLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class BatchesListLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<ContentLoader
				height={4}
				width={344}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={this.props.skinGuide.get('skinHex').get('secondaryColor')}
				secondaryColor={this.props.skinGuide.get('skinHex').get('white')}
			>
				<rect x="0" y="0" rx="0" ry="0" width="344" height="4" />
			</ContentLoader>
		);
	}
}

export default BatchesListLoader;
