import actionConsts from 'constants/actions';

const setBatchesList = payload => ({
    type: actionConsts.get('SET_BATCHES_LIST'),
    payload
});

const setActiveBatchId = activeBatchId => ({
    type: actionConsts.get('SET_ACTIVE_BATCH_ID'),
    activeBatchId
});

const updateBatchIdFromRoute = (batchIdFromRoute) => ({
    type: actionConsts.get('UPDATE_BATCH_FROM_ROUTE'),
    batchIdFromRoute
});

const updateWorkflowStatus = (workflowStatus) => ({
    type: actionConsts.get('UPDATE_WORKFLOW_STATUS'),
    workflowStatus
});

const setUserBatchesList = (payload) => ({
    type: actionConsts.get('SET_USER_BATCHES_LIST'),
    payload
});

const updateBatchDetailedData = (payload) => ({
    type: actionConsts.get('UPDATE_BATCH_DETAILED_DATA'),
    payload
});

const updateUserBatchDetailedData = (payload) => ({
    type: actionConsts.get('UPDATE_USER_BATCH_DETAILED_DATA'),
    payload
});

const setActiveBatchAndPhaseId = payload => ({
    type: actionConsts.get('SET_ACTIVE_BATCH_AND_PHASE_ID'),
    payload
});

const setPlaformDataForDevPhaseAvailablitity = (status) => ({
    type: actionConsts.get('SET_PLATFORM_DATA_FOR_DEV_PHASE_AVAILABILITY'),
    status
})

const setResetFailed = (flag) => ({
    type: actionConsts.get('SET_RESET_BATCH_PROGRESS_FAILED'),
    flag
})

const addBatchToListAfterAllDataLoaded = (batchId) => ({
    type: actionConsts.get('SET_BATCH_LOADED_WITH_ALL_DATA'),
    batchId
})


export {
    setBatchesList,
    setUserBatchesList,
    setActiveBatchId,
    setActiveBatchAndPhaseId,
    updateBatchIdFromRoute,
    updateWorkflowStatus,
    updateBatchDetailedData,
    updateUserBatchDetailedData,
    setPlaformDataForDevPhaseAvailablitity,
    setResetFailed,
    addBatchToListAfterAllDataLoaded
};
