import React, { Component } from 'react';
import CSSModules from 'react-css-modules';

import DownloadIcon from 'svgComponents/downloadIcon';
import VideoPlayer from 'commonComponents/contentPlayers/videoPlayer';
import DocumentPreview from 'commonComponents/contentPlayers/documentPreview';
import SCORMEmbedded from '../scormEmbedded';

import applyLabel from 'utils/LabelProvider';
import styles from './attachmentViewer.module.sass';

class AttachmentImage extends Component {

    componentDidMount(){
        const { onComplete } = this.props;
        onComplete();
    }

    render(){
        const { attachmentDetails } = this.props;
        return <img style={{width: '100%', marginTop: '10px'}} src={attachmentDetails.get('viewUrl')} alt="See pic" />;
    }

}    

@applyLabel
@CSSModules(styles, { allowMultiple: true })
class AttachmentViewer extends Component {

    constructor(props){
        super(props);
        this.state = {
            completed: props.isAlreadyCompleted
        }
    }

    onUpdateProgress = (score, maxScore=0, isCompleted = false) => {
		console.log('attachment progress: ', score, maxScore, isCompleted);
        if(isCompleted){
            this.setState({completed: true}, () => this.props.onProgress(score, maxScore, isCompleted))
        } else {
            this.props.onProgress(score, maxScore, isCompleted || this.state.completed);
        }       
	};

    onDownloadFile = () => {
        //mark the file completed. There's no score associated
        this.onUpdateProgress(0, 0, true);   
    }

    renderVideoPlayer = (attachmentDetails, progress) => {
        return <div styleName="file-content-container">
            <VideoPlayer
                key={`kaltura_attachment_${attachmentDetails.get('uuid')}`}
                config={{
                    kalturaEntryId: attachmentDetails.get('viewUrl')
                }}
                playerWidth="100%"
                playerHeight="100%"
                onStart={this.props.onStart}
                onUpdateProgress={this.onUpdateProgress}
                videoPlayerTargetId='video-player-container'
                progress={progress ? {progress: progress.get('score')} : {}}
                isVideoCompleted={false}
            />
        </div>;
    }

    renderDocumentViewer = (attachmentDetails, progress) => {
        return <div styleName="file-content-container document-container">
            <DocumentPreview
                isPresentationByDefault={attachmentDetails.get('label') === 'PRESENTATION'}
                key={`document_preview_${attachmentDetails.get('uuid')}`}
                viewerWidth="100%"
                viewerWidthHeight="100%"
                onStart={this.props.onStart}
                onUpdateProgress={this.onUpdateProgress}
                progress={progress ? progress.get('score'): 0}
                fileSize={attachmentDetails.getIn(['meta', 'fileSize'])}
                fileUrl={attachmentDetails.get('viewUrl')}
                type={null}
            />
        </div>
    }

    renderImage = (attachmentDetails) => {
        return <AttachmentImage attachmentDetails={attachmentDetails} onComplete={this.onDownloadFile} key={attachmentDetails.get('uuid')}/>
    }

    renderSCORMEmbedded = (attachmentDetails) => {
        const { fetchProductLaunchData, productLaunchDetails, isProductLaunchDetailsAvailable } = this.props;
        return <div styleName="file-content-container">
            <SCORMEmbedded
                productLaunchDetails={productLaunchDetails}
                fetchProductLaunchData={fetchProductLaunchData}
                isProductLaunchDetailsAvailable={isProductLaunchDetailsAvailable}    
            />
        </div>;
    }

    renderDownloadButton = (attachmentDetails) => {
        return <a href={attachmentDetails.get('downloadUrl')}
            target="download"
            styleName="download-link"
            onClick={this.onDownloadFile}
        >
			<div styleName="icon-cnt download-div">
				<DownloadIcon/>
			</div>
			<div styleName="download-div">
				{this.props.getLabel('download_file_label')}
			</div>
		</a>;
    }

    render() {
        const { attachmentDetails, progress } = this.props;
        switch(attachmentDetails.get('label')){
            case 'VIDEO':
                return this.renderVideoPlayer(attachmentDetails, progress);
            case 'CONTENT':
                return this.renderSCORMEmbedded(attachmentDetails);
            case 'DOCUMENT':
            case 'PRESENTATION':
                return this.renderDocumentViewer(attachmentDetails, progress);
            case 'IMAGE':
                return this.renderImage(attachmentDetails);
            default:
                return this.renderDownloadButton(attachmentDetails);
        }
    }
}

export default AttachmentViewer;
