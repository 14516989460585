import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import styles from './sectionHeader.module.sass';
import applyLabel from 'utils/LabelProvider';
import { getLockIcon } from 'utils/locks';
import ModuleUtil from 'utils/moduleItem/modules';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SectionHeader extends Component {

	getModuleCompletionPercentage = (module) => {

		if (module.get('items_count') != 0) {

			if (module.get('required_items_count') === 0) {/*all items in the module are optional */
				return module.get('optional_items_completed_count') * 100 / module.get('optional_items_count');
			}

			return module.get('items_completed_count') * 100 / module.get('required_items_count');
		}

		return 0;

	}


	renderLock = (module, phaseItemData) => {
		if (module) {
			const skinHex = this.props.skinGuide.get('skinHex');
			const lockDetails = ModuleUtil.getModuleLockDetails(module, phaseItemData);
			if (lockDetails.locked || lockDetails.expired)
				return <div styleName="lock-icon-cnt" style={{
					float: 'left',
					marginTop: '5px'
				}}>
					{getLockIcon(lockDetails.expired && !lockDetails.completed, true, skinHex.get('greyColor4'))}
				</div>;
		}
	}

	getDateElement = (module, phaseItemData) => {
		const myStyles = getSkin(this.props.skinGuide);
		const lockDetails = ModuleUtil.getModuleLockDetails(module, phaseItemData);
		return <div className={css(myStyles.date)} styleName="date">
			{lockDetails.explanation}
		</div>
	}

	isSelfPacedPhase = () => {
		const { phase } = this.props;
		if (phase.get('settings')) {
			if (phase.get('settings').get('developmentItemType') == 'MODULE_ITEMS') {
				return true;
			}
		}
		return false;
	}

	onSectionHeaderClick = (e) => {
		e.stopPropagation();
		const { module, phaseItemData } = this.props;
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			moduleName: module.get('name'),
			phaseId: phaseItemData.get('phaseId'),
			phaseItemId: phaseItemData.get('id'),
			alCourseId: phaseItemData.get('toolId'),
			courseName: phaseItemData.get('name'),
			batchId: phaseItemData.get('batchId'),
			journeyId: phaseItemData.get('journeyId'),
		}
		window.analytics.track(
			'Sidebar Module clicked',
			segmentData,
			getAmplitudeSessionDetails()
		)
		this.props.onClick()
	}

	getProgressLabel = (module) => {
		const { getLabelWithDefaults } = this.props;
		let mandatoryItemsCompletedLabel = null;
		let optionalItemsLabel = null;

		if (module.get('items_count') === 0) {
			return getLabelWithDefaults('module_no_items_label', `This module has no items`);
		}

		if (module.get('required_items_count') !== 0) {
			mandatoryItemsCompletedLabel = getLabelWithDefaults(
				'count_items_completed_label',
				`${module.get('items_completed_count')} / ${module.get('required_items_count')} completed`, {
				completed_count: module.get('items_completed_count'),
				total_count: module.get('required_items_count')
			}
			);
		}

		if (module.get('optional_items_count') !== 0) {
			optionalItemsLabel = getLabelWithDefaults(
				'optional_module_items_count',
				`${module.get('optional_items_count')} optional item(s)`,
				{total_module_items: module.get('optional_items_count')}
			);
		}

		if (mandatoryItemsCompletedLabel) {
			if (optionalItemsLabel) {
				return <span>{mandatoryItemsCompletedLabel},<br/>{optionalItemsLabel}</span>;
			}
			return mandatoryItemsCompletedLabel;
		}

		if (optionalItemsLabel) {
			return optionalItemsLabel;
		}

		return null;
	}


	render() {
		const styles = getSkin(this.props.skinGuide);
		const { module, active, phaseItemData } = this.props;
		const { isSidebarOpen } = this.props;
		if (!isSidebarOpen) {
			return <span></span>
		}

		return (


			<div className={css(styles.container)} styleName="container"
				onClick={this.onSectionHeaderClick}
			>
				{!this.isSelfPacedPhase() &&
					<div className={css(styles.name)} styleName="header-name">
						{this.renderLock(module, phaseItemData)} {module.get('name')}
					</div>
				}

				{this.getDateElement(module, phaseItemData)}

				<div styleName="progress-container">
					<div className={css(styles.progressBarOuter)} styleName="progress-bar-outer">
						<div style={{
							width: `${this.getModuleCompletionPercentage(module)}%`,
						}}
							className={css(active ? styles.selected : styles.startedBackground)}
							styleName="progress-bar-inner"></div>
					</div>
					<div styleName="completed-count" className={css(styles.completedCount)}>
						{this.getProgressLabel(module)}
					</div>
				</div>


			</div>
		)
	}
}

export default SectionHeader;
