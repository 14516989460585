import React, { Component } from 'react';
import styles from './menuTitle.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import NavigationMenu from 'svgComponents/navigationMenu';
import MenuClose from 'svgComponents/menuClose';
import Home from 'svgComponents/home';
import Lock from 'images/icons/lock.svg';
import Expired from 'images/icons/expired.svg';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class MenuTitle extends Component {

	sendSegmentData = (event, options = {}) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			...options
		};
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		);
	}

	onClickOfMenu = () => {
		const { isSideBarOpen } = this.props;
		this.sendSegmentData('Sidebar menu clicked', { sidebardOpened: !isSideBarOpen });
		this.props.setSideBarFlag(!isSideBarOpen);
	}

	goToJourneyDashboard = () => {
		this.sendSegmentData('Sidebar home clicked');
		this.props.goToJourneyDashboard();
	}

	getLockIcon = () => {
		const { isLocked, isExpired } = this.props;
		if (isLocked) {
			return <img styleName="lock-icon" src={Lock}></img>;
		}
		if (isExpired) {
			return <img styleName="lock-icon" src={Expired}></img>;
		}
		return null;
	}

	render() {

		const myStyles = getSkin(this.props.skinGuide);
		const { isSideBarOpen, phaseName, getLabel, lockExplanation } = this.props;

		return (
			<div>
				{
					(isSideBarOpen)
						?
						<div styleName="outer-margin" title={lockExplanation}>
							<div styleName="container">
								<div styleName="home-icon" onClick={this.goToJourneyDashboard}> <Home strokeColor="#616881" /> </div>
								<div className={css(myStyles.text)} styleName="text" title={getLabel(phaseName)}>
									{this.getLockIcon()}
									{getLabel(phaseName)}
								</div>
								<div styleName="icon" onClick={this.onClickOfMenu}> <MenuClose /> </div>
							</div>
						</div>
						:
						<div styleName="icon" onClick={this.onClickOfMenu}>
							<NavigationMenu />
						</div>
				}
			</div>
		);
	}
}

export default MenuTitle;
