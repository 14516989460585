import React from 'react';
import { PropTypes } from 'prop-types';
import { Pie } from '@vx/shape';
import { Group } from '@vx/group';
import { LinearGradient } from '@vx/gradient';
import { Text } from '@vx/text';
import { scaleOrdinal } from '@vx/scale';
import { GlyphDot } from '@vx/glyph';
import { localPoint } from "@vx/event";
import { withTooltip, Tooltip } from "@vx/tooltip";
import { getGradientBounds, getFlexDirectionForGraphLegend } from 'utils/analytics/utilFunctions';
import GraphLegendComponent from '../../analytics/graphLegendComponent';
import CSSModules from 'react-css-modules';
import styles from './pieGraph.module.sass';
import InfoComponent from 'commonComponents/analytics/infoComponent';

@CSSModules(styles, { allowMultiple: true })

class PieGraph extends React.Component {

	height = 'height' in this.props ? this.props.height : 300;
	margin = { top: 20, left: 20, right: 20, bottom: 20 };

	getPlatform = d => d.platform;
	getTimeSpent = d => d.duration;
	getTimeFormat = d => d.format;

	constructor(props) {
        super(props);
        this.handleTooltip = this.handleTooltip.bind(this);
	}

	getData = () => {
        let data = this.props.graphData ? this.props.graphData.toJS() : this.props.data;
        let metadata, strings = {};
        if (this.props.graphData) {
			metadata = data.metadata ? data.metadata : {};
			strings = data.strings;
			data = data.data;
		}
        return {
            data: data,
            metadata: metadata,
            strings: strings
        }
    };

    getOffset = () => {
        const leftOffset = this.props.barStyling.leftOffset ? this.props.barStyling.leftOffset : 25;
        const rightOffset = this.props.barStyling.rightOffset ? this.props.barStyling.rightOffset : 50;
        return {
            leftOffset: leftOffset,
            rightOffset: rightOffset
        }
    }

    handleTooltip = (event, data) => {
        const { showTooltip } = this.props;
        const { x, y } = localPoint(event.target.ownerSVGElement, event);

        const tooltipLeft = x;
		const tooltipTop = y;
		const percentage = Math.round((data.endAngle - data.startAngle) / (Math.PI * 2) * 100);
        const tooltipData = {
            ...data,
			percentage: percentage
        };

        if(tooltipData) {
            showTooltip({
                tooltipLeft: tooltipLeft,
                tooltipTop: tooltipTop,
                tooltipData: tooltipData
            });
        }
    }

	getLabelText = (x, y, children) => {
		return (
		<text
			fill="white"
			textAnchor="middle"
			x={x}
			y={y}
			dy=".33em"
			fontSize={9}
		>
			{children}
		</text>
		);
	}

	getWidth = () => {
		const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
        let width = calcWidth < 300 ? 300: calcWidth;
        if (this.props.legendPosition == 'left' || this.props.legendPosition == 'right') {
            width = width * 0.8;
        }
        return width;
	}

	getXMax = () => ( 
		this.getWidth() > this.margin.left + this.margin.right ? this.getWidth() - this.margin.left - this.margin.right : this.getWidth() 
	);
	getYMax = () => {
        let height = this.height;
        if (this.props.legendPosition == 'top' || this.props.legendPosition == 'bottom')
            height = this.height * 0.8;
        return height - this.margin.top - this.margin.bottom;
    };

	getPercentage = (platform) => {
		const graphData = this.getData().data;
		let total = 0, platformTime = 0;
		graphData.map((val, i) => ( total+= this.getTimeSpent(val) ));
		graphData.map((val, i) => { if(this.getPlatform(val) == platform) platformTime = this.getTimeSpent(val) });
		if(total == 0) {
			return 0;
		}
		return Math.round(platformTime/total * 100);
	};

    renderGraphComponent = () => {
		const tooltipStyle = {
            fontFamily: 'Open Sans',
            fontSize: '12px',
            color: 'rgba(9, 16, 39, 0.85)',
            width: '90px',
			height: '20px',
			display: 'flex',
			alignItems: 'center',
		};
		
		const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			fontFamily: 'Open Sans',
			color: '#979eb7',
            direction: "column",
            itemDirection: "row",
            labelMargin: "0",
            shapeMargin: "0 8px 0 0",
            itemMargin: "0 0 10px 0",
            shapeWidth: 16,
            shapeHeight: 16
		};

		const {
            tooltipData,
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
			hideTooltip,
			graphFormat,
			barStyling,
			legendPosition
		} = this.props;

		const graphData = this.getData().data;

		const width = this.getWidth();
		const height = this.height;
		const margin = this.margin;
		
        const xMax = this.getXMax();
        const yMax = this.getYMax();
		const radius = Math.min(xMax, yMax) / 2;

		const pieLabels = graphData.map((val, i) => this.getPlatform(val));
		const { labelColors } = graphFormat;

		const pieColors = labelColors.map((color) => ( `linear-gradient(256deg, ${color.from}, ${color.to})` ));
		
		const legendSize = scaleOrdinal({
			domain: pieLabels,
			range: [16]
		});
		const legendShape = scaleOrdinal({
			domain: pieLabels,
			range: [
				props => {
					const { width, height, size, fill } = props;
					return (<GlyphDot
						r={size/2}
						top={width / 2}
						left={height / 2}
						fill={fill}
					/>)
				}
			],
		});

		const flexDirection = getFlexDirectionForGraphLegend(legendPosition);
		return (
			<div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: flexDirection}}>
			<svg width={width} height={height}>
				{
					labelColors.map((color, index) => (
						<LinearGradient
							key={`pie-${index}-${barStyling.component}`}
							from={color.from}
							to={color.to}
							id={`pie-${pieLabels[index]}`}
							vertical={false}
						/>
					))
				}
				{
					labelColors.map((color, index) => (
						<LinearGradient
							key={`legend-${index}-${barStyling.component}`}
							from={color.from}
							to={color.to}
							id={`legend-${index}-${barStyling.componentName}`}
							vertical={false}
						/>
					))
				}
				<Group top={ yMax/2 + margin.top } left={ xMax/2 + margin.left } >
					<Pie
						data={graphData}
						pieValue={d => this.getTimeSpent(d) }
						outerRadius={radius}
						fill={d => `url(#pie-${this.getPlatform(d.data)})`}
						onMouseMove={data => event =>
							this.handleTooltip(
								event,
								data
						)}
						onMouseLeave={() => event => hideTooltip()}
						onMouseOut={() => event => hideTooltip()}
						// centroid={(centroid, arc) => {
						// 	const [x, y] = centroid;
						// 	return this.getLabelText(x, y, arc.data.week);
						// }}
					/>
				</Group>
			</svg>
			<GraphLegendComponent
				style={legendStyling}
				shape={legendShape}
				size={legendSize}
				componentName={barStyling.componentName}
				labelFormat={(platform) => `${platform} ${this.getPercentage(platform)}${graphFormat.valueFormat}`}
			/>
			{tooltipOpen && (
				<Tooltip
					key={Math.random()}
					top={tooltipTop}
					left={tooltipLeft}
					style={{
						...tooltipStyle,
						background: pieColors[tooltipData.index],
					}}
				>
					<div styleName="tooltip-header">{pieLabels[tooltipData.index]}</div>
					<div styleName="tooltip-content">{tooltipData.percentage}{graphFormat.valueFormat}</div>
				</Tooltip>
			)}
		</div>
		);
	}

	validateData = () => {
		const data = this.getData().data;
		data.map((val, index) => {
			if (this.getTimeSpent(val) != 0)
				return true;
		})
		return false;
	}

	render() {
		const data = this.getData().data;
		if (data.length == 0 && this.validateData()) {
			return <InfoComponent 
						{...this.props} 
						infoMessage="This graph does not have data"
					/>
		}
        return (this.renderGraphComponent());
    }
}

PieGraph.propTypes = {
	graphData: PropTypes.object.isRequired,
	size: PropTypes.object,
	barStyling: PropTypes.object,
	graphFormat: PropTypes.object,
	legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom'])
}

PieGraph.defaultProps = {
	size: {
        width: 500,
        height: 500
	},
	graphFormat: {
		labels: ['Mobile', 'Web', 'Tablet', 'Others'],
		labelColors: [
			{from: '#ff0264', to: '#ff71ab'},
			{from: '#ffce23', to: '#fea429'},
			{from: '#26fa96', to: '#52f9aa'},
			{from: '#00faee', to: ' #72fbf5'}
		]
	},
    legendPosition: 'right'
}

export default withTooltip(PieGraph);