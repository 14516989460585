import React, { Component } from 'react';
import styles from './webSessionsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class WebSessionsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 15">
					<g fill="#828593" fillRule="nonzero">
						<path d="M13.843 12.787a.528.528 0 0 0 0-.748L12.308 10.5a.147.147 0 0 1 0-.207l.727-.726a.529.529 0 0 0-.23-.882l-5.159-1.47a.53.53 0 1 0-.654.652l1.47 5.16a.525.525 0 0 0 .882.23l.66-.657a.147.147 0 0 1 .207 0l1.537 1.537a.528.528 0 0 0 .748 0l1.347-1.35z"/>
						<path d="M5.133 10.954a.145.145 0 0 1 .086-.199.583.583 0 0 0-.188-1.13h-.234a.146.146 0 0 1-.142-.117 8.825 8.825 0 0 1-.208-1.925c0-.548.049-1.094.149-1.633a.145.145 0 0 1 .143-.117h4.667c.07 0 .129.049.143.117.043.23.08.467.105.724a.583.583 0 0 0 1.16-.11A9.106 9.106 0 0 0 10.743 6a.146.146 0 0 1 .146-.166h1.636c.065 0 .121.042.14.104.076.251.135.507.175.766a.583.583 0 0 0 1.153-.175 7 7 0 1 0-6.922 8.055.574.574 0 0 0 .562-.473c.174-.887-1.328-.585-2.5-3.156zM3.453 9.45a.147.147 0 0 1-.143.175H1.717a.147.147 0 0 1-.137-.097 5.767 5.767 0 0 1-.104-3.59.146.146 0 0 1 .14-.105h1.637a.146.146 0 0 1 .145.168 10.422 10.422 0 0 0 .055 3.449zm1.708-4.783a.146.146 0 0 1-.135-.202 5.865 5.865 0 0 1 1.962-2.558.145.145 0 0 1 .17 0 5.885 5.885 0 0 1 1.96 2.558.146.146 0 0 1-.135.202H5.161zm6.817-.226a.146.146 0 0 1-.122.226h-1.32a.146.146 0 0 1-.139-.1 7.693 7.693 0 0 0-1.016-2.032.146.146 0 0 1 .182-.216 5.923 5.923 0 0 1 2.415 2.122zM4.583 2.316a.146.146 0 0 1 .182.216 7.67 7.67 0 0 0-1.019 2.034c-.02.06-.075.1-.138.1h-1.32a.146.146 0 0 1-.122-.224 5.854 5.854 0 0 1 2.417-2.126zm-2.215 8.709a.146.146 0 0 1 .116-.233h1.219c.06 0 .115.038.137.095.229.62.54 1.207.923 1.746a.146.146 0 0 1-.181.217 5.861 5.861 0 0 1-2.214-1.825z"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default WebSessionsIcon;
