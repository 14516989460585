import FetchMeasures from "actions/serverCalls/measures/FetchMeasures";
import FetchUserMeasures from "actions/serverCalls/measures/FetchUserMeasures";
import { fetchBatchDetails } from 'actions/batches/newApiActions';
import { filterObjectFromArray } from 'utils/objectUtils';
import {
    fetchItemFeedbackThunk,
    fetchUserItemFeedbackThunk
} from 'actions/itemFeedback/apiActions';

import AssessmentMeasuresPageValidator from 'utils/validators/AssessmentMeasuresPageValidator';


const fetchMeasuresAndUserMeasuresPromises = (batchId, phaseId) => Promise.all([
    FetchMeasures.call({ queryParameters: { batchId, phaseId } }),
    FetchUserMeasures.call({ queryParameters: { batchId, phaseId } })
]);

const getValidationObject = (batchId, phaseId) => {
    return {
        ids: {
            batchId,
            phaseId
        }
    }
}

const fetchMeasureData = (batchId, phaseId, scenario, entityType) => (dispatch, getState) => {
    // const batchId = getState().get('userState').get('currentBatchId');
    // const phaseId = getState().get('userState').get('currentPhaseId');

    return AssessmentMeasuresPageValidator.preValidate(getValidationObject(+batchId, +phaseId))
        .then(() => dispatch(fetchBatchDetails(+batchId)))
        .then(() => fetchMeasuresAndUserMeasuresPromises(batchId, phaseId))
        .then(() => fetchItemFeedbackThunk(dispatch, getState, phaseId, scenario))
        .then(() => fetchUserItemFeedbackThunk(dispatch, getState, batchId, phaseId, entityType, scenario))
        .then(() => AssessmentMeasuresPageValidator.postValidate(getValidationObject(+batchId, +phaseId)));

};

const fetchMeasuresFeedback = (batchId, phaseId, scenario, entityType) => (dispatch, getState) => {
    dispatch(fetchBatchDetails(+batchId)).then(() => fetchItemFeedbackThunk(dispatch, getState, phaseId, scenario))
        .then(() => fetchUserItemFeedbackThunk(dispatch, getState, batchId, phaseId, entityType, scenario))
        .catch((a) => console.error(a));
}

export {
    fetchMeasureData,
    fetchMeasuresFeedback,
    fetchMeasuresAndUserMeasuresPromises
}