import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './multipleImages.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class MultipleImages extends Component {

	constructor(props) {
		super(props);
		this.state={
			imageId: 0,
			imageSrc: ''
		}
	}

	// shouldComponentUpdate(nextProps, nextState){
		
	// 	if(JSON.stringify(nextProps.selectedItem) === JSON.stringify(this.props.selectedItem)
	// 	&& JSON.stringify(nextState.selectedItem) === JSON.stringify(this.state)
	// 	) {
	// 		return false;
	// 	}
	// 	return true;
    // }

	componentWillReceiveProps(nextProps) {
		const { images } = nextProps;
		
	}

	componentDidMount() {
		const { images } = this.props;
		if (images.length > 0) {
			this.setState({
				imageId: 0,
				imageSrc: images[0]
			}, () => {
				setTimeout(() => {
					this.renderNextImage();
				}, 5000);
			});
		}
	}

	renderNextImage = () => {
		const { images } = this.props;
		this.setState({
			imageId: (this.state.imageId + 1) % images.length,
			imageSrc: images[(this.state.imageId + 1) % images.length]
		}, () => {
            setTimeout(() => { this.renderNextImage(); }, 5000)
        });
	}

	renderImage = (selectedItem, myStyles, getLabel) => {
		if (selectedItem.get('imageLinks') && selectedItem.get('imageLinks').size > 0) {
			const images = selectedItem.get('imageLinks').toJS();
			return (<div styleName="image-container">
				{/* <div className={css(myStyles.title)}>
					{this.props.getLabel('help_image')}
				</div> */}
				<div styleName="image-player">
					<img src={images[0]} height="100%" width="100%" />
				</div>
			</div>)

		}
		else{
			return null;
		}
	}

	renderMultipleImage = (selectedItem, myStyles, getLabel) => {
		return (
			<div styleName="image-container">
				{/* <div className={css(myStyles.title)}>
					{this.props.getLabel('help_image')}
				</div> */}
				<div styleName="image-player">
					<img id={this.state.imageId} src={this.state.imageSrc} height="100%" width="100%" />
				</div>
			</div>
		);
	}

	render() {
		const { getLabel, skinGuide } = this.props;
		const myStyles = getSkin(skinGuide);

		return this.renderMultipleImage();
		
	}
}

export default MultipleImages;
