import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({  
        

        widgetContainerStyle: { 
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
            backgroundImage: 'linear-gradient(to bottom, #ffffff, #fafaff)',
            border: 'solid 0.7px rgba(151, 151, 151, 0.16)'
        },

        widgetTitle: {
            ...myTypography.h5,
            fontSize: '14px',
            color: skin.get('widgetTextColor'),
            opacity: 0.6,
            fontWeight: 400
        },

        widgetContentMain: {
            ...myTypography.h5,
            fontSize: '80px',
            fontWeight: 600,
            color: skin.get('widgetTextColor')
        },

        widgetContentSubMain: {
            ...myTypography.h5,
            fontSize: '30px',
            fontWeight: 600,
            color: skin.get('widgetTextColor'),
            textTransform: 'uppercase'
        },

        widgetContenDivider: {
            ...myTypography.h5,
            fontSize: '30px',
            opacity: 0.6,
            fontWeight: 600,
            color: skin.get('widgetTextColor')
        },

        widgetDescription: {
            ...myTypography.h5,
            borderTop: 'solid 0.7px #979797',
            opacity: 0.23,
            fontSize: '12px',
            opacity: 0.6,
            fontWeight: 400,
            lineHeight: 1.56,
            color: skin.get('widgetInsightColor'),
        }
    });
}

const skinHexFunc = (theme) => {
    const skin = theme.get('skinHex');
    return skin;
}

export { 
    getSkin, 
    skinHexFunc
};