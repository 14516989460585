import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './pieChartWithInfoComponent.module.sass';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent';
import PieGraph from 'commonComponents/graphs/pieGraph';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@CSSModules(styles, { allowMultiple: true })

class PieChartWithInfoComponent extends React.Component {

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchPlatformWiseTimeSpentData(+batchId, +userId);
    }

    // shouldComponentUpdate(nextProps, nextState){
    //     if(JSON.stringify(nextProps) === JSON.stringify(this.props)){
    //         return false;
    //     }
    //     else{
    //         return true;
    //     }
    // }

    renderPieChart = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        if(this.props.apiErrors.get('fetchPlatformWiseTimeSpentDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchPlatformWiseTimeSpentDataError')}
                retryHandler={()=>{
                    this.props.fetchPlatformWiseTimeSpentData(+batchId, +userId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('activity');
        
        if (allGraphsData.get('isPlatformWiseTimeSpentDataFetched')) {
            const graphData = allGraphsData.get('platformWiseTimeSpentData');
            const strings = graphData.get('strings');
            const pieBarStyling = {
                gridValue: 4,
                componentName: 'platform-wise-time',
            };

            const pieGraphFormat = {
                labelColors: [
                    {from: '#ff0264', to: '#ff71ab'},
                    {from: '#ffce23', to: '#fea429'},
                    {from: '#26fa96', to: '#52f9aa'},
                    {from: '#00faee', to: ' #72fbf5'}
                ],
                valueFormat: '%'
            };

            return(
                [<InfoWithoutFilterComponent
                    {...this.props}
                    title={strings.get('graphTitle')}
                    subtitle={strings.get('graphDesc')}
                    key="InfoWithoutFilterComponent"
                />,
                <PieGraph
                    graphData={graphData}
                    skinGuide={this.props.skinGuide}
                    // data={this.props.data.usageData}
                    size={this.props.size}
                    barStyling={pieBarStyling}
                    graphFormat={pieGraphFormat}
                    key="PieGraph"
                />]
            )
        }

        return <LoaderComponent />;
    }

    render(){
        return (
            <div styleName="pie-graph-container">
                {this.renderPieChart()}
            </div>
        )
    }
}


export default PieChartWithInfoComponent;