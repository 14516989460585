import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './itemDetails.module.sass';
import CSSModules from 'react-css-modules';
import ProductStat from './productStat';
import ProductInstruction from "./productInstruction";
import ProductDuration from './productDuration';
// import ProductVideo from './productVideo';
import ProductVideo from 'components/assessment/itemDetails/productVideo/ProductVideo.jsx';
import AssessmentItemDetailsLoader from 'commonComponents/loaders/assessmentItemDetailsLoader';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ItemDetails extends Component {

    renderProductDuration = () => {
        const { selectedItem } = this.props;
        return <ProductDuration selectedItem={selectedItem} />;
    }

    renderProductVideo = () => {
        const { selectedItem } = this.props;
        return <ProductVideo selectedItem={selectedItem} />;
    }

    renderProductInstruction = () => {
        const { selectedItem } = this.props;
        return <ProductInstruction selectedItem={selectedItem} />;
    }

    renderColumnDuration = (contentStyle) => {
        return (
            <div styleName="product-and-duration">
                <div styleName={contentStyle}>
                    {this.renderProductInstruction()}
                    {this.renderProductDuration()}
                    {this.renderProductVideo()}
                </div>
            </div>
        );
    }

    renderRowDuration = (contentStyle) => {
        return (
            <div styleName="product-and-duration">
                <div styleName={contentStyle}>
                    {this.renderProductInstruction()}
                    <div styleName="duration-container-row">
                        {this.renderProductDuration()}
                    </div>
                    {this.renderProductVideo()}
                </div>
            </div>
        );
    }

    renderDuration = () => {
        if (!this.props.selectedItem || !this.props.isBatchLabelsAvailable) {
            return (
                <div styleName="product-content-closed">
                    <AssessmentItemDetailsLoader />
                </div>
            );
        }

        let contentStyle = 'product-content-open';
        return this.renderColumnDuration(contentStyle);
    }

    render() {
        return (
            <div styleName="product-exp-container-show-details">
                <div styleName="product-items-container-show-details">
                    {this.renderDuration()}
                </div>
            </div>
        );
    }
}

export default ItemDetails;
