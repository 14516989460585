import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    const entityNameStyle = {
        ...myTypography.overline,
        fontWeight: 600,
        color: 'rgba(9, 16, 39, 0.85)'
    };

    const connectorCircleStyle = {
        border: '2px solid #F6F7F9',
        background: skin.get('greyColor5')
    }

    return StyleSheet.create({
        journeyFeedbackHeading: {
            ...myTypography.h5,
            fontSize: '22px',
            color: skin.get('greyColor3')
        },
        entityRow:{
            background: skin.get('white'),
            borderBottom: '1px solid rgba(151, 151, 144, 0.2)',
            ':last-of-type': {
                borderBottom: 0
            }
        },
        statLabel:{
            ...myTypography.labelSmall,
            textTransform: 'uppercase',
            color: skin.get('greyColor4'),
            lineHeight: '14px'
        },
        statValue: {
            ...myTypography.labelSmall,
            fontWeight: 600,
            color: '#2B3144',
            lineHeight: '14px'
        },
        editFeedbackButton: {
            ...myTypography.button,
            textTransform: 'uppercase',
            fontSize: '10px',
            cursor: 'pointer',
            color: skin.get('secondaryColor'),
            fontWeight: '600'
        },
        entityName: entityNameStyle,
        stepperConnector: {
            background: skin.get('greyColor5')
        },
        activeEntityName: {
            ...entityNameStyle,
            color: skin.get('secondaryColor')
        },
        completedEntityName: {
            ...entityNameStyle,
            color: skin.get('greyColor4')
        },
        connectorCircle: connectorCircleStyle,
        activeConnectorCircle: {
            ...connectorCircleStyle,
            background: skin.get('secondaryColor')
        },
        completedConnectorCircle: {
            ...connectorCircleStyle,
            background: skin.get('darkSkyBlue')
        }
    });
}

export default getSkin;