import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import Next from 'images/icons/next.svg';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        borders: {
            borderLeft: `solid 1px ${skin.get('greyColor5')}`
        }
    });
}

export default getSkin;