import React, { Component } from 'react';
import styles from './presentationsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PresentationsIcon extends Component {

	render() {
		return (
			<div styleName="icon-cnt">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<path fill="#828593" fillRule="nonzero" d="M12.542 0H1.458C.653 0 0 .653 0 1.458v11.084A1.458 1.458 0 0 0 1.458 14h11.084A1.458 1.458 0 0 0 14 12.542V1.458A1.458 1.458 0 0 0 12.542 0zM3.217 7.292a.292.292 0 0 1-.498.206L1.552 6.331a.292.292 0 0 1 0-.412L2.72 4.752a.292.292 0 0 1 .498.206v2.334zm1.167 5.833a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75zm2.625 0a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75zm2.625 0a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75zm2.83-6.793l-1.166 1.166a.292.292 0 0 1-.498-.206V4.958a.292.292 0 0 1 .498-.206l1.167 1.167a.292.292 0 0 1 0 .413z"/>
				</svg>
			</div>
		);
	}
}

export default PresentationsIcon;
