import React, { Component } from 'react';
import styles from './menuList.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import MenuItem from './menuItem';
import MenuTitle from './menuTitle';
import MenuFooter from './menuFooter';
import MenuListLoader from 'commonComponents/loaders/menuListLoader';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import SidebarFeedbackItem from 'commonComponents/collapsibleSidebar/sidebarFeedbackItem';
import routes from 'utils/webAppRoutes/routeConstants';
import enumConstants from 'constants/enums';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import { replaceDatePlaceholderAndFormat } from 'utils/locks';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class MenuList extends Component {

	getAttachmentDetails = (phaseItem, attachmentsList) => {
		if(phaseItem.get('type') === 'ATTACHMENT'){
			return attachmentsList.find(attachment => attachment.get('uuid') === phaseItem.get('attachmentId'))
		}
		return null;
	}

	renderTimeline = () => {
		const {
			selectedUserItem,
			setUserState,
			location,
			userItemsList,
			isSideBarOpen,
			list,
			batchId,
			updateItemIdFromRoute,
			activePhaseDetails,
			match,
			attachmentsList
		} = this.props;

		const myStyles = getSkin(this.props.skinGuide);

		if (!activePhaseDetails || list.size === 0 || userItemsList.size === 0) {
			if (!isSideBarOpen) {
				return null;
			}
			return this.renderLoader(myStyles);
		}

		return list.map((item, index) => (
			<MenuItem key={index}
				currentElementIndex={index}
				itemsListLength={list.size}
				item={item}
				attachmentDetails={this.getAttachmentDetails(item, attachmentsList)}
				selectedUserItem={selectedUserItem}
				userItemsList={userItemsList}
				isSideBarOpen={isSideBarOpen}
				batchId={batchId}
				setUserState={setUserState}
				location={location}
				updateItemIdFromRoute={updateItemIdFromRoute}
				match={match}
				toggleDialog={this.props.toggleDialog}
				isFromFeedback={this.props.isFromFeedback}
			/>
		));
	}

	renderLoader(myStyles) {
		return (
			<div styleName="container" className={css(myStyles.loaderContainer)}>
				<MenuListLoader />
			</div>
		);
	}

	shouldEnableFeedback = () => {
		const {
			userItemsList,
			list,
			activePhaseDetails
		} = this.props;
		let allCompleted = true;

		for (let i = 0; i < list.size; i++) {
			const userPhaseItem = userItemsList.find(item => item.get('id') === list.getIn([i, 'id']))
			if (userPhaseItem && userPhaseItem.get('workflowStatus') !== enumConstants.get('COMPLETED')) {
				allCompleted = false;
				break;
			}
		}

		if (activePhaseDetails.isLocked || (activePhaseDetails.isExpired && !allCompleted)) {
			return false;
		}

		return allCompleted;
	}

	goToPhaseFeedback = (isFeedbackEnabled) => {
		if (isFeedbackEnabled) {
			const { activePhaseDetails, match } = this.props;
			const batchId = match.params.batchId;
			const route = routes.get('PHASE_FEEDBACK');
			const params = [{ 'BATCH_ID': batchId }, { 'PHASE_ID': activePhaseDetails.id }];
			const routeObj = { route, params };
			// this.props.setPhaseWorkflowStatus(batchId, activePhaseDetails.id, enumConstants.get('COMPLETED'));
			updateRoute(routeObj);
		}
	}

	render() {
		const { isSideBarOpen,
			setSideBarFlag,
			activePhaseDetails,
			batchId,
			userItemsList,
			list,
			phases,
			match,
			currentPhaseData,
			feedbackStatuses
		} = this.props;
		const myStyles = getSkin(this.props.skinGuide);

		let timelineStyle = myStyles.collapsed;
		if (isSideBarOpen) {
			timelineStyle = myStyles.expanded;
		}

		if (!activePhaseDetails) {
			return null;
		}

		let listContainerStyle = (isSideBarOpen) ? "list-items-open" : "list-items-closed";

		const lockExplanation = replaceDatePlaceholderAndFormat(true, activePhaseDetails.lockExplanation, activePhaseDetails.lockDate, "short");

		const phaseFeedbackStatus = feedbackStatuses.get('phaseFeedbackStatuses').find(phaseFeedback => phaseFeedback.get('id') === currentPhaseData.id)

		const isFeedbackEnabled = this.shouldEnableFeedback();
		return (
			<div styleName="container">
				<MenuTitle isSideBarOpen={isSideBarOpen}
					setSideBarFlag={setSideBarFlag}
					phaseName={activePhaseDetails.name}
					isExpired={activePhaseDetails.isExpired}
					isLocked={activePhaseDetails.isLocked}
					lockExplanation={lockExplanation}
					goToJourneyDashboard={this.props.goToJourneyDashboard} />
				<div
					styleName={listContainerStyle}
					className={css(timelineStyle)}
					style={{
						height: isSideBarOpen ? 'calc(100% - 112px)' : 'calc(100% - 130px)'
					}}
				>
					<APIErrorBoundary
						key={this.props.match.params.itemId}
						apiError={this.props.apiErrors.get('fetchPhaseItemsError') || this.props.apiErrors.get('fetchUserPhaseItemsError')}
						retryHandler={() => this.props.fetchItemData(+this.props.match.params.batchId, +this.props.match.params.itemId)}
						style={{
							display: isSideBarOpen ? 'block' : 'none'
						}}
					>
						<div styleName="timeline-items">
							{this.renderTimeline()}
							{
								currentPhaseData &&
								currentPhaseData.settings.feedbackScenarioId &&
								currentPhaseData.settings.isFeedbackEnabled === 'true' &&
								isSideBarOpen &&
								<SidebarFeedbackItem
									isDisabled={!isFeedbackEnabled}
									onClickFunction={
										() => this.goToPhaseFeedback(isFeedbackEnabled)
									}
									isFromFeedback={this.props.isFromFeedback}
									feedbackStatus={phaseFeedbackStatus}
								/>
							}
						</div>
					</APIErrorBoundary>
				</div>
				<MenuFooter
					isSideBarOpen={isSideBarOpen}
					batchId={batchId}
					phases={phases}
					userState={this.props.userState}
				/>
			</div>
		);
	}
}

export default MenuList;
