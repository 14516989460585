import React, { Component } from 'react';
import styles from './genericFilesIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class GenericFilesIcon extends Component {

	render() {
		return (
			<div styleName="icon-cnt">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 15 14">
					<path fill="#828593" fillRule="evenodd" d="M13 12.688c0 .483-.416.874-.929.874H.93c-.513 0-.929-.391-.929-.874V1.312C0 .83.416.438.929.438h9.313c.243 0 .475.09.649.249l1.829 1.682c.179.164.28.39.28.626v9.693zM5.15 8.311a.144.144 0 0 0 .14-.11c.191-.755.66-1.417 1.32-1.857a.14.14 0 0 0 .037-.204L5.169 4.222a.152.152 0 0 0-.252.02L2.78 8.097a.142.142 0 0 0 .003.143.15.15 0 0 0 .129.072H5.15zm3.3 2.917c1.297-.001 2.348-1.024 2.35-2.285 0-.924-.573-1.757-1.452-2.11a2.4 2.4 0 0 0-2.561.495 2.239 2.239 0 0 0-.51 2.49 2.352 2.352 0 0 0 2.173 1.41z"/>
				</svg>
			</div>
		);
	}
}

export default GenericFilesIcon;
