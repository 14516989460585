
const breakpoints = {
	'xxs' : {
		min: 250,
		max: 480
	},
	'xs': {
		min: 480,
		max:  720
	},
	'sm': {
		min: 720,
		max:  1280
	},
	'md': {
		min: 1280,
		max:  1600
	},
	'lg': {
		min: 1600,
		max:  1920
	}
}

const getBreakpoint = (width) => {
	let screen;
	Object.keys(breakpoints).forEach(breakpoint => {
		if(breakpoints[breakpoint].min <= width && breakpoints[breakpoint].max > width) {
			screen = breakpoint;
		}
	});
	return screen;
}

export default getBreakpoint;