import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './takeQuizHeader.module.sass';
import getSkin from './skin.js';
import DownArrow from 'svgComponents/downArrow';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import Dialog from 'commonComponents/dialog'
import applyLabel from 'utils/LabelProvider';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { checkAndAddAudioControls } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class TakeQuizHeader extends React.Component {

	constructor(props) {
		super(props);
		let timeSet = props.moduleItem.getIn(['contentDetails', 'time_limit']) * 60;
		let timeElapsed = props.quiz.getIn(['activeAttempt', 'time_elapsed']);
		let timeLeft = 0;
		if (timeSet && parseInt(timeElapsed) != NaN) {
			if (timeSet > timeElapsed) timeLeft = timeSet - timeElapsed;
		}
		this.state = {
			showInstructions: false,
			showConfirmationPopup: false,
			popupMessage: '',
			popupOkAction: null,
			popupCancelAction: null,
			popupOkButtonText: '',
			isComponentLoading: false,
			timeLeft: timeLeft
		}
		this.quizTimerInterval = null;
	}

	toggleInstructions = () => {
		this.setState({
			showInstructions: !this.state.showInstructions
		})
	}

	componentWillUnmount() {
		this.hideConfirmationPopup()
	}

	componentDidMount() {
		if (
			this.props.moduleItem.get('isModuleItemDataAvailable') &&
			this.props.moduleItem.getIn(['contentDetails', 'time_limit'])
		) {
			this.updateTimeLeft();
		}
	}

	hideConfirmationPopup = () => {
		this.setState({
			showConfirmationPopup: false,
			popupMessage: '',
			popupOkAction: null,
			popupCancelAction: null,
			popupOkButtonText: '',
			isComponentLoading: false
		})
	}

	updateTimeLeft = () => {
		this.quizTimerInterval = setInterval(() => {
			if (this.state.timeLeft) {
				let timeLeft = this.state.timeLeft - 1;
				if (timeLeft === 0) {
					this.setState({
						showConfirmationPopup: true,
						popupMessage: 'Your Time is up! Please Wait, submitting your answers.',
						popupOkAction: null,
						popupCancelAction: null,
						isComponentLoading: true,
						timeLeft
					}, () => {
						clearInterval(this.quizTimerInterval);
						this.props.submitQuiz();
					});
				} else {
					this.setState({
						timeLeft
					})
				}
			}
		}, 1000)
	}

	showTimeLeft = () => {
		let min = 0;
		let sec = 0;
		const { timeLeft } = this.state;
		if (timeLeft) {
			min = parseInt(timeLeft / 60);
			sec = timeLeft % 60;
		}
		if (min < 100) {
			min = ('00' + min).slice(-2)
		}
		sec = ('00' + sec).slice(-2)
		return { min, sec }
	}

	sendSegmentData = (event) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash
		};
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		);
	}

	onSubmitQuiz = () => {
		let { quiz, getLabelWithDefaults } = this.props;
		let incompleteQuestionCount = 0;
		this.sendSegmentData('Quiz Submit clicked');

		let message = getLabelWithDefaults(
			'quiz_submit_confirmation_label',
			'Are you sure you want to submit the quiz?'
		);
		let answeredQuestions = quiz.get('quizAnswerSelected').filter((que) => {
			if (typeof que.get('answer') === 'number' || (typeof que.get('answer') === 'string' && que.get('answer').length > 0)) {
				return que
			}
			if (que.get('answer') && que.get('answer').size > 0) {
				return que
			}
		})
		if (answeredQuestions.size < quiz.get('quizQuestions').size) {
			incompleteQuestionCount = quiz.get('quizQuestions').size - answeredQuestions.size
		}
		if (incompleteQuestionCount > 0) {
			message = getLabelWithDefaults(
				'quiz_submit_confirmation_unanswered_label',
				`<span>You have not answered <b>${incompleteQuestionCount}</b> questions. Are you sure you want to submit the quiz?<span>`,
				{ unanswered_questions_count: incompleteQuestionCount }
			);
		}
		this.setState({
			showConfirmationPopup: true,
			popupMessage: message,
			popupOkButtonText: getLabelWithDefaults('quiz_submit_confirmation_yes_label', 'Yes, submit'),
			popupOkAction: this.submitQuizAfterConfirmation,
			popupCancelAction: this.hideConfirmationPopup,
			isComponentLoading: false
		})
	}

	submitQuizAfterConfirmation = () => {
		clearInterval(this.quizTimerInterval);
		this.setState({
			popupMessage: this.props.getLabelWithDefaults(
				'quiz_submission_in_progress_label',
				'Please Wait. Submitting your answers.'
			),
			popupOkAction: null,
			popupCancelAction: null,
			isComponentLoading: true
		}, () => this.props.submitQuiz())
	}

	onQuizExit = () => {
		this.sendSegmentData('Quiz Exit clicked');
		this.setState({
			showConfirmationPopup: true,
			popupMessage: this.props.getLabelWithDefaults(
				'quiz_exit_confirmation_message',
				'Are you sure you want to exit the quiz?'
			),
			popupOkAction: this.exitQuizAfterConfirmation,
			popupCancelAction: this.hideConfirmationPopup,
			isComponentLoading: false
		})
	}

	exitQuizAfterConfirmation = () => {
		const { match } = this.props;
		const batchId = match.params.batchId;
		const courseId = match.params.courseId;
		const moduleId = match.params.moduleId;
		const moduleItemId = match.params.moduleItemId;
		const phaseId = match.params.phaseId;
		const phaseItemId = match.params.phaseItemId;
		const route = routes.get('BATCH_ITEM_MODULE');
		const params = [
			{ 'BATCH_ID': batchId },
			{ 'COURSE_ID': courseId },
			{ 'MODULE_ID': moduleId },
			{ 'ITEM_ID': moduleItemId },
			{ '_PHASE_ID_': phaseId },
			{ '_PHASEITEMID_': phaseItemId }
		]
		updateRoute({ route, params });
	}

	dontHidePopup = (e) => {
		e.stopPropagation()
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		let { moduleItem, quiz, getLabelWithDefaults } = this.props;
		let { showInstructions, showConfirmationPopup, popupMessage, popupOkAction, popupCancelAction, popupOkButtonText, isComponentLoading } = this.state;
		let quizDescription = moduleItem.get('moduleItem').get('description');

		var quizTitleElement, quizTimerElement, quizActionsElement, quizInstructionsElement, quizDialogElement;

		quizTitleElement = quizTimerElement = quizActionsElement = quizInstructionsElement = quizDialogElement = null;

		let actuallyAnsweredQuestions = quiz.get('quizAnswerSelected').filter(ans => ans.get('answer'));
		// console.log(actuallyAnsweredQuestions)

		actuallyAnsweredQuestions = actuallyAnsweredQuestions.filter(ans => !ans.get('answer')._tail || (ans.get('answer')._tail && ans.get('answer').size));

		let quizTime = this.showTimeLeft();

		if (moduleItem.get('isModuleItemDataAvailable')) {
			quizTitleElement = (
				<div
					key="quiz-title"
					className={css(styles.quizTitle)}
					styleName="quiz-title"
					onClick={this.toggleInstructions}
				>
					<div
						styleName="title"
						title={moduleItem.get('moduleItem').get('title')}
					>
						{moduleItem.get('moduleItem').get('title')}
					</div>
					{
						quizDescription != "" &&
						<div styleName="instruction-arrow-cnt">
							<DownArrow fillColor={'#5d5d5d'} />
						</div>
					}
				</div>
			)

			quizTimerElement = (
				<div
					key="timer-cnt"
					className={css(styles.timerCnt)}
					styleName="timer-cnt"
				>
					{
						moduleItem.getIn(['contentDetails', 'time_limit']) ?
							<div className={css(styles.timerTime)}>
								{
									`${quizTime.min}:${quizTime.sec}`
								}
							</div>
							: null
					}
					<div className={css(styles.questionsAnswered)}>
						{
							getLabelWithDefaults(
								'quiz_questions_answered_count_label',
								`${actuallyAnsweredQuestions.size}/${quiz.get('quizQuestions').size} answered`,
								{
									answered_count: actuallyAnsweredQuestions.size,
									total_count: quiz.get('quizQuestions').size
								}
							)
						}
					</div>
				</div>
			)

			quizActionsElement = (
				<div key="quiz-actions-cnt" styleName="quiz-actions-cnt">
					<div
						className={css(styles.exitBtn)}
						styleName="exit-btn"
						onClick={this.onQuizExit}
					>
						{getLabelWithDefaults('exit_label', 'EXIT')}
					</div>
					<div
						className={css(styles.submitBtn)}
						styleName="submit-btn"
						onClick={this.onSubmitQuiz}
					>
						{getLabelWithDefaults('submit_label', 'SUBMIT')}
					</div>
				</div>
			)

			if (quizDescription != "" && showInstructions) {
				quizInstructionsElement = (
					<div key="quiz-instructions-cnt" styleName="quiz-instructions-overlay" onClick={this.toggleInstructions}>
						<div styleName="my-container">
							<div styleName="triangle-with-shadow"></div>
							<div styleName="quiz-instructions-cnt" onClick={this.dontHidePopup}>
								<div className={css(styles.quizInstructionsTitle)}>Instructions</div>
								<div className={css(styles.quizInstructions)} dangerouslySetInnerHTML={{ __html: checkAndAddAudioControls(quizDescription) }}></div>
							</div>
						</div>
					</div>
				)
			}

			if (showConfirmationPopup) {
				quizDialogElement = (
					<Dialog
						key="quiz-confirmation-dialog"
						dialogMessage={popupMessage}
						okAction={popupOkAction}
						cancelAction={popupCancelAction}
						okButtonText={popupOkButtonText}
						isComponentLoading={isComponentLoading}
					/>
				)
			}

		}
		return (
			<div className={css(styles.quizHeaderCnt)} styleName="quiz-header-cnt">
				{
					moduleItem.get('isModuleItemDataAvailable') ?
						[quizTitleElement, quizTimerElement, quizActionsElement, quizInstructionsElement, quizDialogElement]
						:
						<div>
							{getLabelWithDefaults('quiz_loading_label', 'Loading Quiz Content...')}
						</div>
				}

			</div>
		)
	}
}

export default TakeQuizHeader;