import React, { Component } from 'react';
import styles from './overlappingBarsWidget.module.sass';
import CSSModules from 'react-css-modules';
import { getSkin, skinHexFunc } from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import OverlappingBars from 'commonComponents/graphs/overlappingBars';
import { checkIfPresent } from 'utils/utilFunctions';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class OverlappingBarsWidget extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    renderInsightForWidget = (styles, insightPresent) => {
        if (insightPresent) {
            return(
                <div className={css(styles.widgetDescription)} styleName="widget-description">
                    Your average completion rate in assessments is way below your group average. 
                    You need to complete all assessments soon 
                </div>
            )
        }
    }

    getSectionsData = (courseId) => {
        const allGraphsData = this.props.graphData;
        const courses = allGraphsData.courses;
        const sections = allGraphsData.sections;

        let data, overallAverage, userAverage;
        if (courseId === -1) {
            data = sections.data;
            overallAverage = courses.sectionOverallAverage;
            userAverage = courses.sectionUserAverage;
        }
        else {
            const courseData = courses.data.filter((course) => course.id === courseId)[0];
            data = sections.data.filter((section) => section.courseId === courseId);
            overallAverage = courseData.sectionOverallAverage;
            userAverage = courseData.sectionUserAverage;
        }
        return {
            data: data,
            metadata: sections.metadata,
            overallAverage: overallAverage,
            userAverage: userAverage,
            isUserDataAvailable: this.props.graphData.isUserDataAvailable
        };
    }
  
    render() {
        const styles = getSkin(this.props.skinGuide);

        const hexval = this.props.skinGuide.get('skinHex');
        const widgetDataArray = this.props.widgetArray;

        const insightPresent = checkIfPresent(widgetDataArray.insight);

        const widgetName = widgetDataArray.get('widgetName');

        let graphData = this.props.graphData;
        let isUserDataAvailable = widgetDataArray.get('isUserDataAvailable');

        const strings = graphData.strings;
        const metadata = graphData.metadata;
        let graphFormat;
        let hasContinuousAverage = false;
        
        const dataFormat = '';
        if (widgetName === 'WeeklyCompletionRate') {
            graphFormat = {
                xLabel: strings.weeks,
                yLabel: strings.yAxisLabel,
                valueFormat: dataFormat,
                valuePrefix: '',
                valueName: strings.week
            };
        }
        else if (widgetName === 'AssessmentProgress') {
            hasContinuousAverage = true;
            graphFormat = {
                xLabel: strings.xAxisLabel,
                yLabel: strings.yAxisLabel,
                valueFormat: dataFormat,
            };
        }
        else if (widgetName === 'DevelopmentCourseProgress') {
            graphFormat = {
                xLabel: strings.courseLabel,
                yLabel: strings.yAxisLabel,
                valueFormat: dataFormat,
            };
        }
        else if (widgetName === 'DevelopmentSectionProgress') {
            graphFormat = {
                xLabel: strings.sectionLabel,
                yLabel: strings.yAxisLabel,
                valueFormat: dataFormat,
            };
            graphData = this.getSectionsData(graphData.selectedCourseId);
            isUserDataAvailable = graphData.isUserDataAvailable;
        }
        else if (widgetName === 'DevelopmentCourseScore') {
            graphFormat = {
                xLabel: strings.courseLabel,
                yLabel: strings.yAxisLabel,
                valueFormat: dataFormat,
            };
        }
        else if (widgetName === 'DevelopmentSectionScore') {
            graphFormat = {
                xLabel: strings.sectionLabel,
                yLabel: strings.yAxisLabel,
                valueFormat: dataFormat,
            };
            graphData = this.getSectionsData(graphData.selectedCourseId);
            isUserDataAvailable = graphData.isUserDataAvailable;
        }
        else {
            graphFormat = {
                xLabel: strings.weeks,
                yLabel: strings.yAxisLabel,
                valueFormat: dataFormat,
                valuePrefix: '',
                valueName: strings.week
            };
        }

        const barStyling = {
            fromUser: hexval.get('widgetBlueStart'),
            toUser: hexval.get('widgetBlueEnd'),
            userRotation: 140,
            fromGroup: hexval.get('widgetPinkStart'),
            toGroup: hexval.get('widgetPinkEnd'),
            groupRotation: -356,
            gridValue: 10,
            leftOffset: 0,
            rightOffset: 0,
            componentName: 'bar-widget'
        }

        return (
            <div className={css(styles.widgetContainerStyle)} styleName="widget-container-class">
                <div className={css(styles.widgetTitle)} styleName="widget-title">
                    {widgetDataArray.get('title')}
                </div>
                <div styleName="widget-content">
                    <OverlappingBars 
                        isUserDataAvailable={isUserDataAvailable}
                        skinGuide={this.props.skinGuide}
                        barStyling={barStyling}
                        // data={itemsData}
                        graphData={graphData}
                        height={insightPresent ? 200 : 230}
                        graphFormat={graphFormat}
                        legendPosition='right'
                        hasAverageLinesLegend={!hasContinuousAverage}
                        hasContinuousAverage={hasContinuousAverage}
                        strings={strings}
                    />
                </div>
                {this.renderInsightForWidget(styles, insightPresent)}
            </div>
        );
    }
  }
  
  OverlappingBarsWidget.defaultProps = {
  };

  export default OverlappingBarsWidget;