import React from 'react';
import styles from './journeysLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';
import { css } from 'aphrodite';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneysLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<div styleName="loader-cnt">
				<ContentLoader
					height="100%"
					width="100%"
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '100%', width: '100%' }}
				>
					<rect x="50" y="50" rx="0" ry="0" width="300" height="200" />
					<rect x="400" y="50" rx="0" ry="0" width="300" height="200" />
					<rect x="750" y="50" rx="0" ry="0" width="300" height="200" />
					<rect x="50" y="300" rx="0" ry="0" width="300" height="200" />
					<rect x="400" y="300" rx="0" ry="0" width="300" height="200" />
					<rect x="750" y="300" rx="0" ry="0" width="300" height="200" />
				</ContentLoader>
			</div>
		);
	}
}

export default JourneysLoader;
