/**
 * @author: sourabh p
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Perdo.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * base container is rendered on initial load of application and mounted only
 * once displayed on every route of the application and all other containers are
 * children of this base container
 *
 **/

import React from 'react';
import { connect } from 'react-redux';

import { fetchBatchDetails } from 'actions/batches/newApiActions';
import { updateBatchIdFromRoute } from 'actions/batches/actionCreators';

import { filterObjectFromArray } from 'utils/objectUtils';
import JourneyLearningsPhaseLoader from 'commonComponents/loaders/journeyLearningsPhaseLoader';
import ActivityComponent from 'components/analytics/activityComponent';
import { fetchOverallProgressData, 
		fetchDayWiseTimeSpentData, 
		fetchPlatformWiseTimeSpentData,
		fetchStatsData,
		fetchAssessmentScoreData,
		fetchAssessmentProgressData,
		fetchAssessmentTimeSpentData,
		fetchAssessmentMeasuresData,
		fetchDevelopmentScoreData,
		fetchDevelopmentProgressData,
		fetchDevelopmentTimeSpentData,
		fetchDevelopmentMeasuresData,
		fetchTimeSpentPeriodicallyData,
		fetchProgressPeriodicallyData,
		fetchWidgetAnalyticsData,
		fetchGenericAnalyticsData
	 } from 'actions/analytics/apiActions';

import {
	startTracking,
	endTracking
} from 'utils/mixpanelTracking';

import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import { shouldShowBatchAnalytics } from 'utils/utilFunctions';
import NotAvailable from 'commonComponents/notAvailable/NotAvailable';

class ActivityContainer extends React.Component {

	componentDidMount() {
		const { batchId } = this.props.match.params;
		this.props.fetchBatchDetails(batchId);
		
		const userId = this.props.user.get('userID');
		this.props.fetchGenericAnalyticsData(+batchId, +userId);
		startTracking(true, {
            batchId: this.props.match.params.batchId
        }, 'AnalyticsActivityScreen');
	}

	componentWillReceiveProps(nextProps) {
		const { batchId } = nextProps.match.params;
		if (this.props.match.params.batchId != batchId) {
			this.props.fetchBatchDetails(batchId);
			endTracking();
			startTracking(true, {
				batchId: nextProps.match.params.batchId
			}, 'AnalyticsActivityScreen');
		}
	}

	componentWillUnmount(){
		endTracking();
	}

	render() {
		
		const { batches, apiErrors, user } = this.props;
		const activeBatchId = batches.get('activeBatchId');
		const batchesList = batches.get('batchesList');
		const userBatchesList = batches.get('userBatchesList');
		const activeUserBatch = filterObjectFromArray(userBatchesList, 'batchId', activeBatchId);
		const { phases } = this.props;
		const isGenericDataFetched = this.props.allGraphsData.get('genericData').get('isGenericDataFetched');

		if(apiErrors.get('fetchGenericAnalyticsDataError')){
            return <APIErrorBoundary 
                key={activeBatchId} 
                apiError={apiErrors.get('fetchGenericAnalyticsDataError')}
                retryHandler={()=>{
					this.props.fetchGenericAnalyticsData(+activeBatchId, +user.get('userID'));
				}}
            />
        }

		if (activeBatchId === -1 || activeUserBatch.isEmpty() || !phases.get('isPhasesListFetched') || !isGenericDataFetched) {
			return <JourneyLearningsPhaseLoader />
		}

		if(!shouldShowBatchAnalytics(batches)){
			return <NotAvailable>Analytics are not enabled for this batch</NotAvailable>
		}

		return <ActivityComponent data={this.props.userState.toJS()} {...this.props} />
	}
}

const mapStateToProps = (state) => ({
	batches: state.get('batches'),
	userState: state.get('userState'),
	allGraphsData: state.get('allGraphsData'),
	phases: state.get('phases'),
	user: state.get('user'),
	apiErrors: state.get('apiErrors')
});

const mapDispatchToProps = (dispatch) => ({
	fetchBatchDetails: (batchId) => {
		dispatch(fetchBatchDetails(batchId));
	},
	updateBatchIdFromRoute: (batchIdFromRoute) => {
		dispatch(updateBatchIdFromRoute(batchIdFromRoute));
	},
	fetchOverallProgressData: (batchId, userId) => {
		dispatch(fetchOverallProgressData(batchId, userId))
	},
	fetchDayWiseTimeSpentData: (batchId, userId) => {
		dispatch(fetchDayWiseTimeSpentData(batchId, userId))
	},
	fetchPlatformWiseTimeSpentData: (batchId, userId) => {
		dispatch(fetchPlatformWiseTimeSpentData(batchId, userId))
	},
	fetchStatsData: (batchId, userId) => {
		dispatch(fetchStatsData(batchId, userId))
	},
	fetchAssessmentScoreData: (batchId, userId, phaseId) => {
		dispatch(fetchAssessmentScoreData(batchId, userId, phaseId))
	},
	fetchAssessmentProgressData: (batchId, userId, phaseId) => {
		dispatch(fetchAssessmentProgressData(batchId, userId, phaseId))
	},
	fetchAssessmentTimeSpentData: (batchId, userId, phaseId) => {
		dispatch(fetchAssessmentTimeSpentData(batchId, userId, phaseId))
	},
	fetchAssessmentMeasuresData: (batchId, userId, phaseId) => {
		dispatch(fetchAssessmentMeasuresData(batchId, userId, phaseId))
	},
	fetchDevelopmentScoreData: (batchId, userId, phaseId) => {
		dispatch(fetchDevelopmentScoreData(batchId, userId, phaseId))
	},
	fetchDevelopmentProgressData: (batchId, userId, phaseId) => {
		dispatch(fetchDevelopmentProgressData(batchId, userId, phaseId))
	},
	fetchDevelopmentTimeSpentData: (batchId, userId, phaseId) => {
		dispatch(fetchDevelopmentTimeSpentData(batchId, userId, phaseId))
	},
	fetchDevelopmentMeasuresData: (batchId, userId, phaseId) => {
		dispatch(fetchDevelopmentMeasuresData(batchId, userId, phaseId))
	},
	fetchTimeSpentPeriodicallyData: (batchId, userId) => {
		dispatch(fetchTimeSpentPeriodicallyData(batchId, userId))
	},
	fetchProgressPeriodicallyData: (batchId, userId) => {
		dispatch(fetchProgressPeriodicallyData(batchId, userId))
	},
	fetchWidgetAnalyticsData: (batchId, userId, phaseId) => {
		dispatch(fetchWidgetAnalyticsData(batchId, userId, phaseId))
	},
	fetchGenericAnalyticsData: (batchId, userId) => {
		dispatch(fetchGenericAnalyticsData(batchId, userId))
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityContainer);