import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreComponentAssesment.module.sass';
import ScoreRadarGraphsWithInfo from './scoreRadarGraphsWithInfo/ScoreRadarGraphsWithInfo';
import ScoreCardInfoWithBars from './scoreCardInfoWithBars/ScoreCardInfoWithBars';


@CSSModules(styles, { allowMultiple: true })

class ScoreComponentAssesment extends React.Component {

    render(){
          
        return(
            <div styleName="outer-graph-container">
                <div styleName="graph-container">
                    <ScoreRadarGraphsWithInfo {...this.props} />
                </div> 
            </div>
        )
    }
}
 

export default ScoreComponentAssesment;