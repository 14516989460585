/* collection of utils to work with various kinds of locks in the app*/
import {getFormattedDate} from 'utils/dates';
import LockIcon from 'svgComponents/lockIcon';
import ExpiredIcon from 'images/icons/expired.svg';
import React from 'react';

const getLockMessage = (lockDetails, format="long") => {
    return replaceDatePlaceholderAndFormat(
        lockDetails && lockDetails.get('locked'),
        lockDetails.get('lock_explanation'),
        lockDetails.get('lock_date'),
        format
    );   
}

const replaceDatePlaceholderAndFormat = (locked, lockExplanation, lockDate, format="long") => {
    try{
        if(locked && lockExplanation){
            const formattedDate = getFormattedDate(lockDate, format);
            return lockExplanation.replace('DATE_PLACEHOLDER', formattedDate);
        }
    }
    catch(e){
        console.log('e', e)
    };
    return null;    
}

const getLockIcon = (isExpired, isLocked, svgColor) => {
    if(isExpired) return <img src={ExpiredIcon} width="115%" height="100%"/>;
    return <LockIcon svgColor={svgColor}/>;
}

export {
    getLockMessage,
    replaceDatePlaceholderAndFormat,
    getLockIcon
};