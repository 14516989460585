import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import NextActivityImage from 'images/dashboard/nextBackground.png'

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		userJourneysContainer: {
			borderTop: `7px solid ${skinHex.get('secondaryColor')}`
		},
		welcomeBg: {
			background: `linear-gradient(135deg, ${hexToRgbA(skinHex.get('primaryColor'), 0.1)} , ${hexToRgbA(skinHex.get('secondaryColor'), 0.1)})`
		},
		welcomeBgImg: {
			backgroundImage: `url(${NextActivityImage})`,
			backgroundSize: "100%",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
		},
		requestCourseLabel: {
			...myTypography.button,
			color: skinHex.get('secondaryColor'),
			cursor: 'pointer',
			border: `1px dashed ${skinHex.get('secondaryColor')}`
		},
		backToHome: {
			...myTypography.h4,
			fontWeight: 'normal',
			color: skinHex.get('greyColor2')
		},
		crumbsText: {
			...myTypography.caption,
			color: hexToRgbA('#979EB7', 0.7)
		},
		crumbsTextBorder: {
			...myTypography.caption,
			color: hexToRgbA('#979EB7', 0.7),
			borderLeft: `1px solid ${hexToRgbA('#979EB7', 0.7)}`
		},
		headerCnt: {
			borderTop: `7px solid ${skinHex.get('secondaryColor')}`
		}
	});
}

export default getSkin;