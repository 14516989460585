import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './page.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import { Waypoint } from 'react-waypoint';
import CompletedIcon from 'svgComponents/completedIcon';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class Page extends Component {

    markPageRead = () => {
        console.log('debug: bottom reached for page item');
        this.props.onProgress(0, 0, true);
    }

    onWayPointLeave = () => {
        console.log('debug: not at bottom anymore for page item');
    }

    renderMarkCompleteSection = () => {
        const { isAlreadyCompleted, skinGuide, selectedUserItem } = this.props;
        const styles = getSkin(skinGuide);
        const skinHex = skinGuide.get('skinHex');

        if (isAlreadyCompleted || selectedUserItem.get('workflowStatus')) {
            return <div className={css(styles.completedText)} styleName="completed-cnt">
                <CompletedIcon strokeColor={skinHex.get('appleGreen')} />
                Completed
            </div>;
        }

        return <div className={css(styles.markCompleteButton)} onClick={this.markPageRead} styleName="mark-complete-btn">
            Mark Complete
        </div>;
    }

    componentDidMount() {
        /*polyfill for IE11*/
        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        let linkList = document.querySelectorAll('.pageContainer a');
        linkList.forEach(link => link.setAttribute('target', "_blank"))
    }

    render() {
        let styles = getSkin(this.props.skinGuide);
        let { pageData } = this.props;

        return (
            <div className="pageContainer">
                <div styleName="page-content-cnt" className={css(styles.body)}>
                    <div dangerouslySetInnerHTML={{ __html: pageData.get('content') }} />
                </div>
                <Waypoint
                    onEnter={this.markPageRead}
                    onLeave={this.onWayPointLeave}
                    bottomOffset="-30%"
                />
                <div styleName="mark-complete-cnt">
                    {this.renderMarkCompleteSection()}
                </div>
            </div>
        )
    }
}

export default Page;