import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

import { appendQueryParameters } from 'utils/utilFunctions';

class SaveItemProgress extends NetPackWrapper {

    constructor() {
        super("POST");
    }

    prepareUrl = (apiInfoObject) => {
        const url = urls.get('PLATFORM').get('SAVE_ITEM_PROGRESS');
        return appendQueryParameters(url, apiInfoObject.queryParameters);
    }

    successCall = (response) => {
        return response;
    }

    onError = (error) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong while saving progress.',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new SaveItemProgress();
