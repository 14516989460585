import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		scoreValue: {
            ...myTypography.h4,
            fontSize: '32px',
            fontWeight: 600,
            color: skin.get('white')
        },
        scoreBy: {
            backgroundColor: hexToRgbA(skin.get('white'), 0.5)
        },
        scoreTotal: {
            color: hexToRgbA(skin.get('white'), 0.5),
            ...myTypography.button,
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        scoreCompetency: {
            backgroundColor: skin.get('secondaryColor'),
            borderRadius: '7px',
            color: skin.get('white'),
            ...myTypography.caption,
            fontSize: '10px',
            lineHeight: 'normal',
            textTransform: 'uppercase'
        }
	});
}

export default getSkin;