import actionConsts from 'constants/actions';

const setBatchLeaderboard = leaderboardData => ({
    type: actionConsts.get('SET_MSTEAMS_BATCH_LEADERBOARD'),
    leaderboardData
});

const setLeaderboardFetchingFlag = payload => ({
    type: actionConsts.get('SET_MSTEAMS_BATCH_LEADERBOARD_FETCHING_DATA_FLAG'),
    payload
});

export {
    setBatchLeaderboard,
    setLeaderboardFetchingFlag
};
