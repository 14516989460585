import React from 'react';
import { Link } from 'react-router-dom';
import CSSModules from 'react-css-modules';
import styles from './measures.module.sass';
import MeasuresHeading from './measuresHeading';
import MeasuresFeedback from './measuresFeedback';
import CompleteSummary from './completeSummary';
import NextPlan from './nextPlan';
import Footer from 'commonComponents/footer';
import MeasuresLoader from 'commonComponents/loaders/measuresLoader';
import FeedbackQuestions from 'components/feedbackQuestions';
import Thankyou from  'components/feedback/thankyou';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@CSSModules(styles, { allowMultiple: true })
class Measures extends React.Component {

    renderLoader = () => {
        return (
            <MeasuresLoader />
        );
    }

    renderFeedback = () => {
        return ( <div styleName="feedback">
                    {
                        (this.props.itemFeedback.get('isFeedbackCompleted'))
                        ?
                        <Thankyou/>
                        :
                         <FeedbackQuestions {...this.props} />

                    }
                    </div>);
    }

    render() {
        const {
            measures, match, phases, updateActivePhase, activePhaseDetails, setUserState, apiErrors, fetchMeasureData
        } = this.props;

        const apiError = apiErrors.get('fetchMeasuresError') || apiErrors.get('fetchUserMeasuresError') || apiErrors.get('fetchPhaseItemsError') || apiErrors.get('fetchUserPhaseItemsError');

        if(!apiError){
            if (
                !activePhaseDetails
                || !activePhaseDetails.isMeasuresListAvailable
                || !activePhaseDetails.isUserMeasuresListAvailable
            ) {
                return this.renderLoader();
            }
        }

        return (
            <div styleName="measures-container">
                <APIErrorBoundary 
					key={match.params.moduleItemId} 
					apiError={apiError}
					retryHandler={()=>fetchMeasureData(match.params.batchId, match.params.phaseId)}
				>
                    <MeasuresHeading
                        match={match}
                        activePhaseDetails={activePhaseDetails}
                    />
                    <CompleteSummary
                        measures={measures}
                        match={match}
                        items={this.props.items}
                        activePhaseDetails={activePhaseDetails}
                    />
                    {/* <MeasuresFeedback
                        activePhaseDetails={activePhaseDetails}
                    /> */}
                    {/* <div styleName="measures-feedback">
                        {this.renderFeedback()}
                    </div>

                    <div styleName="below-feedback"/> */}

                    <NextPlan
                        match={match}
                        phases={phases}
                        updateActivePhase={updateActivePhase}
                        // setPhaseWorkflowStatus={setPhaseWorkflowStatus}
                        setUserState={setUserState}
                    />
                    <Footer />
                </APIErrorBoundary>
            </div>
        );
    }
}

export default Measures;