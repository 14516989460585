import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import EMPATHY_MAP_EMOTION_BG from 'images/development/empathyMapBg.png';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		headerTitle: {
			...myTypography.body1,
			fontWeight: 500,
			color: '#ffffff'
		},
		iconText: {
			fontFamily: skin.getIn(['fontFamily', 'bodyFamily']),
			fontSize: '8px',
			color: '#ffffff'
		},
		submitBtnText: {
			...myTypography.button,
			fontWeight: 500,
			color: '#4a57b0'
		},
		emotionContentBg: {
			backgroundImage: `url(${EMPATHY_MAP_EMOTION_BG})`,
			backgroundSize: "cover",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
		},
		feelHeaderText: {
			...myTypography.body1,
			fontWeight: 500,
			color: '#ffffff'
		}
	});
}

export default getSkin;