import actionConsts from 'constants/actions';

const setUserOrganization = (userOrganization) => {
	return {
		type: actionConsts.get('SET_USER_ORGANIZATION_DATA'),
		userOrganization
	}
}

const setUserOrganizationLoading = (flag) => {
	return {
		type: actionConsts.get('SET_USER_ORGANIZATION_LOADING'),
		flag
	}
}

const setOrganizationLevelUserStats = (userStats) => {
	return {
		type: actionConsts.get('SET_ORGANIZATION_LEVEL_USER_STATS'),
		userStats
	}
}

const setOrganizationLevelUserStatsLoading = (flag) => {
	return {
		type: actionConsts.get('SET_ORGANIZATION_LEVEL_USER_STATS_LOADING'),
		flag
	}
}

const setJourneyWiseTimeSpent = (timeSpentData) => {
	return {
		type: actionConsts.get('SET_JOURNEY_WISE_TIME_SPENT'),
		timeSpentData
	}
}

const setJourneyWiseTimeSpentLoading = (flag) => {
	return {
		type: actionConsts.get('SET_JOURNEY_WISE_TIME_SPENT_LOADING'),
		flag
	}
}

const setUserOrganizationError = (userOrganizationError) => {
	return {
		type: actionConsts.get('SET_USER_ORGANIZATION_ERROR'),
		userOrganizationError
	}
}

export {
	setUserOrganizationLoading,
	setUserOrganization,
	setOrganizationLevelUserStatsLoading,
	setOrganizationLevelUserStats,
	setJourneyWiseTimeSpentLoading,
	setJourneyWiseTimeSpent,
	setUserOrganizationError
};
