import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
    loading: true,
	status: null
});

const batchFeedbackStatus = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.get('SET_BATCH_FEEDBACK_STATUS'):
			return state.set(
				'status',
				Immutable.fromJS(action.status)
			).set('loading', false);
		case actionConstants.get('UNSET_BATCH_FEEDBACK_STATUS'):
			return initialState;
		default:
			return state;
	}
};

export default batchFeedbackStatus;