/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: updateRoute.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * update route helps to update the route of the application
 *
 **/

//import { history } from 'react-router-dom';
import { validateRoute, setInvalidRoute, scrollToTop } from './validateRoute';
import routes from 'utils/webAppRoutes/routeConstants'; 
import {getUrlParams, addParametersToUrl} from 'utils/urls';
/**
 * [update route of the application]
 * @param  {String} route [new route of the application]
 */
export const sendUserToInvalidPage = () => {
	setInvalidRoute();
	//history.push('/invalid')
}

const formatRouteParamsValue = (key, value) => {
	switch (key) {
		case 'PATH':
			return value.replace(/\/+$/, '');
		default:
			return value;
	}
}

const prepareRoute = (routeObj) => {
	let route = routeObj.route;
	let params = routeObj.params;

	if (params) {
		for (let i = 0; i < params.length; i++) {
			for (var key in params[i]) {
				if (params[i].hasOwnProperty(key)) {
					params[i][key] = formatRouteParamsValue(key, params[i][key]);
					route = route.replace(key, params[i][key]);
				}
			}
		}

	}
	return route;
}

const updateRoute = (routeObj) => {
	const route = prepareRoute(routeObj);
	scrollToTop();
	window.appHistory.push({
		pathname: route,
		search: window.location.search
	});
}

export const goBackOnePage = () => {
	window.appHistory.goBack()
}

export const goToDashboard = () => {
	const route = routes.get('HOME')
    updateRoute({ route });
}

export const handleHashRoute = () => {
	const hash = window.location.hash;
	if(hash != ""){
		const urlWithoutHash = window.location.href.split('#')[0]; // getting the non-hash part
		const urlParams = getUrlParams(urlWithoutHash);
		const hashRoute = hash.split("#")[1];
		if(hashRoute){
			let finalUrl = `${window.location.origin}${hashRoute}`;
			finalUrl = addParametersToUrl(finalUrl, urlParams);
			window.location.href = finalUrl;
		}
	}
}

export default updateRoute;
