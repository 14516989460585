import React from 'react';
import CSSModules from 'react-css-modules';
import ReactDOM from 'react-dom';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './maq.module.sass';
import getSkin from './skin.js';
import Immutable from 'immutable';
import Checkmark from 'svgComponents/checkmark';

@applySkin
@CSSModules(styles, { allowMultiple: true })

class MAQ extends React.Component {

	componentWillReceiveProps(nextProps) {
		let { currentQuestion } = nextProps;
		const domNode = ReactDOM.findDOMNode(this[currentQuestion])
		if (domNode) {
			let ele = document.getElementById('scrollable-questions-cnt')
			// ele.scrollTo(0, domNode.offsetTop - 150)
			ele.scrollTop = domNode.offsetTop - 150;
		}
	}

	setAnswer = (questionId, optionId) => {
		let answers = this.props.questionAnswers.toJS();
		let alreadyExists = answers.indexOf(optionId.toString())
		if (alreadyExists === -1) {
			alreadyExists = answers.indexOf(optionId)
		}
		if (alreadyExists > -1) {
			answers.splice(alreadyExists, 1)
		} else {
			answers.push(optionId);
		}
		this.props.setQuizQuestionAnswer(questionId, answers)
	}

	showAnswerOptions = () => {
		let optionsContent = [];
		let styles = getSkin(this.props.skinGuide);
		let { question } = this.props;
		let { questionAnswers } = this.props;
		let options = question.get('answers');

		if (options.size > 0) {
			optionsContent = options.map((option) => {
				let isOptionSelected = false;
				if (questionAnswers.indexOf(option.get('id').toString()) > -1 || questionAnswers.indexOf(option.get('id')) > -1) {
					isOptionSelected = true;
				}
				return (
					<div key={`question_${question.get('id')}_option_${option.get('id')}`} styleName="option-cnt">
						<div
							onClick={() => this.setAnswer(question.get('id'), option.get('id'))}
							className={isOptionSelected ? css(styles.selectedOptionLabelText) : css(styles.optionLabelText)}
							styleName={isOptionSelected ? "selected-option-text" : "option-text"}
						>
							<div className={isOptionSelected ? {} : css(styles.checkmark)} styleName="checkmark">
								{isOptionSelected && <Checkmark viewBoxParam={2} />}
							</div>
							{
								option.get('text') ?
									<div styleName="text">{option.get('text')}</div>
									:
									<div styleName="text" dangerouslySetInnerHTML={{ __html: option.get('html') }}></div>
							}

						</div>
					</div>
				)
			});
		}
		return optionsContent;
	}


	render() {
		let styles = getSkin(this.props.skinGuide);
		let { question } = this.props;

		return (
			<div ref={re => { this[`question_${question.get('id')}`] = re }}>
				<div className={css(styles.questionText)} dangerouslySetInnerHTML={{ __html: question.get('actual_question_text') }}></div>
				<div styleName="answer-options-cnt">
					{this.showAnswerOptions()}
				</div>
			</div>
		)
	}
}

export default MAQ;