import React, { Component } from 'react';
import styles from './digitalToolGuide.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import HelpIcon from 'svgComponents/helpIcon'
import CloseCircle from 'svgComponents/closeCircle'
import getSkin from './skin.js';
import { css } from 'aphrodite';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })

class DigitalToolGuide extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showFullScreenImage: false,
			imgLink: ''
		}
	}

	parentClicked = (event) => {
		if (event.target.tagName === "IMG") {
			this.setState({
				showFullScreenImage: true,
				imgLink: event.target.src
			})
		}
	}

	hideImage = () => {
		this.setState({
			showFullScreenImage: false,
			imgLink: ''
		})
	}

	render() {
		const { guideData, closeGuide } = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="digital-tool-guide-cnt">
				<div styleName="guide-content-cnt">
					<div styleName="guide-content-header">
						<div styleName="header-title">
							<div styleName="icon-cnt help-icon">
								<HelpIcon fillColor={'#ffffff'} />
							</div>
							<div style={{ color: 'white' }}>Guide</div>
						</div>
						<div styleName="icon-cnt" onClick={closeGuide}>
							<CloseCircle circleFill="white" circleFillOpacity={1} strokeColor="#4a53ae" strokeWidth={3} />
						</div>
					</div>
					<div styleName="guide-example-cnt">
						<div className={css(myStyles.guideHeadersText)} >{this.props.labels.how_to_fill || "How to Fill"}</div>
						<div
							styleName="guide-content"
							dangerouslySetInnerHTML={{ __html: guideData.howToFill }}
							onClick={this.parentClicked}
						>
						</div>
						{
							guideData.example !== "" ?
								<div styleName="example-cnt">
									<div key="header" className={css(myStyles.guideHeadersText)}>{this.props.labels.examples || "Examples"}</div>
									<div
										key="content"
										styleName="guide-content"
										dangerouslySetInnerHTML={{ __html: guideData.example }}
										onClick={this.parentClicked}
									>
									</div>
								</div>
								: null
						}
					</div>
				</div>
				<div styleName="guide-overlay" onClick={closeGuide}></div>
				{
					this.state.showFullScreenImage &&
					<div
						styleName="fullscreen-image-cnt"
						onClick={this.hideImage}
					>
						<div styleName="image-cnt">
							<img onClick={(event) => { event.stopPropagation() }} src={this.state.imgLink} />
						</div>
					</div>
				}
			</div>
		);
	}
}

export default DigitalToolGuide;