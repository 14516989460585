/**
* @author: sourabh p
* @since: Tue Nov 27 2018 15:15:46 GMT+0530 (IST)
* @file: BatchValidator.js
*
* @copyright: KNOLSKAPE Solutions Pvt Ltd
**/

import AppValidator from 'utils/validators/AppValidator';
import { resolvePromise, rejectPromise } from 'utils/promise.js';
import { filterObjectFromArray, getIndexOfValueInList } from 'utils/objectUtils';
import enumConstants from 'constants/enums';

class AssessmentItemFeedbackValidator extends AppValidator {

    constructor() {
        super();
        this._showError = true;
    }

    isItemAvailable = (itemId) => {
        const itemsList = this.getState().get('items').get('itemsList');


        // if (itemsList.size > 0 && getIndexOfValueInList(itemsList, 'id', +itemId) === -1) {
        //     return rejectPromise({
        //         message: 'Item not available'
        //     });
        // }

        return resolvePromise();
    }

    isItemCompleted = (itemId) => {
        const itemsList = this.getState().get('items').get('userItemsList');

        const itemDetails = filterObjectFromArray(itemsList, 'id', itemId);
        if (itemDetails.get('workflowStatus') !== enumConstants.get('COMPLETED')) {
            return rejectPromise({
                message: 'Item not completed'
            });
        }
        return resolvePromise();
    }

    postValidationLogic = (validationInfoObject) => {
        const itemId = +validationInfoObject.ids.itemId;
        return this.isItemAvailable(itemId)
            .then(() => this.isItemCompleted(itemId))
    }

}

export default new AssessmentItemFeedbackValidator;