const dataGen = (sections, radars, scale) => {
	const data =
		{
			data: Array(sections).fill({}).map((element, index) => {
				return {
					id: index+1,
					userValue: Math.floor(Math.random() * Math.floor(scale)) + 4,
					groupValue: Math.floor(Math.random() * Math.floor(scale)) + 4,
					itemName: "Communication " + (index+1)
				};
			})
		};
	return data;
}

export default dataGen;