import React from 'react';
import { PropTypes } from 'prop-types';

import { Group } from '@vx/group';
import { Bar, Line } from '@vx/shape';
import { AxisRight, AxisTop } from '@vx/axis';

class GradientGrid extends React.Component {

    render() {
        const { scale, opacityScale, xMax, yMax, numTicks, offset, hasHorizontalGrids, tickValues } = this.props;
        const ticks = tickValues ? tickValues : scale.ticks ? scale.ticks(numTicks) : scale.domain();
        const gridBoxSize = Math.abs(scale(ticks[0]) - scale(ticks[1]));
        const getGrids = () => {
            return ticks.slice(1).map((d, i) => {
                const x = hasHorizontalGrids ? scale(d) : 0;
                const y = hasHorizontalGrids ? 0 : scale(d);
                const width = hasHorizontalGrids ? gridBoxSize * (i+1) :(xMax + offset.left + offset.right);
                const height = hasHorizontalGrids ? (yMax + offset.top + offset.bottom) : gridBoxSize * (i+1);

                if (hasHorizontalGrids) {
                    return (
                        <Line
                            key={`column-line-${i}`}
                            from={{x: x, y: 0}}
                            to={{x: x, y: height}}
                            stroke={'white'}
                            strokeWidth={0.5}
                            strokeDasharray={"1,3"}
                        />
                    );
                }
                else {
                    return (
                        <Line
                            key={`row-line-${i}`}
                            from={{x: 0, y: y}}
                            to={{x: width, y: y}}
                            stroke={'white'}
                            strokeWidth={0.5}
                            strokeDasharray={"1,3"}
                        />
                    );
                }
            })
        };

        const getAxis = () => {
            if(hasHorizontalGrids) {
                return (
                    <AxisTop
                        hideTicks={true}
                        scale={scale}
                        top={0}
                        // left={xMax + offset.left + offset.right}
                        stroke={'white'}
                        strokeWidth={0.5}
                        strokeDasharray={"1,3"}
                        numTicks={0}
                    />
                );
            }
            return (
                <AxisRight
                    hideTicks={true}
                    scale={scale}
                    top={0}
                    left={xMax + offset.left + offset.right}
                    stroke={'white'}
                    strokeWidth={0.5}
                    strokeDasharray={"1,3"}
                    numTicks={0}
                    tickFormat={(value, index) => ''}
                />
            );
        }

        return (
            <Group top={0} left={-offset.left}>
                { getAxis() }
                { getGrids() }
                <Group className={'grid-box'} top={0} left={0}>
                {
                    ticks.slice(1).map((d, i) => {
                    
                        const y = hasHorizontalGrids ? 0 : scale(d);
                        const width = hasHorizontalGrids ? gridBoxSize * (i+1) :(xMax + offset.left + offset.right);
                        const height = hasHorizontalGrids ? (yMax + offset.top + offset.bottom) : gridBoxSize * (i+1);

                        return (
                            <Bar
                                key={`row-box-${d}-${i}`}
                                x={0}
                                y={y}
                                width={width}
                                height={height}
                                // fill={`url(#grid-box-gradient${ticks.length-1-i})`}
                                fill={"rgba(213, 217, 231, 0.05"}
                            />
                        );
                    })
                }
                </Group>
            </Group>
        );
    }
}

GradientGrid.propTypes = {
    xMax: PropTypes.number,
    yMax: PropTypes.number,
    numTicks: PropTypes.number,
    opacityScale: PropTypes.func,
    scale: PropTypes.func,
    offset: PropTypes.object,
    hasHorizontalGrids: PropTypes.bool
};

GradientGrid.defaultProps = {
    numTicks: 5,
    offset: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0
    },
    hasHorizontalGrids: false
};

export default GradientGrid;
