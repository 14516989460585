const getUrlParams = url => `${url}?`.split('?')[1]
  .split('&').reduce((params, pair) =>
    ((key, val) => key ? {...params, [key]: val} : params)
    (...`${pair}=`.split('=').map(decodeURIComponent)), {});


const isLaunchedFromExternalIntegration = () => {
    const queryParams = getUrlParams(window.location.href);    
    if(queryParams['isExternalIntegration'] && queryParams['isExternalIntegration'].includes('true')){
        return true;
    }
    return false;
}

export {
    isLaunchedFromExternalIntegration
}