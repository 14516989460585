import React from 'react';
import PropTypes from 'prop-types';
import { scaleOrdinal, scaleLinear } from '@vx/scale';
import { extent, max } from 'd3-array';
import { AreaClosed, Line, LinePath, Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisLeft, AxisRight, AxisBottom } from '@vx/axis';
import { LinearGradient } from '@vx/gradient';
import * as Curve from '@vx/curve';
import GradientGrid from '../gradientGrid';
import { Spring } from 'react-spring'
import { TimingAnimation, Easing } from 'react-spring/dist/addons';
import { getGradientBounds, getFlexDirectionForGraphLegend } from 'utils/analytics/utilFunctions';
import { Text } from '@vx/text';
import { GlyphDot } from '@vx/glyph';
import GraphLegendComponent from '../../analytics/graphLegendComponent';
import { localPoint } from "@vx/event";
import { withTooltip, Tooltip } from "@vx/tooltip";
import CSSModules from 'react-css-modules';
import styles from './inverselyProportional.module.sass';
import lineChartResponsive from 'utils/analytics/responsiveness/lineChart';
import lineChartTransform from 'utils/analytics/transformation/inverselyProportionalChart';
import InfoComponent from 'commonComponents/analytics/infoComponent';
@CSSModules(styles, { allowMultiple: true })
class InverselyProportional extends React.Component {

    height = 'height' in this.props ? this.props.height : 500;
    margin = { top: 0, bottom: 60, left: 60, right: 100 };

    getId = d => d.id;
    getUserData = d => d.userValue;
    getUserRank = d => d.userRank;
    getGroupData = d => d.groupValue;

    constructor(props) {
        super(props);
        this.handleTooltip = this.handleTooltip.bind(this);

        if (!props.showRank) {
            this.margin.right = 30;
        }
    }

    getData = () => {
        let data = this.props.graphData ? this.props.graphData : this.props.data;
        let metadata, strings;
        if (this.props.hasAverageLines || this.props.graphData) {
            metadata = data.metadata;
            data = data.data;
            strings = this.props.strings;
        }
        const responsivePoints = lineChartResponsive(this.getWidth()).points;
        if (data.length > responsivePoints) {
            // data = lineChartTransform(data, metadata, responsivePoints);
        }
        return {
            data: data,
            metadata: metadata,
            strings: strings
        };
    };

    getOffset = () => {
        const leftOffset = this.props.barStyling.leftOffset ? this.props.barStyling.leftOffset : 0;
        const rightOffset = this.props.barStyling.rightOffset ? this.props.barStyling.rightOffset : 0;
        return {
            leftOffset: leftOffset,
            rightOffset: rightOffset
        }
    }

    handleTooltip = (event, data, xScale, yScale) => {
        const { showTooltip } = this.props;
        const { x, y } = localPoint(event.target.ownerSVGElement, event);
        const { leftOffset, rightOffset } = this.getOffset();
        const x0 = Math.round(xScale.invert(x-this.margin.left)); // Subtracts left margin

        const d = this.getData().data;
        const yMaxValue = this.getData().metadata ? this.getData().metadata.maxScore : (max(d, this.getGroupData) > max(d, this.getUserData)) ? max(d, this.getGroupData) : max(d, this.getUserData);
        let barData;
        data.map((val, i) => { if (this.getId(val) == x0) barData = val; });

        const tooltipLeft = xScale(x0);
        const tooltipTop = yScale(Math.ceil((yMaxValue+1)/20)*20);
        const tooltipData = {
            ...barData,
        };
        if(barData) {
            showTooltip({
                tooltipLeft: tooltipLeft,
                tooltipTop: tooltipTop,
                tooltipData: tooltipData
            });
        }
    }

    getTooltipHeader = (data) => {
        let header = "";
        if(!data.ids){
            header = data.id;
        }
        else if(data.ids && data.ids.length === 1){
            header = data.id;
        }
        else if(data.ids && data.ids.length > 1){
            const min = Math.min(...data.ids);
            const max = Math.max(...data.ids);
            header = `${min}-${max}`;
        }
        return header;
    }

    drawLine = (from, to, text, style, isAverageLine, areOverlapping=false) => {
		const strokeDasharray = isAverageLine ? "" : "4,2";
		const verticalAnchor = areOverlapping ? ( isAverageLine ? "start" : "end" ) : "middle";
		return (
			<Group>
				<Line
					from={from}
					to={to}
					strokeDasharray={strokeDasharray}
					stroke={style.lineColor}
					strokeWidth={2}
				/>
				<Text
					{...to}
					textAnchor="start"
					verticalAnchor={verticalAnchor}
					fontSize={12}
					fontFamily={style.fontFamily}
					fill={style.textColor}
				>
				{text}
				</Text>
			</Group>
		);
	}

    getWidth = () => {
        const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
        let width = calcWidth < 300 ? 300: calcWidth;
        if (this.props.legendPosition == 'left' || this.props.legendPosition == 'right') {
            width = width * 0.75;
        }
        return width;
    }

    getXMax = () => {
        const { leftOffset, rightOffset } = this.getOffset();
		return (this.getWidth() - this.margin.left - this.margin.right - leftOffset - rightOffset);
    };

    getYMax = () => {
        let height = this.height;
        if (this.props.legendPosition == 'top' || this.props.legendPosition == 'bottom')
            height = this.height * 0.95;
        return height - this.margin.top - this.margin.bottom;
    };

    getXScale = () => {
        const data = this.getData().data;
        return scaleLinear({
            range: [0, this.getXMax()],
            domain: extent(data, this.getId)
        })
    };

    getYScale = () => {
        const data = this.getData().data;
        const yMaxValue = this.getData().metadata ? this.getData().metadata.maxScore : (max(data, this.getGroupData) > max(data, this.getUserData)) ? max(data, this.getGroupData) : max(data, this.getUserData);
        return scaleLinear({
            range: [this.getYMax(), 0],
            domain: [0, Math.ceil((yMaxValue+1)/20)*20],
        });
    };

    getInverseScale = () => {
        const data = this.getData().data;
        return scaleLinear({
            range: [0, this.getYMax()],
            domain: [1, max(data, this.getUserRank)]
        });
    };

    renderGraph = () => {
        const { hideTooltip, barStyling, hasCurve, showRank } = this.props;
        const data = this.getData().data;

        const xMax = this.getXMax();
        const yMax = this.getYMax();

        const xScale = this.getXScale();
        const yScale = this.getYScale();
        const inverseScale = this.getInverseScale();

        const userId = "user-gradient" + barStyling.componentName;
		const groupId = "group-avg-gradient" + barStyling.componentName;

        return (
            <Group className="graph-container">
                <AreaClosed
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    x={this.getId}
                    y={this.getUserData}
                    fill={`url(#${userId})`}
                    stroke="none"
                    curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
                />
                <LinePath
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    x={this.getId}
                    y={this.getGroupData}
                    strokeWidth={2}
                    curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
                    stroke={barStyling.groupStroke}
                    strokeDasharray={[6,4]}
                />
                <LinePath
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    x={this.getId}
                    y={this.getUserData}
                    strokeWidth={3}
                    curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
                    stroke={barStyling.userStroke}
                    glyph={(d,i) => {
                        return (
                            <g key={`line-point-${i}`}>
                                <GlyphDot
                                    cx={xScale(this.getId(d))}
                                    cy={yScale(this.getUserData(d))}
                                    r={2}
                                    stroke={barStyling.userStroke}
                                    strokeWidth={4}
                                />
                            </g>
                        );
                    }}
                />
                {showRank && <LinePath
                    data={data}
                    xScale={xScale}
                    yScale={inverseScale}
                    x={this.getId}
                    y={this.getUserRank}
                    strokeWidth={3}
                    curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
                    stroke={barStyling.rankStroke}
                    glyph={(d,i) => {
                        return (
                            <g key={`line-point-${i}`}>
                                <GlyphDot
                                    cx={xScale(this.getId(d))}
                                    cy={inverseScale(this.getUserRank(d))}
                                    r={2}
                                    stroke={barStyling.rankStroke}
                                    strokeWidth={4}
                                />
                            </g>
                        );
                    }}
                />}
                <Bar
                    x={0}
                    y={0}
                    width={xMax}
                    height={yMax}
                    data={data}
                    fill="transparent"
                    onMouseMove={data => event =>
                        this.handleTooltip(
                            event,
                            data,
                            xScale,
                            yScale
                    )}
                    onMouseLeave={() => event => hideTooltip()}
                    onMouseOut={() => event => hideTooltip()}
                />
            </Group>
        );
    }

    renderTooltip = () => {
        const { tooltipOpen, tooltipLeft, tooltipData, tooltipTop, barStyling, showRank, graphFormat } = this.props;
        if (!tooltipOpen)
            return

        const { strings } = this.getData();
        const tooltipStyle = {
            fontFamily: 'Open Sans',
            fontSize: '12px',
            color: '#ffffff',
            backgroundColor: '#363e5e',
            width: '150px',
            height: showRank ? '100px' : '75px',
            paddingBottom: '5px'
        };
        const { leftOffset, rightOffset } = this.getOffset();
        const tooltipUserLegendFillValue = `linear-gradient(256deg, ${barStyling.userStroke}, ${barStyling.userStroke})`;
        const tooltipGroupLegendFillValue = `linear-gradient(256deg, ${barStyling.groupStroke}, ${barStyling.groupStroke})`;
        const tooltipRankLegendFillValue = `linear-gradient(256deg, ${barStyling.fromRank}, ${barStyling.toRank})`;
        return (
            <Tooltip
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft + leftOffset - this.margin.left}
                style={tooltipStyle}
            >
                <div styleName="tooltip-header">{graphFormat.valueName} {this.getTooltipHeader(tooltipData)}</div>
                <div styleName="tooltip-content">
                    <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipUserLegendFillValue }}></div>{strings.yourScoreTooltip.replace('PLACEHOLDER', `${Math.round(this.getUserData(tooltipData))}`)}
                    </div>
                    <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipGroupLegendFillValue }}></div>{strings.groupAverageTooltip.replace('PLACEHOLDER', `${Math.round(this.getGroupData(tooltipData))}`)}
                    </div>
                    {showRank && <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipRankLegendFillValue }}></div>{strings.yourRankTooltip.replace('PLACEHOLDER', `${Math.round(this.getUserRank(tooltipData))}`)}
                    </div>}
                </div>
            </Tooltip>
        )
    }

    renderGraphComponent = () => {
        const {
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
            barStyling,
            graphFormat,
            showRank,
            legendPosition
        } = this.props;

        const tickLabelStyling = {
            fill: "#ff0666",
            textAnchor: "end",
            fontSize: 12,
            fontFamily: 'Open Sans'
        };

        const labelStyling = {
            fontFamily: 'Open Sans',
            fontSize: 15,
            fill: '#979eb7',
            fillOpacity: 0.7,
            textAnchor: 'middle'
        };

        const rightTickLabelStyling = {
            fill: "#2de791",
            textAnchor: "start",
            fontSize: 12,
            fontFamily: 'Open Sans'
        };

        const bottomTickLabelStyling = {
            fill: "#979eb7",
            textAnchor: "middle",
            fontSize: 12,
            fontFamily: 'Open Sans'
        }

        const legendFontSize = legendPosition == 'left' || legendPosition == 'right' ? '12px' : '16px';
		const legendLabelDirection = legendPosition == 'left' || legendPosition == 'right' ? 'column' : 'row';
		const legendItemMargin = legendPosition == 'left' || legendPosition == 'right' ? "0 0 9px 0" : "0 24px 0 0";
        const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: legendFontSize,
			fontFamily: 'Open Sans',
			color: '#979eb7',
            direction: legendLabelDirection,
            itemDirection: "row",
            labelMargin: "0",
            shapeMargin: "0 8px 0 0",
            itemMargin: legendItemMargin,
            shapeWidth: 12,
            shapeHeight: 12
        };

        let { hasAverageLines } = this.props;
        let areOverlapping = false;

        let userAvg, groupAvg;
        if (hasAverageLines || this.props.graphData) {
            const data = this.props.graphData ? this.props.graphData : this.props.data;
            userAvg = data.userAverage;
            groupAvg = data.groupAverage;
            areOverlapping = Math.abs(userAvg - groupAvg) <= 2;
        }

        const { data, strings } = this.getData();

        const width = this.getWidth();
        const height = this.height;
        const margin = this.margin;
        
        const xMax = this.getXMax();
        const yMax = this.getYMax();
        const { leftOffset, rightOffset } = this.getOffset();
        
        const xScale = this.getXScale();
        const yScale = this.getYScale();
        let inverseScale;
        let legendLabels = [strings.yourScoreLabel, strings.groupAverageLabel];
        if (showRank) {
            inverseScale = this.getInverseScale();
            legendLabels.push(strings.yourRankLabel);
        }

        const legendSize = scaleOrdinal({
			domain: legendLabels,
			range: [12]
		});

		const legendShape = scaleOrdinal({
			domain: legendLabels,
			range: [
				props => {
					const { width, height, size, fill } = props;
					return (<GlyphDot
						r={size/2}
						top={width / 2}
						left={height / 2}
						fill={fill}
					/>)
				},
				props => {
					const { width, height, size } = props;
					return (<Line
						from={{x: 0, y: width/2}}
						to={{x: width, y: width/2}}
						strokeDasharray={"4,2"}
						strokeWidth={2}
						stroke={barStyling.groupStroke}
					/>)
                },
                props => {
					const { width, height, size, fill } = props;
					return (
                        <g>
                            <Line
                                from={{x: 0, y: width/2}}
                                to={{x: width, y: width/2}}
                                strokeWidth={2}
                                stroke={barStyling.rankStroke}
                            />
                            <GlyphDot
                                r={size/4}
                                top={width / 2}
                                left={height / 2}
                                fill={fill}
                            />
                        </g>)
				},
			],
		});

        // const fromUserTime = Array(data.length).fill(0);
        // const fromGroupAvg = Array(data.length).fill(0);
        const toUserScore = data.map((val, i) => ( this.getUserData(val) ));
        const toGroupAvg = data.map((val, i) => ( this.getGroupData(val) ));

        const ShowAverageLines = () => {
            if (hasAverageLines) {
                return (<Group>
                    {
                        this.drawLine(
                            {x: xScale(1)/2, y: yScale(groupAvg)},
                            {x: xMax - xScale(1)/2, y: yScale(groupAvg)},
                            'Group avg.',
                            {textColor: barStyling.fromGroup, lineColor: barStyling.fromGroup, fontFamily: "Open Sans"},
                            true, areOverlapping
                        )
                    }
                    {
                        this.drawLine(
                            {x: xScale(1)/2, y: yScale(userAvg)},
                            {x: xMax - xScale(1)/2, y: yScale(userAvg)},
                            'Your avg.',
                            {textColor: barStyling.fromUser, lineColor: barStyling.fromUser, fontFamily: "Open Sans"},
                            false, areOverlapping
                        )
                    }
                </Group>)
            }
        }

        const userId = "user-gradient" + barStyling.componentName;
		const groupId = "group-avg-gradient" + barStyling.componentName;

		const userGradientBounds = getGradientBounds(barStyling.userRotation ? barStyling.userRotation : 0);
		const groupGradientBounds = getGradientBounds(barStyling.groupRotation ? barStyling.groupRotation : 0);

        const flexDirection = getFlexDirectionForGraphLegend(legendPosition);
        return(
            <div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: flexDirection}}>
                <svg width={width} height={height}>
                    <LinearGradient
                        from={barStyling.fromUser ? barStyling.fromUser : 'green'}
                        to={barStyling.toUser}
                        {...userGradientBounds}
                        id={userId}
                    />
                    <LinearGradient
                        from={barStyling.fromGroup}
                        to={barStyling.toGroup}
                        {...groupGradientBounds}
                        id={groupId}
                    />
                    <LinearGradient
                        from={barStyling.fromUserLegend ? barStyling.fromUserLegend : 'green'}
                        to={barStyling.toUserLegend}
                        {...userGradientBounds}
                        id={`legend-0-${barStyling.componentName}`}
                    />
                    <LinearGradient
                        from={barStyling.fromGroupLegend ? barStyling.fromGroupLegend : 'green'}
                        to={barStyling.toGroupLegend}
                        {...groupGradientBounds}
                        id={`legend-1-${barStyling.componentName}`}
                    />
                    {showRank && <LinearGradient
                        from={barStyling.fromRank ? barStyling.fromRank : 'green'}
                        to={barStyling.toRank}
                        {...groupGradientBounds}
                        id={`legend-2-${barStyling.componentName}`}
                    />}

                    <Group top={margin.top} left={margin.left + leftOffset}>
                        <GradientGrid scale={yScale} xMax={xMax} yMax={yMax} offset={{left: leftOffset, right: rightOffset}} />
                        <AxisLeft
                            scale={yScale}
                            top={0}
                            left={-leftOffset}
                            label={graphFormat.yLabel}
                            stroke={'#1b1a1e'}
                            tickTextFill={'#ff0666'}
                            hideTicks={true}
                            hideZero={true}
                            numTicks={max(data, this.getUserRank) > 5 ? 5 : max(data, this.getUserRank) - 1}
                            tickLabelProps={() => (tickLabelStyling)}
                            labelProps={labelStyling}
                            stroke={'white'}
                            strokeWidth={0.5}
                            strokeDasharray={"1,3"}
                        />
                        {showRank && <AxisRight
                            hideAxisLine={true}
                            scale={inverseScale}
                            top={0}
                            left={xMax + rightOffset}
                            label={graphFormat.yLabelInverse}
                            stroke={'#2de791'}
                            tickTextFill={'#1b1a1e'}
                            hideTicks={true}
                            numTicks={max(data, this.getUserRank) > 5 ? 5 : max(data, this.getUserRank) - 1}
                            tickFormat={(value, index) => (`${value}`)}
                            tickLabelProps={() => (rightTickLabelStyling)}
                            labelProps={labelStyling}
                            stroke={'white'}
                            strokeWidth={0.5}
                            strokeDasharray={"1,3"}
                        />}
                        <AxisBottom
                            scale={xScale}
                            top={yMax}
                            label={graphFormat.xLabel}
                            stroke={'#1b1a1e'}
                            hideAxisLine={true}
                            hideTicks={true}
                            hideZero={true}
                            numTicks={data.length - 1 > 10 ? 10 : data.length - 1}
                            // tickValues={ data.map(this.getId) }
                            tickFormat={(value, index) => (`${graphFormat.valuePrefix}${value}`)}
                            tickLabelProps={() => (bottomTickLabelStyling)}
                            labelProps={labelStyling}
                            stroke={'white'}
                            strokeWidth={0.5}
                            strokeDasharray={"1,3"}
                        />

                        { ShowAverageLines() }

                        {/* <g> */}
                            {/* <Spring 
                                // from={{ userScore: fromUserScore, groupAvg: fromGroupAvg }}
                                to={{userScore: toUserScore, groupAvg: toGroupAvg}}
                                impl={TimingAnimation}
                                config={{easing: Easing.linear }}
                                data={data}
                                children={Graph} /> */}
                            {/* <Graph data={data}/>
                        </g> */}
                        {this.renderGraph()}
                        {tooltipOpen && (
                            <Line
                                from={{x: tooltipLeft, y: yMax}}
                                to={{x: tooltipLeft, y: (tooltipTop)}}
                                stroke={'white'}
                                strokeWidth={1}
                            />
                        )}
                    </Group>
                </svg>
                <GraphLegendComponent
                    style={legendStyling}
                    shape={legendShape}
                    size={legendSize}
                    componentName={barStyling.componentName}
                />
                {this.renderTooltip()}
            </div>
        );
    }

    render() {
        const data = this.getData().data;
        if (data.length <= 1 || !this.props.isUserDataAvailable) {
            return <InfoComponent 
                        {...this.props} 
                        infoMessage="This graph does not have data"
                    />
        }

        return this.renderGraphComponent();
    }
}

InverselyProportional.propTypes = {
    size: PropTypes.object,
    graphData: PropTypes.object.isRequired,
    hasCurve: PropTypes.bool,
    hasAverageLines: PropTypes.bool,
    barStyling: PropTypes.object,
    graphFormat: PropTypes.object,
    showRank: PropTypes.bool,
    legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom'])
};

InverselyProportional.defaultProps = {
    size: {
        width: 700,
        height: 500
    },
    hasCurve: false,
    hasAverageLines: false,
    barStyling: {
        leftOffset: 0,
        rightOffset: 50
    },
    showRank: true,
    legendPosition: 'bottom'
};

export default withTooltip(InverselyProportional);