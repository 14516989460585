import React, { Component } from 'react';
import styles from './checkmark.module.sass';
import CSSModules from 'react-css-modules';
// import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Checkmark extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		let {fillColor, viewBoxParam} = this.props;
		return (
			<div styleName="tick-mark-component">
				<svg width="100%" height="100%" viewBox={`0 0 9 8`}>
				<path fill={fillColor || "#2C4BB7"} fillRule="evenodd" d="M3.205 6.724L8.62.71a.527.527 0 0 1 .783.706L3.891 7.538a.527.527 0 0 1-.68.088.527.527 0 0 1-.688-.084L.393 5.177a.527.527 0 0 1 .784-.706l2.028 2.253z"/>
				</svg>
			</div>
		);
	}
}



export default Checkmark;
