import actionConsts from 'constants/actions';

const addToast = (toast) => ({
	type: actionConsts.get('ADD_TOAST'),
	toast
})

const updateToastDisplayedStatus = (id, status = false) => ({
	type: actionConsts.get('UPDATE_TOAST_DISPLAYED_STATUS'),
	id,
	status
})

const removeToast = (id = -1) => ({
	type: actionConsts.get('REMOVE_TOAST'),
	id
})

export {
	addToast,
	updateToastDisplayedStatus,
	removeToast
}