import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            boxShadow: '0 0 4px 0 #8892a5'
        },
        text: {
            ...myTypography.button,
            color: skin.get('secondaryColor'),
            ':hover': {
                textDecoration: 'underline'
            }
        },
        seperationLine: {
            borderRight: 'solid 1px rgba(151, 151, 151, 0.1)'
        },
        emptyDiv:{
            backgroundColor:' #f6f7f9'
        },
        disableButton: {
            opacity: 0.6,
            cursor: 'not-allowed',
            textDecoration: 'none'
        },
        tooltipArrow: {
			borderTop: `10px solid ${skin.get('greyColor1')}`,
			borderLeft: `10px solid transparent`,
			borderRight: `10px solid transparent`
		},
		tooltipContent: {
			...myTypography.caption,
			color: skin.get('greyColor5')
		}
    });
}

export default getSkin;