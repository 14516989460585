import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './summaryComponent.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import ScoreCardInfoComponent from './scoreCardInfoComponent';
import NeedleMovementWithInfo from './needleMovementWithInfo';
import OverallGraphs from './overallGraphs';
import { checkIfPresent } from 'utils/utilFunctions';
import { checkIfAssessmentOnlyJourney } from 'utils/analytics/utilFunctions';

@CSSModules(styles, { allowMultiple: true })

class SummaryComponent extends React.Component {

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props)){
            return false;
        }
        else{
            return true;
        }
    }

    renderScoreCardInfo = (showPerformanceFlag) => {
        // if (!isAssessmentOnlyJourney) {
        //     return(
        //         <ScoreCardInfoComponent {...this.props} />
        //     )
        // }

        if(showPerformanceFlag) {
            return(
                <ScoreCardInfoComponent {...this.props} />
            );
        }
    }

    render() {

        // const journeyType = getJourneyType(this.props.phases.get('phasesList'));
        const isAssessmentOnlyJourney = checkIfAssessmentOnlyJourney(this.props.phases.get('phasesList'));
        // const isAssessmentOnlyJourney = journeyType === 'A' ? true : true;

        const showActivityFlag = checkIfPresent(this.props.userState.get('showActivityPage')) ? this.props.userState.get('showActivityPage') : false;
		const showPerformanceFlag = checkIfPresent(this.props.userState.get('showPerformancePage')) ? this.props.userState.get('showPerformancePage') : false;
        // const showActivityFlag = false;
		// const showPerformanceFlag = false;
        
        return(
            <div styleName="container-class">
                <LegendComponent 
                    title={this.props.genericStrings.get('overAllSummaryKey')}
                    {...this.props}/>
                <div styleName="outer-container">
                    <div styleName="outer-content">
                        {/* <NeedleMovementWithInfo {...this.props} /> */}
                        <OverallGraphs
                            {...this.props}
                            showActivityFlag={showActivityFlag}
                            showPerformanceFlag={showPerformanceFlag}
                            isAssessmentOnlyJourney={isAssessmentOnlyJourney}
                        />
                        {!isAssessmentOnlyJourney ? this.renderScoreCardInfo(showPerformanceFlag) : null}
                    </div>
                </div>
            </div>
        )
    }
}
 

export default SummaryComponent;