import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import styles from './quizScorePieChart.module.sass';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import PieChartOnly from 'commonComponents/graphs/pieChartOnly'
import { Legend } from '@vx/legend';
import { scaleOrdinal } from '@vx/scale';
import { GlyphDot } from '@vx/glyph';
import SecondaryButton from 'commonComponents/buttons/secondaryButton';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class QuizScorePieChart extends Component {

	reviewQuiz = () => {
		const route = routes.get('BATCH_QUIZ_REVIEW');
		let params = [
			{ 'BATCH_ID': this.props.params.batchId },
			{ '_PHASEITEMID_': this.props.params.phaseItemId },
			{ 'COURSE_ID': this.props.params.courseId },
			{ '_PHASE_ID_': this.props.params.phaseId },
			{ 'MODULE_ID': this.props.params.moduleId },
			{ 'MODULE_ITEM_ID': this.props.params.moduleItemId },
			{ '_QUIZ_SUBMISSION_ATTEMPT_': this.props.attempt.get('attempt') }
		]
		updateRoute({ route, params });
	}

	showPieChartLegends = () => {

		const { getLabelWithDefaults } = this.props;
		const rightAnswersLabel = getLabelWithDefaults('quiz_chart_right_answers', 'Right Answers');
		const wrongAnswersLabel = getLabelWithDefaults('quiz_chart_wrong_answers', 'Wrong Answers');
		const unansweredQuestionsLabel = getLabelWithDefaults('quiz_chart_unanswered_questions', 'Unanswered');

		const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: '16px',
			fontFamily: 'Open Sans',
			color: '#979eb7'
		};
		const legendSize = scaleOrdinal({
			domain: [rightAnswersLabel, wrongAnswersLabel, unansweredQuestionsLabel],
			range: [16]
		});
		const legendShape = scaleOrdinal({
			domain: [rightAnswersLabel, wrongAnswersLabel, unansweredQuestionsLabel],
			range: [
				props => {
					const { width, height, size, fill } = props;
					return (<GlyphDot
						r={size / 2}
						top={width / 2}
						left={height / 2}
						fill={fill}
					/>)
				}
			],
		});
		return (
			<Legend
				direction="column"
				itemDirection="row"
				style={legendStyling}
				labelMargin="0"
				shapeMargin="0 8px 0 0"
				itemMargin="0 0 10px 0"
				scale={legendSize}
				shapeWidth={16}
				shapeHeight={16}
				fill={({ index }) => `url(#pie-only-grad-${index})`}
				fillOpacity={d => 1 / (d.index + 2)}
				shape={props => {
					return (
						<svg width={props.width} height={props.height}>
							{!React.isValidElement(
								legendShape(props.label.datum),
							) &&
								React.createElement(
									legendShape(props.label.datum),
									{
										...props,
									},
								)}
							{React.isValidElement(
								legendShape(props.label.datum),
							) &&
								React.cloneElement(legendShape(props.label.datum))}
						</svg>
					);
				}}
			/>
		)
	}

	render() {
		let { attempt, quiz, getLabelWithDefaults } = this.props;
		let oneTimeResults = quiz.get('one_time_results');
		let displayReview = true;
		let disableReview = false;
		let disableReivewMsg = ''
		if (quiz.get('hide_results') === 'always') {
			displayReview = false
		}
		if (oneTimeResults && attempt.get('has_seen_results')) {
			disableReview = true
			disableReivewMsg = getLabelWithDefaults('quiz_attempt_only_once_review_disabled_label', 'You can review your responses only once for each attempt of this quiz.');
		}
		if (quiz.get('hide_results') === 'until_after_last_attempt' && attempt.get('attempts_left') !== 0 && attempt.get('attempts_left') !== -1) {
			disableReview = true;
			disableReivewMsg = getLabelWithDefaults(
				'quiz_attempt_only_after_last_review_disabled_label',
				`You can review your responses only after last (${quiz.get('allowed_attempts')}) attempt.`,
				{ attempts_count: quiz.get('allowed_attempts') }
			);
		}

		let dataForPieChart = [
			{ label: "Right", count: attempt.get('correct_answers'), fill: '#6cbc14', gradientTo: '#8fdd3a' },
			{ label: "Wrong/Partially", count: attempt.get('wrong_answers'), fill: '#e92121', gradientTo: '#f15f5f' },
			{ label: "Unanswered", count: attempt.get('unanswered') || 0, fill: '#4c4c4c', gradientTo: '#898989' }
		];

		// console.log(attempt.get('has_seen_results'), disableReview, disableReivewMsg)
		const showPieChart = attempt.get("total_questions") !== 0;

		return (
			[
				<div key="quiz-score-pie-chart" style={{ minWidth: showPieChart ? '250px' : '0' }}>
					{
						showPieChart &&
						<PieChartOnly diameter={171} border={{ width: 10, fill: '#f3f3f3' }} data={dataForPieChart} />
					}
				</div>,
				<div key="quiz-score-pie-chart-legends" styleName="quiz-pie-chart-legends-cnt">
					{showPieChart && this.showPieChartLegends()}

					{
						displayReview &&
						<div
							styleName={`quiz-review-btn ${disableReview ? 'disabled' : ''}`}
							onClick={disableReview ? null : this.reviewQuiz}
							title={disableReivewMsg}
						>
							<SecondaryButton text={getLabelWithDefaults(
								'quiz_review_answers_label',
								'REVIEW ANSWERS'
							)} />
						</div>
					}
				</div>
			]
		)
	}
}

export default QuizScorePieChart;