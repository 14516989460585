import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';

@applySkin
class SidebarList extends Component {
	render() {
		const styles = getSkin(this.props.skinGuide);
		let timelineStyle = styles.collapsed;
		let fillerStyle = styles.collapsed;
		if (this.props.isSidebarOpen) {
			fillerStyle = styles.filler;
			timelineStyle = styles.expanded;
		}
		return (
			<div className={css(timelineStyle)}>
				<div className={css(fillerStyle)} style={{transitionDuration: '.5s', height: this.props.fillerHeight}}></div>
				{this.props.children}	
			</div>
		)
	}
}

export default SidebarList;
