import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScoreComponent from 'commonComponents/scoreComponent';
import {getDefaultFirebaseDatabase} from 'utils/firebase';

import { refreshModuleItem } from 'actions/moduleItems/apiActions';
import { hexToRgbA } from 'utils/styleUtil';
import applyLabel from 'utils/LabelProvider';

@applyLabel
class ModuleItemScoreContainer extends Component {

	initFirebaseAndListenToEvents = () => {
		const { match, refreshModuleItem } = this.props;

		this.state.submissionRef.on('value', function (snapshot) {
			let submission = snapshot.val();
			//console.log('snapshot', submission);
			if (submission && submission.isCompleted) {
				refreshModuleItem(match.params);
			}
		});
	}

	shouldSubscribeToFirebaseDB = () => {
		const moduleItem = this.props.moduleItem.get('moduleItem');

		let subType = moduleItem.get('sub_type') ? moduleItem.get('sub_type').toLowerCase() : null;

		if(subType === 'assignment'){
			const contentDetails = this.props.moduleItem.get('contentDetails');
			const isNLPAssignment = contentDetails.getIn(['al_data', 'nlp']) || false;
	
			if(isNLPAssignment){
				console.log('attach firebase')
				return true;
			}
		}

		if (subType === 'scorm' || subType === 'simulation') {
			return true;
		}

		return false;
	}

	componentDidMount() {
		if (this.shouldSubscribeToFirebaseDB()) {
			this.setFirebaseRef();
		}
	}
	
	setFirebaseRef = async () => {
		const firebaseDbPromise = getDefaultFirebaseDatabase();		
		const firebaseDb = await firebaseDbPromise;
		const userId = this.props.user.get('userID');
		const moduleItem = this.props.moduleItem.get('moduleItem');
		let submission_string = `submissions/user_${userId}_assignment_${moduleItem.get('content_id')}`;

		let submissionRef = firebaseDb.ref(submission_string);

		this.setState({
			submissionRef: submissionRef
		}, () => this.initFirebaseAndListenToEvents());
	}

	componentWillUnmount() {
		if (this.state && this.state.submissionRef) {
			this.state.submissionRef.off('value');
		}
	}

	render() {
		let { moduleItem, maxPoints, quiz, match, defaultScoreComponent } = this.props;
		let quizActiveAttemptData = quiz.get('activeAttempt');
		let quizSubs = quiz.get('quizSubmissions');

		let earnedPoints = null;
		let scoreComponent = null;
		let isItAQuiz = moduleItem.get('moduleItem').get('type_label') === 'Quiz';

		if (maxPoints) {
			scoreComponent = defaultScoreComponent;

			if (moduleItem.get('contentDetails') && moduleItem.get('contentDetails').get('submission')) {
				earnedPoints = moduleItem.get('contentDetails').get('submission').get('score');
				if (earnedPoints || earnedPoints === 0) {
					scoreComponent = <ScoreComponent
						score={earnedPoints}
						scoreColor={this.props.skinGuide.get('skinHex').get('secondaryColor')}
						totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.5)}
						circleStrokeColor={this.props.skinGuide.get('skinHex').get('greyColor2')}
						fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.2)}
						maxPoints={maxPoints}
						match={match}
					/>
				}
			}

			if (isItAQuiz) {
				if (quizActiveAttemptData.size > 0 && quizActiveAttemptData.get('kept_score') !== null) {
					scoreComponent = <ScoreComponent
						score={quizActiveAttemptData.get('kept_score')}
						scoreColor={this.props.skinGuide.get('skinHex').get('secondaryColor')}
						totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.5)}
						circleStrokeColor={this.props.skinGuide.get('skinHex').get('greyColor2')}
						fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.2)}
						maxPoints={maxPoints}
						match={match}
					/>
				}
				if (quizSubs.size > 0 && quizActiveAttemptData.size === 0) {
					if (/survey/.test(moduleItem.getIn(['contentDetails', 'quiz_type']))) {
						scoreComponent = <ScoreComponent
							score={quizSubs.getIn([quizSubs.size - 1, 'kept_score'])}
							scoreColor={this.props.skinGuide.get('skinHex').get('secondaryColor')}
							totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.5)}
							circleStrokeColor={this.props.skinGuide.get('skinHex').get('greyColor2')}
							fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.2)}
							maxPoints={maxPoints}
							match={match}
						/>
					} else {
						scoreComponent = null;
					}
				}
			}
		}
		return scoreComponent;
	}
}

const mapStateToProps = (state) => ({
	user: state.get('user')
})

const mapDispatchToProps = (dispatch) => ({
	refreshModuleItem: (params) => {
		refreshModuleItem(params);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleItemScoreContainer)
