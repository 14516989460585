import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
	availableJourneys: [],
	isAvailableJourneysFetching: false
});

const availableJourneys = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_AVAILABLE_JOURNEYS'):
			return state.set(
				'availableJourneys',
				Immutable.fromJS(action.payload.userBatchesList)
			).set(
				'isAvailableJourneysFetching',
				false
			)
		case actionConstants.get('SET_AVAILABLE_JOURNEYS_LOADING'):
			return state.set(
				'isAvailableJourneysFetching',
				action.flag
			)
		case actionConstants.get('REMOVE_REQUESTED_JOURNEY'):
			return state.set(
				'availableJourneys',
				state.get('availableJourneys').filter(j => j.get('id') !== action.journeyInstanceId)
			)
		default:
			return state;
	}
}

export default availableJourneys;