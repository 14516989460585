import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

import { appendQueryParameters } from 'utils/utilFunctions';
import { pushAttachmentsToList } from 'actions/attachments/actionCreators';

class FetchAttachmentsList extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject) => {
        const url = urls.get('PLATFORM').get('GET_ATTACHMENTS_LIST');
        return appendQueryParameters(url, apiInfoObject.queryParameters);
    }

    successCall = (response) => {
        this.dispatch(pushAttachmentsToList(response));
        return response;
    }

    onError = (response) => {
        console.log("response", response);
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong while loading the file. Please refresh the page.',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new FetchAttachmentsList();
