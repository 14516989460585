import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import environment from 'utils/environment';
import { setUserDetails, setLogoutURL } from 'actions/users/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import { setSentryUserContext } from 'utils/sentry';

class FetchUser extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const variant = urlParams.get('variant') || 'default';
        return `${urls.get('PLATFORM').get('GET_USER')}?variant=${variant}&ts_no_cache=${(new Date()).getTime()}`;
    }

    shouldAjaxCall = () => {
        if (this.getState().get('user').get('isUserDataAvailable')) {
            return false;
        }
        return true;
    }

    successCall = (response) => {
        if (Object.keys(response).length === 0 && response.constructor === Object) {
            response = {
                emailID: environment.REACT_APP_ACCESS_EMAIL,
                accessToken: environment.REACT_APP_ACCESS_TOKEN,
                firstName: 'Bruce',
                lastName: 'Wayne',
                userName: 'Bruce Wayne',
                userId: 1
            };
        }
        this.dispatch(setUserDetails(response));

        //setting sentry user details
        setSentryUserContext(response);

        window.localStorage.setItem('USER_ID', response.userId);

        this.dispatch(setLogoutURL(urls.get('PLATFORM').get('GET_LOGOUT_URL')))
        return response;
    }

    onError = () => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
        sendUserToInvalidPage();
    }

}

export default new FetchUser();
