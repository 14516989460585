/**
 * @author: Amul
 * @since: Wed Sep 04 2019 16:33:37 GMT+0530 (India Standard Time)
 * @file: mixpanelTracking.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';


const initialState = Immutable.fromJS({
	payload: null,
	intervalId: null,
	screenName: null,
	isActive: false,
	startTimestamp: null
});

const mixpanelTracking = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.get('SET_MIXPANEL_TRACKING'):
			return state.set('payload', action.payload).set('screenName', action.screenName).set('isActive', true).set('startTimestamp', action.startTimestamp).set('intervalId', action.intervalId);
		case actionConsts.get('SET_MIXPANEL_TRACKING_ACTIVE_STATUS'):
        	return state.set('isActive', action.isActive).set('intervalId', action.intervalId);
        case actionConsts.get('UNSET_MIXPANEL_TRACKING'):
            return initialState;
		default:
			return state;
	}
};

export default mixpanelTracking;
