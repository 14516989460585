import React, { Component } from 'react';
import styles from './moduleItemOnlyDevelopmentPhaseItem.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import enumConstants from 'constants/enums';
import { filterObjectFromArray } from 'utils/objectUtils';
import ModuleItemCard from 'components/modules/moduleItemCard';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import LockIcon from 'svgComponents/lockIcon';
import CompletedFeedbackIcon from 'svgComponents/completedFeedbackIcon';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ModuleItemOnlyDevelopmentPhaseItem extends Component {

    getUserItem = (userItems, item) => {
        let myUserItem = userItems.find((eachItem) => {
            return (eachItem.get('id') === item.get('id'));
        });
        if (myUserItem) {
            return {
                percentageCompletion: myUserItem.get('percentageCompletion'),
                workflowStatus: myUserItem.get('workflowStatus')
            };
        }
        return {
            percentageCompletion: 0,
            workflowStatus: enumConstants.get('PENDING')
        };
    }

    getItemPhaseStatus = (phaseId) => {
        const userPhase = filterObjectFromArray(
            this.props.phases.get('userPhasesList'), 'id', phaseId
        );
        return userPhase.get('workflowState');
    }

    checkAndSetPhaseAndItemStates = (routeObj) => {

        let { onlyPhaseItem, userPhase, courseID, phaseID, userItems } = this.props;

        this.props.setUserState({
            currentItemId: onlyPhaseItem.get('id'),
            currentPhaseId: onlyPhaseItem.get('phaseId')
        });

        const statusObj = this.getUserItem(userItems, onlyPhaseItem);

        if (statusObj.workflowStatus == enumConstants.get('UNOPENED')) {
            this.props.setItemWorkflowStatus(
                enumConstants.get('STARTED')
            );
        }

        const itemPhaseStatus = this.getItemPhaseStatus(onlyPhaseItem.get('phaseId'));

        if (itemPhaseStatus && itemPhaseStatus == enumConstants.get('NOT_STARTED')) {
            this.props.setPhaseWorkflowStatus(
                onlyPhaseItem.get('batchId'), onlyPhaseItem.get('phaseId'), enumConstants.get('STARTED'), routeObj
            );
        }
        else {
            if (routeObj) {
                updateRoute(routeObj);
            }
        }
    }

    sendSegmentData = (item) => {
        let { batchId, phaseId, phaseItemId, courseId, onlyPhaseItem } = this.props;
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            itemName: item.get('name'),
            itemId: item.get('id'),
            itemType: 'moduleItem',
            itemLocked: onlyPhaseItem.get('isLocked'),
            itemExpired: onlyPhaseItem.get('isExpired'),
            itemCompleted: item.getIn(['completion_requirement', 'completed']),
            batchId,
            phaseId,
            phaseItemId,
            courseId
        }
        window.analytics.track(
            `Phase Item Clicked - Type: ${item.get('type_label')}`,
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    goToItem = (item) => {
        let { batchId, phaseId, phaseItemId, courseId, onlyPhaseItem } = this.props;
        let route = routes.get('BATCH_ITEM_MODULE');

        this.sendSegmentData(item)

        let isItemCompleted = false,
            isItemLocked = false,
            isItemExpired = false;

        if (item.getIn(['completion_requirement', 'completed'])) {
            isItemCompleted = true
        }

        if (onlyPhaseItem.get('isLocked')) {
            isItemLocked = true;
            isItemExpired = false;
        } else if (onlyPhaseItem.get('isExpired')) {
            isItemLocked = false;
            isItemExpired = true;
        } else {
            isItemLocked =
                item.get('lock_details').get('lock_reason') == 'locked'
                || item.get('lock_details').get('lock_reason') == 'not_started'
                || item.get('lock_details').get('lock_reason') == 'prerequisites'
                || item.get('lock_details').get('lock_reason') == 'sequential_progress';

            isItemExpired =
                item.get('lock_details').get('lock_reason') == 'expired' || onlyPhaseItem.get('isExpired');
        }


        if (isItemLocked)
            return;

        if (isItemExpired) {
            if (!isItemCompleted) {
                return;
            }
        }

        let params = [
            { 'BATCH_ID': batchId },
            { 'COURSE_ID': courseId },
            { 'MODULE_ID': item.get('module_id') },
            { 'ITEM_ID': item.get('id') },
            { '_PHASE_ID_': phaseId },
            { '_PHASEITEMID_': phaseItemId }
        ];
        let routeObj = { route, params };
        this.checkAndSetPhaseAndItemStates(routeObj);
    }

    redirectToCourseFeedback = () => {
        if (!this.shouldEnableFeedback()) {
            return false;
        }
        let { batchId, phaseId, phaseItemId, courseId } = this.props;
        const route = routes.get('BATCH_ITEM_COURSE_FEEDBACK');
        const params = [
            { 'BATCH_ID': batchId },
            { 'COURSE_ID': courseId },
            { '_PHASE_ID_': phaseId },
            { '_PHASEITEMID_': phaseItemId }
        ];
        const routeObj = { route, params };
        updateRoute(routeObj)
    }

    getModuleItemCards = () => {
        let { moduleDetails } = this.props;

        let moduleItemCards = <div>Loading...</div>;

        let items = [];
        let currentModuleItemId = null;
        if (moduleDetails && moduleDetails.get('moduleItemsList').size > 0) {
            items = moduleDetails.get('moduleItemsList');
            moduleItemCards = items.map(item => {
                if (!currentModuleItemId) {
                    if (!item.get('completion_requirement')) {
                        currentModuleItemId = item.get('id');
                    } else if (item.get('completion_requirement').get('completed') == false) {
                        currentModuleItemId = item.get('id');
                    }
                }
                return <ModuleItemCard
                    key={`moduleItem${item.get('id')}`}
                    itemData={item}
                    goToItem={this.goToItem.bind(this, item)}
                    isCurrent={(currentModuleItemId == item.get('id'))}
                    onlyPhaseItem={this.props.onlyPhaseItem}
                />
            })

        }
        return moduleItemCards;
    }

    shouldEnableFeedback = () => {
        const { onlyPhaseItem, userItems } = this.props;
        const userPhaseItem = filterObjectFromArray(userItems, 'id', onlyPhaseItem.get('id'));
        if (onlyPhaseItem.get('isLocked') ||
            (onlyPhaseItem.get('isExpired') && (userPhaseItem && userPhaseItem.get('workflowStatus') !== enumConstants.get('COMPLETED')))
        ) {
            return false;
        }
        return true;
    }

    render() {
        const { onlyPhaseItem, getLabelWithDefaults, moduleDetails, feedbackStatuses } = this.props;
        let shouldEnableFeedback = this.shouldEnableFeedback();

        if (moduleDetails.get('isModuleItemsListAvailable') && moduleDetails.get('moduleItemsList').size === 0) {
            return <div>{getLabelWithDefaults('phase_no_activities_label', 'There are no activities in this phase, yet.')}</div>;
        }

        let connectorheight = 0;
        if (moduleDetails.get('isModuleItemsListAvailable') && moduleDetails.get('moduleItemsList').size > 0) {
            let numItems = moduleDetails.get('moduleItemsList').size;
            connectorheight = (numItems - 1) * 182
        }
        if (onlyPhaseItem.getIn(['settings', 'isFeedbackEnabled']) === 'true' &&
            onlyPhaseItem.getIn(['settings', 'feedbackScenarioId'])) {
            connectorheight += 114
        }
        const myStyles = getSkin(this.props.skinGuide);

        const courseFeedbackStatus = feedbackStatuses.get('phaseItemFeedbackStatuses').find(phaseItemFeedback => phaseItemFeedback.get('id') === onlyPhaseItem.get('id'));

        return (
            <div styleName="module-item-cards-row">
                <div styleName="module-item-cards-cnt" style={{ position: "relative" }}>
                    {this.getModuleItemCards()}
                    {
                        onlyPhaseItem.getIn(['settings', 'isFeedbackEnabled']) === 'true' &&
                        onlyPhaseItem.getIn(['settings', 'feedbackScenarioId']) &&
                        <div styleName="feedback-link-cnt" onClick={this.redirectToCourseFeedback}>
                            <div className={css(myStyles.feedbackLinkText)} styleName="feedback-text"
                                style={shouldEnableFeedback ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
                            >
                                <div styleName="feedback-icon-cnt">
                                    {
                                        shouldEnableFeedback ?
                                            (
                                                courseFeedbackStatus && courseFeedbackStatus.get('status') ?
                                                    <CompletedFeedbackIcon />
                                                    :
                                                    <FeedbackIcon />
                                            )
                                            :
                                            <LockIcon svgColor={this.props.skinGuide.getIn(['skinHex', 'greyColor4']) || '#979eb7'} />
                                    }
                                </div>
                                {
                                    courseFeedbackStatus && courseFeedbackStatus.get('status') ?
                                        this.props.getLabel('thank_you_note_after_feedback')
                                        :
                                        this.props.getLabel('like_to_hear_from_you_label')
                                }
                            </div>
                            <div styleName="feedback-dot"></div>
                        </div>
                    }
                    <div styleName="items-connector" style={{ height: `${connectorheight}px` }}></div>
                </div>
            </div>
        );
    }
}

export default ModuleItemOnlyDevelopmentPhaseItem;
