import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { updateUserPhaseDetailedData } from 'actions/phases/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';

class FetchUserPhaseDetails extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	prepareUrl = (apiInfoObject = {}) => (
		urls.get('PLATFORM').get('GET_USER_PHASE_DETAILS').replace(
			'PHASE_ID',
			apiInfoObject.phaseId
		)+`?batchId=${this.getState().get('userState').get('currentBatchId')}`
	)

	successCall = (response) => {
		this.dispatch(updateUserPhaseDetailedData(response));
		return response;
	}

	onError = (errorObj) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR_USER_PHASE_DETAILS'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to fetch phase status. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new FetchUserPhaseDetails();
