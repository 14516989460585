import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		questionNumber:{
			...myTypography.button,
			color: skinHex.get('secondaryColor')
		},
		questionPoints:{
			...myTypography.button,
			color: `rgba(9, 16, 39, 0.34)`
		}
	});
}

export default getSkin;