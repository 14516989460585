import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const myTypography = typography(theme);
	return StyleSheet.create({
		noJourneyText: {
			...myTypography.body1,
			fontWeight: 600,
			color: 'rgba(0, 0, 0, 0.65)'
		}
	});
}

export default getSkin;