/**
 * @author: bharath
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Logo.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Logo component of the application
 *
 **/

import React from 'react';
import applySkin from 'utils/SkinProvider';
import environment from 'utils/environment';

@applySkin
class Logo extends React.Component {

	constructor(props) {
		super(props);
	}


	render() {
		return (
			<svg width="124" height="25" viewBox="0 0 124 25">
				<g fill="none" fillRule="evenodd">
					<text fill={this.props.skinGuide.get('skinHex').get('primaryColor')} fontFamily="Muli-Bold, Muli" fontSize="28" fontWeight="bold" transform="translate(-1 -6)">
						<tspan x="34" y="28">{environment.REACT_APP_PRODUCT_NAME || 'Aktivlearn Plus'} </tspan>
					</text>
					<path fill={this.props.skinGuide.get('skinHex').get('secondaryColor')} d="M10.306 1.127L.599 19.047A2 2 0 0 0 2.358 22h20.16a2 2 0 0 0 1.727-3.008L13.792 1.072a2 2 0 0 0-3.486.055z"/>
					<path fill={this.props.skinGuide.get('skinHex').get('primaryColor')} d="M26.934 4.718L9.844 3.533a2 2 0 0 0-1.905 2.934L16.783 23.1a2 2 0 0 0 3.53.003L28.56 7.655a2 2 0 0 0-1.626-2.937z" opacity=".6"/>
				</g>
			</svg>
		);
	}
}

export default Logo;
