import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		completeSummaryContainer: {
			backgroundColor: skin.get('greyColor6')
		},
		detailedName: {
			color: skin.get('secondaryColor'),
			...myTypography.h6
		}
	});
}

export default getSkin;