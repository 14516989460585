import actionConstants from 'constants/actions';

export const setQuiz = (response) => ({
    type: actionConstants.get('SET_QUIZ_DATA'),
    quizData: response.quiz
})

export const setQuizResponse = (response) => ({
    type: actionConstants.get('SET_USER_QUIZ_RESPONSES'),
    quizResponseData: response.quizResult
})

export const fetchingQuizResponsesFlag = (status) => ({
    type: actionConstants.get('SET_FETCH_USER_QUIZ_RESPONSES_FLAG'),
    status
})

export const fetchingQuizFlag = (status) => ({
    type: actionConstants.get('SET_FETCH_SURVEY_QUIZ_FLAG'),
    status
})


export const fetchingUserQuizSubmissionsFlag = (status) => ({
    type: actionConstants.get('SET_FETCH_USER_QUIZ_SUBMISSIONS_FLAG'),
    status
})

export const setUserSubmissions = (submissions) => ({
    type: actionConstants.get('SET_USER_QUIZ_SUBMISSIONS'),
    submissions
})