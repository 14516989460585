import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setModuleItemsList } from 'actions/courses/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';
import { getLocaleForDAPI } from 'utils/locales';

class FetchCourseModulesList extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
        return urls.get('DEVELOPMENT_PHASE').get('MODULE_ITEMS_LIST')
        .replace('_COURSE_ID_', apiInfoObject.courseId)
        .replace('_MODULE_ID_', apiInfoObject.moduleId)
        .replace('_BATCH_ID_', apiInfoObject.batchId)
        .replace('_PHASE_ID_', apiInfoObject.phaseId)
        .replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
        .replace('LANG', getLocaleForDAPI())
    }
	
    successCall = (response, apiInfoObject) => {
        this.dispatch(setModuleItemsList(response, apiInfoObject));
        this.dispatch(setApiError(
            'fetchModuleError',
            null
        ));  
        return response;
    }

    onError = (errorObj) => {
        this.dispatch(setApiError(
            'fetchModuleError',
            'Something went wrong'
        ));
    }

}

export default new FetchCourseModulesList();