import {
	StyleSheet
} from 'aphrodite';
import {
	hexToRgbA,
	typography
} from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		loadingText: {
			...myTypography.overline,
			fontWeight: 600,
			color: skin.get('greyColor2')
		}
	});
}

export default getSkin;