import React, { Component } from 'react';
import styles from './fullScreen.module.sass';
import CSSModules from 'react-css-modules';

@CSSModules(styles, { allowMultiple: true })
class FullScreen extends Component {

	constructor(props) {
		super(props);
		this.state = {
			fullscreenEnabled: false
		}
	}

	componentWillMount() {
		if (document.addEventListener) {
			document.addEventListener('webkitfullscreenchange', this.exitHandler, false);
			document.addEventListener('mozfullscreenchange', this.exitHandler, false);
			document.addEventListener('fullscreenchange', this.exitHandler, false);
			document.addEventListener('MSFullscreenChange', this.exitHandler, false);
		}
	}

	exitHandler = () => {
		if (
			this.props.fullscreenEnabled
			&& !(
				document.fullscreenElement
				|| document.mozFullScreenElement
				|| document.webkitFullscreenElement
			)
		)
			this.props.onExitFullScreen();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.fullscreenEnabled !== this.props.fullscreenEnabled) {
			if (nextProps.fullscreenEnabled) {
				this.openFullscreen();
			} else {
				this.closeFullscreen()
			}
		}
	}

	componentWillUnmount() {
		this.closeFullscreen();
	}

	openFullscreen = () => {
		let elem = document.documentElement;
		if (!document.fullscreenElement &&    // alternative standard method
			!document.mozFullScreenElement && !document.webkitFullscreenElement) {
			if (elem.requestFullscreen) {
				elem.requestFullscreen();
			} else if (elem.mozRequestFullScreen) { /* Firefox */
				elem.mozRequestFullScreen();
			} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
				elem.webkitRequestFullscreen();
			} else if (elem.webkitRequestFullScreen) { /* Safari */
				elem.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
			} else if (elem.msRequestFullscreen) { /* IE/Edge */
				elem.msRequestFullscreen();
			}
		}
	}

	closeFullscreen = () => {
		if (document.fullscreenElement ||    // alternative standard method
			document.mozFullScreenElement || document.webkitFullscreenElement) {
			if (document.exitFullscreen) {
				document.exitFullscreen();
			} else if (document.mozCancelFullScreen) { /* Firefox */
				document.mozCancelFullScreen();
			} else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
				document.webkitExitFullscreen();
			} else if (document.msExitFullscreen) { /* IE/Edge */
				document.msExitFullscreen();
			}
		}
	}

	render() {
		return (
			<div id="fullscreen-container" styleName="fullscreen-cnt">
				{this.props.children}
			</div>
		);
	}
}

export default FullScreen;