import React, {useState} from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

import styles from './activeWebSession.module.sass';
import getSkin from './skin.js';
import SessionActiveIcon from 'svgComponents/sessionActiveIcon';
import SecondaryButtonInverted from 'commonComponents/buttons/secondaryButtonInverted';
import WebSessionInfoTooltip from 'components/journeys/journeyDashboard/webSessionInfoTooltip'
import InfoIcon from 'svgComponents/infoIcon';

const ActiveWebSession = ({webSessionDetails, onClickOtherSessions, getLabel, skinGuide}) => {
  const myStyles = getSkin(skinGuide);
  const [sessionTooltipVisible, setSessionTooltipVisible] = useState(false);

  if(!webSessionDetails.session) return null;
  const openSessionLink = () => {
    window.open(webSessionDetails.session.get('link'));
  }
  
  const showTooltip = () => {
    setSessionTooltipVisible(true);
  }

  const hideTooltip = () => {
    setSessionTooltipVisible(false);
  }
  
  return (
    <div styleName='active-web-session-banner-container'>
        <div styleName='active-web-session-banner-content' className={css(myStyles.content)}>
            <div >
                <div className={css(myStyles.activeSessionText)}>
                    <SessionActiveIcon/>&nbsp;&nbsp;
                    {getLabel('journey_banner_active_session_in_progress_label')}
                </div>
                <div className={css(myStyles.webSessionLabel)} styleName="web-session-label">
                    {getLabel('web_session_label')}
                    <div styleName='info-icon-cnt'
                        onMouseEnter={showTooltip}
                        onMouseLeave={hideTooltip}
                    >
                        <InfoIcon iconOpacity="1" strokeColor="#FFFFFF"/>
                    </div>
                </div>
                <div className={css(myStyles.sessionTitle)} styleName="session-title">
                    {webSessionDetails.session.get('title')}
                </div>
                { sessionTooltipVisible && <div styleName="session-tooltip-outer">
                    <WebSessionInfoTooltip session={webSessionDetails.session}/>
                </div>}
            </div>
            <div styleName='right-side-container'>
                <div styleName="join-button-container" onClick={() => openSessionLink()}>
                    <SecondaryButtonInverted text={getLabel('websession_join_label_big')}/>
                </div>
                <div styleName="other-upcoming-sessions" className={css(myStyles.otherUpcomingSessions)} onClick={onClickOtherSessions}>
                    {getLabel('journey_info_web_session_other_sessions_label')}
                </div>
            </div>
        </div>
    </div>
  )
}

export default applySkin(applyLabel(CSSModules(ActiveWebSession, styles)));