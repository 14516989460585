import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({ 
        buttonStyle: {
            border: 'solid 1px #4e546c',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            ':hover': {
                border: 'solid 1px #979eb7'
            }

        },

        buttonStyleWithOutHover: {
            border: 'solid 1px #4e546c',
            backgroundColor: 'rgba(0, 0, 0, 0.1)'
        },

        buttonFontStyle: {
            fontSize: '24px',
            color: '#fff',
            fontWeight: 600
        },

        buttonFontStyleDisabled: {
            fontSize: '24px',
            color: '#fff',
            fontWeight: 600,
            opacity: 0.5,
            cursor: 'not-allowed'
        }
    });
}

export default getSkin;