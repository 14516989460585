import React, { Component } from 'react';
import { connect } from 'react-redux';
import BatchFeedbackStatusList from 'components/feedback/batchFeedbackStatusList';
import { fetchEntityFeedback } from 'actions/itemFeedback/apiActions';
import FeedbackWrapper from '../components/feedback/batchFeedbackStatusList/feedbackWrapper';
import {
	submitAnswer,
	submitFeedback
} from 'actions/itemFeedback/apiActions';
import { fetchBatchFeedbackStatus } from 'actions/batchFeedbackStatus/apiActions';
import { unsetBatchFeedbackStatus } from 'actions/batchFeedbackStatus/actionCreators';
import { updateFetchFeedbackStatus, updateFetchUserFeedbackStatus } from 'actions/loaders/actionCreators';

export class BatchFeedbackStatusListContainer extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedEntity: null
        }
    }

    componentWillUnmount(){
        this.props.unsetBatchFeedbackStatus();
    }

    onSelectEntity = (entity) => {
        console.log('onSelectEntity', entity)
        this.props.updateFetchFeedbackStatus(true);
        this.props.updateFetchUserFeedbackStatus(true);
        console.log('onSelectEntity2', entity.toJS())

        this.setState({ selectedEntity: entity });
    }

    onUnSelectEntity = (entity) => {
        this.setState({ selectedEntity: null });
    }

    render() {
        const { selectedEntity } = this.state;
        const { fetchEntityFeedback, entityFeedback, batchDetails, loaders, match, submitAnswerForFeedback, submitFeedback, feedbackStatus } = this.props;

        console.log('selectedEntity', selectedEntity);

        return <React.Fragment>
            {selectedEntity ?
                <FeedbackWrapper
                    fetchEntityFeedback={fetchEntityFeedback}
                    entityFeedback={entityFeedback}
                    selectedEntity={selectedEntity}
                    batchId={batchDetails.get('id')}
                    loading={loaders.get('isFetchingFeedback') || loaders.get('isFetchingUserFeedback')}
                    match={match}
                    submitAnswerForFeedback={submitAnswerForFeedback}
                    submitFeedback={submitFeedback}
                    onUnSelectEntity={this.onUnSelectEntity}
                    batchDetails={batchDetails}
                />
                :
                <BatchFeedbackStatusList
                    {...this.props}
                    batchId={batchDetails.get('id')}
                    feedbackStatus={feedbackStatus}
                    onSelectEntity={this.onSelectEntity}             
                /> }  
        </React.Fragment> 
    }
}

const mapStateToProps = (state) => ({
    loaders: state.get('loaders'),
    entityFeedback: state.get('itemFeedback'),
    feedbackStatus: state.get('batchFeedbackStatus')
})

const mapDispatchToProps = (dispatch) => ({
    fetchEntityFeedback: (batchId, entityId, entityType, scenario) => {
		dispatch(fetchEntityFeedback(batchId, entityId, entityType, scenario))
	},
    submitAnswerForFeedback: (payload, questionId, entityId, entityType, scenario, batchId) => {
        dispatch(submitAnswer(payload, questionId, entityId, entityType, scenario, batchId))
    },
    submitFeedback: (payload, entityId, entityType, scenario, batchId, successCallback) => {
        dispatch(submitFeedback(payload, entityId, entityType, scenario, true, batchId, successCallback));
    },
    fetchBatchFeedbackStatus: (batchId) => {
		dispatch(fetchBatchFeedbackStatus(batchId))
	},
    unsetBatchFeedbackStatus: () => {
        dispatch(unsetBatchFeedbackStatus());
    },
    updateFetchFeedbackStatus: (status) => {
        dispatch(updateFetchFeedbackStatus(status));
    },
    updateFetchUserFeedbackStatus: (status) => {
        dispatch(updateFetchUserFeedbackStatus(status));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchFeedbackStatusListContainer)
