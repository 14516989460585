import SaveSystemCheckResult from 'actions/serverCalls/users/SaveSystemCheckResult';
import FetchUserWhitelistingRequirements from 'actions/serverCalls/users/FetchUserWhitelistingRequirements';


export const saveUserSystemCheckResult = (payload) => {    
    console.log('system check payload', payload);
    SaveSystemCheckResult.call({
        body: payload.systemCheckResult,
        headers: {
            'Content-type': 'application/json'
        }
    });
};


export const fetchUserWhitelistingRequirements = (batchId = null) => {
    FetchUserWhitelistingRequirements.call({ batchId });
}