import actionConsts from 'constants/actions';

const setToolFAQs = toolFaqs => ({
    type: actionConsts.get('UPDATE_TOOLS_FAQS_LIST'),
    toolFaqs
});

export {
    setToolFAQs
};
