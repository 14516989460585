import React from 'react';
import { connect } from 'react-redux';
import { fetchDownloadsSectionData } from 'actions/userJourneys/apiActions';
import UserDownloads from 'components/userDownloads';
import { unsetBatchesFeedbackStatusFlags } from 'actions/batchFeedbackStatus/actionCreators';
import { fetchBatchesFeedbackStatusFlags } from 'actions/batchFeedbackStatus/apiActions';

class UserDownloadsContainer extends React.Component {

	
	componentDidMount() {
		document.title = 'Downloads - Aktivlearn Plus'
		this.props.fetchDownloadsSectionData();
	}
	
	componentWillUnmount(){
		this.props.unsetBatchesFeedbackStatusFlags();
	}

	updatePage = () => {
		const batches = this.props.completedUserBatches.toJS();
		const batchIds = batches.map(batch => batch.batchId);
		this.props.fetchBatchesFeedbackStatusFlags(batchIds);
	}
	
	render() {
		return <UserDownloads {...this.props} updatePage={this.updatePage}/>
	}
}

const mapStateToProps = state => {
	return {
		userOrganization: state.get('userOrganization'),
		batches: state.getIn(['batches', 'batchesList']),
		completedUserBatches: state.getIn(['batches', 'completedUserBatches']),
		isCompletedBatchesFetching: state.getIn(['batches', 'isCompletedBatchesFetching']),
		batchesFeedbackStatusFlags: state.get('batchesFeedbackStatusFlags')
	}
};

const mapDispatchToProps = dispatch => ({
	fetchDownloadsSectionData: () => {
		dispatch(fetchDownloadsSectionData())
	},
    unsetBatchesFeedbackStatusFlags: () => {
        dispatch(unsetBatchesFeedbackStatusFlags());
    },
	fetchBatchesFeedbackStatusFlags: (batchIds) => {
		dispatch(fetchBatchesFeedbackStatusFlags(batchIds));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDownloadsContainer);
