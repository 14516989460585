import React, { Component } from 'react';
import styles from './quizzeIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class QuizzeIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="icon-cnt">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
					<g fill="none" fillRule="evenodd" stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" transform="translate(1 1)">
						<circle cx="10.063" cy="10.069" r="3.5"/>
						<path d="M8.969 9.413a1.094 1.094 0 1 1 1.094 1.093M10.063 11.819a.219.219 0 1 1 0 .437.219.219 0 0 1 0-.437M3.063 6.131h3.062M3.063 8.319h1.75M3.063 10.506h1.75M5.688 13.569H1.313a.875.875 0 0 1-.875-.875V3.506c0-.483.391-.875.875-.875H3.5a2.188 2.188 0 1 1 4.375 0h2.188c.483 0 .874.392.874.875V4.82"/>
						<path d="M5.688 2.194a.219.219 0 1 1 0 .437.219.219 0 0 1 0-.437"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default QuizzeIcon;