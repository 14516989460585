import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './phaseFeedback.module.sass';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import { getUserActiveBatchDetails } from 'utils/batchUtils';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyLabel from 'utils/LabelProvider';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import routes from 'utils/webAppRoutes/routeConstants';
import MenuList from 'commonComponents/menuList';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import Footer from 'commonComponents/footer';
import Dialog from 'commonComponents/dialog/Dialog';
import enumConstants from 'constants/enums';
import CompetencyTags from 'components/feedbackQuestions//competencyTags';
import PrimaryButton from 'commonComponents/buttons/primaryButton';

import ThumbsUpDown from 'commonComponents/feedbackQuestionTemplates/thumbsUpDown';
import StarRating from 'commonComponents/feedbackQuestionTemplates/starRating';
import ScaleRating from 'commonComponents/feedbackQuestionTemplates/scaleRating';
import TextBased from 'commonComponents/feedbackQuestionTemplates/textBased';
import ModuleItemLoader from 'components/moduleItems/moduleItem/ModuleItemLoader.jsx';
import TickMark from 'svgComponents/tickMark';
import { checkIfPresent } from 'utils/utilFunctions';
import { filterObjectFromArray } from 'utils/objectUtils';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PhaseFeedback extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showDialog: false,
			showSaveFeedbackButton: false
		};
	}

	setShowFeedbackQuestions = () => {
		this.setState({
			showSaveFeedbackButton: true
		});
	}

	onSaveClick = () => {
		this.setState({
			showSaveFeedbackButton: false
		});
	}

	onSubmitFeedback = () => {
		const { submitFeedback } = this.props;

		const payload = {
			isSubmitted: true
		}
		submitFeedback(+this.props.match.params.phaseId, payload);
		// this.redirectToMeasure()
	}

	redirectToMeasure = () => {
		const { match } = this.props;
		const batchId = +match.params.batchId;
		const phaseId = +match.params.phaseId;
		const route = routes.get('PHASE_MEASURES');
		const params = [{ 'BATCH_ID': batchId }, { 'PHASE_ID': phaseId }];
		const routeObj = { route, params };
		updateRoute(routeObj);
	}

	onSkipFeedback = () => {
		this.redirectToMeasure()
	}

	onGoToDashboard = () => {
		const { match } = this.props;
		const batchId = +match.params.batchId;
		const route = routes.get('BATCH_HOME');
		const params = [{ 'BATCH_ID': batchId }];
		const routeObj = { route, params };
		updateRoute(routeObj);
	}

	getQuestionTemplate = (question) => {
		const { submitAnswer, submitAnswerForPhase } = this.props;
		switch (question.get('type')) {
			case enumConstants.get('FEEDBACK_THUMBS_UP_DOWN'):
				return <ThumbsUpDown
					key={`phaseFeedbackTUP_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswerForPhase}
					feedbackItemId={this.props.match.params.phaseId}
				/>
			case enumConstants.get('FEEDBACK_RATING'):
				return <StarRating
					key={`phaseFeedbackRating_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswerForPhase}
					feedbackItemId={this.props.match.params.phaseId}
				/>
			case enumConstants.get('FEEDBACK_RATING_SCALE'):
				return <ScaleRating
					key={`phaseFeedbackRating_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswerForPhase}
					feedbackItemId={this.props.match.params.phaseId}
				/>
			case enumConstants.get('FEEDBACK_INPUT_MEDIUM_TEXT'):
				return <TextBased
					key={`phaseFeedbackText_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswerForPhase}
					feedbackItemId={this.props.match.params.phaseId}
				/>
			case enumConstants.get('FEEDBACK_MAQ'):
				return <div styleName="question">
					<CompetencyTags
						key={`phaseFeedbackMAQ_${question.get('id')}`}
						question={question}
						submitAnswer={submitAnswer} />
				</div>
			default:
				return <div></div>;
		}
	}

	getFeedbackQuestions = () => {
		const { phaseFeedback, getLabelWithDefaults } = this.props;
		const myStyles = getSkin(this.props.skinGuide);

		if (phaseFeedback.get('isFeedbackCompleted') && !this.state.showSaveFeedbackButton) {
			return this.getFeedbackCompletionStatusComponent()
		}

		let feedbackComponent = [];
		if (phaseFeedback) {
			feedbackComponent = phaseFeedback.get('questionsList').map((question, index) => {
				return (
					<div key={`feedback-question-${question.get('id')}`} styleName="individual-question-cnt">
						<div className={css(myStyles.questionNumber)} style={{ display: 'flex' }}>
							{getLabelWithDefaults('question_number_label', `Question ${index + 1}`, { number: index + 1 })}
							{
								question.get('mandatory') &&
								<div style={{ color: 'red' }}>&nbsp;*</div>
							}
						</div>
						{this.getQuestionTemplate(question)}
					</div>
				);

			})
		}
		return feedbackComponent;
	}

	getFeedbackHeader = () => {
		const { phases, getLabelWithDefaults } = this.props;
		const currentPhaseId = this.props.match.params.phaseId;
		const currentPhase = phases.get('phasesList').find(phase => phase.get('id') === +currentPhaseId);
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="feedback-header-cnt">
				<div className={css(myStyles.feedbackHeader)} styleName="feedback-header">
					{
						currentPhase ?
							getLabelWithDefaults(
								'phase_feedback_header',
								`Feedback for ${currentPhase.get('name')} Phase`,
								{ phase_name: currentPhase.get('name') || '' }
							)
							:
							'PHASE FEEDBACK'
					}
				</div>
				<div className={css(myStyles.feedbackSubHeader)}>
					{getLabelWithDefaults(
						"phase_feedback_sub_header",
						'Your feedback helps us make better journeys'
					)}
				</div>
				<div className={css(myStyles.feedbackNote)}>
					{
						getLabelWithDefaults(
							'phase_feedback_note',
							'* represents mandatory questions. To submit feedback, please answer all mandatory questions.'
						)
					}
				</div>
			</div>
		)
	}

	getFeedbackCompletionStatusComponent = () => {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="completion-status-cnt">
				<div styleName="completion-status-content">
					<div styleName="feedback-completion-tick-mark-cnt">
						<TickMark fillColor={'white'} />
					</div>
					<div className={css(myStyles.thankYouNote)}>
						{this.props.getLabelWithDefaults('thank_you_note_after_feedback', "Thanks for your feedback")}
					</div>
					<div
						className={css(myStyles.viewResponsesLabel)}
						onClick={this.setShowFeedbackQuestions}>
						{this.props.getLabelWithDefaults('feedback_edit_responses_label', `Edit Responses`)}
					</div>
				</div>
				<div className={css(myStyles.completionBackground)} styleName="completion-bg"></div>
			</div>
		)
	}

	getFeedbackActions = () => {
		const { phaseFeedback, getLabelWithDefaults } = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		let submitButtonLabel = getLabelWithDefaults('feedback_submit_label', 'Submit');
		if (phaseFeedback.get('isFeedbackCompleted')) {
			return this.getPhaseCompletionComponent()
		}
		return (
			<div styleName="feedback-action-cnt">
				{/* <div
					className={css(myStyles.skipButton)}
					styleName="skip-feedback-cnt"
					onClick={this.onSkipFeedback}
				>
					{getLabelWithDefaults('feedback_skip_label', 'Skip')}
				</div> */}
				<div styleName="submit-feedback-cnt">
					<PrimaryButton
						text={submitButtonLabel}
						clickFunction={this.onSubmitFeedback}
						disabled={!this.areAllMandatoryQuestionsAnswered()}
					/>
				</div>
			</div>
		)
	}

	areAllMandatoryQuestionsAnswered = () => {
		let manadatoryQuestionsAnswered = true;
		const { phaseFeedback } = this.props;
		if (phaseFeedback) {
			let numberOfQuestions = phaseFeedback.get('questionsList').size;
			for (let i = 0; i < numberOfQuestions; i++) {
				if (
					phaseFeedback.getIn(['questionsList', i, 'mandatory'])
					&& !phaseFeedback.getIn(['questionsList', i, 'completed'])
				) {
					manadatoryQuestionsAnswered = false;
					break;
				}
			}
		}
		return manadatoryQuestionsAnswered
	}



	getPhaseCompletionComponent = () => {
		const { getLabelWithDefaults } = this.props;
		const currentPhaseId = this.props.match.params.phaseId;
		const userActiveBatchDetails = getUserActiveBatchDetails(this.props.batches);
		const { phases, items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails);
		const currentPhase = phases.get('phasesList').find(phase => phase.get('id') === +currentPhaseId);
		const myStyles = getSkin(this.props.skinGuide);

		let isReportAvailable = this.checkIfAvailable(currentPhase);

		return (
			<div styleName="phase-completion-action-cnt">
				<div styleName="phase-completion-msg-cnt">
					<div styleName="tick-mark">
						<TickMark fillColor="#4A90E2" />
					</div>
					<div className={css(myStyles.phaseCompletionNote)}>
						{
							getLabelWithDefaults(
								'phase_completion_note',
								`You've completed ${currentPhase.get('name')} Phase`,
								{ phase_name: currentPhase.get('name') || '' }
							)
						}
					</div>
				</div>
				<div styleName="phase-completion-cnt-action">
					<div styleName="go-to-measures-btn">
						{isReportAvailable &&
							<PrimaryButton
								text={getLabelWithDefaults('go_to_measures_label', 'Go to measures')}
								clickFunction={this.redirectToMeasure}
							/>
						}
					</div>
					<div
						className={css(myStyles.skipButton)}
						styleName="go-to-dashboard-btn"
						onClick={this.onGoToDashboard}
					>
						{getLabelWithDefaults('go_to_dashboard_label', 'GO TO DASHBOARD')}
					</div>
				</div>
			</div>
		)
	}

	isPhaseCompleted = (phaseId) => {
		const userPhase = filterObjectFromArray(this.props.phases.get('userPhasesList'), 'id', phaseId);
		return userPhase.get('workflowState') == enumConstants.get('COMPLETED');
	}

	checkIfAvailable = (activePhaseDetails) => {
		if (!activePhaseDetails) return false;
		let isAvailable = true;
		let isPhaseCompleted = this.isPhaseCompleted(activePhaseDetails.get('id'));
		if (activePhaseDetails.get('isLocked') || (activePhaseDetails.get('isExpired') && !isPhaseCompleted)) {
			isAvailable = false;
		}

		if (
			checkIfPresent(activePhaseDetails)
			&& checkIfPresent(activePhaseDetails.get('settings'))
			&& checkIfPresent(activePhaseDetails.get('settings').get('isReportScreenAvailable'))
			&& activePhaseDetails.get('settings').get('isReportScreenAvailable') == "false"
		) {
			isAvailable = false;
		}

		return isAvailable;
	}

	goToJourneyDashboard = () => {
		const { isSideBarOpen } = this.props;
		this.props.setSideBarFlag(!isSideBarOpen);
		const route = routes.get('BATCH');
		const params = [{ 'BATCH_ID': this.props.userState.get('currentBatchId') }];
		updateRoute({ route, params });
	}

	toggleDialog = (message = null) => {
		this.setState({
			showDialog: !this.state.showDialog,
			dialogMessage: message
		});
	}

	renderFeedbackComponent = () => {
		const {
			selectedUserItem,
			itemsListInActivePhase,
			isSideBarOpen,
			activePhaseDetails,
			setSideBarFlag,
			selectedItem,
			setUserState,
			updateItemIdFromRoute,
			location,
			batches,
			userState,
			apiErrors,
			fetchItemData,
			loaders,
			getLabelWithDefaults
		} = this.props;
		const phaseId = +this.props.match.params.phaseId;
		let styleSideBar = 'side-bar-collapsed';
		let assessmentStyle = 'assesment-items-collapsed';
		if (isSideBarOpen) {
			styleSideBar = 'side-bar-expanded';
			assessmentStyle = 'assesment-items-expanded';
		}

		const userActiveBatchDetails = getUserActiveBatchDetails(batches);
		const { phases, items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails);
		const userItemsList = items.get('userItemsList');

		const apiError = apiErrors.get('fetchPhaseItemsError') || apiErrors.get('fetchUserPhaseItemsError') || apiErrors.get('fetchPhaseItemDetailsError');

		let currentPhaseData = phases.get('phasesList').find(phase => phase.get('id') === phaseId)
		if (currentPhaseData)
			currentPhaseData = currentPhaseData.toJS()

		return (
			<div styleName="side-bar-product-container">
				<div styleName={`${styleSideBar} menu-container`}
					style={{
						position: this.props.userState.get('makeLeftBarFixed') ? 'fixed' : 'absolute',
						height: this.props.userState.get('makeLeftBarFixed') ? '100vh' : 'calc(100vh - 67px)'
					}}
				>
					<MenuList
						selectedUserItem={selectedUserItem}
						list={itemsListInActivePhase}
						userItemsList={userItemsList}
						isSideBarOpen={isSideBarOpen}
						activePhaseDetails={activePhaseDetails}
						setSideBarFlag={setSideBarFlag}
						phases={phases}
						batchId={selectedItem.get('batchId')}
						setUserState={setUserState}
						location={location}
						updateItemIdFromRoute={updateItemIdFromRoute}
						goToJourneyDashboard={this.goToJourneyDashboard}
						match={this.props.match}
						toggleDialog={this.toggleDialog}
						userState={userState}
						apiErrors={apiErrors}
						fetchItemData={fetchItemData}
						isFromFeedback={true}
						currentPhaseData={currentPhaseData}
						feedbackStatuses={this.props.feedbackStatuses}
						attachmentsList={this.props.attachmentsList}
					/>
				</div>

				<div styleName={`${assessmentStyle} content-row`}>
					<APIErrorBoundary
						key={phaseId}
						apiError={apiError}
						retryHandler={() => console.log("Hello")}
						style={{
							width: '100vw',
							height: 'calc(100vh - 150px)',
							marginTop: '150px'
						}}
					>
						{
							loaders.get('isFetchingFeedback') ?
								<div styleName="loader-cnt">
									<ModuleItemLoader />
								</div>
								:
								<div styleName="all-content-row">
									{this.getFeedbackHeader()}
									<div styleName="phase-feedback-questions-list">
										{this.getFeedbackQuestions()}
										{
											this.state.showSaveFeedbackButton &&
											<div styleName="save-feedback-btn-cnt">
												<PrimaryButton
													text={getLabelWithDefaults('save_feedback_responses', 'Save Responses')}
													clickFunction={this.onSaveClick}
												/>
											</div>
										}
									</div>
									{this.getFeedbackActions()}
								</div>
						}
						<Footer />
					</APIErrorBoundary>
				</div>
			</div>
		)
	}

	renderDialog = () => {
		if (!this.state.showDialog) {
			return null;
		}

		const popupMessage = this.state.dialogMessage || this.props.getLabel('item_unavailable_label');
		const popupOkButtonText = this.props.getLabel('okay_label');;
		return (
			<Dialog
				key="item-status-dialog"
				dialogMessage={popupMessage}
				okAction={this.toggleDialog}
				okButtonText={popupOkButtonText}
			/>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="assesment-container" className={css(myStyles.assessmentBorder)}>
				{this.renderFeedbackComponent()}
				{this.renderDialog()}
			</div>
		);
	}
}

export default PhaseFeedback;