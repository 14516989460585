import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		quizHeaderCnt: {
			backgroundColor: skinHex.get('white')
		},
		quizTitle: {
			...myTypography.h6,
			color: 'rgba(9, 16, 39, 0.85)',

		},
		quizInstructionsTitle: {
			...myTypography.body1,
			color: '#4e535f',
			fontWeight: 600,
			lineHeight: 'normal',
			borderBottom: `1px solid ${hexToRgbA('#979797', 0.1)}`,
    		paddingBottom: '10px'
		},
		quizInstructions: {
			...myTypography.body2,
			color: hexToRgbA('#4e535f', 0.8),
			lineHeight: 'normal'
		},
		timerCnt: {
			backgroundImage: `linear-gradient(119deg, #5782dc, ${skinHex.get('primaryColor')})`
		},
		timerTime: {
			fontSize: '32px',
			fontFamily: skin.get('fontFamily').get('headingsFamily'),
			fontWeight: 'bold',
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: 'normal',
			letterSpacing: 'normal',
			color: skinHex.get('white')
		},
		questionsAnswered: {
			...myTypography.button,
			fontWeight: 'normal',
			color: hexToRgbA(skinHex.get('white'), 0.8)
		},
		submitBtn: {
			...myTypography.button,
			color: skinHex.get('white'),
			backgroundColor: skinHex.get('primaryColor'),
			lineHeight: '32px'
		},
		exitBtn:{
			...myTypography.button,
			border: `solid 1px ${hexToRgbA(skinHex.get('secondaryColor'), 0.8)}`,
			lineHeight: '32px',
			color: skinHex.get('secondaryColor')
		},
		arrowUp: {
            borderBottom: `15px solid ${hexToRgbA('#dfe1e8', 0.9)}`,
			borderLeft: '15px solid transparent',
			borderRight: '15px solid transparent'
        },
        arrowContainer: {
            background: 'transparent'
        }
	});
}

export default getSkin;