/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: loaders.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * loaders reducer is responsible for loaders related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrUpdateObjectInList, removeObjectFromList } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    toastsList: []
});

const toasts = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('ADD_TOAST'):
            return state.set(
                'toastsList', setOrUpdateObjectInList(
                    state.get('toastsList'), action.toast, 'id', 'id'
                )
            );
        case actionConsts.get('UPDATE_TOAST_DISPLAYED_STATUS'):
            let toBeUpdatedToastStatus = {
                id: action.id,
                isDisplayed: action.status
            };
            return state.set(
                'toastsList', setOrUpdateObjectInList(
                    state.get('toastsList'), toBeUpdatedToastStatus, 'id', 'id'
                )
            );
        case actionConsts.get('REMOVE_TOAST'):
            return state.set(
                'toastsList', removeObjectFromList(state.get('toastsList'), 'id', action.id)
            );
        default:
            return state;
    }
};

export default toasts;