import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './additionalInformation.module.sass';

function AudioInDescription({ url, audioPlayerEnabled, onAudioEnded, noOfPlaybacks }) {
  return (
    <div styleName='audio-container' style={audioPlayerEnabled ? {} : {pointerEvents: 'none', opacity: '0.6'}}>
      <audio controls controlsList='nodownload noplaybackrate' src={url} onEnded={onAudioEnded} disabled={true}/>
      {noOfPlaybacks ? <div styleName='audio-no-of-playbacks-note'><strong>Note:</strong>You can play this audio only {noOfPlaybacks} time(s) </div> : null}
    </div>
  )
}

export default CSSModules(AudioInDescription, styles);


