import React, { Component } from 'react';
import styles from './author.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import DialogBubble from 'svgComponents/dialogBubble';
import AuthorIntroLoader from 'commonComponents/loaders/authorIntroLoader';
import AuthorIntroLoaderImage from 'images/loaders/circle.png';
import CeoImage from 'images/ceo2.png';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class AuthorIntro extends Component {

	renderLoader(myStyles) {
		return (
			<div
				ref={this.props.divRef}
				id='messageFromCoo'
				className={css(myStyles.authorIntroBackgroundLoader)}
				styleName="author-intro-container"
			>
				<div
					className={css(myStyles.messageContainerLoader)}
					styleName="message-container-loader"
				>
					<img src={AuthorIntroLoaderImage} styleName="circle-loader-img" />
					<AuthorIntroLoader />
				</div>
			</div>
		);
	}

	renderUserName() {
		if (!this.props.user.get('isUserDataAvailable')) {
			return <span></span>
		}
		return <span>
			{this.props.user.get('firstName')}&nbsp;
			{this.props.user.get('lastName')}
		</span>;
	}

	getUserName() {
		if (!this.props.user.get('isUserDataAvailable')) {
			return '';
		}
		return `${this.props.user.get('firstName')} ${this.props.user.get('lastName')}`;
	}

	render() {
		const {
			skinGuide, journeyDetails, getLabel, isBatchDetailsAvailable, isBatchLabelsAvailable
		} = this.props;
		const myStyles = getSkin(skinGuide);
		if (!isBatchDetailsAvailable || !isBatchLabelsAvailable) {
			return this.renderLoader(myStyles);
		}
		
		// if(this.props.divName == "messageFromCoo") {
		// 	this.refs.messageFromCoo.scrollIntoView({block: 'start', behavior: 'smooth'});
		// }
		const isCeoImageAvailable = journeyDetails.get('assignedByImage') !== null
			&& journeyDetails.get('assignedByImage') !== ""
			&& journeyDetails.get('assignedByImage') !== undefined;
		return (
			<div ref={this.props.divRef}
			styleName="author-intro-container"
			className={css(myStyles.authorIntroBackground)}
			id='messageFromCoo'
			>
				<div
					className={css(myStyles.authorIntroBackgroundPattern)}
					styleName="author-intro-background"
				></div>
				<div
					className={css(myStyles.messageContainer)}
					styleName={this.props.renderingInOverlay ? "message-container message-container-overlay" : "message-container"}
				>
					<div styleName={this.props.renderingInOverlay ?" message-title-image-overlay" : "message-title-image-container"}>
						{
							isCeoImageAvailable && <div
								className={css(myStyles.messageImage)}
								styleName={this.props.renderingInOverlay ? "message-image-overlay" : "message-image"}
								style={{
									backgroundImage: `url("${journeyDetails.get('assignedByImage')}")`
								}}
							></div>
						}
						{
							(!this.props.renderingInOverlay)
								? <div className={css(myStyles.messageTitle)} styleName="message-title">
									<div styleName="message-title-background">
										<DialogBubble />
									</div>
									<div styleName="message-title-content">
										{getLabel('journey_intro_hello')}!
									</div>
								</div>
								: null
						}
					</div>
					<div styleName={this.props.renderingInOverlay ? "message-content-overlay" : "message-content"}>
						<div
							className={css(myStyles.message)}
							styleName="message"
							dangerouslySetInnerHTML={{
								__html: journeyDetails.get('assignedByInstruction').replace(
									'USER_NAME',
									this.getUserName()
								)
							}}
						>
						</div>
						<div>
							<div className={css(myStyles.name)} styleName="name">
								{getLabel(journeyDetails.get('assignedBy'))}
							</div>
							<div className={css(myStyles.designation)} styleName="designation">
								{getLabel(journeyDetails.get('assignedByRole'))}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

}

export default AuthorIntro;