import FetchUser from 'actions/serverCalls/users/FetchUser';
import GetUserOrganization from 'actions/serverCalls/home/GetUserOrganization';
import FetchUserReference from 'actions/serverCalls/userProfile/FetchUserReference';
import SyncUser from 'actions/serverCalls/userProfile/SyncUser';
import ChangePassword from 'actions/serverCalls/userProfile/ChangePassword';
import UploadImage from 'actions/serverCalls/userProfile/UploadImage';
import {
	getFormDataForImage
} from 'utils/fileUtil';
import FetchDemographicsData from 'actions/serverCalls/userProfile/FetchDemographicsData';
import GetNotificationSubscriptionDetails from 'actions/serverCalls/userProfile/GetNotificationSubscriptionDetails';
import SubscribeNotifications from 'actions/serverCalls/userProfile/SubscribeNotifications';
import UnsubscribeNotifications from 'actions/serverCalls/userProfile/UnsubscribeNotifications';
import { setProfileUpdating } from './actionCreators';

const fetchUserProfileThunk = (dispatch, getState) => {
	return FetchUser.call().then((user) => {
		let payload = {
			userId: getState().getIn(['user', 'userID'])
		}
		GetNotificationSubscriptionDetails.call({ email: getState().getIn(['user', 'emailID']) })
		FetchDemographicsData.call();
		GetUserOrganization.call().then(() => {
			FetchUserReference.call(payload);
		});
	})
}

const fetchUserProfile = () => (dispatch, getState) => fetchUserProfileThunk(dispatch, getState)

const syncUser = (userReferences) => (dispatch, getState) => {
	let userPayload = {
		body: userReferences,
		headers: {
			'Content-type': 'application/json'
		}
	}
	dispatch(setProfileUpdating(true));
	SyncUser.call(userPayload)
		.finally(() => dispatch(setProfileUpdating(false)));
}

const changePassword = (passwordPayload) => () => {
	ChangePassword.call(
		{
			body: {
				...passwordPayload
			},
			headers: {
				'Content-type': 'application/json'
			}
		}
	)
}

const uploadAvatarImage = (imageFile) => (dispatch, getState) => {
	return UploadImage.call({
		body: getFormDataForImage(imageFile)
	}).then((response) => {
		if (response.url) {
			let userReferencePayload = getState().getIn(['userProfile', 'userReferences']).toJS();
			userReferencePayload = {
				...userReferencePayload,
				profilePicUrl: response.url
			}
			dispatch(syncUser(userReferencePayload));
		}
	});
}

const updateNotificationSubscription = (flag = false) => (dispatch, getState) => {
	let payload = {
		body: {
			email: getState().getIn(['user', 'emailID'])
		},
		headers: {
			'Content-type': 'application/json'
		}
	}
	if (flag) {
		dispatch(setProfileUpdating(true));
		SubscribeNotifications.call(payload)
			.finally(() => dispatch(setProfileUpdating(false)));
	} else {
		dispatch(setProfileUpdating(true));
		UnsubscribeNotifications.call(payload)
			.finally(() => dispatch(setProfileUpdating(false)));
	}
}

export {
	fetchUserProfile,
	syncUser,
	changePassword,
	uploadAvatarImage,
	updateNotificationSubscription
}