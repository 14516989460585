
import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrUpdateObjectInList, mergeListsBasedOnKey } from 'utils/objectUtils';
import enumConstants from 'constants/enums';

const initialState = Immutable.fromJS({
    questionsList: [],
    isFeedbackQuestionsFetched: false,
    completionDetails: {},
    isFeedbackCompleted: false,
    isNextButtonVisible: false
});

const itemFeedback = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_FEEDBACK_DATA'):
            const questData = action.payload.questions;
            return state.set('questionsList', Immutable.fromJS(questData));

        case actionConsts.get('SET_USER_FEEDBACK_DATA'):
            const payload = mergeListsBasedOnKey(state.get('questionsList'), action.payload.questions, 'id')
            const updatedQuestionsList = Immutable.fromJS(setIsVisibleKey(payload.toJS()));
            return state.set('questionsList', updatedQuestionsList)
                .set('isFeedbackQuestionsFetched', true);

        case actionConsts.get('SET_FEEDBACK_COMPLETION_STATUS'):
            const data = {
                completed: action.payload.completed,
                completedQuestions: action.payload.completedQuestions,
                completionPercentage: action.payload.completionPercentage
            };
            const isNextButtonVisible = (action.payload.completedQuestions) > 0 ? true : false;
            const isFeedbackCompleted = action.payload.isFeedbackSubmitted;
            return state.set('completionDetails', Immutable.fromJS(data)).
                set('isFeedbackCompleted', isFeedbackCompleted)
                .set('isNextButtonVisible', isNextButtonVisible);

        case actionConsts.get('SET_FEEDBACK_QUESTION_RESPONSE'):
            return setAnswerToSelectedQuestion(action.data, state)

        case actionConsts.get('SET_COMPLETION_STATUS_TEMP'):
            return state.set('isFeedbackCompleted', action.flag)

        case actionConsts.get('RESET_USER_FEEDBACK_DATA'):
            return state.set('isFeedbackQuestionsFetched', false)

        case actionConsts.get('RESET_FEEDBACK_DATA'):
            return state.set('isFeedbackQuestionsFetched', false).
                set('isFeedbackCompleted', false).
                set('questionsList', Immutable.fromJS([]))

        default:
            return state;
    }
};

const setAnswerToSelectedQuestion = (data, state) => {
    let questionsList = state.get('questionsList').toJS();
    // let notSelectedOptions = [];

    for (let i = 0; i < questionsList.length; i++) {
        if (questionsList[i].id == data.questionId) {
            // let newQuestion = questionsList[i];
            questionsList[i].completed = true;

            //we can change completed to false also?
            //1. now based on type set the value, selected option id or seelected array
            if (questionsList[i].type == enumConstants.get('FEEDBACK_THUMBS_UP_DOWN')) {
                questionsList[i].selectedOptionId = data.payload.selectedOptionId
            }
            else if (questionsList[i].type == enumConstants.get('FEEDBACK_MAQ')) {
                questionsList[i].selectedOptionIds = data.payload.selectedOptionIds
            }
            else if (questionsList[i].type == enumConstants.get('FEEDBACK_RATING')) {
                questionsList[i].value = data.payload.value
            }
            else if (questionsList[i].type == enumConstants.get('RATING_SCALE')) {
                questionsList[i].value = data.payload.value
            }
            else if (questionsList[i].type == enumConstants.get('FEEDBACK_INPUT_MEDIUM_TEXT')) {
                questionsList[i].textValue = data.payload.textValue

            }
        }

    }

    //check for pre requiste question...loop all questin and chec question id , optin , 
    // belong to which and set vivisibility to true

    questionsList.map((eachQuestion) => {
        if (eachQuestion.prerequisiteQuestions && eachQuestion.prerequisiteQuestions.length > 0) {
            eachQuestion.prerequisiteQuestions.map((eachPre) => {
                if (eachPre.id == data.questionId) {
                    if (!eachPre.selectedOptionId) {
                        eachQuestion.isVisible = true
                    }
                    else if (eachPre.selectedOptionId == data.payload.selectedOptionId) {
                        eachQuestion.isVisible = true
                    }
                    else {
                        eachQuestion.isVisible = false
                    }
                }
            })

        }
    })


    return state.set('questionsList', mergeListsBasedOnKey(state.get('questionsList'),
        questionsList, 'id')).set('isNextButtonVisible', true)

}

const setIsVisibleKey = (questions) => {
    return questions.map((eachQuestion) => {
        eachQuestion.isVisible = true

        if (eachQuestion.prerequisiteQuestions && eachQuestion.prerequisiteQuestions.length > 0) {
            eachQuestion.prerequisiteQuestions.map((eachPre) => {
                questions.map((data) => {
                    if (eachPre.id === data.id) {
                        if (!eachPre.selectedOptionId && data.completed === true) {
                            eachQuestion.isVisible = true
                        }
                        else if (eachPre.selectedOptionId === data.selectedOptionId) {
                            eachQuestion.isVisible = true
                        }
                        else {
                            eachQuestion.isVisible = false
                        }
                    }
                })
            })

        }

        return eachQuestion

    })
}

export default itemFeedback;