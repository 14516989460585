import React, { Component } from 'react';
import styles from './itemTitle.module.sass';
import CSSModules from 'react-css-modules';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import NextActivityLoader from 'commonComponents/loaders/nextActivityLoader';
import routes from 'utils/webAppRoutes/routeConstants';
import enumConstants from 'constants/enums';
import { replaceDatePlaceholderAndFormat } from 'utils/locks';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ItemTitle extends Component {

    getButtonText = () => {
        const { selectedUserItem, fetchProductLaunchData,
            selectedItem, getLabel, setFullScreen, setItemWorkflowStatus } = this.props;
        const phaseId = selectedItem.get('phaseId');
        const batchId = selectedItem.get('batchId');


        let xparams = { 'PATH': this.props.location.pathname };
        console.log('debug: params', xparams);

        if (
            selectedUserItem.get('workflowStatus') == enumConstants.get('UNOPENED')
            || selectedUserItem.get('workflowStatus') == enumConstants.get('OPENED')
        ) {
            return {
                text: `${getLabel('start_label')}`,
                clickHandler: () => {
                    setItemWorkflowStatus(enumConstants.get('STARTED'))
                    fetchProductLaunchData();
                }
            };
        } else if (selectedUserItem.get('workflowStatus') === enumConstants.get('COMPLETED')) {

            if (selectedItem.get('type') === enumConstants.get('ATTACHMENT')) {
                return {
                    text: `${getLabel('replay_content_label')}`,
                    clickHandler: () => {
                        fetchProductLaunchData();
                    }
                };
            }

            const route = routes.get('ITEM_FEEDBACK_FROM_PATH');
            const params = { 'PATH': this.props.location.pathname };
            console.log('debug: ItemTitle params', params);
            return {
                text: getLabel('continue_label'),
                clickHandler: () => updateRoute({ route, params })
            };
        }
        else if (selectedUserItem.get('workflowStatus') == enumConstants.get('STARTED')) {
            return {
                text: getLabel('continue_label'),
                clickHandler: () => {
                    setFullScreen();
                    fetchProductLaunchData();
                }
            };
        }
    }

    sendSegmentData = () => {
        const {
            selectedItem,
            activePhaseDetails,
            activeBatchDetails
        } = this.props;

        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            batchId: activeBatchDetails.get('id'),
            journeyId: activeBatchDetails.getIn(['journey', 'id']),
            journeyName: activeBatchDetails.getIn(['journey', 'name']),
            journeyInstanceId: activeBatchDetails.get('journeyInstanceId'),
            selectedItemId: selectedItem.get('id'),
            selectedItemName: selectedItem.get('name'),
            phaseId: activePhaseDetails.id
        }
        window.analytics.track(
            'Simulation Launched',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onButtonClick = (clickHandler) => {
        this.sendSegmentData()
        clickHandler();
    }

    renderButton = (buttonText, myStyles) => {

        if (this.props.isFeedbackScreen
            || (this.props.selectedItem.get('type') === enumConstants.get('PAGE'))
            || this.props.selectedItem.get('type') === enumConstants.get('QUIZ')) {
            return null;
        }

        const { selectedItem, attachmentDetails } = this.props;

        if (attachmentDetails) {
            if (!(selectedItem.getIn(['settings', 'launchInFullScreen']) === "true")) {
                return null;
            }
        }

        const isLocked = selectedItem.get('isExpired') || selectedItem.get('isLocked');
        const style = (isLocked) ? "button-holder-expired" : "button-holder"
        const lockExplanation = replaceDatePlaceholderAndFormat(true, selectedItem.get('lockExplanation'), selectedItem.get('lockDate'));

        return (
            <div styleName={style}>
                <PrimaryButton
                    text={buttonText.text}
                    clickFunction={() => this.onButtonClick(buttonText.clickHandler)}
                    showButtonShadow={this.props.showStickyHeader ? false : true}
                />
                {
                    (isLocked) ?
                        <p styleName="helper-text" className={css(myStyles.helper)}>
                            {lockExplanation}
                        </p>
                        :
                        null
                }
            </div>
        );
    }

    goToHome = () => {
        const route = routes.get('USER_HOME');;
        updateRoute({ route });
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const {
            selectedItem,
            activePhaseDetails,
            getLabel,
            isBatchLabelsAvailable,
            activeBatchDetails
        } = this.props;
        const buttonText = this.getButtonText();

        if (!activePhaseDetails || !selectedItem || !buttonText || !isBatchLabelsAvailable) {
            return (
                <div
                    className={css(myStyles.loaderContainer)}
                    styleName="product-title-container loader-container"
                >
                    <div styleName="loader-content">
                        <NextActivityLoader />
                    </div>
                </div>
            );
        }

        return (
            <div ref={this.props.divRef} className={css(myStyles.productTitleContainer)} styleName={'product-title-container'}
                style={{
                    height: this.props.showStickyHeader ? 'auto' : '159px',
                    paddingTop: this.props.showStickyHeader ? '5px' : '43px',
                    paddingBottom: this.props.showStickyHeader ? '12px' : '26px'
                }}
            >
                <div className={css(myStyles.productTitleImage)} styleName="product-title-image" ></div>
                {
                    this.props.isSimulationJourney &&
                    <div styleName="home-link-cnt">
                        <span className={css(myStyles.homeLink)} onClick={this.goToHome}>
                            {getLabel('home_label')}
                        </span>
                    </div>
                }
                {
                    this.props.isSimulationJourney ?
                        <div styleName="content">
                            <div styleName="title-action">
                                <div
                                    className={this.props.showStickyHeader ? css(myStyles.titleSticky) : css(myStyles.title)}
                                    style={{ textAlign: 'left', lineHeight: '1' }}
                                >
                                    {activeBatchDetails.getIn(['journey', 'name'])}
                                </div>
                                <div styleName="button-helper">
                                    {this.renderButton(buttonText, myStyles)}
                                </div>
                            </div>
                        </div>
                        :
                        <div styleName="content">
                            <div className={css(myStyles.subTitle)} styleName="phase-title">
                                {getLabel(activePhaseDetails.name)}
                            </div>
                            <div styleName="title-action">
                                <div
                                    className={this.props.showStickyHeader ? css(myStyles.titleSticky) : css(myStyles.title)}
                                    style={{ textAlign: 'left', lineHeight: '1' }}
                                >
                                    {getLabel(selectedItem.get('name'))}
                                </div>
                                <div styleName="button-helper">
                                    {this.renderButton(buttonText, myStyles)}
                                </div>
                            </div>
                        </div>
                }
            </div>
        );
    }
}

export default ItemTitle;
