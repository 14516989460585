import React, { Component } from 'react';
import styles from './noJourneysErrorIcon.module.sass';
import CSSModules from 'react-css-modules';

@CSSModules(styles, { allowMultiple: true })
class NoJourneysErrorIcon extends Component {

	render() {
		return (
			<div styleName="main-component">
				<svg width="100%" height="100%" viewBox="0 0 275 199" fill="none">
					<g opacity="0.6">
						<path opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M119.803 173.017C230.145 200.337 258.582 187.52 258.029 114.612C257.475 41.7049 95.267 -2.7223 50.9173 20.7443C-9.44694 52.6846 24.0198 116.928 38.5325 124.901C56.9225 135.005 94.7751 166.821 119.803 173.017Z" fill="#979EB7" />
						<rect x="64.5508" y="55.2957" width="89.5269" height="123.697" transform="rotate(-12.11 64.5508 55.2957)" fill="#BAC1D6" />
						<g opacity="0.5">
							<rect x="66.4697" y="57.0228" width="86.5012" height="119.516" transform="rotate(-12.11 66.4697 57.0228)" fill="url(#paint0_linear)" />
						</g>
						<path d="M67.7305 57.6521L150.153 39.9671L174.673 154.242L92.2497 171.927L67.7305 57.6521Z" fill="#FAFAFF" />
						<path d="M87.542 48.2191L128.431 39.4458L130.556 49.3506L89.6672 58.1239L87.542 48.2191Z" fill="url(#paint1_linear)" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M101.45 41.2362C100.756 38.0056 102.753 34.8365 105.91 34.1584C109.067 33.4803 112.181 35.5535 112.875 38.7841C113.57 42.0147 111.566 45.1838 108.416 45.8619C105.265 46.54 102.145 44.4668 101.45 41.2362ZM104.283 42.0506C105.101 43.1907 106.527 43.723 107.891 43.3976C108.777 43.1974 109.546 42.6526 110.029 41.8837C110.512 41.1148 110.669 40.1851 110.464 39.3004C110.165 37.9299 109.066 36.8766 107.684 36.6354C106.302 36.3942 104.911 37.013 104.165 38.201C103.419 39.3891 103.466 40.9105 104.283 42.0506Z" fill="url(#paint2_linear)" />
						<path d="M87.8818 47.7019L127.91 39.1134L129.941 48.5815L89.9134 57.17L87.8818 47.7019Z" fill="#979EB7" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M101.516 40.9531C100.853 37.8635 102.82 34.8214 105.91 34.1583C107.394 33.8398 108.943 34.1239 110.218 34.948C111.492 35.7721 112.387 37.0687 112.705 38.5525C113.368 41.6421 111.4 44.6841 108.311 45.3469C105.221 46.0098 102.179 44.0427 101.516 40.9531ZM104.392 41.6343C105.151 42.7337 106.497 43.2678 107.803 42.9876C109.586 42.605 110.721 40.8497 110.339 39.0666C110.059 37.7606 109.02 36.7518 107.706 36.5107C106.392 36.2696 105.063 36.8437 104.337 37.9653C103.612 39.0868 103.633 40.535 104.392 41.6343Z" fill="#979EB7" />
						<rect x="103.222" y="51.4894" width="89.5269" height="123.697" fill="#BAC1D6" />
						<g opacity="0.5">
							<rect x="104.736" y="53.5811" width="86.5012" height="119.516" fill="url(#paint3_linear)" />
						</g>
						<rect x="105.836" y="54.4619" width="84.2989" height="116.875" fill="#FAFAFF" />
						<path d="M127.187 49.3937H169.006V59.5239H127.187V49.3937Z" fill="url(#paint4_linear)" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M142.249 45.4912C142.249 42.1848 144.867 39.5052 148.096 39.5052C151.324 39.5052 153.942 42.1848 153.942 45.4912C153.942 48.7976 151.324 51.4771 148.096 51.4771C144.867 51.4771 142.249 48.7976 142.249 45.4912ZM144.847 46.8708C145.407 48.1585 146.692 48.9785 148.096 48.9452V48.9288C149.974 48.9063 151.481 47.3699 151.468 45.4913C151.467 44.087 150.617 42.8227 149.316 42.2932C148.015 41.7638 146.523 42.0746 145.542 43.0795C144.561 44.0844 144.286 45.5832 144.847 46.8708Z" fill="url(#paint5_linear)" />
						<rect x="127.627" y="48.9594" width="40.9388" height="9.68362" fill="#979EB7" />
						<path fill-rule="evenodd" clip-rule="evenodd" d="M142.81 43.0373C143.695 40.8993 145.782 39.5052 148.096 39.5052C151.256 39.5052 153.818 42.0669 153.818 45.2269C153.818 47.5411 152.424 49.6275 150.285 50.5131C148.147 51.3987 145.686 50.9091 144.05 49.2728C142.414 47.6364 141.924 45.1754 142.81 43.0373ZM144.798 45.2271C144.798 47.0493 146.274 48.5271 148.096 48.5294V48.5192C148.972 48.5203 149.813 48.1731 150.433 47.5541C151.053 46.9351 151.402 46.0951 151.402 45.2189C151.398 43.3967 149.918 41.9225 148.096 41.9248C146.274 41.9271 144.798 43.4049 144.798 45.2271Z" fill="#979EB7" />
						<g opacity="0.5">
							<path d="M53.5205 178.53H54.2908V182.9H53.5205V178.53Z" fill="#BAC1D6" />
							<path d="M56.0908 180.331V181.101H51.7212V180.331H56.0908Z" fill="#BAC1D6" />
						</g>
						<g opacity="0.5">
							<path d="M142.883 27.5394H143.653V31.909H142.883V27.5394Z" fill="#BAC1D6" />
							<path d="M145.453 29.338V30.1083H141.084V29.338H145.453Z" fill="#BAC1D6" />
						</g>
						<path opacity="0.5" d="M208.353 185.804C207.913 185.613 207.687 185.122 207.826 184.663C207.837 184.629 207.844 184.594 207.847 184.559C207.86 184.351 207.734 184.159 207.538 184.086C207.342 184.014 207.122 184.079 206.997 184.245C206.975 184.274 206.958 184.305 206.944 184.338C206.752 184.777 206.261 185.004 205.802 184.864C205.769 184.853 205.734 184.846 205.698 184.844C205.49 184.831 205.298 184.956 205.226 185.152C205.153 185.348 205.218 185.568 205.385 185.694C205.413 185.715 205.444 185.733 205.477 185.747C205.917 185.938 206.143 186.429 206.003 186.888C205.992 186.922 205.985 186.957 205.983 186.993C205.97 187.201 206.096 187.393 206.291 187.465C206.487 187.537 206.707 187.473 206.833 187.306C206.854 187.277 206.872 187.247 206.886 187.214C207.078 186.774 207.568 186.548 208.027 186.687C208.061 186.698 208.096 186.705 208.132 186.708C208.34 186.72 208.532 186.595 208.604 186.399C208.676 186.203 208.612 185.983 208.445 185.858C208.417 185.836 208.386 185.818 208.353 185.804Z" fill="#DFE2EA" />
						<path opacity="0.5" d="M78.3891 143.796C77.9493 143.605 77.7229 143.114 77.8626 142.655C77.8735 142.621 77.8804 142.586 77.8831 142.551C77.8956 142.342 77.7702 142.15 77.5744 142.078C77.3786 142.006 77.1586 142.07 77.0329 142.237C77.0116 142.266 76.9938 142.297 76.9797 142.329C76.7882 142.769 76.2975 142.996 75.8386 142.856C75.8047 142.845 75.7696 142.838 75.7341 142.835C75.5258 142.823 75.3339 142.948 75.2617 143.144C75.1895 143.34 75.2541 143.56 75.4207 143.685C75.4492 143.707 75.4802 143.725 75.5129 143.739C75.9527 143.93 76.1791 144.421 76.0394 144.88C76.0285 144.914 76.0216 144.949 76.0189 144.984C76.0063 145.193 76.1317 145.385 76.3276 145.457C76.5234 145.529 76.7434 145.464 76.869 145.298C76.8903 145.269 76.9082 145.238 76.9223 145.205C77.1138 144.766 77.6045 144.539 78.0634 144.679C78.0973 144.69 78.1323 144.697 78.1678 144.699C78.3762 144.712 78.5681 144.587 78.6403 144.391C78.7125 144.195 78.6479 143.975 78.4813 143.849C78.4527 143.828 78.4218 143.81 78.3891 143.796Z" fill="#DFE2EA" />
						<path opacity="0.5" d="M186.638 41.5065C186.198 41.3151 185.972 40.8243 186.112 40.3655C186.123 40.3316 186.129 40.2965 186.132 40.261C186.145 40.0527 186.019 39.8607 185.823 39.7885C185.628 39.7163 185.408 39.7809 185.282 39.9476C185.261 39.9761 185.243 40.007 185.229 40.0397C185.037 40.4796 184.546 40.706 184.088 40.5662C184.054 40.5553 184.019 40.5485 183.983 40.5457C183.775 40.5332 183.583 40.6586 183.511 40.8544C183.438 41.0502 183.503 41.2702 183.67 41.3959C183.698 41.4172 183.729 41.4351 183.762 41.4492C184.202 41.6406 184.428 42.1314 184.288 42.5902C184.278 42.6241 184.271 42.6592 184.268 42.6947C184.255 42.903 184.381 43.095 184.577 43.1672C184.772 43.2394 184.992 43.1747 185.118 43.0081C185.139 42.9796 185.157 42.9487 185.171 42.9159C185.363 42.4761 185.854 42.2497 186.312 42.3895C186.346 42.4004 186.381 42.4072 186.417 42.4099C186.625 42.4225 186.817 42.2971 186.889 42.1013C186.962 41.9055 186.897 41.6855 186.73 41.5598C186.702 41.5385 186.671 41.5206 186.638 41.5065Z" fill="#DFE2EA" />
						<path opacity="0.5" d="M216.867 93.5036C217.719 93.5036 218.409 92.813 218.409 91.961C218.409 91.1091 217.719 90.4185 216.867 90.4185C216.015 90.4185 215.324 91.1091 215.324 91.961C215.324 92.813 216.015 93.5036 216.867 93.5036Z" fill="#DFE2EA" />
						<path opacity="0.5" d="M97.6578 182.039C98.5098 182.039 99.2004 181.349 99.2004 180.497C99.2004 179.645 98.5098 178.954 97.6578 178.954C96.8059 178.954 96.1152 179.645 96.1152 180.497C96.1152 181.349 96.8059 182.039 97.6578 182.039Z" fill="#DFE2EA" />
						<path opacity="0.5" d="M201.025 126.846C201.877 126.846 202.568 126.156 202.568 125.304C202.568 124.452 201.877 123.761 201.025 123.761C200.173 123.761 199.482 124.452 199.482 125.304C199.482 126.156 200.173 126.846 201.025 126.846Z" fill="#BAC1D6" />
						<path opacity="0.5" d="M68.2428 48.8201C69.0947 48.8201 69.7854 48.1295 69.7854 47.2776C69.7854 46.4256 69.0947 45.735 68.2428 45.735C67.3908 45.735 66.7002 46.4256 66.7002 47.2776C66.7002 48.1295 67.3908 48.8201 68.2428 48.8201Z" fill="#DFE2EA" />
						<path opacity="0.5" d="M51.9342 90.8999C52.7861 90.8999 53.4768 90.2092 53.4768 89.3573C53.4768 88.5053 52.7861 87.8147 51.9342 87.8147C51.0822 87.8147 50.3916 88.5053 50.3916 89.3573C50.3916 90.2092 51.0822 90.8999 51.9342 90.8999Z" fill="#BAC1D6" />
						<path opacity="0.2" d="M205.556 69.9937C201.958 68.0808 194.69 69.977 192.538 72.4192C192.563 72.0224 192.483 71.6885 192.369 71.469C193.272 70.546 195.575 69.9625 196.433 68.9642C196.526 68.8555 196.333 68.6035 196.234 68.7138C195.183 69.8799 193.234 70.3704 192.326 71.3945C192.277 71.3167 192.223 71.2588 192.169 71.2247L192.17 71.224C192.167 71.2222 192.163 71.2208 192.16 71.2191C192.157 71.2173 192.154 71.2154 192.15 71.2137L192.15 71.2144C192.092 71.1891 192.014 71.1768 191.922 71.1794C192.263 69.8543 191.58 67.964 191.959 66.4408C191.995 66.2963 191.678 66.2777 191.64 66.416C191.292 67.6849 192.096 69.9211 191.836 71.1855C191.59 71.2138 191.269 71.3343 190.954 71.5767C191.776 68.4278 189.283 61.3413 185.685 59.4284C184.494 58.7949 183.857 58.721 183.516 59.3626C182.529 61.2187 182.522 68.3317 183.271 69.4903C183.581 69.9699 184.101 70.3266 184.669 70.5013C183.758 70.6869 182.871 71.2445 182.285 72.3463C180.878 74.9926 181.991 77.3339 183.796 78.294C185.748 79.3315 187.556 78.1342 189.768 73.9738C189.706 74.5874 189.655 75.2362 189.167 76.1532C188.324 77.7402 186.917 79.5748 187.509 79.9436L187.509 79.9447C187.512 79.9464 187.515 79.9475 187.519 79.9491C187.522 79.9511 187.525 79.9532 187.528 79.955L187.529 79.9537C188.166 80.2389 188.9 78.0462 189.744 76.4595C190.232 75.5424 190.741 75.1373 191.215 74.743C189.003 78.9036 189.021 81.0719 190.973 82.1094C192.778 83.0694 195.341 82.6827 196.748 80.0363C197.334 78.9348 197.301 77.8875 196.945 77.0279C197.407 77.4014 197.994 77.633 198.565 77.6218C199.944 77.5946 205.837 73.6113 206.824 71.7552C207.165 71.1136 206.747 70.6273 205.556 69.9937Z" fill="black" />
						<path d="M203.841 69.9937C200.243 68.0808 192.975 69.977 190.823 72.4192C190.848 72.0224 190.769 71.6885 190.654 71.469C191.557 70.546 193.861 69.9625 194.718 68.9642C194.811 68.8555 194.619 68.6035 194.519 68.7138C193.468 69.8799 191.519 70.3704 190.611 71.3945C190.562 71.3167 190.508 71.2588 190.455 71.2247C190.377 71.1891 190.299 71.1768 190.207 71.1794C190.548 69.8543 189.865 67.964 190.244 66.4408C190.28 66.2963 189.963 66.2777 189.925 66.416C189.577 67.6849 190.381 69.9211 190.121 71.1855C189.875 71.2138 189.554 71.3343 189.239 71.5767C190.061 68.4278 187.568 61.3413 183.971 59.4284C182.779 58.7949 182.142 58.721 181.801 59.3626C180.814 61.2187 180.807 68.3317 181.556 69.4903C181.866 69.9699 182.386 70.3266 182.955 70.5013C182.043 70.6869 181.156 71.2445 180.57 72.3463C179.163 74.9926 180.276 77.3339 182.081 78.294C184.033 79.3315 185.841 78.1342 188.053 73.9738C187.991 74.5874 187.94 75.2362 187.453 76.1532C186.609 77.7402 185.202 79.5748 185.794 79.9436C186.451 80.2389 187.186 78.0462 188.029 76.4595C188.517 75.5424 189.026 75.1373 189.5 74.743C187.288 78.9036 187.306 81.0719 189.258 82.1094C191.063 83.0694 193.627 82.6827 195.034 80.0363C195.619 78.9348 195.586 77.8875 195.23 77.0279C195.693 77.4014 196.279 77.633 196.85 77.6218C198.229 77.5946 204.122 73.6113 205.109 71.7552C205.45 71.1136 205.032 70.6273 203.841 69.9937Z" fill="#B45181" />
						<path d="M190.823 72.4191C190.848 72.0223 190.769 71.6884 190.654 71.4689C191.557 70.546 193.861 69.9625 194.718 68.9642C194.811 68.8555 194.619 68.6034 194.519 68.7138C193.468 69.8799 191.519 70.3704 190.611 71.3945C190.562 71.3167 190.508 71.2587 190.455 71.2247C190.377 71.189 190.299 71.1767 190.207 71.1793C190.548 69.8543 189.865 67.964 190.244 66.4407C190.28 66.2962 189.963 66.2777 189.925 66.416C189.577 67.6848 190.381 69.9211 190.121 71.1855C189.875 71.2138 189.554 71.3342 189.239 71.5767C188.437 72.9832 188.437 72.9832 188.053 73.9737C187.991 74.5874 187.94 75.2361 187.453 76.1532C186.609 77.7402 185.202 79.5748 185.794 79.9436C186.451 80.2389 187.186 78.0462 188.029 76.4595C188.517 75.5424 189.026 75.1373 189.5 74.743C190.152 73.8406 190.152 73.8406 190.823 72.4191Z" fill="#565171" />
					</g>
					<defs>
						<linearGradient id="paint0_linear" x1="152.97" y1="176.539" x2="152.97" y2="57.0228" gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" stop-opacity="0.25" />
							<stop offset="0.54" stop-color="#808080" stop-opacity="0.12" />
							<stop offset="1" stop-color="#808080" stop-opacity="0.1" />
						</linearGradient>
						<linearGradient id="paint1_linear" x1="130.556" y1="49.3506" x2="128.431" y2="39.4458" gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" stop-opacity="0.25" />
							<stop offset="0.54" stop-color="#808080" stop-opacity="0.12" />
							<stop offset="1" stop-color="#808080" stop-opacity="0.1" />
						</linearGradient>
						<linearGradient id="paint2_linear" x1="114.146" y1="44.573" x2="111.528" y2="32.8937" gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" stop-opacity="0.25" />
							<stop offset="0.54" stop-color="#808080" stop-opacity="0.12" />
							<stop offset="1" stop-color="#808080" stop-opacity="0.1" />
						</linearGradient>
						<linearGradient id="paint3_linear" x1="191.236" y1="173.097" x2="191.236" y2="53.5811" gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" stop-opacity="0.25" />
							<stop offset="0.54" stop-color="#808080" stop-opacity="0.12" />
							<stop offset="1" stop-color="#808080" stop-opacity="0.1" />
						</linearGradient>
						<linearGradient id="paint4_linear" x1="169.006" y1="59.5239" x2="169.006" y2="49.3937" gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" stop-opacity="0.25" />
							<stop offset="0.54" stop-color="#808080" stop-opacity="0.12" />
							<stop offset="1" stop-color="#808080" stop-opacity="0.1" />
						</linearGradient>
						<linearGradient id="paint5_linear" x1="153.942" y1="51.4669" x2="153.942" y2="39.495" gradientUnits="userSpaceOnUse">
							<stop stop-color="#808080" stop-opacity="0.25" />
							<stop offset="0.54" stop-color="#808080" stop-opacity="0.12" />
							<stop offset="1" stop-color="#808080" stop-opacity="0.1" />
						</linearGradient>
					</defs>
				</svg>
			</div>
		);
	}
}



export default NoJourneysErrorIcon;
