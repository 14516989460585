import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const colors = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		thankYouNote: {
			...myTypography.button,
			fontWeight: 'normal',
			lineHeight: 'normal',
			color: '#787878',
		}
	});
}

export default getSkin;