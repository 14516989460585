import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		supportBtn: {
			...myTypography.body2,
			fontSize: '14px',
			backgroundColor: skin.get('white'),
			color: skin.get('secondaryColor'),
			border: `1px solid ${skin.get('secondaryColor')}`,
			boxShadow: '-2px 1px 4px 0px rgba(196, 83, 127, 0.18)',
			textTransform: 'capitalize',
			fontWeight: 600,
			lineHeight: 'normal'
		}
	});
}

export default getSkin;