/**
 * @author: ujjwal
 * @since: Thu Sep 27 2018 09:20:31 GMT+0530 (IST)
 * @file: development.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import {
    getFormattedPayloadDataMultipleLevel,
    getFormattedDevelopmentMeasuresData,
    getHierarchicalFormattedMeasuresData
} from 'utils/analytics/transformationFunctions';



const initialState = Immutable.fromJS({
    
    developmentScoreData: [],
    isDevelopmentScoreFetched: false,

    developmentProgressData: [],
    isDevelopmentProgressFetched: false,

    developmentTimeSpentData: [],
    isDevelopmentTimeSpentFetched: false,

    developmentMeasuresData: [],
    isDevelopmentMeasuresFetched: false,

});

const development = (state = initialState, action = {}) => {
    switch (action.type) {

        /*Development score section data */

        case actionConsts.get('DEVELOPMENT_SCORE_DATA'):

            const developmentScoreData = getFormattedPayloadDataMultipleLevel(action.payload)

            return state.set(
                'developmentScoreData',
                Immutable.fromJS(developmentScoreData)
            ).set(
                'isDevelopmentScoreFetched',
                true
            );
        
        /*Development score section data */

        case actionConsts.get('DEVELOPMENT_PROGRESS_DATA'):
            const developmentProgressData = getFormattedPayloadDataMultipleLevel(action.payload)
            
            return state.set(
                'developmentProgressData',
                Immutable.fromJS(developmentProgressData)
            ).set(
                'isDevelopmentProgressFetched',
                true
            );
        
        /*Development score section data */

        case actionConsts.get('DEVELOPMENT_TIME_SPENT_DATA'):
            
            const developmentTimeSpentData = getFormattedPayloadDataMultipleLevel(action.payload)
            
            return state.set(
                'developmentTimeSpentData',
                Immutable.fromJS(developmentTimeSpentData)
            ).set(
                'isDevelopmentTimeSpentFetched',
                true
            );
        
        /*Development measures section data */

        case actionConsts.get('DEVELOPMENT_MEASURES_DATA'):

            const developmentMeasuresData = getHierarchicalFormattedMeasuresData(action.payload);
            
            return state.set(
                'developmentMeasuresData',
                Immutable.fromJS(developmentMeasuresData)
            ).set(
                'isDevelopmentMeasuresFetched',
                true
            );

        default:
            return state;
    }
};

export default development;