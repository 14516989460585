const getOrderOfphase = (phaseId, phasesList) => {
	const filteredPhase = phasesList.filter(
		phase => phase.get('id') === phaseId
	).get(0);
	if (filteredPhase) {
		return filteredPhase.get('order');
	}
	return -100;
}

const getNextPhaseId = (phaseId, phasesList) => {
	const orderOfCurrentPhase = getOrderOfphase(phaseId, phasesList);
	const orderOfNextPhase = orderOfCurrentPhase + 1;
	const nextPhase = phasesList.filter(
		phase => phase.get('order') === orderOfNextPhase
	).get(0);
	if (nextPhase) {
		return nextPhase.get('id');
	}
	return null;
}

const getNextPhase = (phaseId, phasesList) => { 
	const orderOfCurrentPhase = getOrderOfphase(phaseId, phasesList);
	const orderOfNextPhase = orderOfCurrentPhase + 1;
	const nextPhase = phasesList.filter(
		phase => phase.get('order') === orderOfNextPhase
	).get(0);
	return nextPhase;
}

const getPrevPhaseId = (phaseId, phasesList) => {
    const orderOfCurrentPhase = getOrderOfphase(phaseId, phasesList);
	const orderOfPrevPhase = orderOfCurrentPhase - 1;
	const prevPhase = phasesList.filter(
		phase => phase.get('order') === orderOfPrevPhase
	).get(0);
	if (prevPhase) {
		return prevPhase.get('id');
	}
	return null;
}

export {
	getOrderOfphase,
	getNextPhaseId,
	getNextPhase,
	getPrevPhaseId
}