import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		boxBackground: {
			backgroundSize: "cover",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "center top"

		},
		boxGradient: {
			backgroundImage: `linear-gradient(to bottom, ${hexToRgbA(skin.get('primaryColor'), 0.5)}, #000824)`,
		},
		userGreetings: {
			color: hexToRgbA(skin.get('white'), 0.85),
			...myTypography.h6
		},
		userGreetingsLine: {
			backgroundColor: skin.get('greyColor4'),
			opacity: 0.25
		},
		getStarted: {
			...myTypography.button,
			color: '#C4C9DA'
		},
		journeyTitle: {
			...myTypography.h3,
			color: skin.get('white'),
			lineHeight: '1',
			'@media screen and (max-width: 480px)': {
				fontSize: '28px'
			}
		},
		journeySubTitle: {
			...myTypography.body1,
			color: skin.get('greyColor4')
		},
		rating: {
			...myTypography.overline,
			color: '#c4c9da'
		},
		journeyDesc: {
			...myTypography.body1,
			lineHeight: 1.56,
			color: skin.get('greyColor4')
		},
		durationStudyLine: {
			backgroundColor: 'rgba(196, 201, 218, 0.25)'
		},
		title: {
			...myTypography.overline,
			fontSize: '16px',
			// lineHeight: 2.4,
			fontWeight: 'normal',
			fontStyle: 'normal',
			letterSpacing: 'normal',
			color: skin.get('greyColor4')
		},
		value: {
			...myTypography.overline,
			fontWeight: 600,
			color: skin.get('white')
		},
		termsConditions: {
			...myTypography.caption,
			fontStyle: 'italic',
			fontWeight: 'normal',
			color: skin.get('greyColor5'),
			cursor: 'pointer'
		},
		watchVideo: {
			...myTypography.button,
			borderRadius: '4px',
			border: `1px solid ${skin.get('secondaryColor')}`,
			color: skin.get('secondaryColor'),
			textTransform: 'uppercase',
			cursor: 'pointer',
			':hover': {
				color: skin.get('white'),
				backgroundColor: skin.get('secondaryColor')
			}
		},
		acceptInvitation: {
			...myTypography.button,
			color: skin.get('white'),
			borderRadius: '4px',
			border: `1px solid ${skin.get('primaryColor')}`,
			backgroundColor: skin.get('primaryColor'),
			textTransform: 'uppercase',
			cursor: 'pointer',
			':hover': {
				border: `1px solid ${skin.get('primaryVariantColor')}`,
				backgroundColor: skin.get('primaryVariantColor')
			}
		},
		acceptInvitationDisabled: {
			...myTypography.button,
			color: skin.get('white'),
			borderRadius: '4px',
			border: `1px solid ${skin.get('greyColor4')}`,
			backgroundColor: skin.get('greyColor4'),
			textTransform: 'uppercase',
			cursor: 'pointer',
			':hover': {
				border: `1px solid ${skin.get('greyColor4')}`,
				backgroundColor: skin.get('greyColor4')
			}
		},
		lockExplanation: {
			...myTypography.caption,
			fontWeight: 'normal',
			color: skin.get('greyColor5')
		},
		loaderBackground: {
			backgroundColor: skin.get('greyColor6')
		}
	});
}

export default getSkin;