import FetchItemFeedback from 'actions/serverCalls/itemFeedback/FetchItemFeedback';
import FetchUserItemFeedback from 'actions/serverCalls/itemFeedback/FetchUserItemFeedback';
import SubmitAnswer from 'actions/serverCalls/itemFeedback/SubmitAnswer';
import SubmitFeedback from 'actions/serverCalls/itemFeedback/SubmitFeedback';
import { isMobile } from 'react-device-detect';
import environment from 'utils/environment';
import { filterObjectFromArray } from 'utils/objectUtils';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

const getDeviceType = () => {
    let device = "WEBBROWSER"
    if (isMobile) {
        device = 'WEBMOBILE';
    }
    return device;
}

export const fetchItemFeedbackThunk = (dispatch, getState, itemId, scenario) => {
    let langID = 'en_US';

    const batchesList = getState().get('batches').get('batchesList');
    const batchId = getState().get('userState').get('currentBatchId');
    const activeBatchDetails = filterObjectFromArray(batchesList, 'id', batchId);

    if (activeBatchDetails && activeBatchDetails.size) {
        langID = activeBatchDetails.get('journey').get('lang');
    }

    const orgLang = getState().getIn(['userOrganization', 'userOrganization', 'lang']) ;

    if(getState().getIn(['userOrganization', 'userOrganization', 'lang']) !== 'en_US'){
        langID = orgLang; /*if org lang other than English (US), override */
    }

    const apiInfoObj = {
        queryParameters: {
            device: getDeviceType(),
            scenario: scenario,
            lang: langID,
            toolId: itemId
        }
    };

    return FetchItemFeedback.call(apiInfoObj);
};

export const fetchUserItemFeedbackThunk = (dispatch, getState, batchId, entityId, entityType, scenario) => {
    let langID = 'en_US';

    const batchesList = getState().get('batches').get('batchesList');
    const activeBatchDetails = filterObjectFromArray(batchesList, 'id', batchId);
    if (activeBatchDetails > 0) {
        langID = activeBatchDetails.get('journey').get('lang');
    }

    const apiInfoObj = {
        queryParameters: {
            device: getDeviceType(),
            scenario: scenario,
            entityType: entityType,
            entityId: entityId,
            lang: langID,
            groupId: batchId,
            serviceName: 'ADPlatform',
            env: environment.REACT_APP_FEEDBACK_ENV
        }
    };
    return FetchUserItemFeedback.call(apiInfoObj)
};

export const fetchItemFeedback = (itemId, scenario) => (dispatch, getState) => fetchItemFeedbackThunk(dispatch, getState, itemId, scenario);

export const fetchUserItemFeedback = (batchId, entityId, entityType, scenario) => (dispatch, getState) => fetchUserItemFeedbackThunk(dispatch, getState, batchId, entityId, entityType, scenario);


export const submitAnswer = (payload, questionId, entityId, entityType, scenario, batchId = null) => (dispatch, getState) => {
    let feedbackScenario = scenario;
    let currentItem = getState().getIn(['items', 'itemsList']).find(item => item.get('id') === +entityId)
    if (
        currentItem
        && currentItem.getIn(['settings', 'feedbackScenarioId'])
    ) {
        feedbackScenario = currentItem.getIn(['settings', 'feedbackScenarioId'])
    }

    const apiInfoObj = {
        queryParameters: {
            device: getDeviceType(),
            scenario: feedbackScenario,
            entityType: entityType,
            entityId: entityId,
            groupId: batchId || getState().get('userState').get('currentBatchId'),
            serviceName: 'ADPlatform',
            env: environment.REACT_APP_FEEDBACK_ENV
        },
        body: payload,
        questionId
    }

    return SubmitAnswer.call(apiInfoObj).then(() => {
        if (
            getState().getIn(['itemFeedback', 'questionsList']).size === 1
            && getState().getIn(['itemFeedback', 'questionsList', 0, 'completed'])
            && !getState().getIn(['itemFeedback', 'isFeedbackCompleted'])
        )
            dispatch(submitFeedback({ isSubmitted: true }, entityId, entityType, feedbackScenario));
    });
};


export const submitFeedback = (payload, entityId, entityType, scenario, showSuccessToast = false, batchId = null, successCallback = () => {}) => (dispatch, getState) => {
    let feedbackScenario = scenario;
    let currentItem = getState().getIn(['items', 'itemsList']).find(item => item.get('id') === +entityId)
    if (
        currentItem
        && currentItem.getIn(['settings', 'feedbackScenarioId'])
    ) {
        feedbackScenario = currentItem.getIn(['settings', 'feedbackScenarioId'])
    }

    const apiInfoObj = {
        queryParameters: {
            device: getDeviceType(),
            scenario: feedbackScenario,
            entityType: entityType,
            entityId: entityId,
            groupId: batchId || getState().get('userState').get('currentBatchId'),
            serviceName: 'ADPlatform',
            env: environment.REACT_APP_FEEDBACK_ENV
        },
        body: payload
    }

    return SubmitFeedback.call(apiInfoObj).then(() => {
        if(showSuccessToast) {
            dispatch(addToast({
                id: toastConstants.get('ID_SUCCESS'),
                type: toastConstants.get('TYPE_SUCCESS'),
                heading: 'Done!',
                message: 'Your feedback has been saved.',
                isDisplayed: false,
                autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME_SHORT')
            }));
        }
        successCallback();
    });

};


export const fetchEntityFeedback = (batchId, entityId, entityType, scenario, langId='en_US') => (dispatch, getState) => {

    const orgLang = getState().getIn(['userOrganization', 'userOrganization', 'lang']) ;

    if(getState().getIn(['userOrganization', 'userOrganization', 'lang']) !== 'en_US'){
        langId = orgLang; /*if org lang other than English (US), override */
    }

    const apiInfoObj = {
        queryParameters: {
            device: getDeviceType(),
            scenario: scenario,
            lang: langId
        }
    };
    FetchItemFeedback.call(apiInfoObj).then(() => {
        dispatch(fetchUserItemFeedback(batchId, entityId, entityType, scenario));
    })
}