import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './tagsList.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite/no-important';
import Tag from './tag';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TagsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOptionIds:
                (props.question.get('selectedOptionIds'))
                    ?
                    props.question.get('selectedOptionIds').toJS()
                    :
                    []
        }
    }


    submitAnswer = () => {
        const payload = {
            selectedOptionIds: this.state.selectedOptionIds
        };

        this.props.submitAnswer(payload, this.props.question.get('id'));

    }

    removeOption = (optionId) => {
        const { selectedOptionIds } = this.state;
        let newIds = [];
        selectedOptionIds.map((eachId) => {
            if (eachId != optionId) {
                newIds.push(eachId)
            }
        })
        this.setState({
            selectedOptionIds: newIds
        }, this.submitAnswer)

        // this.submitAnswer()

    }

    addOption = (optionId) => {
        let selectedOptionIds = this.state.selectedOptionIds;
        selectedOptionIds.push(optionId);
        this.setState({
            selectedOptionIds
        }, this.submitAnswer)

    }


    renderOptions = () => {
        const { question, submitAnswer } = this.props;
        const { selectedOptionIds } = this.state;

        let options = this.props.question.get('options').toJS();
        options = options.map((option, index) => {
            let isSelected = false;

            selectedOptionIds.map((eachId) => {
                if (eachId == option.id) {
                    isSelected = true
                }
            })

            if (option.id)
                return <Tag key={index}
                    option={option}
                    isSelected={isSelected}
                    removeOption={this.removeOption}
                    addOption={this.addOption}
                    questionId={question.get('id')}
                    showTick = {question.get('showTick')}
                    submitAnswer={submitAnswer} />
        });

        return options;

    }


    render() {
        const myStyles = getSkin(this.props.skinGuide);

        return (
            <div styleName="tags-list-container">
                {this.renderOptions()}
            </div>
        );
    }
}

export default TagsList;
