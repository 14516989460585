import React, { Component } from 'react';
import styles from './backArrow.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ShareLinkIcon extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
            <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">
                <g fill="none" fillRule="evenodd" stroke="#223b92" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
                    <path d="M13.835 35.178L35.18 13.833M15.838 24.55a9.091 9.091 0 0 0-6.947 2.643l-4.574 4.574a9.148 9.148 0 0 0 12.937 12.937l4.574-4.574a9.091 9.091 0 0 0 2.643-6.946M32.327 25.828a9.638 9.638 0 0 0 7.363-2.801l4.848-4.848A9.696 9.696 0 1 0 30.825 4.466l-4.848 4.848a9.643 9.643 0 0 0-2.79 7.363"/>
                </g>
            </svg>
		);
	}
}



export default ShareLinkIcon;
