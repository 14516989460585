import React, { Component } from 'react';
import applySkin from 'utils/SkinProvider';
@applySkin
class PhaseCompleted extends Component {

	render() {
		const skinHex = this.props.skinGuide.get('skinHex');
		return (
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 20.999C16.9706 20.999 21 16.9696 21 11.999C21 7.02846 16.9706 2.99902 12 2.99902C7.02944 2.99902 3 7.02846 3 11.999C3 16.9696 7.02944 20.999 12 20.999Z" fill={skinHex.get('darkSkyBlue')} stroke={skinHex.get('darkSkyBlue')} stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7.20001 12.9782L9.16001 15.7598C9.31086 15.9851 9.56128 16.1237 9.83231 16.1318C10.1033 16.1399 10.3616 16.0165 10.5256 15.8006L16.8 7.86218" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

		);
	}
}
export default PhaseCompleted;
