import store from 'store/store'
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import { filterObjectFromArray } from 'utils/objectUtils';
import { setInvalid } from 'actions/invalid/actionCreators';
import { getUserActiveBatchDetails } from 'utils/batchUtils';

export const setInvalidRoute = () => {
    store.dispatch(setInvalid(true))
}

export const scrollToTop = () => {
    if (
        store.getState().get('userState').size > 0
        && store.getState().get('userState').get('completeContainerRef').current
        && typeof store.getState().get('userState').get('completeContainerRef').current != 'undefined'
    ) {
        store.getState().get('userState').get('completeContainerRef').current.scrollTop = 0;
    }
    return;
}

export const validateRoute = (routeObj) => {
    let validationSuccess = true;
    const params = routeObj.params;
    if (params.length > 0) {
        for (let i = 0; i < params.length; i++) {
            for (var key in params[i]) {
                if (params[i].hasOwnProperty(key)) {
                    validationSuccess = validateParams(routeObj.route, key, params[i][key]);
                }
            }
        }
    }
    return validationSuccess;

};

const getLockFlagForItemsAndPhases = () => {
    const itemsList = store.getState().get('items');
    const phasesList = store.getState().get('phases');
    const userActiveBatchDetails = getUserActiveBatchDetails(store.getState().get('batches'));
    const { phases, items } = setAvailabilityFlags(phasesList, itemsList, userActiveBatchDetails);
    return {
        phases, items
    };

}

export const validateParams = (route, key, value) => {

    if (route.indexOf("modules") > 1) {
        return true;
    }
    if (value <= 0)
        return false

    const isAvailableData = getLockFlagForItemsAndPhases();

    switch (key) {
        case 'BATCH_ID':
            const batchesList = store.getState().get('batches').get('userBatchesList');
            const isBatchesListFetched = store.getState().get('batches').get('isBatchesListFetched');
            if (isBatchesListFetched) {
                let isBatchPresent = false;
                batchesList.map((batch) => {
                    if (batch.get('batchId') === value) {
                        isBatchPresent = true;
                    }
                })
                return isBatchPresent;
            }
            return true;

        case 'ITEM_ID':
            const itemsListAvailabilityFlag = isAvailableData.items;
            const thisItem = filterObjectFromArray(itemsListAvailabilityFlag.get('itemsList'), 'id', value);

            const itemsList = store.getState().get('items').get('userItemsList');
            let isItemPresent = false;
            let isItemAvailable = false;

            itemsList.map((item) => {
                if (item.get('id') === value) {
                    isItemPresent = true
                    isItemAvailable = !thisItem.get('isLocked');
                }
            })

            return (isItemPresent && isItemAvailable);

        case 'PHASE_ID':
            const phasesListAvailabilityFlag = isAvailableData.phases;
            const thisPhase = filterObjectFromArray(phasesListAvailabilityFlag.get('phasesList'), 'id', value);

            const phasesList = store.getState().get('phases').get('userPhasesList');
            let isPhaseAvailable = false;
            let isPhasePresent = false;

            phasesList.map((phase) => {
                if (phase.get('id') === value) {
                    isPhasePresent = true;
                    isPhaseAvailable = !thisPhase.get('isLocked');
                }
            })

            return (isPhasePresent && isPhaseAvailable);

        // case (route === 'ITEM_FEEDBACK' && key === 'ITEM_ID'):
        // 	return true;


        default:
            return true;
    }

}
