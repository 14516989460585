import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		title:{
			...myTypography.h5,
			fontFamily: skin.get('fontFamily').get('bodyFamily2'),
			color: skinHex.get('greyColor2'),
			fontWeight: 300
		},
		body: {
			...myTypography.body1,
			color: '#000000',
			lineHeight: 1.56,
			'@media screen and (max-width: 480px)': {
				fontSize: '16px'
			}
		}
	});
}

export default getSkin;