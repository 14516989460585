import React, { Component } from 'react';
import CSSModules from 'react-css-modules';

import AdditionalInformation from 'components/moduleItems/additionalInformation';
import styles from './externalUrl.module.sass';

//@applySkin
import applyLabel from 'utils/LabelProvider';

const extractDomain = (url) => {
    var domain;
    //find & remove protocol (http, ftp, etc.) and get domain
    if (url.indexOf("://") > -1) {
        domain = url.split('/')[2];
    }
    else {
        domain = url.split('/')[0];
    }

    //find & remove www
    if (domain.indexOf("www.") > -1) {
        domain = domain.split('www.')[1];
    }

    domain = domain.split(':')[0]; //find & remove port number
    domain = domain.split('?')[0]; //find & remove url params

    return domain;
}

const getURL = (url) => {
    if (extractDomain(url) == "youtube.com") {
        const videoID = getYoutubeVideoId(url);
        return `https://youtube.com/embed/${videoID}`
    }
    return url;
}

function getYoutubeVideoId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    return (match && match[2].length === 11)
        ? match[2]
        : null;
}


@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ExternalUrlEmbedded extends Component {

    constructor(props) {
        super(props)
        this.state = {
            showFeedback: true
        }
    }

    toggleFeedback = () => {
        this.setState({
            showFeedback: !this.state.showFeedback
        })
    }

    render() {
        let { moduleItem, getLabelWithDefaults } = this.props;
        return (
            <span>
                <div style={{
                    background: 'url(https://webassets.knolskape.com/misc/amul_garg/2018/09/24/36/ezgif-2-76b77134d0.gif) center center no-repeat'
                }}>
                    <iframe
                        src={getURL(moduleItem.get('moduleItem').get('external_url'))}
                        styleName="scorm-embedded-iframe"
                        allowFullScreen
                        title="External URL"
                    />
                </div>
                <AdditionalInformation
                    title={getLabelWithDefaults('instructions_label', "Instructions")}
                    body={moduleItem.get('moduleItem').get('description')} />
            </span>
        )
    }
}

export default ExternalUrlEmbedded;