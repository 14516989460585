import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		quizQuestionsOuterCnt:{
		},
		quizDataCnt:{
		},
		questionListCnt:{
		},
		questionNavigatorCnt: {
		}
	});
}

export default getSkin;