import React, { Component } from 'react';
import styles from './completedFeedbackIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class CompletedFeedbackIcon extends Component {

	render() {
		const fillColor = this.props.svgColor || "#979EB7";

		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 15 15">
					<g fill={fillColor} fillRule="nonzero">
						<path d="M5.313 8.75c-.154 0-.301.056-.416.158l-1.772 1.575V9.375A.625.625 0 0 0 2.5 8.75H1.406a.157.157 0 0 1-.156-.156V1.406c0-.086.07-.156.156-.156h11.25c.087 0 .156.07.156.156v4.795c0 .063.038.12.095.144.32.138.625.309.91.51a.156.156 0 0 0 .245-.128V1.25c0-.69-.56-1.25-1.25-1.25H1.25C.56 0 0 .56 0 1.25v7.533A1.22 1.22 0 0 0 1.25 10h.625v1.875a.625.625 0 0 0 1.04.467L5.55 10h.351a.156.156 0 0 0 .153-.125 4.93 4.93 0 0 1 .293-.909.156.156 0 0 0-.143-.216h-.891z" />
						<path d="M10.88 14.88a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm-2.089-3.891c.18-.18.473-.18.653 0l.8.8a.157.157 0 0 0 .235-.017l1.677-2.246a.462.462 0 1 1 .739.554l-1.789 2.388a.923.923 0 0 1-.676.369h-.068a.919.919 0 0 1-.65-.271l-.922-.924a.462.462 0 0 1 .001-.653z" />
					</g>
				</svg>

			</div>
		);
	}
}



export default CompletedFeedbackIcon;
