import actionConstants from 'constants/actions';

const internetConnectivityHandler = (status) => ({
    type: actionConstants.get('INTERNET_CONNECTIVITY_STATUS'),
    status
})

export {
    internetConnectivityHandler
};
