/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: BaseContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * base container is rendered on initial load of application and mounted only
 * once displayed on every route of the application and all other containers are
 * children of this base container
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import ErrorTemplate from 'commonComponents/errorTemplate';
import { setInvalid } from 'actions/invalid/actionCreators';

class ErrorContainer extends React.Component {

	getErrorCode = () => {
		try{
			const userError = this.props.userState.get('userError');
			return userError.get('error_code');
		} catch(e){
			return "SOMETHING_WRONG";
		}
	}

	render() {
		return (
			<ErrorTemplate type={this.getErrorCode()} {...this.props} />
		);
	}
}

const mapStateToProps = state => ({
    errors: state.get('errors')
});


const mapDispatchToProps = dispatch => ({
	setInvalid: (flag) => {
        dispatch(setInvalid(flag));
    }
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ErrorContainer);
