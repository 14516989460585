import actionConsts from 'constants/actions';

const updateFetchBatchesListStatus = (status = false) => ({
	type: actionConsts.get('FETCH_BATCHES_LIST_STATUS'),
	status
})

const updateFetchUserItemsListStatus = (status = false) => ({
	type: actionConsts.get('FETCH_USER_ITEMS_LIST_STATUS'),
	status
})

const updateFetchUserItemsListStatusForFeedbackScreen = (status = false) => ({
	type: actionConsts.get('FETCH_USER_ITEMS_LIST_STATUS_FOR_FEEDBACK_SCREEN'),
	status
})

const updateFetchBatchDashboardBasicDataStatus = (status = false) => ({
	type: actionConsts.get('FETCH_BATCH_DASHBOARD_BASIC_DATA_STATUS'),
	status
})

const updateFetchBatchDashboardStatus = (status = false) => ({
	type: actionConsts.get('FETCH_BATCH_DASHBOARD_STATUS'),
	status
})

const updateFetchBatchInvitationStatus = (status = false) => ({
	type: actionConsts.get('FETCH_BATCH_INVITATION_STATUS'),
	status
})

const updateFetchToolFAQsStatus = (status = false) => ({
	type: actionConsts.get('FETCH_TOOL_FAQS_STATUS'),
	status
})

const updateFetchItemDataStatus = (status = false) => ({
	type: actionConsts.get('FETCH_ITEM_DATA_STATUS'),
	status
})

const updateFetchFeedbackStatus = (status = false) => ({
	type: actionConsts.get('FETCH_FEEDBACK_STATUS'),
	status
})

const updateFetchUserFeedbackStatus = (status = false) => ({
	type: actionConsts.get('FETCH_USER_FEEDBACK_STATUS'),
	status
})

export {
	updateFetchBatchesListStatus,
	updateFetchUserItemsListStatus,
	updateFetchBatchDashboardBasicDataStatus,
	updateFetchBatchDashboardStatus,
	updateFetchBatchInvitationStatus,
	updateFetchToolFAQsStatus,
	updateFetchItemDataStatus,
	updateFetchUserItemsListStatusForFeedbackScreen,
	updateFetchFeedbackStatus,
	updateFetchUserFeedbackStatus
}