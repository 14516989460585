import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
       text: {
            ...myTypography.caption,
            color: 'gray',
            fontWeight: 'normal'
       }
    });
}

export default getSkin;