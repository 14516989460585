import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setUserPhasesList } from 'actions/phases/actionCreators';
import setUserState from 'actions/userState/actionCreators';
import { filterObjectFromArray } from 'utils/objectUtils';
import Immutable from 'immutable';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';

class FetchUserPhases extends NetPackWrapper {

    constructor() {
        super("GET", true);
    }

    prepareUrl = (apiInfoObject = {}) => {
       return urls.get('PLATFORM').get('GET_USER_PHASES') + `?batchId=${apiInfoObject.batchId}`
    }

    successCall = (response) => {
        const myResponse = { ...response };

        const activePhaseId = myResponse.activePhaseId;
        delete myResponse['activePhaseId'];

        const userPhasesList = myResponse.phases;
        delete myResponse['phases'];

        const userPhasesListAdditionalDetails = myResponse;

        const payload = {
            activePhaseId,
            userPhasesList,
            userPhasesListAdditionalDetails
        };

        const getActiveItemId = (phasesList, activePhaseId) => {
            const activePhase = filterObjectFromArray(phasesList, 'id', activePhaseId);
            return activePhase.get('activeItemId');
        }

        let currentPhaseId = this.getState().get('userState').get('currentPhaseId');

        if (currentPhaseId === null) {
            currentPhaseId = activePhaseId;
        }


        let currentItemId = this.getState().get('userState').get('currentItemId');
        let currentItemIdFromApi = getActiveItemId(Immutable.fromJS(userPhasesList), activePhaseId);
        this.dispatch(setUserPhasesList(payload));
        this.dispatch(setUserState({
            currentPhaseId,
            currentItemId: ((currentItemId) ? currentItemId : currentItemIdFromApi)
        }))

        return response;
    }

    onError = (errorObj) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR_USER_PHASE_LIST'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to fetch phase list. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
        sendUserToInvalidPage();
    }

}

export default new FetchUserPhases();
