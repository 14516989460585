import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import FeedbackPattern from 'images/feedback_pattern.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        assessmentBorder: {
            borderTop: `solid 8px ${skin.get('secondaryColor')}`
        },
        feedbackBoxContent: {

        },
        mainFeedbackBackground: {
            backgroundImage: `linear-gradient(107deg, #5782dc, ${skin.get('primaryColor')})`
        },
        mainFeedbackImage: {
            backgroundImage: `url(${FeedbackPattern})`,
            backgroundSize: 'contain'
        },
        showDetailsButton: {
            border: `1px solid ${skin.get('secondaryColor')}`,
            cursor: 'pointer',
            color: skin.get('secondaryColor'),
            ...myTypography.button,
            ':hover': {
                background: skin.get('secondaryColor'),
                color: skin.get('white')
            }
        },
        feedbackMainHeading: {
            ...myTypography.h6,
            color: skin.get('white')
        },
        assessmentResultText: {
            ...myTypography.body1,
            color: skin.get('grey2'),
            '@media only screen and (max-width: 480px)': {
				fontSize: '16px',
				lineHeight: 1.1,
                textAlign: 'left',
                color: skin.get('greyColor2'),
			}      
        }
    });
}

export default getSkin;