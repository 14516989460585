import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

class SetActiveOrganization extends NetPackWrapper {

    constructor() {
        super("POST");
    }

    prepareUrl = (apiInfoObject = {}) => urls.get('PLATFORM').get('SET_ACTIVE_ORGANIZATION').replace(
        'ORG_ID',
        apiInfoObject.orgId
    )

    successCall = (response, apiInfoObject) => {
        window.location.assign(window.location.origin);
        return response;
    }

    onError = (response) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR_SET_BATCH'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to change active organization. Please try again!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));

    }

}

export default new SetActiveOrganization();
