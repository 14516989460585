import React, { Component } from 'react';
import styles from './lockTooltip.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css, StyleSheet } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';

@CSSModules(styles, { allowMultiple: true })
class LockTooltip extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false
		}
	}

	showTooltip = () => {
		this.setState({
			showTooltip: true
		});
	}

	removeTooltip = () => {
		this.setState({
			showTooltip: false
		});
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const style = this.props.tooltipText ? {zIndex: 100} : {};
		return (
			<div styleName="info-container"
				style={style}
				onMouseEnter={this.showTooltip}
				onMouseLeave={this.removeTooltip}
			>
				<div
					styleName="info-icon"
				>
					{this.props.children}
				</div>
				{
					(this.state.showTooltip && this.props.tooltipText)
					?	<div styleName="tooltip-container" style={this.props.style ? this.props.style : {}}>
							<div className={css(myStyles.tooltipArrow)} styleName="tooltip-arrow"></div>
							<div className={css(myStyles.tooltipContent)} styleName="tooltip-content"
								dangerouslySetInnerHTML={{__html: this.props.tooltipText}}
							>
								{/* {this.props.tooltipText} */}
							</div>
						</div>
					:	null
				}
			</div>
		);
	}
}

export default LockTooltip;
