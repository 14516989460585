import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');

    return StyleSheet.create({
        greyBackground: {
            backgroundColor: skin.get('greyColor6')
        },
        boxBorder: {
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
            borderRadius: '4px',
            border: `solid 1px ${skin.get('greyColor5')}`
        },
        borderTop: {
            borderTop: `solid 8px ${skin.get('greyColor4')}`
        }
    });
}

export default getSkin;