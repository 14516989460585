import FetchCourses from 'actions/serverCalls/courses/FetchCourses';
import FetchCourseModulesList from 'actions/serverCalls/courses/FetchCourseModulesList';
import FetchModule from 'actions/serverCalls/module/FetchModule';
import FetchCourseWithModules from 'actions/serverCalls/courses/FetchCourseWithModules';

import FetchModuleItemsList from 'actions/serverCalls/courses/FetchModuleItemsList';

const fetchDevPhaseItems = (params) => {
	return FetchCourses.call(params);
}

const fetchCourseModulesList = (params) => FetchCourseModulesList.call(params);

const fetchModuleItemsList = (params) => FetchModuleItemsList.call(params);

const fetchModuleItemsForSingleModuleCourse = (params) => {
	FetchCourseModulesList.call(params).then(modules => {
		if(modules && modules.length > 0){
			const firstModule = modules[0];
			FetchModuleItemsList.call({...params, moduleId: firstModule.id});
		}
	});
}

const fetchModule = (params) => FetchModule.call(params);

const fetchCourseForNextActivity = (params) => FetchCourseWithModules.call(params);


export {
	fetchModule,
	fetchCourseModulesList,
	fetchModuleItemsList,
	fetchModuleItemsForSingleModuleCourse,
	fetchDevPhaseItems,
	fetchCourseForNextActivity
}