import Immutable from 'immutable';

//TODO: check the completion logic for phases and items
const indexBy = (iterable, searchKey) => {
	return iterable.reduce(
		(lookup, item) => lookup.set(item.get(searchKey), item),
		Immutable.Map()
	);
}

const checkStatusForItems = (preReq, userItemsList) => {
	let status = "";
	for (let preReqItem of preReq) {
		let preReqId = preReqItem.get('id');
		let object = userItemsList.find((obj) => {
			return obj.get('id') === preReqId;
		});
		if (object && object.get('workflowStatus') === 'COMPLETED') {
			status = true;
		}
		else {
			status = false;
		}
	}
	return status;
}

const checkStatusForPhases = (preReq, userPhasesList) => {
	let status = "";
	for (let preReqId of preReq) {
		let object = userPhasesList.find((obj) => {
			return obj.get('id') === preReqId;
		});
		if (object && (object.get('completedItems') === object.get('totalItems'))) {
			status = true;
		}
		else {
			status = false;
		}
	}
	return status;
}

const listItemsThatAreOpenToPlayBasedOnPreReq = (itemsList, userItemsList) => {
	itemsList = itemsList.map((item) => {
		let preReq = item.get('prerequisites');
		if (preReq.size === 0) {
			item = item.set('isAvailable', true);
		}
		else {
			let status = checkStatusForItems(preReq, userItemsList);
			item = item.set('isAvailable', status);
		}
		return item;
	});

	return itemsList;
}

const listPhasesThatAreOpenToPlayBasedOnPreReq = (phasesList, userPhasesList) => {
	phasesList = phasesList.map((phase) => {
		let preReq = phase.get('prerequisites');
		if (preReq.size === 0) {
			phase = phase.set('isAvailable', true);
		}
		else {
			let status = checkStatusForPhases(preReq, userPhasesList);
			phase = phase.set('isAvailable', status);
		}
		return phase;
	});

	return phasesList;
}

const setAllItemsInPhaseToBeUnavailable = (id, itemsList) => {
	itemsList = itemsList.map((item) => {
		if (item.get('phaseId') == id) {
			item = item.set('isAvailable', false);
		}
		return item;
	});
	return itemsList;
}

const updateItemsAvailabilityBasedOnPhases = (itemsList, phasesList) => {

	phasesList.map((phase) => {
		if (!phase.get('isAvailable')) {
			itemsList = setAllItemsInPhaseToBeUnavailable(phase.get('id'), itemsList);
		}
	});

	return itemsList;
}

const setAllItemsInPhaseToBeLocked = (id, itemsList, phaseLockExplanation) => {
	itemsList = itemsList.map((item) => {
		if (item.get('phaseId') == id) {
			item = item.set('isLocked', true);
			item = item.set('lockExplanation', phaseLockExplanation);
			item = item.set('lockedBecauseOfPhase', true);
		}
		return item;
	});
	return itemsList;
}

const updateItemsLockedStatusBasedOnPhases = (itemsList, phasesList) => {
	phasesList.map((phase) => {
		if (phase.get('isLocked')) {
			itemsList = setAllItemsInPhaseToBeLocked(phase.get('id'), itemsList, phase.get('lockExplanation'));
		}
	});

	return itemsList;
}

const updatePhasesLockedStatusBasedOnBatch = (phasesList, userActiveBatchDetails) => {
	const batchLockDetails = userActiveBatchDetails.get('lock_details');
	const isBatchLocked = batchLockDetails.get('locked') && batchLockDetails.get('lock_reason') == 'not_started';
	const isBatchExpired = batchLockDetails.get('lock_reason') == 'expired';

	return phasesList.map((phase) => {
		if (isBatchLocked) {
			phase = phase.set('isLocked', true).set('isExpired', false).set('lockExplanation', batchLockDetails.get('lock_explanation'));
		}

		if (isBatchExpired) {
			phase = phase.set('isExpired', true).set('isLocked', false).set('lockExplanation', batchLockDetails.get('lock_explanation'));
		}

		return phase;
	});
}

const setAllItemsInPhaseToBeExpired = (id, itemsList, phaseLockExplanation) => {
	itemsList = itemsList.map((item) => {
		if (item.get('phaseId') == id) {
			item = item.set('isExpired', true);
			item = item.set('isLocked', false);
			item = item.set('lockExplanation', phaseLockExplanation);
			item = item.set('expiredBecauseOfPhase', true);
		}
		return item;
	});
	return itemsList;
}

const updateItemsExpiredStatusBasedOnPhases = (itemsList, phasesList) => {
	phasesList.map((phase) => {
		if (phase.get('isExpired')) {
			itemsList = setAllItemsInPhaseToBeExpired(phase.get('id'), itemsList, phase.get('lockExplanation'));
		}
	});

	return itemsList;
}

const isLockedBasedOnAvailabilityAndTimestamp = (item, userInfo) => {
	const lockDetails = userInfo ? userInfo.get('lock_details') : null;
	if (lockDetails) {
		if (
			lockDetails.get('lock_reason') == 'not_started' ||
			lockDetails.get('lock_reason') == 'pre_requisite_not_done'
		) {
			return true;
		}
	}
	return false;
}

const isExpiredBasedOnAvailabilityAndTimestamp = (item, userInfo) => {
	const lockDetails = userInfo ? userInfo.get('lock_details') : null;
	if (lockDetails) {
		if (lockDetails.get('lock_reason') == 'expired') {
			return true;
		}
	}
	return false;
}

const setLockExplanationForItem = (item, userInfo) => {
	const lockDetails = userInfo ? userInfo.get('lock_details') : null;
	if (lockDetails) {
		if (lockDetails.get('locked')) {
			return lockDetails.get('lock_explanation');
		}
	}
	return null;
}

const setLockReasonForItem = (item, userInfo) => {
	const lockDetails = userInfo ? userInfo.get('lock_details') : null;
	if (lockDetails) {
		if (lockDetails.get('locked')) {
			return lockDetails.get('lock_reason');
		}
	}
	return null;
}

const setLockDateForItem = (item, userInfo) => {
	const lockDetails = userInfo ? userInfo.get('lock_details') : null;
	if (lockDetails) {
		if (lockDetails.get('locked')) {
			return lockDetails.get('lock_date');
		}
	}
	return null;
}

const setIsLockedFlagForList = (list, userList) => {
	let userInfo = null;
	return list.map((item) => {
		userInfo = userList.get(item.get('id'));
		return item.set('isLocked', isLockedBasedOnAvailabilityAndTimestamp(item, userInfo));
	})
};

const setIsExpiredFlagForList = (list, userList) => {
	let userInfo = null;
	return list.map((item) => {
		userInfo = userList.get(item.get('id'));
		return item.set('isExpired', isExpiredBasedOnAvailabilityAndTimestamp(item, userInfo));
	})
};

const setLockExplanationForList = (list, userList) => {
	let userInfo = null;
	return list.map((item) => {
		userInfo = userList.get(item.get('id'));
		item = item.set('lockReason', setLockReasonForItem(item, userInfo));
		item = item.set('lockDate', setLockDateForItem(item, userInfo));
		return item.set('lockExplanation', setLockExplanationForItem(item, userInfo));
	})
};

const setAvailabilityFlags = (phases, items, userActiveBatchDetails = null) => {

	let itemsList = items.get('itemsList');
	let userItemsList = items.get('userItemsList');

	let phasesList = phases.get('phasesList');
	let userPhasesList = phases.get('userPhasesList');

	userItemsList = indexBy(userItemsList, 'id');
	userPhasesList = indexBy(userPhasesList, 'id');

	//itemsList = listItemsThatAreOpenToPlayBasedOnPreReq(itemsList, userItemsList);

	//phasesList = listPhasesThatAreOpenToPlayBasedOnPreReq(phasesList, userPhasesList);


	phasesList = setIsLockedFlagForList(phasesList, userPhasesList);

	phasesList = setIsExpiredFlagForList(phasesList, userPhasesList);

	phasesList = setLockExplanationForList(phasesList, userPhasesList);
	
	// if(userActiveBatchDetails.size){
	// 	phasesList = updatePhasesLockedStatusBasedOnBatch(phasesList, userActiveBatchDetails);
	// }

	itemsList = updateItemsAvailabilityBasedOnPhases(itemsList, phasesList);

	itemsList = setIsLockedFlagForList(itemsList, userItemsList);

	itemsList = setIsExpiredFlagForList(itemsList, userItemsList);

	itemsList = setLockExplanationForList(itemsList, userItemsList);

	//itemsList = updateItemsLockedStatusBasedOnPhases(itemsList, phasesList);

	//itemsList = updateItemsExpiredStatusBasedOnPhases(itemsList, phasesList);

	items = items.set('itemsList', itemsList);
	phases = phases.set('phasesList', phasesList);
	return ({ phases, items });
}

export default setAvailabilityFlags;