import React, { Component } from 'react';
import applySkin from 'utils/SkinProvider';
@applySkin
class CompletedIcon extends Component {

	render() {
		const skinHex = this.props.skinGuide.get('skinHex');
		return (
            <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} viewBox="0 0 14 14">
                <path fill="none" fillRule="evenodd" stroke={this.props.strokeColor || skinHex.get('darkSkyBlue')} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M12.625 1.375L5.079 12.153a1.1 1.1 0 0 1-1.784.03l-1.92-2.56" opacity=".653" />
            </svg>
		);
	}
}
export default CompletedIcon;
