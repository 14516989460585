import React, { Component } from 'react';
import styles from './thirdPartyCookie.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import { browserDetails } from 'utils/browser';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class ThirdPartyCookieCheck extends Component {
    
    constructor(props){
        super(props);
        this.state = {
			tpc_checking: true,
			tpc_allowed: null
		};	
		window.addEventListener("message", this.receiveMessage, false);
    }

    componentWillUnmount(){
        window.removeEventListener('message', this.receiveMessage, false)
    }

    receiveMessage = (evt) => {
		if (evt.data === 'MM:3PCunsupported') {
			this.setState({
				tpc_checking: false,
				tpc_allowed: false
			});
		} else if (evt.data === 'MM:3PCsupported') {
			this.setState({
				tpc_checking: false,
				tpc_allowed: true
			});
		}
	};

    render() {
        const myStyles = getSkin(this.props.skinGuide);

        if(this.state.tpc_checking){
            return <iframe src={`https://prereq-checker.knolskape.com/tpc/start.html?ts=${Date.now()}`}  style={{display:'none'}} title="TPC iframe"></iframe>;
        }
        
        if(!this.state.tpc_allowed){
            return <div styleName="third-party-instructions-cnt">
                <div styleName="error-icon" className={css(myStyles.errorIcon)}>!</div>
                <div styleName="title-details-cnt">
                    <div className={css(myStyles.title)}>Third Party Cookies Are Disabled</div>
                    <div className={css(myStyles.details)}>
                        You might face issues accessing this content in your browser. <a href="https://support.panopto.com/s/article/How-to-Enable-Third-Party-Cookies-in-Supported-Browsers" target="_blank" rel="noopener noreferrer">Here's how to enable third party cookies needed for this content</a>. For Mac, iPhone or iPad refer to <a target="_blank" href='https://webassets.knolskape.com/misc/amul_garg/2023/08/01/83/thirdpartycookiesios.png' rel="noopener noreferrer">this</a>.
                    </div>
                </div>
                 
            </div>;
        }


        if(browserDetails.name === 'SAFARI' && browserDetails.version < 13){
            return <div className={css(myStyles.oldSafariMessage)}>Note: Please ensure cross-site tracking is enabled under privacy preferences.</div>;
        }

        return null;
    }
}

export default ThirdPartyCookieCheck;
