import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { updateQuestion } from 'actions/quiz/actionCreators'

class UnflagQuestion extends NetPackWrapper {
	constructor() {
		super('PUT', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('UNFLAG_QUIZ_QUESTION')
			.replace('_QUIZ_SUBMISSION_ID_', apiInfoObject.submissionId)
			.replace('_QUESTION_ID_', apiInfoObject.questionId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	};

	successCall = (response, apiInfoObject) => {
		this.dispatch(updateQuestion(response));
		return response;
	}
}

export default new UnflagQuestion();