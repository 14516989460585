import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './moduleItemTitle.module.sass';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import ModuleItemScoreContainer from 'containers/ModuleItemScoreContainer';
import { hexToRgbA } from 'utils/styleUtil';

@applyLabel
@applySkin
@CSSModules(styles, {allowMultiple: true})

class ModuleItemTitle extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			showTooltip: false
		}
	}

	toggleTooltip = () => {
		this.setState({
			showTooltip: !this.state.showTooltip
		});
	}

	render(){
		let {moduleItem, getLabelWithDefaults, maxPoints, quiz, match} = this.props;
		let styles = getSkin(this.props.skinGuide);
		let scoreComponent = null;
		let isItAQuiz = moduleItem.get('moduleItem').get('type_label') == 'Quiz';//quizActiveAttemptData.size > 0;

		const defaultScoreComponent =  (<span 
											title={`You can earn a max of ${this.props.maxPoints} points in this content.`}>
											<div styleName="points">
												{this.props.maxPoints}
											</div>
										<div styleName="text">
											{getLabelWithDefaults('points_label', 'Points')}
										</div>
										</span>);

		scoreComponent = <ModuleItemScoreContainer {...this.props} defaultScoreComponent={defaultScoreComponent}/>;		

		return(
			<div style={{display: 'flex', marginTop: '25px'}}>
				<div styleName="title-cnt">					
					<div className={css(styles.mainTitle)}>
						{this.props.title}
					</div>
					{
						this.props.subTitle &&
							<div styleName="sub-title" className={css(styles.subTitle)}>
								{this.props.subTitle}
							</div>
					}
				</div>
				{(scoreComponent && maxPoints) ?
					<div 
					styleName={`points-cnt ${isItAQuiz ? 'without-border' : ''}`} 
					onMouseEnter={() => {this.toggleTooltip()}}
					onMouseLeave={() => {this.toggleTooltip()}}
					>
						{scoreComponent}
						{
							moduleItem.get('contentDetails') && moduleItem.get('contentDetails').get('submission') ?
							null:
								this.state.showTooltip ?
									<div styleName="tooltip-container">
										<div className={css(styles.tooltipArrow)} styleName="tooltip-arrow"></div>
										<div className={css(styles.tooltipContent)} styleName="tooltip-content">{`You can earn a max of ${this.props.maxPoints} points in this content.`}</div>
									</div>
								: null
						}
					</div>
					: null
				}
				<div style={{clear: 'both'}}></div>
				{
					!maxPoints && <span>{scoreComponent}</span>
				}
			</div>
		)
	}
}

export default ModuleItemTitle;