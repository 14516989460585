import React, { Component } from 'react';
import { logComponentErrorToSentry } from 'utils/sentry';

class SystemCheckErrorBoundary extends Component {

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ hasError: true });

		logComponentErrorToSentry(error);
	}

	render() {
		if (this.state.hasError) {
			return <div>Something went wrong in the system check. Please report this to the support team. <span className="cursor-pointer" onClick={this.props.continueAction}><b>Click here</b></span> to continue.</div>;
		}
		return this.props.children;
	}
}

export default SystemCheckErrorBoundary;