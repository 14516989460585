import React, { Component } from 'react';
import styles from './overlay.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import TabsBar from './tabsBar';
import JourneyItemIntroduction from 'components/journeys/journeysList/journeysListItem/journeyItemIntroduction';
import AuthorIntro from 'components/journeys/journeysList/journeysListItem/authorIntro';
import PreRequisite from 'components/journeys/journeysList/journeysListItem/preRequisites';
import SkillsCovered from 'components/journeys/journeysList/journeysListItem/skillsCovered';
import JourneyExp from 'components/journeys/journeysList/journeysListItem/journeyExp';
import JourneyDetails from 'components/journeys/journeysList/journeysListItem/journeyDetails';
import JourneyFooter from 'components/journeys/journeysList/journeysListItem/journeyFooter';
import { filterObjectFromArray } from 'utils/objectUtils';
import CloseCircle from 'svgComponents/closeCircle';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { checkIfPresent } from 'utils/utilFunctions';
import { isMSTeams } from 'utils/msTeamsUI';
import { isLaunchedFromExternalIntegration } from 'utils/externalIntegrations';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import store from 'store/store';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Overlay extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedTabId: 1,
			showBenefitsAndInsights: false,
			showMessageFromCoo: false,
			showPrerequisites: false,
			showSkills: false
		}
	}

	switchContainer = (id) => {
		this.setState({
			selectedTabId: id
		});
	}

	closeOverlay = (e) => {
		e.stopPropagation();
		this.sendSegmentData();
		this.props.setUiState({
			showOverlay: false,
			showAboutJourney: false,
			openDropDown: false
		});
	}

	sendSegmentData = () => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash
		}

		window.analytics.track(
			'About journey overlay closed',
			segmentData,
			getAmplitudeSessionDetails()
		)
		window.analytics.page(
			'About journey overlay closed',
			{
				title: 'Batch Dashboard',
				url: window.location.href,
				pathHash: window.location.hash
			},
			getAmplitudeSessionDetails()
		)
	}

	getAttachmentsList = () => {
		//a hacky way of doing this. Refactor this.
		return store.getState().get('attachments').get('list');
	}

	renderTabContent = (isBatchDetailsAvailable, journeyDetails, batchDetails, phasesList, itemsList) => {
		const { uiState, user } = this.props;
		if (this.state.selectedTabId === 1) {
			const items = [];
			if (checkIfPresent(journeyDetails.get('aimOfJourney'))) {
				items.push(
					<JourneyExp
						isBatchDetailsAvailable={isBatchDetailsAvailable}
						journeyDetails={journeyDetails}
						uiState={uiState}
						isInOverlay={true}
					/>
				);
			}
			if (checkIfPresent(journeyDetails.get('assignedByInstruction')) && checkIfPresent(journeyDetails.get('assignedBy'))) {
				items.push(
					<AuthorIntro
						isBatchDetailsAvailable={isBatchDetailsAvailable}
						batchDetails={batchDetails}
						journeyDetails={journeyDetails}
						user={user}
						uiState={uiState}
						renderingInOverlay={true}
					/>
				);
			}
			if (checkIfPresent(journeyDetails.get('prerequisitesInfo')) && journeyDetails.get('prerequisitesInfo').size !== 0) {
				items.push(
					<PreRequisite
						isBatchDetailsAvailable={isBatchDetailsAvailable}
						journeyDetails={journeyDetails}
						uiState={uiState}
					/>
				);
			}
			if (checkIfPresent(journeyDetails.get('competencies')) && journeyDetails.get('competencies').size !== 0) {
				items.push(
					<SkillsCovered
						isBatchDetailsAvailable={isBatchDetailsAvailable}
						journeyDetails={journeyDetails}
						uiState={uiState}
					/>
				);
			}
			return items;
		} else if (this.state.selectedTabId === 2) {
			return [
				<JourneyDetails
					phasesList={phasesList}
					itemsList={itemsList}
					items={this.props.items}
					uiState={uiState}
					loaders={this.props.loaders}
					course={this.props.course}
					coursesModulesList={this.props.coursesModulesList}
					modulesItemsList={this.props.modulesItemsList}
					attachmentsList={this.getAttachmentsList()}
				/>,
				<JourneyFooter />
			];
		}
	}

	render() {
		const {
			batches, phases, items, user, fetchBatchesList,
			updateWorkflowStatus, uiState, setUiState
		} = this.props;

		const activeBatchId = batches.get('activeBatchId');
		const batchesList = batches.get('batchesList');
		const batchDetails = filterObjectFromArray(batchesList, 'id', activeBatchId);
		const isBatchDetailsAvailable = (batchDetails.isEmpty() || !batchDetails.get('isDetailedDataAvailable'))
			? false
			: true;
		const journeyDetails = batchDetails.get('journey');
		const phasesList = phases.get('phasesList');
		const itemsList = items.get('itemsList');

		const batchPhasesList = phasesList.filter(phase => phase.get('batchId') == activeBatchId);

		return (
			<div key="openOverlay" onClick={this.closeOverlay} styleName="overlay-container" style={{ background: `rgba(0, 0, 0, ${this.props.uiState.get('overlayOpacity')})` }}>
				<ReactCSSTransitionGroup
					transitionName="userInfoNavigation"
					transitionEnterTimeout={1000}
					transitionLeaveTimeout={1000}
					transitionEnter={true}
					transitionLeave={true}
				>
					{
						this.props.uiState.get('showAboutJourney')
							? [
								<div key="overlay-open" onClick={(e) => { e.stopPropagation() }} styleName="right-side-complete">
									<JourneyItemIntroduction
										isBatchDetailsAvailable={isBatchDetailsAvailable}
										journeyDetails={journeyDetails}
										phasesList={batchPhasesList}
										user={user}
										updateWorkflowStatus={updateWorkflowStatus}
										uiState={uiState}
										renderingInOverlay={true}
										setUiState={setUiState}
									/>
									<TabsBar
										switchContainer={this.switchContainer}
										tabId={this.state.selectedTabId}
									/>
									{this.renderTabContent(isBatchDetailsAvailable, journeyDetails, batchDetails, batchPhasesList, itemsList)}
								</div>,
								<div styleName="close-button" onClick={this.closeOverlay} style={
									isMSTeams() || isLaunchedFromExternalIntegration() ? { top: '50px' } : {}
								}>
									<CloseCircle />
								</div>
							]
							: null
					}
				</ReactCSSTransitionGroup>
			</div>
		);
	}
}

export default Overlay;