import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setPhasesList } from 'actions/phases/actionCreators';
import { appendQueryParameters, sortObjectArrayBasedOnKey } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';

class FetchPhases extends NetPackWrapper {

    constructor() {
        super("GET", true);
    }

    prepareUrl = (apiInfoObject = {}) => appendQueryParameters(
		urls.get('PLATFORM').get('GET_PHASES'),
		apiInfoObject.queryParameters
	)
	
	/* shouldAjaxCall = () => {
        // if (this.getState().get('phases').get('phasesList').size > 0) {   //logic need to be changed to handle pagination
        //     return false;
        // }
        if (this.getState().get('phases').get('isPhasesListFetched')) {
            return false;
        }
        return true;
    } */

    successCall = (response) => {
        const myResponse = { ...response };

        let phasesList = myResponse.phases;
        phasesList = sortObjectArrayBasedOnKey(phasesList, 'order');
        delete myResponse['phases'];

        const phasesListAdditionalDetails = myResponse;

        const payload = {
            phasesList,
            phasesListAdditionalDetails
        };
        
        this.dispatch(setPhasesList(payload));
        return response;
    }

    onError = (errorObj) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR_PHASE_LIST'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to fetch phase list. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
        if (errorObj.apiInfoObject.showErrorPage) {
            sendUserToInvalidPage();
        }
    }

}

export default new FetchPhases();
