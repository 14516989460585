import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules({ allowMultiple: true })
class Home extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const strokeColor = this.props.strokeColor || this.props.skinGuide.get('skinHex').get('secondaryColor')
        return (
            <svg width="100%" height="100%" viewBox="0 0 22 20">
                <g fill="none" fillRule="evenodd" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8">
                    <path d="M4.127 11.62v6.875h5v-5c0-.69.56-1.25 1.25-1.25h1.25c.69 0 1.25.56 1.25 1.25v5h5V11.62M1.627 10.37l8.491-8.49a1.25 1.25 0 0 1 1.769 0l8.49 8.49" />
                </g>
            </svg>
        );
    }
}

export default Home;
