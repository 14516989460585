import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './productVideo.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import defaults from 'constants/defaults';
import {checkIfPresent} from 'utils/utilFunctions';
import VideoPlayer from 'commonComponents/contentPlayers/videoPlayer';
import MultipleImages from './multipleImages';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ProductVideo extends Component {

	renderVideo = (selectedItem, myStyles, getLabel) => {
		if (checkIfPresent(selectedItem.get('videoLink'))) {
			/* video_id is not available in AL API so use default  */
			const kalturaEntryId = selectedItem && selectedItem.get('videoLink') !== ""
				? selectedItem.get('videoLink')
				: null;

			return (
				<div styleName="video-container">
					{/* <div className={css(myStyles.title)}>{getLabel('help_video_label')}</div> */}
					<div styleName="video-player">
						<VideoPlayer
							config={{
								kalturaEntryId
							}}
							playerWidth='100%'
							playerHeight='500px'
							videoPlayerTargetId='video-player-container-show-more'
							key={`phase_item_video_${selectedItem.get('id')}`}
						/>
					</div>
				</div>
			);
		}
		else{
			return null;
		}
	}

	renderImage = (selectedItem, myStyles, getLabel) => {
		if (checkIfPresent(selectedItem.get('imageLinks')) && selectedItem.get('imageLinks').size > 0) {
			const images = selectedItem.get('imageLinks').toJS();
			return (<div styleName="image-container">
				{/* <div className={css(myStyles.title)}>
					{this.props.getLabel('help_image')}
				</div> */}
				<div styleName="image-player">
					<img src={images[0]} height="100%" width="100%" />
				</div>
			</div>)

		}
		else{
			return null;
		}
	}

	renderContent = (selectedItem, myStyles, getLabel) => {
		if (checkIfPresent(selectedItem.get('videoLink'))) {
			return this.renderVideo(selectedItem, myStyles, getLabel)
		} else if(
			checkIfPresent(selectedItem.get('imageLinks'))
			&& selectedItem.get('imageLinks').size > 0
		) {
			const images = selectedItem.get('imageLinks').toJS();
			if(checkIfPresent(images[0]) && images.length == 1) {
				return this.renderImage(selectedItem, myStyles, getLabel);
			}
			else if(images.length >= 1) {				
				return <MultipleImages
					images={images}
					// images={[
					// 	'https://images.freeimages.com/images/small-previews/2fe/butterfly-1390152.jpg',
					// 	'https://resize.indiatvnews.com/en/centered/newbucket/715_431/2018/03/h6-1521531233.jpg',
					// 	'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1QPqg8ev_KJmACRW6rsVLbVFTcCrZ8qk6apowXTX6PsIBdT_D'
					// ]}
				/>;
			}
		}
		return null;
	}

	render() {
		const { getLabel, skinGuide, selectedItem } = this.props;
		const myStyles = getSkin(skinGuide);

		return(
			<div styleName="container">
				{this.renderContent(selectedItem, myStyles, getLabel)}
			</div>
		);
		
	}
}

export default ProductVideo;
