import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		downloadingText: {
			...myTypography.caption,
			color: hexToRgbA(skinHex.get('greyColor1'), 0.85)
		}
	});
}

export default getSkin;