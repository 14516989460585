import React, { Component } from 'react';
import styles from './journeyFooter.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import NextActivityLoader from 'commonComponents/loaders/nextActivityLoader';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyFooter extends Component {

	renderLoader = (myStyles) => {
		return (
			<div
				className={css(myStyles.loaderContainer)}
				styleName="footer-container"
			>
				<div styleName="loader-content">
					<NextActivityLoader />
				</div>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);

		if (!this.props.isBatchLabelsAvailable) {
			return this.renderLoader(myStyles);
		}

		return (
			<div styleName="footer-container">
				<div className={css(myStyles.footerBackground)} styleName="footer-background"></div>
				<div className={css(myStyles.logoBackground)} styleName="logo-knolskape"></div>
				<div styleName="footer-content">
					<div className={css(myStyles.title)} styleName="title" dangerouslySetInnerHTML={{__html: this.props.getLabel('footer_learn_label')}}/>
				</div>
			</div>
		)
	}
}

export default JourneyFooter;
