import React, { Component } from 'react';
import styles from './targetArrow.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TargetArrow extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 24 24">
					<g fill="none" fillRule="evenodd" stroke="#313131" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" transform="translate(1)">
						<circle cx="7.625" cy="15" r="3.75"/>
						<circle cx="7.625" cy="15" r="7.5"/>
						<path d="M7.625 15l9-9M.125 23.25l2.585-2.585M15.125 23.25l-2.585-2.585M18.693 8.175a1.5 1.5 0 0 1-1.273.425l-2.97-.424-.424-2.97a1.5 1.5 0 0 1 .424-1.273L17.632.75l.906 3.337 3.337.906-3.182 3.182z"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default TargetArrow;
