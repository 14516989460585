import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules( { allowMultiple: true })
class BookSvg extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <svg width="100%" height="100%" viewBox="0 0 24 24">
                <path fill={this.props.fillColor} fill-rule="nonzero" d="M20.166 4.084a1.028 1.028 0 0 0-.781-.237L6.452 5.28c-.533-.025-.953-.447-.952-.96 0-.3 0-.753 1.193-.977 1.656-.314 10.839-1.423 10.932-1.43.54-.075.918-.551.85-1.07-.069-.52-.558-.892-1.1-.835C17 .054 8.03 1.133 6.307 1.458 3.865 1.92 3.5 3.45 3.5 4.32V19.2a3.654 3.654 0 0 0 1.137 2.749A3.975 3.975 0 0 0 7.5 23.04c.1 0 12.124-1.448 12.124-1.448.5-.06.876-.468.876-.952V4.8a.944.944 0 0 0-.334-.716z"/>
            </svg>
        );
    }
}



export default BookSvg;
