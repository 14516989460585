import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import styles from './quizScore.module.sass';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import ScoreComponent from 'commonComponents/scoreComponent';
import { hexToRgbA } from 'utils/styleUtil';
import Select from 'react-select'
import QuizScorePieChart from '../quizScorePieChart'
import QuizScoreBarChart from '../quizScoreBarChart';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class QuizScore extends Component {

	constructor(props) {
		super(props);
		this.state = {
			sortedSubmissions: [],
			selectedAttempt: null
		}
	}

	componentWillUnmount() {
		this.setState({
			sortedSubmissions: [],
			selectedAttempt: null
		});
		this.props.unsetQuizSubmissions()
	}

	componentDidMount() {
		if (!this.state.selectedAttempt && this.props.quiz.get('quizSubmissions')) {
			let sortedSubs = this.props.quiz.get('quizSubmissions').sort((s1, s2) => s2.get('attempt') - s1.get('attempt'));

			this.setState({
				sortedSubmissions: sortedSubs,
				selectedAttempt: sortedSubs.get(0)
			})
		}
	}

	selectQuizAttemptForAnalytics = (sub) => {

	}

	getLatestAttemptsScoreElements = () => {
		const styles = getSkin(this.props.skinGuide);
		let { quiz, moduleItem, getLabelWithDefaults } = this.props;
		let subs = quiz.get('quizSubmissions');
		let content = [];
		if (subs.size > 0) {
			let sortedSubs = subs.sort((s1, s2) => s2.get('attempt') - s1.get('attempt'));
			for (let i = 0; i < 3 && i < sortedSubs.size; i++) {
				content.push(
					<div key={`attempt_${sortedSubs.get(i).get('attempt')}_score`}>
						<div className={css(styles.attemptNumberLabel)}>
							{
								getLabelWithDefaults(
									'quiz_attempt_number_label',
									`Attempt ${sortedSubs.get(i).get('attempt')}`,
									{ attempt_number: sortedSubs.get(i).get('attempt') }
								)
							}
						</div>
						<div style={{ transform: 'scale(0.75)' }}>
							<ScoreComponent
								score={sortedSubs.get(i).get('score')}
								scoreColor={this.props.skinGuide.get('skinHex').get('white')}
								totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.5)}
								circleStrokeColor={this.props.skinGuide.get('skinHex').get('white')}
								fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.2)}
								maxPoints={moduleItem.get('moduleItem').get('content_details').get('points_possible')}
							/>
						</div>
					</div>
				)
			}
		}
		return content;
	}

	getLastAttemptsLabel = () => {
		let { quiz, getLabel } = this.props;
		let subs = quiz.get('quizSubmissions');
		if (subs.size > 1) return getLabel('quiz_your_last_attempts_label').replace('NUMBER_OF_ATTEMPTS', subs.size >= 3 ? 3 : subs.size);
		return getLabel('quiz_your_last_attempt_label')
	}

	handleAttemptChange = (attempt) => {
		if (attempt && attempt.value) {
			let selectedAttempt = this.state.sortedSubmissions.find(att => +att.get('attempt') === +attempt.value)
			this.setState({
				selectedAttempt: selectedAttempt
			});
		}
	}

	showQuizAttemptAnalytics = () => {
		const styles = getSkin(this.props.skinGuide);
		let { sortedSubmissions, selectedAttempt } = this.state;
		let attemptOptions = [];

		for (let i = 0; i < sortedSubmissions.size; i++) {
			attemptOptions.push({
				value: sortedSubmissions.get(i).get('attempt'),
				label: this.props.getLabelWithDefaults(
					'quiz_attempt_number_label',
					`Attempt ${sortedSubmissions.get(i).get('attempt')}`,
					{ attempt_number: sortedSubmissions.get(i).get('attempt') }
				)
			})
		}
		if (selectedAttempt) {
			return (
				<div styleName="quiz-review-cnt">
					{
						sortedSubmissions.size === 1 ?
							<span className={css(styles.resultsLabel)}>
								{
									this.props.getLabelWithDefaults(
										'quiz_score_results_label',
										'RESULTS'
									)
								}
							</span>
							:
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
								<span className={css(styles.resultsLabel)}>
									{
										this.props.getLabelWithDefaults(
											'quiz_result_for_label',
											'RESULT FOR'
										)
									}
								</span>
								<div style={{ minWidth: '250px', marginLeft: '20px' }}>
									<Select
										value={attemptOptions.find(a => +a.value === +selectedAttempt.get('attempt'))}
										options={attemptOptions}
										onChange={this.handleAttemptChange}
										isSearchable={false}
									/>
								</div>
							</div>
					}
					{
						this.showAnalytics(selectedAttempt)
					}
				</div>
			)
		}
	}

	showAnalytics = (attempt) => {
		if (attempt.get('workflow_state') === 'pending_review') {
			return (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70px' }}>
					This score is pending for review and may change.
				</div>
			)
		}

		if (!attempt.get('topics')) {
			return null;
		}

		let barChartData = [];
		let { isSidebarOpen } = this.props;
		let topicsLen = attempt.get('topics').size;
		barChartData = attempt.get('topics').map((topic) => {
			return {
				label: topic.get('topic_name'),
				percentage: topic.get('percentage')
			}
		})

		return (
			<div styleName={`quiz-analytics-charts-cnt${isSidebarOpen ? ' sidebar-open' : ''}`}>
				<div styleName="right-wrong-pie-chart-cnt">
					<QuizScorePieChart attempt={attempt} params={this.props.params} quiz={this.props.moduleItem.get('contentDetails')} />
				</div>
				{
					topicsLen != 0 &&
					<div styleName={`topics-bar-chart-cnt${isSidebarOpen ? ' sidebar-open' : ''}`}>
						<QuizScoreBarChart barChartData={barChartData} />
					</div>
				}
			</div>
		)
	}

	render() {
		const styles = getSkin(this.props.skinGuide);
		let { moduleItem, quizScore, getLabelWithDefaults } = this.props;
		let { selectedAttempt } = this.state;
		let allowedAttempts = moduleItem.get('contentDetails').get('allowed_attempts')
		let keptScoreLabel = getLabelWithDefaults('quiz_kept_score_highest', 'Highest Score');

		switch (moduleItem.get('contentDetails').get('scoring_policy')) {
			case "keep_highest":
				keptScoreLabel = getLabelWithDefaults('quiz_kept_score_highest', 'Highest Score');
				break;
			case "keep_latest":
				keptScoreLabel = getLabelWithDefaults('quiz_kept_score_latest', 'Latest Score');
				break;
			case "keep_average":
				keptScoreLabel = getLabelWithDefaults('quiz_kept_score_average', 'Average Score');
				break;
			default:
				keptScoreLabel = getLabelWithDefaults('quiz_kept_score_highest', 'Highest Score');
		}
		return (
			<div styleName="quiz-score-analytics-cnt">
				<div className={css(styles.quizScoreCnt)} styleName="quiz-score-cnt">
					<div className={css(styles.bestScoreCnt)} styleName="quiz-best-score-cnt" style={allowedAttempts === 1 ? { width: '100%', border: 'none' } : {}}>
						<ScoreComponent
							score={quizScore}
							scoreColor={this.props.skinGuide.get('skinHex').get('white')}
							totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.5)}
							circleStrokeColor={this.props.skinGuide.get('skinHex').get('white')}
							fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.2)}
							maxPoints={moduleItem.get('moduleItem').get('content_details').get('points_possible')}
						/>
						<div className={css(styles.bestScoreLabel)} styleName="best-score-label" style={{ color: 'white' }}>{
							allowedAttempts === 1 ? getLabelWithDefaults('quiz_your_score_label', 'Your Score') : keptScoreLabel
						}</div>
					</div>
					{
						(allowedAttempts != 1 && allowedAttempts != 0) &&
						<div styleName="last-attempts-score-cnt">
							<div style={{ color: 'white' }}>
								{this.getLastAttemptsLabel()}
							</div>
							<div styleName="last-attempts-scores">
								{this.getLatestAttemptsScoreElements()}
							</div>
						</div>
					}
				</div>

				{
					selectedAttempt &&
					this.showQuizAttemptAnalytics()
				}
			</div>
		)
	}
}

export default QuizScore;