import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './areaGraphWithInfo.module.sass';
import InfoAndDaysFilterComponent from 'commonComponents/analytics/infoAndDaysFilterComponent';
import AreaWithLine from 'commonComponents/graphs/areaWithLine';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class AreaGraphWithInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterBy: ''
        };
    }

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
            return false;
        }
        else{
            return true;
        }
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchTimeSpentPeriodicallyData(+batchId, +userId);
    }

    handleOnClickFilter = (event, filter) => {
        this.setState({
            filterBy: filter
        });
    }

    renderAreaGraph = () => {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        if(this.props.apiErrors.get('fetchTimeSpentPeriodicallyDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchTimeSpentPeriodicallyDataError')}
                retryHandler={()=>{
                    this.props.fetchTimeSpentPeriodicallyData(+batchId, +userId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('activity');

        if (allGraphsData.get('isTimeSpentPeriodicallyDataFetched')) {
            const graphData = allGraphsData.get('timeSpentPeriodicallyData');
            const strings = graphData.get('strings');
            const filterBy = this.state.filterBy ? this.state.filterBy : graphData.get('filtersEnabledObj').get('selectedFilter').toLowerCase();
            
            let filteredGraphData;

            if(graphData.get('isUserDataAvailable')) {
                filteredGraphData = graphData.get(filterBy).toJS();
            } else {
                filteredGraphData = graphData.get('week').toJS();
            }

            let xLabel, yLabel, timeFormat = '', valueName;
            if (filterBy === 'day') {
                xLabel = strings.get('days');
                valueName = strings.get('day');
            }
            else if (filterBy === 'week') {
                xLabel = strings.get('weeks');
                valueName = strings.get('week');
            }
            else if (filterBy === 'month') {
                xLabel = strings.get('months');
                valueName = strings.get('month');
            }

            switch (filteredGraphData.metadata.units) {
                case 'SECONDS':
                    yLabel = strings.get('timeInSecondssLabel');
                    break;
                case 'MINUTES':
                    yLabel = strings.get('timeInMinutesLabel');
                    break;
                case 'HOURS':
                    yLabel = strings.get('timeInHoursLabel');
                    break;
                case 'DAYS':
                    yLabel = strings.get('timeInDaysLabel');
                    break;
            }
            const graphFormat = {
                xLabel: xLabel,
                yLabel: yLabel,
                valueFormat: timeFormat,
                valuePrefix: '',
                valueName: valueName
            };
            
            const barStyling = {
                fromUser: 'rgba(254, 169, 40, 0.8)',
                toUser: 'rgba(254, 169, 40, 0.3)',
                userStroke: '#fea928',
                userRotation: 170,
                fromUserLegend: '#ffce23',
                toUserLegend: '#fea429',
                // fromGroup: 'rgba(254, 169, 40, 0.8)',
                // toGroup: 'rgba(254, 169, 40, 0.3)',
                // groupStroke: '#fea928',
                groupRotation: 180,
                // fromGroupLegend: '#ffce23',
                // toGroupLegend: '#fea429',
                gridValue: 9.5,
                componentName: 'summary-time',
                ...analyticGroupColors
            }

            return (
                [<InfoAndDaysFilterComponent
                    filterObject = {graphData.get('filtersEnabledObj')}
                    {...this.props}
                    title={strings.get('graphTitle')}
                    subtitle={strings.get('graphDesc')}
                    clickHandler={this.handleOnClickFilter}
                    strings={strings}
                    key="InfoAndDaysFilterComponent"
                />,
                <AreaWithLine
                    isUserDataAvailable={graphData.get('isUserDataAvailable')}
                    skinGuide={this.props.skinGuide}
                    graphFormat={graphFormat}
                    graphData={filteredGraphData}
                    barStyling={barStyling}
                    // data={this.props.data.timeData}
                    size={this.props.size}
                    strings={strings.toJS()}
                    key="AreaWithLine"
                />]
            );
        }
        return <LoaderComponent />
    }

    render(){
        return(
            <div styleName="container-class">
                <div styleName="area-graph-container">
                    <div styleName="area-graph">
                        {this.renderAreaGraph()}
                    </div>
                </div>
            </div>
        )
    }
}


export default AreaGraphWithInfo;