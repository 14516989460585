import FetchUser from 'actions/serverCalls/users/FetchUser';
import FetchEnterpriseUserBatches from 'actions/serverCalls/batches/FetchEnterpriseUserBatches';
import FetchEnterpriseBatches from 'actions/serverCalls/batches/FetchEnterpriseBatches';
import FetchRequestedJourneys from 'actions/serverCalls/batches/FetchRequestedJourneys';
import GetUserOrganization from 'actions/serverCalls/home/GetUserOrganization';
import FetchOrganizationWithCode from 'actions/serverCalls/journeys/FetchOrganizationWithCode';
import FetchJourneyCategories from 'actions/serverCalls/batches/FetchJourneyCategories';
import FetchJourneyTags from 'actions/serverCalls/journeys/FetchJourneyTags';
import FetchJourneyFormats from 'actions/serverCalls/batches/FetchJourneyFormats';
import {
	setFetchingFilteredBatchesFlag,
	setRequestingJourneyAccess
} from './actionCreators';
import {
	setUserOrganizationLoading,
	setOrganizationLevelUserStatsLoading
} from 'actions/home/actionCreators';
import { fetchJourneyCatalogueData } from 'actions/journeys/apiActions';
import RequestJourneyAccess from 'actions/serverCalls/journeys/RequestJourneyAccess';
import CancelJourneyRequest from 'actions/serverCalls/journeys/CancelJourneyRequest';
import FetcJourneyInstanceDetails from 'actions/serverCalls/journeys/FetcJourneyInstanceDetails';
import GetOrganizationLevelUserStats from 'actions/serverCalls/home/GetOrganizationLevelUserStats';
import { fetchBatchesFeedbackStatusFlags } from 'actions/batchFeedbackStatus/apiActions';
import { setBatchesFeedbackStatusFlags } from 'actions/batchFeedbackStatus/actionCreators';
import ShowJourneyInterest from "actions/serverCalls/journeys/ShowJourneyInterest";
import RemoveJourneyInterest from "actions/serverCalls/journeys/RemoveJourneyInterest";
import { fetchCorpDeckJourneyCatalogueData } from '../journeys/apiActions';
import FetchJourneyDetails from '../serverCalls/journeys/FetchJourneyDetails';

const fetchUserAndOrganizationThunk = (dispatch, getState) => {
	FetchJourneyCategories.call();
	FetchJourneyFormats.call();
	FetchJourneyTags.call();
	FetchUser.call()
		.then(() => {
			dispatch(setUserOrganizationLoading(true));
			GetUserOrganization.call();
		})
};

const fetchFileteredUserBatches = (durationStatus, dispatch, getState) => {
	dispatch(setFetchingFilteredBatchesFlag(durationStatus.durationStatus));
	return FetchEnterpriseUserBatches.call(durationStatus)
		.then((response) => {
			const userBatchesList = response.batches || [];
			const batchIdsArray = userBatchesList.map((userBatch) => userBatch.batchId);
			const batchesListApiInfoObject = {
				batchIdsArray,
				durationStatus: durationStatus.durationStatus
			}
			FetchEnterpriseBatches.call(batchesListApiInfoObject);
			return batchIdsArray;
		})
}

const fetchActiveUserBatches = () => (dispatch, getState) => {
	fetchFileteredUserBatches({ durationStatus: "ONGOING" }, dispatch, getState);
}

const fetchUpcomingUserBatches = () => (dispatch, getState) => {
	fetchFileteredUserBatches({ durationStatus: "UPCOMING" }, dispatch, getState);
}

const fetchCompletedUserBatches = () => (dispatch, getState) => {
	return fetchFileteredUserBatches({ durationStatus: "COMPLETED" }, dispatch, getState);
}

const fetchExpiredUserBatches = () => (dispatch, getState) => {
	fetchFileteredUserBatches({ durationStatus: "EXPIRED" }, dispatch, getState);
}

const fetchAllUserBatches = () => (dispatch, getState) => {
	fetchFileteredUserBatches({ }, dispatch, getState);
}

const fetchRequestedUserBatches = (orgId) => (dispatch, getState) => {
	FetchRequestedJourneys.call({ queryParameters: { orgId: orgId, include: "REQUESTED,REJECTED" } })
}

const fetchUserAndOrganization = () => (dispatch, getState) => fetchUserAndOrganizationThunk(dispatch, getState)

const fetchUserAndOrganizationAndUserJourneys = () => (dispatch, getState) => {
	dispatch(setOrganizationLevelUserStatsLoading(true));
	GetOrganizationLevelUserStats.call().then(() => {
		dispatch(setOrganizationLevelUserStatsLoading(false));
	})
	FetchJourneyCategories.call();
	FetchJourneyFormats.call();
	FetchJourneyTags.call();
	FetchUser.call()
		.then(() => {
			dispatch(setUserOrganizationLoading(true));
			GetUserOrganization.call()
				.then((organization) => {
					const homePageStyle = organization.homePageStyle.name
					switch (homePageStyle) {
						case "styleA":
							dispatch(fetchAllUserBatches())		
							break;
						default:
							dispatch(fetchRequestedUserBatches(organization.id))
							dispatch(fetchActiveUserBatches());
							dispatch(fetchUpcomingUserBatches());
							dispatch(fetchCompletedUserBatches());
							dispatch(fetchExpiredUserBatches());
							break;
					}
				});
		})
}

const fetchDownloadsSectionData = () => (dispatch, getState) => {
	FetchUser.call()
		.then(() => {
			dispatch(setUserOrganizationLoading(true));
			GetUserOrganization.call();
			dispatch(fetchCompletedUserBatches()).then(batchIdsArray => {
				console.log('batchIdsarray', batchIdsArray);
				if(batchIdsArray && batchIdsArray.length > 0){
					dispatch(fetchBatchesFeedbackStatusFlags(batchIdsArray));
				} else{
					dispatch(setBatchesFeedbackStatusFlags(null));
				}
			});
		})
}

const requestJourneyAccess = (payload, searchText) => (dispatch, getState) => {
	dispatch(setRequestingJourneyAccess(true))
	RequestJourneyAccess.call({
		body: payload
	}).then(() => {
		FetcJourneyInstanceDetails.call({ journeyInstanceId: payload[0].journeyInstanceId })
			.then(() => {
				GetOrganizationLevelUserStats.call();
				dispatch(fetchJourneyCatalogueData(false, searchText));
				dispatch(fetchRequestedUserBatches(getState().getIn(['userOrganization', 'userOrganization', 'id'])))
				dispatch(setRequestingJourneyAccess(false));
			})
	})
}

const cancelJourneyAccessRequest = (payload, searchText) => (dispatch, getState) => {
	dispatch(setRequestingJourneyAccess(true));
	CancelJourneyRequest.call({
		body: payload.map(instances => instances.journeyInstanceId)
	}).then(() => {
		FetcJourneyInstanceDetails.call({ journeyInstanceId: payload[0].journeyInstanceId })
			.then(() => {
				dispatch(fetchJourneyCatalogueData(false, searchText));
				dispatch(fetchRequestedUserBatches(getState().getIn(['userOrganization', 'userOrganization', 'id'])))
				dispatch(setRequestingJourneyAccess(false));
			})
	})
}

const showJourneyInterest = (payload, searchText) => (dispatch, getState) => {
	ShowJourneyInterest.call({
		body: payload
	}).then(() => {
		FetchJourneyDetails.call({ journeyId: payload.journeyIds[0] })
		.then(() => {
			dispatch(fetchCorpDeckJourneyCatalogueData(searchText));
			// dispatch(fetchRequestedUserBatches(getState().getIn(['userOrganization', 'userOrganization', 'id'])))
		})
	})
}

const removeJourneyInterest = (payload, searchText) => (dispatch, getState) => {
	RemoveJourneyInterest.call({
		body: payload
	}).then(
		FetchJourneyDetails.call({ journeyId: payload.journeyIds[0] })
		.then(() => {
			dispatch(fetchCorpDeckJourneyCatalogueData(searchText));
			// dispatch(fetchRequestedUserBatches(getState().getIn(['userOrganization', 'userOrganization', 'id'])))
	})
	)
}

const fetchOrganizationWithCode = (orgCode) => (dispatch, getState) => {
	FetchJourneyCategories.call();
	FetchJourneyFormats.call();
	FetchJourneyTags.call();
	dispatch(setUserOrganizationLoading(true));
	FetchOrganizationWithCode.call({ queryParameters: { orgCode } })
}


const fetchUserJourneys = (orgId) => (dispatch, getState) => {
	dispatch(fetchActiveUserBatches());
	dispatch(fetchUpcomingUserBatches());
	dispatch(fetchCompletedUserBatches());
	dispatch(fetchExpiredUserBatches());
	dispatch(fetchRequestedUserBatches(orgId));
}

export {
	fetchUserAndOrganization,
	fetchFileteredUserBatches,
	fetchActiveUserBatches,
	fetchUpcomingUserBatches,
	fetchCompletedUserBatches,
	fetchRequestedUserBatches,
	fetchExpiredUserBatches,
	fetchUserAndOrganizationAndUserJourneys,
	requestJourneyAccess,
	fetchOrganizationWithCode,
	fetchUserJourneys,
	cancelJourneyAccessRequest,
	fetchDownloadsSectionData,
	showJourneyInterest,
	removeJourneyInterest
}