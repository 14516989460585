import React, { Component } from 'react';
import styles from './productDuration.module.sass';
import CSSModules from 'react-css-modules';
import Duration from 'commonComponents/duration';
//import SecondaryButton from 'commonComponents/buttons/secondaryButton';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import { convertDateWithYear } from 'utils/utilFunctions';
import { hexToRgbA } from 'utils/styleUtil';
import ScoreComponent from 'commonComponents/scoreComponent';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ProductDuration extends Component {

	renderScoreComponent = (progress, item, containerStyle) => {

		if(!progress) return null;

		const score = progress.get('score');
		const maxScore = +item.getIn(['settings', 'maxScoreSCORM']);

		if(!maxScore) return null;

		if(!score){
			return (<div styleName={containerStyle}><span 
				title={`You can earn a max of ${progress.get('maxScore')} points in this content.`}>
				<div styleName="score-points">
					{maxScore}
				</div>
				<div styleName="score-text">
					{this.props.getLabel('points_label')}
				</div>
			</span></div>);
		}

        return <div styleName="score-component-cnt"><ScoreComponent
			score={score}
			maxPoints={maxScore}
			scoreColor={this.props.skinGuide.get('skinHex').get('secondaryColor')}
			totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.5)}
			circleStrokeColor={this.props.skinGuide.get('skinHex').get('greyColor2')}
			fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.2)}
		/></div>;
    }


	render() {
		 
		const { getLabel, selectedItem, isSideBarOpen, selectedItemProgress } = this.props;

		const containerStyle = (isSideBarOpen) ? 'duration-container-row' : 'duration-container';
		const itemStyle = (isSideBarOpen) ? 'item-row' : 'item-column';
		return (
			<div styleName={containerStyle}>
				{this.renderScoreComponent(selectedItemProgress, selectedItem, containerStyle)}
				<div styleName={itemStyle}>
					<Duration
						title={selectedItem.get('durationLabel')}
						time={selectedItem.get('duration')}
						/>
				</div>
				<div styleName={itemStyle}>
					<Duration
						title={getLabel('due_date_label')}
						time={convertDateWithYear(selectedItem.get('endTimestamp'))}
						text={' '} />
				</div>
				<div styleName={itemStyle}>
					<Duration
						title={getLabel('score_label')}
						time={convertDateWithYear(selectedItem.get('endTimestamp'))}
						text={' '} />
				</div>
				{/* {
					(selectedItem.get('allowDemo'))
						?
						<div styleName={itemStyle}>
							<SecondaryButton text={getLabel('try_demo_label')} />
						</div>
						:
						null
				} */}
			</div>
		);
	}
}

export default ProductDuration;
