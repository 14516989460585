import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { removeRequestedJourneyFromAvailableJourneys, updateSelectedJourneyInstance } from 'actions/journeys/actionCreators';
import { setRequestingJourneyAccess } from 'actions/userJourneys/actionCreators';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';

class RemoveJourneyInterest extends NetPackWrapper {

	constructor() {
		super("DELETE");
	}

	prepareUrl = (apiInfoObject = {}) => urls.get('PLATFORM').get('REMOVE_JOURNEY_INTEREST');

	successCall = (response, apiInfoObject) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_SUCCESS'),
			type: toastConstants.get('TYPE_SUCCESS'),
			heading: 'Thank You!',
			message: "We have removed your interest for this course.",
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));

		return response;
	}

	onError = (error) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: error.errorObject.response.message,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		this.dispatch(setRequestingJourneyAccess(false));
		sendUserToInvalidPage();
	}
}

export default new RemoveJourneyInterest();
