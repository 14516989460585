import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';

class SaveSystemCheckResult extends NetPackWrapper {

    constructor() {
        super("POST");
    }

    prepareUrl = (apiInfoObject = {}) => urls.get('PLATFORM').get('SAVE_SYSTEM_CHECK_RESULTS').replace(
        'USER_ID',
        apiInfoObject.userId
    )

    successCall = (response, apiInfoObject) => {
        return response;
    }

    onError = (error) => {
        console.log('error', error);
    }

}

export default new SaveSystemCheckResult();
