import React, { Component } from 'react';
import styles from './overallPerformance.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import ScoreComponent from 'commonComponents/scoreComponent';
import { hexToRgbA } from 'utils/styleUtil';
import { getCompetencyText, getCompetencyBackground, checkIfPresent } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class OverallPerformance extends Component {

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const { userDetails } = this.props;
		const competencyText = getCompetencyText(userDetails.get('overallScore'), this.props.getLabel);
		const averageScore = Math.round(userDetails.get('overallScore') * 100) / 100;
		const scoreScale = 5;
		// const totalAssessments = userDetails.get('totalCount');
		const totalAssessments = this.props.itemCount;

		return (
			<div styleName="overall-container">
				<div className={css(myStyles.overallHeading)} styleName="overall-heading">
					{this.props.getLabel('assessment_summary_label')}
				</div>
				<div className={css(myStyles.overallContent)} styleName="overall-content">
					<div styleName="overall-score">
						<ScoreComponent
							score={userDetails.get('overallScore')}
							scoreColor={this.props.skinGuide.get('skinHex').get('white')}
							totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('white'), 0.5)}
							text={competencyText}
							textBackground={getCompetencyBackground(userDetails.get('overallScore'))}
						/>
					</div>
					<div styleName="overall-desc">
						<div className={css(myStyles.descTitle)} styleName="desc-title">
							{this.props.getLabel('overall_performance_label')}
						</div>
						<div className={css(myStyles.descSubTitle)} styleName="desc-sub-title">
							{this.props.getLabel('competency_score_message').replace(
								'AVERAGE_SCORE',
								averageScore.toFixed(2)
							).replace(
								'SCORE_SCALE',
								scoreScale
							).replace(
								'TOTAL_COUNT',
								checkIfPresent(totalAssessments) ? totalAssessments : ""
							)}
						</div>
					</div>
					<div styleName="social-box"></div>
				</div>
			</div>
		);
	}
}

export default OverallPerformance;
