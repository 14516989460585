import Immutable from 'immutable';

const competencyScale = Immutable.fromJS([
	{
		name: 'areas_of_improvements_label',
		text: 'areas_of_improvements_label',
		min: 0,
		max: 2.50,
		color: '#d0021b'
	},
	{
		name: 'areas_of_growth',
		text: 'areas_of_growth',
		min: 2.51,
		max: 3.50,
		color: '#f5a623'
	},
	{
		name: 'areas_of_strength',
		text: 'areas_of_strength',
		min: 3.51,
		max: 5,
		color: '#7ed321'
	}
]);

export default competencyScale;	