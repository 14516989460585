import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		quizDetailsColumn: {
			borderLeft: `solid 1px ${hexToRgbA('#d5d8e2', 0.5)}`,
			borderRight: `solid 1px ${hexToRgbA('#d5d8e2', 0.5)}`
		},
		quizDetailsHeader: {
			...myTypography.button,
			fontWeight: 'normal',
			color: `${hexToRgbA('#5b5c61', 0.8)}`,
			borderBottom: `solid 1px ${hexToRgbA('#d5d8e2', 0.6)}`
		},
		quizDetailsInnerCnt: {
			...myTypography.h6,
			color: '#5b5c61'
		},
		topicsHeader: {
			...myTypography.body1,
			fontWeight: 600,
			lineHeight: 1.56,
			color: hexToRgbA(skinHex.get('greyColor2'), 0.7)
		},
		topicCapsule: {
			...myTypography.button,
			lineHeight: 2,
			color: `${hexToRgbA('#9a9eaf', 0.9)}`,
			fontWeight: 'normal',
			backgroundColor: skinHex.get('white')
		},
		attemptsLeft: {
			...myTypography.button,
			fontWeight: 'normal',
			lineHeight: 1.5,
			color: skinHex.get('secondaryColor')
		},
		disabledBtnText: {
			...myTypography.button,
			color: skinHex.get('white'),
			lineHeight: '48px'
		},
		timerTime: {
			...myTypography.button,
			fontWeight: 'normal',
			lineHeight: 'normal',
			color: skinHex.get('secondaryColor')
		}
	});
}

export default getSkin;