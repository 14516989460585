/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './MenuListLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';
import { css } from 'aphrodite/no-important';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class MenuListLoader extends React.Component {

	renderListItemLoader = (loadersDefaultconfig) => {
		return (
			<ContentLoader
				height={98}
				width={321}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '98px', width: '100%' }}
			>
				<rect x="44" y="10" rx="2" ry="2" width="60" height="10" />
				<circle cx="24" cy="48" r="8" />
				<rect x="44" y="35" rx="2" ry="2" width="200" height="24" />
				<rect x="44" y="97" rx="2" ry="2" width="260" height="3" />
			</ContentLoader>
		);
	}

	renderHeaderLoader = (loadersDefaultconfig, myStyles) => {
		return (
			<div className={css(myStyles.backgroundWhite)}>
				<ContentLoader
					height={62}
					width={321}
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '62px', width: '100%' }}
				>
					<circle cx="30" cy="34" r="10" />
					<rect x="78" y="21" rx="2" ry="2" width="166" height="24" />
					<circle cx="285" cy="34" r="10" />
				</ContentLoader>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<div styleName="loader-container">
				{this.renderHeaderLoader(loadersDefaultconfig, myStyles)}
				{this.renderListItemLoader(loadersDefaultconfig)}
				{this.renderListItemLoader(loadersDefaultconfig)}
			</div>
		);
	}
}

export default MenuListLoader;
