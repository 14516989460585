import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import AssessmentBackground from 'images/assessment-title-bg.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        progressItem:{
            borderRadius: '4px',
            backgroundColor: skin.get('greyColor6')
        },
        border:{
            // backgroundImage: `linear-gradient(to left, ${skin.get('secondaryColor')}, #ffc4e2 49%, ${skin.get('secondaryColor')})`,
            backgroundColor: skin.get('secondaryColor')
        },
        progressText:{
            color: hexToRgbA(skin.get('greyColor1'), 0.85),
            ...myTypography.overline
        },
        borderMoving: {
            boxShadow: `0 0 40px 15px ${skin.get('white')}`
        }
    });
}

export default getSkin;