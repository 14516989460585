import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreCardInfoComponent.module.sass';
import ScoreCardComponent from 'commonComponents/analytics/scoreCardComponent';

@CSSModules(styles, { allowMultiple: true })

class ScoreCardInfoComponent extends React.Component {

    render(){
        const fontStyling = {
            fontSize: '20px',
            color: '#979eb7',
            fontWeight: 400
        }

        const allGraphsData = this.props.allGraphsData.get('performance').get('overallPerformanceGraphData');

        const strings = allGraphsData.get('strings');
        const rankCardData = {
            isUserScoreAvailable: allGraphsData.get('isUserDataAvailable'),
            cardTitle: strings.get('rankLabel'),
            showSubscore: false,
            value: allGraphsData.get('userRank').toJS(),
        }
        
        const scoreCardData = {
            isUserScoreAvailable: allGraphsData.get('isUserDataAvailable'),
            cardTitle: strings.get('scoreLabel'),
            showSubscore: true,
            value: allGraphsData.get('userScore').toJS(),
        }


        return(
            <div style={fontStyling} styleName="container-class">
                {/* <div styleName="title-container">
                    Your Rank and Score
                </div> */}
                <div styleName="outer-container">
                    <div styleName="inner-content">
                        <ScoreCardComponent 
                            strings={strings}
                            cardData={rankCardData}
                            {...this.props}/>
                    </div>
                    <div styleName="inner-content">
                        <ScoreCardComponent
                            strings={strings}
                            cardData={scoreCardData}
                            showSubscore={true}  
                            {...this.props}/>
                    </div>
                </div>
            </div>
        )
    }
}
 

export default ScoreCardInfoComponent;