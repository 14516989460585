import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        assignmentSubmissionChooseInstructionLabel:{
            ...myTypography.h5,
           color: skin.get('greyColor2'),
           fontFamily: theme.get('fontFamily').get('bodyFamily2'),
           fontWeight: 300
        },
        assignmentTextAreaContainer: {
            borderTop: `4px solid ${skin.get('primaryColor')}`
        },
        textArea: {
            ...myTypography.body2,
            borderBottom: `1px solid ${skin.get('greyColor5')}`,
            color: skin.get('black'),
            lineHeight: 'normal',
            '::placeholder': {
                ...myTypography.body2,
                color: skin.get('greyColor4'),
                lineHeight: 'normal'
            }
        },
        submissionHeading: {
            ...myTypography.h5,
            fontWeight: 600,
            color: 'rgba(9, 16, 39, 0.85)',
            '@media screen and (max-width: 480px)': {
                fontSize: '20px'
            }
        },
        successMessage: {
            ...myTypography.body2,
            color: 'rgb(46, 79, 180)',
            lineHeight: 'normal'
        },
        submissionTypeLabel: {
            ...myTypography.body2,
            fontWeight: 600,
            color: skin.get('primaryVariantColor')
        },
        submissionTypeCnt: {
            border: `solid 1px ${hexToRgbA(skin.get('greyColor2'), 0.2)}`
        },

        uploadButton: {
            ...myTypography.button,
            border: 'solid 1px #d44980cc',
            color: skin.get('secondaryColor')
        },

        uploadContainer: {
            borderBottom: `solid 0.5px ${skin.get('greyColor5')}`,
            borderTop: `4px solid ${skin.get('primaryColor')}`
        },

        uploadInstruction: {
            ...myTypography.caption,
            color: skin.get('greyColor4')
        },

        uploadedFileName: {
            ...myTypography.caption,
            color: '#091027d9'
        },

        loadingText: {
            ...myTypography.button,
            color: '#abadb5',
            lineHeight: 'normal'
        },

        assignmentNLPInstructionLabel: {
            ...myTypography.h5,
            color: skin.get('greyColor2'),
            fontFamily: theme.get('fontFamily').get('bodyFamily2'),
            fontWeight: 300
        }

    });
}

export default getSkin;