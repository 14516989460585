import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        card: {
            borderRadius: '4px',
            backgroundColor: skin.get('white'),
            boxShadow: `0 3px 14px 0 ${hexToRgbA(skin.get('black'), 0.5)}`
        },
        cardTitle: {
            ...myTypography.h5,
            color: hexToRgbA(skin.get('greyColor1'), 0.85)
        },
        cardDesc: {
            ...myTypography.body2,
            color: hexToRgbA(skin.get('greyColor1'), 0.85)
        }
    });
}

export default getSkin;