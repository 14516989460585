import React from 'react';
import { connect } from 'react-redux';
import Quiz from 'components/moduleItems/quiz';
import GenericErrorBoundary from 'components/errorBoundaries';
import { getQuizSubmissions, completeQuiz } from 'actions/quiz/apiActions';
import { unsetQuizSubmissions } from 'actions/quiz/actionCreators';
import Immutable from 'immutable';

class QuizContainer extends React.Component {
	componentDidMount() {
		this.fetchQuizSubmissions(this.props);
	}

	fetchQuizSubmissions = (props) => {
		this.props.getQuizSubmissions({
			...this.props.match.params,
			quizId: this.props.moduleItem.get('moduleItem').get('content_id')
		});
	}

	componentWillReceiveProps(nextProps) {
		// if(this.props.match.params.itemId != nextProps.match.params.itemId){
		// this.props.getQuizSubmissions();
		// }
	}

	componentWillUnmount() {
		this.props.unsetQuizSubmissions()
	}

	render() {
		let { quiz } = this.props;
		// console.log("quiz container")
		return (
			<GenericErrorBoundary>
				<div>
					{
						// quiz.get('isQuizDataAvailable') ?
						<Quiz {...this.props} fetchQuizSubmissions={() => {
							this.fetchQuizSubmissions(this.props);
						}} />
						// :	 "Loading..."
					}
				</div>
			</GenericErrorBoundary>

		);
	}
}

QuizContainer.propTypes = {};

QuizContainer.defaultProps = {};


const mapStateToProps = (state) => {
	return {
		quiz: Immutable.fromJS(state.get('quiz')),
		fetchQuizError: state.get('apiErrors').get('fetchQuizError')
	}
};

const mapDispatchToProps = (dispatch) => ({
	getQuizSubmissions: (params) => {
		dispatch(getQuizSubmissions(params));
	},
	unsetQuizSubmissions: () => {
		dispatch(unsetQuizSubmissions())
	},
	completeQuiz: (payload) => {
		dispatch(completeQuiz(payload));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(QuizContainer);
