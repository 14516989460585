import React, { Component } from 'react';
import styles from './resetIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ResetIcon extends Component {
	render() {
		const fillColor = this.props.fillColor || "#1A1A1A";
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 19 16">
					<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
						<g transform="translate(-12.000000, -545.000000)" fill="#000000">
							<path d="M18.0409338,553.642785 C18.3563675,556.100614 20.456371,558 23,558 C25.7614237,558 28,555.761424 28,553 C28,550.238576 25.7614237,548 23,548 C22.4617273,548 21.9433208,548.085057 21.4574878,548.242464 L20.0741365,545.55194 C20.9803576,545.195663 21.967329,545 23,545 C27.418278,545 31,548.581722 31,553 C31,557.418278 27.418278,561 23,561 C19.0676427,561 15.7979518,558.162788 15.1263167,554.423755 L12.2900969,555.183717 L14.972171,548.10467 L20.8344389,552.894268 L18.0409338,553.642785 Z" />
						</g>
					</g>
				</svg>
			</div>
		);
	}
}

export default ResetIcon;