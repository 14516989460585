import React, { Component } from 'react';
import styles from './infoIcon.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class InfoIcon extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 16 16">
					<g fill="none" fillRule="evenodd" stroke={this.props.strokeColor || "#313131"} strokeLinecap="round" strokeLinejoin="round" opacity={this.props.iconOpacity || ".5"}>
						<path d="M9.5 11H9a1 1 0 0 1-1-1V7.5a.5.5 0 0 0-.5-.5H7M7.5 4.75a.25.25 0 1 0 .5 0 .25.25 0 0 0-.5 0z"/>
						<path d="M13.303 2.697A7.5 7.5 0 1 1 2.697 13.303 7.5 7.5 0 0 1 13.303 2.697"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default InfoIcon;
