import React, { Component } from 'react';
import styles from './developmentPhaseModuleContent.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import ModuleCard from './moduleCard';
import PhaseItemCardLoader from 'commonComponents/loaders/phaseItemCardLoader';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import { css } from 'aphrodite/no-important';
import getSkin from './skin';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import LockIcon from 'svgComponents/lockIcon';
import enumConstants from 'constants/enums';
import { filterObjectFromArray } from 'utils/objectUtils';
import CompletedFeedbackIcon from 'svgComponents/completedFeedbackIcon';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DevelopmentPhaseModuleContent extends Component {

    componentDidMount() {
        this.fetchModules();
    }

    fetchModules = () => {
        const onlyPhaseItem = this.props.phaseItems.get(0)
        this.props.fetchModulesForDashboard({
            batchId: this.props.match.params.batchId,
            phaseId: this.props.userPhase.get('id'),
            courseId: onlyPhaseItem.get('toolId'),
            phaseItemId: onlyPhaseItem.get('id')
        });
    }

    getActiveModule = (course) => {
        let activeModule = null;
        let moduleWithAtleastOneRequiredItem = course.get('courseModulesList').filter(module => module.get('required_items_count') > 0)

        activeModule = moduleWithAtleastOneRequiredItem.find(module => module.get('required_items_count') != module.get('items_completed_count'))

        return activeModule;
    }


    redirectToCourseFeedback = () => {
        if (!this.shouldEnableFeedback()) {
            return false;
        }
        const route = routes.get('BATCH_ITEM_COURSE_FEEDBACK');
        const onlyPhaseItem = this.props.phaseItems.get(0)
        const params = [
            { 'BATCH_ID': this.props.match.params.batchId },
            { 'COURSE_ID': onlyPhaseItem.get('toolId') },
            { '_PHASE_ID_': this.props.userPhase.get('id') },
            { '_PHASEITEMID_': onlyPhaseItem.get('id') }
        ];
        const routeObj = { route, params };
        updateRoute(routeObj)
    }

    shouldEnableFeedback = () => {
        const { userItems } = this.props;
        const onlyPhaseItem = this.props.phaseItems.get(0);
        const userPhaseItem = filterObjectFromArray(userItems, 'id', onlyPhaseItem.get('id'));
        if (onlyPhaseItem.get('isLocked') ||
            (onlyPhaseItem.get('isExpired') && (userPhaseItem && userPhaseItem.get('workflowStatus') !== enumConstants.get('COMPLETED')))
        ) {
            return false;
        }
        return true;
    }

    render() {

        const { coursesModulesList, userPhase, match, apiErrors, feedbackStatuses } = this.props;
        let moduleCards = [];
        const onlyPhaseItem = this.props.phaseItems.get(0);
        const myStyles = getSkin(this.props.skinGuide);
        let shouldEnableFeedback = this.shouldEnableFeedback()

        if (apiErrors.get('fetchModulesError')) {
            return <APIErrorBoundary
                key={userPhase.get('id')}
                apiError={apiErrors.get('fetchModulesError')}
                retryHandler={this.fetchModules}
            />
        }

        let course = null;
        try {
            course = coursesModulesList.find(course => course.get('id') == onlyPhaseItem.get('toolId'));
        } catch (error) {
            console.log('error:', error);
        }


        let hideConnector = false;
        if (course && course.get('isCourseModulesListAvailable')) {
            const activeModule = this.getActiveModule(course);
            moduleCards = course.get('courseModulesList').map((module, index) => {
                if (index === course.get('courseModulesList').size - 1) {
                    hideConnector = true
                }
                return <ModuleCard
                    module={module}
                    isActiveModule={activeModule && activeModule.get('id') === module.get('id')}
                    batchId={match.params.batchId}
                    phaseId={userPhase.get('id')}
                    phaseItemId={onlyPhaseItem.get('id')}
                    hideConnector={hideConnector}
                    setUserState={this.props.setUserState}
                    userPhase={this.props.userPhase}
                    key={module.get('id')}
                    activeItemId={this.props.activeItemId}
                    phases={this.props.phases}
                    setItemWorkflowStatus={this.props.setItemWorkflowStatus}
                    setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
                    onlyPhaseItem={onlyPhaseItem}
                    userItems={this.props.userItems}
                    toggleDialog={this.props.toggleDialog}
                    index={index}
                />;
            });
        }
        const courseFeedbackStatus = feedbackStatuses.get('phaseItemFeedbackStatuses').find(phaseItemFeedback => phaseItemFeedback.get('id') === onlyPhaseItem.get('id'));

        return (
            course && course.get('isCourseModulesListAvailable') ?
                <div styleName="module-cards-cnt">
                    {moduleCards}
                    {
                        onlyPhaseItem.getIn(['settings', 'isFeedbackEnabled']) === 'true' &&
                        onlyPhaseItem.getIn(['settings', 'feedbackScenarioId']) &&
                        <div styleName="feedback-link-cnt"
                            onClick={this.redirectToCourseFeedback}
                        >
                            <div className={css(myStyles.feedbackLinkText)} styleName="feedback-text"
                                style={shouldEnableFeedback ? { cursor: 'pointer' } : { cursor: 'not-allowed' }}
                            >
                                <div styleName="feedback-icon-cnt">
                                    {
                                        shouldEnableFeedback ?
                                            (
                                                courseFeedbackStatus && courseFeedbackStatus.get('status') ?
                                                    <CompletedFeedbackIcon />
                                                    :
                                                    <FeedbackIcon />
                                            )
                                            :
                                            <LockIcon svgColor={this.props.skinGuide.getIn(['skinHex', 'greyColor4']) || '#979eb7'} />
                                    }
                                </div>
                                {
                                    courseFeedbackStatus && courseFeedbackStatus.get('status') ?
                                        this.props.getLabel('thank_you_note_after_feedback')
                                        :
                                        this.props.getLabel('like_to_hear_from_you_label')
                                }
                            </div>
                            <div styleName="feedback-dot"></div>
                            <div styleName="feedback-connector"></div>
                        </div>
                    }
                </div>
                :
                <div styleName="single-phase-container">
                    <div>
                        <PhaseItemCardLoader />
                        <PhaseItemCardLoader />
                        <PhaseItemCardLoader />
                    </div>
                </div>
        );
    }
}

export default DevelopmentPhaseModuleContent;
