import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './tabs.module.sass';
import { TweenMax } from 'gsap';
import applySkin from 'utils/SkinProvider'
import { css } from 'aphrodite/no-important';
import getSkin from './skin';
import LeftArrow from 'svgComponents/leftArrow';
import RightArrow from 'svgComponents/rightArrow';

let defaultTabsStyles = {
	width: 'calc(100% - 40px)',
	height: '100%'
};

let defaultTabStyles = {
	height: '100%',
	padding: '0 5px',
	margin: '0 10px',
	maxWidth: '250px'
};

@applySkin
@CSSModules(styles, { allowMultiple: true })
class Tabs extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			tabsContainerRef: React.createRef(),
			scrollWidth: 300,
			selectedTabValue: null,
			showLeftArrow: true,
			showRightArrow: true
		}
	}

	componentDidMount() {
		if(this.props.tabsList[0]){
			let selectedTabValue = this.props.tabsList[0].value;
			if (this.props.selectedTabValue) {
				selectedTabValue = this.props.selectedTabValue;
			}
			this.updateSelectedTabValue(selectedTabValue);
			this.checkAndSetArrowVisibility();
		}
	}

	componentWillReceiveProps(nextProps) {
		if(nextProps.tabsList[0]){
			let selectedTabValue = nextProps.tabsList[0].value;
			if (nextProps.selectedTabValue) {
				selectedTabValue = nextProps.selectedTabValue;
			}
			this.updateSelectedTabValue(selectedTabValue);
			this.checkAndSetArrowVisibility();
		}
	}

	checkAndSetArrowVisibility = () => {
		const currentDiv = this.state.tabsContainerRef.current;
		let updatedState = {
			showLeftArrow: true,
			showRightArrow: true
		};

		if (currentDiv.scrollLeft === 0) {
			updatedState.showLeftArrow = false;
		}
		if ((currentDiv.scrollLeft + currentDiv.clientWidth) === currentDiv.scrollWidth) {
			updatedState.showRightArrow = false;
		}

		this.setState(updatedState);
	}

	updateSelectedTabValue = (selectedTabValue) => {
		this.setState({ selectedTabValue });
	}

	onClickArrow = (arrowType = 'left') => {
		const currentDiv = this.state.tabsContainerRef.current;
		let scrollLeft = currentDiv.scrollLeft + this.state.scrollWidth;
		if (arrowType === 'left') {
			scrollLeft = currentDiv.scrollLeft - this.state.scrollWidth
		}

		TweenMax.to(currentDiv, 1, {
			scrollLeft,
			onUpdate: () => this.checkAndSetArrowVisibility()
		});
	}

	onTabClick = (selectedTabValue) => {
		this.updateSelectedTabValue(selectedTabValue);
		this.props.tabClickHandler(selectedTabValue);
	}

	renderTabs = (myStyles) => {
		const tabStyles = {
			...defaultTabStyles,
			...this.props.tabStyles
		};
		return this.props.tabsList.map(tab => {
			const tabContainerStyle = tab.value === this.state.selectedTabValue
				? "tab-container"
				: "tab-container unselected-tabs";
			return (
				<div
					styleName={tabContainerStyle}
					style={tabStyles}
					key={tab.value}
					className={css(myStyles.tabContainer)}
					onClick={this.onTabClick.bind(this, tab.value)}
					title={tab.label}
				>
					<span styleName="tab-content">
						{tab.label}
					</span>
				</div>
			);
		});
	}

	renderLeftArrow = () => {
		if (!this.state.showLeftArrow) {
			return null;
		}
		return (
			<div styleName="arrow-container">
				<div styleName="arrow-content" onClick={this.onClickArrow.bind(this, 'left')}>
					<LeftArrow />
				</div>
			</div>
		);
	}

	renderRightArrow = () => {
		if (!this.state.showRightArrow) {
			return null;
		}
		return (
			<div styleName="arrow-container">
				<div styleName="arrow-content" onClick={this.onClickArrow.bind(this, 'right')}>
					<RightArrow />
				</div>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const tabsStyles = {
			...defaultTabsStyles,
			...this.props.tabsStyles
		};
		return (
			<div styleName="container">
				{this.renderLeftArrow()}
				<div styleName="tabs-container" style={tabsStyles} ref={this.state.tabsContainerRef}>
					{this.renderTabs(myStyles)}
				</div>
				{this.renderRightArrow()}
			</div>
		);
	}
}

Tabs.defaultProps = {
	tabsList: [
		{
			label: 'Dummy Tab 1',
			value: 1
		},
		{
			label: 'Dummy Tab 2',
			value: 2
		},
		{
			label: 'Dummy Tab 3',
			value: 3
		},
		{
			label: 'Dummy Tab 4',
			value: 4
		},
		{
			label: 'Dummy Tab 5',
			value: 5
		}
	],
	tabsStyles: {},
	tabStyles: {},
	selectedTabValue: null,
	tabClickHandler: () => null
};

export default Tabs;