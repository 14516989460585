/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './MeasuresLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import ScoreCardSvg from './scoreCardSvg';
import analyticsImage from 'images/loaders/analytics-graph-bar.png';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class MeasuresLoader extends React.Component {

	renderFooterLoader = (loadersDefaultconfig) => {
		return (
			<ContentLoader
				height={156}
				width={1280}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '100%', width: '100%' }}
			>
				<rect x="36" y="37" rx="0" ry="0" width="140" height="16" />
				<rect x="36" y="78" rx="0" ry="0" width="572" height="32" />
				<rect x="852" y="66" rx="0" ry="0" width="216" height="44" />
			</ContentLoader>
		);
	}

	renderShowMoreLoader = (loadersDefaultconfig) => {
		return (
			<ContentLoader
				height={20}
				width={1280}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '20px', width: '100%' }}
			>
				<rect x="532" y="0" rx="0" ry="0" width="216" height="20" />
			</ContentLoader>
		);
	}

	renderMetricsBoxLoader = (myStyles) => {
		return (
			<div className={css(myStyles.boxBorder)} styleName="metrics-box-container">
				<img src={analyticsImage} width="72px" height="74px" />
			</div>
		);
	}

	renderMetricsHeaderLoader = (loadersDefaultconfig) => {
		return (
			<div styleName="metrics-container">
				<ContentLoader
					height={60}
					width={1280}
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '60px' }}
				>
					<rect x="8" y="0" rx="0" ry="0" width="216" height="20" />
					<rect x="8" y="37" rx="0" ry="0" width="536" height="16" />
				</ContentLoader>
			</div>
		);
	}

	renderYouScoreCardLoader = () => {
		return (
			<div styleName="score-card-container">
				<ScoreCardSvg />
			</div>
		);
	}

	renderYouScoreContentLoader = (loadersDefaultconfig) => {
		return (
			<ContentLoader
				height={204}
				width={650}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '204px' }}
			>
				<rect x="20" y="0" rx="0" ry="0" width="408" height="24" />
				<rect x="20" y="52" rx="0" ry="0" width="635" height="16" />
				<rect x="20" y="82" rx="0" ry="0" width="635" height="16" />
				<rect x="20" y="112" rx="0" ry="0" width="635" height="16" />
				<rect x="20" y="142" rx="0" ry="0" width="200" height="16" />
			</ContentLoader>
		);
	}

	renderYourScoreSectionLoader = (loadersDefaultconfig) => {
		return (
			<div styleName="score-container">
				{this.renderYouScoreCardLoader()}
				{this.renderYouScoreContentLoader(loadersDefaultconfig)}
			</div>
		);
	}

	renderYourScoreTabsLoader = (loadersDefaultconfig) => {
		return (
			<div styleName="score-tabs-container">
				<ContentLoader
					height={31}
					width={1280}
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '31px' }}
				>
					<rect x="20" y="1" rx="0" ry="0" width="186" height="16" />
					<rect x="285" y="1" rx="0" ry="0" width="186" height="16" />
					<rect x="8" y="24" rx="0" ry="0" width="990" height="4" />
				</ContentLoader>
			</div>
		);
	}

	renderSummaryContentLoader = (loadersDefaultconfig) => {
		return (
			<div styleName="summary-heading">
				<ContentLoader
					height={142}
					width={1280}
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '142px' }}
				>
					<rect x="159" y="48" rx="2" ry="2" width="585" height="12" />
					<rect x="159" y="84" rx="2" ry="2" width="379" height="12" />
					<circle cx="84" cy="70" r="47" />
				</ContentLoader>
			</div>
		);
	}

	renderSummaryHeadingLoader = (loadersDefaultconfig) => {
		return (
			<div styleName="summary-heading">
				<ContentLoader
					height={61}
					width={1280}
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '61px' }}
				>
					<rect x="8" y="0" rx="2" ry="2" width="456" height="20" />
				</ContentLoader>
			</div>
		);
	}

	renderFeedbackLoader = (loadersDefaultconfig) => {
		return (
			<ContentLoader
				height={262}
				width={1280}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '262px', width: '100%' }}
			>
				<rect x="372" y="55" rx="0" ry="0" width="543" height="16" />
				<rect x="294" y="93" rx="0" ry="0" width="693" height="16" />
				<circle cx="595" cy="160" r="29" />
				<circle cx="686" cy="160" r="29" />
			</ContentLoader>
		);
	}

	renderHeadingLoader = (loadersDefaultconfig) => {
		return (
			<div styleName="summary-heading">
				<ContentLoader
					height={200}
					width={1280}
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '194px' }}
				>
					<rect x={8} y={62} rx="0" ry="0" width={235} height={12} />
					<rect x={8} y={99} rx="0" ry="0" width={545} height={32} />
				</ContentLoader>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<div>
				<div
					styleName="measures-loader-container"
					className={css(myStyles.greyBackground, myStyles.borderTop)}
				>
					<div styleName="measures-loader-content">
						{this.renderHeadingLoader(loadersDefaultconfig)}
					</div>
				</div>
				<div
					styleName="measures-loader-container"
					className={css(myStyles.greyBackground)}
				>
					<div styleName="measures-loader-content main-section-content">
						{this.renderSummaryHeadingLoader(loadersDefaultconfig)}
						<div styleName="summary-content" className={css(myStyles.boxBorder)}>
							{this.renderSummaryContentLoader(loadersDefaultconfig)}
						</div>
						{this.renderYourScoreTabsLoader(loadersDefaultconfig)}
						{this.renderYourScoreSectionLoader(loadersDefaultconfig)}
						{this.renderYourScoreSectionLoader(loadersDefaultconfig)}
						{this.renderMetricsHeaderLoader(loadersDefaultconfig)}
						{this.renderMetricsBoxLoader(myStyles)}
						{this.renderShowMoreLoader(loadersDefaultconfig)}
					</div>
				</div>
				<div styleName="measures-loader-container" >
					<div styleName="measures-loader-content">
						{this.renderFeedbackLoader(loadersDefaultconfig)}
					</div>
				</div>
				<div styleName="measures-loader-container" >
					<div styleName="measures-loader-content">
						{this.renderFooterLoader(loadersDefaultconfig)}
					</div>
				</div>
			</div>
		);
	}
}

export default MeasuresLoader;
