import React from 'react';
import CSSModules from 'react-css-modules';
import {css} from 'aphrodite/no-important';
import styles from './userDetails.module.sass';
import getSkin from './skin.js';

@CSSModules(styles, { allowMultiple: true })

class UserDetails extends React.Component {

    render(){

        const myStyles = getSkin(this.props.skinGuide);
        
        return(
            <div styleName="container-class">
                <div className={css(myStyles.fontStyling)} styleName="user-details-text-container">
                    {this.props.greeting}
                    <div styleName="dashboard-info">
                        <div>
                            {this.props.infoMessage}
                        </div>
                        <div styleName="update-text">
                           {this.props.allGraphsData.get('genericData').get('analyticsData').get('lastUpdatedAtTime')}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default UserDetails;