import React, { Component } from 'react';
import styles from './stickyHeader.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import enumConstants from 'constants/enums';
import LockIcon from 'svgComponents/lockIcon';
import { getLockMessage } from 'utils/locks';
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class StickyHeader extends Component {

	renderAcceptInvitation = (myStyles) => {
		const {userActiveBatchDetails} = this.props;
		
		const goToBatchRoute = () => {
			const route = `${routes.get('BATCH')}`;
			const params = [{ 'BATCH_ID': userActiveBatchDetails.get('batchId') }];
	
			updateRoute({ route, params });
		};
	
		const handleOnClick = () => {
			const {userActiveBatchDetails} = this.props
	
			if(userActiveBatchDetails.get('workflowState') === 'ACCEPTED') {
				return goToBatchRoute();
			} else {
				this.props.updateWorkflowStatus(enumConstants.get('ACCEPTED'));
				goToBatchRoute();
			}
		}

		let className = css(myStyles.buttonInvitation);
		let startAction = handleOnClick;

		let lockIcon = null;
		const lockDetails = userActiveBatchDetails.get('lock_details');
		let isLocked = lockDetails && lockDetails.get('locked');
		//const lockExplanation = lockDetails.get('lock_explanation');

		const isBatchWorkflowStateCompleted = userActiveBatchDetails.get('workflowState') === 'COMPLETED';
		const isBatchExpired = lockDetails && lockDetails.get('lock_reason') === 'expired';

		const isBatchUpcoming = isLocked && lockDetails.get('lock_reason') === "not_started";

		let lockExplanation;
		let buttonLabel = 'accept_invitation_label';

		let isButtonLocked = false;

		if(isBatchWorkflowStateCompleted && isBatchExpired) {
			buttonLabel = 'view_journey_label';
		} else if(isBatchExpired) {
			buttonLabel = 'view_journey_label';
		} else if(isBatchWorkflowStateCompleted && !isLocked) {
			buttonLabel = 'view_journey_label';
		} else if(isBatchUpcoming) {
			buttonLabel = 'accept_invitation_label';
			lockExplanation = getLockMessage(lockDetails);
			isButtonLocked = true;
		} else if(userActiveBatchDetails.get('workflowState') === 'ACCEPTED') {
			buttonLabel = 'continue_learning_label';
		}  
		
		if(isButtonLocked){
			className = css(myStyles.acceptInvitationDisabled);
			lockIcon = <div styleName="locked-icon">
				<LockIcon/>
			</div>;
			startAction = () => {};
		}
		return (
			<div
				className={css(myStyles.acceptInvitationContainer)}
				styleName="accept-invitation-container"
			>
				<div styleName="accept-invitation-image" className={css(myStyles.acceptInvitationImage)}></div>
				<div styleName="accept-invitation-content">
					<div styleName="title-container">
						<div className={css(myStyles.title)} styleName='journey-title-overflow-hidden'>
							{this.props.isBatchDetailsAvailable
								? this.props.getLabel(this.props.journeyDetails.get('name'))
								: "loading..."
							}
						</div>
					</div>
					<div styleName="button-container">
						<div
							className={className}
							styleName="button-accept"
							onClick={startAction}
						>
							{lockIcon}
							{this.props.getLabel(buttonLabel)}
						</div>
						<div className={css(myStyles.lockExplanation)} styleName="lock-explanation">
							{lockExplanation}
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div
				className={css(myStyles.stickyContainer)}
				styleName="sticky-header-container">
				{this.renderAcceptInvitation(myStyles)}
			</div>
		);
	}
}

export default StickyHeader;