/**
 * @author: sourabh.jain
 * @since: Thu Oct 06 2018 00:47:22 GMT+0530 (IST)
 * @file: endOfJourney.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    needleMovementData: [],
    isNeedleMovementDataFetched: false,
});

const endOfJourney = (state = initialState, action = {}) => {
    switch (action.type) {
        /*Needle movement data */
        
        case actionConsts.get('NEEDLE_MOVEMENT_DATA'):

        	const needleMovementData = action.payload;

            return state.set(
                'needleMovementData',
                Immutable.fromJS(needleMovementData)
            ).set(
                'isNeedleMovementDataFetched',
                true
			);
			            
        default:
            return state;
    }
};

export default endOfJourney;