/**
 * @author: Amul
 * @since: Thu Sep 5 2018 18:22:31 GMT+0530 (IST)
 * @file: courses.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * course reducer is responsible for the sidebar with course and its modules
 *
 **/

import Immutable from 'immutable';
import actionConstants from 'constants/actions';
import { setOrUpdateObjectInList, mergeListsBasedOnKey } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    course: null,
    isCourseAvailable: false,
    courseList: []
});

const course = (state = initialState, action) => {
    switch (action.type) {
        case actionConstants.get('SET_COURSE'):
            let courseList = [];
            courseList.push(action.course);
            return state.set(
                'course',
                Immutable.fromJS(action.course)
            ).set(
                'isCourseAvailable',
                true
            ).set(
                'courseList',
                mergeListsBasedOnKey(state.get('courseList'), courseList, 'id')
            )
        case actionConstants.get('SET_COURSE_AVAILABILITY'):
            return state.set('isCourseAvailable', action.status);
        default:
            return state;
    }
}

export default course;