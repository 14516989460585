import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
	availableCorpDeckJourneys: [],
	isAvailableCorpDeckJourneysFetching: false
});

const corpDeckJourneys = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_AVAILABLE_CORP_DECK_JOURNEYS'):
			return state.set(
				'availableCorpDeckJourneys',
				Immutable.fromJS(action.payload.journeyList)
			).set(
				'isAvailableCorpDeckJourneysFetching',
				false
			)
		case actionConstants.get('SET_AVAILABLE_CORP_DECK_JOURNEYS_LOADING'):
			return state.set(
				'isAvailableCorpDeckJourneysFetching',
				action.flag
			)
		// case actionConstants.get('REMOVE_REQUESTED_JOURNEY'):
		// 	return state.set(
		// 		'availableJourneys',
		// 		state.get('availableJourneys').filter(j => j.get('id') !== action.journeyInstanceId)
		// 	)
		default:
			return state;
	}
}

export default corpDeckJourneys;