import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		navigationContainer: {
            boxShadow: `0 2px 0 0 ${hexToRgbA(skin.get('black'), 0.25)}`,
            backgroundColor: skin.get('white')
        },
		tabName: {
			...myTypography.h6,
			fontWeight: 'normal',
			color: hexToRgbA(skin.get('greyColor2'), 0.7),
			lineHeight: 1.1,
			':hover': {
					fontWeight: 600,
					color: skin.get('greyColor2')
			},
			'@media screen and (max-width: 480px)': {
				fontSize: '12px'		
			},
			'@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait)': {
				fontSize: '12px'
			},
			'@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait)': {
				fontSize: '14px'		
			}
		},
		tabNameActive: {
			...myTypography.h6,
			fontWeight: 600,
			lineHeight: 1.1,
			color: skin.get('greyColor2'),
			'@media screen and (max-width: 480px)': {
				fontSize: '12px'		
			},
			'@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait)': {
				fontSize: '12px'
			},
			'@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait)': {
				fontSize: '14px'		
			}

		},
		tabLine: {
			backgroundColor: skin.get('greyColor3'),
			borderRadius: '2px'
		},
		lineContainer: {
			backgroundColor: skin.get('greyColor5')
		}
	});
}

export default getSkin;