import store from 'store/store'
import mixpanel from 'mixpanel-browser';
import ifvisible from 'ifvisible.js';
import {setMixpanelTracking, unsetMixpanelTracking, setMixpanelTrackingStatus} from 'actions/mixpanelTracking/actionCreators';
import environment from 'utils/environment';
import * as Sentry from '@sentry/browser';


const mixpanelToken = environment.REACT_APP_MIXPANEL_APP_KEY;
const mixpanelEvent = environment.REACT_APP_MIXPANEL_EVENT_NAME;

const trackingInterval = parseInt(environment.REACT_APP_MIXPANEL_TRACKING_INTERVAL || 60) * 1000; // 10 seconds 

const idleTimeOut = environment.REACT_APP_MIXPANEL_IDLE_TIMEOUT || 300;

console.log('mp: tracking interval = ', trackingInterval, 'idleTimeOut = ', idleTimeOut);

export const initializeMixpanel = () => {
    
    mixpanel.init(mixpanelToken, {
        debug: true,
        ignore_dnt: true,
        persistence: 'localStorage'
    });

    ifvisible.setIdleDuration(idleTimeOut); //5 mins

    ifvisible.on("idle", function(){
        let time = new Date();
        console.log('mp: page has gone idle at:', `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`);
        const isActive = store.getState().get('mixpanelTracking').get('isActive');
        if(isActive) endTracking(true);
    });
    
    ifvisible.on("wakeup", function(){
        let time = new Date();
        console.log('mp: page is back to active state at:', `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`);
        const isActive = store.getState().get('mixpanelTracking').get('isActive');
        const screenName = store.getState().get('mixpanelTracking').get('screenName');
        if(!isActive && screenName){ //means tracking is just suspended
            resetTracking(true);
        }
    });

}

export const startTracking = (init = true, payload, screenName) => {

    const isUserDataAvailable = store.getState().get('user').get('isUserDataAvailable');
    if(!isUserDataAvailable) return;

    let time = new Date();
    console.log('mp: starting tracking at', `${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}`);
    const startTimestamp = parseInt(Date.now() / 1000);
    mixpanel.time_event(mixpanelEvent);
    if(init){
        let intervalId = window.setInterval(() => {
            resetTracking();
        }, trackingInterval);        
        store.dispatch(setMixpanelTracking(payload, screenName, intervalId, startTimestamp));
    } else {
        let intervalId = store.getState().get('mixpanelTracking').get('intervalId');
        store.dispatch(setMixpanelTracking(payload, screenName, intervalId, startTimestamp));
    }
}

export const endTracking = (soft = false) => {
    console.log("%cmp: Killing active tracking", "color: red");

    postTrackingDataToMixpanel();
    const intervalId = store.getState().get('mixpanelTracking').get('intervalId');
    window.clearInterval(intervalId);
    if(soft){
        store.dispatch(setMixpanelTrackingStatus(false, null));
    } else{
        store.dispatch(unsetMixpanelTracking());
    }
}

const createPayload = () => {
    const payload = store.getState().get('mixpanelTracking').get('payload');
    const screenName = store.getState().get('mixpanelTracking').get('screenName');
    let startTimestamp = store.getState().get('mixpanelTracking').get('startTimestamp');
    let endTimestamp = parseInt(Date.now() / 1000);
    const date = new Date()
    let timeZoneStartTimeStamp = startTimestamp - (date.getTimezoneOffset() * 60);
    let timeZoneEndTimeStamp = endTimestamp - (date.getTimezoneOffset() * 60);
    const userID = store.getState().get('user').get('userID');

    //window.startTimestamp = null;
    let errorLog = null;

    if (!userID) {
        errorLog = `userID is ${userID}`;
        try{
            console.log('user state', store.getState().get('user').toJS());
            throw store.getState().get('user').toJS();
        } catch(error){
            Sentry.captureException(error);
        }
    }

    if (!startTimestamp) {
        if(errorLog){
            errorLog = `${errorLog} ; startTimestamp is ${startTimestamp}`;
        } else {
            errorLog = `startTimestamp is ${startTimestamp}`;
        }
    }

    const payloadToSend = payload || {};

    return {
        ...payloadToSend,
        screenName,
        userId: userID,
        day_of_the_week: date.toLocaleString('en-us', { weekday: 'long' }),
        hour: date.getHours(),
        minute: date.getMinutes(),
        day: date.getDate(),
        month: date.getMonth() + 1,
        startTimestamp: startTimestamp,
        endTimestamp: endTimestamp,
        timeZoneStartTimeStamp: timeZoneStartTimeStamp,
        timeZoneEndTimeStamp: timeZoneEndTimeStamp,
        errorLog
    }
}

export const postTrackingDataToMixpanel = () => {
    
    const isUserDataAvailable = store.getState().get('user').get('isUserDataAvailable');
    if(!isUserDataAvailable) return;

    const isActive = store.getState().get('mixpanelTracking').get('isActive');
    if(isActive){
        mixpanel.track(mixpanelEvent, createPayload());
    }
}

export const resetTracking = (init = false) => {
    console.log('mp: resetting tracking');
    const payload = store.getState().get('mixpanelTracking').get('payload');
    const screenName = store.getState().get('mixpanelTracking').get('screenName');
    //console.log('mp: payload', payload);
    if(!init) postTrackingDataToMixpanel();
    startTracking(init, payload, screenName);   
}