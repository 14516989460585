import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

class FetchBatchFeedbackStatus extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject = {}) => {
		const url = urls.get('PLATFORM').get('GET_BATCH_FEEDBACK_STATUS').replace(
			'BATCH_ID',
			apiInfoObject.batchId
		);
		return url;
	}

    successCall = (response,apiInfoObject) => {
        return response;
    }

    onError = (response) => {
        console.log("response", response);
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new FetchBatchFeedbackStatus();
