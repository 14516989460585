import React, { Component } from 'react';
import styles from './activeTimeWidget.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import ActiveTimeLogo from 'images/analytics/active_time.svg';
import { checkIfPresent } from 'utils/utilFunctions';


@applySkin
@CSSModules(styles, { allowMultiple: true })
class ActiveTimeWidget extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    renderInsightForWidget = (styles, insightPresent) => {
        if (insightPresent) {
            return(
                <div className={css(styles.widgetDescription)} styleName="widget-description">
                    Your average completion rate in assessments is way below your group average. 
                    You need to complete all assessments soon 
                </div>
            )
        }
    }
  
    render() {
        const styles = getSkin(this.props.skinGuide);

        const widgetDataArray = this.props.widgetArray;
        
        const insightPresent = checkIfPresent(widgetDataArray.insights);

        
        return (
            <div className={css(styles.widgetContainerStyle)} styleName="widget-container-class">
                <div className={css(styles.widgetTitle)} styleName="widget-title">
                    {widgetDataArray.get('title')}
                </div>
                <div styleName="widget-content">
                    <div styleName="widget-main-content">
                        <div className={css(styles.widgetContentMain)} >
                            {widgetDataArray.get('widgetData').get('startHour').get('hour')}
                        </div>
                        <div className={css(styles.widgetContentSubMain)} >
                        {widgetDataArray.get('widgetData').get('startHour').get('zone')}
                        </div>
                    </div>
                    <div className={css(styles.widgetContenDivider)}>
                        to
                    </div>
                    <div styleName="widget-main-content">
                    <div className={css(styles.widgetContentMain)} >
                        {widgetDataArray.get('widgetData').get('endHour').get('hour')}
                    </div>
                    <div className={css(styles.widgetContentSubMain)} >
                        {widgetDataArray.get('widgetData').get('endHour').get('zone')}
                    </div>
                </div>
                </div>
                {this.renderInsightForWidget(styles, insightPresent)}
                <div styleName="bg-logo">
                    <img src={ActiveTimeLogo} />
                </div>
            </div>
        );
    }
  }
  
  ActiveTimeWidget.defaultProps = {
  };

  export default ActiveTimeWidget;