import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		header: {
			background: '#4A5889'
		},
		courseName: {
			...myTypography.overline,
			fontWeight: 700,
			color: '#EFEEEE'
		},
		modalHeading: {
			...myTypography.overline,
			fontSize: '18px',
			fontWeight: 700,
			color: skinHex.get('white')
		},
		sessionTitle: {
			...myTypography.overline,
			fontWeight: 700,
			color: skinHex.get('secondaryColor')
		},
		sessionDateTime: {
            ...myTypography.caption,
            fontSize: '14px',
            fontWeight: 400,
            color: '#5F5E5E',
            lineHeight: '19px'
        },
        facilitator: {
            ...myTypography.caption,
            fontSize: '13px',
            fontWeight: 400,
            color: '#5F5E5E',
            lineHeight: '18px'
        },
        sessionDescription: {
            ...myTypography.caption,
            fontSize: '12px',
            fontWeight: 400,
            color: '#5F5E5E',
            lineHeight: '16px'
        },
        labelBold: {
            fontWeight: 700
        },
		closeIcon: {
			fontSize: '12px',
			color: skinHex.get('white'),
			background: 'rgba(0, 0, 0, 0.59)'
		},
		activeSessionListItem: {
			border: `1px solid ${skinHex.get('secondaryColor')} !important`
		},
		joinSessionButtonCnt: {
			...myTypography.caption,
			color: skinHex.get('secondaryColor'),
			fontWeight: 700
		},
		activeSessionText: {
			...myTypography.caption,
			fontWeight: 700,
			color: '#5F5E5E'
		},
		joinSession: {
			backgroundColor: skinHex.get('secondaryColor')
		},
		joinText: {
			...myTypography.caption,
			fontWeight: 700,
			color: skinHex.get('white')
		}
	});
}

export default getSkin;