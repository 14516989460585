import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import NextActivityImage from 'images/dashboard/nextBackground.png'

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		userProfileContainer: {
			borderTop: `7px solid ${skinHex.get('secondaryColor')}`
		},
		headerBg: {
			background: `linear-gradient(135deg, ${hexToRgbA(skinHex.get('primaryColor'), 0.1)} , ${hexToRgbA(skinHex.get('secondaryColor'), 0.1)})`
		},
		headerBgImg: {
			backgroundImage: `url(${NextActivityImage})`,
			backgroundSize: "100%",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top",
			position: 'absolute',
			top: 0,
			left: 0,
			height: '100%',
			width: '100%'
		},
		contentText: {
			...myTypography.h4,
			color: hexToRgbA(skinHex.get('greyColor1'), 0.85),
			'@media only screen and (max-width: 480px)': {
				fontSize: '27px'
			},
			'@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait)': {
				fontSize: '27px'
			},
			'@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait)': {
				fontSize: '27px'
			}
		},
		headerText: {
			...myTypography.overline,
			color: skinHex.get('contentColor'),
			fontWeight: 600
		},
		saveLink: {
			...myTypography.overline,
			color: skinHex.get('secondaryColor'),
			textTransform: 'uppercase',
			cursor: 'pointer',
			fontWeight: 600
		},
		individualHeaderText: {
			...myTypography.caption,
			color: skinHex.get('greyColor4')
		},
		detailInput: {
			...myTypography.overline,
			color: hexToRgbA(skinHex.get('greyColor2')),
			border: 'none',
			borderBottom: `1px solid ${hexToRgbA(skinHex.get('primaryColor'), 0.1)}`,
			':focus': {
				borderBottom: `1px solid ${skinHex.get('primaryColor')}`,
				outline: 'none'
			}
		},
		errorMsg: {
			...myTypography.caption,
			color: 'red',
			fontWeight: 'normal'
		},
		userName: {
			...myTypography.body1,
			color: '#4E535F',
			lineHeight: 'normals'
		},
		userEmail: {
			...myTypography.button,
			color: hexToRgbA(skinHex.get('greyColor3'), 0.8),
			marginBottom: '15px',
			fontWeight: 'normal'
		},
		changePassword: {
			...myTypography.button,
			textTransform: 'uppercase',
			color: skinHex.get('secondaryColor'),
			cursor: 'pointer'
		},
		breadcrumbsText: {
			...myTypography.caption,
			color: hexToRgbA(skinHex.get('greyColor4'), 0.7),
			cursor: 'pointer'
		},
		errorText: {
			...myTypography.button,
			backgroundColor: skinHex.get('pinkRed'),
			color: skinHex.get('white')
		},
		demographicsLabel: {
			...myTypography.overline,
			color: hexToRgbA(skinHex.get('greyColor2')),
			// fontWeight: 'normal'
		},
		subscribeLabel: {
			...myTypography.button,
			color: hexToRgbA(skinHex.get('greyColor2'), 0.8),
		},
		orgListItem: {
			...myTypography.button,
			color: hexToRgbA(skinHex.get('greyColor2'), 0.8),
		},
		setActiveButton: {
			color: skinHex.get('secondaryColor'),
			cursor: 'pointer'
		}
	});
}

export default getSkin;