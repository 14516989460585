import React, { Component } from 'react';
import styles from './buildingDataStrategy.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DigitalToolHeader from '../digitalToolHeader';
import DigitalToolGuide from '../digitalToolGuide';
import ToolItemList from '../toolItemList';
import { sortObjectOfObjectsBasedOnOrder } from 'utils/utilFunctions';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class BuildingDataStrategy extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuide: false
		}
	}

	toggleShowGuide = () => {
		this.setState({
			showGuide: !this.state.showGuide
		});
	}

	getToolItemListComp = (dataKey) => {
		const { toolData, toolUserData, colorPalette } = this.props;
		const selectedCP = toolUserData && toolUserData.data && toolUserData.data.selectedColorPalette ? toolUserData.data.selectedColorPalette[dataKey] : ""
		return (
			<ToolItemList
				type={dataKey}
				key={`key_${dataKey}`}
				userData={toolUserData && toolUserData.data && toolUserData.data.cards ? toolUserData.data.cards[dataKey] : {}}
				addNewUserDataRecord={this.props.addNewUserDataRecord}
				deleteRecord={this.props.deleteRecord}
				colorPalette={colorPalette}
				selectedColorPalette={selectedCP}
				setColorPalette={this.props.setColorPalette}
				cardStyles={{
					height: '240px',
					maxHeight: '240px',
					justifyContent: 'space-between',
					borderRadius: '6px 0 6px 6px',
					backgroundImage: `${selectedCP && colorPalette[selectedCP].cardBackground ? '' : 'linear-gradient(132deg, #477bc0, #4a53ae)'}`
				}}
				instructions={toolData.cardExamples[dataKey]}
			/>
		)
	}

	submitDataStrategy = () => {
		this.props.markModuleItemDone(this.props.match.params);
	}

	downloadDataStrategy = () => {
		alert("feature not available!");
	}

	getValuePropositionCards = () => {
		const { toolData, skinGuide } = this.props;
		let myStyles = getSkin(skinGuide);
		let roleBasedCardsComponent = [];
		let orderedActors = sortObjectOfObjectsBasedOnOrder(toolData.actors);
		let orderedDataTypes = sortObjectOfObjectsBasedOnOrder(toolData.dataTypes);
		for (let actor in orderedActors) {
			let cardsComponent = [];
			for (let type in orderedDataTypes) {
				let dataKey = `${orderedActors[actor].key}_${orderedDataTypes[type].key}`
				cardsComponent.push(
					<div styleName="individual-role-based-card-cnt">
						{this.getToolItemListComp(dataKey)}
					</div>
				)
			}
			roleBasedCardsComponent.push(
				<div key={`${orderedActors[actor].key}`} styleName="individual-role-based-cards">
					<div styleName="role-text-cnt">
						<div className={css(myStyles.roleText)} styleName="role-text">
							{orderedActors[actor].name}
						</div>
					</div>
					<div styleName="cards-cnt">
						{cardsComponent}
					</div>
				</div>
			)
		}
		return (
			<div styleName="role-based-cards-cnt">
				<div styleName="value-proposition-cnt">
					<div className={css(myStyles.roleHeaderText)} styleName="value-proposition-text">
						{toolData.labels.value_proposition || "Value Proposition"}
					</div>
				</div>
				<div styleName="role-based-cards-inner-cnt">
					{roleBasedCardsComponent}
				</div>
			</div>
		);
	}

	getDataTypeCards = () => {
		const { toolData, skinGuide } = this.props;
		let myStyles = getSkin(skinGuide);
		let dataTypeCardsComponent = [];
		let orderedDataTypes = sortObjectOfObjectsBasedOnOrder(toolData.dataTypes);
		for (let type in orderedDataTypes) {
			let dataKey = `data_type_${orderedDataTypes[type].key}`;
			dataTypeCardsComponent.push(
				<div styleName="individual-data-type-card-cnt">
					<div className={css(myStyles.individualDataTypeText)} styleName="header">
						{orderedDataTypes[type].name}
					</div>
					<div styleName="connector-line"></div>
					<div styleName="tool-items-list-card-cnt">
						{this.getToolItemListComp(dataKey)}
					</div>
				</div>
			)
		}
		return (
			<div styleName="top-data-type-cards-cnt">
				<div styleName="container-header">
					<div className={css(myStyles.individualDataTypeText)} styleName="header-text">
						{toolData.labels.list_data_types || "List down the data types"}
					</div>
				</div>
				<div styleName="individual-cards-cnt">
					{dataTypeCardsComponent}
				</div>
			</div>
		);
	}

	renderBuildingDataStrategyContent = () => {
		let myStyles = getSkin(this.props.skinGuide);
		const { toolData } = this.props;
		return (
			<div styleName="building-data-strategy-content-cnt">
				<div styleName="building-data-strategy-content">
					<div className={css(myStyles.fillCardText)} styleName="fill-card-header">
						{toolData.labels.stepHeading || "Fill the cards given below"}
					</div>
					<div styleName="cards-outer-cnt">
						<div className={css(myStyles.dataTypesText)} styleName="cards-cnt-header">
							{toolData.labels.data_types || 'Data Types'}
						</div>
						<div styleName="cards-inner-cnt">
							{this.getDataTypeCards()}
							{this.getValuePropositionCards()}
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { toolData } = this.props;
		return (
			<div styleName="building-data-strategy-cnt">
				<DigitalToolHeader
					toggleShowGuide={this.toggleShowGuide}
					submitAction={this.submitDataStrategy}
					downloadAction={this.downloadDataStrategy}
					toolData={toolData}
				/>
				{
					this.renderBuildingDataStrategyContent()
				}
				{
					this.props.toolData && this.state.showGuide &&
					<DigitalToolGuide
						closeGuide={this.toggleShowGuide}
						guideData={this.props.toolData.guide}
						labels={this.props.toolData.labels}
					/>
				}
			</div>
		);
	}
}

export default BuildingDataStrategy;