import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({       
        reason:{
            ...myTypography.button,
            color: '#4F71EB',
            fontWeight: 'bold',
            '@media only screen and (max-width: 480px)': {
                fontSize: '12px'
            }
        },
        text: {
            ...myTypography.overline,
            color: '#4E535F',
            '@media only screen and (max-width: 480px)': {
                fontSize: '12px'
            }
        }
	});
}

export default getSkin;