import environment from 'utils/environment';

const checkAndSetAmplitudeSessionDetails = () => {
	if (!window.localStorage.getItem('amplitude_session_id')) {
		return setCurrentTimeAsID();
	}

	let timeDiff = Date.now() - (+window.localStorage.getItem('id_last_updated_at'));
	timeDiff = timeDiff / (1000 * 60) // convert to minutes
	if (timeDiff > +environment.REACT_APP_AMPLITUDE_SESSION_ID_TIMEOUT) {
		return setCurrentTimeAsID();
	} else {
		window.localStorage.setItem('id_last_updated_at', Date.now());
		return window.localStorage.getItem('amplitude_session_id')
	}
}

const setCurrentTimeAsID = () => {
	let currentTimeStamp = Date.now();
	window.localStorage.setItem('amplitude_session_id', currentTimeStamp);
	window.localStorage.setItem('id_last_updated_at', currentTimeStamp);
	return currentTimeStamp;
}

const getAmplitudeSessionDetails = () => {
	let amplitudeSessionId = checkAndSetAmplitudeSessionDetails();
	return {
		"integrations": {
			"Amplitude": {
				"session_id": +amplitudeSessionId
			}
		}
	};
}

export {
	checkAndSetAmplitudeSessionDetails,
	getAmplitudeSessionDetails
}