import actionConstants from 'constants/actions';

export const setReportTask = (reportType, batchId, task) => ({
    type: actionConstants.get('REPORT_TASK_STARTED'),
    batchId: batchId,
    reportType: reportType,
    task: task
});

export const updateReportTask = (reportType, batchId, task) => ({
    type: actionConstants.get('REPORT_TASK_UPDATED'),
    batchId: batchId,
    reportType: reportType,
    task: task
});

export const removeReportTaskOnCompletion = (reportType, batchId) => ({
    type: actionConstants.get('REPORT_TASK_COMPLETED'),
    batchId: batchId,
    reportType: reportType
});

export const setLinkedInSharingTask = (reportType, batchId, task) => ({
    type: actionConstants.get('SHARING_CERTIFICATE_TASK_STARTED'),
    batchId: batchId,
    reportType: reportType,
    task: task
});

export const updateLinkedInSharingTask = (reportType, batchId, task) => ({
    type: actionConstants.get('SHARING_CERTIFICATE_TASK_UPDATED'),
    batchId: batchId,
    reportType: reportType,
    task: task
});

export const removeLinkedInSharingTaskOnCompletion = (reportType, batchId) => ({
    type: actionConstants.get('SHARING_CERTIFICATE_TASK_COMPLETED'),
    batchId: batchId,
    reportType: reportType
});