import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './progressComponent.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import ProgressComponentAssesment from './progressAssesment';
import ProgressComponentDevelopment from './progressDevelopment';
import LockComponent from 'commonComponents/analytics/lockComponent';

@CSSModules(styles, { allowMultiple: true })

class ProgressComponent extends React.Component {

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(nextProps) === JSON.stringify(this.props)) {
            return false;
        }
        else {
            return true;
        }
    }

    renderSelectedPhaseComponent = () => {
        const { phase, userPhase } = this.props;

        if (userPhase.get('workflowState') === 'NOT_STARTED' && userPhase.get('completedItems') == 0) {
            return <LockComponent
                {...this.props}
                unlockMessageHint={this.props.strings.get('scoreDataInavailableKey')} />
        }
        if (phase.get('type') === 'Assessment') {
            return <ProgressComponentAssesment selectedPhase={phase} {...this.props} />;
        }
        else if (phase.get('type') === 'Development') {
            return <ProgressComponentDevelopment selectedPhase={phase} {...this.props} />
        }
        return null;
    }

    render() {
        const { phase } = this.props;
        return (
            <div key={`phase_${phase.get('id')}`} styleName="container-class">
                <div styleName="customised-legend">
                    <LegendComponent title={this.props.strings.get('progressKey')} {...this.props} />
                </div>
                <div styleName="outer-graph-container">
                    {this.renderSelectedPhaseComponent()}
                </div>
            </div>
        )
    }
}


export default ProgressComponent;
