import React, { Component } from 'react';
import styles from './userProfile.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			passwordData: {
				oldPassword: '',
				password: '',
				confirmationPassword: ''
			},
			passwordsDontMatch: false
		}
	}

	componentDidMount() {
		document.title = 'Change Password - Aktivlearn Plus'
		let segmentData = {
			url: window.location.href,
			pathHash: window.location.hash
		};
		window.analytics.page(
			"Reset Password",
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	changePassword = () => {
		this.props.changePassword({
			userId: this.props.userProfile.getIn(['userReferences', 'userId']),
			...this.state.passwordData,
			passwordsDontMatch: true
		})
	}

	updatePasswordDetails = (keyToUpdate, value) => {
		this.setState({
			passwordData: {
				...this.state.passwordData,
				[keyToUpdate]: value
			}
		})
	}

	checkConfirmationPassword = () => {
		const { passwordData } = this.state;
		if (passwordData.password !== passwordData.confirmationPassword) {
			this.setState({ passwordsDontMatch: true })
		} {
			if (this.state.passwordsDontMatch) {
				this.setState({ passwordsDontMatch: false })
			}
		}
	}

	goToHome = () => {
		const route = routes.get('USER_HOME');;
		updateRoute({ route });
	}

	goToProfile = () => {
		const route = routes.get('USER_PROFILE');;
		updateRoute({ route });
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const { getLabel, userProfile } = this.props;
		const { passwordData } = this.state;

		let disableChangeBtn = false;
		if (
			passwordData.oldPassword === '' ||
			passwordData.password === '' ||
			passwordData.confirmationPassword === ''
		) {
			disableChangeBtn = true;
		}
		if (passwordData.password !== passwordData.confirmationPassword)
			disableChangeBtn = true;

		return (
			<div className={css(myStyles.userProfileContainer)} styleName="content-cnt">
				<div className={css(myStyles.headerBg)} styleName="content-header">
					<div className={css(myStyles.headerBgImg)}></div>
					<div styleName="breacrumbs-cnt" style={{ paddingLeft: '11px' }}>
						<span
							className={css(myStyles.breadcrumbsText)}
							onClick={this.goToHome}
						>
							{getLabel('home_label')}
						</span>
						<span
							className={css(myStyles.breadcrumbsText)}
							onClick={this.goToProfile}
						>
							{getLabel('profile_label')}
						</span>
						<span
							className={css(myStyles.breadcrumbsText)}
							style={{ cursor: 'unset' }}
						>
							{getLabel('change_password_label')}
						</span>
					</div>
					<div
						className={css(myStyles.contentText)}
						styleName="content"
						style={{ paddingLeft: '11px' }}
					>
						{getLabel('change_password_label')}
					</div>
				</div>
				<div styleName="password-rules-cnt">
					<span className={css(myStyles.userName)}>
						<b>{getLabel('password_rules_label')}</b>
						<ul>
							<li>
								{getLabel('password_rule_label_1_label')}
							</li>
							<li>
								{getLabel('password_rule_label_2_label')}
							</li>
							<li>
								{getLabel('password_rule_label_3_label')}
							</li>
							<li>
								{getLabel('password_example_label')}
							</li>
						</ul>
					</span>
				</div>
				<div styleName="reset-password-cnt">
					{
						this.props.userProfile.get('passwordUpdatingError') &&
						<div styleName="password-error-cnt">
							<div styleName="error-text" className={css(myStyles.errorText)}>
								{this.props.userProfile.get('passwordUpdatingError')}
							</div>
						</div>
					}
					<div styleName="passwords-input-cnt">
						<div styleName="old-pswd-cnt">
							<div className={css(myStyles.individualHeaderText)}>
								{getLabel('old_password_label')}
							</div>
							<input
								type="password"
								className={css(myStyles.detailInput)}
								value={this.state.passwordData.oldPassword}
								onChange={(e) => this.updatePasswordDetails('oldPassword', e.target.value)}
							/>
						</div>
						<div styleName="new-pswd-cnt">
							<div styleName="new-pswd-inner-cnt">
								<div className={css(myStyles.individualHeaderText)}>
									{getLabel('new_password_label')}
								</div>
								<input
									type="password"
									className={css(myStyles.detailInput)}
									value={this.state.passwordData.password}
									onChange={(e) => this.updatePasswordDetails('password', e.target.value)}
								/>
							</div>
							<div styleName="new-pswd-inner-cnt">
								<div className={css(myStyles.individualHeaderText)}>
									{getLabel('confirm_password_label')}
								</div>
								<input
									type="password"
									className={css(myStyles.detailInput)}
									value={this.state.passwordData.confirmationPassword}
									onChange={(e) => this.updatePasswordDetails('confirmationPassword', e.target.value)}
									onBlur={this.checkConfirmationPassword}
								/>
							</div>
							{
								this.state.passwordsDontMatch &&
								<div className={css(myStyles.errorMsg)}>{getLabel('password_dont_match_label')}</div>
							}
						</div>
					</div>
					<div styleName="change-password-btn-cnt">
						<PrimaryButton
							text={
								userProfile.get('isPasswordUpdating') ?
									getLabel('updating_password_label')
									:
									getLabel('change_password_label')
							}
							clickFunction={this.changePassword}
							disabled={disableChangeBtn || userProfile.get('isPasswordUpdating')}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default ResetPassword;