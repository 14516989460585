import React, { Component } from 'react';
import styles from './driversForDigitalBlur.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DigitalToolHeader from '../digitalToolHeader';
import DigitalToolGuide from '../digitalToolGuide';
import ToolItemList from '../toolItemList';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class DriversForDigitalBlur extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuide: false
		}
	}

	toggleShowGuide = () => {
		this.setState({
			showGuide: !this.state.showGuide
		});
	}

	submitDataStrategy = () => {
		this.props.markModuleItemDone(this.props.match.params);
	}

	downloadDataStrategy = () => {
		alert("feature not available!");
	}

	getToolItemListComp = (dataKey) => {
		const { toolData, toolUserData, colorPalette } = this.props;
		const selectedCP = toolUserData && toolUserData.data && toolUserData.data.selectedColorPalette ? toolUserData.data.selectedColorPalette[dataKey] : ""
		return (
			<ToolItemList
				type={dataKey}
				key={`key_${dataKey}`}
				userData={toolUserData && toolUserData.data && toolUserData.data.cards ? toolUserData.data.cards[dataKey] : {}}
				addNewUserDataRecord={this.props.addNewUserDataRecord}
				deleteRecord={this.props.deleteRecord}
				colorPalette={colorPalette}
				selectedColorPalette={selectedCP}
				setColorPalette={this.props.setColorPalette}
				cardStyles={{
					height: '240px',
					maxHeight: '240px',
					justifyContent: 'space-between',
					borderRadius: '6px 0 6px 6px',
					backgroundImage: `${selectedCP && colorPalette[selectedCP].cardBackground ? '' : 'linear-gradient(132deg, #477bc0, #4a53ae)'}`
				}}
				instructions={toolData.cardExamples[dataKey]}
			/>
		)
	}

	getCardsComponent = () => {
		const { toolData } = this.props;
		let myStyles = getSkin(this.props.skinGuide);
		let cardsComponent = [];
		let cardsHeaderComponent = [];
		if (toolData && toolData.columns) {
			for (let column in toolData.columns) {
				cardsHeaderComponent.push(
					<div key={`connector_for_${toolData.columns[column].key}`} styleName="cards-column-header">
						<div className={css(myStyles.columnHeaderText)} styleName="cards-column-header-text">
							{toolData.columns[column].name}
						</div>
						<div styleName="cards-column-connector"></div>
					</div>
				)
			}
		}
		if (toolData && toolData.digitalBlurDrivers) {
			for (let driver in toolData.digitalBlurDrivers) {
				let cards = [];
				if (toolData && toolData.columns) {
					for (let column in toolData.columns) {
						let dataKey = `${toolData.columns[column].key}_${driver}`
						cards.push(
							<div key={dataKey} styleName="tool-items-list-card-cnt">
								<div styleName="card">
									{this.getToolItemListComp(dataKey)}
								</div>
							</div>
						)
					}
				}
				cardsComponent.push(
					<div key={`driver_${driver}`} styleName="individual-driver-cards-cnt">
						<div styleName="individual-driver-header-cnt">
							<div className={css(myStyles.individualDriverHeaderText)} styleName="individual-driver-header-text">
								{toolData.digitalBlurDrivers[driver]}
							</div>
						</div>
						<div styleName="cards-cnt">
							{cards}
						</div>
					</div>
				)
			}
		}
		if (cardsComponent.length) {
			return (
				[
					<div key="driver-cards-header" styleName="driver-cards-header-cnt">
						{cardsHeaderComponent}
					</div>
					,
					<div key="driver-cards-container" styleName="driver-cards-cnt">
						<div className={css(myStyles.driverHeaderText)} styleName="driver-header-cnt">
							<div styleName="driver-header-text">
								{toolData.labels.digital_blur_driver_label || "Digital Blur Driver"}
							</div>
						</div>
						<div styleName="all-driver-cards-cnt">
							{cardsComponent}
						</div>
					</div>
				]
			)
		}
		return null;
	}

	renderDriversForDigitalBlur = () => {
		let myStyles = getSkin(this.props.skinGuide);
		const { toolData } = this.props;
		let cardsComponent = this.getCardsComponent();
		return (
			<div styleName="drivers-for-digital-blur-content-cnt">
				<div styleName="drivers-for-digital-blur-content">
					<div className={css(myStyles.stepHeaderText)} styleName="step-header-text">
						{toolData.labels.stepHeading || "Fill the Cards below"}
					</div>
					<div styleName="cards-outer-cnt">
						{cardsComponent}
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { toolData } = this.props;
		return (
			<div styleName="drivers-for-digital-blur-cnt">
				<DigitalToolHeader
					toggleShowGuide={this.toggleShowGuide}
					submitAction={this.submitDataStrategy}
					downloadAction={this.downloadDataStrategy}
					toolData={toolData}
				/>
				{
					this.renderDriversForDigitalBlur()
				}
				{
					this.props.toolData && this.state.showGuide &&
					<DigitalToolGuide
						closeGuide={this.toggleShowGuide}
						guideData={this.props.toolData.guide}
						labels={this.props.toolData.labels}
					/>
				}
			</div>
		);
	}
}

export default DriversForDigitalBlur;