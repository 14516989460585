import React, { Component } from 'react';
import styles from './tabsBar.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import TabLoader from 'commonComponents/loaders/tabLoader';
import { TweenMax } from 'gsap';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { isIPad13, isMobile, isTablet } from 'react-device-detect';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TabsBar extends Component {

	scrollContainer = (id) => {
		if(isMobile || isTablet || isIPad13){
			try {
				document.getElementById(id).scrollIntoView({
					behavior: 'smooth'
				});
			} catch(e){
				console.log(e);
			}
		} else {			
			TweenMax.to(
				this.props.userState.get('completeContainerRef').current,
				1,
				{
					scrollTop: this.props.userState.get('invitationDivRefs').get(id).current.offsetTop - 160
				});
		}
	}

	sendSegmentData = (tabName) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			clickedTab: tabName
		}
		window.analytics.track(
			'Journey information tab clicked',
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	onTabClick = (tab) => {
		console.log('tab clicked', tab);
		this.sendSegmentData(tab.name);
		this.scrollContainer(tab.id);
		this.props.setUserState({
			invitationDivName: tab.id
		});
	}

	renderEachTab = (eachTab, index, myStyles) => {
		return (
			<div
				key={`eachTab-${index}`}
				styleName="each-tab"
				onClick={() => {
					this.onTabClick(eachTab)
				}}
			>
				<div
					styleName="each-tab-name"
					className={(this.props.divName == eachTab.id) ? css(myStyles.tabNameActive) : css(myStyles.tabName)}
				>
					{eachTab.name}
				</div>
				{
					this.props.divName == eachTab.id
						? <div styleName="each-tab-line" className={css(myStyles.tabLine)}></div>
						: null
				}

			</div>
		);
	}

	renderLoader = (myStyles) => {
		const tabs = [];
		for (let index = 0; index < 4; index++) {
			tabs.push(
				<div
					key={`eachTab-loader-${index}`}
					styleName="each-tab"
				>
					<div styleName="each-tab-name-loader">
						<TabLoader />
					</div>
				</div>
			);
		}

		return tabs;
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);

		let tabs = [];

		if (!this.props.isBatchLabelsAvailable) {
			tabs = this.renderLoader(myStyles);
		}
		else {
			tabs = this.props.tabsConfig.map((eachTab, index) => {
				return this.renderEachTab(eachTab, index, myStyles);
			});
		}

		return (
			<div
				className={css(myStyles.navigationContainer)}
				styleName={this.props.showStickyHeader ? "navigation-container-relative" : "navigation-container"}
			>
				<div styleName="navigation-tabs-container">
					<div styleName="tabs-container">
						{tabs}
					</div>
					<div className={css(myStyles.lineContainer)} styleName="line-container"></div>
				</div>
			</div>
		);
	}
}

export default TabsBar;