import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import AssessmentBackground from 'images/assessment-title-bg.png';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		headingContainer: {
			background: `linear-gradient(135deg, ${hexToRgbA(skin.get('primaryColor'), 0.1)} , ${hexToRgbA(skin.get('secondaryColor'), 0.1)})`,
			borderTop: `8px solid ${skin.get('secondaryColor')}`
			// backgroundImage: `url(${AssessmentBackground})`,
			// backgroundSize: 'cover',
			// backgroundPosition: 'top'
		},
        headingImage: {
            backgroundImage: `url(${AssessmentBackground})`,
            backgroundSize: "100%",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
        },
		headingSubTitle: {
			...myTypography.overline,
			color: hexToRgbA(skin.get('greyColor1'), 0.85 * 0.6)
		},
		headingTitle: {
			...myTypography.h3,
			fontSize: '40px',
			lineHeight: 1,
			color: hexToRgbA(skin.get('greyColor1'), 0.85)
		}
	});
}

export default getSkin;