import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './feedback.module.sass';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import MenuList from 'commonComponents/menuList';
import ItemTitle from 'components/assessment/itemTitle';
import NextItem from 'commonComponents/nextItem';
import { getUserActiveBatchDetails, getActiveBatchDetails } from 'utils/batchUtils';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import Footer from 'commonComponents/footer';
import applyLabel from 'utils/LabelProvider';
import routes from 'utils/webAppRoutes/routeConstants';
import FeedbackQuestions from 'components/feedbackQuestions';
import FeedbackCompletion from './feedbackCompletion';
import ItemDetails from './showItemDetails';
import ItemStatus from 'components/feedback/itemStatus';
import Dialog from 'commonComponents/dialog/Dialog';

import ToolFAQ from 'commonComponents/toolFAQ';
import { getFAQsForItem } from 'containers/containerHelpers/assessmentContainerHelper.js';
import { checkIfPresent } from 'utils/utilFunctions';
import enumConstants from 'constants/enums';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import CloseCircle from 'svgComponents/closeCircle';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import CompletedFeedbackIcon from 'svgComponents/completedFeedbackIcon';
import TickMarkWithGreenFill from 'svgComponents/tickMarkWithGreenFill';
import ComponentErrorBoundary from 'components/errorBoundaries/componentErrorBoundary';
import LearningPhaseItemFeedback from './learningPhaseItemFeedback';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Feedback extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            isShowThankyouClicked: false,
            openFeedback: false
        };
    }

    sendSegmentData = () => {
        let segmentData = {
            ...(this.props.match.params),
            referrer: window.location.href,
            pathHash: window.location.hash
        }
        let event = !this.state.openFeedback ? 'Item Feedback opened' : 'Item Feedback closed';
        window.analytics.track(
            event,
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    toggleOpenFeedback = () => {
        this.sendSegmentData();
        this.setState({
            openFeedback: !this.state.openFeedback
        })
    }

    componentWillReceiveProps(nextProps) {
        if (
            checkIfPresent(nextProps.activePhaseDetails)
            && checkIfPresent(nextProps.phases)
        ) {
            const currentUserPhase = nextProps.phases.get('userPhasesList').filter((userPhase) => {
                return (userPhase.get('id') === nextProps.activePhaseDetails.id)
            });
            if (
                currentUserPhase.size !== 0
                && currentUserPhase.get(0).get('workflowState') == enumConstants.get('COMPLETED')
            ) {
                this.props.setUserState({
                    makeReportAvailable: true
                });
            }
        }
    }

    componentDidMount() {
        const screenWidth = typeof window === 'object' ? window.innerWidth : null;

        if (screenWidth >= 1280) {
            this.props.setSideBarFlag(true);
        }
    }

    componentWillUnmount = () => {
        this.props.setAssessmentShowDetails(false)
        this.props.setFeedbackCompletedTemp(false)
    }

    goToJourneyDashboard = () => {
        const { isSideBarOpen } = this.props;
        this.props.setSideBarFlag(!isSideBarOpen);
        const route = routes.get('BATCH');
        const params = [{ 'BATCH_ID': this.props.userState.get('currentBatchId') }];
        updateRoute({ route, params });
    }

    setShowThankyouToTrue = () => {
        this.setState({
            isShowThankyouClicked: true
        })
    }

    onClickOfSubmitFeedback = () => {
        const { selectedItem, nextItem, setSelectedItemId } = this.props;
        const { phaseId } = selectedItem.get('phaseId');
        const nextItemId = nextItem.get('id');
        const batchId = selectedItem.get('batchId');
        const route = routes.get('PHASE_MEASURES');
        const params = [{ 'BATCH_ID': batchId },
        { 'PHASE_ID': phaseId }];

        updateRoute({ route, params });
        if (nextItem.size) {
            setSelectedItemId(nextItemId);
            this.props.setUserState({
                currentItemId: nextItemId
            });
            const route = routes.get('BATCH_ITEM');
            const params = [{ 'BATCH_ID': batchId }, { 'ITEM_ID': nextItemId }];

            updateRoute({ route, params });
        } else {
            const route = routes.get('PHASE_MEASURES');
            const params = [{ 'BATCH_ID': batchId }, { 'PHASE_ID': phaseId }];

            updateRoute({ route, params });
        }
    }

    showDetails = () => {
        const { setAssessmentShowDetails } = this.props;
        setAssessmentShowDetails(true)
    }

    toggleDialog = () => {
        this.setState({
            showDialog: !this.state.showDialog
        });
    }

    renderDialog = () => {
        if (!this.state.showDialog) {
            return null;
        }

        const popupMessage = this.props.getLabel('item_unavailable_label');
        const popupOkButtonText = this.props.getLabel('okay_label');;
        return (
            <Dialog
                key="item-status-dialog"
                dialogMessage={popupMessage}
                okAction={this.toggleDialog}
                okButtonText={popupOkButtonText}
            />
        );
    }

    shouldShowFeedback = (item) => {
        if (!item) return false;
        const { selectedUserItem } = this.props;
        if (item.get('isLocked') || (item.get('isExpired') && selectedUserItem.get('workflowStatus') != enumConstants.get('COMPLETED'))) return false;

        return item.getIn(['settings', 'feedbackScenarioId']) &&
            item.getIn(['settings', 'isFeedbackEnabled']) &&
            item.getIn(['settings', 'isFeedbackEnabled']) === 'true';
    }

    showDetailsComponent = () => {
        const {
            selectedItem,
            activePhaseDetails,
            match
        } = this.props;
        return (
            <div styleName="item-details-holder">
                <ItemDetails
                    selectedItem={selectedItem}
                    showDetails={true}
                    activePhaseDetails={activePhaseDetails}
                />
            </div>
        )
    }

    showItemDetailsComponent = (myStyles) => {
        const { activePhaseDetails } = this.props;
        if (activePhaseDetails && activePhaseDetails.type === 'Learning') {
            return this.showDetailsComponent(myStyles);
        }
        const {
            ui,
            getLabel
        } = this.props;
        if (ui.get("isAssessmentShowDetailsClicked") === true)
            return this.showDetailsComponent(myStyles);
        else
            return (
                <div className={css(myStyles.showDetailsButton)}
                    styleName="show-details-button"
                    onClick={this.showDetails}
                    style={{ margin: 'auto' }}
                >
                    {getLabel('show_details_label')}
                </div>
            )
    }

    renderLearningPhaseItemFeedback = () => {
        const {
            loaders,
            itemFeedback,
            selectedItem,
            batches,
            match,
            submitAnswer,
            submitFeedback,
            user
        } = this.props;
        const myStyles = getSkin(this.props.skinGuide);

        const activeBatchDetails = getActiveBatchDetails(batches);
        const userActiveBatchDetails = getUserActiveBatchDetails(batches);

        const isSimulationJourney = activeBatchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true';

        return (
            <div styleName="learning-phase-feedback-cnt">
                <LearningPhaseItemFeedback
                    itemFeedback={itemFeedback}
                    isSimulationJourney={isSimulationJourney}
                    match={match}
                    activeBatchDetails={activeBatchDetails}
                    userActiveBatchDetails={userActiveBatchDetails}
                    selectedItem={selectedItem}
                    submitAnswer={submitAnswer}
                    loaders={loaders}
                    submitFeedback={submitFeedback}
                    user={user}
                />
                {this.showItemDetailsComponent(myStyles)}
            </div>
        )
    }

    renderFeedbackBox = () => {
        const {
            ui,
            faqsList,
            loaders,
            items,
            selectedItem,
            itemFeedback,
            activePhaseDetails
        } = this.props;

        if (
            activePhaseDetails && activePhaseDetails.type === 'Learning'
            && selectedItem && selectedItem.get('itemType')
        ) {
            return this.renderLearningPhaseItemFeedback();
        }

        const { openFeedback } = this.state;
        const myStyles = getSkin(this.props.skinGuide);

        const isFeedbackCompleted = itemFeedback.get('isFeedbackCompleted');

        const itemId = +this.props.match.params.itemId;
        return (
            <div styleName="feedback-box-container">
                {this.renderStatus()}
                {
                    this.shouldShowFeedback(selectedItem) &&
                    <div styleName="feedback-questions-outer-cnt">
                        {
                            loaders.get('isFetchingFeedback') ?
                                null
                                :
                                openFeedback && itemFeedback.get('isFeedbackQuestionsFetched') ?
                                    [
                                        <div styleName="feedback-inner-cnt">
                                            <FeedbackQuestions {...this.props} setShowThankyouToTrue={this.setShowThankyouToTrue} />
                                            {
                                                isFeedbackCompleted &&
                                                <div styleName="thank-you-note-cnt">
                                                    <div styleName="tickmark-cnt">
                                                        <TickMarkWithGreenFill />
                                                    </div>
                                                    <div className={css(myStyles.thankYouNote)}>
                                                        {this.props.getLabelWithDefaults('thank_you_note_after_feedback', "Thanks for your feedback")}
                                                    </div>
                                                </div>
                                            }
                                        </div>,

                                        <div styleName="close-feedback-button" onClick={this.toggleOpenFeedback}>
                                            <CloseCircle circleFillOpacity={0.3} strokeColor="white" />
                                        </div>
                                    ]
                                    :
                                    <div styleName="minimized-feedback" onClick={this.toggleOpenFeedback}>
                                        <div styleName="feedback-icon-cnt">
                                            {
                                                isFeedbackCompleted ?
                                                    <CompletedFeedbackIcon />
                                                    :
                                                    <FeedbackIcon />
                                            }
                                        </div>
                                    </div>
                        }
                    </div>
                }
                {
                    (ui.get("isAssessmentShowDetailsClicked") == true)
                        ?
                        <div styleName="item-details-holder">
                            <ItemDetails
                                selectedItem={this.props.selectedItem}
                                showDetails={true}
                                activePhaseDetails={activePhaseDetails}
                            />
                            {
                                activePhaseDetails && activePhaseDetails.type !== "Learning" &&
                                <ToolFAQ
                                    faqsList={getFAQsForItem(faqsList, itemId, items)}
                                    isFromFeedbackScreen={true}
                                    loaders={loaders}
                                />
                            }
                        </div>
                        :
                        <div className={css(myStyles.showDetailsButton)}
                            styleName="show-details-button"
                            onClick={this.showDetails}
                        >
                            {this.props.getLabel('show_details_label')}
                        </div>
                }
            </div>
        );
    }

    renderFeedbackQuestions = (myStyles) => {
        const {
            setSelectedItemId,
            nextItem,
            activePhaseDetails,
            setPhaseWorkflowStatus,
            batches
        } = this.props;
        const isFeedbackScreen = true;
        const userActiveBatchDetails = getUserActiveBatchDetails(batches);

        return (
            <div styleName="feedback-scrollable-content">
                <div styleName="feedback-row">
                    <div styleName="feedback-col">
                        {this.renderFeedbackBox(myStyles)}
                    </div>
                </div>
                <NextItem {...this.props}
                    nextItem={nextItem}
                    activePhaseDetails={activePhaseDetails}
                    setSelectedItemId={setSelectedItemId}
                    isFeedbackScreen={isFeedbackScreen}
                    setUserState={this.props.setUserState}
                    setPhaseWorkflowStatus={setPhaseWorkflowStatus}
                    toggleDialog={this.toggleDialog}
                    userActiveBatchDetails={userActiveBatchDetails}
                />
                <Footer />
            </div>
        )
    }

    renderStatus = () => {
        return <ItemStatus {...this.props} />
    }

    renderFeedbackCompletion = () => {
        const {
            setSelectedItemId,
            nextItem,
            activePhaseDetails,
            setPhaseWorkflowStatus,
            batches
        } = this.props;

        const isFeedbackScreen = true;
        const { isShowThankyouClicked } = this.state;
        const userActiveBatchDetails = getUserActiveBatchDetails(batches);

        return (
            <div styleName="feedback-scrollable-content">
                <FeedbackCompletion
                    {...this.props}
                    isShowThankyouClicked={isShowThankyouClicked}
                    toggleDialog={this.toggleDialog}
                    userActiveBatchDetails={userActiveBatchDetails}
                />
                <NextItem {...this.props}
                    nextItem={nextItem}
                    activePhaseDetails={activePhaseDetails}
                    setSelectedItemId={setSelectedItemId}
                    isFeedbackScreen={isFeedbackScreen}
                    setUserState={this.props.setUserState}
                    setPhaseWorkflowStatus={setPhaseWorkflowStatus}
                    toggleDialog={this.toggleDialog}
                    userActiveBatchDetails={userActiveBatchDetails}
                />
                <Footer />
            </div>
        )
    }

    render() {
        const { isSideBarOpen,
            selectedUserItem,
            setSideBarFlag,
            selectedItem,
            activePhaseDetails,
            itemsListInActivePhase,
            skinGuide,
            location,
            batches
        } = this.props;
        const userActiveBatchDetails = getUserActiveBatchDetails(this.props.batches);
        const activeBatchDetails = getActiveBatchDetails(batches);

        const { phases, items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails);
        const itemId = +this.props.match.params.itemId;
        const apiError = this.props.apiErrors.get('fetchPhaseItemsError') || this.props.apiErrors.get('fetchUserPhaseItemsError') || this.props.apiErrors.get('fetchPhaseItemDetailsError') || this.props.apiErrors.get('fetchItemFeedbackError') || this.props.apiErrors.get('fetchUserItemFeedbackError');

        const userItemsList = items.get('userItemsList');

        let styleSideBar = 'side-bar-collapsed';
        let assessmentStyle = 'assesment-items-collapsed';
        if (isSideBarOpen) {
            styleSideBar = 'side-bar-expanded';
            assessmentStyle = 'assesment-items-expanded';
        }
        const myStyles = getSkin(skinGuide);
        const isFeedbackScreen = true;
        let showCompletedBar = false;
        if (
            checkIfPresent(activePhaseDetails)
            && checkIfPresent(activePhaseDetails.settings)
            && checkIfPresent(activePhaseDetails.settings.isReportScreenAvailable)
            && activePhaseDetails.settings.isReportScreenAvailable == "false"
            && this.props.userState.get('makeReportAvailable')
        ) {
            showCompletedBar = true;
        }

        const isSimulationJourney = activeBatchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true';

        return (
            <div styleName="assesment-container" className={css(myStyles.assessmentBorder)}>
                <div styleName="side-bar-product-container">
                    {
                        !isSimulationJourney &&
                        <div styleName={`${styleSideBar} menu-container`}
                            style={{
                                position: this.props.userState.get('makeLeftBarFixed') ? 'fixed' : 'absolute',
                                height: this.props.userState.get('makeLeftBarFixed') ? '100vh' : 'calc(100vh - 67px)'
                            }}
                        >
                            <ComponentErrorBoundary>
                                <MenuList
                                    selectedUserItem={selectedUserItem}
                                    list={itemsListInActivePhase}
                                    userItemsList={userItemsList}
                                    isSideBarOpen={isSideBarOpen}
                                    activePhaseDetails={activePhaseDetails}
                                    setSideBarFlag={setSideBarFlag}
                                    phases={phases}
                                    batchId={selectedItem.get('batchId')}
                                    setUserState={this.props.setUserState}
                                    location={location}
                                    goToJourneyDashboard={this.goToJourneyDashboard}
                                    match={this.props.match}
                                    toggleDialog={this.toggleDialog}
                                    userState={this.props.userState}
                                    apiErrors={this.props.apiErrors}
                                    fetchItemData={this.props.fetchItemDataForFeedback}
                                    currentPhaseData={activePhaseDetails} // this is getting calculated differently hence duplicate
                                    feedbackStatuses={this.props.feedbackStatuses}
                                    attachmentsList={this.props.attachmentsList}
                                />
                            </ComponentErrorBoundary>
                        </div>
                    }
                    <div
                        styleName={`${assessmentStyle} content-row`}
                        style={isSimulationJourney ? { marginLeft: 'unset', width: '100%' } : {}}
                    >
                        <ComponentErrorBoundary>
                            <APIErrorBoundary
                                key={itemId}
                                apiError={apiError}
                                retryHandler={() => this.props.fetchItemDataForFeedback(+this.props.match.params.batchId, itemId)}
                                style={{
                                    width: '100vw',
                                    height: 'calc(100vh - 150px)',
                                    marginTop: '150px'
                                }}
                            >
                                {
                                    showCompletedBar
                                        ? <div className={css(myStyles.completedTitleContainer)} styleName='completed-title-container'>
                                            <div className={css(myStyles.completedTitleImage)} styleName="completed-title-image" ></div>
                                            <div className={css(myStyles.completedContent)} styleName="completed-content">
                                                {this.props.getLabel('completed_phase_label')}
                                            </div>
                                        </div>
                                        : null
                                }
                                {
                                    this.props.userState.get('showStickyHeader')
                                        ? <div style={{
                                            width: isSimulationJourney ? '100%' : (isSideBarOpen ? 'calc(100% - 350px)' : '100%'),
                                            position: 'fixed',
                                            top: 0,
                                            left: isSideBarOpen ? 'none' : 0,
                                            right: isSideBarOpen ? 0 : 'none',
                                            backgroundColor: 'white',
                                            zIndex: 3,
                                            height: 'auto',
                                            marginLeft: isSideBarOpen ? '350px' : 0
                                        }}>
                                            <ItemTitle {...this.props}
                                                activePhaseDetails={activePhaseDetails}
                                                selectedUserItem={selectedUserItem}
                                                isSideBarOpen={isSideBarOpen}
                                                isFeedbackScreen={isFeedbackScreen}
                                                // divRef={this.props.userState.get('stickyRef')}
                                                showStickyHeader={this.props.userState.get('showStickyHeader')}
                                                isSimulationJourney={isSimulationJourney}
                                                activeBatchDetails={activeBatchDetails}
                                            />
                                        </div>
                                        : null
                                }
                                <ItemTitle {...this.props}
                                    activePhaseDetails={activePhaseDetails}
                                    selectedUserItem={selectedUserItem}
                                    isSideBarOpen={isSideBarOpen}
                                    isFeedbackScreen={isFeedbackScreen}
                                    divRef={this.props.userState.get('stickyRef')}
                                    showStickyHeader={false}
                                    isSimulationJourney={isSimulationJourney}
                                    activeBatchDetails={activeBatchDetails}
                                />
                                {
                                    // (itemFeedback.get('isFeedbackCompleted'))
                                    //     ?
                                    //     this.renderFeedbackCompletion(myStyles)
                                    //     :
                                    this.renderFeedbackQuestions(myStyles)

                                }
                            </APIErrorBoundary>
                        </ComponentErrorBoundary>
                    </div>
                </div>
                {this.renderDialog()}
            </div>
        );
    }
}

export default Feedback;
