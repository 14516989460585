import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		timelineLine: {
			backgroundColor: skin.get('greyColor5')
		},
		completedBackground: {
			backgroundColor: skin.get('darkSkyBlue')
		},
		pendingBackground: {
			backgroundColor: skin.get('greyColor4')
		},
		startedBackground: {
			backgroundColor: hexToRgbA(skin.get('darkSkyBlue'), 0.6),
		},
		selectedBackground: {
			backgroundColor: skin.get('secondaryColor')
		}
	});
}

export default getSkin;