import React, { Component } from 'react';
import styles from './downArrow.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DownArrow extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		let {fillColor} = this.props
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 12 8">
					<path fill={fillColor || skinHex.get('secondaryColor')} fillRule="nonzero" d="M6.002 7.5c-.33 0-.645-.141-.863-.39L.243 1.547A.922.922 0 0 1 1.627.33l4.28 4.866a.125.125 0 0 0 .188 0L10.376.33a.922.922 0 1 1 1.384 1.217L6.866 7.11c-.219.248-.533.39-.864.391z"/>
				</svg>
			</div>
		);
	}
}



export default DownArrow;
