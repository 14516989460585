import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        title: {
			...myTypography.h5,
			fontWeight: 600,
			color: skin.get('contentColor')
		},
		viewMoreButton: {
			...myTypography.button,
			color: skin.get('secondaryColor'),
			textTransform: 'uppercase',
			cursor: 'pointer',
			':hover': {
				textDecoration: 'underline'
			}
		},
		singleBox: {
			boxShadow: `0 2px 4px 0 ${hexToRgbA(skin.get('black'), 0.16)}`,
			backgroundImage: `linear-gradient(to bottom, ${skin.get('white')}, #fafaff)`,
			border: `solid 1px rgba(151, 151, 151, 0.16)`,
			color: skin.get('greyColor2'),
			...myTypography.caption
		}
    });
}

export default getSkin;