import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrReplaceObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    batchLeaderboard: [],
    isLeaderboardDataFetching: false,
    fetchLeaderboardError: null
});

const leaderboard = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_MSTEAMS_BATCH_LEADERBOARD'):
            return state.set(
                'batchLeaderboard',
                setOrReplaceObjectInList(
                    state.get('batchLeaderboard'),
                    action.leaderboardData,
                    'batchId',
                    'batchId'
                )
            ).set('isLeaderboardDataFetching', false).set('fetchLeaderboardError', null);
        case actionConsts.get('SET_MSTEAMS_BATCH_LEADERBOARD_FETCHING_DATA_FLAG'):
            if (action.payload.isLeaderboardDataFetching !== undefined) {
                return state.set(
                    'isLeaderboardDataFetching', action.payload.isLeaderboardDataFetching
                );
            }
            return state;
        case actionConsts.get('SET_API_ERROR'):
            if (action.errorKey === 'fetchLeaderboardError') {
                return state.set(
                    action.errorKey, action.errorMessage
                ).set('isLeaderboardDataFetching', false);
            }
            return state;
        default:
            return state;
    }
};

export default leaderboard;