import React, { Component } from 'react';
import styles from './selectOption.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { components } from "react-select";
import Checkmark from 'svgComponents/checkmark';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class Option extends Component {
	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<components.Option {...this.props}>
				<div styleName="option-cnt">
					<div styleName={`checkbox-cnt${this.props.isSelected ? ' selected' : ''}`}>
						{
							this.props.isSelected &&
							<Checkmark fillColor={'white'} />
						}
					</div>
					<div className={css(myStyles.label)}>{this.props.label} </div>
				</div>
			</components.Option>
		);
	}
};

export default Option;