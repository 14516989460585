import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        selected: {
            backgroundColor: '#d44980',
            border: 'solid 2px #d5d9e7'
        },
        disabled:{
            backgroundColor: '#979eb7',
            border: 'solid 2px #ffffff'
        },
        completed:{
            backgroundColor: skin.get('darkSkyBlue'),
            border: 'solid 2px #ffffff'
        },
        started:{
            backgroundColor: hexToRgbA(skin.get('darkSkyBlue'), 0.6),
            border: 'solid 2px #ffffff'
        },  
        line:{
            backgroundColor: '#d5d9e7'
        },
        whiteLine:{
            display: 'none'
        },
        noLine: {
            display: 'none'
        },
        firstLine: {
            height: '65%',
            top: '35%',
            backgroundColor: '#d5d9e7'
        },
        firstLineModule: {
            height: 'calc(100% - 20px)',
            top: '20px',
            backgroundColor: '#d5d9e7'
        },
        lastLine:{
            height: '35% !important',
            backgroundColor: '#d5d9e7'
        },
        lastLineModule:{
            height: '20px',
            backgroundColor: '#d5d9e7'
        },
        type:{
            ...myTypography.caption,
            color: hexToRgbA(skin.get('greyColor3'), 0.6),
            fontWeight: 'normal'
        },
        date:{
            ...myTypography.button,
            color: hexToRgbA(skin.get('greyColor3'), 0.6),
            fontWeight: 'normal'
        },
        expiredDate:{
            ...myTypography.button,
            color: hexToRgbA(skin.get('scarlet'), 0.6),
            fontWeight: 'normal'
        },
        completedName:{
            ...myTypography.overline,
            color: skin.get('greyColor4'),
            fontWeight: 600
        },
        selectedName:{
            ...myTypography.overline,
            color: skin.get('secondaryColor'),
            fontWeight: 600
        },
        disabledName:{
            ...myTypography.overline,
            color: skin.get('black'),
            fontWeight: 600
        },
        timeline:{
            backgroundColor:' #f6f7f9',
            boxShadow: '1px 0 0 0 #d5d8e2'
        },
        mainContainer: {
            ':hover': {
               backgroundColor: hexToRgbA(skin.get('greyColor5'),0.5)
            }
        }
    });
}

export default getSkin;