/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: tools.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * tools reducer is responsible for tools related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { mergeListsBasedOnKey } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    activeToolId: -1,
    faqsList: []
});

const tools = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('UPDATE_ITEM_DETAILED_DATA'): //add condition to handle when the item data already available (change the tool id)
            return state.set(
                'activeToolId', Immutable.fromJS(action.payload.toolId)
            );
        case actionConsts.get('UPDATE_TOOLS_FAQS_LIST'):
            // state.set('faqsList', []);
            return state.set(
                'faqsList',
                mergeListsBasedOnKey(state.get('faqsList'), action.toolFaqs, 'id')
            );
        default:
            return state;
    }
};

export default tools;