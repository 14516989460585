import React, { Component } from 'react';
import styles from './simpleDialog.module.sass';
import CSSModules from 'react-css-modules';
import CloseCircle from 'svgComponents/closeCircle';

@CSSModules(styles, { allowMultiple: true })
class SimpleDialog extends Component {

	getPopupContent = () => {
		return (
			<div styleName="popup-content">
				{this.props.children}
			</div>
		);
    }
    
    closeDialog = () => {
        this.props.onCloseDialog();
    }

	render() {
		return (
			<div styleName="simple-popup-cnt">
				<div styleName="popup-content-cnt" style={this.props.contentContainerStyle}>
					{
						this.getPopupContent()
					}
					<div styleName="popup-close-icon" onClick={this.closeDialog}>
						<CloseCircle circleFill="#575E79" circleFillOpacity="1" />
					</div>
				</div>
				<div styleName="popup-overlay-bg" onClick={this.closeDialog}>

				</div>
				
			</div>
		);
	}
}

SimpleDialog.defaultProps = {
	contentContainerStyle: {}
}

export default SimpleDialog;