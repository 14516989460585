import { fetchBatchDetails } from 'actions/batches/newApiActions';
import FetchUserPhaseDetails from 'actions/serverCalls/phases/FetchUserPhaseDetails';
import SetPhaseWorkflowStatus from 'actions/serverCalls/phases/SetPhaseWorkflowStatus';
import FetchItemFeedback from 'actions/serverCalls/itemFeedback/FetchItemFeedback';
import { fetchUserItemFeedback } from 'actions/itemFeedback/apiActions';
import {
    isMobile
} from 'react-device-detect';
import environment from 'utils/environment';
import { filterObjectFromArray } from 'utils/objectUtils';
import SubmitAnswer from 'actions/serverCalls/itemFeedback/SubmitAnswer';
import SubmitFeedback from 'actions/serverCalls/itemFeedback/SubmitFeedback';
import { updateFetchFeedbackStatus } from 'actions/loaders/actionCreators';


const fetchPhaseBaseDataThunk = (dispatch, getState, batchId, phaseId) => {
    return dispatch(fetchBatchDetails(batchId, false)).then(() => {
        return FetchUserPhaseDetails.call({
            phaseId
        });
    });
}

const getDeviceType = () => {
    let device = "WEBBROWSER"
    if (isMobile) {
        device = 'WEBMOBILE';
    }
    return device;
}

const localUserdata = () => {
    if (environment.REACT_APP_ENV == 'local') {
        return {
            'x-userid': 2,
            userId: 2,
        }
    } else {
        return {}
    }
}

const fetchPhaseBaseData = (batchId, phaseId) => (dispatch, getState) =>
    fetchPhaseBaseDataThunk(dispatch, getState, batchId, phaseId);

const setPhaseWorkflowStatusThunk = (
    dispatch, getState, batchId, phaseId, workflowState, routeObj, failureRouteObj = {}
) => {
    // const batchId = getState().get('userState').get('currentBatchId');
    // const phaseId = getState().get('userState').get('currentPhaseId');
    return SetPhaseWorkflowStatus.call({
        phaseId,
        routeObj,
        failureRouteObj,
        shouldRetry: false,
        queryParameters: { batchId },
        body: { workflowState }
    }).then(
        FetchUserPhaseDetails.call({ phaseId })
    );
}

const fetchPhaseFeedbackData = (batchId, phaseId, scenario, entityType) => (dispatch, getState) => {
    dispatch(updateFetchFeedbackStatus(true));
    return fetchPhaseBaseDataThunk(dispatch, getState, batchId, phaseId)
        .then(() => {
            let langID = 'en_US';
            let feedbackScenario = scenario;
            const activePhaseDetails = getState().getIn(['phases', 'phasesList']).find(phase => phase.get('id') === phaseId)
            if (activePhaseDetails)
                feedbackScenario = activePhaseDetails.getIn(['settings', 'feedbackScenarioId'])

            const batchesList = getState().get('batches').get('batchesList');
            const batchId = getState().get('userState').get('currentBatchId');
            const activeBatchDetails = filterObjectFromArray(batchesList, 'id', batchId);

            if (activeBatchDetails && activeBatchDetails.size) {
                langID = activeBatchDetails.get('journey').get('lang');
            }

            const orgLang = getState().getIn(['userOrganization', 'userOrganization', 'lang']) ;

            if(getState().getIn(['userOrganization', 'userOrganization', 'lang']) !== 'en_US'){
                langID = orgLang; /*if org lang other than English (US), override */
            }

            const apiInfoObj = {
                queryParameters: {
                    device: getDeviceType(),
                    scenario: feedbackScenario,
                    lang: langID
                }
            };
            FetchItemFeedback.call(apiInfoObj).then(() => {
                dispatch(fetchUserItemFeedback(batchId, phaseId, entityType, feedbackScenario));
            })
        });
}

const setPhaseWorkflowStatus = (batchId, phaseId, workflowState, routeObj, failureRouteObj) => (dispatch, getState) =>
    setPhaseWorkflowStatusThunk(dispatch, getState, batchId, phaseId, workflowState, routeObj, failureRouteObj);

const submitAnswerForPhase = (payload, questionId, entityId, entityType, scenario) => (dispatch, getState) => {
    let feedbackScenario = scenario;
    let currentPhase = getState().getIn(['phases', 'phasesList']).find(phase => phase.get('id') === +entityId)
    if (
        currentPhase
        && currentPhase.getIn(['settings', 'feedbackScenarioId'])
    ) {
        feedbackScenario = currentPhase.getIn(['settings', 'feedbackScenarioId'])
    }

    const apiInfoObj = {
        queryParameters: {
            device: getDeviceType(),
            scenario: feedbackScenario,
            entityType: entityType,
            entityId: entityId,
            groupId: getState().get('userState').get('currentBatchId'),
            serviceName: 'ADPlatform',
            env: environment.REACT_APP_FEEDBACK_ENV
        },
        body: payload,
        questionId
    }

    return SubmitAnswer.call(apiInfoObj);

};


const submitFeedbackForPhase = (payload, entityId, entityType, scenario) => (dispatch, getState) => {
    let feedbackScenario = scenario;
    let currentPhase = getState().getIn(['phases', 'phasesList']).find(phase => phase.get('id') === +entityId)
    if (
        currentPhase
        && currentPhase.getIn(['settings', 'feedbackScenarioId'])
    ) {
        feedbackScenario = currentPhase.getIn(['settings', 'feedbackScenarioId'])
    }

    const apiInfoObj = {
        queryParameters: {
            device: getDeviceType(),
            scenario: feedbackScenario,
            entityType: entityType,
            entityId: entityId,
            groupId: getState().get('userState').get('currentBatchId'),
            serviceName: 'ADPlatform',
            env: environment.REACT_APP_FEEDBACK_ENV
        },
        body: payload
    }

    return SubmitFeedback.call(apiInfoObj);

};

export {
    fetchPhaseBaseData,
    setPhaseWorkflowStatus,
    setPhaseWorkflowStatusThunk,
    fetchPhaseFeedbackData,
    submitAnswerForPhase,
    submitFeedbackForPhase
};