import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import FeedbackPattern from 'images/feedback_pattern.png';
import QUIZ_SCORE_BG from 'images/development/quiz_score_bg.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        completed: {
            ...myTypography.overline,
            fontWeight: '600',
            color: skin.get('greyColor1')
        },
        timestamp: {
            ...myTypography.caption,
            color: skin.get('greyColor4')
        },
        completionNote: {
            ...myTypography.h6,
            lineHeight: 'normal',
            color: 'white',
            backgroundImage: `url(${QUIZ_SCORE_BG})`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: "top"
        }
    });
}

export default getSkin;