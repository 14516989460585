import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './rating.module.sass';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import MenuList from 'commonComponents/menuList';
import ItemTitle from 'components/assessment/itemTitle';
import NextItem from 'commonComponents/nextItem';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import Footer from 'commonComponents/footer';
import applyLabel from 'utils/LabelProvider';
import RatingStar from 'svgComponents/ratingStar';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Rating extends Component {

	constructor(props) {
		super(props);
		const starsFromProp = this.getStarsVal(props.question.get('value'));
		this.state = {
			stars: starsFromProp
		}
	}


	getStarsVal = (score) => {
		let stars = [];
		const numStars = this.props.question.get('max');

		for (let i = 0; i < numStars; i++) {
			stars.push({
				color: 'none',
				opacity: '0.4',
				strokeColor: null
			})
		}

		for (let i = 0; i < score; i++) {
			let star = stars[i];
			star.color = '#2c4bb7';
			star.strokeColor = 'none';
			stars[i] = star;
		}
		return stars

	}

	rateTool = (score) => {
		let stars = [];
		const numStars = this.props.question.get('max');

		for (let i = 0; i < numStars; i++) {
			stars.push({
				color: 'none',
				opacity: '0.4',
				strokeColor: null
			})
		}

		for (let i = 0; i <= score; i++) {
			let star = stars[i];
			star.color = '#2c4bb7';
			star.strokeColor = 'none';
			stars[i] = star;
		}

		this.setState({ stars: stars });
		const payload = {
			value: score + 1,
		};
		const questionId = this.props.question.get('id');

		this.props.submitAnswer(payload, questionId);
	}

	renderFeedbackRating = (score, scale) => {
		const { stars } = this.state;
		let starArray = [];
		for (let i = 0; i < scale; i++) {
			starArray.push(
				<div key={`rating_star_${i}`} styleName='star' onClick={() => this.rateTool(i)}>
					<RatingStar
						id={i}
						color={stars[i].color}
						strokeColor={stars[i].strokeColor}
					/>
				</div>
			);
		}
		return starArray;
	}


	renderFeedbackBox = (myStyles) => {
		return (
			<div styleName="feedback-box-container">
				<div className={css(myStyles.textStarStyle)} styleName="assessment-result-text">
					{this.props.question.get('text')}
				</div>
				<div styleName="rating-stars">
					{this.renderFeedbackRating(1, this.props.question.get('max'))}
				</div>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return this.renderFeedbackBox(myStyles);
	}
}

export default Rating;
