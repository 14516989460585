import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';

class CheckIfBatchExists extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => urls.get('PLATFORM').get('GET_BATCH_EXISTENCE_STATUS').replace('BATCH_ID', apiInfoObject.batchId)

    successCall = (response) => {
        console.log('CheckIfBatchExists: response', response);        
        return response;
    }

    onError = (response) => {
        console.log("error", response);        
    }

}

export default new CheckIfBatchExists();
