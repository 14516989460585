import Immutable from 'immutable';
import environment from 'utils/environment';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';

var apiURL = '';
if (environment.REACT_APP_ENV === 'local') {
    apiURL = getApiUrl().feedbackUrl;
} else {
    apiURL = getDomainUrl()+'/fdbk-api';
}

const feedbackUrls = () => {
    return Immutable.Map({
        GET_ITEM_FEEDBACK: `${apiURL}/feedback`,
        GET_USER_ITEM_FEEDBACK: `${apiURL}/me/feedback`,
        SUBMIT_USER_ITEM_FEEDBACK_EACH_QUESTION: `${apiURL}/me/question/QUESTION_ID`,
        SUBMIT_FEEDBACK: `${apiURL}/feedback`,
    });

};

export default feedbackUrls;