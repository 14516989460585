import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setProductLaunch } from 'actions/moduleItems/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchProductLaunch extends NetPackWrapper {
	constructor() {
		super('GET', true);
	}

	prepareUrl = apiInfoObject => {
		const moduleItem = this.getState().get('moduleItem').get('moduleItem');

		const iframeCloseUrl = window.location.protocol +
			'//' + window.location.host + '/iframeClose.html';
		const returnURL = encodeURIComponent(iframeCloseUrl);

		if(moduleItem.get('type')=='Simulation'){
			return urls.get('DEVELOPMENT_PHASE').get('PRODUCT_LAUNCH')
			.replace('_COURSE_ID_', apiInfoObject.courseId)
			.replace('_ASSIGNMENT_ID_', moduleItem.get('content_id'))
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
			.replace('_RETURNURL_', returnURL);
		}

		return moduleItem.get('mobile_launch_url').replace(/([&\?]should_redirect=true*$|should_redirect=true&|[?&]should_redirect=true(?=#))/, '');
	};

	shouldAjaxCall = (apiInfoObject) => {
		const moduleItem = this.getState().get('moduleItem').get('moduleItem');
		if(moduleItem && (moduleItem.get('type')=='Simulation' || moduleItem.get('type')=='Scorm')){
			return true;
		}
		return false;
	};

	successCall = (response, apiInfoObject) => {
		this.dispatch(setProductLaunch(response));
		return response;
	}

	onError = (error) => {
		this.dispatch(setApiError(
            'developmentProductLaunchError',
            'Something went wrong'
        ));
		this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to launch the content. Please try again!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new FetchProductLaunch();
