/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Header.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Header component of the application
 *
 **/

import React from 'react';
import PropTypes from 'prop-types';
import styles from './header.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { StyleSheet, css } from 'aphrodite/no-important';
import DownArrow from 'images/header/down-arrow.png';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import {
	getActiveBatchDetails,
	getUserActiveBatchDetails
} from 'utils/batchUtils';
import CircularProgressBar from 'commonComponents/circularProgressBar';
import TextTooltip from 'commonComponents/textTooltip';
import HeaderLoader from '../loaders/headerLoader';
// import Logo from 'svgComponents/logo';
import Logo from 'images/header/logo.png';
import { checkIfPresent } from 'utils/utilFunctions';
import { getLogoFromLabels } from 'utils/labels';
import { isMSTeams } from 'utils/msTeamsUI';
import { isLaunchedFromExternalIntegration } from 'utils/externalIntegrations';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class Header extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			// openDropDown: false,
			clickedDropDown: -1
		}
	}

	toggleDropDown = (clickedDropDown) => {
		if (this.props.uiState.get('openDropDown')) {
			this.props.setUiState({
				openDropDown: false,
				showOverlay: false,
				overlayOpacity: 0
			});
		} else {
			this.props.setUiState({
				openDropDown: true,
				showOverlay: true,
				overlayOpacity: 0
			});
		}

		// this.props.setUiState({
		// 	openDropDown: !this.props.uiState.get('openDropDown'),
		// 	overlayOpacity: this.props.uiState.get('openDropDown') ? 0.5 : 0
		// });
		this.setState({
			clickedDropDown
		});
	}

	sendSegmentData = (event) => {
		let segmentData = {
			...(this.props.match.params),
			referrer: window.location.href,
			pathHash: window.location.hash
		}
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	returnHome = () => {
		this.sendSegmentData('Organization Logo clicked');
		const route = routes.get('HOME');;
		updateRoute({ route });
		// window.location.reload();
	}

	getLogoUrl = (userOrganization) => {
		const logoUrlFromLabels = getLogoFromLabels();
		if (logoUrlFromLabels) return logoUrlFromLabels;
		return userOrganization.getIn(['userOrganization', 'logo']) ? userOrganization.getIn(['userOrganization', 'logo']) : Logo;
	}

	renderLogoContainer = (userOrganization) => {
		const fallbackUrl = 'https://webassets.knolskape.com/misc/amul_garg/2019/05/28/16/alplus_logo.png';
		return (
			<div styleName="logo-container" onClick={this.returnHome}>
				<div styleName="logo-image">
					<img
						src={this.getLogoUrl(userOrganization)}
						height="100%"
						alt="Platform/Org Logo"
						onError={
							(e) => { 
								if (e.target.src !== fallbackUrl) { 
									e.target.onerror = null;
									e.target.src = fallbackUrl;
								}
							}
						}
					/>
				</div>
			</div>
		);
	}

	renderDropDown = (DropDownComponent, dropDownComponentProps, index) => {
		if (!DropDownComponent) return null;
		if (!this.props.uiState.get('openDropDown') || !DropDownComponent || index !== this.state.clickedDropDown) {
			return null;
		}
		this.sendSegmentData('User Info clicked');
		this.props.setUiState({
			showOverlay: true
		});

		return <DropDownComponent {...dropDownComponentProps.toJS()} />
	}

	renderNameAndTooltip = (singleItem) => {
		if (singleItem.get('name').length < 20) {
			return singleItem.get('name');
		}
		return (
			<TextTooltip
				text={singleItem.get('name')}
				charLength={20}
			/>
		);
	}

	renderDirectComponent = (DirectComponent, directComponentProps) => {
		const { userOrganization } = this.props;
		return (
			<div
				key={`my_journeys_link`}
				styleName="individual-item-container"
			>
				<DirectComponent
					{...directComponentProps}
					userOrganization={userOrganization}
				/>
			</div>
		)
	}

	renderComponentItems = (key, headerItemsObj, myStyles) => {
		let headerItems = [];
		if (headerItemsObj.length !== 0) {
			headerItemsObj.map((singleItem, index) => {
				const DropDownComponent = singleItem.get('dropDownComponent');
				const dropDownComponentProps = singleItem.get('dropDownComponentProps');
				if (singleItem.get('directComponent')) {
					headerItems.push(this.renderDirectComponent(
						singleItem.get('directComponent')),
						singleItem.get('directComponentProps')
					)
				} else
					headerItems.push(
						<div
							key={`${key}-item-${index}`}
							className={css(myStyles.addCursor)}
							styleName="individual-item-container"
							onClick={() => this.toggleDropDown(index)}
						>
							<div
								className={index == 0 ? css(myStyles.firstIndividualItemContainer, myStyles.addRelative) : css(myStyles.individualItemContainer, myStyles.addRelative)}
								styleName="individual-item-content"
							>

								{
									singleItem.get('imagePresent')
										? <div styleName="individual-item-image">
											<img src={singleItem.get('imagePath')} height="100%" />
										</div>
										: null
								}
								{
									singleItem.get('showName')
										?
										<div className={css(myStyles.individualItemName)} styleName="individual-item-name">
											{this.renderNameAndTooltip(singleItem)}
										</div>
										: null
								}
								{
									singleItem.get('downArrowPresent')
										? <div styleName="individual-item-downArrow"><img src={DownArrow} height="100%" /></div>
										: null
								}
								{this.renderDropDown(DropDownComponent, dropDownComponentProps, index)}
							</div>
						</div>
					);
			});
		}
		return (
			<div key={key} styleName={isMSTeams() ? 'individual-header-component msteams-individual-header-component' : 'individual-header-component'}>
				{headerItems}
			</div>
		);
	}

	renderHeaderItems = (headerItemsObj, myStyles) => {

		if (isMSTeams()) {
			return [
				this.renderComponentItems("ms-teams-header-right", headerItemsObj.get('msTeamsHeaderComponents'), myStyles)
			];
		}

		if (isLaunchedFromExternalIntegration()) {
			return [
				this.renderComponentItems("external-integration-header-right", headerItemsObj.get('externalIntegrationComponents'), myStyles)
			]
		}

		return (
			[
				this.renderComponentItems("header-left", headerItemsObj.getIn(['newHeaderComponents', 'leftComponents']), myStyles),
				this.renderComponentItems("header-middle", headerItemsObj.getIn(['newHeaderComponents', 'middleComponents']), myStyles),
				this.renderComponentItems("header-right", headerItemsObj.getIn(['newHeaderComponents', 'rightComponents']), myStyles)
			]
		)
	}

	renderLoader = () => {
		return (
			<HeaderLoader />
		);
	}

	isMSTeamsOrExternalIntegration = () => {
		return isMSTeams() || isLaunchedFromExternalIntegration();
	}

	render() {
		const { headerConfig } = this.props;

		const headerStyleName = this.isMSTeamsOrExternalIntegration() ? "header-container msteams-header-container" : "header-container";

		if (!headerConfig.get('showHeader')) {
			return null;
		}

		if (this.props.userOrganization.get('userOrganization').size === 0) {
			return this.renderLoader();
		}
		if (!headerConfig.get('isDataAvailable')) {
			if (!headerConfig.get('showLoader')) {
				return null;
			}
			return this.renderLoader();
		}
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div className={css(myStyles.headerContainer)} styleName={headerStyleName}>
				<div styleName="logo-section">
					{this.renderLogoContainer(this.props.userOrganization)}
				</div>
				<div styleName="other-section">
					{this.renderHeaderItems(headerConfig.get('headerComponents'), myStyles)}
				</div>
			</div>
		);
	}
}

export default Header;
