import React from 'react';
import { connect } from 'react-redux';
import UserJourneys from 'components/userJourneys';
import UserJourneysStyleA from 'components/userJourneysStyleA';

import {
	fetchUserAndOrganization,
	fetchFileteredUserBatches,
	fetchActiveUserBatches,
	fetchUpcomingUserBatches,
	fetchCompletedUserBatches,
	fetchExpiredUserBatches,
	fetchRequestedUserBatches,
	fetchUserAndOrganizationAndUserJourneys,
	fetchUserJourneys
} from 'actions/userJourneys/apiActions';
import { setBatchSwitching } from 'actions/userJourneys/actionCreators';
import { updateFetchBatchDashboardBasicDataStatus } from 'actions/loaders/actionCreators';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import UserJourneysStyleB from '../components/userJourneysStyleB/UserJourneysStyleB';

class UserJourneysContainer extends React.Component {

	componentDidMount() {
		//this.props.updateFetchBatchDashboardBasicDataStatus(true);
		this.props.fetchUserAndOrganizationAndUserJourneys();
	}

	componentWillReceiveProps(nextProps) {
		if (
			!nextProps.userOrganization.get('userOrganizationDataLoading')
			&& this.props.userOrganization.get('userOrganizationDataLoading')
		) {
			if (this.props.selectedTab === 'my_journeys') {
				let segmentData = {
					...(this.props.match.params),
					url: window.location.href,
					pathHash: window.location.hash
				};
				window.analytics.page(
					'User Journeys',
					segmentData,
					getAmplitudeSessionDetails()
				)
			}
		}
	}

	render() {
		const homePageStyle = this.props.userOrganization.getIn(['userOrganization','homePageStyle','id'])
		switch(homePageStyle) {
			case 2:
				return <UserJourneysStyleA
					{...this.props}
				/>;
			case 3:
				return <UserJourneysStyleB
						{...this.props}
					/>;
			default:
				return <UserJourneys
						{...this.props}
					/>;
		}
	}
}



const mapStateToProps = state => {
	return {
		batches: state.getIn(['batches', 'batchesList']),
		userBatches: state.getIn(['batches', 'userBatchesList']),
		user: state.get('user'),
		userOrganization: state.get('userOrganization'),
		organizationLevelUsersStats: state.get('organizationLevelUsersStats'),
		ongoingUserBatches: state.getIn(['batches', 'ongoingUserBatches']),
		upcomingUserBatches: state.getIn(['batches', 'upcomingUserBatches']),
		completedUserBatches: state.getIn(['batches', 'completedUserBatches']),
		expiredUserBatches: state.getIn(['batches', 'expiredUserBatches']),
		requestedUserBatches: state.getIn(['batches', 'requestedUserBatches']),
		ongoingBatchesListAdditionalDetails: state.getIn(['batches', 'ongoingBatchesListAdditionalDetails']),
		upcomingBatchesListAdditionalDetails: state.getIn(['batches', 'upcomingBatchesListAdditionalDetails']),
		completedBatchesListAdditionalDetails: state.getIn(['batches', 'completedBatchesListAdditionalDetails']),
		expiredBatchesListAdditionalDetails: state.getIn(['batches', 'expiredBatchesListAdditionalDetails']),
		requestedBatchesListAdditionalDetails: state.getIn(['batches', 'requestedBatchesListAdditionalDetails']),
		isOngoingBatchesFetching: state.getIn(['batches', 'isOngoingBatchesFetching']),
		isUpcomingBatchesFetching: state.getIn(['batches', 'isUpcomingBatchesFetching']),
		isCompletedBatchesFetching: state.getIn(['batches', 'isCompletedBatchesFetching']),
		isExpiredBatchesFetching: state.getIn(['batches', 'isExpiredBatchesFetching']),
		isRequestedBatchesFetching: state.getIn(['batches', 'isRequestedBatchesFetching']),
		availableJourneys: state.get('availableJourneys'),
		journeyFormats: state.get('journeyFormats'),
		journeyCategories: state.get('journeyCategories'),
		activeBatchId: state.getIn(['batches', 'activeBatchId']),
		userState: state.get('userState')
	}
};

const mapDispatchToProps = dispatch => ({
	fetchUserAndOrganization: () => {
		dispatch(fetchUserAndOrganization())
	},
	fetchFileteredUserBatches: (durationStatus) => {
		dispatch(fetchFileteredUserBatches(durationStatus))
	},
	fetchActiveUserBatches: () => {
		dispatch(fetchActiveUserBatches())
	},
	fetchUpcomingUserBatches: () => {
		dispatch(fetchUpcomingUserBatches())
	},
	fetchCompletedUserBatches: () => {
		dispatch(fetchCompletedUserBatches())
	},
	fetchExpiredUserBatches: () => {
		dispatch(fetchExpiredUserBatches())
	},
	fetchRequestedUserBatches: (orgId) => {
		dispatch(fetchRequestedUserBatches(orgId))
	},
	fetchUserAndOrganizationAndUserJourneys: () => {
		dispatch(fetchUserAndOrganizationAndUserJourneys())
	},
	fetchUserJourneys: (orgId) => {
		dispatch(fetchUserJourneys(orgId))
	},
	setBatchSwitching: (flag = false) => {
		dispatch(setBatchSwitching(flag))
	},
	updateFetchBatchDashboardBasicDataStatus: (status) => {
		dispatch(updateFetchBatchDashboardBasicDataStatus(status));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(UserJourneysContainer);
