import React, { Component } from 'react';
import styles from './catalogueHeader.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import Logo from 'images/header/logo.png';
import AvatarImage from 'images/header/user-profile.svg';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CatalogueHeader extends Component {

	redirectToSignIn = () => {
		const { organization } = this.props;
		window.location = organization.getIn(['userOrganization', 'loginUrl']);
	}

	render() {
		const { organization, skinGuide, getLabel } = this.props;
		const myStyles = getSkin(skinGuide);

		return (
			<div styleName="header-cnt" className>
				<div styleName="organization-logo-cnt">
					<img src={organization.getIn(['userOrganization', 'logo']) || Logo} alt="Organization Logo"/>
				</div>
				<div styleName="sign-in-cnt">
					<img src={AvatarImage} alt="Default Avatar"/>
					<div className={css(myStyles.signInLabel)} onClick={this.redirectToSignIn}>
						{getLabel('sign_in_label')}
					</div>
				</div>
			</div>
		)
	}
}

export default CatalogueHeader;