/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: sim.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * sim reducer is responsible for sim related actions
 *
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

// initialState of the sim reducer
const initialState = Immutable.fromJS({
	error: null,
	generatingUserReport: false
});

const sim = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.get('ERROR'):
			// on error occured due to backend request
			return state.set('error', action.error);
		case actionConsts.get('EMPTY_ERROR'):
			// empty error object after displaying the notification message
			return state.set('error', null);
		case actionConsts.get('UPDATE_GENERATING_USER_REPORT_FLAG'):
			// on update generatingUserReport flag
			return state.set('generatingUserReport', action.flag);
		default:
			// return default state
			return state;
	}
};

export default sim;
