import React, { Component } from 'react';
import ErrorContainer from 'containers/ErrorContainer';
import { logComponentErrorToSentry } from 'utils/sentry';


class GenericErrorBoundary extends Component {

	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, errorInfo) {
		this.setState({ hasError: true });

		logComponentErrorToSentry(error);
	}

	render() {
		if (this.state.hasError) {
			return <ErrorContainer />;
		}
		return this.props.children;
	}
}

export default GenericErrorBoundary;