import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { css } from 'aphrodite/no-important';
import styles from './moduleItemFeedback.module.sass';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import MiniThumbsUpDown from './miniThumbsUpDown';
import CloseCircle from 'svgComponents/closeCircle';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import CompletedFeedbackIcon from 'svgComponents/completedFeedbackIcon';
import TickMarkWithGreenFill from 'svgComponents/tickMarkWithGreenFill';
import enumConstants from 'constants/enums';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class ModuleItemFeedback extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			openFeedback: props.keepFeedbackOpen || false
		}
	}

	sendSegmentData = (event) => {
		let segmentData = {
			...(this.props.match.params),
			referrer: window.location.href,
			pathHash: window.location.hash
		}
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	toggleOpenFeedback = () => {
		if (!this.state.openFeedback) {
			this.sendSegmentData('Module Item Feedback opened');
		} else {
			this.sendSegmentData('Module Item Feedback closed');
		}
		this.setState({
			openFeedback: !this.state.openFeedback
		})
	}

	getQuestionTemplates = (question) => {
		const { submitAnswer, submitFeedback } = this.props;
		switch (question.get('type')) {
			case enumConstants.get('FEEDBACK_THUMBS_UP_DOWN'):
				return <MiniThumbsUpDown
					key={`itemFeedbackQuestionTUD_${question.get('id')}`}
					submitAnswer={submitAnswer}
					submitFeedback={submitFeedback}
					question={question}
				/>
			default:
				return <div></div>;
		}
	}

	getFeedbackQuestions = () => {
		const { itemFeedback } = this.props;

		if (itemFeedback && itemFeedback.get('questionsList').size) {
			return itemFeedback.get('questionsList').map((question) => {
				return <span key={`fdbk_question_${question.get('id')}`}>
					{this.getQuestionTemplates(question)}
				</span>
			})
		}
		return null;
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		const { openFeedback } = this.state;
		const isFeedbackCompleted = this.props.itemFeedback.get('isFeedbackCompleted');
		return (
			<div styleName="feedback-outer-cnt">
				{
					openFeedback ?
						[
							<div styleName="feedback-inner-cnt">
								{this.getFeedbackQuestions()}
								{
									isFeedbackCompleted &&
									<div styleName="thank-you-note-cnt">
										<div styleName="tickmark-cnt">
											<TickMarkWithGreenFill />
										</div>
										<div className={css(styles.thankYouNote)}>
											{this.props.getLabelWithDefaults('thank_you_note_after_feedback', "Thanks for your feedback")}
										</div>
									</div>
								}
							</div>,

							<div styleName="close-feedback-button" onClick={this.toggleOpenFeedback}>
								<CloseCircle circleFillOpacity={0.3} strokeColor="white" />
							</div>
						]
						:
						<div styleName="minimized-feedback" onClick={this.toggleOpenFeedback}>
							<div styleName="feedback-icon-cnt">
								{
									isFeedbackCompleted ?
										<CompletedFeedbackIcon />
										:
										<FeedbackIcon />
								}
							</div>
						</div>
				}
			</div>
		);
	}
}

export default ModuleItemFeedback;