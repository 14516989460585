import store from 'store/store';

const getActiveBatchId = () => {
    try{
        return store.getState().get('batches').get('activeBatchId');
    } catch(e){
        return 1;
    }
}

const getBatchLabels = (batchId) => {
    const labels = store.getState().get('labels');
    if(labels && labels.get('isLabelsFetched')){
        return store.getState().get('labels').get(batchId);
    }
    return null;
}

export const getLogoFromLabels = () => {
    const activeBatchId = getActiveBatchId();
    const batchLabels = getBatchLabels(activeBatchId);
    if(batchLabels){
        const logoUrlFromLabels = batchLabels.get('web_product_logo');
        if(logoUrlFromLabels && logoUrlFromLabels!="PLACEHOLDER_LOGO_URL"){
            return logoUrlFromLabels;
        }
    }
    
    return null;
};