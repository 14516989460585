import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {setModuleItemFileProgress} from 'actions/moduleItems/actionCreators';

class UpdateMediaFileStatus extends NetPackWrapper {

	constructor() {
		super('POST', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('UPDATE_MEDIA_FILE_STATUS')
			.replace('_COURSE_ID_', apiInfoObject.courseId)
			.replace('_ITEM_ID_', apiInfoObject.moduleItemId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	};

	successCall = (response) => {
		this.dispatch(setModuleItemFileProgress(response));
	}
}

export default new UpdateMediaFileStatus();
