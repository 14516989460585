import React from 'react';
import { connect } from 'react-redux';
import GenericErrorBoundary from 'components/errorBoundaries';
import Certificate from 'components/journeys/journeyDashboard/certificate';
import { fetchBatchesFeedbackStatusFlags } from 'actions/batchFeedbackStatus/apiActions';
import { unsetBatchesFeedbackStatusFlags } from 'actions/batchFeedbackStatus/actionCreators';

class CertificateContainer extends React.Component {
e
	componentDidMount() {
		if(this.isFeedbackMandatory()){
			this.props.fetchBatchesFeedbackStatusFlags([this.props.match.params.batchId]);
		}
	}

    componentWillUnmount(){
        this.props.unsetBatchesFeedbackStatusFlags();
    }

	updateFeedbackStatus = () => {
		this.props.fetchBatchesFeedbackStatusFlags([this.props.match.params.batchId]);
	}

	isFeedbackMandatory = () => {
		const { batchDetails } = this.props;
		// eslint-disable-next-line eqeqeq
		return batchDetails.getIn(['journey', 'settings', 'isFeedbackMandatory']) == "true";
	}

	render() {
		return (
			<GenericErrorBoundary>
				<Certificate
					{...this.props}
					updateFeedbackStatus={this.updateFeedbackStatus}
					isFeedbackMandatory={this.isFeedbackMandatory()}
				/>
			</GenericErrorBoundary>

		);
	}
}

CertificateContainer.propTypes = {};

CertificateContainer.defaultProps = {};


const mapStateToProps = (state) => {
	return ({
		batchesFeedbackStatusFlags: state.get('batchesFeedbackStatusFlags')
	});
};

const mapDispatchToProps = (dispatch) => ({
	fetchBatchesFeedbackStatusFlags: (batchIds) => {
		dispatch(fetchBatchesFeedbackStatusFlags(batchIds))
	},
    unsetBatchesFeedbackStatusFlags: () => {
        dispatch(unsetBatchesFeedbackStatusFlags());
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(CertificateContainer);
