import * as Sentry from '@sentry/browser';
import StackTrace from 'stacktrace-js';

export const initializeSentry = () => {
    Sentry.init({ 
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: process.env.REACT_APP_SENTRY_RELEASE,
        debug: process.env.REACT_APP_SENTRY_DEBUG,
        environment: process.env.REACT_APP_SENTRY_ENV,
        ignoreErrors: [
            /.*not loggedin.*/,
            /.*obsolete.*/,
            /.*USER_NOT_FOUND_IN_BATCH.*/,
            /.*"status":4[0-9][0-9].*/, /* don't send 400 errors */
            /.*"httpCode":4[0-9][0-9].*/, /* don't send 400 errors */
            /.*validationInfoObject.*/, /*don't send validation error */


            /.*Cannot read property 'document' of null.*/,
            /.*Unable to get property 'document' of undefined or null reference.*/,
            /.*Unable to get property 'isFunction' of undefined or null reference.*/,
            /.*Cannot read property 'getComputedStyle' of null.*/,
            /.*Unable to get property 'contains' of undefined or null reference.*/,
            /.*Object expected.*/,
            /.*freed script*/,
            /.*defaultView is null*/

        ],
        beforeSend: (event, hint) => {
            try{
                console.log('Sentry: Original Exception', JSON.stringify(hint.originalException));
                if(hint.originalException.status && hint.originalException.status == 'obsolete'){
                    return null;
                }
            } catch(error){
                console.log('Error in sentry beforeSend', error);
            }
            return event;
        }
    });
}

export const setSentryUserContext = (userDetails) => {
    Sentry.configureScope((scope) => {
        scope.setUser({
            "id": userDetails.userID,
            "email": userDetails.emailID
        });
    });
}

export const logComponentErrorToSentry = (error) => {
    StackTrace.fromError(error).then((stacktrace)=>{
        Sentry.withScope(scope => {
            //NOTE: sending state is failing because there is a 200kb limit on data that can be sent to sentry
            //scope.setExtra('redux_store_state', JSON.stringify(store.getState().toJS()));
            scope.setExtra("stacktrace_frames", stacktrace); 
            Sentry.captureException(error);
        });
    });  
}