import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';

class MarkModuleItemRead extends NetPackWrapper {
  constructor() {
    super('POST', true);
  }

  prepareUrl = apiInfoObject => {
    return urls.get('DEVELOPMENT_PHASE').get('MARK_MODULE_ITEM_READ')
      .replace('_COURSE_ID_', apiInfoObject.courseId)
      .replace('_MODULE_ID_', apiInfoObject.moduleId)
      .replace('_ITEM_ID_', apiInfoObject.moduleItemId)
      .replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
      .replace('_BATCH_ID_', apiInfoObject.batchId)
      .replace('_PHASE_ID_', apiInfoObject.phaseId)
      .replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
  };

//   beforeCall = apiInfoObject => {
//       this.dispatch(setModuleItemAvailabilityStatus(false));
//       return apiInfoObject;
//   };
  
  successCall = (response, apiInfoObject) => {
     
  }
}

export default new MarkModuleItemRead();
