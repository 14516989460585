import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import AssessmentBackground from 'images/assessment-title-bg.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        productTitleContainer: {
            background: `linear-gradient(135deg, ${hexToRgbA(skin.get('primaryColor'), 0.1)} , ${hexToRgbA(skin.get('secondaryColor'), 0.1)})`
            // backgroundImage: `url(${AssessmentBackground})`,
            // backgroundSize: '100% 100%',
        },
        productTitleImage: {
            backgroundImage: `url(${AssessmentBackground})`,
            backgroundSize: "cover",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
        },

        subTitle: {
            ...myTypography.overline,
            fontSize: '16px',
            color: 'gray',
            lineHeight: 1.5
        },

        title: {
            ...myTypography.h4,
            fontSize: '40px',
            color: hexToRgbA(skin.get('grey1'), 0.85),
            lineHeight: 0.7
        },

        loaderContainer: {
            backgroundColor: skin.get('greyColor6')
        },
        helper: {
            ...myTypography.caption,
            paddingTop: `3px`,
            textTransform: `none`,
            color: skin.get('grey1')
        }
    });
}

export default getSkin;