import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		mainBackground: {
			backgroundColor: skin.get('greyColor2')
		},
		leftLine: {
			backgroundColor: skin.get('greyColor3'),
			borderRadius: '0.5px'
		},
		mainBoxTitle: {
			...myTypography.h4,
			lineHeight: 1,
			color: skin.get('greyColor5')
		},
		mainBoxDesc: {
			...myTypography.body1,
			color: skin.get('greyColor4')
		},
		mainBoxAddDetails: {
			...myTypography.overline,
			color: skin.get('greyColor4')
		},
		subBoxTitle: {
			...myTypography.h6,
			color: skin.get('greyColor2')
		},
		subBoxDuration: {
			...myTypography.h6,
			color: skin.get('greyColor2'),
			fontWeight: 400
		},
		subBoxDesc: {
			...myTypography.body2,
			color: hexToRgbA(skin.get('black'), 0.85)
		},
		mainDot: {
			backgroundColor: skin.get('secondaryVariantColor'),
			border: `2px solid ${skin.get('black')}`,
			borderRadius: '50%'
		},
		mainDotOutside: {
			backgroundColor: hexToRgbA(skin.get('secondaryVariantColor'), 0.4),
			borderRadius: '50%'
		},
		subDot: {
			backgroundColor: skin.get('secondaryVariantColor'),
			border: `2px solid ${skin.get('white')}`,
			borderRadius: '50%'
		},
		lastDot: {
			backgroundColor: skin.get('secondaryVariantColor'),
			border: `2px solid ${skin.get('white')}`,
			borderRadius: '50%'
		},
		journeyShowButton: {
			...myTypography.button,
			backgroundColor: skin.get('white'),
			borderRadius: '4px',
			border: `1px solid ${skin.get('secondaryColor')}`,
			color: skin.get('secondaryColor'),
			textTransform: 'uppercase',
			':hover': {
				border: `1px solid ${skin.get('secondaryVariantColor')}`,
				backgroundColor: skin.get('secondaryVariantColor'),
				color: skin.get('white')
			}
		},
		journeyShowLine: {
			backgroundColor: skin.get('greyColor5')
		},
		durationText: {
			...myTypography.caption,
			color: skin.get('greyColor4')
		},
		periodText: {
			...myTypography.overline,
			fontWeight: 600,
			color: hexToRgbA(skin.get('white'), 0.85)
		},
		itemTypeText: {
			...myTypography.overline,
			color: skin.get('greyColor4')
		},
		intelRecDesc: {
			...myTypography.overline,
			color: skin.get('secondaryColor')
		}
	});
}

export default getSkin;