import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import {
	setJourneyCategories
} from 'actions/journeys/actionCreators';

class FetchJourneyCategories extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	prepareUrl = (apiInfoObject) => urls.getIn(['PLATFORM', 'JOURNEY_CATEGORIES']);

	// shouldAjaxCall = () => {
	// 	const journeyCategories = this.getState().get('journeyCategories');

	// 	if (journeyCategories.size !== 0) {

	// 		return false;
	// 	}
	// 	return true;
	// }

	successCall = (response) => {
		this.dispatch(setJourneyCategories(response));
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, something went wrong. Please refresh the page!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new FetchJourneyCategories();
