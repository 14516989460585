import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import styles from './moduleItemCard.module.sass';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import ModuleItemTypes from '../../../svgComponents/moduleItemTypes'
import { convertDateWithYear, textTruncate, checkIfPresent } from 'utils/utilFunctions';
import applyLabel from 'utils/LabelProvider';
import Recommended from 'svgComponents/recommended';
import LockIcon from 'svgComponents/lockIcon';
import { getLockMessage, replaceDatePlaceholderAndFormat } from 'utils/locks';
import { getDurationStringUsingUnits } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class ModuleItemCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isItemCompleted: false,
            isItemLocked: false,
            isItemExpired: false,
            inaccessibleMessage: ''
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.itemData && nextProps.onlyPhaseItem) {
            this.setInitialFlags(nextProps);
        }
    }

    componentDidMount() {
        if (this.props.itemData && this.props.onlyPhaseItem) {
            this.setInitialFlags(this.props);
        }
    }

    getPhaseItemLockMessage = (phaseItem) => {
        if(phaseItem.get('isLocked') || phaseItem.get('isExpired')){
            return replaceDatePlaceholderAndFormat(true, phaseItem.get('lockExplanation'), phaseItem.get('lockDate'))
        }
        return null;
    };

    setInitialFlags = (props) => {
        let { onlyPhaseItem, itemData } = props;
        let isItemCompleted = false,
            isItemLocked = false,
            isItemExpired = false,
            inaccessibleMessage = '';
        if (itemData.getIn(['completion_requirement', 'completed'])) {
            isItemCompleted = true
        }

        if (onlyPhaseItem.get('isLocked')) {
            isItemLocked = true;
            isItemExpired = false;
        } else if (onlyPhaseItem.get('isExpired')) {
            isItemLocked = false;
            isItemExpired = true;
        } else {
            isItemLocked =
                itemData.get('lock_details').get('lock_reason') === 'locked'
                || itemData.get('lock_details').get('lock_reason') === 'not_started'
                || itemData.get('lock_details').get('lock_reason') === 'prerequisites'
                || itemData.get('lock_details').get('lock_reason') === 'sequential_progress';

            isItemExpired =
                itemData.get('lock_details').get('lock_reason') === 'expired' || onlyPhaseItem.get('isExpired');
        }

        const phaseItemLockMessage = this.getPhaseItemLockMessage(onlyPhaseItem);

        inaccessibleMessage = phaseItemLockMessage || getLockMessage(itemData.get('lock_details'), "short");

        this.setState({
            isItemCompleted: isItemCompleted,
            isItemLocked: isItemLocked,
            isItemExpired: isItemExpired,
            inaccessibleMessage: inaccessibleMessage
        });
    }

    getCompetenciesCapsules = () => {
        let { itemData } = this.props;
        let styles = getSkin(this.props.skinGuide);
        let competencies = itemData.get('topics');
        let numberOfCompentenciesToShow = 3
        let content = []
        let extraCompentencies = ""
        if (competencies && competencies.size > 0) {
            for (let i = 0; i < competencies.size; i++) {
                if (i < numberOfCompentenciesToShow) {
                    content.push(
                        <div key={`${itemData.get('id')}_comp_${i}`} className={css(styles.competencyCapsule)} styleName="competency-capsule">
                            {competencies.get(i).get('topic_name')}
                        </div>
                    )
                } else {
                    extraCompentencies += ` ${competencies.get(i).get('name')},`
                }
            }
            if (competencies.size > numberOfCompentenciesToShow) {
                content.push(
                    <div key="more-competencies" className={css(styles.competencyCapsule)} styleName="competency-capsule" title={extraCompentencies.slice(1, -1)}>
                        {`+ ${competencies.size - numberOfCompentenciesToShow} more`}
                    </div>
                )
            }
        }
        return content;
    }

    renderItemImage = () => {
        const { itemData } = this.props;
        const { isItemLocked } = this.state;
        let styles = getSkin(this.props.skinGuide);
        
        if(checkIfPresent(itemData.get('thumbnail_url'))){
            return <div styleName="bigger-module-icon">
                <img src={itemData.get('thumbnail_url')} alt={itemData.get('title')} styleName="item-image"/>
                { isItemLocked && <div className={css(styles.lockedDiv)} styleName="locked-div" style={{ 'top': 0 }}>
                    <div styleName="locked-icon"><LockIcon /></div>
                </div> }
            </div>;
        }
    };

    renderItemStatus = () => {
        let {
            itemData,
            getLabelWithDefaults,
            isCurrent,
            onlyPhaseItem
        } = this.props;
        let styles = getSkin(this.props.skinGuide, itemData.get('completion_requirement'), isCurrent, onlyPhaseItem, this.state.isItemLocked);

        const {
            isItemCompleted,
            isItemExpired,
            isItemLocked,
            inaccessibleMessage
        } = this.state;

        if (isItemLocked)
            return (
                <div styleName="lock-msg-cnt">
                    {inaccessibleMessage}
                </div>
            )

        if (isItemCompleted)
            return (
                <div className={css(styles.completionStatus)} styleName="completion-status">
                    {this.props.getLabelWithDefaults('completed_label', 'Completed')}
                </div>
            )

        if (isItemExpired)
            return (
                <div className={css(styles.expiredTag)} styleName="expired-msg-cnt">
                    {this.props.getLabelWithDefaults('expired_label', 'Expired')}
                </div>
            )        

        if (itemData.get('content_details') && itemData.get('content_details').get('due_at'))
            return (
                <div styleName="due-date-cnt">
                    {
                        getLabelWithDefaults(
                            'due_on_label',
                            'Due on %{date}',
                            {
                                date: convertDateWithYear(itemData.get('content_details').get('due_at'))
                            }
                        )
                    }
                </div>
            )
    }

    getTitle = (item) => {
        let title = item.get('title');
        if(item.get('is_optional')){
            title = `${this.props.getLabel('optional_label')} ${title}`
        }
        return title;
    }

    render() {
        let { itemData,
            goToItem,
            getLabelWithDefaults,
            formLabelForModuleItem,
            isCurrent,
            onlyPhaseItem
        } = this.props;

        let styles = getSkin(this.props.skinGuide, itemData.get('completion_requirement'), isCurrent, onlyPhaseItem, this.state.isItemLocked);

        return (
            <div onClick={goToItem} className={css(styles.moduleItemCardCnt)} styleName="module-item-card-cnt">
                <div
                    className={css(styles.itemMetadata)}
                    styleName="module-item-metadata-cnt"
                >
                    <div styleName="module-item-metadata">
                        <div styleName="module-type-cnt">
                            <div styleName="module-type-icon-cnt">
                                <ModuleItemTypes itemType={itemData.get('type_label').toLowerCase()} />
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                {getLabelWithDefaults(formLabelForModuleItem(itemData.get('type_label')), itemData.get('type_label'))}
                            </div>
                        </div>
                        {
                            checkIfPresent(itemData.get('duration')) &&
                            [
                                <div styleName="separator">|</div>,
                                <div>
                                    {getDurationStringUsingUnits(itemData.get('duration'), itemData.get('duration_unit'))}
                                </div>
                            ]
                        }

                        {
                            itemData.get('recommend') &&
                            [
                                <div styleName="separator">|</div>,
                                <div styleName="recommended-cnt">
                                    <div styleName="recommended-icon">
                                        <Recommended />
                                    </div>

                                    <div className={css(styles.recommendedText)}>
                                        {
                                            getLabelWithDefaults('recommended_for_you_label', 'Recommended for you')
                                        }
                                    </div>
                                </div>
                            ]
                        }
                    </div>
                    <div styleName="module-item-status-cnt">
                        {this.renderItemStatus(styles)}
                    </div>
                </div>

                <div className={css(styles.bottomBorder)} styleName="bottom-border"></div>
                <div styleName="desc-and-competency-cnt item-desc-cnt">
                    <div className={css(styles.itemTitle)} styleName="item-title">
                        {textTruncate(this.getTitle(itemData), 140)}
                    </div>
                    {
                        checkIfPresent(itemData.get('subtitle')) &&
                        <div className={css(styles.itemSubTitle)} styleName="item-sub-title">
                            {textTruncate(itemData.get('subtitle'), 190)}
                        </div>
                    }
                </div>

                <div
                    className={css(styles.moduleItemMarker)} styleName="module-item-cicle-marker"
                >
                    <div></div>
                </div>
                <div styleName="bigger-module-icon-cnt">
                    {this.renderItemImage()}
                </div>
            </div>
        );
    }
}

export default ModuleItemCard;