import actionConsts from 'constants/actions';

const setBatchLabels = payload => ({
    type: actionConsts.get('SET_BATCH_LABELS'),
    payload
});

const setOrgLabels = payload => ({
    type: actionConsts.get('SET_ORG_LABELS'),
    payload
});

export {
    setBatchLabels,
    setOrgLabels
};
