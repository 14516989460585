import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';

import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';

import {setCourseForNextActivity} from 'actions/courses/actionCreators';

class FetchCourseWithModules extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
        return urls.get('DEVELOPMENT_PHASE').get('COURSE_WITH_MODULES')
        .replace('_COURSE_ID_', apiInfoObject.courseId)
        .replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
        .replace('_BATCH_ID_', apiInfoObject.batchId)
        .replace('_PHASE_ID_', apiInfoObject.phaseId)
        .replace('_PHASEITEMID_', apiInfoObject.phaseItemId);
    }
	
    successCall = (response, apiInfoObject) => {
        
        if(apiInfoObject.needOnlyBasicDetails) return response;

        if(response.course_type === 'scorm'){            
            this.dispatch(setCourseForNextActivity(response));
        } else{
            const nextModuleItem = this.getState().get('nextActivityItem').get('nextActivityItem');
            const route = routes.get('BATCH_ITEM_MODULE');
            const routeParams = [
                { 'BATCH_ID': apiInfoObject.batchId },
                { '_PHASE_ID_': apiInfoObject.phaseId },
                { 'COURSE_ID': apiInfoObject.courseId },
                { '_PHASEITEMID_': apiInfoObject.phaseItemId},
                { 'MODULE_ID': nextModuleItem.get('context_module_id')},
                { 'ITEM_ID': nextModuleItem.get('id')}
            ];
            updateRoute({route, params: routeParams});
        }       

        return response;
    }

}

export default new FetchCourseWithModules();