import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        successMessage: {
            ...myTypography.body2,
            color: 'rgb(46, 79, 180)',
            lineHeight: 'normal'
        },
        submissionDetailsSection:{
            borderTop: `solid 1px ${skin.get('greyColor5')}`
        },
        heading:{
            ...myTypography.h5,
            fontFamily: theme.get('fontFamily').get('bodyFamily2'),
            fontWeight: 300,
            color: skin.get('greyColor2'),
            '@media screen and (max-width: 480px)': {
                fontSize: '20px'
            }
        },
        submittedOn:{
            ...myTypography.overline,
            color: '#091027d9','@media screen and (max-width: 480px)': {
                fontSize: '14px'
            }
        },
        date: {
            ...myTypography.overline,
            color: '#091027d9',
            fontWeight: 600,
            '@media screen and (max-width: 480px)': {
                fontSize: '14px'
            }
        },
        evaluationStatus:{
            ...myTypography.overline,
            color: skin.get('greyColor1'),
            fontWeight: 600,
            '@media screen and (max-width: 480px)': {
                fontSize: '14px'
            }
        },
        statusCirclePending:{
            background: skin.get('secondaryColor')
        },
        statusCircleEvaluated: {
            background: skin.get('appleGreen')
        },
        resubmitHeading:{
            ...myTypography.button,
            lineHeight: 1.71,
            color: '#091027d9',
            fontWeight: 300
        },
        responseBody: {
            ...myTypography.body1,
            color: skin.get('greyColor1'),
            lineHeight: 1.56,
            whiteSpace: 'pre-wrap',
            '@media screen and (max-width: 480px)': {
                fontSize: '14px'
            }
        },
        responseContainer: {
            borderBottom: `solid 1px ${skin.get('greyColor5')}`
        },
        fileCardFileName: {
            ...myTypography.caption,
            color: '#091027d9'
        },
        fileCardNoPreview: {
            background: '#f9f9fb'
        }

    });
}

export default getSkin;