import React, { Component } from 'react';
import styles from './duration.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import Clock from 'svgComponents/clock';
import { getDurationStringUsingUnits } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Duration extends Component {
	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const { time, title } = this.props;
		let durationUnit = this.props.durationUnit || '';
		return (
			<div>
				{
					(!time || durationUnit.length === 0)
						?
						null
						:
						<div styleName="duration-container">
							<div className={css(myStyles.title)} styleName="title">
								{this.props.getLabelWithDefaults('duration_label', 'Duration')}
							</div>
							<div styleName="icon-time-container">
								<div styleName="clock-icon-wrapper"><Clock /></div>
								<div className={css(myStyles.time)} styleName="time">
									{(!time || time == 'NaN undefined NaN') ? '...' : getDurationStringUsingUnits(time, durationUnit)}
								</div>
							</div>
						</div>
				}
			</div>

		);
	}
}

export default Duration;
