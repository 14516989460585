import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './heatmapWithInfoComponent.module.sass';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent';
import Heatmap from 'commonComponents/graphs/heatmap';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@CSSModules(styles, { allowMultiple: true })

class HeatmapWithInfoComponent extends React.Component {

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props)){
            return false;
        }
        else {
            return true;
        }
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchDayWiseTimeSpentData(+batchId, +userId);
    }    

    renderHeatMapWithInfo = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        if(this.props.apiErrors.get('fetchDayWiseTimeSpentDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchDayWiseTimeSpentDataError')}
                retryHandler={()=>{
                    this.props.fetchDayWiseTimeSpentData(+batchId, +userId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('activity');

        if (allGraphsData.get('isDayWiseTimeSpentDataFetched')) {
            const graphData = allGraphsData.get('dayWiseTimeSpentData');
            const metadata = graphData.get('metadata');
            const strings = graphData.get('strings');

            const barStyling = {
                gridValue: 5,
                componentName: 'dayWiseTimeSpent'
            };

            return(
                [<InfoWithoutFilterComponent
                    {...this.props}
                    title={strings.get('graphTitle')}
                    subtitle={strings.get('graphDesc')}
                    key="InfoWithoutFilterComponent"
                />,
                <Heatmap
                    {...this.props}
                    // data={this.props.data.heatMapData}
                    graphData={graphData}
                    barStyling={barStyling}
                    key="Heatmap"
                />]
            )
        }
        return <LoaderComponent />;
    }

    render(){
        return (
            <div styleName="heat-map-container">
                {this.renderHeatMapWithInfo()}
            </div>
        );
    }
}


export default HeatmapWithInfoComponent;