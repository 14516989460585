import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { removeRequestedJourneyFromAvailableJourneys, updateSelectedJourneyInstance } from 'actions/journeys/actionCreators';
import { setRequestingJourneyAccess } from 'actions/userJourneys/actionCreators';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';

class CancelJourneyRequest extends NetPackWrapper {

	constructor() {
		super("DELETE");
	}

	prepareUrl = () => urls.get('PLATFORM').get('REQUEST_JOURNEY_ACCESS');

	successCall = (response) => {
		return response;
	}

	onError = () => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, something went wrong. Please refresh the page!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		this.dispatch(setRequestingJourneyAccess(false));
		sendUserToInvalidPage();
	}
}

export default new CancelJourneyRequest();