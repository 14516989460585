import React, { Component } from 'react';
import styles from './close.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Close extends Component {

	constructor(props){
		super(props);
		this.state= {
			onHover: false
		};
	}

	setHoverTrue = () => {
		this.setState({
			onHover: true
		});
	}

	setHoverFalse = () => {
		this.setState({
			onHover: false
		});
	}

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="main-component" onMouseEnter={this.setHoverTrue} onMouseLeave={this.setHoverFalse}>
				<svg width="100%" height="100%" viewBox="0 0 12 12">
					<path
						fill={this.props.color !== null && this.props.color !== undefined ? this.props.color : skinHex.get('white')}
						strokeWidth={this.props.strokeWidth !== null && this.props.strokeWidth !== undefined ? this.props.strokeWidth : 1}
						fillRule="nonzero"
						d="M.22 10.72a.75.75 0 1 0 1.06 1.06l4.631-4.63a.125.125 0 0 1 .177 0l4.63 4.63a.75.75 0 1 0 1.062-1.06L7.15 6.088a.125.125 0 0 1 0-.177l4.63-4.63A.75.75 0 1 0 10.72.22L6.089 4.85a.125.125 0 0 1-.178 0L1.281.22A.75.75 0 1 0 .22 1.28l4.63 4.631a.125.125 0 0 1 0 .177L.22 10.72z"
						opacity={this.state.onHover ? "1" : "0.75"}/>
				</svg>
			</div>
		);
	}
}



export default Close;
