/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './HeaderLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class HeaderLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<ContentLoader
				height={59}
				width={1870}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '100%', width: '100%' }}
			>
				<circle cx="4.9%" cy="30" r="16" />
				<rect x="6.8%" y="17" rx="0" ry="0" width="106" height="26" />
				<circle cx="62%" cy="28" r="16" />
				<circle cx="65%" cy="28" r="16" />
				<rect x="67.6%" y="18" rx="0" ry="0" width="100" height="20" />
				<circle cx="80%" cy="28" r="16" />
				<circle cx="83%" cy="28" r="16" />
				<rect x="85.6%" y="18" rx="0" ry="0" width="100" height="20" />
			</ContentLoader>
		);
	}
}

export default HeaderLoader;
