/**
 * @author: Amul
 * @since: Mon Dec 17 2018 10:30:03 GMT+0530 (India Standard Time)
 * @file: apiErrors.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * For proper handling of api errors
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    fetchModulesError: null,
    fetchModuleItemError: null,
    fetchCoursesError: null,
    developmentProductLaunchError: null,
    fetchQuizError: null,
    fetchQuizQuestionsError: null,
    fetchModuleError: null,
    fetchPhaseItemsError: null,
    fetchUserPhaseItemsError: null,
    fetchPhaseItemDetailsError: null,
    fetchItemFeedbackError: null,
    fetchUserItemFeedbackError: null,
    fetchMeasuresError: null,
    fetchUserMeasuresError: null,
    fetchWidgetAnalyticsError: null,
    fetchGenericAnalyticsDataError: null,
    fetchDayWiseTimeSpentDataError: null,
    fetchPlatformWiseTimeSpentDataError: null,
    fetchAssessmentProgressDataError: null,
    fetchDevelopmentProgressDataError: null,
    fetchAssessmentTimeSpentDataError: null,
    fetchDevelopmentTimeSpentDataError: null,
    fetchStatsDataError: null,
    fetchLeaderboardDataError: null,
    fetchOverallProgressDataError: null,
    fetchTimeSpentPeriodicallyDataError: null,
    fetchProgressPeriodicallyDataError: null,
    fetchOverallPerformanceDataError: null,
    fetchAssessmentMeasuresDataError: null,
    fetchAssessmentScoreDataError: null,
    fetchDevelopmentScoreDataError: null
});

const apiErrors = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_API_ERROR'):
            return state.set(action.errorKey, action.errorMessage);
        default:
            return state;
    }
};

export default apiErrors;