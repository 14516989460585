import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';

import styles from './topics.module.sass';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, {allowMultiple: true})
class Topics extends Component {

    getTopicsCapsules = (moduleItem, styles) => {
        let content = []
        let contentDetails = moduleItem.get('moduleItem');
        if(contentDetails.get('topics')){
          content = contentDetails.get('topics').map(topic => 
            <div className={css(styles.topicCapsule)} key={`topic_${topic.get('id')}`} styleName="topic-capsule">
              {topic.get('topic_name')}
            </div>
          )
        }
        return content;
    }


    render() {
        let {moduleItem, getLabelWithDefaults} = this.props;
        const styles = getSkin(this.props.skinGuide);
        if (!moduleItem.get('moduleItem').get('topics')){
            return null;
        }
        return (
            <span>
                {
                    moduleItem.get('moduleItem').get('topics').size > 0 ? 
                    <span>
                        <div className={css(styles.topicsHeader)}>
                            {getLabelWithDefaults('module_item_topics_covered_label', 'Topics Covered')}
                        </div>
                        <div styleName="topics-row">
                            {this.getTopicsCapsules(moduleItem, styles)}
                        </div>
                    </span> : null 
                }
            </span>
             
        )
    }
}

export default Topics;