/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: skin.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * skin reducer is responsible for strings skin related actions
 *
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import deriveCompleteSkinWithFont from 'utils/skinUtil';
import { setOrUpdateObjectInMap } from 'utils/objectUtils';

// initialState of the skin reducer
const initialState = Immutable.fromJS({});

const skin = (state = initialState, action = {}) => {
	if (action.type === actionConsts.get('SET_BATCH_SKIN')) {
		if (action.payload.skin)
			return Immutable.fromJS(action.payload.skin);
		else
			return state
	}
	return state;
};

export default skin;
