import React, { Component } from 'react';
import styles from './desingAStrategy.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DigitalToolHeader from '../digitalToolHeader';
import DigitalToolGuide from '../digitalToolGuide';
import ToolItemList from '../toolItemList';
import InformationIcon from 'svgComponents/informationIcon';
import AddIcon from 'svgComponents/addIcon';
import Checkmark from 'svgComponents/checkmark';
import { getDigitalToolkitFirebaseDatabase } from 'utils/firebase';
import { sortObjectOfObjectsBasedOnOrder } from 'utils/utilFunctions';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class DesingAStrategy extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuide: false,
			showCapsuleInformation: false
		}
	}

	toggleShowGuide = () => {
		this.setState({
			showGuide: !this.state.showGuide
		});
	}

	submitDataStrategy = () => {
		this.props.markModuleItemDone(this.props.match.params);
	}

	downloadDataStrategy = () => {
		alert("feature not available!");
	}

	getToolItemListComp = (dataKey) => {
		const { toolData, toolUserData, colorPalette } = this.props;
		const selectedCP = toolUserData && toolUserData.data && toolUserData.data.selectedColorPalette ? toolUserData.data.selectedColorPalette[dataKey] : ""
		return (
			<ToolItemList
				type={dataKey}
				key={`key_${dataKey}`}
				userData={toolUserData && toolUserData.data && toolUserData.data.cards ? toolUserData.data.cards[dataKey] : {}}
				addNewUserDataRecord={this.props.addNewUserDataRecord}
				deleteRecord={this.props.deleteRecord}
				colorPalette={colorPalette}
				selectedColorPalette={selectedCP}
				setColorPalette={this.props.setColorPalette}
				cardStyles={{
					height: '240px',
					maxHeight: '240px',
					justifyContent: 'space-between',
					borderRadius: '6px 0 6px 6px',
					backgroundImage: `${selectedCP && colorPalette[selectedCP].cardBackground ? '' : 'linear-gradient(132deg, #477bc0, #4a53ae)'}`
				}}
				instructions={toolData.cardExamples[dataKey]}
			/>
		)
	}

	addOrRemoveLever = async (selectedLever) => {
		const { toolUserData, match, currentUserDetails, moduleItem } = this.props;
		let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])}/data/selectedLeverIds`;

		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 
		
		const paletteRef = digitalToolkitDb.ref(refString);
		let leversToSet = {};
		if (toolUserData && toolUserData.data && toolUserData.data.selectedLeverIds) {
			leversToSet = { ...(toolUserData.data.selectedLeverIds) }
		}
		if (leversToSet[selectedLever]) {
			leversToSet[selectedLever] = null;
		} else {
			leversToSet[selectedLever] = selectedLever;
		}
		paletteRef.set(leversToSet);
	}

	getInvidiualValueLeversComponent = (valueLevers) => {
		let leversComp = [];
		let myStyles = getSkin(this.props.skinGuide);
		const { toolUserData } = this.props;
		for (let lever in valueLevers) {
			const isLeverSelected = toolUserData && toolUserData.data && toolUserData.data.selectedLeverIds && toolUserData.data.selectedLeverIds[valueLevers[lever].id] ? true : false;

			leversComp.push(
				<div key={`${valueLevers[lever].id}`} styleName={`individual-lever ${isLeverSelected ? 'selected' : ''}`} onClick={() => this.addOrRemoveLever(valueLevers[lever].id)}>
					<div styleName="info-icon-cnt">
						<InformationIcon fillColor={isLeverSelected ? 'white' : null} />
						<div
							className={css(myStyles.infoText)}
							styleName="capsule-info-card"
							dangerouslySetInnerHTML={{ __html: valueLevers[lever].infoText }}
						>
						</div>
					</div>
					<div className={css(isLeverSelected ? myStyles.selectedLeversText : myStyles.leversText)}>{valueLevers[lever].name}</div>
					{
						isLeverSelected ?
							<div styleName="checkmark-icon-cnt">
								<Checkmark fillColor={isLeverSelected ? 'white' : null} />
							</div>
							:
							<div styleName="add-icon-cnt">
								<AddIcon />
							</div>
					}
				</div>
			)
		}
		return leversComp;
	}

	getInnovationLeversComponent = () => {
		const { toolData } = this.props;
		let myStyles = getSkin(this.props.skinGuide);
		let innovationLeversComponent = [];
		for (let value in toolData.innovationLevers) {
			innovationLeversComponent.push(
				<div key={`${value}_lever`} styleName={`${value}-levers-cnt`}>
					<div className={css(myStyles.innovationLeverHeaderText)} styleName="levers-cnt-header">
						{toolData.innovationLevers[value].label}
					</div>
					<div styleName="levers-cnt">
						{
							this.getInvidiualValueLeversComponent(toolData.innovationLevers[value].levers)
						}
					</div>
				</div>
			)
		}

		return innovationLeversComponent;
	}

	renderDesignAStrategyContent = () => {
		let myStyles = getSkin(this.props.skinGuide);
		const { toolData } = this.props;
		let cardsComponent = [];
		let orderedActors = sortObjectOfObjectsBasedOnOrder(toolData.actors);
		for (let actor in orderedActors) {
			let dataKey = `ecosystem_entities_${orderedActors[actor].key}`;
			cardsComponent.push(
				<div key={dataKey} styleName="individual-card-cnt">
					<div className={css(myStyles.roleText)} styleName="header">
						{orderedActors[actor].name}
					</div>
					<div styleName="connector-line"></div>
					<div styleName="tool-items-list-card-cnt">
						{this.getToolItemListComp(dataKey)}
					</div>
				</div>
			);
		}
		return (
			<div styleName="design-a-strategy-content-cnt">
				<div styleName="design-a-strategy-content">
					<div className={css(myStyles.stepHeaderText)} styleName="step-header-text">
						{toolData.labels.step1 || "Step 1: Pick the most relevant innovation levers"}
					</div>
					<div styleName="innovation-levers-cnt">
						{this.getInnovationLeversComponent()}
					</div>
				</div>

				<div styleName="design-a-strategy-content">
					<div className={css(myStyles.stepHeaderText)} styleName="step-header-text">
						{toolData.labels.step2 || "Step 2: Identify the key value proposition for each ecosystem entity"}
					</div>
					<div styleName="cards-outer-cnt">
						<div styleName="value-proposition-cnt">
							<div className={css(myStyles.valuePropositionText)} styleName="value-proposition-text">
								{toolData.labels.value_proposition || "Value Proposition"}
							</div>
						</div>
						<div styleName="individual-cards-cnt">
							{cardsComponent}
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { toolData } = this.props;

		return (
			<div styleName="design-a-strategy-cnt">
				<DigitalToolHeader
					toggleShowGuide={this.toggleShowGuide}
					submitAction={this.submitDataStrategy}
					downloadAction={this.downloadDataStrategy}
					toolData={toolData}
				/>
				{
					this.renderDesignAStrategyContent()
				}
				{
					this.props.toolData && this.state.showGuide &&
					<DigitalToolGuide
						closeGuide={this.toggleShowGuide}
						guideData={this.props.toolData.guide}
						labels={this.props.toolData.labels}
					/>
				}
			</div>
		);
	}
}

export default DesingAStrategy;