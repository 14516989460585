import React, { Component } from 'react';
import styles from './textTooltip.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { textTruncate, checkIfPresent } from 'utils/utilFunctions';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TextTooltip extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false
		}
	}

	setShowTooltip = (flag) => {
		this.setState({
			showTooltip: flag
		});
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const tooltipStyle = checkIfPresent(this.props.styleProp) ? this.props.styleProp : {};
		return (
			<span
				styleName="main-text"
				onMouseEnter={() => {
					this.setShowTooltip(true);
				}}
				onMouseLeave={() => {
					this.setShowTooltip(false);
				}}
			>
				{textTruncate(this.props.text, this.props.charLength)}
				{
					this.state.showTooltip
					?	<span styleName="tooltip" style={tooltipStyle}>
							<div styleName="tooltip-arrow-container">
								<div className={css(myStyles.tooltipArrow)}></div>
							</div>
							<div className={css(myStyles.tooltipContent)} styleName="tooltip-content">
								{this.props.text}
							</div>
						</span>
					:	null
				}
				
			</span>
		);
	}
}

export default TextTooltip;