import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './infoAndDaysFilterComponent.module.sass';
import InfoIcon from 'images/analytics/info_icon.svg'

import { css } from 'aphrodite/no-important';
import getSkin from './skin';

@CSSModules(styles, { allowMultiple: true })

class InfoAndDaysFilterComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.props.filterObject.get('selectedFilter').toLowerCase()
        };
    }

    handleOnClick = (event, filter) => {
        this.setState({
            selected: filter
        });

        if(this.props.clickHandler) {
            this.props.clickHandler(event, filter);
        }
    };

    renderDailyFilter = (myStyles) => {
        const { strings, filterObject } = this.props;
        if (filterObject.get('dayWiseData') && filterObject.get('weekWiseData')) {
            const getStyleDay = this.state.selected == 'day' ? 'filterSelected' : 'fontStyling';
            return(
                <div 
                    onClick={(e) => this.handleOnClick(e, 'day')} 
                    className={css(myStyles[getStyleDay])} 
                    styleName="filters-text"
                >
                    {strings.get('dailyLabel')}
                </div>
            )
        }
        return;
    }

    renderWeeklyFilter = (myStyles) => {
        const { strings, filterObject } = this.props;
        if (filterObject.get('weekWiseData')) {
            const getStyleWeek = this.state.selected == 'week' ? 'filterSelected' : 'fontStyling';
            return(
                <div 
                    onClick={(e) => this.handleOnClick(e, 'week')} 
                    className={css(myStyles[getStyleWeek])} 
                    styleName="filters-text"
                >
                    {strings.get('weeklyLabel')}
                </div>
            )
        }
        return;
    }

    renderMonthlyFilter = (myStyles) => {
        const { strings, filterObject } = this.props;
        if (filterObject.get('monthWiseData')) {
                const getStyleMonth = this.state.selected == 'month' ? 'filterSelected' : 'fontStyling';
                return(
                    <div
                        onClick={(e) => this.handleOnClick(e, 'month')}
                        className={css(myStyles[getStyleMonth])}
                        styleName="filters-text"
                    >
                        {strings.get('monthlyLabel')}
                    </div>
                )
        }
        return;
    }
    
    renderFiltersBorder = () => {
        const { filterObject } = this.props;
        if(filterObject.get('dayWiseData') && filterObject.get('weekWiseData')) {
            return true;
        }
        return false;
    }

    render() {

        const {title, subtitle} = this.props;        

        const myStyles = getSkin(this.props.skinGuide);
        return(
            <div styleName="info-filters-container">
                <div styleName="info-filters-content">
                    <div styleName="info-content">
                        <div className={css(myStyles.fontStylingTitle)} styleName="title-content">
                            {title}
                        </div>
                        <div styleName="filters-container">
                            <div className={this.renderFiltersBorder() ? css(myStyles.borderStyling) : null} styleName="filters-content">
                                {this.renderDailyFilter(myStyles)}
                                {this.renderWeeklyFilter(myStyles)}
                                {this.renderMonthlyFilter(myStyles)}
                            </div>
                        </div>
                    </div>
                    <div className={css(myStyles.fontStylingSubTitle)} styleName="subtitle-content">
                        <div styleName="logo-container">
                            <img src={InfoIcon} />
                        </div>
                        {subtitle}
                    </div>
                </div>
            </div>
        )
    }
}


export default InfoAndDaysFilterComponent;