import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './takeQuizHeader.module.sass';
import getSkin from './skin.js';
import DownArrow from 'svgComponents/downArrow';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import Dialog from 'commonComponents/dialog'
import applyLabel from 'utils/LabelProvider';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class TakeQuizHeader extends React.Component {

  constructor(props) {
    super(props);
    let timeLeft = this.getRemainingTime();
    timeLeft = timeLeft ? timeLeft : 0;
    this.state = {
      showInstructions: false,
      showConfirmationPopup: false,
      popupMessage: '',
      popupOkAction: null,
      popupCancelAction: null,
      popupOkButtonText: '',
      isComponentLoading: false,
      timeLeft: timeLeft
    }
    this.quizTimerInterval = null;
  }

  getRemainingTime = () => {
    const { quizResponseData, quizData } = this.props;

    if (quizResponseData.get('remTimeInSec') !== undefined) {
      return Math.abs(quizResponseData.get('remTimeInSec'));
    }
    if (quizData.getIn(['dataWithoutAnswers', 'maxTimeToFinish']) !== undefined) {
      return quizData.getIn(['dataWithoutAnswers', 'maxTimeToFinish']);
    }
  }

  toggleInstructions = () => {
    this.setState({
      showInstructions: !this.state.showInstructions
    })
  }

  componentWillUnmount() {
    //this.hideConfirmationPopup()
  }

  componentDidMount() {
    const { quizData } = this.props;
    if (
      quizData.getIn(['dataWithoutAnswers', 'maxTimeToFinish'])
    ) {
      this.updateTimeLeft();
    }
  }

  hideConfirmationPopup = () => {
    this.setState({
      showConfirmationPopup: false,
      popupMessage: '',
      popupOkAction: null,
      popupCancelAction: null,
      popupOkButtonText: '',
      isComponentLoading: false
    })
  }

  getPayloadToSubmitQuiz = () => {
    const { quizId, answeredQuestion } = this.props;
    const payload = {
      result: answeredQuestion ? answeredQuestion : {},
      quizId
    };
    return payload;
  }

  updateTimeLeft = () => {
    this.quizTimerInterval = setInterval(() => {
      if (this.state.timeLeft) {
        let timeLeft = this.state.timeLeft - 1;
        if (timeLeft === 0) {
          this.setState({
            showConfirmationPopup: true,
            popupMessage: 'Your Time is up! Please Wait, submitting your answers.',
            popupOkAction: null,
            popupCancelAction: null,
            isComponentLoading: true,
            timeLeft
          }, () => {
            clearInterval(this.quizTimerInterval);
            const { batchId, phaseItemId } = this.props;
            const payload = this.getPayloadToSubmitQuiz();
            this.props.submitQuiz(payload, batchId, phaseItemId);
          });
        } else {
          this.setState({
            timeLeft
          })
        }
      }
    }, 1000)
  }

  showTimeLeft = () => {
    let min = 0;
    let sec = 0;
    const { timeLeft } = this.state;
    if (timeLeft) {
      min = parseInt(timeLeft / 60);
      sec = timeLeft % 60;
    }
    if (min < 100) {
      min = ('00' + min).slice(-2)
    }
    sec = ('00' + sec).slice(-2)
    return { min, sec }
  }

  sendSegmentData = (event) => {
    let segmentData = {
      referrer: window.location.href,
      pathHash: window.location.hash
    };
    window.analytics.track(
      event,
      segmentData,
      getAmplitudeSessionDetails()
    );
  }

  onSubmitQuiz = () => {
    let { hashedQuestions, answeredQuestion, getLabelWithDefaults } = this.props;
    let incompleteQuestionCount = 0;
    // this.sendSegmentData('Quiz Submit clicked');

    let message = getLabelWithDefaults(
      'quiz_submit_confirmation_label',
      'Are you sure you want to submit the quiz?'
    );

    hashedQuestions.forEach((questionData, questionKey) => {
      if (questionData && answeredQuestion[questionKey] === undefined) {
        incompleteQuestionCount++;
      }
    })

    if (incompleteQuestionCount > 0) {
      message = getLabelWithDefaults(
        'quiz_submit_confirmation_unanswered_label',
        `<span>You have not answered <b>${incompleteQuestionCount}</b> questions. Are you sure you want to submit the quiz?<span>`,
        { unanswered_questions_count: incompleteQuestionCount }
      );
    }
    this.setState({
      showConfirmationPopup: true,
      popupMessage: message,
      popupOkButtonText: getLabelWithDefaults('quiz_submit_confirmation_yes_label', 'Yes, submit'),
      popupOkAction: this.submitQuizAfterConfirmation,
      popupCancelAction: this.hideConfirmationPopup,
      isComponentLoading: false
    })
  }

  submitQuizAfterConfirmation = () => {
    clearInterval(this.quizTimerInterval);
    this.setState({
      popupMessage: this.props.getLabelWithDefaults(
        'quiz_submission_in_progress_label',
        'Please Wait. Submitting your answers.'
      ),
      popupOkAction: null,
      popupCancelAction: null,
      isComponentLoading: true
    }, () => {
      const { batchId, phaseItemId } = this.props;
      const payload = this.getPayloadToSubmitQuiz();
      this.props.submitQuiz(payload, batchId, phaseItemId);
    })
  }

  onQuizExit = () => {
    //this.sendSegmentData('Quiz Exit clicked');
    this.setState({
      showConfirmationPopup: true,
      popupMessage: this.props.getLabelWithDefaults(
        'quiz_exit_confirmation_message',
        'Are you sure you want to exit the quiz?'
      ),
      popupOkAction: this.exitQuizAfterConfirmation,
      popupCancelAction: this.hideConfirmationPopup,
      isComponentLoading: false
    })
  }

  exitQuizAfterConfirmation = () => {
    const { batchId, phaseItemId } = this.props;
    const route = routes.get('BATCH_ITEM');
    const params = [
      { 'BATCH_ID': batchId },
      { 'ITEM_ID': phaseItemId }
    ]
    updateRoute({ route, params });
  }

  dontHidePopup = (e) => {
    e.stopPropagation()
  }

  render() {
    let styles = getSkin(this.props.skinGuide);
    let { quizData, getLabelWithDefaults, answeredQuestion, selectedItem } = this.props;
    let { showInstructions, showConfirmationPopup, popupMessage, popupOkAction, popupCancelAction, popupOkButtonText, isComponentLoading } = this.state;
    let quizDescription = selectedItem.get('playInstructions');

    var quizTitleElement, quizTimerElement, quizActionsElement, quizInstructionsElement, quizDialogElement;

    quizTitleElement = quizTimerElement = quizActionsElement = quizInstructionsElement = quizDialogElement = null;

    let actuallyAnsweredQuestions = Object.keys(answeredQuestion).length;

    let totalNoOfQuestions = quizData.getIn(['dataWithoutAnswers', 'elements']).size;

    let quizTime = this.showTimeLeft();

    quizTitleElement = (
      <div
        key="quiz-title"
        className={css(styles.quizTitle)}
        styleName="quiz-title"
        onClick={this.toggleInstructions}
      >
        <div
          styleName="title"
          title={selectedItem.get('name')}
        >
          {
            selectedItem.get('name')
          }
        </div>
        {
          quizDescription != "" &&
          <div styleName="instruction-arrow-cnt">
            <DownArrow fillColor={'#5d5d5d'} />
          </div>
        }
      </div>
    )

    quizTimerElement = (
      <div
        key="timer-cnt"
        className={css(styles.timerCnt)}
        styleName="timer-cnt"
      >
        {
          quizData.getIn(['dataWithoutAnswers', 'maxTimeToFinish']) ?
            <div className={css(styles.timerTime)}>
              {
                `${quizTime.min}:${quizTime.sec}`
              }
            </div>
            : null
        }
        <div className={css(styles.questionsAnswered)}>
          {
            getLabelWithDefaults(
              'quiz_questions_answered_count_label',
              `${actuallyAnsweredQuestions.size}/${totalNoOfQuestions} answered`,
              {
                answered_count: actuallyAnsweredQuestions,
                total_count: totalNoOfQuestions
              }
            )
          }
        </div>
      </div>
    )

    quizActionsElement = (
      <div key="quiz-actions-cnt" styleName="quiz-actions-cnt">
        <div
          className={css(styles.exitBtn)}
          styleName="exit-btn"
          onClick={this.onQuizExit}
        >
          {getLabelWithDefaults('exit_label', 'EXIT')}
        </div>
        <div
          className={css(styles.submitBtn)}
          styleName="submit-btn"
          onClick={this.onSubmitQuiz}
        >
          {getLabelWithDefaults('submit_label', 'SUBMIT')}
        </div>
      </div>
    )

    if (quizDescription != "" && showInstructions) {
      quizInstructionsElement = (
        <div key="quiz-instructions-cnt" styleName="quiz-instructions-overlay" onClick={this.toggleInstructions}>
          <div styleName="my-container">
            <div styleName="triangle-with-shadow"></div>
            <div styleName="quiz-instructions-cnt" onClick={this.dontHidePopup}>
              <div className={css(styles.quizInstructionsTitle)}>Instructions</div>
              <div className={css(styles.quizInstructions)} dangerouslySetInnerHTML={{ __html: quizDescription }}></div>
            </div>
          </div>
        </div>
      )
    }

    if (showConfirmationPopup) {
      quizDialogElement = (
        <Dialog
          key="quiz-confirmation-dialog"
          dialogMessage={popupMessage}
          okAction={popupOkAction}
          cancelAction={popupCancelAction}
          okButtonText={popupOkButtonText}
          isComponentLoading={isComponentLoading}
        />
      )
    }


    return (
      <div className={css(styles.quizHeaderCnt)} styleName="quiz-header-cnt">
        {
          selectedItem ?
            [quizTitleElement, quizTimerElement, quizActionsElement,
              quizInstructionsElement,
              quizDialogElement
            ]
            :
            <div>
              {getLabelWithDefaults('quiz_loading_label', 'Loading Quiz Content...')}
            </div>
        }

      </div>
    )
  }
}

export default TakeQuizHeader;