/**
 * @author: varun
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: userState.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import React from 'react';
import Immutable from 'immutable';
import radarDataGen from 'scripts/data/radarGraph';
import itemsDataGen from 'scripts/data/itemsCompletedYouVsGroup';
import timeDataGen from 'scripts/data/timeSpent';
import usageDataGen from 'scripts/data/usageAcrossPlatform';
import leaderboardDataGen from 'scripts/data/leaderboard';
import needleMovementDataGen from 'scripts/data/needleMovement';
import { genBins } from '@vx/mock-data';
import inverselyProportionalDataGen from 'scripts/data/inverselyProportional';
import {getCookie} from 'utils/cookies';
import environment from 'utils/environment';

const getQueryParamValue = ( params, url ) => {  
    let href = url;
    //this expression is to get the query strings
    let reg = new RegExp( '[?&]' + params + '=([^&#]*)', 'i' );
    let queryString = reg.exec(href);
    return queryString ? queryString[1] : null;
  };

const checkIfSystemCheckDone = () => {
    if(environment.REACT_APP_ENV === 'local') return true;

    const skipCheck = getQueryParamValue('skipCheck', window.location.href);
    if(skipCheck === "true"){
        window.localStorage.setItem('initialSystemCheckDone', true);
        return "true";
    }

    //keeping getCookie('ap_systemcheckdone') to avoid affecting existing users
    return window.localStorage.getItem('initialSystemCheckDone') || getCookie('ap_systemcheckdone') || "false";
}

const initialState = Immutable.fromJS({
    appLoaderNumber: Math.floor(Math.random() * 1000),
    userError: {
        message: null,
        error_code: null,
        data: null,
        status_code: null
    },
    currentBatchId: null,
    currentPhaseId: null,
    currentItemId: null,
    showFirstTimeFlag: false,
    showFirstTimePhaseFlag: false,
    showAnalytics: false,
    userClosedFirstTimeFlag: false,
    dataGeneratedForAnalytics: false,
    dataGeneratedForActivity: false,
    dataGeneratedForPerformance: false,
    radarData: radarDataGen(8, 2, 10),
    itemsData: itemsDataGen(15, 80),
    timeData: timeDataGen(16, 70),
    usageData: usageDataGen(4, 50),
    progressData: itemsDataGen(1, 80),
    leaderboardData: leaderboardDataGen(20, 1000),
    needleMovementData: needleMovementDataGen(5, 100),
    heatMapData: genBins(7, 5),
    inverselyProportionalData: inverselyProportionalDataGen(16, 100),
    makeLeftBarFixed: false,
    completeContainerRef: React.createRef(),
    stickyRef: React.createRef(),
    showStickyHeader: false,
    invitationDivRefs: {
        benefitsAndInsights: React.createRef(),
        messageFromCoo: React.createRef(),
        prerequisitesAndSkills: React.createRef(),
        aboutIndex: React.createRef()
    },
    invitationDivName: '',
    invitationDivNameSet: false,
    scormLaunched: null,
    freshdeskInitialized: false,
    freshdeskInitializedID: null,
    freshdeskInitializedURL: null,
    makeReportAvailable: false,
    batchAnalyticsFlag: false,
    showActivityPage: 0,
    showPerformancePage: 0,
    showWidgets: 0,
    showJourneyDashboard: 0,
    isPerformancePageAvailable: 0,
    systemCheckDone: checkIfSystemCheckDone(),
    ie11WarningAcknowledged: window.localStorage.getItem('ie11warningack') || getCookie('ap_ie11warningack') || "false",
    batchToRunSystemCheckFor: null
});

const userState = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_USER_STATE'):

            return state.mergeDeep(
                Immutable.fromJS(action.payload)
            );
        case actionConsts.get('ANALYTICS'):

            return state.set(
                'dataGeneratedForAnalytics',
                true
            );
        case actionConsts.get('ACTVITY'):

            return state.set(
                'dataGeneratedForActivity',
                true
            );
        case actionConsts.get('PERFORMANCE'):

            return state.set(
                'dataGeneratedForPerformance',
                true
            );

        case actionConsts.get('SET_ANALYTICS_BUTTON_DATA'):
            // console.log("Analytics Data is fetched", action.payload);
            return state.mergeDeep(
                Immutable.fromJS(action.payload)
            );

        default:
            return state;
    }
};

export default userState;