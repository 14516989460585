import actionConstants from 'constants/actions';

export const unsetBatchFeedbackStatus = () => ({
	type: actionConstants.get('UNSET_BATCH_FEEDBACK_STATUS')
});

export const setBatchFeedbackStatus = (status) => ({
	type: actionConstants.get('SET_BATCH_FEEDBACK_STATUS'),
    status
});

export const setBatchesFeedbackStatusFlags = (statusFlags) => ({
	type: actionConstants.get('SET_BATCHES_FEEDBACK_STATUS_FLAGS'),
	statusFlags
});

export const unsetBatchesFeedbackStatusFlags = () => ({
	type: actionConstants.get('UNSET_BATCHES_FEEDBACK_STATUS_FLAGS')
});
