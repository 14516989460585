import actionConsts from 'constants/actions';

const setActiveUserBatchesData = payload => ({
	type: actionConsts.get('SET_ONGOING_USER_BATCHES'),
	payload
});

const setUpcomingUserBatchesData = payload => ({
	type: actionConsts.get('SET_UPCOMING_USER_BATCHES'),
	payload
});

const setCompletedUserBatchesData = payload => ({
	type: actionConsts.get('SET_COMPLETED_USER_BATCHES'),
	payload
});

const setExpiredUserBatchesData = payload => ({
	type: actionConsts.get('SET_EXPIRED_USER_BATCHES'),
	payload
})

const setRequestedUserBatchesData = payload => ({
	type: actionConsts.get('SET_REQUESTED_USER_BATCHES'),
	payload
});

const setAvailableJourneys = payload => ({
	type: actionConsts.get('SET_AVAILABLE_JOURNEYS'),
	payload
})

const setAvailableCorpDeckJourneys = payload => ({
	type: actionConsts.get('SET_AVAILABLE_CORP_DECK_JOURNEYS'),
	payload
})

const setFetchingFilteredBatchesFlag = flagName => ({
	type: actionConsts.get('SET_FETCHING_FILTERED_BATCHES_FLAG'),
	flagName
})

const setRequestingJourneyAccess = flag => ({
	type: actionConsts.get('SET_REQUESTING_JOURNEY_ACCESS'),
	flag
})

const setBatchSwitching = flag => ({
	type: actionConsts.get('SET_BATCH_SWITCHING'),
	flag
})

export {
	setActiveUserBatchesData,
	setUpcomingUserBatchesData,
	setCompletedUserBatchesData,
	setExpiredUserBatchesData,
	setRequestedUserBatchesData,
	setAvailableJourneys,
	setFetchingFilteredBatchesFlag,
	setRequestingJourneyAccess,
	setBatchSwitching,
	setAvailableCorpDeckJourneys
};