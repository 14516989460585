import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './productDuration.module.sass';
import CSSModules from 'react-css-modules';
import Duration from 'commonComponents/duration';
import SecondaryButton from 'commonComponents/buttons/secondaryButton';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import { convertDateWithYear } from 'utils/utilFunctions';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ProductDuration extends Component {
	render() {

		const { getLabel, selectedItem, isSideBarOpen, skinGuide } = this.props;
		const myStyles = getSkin(skinGuide);

		const containerStyle = 'duration-container-row';
		const itemStyle = 'item-row';
		return (
			<div styleName={containerStyle}>
				<div styleName={itemStyle}>
					<Duration
						title={selectedItem.get('durationLabel')}
						time={selectedItem.get('duration')}
					/>
				</div>
				<div styleName={itemStyle}>
					<Duration
						title={getLabel('due_date_label')}
						time={convertDateWithYear(selectedItem.get('endTimestamp'))}
						text={' '} />
				</div>
				{
					(selectedItem.get('allowDemo'))
						?
						<div styleName={itemStyle}>
							<SecondaryButton text={getLabel('try_demo_label')} />
						</div>
						:
						null
				}
			</div>
		);
	}
}

export default ProductDuration;
