import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules( { allowMultiple: true })
class UserRank extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <svg width="100%" height="100%" viewBox="0 0 24 24">
                <g fill={this.props.fillColor} fill-rule="nonzero">
                    <path d="M12 24c10.7 0 11.851-2.072 11.851-2.963 0-.641 0-2.143-7.041-2.763a.987.987 0 0 0-.173 1.967 26.87 26.87 0 0 1 3.771.557.247.247 0 0 1 0 .481 38.972 38.972 0 0 1-8.408.746c-2.82.056-5.64-.194-8.406-.746a.247.247 0 0 1 0-.481 26.713 26.713 0 0 1 3.731-.553.987.987 0 1 0-.174-1.967c-7 .622-7 2.12-7 2.759C.149 23.711 8.437 24 12 24z"/>
                    <path d="M13.273 6.63a.25.25 0 0 1-.017-.466 3.21 3.21 0 1 0-2.512 0 .25.25 0 0 1-.017.466 3.957 3.957 0 0 0-2.677 3.74v2.963c0 .272.22.493.493.493h.882c.129 0 .236.099.246.227l.356 4.259a.493.493 0 0 0 .492.452h2.962c.257 0 .47-.196.492-.452l.356-4.259a.246.246 0 0 1 .246-.227h.882c.272 0 .493-.22.493-.493V10.37a3.957 3.957 0 0 0-2.677-3.74zM4.779 7.612a.251.251 0 0 1-.022-.462 2.716 2.716 0 1 0-2.3 0 .251.251 0 0 1-.021.462 3.463 3.463 0 0 0-2.287 3.251v1.483a.493.493 0 0 0 .494.493h.819c.126 0 .23.095.245.22l.42 3.786a.5.5 0 0 0 .491.44h1.975a.494.494 0 0 0 .491-.439l.421-3.787a.247.247 0 0 1 .245-.22h.818a.493.493 0 0 0 .494-.493v-1.484a3.46 3.46 0 0 0-2.283-3.25zM21.567 7.612a.251.251 0 0 1-.021-.462 2.716 2.716 0 1 0-2.3 0 .251.251 0 0 1-.021.462 3.461 3.461 0 0 0-2.284 3.251v1.483a.493.493 0 0 0 .494.493h.818c.126 0 .232.095.246.22l.42 3.786a.5.5 0 0 0 .491.44h1.975a.494.494 0 0 0 .491-.439l.421-3.787a.247.247 0 0 1 .245-.22h.818a.493.493 0 0 0 .494-.493v-1.484a3.46 3.46 0 0 0-2.287-3.25z"/>
                    <path d="M12 24c10.7 0 11.851-2.072 11.851-2.963 0-.641 0-2.143-7.041-2.763a.987.987 0 0 0-.173 1.967 26.87 26.87 0 0 1 3.771.557.247.247 0 0 1 0 .481 38.972 38.972 0 0 1-8.408.746c-2.82.056-5.64-.194-8.406-.746a.247.247 0 0 1 0-.481 26.713 26.713 0 0 1 3.731-.553.987.987 0 1 0-.174-1.967c-7 .622-7 2.12-7 2.759C.149 23.711 8.437 24 12 24z"/>
                    <path d="M13.273 6.63a.25.25 0 0 1-.017-.466 3.21 3.21 0 1 0-2.512 0 .25.25 0 0 1-.017.466 3.957 3.957 0 0 0-2.677 3.74v2.963c0 .272.22.493.493.493h.882c.129 0 .236.099.246.227l.356 4.259a.493.493 0 0 0 .492.452h2.962c.257 0 .47-.196.492-.452l.356-4.259a.246.246 0 0 1 .246-.227h.882c.272 0 .493-.22.493-.493V10.37a3.957 3.957 0 0 0-2.677-3.74zM4.779 7.612a.251.251 0 0 1-.022-.462 2.716 2.716 0 1 0-2.3 0 .251.251 0 0 1-.021.462 3.463 3.463 0 0 0-2.287 3.251v1.483a.493.493 0 0 0 .494.493h.819c.126 0 .23.095.245.22l.42 3.786a.5.5 0 0 0 .491.44h1.975a.494.494 0 0 0 .491-.439l.421-3.787a.247.247 0 0 1 .245-.22h.818a.493.493 0 0 0 .494-.493v-1.484a3.46 3.46 0 0 0-2.283-3.25zM21.567 7.612a.251.251 0 0 1-.021-.462 2.716 2.716 0 1 0-2.3 0 .251.251 0 0 1-.021.462 3.461 3.461 0 0 0-2.284 3.251v1.483a.493.493 0 0 0 .494.493h.818c.126 0 .232.095.246.22l.42 3.786a.5.5 0 0 0 .491.44h1.975a.494.494 0 0 0 .491-.439l.421-3.787a.247.247 0 0 1 .245-.22h.818a.493.493 0 0 0 .494-.493v-1.484a3.46 3.46 0 0 0-2.287-3.25z"/>
                 </g>
            </svg>
        );
    }
}



export default UserRank;
