import React, { Component } from 'react';
import styles from './weblinksIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class WebSessionsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			// <div >
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
				<g fill="none" fillRule="evenodd" stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".883">
					<path d="M2.108 11.208h4.434M2.464 4.208h11.078M5.958 7.708h-4.66M7.574 1.307c-3.5 3.791-3.437 8.725.063 13.392M8.426 1.307a9.407 9.407 0 0 1 2.49 4.943M14.708 8A6.708 6.708 0 1 0 8 14.708" />
					<path d="M12.646 10.571l1.278-1.278a.292.292 0 0 0-.126-.487L7.439 6.994a.292.292 0 0 0-.36.36l1.809 6.359a.292.292 0 0 0 .487.126l1.196-1.195 1.856 1.855a.292.292 0 0 0 .412 0l1.663-1.657a.292.292 0 0 0 0-.413l-1.856-1.858z" />
				</g>
			</svg>
			// </div>
		);
	}
}

export default WebSessionsIcon;
