import { filterObjectFromArray } from 'utils/objectUtils';
import Assessment from 'components/assessment';
import React from 'react';
import Immutable from 'immutable';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import enumConstants from 'constants/enums';
import AppLoader from 'commonComponents/appLoader';
import { getUserActiveBatchDetails } from 'utils/batchUtils';
import SystemCheckContainer from 'containers/SystemCheckContainer';
import SaveItemProgress from 'actions/serverCalls/items/SaveItemProgress';
import FetchUserItems from 'actions/serverCalls/items/FetchUserItems'

const getActiveItemDetails = (state) => {
    const userActiveBatchDetails = getUserActiveBatchDetails(state.get('batches'));
    const { items } = setAvailabilityFlags(state.get('phases'), state.get('items'), userActiveBatchDetails);
    const itemsList = items.get('itemsList');
    const activeItemId = state.get('userState').get('currentItemId');
    return filterObjectFromArray(itemsList, 'id', activeItemId);
}

const getNextItemDetails = (state) => {
    const selectedItem = getActiveItemDetails(state);

    const itemsList = getItemsListInActivePhase(state);
    const nextItemIndex = itemsList.indexOf(selectedItem) + 1;
    return itemsList.get(nextItemIndex) || Immutable.fromJS({});
}

const getActiveUserItem = (state) => {
    const activeItemId = state.get('userState').get('currentItemId');
    const userActiveBatchDetails = getUserActiveBatchDetails(state.get('batches'));
    const { items } = setAvailabilityFlags(state.get('phases'), state.get('items'), userActiveBatchDetails);

    const userItemsList = items.get('userItemsList');
    return filterObjectFromArray(userItemsList, 'id', activeItemId);
}

const getActiveToolFAQs = (state) => {
    const tools = state.get('tools');
    const activeToolId = tools.get('activeToolId');
    const faqsList = tools.get('faqsList');

    if (activeToolId === -1 || faqsList.size === 0) {
        return Immutable.List();
    }
    return faqsList;
}


const getActivePhase = (state) => {
    let activePhaseId = state.get('phases').get('phaseIdFromRoute');
    if (activePhaseId === 1) // default value in reducer is set to 1
        activePhaseId = state.get('userState').get('currentPhaseId');

    const userActiveBatchDetails = getUserActiveBatchDetails(state.get('batches'));
    const { phases } = setAvailabilityFlags(state.get('phases'), state.get('items'), userActiveBatchDetails);

    const phasesList = phases.get('phasesList').toJS();

    const activePhaseDetails = phasesList.reduce(function (result, phase) {
        if (phase.id == activePhaseId) {
            result.push(phase);
        }
        return result;
    }, []);

    return activePhaseDetails[0];

}


const getItemsListInActivePhase = (state) => {
    const userActiveBatchDetails = getUserActiveBatchDetails(state.get('batches'));
    const { items } = setAvailabilityFlags(state.get('phases'), state.get('items'), userActiveBatchDetails);
    const list = items.get('itemsList');
    let activePhaseId = state.get('phases').get('phaseIdFromRoute');
    if (activePhaseId === 1) // default value in reducer is set to 1
        activePhaseId = state.get('userState').get('currentPhaseId');

    const itemsListInActivePhase = list.filter(item => item.get('phaseId') == activePhaseId);
    return itemsListInActivePhase;
}

const getFAQsForItem = (faqsList, itemId, items) => {

    const currentItemDetails = filterObjectFromArray(
        items.get('itemsList'), 'id', itemId
    );
    const toolId = currentItemDetails.get('toolId');
    if (!toolId) {
        return Immutable.List();
    }

    return faqsList.filter(faq => faq.get('toolId') === toolId);
}

const saveItemProgress = async (score, maxScore, isCompleted, props) => {
    const { match, user } = props;    
    let timeStampBody = { startedAt: new Date() };
    if(isCompleted){
        timeStampBody = { completedAt: new Date() };
    }

    let percentageCompletion = 0;

    if(maxScore === 0){//this will happen only in case of downloadable files
        percentageCompletion = 100;
    } else{
        percentageCompletion = (score/maxScore) * 100;
    }

    const body = {
        timestamp: (new Date()).getTime(),
        status: {
            ...timeStampBody,
            percentageCompletion
        },
        score,
        maxScore
    };
    
    await SaveItemProgress.call({
        queryParameters: {
            batchId: match.params.batchId,
            itemId: match.params.itemId,
            userId: user.get('userId')
        },
        body,
        headers: {
            'Content-type': 'application/json'
        }
    });

    if(isCompleted)
        await FetchUserItems.call({ queryParameters: { batchId: match.params.batchId } })
}

const getActiveItemProgress = (state) => {
    const activeItemId = state.get('userState').get('currentItemId');
    const userItemProgresses = state.get('items').get('itemProgresses');
    console.log('userItemProgresses', userItemProgresses.toJS(), activeItemId);
    const activeItemProgress = userItemProgresses.find( progress => progress.get('itemId') === +activeItemId );
    console.log('activeItemProgress', activeItemProgress)
    return activeItemProgress;
}


const renderComponent = (props) => {
    const activeItemType = props.selectedItem.get('itemType');
    const apiError = props.apiErrors.get('fetchPhaseItemsError') || props.apiErrors.get('fetchUserPhaseItemsError') || props.apiErrors.get('fetchPhaseItemDetailsError')
    //const activeItemId = props.selectedItem.get('id');
    //const loaders = props.loaders;
    // const { itemId } = this.props.match.params;
    if(props.userState.get('batchToRunSystemCheckFor') === +props.match.params.batchId){
        return <SystemCheckContainer context="batch"/>;
    }

    switch (true) {
        case (!activeItemType && !apiError):
            // return <JourneyLearningsPhaseLoader />;
            return <AppLoader userState={props.userState} />
        // case (loaders.get('isFetchingUserItemsListForFeedbackScreen')):
        //     return <ItemProgress />;
        case (activeItemType == enumConstants.get('SIMULATION')):
        case (activeItemType == enumConstants.get('GAME')):
        case (activeItemType == enumConstants.get('HARRISON_ASSESSMENTS')):
            return <Assessment {...props} saveItemProgress={(score, maxScore, isCompleted) => saveItemProgress(score, maxScore, isCompleted, props)}/>
        default:
            return <Assessment {...props} saveItemProgress={(score, maxScore, isCompleted) => saveItemProgress(score, maxScore, isCompleted, props)}/>;
    }
}



export {
    getActiveItemDetails,
    getNextItemDetails,
    getActiveUserItem,
    renderComponent,
    getActiveToolFAQs,
    getActivePhase,
    getItemsListInActivePhase,
    getFAQsForItem,
    getActiveItemProgress
};