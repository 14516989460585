import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';

class FetchBatchBasicDetails extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => urls.get('PLATFORM').get('GET_BATCH_BASIC_DETAILS').replace('BATCH_ID', apiInfoObject.batchId)

    successCall = (response) => {
        console.log('FetchBatchBasicDetails: response', response);        
        return response;
    }

    onError = (response) => {
        console.log("error", response);        
    }

}

export default new FetchBatchBasicDetails();
