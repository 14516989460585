import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		detailedAnalysisTitle: {
			...myTypography.h5,
			fontWeight: 600,
			color: skin.get('contentColor')
		},
		detailedAnalysisSubTitle: {
			...myTypography.overline,
			color: hexToRgbA(skin.get('greyColor1'), 0.85 * 0.5)
		},
		tabArrow: {
			borderBottom: `15px solid ${skin.get('greyColor5')}`,
			borderLeft: `10px solid transparent`,
			borderRight: `10px solid transparent`	
		},
		singleTab: {
			...myTypography.h6,
			fontSize: '18px',
			cursor: 'pointer',
			color: skin.get('black'),
			border: `1px solid ${skin.get('greyColor6')}`,
			':hover': {
				border: `1px solid ${skin.get('greyColor4')}`
			}
		},
		singleActiveTab: {
			...myTypography.h6,
			fontSize: '18px',
			cursor: 'pointer',
			backgroundColor: skin.get('greyColor5'),
			border: `1px solid ${skin.get('greyColor4')}`,
			color: skin.get('black')
		},
		downloadReportContainer: {
			backgroundColor: skin.get('white'),
			border: `1px solid ${skin.get('greyColor5')}`
		},
		downloadReportText: {
			...myTypography.h6,
			fontSize: '22px',
			fontWeight: 400,
			color: skin.get('greyColor2')
		},
		downloadReportButton: {
			...myTypography.button,
			color: `${skin.get('secondaryColor')} !important`,
			border: `1px solid ${skin.get('secondaryColor')}`,
			borderRadius: '4px',
			':hover': {
				backgroundColor: skin.get('secondaryColor'),
				color: skin.get('white')
			}
		}
	});
}

export default getSkin;