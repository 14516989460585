import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		quizQuestionsOuterCnt:{
			backgroundColor: skinHex.get('greyColor6')
		},
		quizDataCnt:{
		},
		questionListCnt:{
			backgroundColor: skinHex.get('white')
		},
		questionNavigatorCnt: {
			
		},
		questionText: {
			...myTypography.h5,
			fontWeight: 'normal',
			color: '#2e3447',
			lineHeight: '1.28'
		},
		optionLabelText:{
			...myTypography.h6,
			lineHeight: 1.28,
			fontWeight: 'normal',
			backgroundColor: hexToRgbA(skinHex.get('greyColor5'), 0.07),
			color: hexToRgbA('#2e3447', 0.7)
		},
		selectedOptionLabelText:{
			...myTypography.h6,
			lineHeight: 1.28,
			fontWeight: 'normal',
			backgroundColor: skinHex.get('primaryColor'),
			color: skinHex.get('white')
		},
		questionNumber:{
			...myTypography.button,
			color: skinHex.get('secondaryColor')
		},
		questionPoints:{
			...myTypography.button,
			color: `rgba(9, 16, 39, 0.34)`
		},
		checkmark: {
			border: `1px solid #daddea`
		}
	});
}

export default getSkin;