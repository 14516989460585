import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import AdditionalInformation from 'components/moduleItems/additionalInformation';
import styles from './scormContent.module.sass';
import applyLabel from 'utils/LabelProvider';
import ModuleItemFullScreen from 'components/moduleItems/moduleItemFullScreen';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@applyLabel
@CSSModules(styles, {allowMultiple: true})
class ScormEmbedded extends Component {

    constructor(props){
        super();
        props.fetchProductLaunch();
    }

    render() {
        let { moduleItem, getLabelWithDefaults, nextItemDetailsForFeedback, match, fetchProductLaunch, launchError, showPlatformExitButton } = this.props;
        return (
            <APIErrorBoundary
                key={match.params.moduleItemId}
                retryHandler={fetchProductLaunch}
                apiError={launchError}
            >
                <span>
                    {
                        moduleItem.get('productLaunch')?
                            <ModuleItemFullScreen
                                title={moduleItem.get('moduleItem').get('title')}
                                src={moduleItem.get('productLaunch').get('launch_url')}
                                onExit={()=>{}}
                                embedded={true}
                                nextItemDetailsForFeedback={nextItemDetailsForFeedback}
                                moduleItem={moduleItem}
                                match={match}
                                showPlatformExitButton={showPlatformExitButton}
                            />
                        : null
                    }

                    <AdditionalInformation
                    title={getLabelWithDefaults('scorm_additional_information_label', 'Additional Information')}
                    body={moduleItem.get('moduleItem').get('description')}/>
                </span>
            </APIErrorBoundary>
        )
    }
}

export default ScormEmbedded;
