import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import KnolskapeLogo from 'images/knolskape-logo.png';
import AppleStoreImg from 'images/appstore.png';
import GooglePlayImg from 'images/googleplay.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        footerBackground: {
            backgroundImage: `url(https://webassets.knolskape.com/misc/amul_garg/2020/02/12/57/footer-bg.png)`,
            backgroundSize: "cover",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
        },
        logoBackground: {
            backgroundImage: `url(${KnolskapeLogo})`,
            backgroundSize: "contain",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
        },
        title: {
            ...myTypography.h4,
            fontWeight: 600,
            lineHeight: 1.45,
            color: skin.get('white'),
            '@media screen and (max-width: 480px)': {
				fontSize: '20px'
			},
            '@media only screen and (min-device-width : 481px) and (max-device-width : 660px) and (orientation : portrait)': {
                fontSize: '20px'
            },
            '@media only screen and (min-device-width : 668px) and (max-device-width : 1024px) and (orientation : portrait)': {
				fontSize: '20px'
			}
        },
        desc: {
            ...myTypography.h6,
            fontSize: '18px',
            fontWeight: 400,
            color: skin.get('white')
        },
        google: {
            backgroundImage: `url(${GooglePlayImg})`,
            backgroundSize: '100% 100%'
        },
        apple: {
            backgroundImage: `url(${AppleStoreImg})`,
            backgroundSize: '100% 100%'
        },
        loaderContainer: {
            backgroundColor: skin.get('greyColor6')
        }
    });
}

export default getSkin;