import React, { Component } from 'react';
import styles from './offline.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Offline extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="main-component">
				<svg width="100%" height="100%" viewBox="0 0 20 20">
					<g fill="none" fillRule="evenodd" stroke={skinHex.get('white')} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25" opacity=".5">
						<circle cx="6.263" cy="13.739" r="5.625"/>
						<path d="M.958 15.614h10.61M.958 11.864h10.61M5.05 8.245a18.238 18.238 0 0 0 .424 11.064M7.477 8.245a18.15 18.15 0 0 1 .661 4.87 18.103 18.103 0 0 1-1.083 6.194M10.143 4.99A4.966 4.966 0 0 1 15 9.846M11.6.625a8.124 8.124 0 0 1 7.775 7.74"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default Offline;
