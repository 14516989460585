import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const myTypography = typography(theme);
	return StyleSheet.create({
		signInLabel: {
			...myTypography.overline,
			color: hexToRgbA('#353535', 0.8),
			cursor: 'pointer'
		}
	});
}

export default getSkin;