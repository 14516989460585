import React from 'react';
import certificateButtonsStyles from './certificateButtons.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import reports from 'constants/reports';
import ReportDownloadContainer from 'containers/ReportDownloadContainer';
import LinkedInShareCertificateContainer from 'containers/LinkedInShareCertificateContainer';
import DownloadSimReportButton from 'commonComponents/buttons/downloadSimReportButton';
import DownloadIcon2 from 'svgComponents/downloadIcon2';
import SimReportDownloadIcon from 'svgComponents/simReportDownloadIcon';

const CertificateButtons = ({ batchId, getLabel, isCertificateEnabled, isLinkedInShareEnabled, isSimReportEnabled, downloadSimReport }) => {
  return <div styleName="certificate-buttons-cnt">
    {isSimReportEnabled && <div className='sim-report-button-cnt' onClick={downloadSimReport} style={{ marginRight: '17px'}}>
      <DownloadSimReportButton
        text={<span><SimReportDownloadIcon/>{getLabel('download_report')}</span>}
      />
    </div>}
    {isCertificateEnabled && isLinkedInShareEnabled && <LinkedInShareCertificateContainer
      reportType={reports.get('certificate')}
      reportOutputType="pdf"
      metadata={{ batchId }}
      downloadButtonText={getLabel('download_certificate_label')}
    />}
    {isCertificateEnabled && <div style={isLinkedInShareEnabled ? {marginLeft: '17px'}: {}}>
      <ReportDownloadContainer
        reportType={reports.get('certificate')}
        reportOutputType="pdf"
        metadata={{ batchId }}
        downloadButtonText={
          <span><DownloadIcon2/>{getLabel('download_certificate_label')}</span>
        }
        useNewStyle={true}
        useInvertedButtonStyle={isLinkedInShareEnabled}
      />
    </div>}
  </div>;
}

export default applyLabel(CSSModules(CertificateButtons, certificateButtonsStyles));