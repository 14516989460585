import React, { Component } from 'react';
import styles from './scaleRating.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ScaleRating extends Component {

	constructor(props) {
		super(props);
		this.state = {
			ratingValue: props.question.get('value')
		}
	}

	updateRating = (score) => {
		const { feedbackItemId } = this.props;
		this.setState({
			ratingValue: score
		}, () => {
			const payload = {
				value: score,
			};
			const questionId = this.props.question.get('id');
			this.props.submitAnswer(payload, questionId, feedbackItemId);
		});
	}

	getMinMaxLabels = () => {
		const myStyles = getSkin(this.props.skinGuide);
		const { getLabelWithDefaults } = this.props;
		return (
			<div className={css(myStyles.minMaxLabel)} styleName="min-max-label-cnt">
				<div>{getLabelWithDefaults('min_rating_label', 'Not Likely')}</div>
				<div>{getLabelWithDefaults('max_rating_label', 'Very Likely')}</div>
			</div>
		)
	}

	renderScale = (minRating, maxRating) => {
		const { ratingValue } = this.state;
		const { skinGuide } = this.props;
		const myStyles = getSkin(skinGuide);
		let scaleComponent = [];
		for (let i = minRating; i <= maxRating; i++) {
			let selectedStyles = {}
			if (ratingValue === i)
				selectedStyles = {
					backgroundColor: skinGuide.getIn(['skinHex', 'primaryColor']) || '#2c4bb7',
					color: skinGuide.getIn(['skinHex', 'white']) || 'white',
					fontWeight: 400
				}
			scaleComponent.push(
				<div
					key={`scale-${i}`}
					className={css(myStyles.ratingNumber)}
					styleName="individual-score"
					onClick={() => this.updateRating(i)}
					style={selectedStyles}
				>
					{i}
				</div>
			)
		}
		return scaleComponent;
	}

	render() {
		const { question } = this.props;
		const myStyles = getSkin(this.props.skinGuide);

		let scaleWidth = (question.get('max') - question.get('min') + 1) * 50 + 1;

		return (
			<div styleName="scale-rating-question-cnt">
				<div className={css(myStyles.questionText)} styleName="question-text">
					{question.get('text')}
				</div>
				<div styleName="rating-scale-cnt">
					<div styleName="rating-scale-inner-cnt" style={{ width: `${scaleWidth}px` }}>
						{this.renderScale(question.get('min'), question.get('max'))}
					</div>
					{/* {this.getMinMaxLabels()} */}
				</div>
			</div>
		);
	}
}

export default ScaleRating;
