import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './viewMoreComponent.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import RouteButtons from 'commonComponents/analytics/routeButton';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';

import ActivityLogo from 'images/analytics/activity.svg';
import PerformanceLogo from 'images/analytics/performance.svg';
import {checkIfPresent} from 'utils/utilFunctions';


@CSSModules(styles, { allowMultiple: true })

class ViewMoreComponent extends React.Component {

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props)){
            return false;
        }
        else{
            return true;
        }
    }

    onClickOfActivity = () => {
        const { batchId } = this.props.match.params;

        const route = routes.get('BATCH_ACTIVITY');
        const params = [
            {'BATCH_ID': batchId}
        ];
        
        updateRoute({route, params});
    }

    onClickOfPerformance = () => {
        const { batchId } = this.props.match.params;

        const route = routes.get('BATCH_PERFORMANCE');
        const params = [
            {'BATCH_ID': batchId}
        ];

        updateRoute({route, params});
    }

    

    render(){
        
        return(
            <div styleName="container-class">
                <LegendComponent 
                    title={this.props.strings.get('viewMoreKey')} 
                    {...this.props}/>
                <div styleName="outer-container">
                    <div styleName="outer-content">
                        <div styleName="button-container">
                            <RouteButtons
                                clickHandler = {this.onClickOfActivity}
                                buttonTitle = {this.props.strings.get('activityKey')} 
                                logo={ActivityLogo}
                                enabled={checkIfPresent(this.props.userState.get('showActivityPage')) ? this.props.userState.get('showActivityPage') : false}
                                {...this.props} />
                            <RouteButtons
                                clickHandler = {this.onClickOfPerformance}
                                buttonTitle = {this.props.strings.get('performanceKey')}
                                logo={PerformanceLogo}
                                enabled={checkIfPresent(this.props.userState.get('showPerformancePage')) ? this.props.userState.get('showPerformancePage') : false}
                                {...this.props} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
 

export default ViewMoreComponent;