import React, { Component } from 'react';
import styles from './sidebarFeedbackItem.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import LockIcon from 'svgComponents/lockIcon';
import CompletedFeedbackIcon from 'svgComponents/completedFeedbackIcon';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SidebarFeedbackItem extends Component {

	componentDidMount() {
		if (this.props.isFromFeedback && this.el) {
			this.el.scrollIntoView();
		}
	}

	render() {

		const myStyles = getSkin(this.props.skinGuide);
		const { isDisabled, onClickFunction, getLabelWithDefaults, isFromFeedback, feedbackStatus } = this.props;
		return (
			<div styleName="sidebar-feedback-item-cnt" ref={el => { this.el = el }}>
				<div styleName="sidebar-feedback-item"
					style={isDisabled === true ? { cursor: 'not-allowed' } : {}}
					onClick={isDisabled === true ? () => { } : onClickFunction}
					title={isDisabled === true ? getLabelWithDefaults('feedback_please_complete_phase_label', 'Please complete all the items') : ''}
				>
					<div styleName="feedback-icon-cnt">
						{
							!isDisabled ?
								(
									feedbackStatus && feedbackStatus.get('status') ?
										<CompletedFeedbackIcon />
										:
										<FeedbackIcon />
								)
								:
								<LockIcon svgColor={this.props.skinGuide.getIn(['skinHex', 'greyColor4']) || '#979eb7'} />
						}
					</div>
					{
						feedbackStatus && feedbackStatus.get('status') ?
							<div
								className={css(myStyles.likeToHearLabel)
								}
							>
								{this.props.getLabel(
									'thank_you_note_after_feedback'
								)}
							</div>
							:
							<div className={isFromFeedback ? css(myStyles.selectedLikeToHearLabel) : css(myStyles.likeToHearLabel)}>
								{
									getLabelWithDefaults(
										'like_to_hear_from_you_label',
										'We’d like to hear from you'
									)
								}
							</div>
					}
				</div>
			</div>
		);
	}
}

export default SidebarFeedbackItem;