import Immutable from 'immutable';
import environment from 'utils/environment';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';

var apiURL = '';
if (environment.REACT_APP_ENV === 'local') {
    apiURL = getApiUrl().pageUrl;
} else {
    apiURL = `${getDomainUrl()}/quizpages/api`;
}

const pageQuizUrls = () => {
    return Immutable.Map({
        GET_PAGE: `${apiURL}/page/PAGE_ID`,
        GET_QUIZ: `${apiURL}/quiz/QUIZ_ID`,
        GET_USER_QUIZ_RESPONSES: `${apiURL}/me/quiz/answer`,
        UPDATE_USER_QUIZ_RESPONSES: `${apiURL}/me/quiz/answer`,
        START_QUIZ: `${apiURL}/me/quiz/start`,
        SUBMIT_QUIZ: `${apiURL}/me/quiz/submit`,
        GET_USER_QUIZ_SUBMISSIONS: `${apiURL}/me/quiz/submissions`
    });

};

export default pageQuizUrls;
