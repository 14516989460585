import FetchUser from 'actions/serverCalls/users/FetchUser';
import GetUserOrganization from 'actions/serverCalls/home/GetUserOrganization';
import FetchLabels from 'actions/serverCalls/labels/FetchLabels';

const fetchOrganizationLabels = (organization) => {
    const lang = organization.lang;
    const _org_cache_version_ = organization._cache_version_;
    const labelsApiInfoObject = { areOrgLabels: true, queryParameters: { lang, orgId: organization.id, _org_cache_version_ } };
    return FetchLabels.call(labelsApiInfoObject)
}

const fetchAppBaseDataThunk = (dispatch, getState) => {
    return FetchUser.call()
        .then(() => GetUserOrganization.call())
        .then((organization) => {
            if(organization.lang && ([1057,1099,1521].includes(+organization.id)  || organization.lang !== 'en_US')){
                fetchOrganizationLabels(organization);
            }
            //TODO: [1057,1099].includes(+organization.id) needs to be removed. We should remove 
        });
}

const fetchAppBaseData = () => (dispatch, getState) => fetchAppBaseDataThunk(dispatch, getState)

const fetchUser = () => {
    FetchUser.call();
}

const fetchAppBaseDataNew = () => {
    return FetchUser.call().then(() => GetUserOrganization.call())
};

export {
    fetchAppBaseDataThunk,
    fetchAppBaseData,
    fetchUser,
    fetchAppBaseDataNew
}
