import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        container: {
            borderRadius: '4px',
            border: `solid 1px ${skin.get('greyColor5')}`
        },
        backgroundWhite: {
            backgroundColor: skin.get('white')
        }
    });
}

export default getSkin;