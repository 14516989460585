import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({    
        circleBackground:{
            stroke: 'rgba(197, 200, 216, 0.5)'
        },
        circleProgress:{
            stroke: skin.get('secondaryColor')
        }
    });
}

export default getSkin;