/**
* @author: sourabh p
* @since: Tue Nov 27 2018 15:15:46 GMT+0530 (IST)
* @file: BatchValidator.js
*
* @copyright: KNOLSKAPE Solutions Pvt Ltd
**/

import AppValidator from 'utils/validators/AppValidator';
import { resolvePromise, rejectPromise } from 'utils/promise.js';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import { filterObjectFromArray, getIndexOfValueInList } from 'utils/objectUtils';
import { getUserActiveBatchDetails } from 'utils/batchUtils';

class AssessmentItemValidator extends AppValidator {

    constructor() {
        super();
        this._showError = true;
    }

    // isItemBelongsToBatch = (itemsList, itemId) => {
    //     if (getIndexOfValueInList(itemsList, 'id', +itemId) === -1) {
    //         return rejectPromise({
    //             message: 'item doesn\'t belongs to phase'
    //         });
    //     }
    //     return resolvePromise();
    // }


    isItemAvailable = (phases, items, itemId, userActiveBatchDetails) => {

        return resolvePromise();

        const isAvailableData = setAvailabilityFlags(phases, items, userActiveBatchDetails);

        const itemsListAvailabilityFlag = isAvailableData.items;

        const thisItem = filterObjectFromArray(itemsListAvailabilityFlag.get('itemsList'), 'id', itemId);

        const itemsList = items.get('userItemsList');
        let isItemPresent = false;
        let isItemAvailable = false;

        itemsList.map((item) => {
            if (item.get('id') === itemId) {
                isItemPresent = true
                isItemAvailable = thisItem.get('isAvailable');
            }
        })

        if (isItemPresent && isItemAvailable) {
            return resolvePromise();
        } else {
            return rejectPromise({
                message: 'item not available'
            });
        }
    }

    postValidationLogic = (validationInfoObject) => {
        const itemId = +validationInfoObject.ids.itemId;
        const phases = this.getState().get('phases');
        const items = this.getState().get('items');

        const userActiveBatchDetails = getUserActiveBatchDetails(this.getState().get('batches'));

        return this.isItemAvailable(phases, items, itemId, userActiveBatchDetails)
    }

}

export default new AssessmentItemValidator;