import styles from './freshchatBtn.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import { useEffect, useState } from 'react';
import { getFullName, getUserEmail } from '../../util/user';
import { getOrgName } from '../../util/orgUtils';

const FreshChatBtn = ({ skinGuide, getLabel, activeBatchId, activeBatchDetails }) => {
  const [isFcWidgetInitialized, setIsFcWidgetInitialized] = useState(false);

  useEffect(() => {
    const fcWidgetInterval = setInterval(() => {
      console.log("Freshchat initializing")
      if(window.fcWidget) {
        initializeFcWidget();
        setIsFcWidgetInitialized(true);
        clearInterval(fcWidgetInterval);
      }
    }, 1000);

    return () => {
      clearInterval(fcWidgetInterval);

      if(window.fcWidget) {
        window.fcWidget.close();
      }
    }
  }, []);

  const initializeFcWidget = () => {
    const { firstName, lastName } = getFullName();
    const email = getUserEmail();
    const batchName = activeBatchDetails.get("name");
    const orgName = getOrgName();
    const courseName = activeBatchDetails.getIn(["journey", "name"]);

    window.fcWidget.setExternalId(email);
    window.fcWidget.user.setFirstName(firstName);
    window.fcWidget.user.setLastName(lastName);
    window.fcWidget.user.setEmail(email);
    window.fcWidget.user.setProperties({
        cf_batch_id: activeBatchId,
        cf_batch_name: batchName,
        cf_org_name: orgName,
        cf_course_name: courseName,
        cf_hyperlink: window.location.href
    });

  }

  const handleChatClick = () => {
    if(!isFcWidgetInitialized) {
      console.log("FreshChat not initialized");
    } else {
      if(window.fcWidget.isOpen()) {
        window.fcWidget.close();
      } else {
        window.fcWidget.open();
      }
    }
  }

  const myStyles = getSkin(skinGuide);

  return (
    <div styleName="freshchat-btn-cnt" onClick={handleChatClick} style={ isFcWidgetInitialized ? { cursor: "pointer" } : {} }>
      <div className={css(myStyles.freshchatBtnText)} style={ isFcWidgetInitialized ? { opacity: 1 } : { opacity: 0.5 } }>
        { getLabel("freshchat_label") }
      </div>
    </div>
  )
};

export default applySkin(applyLabel(CSSModules(FreshChatBtn, styles, { allowMultiple: true })));