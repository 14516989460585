import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './overallPerformanceWithInfo.module.sass';
import InfoAndDaysFilterComponent from 'commonComponents/analytics/infoAndDaysFilterComponent';
import InverselyProportional from 'commonComponents/graphs/inverselyProportional';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })
class OverallPerformanceWithInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterBy: ''
        };
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchOverallPerformanceData(+batchId, +userId);
    }

    handleOnClickFilter = (event, filter) => {
        this.setState({
            filterBy: filter
        });
    }

    renderInverseGraph = () => {
        const allGraphsData = this.props.allGraphsData.get('performance');
        if (allGraphsData.get('isOverallPerformanceFetched')) {

            const graphData = allGraphsData.get('overallPerformanceGraphData');
            const strings = graphData.get('strings');
            const filterBy = this.state.filterBy ? this.state.filterBy : graphData.get('filtersEnabledObj').get('selectedFilter').toLowerCase();

            let filteredGraphData;
            
            if(graphData.get('isUserDataAvailable')) {
                filteredGraphData = graphData.get(filterBy).toJS();
            } else {
                filteredGraphData = graphData.get('week').toJS();
            }

            let xLabel, timeFormat = '', valueName;
            if (filterBy === 'day') {
                xLabel = strings.get('days');
                valueName = strings.get('day');
            }
            else if (filterBy === 'week') {
                xLabel = strings.get('weeks');
                valueName = strings.get('week');
            }
            else if (filterBy === 'month') {
                xLabel = strings.get('months');
                valueName = strings.get('month');
            }

            const barStyling = {
                fromUser: 'rgba(254, 169, 40, 0.8)',
                toUser: 'rgba(254, 169, 40, 0.3)',
                userStroke: '#fea928',
                userRotation: 171,
                fromUserLegend: '#fea928',
                toUserLegend: '#fea928',
                // fromGroup: 'rgba(254, 169, 40, 0.8)',
                // toGroup: 'rgba(254, 169, 40, 0.3)',
                // groupStroke: '#fea928',
                groupRotation: 0,
                // fromGroupLegend: '#fea928',
                // toGroupLegend: '#fea928',
                fromRank: '#2de791',
                toRank: '#2de791',
                rankStroke: '#2de791',
                gridValue: this.props.gridValue,
                componentName: 'end-of-journey-overall-performance',
                leftOffset: 0,
                rightOffset: 0,
                ...analyticGroupColors
            };
            const graphFormat = {
                yLabel: strings.get('scoreLabel'),
                yLabelInverse: strings.get('rankLabel'),
                xLabel: xLabel,
                valueFormat: timeFormat,
                valuePrefix: '',
                valueName: valueName
            };

            return (
                <div styleName="title-content">
                    {strings.get('overAllPerformance')}
                </div>
            );

            // return(
            //     [<InfoAndDaysFilterComponent
            //         filterObject = {graphData.get('filtersEnabledObj')}
            //         {...this.props}
            //         title={strings.get('overAllPerformance')}
            //         subtitle={strings.get('graphDesc')}
            //         clickHandler={this.handleOnClickFilter}
            //         strings={strings}
            //         key="InfoAndDaysFilterComponent"
            //     />,
            //     <InverselyProportional
            //         isUserDataAvailable={graphData.get('isUserDataAvailable')}
            //         skinGuide={this.props.skinGuide}
            //         barStyling={barStyling}
            //         graphFormat = {graphFormat}
            //         // data={this.props.data.inverselyProportionalData}
            //         size={this.props.size}
            //         graphData={filteredGraphData}
            //         showRank={false}
            //         strings={strings.toJS()}
            //         key="InverselyProportional"
            //     />]
            // )
        }
        return <LoaderComponent />;
    }

    render(){
        if(this.props.isAssessmentOnlyJourney){
            return <span></span>;
        }
        return this.renderInverseGraph();
    }
}


export default OverallPerformanceWithInfo;