import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setDevelopmentTimeSpentData } from 'actions/analytics/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchDevelopmentTimeSpentData extends NetPackWrapper {

    constructor() {
        super("GET");        
	}

    prepareUrl = (apiInfoObject) => {        
        return urls.get('ANALYTICS').get('GET_DEVELOPMENT_TIME_SPENT_DATA')
        .replace('BATCH_ID', apiInfoObject.batchId)
        .replace('USER_ID', '' + apiInfoObject.userId)
        .replace('PHASE_ID', '' + apiInfoObject.phaseId)
    }

    successCall = (response) => {
        this.dispatch(setDevelopmentTimeSpentData(response));
        this.dispatch(setApiError('fetchDevelopmentTimeSpentDataError', null));        return response;
    }

    onError = (errorObj) => {
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchDevelopmentTimeSpentDataError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchDevelopmentTimeSpentData();
