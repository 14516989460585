import React from 'react';
import Loadable from 'react-loadable';
import AppLoader from 'commonComponents/appLoader';

const NullLoader = () => null;
const DefaultLoader = () => <AppLoader/>;

const defaultLoadableObject = {
    loading: DefaultLoader
};
/* const AsyncTestContainer = Loadable({
    ...defaultLoadableObject,
    loader: () => import('containers/TestContainer'),
}); */

/* const AsyncBatchContainer = Loadable({
    ...defaultLoadableObject,
    loader: () => import('containers/batch/BatchContainer'),
});

const AsyncAnalyticsContainer = Loadable({
    ...defaultLoadableObject,
    loader: () => import('containers/AnalyticsContainer'),
});

const AsyncAnalyticsActivityContainer = Loadable({
    ...defaultLoadableObject,
    loader: () => import('containers/ActivityContainer'),
});

const AsyncAnalyticsPerformanceContainer = Loadable({
    ...defaultLoadableObject,
    loader: () => import('containers/PerformanceContainer'),
});

const AsyncUserProfileContainer = Loadable({
    ...defaultLoadableObject,
    loader: () => import('containers/UserProfileContainer')
}) */

const DocumentViewer = Loadable({
    ...defaultLoadableObject,
    loader: () => import('commonComponents/contentPlayers/documentViewer')
});

/* const DigitalToolContainer = Loadable({
    ...defaultLoadableObject,
    loader: () => import('containers/DigitalToolsContainer')
}); */

 
const fetchOtherBundles = (timeout = 3000) => {
    //[Todo] change the below logic to load only necessary bundles based on current route
    setTimeout(() => {
        Loadable.preloadAll()
    }, timeout);
}

export {
    fetchOtherBundles,
    //AsyncTestContainer,
    /* AsyncBatchContainer,
    AsyncAnalyticsContainer,
    AsyncAnalyticsActivityContainer,
    AsyncAnalyticsPerformanceContainer, */
    DocumentViewer,
   /*  DigitalToolContainer,
    AsyncUserProfileContainer */
}