import Immutable from 'immutable';
import environment from 'utils/environment';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';

var apiURL = '';
var measuresUrl = '';
var openApiURL = ''
var externalURL = '';
if (environment.REACT_APP_ENV === 'local') {
    apiURL = getApiUrl().journeyUrl;
    measuresUrl = getApiUrl().journeyUrl;
    openApiURL = getApiUrl().organizationBaseUrl;
    externalURL = getApiUrl().journeyExternalUrl;
} else {
    apiURL = getDomainUrl() + '/journey/api';
    measuresUrl = apiURL;
    openApiURL = getDomainUrl() + '/journey/oapi'
    externalURL = getDomainUrl() + '/external/journey/api';
}

var journeyMockURL = 'https://giydeojshj3gk4ttnfxw4lzrfyydu2tpovzg4zlzfv2s44dsnfzw2ltznvwa.prism.stoplight.io';


const journeyUrls = () => {
    return Immutable.Map({

        //updated APIs
        GET_LABELS: `${apiURL}/labels`,
        GET_SKIN: `${apiURL}/skin/SKIN_ID`,

        GET_ORGANIZATION_LEVEL_USER_STATS: `${apiURL}/me/stats`,
        GET_USER_ORGANIZATION: `${apiURL}/me/organization`,
        GET_ORGANIZATION: `${openApiURL}/organizations`,

        GET_USER_JOURNEY_INSTANCES: `${openApiURL}/journey-instances`,
        GET_JOURNEY_INSTANCE_DETAILS: `${openApiURL}/journey-instance/JOURNEY_INSTANCE_ID`,
        REQUEST_JOURNEY_ACCESS: `${apiURL}/me/journey-instance/request`,

        GET_JOURNEY_DETAILS: `${openApiURL}/journey/JOURNEY_ID`,
        GET_CORP_DECK_JOURNEYS: `${apiURL}/me/journeys`,
        SHOW_JOURNEY_INTEREST: `${apiURL}/me/journey/interested`,
        REMOVE_JOURNEY_INTEREST: `${apiURL}/me/journey/interested`,

        JOURNEY_CATEGORIES: `${openApiURL}/categories`,
        JOURNEY_TAGS: `${openApiURL}/tags`,
        JOURNEY_FORMATS: `${openApiURL}/formats`,

        GET_USER_ACTIVE_BATCH: `${apiURL}/me/batch`,
        GET_USER_BATCHES: `${apiURL}/me/batches`,
        GET_BATCHES: `${apiURL}/batches`,
        GET_BATCH_DETAILS: `${apiURL}/batch/BATCH_ID`,
        SET_ACTIVE_BATCH: `${apiURL}/me/batch/BATCH_ID/set-active`,
        SET_BATCH_WORKFLOW_STATUS: `${apiURL}/me/batch/BATCH_ID/workflow-status`,
        GET_USER_BATCH_DETAILS: `${apiURL}/me/batch/BATCH_ID`,
        RESET_BATCH_PROGRESS_FOR_USER: `${apiURL}/reset/BATCH_ID/user/USER_ID`,

        GET_USER_PHASES: `${apiURL}/me/phases`,
        GET_PHASES: `${apiURL}/phases`,
        GET_PHASE_DETAILS: `${apiURL}/phase/PHASE_ID`,
        GET_USER_PHASE_DETAILS: `${apiURL}/me/phase/PHASE_ID`,
        SET_PHASE_WORKFLOW_STATUS: `${apiURL}/me/phase/PHASE_ID/workflow-status`,

        GET_USER_ACTIVE_ITEM: `${apiURL}/me/item`,
        GET_USER_ITEMS: `${apiURL}/me/items`,
        GET_ITEMS: `${apiURL}/items`,
        GET_ITEM_DETAILS: `${apiURL}/item/ITEM_ID`,
        GET_ITEM_METRICS_DETAILS: `${apiURL}/item/ITEM_ID/measures`,
        SET_ITEM_WORKFLOW_STATUS: `${apiURL}/me/item/ITEM_ID/workflow-status`,

        GET_PRODUCT_LAUNCH_DETAILS: `${apiURL}/item/ITEM_ID/launch-url`,
        IFRAME_CLOSE_URL: `${apiURL}/item/iframe-close`,

        GET_MEASURES: `${measuresUrl}/measures`,
        GET_USER_MEASURES: `${measuresUrl}/me/measures`,

        GET_BATCH_EXISTENCE_STATUS: `${externalURL}/batch/BATCH_ID/exists`,
        GET_BATCH_BASIC_DETAILS: `${externalURL}/batch/BATCH_ID/basic-details`,

        SAVE_SYSTEM_CHECK_RESULTS: `${apiURL}/system-check-results`,
        
        
        GET_USER_ORGANIZATIONS_LIST: `${apiURL}/me/organizations`,
        SET_ACTIVE_ORGANIZATION: `${apiURL}/me/organization/ORG_ID/set-active`,

        GET_USER_WHITELISTING_REQUIREMENTS: `${apiURL}/me/whitelisting-requirements`,
        GET_BATCH_FEEDBACK_STATUS: `${apiURL}/me/batch/BATCH_ID/checkfeedbackcompletion`,
        GET_BATCHES_FEEDBACK_STATUS_FLAGS: `${apiURL}/me/feedbackCompletionStatus`,

        SAVE_ITEM_PROGRESS: `${openApiURL}/submit`,
        GET_ITEM_PROGRESS: `${apiURL}/me/item/ITEM_ID/user-progress`,
        JOURNEY_SYNC_USER: `${apiURL}/me`,
    });

};

export default journeyUrls;