import React, { Component } from 'react';
import styles from './dialog.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import CircularLoader from 'svgComponents/circularLoader';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class Dialog extends Component {

    onClickOfOkay = () => {
        let { okAction } = this.props;
        if (okAction) {
            okAction();
        }
    }

    onClickOfCancel = () => {
        let { cancelAction } = this.props;
        if (cancelAction) {
            cancelAction();
        }
    }

    render() {
        let { dialogMessage, okButtonText, okAction, cancelButtonText, cancelAction, skinGuide, isComponentLoading, getLabelWithDefaults } = this.props;
        let styles = getSkin(skinGuide);

        let msgToDisplay = dialogMessage && dialogMessage != "" ? dialogMessage : getLabelWithDefaults('exit_confirmation_message','Are you sure you want to exit?');
        let okButtonTextToDisplay = okButtonText && okButtonText != "" ? okButtonText : 
        getLabelWithDefaults('exit_confirmation_yes_label','YES, EXIT');
        let cancelButtonTextToDisplay = cancelButtonText && cancelButtonText != "" ? cancelButtonText :    getLabelWithDefaults('cancel_label','Cancel');

        return (
            <div styleName="dialog-container">
                <div styleName="dialog-overlay"
                    onClick={(e) => {
                        e.stopPropagation();
                        this.onClickOfCancel();
                    }
                    }
                ></div>

                <div styleName="dialog-content-cnt">
                    {
                        isComponentLoading ?
                            <div styleName="dialog-message-container">
                                <div style={{ height: '43px', width: '43px', margin: 'auto' }}><CircularLoader /></div>
                            </div>
                            :
                            <div
                                className={css(styles.dialogText)}
                                styleName="dialog-message-container"
                            >
                                <span dangerouslySetInnerHTML={{ __html: msgToDisplay }}/>
                            </div>
                    }
                    {
                        (okAction != null || cancelAction != null) &&
                        <div styleName="action-buttons-cnt">
                            {
                                okAction != null &&
                                <div className={css(styles.okayButton)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.onClickOfOkay();
                                    }
                                    }
                                >
                                    {okButtonTextToDisplay}
                                </div>
                            }
                            {
                                cancelAction != null &&
                                <div
                                    className={css(styles.cancelButton)}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.onClickOfCancel();
                                    }
                                    }
                                >
                                    {cancelButtonTextToDisplay}
                                </div>
                            }
                        </div>
                    }
                    {
                        isComponentLoading &&
                        <div styleName="loading-msg-cnt">
                            <div
                                className={css(styles.loadingText)}
                                dangerouslySetInnerHTML={{ __html: msgToDisplay }}>
                            </div>
                        </div>
                    }
                </div>
            </div>

        );
    }
}

export default Dialog;
