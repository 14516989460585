import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setBatchLeaderboard, setLeaderboardFetchingFlag } from 'actions/msTeams/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
//import { setFetchingDataFlag, setAPIError } from 'actions/actionCreators';

class FetchMSTeamsLeaderboard extends NetPackWrapper {

    constructor() {
        super("GET", true);
    }

    prepareUrl = (apiInfoObject) => appendQueryParameters(
        urls.get('ANALYTICS').get('GET_MS_TEAMS_LEADERBOARD_DATA'),
        apiInfoObject.queryParameters
    )

    beforeCall = (apiInfoObject) => {
        this.dispatch(setLeaderboardFetchingFlag({ isLeaderboardDataFetching: true }));
        return apiInfoObject;
    }

    successCall = (response, apiInfoObject) => {
        response = {
            batchId: apiInfoObject.queryParameters.batchId,
            journeyName: apiInfoObject.queryParameters.journeyName,
            ...response
        }
        this.dispatch(setBatchLeaderboard(response));
        return response;
    }

    onError = (error) => {
        /* this.dispatch(setAPIError(
            'fetchLeaderboardError',
            'There was a problem while fetching leaderboard.'
        )); */
        return error;
    }
}

export default new FetchMSTeamsLeaderboard();
