import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {setQuizQuestions, prefillPreviousAnsweredQuestions, setQuizQuestionsLoading} from 'actions/quiz/actionCreators'
import {setApiError} from 'actions/apiErrors/actionCreators';


class GetQuizQuestions extends NetPackWrapper {
	constructor() {
		super('GET', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('QUIZ_SUBMISSION_QUESTIONS')
		.replace('_QUIZ_SUBMISSION_ID_', apiInfoObject.submissionId)
		.replace('_ACCESS_TOKEN_',this.getAccessTokenForDevPhase())
		.replace('_BATCH_ID_', apiInfoObject.batchId)
		.replace('_PHASE_ID_', apiInfoObject.phaseId)
		.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	};

	successCall = (response, apiInfoObject) => {
		this.dispatch(prefillPreviousAnsweredQuestions(response));
		this.dispatch(setQuizQuestions(response));
		this.dispatch(setQuizQuestionsLoading(false))
	}

	onError = (error) => {
		this.dispatch(setApiError(
            'fetchQuizQuestionsError',
            'There was a problem while fetching quiz questions.'
        ));
	}
}

export default new GetQuizQuestions();