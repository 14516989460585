import actionConstants from 'constants/actions';

export const pushAttachmentsToList = (list) => ({
	type: actionConstants.get('PUSH_TO_ATTACHMENTS_LIST'),
    list
});

export const updateAttachmentInList = (attachmentDetails) => ({
    type: actionConstants.get('UPDATE_ATTACHMENT_IN_LIST'),
    attachmentDetails
})