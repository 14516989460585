import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './timeSpentComponent.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent';
import Heatmap from 'commonComponents/graphs/heatmap';
import PieGraph from 'commonComponents/graphs/pieGraph';
import HeatmapWithInfoComponent from './heatMapWithInfo';
import PieChartWithInfoComponent from './pieChartWithInfoComponent';


@CSSModules(styles, { allowMultiple: true })

class TimeSpentComponent extends React.Component {

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props)){
            return false;
        }
        else{
            return true;
        }
    }

    render(){
            
        return(
            <div styleName="container-class">
                <LegendComponent title={this.props.strings.get('timeSpentKey')} {...this.props}/>
                <div styleName="outer-graph-container">
                    <div styleName="graph-container">
                        <div styleName="graph-content">
                            <HeatmapWithInfoComponent {...this.props}/>
                            <PieChartWithInfoComponent {...this.props}/>
                        </div>
                    </div> 
                </div>         
            </div>
        )
    }
}


export default TimeSpentComponent;