import Immutable from 'immutable';

export default Immutable.fromJS({
	"courses": [
		{
			"account_id": 29,
			"course_type": "regular",
			"cover_image_url": "https://aktivlearndocuments.blob.core.windows.net/public/default_cover_images/v2/course_bg_default.png",
			"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
			"due_at": "2016-10-08T12:18:00Z",
			"end_at": null,
			"id": 75,
			"batchId": 1,
			"phaseId": 2,
			"order": 1,
			"toolId": 0,
			"prerequisites": [],
			"type": "GAME",
			"typeName": "Game",
			"intro_page": {},
			"is_byte_sized": false,
			"locked_for_user": false,
			"modules": [
				{
					"completed_at": null,
					"course_id": 75,
					"course_name": "KNOLSKAPE On-Boarding",
					"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
					"due_at": null,
					"id": 226,
					"is_byte_sized": false,
					"items_completed_count": 0,
					"items_count": 4,
					"items_url": "https://www.aktivlearn.com/api/v1/courses/75/modules/226/items",
					"name": "Welcome to KNOLSKAPE",
					"position": 1,
					"prerequisite_module_ids": [],
					"require_sequential_progress": false,
					"required_items_count": 4,
					"state": "unlocked",
					"unlock_at": null
				},
				{
					"completed_at": null,
					"course_id": 75,
					"course_name": "KNOLSKAPE On-Boarding",
					"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
					"due_at": null,
					"id": 227,
					"is_byte_sized": false,
					"items_completed_count": 0,
					"items_count": 1,
					"items_url": "https://www.aktivlearn.com/api/v1/courses/75/modules/227/items",
					"name": "Quiz",
					"position": 2,
					"prerequisite_module_ids": [],
					"publish_final_grade": false,
					"require_sequential_progress": false,
					"required_items_count": 1,
					"state": "unlocked",
					"unlock_at": null
				},
				{
					"completed_at": null,
					"course_id": 75,
					"course_name": "KNOLSKAPE On-Boarding",
					"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
					"due_at": null,
					"id": 228,
					"is_byte_sized": false,
					"items_completed_count": 0,
					"items_count": 3,
					"items_url": "https://www.aktivlearn.com/api/v1/courses/75/modules/228/items",
					"name": "Meet the Team",
					"position": 3,
					"prerequisite_module_ids": [],
					"publish_final_grade": false,
					"require_sequential_progress": false,
					"required_items_count": 3,
					"state": "unlocked",
					"unlock_at": null
				},
				{
					"completed_at": null,
					"course_id": 75,
					"course_name": "KNOLSKAPE On-Boarding",
					"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
					"due_at": null,
					"id": 230,
					"is_byte_sized": false,
					"items_completed_count": 0,
					"items_count": 2,
					"items_url": "https://www.aktivlearn.com/api/v1/courses/75/modules/230/items",
					"name": "What we do?",
					"position": 4,
					"prerequisite_module_ids": [],
					"publish_final_grade": false,
					"require_sequential_progress": false,
					"required_items_count": 2,
					"state": "unlocked",
					"unlock_at": null
				},
				{
					"completed_at": null,
					"course_id": 75,
					"course_name": "KNOLSKAPE On-Boarding",
					"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
					"due_at": null,
					"id": 253,
					"is_byte_sized": false,
					"items_completed_count": 0,
					"items_count": 5,
					"items_url": "https://www.aktivlearn.com/api/v1/courses/75/modules/253/items",
					"name": "Simulation Demos",
					"position": 5,
					"prerequisite_module_ids": [],
					"publish_final_grade": false,
					"require_sequential_progress": false,
					"required_items_count": 5,
					"state": "unlocked",
					"unlock_at": null
				},
				{
					"completed_at": null,
					"course_id": 75,
					"course_name": "KNOLSKAPE On-Boarding",
					"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
					"due_at": null,
					"id": 2025,
					"is_byte_sized": false,
					"items_completed_count": 0,
					"items_count": 1,
					"items_url": "https://www.aktivlearn.com/api/v1/courses/75/modules/2025/items",
					"name": "Mobile Learning",
					"position": 7,
					"prerequisite_module_ids": [],
					"publish_final_grade": false,
					"require_sequential_progress": false,
					"required_items_count": 1,
					"state": "unlocked",
					"unlock_at": null
				},
				{
					"completed_at": "2018-08-31T05:42:25Z",
					"course_id": 75,
					"course_name": "KNOLSKAPE On-Boarding",
					"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
					"due_at": null,
					"id": 2026,
					"is_byte_sized": false,
					"items_completed_count": 0,
					"items_count": 0,
					"items_url": "https://www.aktivlearn.com/api/v1/courses/75/modules/2026/items",
					"name": "Assesments",
					"position": 8,
					"prerequisite_module_ids": [],
					"publish_final_grade": false,
					"require_sequential_progress": false,
					"required_items_count": 0,
					"state": "completed",
					"unlock_at": null
				},
				{
					"completed_at": null,
					"course_id": 75,
					"course_name": "KNOLSKAPE On-Boarding",
					"description": "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
					"due_at": null,
					"id": 233,
					"is_byte_sized": false,
					"items_completed_count": 0,
					"items_count": 1,
					"items_url": "https://www.aktivlearn.com/api/v1/courses/75/modules/233/items",
					"name": "Feedback",
					"position": 10,
					"prerequisite_module_ids": [
						232
					],
					"publish_final_grade": false,
					"require_sequential_progress": false,
					"required_items_count": 1,
					"state": "unlocked",
					"unlock_at": null
				}
			],
			"modules_completed_count": 3,
			"name": "KNOLSKAPE On-Boarding",
			"prerequisite_course_ids": [],
			"required_modules_count": 5,
			"start_at": "2016-09-08T12:18:00Z",
			"workflow_state": "available"
		}
	],
	"journey_completed_count": 0,
	"journey_id": 1439,
	"journey_name": "KNOLSKAPE FTM",
	"required_journey_count": 1
});