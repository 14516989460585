import React, { Component } from 'react';
import styles from './simulationsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

// @applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SimulationsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="icon-cnt">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
					<g fill="none" fillRule="evenodd" stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round">
						<path d="M14.708 7.708a2.333 2.333 0 0 0-2.333-2.333h-8.75a2.333 2.333 0 0 0-2.333 2.333v6.078a.925.925 0 0 0 .948.922c1.073-.014.615.214 2.62-3.213.313-.538.89-.87 1.512-.87h3.255a1.75 1.75 0 0 1 1.51.864c1.959 3.36 1.53 3.215 2.615 3.22a.949.949 0 0 0 .956-.946V7.708zM4.208 7.125v2.333M5.375 8.292H3.042M6.833 9.458h.584M8.583 9.458h.584M9.167 4.792a.583.583 0 0 0-.584-.584H7.417a.583.583 0 0 0-.584.584v.583h2.334v-.583z" />
						<path d="M8 4.208V2.75a1.462 1.462 0 0 0-1.458-1.458H3.917M11.792 6.979c.08 0 .146.065.146.145M11.646 7.125c0-.08.065-.146.146-.146M11.792 7.27a.146.146 0 0 1-.146-.146M11.938 7.125c0 .08-.066.146-.146.146M10.625 8.145c.08 0 .146.066.146.146M10.48 8.292c0-.08.064-.146.145-.146M10.625 8.437a.146.146 0 0 1-.146-.146M10.77 8.292c0 .08-.064.146-.145.146M11.792 9.312c.08 0 .146.065.146.146M11.646 9.458c0-.08.065-.145.146-.145M11.792 9.604a.146.146 0 0 1-.146-.146M11.938 9.458c0 .08-.066.146-.146.146M12.958 8.145c.08 0 .146.066.146.146M12.812 8.292c0-.08.065-.146.146-.146M12.958 8.437a.146.146 0 0 1-.146-.146M13.104 8.292c0 .08-.066.146-.146.146" />
					</g>
				</svg>
			</div>
		);
	}
}

export default SimulationsIcon;
