import React, { Component } from 'react';
import styles from './toolItemListPopup.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import CloseCircle from 'svgComponents/closeCircle';
import ColorPaletteIcon from 'svgComponents/colorPaletteIcon';
import uuidv4 from 'uuid/v4'
import { hexToRgbA } from 'utils/styleUtil';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ToolItemListPopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			currentInputItem: ''
		}
		this.sortedItemsList = [];
		this.listEnd = React.createRef();
	}

	componentDidMount() {
		if (this.props.uuid)
			this[`item_${this.props.uuid}`].focus();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.userData && this.props.userData && Object.keys(prevProps.itemsList).length !== Object.keys(this.props.itemsList).length)
			this.scrollToBottom()
	}

	preventDefault = (e) => {
		e.preventDefault();
		e.stopPropagation();
	}

	scrollToBottom = () => {
		this.listEnd.current.scrollTop = this.listEnd.current.scrollHeight;
	}

	addValueToItemList = (event) => {
		let tempUUID = uuidv4();
		if (event.keyCode === 13 && this.state.currentInputItem !== '') {
			let newOrder = 1;
			if (this.sortedItemsList.length) {
				newOrder = this.sortedItemsList[this.sortedItemsList.length - 1].order + 1
			}

			let itemsList = {
				...this.props.userData,
				[`${tempUUID}`]: {
					text: this.state.currentInputItem,
					uuid: tempUUID,
					order: newOrder
				}
			}
			this.props.addNewUserDataRecord(itemsList, this.props.type)
			this.setState({
				currentInputItem: ''
			});
		}
	}

	updateValueInItemList = (uuid, order, event) => {
		if (event.keyCode === 13) {
			let itemsList = {
				...this.props.userData,
				[`${uuid}`]: { text: event.target.value, uuid: uuid, order: order }
			}
			this.props.addNewUserDataRecord(itemsList, this.props.type);
			this.newItemInput.focus();
		}
	}

	handleChange = (event) => {
		this.setState({
			currentInputItem: event.target.value
		})
	}

	getItemListHeaderText = (type) => {
		switch (type) {
			case "thinkAndFeel":
				return "Think and Feel"
			case "hear":
				return "Hear"
			case "see":
				return "See"
			case "sayAndDo":
				return "Say and Do"
			case "pain":
				return "Pain"
			case "gain":
				return "Gain"
			default:
				return ""
		}
	}

	getColorCircles = () => {
		const { colorPalette, type, setColorPalette } = this.props;
		let colorCircleComponent = [];
		for (let combo in colorPalette) {
			colorCircleComponent.push(
				<div
					key={`combo_name_${combo}`}
					styleName="color-circle"
					style={{
						backgroundColor: colorPalette[combo].cardBackground || '#ffffff'
					}}
					onClick={() => setColorPalette(type, combo)}
				>
				</div>
			)
		}
		return colorCircleComponent;
	}

	renderItems = (list) => {
		if (list.length < 1)
			return [];

		const myStyles = getSkin(this.props.skinGuide);
		const {
			type,
			deleteRecord,
			selectedColorPalette,
			colorPalette
		} = this.props;
		let textColor = 'rgba(0, 0, 0, 0.7 )';
		if (selectedColorPalette && colorPalette[selectedColorPalette] && colorPalette[selectedColorPalette].textColor) {
			textColor = colorPalette[selectedColorPalette].textColor
		}
		return list.map((item) => {
			return (
				<div
					key={`item_${item.uuid}`}
					styleName="individual-item"
				>
					<input
						type="text"
						defaultValue={item.text}
						className={css(myStyles.itemText)}
						styleName="individual-input-box"
						onKeyDown={(e) => this.updateValueInItemList(item.uuid, item.order, e)}
						style={{ color: textColor }}
						ref={(input) => { this[`item_${item.uuid}`] = input }}
					/>
					<div
						styleName="close-btn-cnt"
						onClick={() => deleteRecord(item.uuid, type)}
					>
						<CloseCircle
							circleFill="black"
							circleFillOpacity={0.8}
							strokeColor="white"
							strokeWidth={3}
						/>
					</div>
					<div
						className={css(myStyles.individualNote)}
						styleName="individual-note"
						style={{ color: textColor }}
					>
						Press Enter to Submit
					</div>
				</div>
			)
		})
	}

	itemsList = () => {
		const { itemsList } = this.props;
		let listComponent = [];
		let itemsListArray = itemsList ? Object.values(itemsList) : [];
		let itemsListWithoutOrder = itemsListArray.filter(item => !item.order);
		this.sortedItemsList = itemsListArray.filter(item => item.order);

		this.sortedItemsList.sort((a, b) => a.order - b.order);

		listComponent.push(this.renderItems(itemsListWithoutOrder));
		listComponent.push(this.renderItems(this.sortedItemsList));

		return listComponent;
	}

	render() {
		const { type, selectedColorPalette, colorPalette } = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		let cardBackgroundColor = '#ffffff';
		let headerBackgroundColor = '';
		if (selectedColorPalette && colorPalette[selectedColorPalette]) {
			if (colorPalette[selectedColorPalette].cardBackground)
				cardBackgroundColor = colorPalette[selectedColorPalette].cardBackground;
			if (colorPalette[selectedColorPalette].headerBackground)
				headerBackgroundColor = colorPalette[selectedColorPalette].headerBackground
		}
		return (
			<div styleName="tool-items-outer-cnt">
				<div
					styleName="tool-items-cnt"
					style={{ backgroundColor: cardBackgroundColor }}
				>
					<div
						styleName="item-list-header"
						className={css(myStyles.headerText)}
						style={
							headerBackgroundColor ?
								{ backgroundColor: headerBackgroundColor }
								:
								{ backgroundImage: 'linear-gradient(95deg, #477bc0, #4a53ae)' }
						}
						onClick={this.preventDefault}
					>
						<div styleName="header-text">
							{this.getItemListHeaderText(type)}
						</div>
						<div styleName="color-palette-cnt">
							{this.getColorCircles()}
							<div styleName="palette-icon-cnt">
								<ColorPaletteIcon fillColor={hexToRgbA(cardBackgroundColor, 0.5)} />
							</div>
						</div>
					</div>
					<div
						styleName="items-list-scrollable-cnt"
						ref={this.listEnd}
						onClick={this.preventDefault}
					>
						{this.itemsList()}
					</div>
					<div styleName="input-box-cnt">
						<input
							type="text"
							ref={(input) => { this.newItemInput = input }}
							styleName="input-box"
							placeholder="Type here"
							value={this.state.currentInputItem}
							className={css(myStyles.inputText)}
							onChange={this.handleChange}
							onKeyDown={this.addValueToItemList}
						/>
						<div
							className={css(myStyles.currenInputNote)}
							styleName="current-input-note"
						>
							Press Enter to Submit
						</div>
					</div>
					<div styleName="close-popup-btn-cnt" onClick={this.props.closeItemListPopup}>
						<CloseCircle
							circleFill="white"
							circleFillOpacity={1}
							strokeColor="#4a53ae"
							strokeWidth={3}
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default ToolItemListPopup;