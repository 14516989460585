import React from 'react';
import { PropTypes } from 'prop-types';
import { Line, LinePath } from '@vx/shape';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { LinearGradient } from '@vx/gradient';
import { max, range } from 'd3-array';
import GradientGrid from '../gradientGrid';
import { Text } from '@vx/text';
import { GlyphDot } from '@vx/glyph';
import {textTruncate, sortObjectArrayBasedOnKey} from 'utils/utilFunctions';
import { getGradientBounds, getRoundedRect, getFlexDirectionForGraphLegend } from 'utils/analytics/utilFunctions';
import { withTooltip, Tooltip } from "@vx/tooltip";
import CSSModules from 'react-css-modules';
import styles from './overlappingBars.module.sass';
import GraphLegendComponent from '../../analytics/graphLegendComponent';
import barChartResponsive from 'utils/analytics/responsiveness/barChart';
import barChartTransform from 'utils/analytics/transformation/barChart';
import InfoComponent from 'commonComponents/analytics/infoComponent';
import * as Curve from '@vx/curve';
import { localPoint } from "@vx/event";
import { checkMetadata } from 'utils/analytics/utilFunctions';

@CSSModules(styles, { allowMultiple: true })
class OverlappingBars extends React.Component {

	height = 'height' in this.props ? this.props.height : 500;
	margin = { top: 0, bottom: 60, left: 60, right: 0 };

	getId = d => d.id;
	getUserData = d => d.userValue;
	getGroupData = d => d.groupValue;
	getUserCompletionRate = d => d.completedUsersCount;
	getItemName = d => d.itemName;

	constructor(props) {
        super(props);
		this.handleTooltip = this.handleTooltip.bind(this);
		this.state = {
			showLabelTooltip: false,
			labelTooltipData: null
		}
		if (this.props.legendPosition === 'right') {
			this.margin.right = 30;
		}
	}

	getData = () => {
		let data = this.props.graphData ? this.props.graphData : this.props.data;
		let metadata, strings;
        if (this.props.hasAverageLines || this.props.graphData) {
			metadata = 'metadata' in data ? data.metadata : {};
			strings = this.props.strings;
            data = sortObjectArrayBasedOnKey(data.data, 'order');
		}
		
		const responsivePoints = barChartResponsive(this.getWidth()).points;
        if (data.length > responsivePoints) {
			// data = barChartTransform(data, metadata, responsivePoints);
        }
		else {
		}
        return {
			data: data,
			metadata: metadata,
			strings: strings
		};
	};
	getOffset = () => {
		const { hasAverageLines } = this.props;
		const minLeftOffset = this.props.hasAverageLinesLegend ? 0 : 0;
		const minRightOffset = this.props.hasAverageLinesLegend ? 0 : hasAverageLines ? 50 : 0;
		let leftOffset = this.props.barStyling.leftOffset ? this.props.barStyling.leftOffset : minLeftOffset;
		let rightOffset = this.props.barStyling.rightOffset ? this.props.barStyling.rightOffset : minRightOffset;
		const data = this.getData().data;
		const responsivePoints = barChartResponsive(this.getWidth()).points;
        if (data.length > responsivePoints) {
            // data = barChartTransform(data, metadata, responsivePoints);
		}
		else {
			const width = this.getWidth() - this.margin.left - this.margin.right;
			const graphWidth = data.length/responsivePoints * width + 0.5 * data.length;
			const offset = width - graphWidth;
			leftOffset = Math.max(leftOffset, offset/2);
			rightOffset = Math.max(rightOffset, offset/2);
		}
		return {
			leftOffset: leftOffset,
			rightOffset: rightOffset
		}
	}

    handleTooltip = (event, data, xScale, yScale) => {
        const { showTooltip } = this.props;

		const yMaxValue = this.getYMaxValue();
        const tooltipLeft = xScale(this.getId(data)) + data.barWidth * (0.75);
		const tooltipTop = yScale(yMaxValue);
        const tooltipData = {
            ...data
		};
		
        if(tooltipData) {
            showTooltip({
                tooltipLeft: tooltipLeft,
                tooltipTop: tooltipTop,
                tooltipData: tooltipData
            });
        }
    }
	
	drawLine = (from, to, text, style, isAverageLine, areOverlapping=false) => {
		const strokeDasharray = isAverageLine ? "" : "4,2";
		const verticalAnchor = areOverlapping ? ( isAverageLine ? "start" : "end" ) : "middle";
		if (this.props.hasAverageLinesLegend) {
			return (
				<Group>
					<Line
						from={from}
						to={to}
						strokeDasharray={strokeDasharray}
						stroke={style.lineColor}
						strokeWidth={2}
					/>
				</Group>
			);
		}
		return (
			<Group>
				<Line
					from={from}
					to={to}
					strokeDasharray={strokeDasharray}
					stroke={style.lineColor}
					strokeWidth={2}
				/>
				<Text
					x={ to.x }
					y={ to.y }
					textAnchor="start"
					verticalAnchor={verticalAnchor}
					fontSize={12}
					fontFamily={style.fontFamily}
					fill={style.textColor}
				>
				{text}
				</Text>
			</Group>
		);
	}

	getWidth = () => {
        const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
        let width = calcWidth < 250 ? 250: calcWidth;
        if (this.props.legendPosition == 'left' || this.props.legendPosition == 'right') {
            width = width * 0.75;
        }
        return width;
    }

	getXMax = () => {
        const {leftOffset, rightOffset} = this.getOffset();
		return (this.getWidth() - this.margin.left - this.margin.right - rightOffset - leftOffset);
	};
    getYMax = () => {
        let height = this.height;
        if (this.props.legendPosition == 'top' || this.props.legendPosition == 'bottom')
            height = this.height * 0.95;
        return height - this.margin.top - this.margin.bottom;
    };

	getXScale = () => {
		const data = this.getData().data;
		return (scaleBand({
			rangeRound: [0, this.getXMax()],
			domain: data.map(this.getId),
			padding: 0.5,
		}));
	};

	getYScale = () => {
        const yMaxValue = this.getYMaxValue();
		return scaleLinear({
			rangeRound: [this.getYMax(), 0],
			domain: [0, yMaxValue],
		})
	};

	getYMaxValue = () => {
        const numTicks = this.getNumTicks();
        const { data, metadata } = this.getData();
        const yMaxValue = 'max' in metadata ? metadata.max : (max(data, this.getGroupData) > max(data, this.getUserData)) ? max(data, this.getGroupData) : max(data, this.getUserData);
        const nextMultiple = Math.floor(yMaxValue/numTicks) + 1;
        return yMaxValue + Math.ceil(yMaxValue/numTicks);
    };

    getNumTicks = () => {
        return 5;
    }

	getTooltipHeader = (data) => {
        let header = "";
        if(!data.ids){
            header = data.id;
        }
        else if(data.ids && data.ids.length === 1){
            header = data.id;
        }
        else if(data.ids && data.ids.length > 1){
            const min = Math.min(...data.ids);
            const max = Math.max(...data.ids);
            header = `${min}-${max}`;
        }
        return header;
	}

	renderGraphComponent = () => {
		const leftTickLabelStyling = {
            fill: "#979eb7",
			textAnchor: "end",
			fontSize: 12,
			fontFamily: 'Open Sans'
		};

		const bottomTickLabelStyling = {
            fill: "#979eb7",
			textAnchor: "middle",
			fontSize: 12,
			fontFamily: 'Open Sans'
        };

        const labelStyling = {
            fontFamily: 'Open Sans',
			fontSize: 15,
			fill: '#979eb7',
			fillOpacity: 0.7,
			textAnchor: 'middle'
		};
		const legendFontSize = this.props.legendPosition == 'left' || this.props.legendPosition == 'right' ? '12px' : '16px';
		const legendLabelDirection = this.props.legendPosition == 'left' || this.props.legendPosition == 'right' ? 'column' : 'row';
		const legendItemMargin = this.props.legendPosition == 'left' || this.props.legendPosition == 'right' ? "0 0 9px 0" : "0 24px 0 0";
        const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: legendFontSize,
			fontFamily: 'Open Sans',
			color: '#979eb7',
            direction: legendLabelDirection,
            itemDirection: "row",
            labelMargin: "0",
            shapeMargin: "0 8px 0 0",
            itemMargin: legendItemMargin,
            shapeWidth: 12,
            shapeHeight: 12
        };

		const {
            tooltipLeft,
            tooltipTop,
            tooltipOpen
		} = this.props;

		const { showLabelTooltip, labelTooltipData } = this.state;

		const { hasAverageLines, hasAverageLinesLegend, barStyling, graphFormat, hasContinuousAverage } = this.props;
		const {leftOffset, rightOffset} = this.getOffset();
		let userAvg, groupAvg;
        if (this.props.hasAverageLines || this.props.graphData) {
			const graphData = this.props.graphData ? this.props.graphData : this.props.data;
			userAvg = graphData.userAverage;
			groupAvg = graphData.overallAverage;
		}
		const graphData = this.getData().data;
		const { strings } = this.getData();

		const width = this.getWidth();
		const height = this.height;

		const xMax = this.getXMax();
        const yMax = this.getYMax();

		// scales
		const xScale = this.getXScale();
		const zScale = this.getXScale();
		const yScale = this.getYScale();
		const numTicks = this.getNumTicks();
		let tickValues = yScale.ticks(numTicks);
		if (tickValues.length !== numTicks+2) {
			tickValues = range(0, this.getYMaxValue() + this.getYMaxValue()/numTicks, this.getYMaxValue()/numTicks);
		}

		let legendLabels = [strings.youLabel, strings.groupLabel];
		let legendShapes = [
			props => {
				const { width, height, size, fill } = props;
				return (<GlyphDot
					r={size/2}
					top={width / 2}
					left={height / 2}
					fill={fill}
				/>)
			},
			props => {
				const { width, height, size, fill } = props;
				return (<GlyphDot
					r={size/2}
					top={width / 2}
					left={height / 2}
					fill={fill}
				/>)
			}
		]

		if (hasAverageLines && hasAverageLinesLegend) {
			legendLabels.push(strings.groupAverageLabel, strings.yourAverageLabel);
			legendShapes.push(
				props => {
					const { width, height, size } = props;
					return (<Line
						from={{x: 0, y: width/2}}
						to={{x: width, y: width/2}}
						strokeWidth={2}
						stroke={barStyling.fromGroup}
					/>)
				},
				props => {
					const { width, height, size } = props;
					return (<Line
						from={{x: 0, y: width/2}}
						to={{x: width, y: width/2}}
						strokeDasharray={"4,2"}
						strokeWidth={2}
						stroke={barStyling.fromUser}
					/>)
				}
			);
		}

		if (hasContinuousAverage) {
			legendLabels.push(strings.userCompleteRatioLabel);
			legendShapes.push(
				props => {
					const { width, height, size, fill } = props;
					return (
                        <g>
                            <Line
                                from={{x: 0, y: width/2}}
                                to={{x: width, y: width/2}}
                                strokeWidth={2}
                                stroke={"#fea928"}
                            />
                            <GlyphDot
                                r={size/4}
                                top={width / 2}
                                left={height / 2}
                                fill={"#fea928"}
                            />
                        </g>)
				}
			);
		}

		const legendSize = scaleOrdinal({
			domain: legendLabels,
			range: [12]
		});
		const legendShape = scaleOrdinal({
			domain: legendLabels,
			range: legendShapes
		});

		const ShowAverageLines = () => {
            if (hasAverageLines && !hasContinuousAverage) {
				const areOverlapping = Math.abs(userAvg - groupAvg) <= 2;
				const textWidth = hasAverageLinesLegend ? 0 : 70;
                return (
				<Group>
					{
						this.drawLine(
							{x: -leftOffset, y: yScale(groupAvg)},
							{x: xMax + rightOffset - textWidth, y: yScale(groupAvg)},
							strings.groupAverageLabel,
							{textColor: barStyling.fromGroup, lineColor: barStyling.fromGroup, fontFamily: "Open Sans"},
							true, areOverlapping
						)
					}
					{
						this.drawLine(
							{x: -leftOffset, y: yScale(userAvg)},
							{x: xMax + rightOffset - textWidth, y: yScale(userAvg)},
							strings.yourAverageLabel,
							{textColor: barStyling.fromUser, lineColor: barStyling.fromUser, fontFamily: "Open Sans"},
							false, areOverlapping
						)
					}
                </Group>)
			}
        }
		const userId = "user-gradient" + barStyling.componentName;
		const groupId = "group-avg-gradient" + barStyling.componentName;

		const userGradientBounds = getGradientBounds(barStyling.userRotation ? barStyling.userRotation : 0);
		const groupGradientBounds = getGradientBounds(barStyling.groupRotation ? barStyling.groupRotation : 0);

		const flexDirection = getFlexDirectionForGraphLegend(this.props.legendPosition);
		
		return(
			<div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: flexDirection}}>
				<svg width={width} height={height} >
					<LinearGradient
						from={barStyling.fromUser ? barStyling.fromUser : 'green'}
						to={barStyling.toUser}
						{...userGradientBounds}
						id={userId}
					/>
					<LinearGradient
						from={barStyling.fromGroup}
						to={barStyling.toGroup}
						{...groupGradientBounds}
						id={groupId}
					/>

					<LinearGradient
						from={barStyling.fromUser ? barStyling.fromUser : 'green'}
						to={barStyling.toUser}
						{...userGradientBounds}
						id={`legend-0-${barStyling.componentName}`}
					/>
					<LinearGradient
						from={barStyling.fromGroup}
						to={barStyling.toGroup}
						{...groupGradientBounds}
						id={`legend-1-${barStyling.componentName}`}
					/>

					<Group top={this.margin.top} left={this.margin.left + leftOffset}>
						<GradientGrid scale={yScale} xMax={xMax} yMax={yMax} tickValues={tickValues} offset={{left: leftOffset, right: rightOffset}} />
						<AxisLeft
							scale={yScale}
							top={0}
							left={-leftOffset}
							label={graphFormat.yLabel}
							stroke={'#1b1a1e'}
							tickTextFill={'#1b1a1e'}
							hideTicks={true}
							hideZero={true}
							tickLabelProps={() => (leftTickLabelStyling)}
							tickFormat={(value, index) => Math.ceil(value)}
							tickValues={tickValues}
							labelProps={labelStyling}
							stroke={'white'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
							numTicks={numTicks}
						/>
						<AxisBottom
							scale={xScale}
							top={yMax}
							left={0}
							label={graphFormat.xLabel}
							stroke={'#1b1a1e'}
							hideAxisLine={true}
							hideTicks={true}
							hideZero={true}
							tickValues={ graphData.map(this.getId) }
							tickFormat={(value, index) => {
								if ('valuePrefix' in graphFormat) {
									const dataObject = graphData.filter((item) => {
										return item.id === value;
									})
									if(dataObject && !dataObject[0].ids){
										return `${graphFormat.valuePrefix}${value}`;
									}
									else if(dataObject[0].ids && dataObject[0].ids.length === 1){
										return `${graphFormat.valuePrefix}${value}`;
									}
									else if(dataObject[0].ids && dataObject[0].ids.length > 1){
										const min = Math.min(...dataObject[0].ids);
										const max = Math.max(...dataObject[0].ids);
										return `${graphFormat.valuePrefix}${min}-${max}`;
									}
								}
								return textTruncate(graphData[index].itemName, 10);
							} }
							tickLabelProps={(val, index) => {
								if ('valuePrefix' in graphFormat) {
									return {
										...bottomTickLabelStyling
									}
								}
								return {
									...bottomTickLabelStyling,
									onMouseOver: (event) => {
										const { x, y } = localPoint(event.target.ownerSVGElement, event);
										this.setState({
											showLabelTooltip: true,
											labelTooltipData: {
												x: x,
												y: y,
												text: graphData[index].itemName
											}
										})
									},
									onMouseOut: (event) => {
										this.setState({
											showLabelTooltip: false,
											labelTooltipData: null
										})
									}
								};
							}}
							labelProps={labelStyling}
							stroke={'white'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
						/>
						{tooltipOpen && (
							<Line
								from={{x: tooltipLeft, y: yMax}}
								to={{x: tooltipLeft, y: (tooltipTop + 20)}}
								stroke={ 'white' }
								strokeWidth={1}
							/>
						)}
						{ ShowAverageLines() }
						{ this.renderGraph() }
						{ hasContinuousAverage &&
							<Group left={xScale.bandwidth()/2}>
								<LinePath
									data={graphData}
									xScale={xScale}
									yScale={yScale}
									x={this.getId}
									y={this.getUserCompletionRate}
									strokeWidth={2}
									curve={ Curve.curveLinear }
									stroke={"#fea928"}
									glyph={(d,i) => {
											return (
												<g key={`line-point-${i}`}>
													<GlyphDot
														cx={xScale(this.getId(d))}
														cy={yScale(this.getUserCompletionRate(d))}
														r={2}
														stroke={"#fea928"}
														strokeWidth={4}
													/>
												</g>
											);
										}
									}
								/>
							</Group>
						}
					</Group>
				</svg>
				<GraphLegendComponent
					style={legendStyling}
					shape={legendShape}
					size={legendSize}
					componentName={barStyling.componentName}
				/>
				{this.renderTooltip()}
				{showLabelTooltip && (
					<Tooltip
						key={Math.random()}
						top={yMax + labelTooltipData.y}
						left={labelTooltipData.x + leftOffset}
						style={{
							color: '#31384B',
							background: '#979eb7',
						}}
					>
						{labelTooltipData.text}
					</Tooltip>
				)}
			</div>
        );
	}

	renderTooltip = () => {
		const {
            tooltipData,
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
            barStyling,
			graphFormat,
			hasContinuousAverage
		} = this.props;

		if(!tooltipOpen)
			return

		const tooltipStyling = {
			fontFamily: 'Open Sans',
            fontSize: '12px',
            color: '#ffffff',
            backgroundColor: '#363e5e',
            width: '150px',
            height: hasContinuousAverage ? '100px' : '75px',
            paddingBottom: '5px',
			boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.5)'
		};
		const { strings } = this.getData();
		const { leftOffset } = this.getOffset();
		const tooltipUserLegendFillValue = `linear-gradient(286deg, ${barStyling.fromUser}, ${barStyling.toUser})`;
		const tooltipGroupLegendFillValue = `linear-gradient(286deg, ${barStyling.fromGroup}, ${barStyling.toGroup})`;
		const tooltipUserCompletionRateFillValue = '#fea928';
		const tooltipHeaderPrefix = 'valueName' in graphFormat ? `${graphFormat.valueName} ${this.getTooltipHeader(tooltipData)}` : textTruncate(this.getItemName(tooltipData), 25);
		const userValueTooltip = 'userValueTooltip' in tooltipData ? tooltipData.userValueTooltip : tooltipData.userValue;
		const groupValueTooltip = 'groupValueTooltip' in tooltipData ? tooltipData.groupValueTooltip : tooltipData.groupValue;
		// let userContentTitle, groupContentTitle;
		// if (graphFormat.yLabel.includes('Score')) {
		// 	userContentTitle = 'Your score';
		// 	groupContentTitle = 'Group score';
		// }
		// else {
		// 	userContentTitle = 'Your progress';
		// 	groupContentTitle = 'Group progress';
		// }
		return (
			<Tooltip
				key={Math.random()}
				top={tooltipTop}
				left={tooltipLeft + leftOffset - (tooltipData.barWidth * 0.75)}
				style={tooltipStyling}
			>
				<div styleName="tooltip-header">{tooltipHeaderPrefix}</div>
                <div styleName="tooltip-content">
                    <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipUserLegendFillValue }}></div>{strings.youTooltip.replace('PLACEHOLDER', `${userValueTooltip}`)}
                    </div>
                    <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipGroupLegendFillValue }}></div>{strings.groupTooltip.replace('PLACEHOLDER', `${groupValueTooltip}`)}
                    </div>
                    {hasContinuousAverage && <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipUserCompletionRateFillValue }}></div>{strings.userCompleteRatioTooltip.replace('PLACEHOLDER', `${tooltipData.completedUsersCountTooltip}`)}
                    </div>}
                </div>
			</Tooltip>
		)
	}

	renderGraph = () => {
		const { hideTooltip, barStyling } = this.props;
		const graphData = this.getData().data;

		const xMax = this.getXMax();
        const yMax = this.getYMax();

		// scales
		const xScale = this.getXScale();
		const zScale = this.getXScale();
		const yScale = this.getYScale();
		const userId = "user-gradient" + barStyling.componentName;
		const groupId = "group-avg-gradient" + barStyling.componentName;

		return (
			<Group name="graph">
				{graphData.map((d, i) => {
					const userBarHeight = yMax - yScale(this.getUserData(d));
					const avgBarHeight = yMax - yScale(this.getGroupData(d));
					return (
						<Group key={`bar-${barStyling.componentName}-${this.getId(d)}`}>
							<path
								id={`user-${barStyling.componentName}-${i}`}
								d={getRoundedRect(xScale(this.getId(d)), yMax - userBarHeight, xScale.bandwidth(), userBarHeight, 4, 'top')}
								style={{ fill: `url(#${userId})` }}
								onMouseMove={event =>
									this.handleTooltip(
										event,
										{ isUser: true, barWidth: zScale.bandwidth(), ...d },
										xScale,
										yScale,
								)}
								onMouseLeave={event => hideTooltip()}
								onMouseOut={event => hideTooltip()}
							/>

							<path
								id={`group-${barStyling.componentName}-${i}`}
								d={getRoundedRect(zScale(this.getId(d)) + xScale.bandwidth()* (0.75), yMax - avgBarHeight, zScale.bandwidth()/2, avgBarHeight, 4, 'top')}
								style={{ fill: `url(#${groupId})` }}
								onMouseMove={event =>
									this.handleTooltip(
										event,
										{ isUser: false, barWidth: zScale.bandwidth(), ...d },
										zScale,
										yScale,
								)}
								onMouseLeave={event => hideTooltip()}
								onMouseOut={event => hideTooltip()}
							/>
						</Group>
					);
				})}
			</Group>
		);
	}

	validateMetadata = () => {
		const { metadata } = this.getData();
		if (!checkMetadata(metadata)) {
			return false;
		}
		return true;
	}

    render() {
		const data = this.getData().data;
        if (data.length == 0 || !this.validateMetadata() || !this.props.isUserDataAvailable) {
            return <InfoComponent 
						{...this.props} 
						infoMessage="This graph does not have data"
					/>
        }

        return this.renderGraphComponent();
    }
}

OverlappingBars.propTypes = {
	size: PropTypes.object,
    graphData: PropTypes.object.isRequired,
	hasAverageLines: PropTypes.bool,
	graphFormat: PropTypes.object,
	legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
	hasAverageLinesLegend: PropTypes.bool
}

OverlappingBars.defaultProps = {
	size: {
        width: 700,
        height: 500
    },
	hasAverageLines: true,
	barStyling: {
		leftOffset: 10,
		rightOffset: 50
	},
	graphFormat: {
		xLabel: '',
		yLabel: '',
		valueFormat: '',
		valuePrefix: '',
		valueName: ''
	},
	legendPosition: 'bottom',
	hasAverageLinesLegend: false
}

export default withTooltip(OverlappingBars);