import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		moduleName: {
			...myTypography.body2,
			color: hexToRgbA(skinHex.get('greyColor1'), 0.6),
			lineHeight: '54px'
		},
		viewActionBtn: {
			...myTypography.button,
			color: '#9a9eaf',
			lineHeight: '38px'
		},
		completionStatusText: {
			...myTypography.body2,
			color: hexToRgbA(skinHex.get('greyColor1'), 0.3),
			lineHeight: '54px'
		},
		likeToHearLabel: {
			...myTypography.overline,
			color: hexToRgbA(skinHex.get('greyColor1'), 0.6)
		}
	});
}

export default getSkin;