import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setQuizAttemptReviewQuestionsWithUserResponses, setQuizAttemptReviewDataLaoding } from 'actions/quiz/actionCreators'

class GetQuizAttemptUserResponses extends NetPackWrapper {
	constructor() {
		super('GET', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('QUIZ_ATTEMPT_REVIEW')
			.replace('_QUIZ_SUBMISSION_ID_', apiInfoObject.submissionId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
			.replace('_QUIZ_SUBMISSION_ATTEMPT_', apiInfoObject.quizSubmissionAttempt)
	};

	successCall = (response, apiInfoObject) => {
		this.dispatch(setQuizAttemptReviewQuestionsWithUserResponses(response))
		this.dispatch(setQuizAttemptReviewDataLaoding(false))
	}
}

export default new GetQuizAttemptUserResponses();