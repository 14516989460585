import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import Next from 'images/icons/next.svg';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        subTitle: {
            ...myTypography.button,
            opacity: '0.5'
        },
        productNext: {
            color: skin.get('secondaryColor'),
            fontSize: '22px',
            ':hover': {
                color: skin.get('secondaryColor'),
                textDecoration: 'underline'
            }
        },
        productNextNoHover: {
            color: skin.get('secondaryColor'),
            fontSize: '22px',
            ':hover': {
                color: skin.get('secondaryColor'),
                // textDecoration: 'underline'
                cursor: 'not-allowed'
            }
        },
        product: {
            ...myTypography.h6,
            color: skin.get('secondaryColor'),
            fontSize: '22px'
        },
        container: {
            boxShadow: "0 -1px 0 0 rgba(136, 136, 136, 0.09), 0 -1px 4px 0 rgba(0, 0, 0, 0.21)"
        },
        icon: {
            backgroundImage: `url(${Next})`,
            width: '25px',
            height: '12px',
            backgroundSize: '100% 100%',
            backgroundRepeat: 'no-repeat'
        },
        disableTooltip: {
            background: skin.get('greyColor2'),
            color: skin.get('white'),
            ...myTypography.caption
        },
        nextItemTooltip: {
            backgroundColor: skin.get('greyColor1'),
            color: skin.get('greyColor5'),
        },
        arrowDown: {
            borderTop: `10px solid ${skin.get('greyColor1')}`
        },
        tooltipSubheading: {
            ...myTypography.button,
            fontWeight: 'bold',
            textAlign: 'left',
            minWidth: '105px'
        },
        tooltipContent: {
            ...myTypography.button,
            fontWeight: 'normal',
            textAlign: 'left',
            width: '100%'
        }
    });
}

export default getSkin;