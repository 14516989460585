import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setNotificationSubscriptionDetails } from 'actions/userProfile/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import defaultLabels from 'constants/labels';

class SubscribeNotifications extends NetPackWrapper {

	constructor() {
		super("POST");
	}

	prepareUrl = () => urls.getIn(['NOTIFICATION', 'GET_SUBSCRIPTION']);

	successCall = (response) => {
		this.dispatch(setNotificationSubscriptionDetails(
			response[0] || { isActive: true }
		));

		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let msgHeader = defaultLabels.get('done_toast_msg');
		let msg = defaultLabels.get('subscribe_notification_option_updated_label');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			msgHeader = labels.get('done_toast_msg');
			msg = labels.get('subscribe_notification_option_updated_label');
		}

		this.dispatch(addToast({
			id: toastConstants.get('ID_SUCCESS'),
			type: toastConstants.get('TYPE_SUCCESS'),
			heading: msgHeader,
			message: msg,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));

		return response;
	}

	onError = (error) => {
		const activeBatchId = this.getState().getIn(['batches', 'activeBatchId']);
		let msgHeader = defaultLabels.get('something_went_wrong_toast_msg');
		let msg = defaultLabels.get('subscribe_notification_option_error_label');
		if (activeBatchId && this.getState().getIn(['labels', activeBatchId])) {
			const labels = this.getState().getIn(['labels', activeBatchId]);
			msgHeader = labels.get('something_went_wrong_toast_msg');
			msg = labels.get('subscribe_notification_option_error_label');
		}

		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: msgHeader,
			message: msg,
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
		return error;
	}
}

export default new SubscribeNotifications();