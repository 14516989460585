import React, { Component } from 'react';
import styles from './sidebarHeader.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import Home from 'svgComponents/home';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class SidebarHeader extends Component {

	sendSegmentData = (event, options = {}) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			...options
		};
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		);
	}

	onHomeClick = () => {
		this.sendSegmentData('Sidebar home clicked');
		this.props.onClickBack()
	}

	onSidebarToggleClick = (e) => {
		this.sendSegmentData('Sidebar menu clicked', { sidebardOpened: !this.props.isSidebarOpen });
		e.stopPropagation();
		this.props.onToggleSidebar();
	}

	render() {

		const myStyles = getSkin(this.props.skinGuide);
		const { isSidebarOpen, title, phaseName, getLabel } = this.props;

		return (
			<div>
				{
					(isSidebarOpen)
						?
						<div styleName="container" className={css(myStyles.container)}>
							<div styleName="icon home-icon" onClick={this.onHomeClick}>
								<Home strokeColor="#616881" />
							</div>
							<div className={css(myStyles.text)} styleName="text" title={title}>{title}</div>
							<div styleName="icon" className={css(myStyles.closeIcon)} onClick={this.onSidebarToggleClick} />
						</div>
						:
						<div styleName="hamburger-icon" className={css(myStyles.menuIcon)} onClick={this.onSidebarToggleClick} />
				}
			</div>
		);
	}
}

export default SidebarHeader;
