import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
        competencyTabContainer: {
			border: `1px solid ${skin.get('greyColor5')}`
		},
		headerContent: {
			backgroundColor: skin.get('greyColor5')
		},
		tabContent: {
			backgroundColor: skin.get('white')
		},
		headerScore: {
			...myTypography.h5,
			fontSize: '22px',
			color: skin.get('greyColor2')
		},
		headerScoreBold: {
			...myTypography.h5,
			fontSize: '22px',
			fontWeight: 600,
			color: skin.get('greyColor2')
		},
		headerCompetency: {
			...myTypography.h6,
			fontWeight: 400,
			color: skin.get('greyColor2')
		},
		headerTitle: {
			...myTypography.h6,
			color: skin.get('greyColor2')
		},
		separationLine: {
			backgroundColor: skin.get('greyColor2')
		},
		subHeading: {
			...myTypography.h6,
			fontSize: '18px',
			color: skin.get('greyColor2')
		},
		subdescription: {
			...myTypography.overline,
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: 1.78,
			color: skin.get('greyColor2')
		},
		descriptorName: {
			...myTypography.overline,
			fontWeight: 400,
			lineHeight: 'normal'
		},
		descriptorScore: {
			...myTypography.overline,
			fontWeight: 600,
			lineHeight: 'normal'
		},
		tipDescription: {
			...myTypography.overline,
			fontSize: '14px',
			fontWeight: 400,
			lineHeight: 1.78,
			color: skin.get('greyColor3')
		},
		descriptorDot: {
			backgroundColor: skin.get('greyColor2')
		},
		borderScoreTop: {
			borderTop: `1px solid ${skin.get('greyColor5')}`,
			borderLeft: `1px solid ${skin.get('greyColor5')}`,
			borderRight: `1px solid ${skin.get('greyColor5')}`
		},
		borderScoreAll: {
			border: `1px solid ${skin.get('greyColor5')}`
		},
		scoreDescShowmore: {
			color: skin.get('secondaryColor'),
			cursor: 'pointer',
			':hover': {
				textDecoration: 'underline'
			}
		}
    });
}

export default getSkin;