import environment from 'utils/environment';
import Immutable from 'immutable';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';

const getReportUrl = () => {
	var apiURL = '';

	if (environment.REACT_APP_ENV === 'local') {
		apiURL = environment.REACT_APP_REPORT_URL;
	} else {
		apiURL = `${getDomainUrl()}/reports`;
	}

	return Immutable.Map({
		'GENERATE_REPORT': `${apiURL}/user-report`,
		'GET_REPORT_STATUS': `${apiURL}/report`,
		'GENERATE_SHARABLE_REPORT_LINK': `${apiURL}/get-certificate-share-url`,
		'GET_LINKEDIN_SHARE_URL': `${apiURL}/linkedin-signin`,
		'SHARE_CERTIFICATE_ON_LINKEDIN': `${apiURL}/share-certificate-on-linkedin`
	})
}

export default getReportUrl;