import React from 'react';
import CSSModules from 'react-css-modules';
import { Pie } from '@vx/shape';
import { Group } from '@vx/group';
import { LinearGradient } from '@vx/gradient';
import { withTooltip, Tooltip } from "@vx/tooltip";
import { localPoint } from "@vx/event";
import styles from './pieChartOnly.module.sass';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })

class PieChartOnly extends React.Component {

	handleTooltip = (event, data) => {
		const { showTooltip } = this.props;
		const { x, y } = localPoint(event.target.ownerSVGElement, event);
		const tooltipLeft = x;
		const tooltipTop = y;
		const count = `${data.data.count} ${data.data.label} answered`
		const tooltipData = {
			...data,
			count: count
		};

		if (tooltipData) {
			showTooltip({
				tooltipLeft: tooltipLeft + 10,
				tooltipTop: tooltipTop + 10,
				tooltipData: tooltipData
			});
		}
	}

	render() {
		let { data, diameter, border } = this.props
		let width, height;
		width = height = diameter ? diameter : 150;
		const radius = Math.min(width, height) / 2;

		const tooltipStyle = {
			fontFamily: 'Open Sans',
			fontSize: '12px',
			color: 'rgba(9, 16, 39, 0.85)',
			minWidth: '100px',
			textAlign: 'center',
			backgroundColor: 'unset'
		};

		const {
			tooltipData,
			tooltipLeft,
			tooltipTop,
			tooltipOpen,
			hideTooltipFlag,
			hideTooltip, // function from vx/tooltip
			graphFormat,
			barStyling,
			legendPosition
		} = this.props;

		let borderGiven = border.width ? border.width : 0;
		return (
			<div style={{ position: 'relative', width: width, margin: 'auto' }}>
				<svg width={width} height={height}>
					{
						data.map((label, index) => {
							if (label.gradientTo) {
								return <LinearGradient
									from={label.fill}
									to={label.gradientTo}
									id={`pie-only-grad-${index}`}
									vertical={false}
									key={`linear_grad_for_bar_${index}`}
								/>
							} else {
								return <LinearGradient
									from={label.fill}
									to={label.fill}
									id={`pie-only-grad-${index}`}
									vertical={false}
									key={`linear_grad_for_bar_${index}`}
								/>
							}
						})
					}
					<Group top={height / 2} left={width / 2}>
						<Pie
							data={[{ letter: "Wrong", frequency: 11, fill: '#f3f3f3' }]}
							pieValue={d => d.frequency}
							outerRadius={radius}
							fill={border.fill ? border.fill : '#f3f3f3'}
						/>
						<Pie
							data={data}
							pieValue={d => d.count}
							outerRadius={radius - borderGiven}
							fill={d => `url(#pie-only-grad-${d.index})`}
							onMouseMove={
								!hideTooltipFlag ?
									data => event =>
										this.handleTooltip(
											event,
											data
										)
									: () => { }
							}
							onMouseLeave={!hideTooltipFlag ? () => event => hideTooltip() : () => { }}
							onMouseOut={!hideTooltipFlag ? () => event => hideTooltip() : () => { }}
						/>
					</Group>
				</svg>
				{tooltipOpen && (
					<Tooltip
						key={Math.random()}
						top={tooltipTop}
						left={tooltipLeft}
						style={{
							...tooltipStyle,
							minWidth: '110px',
							boxShadow: `0 14px 16px 0 rgba(0, 0, 0, 0.19)`,
							backgroundImage: `linear-gradient(to bottom left, ${tooltipData.data.fill}, ${tooltipData.data.gradientTo})`//pieColors[tooltipData.index],
						}}
					>
						{/* <div styleName="tooltip-header">{pieLabels[tooltipData.index]}</div> */}
						<div styleName="tooltip-content">{tooltipData.count}</div>
					</Tooltip>
				)}
			</div>
		);
	}
}

// PieChartOnly.defaultProps = {
// 	size: {
//         width: 500,
//         height: 500
// 	},
// 	graphFormat: {
// 		labels: ['Mobile', 'Web', 'Tablet', 'Others'],
// 		labelColors: [
// 			{from: '#ff0264', to: '#ff71ab'},
// 			{from: '#ffce23', to: '#fea429'},
// 			{from: '#26fa96', to: '#52f9aa'},
// 			{from: '#00faee', to: ' #72fbf5'}
// 		]
// 	},
//     legendPosition: 'right'
// }

export default withTooltip(PieChartOnly);