import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		categoryName: {
			...myTypography.caption,
			color: 'rgba(87,94,121,0.8)',
			// fontWeight: 500,
			textTransform: 'capitalize'
		},
		journeyName: {
			...myTypography.body1,
			color: '#4E535F',
			lineHeight: 1.5,
			fontWeight: 500
		},
		formatName: {
			...myTypography.caption,
			color: 'white',
			backgroundColor: '#551972',
			textTransform: 'uppercase'
		},
		styleACompleted: {
			...myTypography.caption,
			color: 'white',
			fontSize: '15px',
			backgroundColor: '#76CA1A',
			textTransform: 'uppercase'
		},
		aboutJourneyLabel: {
			...myTypography.button,
			color: skinHex.get('secondaryColor'),
			cursor: 'pointer'
		},
		otherDetailsKey: {
			...myTypography.caption,
			color: hexToRgbA('#979EB7', 0.7),
			fontWeight: 'normal'
		},
		otherDetailsValue: {
			...myTypography.caption,
			color: '#979EB7',
			marginLeft: '5px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap'
		},
		selfPacedBg: {
			backgroundColor: '#551972',
		},
		ILTBg: {
			backgroundColor: '#194772',
		},
		VLTBg: {
			backgroundColor: '#0C570B',
		},
		requestStatus: {
			...myTypography.caption,
			fontSize: '13px',
			color: '#575E79',
			fontWeight: 'normal'
		},
		journeyCompletion: {
			...myTypography.caption,
			color: hexToRgbA(skinHex.get('greyColor4'), 0.7),
			fontWeight: 'normal',
			lineHeight: 2
		},
		completedBg: {
			backgroundColor: skinHex.get('secondaryColor')
		},
		lockedDiv: {
			backgroundImage: `radial-gradient(circle at 50% 45%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.63))`
		},
		webSessionActive: {
			border: `4px solid ${skinHex.get('secondaryColor')}`
		},
		upcomingSessionDetails: {
			...myTypography.caption,
			color: skinHex.get('secondaryColor'),
			fontWeight: 700
		},
		activeSessionText: {
			...myTypography.caption,
			fontWeight: 700,
			color: '#5F5E5E'
		},
		joinSession: {
			backgroundColor: skinHex.get('secondaryColor')
		},
		joinText: {
			...myTypography.caption,
			fontWeight: 700,
			color: skinHex.get('white')
		}
	});
}

export default getSkin;