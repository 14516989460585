import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const typo = typography(theme);
	
	return StyleSheet.create({
		congratulationsWithName: {
      ...typo.h4,
      fontSize: '32px',
      color: '#300324',
      fontWeight: 700,
      lineHeight: 1.6
		},
    congratsMessage: {
      ...typo.overline,
      color: '#300324',
      fontWeight: 500
    },
    completionInfo: {
      ...typo.overline,
      color: '#300324',
      fontWeight: 700
    },
    congratsNoCertificate: {
      ...typo.h3,
      fontSize: '40px',
      color: '#300324',
      lineHeight: 2,
      fontWeight: 700
    },
    congratsNoCertificateName: {
      color: skin.get('secondaryColor')
    },
    greetingsNoCertificate: {
      ...typo.h6,
      fontWeight: 500,
      lineHeight: 1.5,
      color: '#300324'
    },
    celebrationImage: {
      borderBottom: '2px solid black'
    }
	});
}

export default getSkin;