import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setModuleItem, setModuleItemFromCache } from 'actions/moduleItems/actionCreators';
import { setApiError } from 'actions/apiErrors/actionCreators';
import { getLocaleForDAPI } from 'utils/locales';

class FetchModuleItem extends NetPackWrapper {
	constructor() {
		super('GET', true);
	}

	returnCached = (moduleItemId) => {
		const list = this.getState().get('moduleItemsCache');
		const filteredList = list.filter((element) => element.get('id') == moduleItemId);

		if (filteredList.size === 0) {
			return null;
		}

		return filteredList.get(0);
	}

	prepareUrl = apiInfoObject => {
		let url = 'MODULE_ITEM_DETAILS';
		return urls.get('DEVELOPMENT_PHASE')
			.get(url)
			.replace('_COURSE_ID_', apiInfoObject.courseId)
			.replace('_CONTENT_TAG_ID', apiInfoObject.moduleItemId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
			.replace('LANG', getLocaleForDAPI())
	};

	beforeCall = apiInfoObject => {
		let cachedItem = this.returnCached(apiInfoObject.moduleItemId);
		if (cachedItem) {
			this.dispatch(setModuleItemFromCache({
				moduleItem: cachedItem.get('moduleItem'),
				contentDetails: cachedItem.get('contentDetails'),
				isModuleItemDataAvailable: true,
				areContentDetailsAvailable: true,
				nextModuleItem: {}
			}));
		}
		return apiInfoObject;
	};

	successCall = (response, apiInfoObject) => {
		let currentModuleItem, nextModuleItem = null;

		currentModuleItem = response.find(function (iterationItem) {
			return iterationItem.id == apiInfoObject.moduleItemId;
		});

		if (currentModuleItem) {
			nextModuleItem = response.find(function (iterationItem) {
				return parseInt(iterationItem.order) == parseInt(currentModuleItem.order) + 1;
			});
		}

		/* logic of Next Item link below module item */
		if (nextModuleItem && nextModuleItem.lock_details && nextModuleItem.lock_details.locked) {
			nextModuleItem.isLocked = true;
		}

		this.dispatch(setApiError(
			'fetchModuleItemError',
			null
		));

		this.dispatch(setModuleItem({
			moduleItem: currentModuleItem,
			nextModuleItem: nextModuleItem
		}));
		return currentModuleItem;
	}

	onError = (errorObj) => {
		console.log('on error', errorObj);
		this.dispatch(setApiError(
			'fetchModuleItemError',
			'There was a problem while fetching the content.'
		));
	}
}

export default new FetchModuleItem();
