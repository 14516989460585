import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules({ allowMultiple: true })
class Like extends Component {

    render() {

        const skinHex = this.props.skinGuide.get('skinHex');
        const {height, width} = this.props
        return (
            <div style={{height: height, width: width}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 26 24">
                    <g fill={this.props.color} fillRule="nonzero">
                        <path d="M25.057 12.174a2.7 2.7 0 0 0-.43-2.438 2.833 2.833 0 0 0-2.26-1.113H16.66c-.439 0-.847-.22-1.081-.583a1.225 1.225 0 0 1-.072-1.205l1.46-2.993c.273-.79.11-1.662-.429-2.308a2.544 2.544 0 0 0-2.041-.91 2.536 2.536 0 0 0-1.974 1.042L7.52 8.594a1.29 1.29 0 0 1-1.044.529.47.47 0 0 0-.474.464V20.9a.5.5 0 0 0 .288.45c3.784 1.792 5.041 2.029 8.123 2.029.355 0 2.373-.02 3.11-.02 2.741 0 4.565-1.555 5.58-4.779l1.943-6.369.01-.037zM4.469 9.123h-.027a1.524 1.524 0 0 0-1.508-1.25H1.4a.506.506 0 0 0-.511.5v14c0 .276.229.5.511.5h1.534c.848 0 1.535-.672 1.535-1.5V9.123z" />
                    </g>
                </svg>
            </div>
        );
    }
}

Like.defaultProps = {
    height: "24px",
    width: "26px"
};

export default Like;
