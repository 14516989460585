import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		button: {
			backgroundColor: skinHex.get('primaryColor'),
			':hover': {
					backgroundColor: skinHex.get('primaryVariantColor')
			}
		},
		shareText: {
			...myTypography.button,
			fontSize: '16px',
			color: skinHex.get('white')
		},
	});
}

export default getSkin;