import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        buttonStyle: {
            backgroundColor: skin.get('primaryColor'),
            borderRadius: '4px',
            boxShadow: `0px 5px 5px 0px rgba(0, 0, 0, 0.19)`,
            ':hover': {
                backgroundColor: skin.get('primaryVariantColor')
            }
        },
        buttonStyleWithoutShadow: {
            backgroundColor: skin.get('primaryColor'),
            borderRadius: '6px'
        },
        buttonText: {
            ...myTypography.button,
            fontSize: '10px',
            color: skin.get('white')
        }

    });
}

export default getSkin;