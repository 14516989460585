import React, { Component } from 'react';
import CSSModules from 'react-css-modules';

import applySkin from 'utils/SkinProvider';
import {css} from 'aphrodite/no-important';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';

import styles from './toggleView.module.sass';

@applyLabel
@applySkin
@CSSModules(styles, {allowMultiple: true})
class ToggleView extends Component {
    render() {
        const { on, onToggle, label } = this.props;
        const styles = getSkin(this.props.skinGuide);

        return (
            <div styleName="control-container" className={`presentation-toggle-container ${css(styles.toggleContainer)}`}>
                <input type="checkbox" styleName="switch" defaultChecked={on} onChange={onToggle}/>
                <span className='toggle-label'>
                    {label}
                </span>
            </div>
        )
    }
}

export default ToggleView;