import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';

import styles from './userJourneysStyleA.module.sass';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import Tabs from 'commonComponents/tabs';
import JourneyCardStyleA from 'commonComponents/journeyCardStyleA';
import JourneysLoader from 'commonComponents/loaders/journeysLoader';
import JourneyIntroductionPopupContainer from 'containers/JourneyIntroductionPopupContainer';
import JourneyCatalogueContainer from 'containers/JourneyCatalogueContainer';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import Footer from 'commonComponents/footer';
import NoJourneyErrorComponent from 'commonComponents/noJourneyErrorComponent';
import UserJourneyStatsStyleA from 'components/userJourneyStatsStyleA';
import WebSessionDialog from 'components/webSessionDialog';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { findTodaysSessionForBatch } from 'utils/webSessions';
import Section from './section';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class UserJourneys extends Component {

	constructor(props) {
		super(props);
		let showJourneyIntroductionPopup = false;
		let selectedJourneyInstanceId = null;
		let localStorageAppState = window.localStorage.getItem('appState');
		if (localStorageAppState) {
			let appState = JSON.parse(localStorageAppState);
			window.localStorage.removeItem("appState")
			if (appState.journeyInstanceId) {
				showJourneyIntroductionPopup = true;
				selectedJourneyInstanceId = appState.journeyInstanceId;
			}
		}
		document.title = 'Home - Aktivlearn Plus'
		this.state = {
			selectedTab: props.selectedTab || 'my_journeys',
			showJourneyIntroductionPopup: showJourneyIntroductionPopup,
			selectedJourneyInstanceId: selectedJourneyInstanceId,
			selectedBatchId: null,
			refreshTime: null 
		}
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.selectedTab !== this.props.selectedTab)
			if (nextProps.selectedTab === 'my_journeys') {
				this.props.fetchUserJourneys(nextProps.userOrganization.getIn(['userOrganization', 'id']));
			}
		let anyJourney = false;

		if (
			!nextProps.organizationLevelUsersStats.get('organizationLevelUsersStatsLoading')
			&& this.props.organizationLevelUsersStats.get('organizationLevelUsersStatsLoading')
		) {
			nextProps.organizationLevelUsersStats.get('organizationLevelUsersStats').entrySeq().forEach(([key, value]) => {
				if (value !== 0) {
					anyJourney = true;
				}
			})
			if (!anyJourney) {
				this.updateSelectedTab('journey_catalogue');
			}
		}
	}

	updateSelectedTab = (selectedTab) => {
		this.sendSegmentData(selectedTab);

		if (selectedTab === 'my_journeys') {
			updateRoute({ route: routes.get('MY_JOURNEY') });
		} else {
			updateRoute({ route: routes.get('JOURNEY_CATALOG') });
		}
	}

	sendSegmentTrackEvent = (event, options = {}) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			...options
		};
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		);
	}

	sendSegmentData = (selectedTab) => {
		this.sendSegmentTrackEvent(
			'Home tab clicked',
			{ clickedTab: selectedTab === 'my_journeys' ? 'My Journeys' : 'Explore Journeys' }
		);
		if (selectedTab === 'my_journeys') {
			window.analytics.page(
				'User Journeys',
				{
					...(this.props.match.params),
					url: window.location.href,
					pathHash: window.location.hash
				},
				getAmplitudeSessionDetails()
			)
		}
	}

	setJourneyIntroductionPopup = (selectedJourneyInstanceId, selectedBatchId = null) => {
		this.setState({
			showJourneyIntroductionPopup: true,
			selectedJourneyInstanceId: selectedJourneyInstanceId,
			selectedBatchId: selectedBatchId
		});
	}

	unsetJourneyIntroductionPopup = () => {
		this.setState({
			showJourneyIntroductionPopup: false,
			selectedJourneyInstanceId: null,
			selectedBatchId: null
		});
	}

	renderTabs = () => {
		const { userOrganization, getLabel, organizationLevelUsersStats } = this.props;
		let anyJourney = false;

		
		organizationLevelUsersStats.get('organizationLevelUsersStats').entrySeq().forEach(([key, value]) => {
			if (value !== 0) {
				anyJourney = true;
			}
		})
		

		let userJourneyTabs = [{
			label: getLabel("my_journeys_label"),
			value: "my_journeys"
		}];


		if(userOrganization.getIn(['userOrganization', 'platformSettings', 'exploreCourses'])){
			userJourneyTabs.push({
				label: getLabel("journey_catalogue_label"),
				value: "journey_catalogue"
			});
		}

		if (!anyJourney) {
			userJourneyTabs = userJourneyTabs.filter(t => t.value !== 'my_journeys')
		}


		return <Tabs
			tabsList={userJourneyTabs}
			tabStyles={{ fontSize: '18px', borderBottom: 'solid 2px #575656', maxWidth: '250px' }}
			tabsStyles={{ width: '100%' }}
			selectedTabValue={this.props.selectedTab}
			tabClickHandler={this.updateSelectedTab}
		/>;
	}

	onEnrollNewJourneyCardClick = () => {
		this.sendSegmentTrackEvent('Enroll Another Journey card clicked');
		updateRoute({ route: routes.get('JOURNEY_CATALOG') });
	}

	getMyJourneys = (myStyles) => {
		const {
			userBatches,
			batches,
			userOrganization,
			getLabel,
			setBatchSwitching,
			activeBatchId
		} = this.props;
    
		const incompleteUserBatches = userBatches.filter((userBatch) => {
			return userBatch.get('workflowState') !== 'COMPLETED'
		})

    const homePageSections = userOrganization.getIn(['userOrganization','journeyInstanceSections']).toJS();
		// Added this to render the courses without section at the top
		homePageSections.unshift({
			id: null,
			name: ""
		})
    const sectionsToRender = homePageSections.map(section => {
      let sectionHeader;
      if(section.id !== null) {
        sectionHeader = <Section sectionName={section.name} sectionId={section.id}/>
      }
			let batchesInSection = userBatches.filter((userBatch) => {
				let batchDetails = batches.find(b => b.get('id') === userBatch.get('batchId'));
				return batchDetails ? batchDetails.get('sectionId') === section.id : false
			});
			
			let sectionBatchesComponent = batchesInSection.map(batch => {
        let batchDetails = batches.find(b => b.get('id') === batch.get('batchId'));
        if (batchDetails) {
          return <JourneyCardStyleA
            key={`user_batch_${batch.get('batchId')}`}
            setJourneyIntroductionPopup={this.setJourneyIntroductionPopup}
            setBatchSwitching={setBatchSwitching}
            userBatch={batch}
            batchDetails={batchDetails}
            activeBatchId={activeBatchId}
            isActive={batch.get('workflowState') !== 'COMPLETED'}
            webSessionDetails={this.getWebSessionDetailsForJourneyCard(batchDetails)}
            onSessionFinish={this.onSessionFinish}
            refreshTime={this.state.refreshTime}
            isCompleted={batch.get('workflowState') === 'COMPLETED'}
          />
        }
        return null;
      });

      return <div styleName="journeys-list">
          <div styleName="list-header">
            {sectionHeader}
          </div>
          <div styleName="list-content">
            {/* {sectionBatchesComponent.size > 0 ? sectionBatchesComponent : getLabel("no_courses_assigned_label")} */}
						{sectionBatchesComponent.size > 0 ? sectionBatchesComponent : ""}
          </div>
        </div>
    });

		return (
			<div styleName="journey-list-cnt">
				<div styleName="inner-cnt">
          <div styleName="journeys-list">
						<UserJourneyStatsStyleA
							incompleteUserBatchesCount={incompleteUserBatches.size}
						/>
					</div>
					{sectionsToRender}
				</div>
			</div>
		)
	}

	getTabContent = (myStyles) => {
		const { userOrganization, selectedTab } = this.props;
		switch (selectedTab) {
			case 'my_journeys':
				return this.getMyJourneys(myStyles);
			case 'journey_catalogue':
				if (!userOrganization.getIn(['userOrganization', 'platformSettings', 'exploreCourses']))
					return this.getMyJourneys(myStyles);
				return <JourneyCatalogueContainer
					orgCode={userOrganization.getIn(['userOrganization', 'code'])}
					match={this.props.match}
				/>;
			default:
				return null;
		}
	}

	goBackToHome = () => {
		const route = routes.get('USER_HOME');
		updateRoute({ route });
	}

	findTodaysSessionForOngoingBatch = (batch, searchForActive = false) => {
		const { userBatches } = this.props;
		if(userBatches && userBatches.size <= 0) return null;

		const batchIds = userBatches.map( batch => batch.get('batchId')).toJS();
		
		if(batchIds.includes(batch.get('id'))){
			return findTodaysSessionForBatch(batch, searchForActive);
		}
		return false;
	}

	getWebSessionDetailsForJourneyCard = (batch) => {
		const activeSessionDetails = this.findTodaysSessionForOngoingBatch(batch, true);
		if(activeSessionDetails && activeSessionDetails.hasActiveSession){
			return activeSessionDetails;
		}
		const upcomingSessionDetails = this.findTodaysSessionForOngoingBatch(batch, false);
		if(upcomingSessionDetails && upcomingSessionDetails.hasUpcomingSessionToday){
			return upcomingSessionDetails;
		}
		return {
			hasActiveSession: false,
			hasUpcomingSessionToday: false,
			session: null
		};
	}

	getDetailsForWebSession = () => {
		const { batches } = this.props;
		let activeSessionDetailsForComponent = null;
		
		const batchWithActiveSession = batches.find(batch => {
			const activeSessionDetails = this.findTodaysSessionForOngoingBatch(batch, true);
			if(activeSessionDetails && activeSessionDetails.hasActiveSession){
				activeSessionDetailsForComponent = activeSessionDetails;
				return true;
			}
			return false;
		});

		console.log('debugws: getBatch batchWithActiveSession', batchWithActiveSession && batchWithActiveSession.toJS());


		if(batchWithActiveSession && batchWithActiveSession.size > 0){
			return activeSessionDetailsForComponent;
		}

		let upcomingSessionDetailsForComponent = null;

		const batchWithSessionUpcomingToday = batches.find(batch => { 
			const upcomingSessionDetails = this.findTodaysSessionForOngoingBatch(batch, false);
			if(upcomingSessionDetails && upcomingSessionDetails.hasUpcomingSessionToday){
				upcomingSessionDetailsForComponent = upcomingSessionDetails;
				return true;
			}
			return false;
		});

		console.log('debugws: getBatch batchWithSessionUpcomingToday', batchWithSessionUpcomingToday && batchWithSessionUpcomingToday.toJS());

		if(batchWithSessionUpcomingToday && batchWithSessionUpcomingToday.size > 0){
			return upcomingSessionDetailsForComponent;
		}
		
		return null;

	}

	onSessionFinish = (refreshTime) => {
		this.setState({ refreshTime });
	}

	render() {
		const {
			user,
			userOrganization,
			skinGuide,
			getLabel
		} = this.props;
		const {
			showJourneyIntroductionPopup,
			selectedJourneyInstanceId,
			selectedBatchId
		} = this.state;
		const myStyles = getSkin(skinGuide);

		if (userOrganization.get('userOrganizationDataLoading')) {
			return (
				<div styleName="user-journeys-cnt" className>
					<JourneysLoader skinGuide={skinGuide} />
				</div>
			)
		}

		if (userOrganization.get('userOrganization').size === 0) {
			return null
		}

		let welcomeMessage = getLabel('welcome_user_note')
		.replace('USER_NAME', user.get('firstName'));

		if(user.get('firstName') && user.get('firstName').includes("@lsk.pe")){
			welcomeMessage = "Welcome!";
		}

		const webSessionDetails = this.getDetailsForWebSession();

		return (
			<div styleName="user-journeys-cnt" className>
				<div className={css(myStyles.welcomeBg)} styleName="welcome-user-bg">
					<div className={css(myStyles.welcomeBgImg)}></div>
					<div styleName="back-to-home" className={css(myStyles.headerCnt)}>
						<div styleName="user-welcome-note">
							<span className={css(myStyles.backToHome)}>
								{welcomeMessage}
							</span>
						</div>
					</div>
				</div>

				<div styleName="user-journeys-content">
					{/* <div styleName="tabs-cnt">
						{this.renderTabs()}
					</div> */}
					{this.getTabContent(myStyles)}
				</div>
				{
					showJourneyIntroductionPopup &&
					<JourneyIntroductionPopupContainer
						journeyInstanceId={selectedJourneyInstanceId}
						selectedBatchId={selectedBatchId}
						closePopup={this.unsetJourneyIntroductionPopup}
					/>
				}
				{webSessionDetails && <WebSessionDialog sessionDetails={webSessionDetails} 
						refreshTime={this.state.refreshTime}/>}
				<Footer />
			</div>
		)
	}
}

export default UserJourneys;