import UAParser from 'ua-parser-js';
const userAgentParsed = new UAParser();

export const browser = userAgentParsed.getBrowser();
export const os = userAgentParsed.getOS();
const device = userAgentParsed.getDevice();

export const isIE11 = () => {
    const browserString = `${browser.name} ${browser.version}`.toLowerCase();
    if(browserString.indexOf("ie 11") !== -1){
        console.log('IE 11 detected *rolls eyes*');
        return true;
    }
    return false;
}

/**
 * These browsers don't have issues with ranged requests. But an issue arose from our Azure storage blob not exposing the Content-Range header to the browser. Newer browsers seem to be ignoring that.
 */
export const doesBrowserHaveRangeRequestProblems = () => {
    if(isIE11()) return true;
    const browserName = browser.name.toLowerCase();
    const minVersionsForRangeRequests = {
        'firefox': 66,
        'edge': 18,
        'safari': 10,
        'chrome': 69
    }

    console.log('debug browser: ', minVersionsForRangeRequests[browserName], parseFloat(browser.version));

    if(minVersionsForRangeRequests[browserName]){
        if(parseFloat(browser.version) <= minVersionsForRangeRequests[browserName]){
            return true;
        }
    }

    return false;

}

export const browserDetails = {
    name: browser.name.toUpperCase(),
    version: parseFloat(browser.version)
}

export const isIPadOrIPhone = () => {
    return device.model === 'iPad' || os.name === 'iOS';
};
