import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './loaderComponent.module.sass';
import Loader from 'svgComponents/analytics/loader';

@CSSModules(styles, { allowMultiple: true })

class LoaderComponent extends React.Component {

    render(){
        return(
            <div styleName="loading-container">
                <Loader fillColor="#d8d8d8" />
                <div styleName="loading-text">Loading..</div>
            </div>
        )
    }
}


export default LoaderComponent;