import actionConstants from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
	quizAttemptReviewDataLoading: false,
	quiz_submission_questions: []
});

const quizAttemptReviewData = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_QUIZ_ATTEMPT_REVIEW_DATA_LOADING'):
			return state.set('quizAttemptReviewDataLoading', action.flag)
		case actionConstants.get('SET_QUIZ_ATTEMPT_REVIEW_DATA'):
			return state.set('quiz_submission_questions', action.payload.quiz_submission_questions)
		default:
			return state
	}
}

export default quizAttemptReviewData