import React, { Component } from 'react';
import styles from './report.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import ReportSvg from '../../../../svgComponents/reportSvg';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Report extends Component {

	moveToBatchAnalytics = () => {
		const batchId = this.props.batches.get('activeBatchId');
		const route = routes.get('BATCH_ANALYTICS');
		const params = [{ 'BATCH_ID': batchId }];
		updateRoute({ route, params });
	}

	render() {

		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div styleName="congratulations-message-container">
				<div styleName="congratulations-message-inner">
					<div styleName="inner-content">
						<div className={css(myStyles.titleStyle)} styleName="title-content">
							{this.props.getLabel('your_report_label')}
						</div>
						<div styleName="report-svg">
							<ReportSvg fillColor={this.props.skinGuide.get('skinHex').get('secondaryColor')} />
						</div>
						<div className={css(myStyles.subTitleStyle)} styleName="subtitle-content">
							{this.props.getLabel('check_performance_greetings')}
						</div>
						<div styleName="button-container" onClick={this.moveToBatchAnalytics}>
							<div className={css(myStyles.getButtonStyle)} styleName="button-style">
								{this.props.getLabel('journey_dashboard_label')}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Report;
