import { getLockMessage, replaceDatePlaceholderAndFormat } from 'utils/locks';


const getPhaseItemLockMessage = (phaseItem) => {
    if(phaseItem.get('isLocked') || phaseItem.get('isExpired')){
        return replaceDatePlaceholderAndFormat(true, phaseItem.get('lockExplanation'), phaseItem.get('lockDate'))
    }
    return null;
};

const getModuleLockDetails = (module, phaseItem) => {
    let completed = false,
        locked = false,
        expired = false,
        explanation = '';
            
    completed = phaseItem.get('isCompleted') || module.get('items_completed_count') == module.get('required_items_count');
    
    if (phaseItem.get('isLocked')) {
        locked = true;
        expired = false;
    } else if (phaseItem.get('isExpired')) {
        locked = false;
        expired = true;
    } else {
        locked =
            module.get('lock_details').get('lock_reason') == 'locked'
            || module.get('lock_details').get('lock_reason') == 'not_started'
            || module.get('lock_details').get('lock_reason') == 'prerequisites'
            || module.get('lock_details').get('lock_reason') == 'sequential_progress';

        expired =
            module.get('lock_details').get('lock_reason') == 'expired';
    }

    const phaseItemLockMessage = getPhaseItemLockMessage(phaseItem);

    explanation = phaseItemLockMessage || getLockMessage(module.get('lock_details'), "short");

    return {locked, completed, expired, explanation};
}

const ModuleUtil = {
    getModuleLockDetails
}


export default ModuleUtil;