import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './productStat.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import StatBulb from 'svgComponents/statBulb';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ProductStat extends Component {
	render() {
		const { getLabel, skinGuide, selectedItem } = this.props;
		const myStyles = getSkin(skinGuide);
		if (!selectedItem) {
			return <div styleName="stat-container"></div>
		}
		return (
			<div styleName="stat-container">
				<div><StatBulb /></div>
				<div className={css(myStyles.text)} styleName="text">
					{getLabel('assessment_summary_label')}
				</div>
			</div>
		);
	}
}

export default ProductStat;
