import React, { Component } from 'react';
import styles from './quizzeIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class QuizzeIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<path fill="#828593" fillRule="nonzero" d="M1.458 0C.653 0 0 .653 0 1.458v11.084A1.458 1.458 0 0 0 1.458 14h11.084A1.458 1.458 0 0 0 14 12.542V1.458A1.458 1.458 0 0 0 12.542 0H1.458zM7 11.083a.875.875 0 1 1 0-1.75.875.875 0 0 1 0 1.75zm.933-3.546a.583.583 0 0 0-.35.535.583.583 0 1 1-1.166 0 1.75 1.75 0 0 1 1.05-1.605A1.167 1.167 0 1 0 5.833 5.4a.583.583 0 0 1-1.166 0 2.333 2.333 0 1 1 3.266 2.138z"/>
				</svg>
			</div>
		);
	}
}

export default QuizzeIcon;
