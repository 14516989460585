import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import AuthorPattern from 'images/author-pattern.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        authorIntroBackground: {
            background: skin.get('greyColor2'),
        },
        authorIntroBackgroundPattern: {
            backgroundImage: `url(${AuthorPattern})`,
            backgroundSize: 'contain',
            backgroundPosition: 'top'
        },
        messageContainer: {
            backgroundColor: skin.get('white')
        },
        message: {
            ...myTypography.body1,
            lineHeight: 1.56,
            color: hexToRgbA(skin.get('greyColor1'), 0.85)
        },
        name: {
            ...myTypography.h4,
            color: skin.get('greyColor1'),
            lineHeight: 1
        },
        designation: {
            ...myTypography.caption,
            letterSpacing: '2px',
            lineHeight: 2,
            color: hexToRgbA(skin.get('greyColor1'), 0.85)
        },
        messageImage: {
            borderRadius: '50%'
        },
        messageTitle: {
            ...myTypography.h3,
            color: skin.get('white'),
            textTransform: 'upperCase',
            '@media screen and (max-width: 480px)': {
                fontSize: '18px'
            }
        },
        messageContainerLoader: {
            borderRadius: '4px',
            backgroundColor: skin.get('white')
        },
        authorIntroBackgroundLoader: {
            backgroundColor: skin.get('greyColor6')
        }
    });
}

export default getSkin;