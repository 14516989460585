import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './overallActivityWithInfo.module.sass';
import InfoAndDaysFilterComponent from 'commonComponents/analytics/infoAndDaysFilterComponent';
import AreaWithLine from 'commonComponents/graphs/areaWithLine';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })
class OverallActivityWithInfo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterBy: ''
        };
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchTimeSpentPeriodicallyData(+batchId, +userId);
    }

    handleOnClickFilter = (event, filter) => {
        this.setState({
            filterBy: filter
        });
    }

    renderAreaGraph = () => {
        const allGraphsData = this.props.allGraphsData.get('activity');
        if (allGraphsData.get('isTimeSpentPeriodicallyDataFetched')) {

            const graphData = allGraphsData.get('timeSpentPeriodicallyData');
            const strings = graphData.get('strings');
            const filterBy = this.state.filterBy ? this.state.filterBy : graphData.get('filtersEnabledObj').get('selectedFilter').toLowerCase();

            let filteredGraphData;

            if(graphData.get('isUserDataAvailable')) {
                filteredGraphData = graphData.get(filterBy).toJS();
            } else {
                filteredGraphData = graphData.get('week').toJS();
            }

            let xLabel, yLabel, timeFormat = '', valueName;
            if (filterBy === 'day') {
                xLabel = strings.get('days');
                valueName = strings.get('day');
            }
            else if (filterBy === 'week') {
                xLabel = strings.get('weeks');
                valueName = strings.get('week');
            }
            else if (filterBy === 'month') {
                xLabel = strings.get('months');
                valueName = strings.get('month');
            }

            switch (filteredGraphData.metadata.units) {
                case 'SECONDS':
                    yLabel = strings.get('timeInSecondssLabel');
                    break;
                case 'MINUTES':
                    yLabel = strings.get('timeInMinutesLabel');
                    break;
                case 'HOURS':
                    yLabel = strings.get('timeInHoursLabel');
                    break;
                case 'DAYS':
                    yLabel = strings.get('timeInDaysLabel');
                    break;
            }
            const graphFormat = {
                xLabel: xLabel,
                yLabel: yLabel,
                valueFormat: timeFormat,
                valuePrefix: '',
                valueName: valueName
            };

            const barStyling = {
                fromUser: 'rgba(254, 169, 40, 0.8)',
                toUser: 'rgba(254, 169, 40, 0.3)',
                userStroke: '#fea928',
                userRotation: 170,
                fromUserLegend: '#ffce23',
                toUserLegend: '#fea429',
                // fromGroup: 'rgba(254, 169, 40, 0.8)',
                // toGroup: 'rgba(254, 169, 40, 0.3)',
                // groupStroke: '#fea928',
                groupRotation: 180,
                // fromGroupLegend: '#ffce23',
                // toGroupLegend: '#fea429',
                gridValue: this.props.gridValue,
                componentName: 'end-of-journey-overall-activity',
                ...analyticGroupColors
            }

            return (
                [<InfoAndDaysFilterComponent
                    filterObject = {graphData.get('filtersEnabledObj')}
                    {...this.props}
                    title={strings.get('overAllActivity')}
                    subtitle={strings.get('graphDesc')}
                    clickHandler={this.handleOnClickFilter}
                    strings={strings}
                    key="InfoAndDaysFilterComponent"
                />,
                <AreaWithLine
                    isUserDataAvailable={graphData.get('isUserDataAvailable')}
                    skinGuide={this.props.skinGuide}
                    graphFormat={graphFormat}
                    graphData={filteredGraphData}
                    barStyling={barStyling}
                    // data={this.props.data.timeData}
                    size={this.props.size}
                    strings={strings.toJS()}
                    key="AreaWithLine"
                />]
            );
        }
        return <LoaderComponent />
    }

    render(){
        return this.renderAreaGraph();
    }
}


export default OverallActivityWithInfo;