import FetchAttachmentDetails from 'actions/serverCalls/attachments/FetchAttachmentDetails';
import FetchAttachmentsList from 'actions/serverCalls/attachments/FetchAttachmentsList';


const fetchAttachmentDetails = (attachmentId) => {
    FetchAttachmentDetails.call({ attachmentId });
}

const fetchAttachmentsList = (attachmentIds = []) => {
    return FetchAttachmentsList.call({
        queryParameters: {
            id: attachmentIds.join(',')
        }  
    })
}

export {
    fetchAttachmentDetails,
    fetchAttachmentsList
};