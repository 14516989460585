import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import styles from './courseFeedbackMain.module.sass';

import enumConstants from 'constants/enums';
import TickMark from 'svgComponents/tickMark';
import ThumbsUpDown from 'commonComponents/feedbackQuestionTemplates/thumbsUpDown';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import StarRating from 'commonComponents/feedbackQuestionTemplates/starRating';
import ScaleRating from 'commonComponents/feedbackQuestionTemplates/scaleRating';
import TextBased from 'commonComponents/feedbackQuestionTemplates/textBased';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CourseFeedbackMain extends Component {

	constructor(props){
		super(props);
		this.state = {
			showSaveFeedbackButton: false
		};
	}

	setShowFeedbackQuestions = () => {
		this.setState({
			showSaveFeedbackButton: true
		});
	}

	onSaveClick = () => {
		this.setState({
			showSaveFeedbackButton: false
		});
	}

	/* onSkipFeedback = () => {
		const { match } = this.props;
		const route = routes.get('BATCH');
		const params = [{ 'BATCH_ID': +match.params.batchId }]
		updateRoute({ route, params });
	} */

	getQuestionTemplate = (question) => {
		const { submitAnswerForFeedback } = this.props;
		switch (question.get('type')) {
			case enumConstants.get('FEEDBACK_THUMBS_UP_DOWN'):
				return <ThumbsUpDown
					key={`courseFeedbackTUP_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswerForFeedback}
					feedbackItemId={this.props.match.params.phaseItemId}
				/>
			case enumConstants.get('FEEDBACK_RATING'):
				return <StarRating
					key={`courseFeedbackRating_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswerForFeedback}
					feedbackItemId={this.props.match.params.phaseItemId}
				/>
			case enumConstants.get('FEEDBACK_RATING_SCALE'):
				return <ScaleRating
					key={`courseFeedbackRating_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswerForFeedback}
					feedbackItemId={this.props.match.params.phaseItemId}
				/>
			case enumConstants.get('FEEDBACK_INPUT_MEDIUM_TEXT'):
				return <TextBased
					key={`courseFeedbackText_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswerForFeedback}
					feedbackItemId={this.props.match.params.phaseItemId}
				/>
			default:
				return <div></div>;
		}
	}

    getFeedbackQuestions = () => {
		const { courseFeedback, getLabelWithDefaults, forceForm } = this.props;
		const myStyles = getSkin(this.props.skinGuide);

		if (courseFeedback.get('isFeedbackCompleted') && !this.state.showSaveFeedbackButton && !forceForm) {
			return this.getFeedbackCompletionStatusComponent()
		}

		let feedbackComponent = [];
		if (courseFeedback) {
			feedbackComponent = courseFeedback.get('questionsList').map((question, index) => {
				return (
					<div key={`feedback-question-${question.get('id')}`} styleName="individual-question-cnt">
						<div className={css(myStyles.questionNumber)} style={{ display: 'flex' }}>
							{getLabelWithDefaults('question_number_label', `Question ${index + 1}`, {number: index + 1})}							
							{
								question.get('mandatory') &&
								<div style={{ color: 'red' }}>&nbsp;*</div>
							}
						</div>
						{this.getQuestionTemplate(question)}
					</div>
				);

			})
		}
		return feedbackComponent;
	}

    getFeedbackCompletionStatusComponent = () => {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="completion-status-cnt">
				<div styleName="completion-status-content">
					<div styleName="feedback-completion-tick-mark-cnt">
						<TickMark fillColor={'white'} />
					</div>
					<div className={css(myStyles.thankYouNote)}>
						{this.props.getLabelWithDefaults('thank_you_note_after_feedback', "Thanks for your feedback")}
					</div>
					<div
						className={css(myStyles.viewResponsesLabel)}
						onClick={this.setShowFeedbackQuestions}>
						{this.props.getLabelWithDefaults('feedback_edit_responses_label', `Edit Responses`)}
					</div>
				</div>
				<div className={css(myStyles.completionBackground)} styleName="completion-bg"></div>
			</div>
		)
	}

	getFeedbackActions = () => {
		const { courseFeedback, getLabelWithDefaults, forceForm } = this.props;
		let submitButtonLabel = getLabelWithDefaults('feedback_submit_label', 'Submit');
		if (courseFeedback.get('isFeedbackCompleted') && !forceForm) {
			return;
		}
		return (
			<div styleName="feedback-action-cnt">
				{/* <div
					className={css(myStyles.skipButton)}
					styleName="skip-feedback-cnt"
					onClick={this.props.onSkipFeedback}
				>
					{getLabelWithDefaults('feedback_skip_label', 'Skip')}
				</div> */}
				<div styleName="submit-feedback-cnt">
					<PrimaryButton
						text={submitButtonLabel}
						clickFunction={this.onSubmitFeedback}
						disabled={!this.areAllMandatoryQuestionsAnswered()}
					/>
				</div>
			</div>
		)
	}

	areAllMandatoryQuestionsAnswered = () => {
		let manadatoryQuestionsAnswered = true;
		const { courseFeedback } = this.props;
		if (courseFeedback) {
			let numberOfQuestions = courseFeedback.get('questionsList').size;
			for (let i = 0; i < numberOfQuestions; i++) {
				if (
					courseFeedback.getIn(['questionsList', i, 'mandatory'])
					&& !courseFeedback.getIn(['questionsList', i, 'completed'])
				) {
					manadatoryQuestionsAnswered = false;
					break;
				}
			}
		}
		return manadatoryQuestionsAnswered
	}

	onSubmitFeedback = () => {
		const { submitFeedback, onSaveCallback } = this.props;

		const payload = {
			isSubmitted: true
		};
		
		submitFeedback(+this.props.match.params.phaseItemId, payload, onSaveCallback);
	}

	getHeader = () => {
		const { getLabelWithDefaults, skinGuide } = this.props;
		const myStyles = getSkin(skinGuide);

		return <React.Fragment><div className={css(myStyles.feedbackSubHeader)}>
			{getLabelWithDefaults(
				"course_feedback_sub_header",
				'Your feedback helps us make better courses'
			)}
		</div>
		<div className={css(myStyles.feedbackNote)}>
			{
				getLabelWithDefaults(
					'phase_feedback_note',
					'* represents mandatory questions. To submit feedback, please answer all mandatory questions.'
				)
			}
		</div></React.Fragment>
	}

    render() {
        const { getLabelWithDefaults } = this.props;
        return <React.Fragment>
            <div styleName="course-feedback-questions-list">
				{this.getHeader()}
                {this.getFeedbackQuestions()}
                {
                    this.state.showSaveFeedbackButton &&
                    <div styleName="save-feedback-btn-cnt">
                        <PrimaryButton
                            text={getLabelWithDefaults('save_feedback_responses', 'Save Responses')}
                            clickFunction={this.onSaveClick}
                        />
                    </div>
                }
            </div>
            {this.getFeedbackActions()}
        </React.Fragment>
    }
}

export default CourseFeedbackMain;