import React from 'react';
import PropTypes from 'prop-types';
import { Bar, Line } from '@vx/shape';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { LinearGradient } from '@vx/gradient';
import { extent, max } from 'd3-array';
import GradientGrid from '../gradientGrid';
import { GlyphDot } from '@vx/glyph';
import { Text } from '@vx/text';
import { getGradientBounds, getFlexDirectionForGraphLegend } from 'utils/analytics/utilFunctions';
import GraphLegendComponent from '../../analytics/graphLegendComponent';
import InfoComponent from 'commonComponents/analytics/infoComponent';
class NeedleMovementGraph extends React.Component {

	height = 450;
	margin = { top: 50, bottom: 0, left: 150, right: 20 };

	getMeasureId = d => d.measureId;
	getMeasure = d => d.measureName;
	getUserStartData = d => d['USER']['PRE'];
	getGroupStartData = d => d['OVERALL']['PRE'];
	getUserEndData = d => d['USER']['POST'];
	getGroupEndData = d => d['OVERALL']['POST'];

	getData = () => {
		const data = this.props.graphData ? this.props.graphData.data : this.props.data;
		const metadata = this.props.graphData ? this.props.graphData.metadata : {};
		return {
			data: data,
			metadata: metadata
		}
	}

	getOffset = () => {
		const leftOffset = this.props.barStyling.leftOffset ? this.props.barStyling.leftOffset : 25;
        const rightOffset = this.props.barStyling.rightOffset ? this.props.barStyling.rightOffset : 25;
        return {
            leftOffset: leftOffset,
            rightOffset: rightOffset
        }
	}
	
	getWidth = () => {
        const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
        let width = calcWidth < 250 ? 250: calcWidth;
        if (this.props.legendPosition == 'left' || this.props.legendPosition == 'right') {
            width = width * 0.8;
        }
        return width;
    }
	
	mouseHoverEvent = (event) => {
		const data = event.target.id.split('-');
		let textStrPre = '';
		let textStrPost = '';
		let textPreStyle = '';
		let textPostStyle = '';
		if(data[0] == 'userbar') {
			textStrPre = 'textuserpre' + data[1];
			textStrPost = 'textuserpost' + data[1];			
		} else if(data[0] == 'groupbar') {
			textStrPre = 'textgrouppre' + data[1];
			textStrPost = 'textgrouppost' + data[1];
		}

		textPreStyle = document.getElementById(textStrPre);
		textPostStyle = document.getElementById(textStrPost);

		textPreStyle.style.visibility = 'visible';
		textPostStyle.style.visibility = 'visible';
	}

	mouseOutEvent = (event) => {
		const data = event.target.id.split('-');
		let textStrPre = '';
		let textStrPost = '';
		let textPreStyle = '';
		let textPostStyle = '';
		if(data[0] == 'userbar') {
			textStrPre = 'textuserpre' + data[1];
			textStrPost = 'textuserpost' + data[1];			
		} else if(data[0] == 'groupbar') {
			textStrPre = 'textgrouppre' + data[1];
			textStrPost = 'textgrouppost' + data[1];
		}

		textPreStyle = document.getElementById(textStrPre);
		textPostStyle = document.getElementById(textStrPost);

		textPreStyle.style.visibility = 'hidden';
		textPostStyle.style.visibility = 'hidden';
	}

	getXMax = () => {
		const { leftOffset, rightOffset } = this.getOffset();
		return (this.getWidth() - this.margin.left - this.margin.right - leftOffset - rightOffset);
	};
    getYMax = () => {
        let height = this.height;
        if (this.props.legendPosition == 'top' || this.props.legendPosition == 'bottom')
            height = this.height * 0.8;
        return height - this.margin.top - this.margin.bottom;
	};

	getYScale = () => {
		const data = this.getData().data;
		const yMax = this.getYMax();
		return scaleBand({
			range: [yMax, 0],
			domain: data.map(this.getMeasureId),
			// padding: 0.5,
		});
	};

	getYScaleAxis = () => {
		const data = this.getData().data;
		const yMax = this.getYMax();
		return scaleLinear({
			range: [yMax, 0],
			domain: [0, data.length]
		});
	};

	getXScale = () => {
		const metadata = this.getData().metadata;
		const xMax = this.getXMax();
		return scaleLinear({
			range: [0, xMax],
			domain: [metadata.min, metadata.max],
		});
	};

	renderGraph = () => {
		const { barStyling } = this.props;

        const data = this.getData().data;

		// scales
		const yScale = this.getYScale();
		const xScale = this.getXScale();

		const numTicks = data.length;
        const ticks = yScale.ticks ? yScale.ticks(numTicks) : yScale.domain();
		const gridHeight = Math.abs(yScale(ticks[0]) - yScale(ticks[1]));

		const userId = "user-gradient" + barStyling.componentName;
		const groupId = "group-avg-gradient" + barStyling.componentName;

		return (
			<Group className="graph">
				{data.map((d, i) => {
					const userBarWidth = Math.abs(xScale(this.getUserEndData(d) - this.getUserStartData(d)));
					const avgBarWidth = Math.abs(xScale(this.getGroupEndData(d) - this.getGroupStartData(d)));

					const userStart = this.getUserStartData(d) >= this.getUserEndData(d) ? xScale(this.getUserEndData(d)) : xScale(this.getUserStartData);
					const groupStart = this.getGroupStartData(d) >= this.getGroupEndData(d) ? xScale(this.getGroupEndData(d)) : xScale(this.getGroupStartData);

					const userPreTextPosition = this.getUserStartData(d) >= this.getUserEndData(d) ? xScale(this.getUserStartData(d)) : xScale(this.getUserEndData(d));
					const userPostTextPosition = this.getUserStartData(d) >= this.getUserEndData(d) ? xScale(this.getUserEndData(d)) : xScale(this.getUserStartData(d));

					const groupPreTextPosition = this.getGroupStartData(d) >= this.getGroupEndData(d) ? xScale(this.getGroupStartData(d)) : xScale(this.getGroupEndData(d));
					const groupPostTextPosition = this.getGroupStartData(d) >= this.getGroupEndData(d) ? xScale(this.getGroupEndData(d)) : xScale(this.getGroupStartData(d));

					return (
						<Group key={`bar-${this.getMeasureId(d)}`}>
							<Bar
								id = {`userbar-${i}`}
								onMouseEnter = {(d, i) => event => {
									this.mouseHoverEvent(event)
								}}
								onMouseLeave = {(d, i) => event => {
									this.mouseOutEvent(event)
								}}
								width={userBarWidth}
								height={20}
								x={userStart}
								y={ yScale(this.getMeasureId(d)) + gridHeight/2 - 20}
								fill={`url(#${userId})`}
								data={{ x: this.getMeasure(d), y: this.getUserStartData(d) }}
								rx={10}
							/>
							<Text
								style={{visibility: 'hidden'}}
								id = {`textuserpre${i}`}
								x={ userPreTextPosition }
								y={ yScale(this.getMeasureId(d)) + gridHeight/2 - 20 }
								textAnchor="end"
								verticalAnchor="end"
								fontSize={14}
								fontFamily={"Open Sans"}
								fill={`#d5d9e7`}
							>
							Pre
							</Text>
							<Text
								style={{visibility: 'hidden'}}
								id = {`textuserpost${i}`}
								x={ userPostTextPosition }
								y={ yScale(this.getMeasureId(d)) + gridHeight/2 - 20}
								textAnchor="start"
								verticalAnchor="end"
								fontSize={14}
								fontFamily={"Open Sans"}
								fill={`#d5d9e7`}
							>
							Post
							</Text>
							<Bar
								id = {`groupbar-${i}`}
								width={avgBarWidth}
								height={10}
								x={groupStart}
								y={yScale(this.getMeasureId(d)) + gridHeight/2 + 6}
								fill={`url(#${groupId})`}
								data={{ x: this.getMeasure(d), y: this.getGroupStartData(d) }}
								onMouseEnter = {(d, i) => event => {
									this.mouseHoverEvent(event)
								}}
								onMouseLeave = {(d, i) => event => {
									this.mouseOutEvent(event)
								}}
								rx={5}
							/>
							<Text
								style={{visibility: 'hidden'}}
								id = {`textgrouppre${i}`}
								x={ groupPreTextPosition }
								y={ yScale(this.getMeasureId(d)) + gridHeight/2 + 10}
								textAnchor="end"
								verticalAnchor="start"
								fontSize={14}
								fontFamily={"Open Sans"}
								fill={`#d5d9e7`}
							>
							Pre
							</Text>
							<Text
								style={{visibility: 'hidden'}}
								id = {`textgrouppost${i}`}
								x={ groupPostTextPosition }
								y={ yScale(this.getMeasureId(d)) + gridHeight/2 + 10}
								textAnchor="start"
								verticalAnchor="start"
								fontSize={14}
								fontFamily={"Open Sans"}
								fill={`#d5d9e7`}
							>
							Post
							</Text>
						</Group>
					);
				})}
			</Group>
		);
	}

    renderGraphComponent = () => {
		const { legendPosition, barStyling } = this.props;
		const leftTickLabelStyling = {
            fill: "#979eb7",
			textAnchor: "end",
			fontSize: 14,
			fontFamily: 'Open Sans'
		};

		const bottomTickLabelStyling = {
            fill: "#979eb7",
			textAnchor: "middle",
			fontSize: 14,
			fontFamily: 'Open Sans'
        };

        const labelStyling = {
            fontFamily: 'Open Sans',
			fontSize: 12,
			fill: '#979eb7',
			fillOpacity: 0.3,
			textAnchor: 'middle'
		};

		const legendFontSize = legendPosition == 'left' || legendPosition == 'right' ? '14px' : '16px';
		const legendLabelDirection = legendPosition == 'left' || legendPosition == 'right' ? 'column' : 'row';
		const legendItemMargin = legendPosition == 'left' || legendPosition == 'right' ? "0 0 9px 0" : "0 24px 0 0";
        const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: legendFontSize,
			fontFamily: 'Open Sans',
			color: '#979eb7',
            direction: legendLabelDirection,
            itemDirection: "row",
            labelMargin: "0",
            shapeMargin: "0 8px 0 0",
            itemMargin: legendItemMargin,
            shapeWidth: 16,
			shapeHeight: 16,
			marginLeft: this.margin.left
		};

        const data = this.getData().data;

        const width = this.getWidth();
		const height = this.height;
		const margin = this.margin;

		const xMax = this.getXMax();
		const yMax = this.getYMax();
		const { leftOffset, rightOffset } = this.getOffset();
		const numTicks = data.length;

		// scales
		const yScale = this.getYScale();
		const yScaleAxis = this.getYScaleAxis();
		const xScale = this.getXScale();

		const legendSize = scaleOrdinal({
			domain: ['Individual Score', 'Group Score'],
			range: [16]
		});

		const legendShape = scaleOrdinal({
			domain: ['Individual Score', 'Group Score'],
			range: [
				props => {
					const { width, height, size, fill } = props;
					return (<GlyphDot
						r={size/2}
						top={width / 2}
						left={height / 2}
						fill={fill}
					/>)
				}
			]
		});
		
		const userId = "user-gradient" + barStyling.componentName;
		const groupId = "group-avg-gradient" + barStyling.componentName;

		const userGradientBounds = getGradientBounds(barStyling.userRotation ? barStyling.userRotation : 0);
		const groupGradientBounds = getGradientBounds(barStyling.groupRotation ? barStyling.groupRotation : 0);
		
		const graphTitle = {
			display: 'flex', 
			justifyContent: 'flex-start',
			alignItems: 'center', 
			fontSize: '20px',
			fontWeight: 400,
			color: '#979eb7',
			paddingLeft: '48px',
			boxSizing: 'border-box'
		}

		const flexDirection = getFlexDirectionForGraphLegend(legendPosition);
		return(
			<div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: flexDirection}}>
				<div style={graphTitle}>
					Here's how you fared
				</div>
				<svg width={width} height={height} >
					<LinearGradient
						from={barStyling.fromUser ? barStyling.fromUser : 'green'}
						to={barStyling.toUser}
						{...userGradientBounds}
						id={userId}
					/>
					<LinearGradient
						from={barStyling.fromGroup}
						to={barStyling.toGroup}
						{...groupGradientBounds}
						id={groupId}
					/>
					<LinearGradient
						from={barStyling.fromUserLegend ? barStyling.fromUserLegend : 'green'}
						to={barStyling.toUserLegend}
						id={`legend-0-${barStyling.componentName}`}
					/>
					<LinearGradient
						from={barStyling.fromGroupLegend}
						to={barStyling.toGroupLegend}
						id={`legend-1-${barStyling.componentName}`}
					/>

					<Group top={margin.top} left={margin.left + leftOffset}>
						<GradientGrid scale={yScaleAxis} xMax={xMax} yMax={yMax} numTicks={numTicks} offset={{left: leftOffset, right: rightOffset}} />
						<AxisLeft
							scale={yScale}
							top={0}
							left={-leftOffset}
							stroke={'#1b1a1e'}
							tickTextFill={'#1b1a1e'}
							hideTicks={true}
							hideZero={true}
							numTicks={data.length}
							tickFormat={(value, index) => `${this.getMeasure(data[index])}` }
							tickLabelProps={(value, index) => leftTickLabelStyling}
							labelProps={labelStyling}
							stroke={'white'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
						/>
						<AxisBottom
							scale={xScale}
							top={yMax}
							stroke={'#1b1a1e'}
							hideAxisLine={true}
							hideTicks={true}
							hideZero={true}
							tickLabelProps={(value, index) => bottomTickLabelStyling}
							labelProps={labelStyling}
							stroke={'white'}
							strokeWidth={0.5}
							strokeDasharray={"1,3"}
						/>
						{ this.renderGraph() }
					</Group>
				</svg>
				<GraphLegendComponent
					style={legendStyling}
					shape={legendShape}
					size={legendSize}
					componentName={barStyling.componentName}
				/>
			</div>
        );
	}

	render() {
		const { data } = this.props;
		if (data.length == 0) {
			return <InfoComponent 
						{...this.props} 
						infoMessage="This graph does not have data"
					/>
		}
        return (this.renderGraphComponent());
    }
}

NeedleMovementGraph.propTypes = {
	data: PropTypes.object.isRequired,
	barStyling: PropTypes.object
}

NeedleMovementGraph.defaultProps = {
	legendPosition: 'bottom'
}

export default NeedleMovementGraph;