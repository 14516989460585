import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './thankyou.module.sass';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import MenuList from 'commonComponents/menuList';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import Footer from 'commonComponents/footer';
import applyLabel from 'utils/LabelProvider';
import routes from 'utils/webAppRoutes/routeConstants';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Thankyou extends Component {
    render() {
        const { 
            skinGuide
        } = this.props;
        const myStyles = getSkin(skinGuide);

        return (
            <div styleName="feedback-box-content">
                <div styleName="main-feedback-box">
                    <div className={css(myStyles.mainFeedbackBackground)} styleName="main-feedback-background"></div>
                    <div className={css(myStyles.mainFeedbackImage)} styleName="main-feedback-image"></div>
                    <div styleName="main-feedback-content" className={css(myStyles.text)}>
                        {this.props.getLabel('thanks_for_feedback_label')}
                    </div>
                </div>
            </div>
        );
    }
}

export default Thankyou;
