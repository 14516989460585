/**
 * @author: Amul
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/


 import React, { Component } from 'react';
 import { connect } from 'react-redux';
 import { fetchEntityFeedback } from 'actions/itemFeedback/apiActions';
 import { getActiveItemDetails, getActiveUserItem } from './containerHelpers/assessmentContainerHelper';
 import {
     submitAnswer,
     submitFeedback
 } from 'actions/itemFeedback/apiActions';
 import {
     setFeedbackCompletionStatus,
     setResponseToQuestion,
     setFeedbackCompletedTemp,
     resetUserData,
     resetFeedbackData
 } from 'actions/itemFeedback/actionCreators';
 
 import enumConstants from 'constants/enums';

 import PhaseItemFeedback from 'components/phaseItemFeedback/PhaseItemFeedback';

 
 class PhaseItemFeedbackContainer extends Component {
 
    componentDidMount() {
        const { batchId, itemId, phaseItem } = this.props; 
        this.props.fetchEntityFeedback(
            batchId,
            +itemId,
            enumConstants.get('ENTITY_TYPE_PHASE_ITEM'),
            getFeedbackScenario(phaseItem)
        );
    }
 
    componentWillUnmount() {
        this.props.resetFeedbackData();
    }    
 
    render() {
        console.log('debugitemfeedback: itemFeedback', this.props.itemFeedback.toJS())
        return (
            <PhaseItemFeedback {...this.props}/>
        );
    }
 }
 
 const mapStateToProps = state => ({
     selectedItem: getActiveItemDetails(state),
     selectedUserItem: getActiveUserItem(state),
     itemFeedback: state.get('itemFeedback'),
     itemId: state.get('userState').get('currentItemId'),
     loaders: state.get('loaders'),
     apiErrors: state.get('apiErrors'),
     feedbackStatuses: state.get('feedbackStatuses'),
     user: state.get('user')
 });

 const getFeedbackScenario = (item) => item.getIn(['settings', 'feedbackScenarioId']);
 
 const submitFeedbackFromContainer = (payload) => {
     return (dispatch, getState) => {
         const itemId = getState().get('userState').get('currentItemId');
         const entityType = enumConstants.get('ENTITY_TYPE_PHASE_ITEM');
         const scenario = enumConstants.get('SCENARIO_CONTENT');//todo: remove this hardcoing
         dispatch(submitFeedback(payload, itemId, entityType, scenario));
     }
 }
 
 const submitAnswerFromContainer = (payload, questionId) => {
     return (dispatch, getState) => {
         const itemId = getState().get('userState').get('currentItemId');
         const entityType = enumConstants.get('ENTITY_TYPE_PHASE_ITEM');
         const scenario = enumConstants.get('SCENARIO_CONTENT');//todo: remove this hardcoing
         dispatch(submitAnswer(payload, questionId, itemId, entityType, scenario));
     }
 }
 
 const mapDispatchToProps = dispatch => ({
    fetchEntityFeedback: (batchId, itemId, entityType, scenario = enumConstants.get('SCENARIO_CONTENT')) => {
         dispatch(fetchEntityFeedback(batchId, itemId, entityType, scenario))
     },
     setFeedbackCompletionStatus: (flag) => {
         dispatch(setFeedbackCompletionStatus(flag))
     },
     setResponseToQuestion: (payload, questionId) => {
         dispatch(setResponseToQuestion(payload, questionId))
     },
     setFeedbackCompletedTemp: (flag) => {
         dispatch(setFeedbackCompletedTemp(flag))
     },
     submitAnswer: (payload, questionId) => {
         dispatch(submitAnswerFromContainer(payload, questionId))
     },
     submitFeedback: (payload) => {
         dispatch(submitFeedbackFromContainer(payload))
     },
     resetUserData: (flag) => {
         dispatch(resetUserData(flag))
     },
     resetFeedbackData: () => {
         dispatch(resetFeedbackData())
     }
 
 });
 
 export default connect(mapStateToProps, mapDispatchToProps)(PhaseItemFeedbackContainer);
 