/**
 * @author: Amul
 * @since: Wed Oct 24 2018 12:22:31 GMT+0530 (IST)
 * @file: courses.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * course reducer is responsible for the sidebar with course and its modules
 *
 **/

import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
    courseForNextActivity: null,
    isCourseForNextActivityAvailable: false
});

const courseForNextActivity = (state = initialState, action) => {
    switch (action.type) {
        case actionConstants.get('SET_COURSE_FOR_NEXT_ACTIVITY'):
             
            return state.set(
                'courseForNextActivity',
                Immutable.fromJS(action.course)
            ).set(
                'isCourseForNextActivityAvailable',
                true
            );
        case actionConstants.get('UNSET_COURSE_FOR_NEXT_ACTIVITY'):
            return Immutable.fromJS(initialState);
        default:
            return state;
    }
}

export default courseForNextActivity;