import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules( { allowMultiple: true })
class ProgressBar extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (

            <svg width="100%" height="100%" viewBox="0 0 6 29">
                <path fill={this.props.fillColor} fill-rule="evenodd" d="M0 0h6v29H0z"/>
            </svg>
        );
    }
}



export default ProgressBar;
