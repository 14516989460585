import React from "react";
import { connect } from "react-redux";
import { generateSharableReportLink } from "actions/reports/apiActions";
import LinkedInShareCertificate from "commonComponents/linkedInShareCertificate";
import FetchLinkedinShareUrl from "actions/serverCalls/reports/FetchLinkedinShareUrl";

class LinkedInShareCertificateContainer extends React.Component {
  fetchLinkedinShareUrl = (params) => {
    return FetchLinkedinShareUrl.call(params);
  };
  render() {
    return (
      <LinkedInShareCertificate
        {...this.props}
        fetchLinkedinShareUrl={this.fetchLinkedinShareUrl}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.get("user"), // for userName
    reports: state.get("reports"),
    batches: state.get("batches"), // for batchId, batchName, journeyId, journeyInstanceId, journeyName
    userOrganization: state.get("userOrganization"), // for organizationName and organizationId
  };
};

const mapDispatchToProps = (dispatch) => ({
  generateSharableReportLink: (payload) => {
    generateSharableReportLink(payload);
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LinkedInShareCertificateContainer);
