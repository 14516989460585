import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		questionText: {
			...myTypography.h5,
			fontWeight: 'normal',
			color: '#2e3447',
			lineHeight: '1.5',
			'@media only screen and (max-width: 480px)': {
				fontSize: '18px',
				lineHeight: 1.3
			}
		},
		optionLabelText:{
			...myTypography.h6,
			lineHeight: 1.28,
			fontWeight: 'normal',
			backgroundColor: hexToRgbA(skinHex.get('greyColor5'), 0.2),
			color: hexToRgbA('#2e3447', 0.7),
			'@media only screen and (max-width: 480px)': {
				fontSize: '16px'
			}
		},
		selectedOptionLabelText:{
			...myTypography.h6,
			lineHeight: 1.28,
			fontWeight: 'normal',
			backgroundColor: skinHex.get('primaryColor'),
			color: skinHex.get('white')
		}
	});
}

export default getSkin;