import Immutable from 'immutable';
import actionConstants from 'constants/actions';
import { setOrReplaceObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS([]);

function setAlreadyAnswers(questionsData) {
	let questions = questionsData.quiz_submission_questions
	if (questions && questions.length > 0) {
		let qustionAnswers = [];
		let len = questions.length;
		for (let i = 0; i < len; i++) {
			if (questions[i].answer) {
				if (Array.isArray(questions[i].answer)) {
					if (questions[i].answer.length > 0) {
						qustionAnswers.push({ id: questions[i].id, answer: questions[i].answer });
					}
				} else {
					qustionAnswers.push({ id: questions[i].id, answer: questions[i].answer });
				}
			}
		}
		return Immutable.fromJS(qustionAnswers);
	}
	return initialState;
}

const quizAnswerSelected = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_QUESTION_ANSWER'):
			return setOrReplaceObjectInList(state, action.payload, 'id', 'id')
		case actionConstants.get('PREFILL_QUESTION_ANSWER'):
			return setAlreadyAnswers(action.payload)
		default:
			return state
	}
}

export default quizAnswerSelected;