import React from 'react';
import { connect } from 'react-redux';
import GenericErrorBoundary from 'components/errorBoundaries';
import {
	fetchCourseFeedbackData
} from 'actions/moduleItems/apiActions';
import Immutable from 'immutable';

import {
	startTracking,
	endTracking
} from 'utils/mixpanelTracking';

import {
	submitAnswer,
	submitFeedback
} from 'actions/itemFeedback/apiActions';
import {
	setResponseToQuestion,
} from 'actions/itemFeedback/actionCreators';
import enumConstants from 'constants/enums';
import setUserState from 'actions/userState/actionCreators';

import CourseFeedback from 'components/courseFeedback';

import { filterObjectFromArray } from 'utils/objectUtils';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import { getUserActiveBatchDetails } from 'utils/batchUtils';

import { fetchNextItemRouteParams } from 'actions/items/apiActions';

class CourseFeedbackContainer extends React.Component {
	componentWillMount() {
		this.props.fetchCourseFeedbackData(this.props.match.params, enumConstants.get('SCENARIO_COURSE'), enumConstants.get('ENTITY_TYPE_COURSE'));
	}

	componentDidMount() {
		this.props.setUserState({
			showStickyHeader: false
		});
		startTracking(true, this.props.match.params, 'CourseFeedbackScreen');
	}

	componentWillReceiveProps(nextProps){
		if(this.props.match.params.phaseItemId != nextProps.match.params.phaseItemId){
			endTracking();
			startTracking(true, nextProps.match.params, 'CourseFeedbackScreen');
		}
	}

	componentWillUnmount() {
		endTracking();
	}

	getPhase = () => {
		const { match, phases } = this.props;
		const phase = phases.get('phasesList').find(phase =>
			phase.get('id') == match.params.phaseId
		);
		return phase;
	}

	getPhaseItemData = () => {
		const userActiveBatchDetails = getUserActiveBatchDetails(this.props.batches);
		const { items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails
		);
		const phaseItemData = items.get('itemsList').find(item => +item.get('toolId') === +this.props.match.params.courseId);

		if(!phaseItemData) return null;

		const userPhaseItemData = filterObjectFromArray(this.props.items.get('userItemsList'), 'id', phaseItemData.get('id'));
		return phaseItemData.set('isCompleted', userPhaseItemData.get('workflowStatus') === enumConstants.get('COMPLETED'));	
	}

	render() {
		let phaseItemData = this.getPhaseItemData();
		const phase = this.getPhase();
		return (
			<GenericErrorBoundary>
				<div>
					{
						<CourseFeedback {...this.props}
							phaseItemData={phaseItemData}
							phase={phase}
						/>
					}
				</div>
			</GenericErrorBoundary>

		);
	}
}

CourseFeedbackContainer.propTypes = {};

CourseFeedbackContainer.defaultProps = {};


const mapStateToProps = (state) => {
	return {
		moduleItem: state.get('moduleItem'),
		tools: state.get('tools'),
		quiz: Immutable.fromJS(state.get('quiz')),
		course: state.get('course'),
		batches: state.get('batches'),
		phases: state.get('phases'),
		items: state.get('items'),

		fetchModuleItemError: state.get('apiErrors').get('fetchModuleItemError'),
		developmentProductLaunchError: state.get('apiErrors').get('developmentProductLaunchError'),

		userState: state.get('userState'),
		loaders: state.get('loaders'),
		courseFeedback: state.get('itemFeedback')
	}
};

const submitFeedbackFromContainer = (courseId, payload) => {
	return (dispatch, getState) => {
		const entityType = enumConstants.get('ENTITY_TYPE_COURSE');
		const scenario = enumConstants.get('SCENARIO_COURSE');
		dispatch(submitFeedback(payload, courseId, entityType, scenario));
	}
}

const submitAnswerForCourseFeedbackFromContainer = (payload, questionId, courseId) => {
	return (dispatch, getState) => {
		const entityType = enumConstants.get('ENTITY_TYPE_COURSE');
		const scenario = enumConstants.get('SCENARIO_COURSE');
		dispatch(submitAnswer(payload, questionId, courseId, entityType, scenario));
	}
}

const mapDispatchToProps = (dispatch) => ({
	fetchCourseFeedbackData: (params, scenario = 'REGULAR_COURSE', entityType = 'PHASEITEM') => {
		dispatch(fetchCourseFeedbackData(params, scenario, entityType))
	},
	setResponseToQuestion: (payload, questionId) => {
		dispatch(setResponseToQuestion(payload, questionId))
	},
	submitAnswerForFeedback: (payload, questionId, courseId) => {
		dispatch(submitAnswerForCourseFeedbackFromContainer(payload, questionId, courseId))
	},
	submitFeedback: (courseId, payload) => {
		dispatch(submitFeedbackFromContainer(courseId, payload))
	},
	setUserState: (payload) => {
		dispatch(setUserState(payload))
	},
	fetchNextItemRouteParams: (batchId, phaseId, currentPhaseItemId) => {
		return dispatch(fetchNextItemRouteParams(batchId, phaseId, currentPhaseItemId));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(CourseFeedbackContainer);