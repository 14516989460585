import React, { Component } from 'react';
import styles from './menuClose.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@CSSModules(styles, { allowMultiple: true })
class MenuClose extends Component {

    render() {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16">
                <path fill={this.props.fillColor || "#616881"} fillRule="nonzero" d="M.293 14.292a1 1 0 0 0 1.414 1.414l6.174-6.173a.167.167 0 0 1 .236 0l6.175 6.175a1 1 0 1 0 1.414-1.414L9.533 8.118a.167.167 0 0 1 0-.236l6.174-6.175A1 1 0 0 0 14.292.294L8.117 6.466a.167.167 0 0 1-.236 0L1.707.294A1 1 0 0 0 .293 1.707l6.173 6.175a.167.167 0 0 1 0 .236L.293 14.292z" />
            </svg>
        );
    }
}



export default MenuClose;
