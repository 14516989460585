import actionConstants from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
	organizationLevelUsersStatsLoading: false,
	organizationLevelUsersStats: {}
});

const organizationLevelUsersStats = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_ORGANIZATION_LEVEL_USER_STATS_LOADING'):
			return state.set(
				'organizationLevelUsersStatsLoading',
				action.flag
			)
		case actionConstants.get('SET_ORGANIZATION_LEVEL_USER_STATS'):
			return state.set(
				'organizationLevelUsersStats',
				Immutable.fromJS(action.userStats)
			)
		default:
			return state
	}
}

export default organizationLevelUsersStats;