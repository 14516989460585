import React, { Component } from 'react';
import styles from './msTeamsLeaderboard.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import getSkin from './skin';
import applySkin from 'utils/SkinProvider';
import { filterObjectFromArray, sortArrayOfObjects, searchObjectsInArray } from 'utils/objectUtils';
import AVATAR_IMG from 'images/avatar.png';
import applyLabel from 'utils/LabelProvider';
import InfoComponent from 'commonComponents/analytics/infoComponent';
import GraphLoader from 'svgComponents/graphLoader';
import SearchIcon from 'svgComponents/searchIcon';
import Tabs from 'commonComponents/tabs';
import { getTabsArrayForPhases } from 'utils/utilFunctions';
//import ApiError from 'commonComponents/errors/apiError';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Leaderboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedPhaseId: null,
			searchText: ''
		};
	}

	componentDidMount() {
		if (this.props.batchLeaderboardData.get('isUserDataAvailable')) {
			this.updateSelectedPhaseId(
				this.props.batchLeaderboardData.getIn(['data', 0, 'phaseId'])
			);
		}
	}

	componentWillReceiveProps(nextProps) {
		const currentPhaseId = this.props.batchLeaderboardData.getIn(['data', 0, 'phaseId']);
		const nextPhaseId = nextProps.batchLeaderboardData.getIn(['data', 0, 'phaseId']);

		if (!this.state.selectedPhaseId && !currentPhaseId && nextPhaseId) {
			this.updateSelectedPhaseId(nextPhaseId)
		}
	}


	onSearchChange = (event) => {
		this.setState({
			searchText: event.target.value
		});
	}

	updateSelectedPhaseId = (selectedPhaseId) => {
		this.setState({ selectedPhaseId });
	}

	renderTabs = (myStyles) => {
		const tabsList = getTabsArrayForPhases(this.props.batchLeaderboardData.get('data'));

		return <Tabs
			tabsList={tabsList}
			tabStyles={{ fontSize: '14px', borderBottom: 'solid 2px #2e344b', maxWidth: '130px' }}
			selectedTabValue={this.state.selectedPhaseId}
			tabClickHandler={this.updateSelectedPhaseId}
		/>;
	}

	formatLeaderboardData = (leaderboardData) => {
		return leaderboardData.map(user => {
			let score = user.score;
			let rank = user.rank;
			if (!rank || rank <= 0) {
				score = 'NA';
				rank = 'NA';
			}
			return {
				...user,
				score,
				rank
			};
		});
	}

	renderUsersList = (myStyles) => {
		let leaderboardData = filterObjectFromArray(
			this.props.batchLeaderboardData.get('data'),
			'phaseId',
			this.state.selectedPhaseId
		);

		leaderboardData = leaderboardData.get('users').toJS();

		leaderboardData = this.formatLeaderboardData(leaderboardData);

		if (this.state.searchText !== "") {
			leaderboardData = searchObjectsInArray(leaderboardData, 'userName', this.state.searchText);
		}

		leaderboardData = sortArrayOfObjects(leaderboardData, 'rank');

		if (leaderboardData.length === 0) {
			return (
				<div styleName="no-data-container">
					<InfoComponent
						skinGuide={this.props.skinGuide}
						infoMessage={this.props.getLabelWithDefaults('no_results_label', 'No Results')}
					/>
				</div>
			);
		}

		return leaderboardData.map((user, index) => {
			const avatarImg = (user.avatarUrl && user.avatarUrl !== "")
				? user.avatarUrl
				: AVATAR_IMG;

			const userNameStyle = user.userName ? 'user-name' : 'user-name text-center';

			return (
				<div
					className={css(myStyles.userContainer)}
					styleName="user-container"
					key={`${user.userName}-${index}`}
				>
					<div styleName="user-rank">{user.rank}</div>
					<div styleName="user-img">
						<img src={avatarImg} alt="img" styleName="avatar-img" />
					</div>
					<div styleName={userNameStyle}>{user.userName || '-'}</div>
					<div styleName="user-score">{user.score}</div>
				</div>
			);
		});
	}

	renderData = (myStyles) => {

		if (this.props.isLeaderboardDataFetching || this.props.isBatchesListDataFetching) {
			return (
				<div styleName="no-data-container">
					<GraphLoader />
				</div>
			);
		}

		// if (this.props.fetchLeaderboardError && this.props.selectedBatches.size !== 0) {
		// 	return (
		// 		<div styleName="no-data-container">
		// 			<ApiError
		// 				errorMessage={this.props.fetchLeaderboardError}
		// 				retryHandler={this.props.fetchLeaderboardData.bind(
		// 					this,
		// 					+this.props.match.params.journeyId,
		// 					this.props.selectedBatches.toJS()
		// 				)}
		// 			/>
		// 		</div>
		// 	);
		// }

		if (
			!this.props.batchLeaderboardData.get('isUserDataAvailable')
			|| !this.state.selectedPhaseId
		) {
			return (
				<div styleName="no-data-container">
					<InfoComponent
						skinGuide={this.props.skinGuide}
						infoMessage={this.props.getLabelWithDefaults('no_data_available_label', 'No Data Available')}
					/>
				</div>
			);
		}

		return (
			<div>
				<div
					className={css(myStyles.tabsContainer)}
					styleName="tabs-container"
				>
					{this.renderTabs(myStyles)}
				</div>
				<div styleName="users-container">
					{this.renderUsersList(myStyles)}
				</div>
			</div>
		);
	}

	getTitle = () => {
		return this.props.batchLeaderboardData.get('journeyName');
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div className={css(myStyles.leaderboardContainer)} styleName="leaderboard-container">
				<div styleName="title-search-container">
					<div className={css(myStyles.title)} styleName="title">
						{this.props.getLabelWithDefaults('leaderboard_label', 'Leaderboard')}:&nbsp;
						{this.getTitle()}
					</div>
					<div styleName="search-container">
						<div styleName="search-icon"><SearchIcon /> </div>
						<input
							type="text"
							placeholder={this.props.getLabelWithDefaults('search_label', 'Search')}
							className={css(myStyles.searchBox)}
							styleName="search-box"
							value={this.state.searchText}
							onChange={this.onSearchChange}
						/>
					</div>
				</div>
				{this.renderData(myStyles)}
			</div>
		);
	}
}

export default Leaderboard;
