import React, { Component } from 'react';
import styles from './tickMark.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TickMark extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		const fillColor = this.props.fillColor || skinHex.get('primaryColor');
		return (
			<div styleName="tick-mark-component">
				<svg width="100%" height="100%" viewBox="0 0 48 48">
					<g fill={fillColor} fillRule="nonzero">
						<path d="M36.96 12.898a2.498 2.498 0 0 0-3.494.53l-11.848 16.08-7.534-6.028a2.502 2.502 0 0 0-3.126 3.906l9.566 7.652a2.526 2.526 0 0 0 3.574-.47l13.4-18.174a2.5 2.5 0 0 0-.538-3.496z" />
						<path d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24 24-10.745 24-24C47.986 10.75 37.249.014 24 0zm0 44C12.954 44 4 35.046 4 24S12.954 4 24 4s20 8.954 20 20c-.012 11.04-8.96 19.988-20 20z" />
					</g>
				</svg>
			</div>
		);
	}
}



export default TickMark;
