import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		body: {
			...myTypography.body1,
			color: '#000000',
			lineHeight: 1.56
		},
		markCompleteButton: {
			...myTypography.overline,
			color: skinHex.get('primaryColor'),
			fontWeight: 600
		},
		completedText:{
			...myTypography.overline,
			color: skinHex.get('appleGreen'),
			fontWeight: 600
		}
	});
}

export default getSkin;