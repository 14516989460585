import React, { Component } from 'react';
import styles from './cameraIcon.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CameraIcon extends Component {

	render() {
		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 10 7">
					<path d="M9.54459 2.54522C9.54459 2.20771 9.41051 1.88401 9.17185 1.64535C8.93319 1.40669 8.60949 1.27261 8.27198 1.27261H7.31752C7.20059 1.26187 7.08967 1.21594 6.99936 1.1409L6.17662 0.318153C5.96713 0.123311 5.69451 0.0103776 5.4086 0H4.13599C3.85008 0.0103776 3.57746 0.123311 3.36797 0.318153L2.54522 1.14153C2.45492 1.21658 2.344 1.2625 2.22707 1.27325H1.27261C0.935094 1.27325 0.6114 1.40733 0.372739 1.64599C0.134078 1.88465 0 2.20834 0 2.54586V5.72739C0 6.06491 0.134078 6.3886 0.372739 6.62726C0.6114 6.86592 0.935094 7 1.27261 7H8.27198C8.60949 7 8.93319 6.86592 9.17185 6.62726C9.41051 6.3886 9.54459 6.06491 9.54459 5.72739V2.54522ZM3.18153 4.13599C3.18153 3.82136 3.27483 3.51381 3.44962 3.25221C3.62442 2.99061 3.87286 2.78671 4.16353 2.66631C4.45421 2.54591 4.77406 2.51441 5.08264 2.57579C5.39122 2.63717 5.67466 2.78868 5.89713 3.01115C6.11961 3.23362 6.27111 3.51707 6.33249 3.82564C6.39387 4.13422 6.36237 4.45407 6.24197 4.74475C6.12157 5.03542 5.91768 5.28386 5.65608 5.45866C5.39448 5.63346 5.08692 5.72675 4.77229 5.72675C4.3504 5.72675 3.94578 5.55915 3.64745 5.26083C3.34913 4.9625 3.18153 4.55788 3.18153 4.13599Z" fill={this.props.fillColor || skinHex.get('secondaryColor')} />
				</svg>
			</div>
		);
	}
}

export default CameraIcon;