import React, { Component } from 'react';
import styles from './appLoader.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import LoaderQuotes from 'constants/loaderQuotes';
import { checkIfPresent } from 'utils/utilFunctions';
import { random } from 'node-forge';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class AppLoader extends Component {

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const quotes = LoaderQuotes.get('quotes');
		const randomNum = this.props.userState && checkIfPresent(this.props.userState.get('appLoaderNumber'))
			? this.props.userState.get('appLoaderNumber')
			: Math.floor(Math.random() * 1000);

		const quote = quotes.get(randomNum % quotes.size)

		return (
			<div className="app-loader-container">
				<div className="app-loader-content">
					<div className="app-loader-circle">
						<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#d44980">
							<g fill="none" fillRule="evenodd">
								<g transform="translate(1 1)" strokeWidth="3">
									<circle stroke="e8eaf2" strokeOpacity=".1" cx="18" cy="18" r="18" />
									<path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(313.068 18 18)">
										<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
									</path>
								</g>
							</g>
						</svg>
					</div>
					<div className="app-loader-text">
						{quote.get('text')}
					</div>
					{
						checkIfPresent(quote.get('author'))
							? <div className="app-loader-author">
								-{quote.get('author')}
							</div>
							: null
					}

				</div>
			</div>
		);
	}
}

export default AppLoader;