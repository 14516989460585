import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import JourneyIntroductionPopup from 'components/userJourneys/journeyIntroductionPopup';
import {
	fetchJourneyInstanceDetails
} from 'actions/journeys/apiActions'
import {
	requestJourneyAccess,
	cancelJourneyAccessRequest,
	showJourneyInterest,
	removeJourneyInterest
} from 'actions/userJourneys/apiActions';
import { checkIfValueIsTrue } from 'utils/utilFunctions';
import { fetchJourneyDetails } from '../actions/journeys/apiActions';
import Immutable from 'immutable';

class JourneyIntroductionPopupContainer extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			isJourneyInstanceUpdatedInStore: false
		}
	}

	componentDidUpdate = (prevProps) => {
		if(this.props.journeyDetails !== prevProps.journeyDetails) {
			this.setState({
				isJourneyInstanceUpdatedInStore: true
			});
		}
	}

	componentDidMount() {
		const {
			journeyInstanceId,
			fetchJourneyInstanceDetails,
			userOrganization,
			journeyDetails,
			isRenderedInsideMyCourses
		} = this.props;
		const isExpressInterestEnabled = checkIfValueIsTrue(userOrganization.getIn(["userOrganization", "platformSettings", "expressInterestsEnabled"]));


		if(isExpressInterestEnabled && !isRenderedInsideMyCourses) {
			this.props.fetchJourneyDetails(journeyInstanceId);
		} else {
			if (journeyInstanceId) {
				fetchJourneyInstanceDetails(journeyInstanceId, isExpressInterestEnabled && !isRenderedInsideMyCourses)
			} else {
				alert("This batch is not having journey instance id")
			}
		}
	}


	render() {
		const { userOrganization, journeyDetails, isRenderedInsideMyCourses } = this.props;
		const isExpressInterestEnabled = userOrganization && checkIfValueIsTrue(userOrganization.getIn(["userOrganization", "platformSettings", "expressInterestsEnabled"]));
		
		if(isExpressInterestEnabled && !isRenderedInsideMyCourses) {
			let journeyDetailsMock;

			if(this.state.isJourneyInstanceUpdatedInStore) {
				journeyDetailsMock = 	Immutable.Map({
					isJourneyInstanceLoading: journeyDetails.get("isJourneyInstanceLoading"),
					journeyInstanceDetails: Immutable.Map({ 
						status: journeyDetails.getIn(["journeyInstanceDetails", "isInterested"]) ? "INTERESTED" : "NOT_ASSIGNED", 
						journey: journeyDetails.getIn(["journeyInstanceDetails"])})
				});
			} else {
				journeyDetailsMock = Immutable.Map({
					isJourneyInstanceLoading: true,
					journeyInstanceDetails: Immutable.Map({ journey: journeyDetails.getIn(["journeyInstanceDetails"])})
				});;
			}

			return <JourneyIntroductionPopup {...this.props} journeyDetails={journeyDetailsMock}/>; 
		}

		return <JourneyIntroductionPopup {...this.props} />;
	}
}

const mapStateToProps = (state) => {
	return ({
		userOrganization: state.get('userOrganization'),
		journeyDetails: state.get('selectedJourneyInstance'),
		isRequestingJourneyAccess: state.getIn(['batches', 'isRequestingJourneyAccess'])
	});
}

const mapDispatchToProps = (dispatch) => ({
	fetchJourneyInstanceDetails: (journeyInstanceId, isExpressInterestEnabled = false) => {
		dispatch(fetchJourneyInstanceDetails(journeyInstanceId, isExpressInterestEnabled));
	},
	requestJourneyAccess: (payload, searchText = "") => {
		dispatch(requestJourneyAccess(payload, searchText));
	},
	cancelJourneyAccessRequest: (payload, searchText = "") => {
		dispatch(cancelJourneyAccessRequest(payload, searchText))
	},
	showJourneyInterest: (payload, searchText = "") => {
		dispatch(showJourneyInterest(payload, searchText));
	},
	removeJourneyInterest: (payload, searchText = "") => {
		dispatch(removeJourneyInterest(payload, searchText));
	},
	fetchJourneyDetails: (journeyId) => {
		dispatch(fetchJourneyDetails(journeyId))
	}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JourneyIntroductionPopupContainer));