import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import {css} from 'aphrodite/no-important';
import styles from './takeQuizLoader.module.sass';
import getSkin from './skin.js';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';


@applySkin
@CSSModules(styles, {allowMultiple: true})

class TakeQuizLoader extends React.Component {

	getModuleItemCardLoader = (instances) => {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));

		let loaderContent = [];
		for(let i = 0; i< instances; i++){
			loaderContent.push(
				<div key={`loader_${i}`} styleName="question-loader">
					<ContentLoader
						height={200}
						width={800}
						speed={loadersDefaultconfig.get('speed')}
						primaryColor={loadersDefaultconfig.get('primaryColor')}
						secondaryColor={loadersDefaultconfig.get('secondaryColor')}
						style={{height: '100%', width: '100%'}}

					>	
						<rect x="60" y="0" rx="0" ry="0" width="100" height="12" />
						<rect x="60" y="25" rx="0" ry="0" width="500" height="20" />
						<rect x="60" y="70" rx="0" ry="0" width="330" height="40" /> 
						<rect x="420" y="70" rx="0" ry="0" width="330" height="40" /> 
						<rect x="60" y="130" rx="0" ry="0" width="330" height="40" /> 
						<rect x="420" y="130" rx="0" ry="0" width="330" height="40" /> 
					</ContentLoader>
				</div>
			);
		}
		return loaderContent;
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));

        return (
			<div styleName="loader-cnt">
				<div styleName="header-loader-cnt">
					<div >
						<ContentLoader
							height={20}
							width={215}
							speed={loadersDefaultconfig.get('speed')}
							primaryColor={loadersDefaultconfig.get('primaryColor')}
							secondaryColor={loadersDefaultconfig.get('secondaryColor')}
							style={{height: '100%', width: '100%'}}
						>
							<rect x="0" y="0" rx="0" ry="0" width="215" height="20"></rect>
						</ContentLoader>
					</div>
					<div >
						<ContentLoader
							height={55}
							width={240}
							speed={loadersDefaultconfig.get('speed')}
							primaryColor={loadersDefaultconfig.get('primaryColor')}
							secondaryColor={loadersDefaultconfig.get('secondaryColor')}
							style={{height: '100%', width: '100%'}}
						>
							<rect x="60" y="0" rx="0" ry="0" width="150" height="55"></rect>
						</ContentLoader>
					</div>
					<div >
						<ContentLoader
							height={40}
							width={280}
							speed={loadersDefaultconfig.get('speed')}
							primaryColor={loadersDefaultconfig.get('primaryColor')}
							secondaryColor={loadersDefaultconfig.get('secondaryColor')}
							style={{height: '100%', width: '100%'}}
						>
							<rect x="0" y="0" rx="0" ry="0" width="120" height="40"></rect>
							<rect x="150" y="0" rx="0" ry="0" width="120" height="40"></rect>
						</ContentLoader>
					</div>
				</div>
				
				<div styleName="question-content-loader-cnt">
					<div styleName="question-list-loader-cnt">
						{this.getModuleItemCardLoader(3)}
					</div>
					<div styleName="quiz-navigator-loader-cnt">
						<ContentLoader
								height={164}
								width={184}
								speed={loadersDefaultconfig.get('speed')}
								primaryColor={loadersDefaultconfig.get('primaryColor')}
								secondaryColor={loadersDefaultconfig.get('secondaryColor')}
								style={{height: '100%', width: '100%'}}
							>
							<rect x="0" y="0" rx="0" ry="0" width="50" height="10"></rect>
							<rect x="0" y="14" rx="0" ry="0" width="130" height="1"></rect>
							<circle cx="15" cy="30" r="5" />
							<circle cx="15" cy="60" r="5" />
							<circle cx="15" cy="90" r="5" />
							<circle cx="15" cy="120" r="5" />

							<circle cx="40" cy="30" r="5" />
							<circle cx="40" cy="60" r="5" />
							<circle cx="40" cy="90" r="5" />
							<circle cx="40" cy="120" r="5" />

							<circle cx="65" cy="30" r="5" />
							<circle cx="65" cy="60" r="5" />
							<circle cx="65" cy="90" r="5" />
							<circle cx="65" cy="120" r="5" />

							<circle cx="90" cy="30" r="5" />
							<circle cx="90" cy="60" r="5" />
							<circle cx="90" cy="90" r="5" />
							<circle cx="90" cy="120" r="5" />

							<circle cx="115" cy="30" r="5" />
							<circle cx="115" cy="60" r="5" />
							<circle cx="115" cy="90" r="5" />
							<circle cx="115" cy="120" r="5" />
						</ContentLoader>
					</div>
				</div>
			</div>
        );    
    }
}

export default TakeQuizLoader;