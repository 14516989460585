import {getUrlParams} from 'utils/urls';

const isMSTeams = () => {
    
    //console.log('msteams: currentlocation', window.location.href);
    if(document.referrer.includes("teams.microsoft.com")) return true;

    const queryParams = getUrlParams(window.location.href);
    
    if(queryParams['referrer'] && queryParams['referrer'].includes('msteams')) return true;
    
    if(document.referrer.includes("msteampersonaltab")) return true;

    if(window.localStorage.getItem('isMSTeams') === "true") return true;
    
    return false;
}

console.log('window.location.pathname', window.location.pathname);

const isMSTeamsChannelTab = window.location.pathname.includes("msteams_leaderboard");

export {
    getUrlParams,
    isMSTeams,
    isMSTeamsChannelTab
};
