import React, { Component } from 'react';
import styles from './devPhaseModuleItemOnlyContent.module.sass';
import CSSModules from 'react-css-modules';
import ModuleItemOnlyDevelopmentPhaseItem from './moduleItemOnlyDevelopmentPhaseItem';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import PhaseItemCardLoader from 'commonComponents/loaders/phaseItemCardLoader';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DevPhaseModuleItemOnlyContent extends Component {

    componentDidMount() {
        this.fetchModuleItems();
    }

    fetchModuleItems = () => {
        const onlyPhaseItem = this.props.phaseItems.get(0)
        this.props.fetchModuleItemsForSingleModuleCourse({
            batchId: this.props.match.params.batchId,
            phaseId: this.props.userPhase.get('id'),
            courseId: onlyPhaseItem.get('toolId'),
            phaseItemId: onlyPhaseItem.get('id')
        })
    }

    getModules = () => {
        const { coursesModulesList, phaseItems } = this.props;
        const onlyPhaseItem = phaseItems.get(0);
        const course = coursesModulesList.find(course => course.get('id') == onlyPhaseItem.get('toolId'));
        if (course && course.get('isCourseModulesListAvailable')) {
            return course.get('courseModulesList');
        }
        return null;
    }

    getModuleDetails = (modules) => {
        if (modules) {
            const module = modules.first();
            if (module) {
                const { modulesItemsList } = this.props;
                const moduleDetails = modulesItemsList.find(listItem => listItem.get('id') == module.get('id'));
                return moduleDetails;
            }
        }
        return null;
    }

    areItemsLoaded = (moduleDetails) => {
        if (moduleDetails && moduleDetails.get('isModuleItemsListAvailable')) {
            return true;
        }
        return false;
    }

    render() {
        const { courses, phaseItems, userPhase, match, apiErrors, feedbackStatuses } = this.props;
        const onlyPhaseItem = phaseItems.get(0);
        const courseModules = this.getModules();
        const moduleDetails = this.getModuleDetails(courseModules);

        if (apiErrors.get('fetchModuleError')) {
            return <APIErrorBoundary
                key={userPhase.get('id')}
                apiError={apiErrors.get('fetchModuleError')}
                retryHandler={this.fetchModuleItems}
            />
        }

        let params = {
            batchId: match.params.batchId,
            phaseId: userPhase.get('id'),
            courseId: onlyPhaseItem.get('toolId'),
            phaseItemId: onlyPhaseItem.get('id'),
            moduleId: onlyPhaseItem.get('toolSettings').get('moduleId')
        }




        return (
            this.areItemsLoaded(moduleDetails) ?
                <ModuleItemOnlyDevelopmentPhaseItem
                    courses={courses} {...params}
                    userPhase={userPhase}
                    onlyPhaseItem={onlyPhaseItem}
                    userItems={this.props.userItems}
                    setUserState={this.props.setUserState}
                    setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
                    activeItemId={this.props.activeItemId}
                    setItemWorkflowStatus={this.props.setItemWorkflowStatus}
                    phases={this.props.phases}
                    toggleDialog={this.props.toggleDialog}
                    moduleDetails={moduleDetails}
                    feedbackStatuses={feedbackStatuses}
                />
                :
                <div styleName="single-phase-container">
                    <div>
                        <PhaseItemCardLoader />
                        <PhaseItemCardLoader />
                        <PhaseItemCardLoader />
                    </div>
                </div>
        );
    }
}

export default DevPhaseModuleItemOnlyContent;
