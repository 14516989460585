import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        expContentTitle: {
            ...myTypography.h4,
            lineHeight: 1,
            color: skin.get('greyColor1')
        },
        expContentDesc: {
            ...myTypography.body1,
            lineHeight: 1.56,
            color: skin.get('greyColor1')
        },
        selectedCardDot: {
            backgroundColor: skin.get('primaryColor')
        },
        normalCardDot: {
            backgroundColor: skin.get('greyColor5')
        }
    });
}

export default getSkin;