import Immutable from 'immutable';

const competencyScale = Immutable.fromJS([
	{
		name: 'novice',
		text: 'novice_label',
		desc: 'novice_desc',
		min: 0,
		max: 1.50
	},
	{
		name: 'emerging',
		text: 'emerging_label',
		desc: 'emerging_desc',
		min: 1.51,
		max: 2.50
	},
	{
		name: 'competent',
		text: 'competent_label',
		desc: 'competent_desc',
		min: 2.51,
		max: 3.50
	},
	{
		name: 'proficient',
		text: 'proficient_label',
		desc: 'proficient_desc',
		min: 3.51,
		max: 4.50
	},
	{
		name: 'role model',
		text: 'role_model_label',
		desc: 'role_model_desc',
		min: 4.51,
		max: 5.00
	}
]);

export default competencyScale;