import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { updateBatchDetailedData } from 'actions/batches/actionCreators';
import { filterObjectFromArray } from 'utils/objectUtils';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import { appendQueryParameters } from 'utils/utilFunctions';

class FetchBatchDetails extends NetPackWrapper {

    constructor() {
        super("GET");
	}

	prepareUrl = (apiInfoObject = {}) => {
		const url = urls.get('PLATFORM').get('GET_BATCH_DETAILS').replace(
			'BATCH_ID',
			apiInfoObject.batchId
		);
		
		if(apiInfoObject.queryParameters && apiInfoObject.queryParameters['x-email']) {
			return appendQueryParameters(url, apiInfoObject.queryParameters);
		}

		return url;
	}
	
	/* shouldAjaxCall = (apiInfoObject) => {
		const batchesList = this.getState().get('batches').get('batchesList');
		const activeBatchDetails = filterObjectFromArray(batchesList, 'id', apiInfoObject.batchId);

		if (activeBatchDetails.get('isDetailedDataAvailable')) {
			return false;
		}
        return true;
	} */
	
	parser = (response) => ({
		...response,
		isDetailedDataAvailable: true
	})

    successCall = (response) => {
        this.dispatch(updateBatchDetailedData(response));
        return response;
	}
	
	onError = () => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong. Please refresh the page!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
        sendUserToInvalidPage();
    }

}

export default new FetchBatchDetails();
