import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules( { allowMultiple: true })
class ClockSvg extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (

            <svg width="100%" height="100%" viewBox="0 0 24 24">
                <g fill={this.props.fillColor} fill-rule="nonzero">
                    <path d="M19.159 7.927l1.548-1.487a.935.935 0 0 0 .273-.934.98.98 0 0 0-.714-.686 1.028 1.028 0 0 0-.973.262l-1.6 1.536A9.677 9.677 0 0 0 13 4.852V1.92h2c.552 0 1-.43 1-.96S15.552 0 15 0H9c-.552 0-1 .43-1 .96s.448.96 1 .96h2v2.932c-4.353.443-7.828 3.685-8.413 7.85-.585 4.165 1.87 8.175 5.946 9.71 4.076 1.534 8.716.196 11.237-3.24 2.52-3.437 2.268-8.082-.611-11.248v.003zM12 21.6c-4.418 0-8-3.438-8-7.68 0-4.242 3.582-7.68 8-7.68s8 3.438 8 7.68c0 2.037-.843 3.99-2.343 5.43-1.5 1.44-3.535 2.25-5.657 2.25z"/>
                    <path d="M12 7.68c-3.59 0-6.5 2.794-6.5 6.24s2.91 6.24 6.5 6.24 6.5-2.794 6.5-6.24a6.116 6.116 0 0 0-1.904-4.412A6.64 6.64 0 0 0 12 7.68zm.53 6.55a.782.782 0 0 1-1.06 0l-2.042-1.96a.7.7 0 0 1 0-1.018.773.773 0 0 1 1.061 0l2.041 1.96c.14.135.22.318.22.51 0 .19-.08.373-.22.508z"/>
                </g>
            </svg>
        );
    }
}



export default ClockSvg;
