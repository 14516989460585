import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		quizQuestionsOuterCnt:{
			backgroundColor: skinHex.get('greyColor6')
		},
		quizDataCnt:{
		},
		questionListCnt:{
			backgroundColor: skinHex.get('white')
		},
		questionNavigatorCnt: {
			
		}
	});
}

export default getSkin;