import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

	return StyleSheet.create({
        progress: {
            height: '4px',
            background: skin.get('secondaryColor'),
            transition:'width 500ms ease-in-out'
        },
        loadingText:{
            ...myTypography.overline,
            opacity: 0.7,
            fontWeight: 600,
            color: '#000000'
        }
	});
}

export default getSkin;