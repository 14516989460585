/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './PhaseItemCardLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class PhaseItemCardLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<ContentLoader
				height={120}
				width={800}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '100%', width: '100%' }}
			>
				<rect x="100" y="40" rx="2" ry="2" width="600" height="15" />
				<rect x="100" y="65" rx="2" ry="2" width="350" height="15" />
				<rect x="0" y="20" rx="4" ry="4" width="80" height="80" />
			</ContentLoader>
		);
	}
}

export default PhaseItemCardLoader;
