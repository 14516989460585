import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './progressTimeSpentAssesment.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent/InfoWithoutFilterComponent';
import InfoWithFilterComponent from 'commonComponents/analytics/infoWithFilterComponent';
import AreaWithLine from 'commonComponents/graphs/areaWithLine';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class ProgressTimeSpentAssesment extends React.Component {

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props)){
            return false;
        }
        else{
            return true;
        }
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        this.props.fetchAssessmentTimeSpentData(+batchId, +userId, +phaseId);
    }

    renderProgressTimeSpentOverlappingGraphs = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');

        if(this.props.apiErrors.get('fetchAssessmentTimeSpentDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchAssessmentTimeSpentDataError')}
                retryHandler={()=>{
                    this.props.fetchAssessmentTimeSpentData(+batchId, +userId, +phaseId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('assessment');

        if (allGraphsData.get('isAssesmentTimeSpentFetched')) {
            const graphData = allGraphsData.get('assesmentTimeSpentData');
            const strings = graphData.get('strings');
            const metadata = graphData.get('metadata');
            let yLabel, timeFormat = '';

            switch (metadata.get('units')) {
                case 'SECONDS':
                    yLabel = strings.get('timeInSecondsLabel');
                    break;
                case 'MINUTES':
                    yLabel = strings.get('timeInMinutesLabel');
                    break;
                case 'HOURS':
                    yLabel = strings.get('timeInHoursLabel');
                    break;
                case 'DAYS':
                    yLabel = strings.get('timeInDaysLabel');
                    break;
            }
            const graphFormat = {
                xLabel: strings.get('xAxisLabel'),
                yLabel: yLabel,
                valueFormat: timeFormat
            };
    
            const barStyling = {
                fromUser: 'rgba(254, 169, 40, 0.8)',
                toUser: 'rgba(254, 169, 40, 0.3)',
                userStroke: '#fda827',
                userRotation: 176,
                fromUserLegend: '#ffce23',
                toUserLegend: '#fea429',
                // fromGroup: 'rgba(254, 169, 40, 0.8)',
                // toGroup: 'rgba(254, 169, 40, 0.3)',
                // groupStroke: '#fda827',
                groupRotation: 180,
                // fromGroupLegend: '#ffce23',
                // toGroupLegend: '#fea429',
                gridValue: 6,
                componentName: 'asses-time',
                ...analyticGroupColors
            };

            return(
                <div styleName="progress-container">      
                    <InfoWithoutFilterComponent
                        {...this.props}
                        title={strings.get('graphTitle')}
                        subtitle={strings.get('graphDesc')}
                    />
                    <AreaWithLine
                        isUserDataAvailable={graphData.get('isUserDataAvailable')}
                        skinGuide={this.props.skinGuide}
                        graphFormat={graphFormat}
                        barStyling={barStyling}
                        // data={this.props.data.timeData}
                        size={this.props.size}
                        hasCurve={false}
                        graphData={graphData.toJS()}
                        strings={strings.toJS()}
                    />
                </div>
            )
        }
        return <LoaderComponent />;
    }

    render(){
  
        return(
            <div styleName="graph-container">
                <div styleName="graph-content">
                    {this.renderProgressTimeSpentOverlappingGraphs()}
                </div>
            </div>
        )
    }
}


export default ProgressTimeSpentAssesment;