const getFormattedPayloadDataOneLevel = (payload, keyName, zeroPointFlag = false) => {

    const receivedData = payload;

    let payloadData = {};
    let formattedData = [];
    receivedData.data.assessments.map((assessmentObject, i) => {
        formattedData[i] = {
            ...assessmentObject,
            id: assessmentObject.phaseItemId,
            itemName: assessmentObject.phaseItemName,
            groupValue: assessmentObject.groupValue,
            userValue: assessmentObject.userValue,
            completedUsersCount: assessmentObject.completedUsersCount,
        }
    });

    if(zeroPointFlag && formattedData.length == 1) {
        console.log("Inside the condition");
        formattedData.push(formattedData[0]);
        formattedData[0] = {
            id: 0,
            itemName: formattedData[0].phaseItemName,
            groupValue: 0,
            userValue: 0,
            completedUsersCount: 0,
            groupValueTooltip: "0",
            order: 1,
            phaseItemId: 0,
            phaseItemName: formattedData[0].phaseItemName,
            userValueTooltip: "0",
        }

        formattedData[1]['order']++;
    }

    payloadData[keyName] = formattedData;
    payloadData['metadata'] = receivedData.metadata;
    payloadData['isUserDataAvailable'] = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;
    payloadData['overallAverage'] = receivedData.data['overAllAverage'];
    payloadData['userAverage'] = receivedData.data['userAverage'];
    payloadData['strings'] = payload.strings;


    return payloadData;
}

// const getFormattedCourseDivisionData = (receivedData) => {

//     console.log(receivedData);

//     let formattedCourseData = [], formattedSectionData = [], formattedActivityData = [];

//     receivedData.courses.map((courseObject, i) => {
//         formattedCourseData[i] = {
//             id: courseObject.courseId,
//             itemName: courseObject.courseName,
//             groupValue: courseObject.overAllAverage,
//             userValue: courseObject.userAverage
//         }
//         courseObject.sections.map((sectionObject, j) => {
//             formattedSectionData[j] = {
//                 id: sectionObject.sectionId,
//                 itemName: sectionObject.sectionName,
//                 groupValue: sectionObject.overAllAverage,
//                 userValue: sectionObject.userAverage
//             }
//             sectionObject.activities.map((activityObject, k) => {
//                 formattedActivityData[k] = {
//                     id: activityObject.activityId,
//                     itemName: activityObject.activityName,
//                     groupValue: activityObject.overAllAverage,
//                     userValue: activityObject.userAverage
//                 }
//             })
//             formattedSectionData[j]['activities'] = formattedActivityData;
//             formattedSectionData[j]['activities']['overallAverage'] = receivedData.courses[i].sections[j]['overAllAverage'];
//             formattedSectionData[j]['activities']['userAverage'] = receivedData.courses[i].sections[j]['userAverage'];

//         })

//         formattedCourseData[i]['sections'] = formattedSectionData; 
//         formattedCourseData[i]['sections']['overallAverage'] = receivedData.courses[i]['overAllAverage'];
//         formattedCourseData[i]['sections']['userAverage'] = receivedData.courses[i]['userAverage'];   
//     })

//     return formattedCourseData;
// }

const getCourseFormattedData = (payload) => {
    const data = payload.data;
    const metadata = payload.metadata;
    let formattedCourseData = [];

    data.courses.map((courseObject, i) => {
        formattedCourseData[i] = {
            ...courseObject,
            id: courseObject.courseId,
            itemName: courseObject.courseName,
            groupValue: courseObject.overAllAverage,
            userValue: courseObject.userAverage,
            order: courseObject.order,
            sectionOverallAverage: courseObject.sectionOverAllAverage,
            sectionUserAverage: courseObject.sectionUserAverage,
            activitiesOverallAverage: courseObject.activityOverAllAverage,
            activitiesUserAverage: courseObject.activityUserAverage
        }
    });
    return {
        data: formattedCourseData,
        metadata: metadata,
        overallAverage: data['courseOverAllAverage'],
        userAverage: data['courseUserAverage'],
        courseOverallAverage: data['courseOverAllAverage'],
        courseUserAverage: data['courseUserAverage'],
        sectionOverallAverage: data['sectionOverAllAverage'],
        sectionUserAverage: data['sectionUserAverage'],
        activitiesOverallAverage: data['activityOverAllAverage'],
        activitiesUserAverage: data['activityUserAverage']
    };
}

const getSecionFormattedData = (payload) => {
    const data = payload.data;
    const metadata = payload.metadata;
    let formattedSectionData = [];

    data.sections.map((sectionObject, i) => {
        formattedSectionData[i] = {
            ...sectionObject,
            id: sectionObject.sectionId,
            itemName: sectionObject.sectionName,
            groupValue: sectionObject.overAllAverage,
            userValue: sectionObject.userAverage,
            order: sectionObject.order,
            courseId: sectionObject.courseId,
            activitiesOverallAverage: sectionObject.activityOverAllAverage,
            activitiesUserAverage: sectionObject.activityUserAverage
        }
    });
    return {
        data: formattedSectionData,
        metadata: metadata
    };
}

const getActivityFormattedData = (payload) => {
    const data = payload.data;
    const metadata = payload.metadata;
    let formattedActivityData = [];

    data.activities.map((activityObject, i) => {
        formattedActivityData[i] = {
            ...activityObject,
            id: activityObject.activityId,
            itemName: activityObject.activityName,
            groupValue: activityObject.overAllAverage,
            userValue: activityObject.userAverage,
            order: activityObject.order,
            courseId: activityObject.courseId,
            sectionId: activityObject.sectionId
        }
    });
    return {
        data: formattedActivityData,
        metadata: metadata
    };
}

const getFormattedPayloadDataMultipleLevel = (payload) => {
    let payloadData = {};

    payloadData['courses'] = getCourseFormattedData(payload);
    payloadData['sections'] = getSecionFormattedData(payload);
    payloadData['activities'] = getActivityFormattedData(payload);
   
    payloadData['isUserDataAvailable'] = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;
    
    payloadData['selectedCourseId'] = payload.selectedCourseId;
    payloadData['selectedSectionId'] = payload.selectedSectionId;

    payloadData['isCoursesGraphAvailable'] = payload.isCoursesGraphAvailable;
    payloadData['isSectionsGraphAvailable'] = payload.isSectionsGraphAvailable;
    payloadData['isActivitiesGraphAvailable'] = payload.isActivitiesGraphAvailable;
   
    payloadData['strings'] = payload.strings;

    return payloadData;
}


const getSlotFormattedData = (payload) => {
    let formattedData = {};

    formattedData['data'] = [];

    payload.map((eachSlotObj, i) => {
        formattedData['data'][i] = {
            id: eachSlotObj.slot,
            order: eachSlotObj.slot,
            ...eachSlotObj
        }
    })

    return formattedData;
}

const getFormattedPeriodicDataMultiLevelData = (payload) => {

    let payloadData = {};

    payloadData['month'] = getSlotFormattedData(payload.data.monthWiseData.data.slots)
    payloadData['month']['metadata'] = payload.data.monthWiseData.metaData;
    payloadData['month']['overallAverage'] = payload.data.monthWiseData.data['overAllAverage'];
    payloadData['month']['userAverage'] = payload.data.monthWiseData.data['userAverage'];

    payloadData['week'] = getSlotFormattedData(payload.data.weekWiseData.data.slots);
    payloadData['week']['metadata'] = payload.data.weekWiseData.metaData;
    payloadData['week']['overallAverage'] = payload.data.weekWiseData.data['overAllAverage'];
    payloadData['week']['userAverage'] = payload.data.weekWiseData.data['userAverage'];

    payloadData['day'] = getSlotFormattedData(payload.data.dayWiseData.data.slots);
    payloadData['day']['metadata'] = payload.data.dayWiseData.metaData;
    payloadData['day']['overallAverage'] = payload.data.dayWiseData.data['overAllAverage'];
    payloadData['day']['userAverage'] = payload.data.dayWiseData.data['userAverage'];

    payloadData['filtersEnabledObj'] = payload.filterData;
    payloadData['isUserDataAvailable'] = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;
    payloadData['strings'] = payload.strings;

    return payloadData;

}

const getFormattedInversePeriodicDataMultiLevelData = (payload) => {
    let payloadData = {};

    payloadData['month'] = getSlotFormattedData(payload.monthWiseData.data);
    payloadData['month']['metadata'] = payload.monthWiseData.metadata;

    payloadData['week'] = getSlotFormattedData(payload.weekWiseData.data);
    payloadData['week']['metadata'] = payload.weekWiseData.metadata;

    payloadData['day'] = getSlotFormattedData(payload.dayWiseData.data);
    payloadData['day']['metadata'] = payload.dayWiseData.metadata;

    payloadData['userRank'] = payload.userRank;

    payloadData['isUserDataAvailable'] = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;

    payloadData['filtersEnabledObj'] = payload.filterObj;

    payloadData['userScore'] = payload.userScore;
    payloadData['strings'] = payload.strings;

    return payloadData;

}

const getFormattedCourseDivisionLeaderboardData = (receivedData) => {

    let payloadData = {};
    let formattedCourseData = [], formattedSectionData = [], formattedActivityData = [];

    const data = receivedData.data;
    const metadata = receivedData.metadata;

    data.courses.map((courseObject, i) => {
        formattedCourseData[i] = {
            ...courseObject,
            id: courseObject.courseId,
            itemName: courseObject.courseName,
            users: courseObject.users
        }
    });
    data.sections.map((sectionObject, i) => {
        formattedSectionData[i] = {
            ...sectionObject,
            id: sectionObject.sectionId,
            itemName: sectionObject.sectionName,
            courseId: sectionObject.courseId,
            users: sectionObject.users
        }
    });
    data.activities.map((activityObject, i) => {
        formattedActivityData[i] = {
            ...activityObject,
            id: activityObject.activityId,
            itemName: activityObject.activityName,
            courseId: activityObject.courseId,
            sectionId: activityObject.sectionId,
            users: activityObject.users
        }
    });

    payloadData['courses'] = formattedCourseData;
    payloadData['sections'] = formattedSectionData;
    payloadData['activities'] = formattedActivityData;
    payloadData['isUserDataAvailable'] = 'isUserDataAvailable' in receivedData ? receivedData.isUserDataAvailable : true;
    payloadData['users'] = receivedData.data.users;
    payloadData['strings'] = receivedData.strings;

    return payloadData;
}

const getFormattedLeaderboardData = (payload) => {

    let payloadData = {};

    payloadData['data'] = [];
    payloadData['metadata'] = payload.metadata;

    payloadData['isCoursesAvailable'] = payload.isCoursesAvailable;
    payloadData['isSectionsAvailable'] = payload.isSectionsAvailable;
    payloadData['isActivitiesAvailable'] = payload.isActivitiesAvailable;

    payloadData['data'] = getFormattedCourseDivisionLeaderboardData(payload);
    payloadData['strings'] = payload.strings;

    return payloadData;
}

const getHierarchicalFormattedMeasuresData = (receivedData) => {
    let payloadData = {};
    const data = receivedData.data;
    const metadata = receivedData.metadata;
    let formattedCompetencyData = [], formattedSubCompetencyData = [], formattedTopicsData = [];

    if(data) {
        data['competencies'].map((competencyObject, i) => {
            formattedCompetencyData[i] = {
                ...competencyObject,
                id: competencyObject.measureId,
                itemName: competencyObject.measureName,
                userValue: competencyObject.userScore,
                groupValue: competencyObject.averageScore,
                measureType: competencyObject.measureType,
                order: competencyObject.order
            }
            formattedSubCompetencyData = [];
            if ('subCompetencies' in competencyObject && competencyObject['subCompetencies'].length > 0) {
                competencyObject['subCompetencies'].map((subCompetencyObject, j) => {
                    formattedSubCompetencyData[j] = {
                        ...subCompetencyObject,
                        id: subCompetencyObject.measureId,
                        itemName: subCompetencyObject.measureName,
                        userValue: subCompetencyObject.userScore,
                        groupValue: subCompetencyObject.averageScore,
                        competencyId: subCompetencyObject.competencyId,
                        measureType: subCompetencyObject.measureType,
                        order: subCompetencyObject.order
                    }
                    formattedTopicsData = [];
                    if ('topics' in subCompetencyObject && subCompetencyObject['topics'].length > 0) {
                        subCompetencyObject['topics'].map((topicObject, k) => {
                            formattedTopicsData[k] = {
                                ...topicObject,
                                id: topicObject.measureId,
                                itemName: topicObject.measureName,
                                groupValue: topicObject.averageScore,
                                userValue: topicObject.userScore,
                                competencyId: topicObject.competencyId,
                                subCompetencyId: topicObject.subCompetencyId,
                                measureType: topicObject.measureType,
                                order: topicObject.order
                            }
                        })
                    }
                    formattedSubCompetencyData[j]['topics'] = {
                        data: formattedTopicsData,
                        overallAverage: data['competencies'][i]['subCompetencies'][j]['overAllAverage'],
                        userAverage: data['competencies'][i]['subCompetencies'][j]['userAverage']
                    };
                })
            }
            formattedCompetencyData[i]['subCompetencies'] = {
                data: formattedSubCompetencyData,
                overallAverage: data['competencies'][i]['overAllAverage'],
                userAverage: data['competencies'][i]['userAverage']
            }
        })
    }
    
    payloadData = {
        competencies: {
            data: formattedCompetencyData,
            metadata: metadata
        }
    };

    payloadData['isUserDataAvailable'] = 'isUserDataAvailable' in receivedData ? receivedData.isUserDataAvailable : true;
    payloadData['isCompetenciesGraphAvailable'] = data ? data.isCompetenciesDataAvailable : false;
    payloadData['isSubCompetenciesGraphAvailable'] = data ? data.isSubCompetenciesDataAvailable : false;
    payloadData['isTopicsGraphAvailable'] = data ? data.isTopicsDataAvailable : false;
    payloadData['selectedCompetencyId'] = data ? data.selectedCompetencyId : false;
    payloadData['selectedSubCompetencyId'] = data ? data.selectedSubCompetencyId : false;
    payloadData['strings'] = receivedData.strings;

    return payloadData;
}

const getFormattedDevelopmentMeasuresData = (receivedData) => {
    let payloadData = {};
    const data = receivedData.data;
    const metadata = receivedData.metadata;
    let formattedCompetencyData = [], formattedSubCompetencyData = [], formattedTopicsData = [];

    data['competencies'].map((competencyObject, i) => {
        formattedCompetencyData[i] = {
            ...competencyObject,
            id: competencyObject.measureId,
            itemName: competencyObject.measureName,
            userValue: competencyObject.userAverage,
            groupValue: competencyObject.overAllAverage,
            measureType: competencyObject.measureType,
            order: competencyObject.order
        }
    });
    data['subCompetencies'].map((subCompetencyObject, i) => {
        formattedSubCompetencyData[i] = {
            ...subCompetencyObject,
            id: subCompetencyObject.measureId,
            itemName: subCompetencyObject.measureName,
            userValue: subCompetencyObject.userAverage,
            groupValue: subCompetencyObject.overAllAverage,
            competencyId: subCompetencyObject.competencyId,
            measureType: subCompetencyObject.measureType,
            order: subCompetencyObject.order
        }
    });
    data['topics'].map((topicObject, i) => {
        formattedTopicsData[i] = {
            ...topicObject,
            id: topicObject.measureId,
            itemName: topicObject.measureName,
            userValue: topicObject.userAverage,
            groupValue: topicObject.overAllAverage,
            competencyId: topicObject.competencyId,
            subCompetencyId: topicObject.subCompetencyId,
            measureType: topicObject.measureType,
            order: topicObject.order
        }
    });

    payloadData['competencies'] = {
        data: formattedCompetencyData,
        metadata: metadata
    };
    payloadData['subCompetencies'] = {
        data: formattedSubCompetencyData,
        metadata: metadata
    };
    payloadData['topics'] = {
        data: formattedTopicsData,
        metadata: metadata
    };

    payloadData['isUserDataAvailable'] = 'isUserDataAvailable' in receivedData ? receivedData.isUserDataAvailable : true;
    payloadData['isTopicsGraphAvailable'] = receivedData.isTopicsGraphAvailable;
    payloadData['strings'] = receivedData.strings;

    return payloadData;
}

const getFormattedAssessmentMeasuresData = (receivedData) => {
    let payloadData = {};
    const data = receivedData.data;
    const metadata = receivedData.metadata;
    let formattedCompetencyData = [], formattedSubCompetencyData = [];

    data['competencies'].map((competencyObject, i) => {
        formattedCompetencyData[i] = {
            ...competencyObject,
            id: competencyObject.measureId,
            itemName: competencyObject.measureName,
            userValue: competencyObject.userAverage,
            groupValue: competencyObject.overAllAverage,
            measureType: competencyObject.measureType,
            order: competencyObject.order
        }
    });
    data['behaviours'].map((subCompetencyObject, i) => {
        formattedSubCompetencyData[i] = {
            ...subCompetencyObject,
            id: subCompetencyObject.measureId,
            itemName: subCompetencyObject.measureName,
            userValue: subCompetencyObject.userAverage,
            groupValue: subCompetencyObject.overAllAverage,
            competencyId: subCompetencyObject.measureParentId,
            measureType: subCompetencyObject.measureType,
            order: subCompetencyObject.order
        }
    });

    payloadData['competencies'] = {
        data: formattedCompetencyData,
        metadata: metadata
    };
    payloadData['subCompetencies'] = {
        data: formattedSubCompetencyData,
        metadata: metadata
    };

    payloadData['isUserDataAvailable'] = 'isUserDataAvailable' in receivedData ? receivedData.isUserDataAvailable : true;
    payloadData['strings'] = receivedData.strings;
    return payloadData;
}

const getFormattedWidgetData = (receivedData) => {
    let formattedData = [];
    let payloadData = [];
    receivedData.map((data) => {
        
        const payload = data.widgetData;
        if (data.type === 'PERFORMANCE' && data.graphType !== 'Text') {
            if ('assessments' in payload.data) {
                data.widgetData = getFormattedPayloadDataOneLevel(payload, 'data');
                data.isUserDataAvailable = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;           
                data.strings = payload.strings;
            }
            else if (data.widgetName.includes('Course')) {
                data.widgetData = getCourseFormattedData(payload);
                data.isUserDataAvailable = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;
                data.widgetData.strings = payload.strings;
            }
            else if (data.widgetName.includes('Section')) {
                data.widgetData = getFormattedPayloadDataMultipleLevel(payload);
                // data.isUserDataAvailable = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;        
                // data.widgetData.strings = payload.strings;
            }
            formattedData.push(data);
        }
        else if (data.type === 'ACTIVITY' && data.graphType !== 'Text') {
            if ('assessments' in payload.data) {
                data.widgetData = getFormattedPayloadDataOneLevel(payload, 'data');
                data.isUserDataAvailable = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;
                data.strings = payload.strings;
            }
            else if (data.widgetName.includes('Course')) {
                data.widgetData = getCourseFormattedData(payload);
                data.isUserDataAvailable = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;
                data.widgetData.strings = payload.strings;
            }
            else if (data.widgetName.includes('Section')) {
                data.widgetData = getFormattedPayloadDataMultipleLevel(payload);
                // data.isUserDataAvailable = 'isUserDataAvailable' in payload ? payload.isUserDataAvailable : true;
                // data.widgetData.strings = payload.strings;
            }

            formattedData.push(data);
        }
        else {
            formattedData.push(data);
        }
    })
    
    return receivedData;
}


export {
    getFormattedPayloadDataOneLevel,
    getFormattedPayloadDataMultipleLevel,
    getFormattedPeriodicDataMultiLevelData,
    getFormattedInversePeriodicDataMultiLevelData,
    getFormattedLeaderboardData,
    getFormattedDevelopmentMeasuresData,
    getFormattedAssessmentMeasuresData,
    getFormattedWidgetData,
    getHierarchicalFormattedMeasuresData
}
