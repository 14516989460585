import { StyleSheet } from 'aphrodite';
import { typography, hexToRgbA } from 'utils/styleUtil';

const getSkin = (skinGuide) => {
	const myTypography = typography(skinGuide);

	return StyleSheet.create({
		leaderboardContainer: {
			...myTypography.cardBackground
		},
		title: {
			...myTypography.chartTitle
		},
		searchBox: {
			background: hexToRgbA(skinGuide.getIn(['skinHex', 'greyColor5']), 0.2),
			color: skinGuide.getIn(['skinHex', 'greyColor3']),
			fontSize: '14px',
			border: `solid 1px #5e77ff`,
			'::placeholder': {
				color: skinGuide.getIn(['skinHex', 'greyColor4'])
			}
		},
		tabsContainer: {
			boxShadow: `0 1px 1px 0 rgba(0, 0, 0, 0.16)`,
			backgroundImage: `linear-gradient(to bottom, #ffffff, #fafaff)`
		},
		userContainer: {
			...myTypography.header,
			fontSize: '14px',
			letterSpacing: '0.5px',
			color: skinGuide.getIn(['skinHex', 'greyColor3']),
			borderBottom: `solid 1px ${skinGuide.getIn(['skinHex', 'greyColor6'])}`
		},
		configureInputInstruction: {
			...myTypography.h6,
			color: 'rgba(9, 16, 39, 0.85)',
			fontWeight: `2px solid ${skinGuide.getIn(['skinHex', 'primaryColor'])}`
		},
		configureInput: {
			borderBottom: '1px solid rgba(44, 75, 183, 0.5)'
		},
		configureInputContainer: {
			borderTop: '2px solid #2c4bb7'
		},
		batchInputSuccessMessage: {
			...myTypography.caption
		},
		batchInputErrorMessage: {
			...myTypography.caption
		}
	});
}

export default getSkin;