import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setItemsList } from 'actions/items/actionCreators';
import developmentCourses from 'scripts/dummyData/developmentCourses';
import { appendQueryParameters, sortObjectArrayBasedOnKey } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchItems extends NetPackWrapper {

    constructor() {
        super("GET", true);
    }

    prepareUrl = (apiInfoObject = {}) => {
        let url =  urls.get('PLATFORM').get('GET_ITEMS');
        return appendQueryParameters(
            url,
            apiInfoObject.queryParameters
        );
    }

    /* shouldAjaxCall = () => {
        if (this.getState().get('items').get('itemsList').size > 0) {   //logic need to be changed to handle pagination
            return false;
        }
        return true;
    } */

    parser = (response) => {
        let updatedResponseItems = response.items;

        updatedResponseItems = updatedResponseItems.map((item) => ({
            ...item,
            itemType: item.type
        }));

        response.items = updatedResponseItems;

        return response;
    }

    successCall = (response) => {
        const myResponse = { ...response };

        let itemsList = myResponse.items;
        itemsList = sortObjectArrayBasedOnKey(itemsList, 'order');
        delete myResponse['items'];

        const itemsListAdditionalDetails = myResponse;

        const payload = {
            itemsList,
            itemsListAdditionalDetails
        };

        this.dispatch(setItemsList(payload));

        //this.dispatch(setApiError('fetchPhaseItemsError',null));

        return response;
    }

    onError = (errorObj) => {
        // this.dispatch(addToast({
        //     id: toastConstants.get('ID_ERROR_ITEM_LIST'),
        //     type: toastConstants.get('TYPE_ERROR'),
        //     heading: 'Something went wrong',
        //     message: 'Sorry, unable to fetch item list. Please refresh the page!',
        //     isDisplayed: false,
        //     autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        // }));
        // if (errorObj.apiInfoObject.showErrorPage) {
        //     sendUserToInvalidPage();
        // }
        console.log('on error', errorObj);
        this.dispatch(setApiError(
            'fetchPhaseItemsError',
            'There was a problem while fetching the content.'
        ));
    }

}

export default new FetchItems();
