import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './infoComponent.module.sass';
import InfoIcon from 'svgComponents/analytics/infoIcon';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';

@CSSModules(styles, { allowMultiple: true })

class InfoComponent extends React.Component {

    render(){

        const myStyles = getSkin(this.props.skinGuide);

        return(
            <div className={css(myStyles.infoContainerStyle)} styleName="info-container">
                <div styleName="info">
                    <div styleName="info-image">
                        <InfoIcon />
                    </div>
                </div>
                <div className={css(myStyles.textStyle)} styleName="info-text">
                    {this.props.infoMessage}
                </div>
            </div>
        )
    }
}


export default InfoComponent;