import { StyleSheet } from 'aphrodite';
import { hexToRgb, typography } from 'utils/styleUtil';
import FeedbackPattern from 'images/feedback_pattern.png';
import Certificate from 'images/certificate.png';
import { hexToRgbA } from '../../../../util/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		innerContent: {
			background: skin.get('greyColor6')
		},
		innerCeleb: {
			backgroundImage: `url(${FeedbackPattern})`,
            backgroundSize: 'contain'
		},
		titleStyle: {
			...myTypography.h5,
			color: hexToRgbA(skin.get('greyColor1'), 0.85)
		},

		titleDesc: {
			...myTypography.body1,
			fontWeight: 300,
			lineHeight: 'normal',
			color: hexToRgbA(skin.get('greyColor1'), 0.5)
		},
		certificateImage: {
			backgroundImage: `url(${Certificate})`,
			backgroundSize: 'contain'
		}
	});
}

export default getSkin;