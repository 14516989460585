import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {setAssignmentSubmitted, unsetUploadedFiles, setAssignmentSubmittingStatus} from 'actions/moduleItems/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

class SubmitAssignment extends NetPackWrapper {
	
	constructor() {
		super('POST', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('ASSIGNMENT_SUBMISSION')
			.replace('_COURSE_ID_', apiInfoObject.courseId)
			.replace('_ASSIGNMENT_ID_', apiInfoObject.assignmentId)
			.replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
			.replace('_BATCH_ID_', apiInfoObject.batchId)
			.replace('_PHASE_ID_', apiInfoObject.phaseId)
			.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	};

	beforeCall = apiInfoObject => {
		return apiInfoObject;
	};
  
	successCall = (response, apiInfoObject) => {
		this.dispatch(setAssignmentSubmitted(response));
		window.localStorage.removeItem(`assignment_${apiInfoObject.assignmentId}_text`);
	}

	onError = (errorObj) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to submit. Please try again!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }
}

export default new SubmitAssignment();
