import actionConsts from 'constants/actions';

const setMeasuresList = payload => ({
    type: actionConsts.get('SET_MEASURES_LIST'),
    payload
});

const setUserMeasuresList = (payload) => ({
    type: actionConsts.get('SET_USER_MEASURES_LIST'),
    payload
});

export {
    setMeasuresList,
    setUserMeasuresList
};
