import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreCardInfoComponent.module.sass';
import ScoreCardComponent from 'commonComponents/analytics/scoreCardComponent';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
@CSSModules(styles, { allowMultiple: true })

class ScoreCardInfoComponent extends React.Component {

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
		this.props.fetchOverallPerformanceData(+batchId, +userId);
    }

    render() {
        const allGraphsData = this.props.allGraphsData.get('performance');
        const strings = allGraphsData.get('overallPerformanceGraphData').get('strings');
        if (allGraphsData.get('isOverallPerformanceFetched')) {
            const fontStyling = {
                fontSize: '20px',
                color: '#979eb7',
                fontWeight: 400
            }
            const userData = allGraphsData.get('overallPerformanceGraphData');
            const rankCardData = {
                isUserScoreAvailable: userData.get('isUserDataAvailable'),
                cardTitle: strings.get('rankLabel'),
                showSubscore: false,
                value: userData.get('userRank').toJS(),
            }
            const scoreCardData = {
                isUserScoreAvailable: userData.get('isUserDataAvailable'),
                cardTitle: strings.get('scoreLabel'),
                showSubscore: true,
                value: userData.get('userScore').toJS(),
            }

            return (
                <div style={fontStyling} styleName="container-class">
                    <div styleName="outer-container">
                        <div styleName="inner-content">
                            <ScoreCardComponent
                                strings={strings}
                                cardData={rankCardData}
                                {...this.props}
                            />
                        </div>
                        <div styleName="inner-content">
                            <ScoreCardComponent
                                strings={strings}
                                cardData={scoreCardData}
                                {...this.props}
                            />
                        </div>
                    </div>
                </div>
            )
        }
        return <LoaderComponent />
    }
}

export default ScoreCardInfoComponent;