import React, { Component } from 'react';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import applyLabel from 'utils/LabelProvider';

@applyLabel
class NotAvailable extends Component {
    
    goToHome = () => {
        window.appHistory.push('/');
    }

    render() {
        return (
            <div style={{padding: 50}}>
                <center>
                    <div style={{padding: 20}}>
                        {this.props.children || "Feature not enabled"}
                    </div>
                    <PrimaryButton text="Back to Home" clickFunction={this.goToHome}/>
                </center>
            </div>
        )
    }
}

export default NotAvailable;
