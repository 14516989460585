import React, { Component } from 'react';
import styles from './nextItem.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import enumConstants from 'constants/enums';
import { filterObjectFromArray } from 'utils/objectUtils';
import LockIcon from 'svgComponents/lockIcon';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import { checkIfPresent } from 'utils/utilFunctions';
import ForwardArrow from 'svgComponents/forwardArrow';
import { replaceDatePlaceholderAndFormat } from 'utils/locks';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class NextItem extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false
		}
	}

	onNextItemMouseEnter = () => {
		this.setState({
			showTooltip: true
		});
	}

	onNextItemMouseLeave = () => {
		this.setState({
			showTooltip: false
		});
	}

	sendSegmentData = (nextItem, options = {}) => {
		let segmentData = {
			...(this.props.match.params),
			referrer: window.location.href,
			pathHash: window.location.hash,
			nextItem: nextItem,
			...options
		}
		window.analytics.track(
			'Next Item clicked',
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	goToDashboard = () => {
		this.sendSegmentData("Journey Dashboard");
		const { batchId } = this.props;
		const route = routes.get('BATCH');
		const params = [{ 'BATCH_ID': batchId }]
		updateRoute({ route, params });
	}

	goToCourseFeedback = () => {
		const { batchId, phaseId, phaseItemId, courseId, moduleId, moduleItemId } = this.props.match.params;
		//console.log(this.props.match.params);
		const route = routes.get('BATCH_ITEM_COURSE_FEEDBACK');
		// const params = [
		// 	{ 'BATCH_ID': batchId },
		// 	{ '_PHASEITEMID_': phaseItemId },
		// 	{ 'COURSE_ID': courseId }
		// ];
		// const route = routes.get('BATCH_ITEM_MODULE');
		this.sendSegmentData("Course Feedback");

		const params = [
			{ 'BATCH_ID': batchId },
			{ 'COURSE_ID': courseId },
			{ 'MODULE_ID': moduleId },
			{ 'ITEM_ID': moduleItemId },
			{ '_PHASE_ID_': phaseId },
			{ '_PHASEITEMID_': phaseItemId }
		];

		const routeObj = { route, params };
		// this.props.setPhaseWorkflowStatus(batchId, phaseId, enumConstants.get('COMPLETED'));
		updateRoute(routeObj);
	}

	goToJourneyDashboard = () => {
		this.sendSegmentData("Journey Dashboard");

		const { selectedItem } = this.props;
		const batchId = selectedItem.get('batchId');
		const route = routes.get('BATCH');
		const params = [{ 'BATCH_ID': batchId }]
		updateRoute({ route, params });
	}

	onClickOfNextItem = (userNextItemDetails = {}) => {
		const { selectedItem, nextItem, setSelectedItemId, onClickOfNext, isDevelopmentItem } = this.props;

		this.sendSegmentData(nextItem.get('name'), { nextItemId: nextItem.get('id') });

		if (isDevelopmentItem) {
			if (nextItem.get('isLocked')) {
				return onClickOfNext();
			}
		}

		if (nextItem.get('isLocked') && !nextItem.get('isExpired')) {
			return false;
		}
		if (onClickOfNext) {
			onClickOfNext();
			return;
		}
		const nextItemId = nextItem.get('id');
		const batchId = selectedItem.get('batchId');
		setSelectedItemId(nextItemId);
		if (this.props.setUserState) {
			this.props.setUserState({
				currentItemId: nextItemId
			});
		}
		const route = routes.get('BATCH_ITEM');
		const params = [{ 'BATCH_ID': batchId }, { 'ITEM_ID': nextItemId }]
		updateRoute({ route, params });
	}

	onClickOfSummary = () => {
		if (this.checkDisableSummary()) {
			return;
		}
		const { selectedItem } = this.props;
		const phaseId = selectedItem.get('phaseId');
		const batchId = selectedItem.get('batchId');
		const route = routes.get('PHASE_MEASURES');
		const params = [{ 'BATCH_ID': batchId }, { 'PHASE_ID': phaseId }];
		const routeObj = { route, params };
		this.props.setPhaseWorkflowStatus(batchId, phaseId, enumConstants.get('COMPLETED'), routeObj);
		// updateRoute(routeObj);
	}

	onClickOfPhaseFeedback = () => {
		if (this.checkDisableSummary()) {
			return;
		}
		const { selectedItem } = this.props;
		const phaseId = selectedItem.get('phaseId');
		const batchId = selectedItem.get('batchId');
		const route = routes.get('PHASE_FEEDBACK');
		const params = [{ 'BATCH_ID': batchId }, { 'PHASE_ID': phaseId }];
		const routeObj = { route, params };
		this.props.setPhaseWorkflowStatus(batchId, phaseId, enumConstants.get('COMPLETED'));
		updateRoute(routeObj);
	}

	renderNextItemSection = (
		myStyles, subHeadingLabel, mainLabel, clickHandler, isLocked, disableSummary
	) => {
		let contentStyle = 'content';
		const skinHex = this.props.skinGuide.get('skinHex');
		let { lockMessage, nextItem } = this.props;

		if (!lockMessage && nextItem) {
			const isLocked = nextItem.get('isLocked') || nextItem.get('isExpired');
			lockMessage = replaceDatePlaceholderAndFormat(isLocked, nextItem.get('lockExplanation'), nextItem.get('lockDate'));
		}

		if (disableSummary) {
			contentStyle += ' disable-section';
		}
		return (
			<div
				styleName="next-item-cnt"
				onClick={clickHandler}
				onMouseEnter={this.onNextItemMouseEnter}
				onMouseLeave={this.onNextItemMouseLeave}
			>
				<div styleName="arrow-cnt">
					<ForwardArrow />
				</div>

				{
					this.state.showTooltip &&
					<div styleName="next-item-tooltip-cnt">
						<div className={css(myStyles.nextItemTooltip)} styleName="next-item-tooltip">
							{
								subHeadingLabel ?
									<div className={css(myStyles.tooltipSubheading)}>{`${subHeadingLabel}: `}&nbsp;</div>
									: null
							}
							<div className={css(myStyles.tooltipContent)}>
								{mainLabel} {lockMessage && <div styleName="lock-message-cnt"> {lockMessage} </div>}
							</div>

						</div>
						<div className={css(myStyles.arrowDown)} styleName="arrow-down"></div>
					</div>
				}
			</div>
		)
		return (
			<div className={css(myStyles.container)} styleName="next-product-container" style={{
				position: disableSummary ? 'relative' : 'static'
			}}>
				<div styleName="line" />
				{
					(disableSummary && this.state.showTooltip)
						? <div styleName="disable-tooltip" className={css(myStyles.disableTooltip)}>{this.props.getLabel('summary_not_available')}</div>
						: null
				}
				<div styleName={contentStyle}>
					<div className={css(myStyles.subTitle)}>{subHeadingLabel}</div>
					<div
						styleName="product-next"
						className={disableSummary ? css(myStyles.productNextNoHover) : css(myStyles.productNext)}
						onClick={clickHandler}
						onMouseEnter={this.onNextItemMouseEnter}
						onMouseLeave={this.onNextItemMouseLeave}
					>
						<div className={css(myStyles.product)} styleName="product-main-label-cnt" title={mainLabel}>
							{
								isLocked ? <div styleName="lock-icon-cnt"><LockIcon svgColor={skinHex.get('greyColor4')} /></div> : null
							}
							{mainLabel}
						</div>
						<div className={css(myStyles.icon)} styleName="icon" />
					</div>
				</div>
			</div>
		);
	}

	renderFeedbackCompletionNextItemSection = (
		myStyles, subHeadingLabel, mainLabel, clickHandler, isLocked, disableSummary
	) => {
		let contentStyle = 'content-feedback';
		if (disableSummary) {
			contentStyle += ' disable-section';
		}
		return (
			<div style={{
				position: disableSummary ? 'relative' : 'static'
			}}>
				{
					(disableSummary && this.state.showTooltip)
						? <div styleName="disable-tooltip-feedback" className={css(myStyles.disableTooltip)}>{this.props.getLabel('summary_not_available')}</div>
						: null
				}
				<div styleName={contentStyle}>
					<div className={css(myStyles.subTitle)}>{subHeadingLabel}</div>
					<div
						styleName="product-next-feedback"
						className={disableSummary ? css(myStyles.productNextNoHover) : css(myStyles.productNext)}
						onClick={clickHandler}
						onMouseEnter={this.onNextItemMouseEnter}
						onMouseLeave={this.onNextItemMouseLeave}
					>
						<div styleName="display-flex" className={css(myStyles.product)}>
							{
								isLocked ? <div styleName="lock-icon-cnt"><LockIcon /></div> : null
							}
							{mainLabel}
						</div>
						<div className={css(myStyles.icon)} styleName="icon" />
					</div>
				</div>
			</div>
		);
	}

	renderContent = (
		subHeadingLabel, mainLabel, clickHandler, disableSummary = false, isLocked = false
	) => {
		const myStyles = getSkin(this.props.skinGuide);
		if (this.props.isFromFeedbackCompletion) {
			return this.renderFeedbackCompletionNextItemSection(
				myStyles, subHeadingLabel, mainLabel, clickHandler, isLocked, disableSummary
			);
		}
		return this.renderNextItemSection(
			myStyles, subHeadingLabel, mainLabel, clickHandler, isLocked, disableSummary
		);
	}

	checkDisableSummary = () => {
		const { selectedItem, activePhaseDetails } = this.props;
		const { phases, items } = setAvailabilityFlags(this.props.phases, this.props.items, this.props.userActiveBatchDetails);
		let allItemsCompleted = true;
		const phaseId = selectedItem.get('phaseId');
		const phaseItemsList = items.get('itemsList').filter(
			item => item.get('phaseId') == phaseId
		);

		phaseItemsList.map(item => {
			const userItemDetails = filterObjectFromArray(items.get('userItemsList'), 'id', item.get('id'))
			if (userItemDetails.get('workflowState') !== enumConstants.get('COMPLETED')) {
				allItemsCompleted = false;
			}
		});

		const isPhaseLocked = activePhaseDetails.isLocked || (activePhaseDetails.isExpired && !allItemsCompleted);

		if (isPhaseLocked) { return true; }


		return !allItemsCompleted;
	}

	shouldShowCourseFeedback = () => {
		const { phaseItemData } = this.props;
		const enabledInSettings = phaseItemData &&
			phaseItemData.getIn(['settings', 'isFeedbackEnabled']) === 'true' &&
			phaseItemData.getIn(['settings', 'feedbackScenarioId']);
		if (enabledInSettings) {
			if (phaseItemData.get('isExpired') && !phaseItemData.get('isCompleted')) {
				return false;
			}
		}

		return enabledInSettings;
	}

	render() {
		const {
			nextItem,
			getLabel,
			getLabelWithDefaults,
			isFeedbackScreen,
			activePhaseDetails,
			items,
			phaseItemData
		} = this.props;


		const subHeadingLabel = getLabelWithDefaults('tooltip_next_activity_label', 'Next Activity');

		if (!nextItem) {
			if (this.shouldShowCourseFeedback()) {
				return this.renderContent('', getLabel('course_feedback_label'), this.goToCourseFeedback, false, false);
			} else {
				const mainLabel = getLabel('go_to_dashboard_label');
				return this.renderContent('', mainLabel, this.goToDashboard, false, false);
			}
		}

		if (this.props.isDevelopmentItem) {
			if (nextItem && nextItem.size) {
				const mainLabel = nextItem.get('title') || getLabel(nextItem.get('name'));
				return this.renderContent(
					subHeadingLabel, mainLabel, () => this.onClickOfNextItem(), false, nextItem.get('isLocked') || false
				);
			}
			return null;
		}

		const userNextItemDetails = filterObjectFromArray(
			items.get('userItemsList'), 'id', nextItem.get('id')
		);

		const isLocked = nextItem.get('isLocked') && !nextItem.get('isExpired');
		if (nextItem && nextItem.size) {
			const mainLabel = nextItem.get('title') || getLabel(nextItem.get('name'));
			return this.renderContent(
				subHeadingLabel, mainLabel, () => this.onClickOfNextItem(userNextItemDetails), false, isLocked
			);
		}

		//if (this.props.selectedItem) {
		const isFeedbackOrExpired = isFeedbackScreen || this.props.selectedItem.get('isExpired');
		//}


		if (isFeedbackOrExpired && activePhaseDetails) {
			if (
				checkIfPresent(activePhaseDetails.settings)
				&& checkIfPresent(activePhaseDetails.settings.isReportScreenAvailable)
				&& activePhaseDetails.settings.isReportScreenAvailable == "false"
			) {
				const mainLabel = getLabel('go_to_dashboard_label');
				return this.renderContent('', mainLabel, this.goToJourneyDashboard, false, false);
			}
			const disableSummary = this.checkDisableSummary();

			if (disableSummary) {
				return <span></span>;
			}

			if (
				activePhaseDetails.settings &&
				activePhaseDetails.settings.isFeedbackEnabled &&
				activePhaseDetails.settings.isFeedbackEnabled === 'true' &&
				activePhaseDetails.settings.feedbackScenarioId
			) {
				const mainLabel = getLabelWithDefaults(
					'phase_feedback_label',
					`${getLabel(activePhaseDetails.name)} Phase Feedback`,
					{ phase_name: activePhaseDetails.name }
				)
				return this.renderContent(subHeadingLabel, mainLabel, this.onClickOfPhaseFeedback, disableSummary, isLocked);
			} else {
				if (
					activePhaseDetails.settings &&
					activePhaseDetails.type === 'Learning'
				)
					return null;
				const mainLabel = `${getLabel(activePhaseDetails.name)} ${getLabel('summary_label')}`;
				return this.renderContent(subHeadingLabel, mainLabel, this.onClickOfSummary, disableSummary, isLocked);
			}

		}
		return null;
	}
}

export default NextItem;
