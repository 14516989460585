import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './needleMovementWithInfo.module.sass';
import NeedleMovementGraph from 'commonComponents/graphs/needleMovementGraph';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class NeedleMovementWithInfo extends React.Component {

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        this.props.fetchNeedleMovementData(+batchId, +userId);
    }

    renderNeedleMovementWithInfo = () => {
        let allGraphsData = this.props.allGraphsData.get('endOfJourney');

        if (allGraphsData.get('isNeedleMovementDataFetched')) {
            const needleStyling = {
                fromUser: '#ffce23',
                toUser: '#fea429',
                userRotation: -108,
                fromUserLegend: '#ffce23',
                toUserLegend: '#fea429',
                // fromGroup: '#3956ff',
                // toGroup: '#1b3dff',
                groupRotation: -94,
                // fromGroupLegend: '#3956ff',
                // toGroupLegend: '#1b3dff',
                ...analyticGroupColors,
                gridValue: 7,
                componentName: 'end-of-journey-needle-movement'
            }

            const discStyle ={
                width: '8px',
                height: '8px',
                borderRadius: '50%',
                backgroundImage: 'linear-gradient(to bottom, #26fa96, #52f9aa)'
            }

            const listFontStyle = {
                fontSize: '12px',
                fontWeight: 'normal',
                color: '#979eb7',
            }

            return(
                [
                    <div styleName="needle-graph">
                        <NeedleMovementGraph
                            skinGuide={this.props.skinGuide}
                            size={this.props.size}
                            data={this.props.data.needleMovementData}
                            barStyling={needleStyling}
                            graphData={allGraphsData.get('needleMovementData').toJS()}
                        />
                    </div>,
                    <div styleName="needle-graph-content">
                        <div styleName="needle-content">
                            <div styleName="needle-list">
                                <div styleName="needle-disc">
                                    <div style={discStyle}></div>
                                </div>
                                <div style={listFontStyle} styleName="needle-list-content">
                                    Over a million users are utilizing the power of Animaker to boost 
                                    their personal brands and businesses.
                                </div>
                            </div>
                            <div styleName="needle-list">
                                <div styleName="needle-disc">
                                    <div style={discStyle}></div>
                                </div>
                                <div style={listFontStyle} styleName="needle-list-content">
                                    Over a million users are utilizing the power of Animaker to 
                                    boost their personal brands and businesses.
                                </div>
                            </div>
                        </div>
                    </div>
                ]
            )
        }
        return <LoaderComponent />
    }

    render(){
        return(
            <div styleName="needle-graph-container">
                {this.renderNeedleMovementWithInfo()}
            </div>
        );
    }
}

export default NeedleMovementWithInfo;