import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import FeedbackBackground from 'images/feedback_pattern.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        assessmentBorder: {
            borderTop:  `solid 8px ${skin.get('secondaryColor')}`
        },
        completedTitleContainer: {
            background: `linear-gradient(267deg, #3d486f , ${hexToRgbA(skin.get('greyColor2'), 1)})`
        },
        completedTitleImage: {
            backgroundImage: `url(${FeedbackBackground})`,
            backgroundSize: "cover",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
        },
        completedContent: {
            color: skin.get('white'),
            ...myTypography.h6
        }
    });
}

export default getSkin;