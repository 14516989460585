import React, { Component } from 'react';
import styles from './videosIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class VideosIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<path fill="#828593" fillRule="nonzero" d="M12.634 0H1.366C.612 0 0 .612 0 1.366v11.268C0 13.388.612 14 1.366 14h11.268c.754 0 1.366-.612 1.366-1.366V1.366C14 .612 13.388 0 12.634 0zM9.887 7.705l-4.045 2.311a.71.71 0 0 1-1.06-.614V4.78a.71.71 0 0 1 1.06-.614l4.045 2.31a.709.709 0 0 1 0 1.23z"/>
				</svg>
			</div>
		);
	}
}

export default VideosIcon;
