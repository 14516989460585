/**
 * @author: Amul
 * @since: Thu Aug 30 2018 14:52:31 GMT+0530 (IST)
 * @file: courses.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
  Handles part of the start for courses list and module screen (dev phase)
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    isCoursesListAvailable: false,
    coursesList: null,
    isLoadedModuleAvailable: false,
    loadedModule: null,
    activeCourseIdInSidebar: null,
    coursesListProgress:[]
});

const journeyCoursesInfo = (state = initialState, action = {}) => {
    switch (action.type) {
        // case actionConsts.get('SET_COURSES_LIST'):
        //     return state.set(
        //         'coursesList', Immutable.fromJS(action.coursesList)
        //     ).set('isCoursesListAvailable', true);
        case actionConsts.get('SET_LOADED_MODULE'):
            return state.set(
                'loadedModule', Immutable.fromJS(action.module)
            ).set('isLoadedModuleAvailable', true);
        case actionConsts.get('SET_LOADED_MODULE_AVAILABILITY'):
            return state.set('isLoadedModuleAvailable', action.status);
        case actionConsts.get('SET_ACTIVE_COURSE_ID_SIDEBAR'):
            console.log('setting active course');
            if(state.get('activeCourseIdInSidebar') == action.courseId){
                return state.set('activeCourseIdInSidebar', null);
            }
            return state.set('activeCourseIdInSidebar', action.courseId);
        /* case actionConsts.get('SET_COURSES_PROGRESS_LIST'):
            return state.set('coursesListProgress', Immutable.fromJS(action.coursesListProgress)); */
        // case actionConsts.get('SET_USER_ACTIVE_BATCH_DETAILS'):
        //     return state.set(
        //         'userActiveBatchDetails', Immutable.fromJS(action.userActiveBatchDetails)
        //     ).set('isUserActiveBatchDetailsAvailable', true);
        // case actionConsts.get('SET_ACTIVE_BATCH_ID'):
        //     return state.set(
        //         'activeBatchId', action.activeBatchId
        //     ).set('isActiveBatchAvailable', true);
        // case actionConsts.get('SET_ACTIVE_BATCH_DETAILS'):
        //     return state.set(
        //         'activeBatchDetails', Immutable.fromJS(action.activeBatchDetails)
        //     ).set('isActiveBatchDetailsAvailable', true);
        // case actionConsts.get('UPDATE_BATCH_FROM_ROUTE'):
        //     return state.set('batchIdFromRoute', +action.batchIdFromRoute);
        // case actionConsts.get('UPDATE_WORKFLOW_STATUS'):
        //     return state.set('workflowState', action.workflowStatus);
        default:
            return state;
    }
};

export default journeyCoursesInfo;