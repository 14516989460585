import Immutable from 'immutable';
import { getDevPhaseAPIUrl } from 'utils/apiUrl';

const apiURL = getDevPhaseAPIUrl();

const developmentPhaseUrls = () => {
    let urls =  Immutable.Map({
        COURSES_LIST: `${apiURL}/ad/courses?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&per_page=1000`,
        
        COURSE_MODULES_LIST: `${apiURL}/ad/courses/_COURSE_ID_/modules?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&per_page=1000&lang=LANG`,

        MODULE_ITEMS_LIST: `${apiURL}/ad/courses/_COURSE_ID_/modules/_MODULE_ID_/items?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&per_page=1000&include[]=content_details&lang=LANG`,

        COURSE_MODULE: `${apiURL}/ad/courses/_COURSE_ID_/modules/_MODULE_ID_?include[]=items&include[]=content_details&batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&per_page=1000`,

        MODULE_ITEM_DETAILS: `${apiURL}/ad/courses/_COURSE_ID_/module_item_sequence?include[]=content_details&content_tag_id=_CONTENT_TAG_ID&radius=1&batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&lang=LANG`,

        COURSE: `${apiURL}/ad/courses/_COURSE_ID_/modules?include[]=modules&include[]=items&include[]=content_details&batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&per_page=1000&lang=LANG`,


        COURSE_WITH_MODULES: `${apiURL}/ad/courses/_COURSE_ID_?per_page=1000&batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        PRODUCT_LAUNCH: `${apiURL}/ad/courses/_COURSE_ID_/assignments/_ASSIGNMENT_ID_/product_launch?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&return_url=_RETURNURL_`,

        MARK_MODULE_ITEM_READ: `${apiURL}/ad/courses/_COURSE_ID_/modules/_MODULE_ID_/items/_ITEM_ID_/mark_read?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        MARK_MODULE_ITEM_DONE: `${apiURL}/ad/courses/_COURSE_ID_/modules/_MODULE_ID_/items/_ITEM_ID_/done?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        UPDATE_MEDIA_FILE_STATUS: `${apiURL}/ad/courses/_COURSE_ID_/items/_ITEM_ID_/file_status?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        QUIZ_SUBMISSIONS: `${apiURL}/ad/courses/_COURSE_ID_/quizzes/_QUIZ_ID_/submissions?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&per_page=1000`,

        QUIZ_SUBMISSION_QUESTIONS: `${apiURL}/ad/quiz_submissions/_QUIZ_SUBMISSION_ID_/questions?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&per_page=1000`,

        ANSWER_QUIZ_QUESTIONS: `${apiURL}/ad/quiz_submissions/_QUIZ_SUBMISSION_ID_/questions?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        FLAG_QUIZ_QUESTION: `${apiURL}/ad/quiz_submissions/_QUIZ_SUBMISSION_ID_/questions/_QUESTION_ID_/flag?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        UNFLAG_QUIZ_QUESTION: `${apiURL}/ad/quiz_submissions/_QUIZ_SUBMISSION_ID_/questions/_QUESTION_ID_/unflag?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        COMPLETE_QUIZ_SUBMISSION: `${apiURL}/ad/courses/_COURSE_ID_/quizzes/_QUIZ_ID_/submissions/_QUIZ_SUBMISSION_ID_/complete?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        QUIZ_ATTEMPT_REVIEW: `${apiURL}/quiz_submissions/_QUIZ_SUBMISSION_ID_/questions?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&quiz_submission_attempt=_QUIZ_SUBMISSION_ATTEMPT_`,

        QUIZ_ATTEMPT_SEEN: `${apiURL}/ad/courses/_COURSE_ID_/quizzes/_QUIZ_ID_/submissions/_QUIZ_SUBMISSION_ID_/set_seen_result?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_&quiz_submission_attempt=_QUIZ_SUBMISSION_ATTEMPT_`,

        COURSE_LIST: `${apiURL}/ad/development_summary?include[]=courses&per_page=1000`,

        ASSIGNMENT_SUBMISSION: `${apiURL}/ad/courses/_COURSE_ID_/assignments/_ASSIGNMENT_ID_/submissions.json?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        NEXT_ACTIVITY_ITEM: `${apiURL}/ad/courses/_COURSE_ID_/next_activity_item?batchId=_BATCH_ID_&phaseId=_PHASE_ID_&phaseItemId=_PHASEITEMID_`,

        URL_WITH_ERROR: 'http://localhost:3000/',

        UPLOAD_FILE: `${apiURL}/ad/files/upload`,

        FIREBASE_TOKEN: `${apiURL}/ad/firebase-auth`
    });

    return urls;
};
export default developmentPhaseUrls;
