import actionConstants from 'constants/actions';

const quizAttemptExhausted = (state = false, action) => {
	switch (action.type) {
		case actionConstants.get('SET_QUIZ_ATTEMPT_EXPIRED'):
			return action.flag;
		default:
			return state;
	}
}

export default quizAttemptExhausted