/**
 * @author: sourabh p
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: header.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Single point source for header, exports all the header
 *
 **/

import Immutable from 'immutable';
import environment from 'utils/environment';

const header = Immutable.Map({
    'DEFAULT_KALTURA_ID': '0_822rg07l',

    'DEFAULT_PRIMARY_COLOR': '#2C4BB7',

    'TERMS_CONDITION_URL': 'https://accounts.knolskape.com/user/terms-and-conditions?service=aktivlearn&variant=default&type=&continue=https%3A%2F%2Faccounts.knolskape.com%2Fv1%2Foauth%2Fauthorize%3Fservice%3Daktivlearn%26client_id%3Daktivlearn%26redirect_uri%3Dhttps%3A%2F%2Fwww.aktivlearn.com%2Flogin%2Foauth2%2Fcallback%26scope%3Dopenid%26state%3DeyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhYWNfaWQiOjEwMDAwMDAwMDAwMDA0LCJub25jZSI6ImNkZWE0YWNhYzRmYjBkMWNmYzA2NmM1OTgzNjFhNTA3MGM4Mjk1YWU0OWY0YWU3ZCJ9.Y_wNH73rBS9Cm6Gq3KJoiUEPLxzMGEhhAaJ1dam-l2w%26response_type%3Dcode%26variant%3Ddefault%26variant%3Ddefault&demostorylineid=',

    'DEFAULT_FRESHDESK_USER_EMAIL': 'support@knolskape.com',

    'DEFAULT_FRESHDESK_EMAIL': 'https://knolskape.freshdesk.com',

    'DEFAULT_FRESHDESK_BATCH_ID': '',

    'DEFAULT_FRESHDESK_PRODUCT_ID': 1000006847,

    'DEFAULT_FRESHDESK_PRODUCT_NAME': environment.REACT_APP_PRODUCT_NAME || 'AktivLearn Plus',

    'DEFAULT_FRESDESK_BUTTON_NAME': 'SUPPORT',

    'DEFAULT_FRESHDESK_BUTTON_COLOR': '#000000',

    'DEFAULT_FRESHDESK_BUTTON_TEXT_COLOR': '#FFFFFF',

    'DEFAULT_MIN_DESIRABLE_RANGE': 3,

    'DEFAULT_MAX_DESIRABLE_RANGE': 5
});

export default header;
