/*
    helper for module item progression

*/
import store from 'store/store';

import {
    setModuleItemCompletionStatus,
    setNextModuleItemLockStatus
} from 'actions/moduleItems/actionCreators';


const isCompleted = (moduleItem) => {
    if(moduleItem.get('completion_requirement')){
        return moduleItem.get('completion_requirement').get('completed');
    }
    return false;
};

// const checkAndUpdateModuleProgression = (moduleItem) => {
//     let {moduleItem} = store.getState();
// };

const onModuleItemComplete = (moduleItem, nextModuleItem) => {
    store.dispatch(setNextModuleItemLockStatus(false));
};

const ContextModuleProgression = {
    onModuleItemComplete,
    isCompleted
};

export default ContextModuleProgression;