import React, { Component } from 'react';
import styles from './toastOverlay.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { ToastContainer, toast } from 'react-toastify';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ToastOverlay extends Component {

	constructor(props) {
		super(props);
	}

	myToast = (closeToast) => {
		return (
			<div style={{width: '250px', height: '250px'}}>
				<button onClick={() => {closeToast();}}>Close</button>
				{this.props.userState.get('toastMessage')}
			</div>
		);
	}

	componentDidMount() {
		// this.notify();
	}

	notify = () => {
        toast(this.myToast(this.closeToast), { autoClose: 7000, position: toast.POSITION.BOTTOM_RIGHT });
	};
	
	closeToast = () => {
		this.props.setUserState({
			showToast: false,
			toastMessage: ''
		});
	}

	render() {
		return (
			<div styleName="toast-overlay-container">
				<ToastContainer
					closeButton={false}
					hideProgressBar={true}
				/>
			</div>
		);
	}
}

export default ToastOverlay;