import React, { Component } from 'react';
import styles from './classroomsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ClassroomsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			// <div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<g fill="none" fillRule="evenodd">
						<path stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".783" d="M7.415 12.713a.583.583 0 1 1-.825.824.583.583 0 0 1 .825-.824M1.46 1.458h11.084v9.917H1.461zM7 11.375v1.167"/>
						<path stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".783" d="M3.5 9.042l1.553-2.655a.531.531 0 0 1 .808-.175l1.126 1.144 1.057-1.803a.583.583 0 0 1 .513-.303c.217.024.404.16.493.359l1.742 3.433H3.5zM5.58 3.465a.875.875 0 1 1-1.238 1.237.875.875 0 0 1 1.237-1.237"/>
						<path fill="#7E818F" d="M13.708 1.5c0 .276-.26.5-.583.5H.875c-.322 0-.583-.224-.583-.5s.26-.5.583-.5h12.25c.322 0 .583.224.583.5z"/>
						<path stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".783" d="M.875 11.375h12.25"/>
					</g>
				</svg>
			// </div>
		);
	}
}

export default ClassroomsIcon;
