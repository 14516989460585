import { combineReducers } from 'redux';
import quizQuestionsLoading from './quizQuestionsLoading';
import quizAttemptExhausted from './quizAttemptExhausted';
import quizSubmissions from './quizSubmissions';
import quizQuestions from './quizQuestions';
import activeAttempt from './activeAttempt';
import quizAnswerSelected from './quizAnswerSelected';
import quizAttemptReviewData from './quizAttemptReviewData';

const quiz = combineReducers({
	quizQuestionsLoading: quizQuestionsLoading,
	quizSubmissions: quizSubmissions,
	activeAttempt: activeAttempt,
	quizQuestions: quizQuestions,
	quizAnswerSelected: quizAnswerSelected,
	quizAttemptReviewData: quizAttemptReviewData,
	quizAttemptExhausted: quizAttemptExhausted
});

export default quiz;