import React, { Component } from 'react';
import styles from './skillCard.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import SkillSvg from 'svgComponents/skillSvg';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SkillCard extends Component {
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div key={this.props.key} className={css(myStyles.card)} styleName="card">
                <div styleName="card-image">
                    <div styleName="card-image-main">
                        <SkillSvg />
                    </div>
                </div>
                <div className={css(myStyles.cardTitle)} styleName="card-title">
                    {this.props.getLabel(this.props.skillData.get('name'))}
                </div>
                <div className={css(myStyles.cardDesc)} styleName="card-desc"
                    dangerouslySetInnerHTML={{
                        __html: this.props.getLabel(this.props.skillData.get('description'))
                    }}
                >
                    {/* {this.props.getLabel(this.props.skillData.get('description'))} */}
                </div>
            </div>
        )
    }
}

export default SkillCard;
