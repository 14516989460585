import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import styles from './moduleItemTypes.module.sass';
import getSkin from './skin.js';
import {css} from 'aphrodite/no-important';
import AssignmentsIcon from './assignments'
import BitesIcon from './bites'
import ClassroomsIcon from './classroomSessions'
import PdfsIcon from './pdfs'
import QuizzesIcon from './quizzes'
import ScormsIcon from './scorms'
import SimulationsIcon from './simulations'
import VideosIcon from './videos'
import WikiPagesIcon from './wikiPages'
import WebSessions from './webSessions';
import ExternalUrlsIcon from './externalUrls'
import PresentationsIcon from './presentation'
import GenericFilesIcon from './genericFiles'

@applySkin
@CSSModules(styles, {allowMultiple: true})

class ModuleItemTypes extends React.Component {
	getIcon = (itemType, itemSubType = null) => {
		switch(itemType){
		case 'assignment':
			return <AssignmentsIcon />;
		case 'bite':
			return <BitesIcon />;
		case 'classroom session':
			return <ClassroomsIcon />;
		case 'quiz':
			return <QuizzesIcon />;
		case 'scorm':
			return <ScormsIcon />;
		case 'simulation':
			return <SimulationsIcon />;
		case 'video', 'video/mp4':
			return <VideosIcon />;
		case 'pdf':
			return <PdfsIcon />;
		case 'externalurl':
			return <ExternalUrlsIcon />
		case 'weblink':
			return <WebSessions />;
		case 'page':
			return <WikiPagesIcon />;
		case 'presentation':
			return <PresentationsIcon />
		default:
			return <GenericFilesIcon />;
		}
	}	
	render() {
		const {itemType} = this.props;
		var itemSubType = this.props.itemSubType || null;
		return 	(this.getIcon(itemType, itemSubType))
   }
}

export default ModuleItemTypes;