import React, { Component } from 'react';

class SessionBellIcon extends Component {
	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 11 13" fill="none">
				<path d="M6.35635 11.375H3.93402C3.86519 11.3751 3.8074 11.4269 3.79969 11.4953C3.79378 11.5452 3.79089 11.5955 3.79102 11.6458C3.79102 12.3937 4.3973 13 5.14519 13C5.89307 13 6.49935 12.3937 6.49935 11.6458C6.49948 11.5955 6.49659 11.5452 6.49069 11.4953C6.48345 11.4266 6.42537 11.3746 6.35635 11.375Z" fill="#918C8C"/>
				<path d="M10.2619 10.4412C9.64193 9.20894 9.31809 7.84905 9.31612 6.46967V6.05367C9.34659 4.41521 8.56203 2.86842 7.22203 1.92508C6.83767 1.67731 6.40795 1.50829 5.95778 1.42783V0.8125C5.95778 0.363769 5.59401 0 5.14528 0C4.69655 0 4.33278 0.363769 4.33278 0.8125V1.43487C3.77094 1.54128 3.24196 1.77822 2.78849 2.12658C1.61756 3.09124 0.94983 4.53675 0.97445 6.05367V6.46967C0.972477 7.84905 0.64864 9.20894 0.0286998 10.4412C-0.013327 10.525 -0.00896036 10.6247 0.0402418 10.7045C0.0894439 10.7844 0.176474 10.8331 0.270283 10.8333H10.0203C10.1142 10.8332 10.2014 10.7844 10.2507 10.7044C10.2999 10.6244 10.3042 10.5245 10.2619 10.4406V10.4412Z" fill="#918C8C"/>
			</svg>
		);
	}
}



export default SessionBellIcon;
