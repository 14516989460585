/**
 * @author: ujjwal
 * @since: Thu Sep 27 2018 09:20:31 GMT+0530 (IST)
 * @file: performance.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { getFormattedInversePeriodicDataMultiLevelData,
    getFormattedLeaderboardData } from 'utils/analytics/transformationFunctions';



const initialState = Immutable.fromJS({

    leaderboardData: [],
    isLeaderboardFetched: false,

    overallPerformanceGraphData: [],
    isOverallPerformanceFetched: false,


});

const performance = (state = initialState, action = {}) => {
    switch (action.type) {
        
        /*Leaderboard section data */

        case actionConsts.get('LEADERBOARD_DATA'):
            const leaderboardData = getFormattedLeaderboardData(action.payload);
            return state.set(
                'leaderboardData',
                Immutable.fromJS(leaderboardData)
            ).set(
                'isLeaderboardFetched',
                true
            );
        
        /*Overall performance section data */

        case actionConsts.get('OVERALL_PERFORMANCE_DATA'):

            const overallPerformance = getFormattedInversePeriodicDataMultiLevelData(action.payload);

            return state.set(
                'overallPerformanceGraphData',
                Immutable.fromJS(overallPerformance)
            ).set(
                'isOverallPerformanceFetched',
                true
            );
            
        default:
            return state;
    }
};

export default performance;