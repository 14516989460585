import { connect } from 'react-redux';
import defaultLabels from 'constants/labels';

const mapStateToProps = ((state) => {
    const activeBatchId = state.get('batches').get('activeBatchId');

    let labels = state.get('labels').get(`${activeBatchId}`);
    if(state.get('labels').get('areOrgLabelsSet')){
        labels = state.get('labels').get(`org_${state.getIn(['userOrganization','userOrganization','id'])}`);
    }

    return {
        isBatchLabelsAvailable: labels !== undefined ? true : false,

        getLabel: (key, vars = {}) => {

            let label = key;

            if(defaultLabels !== undefined && defaultLabels.get(key) !== undefined){
                label = defaultLabels.get(key);
            }

            if (labels !== undefined && labels.get(key) !== undefined) {
                label = labels.get(key);
            }

            if (key === null || key === undefined) {
                label = key;
            }

            for (var varKey in vars) {
                if (vars.hasOwnProperty(varKey)) {
                    label = label.replace(`%{${varKey}}`, vars[varKey]);
                }
            }

            return label;
        },

        getLabelWithDefaults: (key, defaultValue = "", vars = {}) => {

            let label = defaultValue;

            if(defaultLabels !== undefined && defaultLabels.get(key) !== undefined){
                label = defaultLabels.get(key);
            }

            if (labels !== undefined && labels.get(key) !== undefined) {
                label = labels.get(key);
            }

            if (key === null || key === undefined) {
                label = defaultValue;
            }

            for (var varKey in vars) {
                if (vars.hasOwnProperty(varKey)) {
                    label = label.replace(`%{${varKey}}`, vars[varKey]);
                }
            }

            return label;
        },

        formLabelForModuleItem: (type_label, sub_type = "default") => {
            if(type_label == "ExternalUrl"){
                type_label = sub_type;
            }
            return type_label.toLowerCase().split(" ").join("_") + "_label";
        }

    };
})

const mapDispatchToProps = () => ({})

const mergeProps = (propsFromState, propsFromDispatch, ownProps) => ({
    ...propsFromState,
    ...propsFromDispatch,
    ...ownProps
})

const applyLabel = (WrappedComponent) => connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(WrappedComponent);

export default applyLabel;