import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import FeedbackPattern from 'images/feedback_pattern.png';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		feedbackContent: {
			
		},
		mainFeedbackBackground: {
			backgroundImage: `linear-gradient(107deg, #5782dc, ${skin.get('primaryColor')})`,
			boxShadow: `0 2px 13px 0 ${hexToRgbA(skin.get('black'), 0.14)}`,
			borderRadius: '4px'
        },
        mainFeedbackImage: {
            backgroundImage: `url(${FeedbackPattern})`,
            backgroundSize: 'contain'
		},
		feedbackMainHeading: {
            ...myTypography.h6,
            color: skin.get('white')
        },
        assessmentResultText: {
            ...myTypography.h6,
            fontSize: '18px',
            fontWeight: 400,
            color: skin.get('white')
        }
	});
}

export default getSkin;