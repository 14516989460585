import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreCardInfoWithBars.module.sass';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent/InfoWithoutFilterComponent';
import OverlappingBars from 'commonComponents/graphs/overlappingBars';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import analyticGroupColors from 'constants/analyticGroupColors';

@CSSModules(styles, { allowMultiple: true })

class ScoreCardInfoWithBars extends React.Component {

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
		this.props.fetchAssessmentScoreData(+batchId, +userId, +phaseId);
    }

    renderCompetencyDetails = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        if(this.props.apiErrors.get('fetchAssessmentScoreDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchAssessmentScoreDataError')}
                retryHandler={()=>{
                    this.props.fetchAssessmentScoreData(+batchId, +userId, +phaseId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('assessment');

        if (allGraphsData.get('isAssesmentScoreFetched')) {
            const graphData = allGraphsData.get('assesmentScoreData').toJS();
            const barStyling = {
                fromUser: '#26fa96',
                toUser: '#52f9aa',
                userRotation: 0,
                fromGroup: analyticGroupColors.fromGroup,
                toGroup: analyticGroupColors.toGroup,
                groupRotation: 356,
                gridValue: 6,
                componentName: 'items'
            };
    
            const graphFormat = {
                xLabel: 'Activities',
                yLabel: 'Score',
            }
            
            return(
                <div styleName="graph-container">
                    <div styleName="graph-content">
                        <div styleName="bar-graph-container">
                                <InfoWithoutFilterComponent
                                    {...this.props}
                                    title="Assessment Score"
                                    subtitle="Shows your score across the assessments in comparison to your group"
                                />
                                <OverlappingBars 
                                    isUserDataAvailable={allGraphsData.get('assesmentScoreData').get('isUserDataAvailable')}
                                    skinGuide={this.props.skinGuide}
                                    barStyling={barStyling}
                                    size={this.props.size}
                                    hasAverageLines={false}
                                    graphFormat={graphFormat}
                                    graphData={graphData}
                                />
                        </div>
                    </div>
                </div>
            )
        }

        return 'Loading graph..'
       
    }
    render(){
  
        return(
            <div styleName={this.props.getStyleForAssesment}>
                <div styleName="graph-container">
                    {this.renderCompetencyDetails()}
                </div> 
            </div>
        )
    }
}
 

export default ScoreCardInfoWithBars;