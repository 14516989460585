import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './toolFAQ.module.sass';
import CSSModules from 'react-css-modules';
import FAQItem from './FAQItem';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import AssessmentItemDetailsLoader from 'commonComponents/loaders/assessmentItemDetailsLoader';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ToolFAQ extends Component {

	renderFAQs = (faqsList) => {

		const prodFAQ = faqsList.map((faq) => ({
			question: faq.get('heading'),
			answer: faq.get('text')
		}));

		if (prodFAQ) {
			return (prodFAQ).map((item) => {
				return <FAQItem item={item} />
			});
		}
		return '';
	}
	render() {
		const {
			getLabel, faqsList, isSideBarOpen, skinGuide, loaders, isBatchLabelsAvailable
		} = this.props;
		const myStyles = getSkin(skinGuide);

		let style = (isSideBarOpen) ? "all-q-a-open" : "all-q-a-closed";
		let titleStyle = 'FAQ-title';
		let containerStyle = "FAQ-container";
		let rowStyle = "row";
		if (this.props.isFromFeedbackScreen) {
			style = "full-width";
			titleStyle = "FAQ-title-for-feedback";
			containerStyle = "FAQ-container-for-feedback";
			rowStyle = "row-for-feedback";
		}
		if (loaders.get('isFetchingToolFAQs') || !isBatchLabelsAvailable) {
			return (
				<div styleName="FAQ-container">
					<div styleName="row">
						<div styleName="loader-column">
							<AssessmentItemDetailsLoader />
						</div>
					</div>
				</div>
			);
		}

		if (faqsList.size === 0) {
			return null;
		}

		return (
			<div styleName={containerStyle}>
				<div className={css(myStyles.title)} styleName={titleStyle}>
					{getLabel('faq_label')}
				</div>
				<div styleName={rowStyle}>
					<div styleName={style}>
						{this.renderFAQs(faqsList)}
					</div>
				</div>
			</div>
		);
	}
}

export default ToolFAQ;
