import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    let textStyle = {
        textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
        ...myTypography.button,
        color: skin.get('white'),
        paddingLeft: '15px',
        fontWeight: 'normal'
    };

    return StyleSheet.create({
      freshchatBtnText: {
          ...textStyle,
          padding: 'unset',
          textTransform: 'capitalize'
      }
    });
}

export default getSkin;