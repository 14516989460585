import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        yesButton: {
            ...myTypography.button,
            fontWeight: 'normal',
            cursor: 'pointer',
            color: skin.get('secondaryColor'),
            fontWeight: '600',
            ':hover': {
                textDecoration: 'underline'
            }
        },

        cancelButton: {
            ...myTypography.button,
            fontWeight: 'normal',
            cursor: 'pointer',
            color: skin.get('primaryColor'),
            fontWeight: '600',
            ':hover': {
                textDecoration: 'underline'
            }
        },

        exitText: {
            ...myTypography.overline,
            color: hexToRgbA(skin.get('greyColor1'), 0.85)
        }
    });
}

export default getSkin;