/**
 * @author: sourabh p
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: BatchContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * base container is rendered on initial load of application and mounted only
 * once displayed on every route of the application and all other containers are
 * children of this base container
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import setUserState from 'actions/userState/actionCreators';
import JourneyDashboard from 'components/journeys/journeyDashboard';

import { resetBatchProgressForUser } from 'actions/batches/apiActions';
import { fetchBatchDetails } from 'actions/batches/newApiActions';

import { fetchDevPhaseItems, fetchModuleItemsForSingleModuleCourse, fetchCourseModulesList } from 'actions/courses/apiActions';
import { getActiveBatchDetails } from 'utils/batchUtils';

import { fetchNextActivityItem } from 'actions/moduleItems/apiActions';

import setUiState from 'actions/ui/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import { setPhaseWorkflowStatus } from 'actions/phases/apiActions';
import { fetchWidgetAnalyticsData } from 'actions/analytics/apiActions';
import { setItemWorkflowStatus } from 'actions/items/apiActions';

import {
	startTracking,
	endTracking
} from 'utils/mixpanelTracking';

import { fetchCourseForNextActivity } from 'actions/courses/apiActions';
import { unsetCourseForNextActivity, setNextModuleItemAvailability, unsetCourses } from 'actions/courses/actionCreators';
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { updateFetchBatchDashboardStatus } from 'actions/loaders/actionCreators';

class BatchDashboardContainer extends React.Component {

	componentDidMount() {
		const { batchId } = this.props.match.params;
		const userId = this.props.user.get('userID');
		const phaseId = this.props.phases.get('activePhaseId');
		if (userId && phaseId) {
			this.props.fetchWidgetAnalyticsData(+batchId, +userId, +phaseId);

		}
		startTracking(true, {
			batchId: this.props.match.params.batchId
		}, 'BatchDashboard');

		const batchDetails = getActiveBatchDetails(this.props.batches);
		document.title = `Dashboard - ${batchDetails.getIn(['journey', 'name'])} - Aktivlearn Plus`;

		this.sendSegmentData();
	}

	sendSegmentData = () => {
		let segmentData = {
			...(this.props.match.params),
			url: window.location.href,
			pathHash: window.location.hash
		};
		window.analytics.page(
			`Journey Dashboard - Batch ID ${this.props.match.params.batchId}`,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	componentWillReceiveProps(nextProps) {
		const { batches } = nextProps;
		const { batchId } = nextProps.match.params;
		const userId = this.props.user.get('userID');
		const phaseId = this.props.phases.get('activePhaseId');
		if (this.props.match.params.batchId != batchId) {
			if (userId && phaseId) {
				this.props.fetchWidgetAnalyticsData(+batchId, +userId, +phaseId);
			}
			endTracking();
			startTracking(true, {
				batchId: nextProps.match.params.batchId
			}, 'BatchDashboard');
		}
		const batchDetails = getActiveBatchDetails(batches);
		let route = routes.get('BATCH_ITEM')
		let params = [{ 'BATCH_ID': batchDetails.get('id') }];
		if (batchDetails.getIn(['journey', 'settings', 'isSimulationJourney']) === 'true') {
			params.push({ 'ITEM_ID': batchDetails.getIn(['journey', 'phaseItemId']) });
			updateRoute({ route, params });
		}
	}

	retryFetchWidgetAnalyticsData = () => {
		const { batchId } = this.props.match.params;
		const userId = this.props.user.get('userID');
		const phaseId = this.props.phases.get('activePhaseId');
		this.props.fetchWidgetAnalyticsData(+batchId, +userId, +phaseId);
	}

	componentWillUnmount() {
		endTracking();
		this.props.unsetNextActivityModuleItem();
	}

	render() {
		return <JourneyDashboard {...this.props} retryFetchWidgetAnalyticsData={this.retryFetchWidgetAnalyticsData} />;
	}
}

const mapStateToProps = (state) => ({
	phases: state.get('phases'),
	items: state.get('items'),
	loaders: state.get('loaders'),
	courses: state.get('journeyCoursesInfo'),
	batches: state.get('batches'),
	attachmentsList: state.get('attachments').get('list'),
	userState: state.get('userState'),
	user: state.get('user'),
	uiState: state.get('uiState'),
	widgetData: state.get('allGraphsData').get('activity').get('widgetData'),
	isWidgetDataFetched: state.get('allGraphsData').get('activity').get('isWidgetDataFetched'),

	courseForNextActivity: state.get('courseForNextActivity'), //for launching scorm course

	course: state.get('course'), //for self paced course modules,

	nextActivityModuleItem: state.get('nextActivityItem'),

	apiErrors: state.get('apiErrors'),
	phaseCourses: state.get('phaseCourses'),
	coursesModulesList: state.get('coursesModulesList'),
	modulesItemsList: state.get('modulesItemsList'),
	feedbackStatuses: state.get('feedbackStatuses'),

});

const mapDispatchToProps = (dispatch) => ({
	fetchBatchDetails: (batchId) => {
		dispatch(fetchBatchDetails(batchId));
	},
	setUserState: (payload) => {
		dispatch(setUserState(payload))
	},
	fetchDevPhaseItems: (params) => {
		fetchDevPhaseItems(params);
	},
	fetchModuleItemsForSingleModuleCourse: (params) => {
		fetchModuleItemsForSingleModuleCourse(params);
	},
	setUiState: (payload) => {
		dispatch(setUiState(payload));
	},
	addToast: (obj) => {
		dispatch(addToast(obj))
	},
	setPhaseWorkflowStatus: (batchId, phaseId, workflowStatus, routeObj) => {
		dispatch(setPhaseWorkflowStatus(batchId, phaseId, workflowStatus, routeObj))
	},
	fetchWidgetAnalyticsData: (batchId, userId, phaseId) => {
		dispatch(fetchWidgetAnalyticsData(batchId, userId, phaseId))
	},
	setItemWorkflowStatus: (workflowstatus) => {
		dispatch(setItemWorkflowStatus(workflowstatus));
	},
	fetchCourseForNextActivity: (params) => {
		fetchCourseForNextActivity(params);
	},
	unsetCourseForNextActivity: () => {
		dispatch(unsetCourseForNextActivity());
	},
	fetchModulesForDashboard: (params) => {
		fetchCourseModulesList(params);
	},


	fetchNextActivityModuleItem: (params) => {
		fetchNextActivityItem(params);
	},

	unsetNextActivityModuleItem: () => {
		dispatch(setNextModuleItemAvailability(false));
	},

	unsetCourses: () => {
		dispatch(unsetCourses());
	},
	resetBatchProgressForUser: (batchId, userId) => {
		dispatch(resetBatchProgressForUser(batchId, userId));
	},
	updateFetchBatchDashboardStatus: (status) => {
		dispatch(updateFetchBatchDashboardStatus(true));
	}



});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BatchDashboardContainer));