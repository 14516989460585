import React, { Component } from 'react';
import styles from './supportIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class SupportIcon extends Component {

	render() {
		const { fill } = this.props;

		return (
			<div styleName="complete-component">
				<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18" fill="none">
					<path d="M14.7109 0.999999L8.7124 1C4.45296 1 0.999999 4.45296 1 8.7124V8.7124C1 12.9718 4.45296 16.4248 8.7124 16.4248L14.7109 16.4248" stroke={fill || "#FFF"} stroke-width="1.71387" stroke-linecap="round"/>
					<path d="M11.2832 5.28506C10.3367 5.28506 9.56934 4.51774 9.56934 3.5712L9.56934 2.71426C9.56934 1.76772 10.3367 1.0004 11.2832 1.0004L14.7109 1.0004C15.6575 1.0004 16.4248 1.76772 16.4248 2.71426L16.4248 3.5712C16.4248 4.51774 15.6575 5.28506 14.7109 5.28506L11.2832 5.28506Z" stroke={fill || "#FFF"} stroke-width="1.71387"/>
					<path d="M11.2832 12.1399C10.3367 12.1399 9.56934 12.9072 9.56934 13.8537L9.56934 14.7107C9.56934 15.6572 10.3367 16.4245 11.2832 16.4245L14.7109 16.4245C15.6575 16.4245 16.4248 15.6572 16.4248 14.7107L16.4248 13.8537C16.4248 12.9072 15.6575 12.1399 14.7109 12.1399L11.2832 12.1399Z" stroke={fill || "#FFF"} stroke-width="1.71387"/>
				</svg>
			</div>
		);
	}
}



export default SupportIcon;
