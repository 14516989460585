import React, { Component } from 'react';
import * as microsoftTeams from "@microsoft/teams-js";
import CSSModules from 'react-css-modules';
import styles from './configure.module.sass';
import getSkin from './skin';
import applySkin from 'utils/SkinProvider';
import { css } from 'aphrodite/no-important';
import {deleteTeamTabLinking} from 'actions/msTeams/apiActions';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class Remove extends Component {

    constructor(props){
        super(props);
    }
    
    componentDidMount(){        
        //Handler for removing the tab
        microsoftTeams.settings.registerOnRemoveHandler((removeEvent) => {
            console.log('removing the tab');

            //call notif service api and then notify success
            const successCallBack = () => removeEvent.notifySuccess();
            const failureCallBack = (error) => removeEvent.notifyFailure(error);
            this.deleteTeamTabLinking(successCallBack, failureCallBack);
        });
    }

    deleteTeamTabLinking = (successCallBack, failureCallBack) => {
        microsoftTeams.getContext((context) => {
            console.log('msteams: context:', context);
            const payload = {
                teamId: context.teamId,
                tabId: this.createEntityId(),
                batchId: this.props.match.params.batchId
            }
            deleteTeamTabLinking(payload, successCallBack, failureCallBack);
        });
        
    }

    createEntityId = () => {
        return `alplus_batch_${this.props.match.params.batchId}_leaderboard`;
    }

    render() {
        return <h3 style={{textAlign: 'center', marginTop: '40px'}}>We are sad to see you go!</h3> 
    }
}

export default Remove;
