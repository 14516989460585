import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
	journeyTags: [],
	isJourneyTagsFetching: false
});

const journeyTags = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_JOURNEY_TAGS'):
			return state.set(
				'journeyTags',
				Immutable.fromJS(action.tags)
			).set(
				'isJourneyTagsFetching',
				false
			)
		default:
			return state;
	}
}

export default journeyTags;