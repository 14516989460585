import Immutable from 'immutable';
import environment from 'utils/environment';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';

var apiURL = '';
var notificationURL = '';
if (environment.REACT_APP_ENV === 'local') {
	apiURL = getApiUrl().notificationExternalUrl;
	notificationURL = getApiUrl().notificationBaseUrl;
} else {
	apiURL = getDomainUrl() + '/external/notification/api';
	notificationURL = getDomainUrl() + '/admin/notification/api';
}

const notificationUrls = () => {
	return Immutable.Map({
		'MSTEAMS_TAB_LINKING': `${apiURL}/msteam/team-tab-linking`,
		'GET_SUBSCRIPTION': `${notificationURL}/subscription`,
		'UNSUBSCRIBE_NOTIFICATION': `${notificationURL}/unsubscribe`
	});
};

export default notificationUrls;