import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './textArea.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite/no-important';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TextArea extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: props.question.get('textValue') || ''
        }
    }

    handleChange = (event) => {
        this.setState({
            value: event.target.value
        })
    }

    onClickOfSubmit = () => {
        const { questionId, submitAnswer } = this.props;
        console.log(this.state.value)
        const payload = {
            textValue: this.state.value
        };
        console.log(payload)
        submitAnswer(payload, questionId);
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const { value } = this.state;
        const { question } = this.props;

        return (
            <div styleName="text-area-container">
                <textarea
                    className={css(myStyles.textArea)}
                    styleName="text-area" name="body"
                    onChange={this.handleChange}
                    defaultValue={value} />
                <div className={css(myStyles.sumbitText)}
                    styleName="button-style"
                    onClick={this.onClickOfSubmit}>
                    {question.get('buttonText')}
                </div>
            </div>
        );
    }
}

export default TextArea;
