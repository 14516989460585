import React, { Component } from 'react';
import styles from './empathyMap.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DownloadIcon from 'svgComponents/downloadIcon';
import HelpIcon from 'svgComponents/helpIcon';
import ToolItemList from '../toolItemList';
import { getDigitalToolkitFirebaseDatabase } from 'utils/firebase';
import DigitalToolHeader from '../digitalToolHeader';
import DigitalToolGuide from '../digitalToolGuide';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class EmpathyMap extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuide: false
		}
	}

	toggleShowGuide = () => {
		this.setState({
			showGuide: !this.state.showGuide
		});
	}

	submitEmpathyMap = () => {
		this.props.markModuleItemDone(this.props.match.params);
	}

	downloadEmpathyMap = () => {
		alert("feature not available!");
	}

	addNewUserDataRecord = async (data, type) => {
		const { match, currentUserDetails, moduleItem, toolUserDataRef } = this.props;

		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise;

		toolUserDataRef.once('value', (snapshot) => {
			if (snapshot.exists()) {
				let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['al_data', 'tool_id']) || 'empathyMapEnglish'}/data/${type}`

				const dataRef = digitalToolkitDb.ref(refString)
				dataRef.update(data)
			} else {
				let dataTemp = {};
				dataTemp[type] = data;
				let dataToSave = {
					...(match.params),
					userId: currentUserDetails.get('userID'),
					toolId: moduleItem.getIn(['contentDetails', 'al_data', 'tool_id']) || 'empathyMapEnglish',
					data: dataTemp
				}
				toolUserDataRef.update(dataToSave);
			}
		})
	}

	deleteRecord = async (uuid, type) => {
		const { match, currentUserDetails, moduleItem } = this.props;
		let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id']) || 'empathyMapEnglish'}/data/${type}/${uuid}`;
		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 
		const dataRef = digitalToolkitDb.ref(refString);
		dataRef.remove();
	}

	setColorPalette = async (type, selectedCombo = "combo1") => {
		const { match, currentUserDetails, moduleItem } = this.props;
		let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])}/data/selectedColorPalette/${type}`;

		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 

		const paletteRef = digitalToolkitDb.ref(refString);
		paletteRef.set(selectedCombo);
	}

	getEmpathyMapHeader = () => {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="empathy-map-header">
				<div className={css(myStyles.headerTitle)}>
					{this.props.toolData.toolName}
				</div>
				<div styleName="header-actions">
					<div styleName="download-cnt">
						<div
							styleName="innner-cnt"
							style={{ cursor: 'not-allowed' }}
							title="Coming Soon..."
						>
							<div styleName="download-icon-cnt">
								<DownloadIcon fillColor={'#ffffff'} />
							</div>
							<div className={css(myStyles.iconText)}>Download</div>
						</div>
					</div>
					<div styleName="guide-cnt">
						<div styleName="innner-cnt" onClick={this.toggleShowGuide}>
							<div styleName="guide-icon-cnt">
								<HelpIcon fillColor={'#ffffff'} />
							</div>
							<div className={css(myStyles.iconText)}>
								Guide
							</div>
						</div>
					</div>
					<div styleName="submit-cnt" className={css(myStyles.submitBtnText)} onClick={() => { this.props.markModuleItemDone(this.props.match.params) }}>
						Submit
					</div>
				</div>
			</div>
		)
	}

	getFullscreenTemplate = (type) => {
		const { toolUserData, colorPalette } = this.props;
		return (
			<ToolItemList
				type={type}
				key={`key_${type}_emo`}
				userData={toolUserData && toolUserData.data ? toolUserData.data[type] : {}}
				addNewUserDataRecord={this.addNewUserDataRecord}
				deleteRecord={this.deleteRecord}
				colorPalette={colorPalette}
				selectedColorPalette={toolUserData && toolUserData.data && toolUserData.data.selectedColorPalette ? toolUserData.data.selectedColorPalette[type] : ""}
				setColorPalette={this.setColorPalette}
			/>
		)
	}

	getEmpathyMapBody = () => {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div key={`${this.props.type}_bucket`} styleName="empathy-map-content">
				<div
					className={css(myStyles.emotionContentBg)}
					styleName="user-emotions-content"
				>
					<div styleName="feel-cnt">
						<div className={css(myStyles.feelHeaderText)} style={{ marginBottom: '10px' }}>Think and feel?</div>
						<div styleName="tool-item-list-cnt">
							{this.getFullscreenTemplate("thinkAndFeel")}
						</div>
					</div>
					<div styleName="feel-cnt multiple">
						<div styleName="hear-cnt">
							<div className={css(myStyles.feelHeaderText)} style={{ marginRight: '10px' }}>Hear?</div>
							<div styleName="tool-item-list-cnt">
								{this.getFullscreenTemplate("hear")}
							</div>
						</div>
						<div styleName="see-cnt">
							<div styleName="tool-item-list-cnt">
								{this.getFullscreenTemplate("see")}
							</div>
							<div className={css(myStyles.feelHeaderText)} style={{ marginLeft: '10px' }}>See?</div>
						</div>
					</div>
					<div styleName="feel-cnt">
						<div styleName="tool-item-list-cnt">
							{this.getFullscreenTemplate("sayAndDo")}
						</div>
						<div className={css(myStyles.feelHeaderText)} style={{ marginTop: '10px' }}>Say and do?</div>
					</div>
				</div>
				<div styleName="pain-gain-cnt">
					<div styleName="user-pain-content">
						<div className={css(myStyles.feelHeaderText)} style={{ marginRight: '10px' }}>Pain</div>
						<div styleName="tool-item-list-cnt">
							{this.getFullscreenTemplate("pain")}
						</div>
					</div>
					<div styleName="user-gain-content">
						<div className={css(myStyles.feelHeaderText)} style={{ marginRight: '10px' }}>Gain</div>
						<div styleName="tool-item-list-cnt">
							{this.getFullscreenTemplate("gain")}
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { toolData } = this.props;
		return (
			<div styleName="empathy-map-cnt">
				<DigitalToolHeader
					toggleShowGuide={this.toggleShowGuide}
					submitAction={this.submitEmpathyMap}
					downloadAction={this.downloadEmpathyMap}
					toolData={toolData}
				/>
				<div styleName="empathy-map-scrollable">
					{this.getEmpathyMapBody()}
				</div>
				{
					this.props.toolData && this.state.showGuide &&
					<DigitalToolGuide
						closeGuide={this.toggleShowGuide}
						guideData={this.props.toolData.guide}
						labels={this.props.toolData.labels}
					/>
				}
			</div>
		);
	}
}

export default EmpathyMap;