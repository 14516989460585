/**
 * @author: amul
 * @since: Mon Jul 05 2021 12:17:42 GMT+0530 (India Standard Time)
 * @file: AttachmentContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Attachment container is responsible for rendering different players for attachments (files)
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import GenericErrorBoundary from 'components/errorBoundaries';

import {
    fetchAttachmentDetails
} from 'actions/attachments/apiActions';

import AttachmentViewer from 'components/attachments/attachmentViewer';
import CircularLoader from 'svgComponents/circularLoader'

class AttachmentContainer extends React.Component {

    componentDidMount(){
        this.props.fetchAttachmentDetails(this.props.attachmentId);
    }

    getAttachmentFromList = () => {
        const { attachments, attachmentId } = this.props;
        return attachments.get('listWithDetails').find(attachment => attachment.get('uuid') === attachmentId);
    }

    render() {
        const { onStart, onProgress, progress, isAlreadyCompleted, productLaunchDetails, fetchProductLaunchData, isProductLaunchDetailsAvailable } = this.props;
        const attachmentDetails = this.getAttachmentFromList();
        
        if(!attachmentDetails || !progress) return <div style={{ height: 'calc(100vh - 400px)', width: '43px', margin: 'auto' }}>
            <CircularLoader />
        </div>

        return (
            <GenericErrorBoundary>
                <AttachmentViewer
                    attachmentDetails={attachmentDetails}
                    onStart={onStart}
                    onProgress={onProgress}
                    progress={progress}
                    isAlreadyCompleted={isAlreadyCompleted}
                    productLaunchDetails={productLaunchDetails}
                    fetchProductLaunchData={fetchProductLaunchData}
                    isProductLaunchDetailsAvailable={isProductLaunchDetailsAvailable}
                />
            </GenericErrorBoundary>

        );
    }

} 
 
const mapStateToProps = (state) => {
    return {
        attachments: state.get('attachments')
    }
};

const mapDispatchToProps = (dispatch) => ({
    fetchAttachmentDetails: (attachmentId) => {
        fetchAttachmentDetails(attachmentId);
    }
});
 
 export default connect(mapStateToProps, mapDispatchToProps)(AttachmentContainer);
 