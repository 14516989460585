import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import { relative } from 'upath';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		mainTitle:{
			color: skinHex.get('greyColor1'),
			fontSize: '40px',
			fontFamily: skin.get('fontFamily').get('bodyFamily2'),
			lineHeight: 1.25,
			fontWeight: 300,
			'@media screen and (max-width: 480px)': {
				fontSize: '30px'
			}
		},
		subTitle: {
			fontFamily: skin.get('fontFamily').get('bodyFamily'),
		fontSize: '22px',
		fontWeight: 300,
		lineHeight: 1.27,
			color: skinHex.get('greyColor3')
		},
		tooltipContent: {
			backgroundColor: skinHex.get('greyColor2'),
			color: skinHex.get('white'),
			boxShadow: `5px 5px 10px 0 ${hexToRgbA(skinHex.get('black'), 0.5)}`,
			...myTypography.caption
		},
		tooltipArrow: {
			borderLeft: `5px solid transparent`,
			borderRight: `5px solid transparent`,
			borderBottom: `5px solid ${skinHex.get('greyColor2')}`
		}
	});
}

export default getSkin;