import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        sumbitText: {
            color: skin.get('secondaryColor'),
            ...myTypography.button
        },
        textArea: {
            ...myTypography.overline,
            opacity: 0.8
        }
    });
}

export default getSkin;