import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setUserReferences, setUserReferencesLoading } from 'actions/userProfile/actionCreators';
import { setUserDetails } from 'actions/users/actionCreators';

class FetchUserReferences extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	shouldAjaxCall = () => {
		const userReferences = this.getState().getIn(['userProfile', 'userReferences']);
		if (!userReferences.isEmpty()) {
			return false;
		}
		return true;
	}

	prepareUrl = (apiInfoObject = {}) => urls.get('PLATFORM').get('GET_USER_REFERENCES').replace('USER_ID', apiInfoObject.userId);

	beforeCall = (apiInfoObject) => {
		this.dispatch(setUserReferencesLoading(true));
		return apiInfoObject;
	}

	successCall = (response) => {
		this.dispatch(setUserReferences(response));
		this.dispatch(setUserDetails({
			firstName: response.firstName,
			lastName: response.lastName,
			profilePicUrl: response.profilePicUrl
		}));
		return response;
	}

	onError = (error) => {
		this.dispatch(setUserReferencesLoading(true));
		return error;
	}
}

export default new FetchUserReferences();
