import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        profileAndOptionsContainer: {
            boxShadow: `0 2px 4px 0 rgba(0, 0, 0, 0.13), 0 4px 16px 0 rgba(0, 0, 0, 0.06)`,
            borderRadius: '4px',
            overflow: 'hidden'
        },
        userProfileContainer:{
            backgroundColor: '#dfe1e8',
            boxShadow: `0 1px 0 0 rgb(223, 225, 232)`

        },
        userName: {
            ...myTypography.overline,
            fontWeight: 600,
            fontStyle: `normal`,
            fontStretch: `normal`,
            lineHeight: `normal`,
            letterSpacing: `normal`
        },
        userEmail: {
            ...myTypography.button,
            fontWeight: `normal`,
            fontStyle: `normal`,
            fontStretch: `normal`,
            lineHeight: 1.43,
            letterSpacing: `normal`
        },
        individualItem: {
            backgroundColor: skin.get('white'),
            ':hover': {
				backgroundColor: skin.get('greyColor6')
			}
        },
        individualItemText: {
            ...myTypography.overline,
            fontWeight: `normal`,
            fontStyle: `normal`,
            fontStretch: `normal`,
            lineHeight: `normal`,
            letterSpacing: `normal`,
            opacity: 0.85,
            color: skin.get('greyColor1'),
        },
        arrowUp: {
            borderBottom: `15px solid #dfe1e8`,
			borderLeft: '15px solid transparent',
			borderRight: '15px solid transparent'
        }
	});
}

export default getSkin;