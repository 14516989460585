import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreWithInfoBarsDevelopment.module.sass';
import RadarGraph from 'commonComponents/graphs/radarGraph';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent/InfoWithoutFilterComponent';
import OverlappingBars from 'commonComponents/graphs/overlappingBars';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import DropDown from 'commonComponents/analytics/dropDown';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class ScoreWithInfoBarsDevelopment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            courseIdForSectionGraph: null,
            courseId: null,
            sectionId: null
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
            return false;
        }
        else{
            return true;
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(state.courseId){
            return null;
        }
        const allGraphsData = props.allGraphsData.get('development');
        if (allGraphsData.get('isDevelopmentScoreFetched')) {
            const courses = allGraphsData.get('developmentScoreData').get('courses').toJS();
            const graphData = allGraphsData.get('developmentScoreData').toJS();
            if (courses.data.length > 0) {
                return (
                    {
                        courseIdForSectionGraph: 'selectedCourseId' in graphData ? graphData['selectedCourseId'] : -1,
                        courseId: 'selectedCourseId' in graphData ? graphData['selectedCourseId'] : -1,
                        sectionId: 'selectedSectionId' in graphData ? graphData['selectedSectionId'] : -1
                    }
                )
            }
        }
        return null;
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        this.props.fetchDevelopmentScoreData(+batchId, +userId, +phaseId);
    }

    getSections = (courseId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        const courses = allGraphsData.get('developmentScoreData').get('courses').toJS();
        const sections = allGraphsData.get('developmentScoreData').get('sections').toJS();

        let data, overallAverage, userAverage;
        if (courseId === -1) {
            data = sections.data;
            overallAverage = courses.sectionOverallAverage;
            userAverage = courses.sectionUserAverage;
        }
        else {
            const courseData = courses.data.filter((course) => course.id === courseId)[0];
            data = sections.data.filter((section) => section.courseId === courseId);
            overallAverage = courseData.sectionOverallAverage;
            userAverage = courseData.sectionUserAverage;
        }

        return {
            data: data,
            metadata: sections.metadata,
            overallAverage: overallAverage,
            userAverage: userAverage
        };
    }

    getActivities = (courseId, sectionId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        const courses = allGraphsData.get('developmentScoreData').get('courses').toJS();
        const sections = allGraphsData.get('developmentScoreData').get('sections').toJS();
        const activities = allGraphsData.get('developmentScoreData').get('activities').toJS();

        let data, overallAverage, userAverage;
        if (courseId === -1) {
            if (sectionId === -1) {
                data = activities.data;
                overallAverage = courses.activitiesOverallAverage;
                userAverage = courses.activitiesUserAverage;
            }
            else {
                const sectionData = sections.data.filter((section) => section.id === sectionId)[0];
                data = activities.data.filter((activity) => activity.sectionId === sectionId);
                overallAverage = sectionData.activitiesOverallAverage;
                userAverage = sectionData.activitiesUserAverage;
            }
        }
        else {
            const courseData = courses.data.filter((course) => course.id === courseId)[0];
            if (sectionId === -1) {
                data = activities.data.filter((activity) => activity.courseId === courseId);
                overallAverage = courseData.activitiesOverallAverage;
                userAverage = courseData.activitiesUserAverage;
            }
            else {
                const sectionData = sections.data.filter((section) => section.id === sectionId)[0];
                data = activities.data.filter((activity) => activity.courseId === courseId && activity.sectionId === sectionId);
                overallAverage = sectionData.activitiesOverallAverage;
                userAverage = sectionData.activitiesUserAverage;
            }
        }
        return {
            data: data,
            metadata: activities.metadata,
            overallAverage: overallAverage,
            userAverage: userAverage
        };
    }

    onCourseFilterChangedForSectionGraph = (courseId) => {
        this.setState({
            courseIdForSectionGraph: +courseId
        });
    }

    onCourseFilterChanged = (courseId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        const graphData = allGraphsData.get('developmentScoreData').toJS();
        const sections = allGraphsData.get('developmentScoreData').get('sections').toJS();
        const sectionsForCourse = sections.data.filter(section => section.courseId === +courseId);
        const firstSectionId = sectionsForCourse.length > 0 ? sectionsForCourse[0].id : -1;
        this.setState({
            courseId: +courseId,
            sectionId: 'selectedSectionId' in graphData && graphData.selectedSectionId !== -1 ? firstSectionId : -1
        });
    }

    onSectionFilterChanged = (sectionId) => {
        this.setState({
            sectionId: +sectionId
        });
    }

    renderCourseDetails = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        if(this.props.apiErrors.get('fetchDevelopmentScoreDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchDevelopmentScoreDataError')}
                retryHandler={()=>{
                    this.props.fetchDevelopmentScoreData(+batchId, +userId, +phaseId);
				}}
            />
        }

        const allGraphsData = this.props.allGraphsData.get('development');

        if(allGraphsData.get('isDevelopmentScoreFetched') && this.state.courseId) {

            const { courseId, sectionId, courseIdForSectionGraph } = this.state;

            const scoreData = allGraphsData.get('developmentScoreData');
            const strings = scoreData.get('strings');
            // const courses = scoreData.get('courses').toJS();
            // const courseDataFormat = '';
            const isCoursesGraphAvailable = scoreData.get('isCoursesGraphAvailable');
            const isSectionsGraphAvailable = scoreData.get('isSectionsGraphAvailable');
            const isActivitiesGraphAvailable = scoreData.get('isActivitiesGraphAvailable');
            let courses, courseDataFormat, sections, activities, sectionsForSectionGraph, sectionDataFormat, activityDataFormat;
            
            if (isCoursesGraphAvailable) {
                courses = scoreData.get('courses').toJS();
                courseDataFormat = '';
            }
            
            if (isSectionsGraphAvailable) {
                sectionsForSectionGraph = this.getSections(courseIdForSectionGraph);
                sectionDataFormat = '';
            }

            if (isActivitiesGraphAvailable) {
                sections = this.getSections(courseId);
                activities = this.getActivities(courseId, sectionId);
                activityDataFormat = '';
            }

            const scoreDataGraph = scoreData.toJS();

            let shouldShowAllOptionForSectionInSectionGraph = true, shouldShowAllOptionForSectionGraph = true, shouldShowAllOptionForActivityGraph = true;
            if ('selectedCourseId' in scoreDataGraph) {
                if (scoreDataGraph.selectedCourseId !== -1) {
                    shouldShowAllOptionForSectionInSectionGraph = false;
                }
            }
            if ('selectedSectionId' in scoreDataGraph) {
                if (scoreDataGraph.selectedCourseId !== -1 && scoreDataGraph.selectedSectionId !== -1) {
                    shouldShowAllOptionForSectionGraph = false;
                    shouldShowAllOptionForActivityGraph = false;
                }
            }
            const graphStyleName = isCoursesGraphAvailable && isSectionsGraphAvailable ? 'two-graph-container' : 'single-graph-container';
            const devCourseBarStyling = {
                fromUser: '#26fa96',
                toUser: '#52f9aa',
                userRotation: 0,
                fromGroup: analyticGroupColors.fromGroup,
                toGroup: analyticGroupColors.toGroup,
                groupRotation: 356,
                gridValue: isSectionsGraphAvailable ? 4.5 : 9,
                componentName: 'dev-course-score'
            };
    
            const devCourseGraphFormat = {
                xLabel: strings.get('courseLabel'),
                yLabel: strings.get('yAxisLabel'),
                valueFormat: courseDataFormat,
            }
    
            const devSectionBarStyling = {
                fromUser: '#ffce23',
                toUser: '#fea429',
                userRotation: 0,
                fromGroup: analyticGroupColors.fromGroup,
                toGroup: analyticGroupColors.toGroup,
                groupRotation: 356,
                gridValue: isCoursesGraphAvailable ? 4.5 : 10,
                componentName: 'dev-section-score'
            };
    
            const devSectionGraphFormat = {
                xLabel: strings.get('sectionLabel'),
                yLabel: strings.get('yAxisLabel'),
                valueFormat: sectionDataFormat,
            }
    
            const devActivityBarStyling = {
                fromUser: '#00faee',
                toUser: '#72fbf5',
                userRotation: 175,
                fromGroup: analyticGroupColors.fromGroup,
                toGroup: analyticGroupColors.toGroup,
                groupRotation: 356,
                gridValue: 10,
                componentName: 'dev-activity-score'
            };
    
            const devActivityGraphFormat = {
                xLabel: strings.get('ActivityLabel'),
                yLabel: strings.get('yAxisLabel'),
                valueFormat: activityDataFormat,
            }
            //TODO: Add iscoursegraph available condition here and in all places where data for this graph is being obtained above
            //Do not render course drop down if course graph is not available and by default -1 value will be selected for activities section filter
            //If section and course graph are not available do not show the drop down for activities graph
            //Stretch the sections graph to full width if course graph is unavailable
            //Repeat the same for both activity and performance (time, progress, performance)
            //If course is available ,sections is not available and activities is available, show activity course dropdown and by default value for it will be -1
            return (
                [<div styleName="dev-graph-content" key="score-course-graph">
                    {isCoursesGraphAvailable && <div styleName={graphStyleName}>
                        <InfoWithoutFilterComponent
                            {...this.props}
                            title={strings.get('courseGraphTitle')}
                            subtitle={strings.get('courseGraphDesc')}
                        />
                        <OverlappingBars
                            isUserDataAvailable={scoreData.get('isUserDataAvailable')}
                            skinGuide={this.props.skinGuide}
                            barStyling={devCourseBarStyling}
                            size={this.props.size}
                            hasAverageLines={false}
                            graphFormat={devCourseGraphFormat}
                            graphData={courses}
                            strings={strings.toJS()}
                        />
                    </div>}
                    {isSectionsGraphAvailable && <div styleName={graphStyleName}>
                        <div styleName="title-header">
                            <InfoWithoutFilterComponent
                                {...this.props}
                                title={strings.get('sectionGraphTitle')}
                                subtitle={strings.get('sectionGraphDesc')}
                            />
                            {isCoursesGraphAvailable && 
                                <DropDown
                                    handleOnClick={this.onCourseFilterChangedForSectionGraph}
                                    allLabel={strings.get('allLabel')}
                                    showAllOption={shouldShowAllOptionForSectionInSectionGraph}
                                    list={courses.data}
                                />
                            }
                        </div>
                        <OverlappingBars
                            isUserDataAvailable={scoreData.get('isUserDataAvailable')}
                            skinGuide={this.props.skinGuide}
                            barStyling={devSectionBarStyling}
                            size={this.props.size}
                            hasAverageLines={false}
                            graphFormat={devSectionGraphFormat}
                            graphData={sectionsForSectionGraph}
                            strings={strings.toJS()}
                        />
                    </div>}
                </div>,
                isActivitiesGraphAvailable && <div styleName="dev-graph-content" key="score-activity-graph">
                    <div styleName="title-header">
                        <InfoWithoutFilterComponent
                            {...this.props}
                            title={strings.get('activityGraphTitle')}
                            subtitle={strings.get('activityGraphDesc')}
                        />
                        {isCoursesGraphAvailable && 
                            <DropDown
                                handleOnClick={this.onCourseFilterChanged}
                                allLabel={strings.get('allLabel')}
                                showAllOption={shouldShowAllOptionForSectionGraph}
                                list={courses.data}
                            />
                        }
                        {isSectionsGraphAvailable && 
                            <DropDown
                                handleOnClick={this.onSectionFilterChanged}
                                allLabel={strings.get('allLabel')}
                                showAllOption={shouldShowAllOptionForActivityGraph}
                                list={sections.data}
                            />
                        }
                    </div>
                    <div styleName="title-header">
                        <OverlappingBars
                            isUserDataAvailable={scoreData.get('isUserDataAvailable')}
                            skinGuide={this.props.skinGuide}
                            barStyling={devActivityBarStyling}
                            size={this.props.size}
                            hasAverageLines={false}
                            graphFormat={devActivityGraphFormat}
                            graphData={activities}
                            strings={strings.toJS()}
                        />
                    </div>
                </div>]
            )
        }
        return <LoaderComponent />;
    }
    render(){
        return (
            <div styleName="dev-graph-container">
                {this.renderCourseDetails()}
            </div>
        )
    }
}
 

export default ScoreWithInfoBarsDevelopment;