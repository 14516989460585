import React, { Component } from 'react';
import styles from './detailedAnalysis.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import CompetencyTab from './competencyTab';
import {
	getCompetencyArray,
	getCompetencyText,
	checkIfPresent,
	textTruncate
} from 'utils/utilFunctions';
import enumConstants from 'constants/enums';
import getReportUrl from 'constants/urls/reportUrls';
import reports from 'constants/reports';
import ReportDownloadContainer from 'containers/ReportDownloadContainer';
import { TweenMax } from 'gsap';
import LeftArrow from 'svgComponents/leftArrow';
import RightArrow from 'svgComponents/rightArrow';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class DetailedAnalysis extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tabsContainerRef: React.createRef(),
			showLeftArrow: true,
			showRightArrow: true,
			scrollWidth: 300,
			activeTabId: -1
		}
	}

	componentDidMount = () => {
		const { measuresList, userMeasuresList } = this.props;
		const competencies = measuresList.filter((eachMeasure) => {
			return (eachMeasure.get('typeId') === enumConstants.get('COMPETENCY'));
		});

		const tabs = userMeasuresList.filter((userMeasure) => {
			let finalMeasure = competencies.filter((eachMeasure) => {
				return (eachMeasure.get('id') === userMeasure.get('id'));
			});
			finalMeasure = finalMeasure.toJS();
			if (finalMeasure[0] == undefined) {
				return false;
			}
			return true;
		});
		if (tabs.size > 0) {
			this.setState({
				activeTabId: tabs.toJS()[0].id
			});
			this.checkAndSetArrowVisibility();
		}
	}

	setActiveTabId = (id) => {

		this.setState({
			activeTabId: id
		});
	}

	getMeasureDetails = (measuresList, userMeasuresList, competencyValueArray, myStyles) => {
		if (this.state.activeTabId === -1) {
			return null;
		}
		let mainMeasure = measuresList.filter((eachMeasure) => {
			return (eachMeasure.get('id') === this.state.activeTabId);
		});
		mainMeasure = mainMeasure.toJS();
		let userMeasure = userMeasuresList.filter((eachMeasure) => {
			return (eachMeasure.get('id') === this.state.activeTabId);
		});
		userMeasure = userMeasure.toJS();
		if (mainMeasure[0] === undefined || userMeasure[0] === undefined) {
			return null;
		}
		return {
			mainMeasure: mainMeasure[0],
			userMeasure: userMeasure[0]
		}
	}


	findUserMeasureScore = (array, id) => {
		var object = array.find((element) => {
			return element.id == id;
		});
		return object.score;
	}

	getGraphData = (finalMeasure) => {
		return finalMeasure.mainMeasure.subMeasures.map((eachSubMeasure, index) => {
			const userMeasureScoreForSubMeasure = this.findUserMeasureScore(finalMeasure.userMeasure.subMeasures, eachSubMeasure.id);
			return {
				"behaviour": eachSubMeasure.name,
				"Score": userMeasureScoreForSubMeasure
			}
		});
	}

	renderCompetencyTabContent = (finalMeasure, myStyles) => {
		if (!checkIfPresent(finalMeasure)) {
			return null;
		}
		return (
			<CompetencyTab
				key={finalMeasure.mainMeasure.id}
				finalMeasure={finalMeasure}
				graphData={this.getGraphData(finalMeasure)}
			/>
		);
	}

	onClickArrow = (arrowType = 'left') => {
		const currentDiv = this.state.tabsContainerRef.current;
		let scrollLeft = currentDiv.scrollLeft + this.state.scrollWidth;
		if (arrowType === 'left') {
			scrollLeft = currentDiv.scrollLeft - this.state.scrollWidth
		}

		TweenMax.to(currentDiv, 1, {
			scrollLeft,
			onUpdate: () => this.checkAndSetArrowVisibility()
		});
	}

	checkAndSetArrowVisibility = () => {
		const currentDiv = this.state.tabsContainerRef.current;
		let updatedState = {
			showLeftArrow: true,
			showRightArrow: true
		};

		if (currentDiv.scrollLeft === 0) {
			updatedState.showLeftArrow = false;
		}
		if ((currentDiv.scrollLeft + currentDiv.clientWidth) === currentDiv.scrollWidth) {
			updatedState.showRightArrow = false;
		}

		this.setState(updatedState);
	}

	renderLeftArrow = () => {
		if (!this.state.showLeftArrow) {
			return null;
		}
		return (
			<div styleName="arrow-container">
				<div styleName="arrow-content" onClick={this.onClickArrow.bind(this, 'left')}>
					<LeftArrow />
				</div>
			</div>
		);
	}

	renderRightArrow = () => {
		if (!this.state.showRightArrow) {
			return null;
		}
		return (
			<div styleName="arrow-container">
				<div styleName="arrow-content" onClick={this.onClickArrow.bind(this, 'right')}>
					<RightArrow />
				</div>
			</div>
		);
	}

	renderCompetencyTabs = (measuresList, userMeasuresList, competencyValueArray, myStyles) => {
		const tabs = userMeasuresList.map((userMeasure) => {
			let finalMeasure = measuresList.filter((eachMeasure) => {
				return (eachMeasure.get('id') === userMeasure.get('id'));
			});
			finalMeasure = finalMeasure.toJS();
			if (!checkIfPresent(finalMeasure[0])) {
				return null;
			}
			return (
				<div styleName="single-tab-container">
					<div
						className={
							finalMeasure[0].id === this.state.activeTabId
								? css(myStyles.singleActiveTab)
								: css(myStyles.singleTab)
						}
						styleName="single-tab"
						onClick={() => { this.setActiveTabId(finalMeasure[0].id) }}
					>
						<div styleName="text">
							{finalMeasure[0].name}
						</div>
					</div>
					{
						this.state.activeTabId === finalMeasure[0].id
							? <div className={css(myStyles.tabArrow)} styleName="tab-arrow"></div>
							: null
					}
				</div>
			);
		});
		const finalMeasure = this.getMeasureDetails(measuresList, userMeasuresList, competencyValueArray, myStyles);
		return (
			<div styleName="header-container">
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					{this.renderLeftArrow()}
					<div styleName="tabs-container" ref={this.state.tabsContainerRef}>
						{tabs}
					</div>
					{this.renderRightArrow()}
				</div>
				{this.renderCompetencyTabContent(finalMeasure, myStyles)}
			</div>
		);
	}

	openReport = () => {
		// let url = this.props.userMeasuresList.get('reportUrl');
		// if (checkIfPresent(url)) {
		// 	window.open(url, '_blank');
		// }

		let url = getReportUrl();
		const { phaseId, batchId } = this.props.match.params;
		url = url.replace('BATCH_ID', '' + batchId)
			.replace('PHASE_ID', '' + phaseId);
		if (checkIfPresent(url)) {
			window.open(url, '_blank');
		}
		console.log("this is the link", url);
		return;
	}

	renderReportSection = (myStyles, getLabel) => {
		if (
			true ||
			this.props.userMeasuresList
			&& this.props.userMeasuresList.get('reportUrl')
			&& checkIfPresent(this.props.userMeasuresList.get('reportUrl'))
		) {
			return (
				<div className={css(myStyles.downloadReportContainer)} styleName="download-report-container">
					<div className={css(myStyles.downloadReportText)} styleName="download-report-text">
						{getLabel('assessed_criteria_label')}
					</div>
					<ReportDownloadContainer
						reportType={reports.get('assessmentReport')}
						reportOutputType="pdf"
						metadata={{
							batchId: +this.props.match.params.batchId,
							phaseId: +this.props.match.params.phaseId
						}}
						downloadButtonText={this.props.getLabelWithDefaults('download_report', 'Download Report')}
					/>
					{/* <div className={css(myStyles.downloadReportButton)} styleName="download-report-button"
					onClick={this.openReport}
					>
						{getLabel('download_report')}
					</div> */}
				</div>
			);
		}
	}

	renderCompetencyTabComponent = (myStyles, getLabel) => {
		const { measuresList, userMeasuresList } = this.props;
		const competencyValueArray = getCompetencyArray();

		if(!userMeasuresList || userMeasuresList.size === 0){
			return <div styleName="single-tab-container"><div>No measures</div></div>
		}

		const competencies = measuresList.filter((eachMeasure) => {
			return (eachMeasure.get('typeId') === enumConstants.get('COMPETENCY'));
		});

		if (checkIfPresent(userMeasuresList) && (userMeasuresList.size > 0)) {
			return [
				<div className={css(myStyles.detailedAnalysisTitle)} styleName="detailed-analysis-title">
					{getLabel('detailed_analysis_label')}
				</div>,
				<div className={css(myStyles.detailedAnalysisSubTitle)} styleName="detailed-analysis-sub-title">
					{getLabel('detailed_analysis_defination')}
				</div>,
				<div styleName="detailed-analysis-content">
					{this.renderCompetencyTabs(competencies, userMeasuresList, competencyValueArray, myStyles)}
				</div>
			];
		}
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const { getLabel } = this.props;
		// const competencyValueArray = getCompetencyArray();

		// const competencies = measuresList.filter((eachMeasure) => {
		// 	return (eachMeasure.get('typeId') === enumConstants.get('COMPETENCY'));
		// });

		// if(checkIfPresent(userMeasuresList) && (userMeasuresList.size > 0)) {
		// 	return (
		// 		<div styleName="detailed-container">
		// 			{this.renderCompetencyTabComponent(myStyles, getLabel)}
		// 			{this.renderReportSection(myStyles, getLabel)}
		// 		</div>
		// 	);
		// }
		return (
			<div styleName="detailed-container">
				{this.renderCompetencyTabComponent(myStyles, getLabel)}
				{this.renderReportSection(myStyles, getLabel)}
			</div>
		);
	}
}

export default DetailedAnalysis;
