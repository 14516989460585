import React, { Component } from 'react';
import styles from './presentationsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PresentationsIcon extends Component {

	render() {
		return (
			<div styleName="icon-cnt">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<g fill="none" fillRule="evenodd" stroke="#828593" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".875">
						<rect width="13.125" height="13.125" x=".438" y=".438" rx="1.75"/>
						<path d="M4.375 11.375a.219.219 0 1 1 0 .437.219.219 0 0 1 0-.437M7 11.375a.219.219 0 1 1 0 .437.219.219 0 0 1 0-.437M9.625 11.375a.219.219 0 1 1 0 .437.219.219 0 0 1 0-.437M3.33 4.375L2.186 5.688 3.33 7M10.67 4.375l1.143 1.313L10.67 7"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default PresentationsIcon;
