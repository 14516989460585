import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import PrimaryButton from 'commonComponents/buttons/primaryButton';

import AdditionalInformation from 'components/moduleItems/additionalInformation';
import styles from './externalUrl.module.sass';

import applyLabel from 'utils/LabelProvider';
import ExternalUrlEmbedded from './ExternalUrlEmbedded';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { openLinkInNewTab } from 'utils/utilFunctions'; 

@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ExternalUrl extends Component {

    sendSegmentData = () => {
        let segmentData = {
            ...(this.props.match.params),
            referrer: window.location.href,
            pathHash: window.location.hash
        }
        window.analytics.track(
            'External URL Opened',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onClickLaunch = () => {
        const { moduleItem } = this.props;
        this.sendSegmentData();
        openLinkInNewTab(moduleItem.get('moduleItem').get('external_url'));
        this.props.markRead();
    }

    componentDidMount() {
        const { moduleItem } = this.props;
        if (!moduleItem.get('moduleItem').get('new_tab')) {
            this.props.markRead();
        }
    }

    render() {
        const { moduleItem, getLabelWithDefaults } = this.props;
        if (!moduleItem.get('moduleItem').get('new_tab')) {
            return <ExternalUrlEmbedded {...this.props} />
        }
        return (
            <div>
                <AdditionalInformation
                    title={getLabelWithDefaults('instructions_label', "Instructions")}
                    body={moduleItem.get('moduleItem').get('description')} />

                <div styleName="launch-btn-cnt">
                    <PrimaryButton text={getLabelWithDefaults('open_new_tab_label', "Open In New Tab")} clickFunction={this.onClickLaunch} />
                </div>
            </div>
        )
    }
}

export default ExternalUrl;