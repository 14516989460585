import React, { Component } from 'react';
import styles from './measuresFeedback.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class MeasuresFeedback extends Component {

	getCongratsMessage = (activePhaseDetails) => {
		if (!activePhaseDetails) {
			return '';
		}
		return this.props.getLabel('phase_completion_message').replace(
			'PHASE_NAME',
			activePhaseDetails.name
		);
	}

	render() {
		const { activePhaseDetails, skinGuide } = this.props;
		
		const myStyles = getSkin(skinGuide);
		return (
			<div styleName="feedback-container">
				<div styleName="feedback-content">
					<div className={css(myStyles.feedbackContent)} styleName="main-feedback-box">
                        <div className={css(myStyles.mainFeedbackBackground)} styleName="main-feedback-background"></div>
                        <div className={css(myStyles.mainFeedbackImage)} styleName="main-feedback-image"></div>
                        <div styleName="main-feedback-content">
                            <div styleName="assessment-result-text" className={css(myStyles.assessmentResultText)}>
								{this.getCongratsMessage(activePhaseDetails)}
                            </div>
                            <div styleName="feedback-main-heading" className={css(myStyles.feedbackMainHeading)}>
                                {this.props.getLabel('feedback_question_label')}
                            </div>
                        </div>
                    </div>
					
				</div>
			</div>
		);
	}
}

export default MeasuresFeedback;
