import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './summaryComponent.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import InfoAndDaysFilterComponent from 'commonComponents/analytics/infoAndDaysFilterComponent';
import InverselyProportional from 'commonComponents/graphs/inverselyProportional';
import ScoreCardInfoComponent from './scoreCardInfoComponent';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import LockComponent from 'commonComponents/analytics/lockComponent';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class SummaryComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            filterBy: ''
        };
    }

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)){
            return false;
        }
        else{
            return true;
        }
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
		this.props.fetchOverallPerformanceData(+batchId, +userId);
    }

    handleOnClickFilter = (event, filter) => {
        this.setState({
            filterBy: filter
        });
    }

    renderPerformanceSummary = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');

        if(this.props.apiErrors.get('fetchOverallPerformanceDataError')){
            return <APIErrorBoundary 
                key={batchId} 
                apiError={this.props.apiErrors.get('fetchOverallPerformanceDataError')}
                retryHandler={()=>{
                    this.props.fetchOverallPerformanceData(+batchId, +userId);
				}}
            />
        }

        const isComponentLocked = 'locked' in this.props ? this.props.locked : false;

        if (isComponentLocked) {
            return (
                <div styleName="lock-container">
                    <LockComponent 
                        {...this.props}
                        unlockMessageHint={this.props.genericStrings.get('scoreDataInavailableKey')}
                    />
                </div>
            );
        }

        const allGraphsData = this.props.allGraphsData.get('performance');

        if (allGraphsData.get('isOverallPerformanceFetched')) {

            const graphData = allGraphsData.get('overallPerformanceGraphData');
            const strings = graphData.get('strings');
            const filterBy = this.state.filterBy ? this.state.filterBy : graphData.get('filtersEnabledObj').get('selectedFilter').toLowerCase();

            let xLabel, timeFormat = '', valueName;
            if (filterBy === 'day') {
                xLabel = strings.get('days');
                valueName = strings.get('day');
            }
            else if (filterBy === 'week') {
                xLabel = strings.get('weeks');
                valueName = strings.get('week');
            }
            else if (filterBy === 'month') {
                xLabel = strings.get('months');
                valueName = strings.get('month');
            }

            // xLabel = filterBy.charAt(0).toUpperCase() + filterBy.slice(1);
            const barStyling = {
                fromUser: 'rgba(254, 169, 40, 0.8)',
                toUser: 'rgba(254, 169, 40, 0.3)',
                userStroke: '#fea928',
                userRotation: 171,
                fromUserLegend: '#fea928',
                toUserLegend: '#fea928',
                // fromGroup: 'rgba(254, 169, 40, 0.8)',
                // toGroup: 'rgba(254, 169, 40, 0.3)',
                // groupStroke: '#fea928',
                groupRotation: 0,
                // fromGroupLegend: '#fea928',
                // toGroupLegend: '#fea928',
                fromRank: '#2de791',
                toRank: '#2de791',
                rankStroke: '#2de791',
                gridValue: 10,
                componentName: 'performance-summary',
                leftOffset: 0,
                rightOffset: 0,
                ...analyticGroupColors
            };
            const graphFormat = {
                yLabel: strings.get('scoreLabel'),
                yLabelInverse: strings.get('rankLabel'),
                xLabel: xLabel,
                valueFormat: timeFormat,
                valuePrefix: '',
                valueName: valueName
            };

            let filteredGraphData;

            if(graphData.get('isUserDataAvailable')) {
                filteredGraphData = graphData.get(filterBy).toJS();
            } else {
                filteredGraphData = graphData.get('week').toJS();
            }

            // return(
            //     [<InfoAndDaysFilterComponent
            //         filterObject = {graphData.get('filtersEnabledObj')}
            //         {...this.props}
            //         title={strings.get('graphTitle')}
            //         subtitle={strings.get('graphDesc')}
            //         clickHandler={this.handleOnClickFilter}
            //         strings={strings}
            //         key="InfoAndDaysFilterComponent"
            //     />,
            //     <InverselyProportional
            //         isUserDataAvailable={graphData.get('isUserDataAvailable')}
            //         skinGuide={this.props.skinGuide}    
            //         barStyling={barStyling}
            //         graphFormat = {graphFormat}
            //         // data={this.props.data.inverselyProportionalData}
            //         size={this.props.size}
            //         graphData={filteredGraphData}
            //         strings={strings.toJS()}
            //         key="InverselyProportional"
            //     />,
            //     <ScoreCardInfoComponent {...this.props} key="ScoreCardInfoComponent" />]
            // )
            return ([
                <ScoreCardInfoComponent {...this.props} key="ScoreCardInfoComponent" />
            ]);
        }
        return <LoaderComponent />;
    }

    render(){
        return(
            <div styleName="container-class">
                <LegendComponent title={this.props.genericStrings.get('summaryKey')} {...this.props}/>
                <div styleName="outer-container">
                    <div styleName="outer-content">
                        {this.renderPerformanceSummary()}
                    </div>
                </div>
            </div>
        )
    }
}
 

export default SummaryComponent;
