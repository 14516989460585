import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './classroomSession.module.sass';
import applySkin from 'utils/SkinProvider';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import getSkin from './skin.js';
import AdditionalInformation from '../additionalInformation';
import { convertDateWithYear, getDayForDate, getTimeIn12HrFormatFromDateString, getStarComponentForRating } from 'utils/utilFunctions';
import { css } from 'aphrodite/no-important';
import InvertedModuleItemTypes from 'svgComponents/invertedModuleItemTypes';
import LocationIcon from 'svgComponents/locationIcon';
import DownloadIcon from 'svgComponents/downloadIcon';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ClassroomSession extends React.Component {

	onJoinSessionClick = () => {
		window.open(this.props.moduleItem.get('contentDetails').get('session_details').get('url'));
	}

	getResourceSizeInMB = (sizeInBytes) => {
		return (sizeInBytes / (1024 * 1024)).toFixed(2)
	}

	getResourcesList = (resources) => {
		let resourcesList = [];
		let styles = getSkin(this.props.skinGuide);

		resourcesList = resources.map(resource =>
			<div key={`resource_${resource.get('id')}`} className={css(styles.eachResource)} styleName="each-resource-cnt">
				<div styleName="file-icon-cnt">
					<InvertedModuleItemTypes itemType={resource.get('content_type') || 'default'} />
				</div>
				<div className={css(styles.resourceName)} styleName="resource-name">
					<div>{resource.get('filename')}</div>
					<div className={css(styles.resourceSize)}>{this.getResourceSizeInMB(resource.get('size'))} MB</div>
				</div>
				<div>
					<a href={resource.get('url')} download target="_blank">
						<div styleName="download-icon"><DownloadIcon /></div>
					</a>
				</div>
			</div>
		);
		return resourcesList;
	}

	getFacilitatorsList = (facilitators) => {
		let facilitatorsList = [];
		let styles = getSkin(this.props.skinGuide);
		facilitatorsList = facilitators.map((facilitator, index) =>
			<div key={`facilitator_${index}`} styleName="facilitator-details">
				<div className={css(styles.facilitatorProfile)} styleName="facilitator-profile">
					<div styleName="facilitator-avatar">
						<img src={facilitator.get('image_url')} alt="Facilitator"/>
					</div>
					<div styleName="column">
						<div className={css(styles.facilitatorName)}>{facilitator.get('name')}</div>
						<div className={css(styles.facilitatorDesignation)}>{facilitator.get('designation')}</div>
						<div key={`star_comp_${index}`} styleName="row rating">{getStarComponentForRating(facilitator.get('rating'), facilitator.get('max_rating'), `facilitator_${index}`)}<span className={css(styles.facilitatorRating)}>{facilitator.get('rating')}/{facilitator.get('max_rating')}</span></div>
					</div>
				</div>
				<div className={css(styles.facilitatorBio)} styleName="facilitator-bio">
					{facilitator.get('bio') || "No information available"}
				</div>
			</div>
		);
		return facilitatorsList;
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		let { moduleItem, isSidebarOpen, getLabel } = this.props;
		let timIn12hrs = getTimeIn12HrFormatFromDateString(moduleItem.get('contentDetails').get('session_details').get('date'))

		return (
			<div styleName="content-cnt">
				<div styleName={`session-desc-cnt${isSidebarOpen ? ' sidebar-open' : ''}`}>
					<AdditionalInformation title="Details" body={moduleItem.get('contentDetails').get('description')} />

				</div>
				<div styleName={`session-details-cnt${isSidebarOpen ? ' sidebar-open' : ''}`}>
					<div className={css(styles.sessionDetailsHeader)}>
						{getLabel('web_session_date_time_heading_label')}

					</div>
					<div className={css(styles.sessionDetailsTitles)} styleName="date-time-cnt">
						<div styleName="column">
							{convertDateWithYear(moduleItem.get('contentDetails').get('session_details').get('date'))}
						</div>
						<div styleName="time column">
							{timIn12hrs.time}<span className={css(styles.sessionDetailsContent)}>{timIn12hrs.ampm}</span>
						</div>
					</div>
					<div styleName="day-duration-cnt">
						<div className={css(styles.sessionDetailsContent)} styleName="column">
							{getDayForDate(moduleItem.get('contentDetails').get('session_details').get('date'))}
						</div>
						<div className={css(styles.sessionDetailsContent)} styleName="duration column">
							{moduleItem.get('contentDetails').get('session_details').get('duration')} {moduleItem.get('contentDetails').get('session_details').get('duration_unit')}
						</div>
					</div>

					<div styleName="add-to-calendar-cnt">

					</div>

					<div className={css(styles.sessionDetailsHeader)}>
						VENUE
					</div>
					<div className={css(styles.sessionDetailsContent)} styleName="address-cnt">
						<div styleName="location-icon-cnt"><LocationIcon /></div>
						<div styleName="location-text">{moduleItem.get('contentDetails').get('session_details').get('venue').get('address')}</div>
					</div>
				</div>
				<div styleName={`session-desc-cnt${isSidebarOpen ? ' sidebar-open' : ''}`}>
					<div styleName="button">
						<PrimaryButton text={getLabel('web_session_join_link_label')} clickFunction={this.onJoinSessionClick} />
					</div>
				</div>
				{
					(moduleItem.get('contentDetails').get('al_data').get('resources') || moduleItem.get('contentDetails').get('al_data').get('facilitators')) &&
					<div styleName="other-session-detail-cnt">
						{
							moduleItem.getIn(['contentDetails', 'al_data', 'resources']) &&
							moduleItem.getIn(['contentDetails', 'al_data', 'resources']).size !== 0 &&
							<div styleName="resources-cnt">
								<div className={css(styles.containerHeader)} styleName="resources-cnt-header">
								{getLabel('web_session_resources_label')}
								</div>
								<div className={css(styles.resourcesCnt)} styleName="resources-list">
									{this.getResourcesList(moduleItem.get('contentDetails').get('al_data').get('resources'))}
								</div>
							</div>
						}
						{
							moduleItem.getIn(['contentDetails', 'al_data', 'facilitators']) &&
							moduleItem.getIn(['contentDetails', 'al_data', 'facilitators']).size !== 0 &&
							<div styleName="facilitator-cnt">
								<div className={css(styles.containerHeader)} styleName="facilitator-cnt-header">
									{getLabel('web_session_facilitators_label')}
								</div>
								{this.getFacilitatorsList(moduleItem.get('contentDetails').get('al_data').get('facilitators'))}
							</div>
						}
					</div>
				}
			</div>
		)
	}
}

export default ClassroomSession;