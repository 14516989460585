import React, { Component } from 'react'
import CourseFeedbackMain from 'components/courseFeedbackMain'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import styles from './feedbackWrapper.module.sass';
import getSkin from './skin.js';
import CircularLoader from 'svgComponents/circularLoader'
import BackArrow from 'svgComponents/backArrow';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class FeedbackWrapper extends Component {

    componentDidMount(){
        const { selectedEntity } = this.props;
        this.props.fetchEntityFeedback(this.props.batchId, selectedEntity.get('entityId'), selectedEntity.get('entityType'), selectedEntity.get('scenario'));
    }

    submitAnswerForFeedback = (payload, questionId) => {
        const { selectedEntity } = this.props;
        this.props.submitAnswerForFeedback(payload, questionId, selectedEntity.get('entityId'), selectedEntity.get('entityType'), selectedEntity.get('scenario'), this.props.batchId);
    }

    submitFeedback = (entityId, payload, successCallback) => {
        const { selectedEntity } = this.props;
        this.props.submitFeedback(payload, selectedEntity.get('entityId'), selectedEntity.get('entityType'), selectedEntity.get('scenario'), this.props.batchId, successCallback);
    }

    renderLoader = () => {
		return <div style={{ height: '100%', width: '43px', margin: 'auto' }}>
			<CircularLoader />
		</div>
	}

    getEntityLabel = (entity) => {
        const { batchDetails, getLabel } = this.props;

        if(batchDetails.get('totalItems') === 1)
            return getLabel('course_feedback_label');

        if(entity.get('scenario') === 'ASSESSMENT_PHASE')
            return getLabel('assessment_phase_feedback_label');
            
        return entity.get('entityName')
    }

    render() {
        const { selectedEntity, loading, entityFeedback, match, onUnSelectEntity, skinGuide } = this.props;
        const styles = getSkin(skinGuide);

        if(loading) return this.renderLoader();

        return (
            <div styleName="container">
                <div styleName="header">
                    <div styleName="backarrow-icon" onClick={onUnSelectEntity}>
                        <BackArrow color={skinGuide.get('skinHex').get('greyColor3')}/>
                    </div>
                    <div className={css(styles.entityName)} styleName="entity-name">
                        {this.getEntityLabel(selectedEntity)}
                    </div>
                </div>
                <div styleName="feedback-form-container">
                    <CourseFeedbackMain
                        courseFeedback={entityFeedback}
                        match={match}
                        submitAnswerForFeedback={this.submitAnswerForFeedback}
                        submitFeedback={this.submitFeedback}
                        forceForm={true}
                        onSaveCallback={onUnSelectEntity}
                    />
                </div>
            </div>
        )
    }
}

export default FeedbackWrapper;