import React, { Component } from 'react';
import styles from './myJourneyLinkComponent.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import SecondaryButton from 'commonComponents/buttons/secondaryButton';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class MyJourneyLinkComponent extends Component {

	redirectToMyJourneys = () => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash
		}
		window.analytics.track(
			'My Journeys link clicked',
			segmentData,
			getAmplitudeSessionDetails()
		)
		const route = routes.get('MY_JOURNEY');
		updateRoute({ route });
	}

	render() {
		const { getLabel, userOrganization } = this.props;
		if (window.location.hash.includes('journeys') || window.location.hash.includes('home')) {
			return null;
		}
		if (
			userOrganization
			&& (userOrganization.getIn(['userOrganization', 'activeDealId']) === null || !userOrganization.getIn(['userOrganization', 'isActive']))) {
			return null;
		}
		return (
			<div styleName="my-journeys-btn-cnt" onClick={this.redirectToMyJourneys}>
				<SecondaryButton text={getLabel('my_journeys_label')} />
			</div>
		);
	}
}

export default MyJourneyLinkComponent;
