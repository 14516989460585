import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		userStatsBackground: {
			backgroundImage: `linear-gradient(to top, #3d486f, ${skinHex.get('greyColor2')})`
		},
		homePageTitles: {
			...myTypography.h5,
			color: '#282828'
		},
		statCount: {
			...myTypography.h5,
			fontSize: '38px',
			fontWeight: 'normal',
			color: skinHex.get('white')
		},
		multiStats: {
			...myTypography.button,
			color: skinHex.get('white'),
			marginRight: '10px !important'
		},
		statLabel: {
			...myTypography.caption,
			color: hexToRgbA(skinHex.get('white'), 0.8),
			fontWeight: 'bold',
			fontSize: 14,
			width: '59px'
		},
		statLabelNoWidth: {
			...myTypography.caption,
			color: hexToRgbA(skinHex.get('white')),
			fontWeight: 'normal',
			fontSize: '20px'

		},
		courseCatalogueLink: {
			...myTypography.button,
			color: skinHex.get('secondaryColor'),
			textTransform: 'uppercase',
			cursor: 'pointer'
		}
	});
}

export default getSkin;