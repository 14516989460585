import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import FeedbackPattern from 'images/feedback_pattern.png';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        tagText:{
            opacity: 0.8,
            ...myTypography.caption,
            fontWeight: "normal",
            color: '#313131'
        },
        tagSelectedText:{
            opacity: 0.8,
            ...myTypography.caption,
            fontWeight: "normal",
            color: skin.get('primaryColor')
        },

        tagTypeSelected: {
            ...myTypography.caption,
            fontWeight: "normal",
            backgroundColor: skin.get('primaryColor'),
            color: skin.get('white')
        }
       
    });
}

export default getSkin;