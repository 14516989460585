import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreComponent.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import { filterObjectFromArray } from 'utils/objectUtils';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import ScoreComponentAssesment from './scoreComponentAssesment';
import ScoreComponentDevelopment from './scoreComponentDevelopment';
import LockComponent from 'commonComponents/analytics/lockComponent';

@CSSModules(styles, { allowMultiple: true })

class ScoreComponent extends React.Component {

    constructor(props) {
        super(props);
        const phasesList = props.phases.get('phasesList');
        const batchPhasesList = phasesList.filter(phase => phase.get('batchId') == this.props.match.params.batchId);
        const userPhasesList = props.phases.get('userPhasesList');
        const selectedPhase = filterObjectFromArray(batchPhasesList, 'order', 1);
        const selectedUserPhase = filterObjectFromArray(userPhasesList, 'id', selectedPhase.get('id'));
        this.state = {
            selectedPhase: selectedPhase,
            selectedUserPhase: selectedUserPhase
        };
    }

    onTabClicked = (event, phase) => {
        const userPhasesList = this.props.phases.get('userPhasesList');
        const selectedUserPhase = filterObjectFromArray(userPhasesList, 'id', phase.get('id'));
        this.setState({
            selectedPhase: phase,
            selectedUserPhase: selectedUserPhase
        });
    }

    renderPhaseTabs = () => {
        const { phases } = this.props;
        const phasesList = phases.get('phasesList');
        const batchPhasesList = phasesList.filter(phase => phase.get('batchId') == this.props.match.params.batchId);

        const myStyles = getSkin(this.props.skinGuide);

        return batchPhasesList.map((phase, index) => {
            const getSelectedPhaseStyle = this.state.selectedPhase.get('name') === phase.get('name') ? 'tabFontStyleSelected' : 'tabFontStyle';
            return (
                <div key={phase.get('id')} onClick={(e) => this.onTabClicked(e, phase)} className={css(myStyles[getSelectedPhaseStyle])} styleName="tab-button">{phase.get('name')}</div>
            );
        })
    }

    renderSelectedPhaseScoreComponent = () => {
        const { selectedPhase, selectedUserPhase } = this.state;
        if (selectedUserPhase.get('workflowState') === 'NOT_STARTED' && selectedUserPhase.get('completedItems') == 0) {
            return <LockComponent
                {...this.props}
                unlockMessageHint={this.props.genericStrings.get('scoreDataInavailableKey')}
            />
        }
        if (selectedPhase.get('type') === 'Assessment') {
            return <ScoreComponentAssesment
                key={selectedPhase.get('id')}
                selectedPhase={selectedPhase}
                {...this.props}
            />;
        }
        else if (selectedPhase.get('type') === 'Development') {
            return <ScoreComponentDevelopment
                key={selectedPhase.get('id')}
                selectedPhase={selectedPhase}
                {...this.props}
            />;
        }
        return null;
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="container-class">
                <div className={css(myStyles.tabStyle)} styleName="tabs-container">
                    {this.renderPhaseTabs()}
                </div>
                <LegendComponent title={this.props.genericStrings.get('masteryKey')} {...this.props} />
                {this.renderSelectedPhaseScoreComponent()}
            </div>
        )
    }
}


export default ScoreComponent;
