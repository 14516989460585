import actionConsts from 'constants/actions';

const setUserDetails = userDetails => ({
    type: actionConsts.get('SET_USER_DETAILS'),
    userDetails
});

const setLogoutURL = (url) => ({
    type: actionConsts.get('SET_LOGOUT_URL'),
    url
});

const setAnalyticsData = (url) => ({
    type: actionConsts.get('ANALYTICS'),
    url
})

const setActivityData = (url) => ({
    type: actionConsts.get('ACTIVITY'),
    url
})

const setPerformanceData = (url) => ({
    type: actionConsts.get('PERFORMANCE'),
    url
})

const setLogoutClicked = (flag) => ({
    type: actionConsts.get('LOGOUT_CLICKED'),
    flag
})

const setUserWhitelistingRequirements = (userWhitelistingRequirements) => ({
    type: actionConsts.get('SET_USER_WHITELISTING_REQUIREMENTS'),
    userWhitelistingRequirements
});

export {
    setUserDetails,
    setLogoutURL,
    setActivityData,
    setAnalyticsData,
    setPerformanceData,
    setLogoutClicked,
    setUserWhitelistingRequirements
};
