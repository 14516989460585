import React, { Component } from 'react';
import styles from './lowHighPerformance.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import ScoreComponent from 'commonComponents/scoreComponent';
import { hexToRgbA } from 'utils/styleUtil';
import { getCompetencyText, getCompetencyBackground } from 'utils/utilFunctions';
import TargetArrow from 'svgComponents/targetArrow';
import InfoTooltip from 'commonComponents/infoTooltip';
import {isEmptyObject} from 'utils/objectUtils';
import {checkIfPresent} from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LowHighPerformance extends Component {

	constructor(props) {
		super(props);
		this.state = {
			renderComponent: 'HIGH' //can take values ['LOW', 'HIGH]
		};
	}

	setScoreComponent = (componentTag) => {
		this.setState({
			renderComponent: componentTag
		});
	}

	renderRightSideContent = (myStyles) => {
		return (
			<div styleName="right-container">
				<div className={css(myStyles.rightTitle)} styleName="right-title">Do Your Self Realizations Quickly Fade</div>
				<div className={css(myStyles.rightDesc)} styleName="right-desc">
					When I was just starting 6th grade I got my first job. Paperboy! Boy, was I excited.
					At that time I had spent a lot of time actually playing the video game Paperboy,
					so I knew I had what it took to get the job done. But, its just not that easy. 
					<br/><br/>
					The StarTribune doesn’t just hand out the responsibility of informing their community’s
					citizenry on a daily basis to just any fool that says they’d like a shot at paper delivery
					greatness. Nope, I had to interview for this job. And I tell you, I was magnificent,
					so much so that, as you know, I got the position. Upon completion of the interview the
					gentleman that was to give me my first opportunity at financial freedom stood up from my
					dining room table, walked to the front door and with a smile and a handshake, was off into
					the darkness. 
				</div>
			</div>
		);
	}

	renderContent = (measuresList, usersList, myStyles) => {
		const competencyBoxes = usersList.map((userMeasure) => {
			let finalMeasure = measuresList.filter((eachMeasure) => {
				return (eachMeasure.get('id') === userMeasure.get('id'));
			});
			finalMeasure = finalMeasure.toJS();
			if(finalMeasure[0] == undefined || isEmptyObject(finalMeasure[0])) {
				return null;
			}
			return (
				<div className={css(myStyles.competencyBox)} styleName="competency-box">
					<div className={css(myStyles.competencyHeader)} styleName="competency-header">
						<div className={css(myStyles.competencyIcon)} styleName="competency-icon"><TargetArrow /></div>
						<div className={css(myStyles.competencyName)} styleName="competency-name">{finalMeasure[0].name}</div>
						<div className={css(myStyles.competencyInfo)} styleName="competency-info">
							<InfoTooltip tooltipText={finalMeasure[0].description}/>
						</div>
					</div>
					<div styleName="competency-score-box">
						<ScoreComponent
							score={userMeasure.get('score') > 5 ? 5 : userMeasure.get('score')}
							scoreColor={this.props.skinGuide.get('skinHex').get('greyColor2')}
							totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.5)}
							text={getCompetencyText(
								userMeasure.get('score') > 5 ? 5 : userMeasure.get('score'),
								this.props.getLabel
							)}
							textBackground={getCompetencyBackground(userMeasure.get('score') > 5 ? 5 : userMeasure.get('score'))}
						/>
					</div>
				</div>
			);
		});
		return (
			<div styleName="score-container">
				<div styleName="left-container">{competencyBoxes}</div>
				{/* {this.renderRightSideContent(myStyles)} */}
			</div>
		);
	}

	renderHeaderTabs = (myStyles) => {
		return (
			<div styleName="header-tabs-container">
				<div styleName="header-tabs">
					<div
						className={this.state.renderComponent == 'HIGH' ? css(myStyles.eachTabFocus) :css(myStyles.eachTab)}
						styleName="each-tab"
						onClick={() => { this.setScoreComponent('HIGH') }}
					>
						<div styleName="tab-title">{this.props.getLabel('high_score_label')}</div>
						<div
							className={this.state.renderComponent == 'HIGH' ? css(myStyles.eachTabLineFocus) :css(myStyles.eachTabLine)}
							styleName="each-tab-line">
						</div>
					</div>
					<div
						className={this.state.renderComponent == 'LOW' ? css(myStyles.eachTabFocus) :css(myStyles.eachTab)}
						styleName="each-tab"
						onClick={() => { this.setScoreComponent('LOW') }}
					>
						<div styleName="tab-title">{this.props.getLabel('low_score_label')}</div>
						<div
							className={this.state.renderComponent == 'LOW' ? css(myStyles.eachTabLineFocus) :css(myStyles.eachTabLine)}
							styleName="each-tab-line">
						</div>
					</div>
				</div>
				<div
					className={css(myStyles.tabsLine)}
					styleName="tabs-line"></div>
			</div>
		);
	}

	render() {

		const { measuresList, userHighScoresList, userLowScoresList } = this.props;
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="low-high-container">
				{/* {this.renderHeaderTabs(myStyles)} */}
				<div styleName="content-container">
					{/* {
						(this.state.renderComponent == 'HIGH')
						? this.renderContent(measuresList, userHighScoresList, myStyles)
						: this.renderContent(measuresList, userLowScoresList, myStyles)
					} */}
					{
						(checkIfPresent(userHighScoresList) && userHighScoresList.size > 0)
						?	[
								<div className={css(myStyles.eachTab)} styleName="each-tab tab-title">{this.props.getLabel('high_score_label')}</div>,
								this.renderContent(measuresList, userHighScoresList, myStyles)
							]
						:	null
					}
					{
						(checkIfPresent(userLowScoresList) && userLowScoresList.size > 0)
						?	[
								<div className={css(myStyles.eachTab)} styleName="each-tab tab-title">{this.props.getLabel('low_score_label')}</div>,
								this.renderContent(measuresList, userLowScoresList, myStyles)
							]
						:	null
					}
				</div>
			</div>
		);
	}
}

export default LowHighPerformance;
