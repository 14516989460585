import moment from 'moment';
import 'moment/locale/tr';
import localesMap from 'constants/locales';
import { getOrganizationLocale } from 'utils/orgUtils';

const getMomentLocale = () => {
    try{
        return localesMap.get(getOrganizationLocale().toLowerCase());
    }catch(e){
        return 'en';
    }
}

const getFormattedDate = (date, format="long") => {
    const momentLocale = getMomentLocale();
    const momentDate = moment(date).locale(momentLocale);
    const currentYear = moment().year();
    const dateYear = momentDate.year();
    const isDateInSameYear = (currentYear == dateYear);
    if(format==="short")
        return getShortDateString(momentDate, !isDateInSameYear);
    return getLongDateString(momentDate, !isDateInSameYear);
};

const getRelativeDate = (date, showOnlyTime=false) => {
    const momentLocale = getMomentLocale();
    return moment(date).locale(momentLocale).calendar(null, {
        sameDay: showOnlyTime ? 'h:mm A' :  '[Today] [at] h:mm A',
        nextDay: showOnlyTime ? 'h:mm A' :  '[Tomorrow] [at] h:mm A'
    });
}

const getLongDateString = (date, showYear=true) => {
    if(showYear)
        return date.format('ddd MMMM Do, YYYY [at] h:mm A');
    return date.format('ddd MMMM Do [at] h:mm A');
}

const getShortDateString = (date, showYear=true) => {
    if(showYear)
        return date.format('MMMM Do, YYYY [at] h:mm A');
    return date.format('MMMM Do [at] h:mm A');
}

const getDateAndMonthShortName = (date) => {
    const momentLocale = getMomentLocale();
    return moment(date).locale(momentLocale).format('DD MMM');
}

const getWeekdayFromDate = (date) => {
    const momentLocale = getMomentLocale();
    return moment(date).locale(momentLocale).format('dddd');
}

const getTimeFromDateString = (date) => {
    const momentLocale = getMomentLocale();

    return {
        time: moment(date).locale(momentLocale).format('hh:mm'),
        ampm: moment(date).locale(momentLocale).format('a')
    }

}

const getDateRangeString = (startDate, endDate) => {

    const momentLocale = getMomentLocale();
    const date1 = moment(startDate);
    const date2 = moment(endDate);

    console.log('debugws time comparisons', date1 === date2, date1>date2, date2>date1, date2.isAfter(date1));

    if(date2.isAfter(date1, 'day')){
        return `${moment(startDate).locale(momentLocale).format('MMMM Do, YYYY')} - ${moment(endDate).locale(momentLocale).format('MMMM Do, YYYY')}`;
    }
    return moment(startDate).locale(momentLocale).format('MMMM Do, YYYY');
}

const getTimeRangeString = (startDate, endDate) => {
   const startTime = getTimeFromDateString(startDate);
   const endTime = getTimeFromDateString(endDate);
   return `${startTime.time} ${startTime.ampm} - ${endTime.time} ${endTime.ampm}`;
}

const isItToday = (date) => {
    console.log('debugws: getBatch isItToday', date, moment(date).isSame(moment(), 'day'));
    return moment(date).isSame(moment(), 'day');
}


const isItTomorrow = (date) => {
    const tomorrow = moment().add('1', 'day');
    return moment(date).isSame(tomorrow, 'day');
}

const isItTodayOrTomorrow = (date) => {
    return isItToday(date) || isItTomorrow(date)
}

const isItLaterTodayOrTomorrow = (date, expiryDate=null, expiryBufferInSeconds=90) => {
    const ifExpirtyDateIsAlmostHere = expiryDate && moment(expiryDate).diff(moment(), 'seconds') <= expiryBufferInSeconds;
    if(ifExpirtyDateIsAlmostHere){
        return false;
    }
    return isItTodayOrTomorrow(date) && moment(date).isAfter(moment());
}

const isCurrentDateTimeInRange = (startDateTime, endDateTime, startTimeBuffer=120, endTimeBuffer=90) => {
    const currentDateTime = moment();
    let currentDateTimeWithBuffer = moment();
    
    if(currentDateTime.isBefore(moment(startDateTime))){
        currentDateTimeWithBuffer = currentDateTimeWithBuffer.add(startTimeBuffer, 'seconds');
    }

    endDateTime = moment(endDateTime).subtract(endTimeBuffer, 'seconds');
    
    if(currentDateTimeWithBuffer.isSameOrAfter(moment(startDateTime)) && currentDateTime.isBefore(endDateTime)){
        return true;
    }

    return false;
}

const getEndOfToday = () => {
    return moment().endOf('day').valueOf();
}

const isLater = (date) => {
    return moment(date).isAfter(moment());
}

const doTheseDatesHaveTheSameDay = (date1, date2) => {
    return moment(date1).isSame(moment(date2), 'day');
}

const getTimerText = (timeInMilliseconds) => {
    return moment.utc(timeInMilliseconds).format('mm:ss');
}

const getTimeInMinutes = (timeInMilliseconds) => {
    return moment.utc(timeInMilliseconds).format('m');
}

export { getFormattedDate, getRelativeDate, getDateAndMonthShortName, getMomentLocale, getWeekdayFromDate, getTimeFromDateString, getDateRangeString, getTimeRangeString, isItToday, isCurrentDateTimeInRange, isItLaterTodayOrTomorrow, getEndOfToday, isLater, isItTodayOrTomorrow, doTheseDatesHaveTheSameDay, getTimerText, getTimeInMinutes };