import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './prerequisite.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import PreRequisiteSvg from 'svgComponents/preRequisite';
import TickMark from 'svgComponents/tickMark';
import PreRequisiteLoader from 'commonComponents/loaders/preRequisiteLoader';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PreRequisite extends Component {

	renderSinglePreRequisiteImage = (preRequisite) => {
		const isPreRequisiteImageAvailable = preRequisite.get('imageUrl') !== null
			&& preRequisite.get('imageUrl') !== ""
			&& preRequisite.get('imageUrl') !== undefined;
		if (isPreRequisiteImageAvailable) {
			return <img src={preRequisite.get('imageUrl')} styleName="pre-requisite-image" />
		}
		return <TickMark />
	}

	renderSinglePreRequisite(preRequisite, index) {
		return (
			<div key={`preRequisite-${index}`} styleName="single-preReq">
				<div styleName="single-preReq-image">
					{this.renderSinglePreRequisiteImage(preRequisite)}
				</div>
				<div styleName="single-preReq-content">{preRequisite.get('info')}</div>
			</div>
		);
	}

	renderPreRequisites() {
		const preRequisites = this.props.journeyDetails.get('prerequisitesInfo');
		return preRequisites.map(
			(preRequisite, index) => this.renderSinglePreRequisite(preRequisite, index)
		);
	}

	renderLoader = () => {
		return (
			<div styleName="pre-container">
				<div styleName="pre-content-loader">
					<PreRequisiteLoader />
				</div>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		if (!this.props.isBatchDetailsAvailable || !this.props.isBatchLabelsAvailable) {
			return this.renderLoader();
		}

		// if(this.props.divName == "prerequisitesAndSkills") {
		// 	this.refs.prerequisitesAndSkills.scrollIntoView({block: 'start', behavior: 'smooth'});
		// }
		return (
			<div styleName="pre-container">
				<div styleName="pre-content">
					<div styleName="pre-image">
						<PreRequisiteSvg />
					</div>
					<div className={css(myStyles.preReqTitle)} styleName="title">
						{this.props.getLabel('journey_prereq_label')}
					</div>
					<div className={css(myStyles.preReqDesc)} styleName="desc-container">
						{this.renderPreRequisites()}
					</div>
				</div>
			</div>
		)
	}
}

export default PreRequisite;
