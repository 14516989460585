import React, { Component } from 'react';
import styles from './informationIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class InformationIcon extends Component {

	render() {
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 16 16">
					<path fill={this.props.fillColor || "#4A54AECC"} fillRule="nonzero" d="M7.2 12h1.6V7.2H7.2V12zM8 0C3.58 0 0 3.58 0 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm0 15c-3.859 0-7-3.141-7-7s3.141-7 7-7 7 3.141 7 7-3.141 7-7 7zm-.8-9.4h1.6V4H7.2v1.6z" />
				</svg>
			</div>
		);
	}
}



export default InformationIcon;
