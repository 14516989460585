import React, { Component } from 'react';
import styles from './bitesIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class BitesIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 15 15">
					<g fill="none" fillRule="evenodd">
						<path fill="#828593" d="M14.708.875a.583.583 0 0 0-.583-.583h-10.5a.583.583 0 0 0-.583.583v10.5c0 .322.26.583.583.583h10.5a.583.583 0 0 0 .583-.583V.875z"/>
						<path stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" d="M2.042 2.625h-.167a.583.583 0 0 0-.583.583v8.917c0 .875.75 1.583 1.583 1.583h8.917a.583.583 0 0 0 .583-.583v-.167"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default BitesIcon;
