import Immutable from 'immutable';
import actionConstants from 'constants/actions';
import { setOrUpdateObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS([]);

function sortBasedOnPosition(questions) {
	return questions.sort((q1, q2) => q1.position - q2.position)
}

function findAndUpdateQuestions(allQuestions, questions) {
	let updatedQuestionList = allQuestions.map(q => q);
	questions.forEach(q => {
		let questionToUpdate = updatedQuestionList.find(que => que.get('id') === q.id);
		for (let key in q)
			if (key !== 'answers')
				questionToUpdate = questionToUpdate.set(key, q[key]);

		updatedQuestionList = setOrUpdateObjectInList(updatedQuestionList, questionToUpdate.toJS(), 'id', 'id')
	})
	return updatedQuestionList;
}

const quizQuestions = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_QUIZ_QUESTIONS'):
			return Immutable.fromJS(action.questions.quiz_submission_questions)
		case actionConstants.get('UPDATE_QUESTION'):
			if (action.payload) {
				return findAndUpdateQuestions(state, action.payload.quiz_submission_questions)
			} else {
				return state
			}
		default:
			return state
	}
}

export default quizQuestions;