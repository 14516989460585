import React, { Component } from 'react';
import styles from './errorSomethingWrong.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ErrorSomethingWrong extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="main-component">
				<svg width="100%" height="100%" viewBox="0 0 536 380">
					<g fill="none" fillRule="evenodd">
						<path fill="#F2F3F7" d="M220.765 376.498c24.482 0 71.538 7.88 111.019 0 46.452-9.272 85.287-35.352 106.652-48.24 57.252-34.536 115.386-110.85 92.427-170.399-36.31-94.18-101.53-50.479-178.975-81.543-31.787-12.75-111.17-54.905-160.876-68.468C67.687-25.802 0 53.338 0 157.859c0 41.29 21.695 123.275 44.22 159.934 34.494 56.137 97.613 58.705 176.545 58.705z"/>
						<path fill="#00C6FF" d="M55.336 256.208c14.892-11.5 73.678-34.965 94.03-39.216 6.704-1.4 62.445-12.746 69.13-14.224 8.579-1.898 39.87 7.288 43.293 7.513 43.05 2.835 34.818-10.415 78.048-7.513 23.216 1.558 60.644-13.616 69.36 7.82l2.605 1.762c.583 1.435 9.501 4.055 10.942 4.642 17.823 7.264 36.477 1.581 53.451 15.746 14.39 12.007-11.198 39.02-19.776 43.518-11.34 5.946-84.79 50.26-121.16 35.275-11.108-4.577-97.798 9.49-116.763-8.705-9.96-9.555-81.044-4.781-97.338-5.328-30.856-1.035-69.949 7.469-75.158-29.701l9.336-11.589z" opacity=".119"/>
						<path stroke="#F5F5F5" d="M140.184 237c-9.343 2.902-19.617 15.21-4.495 20.814 8.215 3.045 17.431 2.79 25.615 5.916 32.264 12.321 1.095 13.158 51.696 9.057M101 248c-25.987 35.215 9.991 18.898 36 37M240 274.167c37.977 1.644 117.065 42.658 125-31.167M348.015 301.678c21.927 0 17.02-5.758 37.56-18.324 2.19-1.34 79.589-34.264 40.555-50.563-5.31-2.217-11.262-2.366-16.893-3.55M297 261.72c21.905 0 56.663 4.946 53.838-26.72"/>
						<path fill="#AAB2CE" d="M342.651 153.586l-2.33 88.631-118.246 13.06c-5.03-.4-8.492-1.171-10.385-2.311-2.84-1.71-4.802-2.312-4.802 0 0 1.54-2.377 2.784-7.13 3.73-2.952.596-5.25.123-6.896-1.419-1.645-1.54-5.19-1.068-10.636 1.42l-15.103-11.634c10.495-9.968 18.477-16.62 23.947-19.953 21.158-12.896 53.166-28.206 65.805-34.38 17.54-8.567 46.132-20.948 85.776-37.144z"/>
						<path fill="#C6CDE3" d="M160.389 253.059l2.242-.37 4.422.37c1.088-.204 2.022-.204 2.803 0 1.18.308 2.16.118 4.267 1.87 4.198 3.49 7.316 4.006 9.355 1.544 1.381-5.36 3.105-7.62 5.171-6.782 2.066.84 5.026.84 8.88 0l155.393-26.34L391 216.897l-36.854-48.107-156.618 56.55-8.879 5.544-9.56 6.485-9.604 7.917-3.368 2.908-5.728 4.865c-.689.339-2.37.509-5.044.509-2.675 0-4.875-1.045-6.602-3.135-3.162-2.877-4.743-4.815-4.743-5.814 0-1.498 4.067-3.14 4.743-2.827 7.457 3.445 7.823 2.02 10.037-2.884.905-2.005.696-3.666 1.197-4.92.556-1.393 1.268-2.428 2.135-3.104 3.809.294 5.713 1.704 5.713 4.232 0 2.528 1.14 2.528 3.42 0l5.74-14.486c4.34-3.634 8.227-6.3 11.664-7.999 3.437-1.698 11.16-6.924 23.17-15.679l15.538-9.987 10.043-8.398 29.648-22.026 29.895-23.41L311.096 123h3.81l27.97 30.86-14.773 6.833-53.918 22.413-67.835 33.791-14.05 8.444-3.651 3.596"/>
					</g>
				</svg>
			</div>
		);
	}
}



export default ErrorSomethingWrong;
