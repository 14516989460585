import React, { Component } from 'react';
import styles from './navigationMenu.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class NavigationMenu extends Component {

    render() {

        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div>
                <svg width="19" height="16" viewBox="0 0 19 16">
                    <g className={css(myStyles.fillColor)} fillRule="nonzero">
                        <rect width="18.208" height="2.375" x=".396" y=".396" rx="1" />
                        <rect width="18.208" height="2.375" x=".396" y="6.729" rx="1" />
                        <rect width="18.208" height="2.375" x=".396" y="13.063" rx="1" />
                    </g>
                </svg>
            </div>
        );
    }
}



export default NavigationMenu;
