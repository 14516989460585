import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './progressTimeSpentDevelopment.module.sass';
import LegendComponent from 'commonComponents/analytics/legendComponent';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent/InfoWithoutFilterComponent';
import InfoWithFilterComponent from 'commonComponents/analytics/infoWithFilterComponent';
import AreaWithLine from 'commonComponents/graphs/areaWithLine';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import DropDown from 'commonComponents/analytics/dropDown';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class ProgressTimeSpentDevelopment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            courseIdForSectionGraph: null,
            courseId: null,
            sectionId: null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
            return false;
        }
        else {
            return true;
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (state.courseId) {
            return null;
        }
        const allGraphsData = props.allGraphsData.get('development');
        if (allGraphsData.get('isDevelopmentTimeSpentFetched')) {
            const graphData = allGraphsData.get('developmentTimeSpentData').toJS();
            const courses = allGraphsData.get('developmentTimeSpentData').get('courses').toJS();
            if (courses.data.length > 0) {
                return (
                    {
                        courseIdForSectionGraph: 'selectedCourseId' in graphData ? graphData['selectedCourseId'] : -1,
                        courseId: 'selectedCourseId' in graphData ? graphData['selectedCourseId'] : -1,
                        sectionId: 'selectedSectionId' in graphData ? graphData['selectedSectionId'] : -1
                    }
                )
            }
        }
        return null;
    }


    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        this.props.fetchDevelopmentTimeSpentData(+batchId, +userId, +phaseId);
    }

    getSections = (courseId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        const courses = allGraphsData.get('developmentTimeSpentData').get('courses').toJS();
        const sections = allGraphsData.get('developmentTimeSpentData').get('sections').toJS();

        let data, overallAverage, userAverage;
        if (courseId === -1) {
            data = sections.data;
            overallAverage = courses.sectionOverallAverage;
            userAverage = courses.sectionUserAverage;
        }
        else {
            const courseData = courses.data.filter((course) => course.id === courseId)[0];
            data = sections.data.filter((section) => section.courseId === courseId);
            overallAverage = courseData.sectionOverallAverage;
            userAverage = courseData.sectionUserAverage;
        }

        return {
            data: data,
            metadata: sections.metadata,
            overallAverage: overallAverage,
            userAverage: userAverage
        };
    }

    getActivities = (courseId, sectionId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        const courses = allGraphsData.get('developmentTimeSpentData').get('courses').toJS();
        const sections = allGraphsData.get('developmentTimeSpentData').get('sections').toJS();
        const activities = allGraphsData.get('developmentTimeSpentData').get('activities').toJS();

        let data, overallAverage, userAverage;
        if (courseId === -1) {
            if (sectionId === -1) {
                data = activities.data;
                overallAverage = courses.activitiesOverallAverage;
                userAverage = courses.activitiesUserAverage;
            }
            else {
                const sectionData = sections.data.filter((section) => section.id === sectionId)[0];
                data = activities.data.filter((activity) => activity.sectionId == sectionId);
                overallAverage = sectionData.activitiesOverallAverage;
                userAverage = sectionData.activitiesUserAverage;
            }
        }
        else {
            const courseData = courses.data.filter((course) => course.id === courseId)[0];
            if (sectionId === -1) {
                data = activities.data.filter((activity) => activity.courseId === courseId);
                overallAverage = courseData.activitiesOverallAverage;
                userAverage = courseData.activitiesUserAverage;
            }
            else {
                const sectionData = sections.data.filter((section) => section.id === sectionId)[0];
                data = activities.data.filter((activity) => activity.courseId === courseId && activity.sectionId == sectionId);
                overallAverage = sectionData.activitiesOverallAverage;
                userAverage = sectionData.activitiesUserAverage;
            }
        }

        return {
            data: data,
            metadata: activities.metadata,
            overallAverage: overallAverage,
            userAverage: userAverage
        };
    }

    onCourseFilterChangedForSectionGraph = (courseId) => {
        this.setState({
            courseIdForSectionGraph: +courseId
        });
    }

    onCourseFilterChanged = (courseId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        const graphData = allGraphsData.get('developmentTimeSpentData').toJS();
        const sections = allGraphsData.get('developmentTimeSpentData').get('sections').toJS();
        const sectionsForCourse = sections.data.filter(section => section.courseId === +courseId);
        const firstSectionId = sectionsForCourse.length > 0 ? sectionsForCourse[0].id : -1;
        this.setState({
            courseId: +courseId,
            sectionId: 'selectedSectionId' in graphData && graphData.selectedSectionId !== -1 ? firstSectionId : -1
        });
    }

    onSectionFilterChanged = (sectionId) => {
        this.setState({
            sectionId: +sectionId
        });
    }

    getDataFormat = (unit, strings) => {
        let dataFormat;
        if (unit == 'SECONDS') {
            dataFormat = strings.get('timeInSecondsLabel');
        } else if (unit == 'MINUTES') {
            dataFormat = strings.get('timeInMinutesLabel');
        } else if (unit == 'HOURS') {
            dataFormat = strings.get('timeInHoursLabel');
        } else if (unit == 'DAYS') {
            dataFormat = strings.get('timeInDaysLabel');
        }
        return dataFormat;
    }

    renderProgressTimeSpentDevelopmentBars = () => {

        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        if (this.props.apiErrors.get('fetchDevelopmentTimeSpentDataError')) {
            return <APIErrorBoundary
                key={batchId}
                apiError={this.props.apiErrors.get('fetchDevelopmentTimeSpentDataError')}
                retryHandler={() => {
                    this.props.fetchAssessmentTimeSpentData(+batchId, +userId, +phaseId);
                }}
            />
        }
        const allGraphsData = this.props.allGraphsData.get('development');

        if (allGraphsData.get('isDevelopmentTimeSpentFetched') && this.state.courseId) {
            const { courseId, sectionId, courseIdForSectionGraph } = this.state;
            const progressData = allGraphsData.get('developmentTimeSpentData');
            const strings = progressData.get('strings');
            const isCoursesGraphAvailable = progressData.get('isCoursesGraphAvailable');
            const isSectionsGraphAvailable = progressData.get('isSectionsGraphAvailable');
            const isActivitiesGraphAvailable = progressData.get('isActivitiesGraphAvailable');
            let courses, courseDataFormat, sections, activities, sectionsForSectionGraph, sectionDataFormat, activityDataFormat

            if (isCoursesGraphAvailable) {
                courses = progressData.get('courses').toJS();
                courseDataFormat = this.getDataFormat(courses.metadata.units, strings);
            }
            if (isSectionsGraphAvailable) {
                sectionsForSectionGraph = this.getSections(courseIdForSectionGraph);
                sectionDataFormat = this.getDataFormat(sectionsForSectionGraph.metadata.units, strings);
            }

            if (isActivitiesGraphAvailable) {
                sections = this.getSections(courseId);
                activities = this.getActivities(courseId, sectionId);
                activityDataFormat = this.getDataFormat(activities.metadata.units, strings);
            }

            const progressDataGraph = progressData.toJS();

            let shouldShowAllOptionForSectionInSectionGraph = true, shouldShowAllOptionForSectionGraph = true, shouldShowAllOptionForActivityGraph = true;
            if ('selectedCourseId' in progressDataGraph) {
                if (progressDataGraph.selectedCourseId !== -1) {
                    shouldShowAllOptionForSectionInSectionGraph = false;
                }
            }
            if ('selectedSectionId' in progressDataGraph) {
                if (progressDataGraph.selectedCourseId !== -1 && progressDataGraph.selectedSectionId !== -1) {
                    shouldShowAllOptionForSectionGraph = false;
                    shouldShowAllOptionForActivityGraph = false;
                }
            }
            const graphStyleName = isCoursesGraphAvailable && isSectionsGraphAvailable ? 'two-graph-container' : 'single-graph-container';
            const devCourseBarStyling = {
                fromUser: 'rgba(254, 169, 40, 0.8)',
                toUser: 'rgba(254, 169, 40, 0.3)',
                userStroke: '#fda827',
                userRotation: 176,
                fromUserLegend: '#ffce23',
                toUserLegend: '#fea429',
                // fromGroup: 'rgba(254, 169, 40, 0.8)',
                // toGroup: 'rgba(254, 169, 40, 0.3)',
                // groupStroke: '#fda827',
                groupRotation: 180,
                // fromGroupLegend: '#ffce23',
                // toGroupLegend: '#fea429',
                gridValue: isSectionsGraphAvailable ? 4.5 : 9,
                componentName: 'dev-course-time',
                ...analyticGroupColors
            }

            const devSectionBarStyling = {
                fromUser: 'rgba(28, 62, 255, 0.8)',
                toUser: 'rgba(28, 62, 255, 0.3)',
                userStroke: '#1c3eff',
                userRotation: 176,
                fromUserLegend: '#1c3eff',
                toUserLegend: '#1c3eff',
                // fromGroup: 'rgba(254, 169, 40, 0.8)',
                // toGroup: 'rgba(254, 169, 40, 0.3)',
                // groupStroke: '#fda827',
                groupRotation: 180,
                // fromGroupLegend: '#ffce23',
                // toGroupLegend: '#fea429',
                gridValue: isCoursesGraphAvailable ? 4.5 : 9,
                componentName: 'dev-section-time',
                ...analyticGroupColors
            }

            const devActivityBarStyling = {
                fromUser: 'rgba(45, 250, 154, 0.8)',
                toUser: 'rgba(45, 250, 154, 0.3)',
                userStroke: '#2dfa9a',
                userRotation: 180,
                fromUserLegend: '#2dfa9a',
                toUserLegend: '#2dfa9a',
                // fromGroup: 'rgba(254, 169, 40, 0.8)',
                // toGroup: 'rgba(254, 169, 40, 0.3)',
                // groupStroke: '#febc25',
                groupRotation: 180,
                // fromGroupLegend: '#ffce23',
                // toGroupLegend: '#fea429',
                gridValue: 10,
                componentName: 'dev-activity-time',
                ...analyticGroupColors
            }

            const graphFormatCourses = {
                xLabel: strings.get('courseLabel'),
                yLabel: courseDataFormat
            };

            const graphFormatSections = {
                xLabel: strings.get('sectionsLabel'),
                yLabel: sectionDataFormat
            };

            const graphFormatActivities = {
                xLabel: strings.get('activitiesLabel'),
                yLabel: activityDataFormat
            };
            // console.log("courses.data", courses.data);

            return (
                [<div key="course-wise-div" styleName="dev-graph-content">
                    {isCoursesGraphAvailable && <div styleName={graphStyleName}>
                        <InfoWithoutFilterComponent
                            {...this.props}
                            title={strings.get('courseGraphTitle')}
                            subtitle={strings.get('courseGraphDesc')}
                        />
                        <AreaWithLine
                            isUserDataAvailable={progressData.get('isUserDataAvailable')}
                            skinGuide={this.props.skinGuide}
                            graphFormat={graphFormatCourses}
                            barStyling={devCourseBarStyling}
                            // data={this.props.data.timeData}
                            size={this.props.size}
                            hasCurve={false}
                            graphData={courses}
                            strings={strings.toJS()}
                        />
                    </div>}
                    {isSectionsGraphAvailable && <div styleName={graphStyleName}>
                        <div styleName="title-header">
                            <InfoWithoutFilterComponent
                                {...this.props}
                                title={strings.get('sectionGraphTitle')}
                                subtitle={strings.get('sectionGraphDesc')}
                            />
                            {isCoursesGraphAvailable &&
                                <DropDown
                                    handleOnClick={this.onCourseFilterChangedForSectionGraph}
                                    allLabel={strings.get('allLabel')}
                                    showAllOption={shouldShowAllOptionForSectionInSectionGraph}
                                    list={courses.data.filter(c => c.id !== -1)}
                                />}
                        </div>
                        <AreaWithLine
                            isUserDataAvailable={progressData.get('isUserDataAvailable')}
                            skinGuide={this.props.skinGuide}
                            graphFormat={graphFormatSections}
                            barStyling={devSectionBarStyling}
                            // data={this.props.data.timeData}
                            size={this.props.size}
                            hasCurve={false}
                            graphData={sectionsForSectionGraph}
                            strings={strings.toJS()}
                        />
                    </div>}
                </div>,
                isActivitiesGraphAvailable && <div key="activity-wise-div" styleName="dev-activity-progress-container">
                    <div styleName="title-header">
                        <InfoWithoutFilterComponent
                            {...this.props}
                            title={strings.get('activityGraphTitle')}
                            subtitle={strings.get('activityGraphDesc')}
                        />
                        {isCoursesGraphAvailable && <DropDown
                            handleOnClick={this.onCourseFilterChanged}
                            allLabel={strings.get('allLabel')}
                            showAllOption={shouldShowAllOptionForSectionGraph}
                            list={courses.data.filter(c => c.id !== -1)}
                        />}
                        {isSectionsGraphAvailable && <DropDown
                            handleOnClick={this.onSectionFilterChanged}
                            allLabel={strings.get('allLabel')}
                            showAllOption={shouldShowAllOptionForActivityGraph}
                            list={sections.data}
                        />}
                    </div>
                    <AreaWithLine
                        isUserDataAvailable={progressData.get('isUserDataAvailable')}
                        skinGuide={this.props.skinGuide}
                        graphFormat={graphFormatActivities}
                        barStyling={devActivityBarStyling}
                        // data={this.props.data.timeData}
                        size={this.props.size}
                        hasCurve={false}
                        graphData={activities}
                        strings={strings.toJS()}
                    />
                </div>]
            )
        }
        return <LoaderComponent />
    }

    render() {

        return (
            <div styleName="outer-dev-graph-container">
                <div styleName="dev-graph-container">
                    {this.renderProgressTimeSpentDevelopmentBars()}
                </div>
            </div>
        )
    }
}


export default ProgressTimeSpentDevelopment;