import React, { Component } from 'react';
import styles from './inverselyProportionalWidget.module.sass';
import CSSModules from 'react-css-modules';
import { getSkin, skinHex } from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import InverselyProportional from 'commonComponents/graphs/inverselyProportional';
import inverselyProportionalDataGen from 'scripts/data/inverselyProportional';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class InverselyProportionalWidget extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    // TODO: Passing strings to graph component and updating this widget to work without insight from api
    render() {
        const styles = getSkin(this.props.skinGuide);

        const hexval = skinHex(this.props.skinGuide);

        const widgetDataArray = this.props.widgetArray;
        
        const timeData = inverselyProportionalDataGen(16, 100);
  
        const barStyling = {
            fromUser: hexval.get('widgetPinkStart'),
            toUser: hexval.get('widgetPinkEndOpacity'),
            userStroke: hexval.get('widgetPinkStart'),
            userRotation: 171,
            fromUserLegend: hexval.get('widgetPinkEnd'),
            toUserLegend: hexval.get('widgetPinkEnd'),
            fromGroup: 'rgba(254, 169, 40, 0.8)',
            toGroup: 'rgba(254, 169, 40, 0.3)',
            groupStroke: hexval.get('widgetYellowStart'),
            groupRotation: 0,
            fromGroupLegend: hexval.get('widgetYellowStart'),
            toGroupLegend: hexval.get('widgetYellowStart'),
            fromRank: '#2de791',
            toRank: '#2de791',
            rankStroke: '#2de791',
            gridValue: 10,
            componentName: 'performance-summary',
            leftOffset: 0,
            rightOffset: 0
        };
        const graphFormat = {
            xLabel: 'Weeks',
            yLabel: 'Score',
            valueFormat: '',
            valuePrefix: '',
            valueName: 'Week',
        };

        return (
            <div className={css(styles.widgetContainerStyle)} styleName="widget-container-class">
                <div className={css(styles.widgetTitle)} styleName="widget-title">
                    Average Completion rate of Assessments
                </div>
                <div styleName="widget-content">
                    <InverselyProportional 
                        isUserDataAvailable={widgetDataArray.get('isUserDataAvailable')}
                        skinGuide={this.props.skinGuide}
                        barStyling={barStyling}
                        graphFormat = {graphFormat} 
                        // data={timeData}
                        height={200}
                        graphData={this.props.graphData}
                        legendPosition='right'
                        hasAverageLinesLegend={false}
                        showRank={false}
                    />
                </div>
                <div className={css(styles.widgetDescription)} styleName="widget-description">
                    Your average completion rate in assessments is way below your group average. 
                    You need to complete all assessments soon 
                </div>
            </div>
        );
    }
  }
  
  InverselyProportionalWidget.defaultProps = {
  };

  export default InverselyProportionalWidget;