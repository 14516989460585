import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import Next from 'images/icons/next.svg';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        title: {
            ...myTypography.h5,
            fontWeight: 600,
            color: '#282828'
        },

        instText: {
            ...myTypography.body1,
            color: "#313131",
            opacity: "0.8"
        }
    });
}

export default getSkin;