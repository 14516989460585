import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const myTypography = typography(skin);
	return StyleSheet.create({
		stepHeaderText: {
			...myTypography.body1,
			color: '#000000CC'
		},
		valuePropositionText: {
			...myTypography.body1,
			color: '#000000CC',
			fontWeight: 500
		},
		roleText: {
			...myTypography.body1,
			color: '#000000CC',
			fontWeight: 500
		},
		roleText: {
			...myTypography.button,
			color: '#000000CC',
			fontWeight: 'normal'
		},
		selectedroleText: {
			...myTypography.button,
			color: '#ffffff',
			fontWeight: 'normal'
		},
		infoText: {
			...myTypography.caption,
			fontWeight: 'normal',
			color: '#2c2c2c80'
		}
	});
}

export default getSkin;