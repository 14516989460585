import React from 'react';
import PropTypes from 'prop-types';
import { scaleOrdinal, scaleLinear } from '@vx/scale';
import { extent, max, range } from 'd3-array';
import { AreaClosed, Line, LinePath, Bar } from '@vx/shape';
import { Group } from '@vx/group';
import { AxisLeft, AxisRight, AxisBottom } from '@vx/axis';
import { LinearGradient } from '@vx/gradient';
import * as Curve from '@vx/curve';
import GradientGrid from '../gradientGrid';
import { Spring } from 'react-spring';
import { TimingAnimation, Easing } from 'react-spring/dist/addons';
import { Text } from '@vx/text';
import { GlyphDot } from '@vx/glyph';
import {textTruncate, sortObjectArrayBasedOnKey} from 'utils/utilFunctions';
import { getGradientBounds, getFlexDirectionForGraphLegend } from 'utils/analytics/utilFunctions';
import { localPoint } from "@vx/event";
import { withTooltip, Tooltip } from "@vx/tooltip";
import GraphLegendComponent from '../../analytics/graphLegendComponent';
import CSSModules from 'react-css-modules';
import styles from './areaWithLine.module.sass';
import lineChartResponsive from 'utils/analytics/responsiveness/lineChart';
import lineChartTransform from 'utils/analytics/transformation/lineChart';
import InfoComponent from 'commonComponents/analytics/infoComponent';
import { checkMetadata } from 'utils/analytics/utilFunctions';
import { checkIfPresent } from '../../../util/utilFunctions';

@CSSModules(styles, { allowMultiple: true })
class AreaWithLine extends React.Component {

    height = 'height' in this.props ? this.props.height : 500;
    margin = { top: 0, bottom: 60, left: 60, right: 0 };

    getId = d => d.id;
	getUserData = d => d.userValue;
    getGroupData = d => d.groupValue;
    getOrderId = d => d.order;
    getRenderingOrder = d => d.renderingOrder;

    constructor(props) {
        super(props);
        this.handleTooltip = this.handleTooltip.bind(this);
        this.state = {
			showLabelTooltip: false,
			labelTooltipData: null
        }
        if (this.props.legendPosition === 'right') {
			this.margin.right = 30;
		}
    }

    getData = () => {
        let data = this.props.graphData ? this.props.graphData : this.props.data;
        let metadata, strings;
        if (this.props.hasAverageLines || this.props.graphData) {
            metadata = 'metadata' in data ? data.metadata : {};
            strings = this.props.strings;
            if(data.data.length === 1) {
                data.data.push({
                    id: -1,
                    order: -1,
                    renderingOrder: -1,
                    userValue: 0,
                    groupValue: 0,
                    itemName: checkIfPresent(this.props.strings['startLabel']) ? this.props.strings['startLabel'] : 'Start'
                })
            }
            data = sortObjectArrayBasedOnKey(data.data, 'order');
            data = data.map((eachData, index) => {
                return {
                    ...eachData,
                    renderingOrder: index + 1
                }
            });
        }
        const responsivePoints = lineChartResponsive(this.getWidth()).points;
        if (data.length > responsivePoints) {
            // data = lineChartTransform(data, metadata, responsivePoints);
        }
        
        return {
            data: data,
            metadata: metadata,
            strings: strings
        };
    };

    getOffset = () => {
        const leftOffset = this.props.barStyling.leftOffset ? this.props.barStyling.leftOffset : 25;
        const rightOffset = this.props.barStyling.rightOffset ? this.props.barStyling.rightOffset : 50;
        return {
            leftOffset: leftOffset,
            rightOffset: rightOffset
        }
    }

    handleTooltip = (event, data, xScale, yScale) => {
        const { showTooltip } = this.props;
        const { x, y } = localPoint(event.target.ownerSVGElement, event);
        const x0 = Math.round(xScale.invert(x - this.margin.left)); // Subtracts left margin

        const yMaxValue = this.getYMaxValue();
        let barData;
        data.map((val, i) => { if (this.getRenderingOrder(val) == x0) barData = val; });

        const tooltipLeft = xScale(x0);
        const tooltipTop = yScale(yMaxValue);
        const tooltipData = {
            ...barData,
        };
        if(barData) {
            showTooltip({
                tooltipLeft: tooltipLeft,
                tooltipTop: tooltipTop,
                tooltipData: tooltipData
            });
        }
    }


    drawLine = (from, to, text, style, isAverageLine, areOverlapping=false) => {
		const strokeDasharray = isAverageLine ? "" : "4,2";
		const verticalAnchor = areOverlapping ? ( isAverageLine ? "start" : "end" ) : "middle";
		if (this.props.hasAverageLinesLegend) {
			return (
				<Group>
					<Line
						from={from}
						to={to}
						strokeDasharray={strokeDasharray}
						stroke={style.lineColor}
						strokeWidth={2}
					/>
				</Group>
			);
		}
		return (
			<Group>
				<Line
					from={from}
					to={to}
					strokeDasharray={strokeDasharray}
					stroke={style.lineColor}
					strokeWidth={2}
				/>
				<Text
					x={ to.x }
					y={ to.y }
					textAnchor="start"
					verticalAnchor={verticalAnchor}
					fontSize={12}
					fontFamily={style.fontFamily}
					fill={style.textColor}
				>
				{text}
				</Text>
			</Group>
		);
	}

    getWidth = () => {
        const calcWidth = (this.props.barStyling.gridValue/12) * this.props.size.width;
        let width = calcWidth < 250 ? 250: calcWidth;
        if (this.props.legendPosition == 'left' || this.props.legendPosition == 'right') {
            width = width * 0.75;
        }
        return width;
    }

    getXMax = () => {
        const { leftOffset, rightOffset } = this.getOffset();
		return (this.getWidth() - this.margin.left - this.margin.right - rightOffset - leftOffset);
	};
    getYMax = () => {
        let height = this.height;
        if (this.props.legendPosition == 'top' || this.props.legendPosition == 'bottom')
            height = this.height * 0.95;
        return height - this.margin.top - this.margin.bottom;
    };

    getYMaxValue = () => {
        const numTicks = this.getNumTicks();
        const { data, metadata } = this.getData();
        const yMaxValue = 'max' in metadata ? metadata.max : (max(data, this.getGroupData) > max(data, this.getUserData)) ? max(data, this.getGroupData) : max(data, this.getUserData);
        const nextMultiple = Math.floor(yMaxValue/numTicks) + 1;
        return yMaxValue + Math.floor(yMaxValue/numTicks);
    };

    getNumTicks = () => {
        return 5;
    }

    getXScale = () => {
        let data = this.getData().data;   
        // console.log(this.props.barStyling.componentName, extent(data, this.getOrderId));     
        return scaleLinear({
            range: [0, this.getXMax()],
            domain: extent(data, this.getRenderingOrder)
        });
    };
    getYScale = () => {
        const yMaxValue = this.getYMaxValue();
        return scaleLinear({
            rangeRound: [this.getYMax(), 0],
            domain: [0, yMaxValue], // Extra space for tooltips
        });
    }
      
    getTooltipHeader = (data) => {
        let header = "";
        if(!data.ids){
            header = data.id;
        }
        else if(data.ids && data.ids.length === 1){
            header = data.id;
        }
        else if(data.ids && data.ids.length > 1){
            const min = Math.min(...data.ids);
            const max = Math.max(...data.ids);
            header = `${min}-${max}`;
        }
        return header;
    }

    renderGraph = () => {
        const { hasCurve, hideTooltip, barStyling } = this.props;
        const data = this.getData().data;
        const height = this.height;
        
        const xMax = this.getXMax();
        const yMax = this.getYMax();
        const { leftOffset, rightOffset } = this.getOffset();

        const userId = "user-gradient" + barStyling.componentName;
        const groupId = "group-avg-gradient" + barStyling.componentName;

        const xScale = this.getXScale();
        const yScale = this.getYScale();

        return (
            <Group className="graph">
                <AreaClosed
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    x={this.getRenderingOrder}
                    y={this.getGroupData}
                    fill={`url(#${groupId})`}
                    stroke="none"
                    curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
                />
                <AreaClosed
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    x={this.getRenderingOrder}
                    y={this.getUserData}
                    fill={`url(#${userId})`}
                    stroke="none"
                    curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
                />
                <LinePath
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    x={this.getRenderingOrder}
                    y={this.getGroupData}
                    strokeWidth={2}
                    curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
                    stroke={barStyling.groupStroke}
                    strokeDasharray={[6,2]}
                    glyph={(d,i) => {
                        if(!hasCurve) {
                            return (
                                <g key={`line-point-${i}`}>
                                    <GlyphDot
                                        cx={xScale(this.getRenderingOrder(d))}
                                        cy={yScale(this.getGroupData(d))}
                                        r={2}
                                        stroke={barStyling.groupStroke}
                                        strokeWidth={4}
                                    />
                                </g>
                            );
                        }
                    }}
                />
                <LinePath
                    data={data}
                    xScale={xScale}
                    yScale={yScale}
                    x={this.getRenderingOrder}
                    y={this.getUserData}
                    strokeWidth={3}
                    curve={ !hasCurve ? Curve.curveLinear : Curve.curveMonotoneX }
                    stroke={barStyling.userStroke}
                    glyph={(d,i) => {
                        if(!hasCurve) {
                            return (
                                <g key={`line-point-${i}`}>
                                    <GlyphDot
                                        cx={xScale(this.getRenderingOrder(d))}
                                        cy={yScale(this.getUserData(d))}
                                        r={2}
                                        stroke={barStyling.userStroke}
                                        strokeWidth={4}
                                    />
                                </g>
                            );
                        }
                    }}
                />
                <Bar
                    x={-leftOffset}
                    y={0}
                    width={xMax + rightOffset + leftOffset}
                    height={yMax}
                    data={data}
                    fill="transparent"
                    onMouseMove={data => event =>
                        this.handleTooltip(
                            event,
                            data,
                            xScale,
                            yScale
                    )}
                    onMouseLeave={() => event => hideTooltip()}
                    onMouseOut={() => event => hideTooltip()}
                />
            </Group>
        )
    }

    renderTooltip = () => {
        const {
            tooltipData,
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
            barStyling,
            graphFormat
        } = this.props;

        if (!tooltipOpen)
            return;

        const { strings } = this.getData();
        const tooltipStyling = {
            fontFamily: 'Open Sans',
            fontSize: '12px',
            color: '#ffffff',
            backgroundColor: '#363e5e',
            width: '170px',
            height: '85px'
        }

        const { leftOffset, rightOffset } = this.getOffset();
        const tooltipUserLegendFillValue = `linear-gradient(${barStyling.fromUserLegend}, ${barStyling.toUserLegend})`;
        const tooltipGroupLegendFillValue = `linear-gradient(${barStyling.fromGroupLegend}, ${barStyling.toGroupLegend})`;
        const tooltipHeaderPrefix = 'valueName' in graphFormat ? `${graphFormat.valueName} ${this.getTooltipHeader(tooltipData)}` : textTruncate(tooltipData.itemName, 25);
        const userValueTooltip = 'userValueTooltip' in tooltipData ? tooltipData.userValueTooltip : tooltipData.userValue;
		const groupValueTooltip = 'groupValueTooltip' in tooltipData ? tooltipData.groupValueTooltip : tooltipData.groupValue;
        return (
            <Tooltip
                key={Math.random()}
                top={tooltipTop}
                left={tooltipLeft + leftOffset - this.margin.left}
                style={tooltipStyling}
            >
                <div styleName="tooltip-header">{tooltipHeaderPrefix}</div>
                <div styleName="tooltip-content">
                    <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipUserLegendFillValue }}></div>{strings.youTooltip.replace('PLACEHOLDER', `${userValueTooltip}`)}
                    </div>
                    <div styleName="tooltip-content-item">
                        <div styleName="tooltip-legend" style={{ background: tooltipGroupLegendFillValue }}></div>{strings.groupTooltip.replace('PLACEHOLDER', `${groupValueTooltip}`)}
                    </div>
                </div>
            </Tooltip>
        )
    }

    renderGraphComponent = () => {
        let { hasAverageLines, hasAverageLinesLegend, legendPosition } = this.props;
        const tickLabelStyling = {
            fill: "#979eb7",
            textAnchor: "end",
            fontSize: 12,
            fontFamily: 'Open Sans'
        };

        const labelStyling = {
            fontFamily: 'Open Sans',
            fontSize: 15,
            fill: '#979eb7',
            fillOpacity: 0.7,
            textAnchor: 'middle'
        };

        const legendFontSize = legendPosition == 'left' || legendPosition == 'right' ? '12px' : '16px';
		const legendLabelDirection = legendPosition == 'left' || legendPosition == 'right' ? 'column' : 'row';
		const legendItemMargin = legendPosition == 'left' || legendPosition == 'right' ? "0 0 9px 0" : "0 24px 0 0";
        const legendStyling = {
			display: 'flex',
			justifyContent: 'center',
			fontSize: legendFontSize,
			fontFamily: 'Open Sans',
			color: '#979eb7',
            direction: legendLabelDirection,
            itemDirection: "row",
            labelMargin: "0",
            shapeMargin: "0 8px 0 0",
            itemMargin: legendItemMargin,
            shapeWidth: 12,
            shapeHeight: 12
        };

        const {
            tooltipLeft,
            tooltipTop,
            tooltipOpen,
            barStyling,
            graphFormat
        } = this.props;
        const { showLabelTooltip, labelTooltipData } = this.state;

        let areOverlapping = false;
        let userAvg, groupAvg;

        if (hasAverageLines || this.props.graphData) {
            const d = this.props.graphData ? this.props.graphData : this.props.data;
            userAvg = d.userAverage;
            groupAvg = d.overallAverage;
            areOverlapping = Math.abs(userAvg - groupAvg) <= 2;
        }

        const { data, strings } = this.getData();

        const width = this.getWidth();
        const height = this.height;
        const margin = this.margin;
        
        const xMax = this.getXMax();
        const yMax = this.getYMax();
        const { leftOffset, rightOffset } = this.getOffset();

        const userId = "user-gradient" + barStyling.componentName;
        const groupId = "group-avg-gradient" + barStyling.componentName;

        const userGradientBounds = getGradientBounds(barStyling.userRotation ? barStyling.userRotation : 0);
        const groupGradientBounds = getGradientBounds(barStyling.groupRotation ? barStyling.groupRotation : 0);
        
        const xScale = this.getXScale();
        const yScale = this.getYScale();
        const numTicks = this.getNumTicks();
        let tickValues = yScale.ticks(numTicks);
		if (tickValues.length !== numTicks+2) {
			tickValues = range(0, this.getYMaxValue() + this.getYMaxValue()/numTicks, this.getYMaxValue()/numTicks);
		}

        let legendLabels = [strings.youLabel, strings.groupLabel];
		let legendShapes = [
			props => {
				const { width, height, size, fill } = props;
				return (<GlyphDot
					r={size/2}
					top={width / 2}
					left={height / 2}
					fill={fill}
				/>)
			},
			props => {
				const { width, height, size, fill } = props;
				return (<GlyphDot
					r={size/2}
					top={width / 2}
					left={height / 2}
					fill={fill}
				/>)
			}
		]

		if (hasAverageLines && hasAverageLinesLegend) {
			legendLabels.push(strings.groupAverageLabel, strings.yourAverageLabel);
			legendShapes.push(
				props => {
					const { width, height, size } = props;
					return (<Line
						from={{x: 0, y: width/2}}
						to={{x: width, y: width/2}}
						strokeDasharray={"4,2"}
						strokeWidth={2}
						stroke={barStyling.fromGroup}
					/>)
				},
				props => {
					const { width, height, size } = props;
					return (<Line
						from={{x: 0, y: width/2}}
						to={{x: width, y: width/2}}
						strokeDasharray={"4,2"}
						strokeWidth={2}
						stroke={barStyling.fromUser}
					/>)
				}
			);
		}

		const legendSize = scaleOrdinal({
			domain: legendLabels,
			range: [12]
		});
		const legendShape = scaleOrdinal({
			domain: legendLabels,
			range: legendShapes
		});

        // const fromUserTime = Array(data.length).fill(0);
        // const fromGroupAvg = Array(data.length).fill(0);
        // const toUserTime = data.map((val, i) => ( val.userValue ));
        // const toGroupAvg = data.map((val, i) => ( val.groupValue ));


        // const Graph = (props) => {
        //     let { data, hideTooltip } = props;
        //     return (
        // )};

        const ShowAverageLines = () => {
            if (hasAverageLines) {
                return (<Group>
                    {
                        this.drawLine(
                            {x: xScale(1)/2, y: yScale(groupAvg)},
                            {x: xMax - xScale(1)/2, y: yScale(groupAvg)},
                            strings.groupAverageLabel,
                            {textColor: barStyling.groupStroke, lineColor: barStyling.groupStroke, fontFamily: "Open Sans"},
                            true, areOverlapping
                        )
                    }
                    {
                        this.drawLine(
                            {x: xScale(1)/2, y: yScale(userAvg)},
                            {x: xMax - xScale(1)/2, y: yScale(userAvg)},
                            strings.yourAverageLabel,
                            {textColor: barStyling.userStroke, lineColor: barStyling.userStroke, fontFamily: "Open Sans"},
                            false, areOverlapping
                        )
                    }
                </Group>)
            }
        }
        // console.log(barStyling.componentName, data, data.length - 1 > 10 ? 10 : data.length - 1);
        const flexDirection = getFlexDirectionForGraphLegend(legendPosition);
        return(
            <div style={{ height: height, position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: flexDirection}}>
                <svg width={width} height={height}>
                    <LinearGradient
                        from={barStyling.fromUser ? barStyling.fromUser : 'green'}
                        to={barStyling.toUser}
                        {...userGradientBounds}
                        id={userId}
                    />
                    <LinearGradient
                        from={barStyling.fromGroup}
                        to={barStyling.toGroup}
                        {...groupGradientBounds}
                        id={groupId}
                    />

                    <LinearGradient
                        from={barStyling.fromUserLegend ? barStyling.fromUserLegend : 'green'}
                        to={barStyling.toUserLegend}
                        id={`legend-0-${barStyling.componentName}`}
                    />
                    <LinearGradient
                        from={barStyling.fromGroupLegend}
                        to={barStyling.toGroupLegend}
                        id={`legend-1-${barStyling.componentName}`}
                    />
                    <Group top={margin.top} left={margin.left + leftOffset}>
                        <GradientGrid scale={yScale} xMax={xMax} yMax={yMax} numTicks={5} tickValues={tickValues} offset={{left: leftOffset, right: rightOffset}} />
                        <AxisLeft
                            scale={yScale}
                            top={0}
                            left={-leftOffset}
                            label={graphFormat.yLabel}
                            stroke={'#1b1a1e'}
                            tickTextFill={'#1b1a1e'}
                            hideTicks={true}
                            hideZero={true}
                            numTicks={numTicks}
                            tickFormat={(value, index) => ( Math.round(value) )}
                            tickLabelProps={ () => (tickLabelStyling)}
                            labelProps={labelStyling}
                            tickValues={tickValues}
                            stroke={'white'}
                            strokeWidth={0.5}
                            strokeDasharray={"1,3"}
                        />
                        <AxisBottom
                            scale={xScale}
                            top={yMax}
                            label={graphFormat.xLabel}
                            stroke={'#1b1a1e'}
                            hideAxisLine={true}
                            hideTicks={true}
                            // hideZero={true}
                            // numTicks={data.length - 1 > 10 ? 10 : data.length - 1}
                            tickValues={ data.map(this.getRenderingOrder) }
                            tickFormat={(value, index) => {
                                // console.log(barStyling.componentName, value, index);
                                if ('valuePrefix' in graphFormat) {
                                    return `${graphFormat.valuePrefix}${value}`;
                                }                                
                                return data[index] ? textTruncate(data[index].itemName, 7) : '';
                            }}
                            tickLabelProps={(val, index) => {
								if ('valuePrefix' in graphFormat) {
									return {
										...tickLabelStyling
									}
								}
								return {
									...tickLabelStyling,
									onMouseOver: (event) => {
										const { x, y } = localPoint(event.target.ownerSVGElement, event);
										this.setState({
											showLabelTooltip: true,
											labelTooltipData: {
												x: x,
												y: y,
												text: data[index].itemName
											}
										})
									},
									onMouseOut: (event) => {
										this.setState({
											showLabelTooltip: false,
											labelTooltipData: null
										})
									}
								};
							}}
                            labelProps={labelStyling}
                            stroke={'white'}
                            strokeWidth={0.5}
                            strokeDasharray={"1,3"}
                        />

                        { ShowAverageLines() }
                        { this.renderGraph() }
                        {/* <g> */}
                            {/* <Spring 
                                // from={{ userTime: fromUserTime, groupAvg: fromGroupAvg }}
                                to={{userTime: toUserTime, groupAvg: toGroupAvg}}
                                impl={TimingAnimation}
                                config={{easing: Easing.linear }}
                                data={data}
                                children={Graph} />*/}
                            {/* <Graph data={data} hideTooltip={hideTooltip} />
                        </g> */}

                        {tooltipOpen && (
                            <Line
                                from={{x: tooltipLeft, y: yMax}}
                                to={{x: tooltipLeft, y: (tooltipTop + 39)}}
                                stroke={'white'}
                                strokeWidth={1}
                            />
                        )}
                        
                    </Group>
                </svg>
                <GraphLegendComponent
                    style={legendStyling}
                    shape={legendShape}
                    size={legendSize}
                    componentName={barStyling.componentName}
                />
                {this.renderTooltip()}
                {showLabelTooltip && (
					<Tooltip
						key={Math.random()}
						top={yMax + labelTooltipData.y}
						left={labelTooltipData.x + leftOffset}
						style={{
							color: '#31384B',
							background: '#979eb7',
						}}
					>
						{labelTooltipData.text}
					</Tooltip>
				)}
            </div>
        );
    }

    validateMetadata = () => {
        const { metadata } = this.getData();

        if (!checkMetadata(metadata)) {
            return false;
        }
        return true;
    }

    render() {
        const data = this.getData().data;
        if (data.length < 1 || !this.validateMetadata() || !this.props.isUserDataAvailable) {
            return <InfoComponent 
                        {...this.props} 
                        infoMessage="This graph does not have data" />
        }

        return this.renderGraphComponent();
    }
}

AreaWithLine.propTypes = {
    size: PropTypes.object,
    graphData: PropTypes.object.isRequired,
    hasCurve: PropTypes.bool,
    hasAverageLines: PropTypes.bool,
    barStyling: PropTypes.object,
    graphFormat: PropTypes.object,
    legendPosition: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
    hasAverageLinesLegend: PropTypes.bool
};

AreaWithLine.defaultProps = {
    size: {
        width: 700,
        height: 500
    },
    hasCurve: true,
    hasAverageLines: false,
    barStyling: {
        leftOffset: 25,
        rightOffset: 50,
    },
    legendPosition: 'bottom',
	hasAverageLinesLegend: false
};

export default withTooltip(AreaWithLine);