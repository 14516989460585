import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import defaults from 'constants/defaults';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		loadingText: {
			...myTypography.overline,
			fontWeight: 600,
			color: hexToRgbA(skin.get('black'), 0.7)
		},
		loadingAuthor: {
			...myTypography.overline,
			fontSize: '14px',
			fontStyle: 'italic',
			color: hexToRgbA(skin.get('black'), 0.7)
		}
	});
}

export default getSkin;
