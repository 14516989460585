import React, { Component } from 'react'
import styles from './section.module.sass';
import { css } from 'aphrodite/no-important';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class Section extends Component {
	render() {
		const { skinGuide } = this.props;
		const myStyles = getSkin(skinGuide);
		return <div styleName='section-header' className={css(myStyles.sectionHeader)}> 
			<div className={css(myStyles.sectionText)} key={this.props.sectionId}>
				{this.props.sectionName}
			</div>
		</div>;
	}
}

export default Section