/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './JourneyExpLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';
import AnalyticsImage from 'images/loaders/analytics-graph-bar.png';
import getSkin from './skin';
import { css } from 'aphrodite';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyExpLoader extends React.Component {

	renderFactImageLoader = (myStyles) => {
		return (
			<div styleName="image-container" className={css(myStyles.imageBorder)}>
				<img src={AnalyticsImage} styleName="image" />
			</div>
		);
	}

	renderContentLoader = (loadersDefaultconfig) => {
		return (
			<div styleName="content-loader-container">
				<ContentLoader
					height={210}
					width="100%"
					speed={loadersDefaultconfig.get('speed')}
					primaryColor={loadersDefaultconfig.get('primaryColor')}
					secondaryColor={loadersDefaultconfig.get('secondaryColor')}
					style={{ height: '100%', width: '100%' }}
				>
					<rect x="0" y="30" rx="0" ry="0" width="80%" height="24" />
					<rect x="0" y="109" rx="0" ry="0" width="100%" height="16" />
					<rect x="0" y="137" rx="0" ry="0" width="100%" height="16" />
					<rect x="0" y="165" rx="0" ry="0" width="40%" height="16" />
				</ContentLoader>
			</div>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<div styleName="container">
				{this.renderContentLoader(loadersDefaultconfig)}
				{this.renderFactImageLoader(myStyles)}
			</div>
		);
	}
}

export default JourneyExpLoader;
