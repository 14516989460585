/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: user.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * theme reducer is responsible for platform theme related actions
 *
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';

const screenWidth = typeof window === 'object' ? window.innerWidth : null;

const initialState = Immutable.fromJS({
	isSideBarOpen: (screenWidth > 1280) ? true : false,
	isFullScreen: false,
	isExitPopupOpen: false,
	isAssessmentShowDetailsClicked: false
});

const ui = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.get('SET_SIDEBAR'):
			return state.set('isSideBarOpen', action.flag);
		case actionConsts.get('SET_FULLSCREEN'):
			return state.set('isFullScreen', action.flag);
		case actionConsts.get('SET_EXIT_POPUP'):
			return state.set('isExitPopupOpen', action.flag)
		case actionConsts.get('SET_ASSESSMENT_SHOW_DETAILS'):
			return state.set('isAssessmentShowDetailsClicked', action.flag)
		default:
			return state;
	}
};

export default ui;
