import React, { Component } from 'react';
import styles from './userDot.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css, StyleSheet } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class UserDot extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showTooltip: false
		}
	}

	openTooltip = () => {
		this.setState({
			showTooltip: true
		});
	}

	closeTooltip = () => {
		this.setState({
			showTooltip: false
		});
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="info-container">
				<div
					className={css(myStyles.userDot)}
					styleName="info-icon"
					style={{
						backgroundColor: this.props.dotBackground
					}}
					onMouseEnter={() => {this.openTooltip()}}
					onMouseLeave={() => {this.closeTooltip()}}
				>
				</div>
				{
					(this.state.showTooltip)
					?	<div styleName="tooltip-container">
							<div className={css(myStyles.tooltipContent)} styleName="tooltip-content">{this.props.tooltipText}</div>
							<div className={css(myStyles.tooltipArrow)} styleName="tooltip-arrow"></div>
						</div>
					:	null
				}
			</div>
		);
	}
}

export default UserDot;
