import Immutable from 'immutable';
import platformUrls from './platformUrls';
import developmentPhaseUrls from './developmentPhaseUrls';
import analyticsUrls from './analyticsUrls';
import getReportUrl from './reportUrls';
import notificationUrls from './notificationUrls';

const urls =  Immutable.Map({
    PLATFORM: platformUrls(),
    DEVELOPMENT_PHASE: developmentPhaseUrls(),
    ANALYTICS: analyticsUrls(),
    REPORT: getReportUrl(),
    NOTIFICATION: notificationUrls()
});

export default urls;