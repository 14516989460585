import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './feedbackPopup.module.sass';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import enumConstants from 'constants/enums';
import ThumbsUpDown from 'commonComponents/feedbackQuestionTemplates/thumbsUpDown';
import StarRating from 'commonComponents/feedbackQuestionTemplates/starRating';
import ScaleRating from 'commonComponents/feedbackQuestionTemplates/scaleRating';
import TextBased from 'commonComponents/feedbackQuestionTemplates/textBased';
import ModuleItemLoader from 'components/moduleItems/moduleItem/ModuleItemLoader.jsx';
import CloseCircle from 'svgComponents/closeCircle';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class FeedbackPopup extends Component {

	constructor(props) {
		super(props);
		this.escFunction = this.escFunction.bind(this);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.props.closeFeedbackPopup()
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
	}

	getQuestionTemplate = (question) => {
		const { submitAnswer } = this.props;
		switch (question.get('type')) {
			case enumConstants.get('FEEDBACK_THUMBS_UP_DOWN'):
				return <ThumbsUpDown
					key={`itemsFeedbackTUP_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswer}
					feedbackItemId={this.props.match.params.itemId}
				/>
			case enumConstants.get('FEEDBACK_RATING'):
				return <StarRating
					key={`itemsFeedbackRating_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswer}
					feedbackItemId={this.props.match.params.itemId}
				/>
			case enumConstants.get('FEEDBACK_RATING_SCALE'):
				return <ScaleRating
					key={`itemsFeedbackRating_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswer}
					feedbackItemId={this.props.match.params.itemId}
				/>
			case enumConstants.get('FEEDBACK_INPUT_MEDIUM_TEXT'):
				return <TextBased
					key={`itemsFeedbackText_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswer}
					feedbackItemId={this.props.match.params.itemId}
				/>
			default:
				return <div></div>;
		}
	}

	getFeedbackQuestions = (myStyles) => {
		const {
			itemFeedback,
			loaders,
			getLabelWithDefaults
		} = this.props;

		if (loaders.get('isFetchingFeedback')) {
			return (
				<div styleName="loader-cnt">
					<ModuleItemLoader />
				</div>
			)
		}

		let feedbackComponent = [];
		if (itemFeedback) {
			feedbackComponent = itemFeedback.get('questionsList').map((question, index) => {
				return (
					<div key={`feedback-question-${question.get('id')}`} styleName="individual-question-cnt">
						<div className={css(myStyles.questionNumber)} style={{ display: 'flex' }}>
							{getLabelWithDefaults('question_number_label', `Question ${index + 1}`, { number: index + 1 })}
							{
								question.get('mandatory') &&
								<div style={{ color: 'red' }}>&nbsp;*</div>
							}
						</div>
						{this.getQuestionTemplate(question)}
					</div>
				);

			})
		}
		return feedbackComponent;
	}

	onSubmitFeedback = () => {
		this.props.submitFeedback({ isSubmitted: true });
		this.props.closeFeedbackPopup();
	}

	areAllMandatoryQuestionsAnswered = () => {
		let manadatoryQuestionsAnswered = true;
		const { itemFeedback } = this.props;
		if (itemFeedback) {
			let numberOfQuestions = itemFeedback.get('questionsList').size;
			for (let i = 0; i < numberOfQuestions; i++) {
				if (
					itemFeedback.getIn(['questionsList', i, 'mandatory'])
					&& !itemFeedback.getIn(['questionsList', i, 'completed'])
				) {
					manadatoryQuestionsAnswered = false;
					break;
				}
			}
		}
		return manadatoryQuestionsAnswered
	}

	getFeedbackActions = (myStyles) => {
		const {
			itemFeedback,
			loaders,
			getLabelWithDefaults
		} = this.props;
		if (loaders.get('isFetchingFeedback')) return null;
		let submitButtonLabel = getLabelWithDefaults('feedback_submit_label', 'Submit');
		if (itemFeedback.get('isFeedbackCompleted')) {
			submitButtonLabel = getLabelWithDefaults('save_feedback_responses')
		}
		return (
			<div styleName="feedback-action-cnt">
				<div styleName="submit-feedback-cnt">
					<PrimaryButton
						text={submitButtonLabel}
						clickFunction={this.onSubmitFeedback}
						disabled={!this.areAllMandatoryQuestionsAnswered()}
					/>
				</div>
			</div>
		)
	}

	isFeedbackMandatory = (activeBatchDetails) => {
		return activeBatchDetails.getIn([
			'journey',
			'settings',
			'isFeedbackMandatory']) === 'true'
	}

	onClosePopup = () => {
		const {
			activeBatchDetails,
			itemFeedback,
			closeFeedbackPopup
		} = this.props;

		let shouldClosePopup = true;
		
		if(this.isFeedbackMandatory(activeBatchDetails)){
			shouldClosePopup = itemFeedback.get('isFeedbackCompleted');
		}

		if(shouldClosePopup){
			closeFeedbackPopup();
		}
	}

	render() {
		const {
			skinGuide,
			activeBatchDetails,
			selectedItem,
			getLabelWithDefaults,
			itemFeedback
		} = this.props;
		const myStyles = getSkin(skinGuide);

		return (
			<div
				styleName="feedback-popup-cnt"
				className={css(myStyles.popupBackground)}
				onClick={this.onClosePopup}
			>
				<div styleName="feedback-content-cnt" onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
					<div className={css(myStyles.feedbackHeader)} styleName="feedback-header">
						{
							activeBatchDetails.getIn([
								'journey',
								'settings',
								'isSimulationJourney']) === 'true' ?

								getLabelWithDefaults(
									'feedback_title',
									`Feedback for ${activeBatchDetails.getIn(['journey', 'name'])}`,
									{ entityName: activeBatchDetails.getIn(['journey', 'name']) }
								)
								:
								getLabelWithDefaults(
									'feedback_title',
									`Feedback for ${selectedItem.get('name')}`,
									{ entityName: selectedItem.get('name') }
								)

						}
					</div>
					<div styleName="scrollable-cnt">
						<div styleName="feedback-subheaders">
							<div className={css(myStyles.feedbackSubHeader)}>
								{getLabelWithDefaults('phase_feedback_sub_header')}
							</div>
							<div className={css(myStyles.feedbackNote)}>
								{getLabelWithDefaults('phase_feedback_note')}
							</div>
						</div>
						{/* <div styleName="feedback-questions-cnt"> */}
						<div styleName="feedback-questions" style={{ marginLeft: '4%' }}>
							{this.getFeedbackQuestions(myStyles)}
						</div>
						{/* </div> */}
						
						{this.getFeedbackActions(myStyles)}
						
						{ (!this.isFeedbackMandatory(activeBatchDetails) || (this.isFeedbackMandatory(activeBatchDetails) && itemFeedback.get('isFeedbackCompleted'))) && <div styleName="close-popup-btn" onClick={this.onClosePopup}>
								<CloseCircle />
							</div> 
						}
					</div>
				</div>
			</div>
		);
	}
}

export default FeedbackPopup;
