import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import loaderStyles from './loader.module.sass';

@CSSModules(loaderStyles, { allowMultiple: true })
class Loader extends Component {
    render() {
        const quote = {
			text: "Go forth on your path, as it exists only through your walking.",
			author: "Saint Augustine"
        };
        
        return (
            <div styleName="loader-container">
				<div styleName="loader-content">
					<div styleName="loading-circle">
						<svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" stroke="#d44980">
							<g fill="none" fillRule="evenodd">
								<g transform="translate(1 1)" strokeWidth="3">
									<circle stroke="e8eaf2" strokeOpacity=".1" cx="18" cy="18" r="18" />
									<path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(313.068 18 18)">
										<animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="1s" repeatCount="indefinite" />
									</path>
								</g>
							</g>
						</svg>
					</div>
					<div styleName="loading-text">
						{quote.text}
					</div>
					<div styleName="loading-author">
                        -{quote.author}
                    </div>
				</div>
			</div>
        )
    }
}

export default Loader;