import React, { Component } from 'react';
import styles from './pdfsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PdfsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 14 14">
					<g fill="none" fillRule="evenodd">
						<path fill="#828593" fillRule="nonzero" d="M1.458 0C.653 0 0 .653 0 1.458v11.084A1.458 1.458 0 0 0 1.458 14h11.084A1.458 1.458 0 0 0 14 12.542V1.458A1.458 1.458 0 0 0 12.542 0H1.458z"/>
						<path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".7" d="M4.68 4.887c-.425-.66.593-1.268.859-.59.455 1.163 1.188 4.31-.465 6.45-.46.596-1.455.032-.92-.59 1.55-1.725 3.833-2.712 6.231-2.694.834.03.791 1.147.045 1.06-2.414-.297-4.541-1.643-5.75-3.636z"/>
					</g>
				</svg>
			</div>
		);
	}
}

export default PdfsIcon;
