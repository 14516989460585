/**
 * @author: ujjwal
 * @since: Thu Sep 27 2018 09:20:31 GMT+0530 (IST)
 * @file: activity.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import {
    getFormattedPayloadDataOneLevel,
    getFormattedPayloadDataMultipleLevel,
    getFormattedPeriodicDataMultiLevelData,
    getFormattedWidgetData
} from 'utils/analytics/transformationFunctions';



const initialState = Immutable.fromJS({
    overallProgressGraphData: [],
    isOverallProgressDataFetched: false,

    dayWiseTimeSpentData: [],
    isDayWiseTimeSpentDataFetched: false,

    platformWiseTimeSpentData: [],
    isPlatformWiseTimeSpentDataFetched: false,

    statsData: [],
    isStatsDataFetched: false,

    timeSpentPeriodicallyData: [],
    isTimeSpentPeriodicallyDataFetched: false,

    progressPeriodicallyData: [],
    isProgressPeriodicallyDataFetched: false,

    widgetData: [],
    isWidgetDataFetched: false


});

const activity = (state = initialState, action = {}) => {
    switch (action.type) {

        /* Am I progressing faster than my peers? */
        case actionConsts.get('OVERALL_PROGRESS_DATA'):
            return state.set(
                'overallProgressGraphData',
                Immutable.fromJS(action.payload)
            ).set(
                'isOverallProgressDataFetched',
                true
            );

        /* When am I most active?*/
        case actionConsts.get('DAY_WISE_TIME_SPENT_DATA'):
            return state.set(
                'dayWiseTimeSpentData',
                Immutable.fromJS(action.payload)
            ).set(
                'isDayWiseTimeSpentDataFetched',
                true
            );

        /*Where did I access the most? */
        case actionConsts.get('PLATFORM_WISE_TIME_SPENT_DATA'):
            return state.set(
                'platformWiseTimeSpentData',
                Immutable.fromJS(action.payload)
            ).set(
                'isPlatformWiseTimeSpentDataFetched',
                true
            );

        /*STATS section data */

        case actionConsts.get('STATS_DATA'):
            return state.set(
                'statsData',
                Immutable.fromJS(action.payload)
            ).set(
                'isStatsDataFetched',
                true
            );

        /*How active am I on the platform? section data */

        case actionConsts.get('TIME_SPENT_PERIODICALLY_DATA'):

            const timeSpentPeriodically = getFormattedPeriodicDataMultiLevelData(action.payload);

            return state.set(
                'timeSpentPeriodicallyData',
                Immutable.fromJS(timeSpentPeriodically)
            ).set(
                'isTimeSpentPeriodicallyDataFetched',
                true
            );

        /*Progress periodically data */
        case actionConsts.get('PROGRESS_PERIODICALLY_DATA'):

            const progressPeriodically = getFormattedPeriodicDataMultiLevelData(action.payload);

            return state.set(
                'progressPeriodicallyData',
                Immutable.fromJS(progressPeriodically)
            ).set(
                'isProgressPeriodicallyDataFetched',
                true
            );

        case actionConsts.get('SET_WIDGET_ANALYTICS_DATA'):
            const formattedData = getFormattedWidgetData(action.payload);
            return state.set('widgetData', Immutable.fromJS(formattedData)).set('isWidgetDataFetched', true)

        default:
            return state;
    }
};

export default activity;