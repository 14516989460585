import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import QUIZ_SCORE_BG from 'images/development/quiz_score_bg.png';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);

	return StyleSheet.create({
		sidebarContainer: {
			borderRight: `solid 1px ${skinHex.get('greyColor5')}`
		},
		feedbackHeader: {
			color: skinHex.get('greyColor1'),
			fontSize: '40px',
			fontFamily: skin.get('fontFamily').get('bodyFamily2'),
			lineHeight: 1.87,
			fontWeight: 300,
			borderBottom: `1px solid #D5D8E2`
		},
		feedbackSubHeader: {
			color: skinHex.get('greyColor3'),
			fontSize: '22px',
			fontFamily: skin.get('fontFamily').get('bodyFamily'),
			lineHeight: 1.27,
			fontWeight: 300
		},
		questionNumber: {
			...myTypography.button,
			color: `rgba(9, 16, 39, ${0.85 * 0.4})`,
			textTransform: 'uppercase',
			textAlign: 'left'
		},
		skipButton: {
			...myTypography.button,
			color: skinHex.get('secondaryColor'),
			textTransform: 'uppercase'
		},
		completionBackground: {
			backgroundImage: `url(${QUIZ_SCORE_BG})`,
			backgroundSize: "cover",
			backgroundRepeat: 'no-repeat',
			backgroundPosition: "top"
		},
		thankYouNote: {
			...myTypography.h6,
			lineHeight: 2,
			color: skinHex.get('white')
		},
		viewResponsesLabel: {
			...myTypography.button,
			color: skinHex.get('white'),
			lineHeight: 'normal',
			textDecoration: 'underline',
			cursor: 'pointer'
		},
		itemCompletionNote: {
			...myTypography.h6,
			lineHeight: 1.4,
			color: 'rgba(9, 16, 39, 0.85)'
		},
		courseCompletionNote: {
			...myTypography.h6,
			lineHeight: 1.4,
			color: 'rgba(9, 16, 39, 0.85)'
		},
		backButtonText: {
			...myTypography.button,
			color: skinHex.get('secondaryColor'),
			textTransform: 'uppercase'
		},
		feedbackMetadata: {
			...myTypography.button,
			color: hexToRgbA(skinHex.get('greyColor2'), 0.48)
		},
		feedbackNote: {
			...myTypography.caption,
			color: 'red',
			fontStyle: 'italic',
			fontWeight: 'normal',
			marginTop: '5px'
		},
		popupBackground: {
			backgroundColor: hexToRgbA(skinHex.get('greyColor2'), 0.48)
		}
	});
}

export default getSkin;