import React, { Component } from 'react';
import styles from './addIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class AddIcon extends Component {

	render() {
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 16 16">
					<path fill={this.props.fillColor || "#4A54AECC"} fillRule="nonzero" d="M.495 7.498A.498.498 0 0 1 0 6.996c0-.275.22-.494.495-.494h6.003v-6a.498.498 0 1 1 .997 0v6h6.003a.498.498 0 1 1 0 .996H7.495v6a.498.498 0 1 1-.997 0v-6H.495z" />
				</svg>
			</div>
		);
	}
}



export default AddIcon;
