import React, { Component } from 'react';
import styles from './userOrganizationsList.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './userOrganizationsSkin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import FetchUserOrganizationsList from 'actions/serverCalls/userProfile/FetchUserOrganizationsList';
import SetActiveOrganization from 'actions/serverCalls/userProfile/SetActiveOrganization';
import CircularLoader from 'svgComponents/circularLoader';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class UserOrganizationsList extends Component {    

    constructor(props){
        super(props);
        this.state = {
            orgsList: [],
            error: null,
            loading: true
        }
    }
    
    fetchOrgsList = async () => {
        //Skipping storing this data in redux store as it's not needed anywhere else
        try{
            const response = await FetchUserOrganizationsList.call();
            this.setState({orgsList: response.organizations, loading: false});
        } catch(error){
            this.setState({error: 'Error displaying organizations list', loading: false});
        }
    }

    setOrganizationActive = (orgId) => {
        SetActiveOrganization.call({orgId});
    }
    
    componentDidMount(){
        this.fetchOrgsList();
    }

    getCurrentOrgId = () => {
        const userOrganization = this.props.userOrganization.get('userOrganization');
        if(userOrganization.size > 0){
            return userOrganization.get('id');
        }
        return null;
    }

    renderLabelOrAction = (organization) => {

        const myStyles = getSkin(this.props.skinGuide);       

        const currentOrgId = this.getCurrentOrgId();

        if(organization.isDealUpcoming){
            return <div styleName="org-status-action" className={css(myStyles.lockedOrg)}>(
                {this.props.getLabel('locked_label')}
            )</div>;
        }
        
        
        if(organization.isDealExpired){
            return <div styleName="org-status-action" className={css(myStyles.expiredOrg)}>(
                {this.props.getLabel('expired_label')}
            )</div>;
        }        

        if(organization.id == currentOrgId){
            return <div styleName="org-status-action">({this.props.getLabel('active_organization_label')})</div>;
        }        

        return  <div className={css(myStyles.setActiveButton)} styleName="org-status-action" onClick={() => this.setOrganizationActive(organization.id)}>{this.props.getLabel('set_active_organization_label')}</div>;
    }

    renderList = () => {
        const {orgsList, error} = this.state;

        if(error) return error;

        const myStyles = getSkin(this.props.skinGuide);       

        return <ul>
            { orgsList.map((organization) => {
                return <li>
                    <div styleName="org-list-item" className={css(myStyles.orgListItem)}>
                        <div styleName="org-name">{organization.name}</div> 
                        {this.renderLabelOrAction(organization)}
                    </div>
                </li>;
            })}
        </ul>;
    }

    render() {

        const myStyles = getSkin(this.props.skinGuide);

        if(this.state.loading) return <div styleName="loader-cnt"><CircularLoader/></div>

        return <div styleName="details-box">
            <div styleName="details-header">
                <div className={css(myStyles.headerText)}>
                    {this.props.getLabel('organizations_list_label')}
                </div>
            </div>
            <div styleName={this.props.isChildOfUserError ? 'details-content organizations-list-cnt-overflow' : 'details-content'}>
                {this.renderList()}
            </div>
        </div>;  
    }
}

export default UserOrganizationsList;