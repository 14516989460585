import React, { Component } from 'react';
import styles from './userDownloads.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import DefaultLoader from 'commonComponents/loaders/defaultLoader';
import NoJourneyErrorComponent from 'commonComponents/noJourneyErrorComponent';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import JourneysLoader from 'commonComponents/loaders/journeysLoader';
import { checkIfPresent } from 'utils/utilFunctions';
import ReportDownloadContainer from 'containers/ReportDownloadContainer';
import reports from 'constants/reports';
import LinkedInShareCertificateContainer from 'containers/LinkedInShareCertificateContainer';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import SimpleDialog from 'commonComponents/simpleDialog';
import BatchFeedbackStatusListContainer from 'containers/BatchFeedbackStatusListContainer';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class UserDownloads extends Component {

	constructor(props){
		super(props);
		this.state = {
			feedbackStatusDialogOpenForBatchId: null
		}
	}

	goToHome = () => {
		const route = routes.get('USER_HOME');;
		updateRoute({ route });
	}

	renderDownloadButtons = (batchDetails) => {
		const batchId = +batchDetails.get('id');
		const showLinkedinShareButton = batchDetails.getIn(['journey', 'settings', 'showLinkedInShare']) === 'true';
		return <React.Fragment>
			<ReportDownloadContainer
				key={`certificate_batch_${batchId}`}
				reportType={reports.get('certificate')}
				reportOutputType="pdf"
				metadata={{ batchId }}
				downloadButtonText={this.props.getLabelWithDefaults('download_certificate_label', 'Download Certificate')}
			/>
			{showLinkedinShareButton && <LinkedInShareCertificateContainer
				key={`linkedin_batch_${batchId}`}
				reportType={reports.get('certificate')}
				reportOutputType="pdf"
				metadata={{ batchId: batchId }}
			/>}
		</React.Fragment>;
	}

	shouldRenderCertificateButtons = (batchDetails, batchesFeedbackStatusFlags) => {
		if(batchDetails.getIn(['journey', 'settings', 'isFeedbackMandatory']) != 'true'){
			return true;
		}	
		return batchesFeedbackStatusFlags.getIn(['statusFlags','completedBatchIds']).includes(+batchDetails.get('id'));
	}

	getCompletedJourneysCards = (myStyles) => {
		const {
			completedUserBatches,
			batches,
			isCompletedBatchesFetching,
			batchesFeedbackStatusFlags,
			getLabel
		} = this.props;

		if (!isCompletedBatchesFetching && completedUserBatches.isEmpty()) {
			return <NoJourneyErrorComponent msgLabelKey={'no_completed_journeys'} />
		}

		if (isCompletedBatchesFetching || batches.size === 0 || batchesFeedbackStatusFlags.get('loading')) {
			return <DefaultLoader />
		}


		let journeyCards = [];
		completedUserBatches.forEach(batch => {
			let batchDetails = batches.find(b => b.get('id') === batch.get('batchId'));

			if (batchDetails) {

				let image_url = batchDetails.getIn(['journey', 'catalogImageUrl']);
				if (!checkIfPresent(image_url))
					image_url = "https://webassets.knolskape.com/misc/ajinkya_wankhade/2019/11/25/52/videoimage.png";
				if (batchDetails.getIn(['journey', 'settings', 'isCertificateAvailable']) === 'true') {
					journeyCards.push(
						<div key={`journey_${batch.get('batchId')}`} styleName="journey-card">
							<div styleName="journey-info-cnt">
								<div styleName="journey-icon">
									<img src={image_url} alt="Journey Thumnail"/>
								</div>
								<div className={css(myStyles.journeyName)} styleName="journey-name">
									{batchDetails.getIn(['journey', 'name'])}
								</div>
							</div>
							<div styleName="download-btn-cnt">
								{
									this.shouldRenderCertificateButtons(batchDetails, batchesFeedbackStatusFlags) ? 
									this.renderDownloadButtons(batchDetails)
									: <PrimaryButton
										text={getLabel('mandatory_give_feedback_button_label')}
										clickFunction={() => this.onGiveFeedbackButtonClick(+batch.get('batchId'))}
									/> 
								}
								
							</div>
						</div>
					)
				}
			}
		})
		return (
			<div styleName="content">
				<div styleName="journey-cards-cnt">
					{journeyCards}
				</div>
			</div>
		)
	}

	getSectionWiseData = (myStyles) => {
		const { getLabel } = this.props;
		let sectionWiseComponents = [];

		sectionWiseComponents.push(
			<div styleName="section-cnt">
				<div styleName="section-header" className={css(myStyles.contentText)}>
					{getLabel('certificates_label')}
				</div>
				<div styleName="section-content">
					{this.getCompletedJourneysCards(myStyles)}
				</div>
			</div>
		)

		return sectionWiseComponents;
	}

	getSelectedBatchDetails = (batchId) => {
		const { batches } = this.props;
		return batches.find(b => b.get('id') === batchId);
	}

	onGiveFeedbackButtonClick = (batchId) => {
        this.setState({ feedbackStatusDialogOpenForBatchId : batchId });
    }

    onCloseStatusDialog = () => {
		this.props.updatePage();
        this.setState({ feedbackStatusDialogOpenForBatchId: null });
    }

	render() {
		const {
			getLabel,
			skinGuide,
			userOrganization,
			match
		} = this.props;
		const myStyles = getSkin(skinGuide);
		const { feedbackStatusDialogOpenForBatchId } = this.state;

		if (userOrganization.get('userOrganizationDataLoading')) {
			return (
				<div styleName="content-cnt" className>
					<JourneysLoader skinGuide={skinGuide} />
				</div>
			)
		}

		if (userOrganization.get('userOrganization').size === 0) {
			return null
		}

		return (
			<div className={css(myStyles.userProfileContainer)} styleName="content-cnt">
				<div className={css(myStyles.headerBg)} styleName="content-header">
					<div className={css(myStyles.headerBgImg)}></div>
					<div styleName="breacrumbs-cnt" style={{ padding: '11px' }}>
						<span
							className={css(myStyles.breadcrumbsText)}
							onClick={this.goToHome}
						>
							{getLabel('home_label')}
						</span>
					</div>
					<div
						className={css(myStyles.contentText)}
						styleName="content"
						style={{ padding: '11px' }}
					>
						{getLabel('downloads_label')}
					</div>
				</div>
				{
					this.getSectionWiseData(myStyles)
				}
				{ feedbackStatusDialogOpenForBatchId &&  <SimpleDialog onCloseDialog={this.onCloseStatusDialog}
                    contentContainerStyle={{
                        width: '1049px',
                        minWidth: '1049px',
                        padding: '20px',
                        height: '600px',
                        maxHeight: '600px',
                        background: skinGuide.get('skin').get('greyColor6')
                    }}                
                >
                    <BatchFeedbackStatusListContainer batchDetails={this.getSelectedBatchDetails(feedbackStatusDialogOpenForBatchId)} match={match}/>
                </SimpleDialog> }
			</div>
		);
	}
}

export default UserDownloads;