import React from 'react';
import ReactDOM from 'react-dom';

import styles from './journeyListItem.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';

import JourneyItemIntroduction from './journeyItemIntroduction';
import AuthorIntro from './authorIntro';
import PreRequisite from './preRequisites';
import SkillsCovered from './skillsCovered';
import JourneyExp from './journeyExp';
import JourneyDetails from './journeyDetails';
import JourneyFooter from './journeyFooter';
import StickyHeader from './stickyHeader';
import TabsBar from './tabsBar';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { TweenMax } from 'gsap';
import Footer from 'commonComponents/footer';
import { getActiveBatchDetails, getUserActiveBatchDetails } from 'utils/batchUtils';
import { checkIfPresent } from 'utils/utilFunctions';
import { isIPad13, isMobile, isTablet } from 'react-device-detect';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneysListItem extends React.Component {

	constructor(props) {
		super(props);
		this.completeContainer = React.createRef();
		this.divRefs = props.userState.get('invitationDivRefs');
		this.state = {
			showStickyHeader: false,
			divName: '',
			showBenefitsAndInsights: false,
			showMessageFromCoo: false,
			showPrerequisites: false,
			showSkills: false,
			tabsConfig: [],
			divNameSet: false
		}
	}

	componentWillUnmount = () => {
		this.props.setUserState({
			invitationDivName: undefined,
			invitationDivNameSet: false
		});
	}

	componentWillReceiveProps() {
		this.divRefs = this.props.userState.get('invitationDivRefs');
		const batchDetails = getActiveBatchDetails(this.props.batches);
		const journeyDetails = batchDetails.get('journey');

		const tabsConfig = [];
		if (checkIfPresent(journeyDetails.get('aimOfJourney'))) {
			this.setState({
				showBenefitsAndInsights: true
			});
			tabsConfig.push({
				name: this.props.getLabel('journey_benifits_and_insights_label'),
				id: "benefitsAndInsights"
			});
		}
		if (checkIfPresent(journeyDetails.get('assignedByInstruction')) && checkIfPresent(journeyDetails.get('assignedBy'))) {
			this.setState({
				showMessageFromCoo: true
			});
			tabsConfig.push({
				name: this.props.getLabel('journey_message_from_designation_label').replace(
					'DESIGNATION_REPLACEMENT',
					journeyDetails.get('assignedByRoleShort')
				),
				id: "messageFromCoo"
			});
		}
		if (checkIfPresent(journeyDetails.get('prerequisitesInfo')) && journeyDetails.get('prerequisitesInfo').size !== 0) {
			this.setState({
				showPrerequisites: true
			});
			if (checkIfPresent(journeyDetails.get('competencies')) && journeyDetails.get('competencies').size !== 0) {
				tabsConfig.push({
					name: this.props.getLabel('journey_prereq_skill_label'),
					id: "prerequisitesAndSkills"
				});
			} else {
				tabsConfig.push({
					name: this.props.getLabel('journey_prereq_label'),
					id: "prerequisitesAndSkills"
				});
			}
		}
		if (checkIfPresent(journeyDetails.get('competencies')) && journeyDetails.get('competencies').size !== 0) {
			this.setState({
				showSkills: true
			});
			if (checkIfPresent(journeyDetails.get('prerequisitesInfo')) && journeyDetails.get('prerequisitesInfo').size !== 0) {
				//do nothing
			} else {
				tabsConfig.push({
					name: this.props.getLabel('journey_skill_covered_label'),
					id: "prerequisitesAndSkills"
				});
			}
		}
		tabsConfig.push({
			name: this.props.getLabel('journey_index_label'),
			id: "aboutIndex"
		});
		this.setState({
			tabsConfig: tabsConfig
		});
		if (tabsConfig.length !== 0 && !this.props.userState.get('invitationDivNameSet')) {
			this.props.setUserState({
				invitationDivName: tabsConfig[0].id,
				invitationDivNameSet: true
			});
		}
	}

	scrollContainer = (id) => {
		// this.divRefs[id].current.scrollIntoView({block: 'center', behavior: 'smooth'});
		// this.completeContainer.current.scrollTop = this.divRefs[id].current.offsetTop - 160;
		TweenMax.to(this.completeContainer.current, 1, { scrollTop: this.divRefs[id].current.offsetTop - 160 });
	}

	onScrollContainer = (e) => {
		if(isMobile || isTablet || isIPad13) return ;
		
		for (var key in this.divRefs) {
			if (this.divRefs[key].current.getBoundingClientRect().y < 240) {
				this.setState({
					divName: key
				});
			}
		}
		if (e.target.scrollTop > 660) {
			this.setState({
				showStickyHeader: true
			});
		} else {
			this.setState({
				showStickyHeader: false
			});
		}
	}

	renderStickyHeader = (isBatchDetailsAvailable, journeyDetails, updateWorkflowStatus) => {
		const userActiveBatchDetails = getUserActiveBatchDetails(this.props.batches);

		if (this.props.userState.get('showStickyHeader')) {
			return (
				<StickyHeader
					isBatchDetailsAvailable={isBatchDetailsAvailable}
					journeyDetails={journeyDetails}
					updateWorkflowStatus={updateWorkflowStatus}
					userActiveBatchDetails={userActiveBatchDetails}
				/>
			);
		}
		return null;

	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const {
			batches, phases, items, user,
			updateWorkflowStatus, uiState, setUiState, loaders, coursesModulesList, modulesItemsList, attachmentsList
		} = this.props;

		const batchDetails = getActiveBatchDetails(batches);
		const userActiveBatchDetails = getUserActiveBatchDetails(this.props.batches);

		const isBatchDetailsAvailable = (batchDetails.isEmpty() || !batchDetails.get('isDetailedDataAvailable'))
			? false
			: true;
		const journeyDetails = batchDetails.get('journey');
		const phasesList = phases.get('phasesList');
		const itemsList = items.get('itemsList');

		const batchPhasesList = phasesList.filter(phase => phase.get('batchId') == batchDetails.get('id'));

		return (
			<div styleName="complete-container" >
				{this.renderStickyHeader(isBatchDetailsAvailable, journeyDetails, updateWorkflowStatus)}
				<div id="myCompleteContainer" styleName="content-container" ref={this.completeContainer}>
					<JourneyItemIntroduction
						isBatchDetailsAvailable={!loaders.get('isFetchingBatchDashboardBasicData')}
						journeyDetails={journeyDetails}
						phasesList={batchPhasesList}
						user={user}
						updateWorkflowStatus={updateWorkflowStatus}
						uiState={uiState}
						renderingInOverlay={false}
						setUiState={setUiState}
						stickyRef={this.props.userState.get('stickyRef')}
						userActiveBatchDetails={userActiveBatchDetails}
					/>
					<TabsBar
						divName={this.props.userState.get('invitationDivName')}
						showStickyHeader={this.props.userState.get('showStickyHeader')}
						scrollContainer={this.scrollContainer}
						tabsConfig={this.state.tabsConfig}
						userState={this.props.userState}
						setUserState={this.props.setUserState}
					/>
					{
						this.state.showBenefitsAndInsights
							? <JourneyExp
								isBatchDetailsAvailable={!loaders.get('isFetchingBatchDashboardBasicData')}
								journeyDetails={journeyDetails}
								uiState={uiState}
								divRef={this.props.userState.get('invitationDivRefs').get('benefitsAndInsights')}
								setUserState={this.props.setUserState}
							/>
							: null
					}
					{
						this.state.showMessageFromCoo
							? <AuthorIntro
								isBatchDetailsAvailable={!loaders.get('isFetchingBatchDashboardBasicData')}
								journeyDetails={journeyDetails}
								user={user}
								uiState={uiState}
								divRef={this.props.userState.get('invitationDivRefs').get('messageFromCoo')}
								renderingInOverlay={false}
							/>
							: null
					}
					<div ref={this.props.userState.get('invitationDivRefs').get('prerequisitesAndSkills')} style={{ width: '100%' }} id='prerequisitesAndSkills'>
						{
							this.state.showPrerequisites
								? <PreRequisite
									isBatchDetailsAvailable={!loaders.get('isFetchingBatchDashboardBasicData')}
									journeyDetails={journeyDetails}
									uiState={uiState}
									divRef={this.props.userState.get('invitationDivRefs').get('prerequisitesAndSkills')}
								/>
								: null
						}
						{
							this.state.showSkills
								? <SkillsCovered
									isBatchDetailsAvailable={!loaders.get('isFetchingBatchDashboardBasicData')}
									journeyDetails={journeyDetails}
									uiState={uiState}
								/>
								: null
						}
					</div>
					<JourneyDetails
						phasesList={batchPhasesList}
						itemsList={itemsList}
						items={items}
						uiState={uiState}
						divRef={this.props.userState.get('invitationDivRefs').get('aboutIndex')}
						loaders={loaders}
						course={this.props.course}
						coursesModulesList={coursesModulesList}
						modulesItemsList={modulesItemsList}
						attachmentsList={attachmentsList}
					/>
					<JourneyFooter />
					<Footer />
				</div>
			</div>
		);
	}
}

export default JourneysListItem;
