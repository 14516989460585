import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        cardContent: {
            ...myTypography.button,
            lineHeight: 1.86,
            fontWeight: 400,
            color: skin.get('greyColor1')
        },
        cardValue: {
            ...myTypography.h4,
            lineHeight: 1.86,
            fontWeight: 700,
            color: skin.get('primaryColor')
        },
        analyticsCard: {
            backgroundColor: skin.get('white'),
            borderRadius: '4px',
            boxShadow: `0 1px 8px 0 ${hexToRgbA(skin.get('black'), 0.25)}`,
        }
    });
}

export default getSkin;