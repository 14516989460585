import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import styles from './additionalInformation.module.sass';
import AudioInDescription from './AudioInDescription';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, {allowMultiple: true})
class AdditionalInformation extends Component {

	componentDidMount(){
		
		/*polyfill for IE11*/
		if (window.NodeList && !NodeList.prototype.forEach) {
			NodeList.prototype.forEach = Array.prototype.forEach;
		}

		let linkList = document.querySelectorAll('.additionalInformationContainer a');
		linkList.forEach(link => link.setAttribute('target', "_blank"))
	}

	render() {
		const { body, skinGuide, audioUrl, audioPlayerEnabled, onAudioEnded, noOfPlaybacks } = this.props;
		const mySkin = getSkin(skinGuide);

		if(!body && !audioUrl){
			return null;
		}

		return (
			<div styleName="container" className="additionalInformationContainer">
				{body && <div styleName="body" className={css(mySkin.body)}
					dangerouslySetInnerHTML={{__html: body}}/>}
				{audioUrl && <AudioInDescription				
					url={audioUrl}
					audioPlayerEnabled={audioPlayerEnabled}
					onAudioEnded={onAudioEnded}
					noOfPlaybacks={noOfPlaybacks}
				/>}
			</div>
		)
	}
}

export default AdditionalInformation;