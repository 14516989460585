import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './learningPhaseItemFeedback.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite';
import ReportDownloadContainer from 'containers/ReportDownloadContainer';
import reports from 'constants/reports';
import FeedbackPopup from '../feebackPopup/FeedbackPopup';
import SecondaryButton from 'commonComponents/buttons/secondaryButton';
import { getApiUrl, getDomainUrl } from 'utils/apiUrl';
import environment from 'utils/environment';
import LinkedInShareCertificateContainer from 'containers/LinkedInShareCertificateContainer';
import CertificateSectionContainer from 'containers/CertificateSectionContainer';
import CircularLoader from 'svgComponents/circularLoader'
import { isMobile } from 'react-device-detect';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LearningPhaseItemFeedback extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showFeedbackPopup: false
		}
	}

	openFeedbackPopup = () => {
		this.setState({ showFeedbackPopup: true })
	}

	closeFeedbackPopup = () => {
		this.setState({ showFeedbackPopup: false })
	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.loaders.get('isFetchingUserFeedback')
			&& this.props.loaders.get('isFetchingUserFeedback')
			&& !(nextProps.itemFeedback.get('isFeedbackCompleted') === true)) {
			this.setState({
				showFeedbackPopup: true
			})
		}
	}

	downloadReport = () => {
		const { selectedItem, activeBatchDetails, user } = this.props;
		var apiURL = '';
		if (environment.REACT_APP_ENV === 'local') {
			apiURL = getApiUrl().toolsUrl;
		} else {
			apiURL = getDomainUrl();
		}
		let url = `${apiURL}/cdb/platform/download/user-report?referenceId=${selectedItem.get('id')}&userGroupId=${activeBatchDetails.get('id')}&userId=${user.get('userID')}&platformId=${environment.REACT_APP_PLATFORM_ID}`;

		console.log('url', url);

		window.open(url)
	}

	renderSimulationJourneyReportsCertificate = (myStyles) => {
		const {
			selectedItem,
			activeBatchDetails,
			userActiveBatchDetails,
			itemFeedback,
			getLabel
		} = this.props;
		if (
			activeBatchDetails.getIn([
				'journey',
				'settings',
				'isSimulationJourney']) !== 'true'
			|| userActiveBatchDetails.get('workflowState') !== 'COMPLETED') {
			return null;
		}
		
		const isCertificateEnabled = activeBatchDetails.getIn(['journey', 'settings', 'isCertificateAvailable']) === 'true';
		
		const isLinkedInShareEnabled = activeBatchDetails.getIn(['journey', 'settings', 'showLinkedInShare']) === 'true';

		const isSimReportEnabled = activeBatchDetails.getIn(['journey', 'settings', 'canDownloadSimReport']) === 'true';

		if(!isMobile){
			return <>
				<CertificateSectionContainer    
						isCertificateEnabled={isCertificateEnabled} 
						isLinkedInShareEnabled={isLinkedInShareEnabled}
						isSimReportEnabled={isSimReportEnabled}
						downloadSimReport={this.downloadReport}               
						batchId={+this.props.match.params.batchId }
						batchDetails={activeBatchDetails}
						match={this.props.match}
						isSimOnlyCourse={true}
				/>
				{
						selectedItem.getIn(['settings', 'isFeedbackEnabled']) === "true" &&
						<div style={ isCertificateEnabled ? {
							position: 'relative',
							marginTop: '10px',
							textAlign: 'left',
							marginLeft: 'calc(100% - 695px)',
						}: {
							position: 'relative',
							marginTop: '10px',
							textAlign: 'center'
						}}>
							<span className={css(myStyles.feedbackLink)} onClick={this.openFeedbackPopup}>
								{
									itemFeedback.get('isFeedbackCompleted') ?
										getLabel('view_feedback_responses_label')
										:
										getLabel('give_feedback_label')
								}
							</span>
						</div>
					}
			</>
		}

		return (
			<div
				styleName="completion-comp-background"
				className={css(myStyles.mainFeedbackBackground)}
			>
				<div styleName="background-image" className={css(myStyles.mainFeedbackImage)}></div>
				<div className={css(myStyles.journeyCompletionText)} style={{ position: 'relative' }}>
					{getLabel('sim_journey_completed_greetings_label')}
				</div>
				<div styleName="buttons-cnt">
					{
						activeBatchDetails.getIn(['journey', 'settings', 'canDownloadSimReport']) === 'true' &&
						<div styleName="report-btn-cnt" onClick={this.downloadReport}>
							<SecondaryButton
								text={getLabel('download_report')}
							/>
						</div>
					}
					{
						activeBatchDetails.getIn(['journey', 'settings', 'isCertificateAvailable']) === 'true' &&
						<div styleName="action-btn-cnt">
							<ReportDownloadContainer
								reportType={reports.get('certificate')}
								reportOutputType="pdf"
								metadata={{ batchId: +this.props.match.params.batchId }}
								downloadButtonText={this.props.getLabel('download_certificate_label')}
							/>
							{isLinkedInShareEnabled && <LinkedInShareCertificateContainer
								reportType={reports.get('certificate')}
								reportOutputType="pdf"
								metadata={{ batchId: +this.props.match.params.batchId }}
								downloadButtonText={this.props.getLabelWithDefaults('download_certificate_label', 'Download Certificate')}
							/>}
						</div>
					}
				</div>
				{
					selectedItem.getIn(['settings', 'isFeedbackEnabled']) === "true" &&
					<div style={{ position: 'relative', marginTop: '10px' }}>
						<span className={css(myStyles.feedbackLink)} onClick={this.openFeedbackPopup}>
							{
								itemFeedback.get('isFeedbackCompleted') ?
									getLabel('view_feedback_responses_label')
									:
									getLabel('give_feedback_label')
							}
						</span>
					</div>
				}
			</div>
		)
	}

	renderLearningPhaseItemFeedback = (myStyles) => {
		const {
			selectedItem,
			activeBatchDetails,
			itemFeedback,
			getLabelWithDefaults,
			getLabel
		} = this.props;
		if (
			activeBatchDetails.getIn([
				'journey',
				'settings',
				'isSimulationJourney']) === 'true') {
			return null;
		}

		return (
			<div
				styleName="completion-comp-background"
				className={css(myStyles.itemFeedbackBackground)}
			>
				<div styleName="background-image" className={css(myStyles.mainFeedbackImage)}></div>
				<div className={css(myStyles.itemCompletionText)}>
					{
						getLabelWithDefaults(
							'completion_note',
							`You’ve completed ${selectedItem.get('name')}`,
							{ entity_name: selectedItem.get('name') }
						)
					}
				</div>
				<div styleName="individual-report-btn-cnt" onClick={this.downloadReport}>
					<SecondaryButton
						text={getLabel('download_report')}
						clickFunction={this.downloadReport}
					/>
				</div>
				{
					selectedItem.getIn(['settings', 'isFeedbackEnabled']) === "true" &&
					<div style={{ position: 'relative', marginTop: '10px' }}>
						<span className={css(myStyles.itemFeedbackLink)} onClick={this.openFeedbackPopup}>
							{
								itemFeedback.get('isFeedbackCompleted') ?
									getLabel('view_feedback_responses_label')
									:
									getLabel('give_feedback_label')
							}
						</span>
					</div>
				}
			</div>
		)
	}

	render() {
		const {
			itemFeedback,
			activeBatchDetails,
			selectedItem,
			submitAnswer,
			submitFeedback,
			match,
			skinGuide,
			loaders
		} = this.props;
		const { showFeedbackPopup } = this.state;
		const myStyles = getSkin(skinGuide);

		if(selectedItem.getIn(['settings', 'isFeedbackEnabled']) === "true" &&
		(loaders.get('isFetchingFeedback') || loaders.get('isFetchingUserFeedback'))){
			return <div style={{ height: '100%', width: '43px', margin: 'auto' }}>
				<CircularLoader />
			</div>;
		}

		return (
			<div styleName="learning-phase-item-feedback">
				{this.renderSimulationJourneyReportsCertificate(myStyles)}
				{this.renderLearningPhaseItemFeedback(myStyles)}
				{
					showFeedbackPopup &&
					selectedItem.getIn(['settings', 'isFeedbackEnabled']) === "true" &&
					!loaders.get('isFetchingFeedback') &&
					<FeedbackPopup
						itemFeedback={itemFeedback}
						submitAnswer={submitAnswer}
						match={match}
						loaders={loaders}
						closeFeedbackPopup={this.closeFeedbackPopup}
						selectedItem={selectedItem}
						submitFeedback={submitFeedback}
						activeBatchDetails={activeBatchDetails}
					/>
				}
			</div>
		);
	}
}

export default LearningPhaseItemFeedback;