import React from 'react';
import { connect } from 'react-redux';
import { fetchJourneyCatalogueData } from 'actions/journeys/apiActions';
import JourneyCatalogue from 'components/journeyCatalogue';
import {

} from 'actions/userJourneys/apiActions';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import JourneyCatalogueStyleB from '../components/journeyCatalogueStyleB';
import { checkIfValueIsTrue } from 'utils/utilFunctions';
import { fetchCorpDeckJourneyCatalogueData } from '../actions/journeys/apiActions';
import JourneyCatalogueCorpDeck from 'components/journeyCatalogueCorpDeck';
import {
	showJourneyInterest,
	removeJourneyInterest
} from 'actions/userJourneys/apiActions';

class JourneyCatalogueContainer extends React.Component {

	componentDidMount() {
		const isExpressInterestEnabled = checkIfValueIsTrue(this.props.userOrganization.getIn(["userOrganization", "platformSettings", "expressInterestsEnabled"]));

		if(isExpressInterestEnabled && !this.props.showStyleB) {
			this.props.fetchCorpDeckJourneyCatalogueData("");
		} else {
			this.props.fetchJourneyCatalogueData(this.props.fromPublicCatalog, "");
		}
		let segmentData = {
			...(this.props.match.params),
			url: window.location.href,
			pathHash: window.location.hash
		};
		window.analytics.page(
			'Journey Catalog',
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	render() {
		if(this.props.showStyleB) {
			return <JourneyCatalogueStyleB { ...this.props }/>;
		}

		if(checkIfValueIsTrue(this.props.userOrganization.getIn(["userOrganization", "platformSettings", "expressInterestsEnabled"]))) {
			return <JourneyCatalogueCorpDeck { ...this.props } />
		}

		return <JourneyCatalogue
			{...this.props}
		/>;
	}
}



const mapStateToProps = state => {
	return {
		journeyCategories: state.getIn(['journeyCategories', 'journeyCategories']),
		journeyFormats: state.getIn(['journeyFormats', 'journeyFormats']),
		journeyTags: state.getIn(['journeyTags', 'journeyTags']),
		catalogueJourneys: state.get('availableJourneys'),
		userOrganization: state.get('userOrganization'),
		corpDeckJourneys: state.get("corpDeckJourneys")
	}
};

const mapDispatchToProps = dispatch => ({
	fetchJourneyCatalogueData: (removeInclude, searchText = "") => {
		dispatch(fetchJourneyCatalogueData(removeInclude, searchText))
	},
	fetchCorpDeckJourneyCatalogueData: (searchText = "") => {
		dispatch(fetchCorpDeckJourneyCatalogueData(searchText));
	},
	showJourneyInterest: (payload, searchText = "") => {
		dispatch(showJourneyInterest(payload, searchText));
	},
	removeJourneyInterest: (payload, searchText = "") => {
		dispatch(removeJourneyInterest(payload, searchText));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(JourneyCatalogueContainer);
