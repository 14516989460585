import React, { Component } from 'react';
import styles from './buildingAgilityAsStrategy.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DigitalToolHeader from '../digitalToolHeader';
import DigitalToolGuide from '../digitalToolGuide';
import ToolItemList from '../toolItemList';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class BuildingAgilityAsStrategy extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuide: false
		}
	}

	toggleShowGuide = () => {
		this.setState({
			showGuide: !this.state.showGuide
		});
	}

	submitDataStrategy = () => {
		this.props.markModuleItemDone(this.props.match.params);
	}

	downloadDataStrategy = () => {
		alert("feature not available!");
	}

	getToolItemListComp = (dataKey) => {
		const { toolData, toolUserData, colorPalette } = this.props;
		const selectedCP = toolUserData && toolUserData.data && toolUserData.data.selectedColorPalette ? toolUserData.data.selectedColorPalette[dataKey] : ""
		return (
			<ToolItemList
				type={dataKey}
				key={`key_${dataKey}`}
				userData={toolUserData && toolUserData.data && toolUserData.data.cards ? toolUserData.data.cards[dataKey] : {}}
				addNewUserDataRecord={this.props.addNewUserDataRecord}
				deleteRecord={this.props.deleteRecord}
				colorPalette={colorPalette}
				selectedColorPalette={selectedCP}
				setColorPalette={this.props.setColorPalette}
				cardStyles={{
					height: '240px',
					maxHeight: '240px',
					justifyContent: 'space-between',
					borderRadius: '6px 0 6px 6px',
					backgroundImage: `${selectedCP && colorPalette[selectedCP].cardBackground ? '' : 'linear-gradient(132deg, #477bc0, #4a53ae)'}`
				}}
				instructions={toolData.cardExamples[dataKey]}
			/>
		)
	}

	renderBuildingAgilityAsStrategy = () => {
		let myStyles = getSkin(this.props.skinGuide);
		const { toolData } = this.props;
		let cardsComponent = [];
		if (toolData && toolData.actors) {
			for (let actor in toolData.actors) {
				let dataKey = `actor_${toolData.actors[actor].toLowerCase()}`;
				cardsComponent.push(
					<div key={dataKey} styleName="individual-card-cnt">
						<div className={css(myStyles.roleText)} styleName="header">
							{toolData.actors[actor]}
						</div>
						<div styleName="connector-line"></div>
						<div styleName="tool-items-list-card-cnt">
							{this.getToolItemListComp(dataKey)}
						</div>
					</div>
				);
			}
		}
		return (
			<div styleName="building-agility-as-strategy-content-cnt">
				<div className={css(myStyles.stepHeaderText)} styleName="step-header-text">
					{toolData.labels.stepHeading || "Fill the Cards below"}
				</div>
				<div styleName="cards-outer-cnt">
					<div styleName="value-proposition-header">
						<div className={css(myStyles.valuePropositionText)} styleName="value-proposition-text">
							{toolData.labels.value_proposition_label || "Value Proposition"}
						</div>
					</div>
					<div styleName="cards-inner-cnt">
						{cardsComponent}
					</div>
				</div>
			</div>
		)
	}

	render() {
		const { toolData } = this.props;
		return (
			<div styleName="building-agility-as-strategy-cnt">
				<DigitalToolHeader
					toggleShowGuide={this.toggleShowGuide}
					submitAction={this.submitDataStrategy}
					downloadAction={this.downloadDataStrategy}
					toolData={toolData}
				/>
				{
					this.renderBuildingAgilityAsStrategy()
				}
				{
					this.props.toolData && this.state.showGuide &&
					<DigitalToolGuide
						closeGuide={this.toggleShowGuide}
						guideData={this.props.toolData.guide}
						labels={this.props.toolData.labels}
					/>
				}
			</div>
		);
	}
}

export default BuildingAgilityAsStrategy;