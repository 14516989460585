import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import Close from 'images/icons/close.svg';
import Shrink from 'images/icons/shrink.svg';
import Menu from 'images/icons/menu.svg';


const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		feedbackItem: {
			border: `1px solid ${skin.get('greyColor5')}`
		},
		likeToHearLabel: {
			...myTypography.overline,
			color: skin.get('greyColor1'),
			fontWeight: 600
		},
		selectedLikeToHearLabel: {
			...myTypography.overline,
			color: skin.get('secondaryColor'),
			fontWeight: 600
		}
	});
}

export default getSkin;