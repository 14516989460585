/**
 * @author: sourabh
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: measures.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * measures reducer is responsible for measures related actions
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { mergeListsBasedOnKey } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    measuresList: [],
    userMeasuresList: [],
    userLowScoresList: [],
    userHighScoresList: [],    
    measuresListAdditionalDetails: {},
    userMeasuresListAdditionalDetails: {}
});

const measures = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_MEASURES_LIST'):
            return state.set(
                'measuresList',
                mergeListsBasedOnKey(state.get('measuresList'), action.payload.measuresList, 'id')
            ).set(
                'measuresListAdditionalDetails',
                Immutable.fromJS(action.payload.measuresListAdditionalDetails)
            );
        case actionConsts.get('SET_USER_MEASURES_LIST'):
            return state.set(
                'userMeasuresList',
                mergeListsBasedOnKey(
                    state.get('userMeasuresList'), action.payload.userMeasuresList, 'id'
                )
            ).set(
                'userMeasuresListAdditionalDetails',
                Immutable.fromJS(action.payload.userMeasuresListAdditionalDetails)
            ).set(
                'userLowScoresList',
                mergeListsBasedOnKey(
                    state.get('userLowScoresList'), action.payload.userLowScoresList, 'id'
                )
            ).set(
                'userHighScoresList',
                mergeListsBasedOnKey(
                    state.get('userHighScoresList'), action.payload.userHighScoresList, 'id'
                )
            );
        default:
            return state;
    }
};

export default measures;