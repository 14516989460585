import React, { Component } from 'react';
import styles from './circularProgressBar.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class CircularProgressBar extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    render() {
        const styles = getSkin(this.props.skinGuide);

        // Size of the enclosing square
        const sqSize = this.props.sqSize;
        // SVG centers the stroke width on the radius, subtract out so circle fits in square
        const radius = (this.props.sqSize - this.props.strokeWidth) / 2;
        // Enclose cicle in a circumscribing square
        const viewBox = `0 0 ${sqSize} ${sqSize}`;
        // Arc length at 100% coverage is the circle circumference
        const dashArray = radius * Math.PI * 2;
        // Scale 100% coverage overlay with the actual percent
        const dashOffset = dashArray - dashArray * this.props.percentage / 100;
  
        return (
            <svg
                width={this.props.sqSize}
                height={this.props.sqSize}
                viewBox={viewBox}>
                <circle
                    styleName="circle-background"
                    className={css(styles.circleBackground)}
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${this.props.strokeWidth}px`} />
                <circle
                    styleName="circle-progress"
                    className={css(styles.circleProgress)}
                    cx={this.props.sqSize / 2}
                    cy={this.props.sqSize / 2}
                    r={radius}
                    strokeWidth={`${this.props.strokeWidth}px`}
                    // Start progress marker at 12 O'Clock
                    transform={`rotate(-180 ${this.props.sqSize / 2} ${this.props.sqSize / 2})`}
                    style={{
                    strokeDasharray: dashArray,
                    strokeDashoffset: dashOffset
                    }} />x
                
            </svg>
        );
    }
  }
  
  CircularProgressBar.defaultProps = {
    sqSize: 28,
    percentage: 25,
    strokeWidth: 2
  };

  export default CircularProgressBar;