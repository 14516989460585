import React, { Component } from 'react';
import styles from './videoOverlay.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { filterObjectFromArray } from 'utils/objectUtils';
import VideoPlayer from 'commonComponents/contentPlayers/videoPlayer';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import BackArrow from 'svgComponents/backArrow';
import defaults from 'constants/defaults';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class VideoOverlay extends Component {

	constructor(props) {
		super(props);
	}

	updateRoute = () => {
		const route = routes.get('BATCH');
		const params = [{ batchId: this.props.batches.get('activeBatchId') }];
		updateRoute(route, params);
	}



	getVideoLink = () => {
		const batchesList = this.props.batches.get('batchesList');
		const activeBatchId = this.props.batches.get('activeBatchId');
		const activeBatch = filterObjectFromArray(batchesList, 'id', activeBatchId);
		const title = activeBatch.get('journey').get('name');
		const videoLink = activeBatch.get('journey').get('videoLink');
		return { title, videoLink };
	}

	sendSegmentData = () => {
		window.analytics.track(
			'Journey video closed',
			{
				journeyName: this.getVideoLink().title,
				batchId: this.props.batches.get('activeBatchId')
			},
			getAmplitudeSessionDetails()
		)
	}

	hideOverlay = () => {
		const { setUiState } = this.props;
		this.sendSegmentData();
		setUiState({ showVideoOverlay: false });
	}

	render() {
		const batch = this.getVideoLink();
		const kalturaEntryId = batch.videoLink;
		const activeBatchId = this.props.batches.get('activeBatchId');
		const title = batch.title;
		const config = { kalturaEntryId };
		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div styleName="video-overlay-container"
				onClick={this.hideOverlay}>
				<div styleName="menu-title">
					<div styleName="icon"><BackArrow color={'white'} /></div>
					<div styleName="journey-title" className={css(myStyles.title)} onClick={this.updateRoute}>{title}</div>
				</div>
				<div styleName="video">
					<VideoPlayer
						key={`batch_video_${activeBatchId}`}
						videoPlayerTargetId='journey-video-player-container'
						config={config}
						playerHeight={'80vh'}
						playerWidth={'80vw'} />
				</div>
			</div>
		);

	}
}

export default VideoOverlay;