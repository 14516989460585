import actionConsts from 'constants/actions';

const setUserState = (payload) => {
    return {
        type: actionConsts.get('SET_USER_STATE'),
        payload
    };
};

export default setUserState;
