import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
        userDot: {
			backgroundColor: hexToRgbA(skin.get('greyColor1'), 0.85 * 0.6)
		},
		tooltipContent: {
            backgroundColor: skin.get('white'),
            color: skin.get('greyColor2'),
            boxShadow: `0px 2px 6px 3px ${hexToRgbA(skin.get('black'), 0.16)}`,
            ...myTypography.caption,
            fontSize: '14px',
            fontWeight: 400
        },
        tooltipArrow: {
            borderLeft: `7px solid transparent`,
            borderRight: `7px solid transparent`,
            borderTop: `7px solid ${skin.get('white')}`
        }
	});
}

export default getSkin;