import React, { Component } from 'react';
import styles from './backArrow.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class WriteIcon extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">
                <g fill="none" fillRule="evenodd" stroke="#223B92" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3}>
                <path d="M25.5 48.44V30.097M17.326 37.74l7.781-7.642 7.782 7.642" />
                <path d="M36.26 32.57h2.334c4.355.008 8.226-2.724 9.609-6.78 1.383-4.056-.033-8.526-3.515-11.096a10.265 10.265 0 0 0-11.83-.23C31.414 6.912 24.486 1.587 16.675 2.025 8.864 2.464 2.599 8.53 2.04 16.195c-.558 7.664 4.764 14.544 12.432 16.07" />
                </g>
            </svg>
		);
	}
}



export default WriteIcon;
