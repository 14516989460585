import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './scormContent.module.sass';

import applyLabel from 'utils/LabelProvider';
import ModuleItemFullScreen from 'components/moduleItems/moduleItemFullScreen';

@applyLabel
@CSSModules(styles, {allowMultiple: true})
class ScormCourse extends Component {

    render() {
        return (
            <span>
                {
                    <ModuleItemFullScreen
                        title={this.props.title}
                        src={this.props.src}
                        onExit={this.props.onExit}
                        showPlatformExitButton={this.props.showPlatformExitButton}
                    />
                }
            </span>
        )
    }

}

export default ScormCourse;
