/**
 * @author: sourabh p
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: BatchContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * base container is rendered on initial load of application and mounted only
 * once displayed on every route of the application and all other containers are
 * children of this base container
 *
 **/

import React from 'react';
import { connect } from 'react-redux';

import { setActiveBatch } from 'actions/batches/apiActions';

import {fetchBatchDetails} from 'actions/batches/newApiActions';

import { updateBatchIdFromRoute } from 'actions/batches/actionCreators';
import BatchInvitationContainer from 'containers/batch/BatchInvitationContainer';
import BatchDashboardContainer from 'containers/batch/BatchDashboardContainer';
import SystemCheckContainer from 'containers/SystemCheckContainer';

import { filterObjectFromArray } from 'utils/objectUtils';
import AppLoader from 'commonComponents/appLoader';

import enumConstants from 'constants/enums';

class BatchContainer extends React.Component {

	constructor(props) {
		super(props);
		const { batchId } = props.match.params;
		props.fetchBatchDetails(batchId);
		props.updateBatchIdFromRoute(batchId);
	}

	render() {
		const { batches, loaders, match, userState } = this.props;
		const activeBatchId = batches.get('activeBatchId');
		const batchesList = batches.get('batchesList');
		const userBatchesList = batches.get('userBatchesList');
		const activeUserBatch = filterObjectFromArray(userBatchesList, 'batchId', activeBatchId);


		if (batches.get('isSwitchingBatch')) {
			return <AppLoader userState={this.props.userState} />
		}

		if (activeBatchId === -1 || activeUserBatch.isEmpty() || batchesList.isEmpty() || loaders.get('isFetchingBatchDashboardBasicData')) {
			return <AppLoader userState={this.props.userState} />
		}

		if(userState.get('batchToRunSystemCheckFor') === +match.params.batchId){
			return <SystemCheckContainer context="batch"/>;
		}

		if (activeUserBatch.get('workflowState') === enumConstants.get('INVITED')) {
			return <BatchInvitationContainer />
		}

		return <BatchDashboardContainer key={`batch_${match.params.batchId}`} />
	}
}

const mapStateToProps = (state) => ({
	batches: state.get('batches'),
	loaders: state.get('loaders'),
	userState: state.get('userState')
});

const mapDispatchToProps = (dispatch) => ({
	updateBatchIdFromRoute: (batchIdFromRoute) => {
		dispatch(updateBatchIdFromRoute(batchIdFromRoute));
	},
	setActiveBatch: (batchId) => {
		dispatch(setActiveBatch(batchId));
	},
	fetchBatchDetails: (batchId)=>{
		dispatch(fetchBatchDetails(batchId));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchContainer);