import React, { Component } from 'react';
import styles from './analytics.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { checkIfPresent } from 'utils/utilFunctions';
import StatImg from 'images/stat.png';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class AnalyticsCard extends Component {
    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (  
            <div className={css(myStyles.analyticsCard)} styleName="analytics-card">
                <div styleName="card-graph">
                    {
                        checkIfPresent(this.props.cardDetails.imageUrl)
                        ?   <img width="75px" src={this.props.cardDetails.imageUrl} alt="facts_image" />
                        :   <img width="75px" src={StatImg} alt="facts_image" />
                    }
                </div>
                <div className={css(myStyles.cardContent)} styleName="card-content"
                    dangerouslySetInnerHTML={{
                        __html: this.props.getLabel(this.props.cardDetails.fact)
                    }}
                >
                    {/* {this.props.getLabel(this.props.cardDetails.fact)} */}
                </div>
            </div>
        );
    }

}

export default AnalyticsCard;