import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { appendQueryParameters } from 'utils/utilFunctions';

class FetchLinkedinShareUrl extends NetPackWrapper {

	constructor() {
		super('POST', true);
	}

	prepareUrl = apiInfoObject => {
		const url = urls.getIn(['REPORT', 'SHARE_CERTIFICATE_ON_LINKEDIN']);
		return appendQueryParameters(url, apiInfoObject.queryParameters);
	};

	successCall = (response, apiInfoObject) => {
    console.log('successCall', response);		
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, unable to share your certificate. Please try again!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new FetchLinkedinShareUrl();
