import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import styles from './assignment.module.sass';
import applyLabel from 'utils/LabelProvider';
import {getDefaultFirebaseDatabase} from 'utils/firebase';
import { getUserId } from 'utils/user';
import { getTimerText } from 'utils/dates';
import _ from 'lodash';
import ModuleItemUtil from 'utils/moduleItem/moduleItem';

@applySkin
@applyLabel
@CSSModules(styles, {allowMultiple: true})
class TextEntry extends Component {

  constructor(props){
    super(props);
    this.timerIntervalId = null;
    this.state = {
      timerRef: null,
      remaining: null,
      submittingOnTimerEnd: false
    }
  }

  async componentDidMount(){
    if (this.textAreaInput) {
      this.textAreaInput.focus();
    }

    if(this.props.savedBody){
      this.validateInput(this.props.savedBody);
    }

    if(this.isNLPAssignment() && this.getTimerSetting()){
      const timerRef = await this.getFirebaseRef(); 
      this.setState({ timerRef }, () => {
        this.initTimer();
      }) 
    }
  }

  componentWillUnmount(){
    this.clearTimerInterval();
  }

  isNLPAssignment = () => {
    return ModuleItemUtil.isNLPAssignment(this.props.moduleItem);   
  }

  getTimerSetting = () => {
    const { moduleItem } = this.props;
    const assignment = moduleItem.get('contentDetails');
    return parseInt(assignment.getIn(['al_data', 'time']) || 0, 10);  
  }

  validateInput = (inputText) => {
    const { moduleItem, setInputError } = this.props;
    const assignment = moduleItem.get('contentDetails');
    const isNLPAssignment = this.isNLPAssignment();
    if(isNLPAssignment){
      const textLimits = assignment.getIn(['al_data', 'textLimits']) || null;
      if(textLimits){
        const wordCount = inputText.trim().split(/\s+/).length;
        if(!(wordCount>=textLimits.get('min') && wordCount<=textLimits.get('max'))){
          setInputError(`You response needs to be a minimum of ${textLimits.get('min')} words and a maximum of ${textLimits.get('max')} words. Current word count: ${wordCount}`);
        } else {
          setInputError(null);
        }
      }      
    }
  }

  onTextChange = (event) => {
    const { setTextEntryBody } = this.props;
    const inputText = event.target.value;
    setTextEntryBody(inputText);
    this.validateInput(inputText);
  }

  onPaste = (e) => {
    const isNLPAssignment = this.isNLPAssignment();
    if(isNLPAssignment){
      window.alert('Pasting is not allowed');
      e.preventDefault()
      return false;
    }
  }

  clearTimerInterval = () => {
    window.clearInterval(this.timerIntervalId);
  }

  initTimer = () => {
    let timer = 1000;
    const timerRef = this.state.timerRef;
    let remaining = timer;
    const timerSetting = this.getTimerSetting() * 1000;

    const onTimerUp = () => {
      console.log('timer: submitting....');
      this.clearTimerInterval();
      this.setState({ remaining: 0, submittingOnTimerEnd: true  }, () => {
        window.setTimeout(() => this.props.onSubmit(), 1000);
      });
    }

    this.state.timerRef.once('value', async (snapshot) => {
     console.log('snapshot', snapshot.val());

      let startTimestamp = snapshot.val()?.startTimestamp;

      if(!startTimestamp){
        startTimestamp = Date.now();
        await timerRef.update({ startTimestamp });
      }

      remaining = timerSetting - (Date.now() - startTimestamp);

			this.setState({ remaining });

      this.timerIntervalId = window.setInterval(() => {
        remaining = timerSetting - (Date.now() - startTimestamp);
        console.log('500s done: timer: time up', remaining,  remaining < 0);
        if(remaining < 0){
          onTimerUp();  
        } else {
          this.setState({ remaining });      
        }
      }, 500);

    });

  };

  getFirebaseRef = async () => {
    const assignmentId = this.props.moduleItem.getIn(['contentDetails', 'id']);
		const firebaseDbPromise = getDefaultFirebaseDatabase();		
		const firebaseDb = await firebaseDbPromise;
		let timerString = `assignment_timers/user_${getUserId()}_assignment_${assignmentId}`;
		return firebaseDb.ref(timerString);
	}
  
  render(){
    const { getLabelWithDefaults, savedBody } = this.props;
    const { remaining, submittingOnTimerEnd } = this.state;
    
    const styles = getSkin(this.props.skinGuide);

    return <div className={css(styles.assignmentTextAreaContainer)}   styleName="assignment-text-area-cnt">
      {
        submittingOnTimerEnd ? <span styleName='time-up-msg'>
          Time's up! Submitting...
        </span> :
        ((remaining && remaining > 0) && <div styleName='timer' style={remaining < 60000 ? {color: 'red'} : {}}>
          Time (mins): {getTimerText(remaining)}
        </div>)
      }
      <textarea className={css(styles.textArea)}
        styleName="assignment-text-area"
        placeholder={getLabelWithDefaults('assignment_write_here_label', 'Write here...')}
        ref={(input) => { this.textAreaInput = input; }}
        onChange={this.onTextChange}
        onPaste={this.onPaste}
        defaultValue={savedBody}
      >
      </textarea>
    </div>;
  }

}

export default TextEntry;