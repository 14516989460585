import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');

    return StyleSheet.create({
        imageBorder: {
            border: `solid 2px ${skin.get('greyColor5')}`
        }
    });
}

export default getSkin;