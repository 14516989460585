const dataGen = (weeks, max) => {

	var data = [];
	for (let index = 1; index < weeks+1; index++) {
		data.push(
			{
				week: index,
				userTime: Math.floor(Math.random() * Math.floor(max)),
				groupAvg: Math.floor(Math.random() * Math.floor(max))
			}
		);
	}
	return data;

};

export default dataGen;