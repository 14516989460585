import React, { Component } from 'react';
import styles from './nextActivity.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import NextActivityLoader from 'commonComponents/loaders/nextActivityLoader';
import routes from 'utils/webAppRoutes/routeConstants';
import applyLabel from 'utils/LabelProvider';
import enumConstants from 'constants/enums';
import { filterObjectFromArray } from 'utils/objectUtils';
import ScormCourse from 'components/moduleItems/scormContent/ScormCourse';
import { replaceDatePlaceholderAndFormat } from 'utils/locks';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class NextActivity extends Component {

    componentDidMount(){
        window.adNextActivityScormCourseCloseWindow =  () => {
            this.onExitScormCourse();
        }
    }

    componentWillReceiveProps(nextProps) {
        const { activeItem, routeParams, nextActivityModuleItem } = nextProps;
        if (activeItem.get('type') == 'COURSE') {
            const isFetching = nextActivityModuleItem.get('fetching');
            const isAvailable = nextActivityModuleItem.get('isNextItemActivityAvailable');
            if(!isAvailable || (nextProps.activeItemId !== this.props.activeItemId)){
                if (!isFetching) {
                    nextProps.fetchNextActivityModuleItem({
                        ...routeParams,
                        courseId: activeItem.get('toolId'),
                        phaseId: activeItem.get('phaseId'),
                        phaseItemId: activeItem.get('id')
                    });
                }
            }
        }
    }

    getItemDetails = (itemId) => {
        return filterObjectFromArray(
            this.props.items.get('itemsList'), 'id', itemId
        );
    }

    getItemPhaseStatus = (phaseId) => {
        const userPhase = filterObjectFromArray(
            this.props.phases.get('userPhasesList'), 'id', phaseId
        );
        return userPhase.get('workflowState');
    }

    getScormLaunchDetails = () => {
        const { courseForNextActivity } = this.props;
        let launchDetails = {
            shouldLaunch: false,
            src: null,
            title: ''
        }

        if (courseForNextActivity) {
            const course = courseForNextActivity.get('courseForNextActivity');
            if (course && course.get('course_type') == 'scorm') {
                launchDetails.shouldLaunch = true;
                launchDetails.src = course.get('mobile_launch_data').get('mobile_launch_url');
                launchDetails.title = course.get('name');
            }
        }
        return launchDetails;
    }

    onExitScormCourse = () => {
        this.props.unsetCourseForNextActivity();
    }

    checkAndSetItemAndPhaseWorkflowStatus = () => {
        const {
            activeItemId,
            items
        } = this.props;

        const itemDetails = this.getItemDetails(activeItemId);
        const userItem = filterObjectFromArray(items.get('userItemsList'), 'id', itemDetails.get('id'));

        if (
            itemDetails.get('isLocked') && !itemDetails.get('isExpired')
            && (userItem.get('workflowStatus') !== enumConstants.get('COMPLETED'))
        ) {
            this.props.toggleDialog();
            return;
        }

        const itemPhaseStatus = this.getItemPhaseStatus(itemDetails.get('phaseId'));
        if (itemPhaseStatus && itemPhaseStatus == enumConstants.get('NOT_STARTED')) {
            this.props.setPhaseWorkflowStatus(
                itemDetails.get('batchId'), itemDetails.get('phaseId'), enumConstants.get('STARTED'), null
            );
        }

        if (userItem.get('workflowStatus') == enumConstants.get('UNOPENED')) {
            console.log('setting item workfs')
            this.props.setItemWorkflowStatus(
                enumConstants.get('STARTED')
            );
        }

        this.props.setUserState({
            currentItemId: activeItemId
        });
    }

    goToCourseModuleItem = () => {
        const {
            activeItem,
            routeParams,
            fetchCourseForNextActivity,
            activeItemId
        } = this.props;

        const itemDetails = this.getItemDetails(activeItemId);
        if (itemDetails.get('isLocked') || itemDetails.get('isExpired')) {
            const phaseItemLockExplanation = replaceDatePlaceholderAndFormat(true, itemDetails.get('lockExplanation'), itemDetails.get('lockDate'));
            this.props.toggleDialog(phaseItemLockExplanation);
            return;
        }

        /*Navigate to the next incomplete item in current development phase item (course) */

        fetchCourseForNextActivity({
            courseId: activeItem.get('toolId'),
            batchId: routeParams.batchId,
            phaseId: activeItem.get('phaseId'),
            phaseItemId: activeItem.get('id')
        });

        this.checkAndSetItemAndPhaseWorkflowStatus();

        /* do the workflow status setting after that since it's not immediately required */
    }

    sendSegmentData = () => {
        const { activeItemId } = this.props;
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            activeItemId: activeItemId
        }
        window.analytics.track(
            'Next Activity Clicked',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onClickStart = () => {
        const {
            location,
            activeItemId,
            forDevelopmentPhase,
            activeItem,
            routeParams
        } = this.props;

        this.sendSegmentData()

        /* module page */
        if (forDevelopmentPhase) {
            const route = routes.get('BATCH_ITEM_MODULE');
            const params = [
                { 'BATCH_ID': routeParams.batchId },
                { 'COURSE_ID': routeParams.courseId },
                { 'MODULE_ID': activeItem.get('context_module_id') },
                { 'ITEM_ID': activeItem.get('id') },
                { '_PHASE_ID_': routeParams.phaseId },
                { '_PHASEITEMID_': routeParams.phaseItemId }
            ]
            updateRoute({ route, params });
            return;
        }

        if (activeItem.get('type') == 'COURSE') {
            this.goToCourseModuleItem();
        } else { /*for assessment items*/
            const route = routes.get('ITEM_FROM_PATH');
            const params = [
                { 'PATH': location.pathname },
                { 'ITEM_ID': activeItemId }
            ];
            const routeObj = { route, params };

            const itemDetails = this.getItemDetails(activeItemId);

            if (itemDetails.get('isLocked') && !itemDetails.get('isExpired')) {
                const lockExplanation = replaceDatePlaceholderAndFormat(true, itemDetails.get('lockExplanation'), itemDetails.get('lockDate'));
                this.props.toggleDialog(lockExplanation);
                return;
            }

            const itemPhaseStatus = this.getItemPhaseStatus(itemDetails.get('phaseId'));
            if (itemPhaseStatus && itemPhaseStatus == enumConstants.get('NOT_STARTED')) {
                this.props.setPhaseWorkflowStatus(
                    itemDetails.get('batchId'), itemDetails.get('phaseId'), enumConstants.get('STARTED'), routeObj
                );
            }
            else {
                updateRoute(routeObj);
            }

            this.props.setUserState({
                currentItemId: activeItemId
            });
        }
    }

    render() {
        const {
            skinGuide,
            hideTopBorder,
            activityLabel,
            activityButtonLabel,
            activeItem,
            activeItemId,
            forDevelopmentPhase,
            activityButtonHidden,
            nextActivityModuleItem,
            apiErrors,
            getLabelWithDefaults,
            formLabelForModuleItem
        } = this.props;

        /* if (!nextActivityModuleItem.get('nextActivityItem') || nextActivityModuleItem.get('nextActivityItem').isEmpty()) return null; */

        if (activeItem.isEmpty()) return null;

        let { activityTitle } = this.props;

        const myStyles = getSkin(skinGuide, hideTopBorder);


        const scormLaunchDetails = this.getScormLaunchDetails();
        if (scormLaunchDetails.shouldLaunch) {
            return <ScormCourse
                title={scormLaunchDetails.title}
                src={scormLaunchDetails.src}
                onExit={this.onExitScormCourse}
            />
        }

        if (apiErrors && (apiErrors.get('fetchPhaseItemsError') || apiErrors.get('fetchUserPhaseItemsError'))) {
            return <span></span>;
        }

        if (!forDevelopmentPhase) {
            if (
                activeItemId === -1 || activityLabel === "" || activityTitle === ""
                || activityButtonLabel === "" || !this.props.isBatchLabelsAvailable
            ) {
                return (
                    <div
                        styleName="next-activity-container loader-container"
                    >
                        <div styleName="next-activity-loader-content">
                            <NextActivityLoader />
                        </div>
                    </div>
                );
            }
        }

        if (activeItem.get('type') == 'COURSE') {
            if (nextActivityModuleItem.get('isNextItemActivityAvailable') == false) {
                return (
                    <div
                        className={css(myStyles.nextActivityLoaderContainer)}
                        styleName="next-activity-container loader-container"
                    >
                        <div styleName="next-activity-loader-content">
                            <NextActivityLoader />
                        </div>
                    </div>
                );
            } else {
                activityTitle = nextActivityModuleItem.get('nextActivityItem').get('title');
            }
        }

        return (
            <div
                ref={this.props.nextActivityRef}
                className={css(myStyles.nextActivityContainer)}
                styleName={forDevelopmentPhase ? "next-activity-container-development" : "next-activity-container"}
                style={this.props.showStickyHeader ? { margin: 'unset' } : {}}
            >
                <div
                    styleName={forDevelopmentPhase ? "next-activity-content-development" : "next-activity-content"}
                    className={css(myStyles.nextActivityContent)}
                // style={this.props.showStickyHeader ? { paddingBottom: '10px' } : {}}
                >
                    {
                        !this.props.showStickyHeader &&
                        <div className={css(myStyles.tagLine)} styleName="tag-line">
                            {activityLabel}
                        </div>
                    }
                    <div
                        styleName="content-outer-cnt"
                        style={this.props.showStickyHeader ? { padding: 'unset' } : {}}
                    >
                        <div styleName="content-container">
                            <div>
                                <div className={css(myStyles.itemType)}>                                
                                    { 
                                        nextActivityModuleItem.get('nextActivityItem') && getLabelWithDefaults(
											formLabelForModuleItem(
												nextActivityModuleItem.getIn(['nextActivityItem', 'type_label']),
												nextActivityModuleItem.getIn(['nextActivityItem', 'sub_type'])
											), nextActivityModuleItem.getIn(['nextActivityItem', 'type_label'])
										)
                                    }
                                </div>
                                <div
                                    className={this.props.showStickyHeader ? css(myStyles.titleSticky) : css(myStyles.title)}
                                    styleName="title"
                                >
                                    {activityTitle}
                                </div>
                            </div>
                        </div>
                        <div styleName="button-container">
                            {
                                activityButtonHidden ? null :
                                    <div
                                        className={this.props.showStickyHeader ? css(myStyles.buttonContinueSticky) : css(myStyles.buttonContinue)}
                                        styleName="button-continue"
                                        onClick={this.onClickStart}
                                    >
                                        {activityButtonLabel}
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NextActivity;
