import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import defaults from 'constants/defaults';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		errorHeading: {
			...myTypography.h2,
			color: skin.get('greyColor1')
		},
		errorDesc: {
			...myTypography.overline,
			color: skin.get('greyColor1')
		},
		buttonContainer: {
			...myTypography.button,
			cursor: 'pointer',
			backgroundColor: skin.get('primaryColor') || defaults.get('DEFAULT_PRIMARY_COLOR'),
			color: skin.get('white'),
			':hover': {
				backgroundColor: skin.get('primaryVariantColor')
			}
		}

	});
}

export default getSkin;
