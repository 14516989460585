import React, { Component } from 'react';
import styles from './journeyIntroductionPopup.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { checkIfPresent, checkIfValueIsTrue } from 'utils/utilFunctions';
import Calendar from 'svgComponents/calendarSvg';
import Clock from 'svgComponents/clock';
import CloseCircle from 'svgComponents/closeCircle';
import VideoOverlay from 'commonComponents/journeyVideoOverlay';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import SecondaryButton from 'commonComponents/buttons/secondaryButton';
import CircleTick from 'svgComponents/circleTick/CircleTick';
import DefaultLoader from 'commonComponents/loaders/defaultLoader';
import TickMarkWithGreenFill from 'svgComponents/tickMarkWithGreenFill';
import VideoPlayIcon from 'svgComponents/videoPlayIcon';
import { hexToRgbA } from 'utils/styleUtil';
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { shouldAllowSelfEnrollmentInBatch } from "utils/batchUtils.js";

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyIntroductionPopup extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showJourneyVideo: false
		}
	}

	sendSegmentData = (event) => {
		const { journeyInstanceId, journeyDetails } = this.props;
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			journeyName: journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'name']),
			journeyId: journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'id']),
			journeyInstanceId
		};
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	onClickOfRequestButton = () => {
		const { journeyInstanceId, requestJourneyAccess, searchText } = this.props;
		this.sendSegmentData('Journey Requested/Enrolled');
		requestJourneyAccess([{ journeyInstanceId }], searchText);
	}

	onClickOfInterestButton = () => {
		const { journeyInstanceId, showJourneyInterest, searchText, userOrganization } = this.props;
		showJourneyInterest({ journeyIds: [journeyInstanceId], orgId: userOrganization.getIn(['userOrganization', 'id']) }, searchText);
	}

	onClickOfCancelInterestButton = () => {
		const { journeyInstanceId, removeJourneyInterest, searchText,userOrganization } = this.props;
		removeJourneyInterest({ journeyIds: [journeyInstanceId], orgId: userOrganization.getIn(['userOrganization', 'id']) }, searchText);
	}

	onClickOfCancelRequestButton = () => {
		const { journeyInstanceId, cancelJourneyAccessRequest, searchText } = this.props;
		this.sendSegmentData('Journey Request Canceled');
		cancelJourneyAccessRequest([{ journeyInstanceId }], searchText);
	}

	onClickOfSignUp = () => {
		const { journeyInstanceId, userOrganization } = this.props;
		let orgHash = "";
		let userHash = "";
		let urlPathTokens = window.location.pathname.split('/');
		orgHash = urlPathTokens[2];
		if (urlPathTokens.indexOf("user") > 0) {
			userHash = urlPathTokens[4];
		}
		let payload = {
			organizationId: userOrganization.getIn(['userOrganization', 'id']),
			journeyInstanceId: journeyInstanceId,
			frontEndRouteHash: '/journeys/catalogue'
		}
		window.localStorage.setItem(
			'appState',
			JSON.stringify(payload)
		)
		if (userHash !== "")
			window.location = `/organization-user/${userHash}/signup`;
		else
			window.location = `/organization/${orgHash}/signup`;
	}

	getJourneySyllabusDetails = (myStyles) => {
		const {
			journeyDetails,
		} = this.props;
		return [
			journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'duration']) &&
			<div key="popup-duration-details" styleName="individual-details-cnt">
				<div className={css(myStyles.durationLabel)}>
					{`${journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'durationLabel'])}`}
				</div>
				<div styleName="detail-value-cnt" className={css(myStyles.durationValue)}>
					<div styleName="icon-cnt">
						<Calendar />
					</div>
					<div className={css(myStyles.durationValue)}>
						{`${journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'duration'])}`}
					</div>
				</div>
			</div>,
			journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'effort']) &&
			<div key="popup-study-details" styleName="individual-details-cnt">
				<div className={css(myStyles.durationLabel)}>
					{`${journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'effortsNeededLabel'])}`}
				</div>
				<div styleName="detail-value-cnt" className={css(myStyles.durationValue)}>
					<div styleName="icon-cnt">
						<Clock />
					</div>
					<div className={css(myStyles.durationValue)}>
						{`${journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'effort'])}`}
					</div>
				</div>
			</div>
		]
	}

	getPrerequisites = (myStyles) => {
		const { journeyDetails, getLabel } = this.props;
		if (!journeyDetails.getIn(['journeyInstanceDetails', 'journey']) || journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'prerequisitesInfo']).size === 0)
			return null;
		let eachPrerequisiteRow = [];
		journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'prerequisitesInfo']).forEach((record, index) => {
			eachPrerequisiteRow.push(
				<div key={`prerequisite_${index}`} styleName="each-details">
					<div styleName="tick-icon-cnt">
						<CircleTick />
					</div>
					<div
						className={css(myStyles.infoText)}
						dangerouslySetInnerHTML={{ __html: record.get('info') }}
					></div>
				</div>
			)
		})
		return (
			<div styleName="prerequisite-cnt">
				<div className={css(myStyles.detailTitle)}>
					{getLabel('journey_prereq_label')}
				</div>
				{eachPrerequisiteRow}
			</div>
		)
	}

	getSkillsCovered = (myStyles) => {
		const { journeyDetails, getLabel } = this.props;
		if (!journeyDetails.getIn(['journeyInstanceDetails', 'journey']) || journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'competencies']).size === 0)
			return null;
		let eachSkillRow = [];
		journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'competencies']).forEach((record, index) => {
			eachSkillRow.push(
				<div key={`prerequisite_${index}`} styleName="each-details">
					<div styleName="tick-icon-cnt">
						<CircleTick />
					</div>
					<div
						className={css(myStyles.infoText)}
						dangerouslySetInnerHTML={{ __html: record.get('name') }}
					></div>
				</div>
			)
		})
		return (
			<div styleName="skills-cnt">
				<div className={css(myStyles.detailTitle)}>
					{getLabel('journey_skill_covered_label')}
				</div>
				{eachSkillRow}
			</div>
		)
	}

	playJourneyVideo = () => {
		this.sendSegmentData('Journey video opened');
		this.setState({ showJourneyVideo: true })
	}

	closeVideo = () => {
		this.sendSegmentData('Journey video closed');
		this.setState({ showJourneyVideo: false })
	}

	getNotAssignedButton = () => {
		const {
			journeyDetails,
			getLabel,
			isRequestingJourneyAccess,
			userOrganization
		} = this.props;

		const isExpressInterestEnabled = checkIfValueIsTrue(userOrganization.getIn(["userOrganization", "platformSettings", "expressInterestsEnabled"]));
		let journey = journeyDetails.getIn(['journeyInstanceDetails']);
		let text = "";

		if(isExpressInterestEnabled) {
			text = getLabel("i_m_interested_label");
		} else if(shouldAllowSelfEnrollmentInBatch(journey.getIn(["journey", "format", "key"]) === "SELF_PACED", userOrganization)) {
			text = getLabel('enrol_label');
		} else {
			text = getLabel('request_access_label');
		}

		return (
			<PrimaryButton
				text={text}
				clickFunction={isExpressInterestEnabled ? this.onClickOfInterestButton : this.onClickOfRequestButton}
				disabled={isRequestingJourneyAccess}
				buttonShadow={false}
			/>
		);
	}

	getRequestStatusWithButton = (myStyles) => {
		const {
			journeyDetails,
			getLabel,
			skinGuide,
			isRequestingJourneyAccess,
			alwaysSignUp,
			userOrganization
		} = this.props;

    const isExpressInterestEnabled = checkIfValueIsTrue(userOrganization.getIn(["userOrganization", "platformSettings", "expressInterestsEnabled"]));

		if (alwaysSignUp)
			return (
				<PrimaryButton
					text={getLabel('sign_up_to_request_label')}
					clickFunction={this.onClickOfSignUp}
					buttonShadow={false}
				/>
			)

		let journey = journeyDetails.getIn(['journeyInstanceDetails']);
		let message = "";
		let messageColor = "#6CBC14";
		let buttonText = "";

		switch (journey.get('status')) {
			case "REQUESTED":
				message = getLabel('already_requested_msg');
				messageColor = "#6CBC14";
				buttonText = getLabel('already_requested_label');
				break;
			case "REJECTED":
				message = getLabel('request_rejected_msg');
				messageColor = skinGuide.getIn(['skinHex', 'secondaryColor']);
				buttonText = getLabel('request_rejected_label');
				break;
			case "ACCEPTED":
			case "COMPLETED":
			case "ASSIGNED":
				if (shouldAllowSelfEnrollmentInBatch(journey.getIn(["journey", "format", "key"]) === "SELF_PACED", userOrganization)) {
					buttonText = getLabel('enrolled_successfully_label');
					message = getLabel('request_accepted_auto_enroll_label');
				}
				else {
					message = getLabel('request_accepted_msg');
					buttonText = getLabel('request_accepted_label');
				}
				messageColor = "#6CBC14";
				break;
			case "NOT_ASSIGNED":
				return this.getNotAssignedButton();
				// message = "";
				// messageColor = "#6CBC14";
				// buttonText = getLabel("interested_label");
				break;
      case "INTERESTED":
        message = "";
        messageColor = "#6CBC14";
        buttonText = getLabel("interested_label");
        break;
			case null:
				return (
					<PrimaryButton
						text={
							shouldAllowSelfEnrollmentInBatch(
								journey.getIn(["journey", "format", "key"]) === "SELF_PACED",
								userOrganization
								)
									? getLabel('sign_up_to_enrol_label')
									: getLabel('sign_up_to_request_label')
						}
						clickFunction={this.onClickOfSignUp}
						buttonShadow={false}
					/>
				)
			default:
				return null;
		}

		return (
			<div styleName="request-status">
				<div styleName="status" style={{ backgroundColor: hexToRgbA(messageColor, 0.2) }}>
					<div styleName="status-icon-cnt">
						{
							journey.get('status') === "REJECTED" ?
								<CloseCircle circleFill={messageColor} strokeColor={'white'} circleFillOpacity={1} />
								:
								<TickMarkWithGreenFill />
						}
					</div>
					<div className={css(myStyles.statusButtonText)} style={{ color: messageColor }}>
						{buttonText}
					</div>
				</div>
				<div className={css(myStyles.statusText)}>
					{message}
				</div>

				{this.getSecondaryActionButton(journey.get('status'))}
			</div>
		)
	}

	getSecondaryActionButton = (status) => {
		const {
			journeyDetails,
			isRequestingJourneyAccess,
			getLabel
		} = this.props;
		let journey = journeyDetails.getIn(['journeyInstanceDetails']);

		switch (status) {
			case "REJECTED":
				return (
					<div styleName="request-again-btn-cnt">
						<PrimaryButton
							text={getLabel('re_request_access_label')}
							clickFunction={this.onClickOfRequestButton}
							disabled={isRequestingJourneyAccess}
							buttonShadow={false}
						/>
					</div>
				);
			case "REQUESTED":
				return (
					<div styleName="request-again-btn-cnt" onClick={this.onClickOfCancelRequestButton}>
						<SecondaryButton
							text={getLabel('cancel_request_label')}
							disabled={isRequestingJourneyAccess}
							buttonShadow={false}
						/>
					</div>
				);
			case "INTERESTED": 
				return (
					<div styleName="request-again-btn-cnt" onClick={this.onClickOfCancelInterestButton}>
						<SecondaryButton
							text={getLabel('cancel_interest_label')}
							disabled={isRequestingJourneyAccess}
							buttonShadow={false}
						/>
					</div>
				);
			case "ASSIGNED":
			case "ACCEPTED":
			case "COMPLETED":
				return (
					<div styleName="request-again-btn-cnt">
						<PrimaryButton
							text={getLabel('go_to_journey_label')}
							clickFunction={this.goToJourney}
							disabled={isRequestingJourneyAccess}
							buttonShadow={false}
						/>
					</div>
				)
			default: return null;
		}
	}

	goToJourney = () => {
		const {
			userOrganization,
			journeyDetails,
			selectedBatchId
		} = this.props;

		let journey = journeyDetails.getIn(['journeyInstanceDetails']);
		let batchId = selectedBatchId;

		/*TODO: not the best logic, replace with flag for parent container - User Courses / courses catalogue*/
		if(!selectedBatchId){
			if (shouldAllowSelfEnrollmentInBatch(journey.getIn(["journey", "format", "key"]) === "SELF_PACED", userOrganization)){
				batchId = journey.get('selfPacedBatchId');
			}
		}

		if (!batchId) {
			console.error("Batch Id not present");
			return null;
		}

		if (
			journey.getIn(['journey', 'settings', 'isSimulationJourney']) === true
			|| journey.getIn(['journey', 'settings', 'isSimulationJourney']) === "true"
		) {
			updateRoute({
				route: routes.get('BATCH_ITEM'),
				params: [
					{ 'BATCH_ID': batchId },
					{ 'ITEM_ID': journey.get('phaseItemId') }
				]
			});
		} else {
			updateRoute({
				route: routes.get('BATCH'),
				params: [{ 'BATCH_ID': batchId }]
			});
		}
	}

	getPopupContent = (myStyles) => {
		const {
			journeyDetails,
			userOrganization,
			getLabel
		} = this.props;

		if (journeyDetails.get('isJourneyInstanceLoading') || userOrganization.get('userOrganizationDataLoading'))
			return (
				<DefaultLoader
					styleObject={{ height: '100%', width: '100%' }}
				/>
			);

		if (journeyDetails.get('journeyInstanceDetails').size === 0)
			return null;

		let isBackdropAvailable = checkIfPresent(journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'backdropImage']));
		let image_url = journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'catalogImageUrl']);
		if (!checkIfPresent(image_url))
			image_url = "https://webassets.knolskape.com/misc/ajinkya_wankhade/2019/11/25/52/videoimage.png";

		const journeyCategoryKey = journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'categories', 0, 'key']);

		return (
			<div styleName="popup-content">
				<div styleName="journey-details-cnt">
					<div styleName="journey-details">
						<div className={css(myStyles.journeyName)}>{journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'name'])}</div>
						<div className={css(myStyles.journeyCategory)}>
							{
								journeyCategoryKey && getLabel(`category_${journeyCategoryKey}_label`)
							}
						</div>
						<div
							className={css(myStyles.journeyDescription)}
							styleName="journey-description"
							dangerouslySetInnerHTML={{
								__html: this.props.journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'description'])
							}}
						></div>
						<div styleName="read-more-btn"></div>
						<div styleName="journey-syllabus-cnt">
							{this.getJourneySyllabusDetails(myStyles)}
						</div>
					</div>
					<div
						styleName="content-bg"
						style={{
							backgroundImage: isBackdropAvailable ?
								`url("${this.props.journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'backdropImage'])}")`
								:
								`url("https://webassets.knolskape.com/misc/amul_garg/2020/02/12/56/ftm1.jpg")`,
							zIndex: '10'
						}}
					></div>
					<div
						styleName="content-bg"
						className={css(myStyles.boxGradient)}
						style={{ zIndex: '15' }}
					></div>
				</div>
				{
					checkIfPresent(journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'aimOfJourney'])) &&
					<div styleName="journey-overview-cnt">
						<div
							className={css(myStyles.expContentTitle)}
							styleName="exp-content-title"
						>
							{getLabel('journey_intro_why_label')}
						</div>
						<div
							className={css(myStyles.expContentDesc)}
							styleName="exp-content-desc"
							dangerouslySetInnerHTML={{
								__html: journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'aimOfJourney'])
							}}
						/>
					</div>
				}
				<div styleName="request-access-with-additional-details-cnt">
					{
						checkIfPresent(journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'videoLink'])) ?
							<div styleName="video-preview-cnt" onClick={this.playJourneyVideo}>
								<div styleName="play-icon-cnt">
									<VideoPlayIcon />
								</div>
							</div>
							:
							<div styleName="journey-thumbnail-cnt">
								<img src={image_url} alt="Course Thumbnail" />
							</div>
					}
					<div styleName="request-access-btn-cnt">
						{this.getRequestStatusWithButton(myStyles)}
					</div>
					<div styleName="prerequisite-cnt">
						{this.getPrerequisites(myStyles)}
					</div>
					<div styleName="skills-cnt">
						{this.getSkillsCovered(myStyles)}
					</div>
				</div>
			</div>
		)
	}

	onClosePopup = () => {
		this.sendSegmentData('Journey Popup Closed');
		this.props.closePopup();
	}

	render() {
		const {
			skinGuide,
			journeyDetails,
			closePopup
		} = this.props;
		const {
			showJourneyVideo
		} = this.state;
		const myStyles = getSkin(skinGuide);

		return (
			<div styleName="journey-introduction-popup-cnt">
				<div styleName="popup-content-cnt">
					{
						this.getPopupContent(myStyles)
					}
					<div styleName="popup-close-icon" onClick={this.onClosePopup}>
						<CloseCircle circleFill="#575E79" circleFillOpacity="1" />
					</div>
				</div>
				<div styleName="popup-overlay-bg" onClick={closePopup}>

				</div>
				{
					showJourneyVideo &&
					<VideoOverlay
						closeVideo={this.closeVideo}
						journeyId={journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'id'])}
						journeyName={journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'name'])}
						videoLink={journeyDetails.getIn(['journeyInstanceDetails', 'journey', 'videoLink'])}
					/>
				}
			</div>
		);
	}
}

export default JourneyIntroductionPopup;