import React, { Component } from 'react';
class PhaseExpired extends Component {

	render() {
		return (
            <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 17.5C18.1421 17.5 21.5 14.1421 21.5 10C21.5 5.85786 18.1421 2.5 14 2.5C9.85786 2.5 6.5 5.85786 6.5 10C6.5 14.1421 9.85786 17.5 14 17.5Z" fill="#9C9FA8" stroke="#9C9FA8" stroke-width="1.28571" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.8214 11.3351L16.5 11.3351" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M13.8673 11.2351L13.8678 6.50002" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.93658 10.0385C9.76115 9.70888 9.39789 9.5 9 9.5C8.60211 9.5 8.23885 9.70888 8.06342 10.0385L2.59014 20.3309C2.4562 20.5824 2.47239 20.88 2.63297 21.1178C2.79355 21.3557 3.07576 21.5002 3.37938 21.5H14.6206C14.9242 21.5002 15.2065 21.3557 15.367 21.1178C15.5276 20.88 15.5438 20.5824 15.4099 20.3309L9.93658 10.0385Z" fill="#DC6565" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.07084 19.2835C8.97649 19.2835 8.9 19.36 8.9 19.4543C8.9 19.5487 8.97649 19.6252 9.07084 19.6252C9.16519 19.6252 9.24167 19.5487 9.24167 19.4543C9.24167 19.36 9.16519 19.2835 9.07084 19.2835Z" fill="white"/>
                <path d="M9.07084 19.2835C8.97649 19.2835 8.9 19.36 8.9 19.4543C8.9 19.5487 8.97649 19.6252 9.07084 19.6252C9.16519 19.6252 9.24167 19.5487 9.24167 19.4543C9.24167 19.36 9.16519 19.2835 9.07084 19.2835V19.2835" stroke="white" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.07083 16.9167V13.5V16.9167Z" fill="white"/>
                <path d="M9.07083 16.9167V13.5" stroke="white" stroke-width="1.3" stroke-linecap="round"/>
            </svg>
		);
	}
}
export default PhaseExpired;
