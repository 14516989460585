import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setUserOrganization } from 'actions/home/actionCreators';
import { appendQueryParameters } from 'utils/utilFunctions';
import { setBatchSkin } from 'actions/skin/actionCreators';

class FetchOrganizationWithCode extends NetPackWrapper {

	constructor() {
		super("GET", true);
	}

	prepareUrl = (apiInfoObject = {}) => appendQueryParameters(
		urls.get('PLATFORM').get('GET_ORGANIZATION'),
		apiInfoObject.queryParameters
	);

	successCall = (response) => {
		if (response.organizations && response.organizations.length > 0) {
			const activeBatchId = this.getState().get('batches').get('activeBatchId');
			this.dispatch(setUserOrganization(response.organizations[0]));
			this.dispatch(setBatchSkin({ batchId: activeBatchId, skin: response.organizations[0].skin }))
		}
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR_METRICS'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Encountered an error while fetching your overall statistics.',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}

}

export default new FetchOrganizationWithCode();
