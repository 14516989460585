import React, { Component } from 'react';
import styles from './feedbackItemIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class FeedbackItemIcon extends Component {

	render() {
		const fillColor = this.props.svgColor || "#7E818F";

		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 16 16">
					<g fill="none" fillRule="evenodd" stroke={fillColor} strokeLinecap="round" strokeLinejoin="round" opacity=".8">
						<path d="M2.458 14.709a1.167 1.167 0 0 1-1.166-1.167V2.46c0-.644.522-1.167 1.166-1.167h11.084c.644 0 1.166.523 1.166 1.167v11.083c0 .645-.522 1.167-1.166 1.167H2.458z" />
						<path d="M5.813 6.396a2.189 2.189 0 1 1 2.66 2.137A.587.587 0 0 0 8 9.1v.649M8 11.208a.146.146 0 1 1-.146.146c0-.08.065-.146.146-.146" />
					</g>
				</svg>

			</div>
		);
	}
}



export default FeedbackItemIcon;
