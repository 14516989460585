import FetchQuiz from 'actions/serverCalls/surveyQuiz/FetchQuiz';
import FetchQuizResponse from 'actions/serverCalls/surveyQuiz/FetchQuizResponse';
import StartQuiz from 'actions/serverCalls/surveyQuiz/StartQuiz';
import { fetchBatchDetailsForItem } from 'actions/batches/newApiActions';
import {
    getActiveItemDetails
} from 'containers/containerHelpers/assessmentContainerHelper';
import UpdateQuizResponse from 'actions/serverCalls/surveyQuiz/UpdateQuizResponse';
import SubmitQuiz from 'actions/serverCalls/surveyQuiz/SubmitQuiz';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import FetchUserItems from 'actions/serverCalls/items/FetchUserItems';
import FetchQuizSubmissions from 'actions/serverCalls/surveyQuiz/FetchQuizSubmissions';
import setUserState from 'actions/userState/actionCreators';
import { setItemWorkflowStatus } from 'actions/items/apiActions';
import enumConstants from 'constants/enums';

const fetchQuizResponse = (quizId) => {
    return FetchQuizResponse.call({
        queryParameters: {
            quizId
        }
    }).then(() => FetchQuizSubmissions.call({
        queryParameters: {
            quizId
        }
    }))
}


const fetchQuiz = (quizId) => {
    return FetchQuiz.call({
        quizId,
        queryParameters: {
            isAdmin: 'false'
        }
    }).then(() => fetchQuizResponse(quizId))
}

const startQuiz = (phaseItemId, batchId, quizId) => {
    return StartQuiz.call({
        queryParameters: {
            batchId: +batchId,
            phaseItemId: +phaseItemId
        },
        body: {
            quizId
        },
        headers: {
            'Content-type': 'application/json'
        }
    })
}

const getQuizInitialData = (batchId, itemId) => (dispatch, getState) => {
    fetchBatchDetailsForItem(dispatch, batchId).then(async () => {
        dispatch(setUserState({
            currentItemId: parseInt(itemId),
            showStickyHeader: false
        }));
        const itemsList = getState().getIn(['items', 'itemsList']);
        const item = itemsList.find((item) => item.get('id') === +itemId);
        const quizId = item ? item.get('toolId') : null;
        if (quizId !== undefined && quizId !== null) {
            const { quiz: quizData } = await FetchQuiz.call({
                quizId,
                queryParameters: {
                    isAdmin: false
                }
            })
            const { quizResult: quizResponseData } = await FetchQuizResponse.call({
                queryParameters: {
                    quizId
                }
            });
            const attemptsLeft = quizData.maxAttempt - (quizResponseData.attemptsAlreadyTaken ? quizResponseData.attemptsAlreadyTaken : 0);
            const atLeastOneAttemptPlayed = attemptsLeft < quizData.maxAttempt ? true : false;
            const quizTimer = quizData['dataWithoutAnswers']['maxTimeToFinish'];
            const isTimerQuiz = quizTimer ? true: false;
            const currentAttemptIsInProgress = quizResponseData.remTimeInSec ||
                (!isTimerQuiz && 
                    (quizResponseData['quizResultData'] && 
                quizResponseData['quizResultData']['status']==='started'))  ? true : false;
            if (!currentAttemptIsInProgress) {
                startQuiz(itemId, batchId, quizId).then(() => {
                    if (!atLeastOneAttemptPlayed) {
                        dispatch(setItemWorkflowStatus(enumConstants.get('STARTED')));
                    }
                });
            }
        }

    })

}

const updateQuizResponse = (payload, batchId, phaseItemId) => {
    UpdateQuizResponse.call({
        body: payload,
        queryParameters: {
            batchId: +batchId,
            phaseItemId: +phaseItemId
        },
        headers: {
            'Content-type': 'application/json'
        }
    })
}


const submitQuiz = (payload, batchId, phaseItemId, shouldUpdateRoute = true) => {
    SubmitQuiz.call({
        body: payload,
        queryParameters: {
            batchId: +batchId,
            phaseItemId: +phaseItemId
        },
        headers: {
            'Content-type': 'application/json'
        }
    }).then(async () => {
        await FetchUserItems.call({ queryParameters: { batchId } });
        if (shouldUpdateRoute) {
            const route = routes.get('BATCH_ITEM');
            const params = [
                { 'BATCH_ID': batchId },
                { 'ITEM_ID': phaseItemId }
            ]
            updateRoute({ route, params });
        } else {
            fetchQuizResponse(payload.quizId);
        }

    })
}

export {
    fetchQuiz,
    fetchQuizResponse,
    startQuiz,
    getQuizInitialData,
    updateQuizResponse,
    submitQuiz
};