import React, { Component } from 'react';
import styles from './competencyTab.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import {
	getCompetencyArray,
	getCompetencyText,
	getCompetencyNumber,
	textTruncate
} from 'utils/utilFunctions';
import TargetArrow from 'svgComponents/targetArrow';
import LinkInfo from 'commonComponents/linkInfo';
import InfoTooltip from 'commonComponents/infoTooltip';
import { ResponsiveRadar } from '@nivo/radar';
import {isEmptyObject} from 'utils/objectUtils';
import { checkIfPresent } from '../../../../../util/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CompetencyTab extends Component {

	constructor(props) {
		super(props);
		this.state = {
			selctedShowMoreId: -1
		};
	}

	setShowMoreId = (id) => {
		this.setState({
			selctedShowMoreId: id
		});
	}

	// getGraphData = (finalMeasure) => {
	// 	return finalMeasure.mainMeasure.subMeasures.map((eachSubMeasure) => {
	// 		return {
	// 			"behaviour": eachSubMeasure.name,
	// 			"iLead": Math.floor(Math.random() * 5),
	// 			"Kognitix": Math.floor(Math.random() * 5)
	// 		}
	// 	});
	// }

	getSubCompetencyType = (finalMeasure, myStyles) => {
		let subType = "";
		finalMeasure.mainMeasure.subMeasures.map((eachMeasure) => {
			if(checkIfPresent(eachMeasure.typeName)) {
				subType = eachMeasure.typeName;
			}
		});
		return subType;
	}

	renderScoreDescriptors = (finalMeasure, myStyles) => {
		return finalMeasure.mainMeasure.subMeasures.map((eachMeasure, index) => {
			const userMeasure = finalMeasure.userMeasure.subMeasures.filter((eachUserMeasure) => {
				return (eachUserMeasure.id === eachMeasure.id);
			});
			if (isEmptyObject(userMeasure[0]) || userMeasure[0] === null || userMeasure[0] === undefined) {
				return null;
			}
			const tipNumber = getCompetencyNumber(userMeasure[0].score);
			
			return (
				<div styleName="score-descriptor-container"
					className={
						(index === (finalMeasure.mainMeasure.subMeasures.length - 1))
						?	css(myStyles.borderScoreAll)
						:	css(myStyles.borderScoreTop)
					}>
					{/* <div styleName="score-descriptor-dot"></div> */}
					<div styleName="score-name-container">
						<div styleName="score-descriptor-name">
							<div className={css(myStyles.descriptorName)} styleName="name">{eachMeasure.name}</div>
							{
								checkIfPresent(eachMeasure.description)
								?	<InfoTooltip
										tooltipText={eachMeasure.description}
									/>
								:	null
							}
						</div>
						<div className={css(myStyles.descriptorScore)} styleName="score-descriptor-score">{userMeasure[0].score.toFixed(2)} on 5</div>
					</div>
					{
						checkIfPresent(tipNumber) && checkIfPresent(eachMeasure.tips[tipNumber]) && checkIfPresent(eachMeasure.tips[tipNumber][0])
						?	eachMeasure.tips[tipNumber][0].length > 70 && this.state.selctedShowMoreId !== eachMeasure.id
						?	<div className={css(myStyles.tipDescription)} styleName="score-descriptor-tip">
								{textTruncate(eachMeasure.tips[tipNumber][0], 70)}
								<span
									className={css(myStyles.scoreDescShowmore)}
									styleName="score-desc-showmore"
									onClick={() => {
										this.setShowMoreId(eachMeasure.id);
									}}
								>
									Show More
								</span>
							</div>
						:	<div className={css(myStyles.tipDescription)} styleName="score-descriptor-tip">
								{eachMeasure.tips[tipNumber][0]}
							</div>
						:	null
					}
				</div>
			);
		});
	}

	renderMeasuredWithLinks = (finalMeasure, myStyles) => {
		const measuredWithItems = finalMeasure.mainMeasure.measuredWith;
		const links = measuredWithItems.map((eachItem, id) => {
			return (
				<LinkInfo
					linkName={(id === measuredWithItems.length - 1) ? eachItem.name : `${eachItem.name},`}
					tooltipText={eachItem.desc}
				/>
			);
		});
		return links;
	}

	renderMeasureScoreTip = (finalMeasure, myStyles) => {
		const score = finalMeasure.userMeasure.score;
		const tipNumber = getCompetencyNumber(score);
		const tips = finalMeasure.mainMeasure.tips;
		if(isEmptyObject(tips)) {
			console.log("No tips enabled for ", finalMeasure.mainMeasure.name);
			return "";
		}
		if(
			checkIfPresent(tipNumber)
			&& checkIfPresent(tips[tipNumber][0])
		) {
			return tips[tipNumber][0];
		}
		return "";
	}

	renderBehaviorGraph = (finalMeasure, graphData, myStyles) => {
		return (
			<ResponsiveRadar
				data={graphData}
				keys={[
					"Score"
				]}
				indexBy="behaviour"
				maxValue={5}
				margin={{
					"top": 70,
					"right": 80,
					"bottom": 45,
					"left": 80
				}}
				curve="linearClosed"
				borderWidth={2}
				borderColor="inherit"
				gridLevels={5}
				gridShape="circular"
				gridLabelOffset={36}
				enableDots={true}
				dotSize={8}
				dotColor="inherit"
				dotBorderWidth={0}
				dotBorderColor="#ffffff"
				enableDotLabel={true}
				dotLabel="value"
				dotLabelYOffset={-12}
				colors="red_blue"
				colorBy="key"
				fillOpacity={0.1}
				animate={false}
				motionStiffness={90}
				motionDamping={15}
				isInteractive={true}
				legends={[
					{
						"anchor": "top-right",
						"direction": "column",
						"translateX": -50,
						"translateY": -40,
						"itemWidth": 80,
						"itemHeight": 20,
						"itemTextColor": "#999",
						"symbolSize": 12,
						"symbolShape": "circle",
						"effects": [
							{
								"on": "hover",
								"style": {
									"itemTextColor": "#000"
								}
							}
						]
					}
				]}
			/>
		);
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const finalMeasure = this.props.finalMeasure;
		const graphData = this.props.graphData;
		const competencyText = getCompetencyText(finalMeasure.userMeasure.score, this.props.getLabel);

		return (
			<div className={css(myStyles.competencyTabContainer)} styleName="competency-tab-container">
				<div className={css(myStyles.headerContent)} styleName="header-content">
					<div styleName="header-left">
						<div styleName="header-arrow"><TargetArrow /></div>
						<div className={css(myStyles.headerTitle)} styleName="header-title">
							{finalMeasure.mainMeasure.name}
							{
								checkIfPresent(finalMeasure.mainMeasure.description)
								?	<div styleName="left-info">
										<InfoTooltip tooltipText={finalMeasure.mainMeasure.description} />
									</div>
								:	null
							}
							
						</div>
					</div>
					<div styleName="header-right">
						<div className={css(myStyles.headerScore)}><span className={css(myStyles.headerScoreBold)}>{finalMeasure.userMeasure.score.toFixed(2)}</span>/5</div>
						<div className={css(myStyles.separationLine)} styleName="separation-line"></div>
						<div className={css(myStyles.headerCompetency)}>
							{competencyText}
							{/* {this.props.getLabel('competency_range_greeting').replace(
								'COMPETENCY_RANGE_NAME',
								competencyText
							)} */}
						</div>
					</div>
				</div>
				<div className={css(myStyles.tabContent)} styleName="tab-content">
					{
						checkIfPresent(this.renderMeasureScoreTip(finalMeasure, myStyles))
						?	<div className={css(myStyles.subdescription)} styleName="competency-description" dangerouslySetInnerHTML={{__html: this.renderMeasureScoreTip(finalMeasure, myStyles)}}>
								{/* {this.renderMeasureScoreTip(finalMeasure, myStyles)} */}
							</div>
						:	null
					}
					{
						(finalMeasure.mainMeasure.subMeasures.length > 0)
						?	[
								<div className={css(myStyles.subHeading)} styleName="score-descriptors-heading">
									{/* {this.props.getLabel('score_descriptors_label')} */}
									{this.getSubCompetencyType(finalMeasure, myStyles)}&nbsp;
									{this.props.getLabel('scores_label')}
								</div>,
								<div className={css(myStyles.subdescription)} styleName="score-descriptors-desc">
									<div styleName="behaviour-graph">
										{this.renderBehaviorGraph(finalMeasure, graphData, myStyles)}
									</div>
									<div styleName="score-descriptor-table">
										{this.renderScoreDescriptors(finalMeasure, myStyles)}
									</div>
								</div>
							]
						:	null
					}
					{/* <div className={css(myStyles.subHeading)} styleName="measured-with-heading">
						{this.props.getLabel('measured_with_label')}
					</div>
					<div className={css(myStyles.subdescription)} styleName="measured-with-links">
						{this.renderMeasuredWithLinks(finalMeasure, myStyles)}
					</div> */}
					{/* <div className={css(myStyles.subHeading)} styleName="behaviour-heading">
						{this.props.getLabel('behaviours_label')}
					</div> */}
					{/* <div styleName="behaviour-graph">
						{this.renderBehaviorGraph(finalMeasure, graphData, myStyles)}
					</div> */}
				</div>
			</div>
		);
	}
}

export default CompetencyTab;