import React, { Component } from 'react';
import styles from './videoPlayIcon.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class VideoPlayIcon extends Component {

	render() {

		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 80 80" fill="none">
					<path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z" fill="white" />
					<g filter="url(#filter0_d)">
						<path fillRule="evenodd" clipRule="evenodd" d="M29 62.0667V17L61 39.5334L29 62.0667Z" fill="white" />
					</g>
					<defs>
						<filter id="filter0_d" x="25" y="15" width="40" height="53.0667" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
							<feFlood floodOpacity="0" result="BackgroundImageFix" />
							<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
							<feOffset dy="2" />
							<feGaussianBlur stdDeviation="2" />
							<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
							<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
							<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
						</filter>
					</defs>
				</svg>
			</div>
		);
	}
}



export default VideoPlayIcon;
