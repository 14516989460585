import React, { Component } from 'react';
import styles from './tabsBar.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TabsBar extends Component {

	getTabsConfig = () => {
		return [
			{
				name: this.props.getLabel('journey_overview_label'),
				id: 1
			},
			{
				name: this.props.getLabel('journey_index_label'),
				id: 2
			}
		]
	}

	sendSegmentData = (tabName) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			clickedTab: tabName
		}
		window.analytics.track(
			'Journey information overlay tab clicked',
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const tabs = this.getTabsConfig().map((eachTab, index) => {
			return (
				<div
					key={`eachTab-${index}`}
					styleName="each-tab"
					onClick={() => {
						this.sendSegmentData(eachTab.name);
						this.props.switchContainer(eachTab.id);
					}}
				>
					<div
						styleName="each-tab-name"
						className={(this.props.tabId == eachTab.id) ? css(myStyles.tabNameActive) : css(myStyles.tabName)}
					>
						{eachTab.name}
					</div>
					{
						this.props.tabId == eachTab.id
							? <div styleName="each-tab-line" className={css(myStyles.tabLine)}></div>
							: null
					}

				</div>
			);
		});
		return (
			<div
				className={css(myStyles.navigationContainer)}
				styleName={this.props.showStickyHeader ? "navigation-container-relative" : "navigation-container"}
			>
				<div styleName="navigation-tabs-container">
					<div styleName="tabs-container">
						{tabs}
					</div>
					<div className={css(myStyles.lineContainer)} styleName="line-container"></div>
				</div>
			</div>
		);
	}
}

export default TabsBar;