import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './competencyTags.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import Question from './../question';
import TagsList from './tagsList';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CompetencyTags extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const { question, submitAnswer } = this.props;
        if (question) {
            return (
                <div styleName="question-and-tags-container">
                    <Question question={question} />
                    <TagsList
                        question={question}
                        submitAnswer={submitAnswer}
                    />
                </div>
            );
        }
        return null;
    }
}

export default CompetencyTags;
