import { StyleSheet } from 'aphrodite';
import enumConstants from 'constants/enums';

const getDotColor = (isSelected, isLockedOrExpired, workflowStatus, theme) => {
    const skin = theme.get('skinHex');

    if(isLockedOrExpired) {
        return getStyleOnType(enumConstants.get('LOCKED'), skin);
    } else if(isSelected){
        return getStyleOnType(enumConstants.get('SELECTED'), skin);
    } else if(workflowStatus === enumConstants.get('COMPLETED')) {
        return getStyleOnType(enumConstants.get('COMPLETED'), skin);
    } else if(workflowStatus === enumConstants.get('STARTED')) {
        return getStyleOnType(enumConstants.get('STARTED'), skin);
    } else {
        return getStyleOnType(enumConstants.get('PENDING'), skin);
    }
}

const getStyleOnType = (statusLabel, skin) => {
    const myStyleSheetBackground = StyleSheet.create({
        selected: {
            backgroundColor: skin.get('secondaryColor')
        },
        started: {
            backgroundColor: skin.get('darkSkyBlue')
        },
        completed: {
            backgroundColor: skin.get('darkSkyBlue')
        },
        pending: {
            backgroundColor: skin.get('greyColor4')
        },
        locked: {
            backgroundColor: skin.get('greyColor4')    
        },
        expired: {
            backgroundColor: skin.get('greyColor4')
        }
    });

    switch(statusLabel) {
        case enumConstants.get('SELECTED'):
            return myStyleSheetBackground.selected
        case enumConstants.get('STARTED'):
            return myStyleSheetBackground.started
        case enumConstants.get('PENDING'):
            return myStyleSheetBackground.pending
        case enumConstants.get('LOCKED'):
            return myStyleSheetBackground.locked
        case enumConstants.get('EXPIRED'):
            return myStyleSheetBackground.expired
        case enumConstants.get('COMPLETED'):
            return myStyleSheetBackground.completed
        default:
            return myStyleSheetBackground.pending
    }
}

export default getDotColor;