import React from 'react';
import moment from 'moment';
import 'moment/locale/tr';
import humanizeDuration from 'humanize-duration';
import monthNames from 'constants/months';
import StarComponent from 'svgComponents/starComponent';
import competencyScale from 'constants/competencyScale';
import competencyScaleBackground from 'constants/competencyScaleBackground';
import environment from 'utils/environment';
import { getActiveBatchDetails } from 'utils/batchUtils';
import { getFormattedDate, getDateAndMonthShortName } from 'utils/dates';
import { signOutFromFirebase } from 'utils/signOutFirebase';
import { unregister } from '../../registerServiceWorker';
import { getAmplitudeSessionDetails } from './segmentUtil';
import { getMomentLocale, getWeekdayFromDate, getTimeFromDateString } from 'utils/dates';
import { isMobile } from 'react-device-detect';
import { getOrgCommondbVariant } from 'utils/orgUtils';

const shortEnglishHumanizer = humanizeDuration.humanizer({
    language: "shortEn",
    languages: {
      shortEn: {
        y: () => "yr",
        mo: () => "mth",
        w: () => "wk",
        d: () => "d",
        h: () => "hr",
        m: () => "min",
        s: () => "sec",
        ms: () => "ms",
      },
    },
  });

const renderNumber = (value) => {
    if (value == 0) {
        return value;
    }
    return value.toFixed(2);
}

const checkIfPresent = (value) => {
    if (value === undefined || value === null || value === "") {
        return false;
    }
    return true;
}

const getCompetencyArray = () => {
    return competencyScale.toJS();
}

const getCompetencyColorArray = () => {
    return competencyScaleBackground.toJS();
}

const getCompetencyText = (score, getLabel) => {
    const competencyArray = getCompetencyArray();
    for (var i = 0; i < competencyArray.length; i++) {
        if ((score >= competencyArray[i].min) && (score <= competencyArray[i].max)) {
            return getLabel(competencyArray[i].text);
        }
    }
    if (score === 0) {
        return getLabel(competencyArray[0].text);
    }
    if (score === 5) {
        return competencyArray[competencyArray.length - 1].text;
    }
    return "Not Found";
}

const getCompetencyDescText = (score, getLabel) => {
    const competencyArray = getCompetencyArray();
    for (var i = 0; i < competencyArray.length; i++) {
        if ((score >= competencyArray[i].min) && (score <= competencyArray[i].max)) {
            return getLabel(competencyArray[i].desc);
        }
    }
    if (score === 0) {
        return getLabel(competencyArray[0].desc);
    }
    if (score === 5) {
        return competencyArray[competencyArray.length - 1].desc;
    }
    return "Not Found";
}

const getCompetencyBackground = (score, getLabel) => {
    const competencyArray = getCompetencyColorArray();
    for (var i = 0; i < competencyArray.length; i++) {
        if ((score >= competencyArray[i].min) && (score <= competencyArray[i].max)) {
            return competencyArray[i].color;
        }
    }
    if (score === 0) {
        return competencyArray[0].color;
    }
    if (score === 5) {
        return competencyArray[competencyArray.length - 1].color;
    }
    return "#d44980";
}

const getCompetencyNumber = (score) => {
    const competencyArray = getCompetencyArray();
    if (score === 0) {
        return 1;
    }
    for (var i = 0; i < competencyArray.length; i++) {
        if ((score >= competencyArray[i].min) && (score <= competencyArray[i].max)) {
            return i + 1;
        }
    }
    if (score >= 5) {
        return 5;
    }
    return null;
}

const textTruncate = function (str, length = 80, ending = '...') {
    if (str === null) {
        return '';
    }
    if (length == null) {
        length = 100;
    }
    if (ending == null) {
        ending = '...';
    }
    if (str.length > length) {
        return str.substring(0, length - ending.length) + ending;
    } else {
        return str;
    }
};

const appendQueryParameters = (url, queryParameters = {}) => {
    let queryString = url.indexOf('?') !== -1 ? "&" : "?";

    for (const key in queryParameters) {
        if (queryParameters.hasOwnProperty(key) && queryParameters[key]) {
            queryString += `${key}=${queryParameters[key]}&`;
        }
    }

    queryString = queryString.slice(0, -1);

    return (url + queryString);
}

const convertDate = (dateString = '') => {
    const d = new Date(dateString);

    const date = d.getDate();
    const month = d.getMonth();

    const monthName = monthNames.get(month);

    return `${date} ${monthName}`;
}

const convertDateWithYear = (dateString) => {
    if (!dateString) {
        return '';
    }

    return getFormattedDate(dateString, "short");
}

const convertDateWithMonth = (dateString) => {

    if (!dateString) {
        return '';
    }

    return getDateAndMonthShortName(dateString);
}

const getDayForDate = (dateString = '') => {
   if(dateString === '') return '';
   return getWeekdayFromDate(dateString);
}

const getDateAndMonthSubStringFromDateString = (dateString = '') => {

    if (!dateString) {
        return {
            date: '',
            month: ''
        };
    }

    var d = new Date(dateString);
    var date = d.getDate();
    if (date < 10) {
        date = `0${date}`
    }

    const month = dateString.substring(2, 5);
    return {
        date,
        month
    };
}

const getTimeIn12HrFormatFromDateString = (dateString = '') => {
    if(dateString === '') return '';
    return getTimeFromDateString(dateString);
}

const getStarComponentForRating = (score, scale, key = "star") => {
    // Please pass a value for key if this component is used multiple time in the entire DOM
    let starArray = [];
    for (let i = 0; i < scale; i++) {
        if (score < 0) {
            starArray.push(
                <StarComponent id={`${key}_${i + 1}`} starWidthPerc={0} />
            );
        } else if (score < 1) {
            starArray.push(
                <StarComponent id={`${key}_${i + 1}`} starWidthPerc={score * 100} />
            );
        } else {
            starArray.push(
                <StarComponent id={`${key}_${i + 1}`} starWidthPerc={100} />
            );
        }
        score -= 1;
    }
    return starArray;
}

const getTimeDifference = (time1, time2) => {
    const timeDiff = time2 - time1;
    const diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));

    return {
        weeks: Math.floor(diffDays / 7),
        days: (diffDays % 7),
        hours: Math.floor(timeDiff / (1000 * 60 * 60)) % 24,
        minutes: Math.floor(timeDiff / (1000 * 60)) % 60,
        seconds: Math.floor(timeDiff / (1000)) % 60
    };
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const sortObjectArrayBasedOnKey = (inputArray, key, order = "INCEREMENT") => {
    return inputArray.sort((obj1, obj2) => {
        const value1 = obj1[key];
        const value2 = obj2[key];

        let diff = value1 - value2;
        if (order === "DECREMENT") {
            diff = value2 - value1;
        }

        if (diff > 0) {
            return 1;
        }
        if (diff < 0) {
            return -1;
        }
        return 0;
    });
}

const shouldShowAnalytics = () => {
    if (checkIfPresent(environment.REACT_APP_ENABLE_ANALYTICS) && environment.REACT_APP_ENABLE_ANALYTICS) {
        return environment.REACT_APP_ENABLE_ANALYTICS;
    }
    return false;
}

const shouldShowBatchAnalytics = (batches) => {
    const batchDetails = getActiveBatchDetails(batches);

    if (
        batches.get('isActiveBatchFetched')
        && checkIfPresent(batchDetails.get('journey'))
        && checkIfPresent(batchDetails.get('journey').get('settings'))
        && checkIfPresent(batchDetails.get('journey').get('settings').get('showAnalytics'))
        && (batchDetails.get('journey').get('settings').get('showAnalytics') == 'true')
    ) {
        // console.log("The batch analytics has been set");
        return true;
    }
    // console.log("The batch analytics has been disabled");
    return false;
}

// eslint-disable-next-line no-unused-expressions
const debounce = (a, b, c) => { var d, e; return function () { function h() { d = null, c || (e = a.apply(f, g)) } var f = this, g = arguments; return clearTimeout(d), d = setTimeout(h, b), c && !d && (e = a.apply(f, g)), e } }

const sortObjectOfObjectsBasedOnOrder = (objectToBeSorted) => {
    if (Object.keys(objectToBeSorted).length === 0 && objectToBeSorted.constructor === Object)
        return {}
    let values = Object.values(objectToBeSorted);
    values = values.sort((a, b) => a.order - b.order);
    let result = {};
    values.forEach((value, index) => {
        result[`index${index}`] = value;
    })
    return result;
}

const getTabsArrayForPhases = (phasesList) => {
    let tabsList = [];

    phasesList.toJS().map(phaseWiseData => {
        const phaseName = phaseWiseData.phaseName ?
            phaseWiseData.phaseName :
            `${phaseWiseData.phaseType} ${phaseWiseData.phaseId}`;

        tabsList.push({
            label: phaseName,
            value: phaseWiseData.phaseId
        });

        return phaseWiseData;
    });

    return tabsList;
}

const getQueryParamsObjectFromQueryString = (queryString) => {
    if (queryString === undefined || queryString === "") {
        return {};
    }
    let keys = queryString.slice(1).split('&');
    let queryParamObject = {};
    keys.forEach((key) => {
        key = key.split('=');
        queryParamObject[key[0]] = decodeURIComponent(key[1] || '');
    });
    return queryParamObject;
}

const checkIfValueIsTrue = (value) => {
    if (value === true || value === "true")
        return true;
    return false;
}

const sendLogoutSegmentData = () => {
    let segmentData = {
        referrer: window.location.href,
        pathHash: window.location.hash
    }
    window.analytics.track(
        'Log out clicked',
        segmentData,
        getAmplitudeSessionDetails()
    )
    window.localStorage.removeItem('amplitude_session_id');
    window.analytics.reset();
}

const signOutFromALPlus = (logoutPath = "/logout") => {
    const urlParams = new URLSearchParams(window.location.search);
    let variant = urlParams.get('variant');
    variant = variant || getOrgCommondbVariant();
    console.log('debug: signOutFromALPlus', logoutPath, variant);
    const callback = () => {
        let logoutUrl = `${logoutPath}?variant=${variant}&continueUrl=${window.location.href}`;
        const orgId = window.localStorage.getItem('MSTeamsOrgId');
        if (orgId) {
            logoutUrl = `${logoutUrl}?variant=${variant}&orgId=${orgId}&continueUrl=${window.location.href}`
        }

        window.localStorage.clear(); // clear localstorage on logout
        // Segment events on logout
        sendLogoutSegmentData();

        unregister();//unregister the service worker before logout   
        window.location = logoutUrl;
    }
    console.log('Deleting cache and redirecting...');
    signOutFromFirebase(callback);
}

const openLinkInNewTab = (url, name, specs) => {
    if (!url.match(/^https?:\/\//i)) {
        url = 'https://' + url;
    }
    return window.open(url, name, specs);
}


function getDurationString(timeInSeconds, format="long") {
    timeInSeconds = Number(timeInSeconds);
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor(timeInSeconds % 3600 / 60);
    const seconds = Math.floor(timeInSeconds % 3600 % 60);

    let humanizeFunction = humanizeDuration;
    if(format === 'short'){
        humanizeFunction = shortEnglishHumanizer;
    }

    try {
        return humanizeFunction(moment.duration({
            hours, minutes, seconds
        }).asMilliseconds(), { language: isMobile ? 'shortEn' : getMomentLocale() });
    } catch (e) {
        return "-";
    }
}

function getDurationStringUsingUnits(time, timeUnits) {
    try {
        return humanizeDuration(moment.duration({
            [timeUnits]: time
        }).asMilliseconds(), { language: getMomentLocale() });
    } catch (e) {
        return "-";
    }
}

function hashCreator(dataArray, key = "id") {
    let hashedData = {};
    let newDataArray = Object.assign([], dataArray);

    for (let i = 0; i < newDataArray.length; i++) {
        if (hashedData[newDataArray[i][key]] === null) {
            hashedData[newDataArray[i][key]] = [];
        }
        hashedData[newDataArray[i][key]] = newDataArray[i];
    }
    return hashedData;
}

const checkAndAddAudioControls = (text) => {
    return text.replace('<audio>', "<audio controls='controls' controlsList='nodownload noplaybackrate' class='center-audio'>");
}

export {
    renderNumber,
    checkIfPresent,
    appendQueryParameters,
    convertDate,
    convertDateWithYear,
    convertDateWithMonth,
    textTruncate,
    getDateAndMonthSubStringFromDateString,
    getCompetencyArray,
    getCompetencyText,
    getCompetencyDescText,
    getCompetencyNumber,
    getDayForDate,
    getTimeIn12HrFormatFromDateString,
    getStarComponentForRating,
    getTimeDifference,
    getCompetencyColorArray,
    getCompetencyBackground,
    capitalizeFirstLetter,
    sortObjectArrayBasedOnKey,
    shouldShowAnalytics,
    shouldShowBatchAnalytics,
    debounce,
    sortObjectOfObjectsBasedOnOrder,
    getTabsArrayForPhases,
    getQueryParamsObjectFromQueryString,
    checkIfValueIsTrue,
    signOutFromALPlus,
    openLinkInNewTab,
    getDurationString,
    getDurationStringUsingUnits,
    hashCreator,
    checkAndAddAudioControls
};