import React from 'react';
import CSSModules from 'react-css-modules';
import ReactDOM from 'react-dom';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './mcq.module.sass';
import getSkin from './skin.js';

@applySkin
@CSSModules(styles, { allowMultiple: true })

class QuestionList extends React.Component {

	componentWillReceiveProps(nextProps) {
		let { currentQuestion } = nextProps;
		const domNode = ReactDOM.findDOMNode(this[currentQuestion])
		if (domNode) {
			let ele = document.getElementById('scrollable-questions-cnt')
			// ele.scrollTo(0, domNode.offsetTop - 150)
			ele.scrollTop = domNode.offsetTop - 150;
		}
	}

	setAnswer = (questionId, optionId) => {
		this.props.setQuizQuestionAnswer(questionId, optionId)
	}

	showAnswerOptions = () => {
		let optionsContent = [];
		let styles = getSkin(this.props.skinGuide);
		let { question, questionAnswers } = this.props;
		let options = question.get('answers');
		if (options.size > 0) {
			optionsContent = options.map((option) => {
				let isOptionSelected = false;
				if (questionAnswers && questionAnswers == option.get('id')) {
					isOptionSelected = true;
				}
				return (
					<div key={`question_${question.get('id')}_option_${option.get('id')}`} styleName="option-cnt">
						<div
							onClick={() => this.setAnswer(question.get('id'), option.get('id'))}
							className={isOptionSelected ? css(styles.selectedOptionLabelText) : css(styles.optionLabelText)}
							styleName={isOptionSelected ? "selected-option-text" : "option-text"}
						>
							{
								option.get('text') ?
									option.get('text')
									:
									<div dangerouslySetInnerHTML={{ __html: option.get('html') }}></div>
							}
						</div>
					</div>
				)
			})
		}
		return optionsContent;
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		let { question } = this.props;
		return (
			<div styleName="mcq-cnt" ref={re => { this[`question_${question.get('id')}`] = re }} >
				<div className={css(styles.questionText)} dangerouslySetInnerHTML={{ __html: question.get('actual_question_text') }} styleName="question-text"></div>
				<div styleName="answer-options-cnt">
					{this.showAnswerOptions()}
				</div>
			</div>
		)
	}
}

export default QuestionList;