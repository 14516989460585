import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GenericErrorBoundary from 'components/errorBoundaries';
import {
	fetchOrganizationWithCode
} from 'actions/userJourneys/apiActions';

import { getQueryParamsObjectFromQueryString } from 'utils/utilFunctions';

import PublicJourneyCatalogue from 'components/publicJourneyCatalogue'

class JourneyCatalogContainer extends React.Component {

	componentDidMount() {
		this.props.fetchOrganizationWithCode(this.props.orgCode);
	}

	render() {
		return (
			<GenericErrorBoundary>
				<PublicJourneyCatalogue {...this.props} />
			</GenericErrorBoundary>

		);
	}
}

JourneyCatalogContainer.propTypes = {};

JourneyCatalogContainer.defaultProps = {};


const mapStateToProps = (state) => {
	return ({
		userOrganization: state.get('userOrganization')
	});
};

const mapDispatchToProps = (dispatch) => ({
	fetchOrganizationWithCode: (orgCode = "") => {
		dispatch(fetchOrganizationWithCode(orgCode))
	}
});

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(JourneyCatalogContainer));
