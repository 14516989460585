import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules({ allowMultiple: true })
class ReportSvg extends Component {

    render() {
        const skinHex = this.props.skinGuide.get('skinHex');
        return (
            <svg width="100%" height="100%" viewBox="0 0 34 40">
                <g fill={this.props.fillColor} fill-rule="nonzero">
                    <path d="M24.155.488A1.667 1.667 0 0 0 22.977 0H3.667A3.333 3.333 0 0 0 .333 3.333v33.334A3.333 3.333 0 0 0 3.667 40h26.666a3.333 3.333 0 0 0 3.334-3.333V10.69c0-.442-.176-.866-.489-1.178L24.155.488zm6.178 35.345c0 .46-.373.834-.833.834h-25a.833.833 0 0 1-.833-.834V4.167c0-.46.373-.834.833-.834h17.442c.22 0 .432.088.588.244l7.56 7.56a.833.833 0 0 1 .243.588v24.108z"/>
                    <path d="M10.235 24.433l4.293-4.291a.417.417 0 0 1 .5-.064l2.774 1.667c.656.394 1.496.29 2.036-.252l5.329-5.315a1.667 1.667 0 0 0-2.357-2.356l-4.167 4.166a.413.413 0 0 1-.5.062l-2.773-1.667a1.667 1.667 0 0 0-2.035.25l-5.457 5.444a1.667 1.667 0 0 0 2.357 2.356zM24.917 27.397H8.667a1.667 1.667 0 1 0 0 3.333h16.25a1.667 1.667 0 0 0 0-3.333z"/>
                </g>
            </svg>
        );
    }
}



export default ReportSvg;
