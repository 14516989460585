import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import AdditionalInformation from 'components/moduleItems/additionalInformation';

import styles from './scormContent.module.sass';

import applyLabel from 'utils/LabelProvider';
import ScormEmbedded from './ScormEmbedded';
import ModuleItemFullScreen from 'components/moduleItems/moduleItemFullScreen';
import DefaultLoader from 'commonComponents/loaders/defaultLoader/DefaultLoader';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ScormContent extends Component {

    constructor(props) {
        super();
        this.state = {
            isLaunched: false,
            launching: false
        };
        props.unsetProductLaunch();
    }

    componentWillMount() {
        this.props.unsetProductLaunch();
    }

    componentDidMount() {
        let completion_requirement = this.props.moduleItem.get('moduleItem').get('completion_requirement');
        if (completion_requirement && completion_requirement.size && completion_requirement.get('type') == 'must_view') {
            this.props.markRead();
        }       
       
        window.adCloseWindow =  () => {
            console.log(' window.adCloseWindow ')
            this.onExit();
        }
        
    }

    sendSegmentData = () => {
        let segmentData = {
            ...(this.props.match.params),
            referrer: window.location.href,
            pathHash: window.location.hash
        }
        window.analytics.track(
            'SCORM Content Opened',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onClickLaunch = () => {
        this.sendSegmentData();
        this.setState({
            launching: true
        }, () => {
            this.props.fetchProductLaunch();
        });
    }

    onExit = () => {
        this.setState({
            isLaunched: false
        }, () => {
            this.props.unsetProductLaunch();
        });
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.moduleItem.get('productLaunch') && nextProps.moduleItem.get('productLaunch')) {
            this.setState({
                isLaunched: true,
                launching: false
            });
        }

        if (nextProps.launchError) {
            this.setState({
                isLaunched: false,
                launching: false
            });
        }
    }

    render() {
        const { moduleItem, getLabelWithDefaults, userState, getLabel } = this.props;
        const { isLaunched, launching } = this.state;
        let showPlatformExitButton = moduleItem.get('contentDetails').get('al_data').get('show_platform_exit_button');

        if(typeof showPlatformExitButton === "undefined") showPlatformExitButton = true; // the exit button is needed by default

        if (!moduleItem.get('contentDetails').get('al_data').get('launch_in_full_screen')) {
            return <ScormEmbedded {...this.props} showPlatformExitButton={showPlatformExitButton}/>
        }

        let launchButtonText = getLabelWithDefaults('launch_content_label', "Launch Content");

        if (moduleItem.get('contentDetails') && moduleItem.get('contentDetails').get('submission')) {
            if (moduleItem.get('contentDetails').get('submission').get('workflow_state') !== 'unsubmitted') {
                launchButtonText = getLabelWithDefaults('resume_content_label', "Resume");
            }
        }

        let completion_requirement = this.props.moduleItem.get('moduleItem').get('completion_requirement');

        if (completion_requirement.get('completed')) {
            launchButtonText = getLabelWithDefaults('replay_content_label', "Re-play");
        }

        return (
            <div>
                <AdditionalInformation
                    title={getLabelWithDefaults('yaradditonal_information_label', 'Additional Information')}
                    body={moduleItem.get('moduleItem').get('description')} />
                {
                    launching ?
                        < div styleName="loader-cnt">
                            <DefaultLoader loaderText={getLabel('loading_content_label')} />
                        </div>
                        :
                        <div styleName="launch-btn-cnt">
                            <PrimaryButton text={launchButtonText} clickFunction={this.onClickLaunch} />
                        </div>
                }

                {/* beyond this: to launch content in full screen */}
                {
                    isLaunched ?
                        <ModuleItemFullScreen
                            title={moduleItem.get('moduleItem').get('title')}
                            src={moduleItem.get('productLaunch').get('launch_url')}
                            onExit={this.onExit.bind(this)}
                            userState={userState}
                            showPlatformExitButton={showPlatformExitButton}
                        />
                        : null
                }
            </div>
        )
    }
}

export default ScormContent;