import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

	return StyleSheet.create({
        toggleContainer: {
            ...myTypography.caption,
            background: 'rgba(255, 255, 255, 0.08)',
            color: skin.get('white')
        }
	});
}

export default getSkin;