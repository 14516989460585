import React, { Component } from 'react'

export default class Next extends Component {
  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" viewBox="0 0 12 18">
            <path fill="#FFF" fillRule="nonzero" d="M11.273 8.979c0 .497-.215.97-.588 1.299l-8.361 7.355a1.385 1.385 0 1 1-1.83-2.08L7.81 9.12a.187.187 0 0 0 0-.282L.495 2.404A1.385 1.385 0 0 1 2.324.324l8.364 7.358c.372.328.585.8.585 1.297z"/>
        </svg>

    )
  }
}
