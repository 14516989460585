import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        offlineBarContainer: {
            backgroundColor: '#4d4d4d'
        },
        offlineBarContent: {
            ...myTypography.button,
            fontStyle: 'normal',
        },
        white: {
            color: skin.get('white')
        },
        whiteOpacity: {
            color: hexToRgbA(skin.get('white'), 0.5)
        },
        tryNowButton: {
            color: skin.get('secondaryColor'),
            ...myTypography.button,
            ':hover': {
                textDecoration: 'underline'
            }
        }
	});
}

export default getSkin;