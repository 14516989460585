/**
 * @author: sourabh p
 * @since: Thu Aug 23 2018 18:20:31 GMT+0530 (IST)
 * @file: phases.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * phases reducer is responsible for phases related actions
 *
 **/

import Immutable from 'immutable';
import actionConsts from 'constants/actions';
import { setOrUpdateObjectInList, mergeListsBasedOnKey } from 'utils/objectUtils';

const initialState = Immutable.fromJS({
    phasesList: [],
    userPhasesList: [],
    activePhaseId: -1,
    phasesListAdditionalDetails: {},
    userPhasesListAdditionalDetails: {},
    phaseIdFromRoute: 1,
    isPhasesListFetched: false,
    isUserPhasesListFetched: false
});

const phases = (state = initialState, action = {}) => {
    switch (action.type) {
        case actionConsts.get('SET_PHASES_LIST'):

            return state.set(
                'phasesList',
                mergeListsBasedOnKey(state.get('phasesList'), action.payload.phasesList, 'id')
            ).set(
                'phasesListAdditionalDetails',
                Immutable.fromJS(action.payload.phasesListAdditionalDetails)
            ).set(
                'isPhasesListFetched', true
            );
        case actionConsts.get('SET_USER_PHASES_LIST'):
            return state.set(
                'userPhasesList',
                mergeListsBasedOnKey(
                    state.get('userPhasesList'), action.payload.userPhasesList, 'id'
                )
            ).set(
                'userPhasesListAdditionalDetails',
                Immutable.fromJS(action.payload.userPhasesListAdditionalDetails)
            );
        case actionConsts.get('SET_ACTIVE_PHASE_ID'):
            return state.set(
                'activePhaseId', action.activePhaseId
            ).set(
                'phaseIdFromRoute', action.activePhaseId
            );
        case actionConsts.get('SET_ACTIVE_BATCH_AND_PHASE_ID'):
            return state.set(
                'activePhaseId', action.payload.activePhaseId
            );
        case actionConsts.get('UPDATE_PHASE_DETAILED_DATA'):
            return state.set(
                'phasesList',
                setOrUpdateObjectInList(state.get('phasesList'), action.payload, 'id', 'id')
            );
        case actionConsts.get('UPDATE_USER_PHASE_DETAILED_DATA'):
            return state.set(
                'userPhasesList',
                setOrUpdateObjectInList(state.get('userPhasesList'), action.payload, 'id', 'id')
            );
        case actionConsts.get('SET_MEASURES_LIST'):
            const updatedMeasuresListFlagForPhase = {
                id: action.payload.phaseId,
                isMeasuresListAvailable: true
            };
            return state.set(
                'phasesList',
                setOrUpdateObjectInList(
                    state.get('phasesList'), updatedMeasuresListFlagForPhase, 'id', 'id'
                )
            );
        case actionConsts.get('SET_USER_MEASURES_LIST'):
            const updatedUserMeasuresListFlagForPhase = {
                id: action.payload.phaseId,
                isUserMeasuresListAvailable: true
            };
            return state.set(
                'phasesList',
                setOrUpdateObjectInList(
                    state.get('phasesList'), updatedUserMeasuresListFlagForPhase, 'id', 'id'
                )
            );
        default:
            return state;
    }
};

export default phases;