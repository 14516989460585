import React, { Component } from 'react';
import styles from './videoOverlay.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import VideoPlayer from 'commonComponents/contentPlayers/videoPlayer';
import BackArrow from 'svgComponents/backArrow';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class VideoOverlay extends Component {

	constructor(props) {
		super(props);
	}

	render() {
		const { journeyId, journeyName, videoLink } = this.props;
		const kalturaEntryId = videoLink;
		const title = journeyName;
		const config = { kalturaEntryId };
		const myStyles = getSkin(this.props.skinGuide);

		return (
			<div styleName="video-overlay-container"
				onClick={this.props.closeVideo}>
				<div styleName="menu-title">
					<div styleName="icon"><BackArrow color={'white'} /></div>
					<div styleName="journey-title" className={css(myStyles.title)}>{title}</div>
				</div>
				<div styleName="video">
					<VideoPlayer
						config={config}
						playerHeight={'80vh'}
						playerWidth={'80vw'}
						key={`journey_video_${journeyId}`}
						videoPlayerTargetId='journey-popup-video-player-container'					
					/>
				</div>
			</div>
		);

	}
}

export default VideoOverlay;