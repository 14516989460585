/**
 * @author: Amul
 * @since: Thu Oct 4 2018 17:22:31 GMT+0530 (IST)
 * @file: nextActivityItem.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/


import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
    nextActivityItem: null,
    isNextItemActivityAvailable: false,
    fetching: false
});

const course = (state = initialState, action) => {
    switch (action.type) {
        case actionConstants.get('SET_NEXT_ACTIVITY_ITEM'):
             
            return state.set(
                'nextActivityItem',
                Immutable.fromJS(action.nextActivityItem)
            ).set(
                'isNextItemActivityAvailable',
                true
            ).set(
                'fetching',
                false
            );
        case actionConstants.get('SET_NEXT_ACTIVITY_ITEM_AVAILABILITY'):
            return state.set('isNextItemActivityAvailable', action.status);
        case actionConstants.get('SET_NEXT_ACTIVITY_ITEM_FETCHING'):
            return state.set('fetching', action.status);
        default:
            return state;
    }
}

export default course;