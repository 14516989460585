import React, { Component } from 'react';
import styles from './userError.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import Footer from 'commonComponents/footer';
import ErrorNotFound from 'svgComponents/errorNotFound';
import ErrorSomethingWrong from 'svgComponents/errorSomethingWrong';

import UserOrganizationsList from 'components/userProfile/UserOrganizationsList';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class UserError extends Component {

	onClickGoToHome = () => {
		this.props.logout();
	}

	getErrorPageDetails = (type) => {
		const { userError } = this.props;
		switch (userError.get('error_code')) {
			case "USER_NOT_FOUND":
			case "NO_USERS_FOUND_IN_BATCH":
			case "BATCH_USERS_NOT_FOUND":
			case "USER_NOT_LOGGED_IN":
			case 'BATCH_NOT_FOUND':
			case 'BATCHES_NOT_FOUND':
				return {
					heading: "No Course Found",
					descMain: "We could not find any courses assigned to your email ID. Please contact your admin or write to us at info@aktivlearn.com",
					descSub: "",//"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
					imageComponent: ErrorNotFound
				};
			case 'USER_SUSPENDED_FROM_THIS_BATCH':
				return {
					heading: "Oops!",
					descMain: "Looks like you have been suspended from this course.",
					descSub: "",//"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
					imageComponent: ErrorNotFound
				};
			case 'ORGANIZATION_NOT_FOUND':
				return {
					heading: "Oops!",
					descMain: "Looks like you are not part of any organization.",
					imageComponent: ErrorNotFound
				};
			case 'USER_SUSPENDED_FROM_ORGANIZATION':
				return {
					heading: "Oops!",
					descMain: "Looks like your subscription is deactivated. Please contact admin to reactivate it.",
					imageComponent: ErrorNotFound
				};
			case 'DEAL_EXPIRED':
				return {
					heading: "Oops!",
					descMain: "Your subscription to this organization has expired. You can switch to another organization (if available below) to continue your learning journey.",
					imageComponent: ErrorNotFound
				};
			case 'DEAL_UPCOMING':
				return {
					heading: "Oops!",
					descMain: "Your access to this organization is not active yet.  You can switch to another organization (if available below) to continue your learning journey.",
					imageComponent: ErrorNotFound
				};
			case "USER_NOT_FOUND_IN_BATCH":
				return {
					heading: "No access",
					descMain: "You can't access this course as you aren't enrolled into it.",
					descSub: "",//"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
					imageComponent: ErrorNotFound
				};
			case 'USER_DOES_NOT_EXIST':
				return {
					heading: "Oops!",
					descMain: "You don't have access to Aktivlearn Plus because you aren't enrolled to a course.",
					imageComponent: ErrorNotFound
				};
			default:
				console.log('error_code2');
				return {
					heading: "Oops!",
					descMain: "Something went wrong.",
					descSub: "",//"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
					imageComponent: ErrorSomethingWrong
				};
		}
	}

	renderResolution = () => {
		const { userError } = this.props;
		const myStyles = getSkin(this.props.skinGuide);

		switch(userError.get('error_code')){
			case 'DEAL_UPCOMING':
			case 'DEAL_EXPIRED':
				return  <div styleName='org-list-cnt'>
					<UserOrganizationsList
						userOrganization={this.props.userOrganization}
						isChildOfUserError={true}
					/>
				</div>;					
			default: return <div
				className={css(myStyles.buttonContainer)}
				styleName="button-container"
				onClick={this.onClickGoToHome}
			>
				Sign Out
			</div>;
		}
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const pageDetails = this.getErrorPageDetails("SOMETHING_WRONG");


		return (
			<div styleName="complete-container" style={this.props.componentStyles}>
				<div styleName="error-container">
					<div styleName="error-content">
						<div styleName="error-image-container">
							<div styleName="error-image">
								<pageDetails.imageComponent />
							</div>
						</div>
						<div styleName="error-message-container">
							<div className={css(myStyles.errorHeading)} styleName="error-heading">{pageDetails.heading}</div>
							<div className={css(myStyles.errorDesc)} styleName="error-main-desc">{pageDetails.descMain}</div>
							{pageDetails.descSub && <div className={css(myStyles.errorDesc)} styleName="error-sub-desc">{pageDetails.descSub}</div>}
							{this.renderResolution()}
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default UserError;