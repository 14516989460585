import React, { Component } from 'react';
import styles from './lessThanTypeArrow.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LessThanTypeArrow extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		const fillColor = this.props.svgColor || skinHex.get('secondaryColor');

		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 7 11">
					<path fill={fillColor} fillRule="nonzero" d="M0 5.487c0-.303.132-.592.363-.792L5.557.198a.87.87 0 0 1 1.199.09c.308.345.28.87-.063 1.181L2.15 5.401a.114.114 0 0 0 0 .172l4.542 3.932a.84.84 0 0 1-.284 1.453.87.87 0 0 1-.852-.182L.365 6.28C.133 6.08 0 5.79 0 5.487z" />
				</svg>
			</div>
		);
	}
}

export default LessThanTypeArrow;