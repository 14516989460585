import React, { Component } from 'react';
import AdditionalInformation from 'components/moduleItems/additionalInformation';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import Duration from 'commonComponents/duration';
import styles from './simulation.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import ModuleItemFullScreen from 'components/moduleItems/moduleItemFullScreen';
import DefaultLoader from 'commonComponents/loaders/defaultLoader/DefaultLoader';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Simulation extends Component {
    constructor(props) {
        super();
        this.state = {
            isFull: false,
            isLaunched: false,
            launching: false
        };
        props.unsetProductLaunch();
    }

    componentDidMount() {
        if (!window.adSimCloseWindow) {
            window.adSimCloseWindow = () => {
                console.log('d sim close window');
                this.onExit();
            }
        }

        let completion_requirement = this.props.moduleItem.get('moduleItem').get('completion_requirement');
        if (completion_requirement && completion_requirement.size && completion_requirement.get('type') == 'must_view') {
            this.props.markRead();
        }
    }

    componentWillUnmount() {
        window.adSimCloseWindow = null;
    }


    toggleFull = (value) => {
        this.setState({ isFull: value });
    }

    sendSegmentData = () => {
        let segmentData = {
            ...(this.props.match.params),
            referrer: window.location.href,
            pathHash: window.location.hash
        }
        window.analytics.track(
            'AL Simulation Opened',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onClickLaunch = () => {
        this.sendSegmentData();
        this.setState({
            launching: true
        }, () => {
            this.props.fetchProductLaunch();
        });
    }

    onExit = () => {
        this.setState({
            isFull: false,
            isLaunched: false
        }, () => {
            this.props.unsetProductLaunch();
        });
    }

    componentWillReceiveProps(nextProps) {
        if (!this.props.moduleItem.get('productLaunch') && nextProps.moduleItem.get('productLaunch')) {
            this.setState({
                isFull: false,
                isLaunched: true,
                launching: false
            });
        }

        if (nextProps.launchError) {
            this.setState({
                isFull: false,
                isLaunched: false,
                launching: false
            });
        }
    }

    render() {
        const { moduleItem, tools, isSidebarOpen, getLabelWithDefaults, nextItemDetailsForFeedback, getLabel } = this.props;
        const { isFull, isLaunched, launching } = this.state;

        let fullScreenButton = <div styleName="option"
            onClick={this.toggleFull.bind(this, true)}>
            Full Screen
        </div>;

        if (isFull) {
            fullScreenButton = <div styleName="option" onClick={this.toggleFull.bind(this, false)}>Leave Full Screen</div>;
        }
        this.props.match.params.itemId = this.props.match.params.moduleItemId


        let simDuration, durationUnit = null;

        if (moduleItem.get('moduleItem').get('duration')) {
            simDuration = moduleItem.get('moduleItem').get('duration');
            durationUnit = moduleItem.get('moduleItem').get('duration_unit');
        }

        let launchButtonText = getLabelWithDefaults('launch_simulation_label', "Open Simulation");

        if (moduleItem.get('contentDetails') && moduleItem.get('contentDetails').get('submission')) {
            if (moduleItem.get('contentDetails').get('submission').get('workflow_state') != 'unsubmitted') {
                launchButtonText = getLabelWithDefaults('resume_simulation_label', "Resume Simulation");
            }
        }

        let completion_requirement = this.props.moduleItem.get('moduleItem').get('completion_requirement');

        if (completion_requirement.get('completed')) {
            launchButtonText = getLabelWithDefaults('replay_simulation_label', "Open Simulation");
        }

        return (
            <div>
                <div styleName={`row ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                    <div styleName="details">
                        <AdditionalInformation
                            title={getLabelWithDefaults('details_heading_label', "Details")}
                            body={moduleItem.get('moduleItem').get('description')}
                        />

                        {
                            isSidebarOpen ?
                                <div styleName="side-items">
                                    {
                                        simDuration && <div styleName="duration-cnt">
                                            <Duration time={simDuration} durationUnit={durationUnit} title={getLabelWithDefaults('duration_label', 'Duration')} />
                                        </div>
                                    }
                                    <div styleName="launch-btn-cnt">
                                        {
                                            launching ?
                                                <div styleName="loader-cnt">
                                                    <DefaultLoader loaderText={getLabel('loading_simulation_label')} />
                                                </div>
                                                :
                                                <PrimaryButton text={launchButtonText} clickFunction={this.onClickLaunch} />
                                        }
                                    </div>
                                </div> : null
                        }
                    </div>

                    {
                        isSidebarOpen ? null :
                            <div styleName="side-items">
                                {
                                    simDuration && <Duration time={simDuration} durationUnit={durationUnit} text={durationUnit} title={getLabelWithDefaults('duration_label', 'Duration')}
                                    />
                                }
                                {
                                    launching ? <div styleName="loader-cnt">
                                        <DefaultLoader loaderText={getLabel('loading_simulation_label')} />
                                    </div>
                                        : <PrimaryButton text={getLabelWithDefaults('launch_simulation_label', "Launch Simulation")} clickFunction={this.onClickLaunch} />
                                }
                            </div>
                    }

                </div>

                {/* beyond this: to launch content in full screen */}
                {
                    isLaunched ?
                        <ModuleItemFullScreen
                            title={moduleItem.get('moduleItem').get('title')}
                            src={moduleItem.get('productLaunch').get('webLaunchUrl')}
                            onExit={this.onExit.bind(this)}
                            isSimOrAssessment={true}
                        />
                        : null
                }


            </div>
        )
    }
}

export default Simulation;