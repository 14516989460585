import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const skinHex = skin.get('skinHex');
	const myTypography = typography(skin);
	return StyleSheet.create({
		metadataCnt: {
			borderRight: `solid 1px ${hexToRgbA('#d5d8e2', 0.5)}`,
			borderLeft: `solid 1px ${hexToRgbA('#d5d8e2', 0.5)}`
		},
		metadataHeader: {
			...myTypography.button,
			color: hexToRgbA('#5b5c61', 0.8),
			fontWeight: 'normal',
			borderBottom: `solid 1px ${hexToRgbA('#7c7d81', 0.6)}`
		},
		metadataContent: {
			...myTypography.h6,
			color: '#5b5c61'
		},
		bgTempImage: {
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center'
		},
		extraDetailsDesc: {
			...myTypography.body1,
			color: hexToRgbA('#000000', 0.7),
			lineHeight: 1.56
		}
	});
}

export default getSkin;