import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './quizDashboard.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import applyLabel from 'utils/LabelProvider';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import QuizScore from './quizScore';
import ScoreComponent from 'commonComponents/scoreComponent';
import { hexToRgbA } from 'utils/styleUtil';
import AdditionalInformation from 'components/moduleItems/additionalInformation';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class QuizDashboard extends Component {
    constructor(props) {
        super(props);
        let timeLeft = this.getRemainingTime();
        timeLeft = timeLeft ? timeLeft : 0;
        this.state = {
            timeLeft: timeLeft
        }
        this.quizTimerInterval = null;
    }

    showTimeLeft = () => {
        let min = 0;
        let sec = 0;
        const { timeLeft } = this.state;
        if (timeLeft) {
            min = parseInt(timeLeft / 60);
            sec = timeLeft % 60;
        }
        if (min < 100) {
            min = ('00' + min).slice(-2)
        }
        sec = ('00' + sec).slice(-2)
        return { min, sec }
    }


    getRemainingTime = () => {
        const { quizResponseData, quizData } = this.props;

        if (quizResponseData.get('remTimeInSec') !== undefined) {
            return Math.abs(quizResponseData.get('remTimeInSec'));
        }
    }

    componentDidMount() {
        const { quizData } = this.props;
        if (
            quizData.getIn(['dataWithoutAnswers', 'maxTimeToFinish'])
        ) {
            this.updateTimeLeft();
        }
    }

    componentWillUnmount() {
        clearInterval(this.quizTimerInterval)
    }


    getPayloadToSubmitQuiz = () => {
        const { quizId, quizResponseData } = this.props;
        const payload = {
            result: quizResponseData.getIn(['quizResultData', 'result']).toJS(),
            quizId
        };
        return payload;
    }

    updateTimeLeft = () => {
        this.quizTimerInterval = setInterval(() => {
            if (this.state.timeLeft) {
                let timeLeft = this.state.timeLeft - 1;
                if (timeLeft === 0) {
                    this.setState({
                        showConfirmationPopup: true,
                        popupMessage: 'Your Time is up! Please Wait, submitting your answers.',
                        popupOkAction: null,
                        popupCancelAction: null,
                        isComponentLoading: true,
                        timeLeft
                    }, () => {
                        clearInterval(this.quizTimerInterval);
                        const { batchId, phaseItemId } = this.props;
                        const payload = this.getPayloadToSubmitQuiz();
                        this.props.submitQuiz(payload, batchId, phaseItemId);
                    });
                } else {
                    this.setState({
                        timeLeft
                    })
                }
            }
        }, 1000)
    }

    onStartQuiz = (isCurrentAttemptIsInProgress = false, isFirstAttempt = false) => {

        const { batchId, phaseItemId, quizId, onSetItemWorkFlowStateStart } = this.props;

        const route = routes.get('BATCH_SURVEY_QUIZ_TAKE');

        // this.sendSegmentData();
        const params = [
            { 'BATCH_ID': batchId },
            { '_PHASEITEMID_': phaseItemId }
        ]

        updateRoute({ route, params });


    }


    render() {
        let styles = getSkin(this.props.skinGuide);
        let { quizData, quizResponseData, getLabelWithDefaults, hashedQuestions, fetchingUserQuizSubmissions, submissions, instructions, isSidebarOpen, lockDetails } = this.props;
        const quizTimer = quizData.get('dataWithoutAnswers').get('maxTimeToFinish');
        const isTimerQuiz = quizTimer ? true: false;
        const attemptsLeft = quizData.get('maxAttempt') - (quizResponseData.get('attemptsAlreadyTaken') ? quizResponseData.get('attemptsAlreadyTaken') : 0);
        const atLeastOneAttemptPlayed = attemptsLeft < quizData.get('maxAttempt') ? true : false;
        const currentAttemptIsInProgress = quizResponseData.get('remTimeInSec') ||
            (!isTimerQuiz && (quizResponseData.getIn(['quizResultData','status'])==='started'))  ? true : false;
        const userMaxScore = quizResponseData.get('userMaxScore');
        console.log(attemptsLeft, atLeastOneAttemptPlayed, currentAttemptIsInProgress)
        let quizTime = this.showTimeLeft();
        return (
            <div styleName='quiz-score-btn-cnt'>
                <div styleName="dev-row">
                    {
                        currentAttemptIsInProgress ?
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
                                <ScoreComponent
                                    score={userMaxScore}
                                    scoreColor={this.props.skinGuide.get('skinHex').get('secondaryColor')}
                                    totalColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.5)}
                                    circleStrokeColor={this.props.skinGuide.get('skinHex').get('greyColor2')}
                                    fullCircleStrokeColor={hexToRgbA(this.props.skinGuide.get('skinHex').get('greyColor2'), 0.2)}
                                    maxPoints={quizData.get('maxScore')} />
                            </div>
                            : null
                    }
                    {
                        quizResponseData.get('attemptsAlreadyTaken') !== undefined && quizResponseData.get('attemptsAlreadyTaken') === 0 ?
                            <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
                                <span
                                    title={`You can earn a max of ${quizData.get('maxScore')} points in this content.`}>
                                    <div styleName="points">
                                        {quizData.get('maxScore')}
                                    </div>
                                    <div styleName="text">
                                        {getLabelWithDefaults('points_label', 'Points')}
                                    </div>
                                </span>
                            </div> : null
                    }
                    {
                        currentAttemptIsInProgress ?
                            <div className={css(styles.incompleteSubmissionScoreCnt)} styleName="incomplete-submission-score-cnt">
                                <div styleName="ongoing-attempt-text">
                                    {getLabelWithDefaults('quiz_resume_message_label', 'We see that you have not submitted your quiz. Please resume.')}
                                </div>
                                <div>
                                    {
                                        isTimerQuiz &&
                                        this.state.timeLeft <= 3 ?
                                            <PrimaryButton
                                                text={getLabelWithDefaults('quiz_resume_label', 'RESUME QUIZ')}
                                                clickFunction={() => this.onStartQuiz(true)}
                                                disabled={true}
                                            />
                                            :
                                            <PrimaryButton
                                                text={getLabelWithDefaults('quiz_resume_label', 'RESUME QUIZ')}
                                                clickFunction={() => this.onStartQuiz(true)}
                                            />
                                    }
                                    {
                                        isTimerQuiz ?
                                        <div className={css(styles.timerTime)} styleName="timer-time">
                                            <div>
                                                {
                                                    getLabelWithDefaults(
                                                        `quiz_time_remaining_label`,
                                                        `Time remaining to resume ${(quizTime.min)}:${(quizTime.sec)}`,
                                                        {
                                                            min: quizTime.min,
                                                            sec: quizTime.sec
                                                        }
                                                    )
                                                }
                                            </div>
                                            <div>
                                                {
                                                    this.state.timeLeft <= 3 ?
                                                        getLabelWithDefaults(
                                                            'quiz_wait_submiting_quiz_label',
                                                            "Please wait, submitting this attempt..."
                                                        )
                                                        : null
                                                }
                                            </div>
                                        </div>
                                        :null
                                    }
                                </div>
                            </div>
                            :
                            userMaxScore !== undefined && userMaxScore !== null &&
                            <QuizScore quizData={quizData} quizResponseData={quizResponseData} fetchingUserQuizSubmissions={fetchingUserQuizSubmissions}
                                submissions={submissions} />

                    }
                    <div styleName={`quiz-content-column${isSidebarOpen ? ' sidebar-open' : ''}`}>
                        <AdditionalInformation
                            title={getLabelWithDefaults('quiz_additional_information_label', 'Additional Information')}
                            body={instructions} />
                    </div>
                    <div className={css(styles.quizDetailsColumn)} styleName={`quiz-details-column${isSidebarOpen ? ' sidebar-open' : ''}`} style={{ textAlign: 'left' }}>
                        <div styleName="quiz-details">
                            <div className={css(styles.quizDetailsHeader)}>
                                {getLabelWithDefaults('quiz_details_label', 'QUIZ DETAILS')}
                            </div>
                            <div className={css(styles.quizDetailsInnerCnt)} styleName="quiz-details-inner-cnt">
                                <div styleName="align-items-center">
                                    <span style={{ marginLeft: '8px' }}>
                                        {getLabelWithDefaults('questions_count_label', `${Object.keys(hashedQuestions.toJS()).length} Questions`, { count: Object.keys(hashedQuestions.toJS()).length })}
                                    </span>
                                </div>
                            </div>
                            {
                                attemptsLeft != null &&
                                <div className={css(styles.attemptsLeft)} styleName="attempts-left">
                                    {
                                        getLabelWithDefaults('attempts left_label', `${attemptsLeft == 0 ? 'No' : attemptsLeft} more attempts left`, { count: attemptsLeft })
                                    }
                                </div>
                            }
                        </div>

                        <div styleName="button-cnt">
                            {
                                !lockDetails.get('locked') ?

                                    !currentAttemptIsInProgress ?
                                        <div>
                                            {atLeastOneAttemptPlayed && !currentAttemptIsInProgress
                                                ? <PrimaryButton text={getLabelWithDefaults('retake_quiz_label', 'RETAKE QUIZ')}
                                                    disabled={!attemptsLeft ? true : false}
                                                    clickFunction={() => this.onStartQuiz()} />
                                                : null}
                                            {!atLeastOneAttemptPlayed && !currentAttemptIsInProgress
                                                ? <PrimaryButton text={getLabelWithDefaults('take_quiz_label', 'TAKE QUIZ')}
                                                    disabled={!attemptsLeft ? true : false}

                                                    clickFunction={() => this.onStartQuiz(false, true)} />
                                                : null}
                                        </div>
                                        : null
                                    :
                                    <span>{lockDetails.get('lock_explanation')}</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuizDashboard;