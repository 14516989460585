export const getUrlParams = url => `${url}?`.split('?')[1]
  .split('&').reduce((params, pair) =>
    ((key, val) => key ? {...params, [key]: val} : params)
    (...`${pair}=`.split('=').map(decodeURIComponent)), {});

export const getAppStateFromUrl = () => {
    try{
        const queryParams = getUrlParams(window.location.href);
        if(queryParams['appstate']){
            const appStateString = decodeURIComponent(queryParams['appstate']);
            console.log('appStateString', appStateString);
            const appState = JSON.parse(appStateString.split('#')[0]);
            console.log('appState', appState);
            return appState;
        }
    } catch(error){
        console.log('error', error);
    }

    return null;
}

export const addParameterToURL = (_url, param) => {
    _url += (_url.split('?')[1] ? '&':'?') + param;
    return _url;
}

export const addParametersToUrl = (_url, params = {}) => {
    Object.keys(params).forEach((paramKey) => {
        _url = addParameterToURL(_url, `${paramKey}=${params[paramKey]}`);
    });
    return _url;
}