import GenerateReport from 'actions/serverCalls/reports/GenerateReport';
import GenerateSharableReportLink from 'actions/serverCalls/reports/GenerateSharableReportLink';
import CheckReportStatus from 'actions/serverCalls/reports/CheckReportStatus';

const pollingInterval = 1000; // poll every second

const generateReport = (payload) => {

    payload = {
        body: payload,
        headers: {
            'Content-type': 'application/json'
        }
    };

    GenerateReport.call(payload).then((response) => {
        let reportInterval = window.setInterval(() => {
            CheckReportStatus.call({
                taskId: response.taskId,
                reportType: payload.body.reportType,
                pollInterval: reportInterval,
                batchId: payload.body.metaData.batchId
            });
        }, pollingInterval);
    });

}

const generateSharableReportLink = (payload) => {
    payload = {
        body: payload,
        headers: {
            'Content-type': 'application/json'
        }
    };
    GenerateSharableReportLink.call(payload);
}

export {
    generateReport,
    generateSharableReportLink
};