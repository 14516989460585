import React, { Component } from 'react';
import styles from './bitesIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class BitesIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			// <div >
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 16 16">
					<g fill="none" fillRule="evenodd" stroke="#7E818F" strokeLinecap="round" strokeLinejoin="round">
						<path d="M14.708 1.875a.583.583 0 0 0-.583-.583h-10.5a.583.583 0 0 0-.583.583v10.5c0 .322.26.583.583.583h10.5a.583.583 0 0 0 .583-.583v-10.5z"/>
						<path d="M3.042 3.625H1.875a.583.583 0 0 0-.583.583v9.917c0 .322.26.583.583.583h9.917a.583.583 0 0 0 .583-.583v-1.167"/>
					</g>
				</svg>
			// </div>
		);
	}
}

export default BitesIcon;
