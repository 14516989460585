import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './legendComponent.module.sass';


@CSSModules(styles, { allowMultiple: true })

class LegendComponent extends React.Component {

    render(){
        const fontStyling = {
            fontSize: '20px',
            color: 'rgba(151, 158, 183, 0.3)',
            fontWeight: 600
        }

        const legendWidth = {
            width: '40%',
            backgroundColor: 'rgba(151, 158, 183, 0.3)'
        }
        
        return(
            <div styleName="legend-container">
                <div style={legendWidth} styleName="legend">
                </div>
                <div style={fontStyling} styleName="legend-title">
                    {this.props.title}
                </div>
                <div style={legendWidth} styleName="legend">
                </div>
            </div>
        )
    }
}


export default LegendComponent;