import React, { Component } from 'react'

export default class FullScreen extends Component {
  render() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
            <g fill="#FFF" fillRule="nonzero">
                <path d="M18.352 1.742A1.746 1.746 0 0 0 16.611 0h-4.538a.99.99 0 1 0 0 1.98h4.102c.11 0 .198.088.198.197v2.587a.99.99 0 1 0 1.98 0V1.742zM0 4.767a.99.99 0 0 0 1.98 0v-2.59a.198.198 0 0 1 .197-.198H6.28A.99.99 0 0 0 6.28 0H1.745C.785.003.006.78.004 1.742L0 4.767zM18.352 16.68v-3.024a.99.99 0 0 0-1.979 0v2.592c0 .11-.088.198-.198.198h-4.102a.99.99 0 0 0 0 1.98h4.534c.964-.001 1.745-.782 1.745-1.746zM0 16.68a1.746 1.746 0 0 0 1.746 1.741H6.28a.99.99 0 0 0 0-1.979H2.176a.198.198 0 0 1-.198-.194v-2.592a.99.99 0 1 0-1.979 0v3.024z"/>
            </g>
        </svg>
    )
  }
}
