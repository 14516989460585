import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchMSTeamsLeaderboardData } from 'actions/msTeams/apiActions';
import MSTeamsLeaderboard from 'components/msTeamsLeaderboard/MSTeamsLeaderboard';
import { filterBatchLeaderboardData } from 'utils/msTeamsLeaderboard';

class MSTeamsLeaderboardContainer extends React.Component {

    componentDidMount() {
        const { match } = this.props;
        this.props.fetchMSTeamsLeaderboardData(match.params.batchId);
    }

    render() {
        return (
            <MSTeamsLeaderboard {...this.props} />
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    //batches: state.get('batches'),
    batchLeaderboardData: filterBatchLeaderboardData(
        state.getIn(['msTeamsLeaderboard', 'batchLeaderboard']), ownProps.match.params.batchId
    ),
    isLeaderboardDataFetching: state.getIn(['msTeamsLeaderboard', 'isLeaderboardDataFetching']),
    fetchLeaderboardError: state.getIn(['msTeamsLeaderboard', 'fetchLeaderboardError'])
});

const mapDispatchToProps = dispatch => ({
    fetchMSTeamsLeaderboardData: (batchId) => {
        dispatch(fetchMSTeamsLeaderboardData(batchId))
    }
});

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MSTeamsLeaderboardContainer));
