import React, { Component } from 'react';
import styles from './menuItem.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { convertDateWithYear } from 'utils/utilFunctions';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import getDotStyle from 'utils/dotColor';
import getTextStyle from 'utils/textColor';
import { filterObjectFromArray } from 'utils/objectUtils';
import routes from 'utils/webAppRoutes/routeConstants';
import enumConstants from 'constants/enums';
import TickWithoutBG from 'svgComponents/tickWithoutBG';
import { getLockMessage, getLockIcon } from 'utils/locks';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil'

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class MenuItem extends Component {

    sendSegmentData = (userItem) => {
        const { item, batchId } = this.props;
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            batchId: batchId,
            selectedItemId: item.get('id'),
            selectedItemName: item.get('name'),
            journeyId: item.get('journeyId'),
            phaseId: item.get('phaseId'),
            itemType: item.get('typeName'),
            itemLocked: item.get('isLocked'),
            itemExpired: item.get('isExpired'),
            itemCompleted: userItem.get('workflowStatus') !== enumConstants.get('COMPLETED')
        }
        window.analytics.track(
            'Sidebar item clicked',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onClickOfItem = (userItem) => {
        const { item, batchId, setUserState } = this.props;

        this.sendSegmentData(userItem);

        if (item.get('isLocked') && !item.get('isExpired')) {
            return;
        } else if (+this.props.match.params.itemId !== item.get('id')) {
            const route = routes.get('BATCH_ITEM');
            const params = [
                { 'BATCH_ID': batchId },
                { 'ITEM_ID': item.get('id') }
            ];
            updateRoute({ route, params });
            setUserState({
                currentItemId: item.get('id')
            });
        }
    }

    getStyleForMenuItemAndName = (userItem) => {
        const { item, selectedUserItem, isFromFeedback } = this.props;
        const myStyles = getSkin(this.props.skinGuide);
        const myDotStyle = css(getDotStyle(
            item.get('id') === selectedUserItem.get('id') && !isFromFeedback,
            item.get('isLocked') || item.get('isExpired'),
            userItem.get('workflowStatus'),
            this.props.skinGuide
        ));

        const myTextStyle = css(myStyles.name, getTextStyle(
            item.get('id') === selectedUserItem.get('id') && !isFromFeedback,
            item.get('isLocked'),
            selectedUserItem.get('workflowStatus'),
            item.get('isExpired'),
            this.props.skinGuide
        ));

        return {
            dot: myDotStyle,
            name: myTextStyle,
            isLocked: item.get('isLocked') && !item.get('isExpired'),
            isExpired: item.get('isExpired') && (userItem.get('workflowStatus') !== enumConstants.get('COMPLETED'))
        };
    }

    getStyleForLine = () => {
        const { itemsListLength, currentElementIndex } = this.props;
        const myStyles = getSkin(this.props.skinGuide);
        if (currentElementIndex === (itemsListLength - 1)) {
            return css(myStyles.whiteLine);
        }
        else {
            return css(myStyles.line);
        }
    }

    renderItemStatus = (item, userItem) => {
        const styles = getSkin(this.props.skinGuide);
        const lockExplanation = getLockMessage(userItem.get('lock_details'), "short");

        if (item.get('isLocked')) {
            return lockExplanation;
        } else if (userItem.get('workflowStatus') === enumConstants.get('COMPLETED')) {
            return (
                <div styleName="item-status-container">
                    <div styleName="completed-tick-container">
                        <TickWithoutBG />
                    </div>
                    <span styleName="completed-status">
                        {this.props.getLabel('completed_label')}
                    </span>
                </div>
            );
        } else if (item.get('isExpired')) {
            return <span className={css(styles.expired)}>{lockExplanation}</span>;
        }

        if (!item.get('endTimestamp')) {
            return null;
        }

        return (
            <span>
                {`${this.props.getLabel('due_date_label')}: ${convertDateWithYear(item.get('endTimestamp'))}`}
            </span>
        );
    }


    getTypeNameEnglish = (itemDetails, attachmentDetails) => {
        if (attachmentDetails) {
            return attachmentDetails.get('label');
        }
        return itemDetails.get('typeName')
    }

    getTypeNameWithLocale = (item, attachmentDetails) => {
		const type = attachmentDetails ? attachmentDetails.get('label') : item.get('type');
        const typeNameInEnglish = this.getTypeNameEnglish(item, attachmentDetails);
		try{
			return this.props.getLabelWithDefaults(`${type.toLowerCase()}_label`, typeNameInEnglish);
		} catch (e){
			return typeNameInEnglish;
		}
	}

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const skinHex = this.props.skinGuide.get('skinHex');
        const { item, getLabel, isSideBarOpen, userItemsList, attachmentDetails, getLabelWithDefaults, formLabelForModuleItem } = this.props;
        const userItem = filterObjectFromArray(userItemsList, 'id', item.get('id'));
        const style = this.getStyleForMenuItemAndName(userItem);
        const dotStyle = style.dot;
        const nameStyle = style.name;
        const lineStyle = this.getStyleForLine();
        const typeName = this.getTypeNameWithLocale(item, attachmentDetails);

        return (
            <div>

                {
                    (isSideBarOpen)
                        ?
                        <div styleName="main-container">
                            <div styleName="status-container">
                                <div styleName="column-container">
                                    {
                                        style.isExpired ?
                                            <div styleName="dot-lock">{}
                                                {getLockIcon(true, true, skinHex.get('greyColor4'))}
                                            </div>
                                            :

                                            style.isLocked
                                                ? <div styleName="dot-lock">{}
                                                    {getLockIcon(false, true, skinHex.get('greyColor4'))}
                                                </div>
                                                : <div styleName="dot" className={dotStyle} />
                                    }
                                    {/* <div styleName="dot" className={dotStyle} /> */}
                                    <div styleName="line" className={lineStyle} />
                                </div>
                                <div styleName="column-container text-container">
                                    <div
                                        styleName="text-content"
                                        className={css(myStyles.seperationLine)}
                                        onClick={() => this.onClickOfItem(userItem)}
                                    >
                                        <div className={css(myStyles.type)} styleName="name">
                                            <div styleName="type-duration">
                                                <div styleName="type-name">                                                 {getLabelWithDefaults(formLabelForModuleItem(typeName), typeName)}
                                                </div>
                                                
                                                <div>{item.get('duration')}</div>
                                            </div>
                                        </div>
                                        <div styleName="name product" className={nameStyle} title={getLabel(item.get('name'))}>
                                            {getLabel(item.get('name'))}
                                        </div>
                                        <div className={css(myStyles.date)} styleName="name date">
                                            {this.renderItemStatus(item, userItem)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <div styleName="column-container">
                            {
                                style.isExpired ?
                                    <div styleName="dot-lock">
                                        {getLockIcon(true, true, skinHex.get('greyColor4'))}
                                    </div>
                                    :
                                    style.isLocked
                                        ? <div styleName="dot-lock">
                                            {getLockIcon(false, true, skinHex.get('greyColor4'))}
                                        </div>
                                        : <div styleName="dot" className={dotStyle} />
                            }
                            {/* <div styleName="dot" className={dotStyle} /> */}
                            <div styleName="line" className={lineStyle} />
                        </div>
                }

            </div>
        );
    }
}

export default MenuItem;
