import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules({ allowMultiple: true })
class Unlike extends Component {

    render() {
        const { height, width } = this.props;
        return (
            <div style={{ height: height, width: width }}>
                <svg width="100%" height="100%" viewBox="0 0 12 12">
                    <g fill={this.props.color} fillRule="nonzero">
                        <path d="M.194 5.638a1.33 1.33 0 0 0 1.271 1.717h2.698a.604.604 0 0 1 .545.864l-.69 1.448c-.129.382-.052.803.203 1.116a1.191 1.191 0 0 0 1.898-.064l2.365-3.35a.606.606 0 0 1 .493-.256c.124 0 .225-.1.225-.224v-5.47a.242.242 0 0 0-.137-.218C7.276.335 6.682.221 5.225.221c-.168 0-1.122.009-1.47.009-1.296 0-2.158.752-2.638 2.31L.198 5.62l-.004.018zM9.927 7.113h.013c.058.348.36.603.712.604h.726a.242.242 0 0 0 .241-.241V.706a.242.242 0 0 0-.241-.241h-.726c-.4 0-.725.324-.725.725v5.923z" />
                    </g>
                </svg>
            </div>
        );
    }
}


Unlike.defaultProps = {
    height: "24px",
    width: "26px"
};

export default Unlike;
