import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setFeedbackData } from 'actions/itemFeedback/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import { appendQueryParameters } from 'utils/utilFunctions';
import { setApiError } from 'actions/apiErrors/actionCreators';

import { updateFetchFeedbackStatus } from 'actions/loaders/actionCreators';

class FetchItemFeedback extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	prepareUrl = (apiInfoObject = {}) => appendQueryParameters(
		urls.get('PLATFORM').get('GET_ITEM_FEEDBACK'),
		apiInfoObject.queryParameters
	)

	shouldAjaxCall = (apiInfoObject) => {
		return true;
	}

	successCall = (response) => {
		this.dispatch(setFeedbackData(response));
		this.dispatch(setApiError('fetchItemFeedbackError', null));
		return response;
	}

	onError = (errorObj) => {
		// this.dispatch(addToast({
		// 	id: toastConstants.get('ID_ERROR'),
		// 	type: toastConstants.get('TYPE_ERROR'),
		// 	heading: 'Something went wrong',
		// 	message: 'Sorry, unable to fetch feedback details. Please try again!',
		// 	isDisplayed: false,
		// 	autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		// }));
		// sendUserToInvalidPage();
		this.dispatch(updateFetchFeedbackStatus(false));
		this.dispatch(setApiError('fetchItemFeedbackError', 'There was a problem while fetching the content.'));
	}

}

export default new FetchItemFeedback();
