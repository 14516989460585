import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const myTypography = typography(skin);
	return StyleSheet.create({
		stepHeaderText: {
			...myTypography.body1,
			color: '#000000CC'
		},
		valuePropositionText: {
			...myTypography.overline,
			color: '#000000CC',
			fontWeight: 600,
			lineHeight: 'normal'
		},
		roleText: {
			...myTypography.body1,
			color: '#000000CC',
			fontWeight: 500
		}
	});
}

export default getSkin;