import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        headerContainer: {
        },
		logoName: {
            ...myTypography.h5,
            fontWeight: 700,
            color: skin.get('primaryColor')
        },
        individualItemContainer: {
            borderLeft: `2px solid ${hexToRgbA('#D6D6D6', 0.4)}`
        },
        individualItemName: {
            ...myTypography.overline,
            fontWeight: 600,
            color: hexToRgbA('#353535', 0.8)
        },
        addCursor: {
            cursor: 'pointer'
        },
        arrowUp: {
            borderBottom: `15px solid ${hexToRgbA('#dfe1e8', 0.7)}`,
			borderLeft: '15px solid transparent',
			borderRight: '15px solid transparent'
        },
        arrowContainer: {
            background: 'transparent'
        },
        journeysContainer: {
            backgroundColor: skin.get('white'),
            border: `solid 1px ${hexToRgbA(skin.get('greyColor4'), 0.27)}`,
            boxShadow: `0 2px 4px 0 ${hexToRgbA(skin.get('black'), 0.13)}, 0 4px 16px 0 ${hexToRgbA(skin.get('black'), 0.06)}`,
            borderRadius: '5px'
        },
        individualJourneyTitle: {
            ...myTypography.h5,
            fontSize: '18px',
            fontWeight: 600,
            color: skin.get('greyColor2')
        },
        individualJourneyDesc: {
            ...myTypography.caption,
            fontSize: '12px',
            fontWeight: 400,
            color: hexToRgbA(skin.get('greyColor1'), 0.7)
        },
        individualJourney: {
            ':hover': {
				backgroundColor: '#dfe1e8'
			}
        },
        individualJourneyActive: {
            backgroundColor: hexToRgbA('#dfe1e8', 0.7)
        },
        aboutJourneyButton: {
            border: `1px solid ${hexToRgbA(skin.get('secondaryColor'), 0.8)}`,
            color: hexToRgbA(skin.get('secondaryColor'), 0.8),
            ...myTypography.button,
            borderRadius: '4px',
            cursor: 'pointer',
            ':hover': {
                backgroundColor: hexToRgbA(skin.get('secondaryColor'), 0.8),
                color: skin.get('white')
			}
        },
        individualJourneyPercCompleted: {
            ...myTypography.caption,
            color: hexToRgbA(skin.get('greyColor3'), 0.6),
            fontWeight: 400
        }
	});
}

export default getSkin;