/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: Base.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Base component of the application
 *
 **/

import React from 'react';
import styles from './JourneyItemIntruductionLoader.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import ContentLoader from "react-content-loader";
import getLoadersDefaultConfig from 'commonComponents/loaders/loadersDefaultConfig';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class JourneyItemIntruductionLoader extends React.Component {

	render() {
		const loadersDefaultconfig = getLoadersDefaultConfig(this.props.skinGuide.get('skinHex'));
		return (
			<ContentLoader
				height={618}
				width={1050}
				speed={loadersDefaultconfig.get('speed')}
				primaryColor={loadersDefaultconfig.get('primaryColor')}
				secondaryColor={loadersDefaultconfig.get('secondaryColor')}
				style={{ height: '100%', width: '100%' }}
			>
				{/* <rect x="0" y="0" rx="0" ry="0" width="1280" height="743" /> */}
				<rect x="424" y="110" rx="0" ry="0" width="200" height="20" />
				<rect x="424" y="150" rx="0" ry="0" width="200" height="1" />
				<rect x="472" y="167" rx="0" ry="0" width="104" height="20" />
				<rect x="419" y="274" rx="0" ry="0" width="213" height="20" />
				<rect x="419" y="313" rx="0" ry="0" width="213" height="20" />
				<rect x="224" y="380" rx="0" ry="0" width="602" height="16" />
				<circle cx="486" cy="474" r="17" />
				<circle cx="565" cy="474" r="17" />
				<rect x="445" y="500" rx="0" ry="0" width="58" height="10" />
				<rect x="425" y="521" rx="0" ry="0" width="78" height="10" />
				<rect x="548" y="500" rx="0" ry="0" width="58" height="10" />
				<rect x="548" y="521" rx="0" ry="0" width="78" height="10" />
				<rect x="525" y="453" rx="0" ry="0" width="1" height="84" />
				<rect x="363" y="226" rx="0" ry="0" width="322" height="40" />
				<rect x="224" y="569" rx="0" ry="0" width="279" height="48" />
				<rect x="548" y="569" rx="0" ry="0" width="279" height="48" />
			</ContentLoader>
		);
	}
}

export default JourneyItemIntruductionLoader;
