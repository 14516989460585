import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {
	setDemographicsData,
	setDemographicsDataLoading
} from 'actions/userProfile/actionCreators';

class FetchDemographicsData extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	shouldAjaxCall = () => {
		const demographicsData = this.getState().getIn(['userProfile', 'demographicsData']);
		if (!demographicsData.isEmpty()) {
			return false;
		}
		return true;
	}

	prepareUrl = (apiInfoObject = {}) => urls.get('PLATFORM').get('GET_DEMOGRAPHICS_DATA');

	beforeCall = (apiInfoObject) => {
		this.dispatch(setDemographicsDataLoading(true));
		return apiInfoObject;
	}

	successCall = (response) => {
		this.dispatch(setDemographicsData(response));
		return response;
	}

	onError = (error) => {
		this.dispatch(setDemographicsDataLoading(true));
		return error;
	}
}

export default new FetchDemographicsData();
