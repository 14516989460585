/**
 * @author: Amul
 * @since: Mon Jan 10 2020 14:18:31 GMT+0530 (IST)
 * @file: coursesModulesList.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
  Keep loaded modules' items here
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrReplaceObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS([]);

const modulesItemsList = (state = initialState, action = {}) => {
    switch (action.type) {

        case actionConsts.get('SET_MODULES_ITEMS_LIST'):
            const updatedModuleItemsInfo = {
                id: action.moduleId,
                isModuleItemsListAvailable: true,
                moduleItemsList: Immutable.fromJS(action.moduleItemsList)
            };
            return setOrReplaceObjectInList(
                state, updatedModuleItemsInfo, 'id', 'id'
            );      
        case actionConsts.get('UNSET_MODULES_ITEMS_LIST'):
            return initialState;
        default:
            return state;
    }
};

export default modulesItemsList;