/**
 * @author: sourabh.jain
 * @since: Thu Oct 06 2018 00:47:22 GMT+0530 (IST)
 * @file: genericData.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    analyticsData: [],
    isGenericDataFetched: false,
});

const genericData = (state = initialState, action = {}) => {
    switch (action.type) {
        /*Generic analytics data */
        
        case actionConsts.get('SET_GENERIC_ANALYTICS_DATA'):

        	const analyticsData = action.payload;

            return state.set(
                'analyticsData',
                Immutable.fromJS(analyticsData)
            ).set(
                'isGenericDataFetched',
                true
			);
			            
        default:
            return state;
    }
};

export default genericData;