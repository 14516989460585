import { filterObjectFromArray } from "utils/objectUtils";
import store from "store/store";
import { isOrgInFastEnrollList } from "utils/orgUtils";
import { checkIfValueIsTrue } from "utils/utilFunctions";

const getActiveBatchDetails = (batches, batchIdFromRoute = null) => {
  const activeBatchId = batchIdFromRoute || batches.get("activeBatchId");
  const batchesList = batches.get("batchesList");
  const batchDetails = filterObjectFromArray(batchesList, "id", activeBatchId);
  return batchDetails;
};

const getUserActiveBatchDetails = (batches, batchIdFromRoute = null) => {
  const activeBatchId = batchIdFromRoute || batches.get("activeBatchId");
  const userBatchesList = batches.get("userBatchesList");
  const userBatchDetails = filterObjectFromArray(
    userBatchesList,
    "batchId",
    activeBatchId
  );
  return userBatchDetails;
};

const getActiveBatchDetailsWithId = (batchId) => {
  const batchesList = store.getState().get("batches").get("batchesList");
  const batchDetails = filterObjectFromArray(batchesList, "id", batchId);
  return batchDetails;
};

const shouldAllowSelfEnrollmentInBatch = (
  isCourseSelfPaced,
  userOrganization
) => {
  if (!userOrganization) return false;
  const orgInFastEnrollList = isOrgInFastEnrollList(
    userOrganization.getIn(["userOrganization", "id"])
  );

  if (orgInFastEnrollList) {
    return true;
  }

  const orgAllowsSelfEnrollment = checkIfValueIsTrue(
    userOrganization.getIn([
      "userOrganization",
      "platformSettings",
      "autoEnrollToSelfPacedJourney",
    ])
  );

  return orgAllowsSelfEnrollment && isCourseSelfPaced;
};

const getActiveBatchId = () => {
  return store.getState().getIn(["batches", "activeBatchId"]);
}

export {
  getActiveBatchDetails,
  getUserActiveBatchDetails,
  getActiveBatchDetailsWithId,
  shouldAllowSelfEnrollmentInBatch,
  getActiveBatchId
};
