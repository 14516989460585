import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import styles from './quizScoreBarChart.module.sass';
import getSkin from './skin';
import applySkin from 'utils/SkinProvider';
import { Bar, Line } from '@vx/shape';
import { Group } from '@vx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@vx/scale';
import { AxisLeft, AxisBottom } from '@vx/axis';
import { LinearGradient } from '@vx/gradient';
import { getGradientBounds, getRoundedRect, getFlexDirectionForGraphLegend } from 'utils/analytics/utilFunctions';

@applySkin
@CSSModules(styles, {allowMultiple: true})
class QuizScoreBarChart extends Component {

	barColors = [
		{from: '#c26be3', to: '#6e31ff'},
		{from: '#1d7ed7', to: '#05d5b9'},
		{from: '#e9216d', to: '#f15f7b'}
	]

	margin = {
		top: 40,
		left: 150,
		right: 50,
		bottom: 40
	}

    render(){
        const data = this.props.barChartData.toJS()
        const width = 460;
        const height = this.margin.top + 10 * (2 * data.length + 1) + this.margin.bottom;
        const x = d => d.label;
        const y = d => (Math.round(d.percentage, 2) > 100 ? 100 : Math.round(d.percentage, 2));

		const xMax = width - this.margin.left - this.margin.right;
		const yMax = height - this.margin.top - this.margin.bottom;

		// scales
		const yScale = scaleBand({
			rangeRound: [0, yMax],
			domain: data.map(x),
			padding: 0.4,
		});
		const xScale = scaleLinear({
			rangeRound: [0, xMax],
			domain: [0, 100],
		});
		const leftTickLabelStyling = {
            fill: 'rgba(60, 60, 60, 0.6',
			textAnchor: "end",
			fontSize: 12,
			fontFamily: 'Open Sans'
		};
		const bottomTickLabelStyling = {
            fill: 'rgba(60, 60, 60, 0.4',
			textAnchor: "middle",
			fontSize: 12,
			fontFamily: 'Open Sans'
        };

		return(
			<svg 
				width={width} 
				height={height}
			>
				{
					data.map((datum, i) => {
						return <LinearGradient
							key={`linear_grad_for_bar_${i}`}
							from={this.barColors[i%3].from}
							to={this.barColors[i%3].to}
							id={`bar-color-${i}`}
						/>
					})
				}
				<Group top={this.margin.top} left={this.margin.left}>
					<AxisLeft
						scale={yScale}
						top={0}
						left={0}
						stroke={'rgba(0, 0, 0, 0.1'}
						hideTicks={true}
						tickLabelProps={() => (leftTickLabelStyling)}
					/>
					<AxisBottom
						scale={xScale}
						top={yMax}
						stroke={'rgba(0, 0, 0, 0.1'}
						tickTextFill={'#ff0000'}
						hideTicks={true}
						tickLabelProps={() => (bottomTickLabelStyling)}
						numTicks={3}
						tickFormat={value => value+'%'}
					/>
					
					<Group className="background">
						{
                            data.map((datum, i) => {
								const barWidth = xScale(y(datum));
								
                                return (
                                    <Group key={`bar-${x(datum)}`}>
										<path
										id={`some-random-bar-${i%3}`}
										d={getRoundedRect(1, yScale(x(datum)), barWidth, yScale.bandwidth(), 4, 'right')}
										style={{
											fill: `url(#bar-color-${i})`
										}}
									/>
                                    </Group>
                                );
                            })
                        }
					</Group>
				</Group>
			</svg>
        );
    }
}

export default QuizScoreBarChart;