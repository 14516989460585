import React, { Component } from 'react'

export default class Prev extends Component {
  render() {
    let {height, width, fill} = this.props;
    height = height || 18;
    width = width || 12;
    let viewBox = `0 0 ${width} 18`;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
            <path fill={fill || "#FFF"} fillRule="nonzero" d="M0 8.979c0-.497.213-.969.585-1.297L8.949.324a1.385 1.385 0 0 1 1.829 2.08L3.464 8.838a.187.187 0 0 0 0 .282l7.314 6.434a1.385 1.385 0 1 1-1.83 2.08l-8.36-7.356A1.732 1.732 0 0 1 0 8.978z"/>
        </svg>
    )
  }
}
