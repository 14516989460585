import React, { Component } from 'react';
import styles from './lockIcon.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class LockIcon extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		const fillColor = this.props.svgColor || skinHex.get('white');

		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 32 41">
					<path fill={fillColor} fillRule="nonzero" d="M28.632 16h-1.264v-4.632C27.368 5.09 22.278 0 16 0 9.721 0 4.632 5.09 4.632 11.368V16H3.368A3.368 3.368 0 0 0 0 19.368v17.685a3.368 3.368 0 0 0 3.368 3.368h25.264A3.368 3.368 0 0 0 32 37.053V19.368A3.368 3.368 0 0 0 28.632 16zM16 31.158a3.368 3.368 0 1 1 0-6.737 3.368 3.368 0 0 1 0 6.737zm7.158-16a.842.842 0 0 1-.842.842H9.684a.842.842 0 0 1-.842-.842v-3.79a7.158 7.158 0 1 1 14.316 0v3.79z" opacity=".8"/>
				</svg>
			</div>
		);
	}
}



export default LockIcon;
