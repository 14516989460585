import FetchPage from 'actions/serverCalls/page/FetchPage';


const fetchPage = (pageId) => {
    return FetchPage.call({
        pageId
    })
}

export {
    fetchPage
};