/**
 * @author: Amul
 * @since: Fri June 14 2019 14:20:31 GMT+0530 (IST)
 * @file: ReportDownloadContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

import React from 'react';
import { connect } from 'react-redux';
import { generateReport } from 'actions/reports/apiActions';
import ReportDownload from 'commonComponents/reports/ReportDownload';

class ReportDownloadContainer extends React.Component {
    render() {
        return <ReportDownload {...this.props} />
    }
}

const mapStateToProps = (state) => ({
    user: state.get('user'),
    reports: state.get('reports')
});

const mapDispatchToProps = (dispatch) => ({
    generateReport: (payload) => {
        generateReport(payload);
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportDownloadContainer);