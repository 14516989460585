import actionConsts from 'constants/actions';

const setSelectedJourneyInstanceDetails = journeyInstance => ({
	type: actionConsts.get('SET_JOURNEY_INSTANCE'),
	journeyInstance
})

const updateSelectedJourneyInstance = () => ({
	type: actionConsts.get('UPDATE_SELECTED_JOURNEY_INSTANCE')
})

const setSelectedJourneyInstanceLoading = flag => ({
	type: actionConsts.get('SET_FETCHING_JOURNEY_INSTANCE_FLAG'),
	flag
})

const setJourneyCategories = categories => ({
	type: actionConsts.get('SET_JOURNEY_CATEGORIES'),
	categories
})

const setJourneyFormats = formats => ({
	type: actionConsts.get('SET_JOURNEY_FORMATS'),
	formats
})

const setJourneyTags = tags => ({
	type: actionConsts.get('SET_JOURNEY_TAGS'),
	tags
})

const setAvailableJourneysLoading = flag => ({
	type: actionConsts.get('SET_AVAILABLE_JOURNEYS_LOADING'),
	flag
})

const setAvailableCorpDeckJourneysLoading = flag => ({
	type: actionConsts.get('SET_AVAILABLE_CORP_DECK_JOURNEYS_LOADING'),
	flag
})

const removeRequestedJourneyFromAvailableJourneys = journeyInstanceId => ({
	type: actionConsts.get('REMOVE_REQUESTED_JOURNEY'),
	journeyInstanceId
})

export {
	setSelectedJourneyInstanceDetails,
	setSelectedJourneyInstanceLoading,
	setJourneyCategories,
	setJourneyFormats,
	setJourneyTags,
	setAvailableJourneysLoading,
	removeRequestedJourneyFromAvailableJourneys,
	updateSelectedJourneyInstance,
	setAvailableCorpDeckJourneysLoading
};