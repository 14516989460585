/* eslint-disable use-isnan */
import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import styles from './quiz.module.sass';
import AdditionalInformation from '../additionalInformation';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import Clock from 'svgComponents/clock';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import QuizScore from './quizScore';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { checkAndAddAudioControls } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Quiz extends Component {

	constructor(props) {
		super(props);
		let timeSet = props.moduleItem.getIn(['contentDetails', 'time_limit']) * 60;
		let timeElapsed = props.quiz.getIn(['activeAttempt', 'time_elapsed']);
		let timeLeft = undefined;
		if (timeSet && parseInt(timeElapsed) !== NaN) {
			if (timeSet > timeElapsed) timeLeft = timeSet - timeElapsed;
		}
		this.state = {
			timeLeft: timeLeft,
			audioPlayedCount: parseInt(window.localStorage.getItem(`${props.moduleItem.getIn(['moduleItem', 'id'])}_played_count`) || 0, 0),
			audioPlayerEnabled: this.getAudioPlayerEnabledInitValue()
		}
		this.quizTimerInterval = null;
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.moduleItem.get('isModuleItemDataAvailable') &&
			nextProps.moduleItem.getIn(['contentDetails', 'time_limit']) &&
			nextProps.quiz.getIn(['activeAttempt', 'time_elapsed']) !== this.props.quiz.getIn(['activeAttempt', 'time_elapsed'])
		) {
			let timeSet = nextProps.moduleItem.getIn(['contentDetails', 'time_limit']) * 60;
			let timeElapsed = nextProps.quiz.getIn(['activeAttempt', 'time_elapsed']);
			let timeLeft = 0;
			if (timeSet && parseInt(timeElapsed) !== NaN) {
				// console.log("timeSet , timeElapsed", timeSet, timeElapsed)
				if (timeSet > timeElapsed) timeLeft = timeSet - timeElapsed;
			}
			this.setState({
				timeLeft: timeLeft
			}, () => {
				if (!this.quizTimerInterval)
					this.updateTimeLeft();
			});
		}
	}

	componentDidMount() {
		let completion_requirement = this.props.moduleItem.get('moduleItem').get('completion_requirement');
		if (completion_requirement && completion_requirement.size && completion_requirement.get('type') === 'must_view') {
			this.props.markRead();
		}

		if (
			this.props.moduleItem.get('isModuleItemDataAvailable') &&
			this.props.moduleItem.getIn(['contentDetails', 'time_limit'])
		) {
			this.updateTimeLeft();
		}
	}

	componentWillUnmount() {
		this.props.unsetQuizSubmissions()
	}

	getAudioUrl = () => {
		return this.props.moduleItem.getIn(['contentDetails', 'al_data', 'audioUrl']);
	}

	isNLPQuiz = () => {
		return this.props.moduleItem.getIn(['contentDetails', 'al_data', 'nlp']);
	}

	getPlayedAndAvailablePlaybacksCounts = () => {
		const { moduleItem } = this.props;
		const moduleItemId = moduleItem.getIn(['moduleItem', 'id']);
		console.log('moduleItemId', moduleItemId); 
		const noOfPlaybacks = parseInt(moduleItem.getIn(['contentDetails', 'al_data', 'noOfPlaybacks']) || 0, 10);
		const playedCount = parseInt(this.state?.audioPlayedCount || window.localStorage.getItem(`${moduleItem.getIn(['moduleItem', 'id'])}_played_count`) || 0, 10);

		console.log('debugu playedcount', playedCount, noOfPlaybacks);
		return [playedCount, noOfPlaybacks, moduleItemId];
	}

	setAudioPlayerEnabled = (enabled) => {
		this.setState({ audioPlayerEnabled: enabled });
	}

	setPlayedCount = (moduleItemId) => {		
		const audioPlayedCount = this.state.audioPlayedCount;
		this.setState({ audioPlayedCount: audioPlayedCount + 1 }, () => {
			window.localStorage.setItem(`${moduleItemId}_played_count`, audioPlayedCount + 1);
		});
	}

	getAudioPlayerEnabledInitValue = () => {
		const [playedCount , noOfPlaybacks] = this.getPlayedAndAvailablePlaybacksCounts();
		if (!noOfPlaybacks) return true;
		return playedCount < noOfPlaybacks;
	}

	onAudioEnded = (event) => {
		const [playedCount , noOfPlaybacks, moduleItemId ] = this.getPlayedAndAvailablePlaybacksCounts();
		this.setPlayedCount(moduleItemId);
		if(noOfPlaybacks){
			if(playedCount + 1 >= noOfPlaybacks){
				this.setAudioPlayerEnabled(false);
			}
		}
  }

	checkIfQuizDisabled = () => {
		const isNLPQuiz = this.isNLPQuiz();
		if(!isNLPQuiz) return false;		
		//User should play the audio at least once
		const [playedCount] = this.getPlayedAndAvailablePlaybacksCounts();
		if(playedCount >= 1) return false;
		return true;
	}

	updateTimeLeft = () => {
		this.quizTimerInterval = setInterval(() => {
			if (this.state.timeLeft) {
				let timeLeft = this.state.timeLeft - 1;
				if (timeLeft === 0) {
					this.setState({
						showConfirmationPopup: true,
						popupMessage: 'Your Time is up! Please Wait, submitting your answers.',
						popupOkAction: null,
						popupCancelAction: null,
						isComponentLoading: true,
						timeLeft
					}, () => {
						clearInterval(this.quizTimerInterval);
						setTimeout(() => {
							this.props.fetchQuizSubmissions()
						}, 2000)
					});
				} else {
					this.setState({
						timeLeft
					})
				}
			}
		}, 1000)
	}

	showTimeLeft = () => {
		let min = 0;
		let sec = 0;
		const { timeLeft } = this.state;
		if (timeLeft) {
			min = parseInt(timeLeft / 60);
			sec = timeLeft % 60;
		}
		if (min < 100) {
			min = ('00' + min).slice(-2)
		}
		sec = ('00' + sec).slice(-2)
		return { min, sec }
	}

	sendSegmentData = () => {
		const { match } = this.props;
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			phaseId: match.params.phaseId,
			phaseItemId: match.params.phaseItemId,
			alCourseId: match.params.courseId,
			batchId: match.params.batchId,
			moduleId: match.params.moduleId,
			moduleItemId: match.params.moduleItemId
		}
		window.analytics.track(
			'Quiz Opened',
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	onStartQuiz = () => {
		const { match } = this.props;
		const batchId = match.params.batchId;
		const courseId = match.params.courseId;
		const moduleId = match.params.moduleId;
		const moduleItemId = match.params.moduleItemId;
		const phaseId = match.params.phaseId;
		const phaseItemId = match.params.phaseItemId;
		const route = routes.get('BATCH_QUIZ_TAKE');

		this.sendSegmentData();

		const params = [
			{ 'BATCH_ID': batchId },
			{ 'COURSE_ID': courseId },
			{ 'MODULE_ID': moduleId },
			{ 'MODULE_ITEM_ID': moduleItemId },
			{ '_PHASE_ID_': phaseId },
			{ '_PHASEITEMID_': phaseItemId }
		]
		updateRoute({ route, params });
	}

	getAttemptsLeft = () => {
		let quizSubmissions = this.props.quiz.get('quizSubmissions');
		let latestCompletedAttemptIndex = -1;
		if (quizSubmissions.size > 0) {
			latestCompletedAttemptIndex = quizSubmissions.reduce((max, sub) => sub.get('attempt') > max ? sub.get('attempt') : max, quizSubmissions.get(0).get('attempt'))
		}
		let latestCompletedAttempt = quizSubmissions.find(sub =>
			sub.get('attempt') === latestCompletedAttemptIndex)
		if (latestCompletedAttempt) {
			return latestCompletedAttempt.get('attempts_left')
		}
		//if there are no sumissions created for the quiz
		return this.props.moduleItem.get('contentDetails').get('allowed_attempts');
	}

	getLatestSubmissionScore = () => {
		let quizSubmissions = this.props.quiz.get('quizSubmissions');
		if (this.props.quiz.get('activeAttempt').get('workflow_state') === 'untaken') {
			return this.props.quiz.get('activeAttempt').get('kept_score')
		}
		let filteredSubmissions = quizSubmissions.filter(sub => sub.get('workflow_state') === 'complete')
		let find = null;
		if (filteredSubmissions.size > 0) {
			find = filteredSubmissions.reduce((max, sub) => sub.get('attempt') > max ? sub.get('attempt') : max, filteredSubmissions.get(0).get('attempt'))
		}
		let latestCompletedAttempt = filteredSubmissions.find(sub =>
			sub.get('attempt') === find)
		if (latestCompletedAttempt) {
			return latestCompletedAttempt.get('kept_score')
		}
		return null
	}

	viewSurveyResponses = () => {
		const route = routes.get('BATCH_QUIZ_REVIEW');
		const { match } = this.props;
		let params = [
			{ 'BATCH_ID': match.params.batchId },
			{ '_PHASEITEMID_': match.params.phaseItemId },
			{ 'COURSE_ID': match.params.courseId },
			{ '_PHASE_ID_': match.params.phaseId },
			{ 'MODULE_ID': match.params.moduleId },
			{ 'MODULE_ITEM_ID': match.params.moduleItemId },
			{ '_QUIZ_SUBMISSION_ATTEMPT_': this.props.quiz.get('quizSubmissions').getIn([this.props.quiz.get('quizSubmissions').size - 1, 'attempt']) }
		]
		updateRoute({ route, params });
	}

	submitQuiz = () => {
		let { quiz, moduleItem, match } = this.props;
		let payload = {
			submissionId: quiz.get('activeAttempt').get('id'),
			courseId: this.props.match.params.courseId,
			quizId: moduleItem.get('moduleItem').get('content_id'),
			body: {
				attempt: quiz.get('activeAttempt').get('attempt'),
				validation_token: quiz.get('activeAttempt').get('validation_token')
			},
			match: match,
			batchId: match.params.batchId,
			phaseId: match.params.phaseId,
			phaseItemId: match.params.phaseItemId
		}
		this.props.completeQuiz(payload)
	}

	render() {
		let { moduleItem, quiz, isSidebarOpen, getLabelWithDefaults, match, fetchQuizError, fetchQuizSubmissions, lockDetails } = this.props;
		const styles = getSkin(this.props.skinGuide);
		let contentDetails = moduleItem.get('contentDetails')
		let quizScore = this.getLatestSubmissionScore();
		let attemptsLeft = this.getAttemptsLeft();
		let quizTime = this.showTimeLeft();
		const [ , noOfPlaybacks ] = this.getPlayedAndAvailablePlaybacksCounts();

		return (
			<APIErrorBoundary
				key={quiz.get('id')}
				apiError={fetchQuizError}
				retryHandler={fetchQuizSubmissions}>
				<div styleName="dev-row">
					{
						quiz.get('activeAttempt').get('id') ?
							<div className={css(styles.incompleteSubmissionScoreCnt)} styleName="incomplete-submission-score-cnt">
								<div styleName="ongoing-attempt-text">
									{getLabelWithDefaults('quiz_resume_message_label', 'We see you did not submit your last quiz attempt. Please resume.')}
								</div>
								<div>
									{
										moduleItem.getIn(['contentDetails', 'time_limit']) &&
											this.state.timeLeft <= 3 ?
											<PrimaryButton
												text={getLabelWithDefaults('quiz_resume_label', 'RESUME QUIZ')}
												clickFunction={this.onStartQuiz}
												disabled={true}
											/>
											:
											<PrimaryButton
												text={getLabelWithDefaults('quiz_resume_label', 'RESUME QUIZ')}
												clickFunction={this.onStartQuiz}
											/>
									}
									{
										moduleItem.getIn(['contentDetails', 'time_limit']) ?
											<div className={css(styles.timerTime)} styleName="timer-time">
												<div>
													{
														getLabelWithDefaults(
															`quiz_time_remaining_label`,
															`Time remaining to resume ${(quizTime.min)}:${(quizTime.sec)}`,
															{
																min: quizTime.min,
																sec: quizTime.sec
															}
														)
													}
												</div>
												<div>
													{
														this.state.timeLeft <= 3 ?
															getLabelWithDefaults(
																'quiz_wait_submiting_quiz_label',
																"Please wait, submitting this attempt..."
															)
															: null
													}
												</div>
											</div>
											: null
									}
								</div>
							</div>
							:
							quizScore != null &&
							(
								!(/survey/.test(contentDetails.get('quiz_type'))) ?
									<QuizScore moduleItem={moduleItem} quiz={quiz} quizScore={quizScore} isSidebarOpen={isSidebarOpen} params={match.params} unsetQuizSubmissions={this.props.unsetQuizSubmissions}/>
									:
									<div styleName="survey-review-btn-cnt">
										<PrimaryButton text="VIEW YOUR RESPONSES" clickFunction={this.viewSurveyResponses} />
									</div>

							)
					}
					<div styleName={`quiz-content-column${isSidebarOpen ? ' sidebar-open' : ''}`}>
						<AdditionalInformation
							title={getLabelWithDefaults('quiz_additional_information_label', 'Additional Information')}
							body={contentDetails.get('description') && checkAndAddAudioControls(contentDetails.get('description'))}
							audioUrl={this.getAudioUrl()}
							audioPlayerEnabled={this.state.audioPlayerEnabled}
							onAudioEnded={this.onAudioEnded}
							noOfPlaybacks={noOfPlaybacks}
						/>
					</div>
					<div className={css(styles.quizDetailsColumn)} styleName={`quiz-details-column${isSidebarOpen ? ' sidebar-open' : ''}`}>
						<div styleName="quiz-details">
							<div className={css(styles.quizDetailsHeader)}>
								{getLabelWithDefaults('quiz_details_label', 'QUIZ DETAILS')}
							</div>
							<div className={css(styles.quizDetailsInnerCnt)} styleName="quiz-details-inner-cnt">
								{
									contentDetails.get('duration') &&
									<div styleName="align-items-center">
										<Clock />
										<span style={{ marginLeft: '8px' }}>
											{`${contentDetails.get('duration')} ${contentDetails.get('duration_unit')}`}
										</span>
									</div>
								}
								<div styleName="align-items-center">
									<span style={{ marginLeft: '8px' }}>
										{getLabelWithDefaults('questions_count_label', `${contentDetails.get('question_count')} Questions`, { count: contentDetails.get('question_count') })}
									</span>
								</div>
							</div>
							{
								attemptsLeft != null &&
								<div className={css(styles.attemptsLeft)} styleName="attempts-left">
									{
										attemptsLeft == -1 ?
											getLabelWithDefaults('quiz_unlimited_attempts_label', 'Unlimited Attempts') :
											getLabelWithDefaults('attempts left_label', `${attemptsLeft == 0 ? 'No' : attemptsLeft} more attempts left`, { count: attemptsLeft })
									}
								</div>
							}
						</div>

						<div styleName="button-cnt">
							{
								!lockDetails.expired ?

									(quiz.get('activeAttempt').get('workflow_state') === null || quiz.get('activeAttempt').get('workflow_state') === undefined) &&
									(
										contentDetails.get('question_count') > 0 ?
											attemptsLeft === 0 ?
												<div styleName="disabled-btn-style" title="No attempts left">
													<div className={css(styles.disabledBtnText)} styleName="text">
														{getLabelWithDefaults('retake_quiz_label', 'RETAKE QUIZ')}
													</div>
												</div>
												:
												quiz.get('quizSubmissions').size > 0 ?
													<PrimaryButton text={getLabelWithDefaults('retake_quiz_label', 'RETAKE QUIZ')} clickFunction={this.onStartQuiz} />
													:
													<PrimaryButton text={getLabelWithDefaults('take_quiz_label', 'TAKE QUIZ')} clickFunction={this.onStartQuiz} disabled={this.checkIfQuizDisabled()} />
											:
											<div styleName="disabled-btn-style" title="No Questions in Quiz">
												<div className={css(styles.disabledBtnText)} styleName="text">
													{getLabelWithDefaults('take_quiz_label', 'TAKE QUIZ')}
												</div>
											</div>
									)

									:

									<span>{lockDetails.explanation}</span>
							}
						</div>
					</div>
				</div>
			</APIErrorBoundary>
		)
	}
}

export default Quiz;