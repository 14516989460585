import React, { Component } from 'react';
import styles from './resultSection.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import { Warning } from 'svgComponents/toastIcons';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ResultSection extends Component {
    
    constructor(props){
        super(props);
        this.state = {
            expanded: false
        };
    }

    onToggleDetails = () => {
        this.setState({expanded: !this.state.expanded});
    }

    getExpandArrow = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none" styleName="expand-arrow" style={this.state.expanded ? {}:{transform: 'rotate(180deg)'}}>
            <path d="M6.00213 -9.53674e-07C5.67195 -0.000409603 5.3576 0.141404 5.1394 0.3892L0.243953 5.95377C-0.0778508 6.33728 -0.0346394 6.90755 0.341278 7.23819C0.717195 7.56883 1.28832 7.53889 1.62761 7.17077L5.90832 2.30477C5.932 2.27774 5.96619 2.26225 6.00213 2.26225C6.03806 2.26225 6.07225 2.27774 6.09593 2.30477L10.3766 7.17077C10.5916 7.42691 10.9284 7.54655 11.2567 7.48338C11.5851 7.42022 11.8535 7.18416 11.958 6.86656C12.0626 6.54896 11.9869 6.19964 11.7603 5.95377L6.86635 0.391196C6.64778 0.1428 6.333 0.000313282 6.00213 -9.53674e-07Z" fill="#5D5D5D"/>
        </svg>
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        const {expanded} = this.state;
        const {title, description, moreDetailsChildren, resolution, warningsOnly} = this.props;

        return <div styleName="result-cnt">
            <div styleName="result-top-section">
                {
                    warningsOnly ? <div styleName="warning-icon-cnt"><Warning/></div> :  <div styleName="error-icon" className={css(myStyles.errorIcon)}>!</div>
                }
                <div className={css(myStyles.title)} styleName='title'>
                    {title}
                </div> 
                <div className={css(myStyles.details)}>
                    {description}
                </div>
                {expanded && <div styleName="more-details-cnt">
                    {moreDetailsChildren}
                </div>}            
                <div onClick={this.onToggleDetails} styleName="show-details-icon">                {this.getExpandArrow()}
                </div>
            </div>
            {(expanded && resolution) && <div className={css(myStyles.resolution)} styleName="resolution-cnt">
                <div className={css(myStyles.resolutionTitle)}>
                   {this.props.getLabel('system_check_resolution_what_can_you_do')}
                </div>
                <div className={css(myStyles.resolutionDetails)}
                    dangerouslySetInnerHTML={{ __html: resolution }}/>
            </div>}
        </div>;        
    }
}


/* ResultSection.defaultProps = {
    title: 'Feature X is not enabled',
    description: 'You might face issues accessing this content in your browser.',
    moreDetails: <ul>Issues in these modules <li>Module A</li><li>Module B</li></ul>,
    resolution: <span><a href="#">Click here</a> to know how to enable this feature</span>
} */


export default ResultSection;
