import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { setLoadedModule, setLoadedModuleAvailabilityStatus } from 'actions/courses/actionCreators';
import {setApiError} from 'actions/apiErrors/actionCreators';

class FetchCourses extends NetPackWrapper {

    constructor() {
        super("GET");
    }

	prepareUrl = (apiInfoObject) => {
        return urls.get('DEVELOPMENT_PHASE').get('COURSE_MODULE')
            .replace('_COURSE_ID_', apiInfoObject.courseId)
            .replace('_MODULE_ID_', apiInfoObject.moduleId)
            .replace('_ACCESS_TOKEN_', this.getAccessTokenForDevPhase())
            .replace('_BATCH_ID_', apiInfoObject.batchId)
            .replace('_PHASE_ID_', apiInfoObject.phaseId)
            .replace('_PHASEITEMID_', apiInfoObject.phaseItemId);
    }

    beforeCall = (apiInfoObject) => {
        //this.dispatch(setLoadedModuleAvailabilityStatus(false));
        return apiInfoObject;
    }
	
/* 	shouldAjaxCall = () => {
        if (this.getState().get('batches').get('isBatchesListAvailable')) {
            return false;
        }
        return true;
    } */

    successCall = (response) => {
        this.dispatch(setLoadedModule(response));
        this.dispatch(setLoadedModuleAvailabilityStatus(true));
        this.dispatch(setApiError(
            'fetchModuleError',
            null
        ));
        return response;
    }

    onError = (response) => {
        this.dispatch(setApiError(
            'fetchModuleError',
            'Error loading module'
        ));
    }
}

export default new FetchCourses();
