import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

class FetchUserOrganizationsList extends NetPackWrapper {

	constructor() {
		super("GET", true);
	}

	prepareUrl = () => urls.get('PLATFORM').get('GET_USER_ORGANIZATIONS_LIST')

	successCall = (response) => {
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'We couldn\'t fetch the list of your organizations',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
	}

}

export default new FetchUserOrganizationsList();
