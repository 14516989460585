import React, { Component } from 'react';
import styles from './digitalBlur.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import DigitalToolHeader from '../digitalToolHeader';
import DigitalToolGuide from '../digitalToolGuide';
import ToolItemList from '../toolItemList';
import InformationIcon from 'svgComponents/informationIcon';
import Checkmark from 'svgComponents/checkmark';
import ArrowConnectorForDT from 'svgComponents/arrowConnectorForDT';
import HorizontalArrowConnector from 'svgComponents/arrowConnectorForDT1';
import { getDigitalToolkitFirebaseDatabase } from 'utils/firebase';
import { sortObjectOfObjectsBasedOnOrder } from 'utils/utilFunctions';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class DigitalBLUR extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showGuide: false
		}
	}

	toggleShowGuide = () => {
		this.setState({
			showGuide: !this.state.showGuide
		});
	}

	submitDataStrategy = () => {
		this.props.markModuleItemDone(this.props.match.params);
	}

	downloadDataStrategy = () => {
		alert("feature not available!");
	}

	addOrRemoveRole = async (selectedRoleId) => {
		const { match, currentUserDetails, moduleItem } = this.props;
		let refString = `digitalToolkit/userTools/user_${currentUserDetails.get('userID')}_batch_${match.params.batchId}_moduleItemId_${match.params.moduleItemId}_tool_${moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])}/data/selectedRoleId`;

		const digitalToolkitDbPromise = getDigitalToolkitFirebaseDatabase();
		const digitalToolkitDb = await digitalToolkitDbPromise; 
		
		const paletteRef = digitalToolkitDb.ref(refString);
		paletteRef.set({ [selectedRoleId]: selectedRoleId });
	}

	getToolItemListComp = (dataKey) => {
		const { toolData, toolUserData, colorPalette } = this.props;
		const selectedCP = toolUserData && toolUserData.data && toolUserData.data.selectedColorPalette ? toolUserData.data.selectedColorPalette[dataKey] : ""
		return (
			<ToolItemList
				type={dataKey}
				key={`key_${dataKey}`}
				userData={toolUserData && toolUserData.data && toolUserData.data.cards ? toolUserData.data.cards[dataKey] : {}}
				addNewUserDataRecord={this.props.addNewUserDataRecord}
				deleteRecord={this.props.deleteRecord}
				colorPalette={colorPalette}
				selectedColorPalette={selectedCP}
				setColorPalette={this.props.setColorPalette}
				cardStyles={{
					height: '240px',
					maxHeight: '240px',
					justifyContent: 'space-between',
					borderRadius: '6px 0 6px 6px',
					backgroundImage: `${selectedCP && colorPalette[selectedCP].cardBackground ? '' : 'linear-gradient(132deg, #477bc0, #4a53ae)'}`
				}}
				instructions={toolData.cardExamples[dataKey]}
			/>
		)
	}

	getRolesComponent = () => {
		const { toolData, toolUserData } = this.props;
		let myStyles = getSkin(this.props.skinGuide);
		let rolesComponent = [];
		for (let role in toolData.roles) {
			const isRoleSelected = toolUserData && toolUserData.data && toolUserData.data.selectedRoleId && toolUserData.data.selectedRoleId[toolData.roles[role].key] ? true : false;

			rolesComponent.push(
				<div key={`role_${role}`} styleName={`individual-role-cnt ${isRoleSelected ? 'selected' : ''}`} onClick={() => { this.addOrRemoveRole(toolData.roles[role].key) }}>
					<div styleName="info-icon-cnt">
						<InformationIcon fillColor={isRoleSelected ? 'white' : null} />
						<div
							className={css(myStyles.infoText)}
							styleName="capsule-info-card"
							dangerouslySetInnerHTML={{ __html: toolData.roles[role].infoText }}
						>
						</div>
					</div>
					<div className={css(isRoleSelected ? myStyles.selectedroleText : myStyles.roleText)}>{toolData.roles[role].name}</div>
					{
						isRoleSelected ?
							<div styleName="checkmark-icon-cnt">
								<Checkmark fillColor={isRoleSelected ? 'white' : null} />
							</div>
							:
							<div styleName="radio-button-cnt"></div>
					}
				</div>
			)
		}
		return rolesComponent;
	}

	getTopCards = (actors, rows, myStyles) => {
		let actorsCardsComp = [];
		let arrowsToConnect = 1;
		for (let actor in actors) {
			let rowCardsCom = [];
			for (let row in rows) {
				rowCardsCom.push(
					<div key={`${actors[actor].key}_${rows[row].key}`} styleName="actor-row-card">
						{this.getToolItemListComp(`${actors[actor].key}_${rows[row].key}`)}
					</div>
				)
			}
			actorsCardsComp.push(
				<div key={`actor_${actors[actor].key}`} styleName="individual-actor-cards-cnt">
					<div className={css(myStyles.strategyHeaderText)} styleName="header">{actors[actor].name}</div>
					<div styleName="actor-row-cards-cnt">
						{rowCardsCom}
					</div>
				</div>
			)
			if (arrowsToConnect < 3) {
				actorsCardsComp.push(
					<HorizontalArrowConnector />
				)
				arrowsToConnect += 1;
			}
		}
		let rowsHeaderComp = [];
		for (let row in rows) {
			rowsHeaderComp.push(
				<div
					key={`row_${rows[row].key}_header`}
					styleName="header-text"
					className={css(myStyles.blurActorHeaderText)}
				>
					{rows[row].name}
				</div>
			)
		}
		return (
			<div styleName="top-row-content">
				<div styleName="arrow-connector-cnt">
					<ArrowConnectorForDT />
				</div>
				<div styleName="actor-row-content-cnt">
					<div styleName="row-horizontal-header-cnt">
						{rowsHeaderComp}
					</div>
					<div styleName="actor-row-content">
						{actorsCardsComp}
					</div>
				</div>
			</div>
		);
	}

	getBLURCardsComponent = (blurRows, actors, strategies, myStyles) => {
		let BLURCardsComponent = [];
		// let myStyles = getSkin(this.props.skinGuide);
		for (let row in blurRows) {
			let actorsStrategiesCardsComponent = [];
			for (let actor in actors) {
				let cards = [];
				for (let strategy in strategies) {
					let dataKey = `${blurRows[row].key}_${actors[actor].key}_${strategies[strategy].key}`;
					cards.push(
						<div key={dataKey} styleName="blur-cards-cnt">
							{this.getToolItemListComp(dataKey)}
						</div>
					)
				}
				actorsStrategiesCardsComponent.push(
					<div key={`actor_${actors[actor].key}_content`} styleName="actor-wise-cards-cnt">
						<div styleName="role-cards-cnt-header">
							<div className={css(myStyles.blurActorHeaderText)} styleName="text">
								{actors[actor].name}
							</div>
						</div>
						<div styleName="cards">
							{cards}
						</div>
					</div>
				)
			}
			BLURCardsComponent.push(
				<div key={`row_${blurRows[row].key}_content`} styleName="blur-cards-inner-cnt">
					<div styleName="cards-cnt-header">
						<div className={css(myStyles.blurRowHeaderText)} styleName="text">
							{blurRows[row].name}
						</div>
					</div>
					<div style={{ width: '100%' }}>
						{actorsStrategiesCardsComponent}
					</div>
				</div>
			)
		}
		return BLURCardsComponent;
	}

	getBLURCards = (rows, actors, strategies, myStyles) => {
		let blurBannerContent = [];
		let strategiesHeaderContent = [];
		// let myStyles = getSkin(this.props.skinGuide);
		const { toolData } = this.props;
		let blur = ['B', 'L', 'U', 'R'];
		blurBannerContent = blur.map(letter =>
			<div key={`${letter.toLowerCase()}_label`} styleName="blur-meaning-cnt">
				<div className={css(myStyles.blurHeaderText)}>
					{letter}
				</div>
				<div className={css(myStyles.blurHeaderDescription)} style={{ width: '50%', margin: 'auto' }}>
					{toolData.labels[`${letter.toLowerCase()}_label`]}
				</div>
			</div>
		)
		for (let strategy in strategies) {
			strategiesHeaderContent.push(
				<div key={`strategy_${strategies[strategy].key}_header`} styleName="individual-strategy-header">
					<div className={css(myStyles.strategyHeaderText)}>
						{strategies[strategy].name}
					</div>
					<div className={css(myStyles.asStrategyLabel)}>
						{toolData.labels.as_strategy_label}
					</div>
					<div styleName="connector"></div>
				</div>
			)
		}
		return (
			<div styleName="blur-cards-outer-cnt">
				<div styleName="blur-banner-cnt">
					{blurBannerContent}
				</div>
				<div styleName="blur-strategies-header-cnt">
					{strategiesHeaderContent}
				</div>
				{this.getBLURCardsComponent(rows, actors, strategies, myStyles)}
			</div>
		)
	}

	getBottomCards = (bottomColumns, myStyles) => {
		let cardsComponent = [];
		for (let column in bottomColumns) {
			cardsComponent.push(
				<div key={`card_${bottomColumns[column].key}`} styleName="bottom-cards-holder">
					<div className={css(myStyles.strategyHeaderText)} style={{ textAlign: 'center' }}>
						{bottomColumns[column].name}
					</div>
					<div styleName="bottom-card-cnt">
						{this.getToolItemListComp(bottomColumns[column].key)}
					</div>
				</div>
			)
		}
		return (
			<div styleName="bottom-cards-holder-cnt">
				{cardsComponent}
			</div>
		);
	}

	renderDigitalBLURContent = () => {
		let myStyles = getSkin(this.props.skinGuide);
		const { toolData } = this.props;
		let orderedTopRows = {};
		let orderedActors = {};
		let orderedBottomCards = {};
		let orderedBlurRows = {};
		let orderedBlurStrategies = {};
		if (toolData && toolData.topRows) {
			orderedTopRows = sortObjectOfObjectsBasedOnOrder(toolData.topRows)
		}
		if (toolData && toolData.actors) {
			orderedActors = sortObjectOfObjectsBasedOnOrder(toolData.actors)
		}
		if (toolData && toolData.bottomCards) {
			orderedBottomCards = sortObjectOfObjectsBasedOnOrder(toolData.bottomCards)
		}
		if (toolData && toolData.blurRows) {
			orderedBlurRows = sortObjectOfObjectsBasedOnOrder(toolData.blurRows)
		}
		if (toolData && toolData.blurStrategies) {
			orderedBlurStrategies = sortObjectOfObjectsBasedOnOrder(toolData.blurStrategies)
		}
		return (
			<div styleName="digital-blur-content-cnt">
				<div styleName="digital-blur-content">
					<div className={css(myStyles.stepHeaderText)} styleName="step-header-text">
						{toolData.labels.step1_label || "Step 1: Select any role from below"}
					</div>
					<div styleName="roles-cnt">
						{this.getRolesComponent()}
					</div>
				</div>
				<div styleName="digital-blur-content">
					<div className={css(myStyles.stepHeaderText)} styleName="step-header-text">
						{toolData.labels.step2_label || "Step 2: Fill the Card below"}
					</div>
					{this.getTopCards(orderedActors, orderedTopRows, myStyles)}
					{this.getBLURCards(orderedBlurRows, orderedActors, orderedBlurStrategies, myStyles)}
					{this.getBottomCards(orderedBottomCards, myStyles)}
				</div>
			</div>
		)
	}

	render() {
		const { toolData } = this.props;
		return (
			<div styleName="digital-blur-cnt">
				<DigitalToolHeader
					toggleShowGuide={this.toggleShowGuide}
					submitAction={this.submitDataStrategy}
					downloadAction={this.downloadDataStrategy}
					toolData={toolData}
				/>
				{
					this.renderDigitalBLURContent()
				}
				{
					this.props.toolData && this.state.showGuide &&
					<DigitalToolGuide
						closeGuide={this.toggleShowGuide}
						guideData={this.props.toolData.guide}
						labels={this.props.toolData.labels}
					/>
				}
			</div>
		);
	}
}

export default DigitalBLUR;