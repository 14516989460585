import React, { Component } from 'react';
import styles from './toolFullScreen.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';
import FullScreenIcon from 'svgComponents/fullScreenIcon';
import Close from 'svgComponents/close';
import BackArrow from 'svgComponents/backArrow';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import Dialog from 'commonComponents/dialog';
import SupportIcon from 'svgComponents/supportIcon';
import AppLoader from 'commonComponents/appLoader';
import EmpathyMap from 'components/moduleItems/digitalTool/empathyMap';
import BuildingDataStrategy from 'components/moduleItems/digitalTool/buildingDataStrategy';
import BuildAPlatform from 'components/moduleItems/digitalTool/buildAPlatform';
import DesignAStrategy from 'components/moduleItems/digitalTool/designAStrategy';
import ValueProposition from 'components/moduleItems/digitalTool/valueProposition';
import BuildingAgilityAsStrategy from 'components/moduleItems/digitalTool/buildingAgilityAsStrategy';
import DriversForDigitalBlur from 'components/moduleItems/digitalTool/driversForDigitalBlur';
import APIExposureAreas from 'components/moduleItems/digitalTool/apiExposureAreas';
import DesignExperienceJourney from 'components/moduleItems/digitalTool/designExperienceJourney';
import DigitalBLUR from 'components/moduleItems/digitalTool/digitalBlur';
import FullScreen from 'commonComponents/fullScreen';
import environment from 'utils/environment';
import { isLaunchedFromExternalIntegration } from 'utils/externalIntegrations';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class ToolFullScreen extends Component {

	constructor(props) {
		super();
		this.state = {
			isFull: false,
			isExitPopupOpen: false,
			showLoader: true
		}
	}

	componentWillMount() {
		let supportElement = document.getElementById('freshwidget-button');

		if (supportElement)
			supportElement.style.visibility = 'hidden';
	}

	componentWillUnmount() {
		let supportElement = document.getElementById('freshwidget-button');
		if (supportElement)
			supportElement.style.visibility = 'visible';
	}

	sendSegmentData = (event) => {
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash
		}
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	onClickOfFullScreen = () => {
		this.sendSegmentData('Item Fullscreen clicked');
		let newState = {
			isFull: !this.state.isFull,
		};
		this.setState(newState);
	}

	exitFullScreen = () => {
		this.setState({
			isFull: false
		})
	}

	openPopup = () => {
		// this.sendSegmentData('Item fullscreen exit clicked');
		this.setState({
			isExitPopupOpen: true
		})
	}

	closePopup = () => {
		this.setState({
			isExitPopupOpen: false
		})
	}

	getLoader = () => {
		return <AppLoader userState={this.props.userState} />;
	}

	toggleLoader = () => {
		this.setState({
			showLoader: !this.state.showLoader
		});
	}

	onClickOfSupport = () => {
		this.sendSegmentData('Item Support clicked');
		let supportElement = document.getElementById('freshwidget-button').querySelector('a');
		if (supportElement)
			supportElement.click()
	}

	getDigitalTool = () => {
		const { moduleItem } = this.props;
		switch (moduleItem.getIn(['contentDetails', 'al_data', 'tool_id'])) {
			case "empathyMapEnglish":
				return <EmpathyMap {...this.props} />
			case "buildingDataStrategyEnglish":
				return <BuildingDataStrategy {...this.props} />
			case "buildAPlatformEnglish":
				return <BuildAPlatform {...this.props} />
			case "designAStrategyEnglish":
				return <DesignAStrategy {...this.props} />
			case "valuePropositionEnglish":
				return <ValueProposition {...this.props} />
			case "buildingAgilityAsStrategyEnglish":
				return <BuildingAgilityAsStrategy {...this.props} />
			case "digitalBlurDriversEnglish":
				return <DriversForDigitalBlur {...this.props} />
			case "apiExposureAreasEnglish":
				return <APIExposureAreas {...this.props} />
			case "designExperienceJourneyEnglish":
				return <DesignExperienceJourney {...this.props} />
			case "digitalBlurEnglish":
				return <DigitalBLUR {...this.props} />
			default:
				return <div>This type of tool is not supported!</div>
		}
	}

	render() {
		const {
			getLabel,
			skinGuide,
			getLabelWithDefaults
		} = this.props;

		const { isFull, isExitPopupOpen, showLoader } = this.state;

		const myStyles = getSkin(skinGuide);

		let fullScreenToggleText = getLabel('fullscreen_label');
		if (isFull) {
			fullScreenToggleText = getLabel('leave_fullscreen_label');
		}

		return (
			<FullScreen fullscreenEnabled={isFull} onExitFullScreen={this.exitFullScreen}>
				<div styleName="tool-container">
					{
						(isExitPopupOpen)
							?
							<div styleName="overlay">
								<Dialog
									key="quiz-confirmation-dialog"
									dialogMessage={getLabelWithDefaults('exit_confirmation_message', 'Are you sure you want to exit?')}
									okAction={this.props.onExit}
									cancelAction={this.closePopup}
									okButtonText="YES"
								/>
							</div>
							:
							null
					}
					<div styleName="tool-header">

						<div styleName="header-left">
							<div styleName="item" onClick={this.onClickOfFullScreen}>
								<FullScreenIcon />
								<div className={css(myStyles.text)}>{fullScreenToggleText}</div>
							</div>
						</div>
						{
							!isLaunchedFromExternalIntegration()
							&& environment.REACT_APP_ENABLE_FRESHDESK === 'true'
							&&
							<div styleName="support-btn-cnt" onClick={this.onClickOfSupport}>
								<div styleName="support-image-cnt">
									<SupportIcon />
								</div>
								<div className={css(myStyles.supportBtnText)}>
									{getLabel('freshdesk_support_label').toLowerCase()}
								</div>
							</div>
						}
						<div styleName="header-right" onClick={this.openPopup}>
							<div styleName="icon"><Close /></div>
							<div className={css(myStyles.text)}>{getLabel('exit_label')}</div>
						</div>

					</div>

					<div styleName="product">
						{
							this.getDigitalTool()
						}
					</div>
				</div>
			</FullScreen>
		);
	}
}

export default ToolFullScreen;