import React, { Component } from 'react';
import { css } from 'aphrodite/no-important';
import styles from './moreDetailsRow.module.sass';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin';
import MenuClose from 'svgComponents/menuClose';
import LockTooltip from 'commonComponents/lockTooltip';
import { isMobile } from 'react-device-detect';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class MoreDetailsRow extends Component {
    render(){
        const {detailText, tooltipText, infoButtonText} = this.props;
        const styles = getSkin(this.props.skinGuide);
        return <div styleName="row">
            <span styleName="icon"><MenuClose fillColor='#EB3131'/></span>
            <span styleName="detail-text" className={css(styles.text)}>{detailText}</span>
                {tooltipText  ? 
                <span styleName="info-button" className={css(styles.reason)}>
                    <LockTooltip
                        tooltipText={tooltipText}
                        style={isMobile ? { top: -60, left: -50, width: 170 }: { top: 25, left: 100, width: 170 }}
                        skinGuide={this.props.skinGuide}>
                        {infoButtonText}
                    </LockTooltip>     
                </span>:  <span styleName="info-button" className={css(styles.reason)}></span>}
        </div>
    }
}

export default MoreDetailsRow;