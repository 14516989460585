import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);
    
	return StyleSheet.create({
        preReqTitle: {
            ...myTypography.h4,
            color: skin.get('greyColor1')
        },
        preReqDesc: {
            ...myTypography.h6,
            fontWeight: 400,
            color: hexToRgbA(skin.get('greyColor1'), 0.85)
        },
    });
}

export default getSkin;