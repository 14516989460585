/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: BaseContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * base container is rendered on initial load of application and mounted only
 * once displayed on every route of the application and all other containers are
 * children of this base container
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import GenericErrorBoundary from 'components/errorBoundaries';

import PhasesList from '../components/phases/phasesList/PhasesList';

class PhasesContainer extends React.Component {

	componentDidMount() {
		 
	}

	render() {
		return (
			<GenericErrorBoundary>
				<PhasesList {...this.props} />
			</GenericErrorBoundary>
		);
	}
}

PhasesContainer.propTypes = {};

PhasesContainer.defaultProps = {
	phasesList: [
		{
			id: 1,
			name: 'phase_1'
		},
		{
			id: 2,
			name: 'phase_2'
		},
		{
			id: 3,
			name: 'phase_3'
		}
	]
};

// const mapStateToProps = (state) => ({ ...(state.toJS()) });

// const mapDispatchToProps = (dispatch) => ({
// 	fetchAppBase() {
// 		dispatch(fetchAppBaseData());
// 	},

// 	clearError() {
// 		dispatch(clearErrorAction());
// 	}
// });

//export default connect(mapStateToProps, mapDispatchToProps)(PhasesContainer);
export default PhasesContainer;