import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { setPage } from 'actions/page/actionCreators';

class FetchPage extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject) => urls.get('PLATFORM').get('GET_PAGE').replace(
        'PAGE_ID',
        apiInfoObject.pageId
    );

    successCall = (response) => {
        this.dispatch(setPage(response));
        return response;
    }

    onError = (response) => {
        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, something went wrong while loading the page. Please refresh the page.',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }

}

export default new FetchPage();
