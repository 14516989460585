import React, { Component } from 'react';
import styles from './upArrow.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class UpArrow extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 11 7">
					<path fill={skinHex.get('secondaryColor')} fillRule="nonzero" d="M.292 5.951c.403.412.962.444 1.454 0l3.605-3.456 3.605 3.456c.491.444 1.052.412 1.452 0 .402-.41.376-1.104 0-1.49C10.033 4.077 6.077.31 6.077.31a1.01 1.01 0 0 0-1.454 0S.67 4.076.293 4.462c-.378.385-.403 1.079 0 1.49z"/>
				</svg>
			</div>
		);
	}
}



export default UpArrow;
