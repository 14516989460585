import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { css } from 'aphrodite/no-important';
import styles from './miniThumbsUpDown.module.sass';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';
import Like from 'svgComponents/like';
import Unlike from 'svgComponents/unlike';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class MiniThumbsUpDown extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			selectedOptionId: props.question.get('selectedOptionId')
		}
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			selectedOptionId: nextProps.question.get('selectedOptionId')
		})
	}

	setSelectedOption = (option, questionId) => {
		this.setState({
			selectedOptionId: option.get('id')
		}, () => {
			let payload = {
				selectedOptionId: option.get('id'),
			};
			this.props.submitAnswer(payload, questionId);
		})
	}

	renderOptions = () => {
		const { selectedOptionId } = this.state;
		const { question } = this.props;

		if (question) {
			return (
				question.get('options').map((option) => {
					let optionStyles = {}
					if (selectedOptionId == option.get('id')) {
						optionStyles = {
							...optionStyles,
							boxShadow: '0 1px 0 0 rgba(0, 0, 0, 0.1)',
							backgroundColor: '#2c4bb7'
						}
					}
					if (option.get('icon_key') == 'tup') {
						return (
							<div styleName="inside-circle"
								style={optionStyles}
								onClick={() => {
									this.setSelectedOption(option, question.get('id'))
								}}>
								<Like color={selectedOptionId && (selectedOptionId == option.get('id')) ? '#FFFFFF' : '#DCDCDC'} height="20px" width="12px" />
							</div>
						);
					}
					else {
						return (
							<div styleName="inside-circle"
								style={optionStyles}
								onClick={() => {
									this.setSelectedOption(option, question.get('id'))
								}}>
								<Unlike color={selectedOptionId && (selectedOptionId == option.get('id')) ? '#FFFFFF' : '#DCDCDC'} height="20px" width="12px" />
							</div>
						);
					}
				})
			)
		}
	}

	render() {
		const styles = getSkin(this.props.skinGuide);
		const { question } = this.props;
		return (
			<div style={{ height: '36px', width: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
				<div styleName="mini-thumbs-options-cnt">
					<div className={css(styles.rateContentLabel)}>{question.get('text')}</div>
					{this.renderOptions()}
				</div>
			</div>
		)
	}
}

export default MiniThumbsUpDown;