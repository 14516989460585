/**
 * @author: Amul
 * @since: Mon Jan 08 2020 16:18:31 GMT+0530 (IST)
 * @file: coursesModulesList.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
  Keep loaded modules here
 *
 **/

import actionConsts from 'constants/actions';
import Immutable from 'immutable';
import { setOrReplaceObjectInList } from 'utils/objectUtils';

const initialState = Immutable.fromJS([]);

const coursesModulesList = (state = initialState, action = {}) => {
    switch (action.type) {

        case actionConsts.get('SET_COURSES_MODULES_LIST'):
            const updatedCourseModulesInfo = {
                id: action.courseId,
                phaseItemId: action.phaseItemId,
                isCourseModulesListAvailable: true,
                courseModulesList: Immutable.fromJS(action.courseModulesList)
            };
            return setOrReplaceObjectInList(
                state, updatedCourseModulesInfo, 'id', 'id'
            );      
        case actionConsts.get('UNSET_COURSES_MODULES_LIST'):
            return initialState;
        default:
            return state;
    }
};

export default coursesModulesList;