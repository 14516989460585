import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './ThumbsUpDown.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyLabel from 'utils/LabelProvider';
import Like from 'svgComponents/like';
import Unlike from 'svgComponents/unlike';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ThumbsUpDown extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: this.getOptionName(props)
        }
    }

    getOptionName = (props) => {
        const selectedOptionId = props.question.get('selectedOptionId');
        const options = props.question.get('options').toJS();
        for (let i = 0; i < options.length; i++) {
            if (options[i].id == selectedOptionId) {
                return options[i].icon_key;
            }
        }
        return null;
    }

    setOption = (option, questionId) => {
        this.setState({
            selected: option.get('icon_key')
        })
        const payload = {
            selectedOptionId: option.get('id'),
        };
        this.props.submitAnswer(payload, questionId, this.props.feedbackItemId);
    }


    renderOptions = () => {
        const { selected } = this.state;
        const questionId = this.props.question.get('id');
        return (
            this.props.question.get('options').map((option) => {
                if (option.get('icon_key') == 'tup') {
                    return (
                        <div key={`option-type-${option.get('icon_key')}`} styleName="outside-circle">
                            <div styleName="inside-circle"
                                onClick={() => {
                                    this.setOption(option, questionId)
                                }}>
                                <Like color={(selected == 'tup') ? '#2c4bb7' : '#DCDCDC'} />
                            </div>
                        </div>
                    );
                }
                else {
                    return (
                        <div key={`option-type-${option.get('icon_key')}`} styleName="outside-circle tdown">
                            <div styleName="inside-circle"
                                onClick={() => { this.setOption(option, questionId) }}>
                                <Unlike color={(selected == 'tdown') ? '#2c4bb7' : '#DCDCDC'} />
                            </div>
                        </div>
                    );
                }
            })
        );
    }

    renderFeedbackBox = (myStyles) => {
        return (
            <div styleName="feedback-box-container">
                <div styleName="assessment-result-text" className={css(myStyles.assessmentResultText)}>
                    {this.props.question.get('text')}
                </div>
                <div styleName="options-container">
                    {this.renderOptions()}
                </div>
            </div>
        );
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return this.renderFeedbackBox(myStyles);
    }
}

export default ThumbsUpDown;
