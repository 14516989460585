import React, { Component } from 'react';
import styles from './completeSummary.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import DetailedAnalysis from './detailedAnalysis';
import LowHighPerformance from './lowHighPerformance';
import ScoreSnapshot from './scoreSnapshot';
import OverallPerformance from './overallPerformance';
import UpArrow from 'svgComponents/upArrow';
import DownArrow from 'svgComponents/downArrow';
import { getOrganizationId } from 'utils/orgUtils';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CompleteSummary extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showDetailedAnalysis: false
		};
	}

	toggleDetailedAnalysis = () => {
		this.setState({
			showDetailedAnalysis: !this.state.showDetailedAnalysis
		});
	}

	renderDetailedAnalysis = () => {
		if (!this.state.showDetailedAnalysis) {
			return null;
		}
		return (
			<div styleName="detailed-analysis-container">
				<DetailedAnalysis
					measuresList={this.props.measures.get('measuresList')}
					userMeasuresList={this.props.measures.get('userMeasuresList')}
					match={this.props.match}
				/>
			</div>
		);
	}

	getPhaseItemCount = () => {
		const { phaseId } = this.props.match.params;
		const items = this.props.items.get('itemsList');
		const phaseItems = items.filter((eachItem) => {
			return (eachItem.get('phaseId') == phaseId);
		});
		return phaseItems.size;
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const shouldHideTop3Sections = getOrganizationId() == 1045; 

		return (
			<div
				className={css(myStyles.completeSummaryContainer)}
				styleName="complete-summary-container"
			>
				{shouldHideTop3Sections ? null : <>
				<OverallPerformance userDetails={this.props.measures.get('userMeasuresListAdditionalDetails')}
					itemCount={this.getPhaseItemCount()}/>
				<LowHighPerformance
					measuresList={this.props.measures.get('measuresList')}
					userHighScoresList={this.props.measures.get('userHighScoresList')}
					userLowScoresList={this.props.measures.get('userLowScoresList')}
				/>
				</>}
				<ScoreSnapshot
					measuresList={this.props.measures.get('measuresList')}
					userMeasuresList={this.props.measures.get('userMeasuresList')}
					activePhaseDetails={this.props.activePhaseDetails}
				/>
				{this.renderDetailedAnalysis()}
				<div styleName="show-details-button-container">
					<div styleName="show-button-content" onClick={() => { this.toggleDetailedAnalysis() }}>
						<div className={css(myStyles.detailedName)} styleName="detailed-name">
							{this.state.showDetailedAnalysis
								? this.props.getLabel('hide_detailed_analysis')
								: this.props.getLabel('show_detailed_analysis')
							}
						</div>
						<div styleName="show-button-arrow">
							{
								this.state.showDetailedAnalysis
									? <UpArrow />
									: <DownArrow />
							}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CompleteSummary;
