import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import FeedbackPattern from 'images/feedback_pattern.png';
import FeedbackBackground from 'images/feedback_pattern.png';

const getSkin = (theme) => {
	const skinHex = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
		mainFeedbackBackground: {
			backgroundColor: skinHex.get('greyColor6')
		},
		mainFeedbackImage: {
			backgroundImage: `url(${FeedbackPattern})`,
			backgroundSize: 'contain'
		},
		itemCompletionText: {
			...myTypography.h5,
			color: hexToRgbA(skinHex.get('greyColor1'), 0.85)
			// color: skinHex.get('white')
		},
		journeyCompletionText: {
			...myTypography.h5,
			color: hexToRgbA(skinHex.get('greyColor1'), 0.85)
		},
		itemFeedbackLink: {
			...myTypography.button,
			fontWeight: 'normal',
			fontStyle: 'italic',
			color: skinHex.get('primaryColor'),
			// color: skinHex.get('white'),
			textDecoration: 'underline',
			cursor: 'pointer'
		},
		feedbackLink: {
			...myTypography.button,
			fontWeight: 700,
			fontStyle: 'italic',
			color: skinHex.get('primaryColor'),
			textDecoration: 'underline',
			cursor: 'pointer'
		},
		itemFeedbackBackground: {
			backgroundColor: skinHex.get('greyColor6')
			// backgroundImage: `linear-gradient(107deg, #5782dc, ${skinHex.get('primaryColor')})`
		}
	});
}

export default getSkin;