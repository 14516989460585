import FetchOverallProgressData from 'actions/serverCalls/analytics/FetchOverallProgressData';
import FetchDayWiseTimeSpentData from 'actions/serverCalls/analytics/FetchDayWiseTimeSpentData';
import FetchPlatformWiseTimeSpent from 'actions/serverCalls/analytics/FetchPlatformWiseTimeSpent';
import FetchStatsData from 'actions/serverCalls/analytics/FetchStatsData';
import FetchTimeSpentPeriodicallyData from 'actions/serverCalls/analytics/FetchTimeSpentPeriodicallyData';
import FetchProgressPeriodicallyData from 'actions/serverCalls/analytics/FetchProgressPeriodicallyData';

import FetchAssessmentScoreData from 'actions/serverCalls/analytics/FetchAssessmentScoreData';
import FetchAssessmentProgressData from 'actions/serverCalls/analytics/FetchAssessmentProgressData';
import FetchAssessmentTimeSpentData from 'actions/serverCalls/analytics/FetchAssessmentTimeSpentData';
import FetchAssessmentMeasuresData from 'actions/serverCalls/analytics/FetchAssessmentMeasuresData';

import FetchDevelopmentScoreData from 'actions/serverCalls/analytics/FetchDevelopmentScoreData';
import FetchDevelopmentProgressData from 'actions/serverCalls/analytics/FetchDevelopmentProgressData';
import FetchDevelopmentTimeSpentData from 'actions/serverCalls/analytics/FetchDevelopmentTimeSpentData';
import FetchDevelopmentMeasuresData from 'actions/serverCalls/analytics/FetchDevelopmentMeasuresData';

import FetchLeaderboardData from 'actions/serverCalls/analytics/FetchLeaderboardData';
import FetchOverallPerformanceData from 'actions/serverCalls/analytics/FetchOverallPerformanceData';

import FetchNeedleMovementData from 'actions/serverCalls/analytics/FetchNeedleMovementData';

import FetchGenericData from 'actions/serverCalls/analytics/FetchGenericData';

import FetchWidgetAnalytics from 'actions/serverCalls/analytics/FetchWidgetAnalytics';
import FetchAnalyticsButtonData from 'actions/serverCalls/analytics/FetchAnalyticsButtonData';

import FetchUser from 'actions/serverCalls/users/FetchUser';
import store from 'store/store';

const fetchPayloadObject = (batchId, userId) => {
    const payload = {
        batchId,
        userId,
        headers: {
            'x-api-key': 'abc'
        }
    }
    return payload;
}

const fetchPhasePayloadObject = (batchId, userId, phaseId) => {
    const payload = {
        batchId,
        userId,
        phaseId,
        headers: {
            'x-api-key': 'abc'
        }
    }
    return payload;
}

const fetchOverallProgressData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchOverallProgressData.call(payload);
}

const fetchDayWiseTimeSpentData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchDayWiseTimeSpentData.call(payload);
}

const fetchPlatformWiseTimeSpentData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchPlatformWiseTimeSpent.call(payload);
}

const fetchStatsData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchStatsData.call(payload);
}

const fetchAssessmentScoreData = (batchId, userId, phaseId) => (dispatch, getState) => {
    const payload = fetchPhasePayloadObject(batchId, userId, phaseId);
    return FetchAssessmentScoreData.call(payload);
}

const fetchAssessmentProgressData = (batchId, userId, phaseId) => (dispatch, getState) => {
    const payload = fetchPhasePayloadObject(batchId, userId, phaseId);
    return FetchAssessmentProgressData.call(payload);
}

const fetchAssessmentTimeSpentData = (batchId, userId, phaseId) => (dispatch, getState) => {
    const payload = fetchPhasePayloadObject(batchId, userId, phaseId);
    return FetchAssessmentTimeSpentData.call(payload);
}

const fetchAssessmentMeasuresData = (batchId, userId, phaseId) => (dispatch, getState) => {
    const payload = fetchPhasePayloadObject(batchId, userId, phaseId);
    return FetchAssessmentMeasuresData.call(payload);
}

const fetchDevelopmentScoreData = (batchId, userId, phaseId) => (dispatch, getState) => {
    const payload = fetchPhasePayloadObject(batchId, userId, phaseId);
    return FetchDevelopmentScoreData.call(payload);
}

const fetchDevelopmentProgressData = (batchId, userId, phaseId) => (dispatch, getState) => {
    const payload = fetchPhasePayloadObject(batchId, userId, phaseId);
    return FetchDevelopmentProgressData.call(payload);
}

const fetchDevelopmentTimeSpentData = (batchId, userId, phaseId) => (dispatch, getState) => {
    const payload = fetchPhasePayloadObject(batchId, userId, phaseId);
    return FetchDevelopmentTimeSpentData.call(payload);
}

const fetchDevelopmentMeasuresData = (batchId, userId, phaseId) => (dispatch, getState) => {
    const payload = fetchPhasePayloadObject(batchId, userId, phaseId);
    return FetchDevelopmentMeasuresData.call(payload);
}

const fetchTimeSpentPeriodicallyData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchTimeSpentPeriodicallyData.call(payload);
}

const fetchProgressPeriodicallyData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchProgressPeriodicallyData.call(payload);
}

const fetchLeaderboardData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchLeaderboardData.call(payload);
}

const fetchOverallPerformanceData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchOverallPerformanceData.call(payload);
}

const fetchNeedleMovementData = (batchId, userId) => (dispatch, getState) => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchNeedleMovementData.call(payload);
}

const fetchGenericAnalyticsData = (batchId, userId) => (dispatch, getState) => {
    return FetchUser.call().then(() => {
        let analyticsUserId = store.getState().get('user').get('userID');
        const payload = fetchPayloadObject(batchId, +analyticsUserId);
        FetchGenericData.call(payload);
    });
}

const fetchWidgetAnalyticsData = (batchId, userId, phaseId) => () => {
    const payload = {
        batchId,
        userId,
        phaseId,
        headers: {
            'x-api-key': 'abc'
        }
    };
    return FetchWidgetAnalytics.call(payload);
}

const fetchAnalyticsButtonData = (batchId, userId) => () => {
    const payload = fetchPayloadObject(batchId, userId);
    return FetchAnalyticsButtonData.call(payload);
}

export {
    fetchOverallProgressData,
    fetchDayWiseTimeSpentData,
    fetchPlatformWiseTimeSpentData,
    fetchStatsData,
    fetchAssessmentScoreData,
    fetchAssessmentProgressData,
    fetchAssessmentTimeSpentData,
    fetchAssessmentMeasuresData,
    fetchDevelopmentScoreData,
    fetchDevelopmentProgressData,
    fetchDevelopmentTimeSpentData,
    fetchDevelopmentMeasuresData,
    fetchTimeSpentPeriodicallyData,
    fetchProgressPeriodicallyData,
    fetchLeaderboardData,
    fetchOverallPerformanceData,
    fetchNeedleMovementData,
    fetchWidgetAnalyticsData,
    fetchAnalyticsButtonData,
    fetchGenericAnalyticsData,
}