/**
 * @author: ajinkya
 * @since: Thu Aug 24 2018 15:32:00 GMT+0530 (IST)
 * @file: DashboardContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Items container is responsible for displaying module item details
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import ModuleItemScreen from 'components/moduleItems/moduleItemScreen';
import GenericErrorBoundary from 'components/errorBoundaries';
import {
	fetchModuleItem,
	markModuleItemRead,
	fetchProductLaunch,
	updateMediaFileStatus,
	submitAssignment,
	uploadFile,
} from 'actions/moduleItems/apiActions';
import Immutable from 'immutable';

import {
	unsetProductLaunch,
	unsetModuleItem,
	removeFile
} from 'actions/moduleItems/actionCreators';

import { setCourseAvailablity } from 'actions/courses/actionCreators';
import setUserState from 'actions/userState/actionCreators';

import { filterObjectFromArray } from 'utils/objectUtils';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import { getUserActiveBatchDetails } from 'utils/batchUtils';
import enumConstants from 'constants/enums';
import {
	startTracking,
	endTracking
} from 'utils/mixpanelTracking';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';
import { markModuleItemDone } from 'actions/moduleItems/apiActions';

class ModuleItemContainer extends React.Component {
	componentWillMount() {
		this.props.fetchModuleItem(this.props.match.params);
	}

	getPhase = () => {
		const { match, phases } = this.props;
		const phase = phases.get('phasesList').find(phase =>
			phase.get('id') == match.params.phaseId
		);
		return phase;
	}

	shouldUnsetCourseAvailability = () => {
		const phase = this.getPhase();
		if (phase.get('settings')) {
			if (phase.get('settings').get('developmentItemType') == 'MODULE' || phase.get('settings').get('developmentItemType') == 'MODULE_ITEMS') {
				return false;
			}
		}

		return true;
	}

	componentDidMount() {
		startTracking(true, this.props.match.params, 'ModuleItem');
		this.props.setUserState({
			showStickyHeader: false
		})
	}

	componentWillReceiveProps(nextProps) {

		if (this.props.match.params.moduleItemId != nextProps.match.params.moduleItemId) {
			endTracking();
			startTracking(true, nextProps.match.params, 'ModuleItem');
			this.props.unsetModuleItem();
			this.props.fetchModuleItem(nextProps.match.params, false, false);
		}
		if (
			nextProps.moduleItem.get('isModuleItemDataAvailable')
			&& !this.props.moduleItem.get('isModuleItemDataAvailable')
		) {
			document.title = `${nextProps.moduleItem.getIn(['moduleItem', 'type_label'])} - ${nextProps.moduleItem.getIn(['moduleItem', 'title'])} - Aktivlearn Plus`;
			this.sendSegmentData(nextProps);
		}
	}

	sendSegmentData = (nextProps) => {
		let segmentData = {
			...(nextProps.match.params),
			url: window.location.href,
			pathHash: window.location.hash,
			moduleItemName: nextProps.moduleItem.getIn(['moduleItem', 'title']),
			moduleItemType: nextProps.moduleItem.getIn(['moduleItem', 'type_label']),
			completed: nextProps.moduleItem.getIn(['moduleItem', 'completion_requirement', 'completed'])
		};
		window.analytics.page(
			`Item Page Opened - ${nextProps.moduleItem.getIn(['moduleItem', 'type_label'])}`,
			segmentData,
			getAmplitudeSessionDetails()
		)
	}

	componentWillUnmount() {
		this.props.unsetModuleItem();
		endTracking();
		if (this.shouldUnsetCourseAvailability()) {
			this.props.setCourseAvailablity(false);
		}
	}

	getPhaseItemData = () => {
		const userActiveBatchDetails = getUserActiveBatchDetails(this.props.batches);
		const { items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails
		);
		const phaseItemData = items.get('itemsList').find(item => item.get('id') === +this.props.match.params.phaseItemId);

		if (!phaseItemData) return null;

		const userPhaseItemData = filterObjectFromArray(this.props.items.get('userItemsList'), 'id', phaseItemData.get('id'));
		return phaseItemData.set('isCompleted', userPhaseItemData.get('workflowStatus') == enumConstants.get('COMPLETED'));
	}

	render() {
		let phaseItemData = this.getPhaseItemData();
		let phase = this.getPhase();
		return (
			<GenericErrorBoundary>
				<div>
					{
						<ModuleItemScreen
							phaseItemData={phaseItemData}
							phase={phase}
							{...this.props}
						/>
					}
				</div>
			</GenericErrorBoundary>

		);
	}
}

ModuleItemContainer.propTypes = {};

ModuleItemContainer.defaultProps = {};


const mapStateToProps = (state) => {
	return {
		moduleItem: state.get('moduleItem'),
		tools: state.get('tools'),
		quiz: Immutable.fromJS(state.get('quiz')),
		course: state.get('course'),
		batches: state.get('batches'),
		phases: state.get('phases'),
		items: state.get('items'),

		fetchModuleItemError: state.get('apiErrors').get('fetchModuleItemError'),
		developmentProductLaunchError: state.get('apiErrors').get('developmentProductLaunchError'),

		userState: state.get('userState')
	}
};

const mapDispatchToProps = (dispatch) => ({
	fetchModuleItem: (params, shouldFetchBaseData = true) => {
		dispatch(fetchModuleItem(params, shouldFetchBaseData));
	},
	markModuleItemRead: (params) => {
		markModuleItemRead(params);
	},
	fetchProductLaunch: (params) => {
		fetchProductLaunch(params);
	},
	updateMediaFileStatus: (params) => {
		updateMediaFileStatus(params);
	},
	unsetProductLaunch: () => {
		dispatch(unsetProductLaunch());
	},
	submitAssignment: (urlParams, payload) => {
		dispatch(submitAssignment(urlParams, payload));
	},
	setCourseAvailablity: (status) => {
		dispatch(setCourseAvailablity(status));
	},
	uploadFile: (params, file) => {
		dispatch(uploadFile(params, file));
	},
	unsetModuleItem: () => {
		dispatch(unsetModuleItem());
	},
	removeFile: (id) => {
		dispatch(removeFile(id));
	},
	setUserState: (payload) => {
		dispatch(setUserState(payload))
	},
	markModuleItemDone: (params) => {
		markModuleItemDone(params);
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleItemContainer);
