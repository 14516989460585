import FetchBatches from 'actions/serverCalls/batches/FetchBatches';
import FetchUserBatches from 'actions/serverCalls/batches/FetchUserBatches';
import { resolvePromise, rejectPromise } from 'utils/promise';

import {
    updateFetchBatchesListStatus
} from 'actions/loaders/actionCreators';


import SetActiveBatch from 'actions/serverCalls/batches/SetActiveBatch';
import SetBatchWorkflowStatus from 'actions/serverCalls/batches/SetBatchWorkflowStatus';
import FetchUserBatchDetails from 'actions/serverCalls/batches/FetchUserBatchDetails';
import ResetBatchProgressForUser from 'actions/serverCalls/batches/ResetBatchProgressForUser';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import { scrollToTop } from 'utils/webAppRoutes/validateRoute';

/*Note: major functionality moved to ./newApiActions*/

const fetchBatchesList = () => (dispatch, getState) => {
    dispatch(updateFetchBatchesListStatus(true));
    return FetchUserBatches.call({ showErrorPage: false })
        .then(() => {
            const userBatchesList = getState().get('batches').get('userBatchesList');
            const batchIdsList = userBatchesList.map((userBatch) => userBatch.get('batchId'));
            const batchIdsArray = batchIdsList.toJS();
            const batchesListApiInfoObject = {
                batchIdsArray
            }
            return FetchBatches.call(batchesListApiInfoObject);
        })
        .then((response) => {
            dispatch(updateFetchBatchesListStatus(false));
            return resolvePromise(response);
        })
        .catch((response) => {
            dispatch(updateFetchBatchesListStatus(false));
            return rejectPromise(response);
        });
}

const setActiveBatch = (batchId) => (dispatch, getState) => SetActiveBatch.call({
    batchId,
    shouldRetry: false
});

const setBatchWorkflowStatus = (workflowState) => (dispatch, getState) => {
    const batchId = getState().get('batches').get('activeBatchId');
    return SetBatchWorkflowStatus.call({
        batchId,
        shouldRetry: false,
        body: {
            workflowState
        }
    })
        .then(() => {
            scrollToTop();
            return FetchUserBatchDetails.call({ batchId })
        });
}


const resetBatchProgressForUser = (batchId, userId) => (dispatch, getState) => {
    let payload = {
        batchId,
        userId
    }
    ResetBatchProgressForUser.call(payload).then((response) => {
        const route = routes.get('HOME')
        updateRoute({ route });
    });
}

export {
    fetchBatchesList,    
    setActiveBatch,
    setBatchWorkflowStatus,
    resetBatchProgressForUser
}