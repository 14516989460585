import actionConstants from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
	journeyWiseTimeSpentLoading: false,
	journeyWiseTimeSpent: {}
});

const journeyWiseTimeSpent = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_JOURNEY_WISE_TIME_SPENT_LOADING'):
			return state.set(
				'journeyWiseTimeSpentLoading',
				action.flag
			)
		case actionConstants.get('SET_JOURNEY_WISE_TIME_SPENT'):
			return state.set(
				'journeyWiseTimeSpent',
				Immutable.fromJS(action.timeSpentData)
			)
		default:
			return state
	}
}

export default journeyWiseTimeSpent;