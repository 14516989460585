import actionConstants from 'constants/actions';

const quizQuestionsLoading = (state = false, action) => {
	switch (action.type) {
		case actionConstants.get('SET_QUIZ_QUESTIONS_LOADING'):
			return action.status
		default:
			return state
	}
}

export default quizQuestionsLoading