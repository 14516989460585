import React, { Component } from 'react';
import styles from './areaWithLineWidget.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import AreaWithLine from 'commonComponents/graphs/areaWithLine';
import { checkIfPresent } from 'utils/utilFunctions';

@applySkin
@CSSModules(styles, { allowMultiple: true })
class AreaWithLineWidget extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    renderInsightForWidget = (styles, insightPresent) => {
        if (insightPresent) {
            return(
                <div className={css(styles.widgetDescription)} styleName="widget-description">
                    Your average completion rate in assessments is way below your group average. 
                    You need to complete all assessments soon 
                </div>
            )
        }
    }

    getSectionsData = (courseId) => {
        const allGraphsData = this.props.graphData;
        const courses = allGraphsData.courses;
        const sections = allGraphsData.sections;

        let data, overallAverage, userAverage;
        if (courseId === -1) {
            data = sections.data;
            overallAverage = courses.sectionOverallAverage;
            userAverage = courses.sectionUserAverage;
        }
        else {
            const courseData = courses.data.filter((course) => course.id === courseId)[0];
            data = sections.data.filter((section) => section.courseId === courseId);
            overallAverage = courseData.sectionOverallAverage;
            userAverage = courseData.sectionUserAverage;
        }
        return {
            data: data,
            metadata: sections.metadata,
            overallAverage: overallAverage,
            userAverage: userAverage,
            isUserDataAvailable: this.props.graphData.isUserDataAvailable
        };
    }
  
    render() {
        const styles = getSkin(this.props.skinGuide);

        const hexval = this.props.skinGuide.get('skinHex');
        let graphData = this.props.graphData;

        const widgetDataArray = this.props.widgetArray;
        
        let isUserDataAvailable = widgetDataArray.get('isUserDataAvailable');
        const insightPresent = checkIfPresent(widgetDataArray.insight);
        const widgetName = widgetDataArray.get('widgetName');
        const strings = this.props.graphData.strings;

        if (widgetName === 'DevelopmentSectionTimespent') {
            graphData = this.getSectionsData(graphData.selectedCourseId);
        }
        
        const metadata = graphData.metadata;
        let graphFormat;

        let yLabel;
        switch (metadata.units) {
            case 'SECONDS':
                yLabel = strings.timeInSecondsLabel;
                break;
            case 'MINUTES':
                yLabel = strings.timeInMinutesLabel;
                break;
            case 'HOURS':
                yLabel = strings.timeInHoursLabel;
                break;
            case 'DAYS':
                yLabel = strings.timeInDaysLabel;
                break;
        }
        const dataFormat = '';
        if (widgetName === 'WeeklyTimeSpent') {
            graphFormat = {
                xLabel: strings.weeks,
                yLabel: yLabel,
                valueFormat: dataFormat,
                valuePrefix: '',
                valueName: strings.week
            };
        }
        else if (widgetName === 'AssessmentTimeSpent' || widgetName === 'DevelopmentCourseTimespent') {
            graphFormat = {
                xLabel: strings.xAxisLabel,
                yLabel: yLabel,
                valueFormat: dataFormat
            };
        }
        else if (widgetName === 'DevelopmentCourseTimespent') {
            graphFormat = {
                xLabel: strings.courseLabel,
                yLabel: yLabel,
                valueFormat: dataFormat
            };
        }
        else if (widgetName === 'DevelopmentSectionTimespent') {
            graphFormat = {
                xLabel: strings.sectionLabel,
                yLabel: yLabel,
                valueFormat: dataFormat,
            };
            isUserDataAvailable = graphData.isUserDataAvailable;
        }
        else {
            graphFormat = {
                xLabel: strings.weeks,
                yLabel: yLabel,
                valueFormat: dataFormat,
                valuePrefix: '',
                valueName: strings.week
            };
        }
        
        const barStyling = {
            fromUser: hexval.get('widgetPinkStart'),
            toUser: hexval.get('widgetPinkEndOpacity'),
            userStroke: hexval.get('widgetPinkStart'),
            userRotation: 170,
            fromUserLegend: hexval.get('widgetPinkStart'),
            toUserLegend: hexval.get('widgetPinkEnd'),
            fromGroup: hexval.get('widgetYellowStart'),
            toGroup: hexval.get('widgetYellowEndOpacity'),
            groupStroke: hexval.get('widgetYellowStart'),
            groupRotation: 180,
            fromGroupLegend: hexval.get('widgetYellowStart'),
            toGroupLegend: hexval.get('widgetYellowEnd'),
            gridValue: 10,
            componentName: 'area-widget'
        }

        return (
            <div className={css(styles.widgetContainerStyle)} styleName="widget-container-class">
                <div className={css(styles.widgetTitle)} styleName="widget-title">
                    {widgetDataArray.get('title')}
                </div>
                <div styleName="widget-content">
                    <AreaWithLine 
                        isUserDataAvailable={isUserDataAvailable}
                        skinGuide={this.props.skinGuide}
                        graphFormat={graphFormat}
                        barStyling={barStyling}
                        // data={timeData}
                        height={insightPresent ? 200 : 230}
                        legendPosition='right'
                        hasAverageLines={false}
                        hasAverageLinesLegend={false}
                        graphData={graphData}
                        strings={strings}
                    />
                </div>
                {this.renderInsightForWidget(styles, insightPresent)}
            </div>
        );
    }
  }
  
  AreaWithLineWidget.defaultProps = {
  };

  export default AreaWithLineWidget;