import { useEffect, useRef, useState } from 'react';
import styles from './etq.module.sass';
import CSSModules from 'react-css-modules';
import applyLabel from 'utils/LabelProvider';

const saveAnswer = (questionId, value, setQuizQuestionAnswer) => {
    if(value && value.length > 0) {
        setQuizQuestionAnswer(questionId, value);
    }
}

const ETQ = ({ question, setQuizQuestionAnswer, currentQuestion, getLabel }) => {
    const [answer, setAnswer] = useState("");
    const questionBoxRef = useRef(null);

    const handleAnswerChange = (e) => {
        const value = e.target.value;
        if(value.trim().length > 0) {
            setAnswer(() => value);
        } else {
            setAnswer("")
        }
    }

    useEffect(() => {
        if(answer !== question.get("answer")) {
            setAnswer(() => question.get("answer") || "");
        }
    }, [question.get("answer")]);


    useEffect(() => {
        if(currentQuestion === `question_${question.get("id")}`) {
            questionBoxRef.current.scrollIntoView({ behavior: "smooth", inline: "nearest" });
        }
    }, [currentQuestion])

    const questionId = question.get("id");
    return (
        <div ref={questionBoxRef}>
            <div styleName="question-text" dangerouslySetInnerHTML={{ __html: question.get('actual_question_text') }}/>
                <div styleName='answer-box-outer'>
                <textarea styleName='answer-text' value={answer} onChange={handleAnswerChange} maxLength={2000}/>
                <div styleName={`save-btn ${answer && answer.length > 0 ? "" : "disabled"}`} onClick={() => saveAnswer(questionId, answer, setQuizQuestionAnswer)}>
                    { getLabel("save_label") }
                </div>
            </div>
        </div>
    )
}

export default applyLabel(CSSModules(ETQ, styles, { allowMultiple: true }));