import React, { Component } from 'react';
import styles from './clock.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class Clock extends Component {

    render() {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div styleName="clock-container">
                <svg width="100%" height="100%" viewBox="0 0 20 20">
                    <g className={css(myStyles.fillColor)} fillRule="nonzero">
                        <path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.994 4.48 15.52.006 10 0zm0 18.333A8.333 8.333 0 1 1 18.333 10 8.342 8.342 0 0 1 10 18.333z" />
                        <path d="M14.278 13.175l-3.861-3.54V5.416a.833.833 0 0 0-1.667 0V10c0 .234.098.457.27.615l4.133 3.787a.842.842 0 0 0 1.177-.05.833.833 0 0 0-.052-1.177z" />
                    </g>
                </svg>
            </div>
        );
    }
}



export default Clock;
