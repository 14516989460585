import actionConsts from 'constants/actions';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
	userReferences: {},
	isUserReferencesLoading: false,
	isPasswordUpdating: false,
	passwordUpdatingError: null,
	demographicsData: {},
	isDemograohicsDataLoading: false,
	notificationSubscriptionDetails: {
		isActive: true
	},
	profileUpdating: false
});

const user = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConsts.get('SET_USER_REFERENCES'):
			return state.set('userReferences', Immutable.fromJS(action.userData))
				.set('isUserReferencesLoading', false);
		case actionConsts.get('SET_USER_REFERENCES_LOADING'):
			return state.set('isUserReferencesLoading', action.flag);
		case actionConsts.get('SET_PASSWORD_UPDATING'):
			return state.set('isPasswordUpdating', action.flag);
		case actionConsts.get('SET_PASSWORD_UPDATING_ERROR'):
			return state.set('passwordUpdatingError', action.message);
		case actionConsts.get('SET_DEMOGRAPHICS_DATA'):
			return state.set('demographicsData', Immutable.fromJS(action.userData))
				.set('isDemograohicsDataLoading', false);
		case actionConsts.get('SET_DEMOGRAPHICS_DATA_LOADING'):
			return state.set('isDemograohicsDataLoading', action.flag);
		case actionConsts.get('SET_NOTIFICATION_SUBSCRIPTION_DETAILS'):
			return state.set(
				'notificationSubscriptionDetails',
				Immutable.fromJS(action.subscriptionDetails)
			)
		case actionConsts.get('SET_PROFILE_UPDATING'):
			return state.set('profileUpdating', action.flag);
		default:
			return state;
	}
};

export default user;