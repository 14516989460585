import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({
    loading: true,
	statusFlags: null
});

const batchesFeedbackStatusFlags = (state = initialState, action = {}) => {
	switch (action.type) {
		case actionConstants.get('SET_BATCHES_FEEDBACK_STATUS_FLAGS'):
			return state.set(
				'statusFlags',
				Immutable.fromJS(action.statusFlags)
			).set('loading', false);
		case actionConstants.get('UNSET_BATCHES_FEEDBACK_STATUS_FLAGS'):
			return initialState;
		default:
			return state;
	}
};

export default batchesFeedbackStatusFlags;