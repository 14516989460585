import React, { Component } from 'react';
import CSSModules from 'react-css-modules';

@CSSModules({ allowMultiple: true })
class FullScreenIcon extends Component {

    render() {
        return (
            <svg width="18" height="18" viewBox="0 0 18 18">
                <g fill="none" fillRule="evenodd" stroke={this.props.strokeColor || "#FFF"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2">
                    <path d="M7.5 10.499l-6 6M16.5 5.999v-4.5H12M1.5 11.999v4.5H6M16.5 1.499l-6 6" />
                </g>
            </svg>
        );
    }
}



export default FullScreenIcon;
