import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { updateItemDetailedData } from 'actions/items/actionCreators';
import { filterObjectFromArray } from 'utils/objectUtils';
import { appendQueryParameters } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import { setApiError } from 'actions/apiErrors/actionCreators';
import { updateFetchFeedbackStatus } from 'actions/loaders/actionCreators';

class FetchItemDetails extends NetPackWrapper {

	constructor() {
		super("GET");
	}

	prepareUrl = (apiInfoObject = {}) => {
		let url = urls.get('PLATFORM').get('GET_ITEM_DETAILS')
		let condition = [true, false][Math.floor(Math.random() * Math.floor(2))];

		if (false) {
			url = urls.get('DEVELOPMENT_PHASE').get('URL_WITH_ERROR');
		}

		return appendQueryParameters(
			url.replace(
				'ITEM_ID',
				apiInfoObject.itemId
			),
			apiInfoObject.queryParameters
		);
	}

	shouldAjaxCall = (apiInfoObject) => {
		const itemsList = this.getState().get('items').get('itemsList');
		const activeItemDetails = filterObjectFromArray(itemsList, 'id', apiInfoObject.itemId);

		if (activeItemDetails.get('isDetailedDataAvailable')) {
			return false;
		}
		return true;
	}

	parser = (response) => ({
		...response,
		isDetailedDataAvailable: true
	})

	successCall = (response) => {
		this.dispatch(updateItemDetailedData(response));
		this.dispatch(setApiError(
			'fetchPhaseItemDetailsError',
			null
		));
		this.dispatch(updateFetchFeedbackStatus(false));
		return response;
	}

	onError = (errorObj) => {
		// this.dispatch(addToast({
		//     id: toastConstants.get('ID_ERROR'),
		//     type: toastConstants.get('TYPE_ERROR'),
		//     heading: 'Something went wrong',
		//     message: 'Sorry, unable to fetch item details. Please try again!',
		//     isDisplayed: false,
		//     autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		// }));
		// sendUserToInvalidPage();
		console.log('on error', errorObj);
		this.dispatch(updateFetchFeedbackStatus(false));
		this.dispatch(setApiError(
			'fetchPhaseItemDetailsError',
			'There was a problem while fetching the content.'
		));
	}

}

export default new FetchItemDetails();
