import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme, isLocked = false, isCompleted = false, isExpired = false, isDuration = false) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	let labelBorder = 'none';

	if(isLocked || isDuration) labelBorder = `1px solid ${hexToRgbA('#9a9fb0', 0.8)}`;
	else if(isCompleted) labelBorder = 'none';
	else if(isExpired) labelBorder = `1px solid ${hexToRgbA('#9a9fb0', 0.8)}`

	return StyleSheet.create({
		individualPhaseItem: {
			backgroundColor: skin.get('white'),
		},
		title: {
			...myTypography.h6
		},
		desc: {
			...myTypography.caption,
			color: skin.get('greyColor4')
		},
		completed: {
			color: '#9a9eaf'
		},
		pending: {
			color: '#797e94'
		},
		aktiv: {
			color: skin.get('secondaryColor')
		},
		completedBackground: {
			backgroundColor: hexToRgbA(skin.get('darkSkyBlue'), 0.5)
		},
		pendingBackground: {
			backgroundColor: skin.get('greyColor4')
		},
		aktivBackground: {
			backgroundColor: skin.get('secondaryColor')
		},
		outerBar: {
			backgroundColor: skin.get('greyColor5')
		},
		bottomText: {
			...myTypography.caption,
			fontWeight: 'normal',
			lineHeight: 'normal',
			color: hexToRgbA(skin.get('greyColor3'), 0.8)
		},
		completionStatus: {
			...myTypography.button,
			lineHeight: 'normal',
			background: skin.get('appleGreen'),
			color: skin.get('white')
		},
		lockedDiv: {
			backgroundImage: `radial-gradient(circle at 50% 45%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.63))`
		},
		expiredTag: {
			backgroundColor: skin.get('scarlet'),
			color: skin.get('white'),
			...myTypography.button
		},
		locked: {
			color: '#797e94'
		},
		lockedBackground: {
			backgroundColor: skin.get('greyColor4')
		},
		moduleContentCnt: {
			borderTop: `1px solid ${hexToRgbA('#9a9fb0', 0.3)}`
		},
		lockMsg: {
			...myTypography.button,
			lineHeight: 'normal',
			fontWeight: 'normal',
			color: hexToRgbA(skin.get('greyColor3'), 0.6)
		},
		expired: {
			color: hexToRgbA(skin.get('scarlet'), 0.6)
		},
		lockLabel: {
			borderLeft: labelBorder,
			borderRight: labelBorder,
			fontWeight: 600
		},
		durationLabel: {
			borderLeft: labelBorder
		}
	});
}

export default getSkin;