import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './reportDownload.module.sass';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import PrimaryButtonInverted from 'commonComponents/buttons/primaryButtonInverted';
import ReportDownloadProgressBar from 'commonComponents/circularProgressBar/ReportDownloadProgressBar';
import applyLabel from 'utils/LabelProvider';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class ReportDownload extends Component {

    onDownloadReportClick = () => {
        const { reportType,
            reportOutputType,
            metadata,
            user,
            generateReport,
            reports
        } = this.props;
        if (
            reports.get(`batch_${metadata.batchId}_${reportType}`)
            && reports.getIn([`batch_${metadata.batchId}_${reportType}`, 'task', 'completionPercentage']) === 100
        ) {
            window.open(reports.getIn([`batch_${metadata.batchId}_${reportType}`, 'task', 'reportLink']));
        } else {
            generateReport({
                reportType,
                reportOutputType,
                metaData: {
                    ...metadata,
                    userId: +user.get('userID')
                }
            })
        }
    }

    getDownloadCertificateButton = (myStyles) => {
        const {
            reportType,
            downloadButtonText,
            metadata,
            reports,
            getLabel,
            useNewStyle,
            useInvertedButtonStyle
        } = this.props;

        if (
            reports.get(`batch_${metadata.batchId}_${reportType}`)
            && reports.getIn([`batch_${metadata.batchId}_${reportType}`, 'task', 'completionPercentage']) !== 100
        ) {
            return (
                <div styleName="progress-cnt">
                    <ReportDownloadProgressBar value={reports.getIn([`batch_${metadata.batchId}_${reportType}`, 'task', 'completionPercentage']) || 0} />
                    <span className={css(myStyles.downloadingText)}>{getLabel('downloading_label')}...</span>
                </div>
            );
        }

        return (
            <div styleName={useNewStyle ? 'download-button-new-style' : 'download-button'}>
                {useInvertedButtonStyle ? <PrimaryButtonInverted
                    text={downloadButtonText}
                    clickFunction={this.onDownloadReportClick}
                /> : <PrimaryButton
                    text={downloadButtonText}
                    clickFunction={this.onDownloadReportClick}
                />}
            </div>
        )
    }

    render() {
        const myStyles = getSkin(this.props.skinGuide);

        return this.getDownloadCertificateButton(myStyles);
    }
}

export default ReportDownload;