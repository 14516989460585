import React, { Component } from 'react';
import AdditionalInformation from 'components/moduleItems/additionalInformation';
import CSSModules from 'react-css-modules';
import styles from './file.module.sass';
import DownloadIcon from 'svgComponents/downloadIcon';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@CSSModules(styles, { allowMultiple: true })

class OtherFile extends Component {

	componentDidMount() {
		let { moduleItem, markRead } = this.props;
		if (moduleItem.get('moduleItem').get('sub_type') == 'image') {
			markRead();
		}
	}

	renderFileComponent = () => {
		let { moduleItem, markRead } = this.props;
		const downloadUrl = moduleItem.get('contentDetails').get('url');

		if (moduleItem.get('moduleItem').get('sub_type') === 'image') {
			return <img styleName="image" src={downloadUrl} alt="Download pic" />;
		}

		return <a href={downloadUrl} target="download" styleName="download-link" onClick={markRead}>
			<div styleName="icon-cnt div">
				<DownloadIcon />
			</div>
			<div styleName="div">
				{this.props.getLabel('download_file_label')}
			</div>
		</a>;
	}

	render() {
		let { moduleItem } = this.props;
		return (
			<div>
				{this.renderFileComponent()}
				<AdditionalInformation body={moduleItem.get('moduleItem').get('description')} />
			</div>
		)
	}
}

export default OtherFile;