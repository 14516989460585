/**
 * 
 *
 * @param {*} weeks
 * @param {*} max
 * @returns []
 */
const dataGen = (weeks, max) => {

	
	var data = [];
	for (let index = 1; index < weeks+1; index++) {
		data.push(
			{
				id: index,
				userValue: Math.floor(Math.random() * Math.floor(max)),
				groupValue: Math.floor(Math.random() * Math.floor(max))
			}
		);
	}
	return data;

};

export default dataGen;