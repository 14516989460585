import React from 'react';
const LinkedinIcon = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={10}
    height={10}
    fill="none"
    style={{marginRight: '-1px'}}
  >
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M9.992 9.997H7.926v-.186c0-1.1.007-2.198-.007-3.297a3.503 3.503 0 0 0-.118-.87c-.117-.429-.427-.64-.867-.653a2.029 2.029 0 0 0-.334.012c-.49.069-.787.363-.895.833-.06.26-.091.533-.094.8-.01 1.063-.004 2.127-.004 3.19V10h-2.06v-6.67H5.52v.918c.042-.047.059-.062.07-.08.401-.633.994-.94 1.722-1.002.375-.033.75 0 1.116.101.826.228 1.266.812 1.432 1.617.079.386.123.785.128 1.179.018 1.272.009 2.544.01 3.816 0 .035-.004.07-.007.12Z"
      />
      <path
        fill="#fff"
        d="M9.992 9.997H7.926v-.186c0-1.1.007-2.198-.007-3.297a3.503 3.503 0 0 0-.118-.87c-.117-.429-.427-.64-.867-.653a2.029 2.029 0 0 0-.334.012c-.49.069-.787.363-.895.833-.06.26-.091.533-.094.8-.01 1.063-.004 2.127-.004 3.19V10h-2.06v-6.67H5.52v.918c.042-.047.059-.062.07-.08.401-.633.994-.94 1.722-1.002.375-.033.75 0 1.116.101.826.228 1.266.812 1.432 1.617.079.386.123.785.128 1.179.018 1.272.009 2.544.01 3.816 0 .035-.004.07-.007.12Z"
      />
      <path fill="url(#c)" d="M.167 9.993v-6.66h2.055v6.66H.167Z" />
      <path fill="#fff" d="M.167 9.993v-6.66h2.055v6.66H.167Z" />
      <path
        fill="url(#d)"
        d="M1.194 2.405A1.196 1.196 0 0 1 0 1.197a1.2 1.2 0 1 1 2.402.011 1.203 1.203 0 0 1-1.208 1.197Z"
      />
      <path
        fill="#fff"
        d="M1.194 2.405A1.196 1.196 0 0 1 0 1.197a1.2 1.2 0 1 1 2.402.011 1.203 1.203 0 0 1-1.208 1.197Z"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={3.547}
        x2={10}
        y1={6.576}
        y2={6.576}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1304A" />
        <stop offset={1} stopColor="#5C40E2" />
      </linearGradient>
      <linearGradient
        id="c"
        x1={0.167}
        x2={2.222}
        y1={6.663}
        y2={6.663}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1304A" />
        <stop offset={1} stopColor="#5C40E2" />
      </linearGradient>
      <linearGradient
        id="d"
        x1={0}
        x2={2.402}
        y1={1.202}
        y2={1.202}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F1304A" />
        <stop offset={1} stopColor="#5C40E2" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h10v10H0z" />
      </clipPath>
    </defs>
  </svg>
  )
}

const TwitterXIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"  style={{marginRight: '-2px'}}>
    <path d="M11.8442 10.6049C12.0891 10.3332 12.3247 10.072 12.5599 9.81022C13.0616 9.25203 13.5646 8.69516 14.0626 8.13359C14.1486 8.03643 14.2384 7.99632 14.3655 8.00023C14.6625 8.00934 14.96 8.00309 15.2575 8.00309C15.2663 8.01534 15.275 8.02758 15.2837 8.03982C14.3005 9.1304 13.3175 10.221 12.3312 11.3152C13.3968 12.8689 14.4583 14.4166 15.5333 15.9841C15.48 15.9908 15.449 15.9984 15.4179 15.9984C14.4947 15.9984 13.5716 15.9958 12.6484 16C12.5432 16.0005 12.4828 15.9593 12.4257 15.876C11.8452 15.0305 11.2624 14.1866 10.6804 13.3427C10.658 13.3101 10.6345 13.2786 10.6016 13.233C10.4777 13.3692 10.3595 13.4982 10.2423 13.6281C9.55626 14.3885 8.871 15.1495 8.18399 15.9085C8.15033 15.9458 8.10095 15.9908 8.05806 15.9916C7.72266 15.9984 7.38727 15.9955 7.05162 15.9955C7.04414 15.9833 7.03641 15.9708 7.02893 15.9585C8.05657 14.8182 9.08446 13.6779 10.1183 12.5311C9.08321 11.0284 8.05233 9.53152 7 8.00335C7.17206 8.00335 7.30797 8.00335 7.44387 8.00335C8.24633 8.00361 9.04855 8.0083 9.85101 8.00023C9.99814 7.99867 10.0889 8.04868 10.1707 8.16953C10.5667 8.75402 10.9682 9.33434 11.3682 9.91597C11.5235 10.1421 11.6801 10.3676 11.8442 10.6049ZM8.57251 8.84675C8.60991 8.90587 8.62986 8.93999 8.65205 8.97229C9.1261 9.65915 9.60015 10.3463 10.0749 11.0323C10.9161 12.2479 11.7574 13.4635 12.5998 14.6781C12.7083 14.8346 12.7968 15.0435 12.9457 15.1217C13.0948 15.1998 13.308 15.1451 13.4933 15.1467C13.6499 15.148 13.8067 15.1469 13.983 15.1469C13.9494 15.0941 13.9297 15.0607 13.9077 15.0289C13.0424 13.7758 12.1769 12.5227 11.3111 11.2699C10.7762 10.4955 10.2413 9.72114 9.70388 8.94885C9.67421 8.90613 9.62259 8.85222 9.5802 8.85118C9.25353 8.8431 8.92661 8.84675 8.57251 8.84675Z" fill="white"/>
  </svg>
}

const YoutubeIcon = () => {
      return <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none"  style={{marginRight: '-1px'}}>
      <g clip-path="url(#clip0_113_7466)">
      <path d="M0.783203 5.50145C0.783203 5.10829 0.783203 4.71514 0.783203 4.32199C0.791098 4.23798 0.798442 4.15396 0.807071 4.07012C0.863253 3.52836 0.910255 2.98559 0.979473 2.4452C1.02794 2.0667 1.19631 1.73541 1.51192 1.47689C1.8244 1.22092 2.20317 1.12481 2.60306 1.09788C3.3841 1.04556 4.16569 0.981147 4.94783 0.968537C6.26645 0.947405 7.58617 0.944167 8.90461 0.969389C9.74642 0.985578 10.5886 1.04778 11.4271 1.12413C12.2069 1.19519 12.7013 1.6364 12.85 2.3484C12.9159 2.66418 12.9616 2.98491 12.9916 3.30546C13.0702 4.14851 13.1021 4.99395 13.0386 5.83853C12.9969 6.39204 12.9444 6.94743 12.8465 7.4943C12.7231 8.18465 12.2559 8.59962 11.5114 8.70817C11.0584 8.7743 10.5987 8.81758 10.1406 8.83207C8.98228 8.86888 7.82302 8.90143 6.66431 8.90296C5.25371 8.90484 3.84236 8.88711 2.43855 8.72845C1.69386 8.64427 1.1706 8.24123 1.02207 7.56332C0.923842 7.11495 0.887672 6.65448 0.833143 6.19845C0.805786 5.96737 0.799176 5.73389 0.783203 5.50145ZM5.70592 3.21821V6.62756C6.77117 6.05717 7.82173 5.49446 8.88956 4.92271C7.81843 4.34926 6.76933 3.78757 5.70592 3.21821Z" fill="white"/>
      </g>
      <defs>
      <clipPath id="clip0_113_7466">
      <rect width="12.2892" height="7.95181" fill="white" transform="translate(0.783203 0.951569)"/>
      </clipPath>
      </defs>
      </svg>
}

const FBIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
    <g clip-path="url(#clip0_113_7462)">
    <path d="M5.17273 0.782898C5.41245 0.782898 5.65203 0.782898 5.89175 0.782898C6.05406 0.798612 6.21667 0.812269 6.37884 0.83048C6.64676 0.860585 6.91425 0.893628 7.18071 0.925349V2.81261C7.12739 2.81261 7.08023 2.81217 7.03323 2.81261C6.69788 2.8157 6.36165 2.806 6.02718 2.82509C5.4458 2.85828 5.04934 3.21294 5.00674 3.78965C4.96708 4.32656 4.99044 4.86832 4.98662 5.40787C4.98662 5.41433 4.99411 5.42094 5.00616 5.44047H7.09066C7.08875 5.47763 7.08949 5.50171 7.08596 5.52521C6.98285 6.20061 6.87826 6.87585 6.7772 7.55155C6.76574 7.62791 6.73269 7.64656 6.65998 7.64612C6.1447 7.64333 5.62955 7.64451 5.11427 7.6448C5.07226 7.6448 5.03039 7.64832 4.97825 7.65067V12.9785C4.18196 13.103 3.39713 13.103 2.6004 12.9764V7.64348H0.674683V5.43739C1.00269 5.43739 1.32188 5.43739 1.64107 5.43739C1.96026 5.43739 2.27622 5.43739 2.60305 5.43739C2.60305 5.37571 2.60305 5.33239 2.60305 5.28892C2.60305 4.79298 2.59746 4.2969 2.60554 3.80096C2.60951 3.5538 2.62346 3.30488 2.65886 3.06051C2.82999 1.88124 3.60351 1.07647 4.77304 0.849718C4.90568 0.824018 5.0395 0.805073 5.17273 0.782898Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_113_7462">
    <rect width="6.50602" height="12.2892" fill="white" transform="translate(0.674683 0.782898)"/>
    </clipPath>
    </defs>
  </svg>
}

const InstagramIcon = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" style={{marginRight: '-2px'}}>
    <g clip-path="url(#clip0_113_7475)">
      <path d="M7.91954 11.9588H3.11257C3.07753 11.9489 3.04314 11.9342 3.00715 11.93C1.32972 11.737 0.0257682 10.3114 0.00969781 8.62506C-0.00412272 7.18318 -0.00187287 5.7413 0.0093764 4.29941C0.0196614 2.95116 0.87107 1.71636 2.13002 1.22583C2.4434 1.10343 2.78441 1.05038 3.11257 0.965698C4.71478 0.965698 6.31732 0.965698 7.91954 0.965698C7.95457 0.975605 7.98896 0.989027 8.02496 0.994459C9.40155 1.2009 10.3381 1.95731 10.8302 3.2506C10.9289 3.50977 10.9639 3.79322 11.0282 4.06549V8.85898C11.0182 8.89382 11.0034 8.92801 10.9993 8.9638C10.8431 10.3172 9.85988 11.466 8.54082 11.8274C8.33705 11.8834 8.12685 11.9156 7.91986 11.9588H7.91954ZM9.79045 6.46863C9.79335 6.46863 9.79656 6.46863 9.79945 6.46863C9.79945 5.77645 9.80524 5.08395 9.79817 4.39177C9.78563 3.14099 8.84487 2.19922 7.59331 2.19251C6.20997 2.18516 4.82696 2.1858 3.44362 2.19251C2.19141 2.19827 1.24133 3.14099 1.23426 4.38665C1.22655 5.77134 1.22623 7.15602 1.23426 8.5407C1.24133 9.76687 2.16827 10.7141 3.3983 10.7294C4.80799 10.7473 6.21833 10.7454 7.62802 10.73C8.79859 10.7173 9.71042 9.87073 9.78178 8.70463C9.82709 7.96196 9.79013 7.21418 9.79013 6.46863H9.79045Z" fill="white"/>
      <path d="M8.33406 6.46271C8.33406 8.01196 7.06322 9.27521 5.50793 9.27233C3.96196 9.26946 2.69818 8.00557 2.69818 6.46271C2.69818 4.91377 3.96903 3.6502 5.52432 3.65308C7.06965 3.65564 8.33439 4.92016 8.33439 6.46303L8.33406 6.46271ZM7.2236 6.46942C7.22874 5.52478 6.46829 4.76166 5.5195 4.75974C4.57713 4.75782 3.81893 5.50593 3.80864 6.44705C3.79836 7.39073 4.55527 8.15737 5.50471 8.16536C6.44997 8.17335 7.21846 7.41533 7.2236 6.46942Z" fill="white"/>
      <path d="M8.94674 3.48999C8.94609 3.75459 8.73011 3.97126 8.46977 3.96806C8.21521 3.96487 7.99794 3.75204 7.99312 3.50182C7.9883 3.24425 8.20943 3.0164 8.46591 3.01448C8.72721 3.01256 8.94738 3.23019 8.94674 3.48999Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_113_7475">
        <rect width="11.0281" height="10.9931" fill="white" transform="translate(0 0.965607)"/>
      </clipPath>
    </defs>
  </svg>;
}

export {
  LinkedinIcon,
  TwitterXIcon,
  YoutubeIcon,
  FBIcon,
  InstagramIcon
}