import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { sendUserToInvalidPage } from 'utils/webAppRoutes/updateRoute';
import setUserState from 'actions/userState/actionCreators';

class SetActiveBatch extends NetPackWrapper {

    constructor() {
        super("POST");
    }

    prepareUrl = (apiInfoObject = {}) => urls.get('PLATFORM').get('SET_ACTIVE_BATCH').replace(
        'BATCH_ID',
        apiInfoObject.batchId
    )

    shouldAjaxCall = (apiInfoObject) => {
        const activeBatchId = this.getState().get('batches').get('activeBatchId');

        if (activeBatchId == apiInfoObject.batchId) {
            return false;
        }
        return true;
    }

    successCall = (response, apiInfoObject) => {
        return response;
    }

    onError = (response) => {
        const errorObj = response.errorObject.response;
        const apiObj = response.errorObject.ajaxCallInfo;
        this.dispatch(setUserState({
            userError: {
                message: errorObj.message,
                error_code: errorObj.error_code,
                data: errorObj.data,
                status_code: apiObj.status
            }
        }));

        this.dispatch(addToast({
            id: toastConstants.get('ID_ERROR_SET_BATCH'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to change active batch. Please try again!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));

        sendUserToInvalidPage();
    }

}

export default new SetActiveBatch();
