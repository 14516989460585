import React, { Component } from 'react';
import SectionHeader from 'commonComponents/collapsibleSidebar/sectionHeader';
import SidebarHeader from 'commonComponents/collapsibleSidebar/sidebarHeader';
import SidebarList from 'commonComponents/collapsibleSidebar/sidebarList';
import SidebarLoader from 'commonComponents/collapsibleSidebar/sidebarLoader';
import SidebarFeedbackItem from 'commonComponents/collapsibleSidebar/sidebarFeedbackItem';
import ModuleItemsList from './ModuleItemsList';

import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import MenuFooter from 'commonComponents/menuList/menuFooter';
import APIErrorBoundary from 'components/errorBoundaries/apiErrorBoundary';

export default class ModulesSidebar extends Component {

	setActiveSection = (props) => {
		this.setActiveSectionInStore(props.routeParams.moduleId)
	}

	setActiveSectionInStore = (moduleId) => {
		const { phaseItemData } = this.props;
		this.props.setCourseActiveSection(
			phaseItemData.get('toolId'),
			phaseItemData.get('id'),
			moduleId
		);
	}

	getCourseActiveSection = () => {
		const { coursesActiveSections, phaseItemData } = this.props;
		const courseDetails = coursesActiveSections.find(course => course.get('id') == phaseItemData.get('toolId'));

		if (courseDetails && courseDetails.get('activeSectionId')) {
			return courseDetails.get('activeSectionId');
		}
		return -1;
	}

	componentDidMount() {
		if (this.getCourseModulesDetails(this.props)) {
			if (!this.props.routeParams.moduleId) return;
			this.setActiveSection(this.props);
		}
	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.routeParams.moduleId) return;

		if ((!this.getCourseModulesDetails(this.props) && this.getCourseModulesDetails(nextProps))
			|| (this.props.routeParams.moduleId != nextProps.routeParams.moduleId)
		) {
			this.setActiveSection(nextProps);
		}
	}

	setActiveSectionOnClick = (moduleId) => {
		const currentActiveSectionId = this.getCourseActiveSection();
		moduleId = (moduleId == currentActiveSectionId) ? -1 : moduleId;
		this.setActiveSectionInStore(moduleId);
	}

	goToBatchDashboard = () => {
		const route = routes.get('BATCH');
		const params = [
			{ 'BATCH_ID': this.props.routeParams.batchId }
		]
		updateRoute({ route, params });
	}

	getAnalyticsData = () => {
		const { batchId } = this.props.routeParams;
		const userId = this.props.user.get('userID') ? this.props.user.get('userID') : this.props.user.get('userId');
		const phaseId = this.props.user.get('userId') ? 2 : this.props.phases.get('activePhaseId');
		if (userId && phaseId) {
			this.props.fetchWidgetAnalyticsData(+batchId, +userId, +phaseId);
		}
	};

	goToCourseFeedback = () => {
		const { batchId, phaseId, phaseItemId, courseId, moduleId, moduleItemId } = this.props.routeParams;
		const route = routes.get('BATCH_ITEM_COURSE_FEEDBACK');
		const params = [
			{ 'BATCH_ID': batchId },
			{ 'COURSE_ID': courseId },
			{ 'MODULE_ID': moduleId },
			{ 'ITEM_ID': moduleItemId },
			{ '_PHASE_ID_': phaseId },
			{ '_PHASEITEMID_': phaseItemId }
		];

		const routeObj = { route, params };
		updateRoute(routeObj);
	}

	shouldShowCourseFeedback = () => {
		const { isSidebarOpen, phaseItemData } = this.props;
		const enabledInSettings = phaseItemData &&
			phaseItemData.getIn(['settings', 'isFeedbackEnabled']) === 'true' &&
			phaseItemData.getIn(['settings', 'feedbackScenarioId']) &&
			isSidebarOpen;
		if (enabledInSettings) {
			if (phaseItemData.get('isExpired') && !phaseItemData.get('isCompleted')) {
				return false;
			}
		}

		return enabledInSettings;
	}

	getCourseModulesDetails = (props) => {
		const { coursesModulesList, phaseItemData } = props;
		const courseModulesDetails = coursesModulesList.find(course => course.get('id') == phaseItemData.get('toolId'));
		return courseModulesDetails;
	}

	render() {
		const { isSidebarOpen, onToggleSidebar, routeParams, phases, fetchModulesError, fetchModules, phaseItemData, phase, modulesItemsList, fetchModuleItemsList, sidebarHeaderTitle, feedbackStatuses, phaseItemId } = this.props;
		let listOfModuleElements = null;
		const courseModulesDetails = this.getCourseModulesDetails(this.props);
		const currentActiveSectionId = this.getCourseActiveSection();

		const phaseItemFeedbackStatus = feedbackStatuses.get('phaseItemFeedbackStatuses').find(itemFeedback => itemFeedback.get('id') == phaseItemData.get('id'))
		if (!(courseModulesDetails && courseModulesDetails.get('isCourseModulesListAvailable')) && !fetchModulesError) {
			return <SidebarLoader />
		}

		if (courseModulesDetails) {
			let modules = courseModulesDetails.get('courseModulesList');

			listOfModuleElements = modules.map((module, sectionIndex) => {

				return (
					<div key={module.get('id')}>
						<SectionHeader
							module={module}
							isSidebarOpen={isSidebarOpen}
							onClick={this.setActiveSectionOnClick.bind(this, module.get('id'))}
							active={module.get('id') == currentActiveSectionId}
							phaseItemData={phaseItemData}
							phase={phase}
						/>
						{
							module.get('id') == currentActiveSectionId ? <SidebarList isSidebarOpen={isSidebarOpen} fillerHeight={0}>
								<ModuleItemsList
									module={module}
									modulesItemsList={modulesItemsList}
									isSidebarOpen={isSidebarOpen}
									routeParams={routeParams}
									phaseItemData={phaseItemData}
									key={module.get('id')}
									fetchModuleItemsList={fetchModuleItemsList}
								/>
							</SidebarList> : null
						}
					</div>
				);
			});
		}

		return <div style={{
			overflowX: 'hidden',
		}}>
			<div style={{
					height: isSidebarOpen ? 'calc(100vh - 60px)' : 'calc(100vh - 90px)',
					overflowY: 'hidden'
				}}
				onClick={() => { 
					if(!isSidebarOpen){
						onToggleSidebar();
					}
				}}
			>
				<APIErrorBoundary
					apiError={fetchModulesError}
					retryHandler={() => fetchModules(routeParams)}
				>
					<React.Fragment>
						<SidebarHeader
							isSidebarOpen={isSidebarOpen}
							onToggleSidebar={onToggleSidebar}
							title={sidebarHeaderTitle}
							onClickBack={this.goToBatchDashboard.bind(this)}
						/>
						<div className="tobesco" style={{
							height: isSidebarOpen ? 'calc(100vh - 120px)' : 'calc(100vh - 210px)',
							overflowY: isSidebarOpen ? 'auto' : 'hidden'
						}}>
							{listOfModuleElements}

							{
								this.shouldShowCourseFeedback() &&
								<SidebarFeedbackItem
									onClickFunction={this.goToCourseFeedback}
									isFromFeedback={this.props.isFromFeedback}
									feedbackStatus={phaseItemFeedbackStatus}
								/>
							}

							<div style={{
								backgroundColor: isSidebarOpen ? '#f6f7f9' : 'white',
								height: '25px'
							}}></div>
						</div>
					</React.Fragment>
				</APIErrorBoundary>
			</div>
			<MenuFooter
				isSideBarOpen={isSidebarOpen}
				batchId={routeParams.batchId}
				forDevPhase={true}
				phases={phases}
				userState={this.props.userState}
			/>
		</div>

	}
}
