import React, { Component } from 'react';
import styles from './errorNotFound.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ErrorNotFound extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
			<div styleName="main-component">
				<svg width="100%" height="100%" viewBox="0 0 534 355">
					<defs>
						<path id="b" d="M1 157.088l61.174-39.783 110.78 73.944-61.486 41.413z"/>
						<filter id="a" width="113.4%" height="119.9%" x="-7.3%" y="-8.2%" filterUnits="objectBoundingBox">
							<feOffset dx="-1" dy="2" in="SourceAlpha" result="shadowOffsetOuter1"/>
							<feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3.5"/>
							<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
						</filter>
						<path id="d" d="M172.936 191.312l107.704-74.17 63.252 39.96-111.623 75.338z"/>
						<filter id="c" width="123.4%" height="134.7%" x="-17.5%" y="-14.7%" filterUnits="objectBoundingBox">
							<feOffset dx="-10" dy="3" in="SourceAlpha" result="shadowOffsetOuter1"/>
							<feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="5"/>
							<feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"/>
						</filter>
					</defs>
					<g fill="none" fillRule="evenodd">
						<path fill="#F2F3F7" d="M305.154 351.983c25.194-5.028 119.649-23.766 172.971-70.006C523.12 242.958 529.694 177.814 533.048 151c8.323-66.532-56.17-136.83-104.09-131.126-55.956 6.659-223.737 46.027-273.443 32.464C32.189 18.688-22.682 85.451 10 178.465c6.729 19.151 81.038 70.02 94.503 111.067 19.371 59.047 161.17 70.331 200.651 62.451z"/>
						<path fill="#BAC1D8" d="M161.281 117.385l110.207-75.063-.105 148.897z"/>
						<path fill="#A4ADC8" d="M379.74 117.168L271.491 42.294l-.799 150.09z"/>
						<path fill="#8890AA" d="M162.687 248.476l108-78.492 110.422 75.361-109.21 76.271z"/>
						<path fill="#5C74C9" d="M161.255 117.43l1.656 131.106 109.058 72.512V191.23z"/>
						<path fill="#667FD5" d="M271.96 191.227l109.123-72.993v127.468L271.96 321.05z"/>
						<g transform="translate(99)">
							<use fill="#000" filter="url(#a)" xlinkHref="#b"/>
							<use fill="#D6DBEE" xlinkHref="#b"/>
						</g>
						<g transform="translate(99)">
							<use fill="#000" filter="url(#c)" xlinkHref="#d"/>
							<use fill="#D6DBEE" xlinkHref="#d"/>
						</g>
						<path fill="#D6DBEE" d="M99.075 75.514L210.628.234l60.88 42.076-110.184 75.122zM271.508 42.32l108.095 74.834 62.786-40.668-109.258-74.28z"/>
						<g stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".3">
							<path d="M237.686 133.086c6.888.869 23.155 2.54 27.13 2.934 14.045 1.393 14.825 2.658 37.437-1.103M302.21 134.93a733.888 733.888 0 0 1-4.139 5.01c-3.58 4.303-8.002 9.592-12.393 14.502-2.668 2.983-5.412 5.534-7.698 8.481-2.938 3.788-3.855 6.85-6.562 10.77-2.48 3.593-4.758 7.103-6.886 10.768"/>
							<path d="M300.445 134.917c-1.5 2.971-1.819 6.121-2.927 8.657-.775 1.773-1.864 3.324-2.499 4.945-1.098 2.806-.961 5.262-1.66 7.886-.834 3.13-2.144 5.56-2.74 8.967-.932 5.335-1.7 7.107-1.7 13.297M302.253 134.917c1.222 23.053 3.832 19.867 7.45 29.245M300.445 134.917c6.788 1.214 48.44 1.348 55.127-1.456M300.425 134.686c4.621-2.331 20.719-9.757 32.85-12.237 19.348-3.956 37.041-4.195 45.514-4.734"/>
							<path d="M300.445 134.917c.517-2.941.182-2.603 4.485-5.511 6.767-4.573 13.711-8.908 20.878-12.835 6.28-3.44 12.675-6.693 19.408-9.235 6.402-2.417 10.283-3.587 14.069-4.725"/>
							<path d="M300.445 134.917c.924-6.086 7.656-10.731 11.444-14.672 1.234-1.283 27.172-21.242 32.95-27.194M300.445 134.917a59.108 59.108 0 0 1-1.191-6.1 64.086 64.086 0 0 1-.56-6.363l-.058-17.944M300.445 134.917c-3.485-4.063-9.438-7.454-12.77-11.745-.9-1.158-3.987-4.836-5.745-6.42-.598-.538-9.844-7.78-10.655-8.749"/>
							<path d="M273.318 137.29c2.429 4.99 3.904 9.011 4.426 12.06.521 3.05.521 7.652 0 13.807 2.993-1.24 5.318-1.721 6.975-1.443 1.656.278 3.634 1.481 5.935 3.61 2.786-2.778 4.973-4.96 6.562-6.547 1.589-1.586 4.473-2.453 8.654-2.6 3.13-6.107 5.704-10.252 7.72-12.436 2.017-2.184 6.03-4.877 12.041-8.08 1.1-3.349 2.54-5.95 4.32-7.8 1.78-1.851 4.007-3.725 6.68-5.622-1.132-1.99-1.573-3.847-1.324-5.574.25-1.726 1.287-4.005 3.115-6.836-3.286.218-5.823.218-7.612 0-1.79-.217-2.675-1.064-2.658-2.54-8.281 2.173-13.855 3.36-16.72 3.56-2.865.2-6.946-.14-12.242-1.02-2.713 2.972-5.13 4.873-7.252 5.703-2.122.83-5.533 1.207-10.234 1.133-.718 4.82-1.546 8.383-2.483 10.687-.937 2.305-2.905 5.617-5.903 9.937z"/>
							<path d="M287.389 122.73c-.411 3.745-1.018 6.503-1.82 8.275-.804 1.771-2.476 3.983-5.017 6.634 2.187 2.346 3.589 4.563 4.205 6.651.616 2.089.886 5.54.811 10.355 1.447-.623 2.632-.831 3.556-.625.923.206 2.328.993 4.214 2.361 1.632-2.847 3.057-4.727 4.274-5.638 1.217-.911 3.331-1.683 6.344-2.314 1.32-3.429 2.893-6.003 4.718-7.723 1.825-1.72 5.006-3.625 9.543-5.717 2.31-3.443 4.232-5.863 5.765-7.26 1.534-1.398 3.936-2.916 7.206-4.556-1-1.132-1.611-2.18-1.834-3.14-.222-.961-.222-2.555 0-4.782-1.404-1.242-2.333-2.293-2.787-3.153-.454-.86.018-2.492 1.415-4.895"/>
							<path d="M292.1 127.514c.007 2.327-.21 4.1-.651 5.319-.442 1.22-1.47 2.89-3.088 5.013 1.278 1.566 2.09 2.983 2.433 4.25.344 1.267.244 3.55-.298 6.852 1.413-.693 2.446-1.112 3.098-1.255.652-.143 1.201.116 1.648.776 2.049-1.535 3.512-2.522 4.39-2.963.877-.44 2.167-.744 3.87-.91 1.231-2.433 2.364-4.146 3.398-5.14 1.033-.993 2.843-2.107 5.43-3.344.18-1.755 1.172-3.406 2.979-4.954 1.806-1.549 4.612-3.554 8.418-6.016-.328-.978-.491-1.86-.491-2.65 0-.79.24-1.821.718-3.095-1.74-.702-2.945-1.29-3.614-1.761-.669-.472-1.427-1.27-2.274-2.394-3.57 1.381-6.484 2.179-8.746 2.394-2.262.214-5.238-.025-8.93-.717-2.162 2.48-4.007 4.104-5.535 4.873-1.527.77-3.884 1.26-7.07 1.473l4.314 4.25z"/>
							<path d="M295.59 126.346c1.034-.561 1.998-1.876 2.892-3.945 3.286.47 5.593.67 6.92.6 1.329-.07 3.36-.864 6.093-2.384-.126 1.503.222 2.297 1.045 2.384.823.086 2.216.086 4.18 0-.316.996-.506 1.768-.57 2.316-.063.547-.063 1.328 0 2.34-2.592 1.93-4.516 3.457-5.77 4.582-1.255 1.124-1.665 2.191-1.23 3.2-1.898.562-3.147 1.04-3.747 1.43-.6.392-1.526 1.492-2.777 3.301-.764.204-1.443.457-2.036.76-.592.302-1.563 1.164-2.91 2.586l-2.857.345c.003-2.036-.234-3.483-.712-4.34-.478-.858-1.146-1.804-2.004-2.84.81-1.34 1.37-2.4 1.677-3.178.308-.779.655-2.007 1.039-3.685l-3.42-2.47c1.756-.107 3.152-.441 4.186-1.002z"/>
							<path d="M298.405 132.163c-.17 1.104-.381 1.91-.636 2.417-.254.506-.804 1.218-1.649 2.133.843.472 1.393.909 1.65 1.31.256.4.468 1.154.635 2.26l3.913-4.996 2.973 1.64.602-4.574 7.21-2.267c-1.655.134-2.508-.032-2.557-.498-.049-.466.067-1.835.349-4.107h-4.58c-.942 1.167-1.928 1.856-2.96 2.068a7.846 7.846 0 0 1-3.14 0c-.959.846-1.774 1.432-2.446 1.76-.671.329-1.588.587-2.75.777l3.386 2.077z"/>
							<path d="M298.294 132.739l1.212-2.545 3.265-.662 2.615-.408c-.137.88-.177 1.51-.12 1.89.057.38.258.833.604 1.358l-4.026 2.545c-.524.19-1.009.149-1.454-.125-.446-.273-1.144-.957-2.096-2.053zM264.834 136.086c4.482 6.611 7.04 11.915 7.676 15.912.635 3.996.206 11.318-1.285 21.966"/>
						</g>
					</g>
				</svg>
			</div>
		);
	}
}



export default ErrorNotFound;
