import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	return StyleSheet.create({
        ie11Warning:{
            ...myTypography.caption,
            background: skin.get('primaryColor'),        
            color: skin.get('white')        
        }
	});
}

export default getSkin;