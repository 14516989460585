import React, { Component } from 'react';
import styles from './tickMarkWithGreenFill.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class TickMarkWithGreenFill extends Component {

	render() {

		return (
			<div styleName="complete-component">
				<svg width="100%" height="100%" viewBox="0 0 24 24">
					<g fill="#76CA1A" fillRule="nonzero">
						<path d="M18.48 6.449a1.249 1.249 0 0 0-1.747.265l-5.924 8.04-3.767-3.014a1.251 1.251 0 0 0-1.563 1.953l4.783 3.826a1.263 1.263 0 0 0 1.787-.235l6.7-9.087a1.25 1.25 0 0 0-.269-1.748z" />
						<path d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.993 5.375 18.624.007 12 0zm0 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10z" />
					</g>
				</svg>
			</div>
		);
	}
}



export default TickMarkWithGreenFill;
