import React, { Component } from 'react';
import styles from './backArrow.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class WriteIcon extends Component {

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		return (
				<svg xmlns="http://www.w3.org/2000/svg" width="49" height="49" viewBox="0 0 49 49">
					<g fill="none" fillRule="evenodd" stroke="#223b92" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3">
						<path d="M18.63 38.98a3.046 3.046 0 0 1-4.309 0l-4.307-4.31a3.046 3.046 0 0 1 0-4.307L36.939 3.438a6.093 6.093 0 0 1 8.617 8.615L18.631 38.98zM36.939 3.438a6.093 6.093 0 0 0-8.617 0L21.86 9.9M29.4 10.976l8.615 8.617"/>
						<path d="M12.195 36.852a4.701 4.701 0 0 1-.132 6.594c-1.838 1.84-10.416 3.901-10.416 3.901S3.763 38.817 5.6 36.99a4.7 4.7 0 0 1 6.594-.138z"/>
					</g>
				</svg>
		);
	}
}



export default WriteIcon;
