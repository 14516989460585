import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import styles from './assignment.module.sass';
import applyLabel from 'utils/LabelProvider';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import TextEntry from './TextEntry';
import FileUpload from './FileUpload';
import BackArrow from 'svgComponents/backArrow';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil.js';
import ModuleItemUtil from 'utils/moduleItem/moduleItem';

@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })
class Submission extends Component {

    constructor(props) {
        super();
        this.state = {
            body: this.getBodyFromLocalStorage(props),
            url: null,
            file_ids: null,
            inputError: null
        }
    }

    getBodyFromLocalStorage = (props) => {
        const {submissionType, moduleItem} = props;
        return ModuleItemUtil.getAssignmentBodyFromLocalStorage(submissionType, moduleItem);
    }

    componentDidMount() {
        if (this.linkInput) {
            this.linkInput.focus();
        }
    }

    setTextEntryBody = (text) => {
        const assignmentId = this.props.moduleItem.get('moduleItem').get('content_id');
        this.setState({ body: text }, () => {
            window.localStorage.setItem(`assignment_${assignmentId}_text`, text);
        });
    }

    setInputError = (error) => {
        this.setState({ inputError: error });
    }

    isValidUrl = (url) => {
        var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
        if (res == null)
            return false;
        else
            return true;
    }

    getSubmitButtonStyleName = () => {
        const { moduleItem, submissionType } = this.props;
        const { body, url } = this.state;

        if (submissionType === "online_upload" && moduleItem.get('uploadedFiles').size === 0) {
            return "submit-btn-cnt-disabled";
        }

        if (submissionType === "online_text_entry") {
            if (!body || !body.trim())
                return "submit-btn-cnt-disabled";

        }

        if (submissionType === "online_url") {
            if (!url || !this.isValidUrl(url))
                return "submit-btn-cnt-disabled";
        }

        return "submit-btn-cnt";
    }

    sendSegmentData = () => {
        const { moduleItem, match } = this.props;
        let segmentData = {
            referrer: window.location.href,
            pathHash: window.location.hash,
            ...match.params,
            assignmentId: moduleItem.getIn(['moduleItem', 'content_id'])
        }
        window.analytics.track(
            'Assignment Submitted',
            segmentData,
            getAmplitudeSessionDetails()
        )
    }

    onSubmit = () => {
        const { moduleItem, match, submitAssignment, submissionType, resetSubmissionType } = this.props;
        const urlParams = {
            ...match.params,
            assignmentId: moduleItem.get('moduleItem').get('content_id'),
        };

        this.sendSegmentData();

        let submission = {
            submission_type: submissionType
        };

        switch (submissionType) {
            case 'online_text_entry':
                submission.body = this.state.body || '';
                break;
            case 'online_url':
                submission.url = this.linkInput.value;
                break;
            default:
                break;
        }

        let payload = { submission };

        submitAssignment(urlParams, payload);
        resetSubmissionType();
    }

    onFileSelect = (event) => {
        console.log('file', event.target.files[0]);
        const { match, uploadFile } = this.props;
        uploadFile(match.params, event.target.files[0])
    }

    getSubmissionTypeForm = () => {
        const { submissionType, getLabelWithDefaults, moduleItem } = this.props;
        const styles = getSkin(this.props.skinGuide);
        let instruction = "";
        let formComponent = <div> This submission type is not supported yet </div>
        switch (submissionType) {
            case 'online_text_entry':
                instruction = getLabelWithDefaults('assignment_type_your_submission', "Type your submission");
                formComponent = <TextEntry
                    setTextEntryBody={this.setTextEntryBody}
                    moduleItem={moduleItem}
                    setInputError={this.setInputError}
                    onSubmit={this.onSubmit}
                    savedBody={this.state.body}
                />;
                break;
            case 'online_url':
                instruction = getLabelWithDefaults('assignment_type_your_submission', "Type your submission");
                formComponent =
                    <div className={css(styles.assignmentTextAreaContainer)} styleName="share-link-cnt">
                        <input
                            type="text"
                            className={css(styles.textArea)}
                            styleName="share-link-input"
                            placeholder={getLabelWithDefaults('assignment_share_link_here_label', 'Enter link here...')}
                            ref={(input) => { this.linkInput = input; }}
                            onChange={(event) => { this.setState({ url: event.target.value }) }}
                        />
                    </div>;
                break;
            case 'online_upload':
                instruction = getLabelWithDefaults('assignment_upload_file_label', "Upload file(s)");
                formComponent = <FileUpload {...this.props} />;
                break;
            default:
                return false;
        }
        return {
            formInstruction: instruction,
            formComponent
        }
    }

    render() {
        const { getLabelWithDefaults, setSubmissionType, onlyOneSubmissionType } = this.props;
        const { inputError } = this.state;
        const styles = getSkin(this.props.skinGuide);
        const submissionForm = this.getSubmissionTypeForm();
        return (
            <div style={{ marginTop: '30px' }}>
                <div className={css(styles.submissionHeading)} styleName="submission-heading">
                    {!onlyOneSubmissionType && <div styleName="arrow-cnt" onClick={() => setSubmissionType(null)}>
                        <BackArrow color={'rgba(9, 16, 39, 0.85)'} />
                    </div>}
                    {submissionForm ? submissionForm.formInstruction : "This submission type is not supported yet."}
                </div>
                {
                    submissionForm ?
                        <span>
                            {submissionForm.formComponent}
                            {inputError && <div styleName='input-error'>
                                {inputError}
                            </div>}
                            <div styleName={this.getSubmitButtonStyleName()}>
                                <PrimaryButton
                                    text={getLabelWithDefaults('submit_label', "Submit")}
                                    clickFunction={this.onSubmit}
                                    disabled={!!inputError}
                                />
                            </div>
                        </span> : null
                }
            </div>
        )
    }
}

export default Submission;
