import React, { Component } from 'react';
import styles from './helpIcon.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class InfoIcon extends Component {

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="complete-component">
				<svg width="20" height="20" viewBox="0 0 20 20">
					<g fill={this.props.fillColor || '#8C8C90'} fillRule="nonzero">
						<path d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.994 4.48 15.52.006 10 0zm0 18.333A8.333 8.333 0 1 1 18.333 10 8.342 8.342 0 0 1 10 18.333z" />
						<g transform="translate(6.5 4)">
							<circle cx="3.5" cy="10.938" r="1.313" />
							<path d="M3.5.222a3.5 3.5 0 0 0-3.5 3.5.875.875 0 1 0 1.75 0A1.75 1.75 0 1 1 4.2 5.326a2.625 2.625 0 0 0-1.575 2.406.875.875 0 1 0 1.75 0c0-.348.206-.663.525-.802A3.5 3.5 0 0 0 3.5.222z" />
						</g>
					</g>
				</svg>
			</div>
		);
	}
}



export default InfoIcon;
