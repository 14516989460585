import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import { updateReportTask, removeReportTaskOnCompletion } from 'actions/reports/actionCreators';
import { isSafari } from 'react-device-detect';

class CheckReportStatus extends NetPackWrapper {

	constructor() {
		super('GET', true);
	}

	prepareUrl = apiInfoObject => {
		return urls.getIn(['REPORT', 'GET_REPORT_STATUS']) + '?taskId=' + apiInfoObject.taskId;
	};

	successCall = (response, apiInfoObject) => {
		const isTaskCompleted = response.completionPercentage == 100 || response.completionPercentage == "100";
		if (isTaskCompleted) {
			if(isSafari){
				window.setTimeout(() => window.open(response.reportLink, "_top")); // to download the report //the setTimeout hack is for safari - https://stackoverflow.com/a/70463940
			} else {
				window.open(response.reportLink, "_blank");
			}
			
			window.clearInterval(apiInfoObject.pollInterval); // stop the polling
		}
		this.dispatch(updateReportTask(
			apiInfoObject.reportType,
			apiInfoObject.batchId,
			response
		));

		return response;
	}

	onError = (errorObj, apiInfoObject) => {
		this.dispatch(removeReportTaskOnCompletion(
			errorObj.apiInfoObject.reportType,
			errorObj.apiInfoObject.batchId
		));
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, unable to generate your report/certificate. Please try again!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new CheckReportStatus();
