import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		navigationContainer: {
            // boxShadow: `0 2px 0 0 ${hexToRgbA(skin.get('black'), 0.25)}`,
            backgroundColor: skin.get('white')
        },
		tabName: {
			...myTypography.h6,
			fontWeight: 'normal',
			color: hexToRgbA(skin.get('greyColor2'), 0.7),
			lineHeight: 1.1,
            ':hover': {
                fontWeight: 600,
                color: skin.get('greyColor2')
            }
		},
		tabNameActive: {
			...myTypography.h6,
			fontWeight: 600,
			color: skin.get('greyColor2'),
			lineHeight: 1.1,
		},
		tabLine: {
			backgroundColor: skin.get('greyColor3'),
			borderRadius: '2px'
		},
		lineContainer: {
			backgroundColor: skin.get('greyColor5')
		}
	});
}

export default getSkin;