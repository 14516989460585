import React, { Component } from 'react';
import styles from './closeCircle.module.sass';
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CloseCircle extends Component {

	constructor(props) {
		super(props);
		this.state = {
			onHover: false
		};
	}

	setHoverTrue = () => {
		this.setState({
			onHover: true
		});
	}

	setHoverFalse = () => {
		this.setState({
			onHover: false
		});
	}

	render() {

		const skinHex = this.props.skinGuide.get('skinHex');
		const {
			circleFillOpacity,
			circleFill,
			strokeColor,
			strokeWidth
		} = this.props;
		return (
			<div styleName="main-component" onMouseEnter={this.setHoverTrue} onMouseLeave={this.setHoverFalse}>
				<svg width="100%" height="100%" viewBox="0 0 32 32">
					{/* <g>
						<path
							fill={this.state.onHover ? skinHex.get('primaryVariantColor') : skinHex.get('primaryColor')}
							d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1          C393,504.1,504.1,393,504.1,256z"/>
						<path fill={skinHex.get('white')} d="M285,256l72.5-84.2c7.9-9.2,6.9-23-2.3-31c-9.2-7.9-23-6.9-30.9,2.3L256,222.4l-68.2-79.2    c-7.9-9.2-21.8-10.2-31-2.3c-9.2,7.9-10.2,21.8-2.3,31L227,256l-72.5,84.2c-7.9,9.2-6.9,23,2.3,31c4.1,3.6,9.2,5.3,14.3,5.3    c6.2,0,12.3-2.6,16.6-7.6l68.2-79.2l68.2,79.2c4.3,5,10.5,7.6,16.6,7.6c5.1,0,10.2-1.7,14.3-5.3c9.2-7.9,10.2-21.8,2.3-31L285,256    z"/>
					</g> */}
					<g fill="none" fillRule="evenodd">
						<circle cx="16" cy="16" r="16" fill={circleFill || "#000"} opacity={circleFillOpacity ? circleFillOpacity : ".202"} />
						<g stroke={strokeColor ? strokeColor : "#F2F3F7"} strokeLinecap="round" strokeLinejoin="round" strokeWidth={ strokeWidth || "2"}>
							<path d="M8.5 23.5l15-15M23.5 23.5l-15-15" />
						</g>
					</g>
				</svg>
			</div>
		);
	}
}



export default CloseCircle;
