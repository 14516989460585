import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { appendQueryParameters } from 'utils/utilFunctions';

class FetchFirebaseCustomToken extends NetPackWrapper {

    constructor() {
        super("GET");
    }

    prepareUrl = (apiInfoObject = {}) => appendQueryParameters(
        urls.get('DEVELOPMENT_PHASE').get('FIREBASE_TOKEN'),
        {...apiInfoObject, ts_no_cache: `${apiInfoObject.userId}_${(new Date()).getTime()}`}
    ); 

    successCall = (response) => {
        return response;
    }
}

export default new FetchFirebaseCustomToken();
