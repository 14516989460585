import React from 'react';
import PropTypes from 'prop-types';
import CSSModules from 'react-css-modules';
import styles from './leaderboardComponent.module.sass';
import getSkin from './skin';
import { css } from 'aphrodite/no-important';
import InfoComponent from '../infoComponent/InfoComponent';

@CSSModules(styles, { allowMultiple: true })

class LeaderboardComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            viewMore: false,
            maxRank: 8,
            renderedUsersCount: 0
        }
    }

    scrollIntoView = () => {
        try{
            this.refs.userRank.scrollIntoView({block: 'center', behavior: 'smooth' });
        } catch(error){
            this.refs.userRank.scrollIntoView();
        }
    }

    componentDidUpdate() {
        if(this.state.viewMore && this.state.renderedUsersCount > 0)
            this.scrollIntoView();
    }

    getData = () => {
        return this.props.leaderboardData ? this.props.leaderboardData : this.props.data.leaderboardData;
    }

    toggleViewMore = () => {
        const maxRank = this.state.viewMore ? 8 : this.getData().data.length;
        this.setState( (prevState) =>  ({viewMore: !prevState.viewMore, maxRank: maxRank}));
    }

    displayListHeading = (strings) => {
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div className={css(myStyles.listHeadingStyle)} styleName="list-heading">
                <div styleName="list-rank-heading">{strings.get('rankLabel')}</div>
                <div styleName="list-avatar"></div>
                <div styleName="list-name-heading">{strings.get('nameLabel')}</div>
                <div styleName="list-score">{strings.get('scoreLabel')}</div>
            </div>
        );
    }

    displayListLegend = (count) => {
        const strings = this.props.allGraphsData.get('performance').get('leaderboardData').get('strings');
        const myStyles = getSkin(this.props.skinGuide);
        return (
            <div className={css(myStyles.listLegendStyle)} styleName="list-legend">
                <div styleName="legend"></div>
                <div styleName="legend-title" onClick={() => this.toggleViewMore()}>{strings.get('moreLabel').replace('PLACEHOLDER', '+' + count)}</div>
                <div styleName="legend"></div>
            </div>
        );
    }

    displayListItem = (element, isUser=false) => {
        if (!element) {
            return null;
        }
        const myStyles = getSkin(this.props.skinGuide);
        const style = isUser ? "list-item list-user-item" : "list-item";
        const classStyle = isUser ? css(myStyles.listUserItemStyle) : css(myStyles.listItemStyle);
        const currRankVal = element.rank - element.previousRank;
        const prevRankClassStyle = currRankVal > 0 ? css(myStyles.negativeRankIncreaseStyle) : currRankVal < 0 ? css(myStyles.positiveRankIncreaseStyle): "";
        const prevRankStyle = currRankVal > 0 ? "arrow-up" : currRankVal < 0 ? "arrow-down" : "";
        const showRankVariation = element.previousRank == -1 || currRankVal == 0 ? false : true;
        return (
            <div ref={isUser ? 'userRank' : ''} className={classStyle} styleName={style} key={Math.random()}>
                <div styleName="list-rank">
                    {element.rank !== -1 ? element.rank : 'NA'}
                    {showRankVariation &&
                        <div styleName="rank-variation-container">
                            <div className={prevRankClassStyle} styleName={prevRankStyle}>
                            </div>
                            <div styleName="list-previous-rank">{Math.abs(currRankVal)}</div>
                        </div>
                    }
                </div>
                <div styleName="list-name">
                    <img styleName="avatar-image" />
                    <div styleName="text-container">{element.userName}</div>
                </div>
                <div styleName="list-score">{element.score !== -1 ? element.score : 'NA'}</div>
            </div>
        );
    }

    displayList = () => {
        const myStyles = getSkin(this.props.skinGuide);

        const leaderboard = this.getData();
        let data = leaderboard.data;
        data = data.filter( userDataObj => userDataObj.rank != -1 );
        this.state.renderedUsersCount = data.length;
        if (data.length === 0) {
            return null;
        }

        data.sort((userData1, userData2) => userData1.rank - userData2.rank);

        let userRank;
        data.map((val, i) => { if (val.userId == this.props.user.get('userID')) userRank = i+1})

        const rankCount = leaderboard.data.length;
        const maxRank = this.state.maxRank;
        let afterCount, beforeCount, displayCount=maxRank-2, isUserLast=false, isUserInside=false;

        if(!userRank){
            afterCount = rankCount - maxRank;
            displayCount = maxRank;
        }  else if (userRank <= maxRank) {
            isUserInside = true;
            afterCount = rankCount - maxRank;
            displayCount = maxRank;
        }
        else if (userRank === rankCount) {
            isUserLast = true;
            beforeCount = userRank - maxRank - 2;
        }
        else {
            beforeCount = userRank - maxRank + 1;
            afterCount = rankCount - userRank - 1;
        }

        displayCount = Math.min(displayCount, data.length);

        let list = [];
        for (let index = 0; index < displayCount; index++) {
            if (userRank === index+1) {
                list.push(
                    this.displayListItem(data[index], true)
                );
            }
            else {
                list.push(
                    this.displayListItem(data[index])
                );
            }
        }

        if (isUserLast) {
            list.push(this.displayListLegend(beforeCount));
            list.push(this.displayListItem(data[userRank-2]));
            list.push(this.displayListItem(data[userRank-1], true));
        }
        else {
            if (userRank && !isUserInside) {
                list.push(this.displayListLegend(beforeCount));
                list.push(this.displayListItem(data[userRank-1], true));
                list.push(this.displayListItem(data[userRank]));
            }
            // if (afterCount != 0)
            //     list.push(this.displayListLegend(afterCount));
        }

        return (
            <div className={css(myStyles.leaderboardListStyle)}  styleName="leaderboard-list">
                {list}
            </div>
        );
    };

    renderViewMoreButton = (strings) => {
        const myStyles = getSkin(this.props.skinGuide);
        const leaderboard = this.getData();
        const data = leaderboard.data;
        const shouldShowViewMore = data.length >= this.state.maxRank ? true : false;
        if (shouldShowViewMore) {
            return (
                <div className={css(myStyles.viewMoreStyle)} styleName="view-more">
                    <div styleName="cursor-pointer" onClick={() => this.toggleViewMore()}>
                        {this.state.viewMore ? strings.get('viewLessLabel') : strings.get('viewMoreLabel')}
                    </div>
                </div>
            );
        }
        return null;
    }

    renderLeaderboard = () => {

        let strings = this.props.allGraphsData.get('performance').get('leaderboardData').get('strings');

        return(
            [<div styleName="leaderboard-list-container" key="leaderboardList">
                {this.displayListHeading(strings)}
                {this.displayList()}
                {this.renderViewMoreButton(strings)}
            </div>
            ]
        )
    }

    render(){
        
        if ('data' in this.getData() && this.getData().data.length > 0) {
            return(
                this.renderLeaderboard()
            )
        }
        return <InfoComponent {...this.props} infoMessage={this.props.genericStrings.get('dataNotPresentKey')}/>
    }
}

LeaderboardComponent.propTypes = {
    data: PropTypes.object.isRequired,
};

LeaderboardComponent.defaultProps = {

};

export default LeaderboardComponent;
