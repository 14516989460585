import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);
	
	return StyleSheet.create({
		scoreTitle: {
			...myTypography.h5,
			fontWeight: 600,
			color: skin.get('contentColor')
		},
		scoreSubTitle: {
			...myTypography.overline,
			color: hexToRgbA(skin.get('greyColor1'), 0.85 * 0.5)
		},
		leftIcon: {
			backgroundImage: `url()`
		},
		leftName: {
			...myTypography.h6,
			fontSize: '18px',
			color: skin.get('greyColor2')
		},
		leftInfo: {
			backgroundImage: `url()`
		},
		mainRow: {
			border: `1px solid ${skin.get('greyColor5')}`,
			borderRadius: '4px'
		},
		mainLeft: {
			backgroundColor: skin.get('greyColor5')
		},
		tableHeading: {
			...myTypography.button,
			color: hexToRgbA(skin.get('greyColor1'), 0.85 * 0.6),
			textTransform: 'uppercase'
		},
		tableItemBorder: {
			borderRight: `1px solid ${skin.get('greyColor5')}`
		},
		userDot: {
			backgroundColor: hexToRgbA(skin.get('greyColor1'), 0.85 * 0.6)
		},
		legendText: {
			...myTypography.button,
			color: skin.get('greyColor2'),
			fontWeight: 400
		},
		mainLine: {
			backgroundColor: hexToRgbA(skin.get('greyColor3'), 0.5)
		},
		leftArrow: {
			borderRight: `8px solid ${hexToRgbA(skin.get('greyColor3'), 0.5)}`,
			borderTop: `8px solid transparent`,
			borderBottom: `8px solid transparent`
		},
		rightArrow: {
			borderLeft: `8px solid ${hexToRgbA(skin.get('greyColor3'), 0.5)}`,
			borderTop: `8px solid transparent`,
			borderBottom: `8px solid transparent`
		},
		desirableText: {
			...myTypography.button,
			opacity: 0.5,
			color: skin.get('greyColor3')
		}
	});
}

export default getSkin;