import React, { Component } from 'react';
import styles from './circularProgressBar.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css, StyleSheet } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class CircularProgressBar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            radius: 38
        };
    }

	render() {
        const skinHex = this.props.skinGuide.get('skinHex');

        const circlePerc = 1;
        const value = this.props.value;
        const radius = this.state.radius;
        const circumference = 2 * 3.14 * radius;
        const fillValue = value * (circumference/100) * circlePerc;
        const strokeValue = `${fillValue},${circumference}`;

        const circleStrokeColor = skinHex.get('secondaryColor');
        const fullCircleStrokeColor = skinHex.get('greyColor5');

		return (
			<div styleName="score-container">
                <svg width="100%" height="100%" viewBox="0 0 100 100" style={{"WebkitTransform": "rotate(-90deg)", "transform" :"rotate(-90deg)"}}>
                    <g>
                        <circle r={radius} cy="50" cx="50"
                                        strokeWidth="8" stroke={fullCircleStrokeColor} fill="none" strokeLinecap="round" strokeDasharray={circumference * circlePerc}></circle>
                        <circle r={radius} cy="50" cx="50"
                            strokeWidth="12" stroke={circleStrokeColor} fill="none" strokeLinecap="round" strokeDasharray={strokeValue}
                        >
                            <animate
                                attributeType="CSS" attributeName="strokeDasharray" 
                                from={`0,${circumference}`} to={`${fillValue},${circumference}`} dur="2s" repeatCount="1"
                            />
                        </circle>
                    </g>
                </svg>
            </div>
		);
	}
}

export default CircularProgressBar;
