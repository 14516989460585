/**
 * @author: Amul Garg
 * @since: Thu Aug 23 2018 14:41:31 GMT+0530 (IST)
 * @file: VideoPlayer.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Component to load and render video player
 *
 **/

import React, { Component } from 'react';
import ReactDependentScript from 'react-dependent-script';
import CSSModules from 'react-css-modules';
import environment from 'utils/environment';

import styles from './videoPlayer.module.sass';
import VideoPlayerLoader from 'commonComponents/loaders/videoPlayerLoader';

@CSSModules(styles, { allowMultiple: true })
class VideoPlayerInternal extends Component {

    getCompletionPoint = (videoDuration) => {
        let ninetyPercentOfDuration = 0.9 * videoDuration;
        let thirtySecondsBeforeDuration = videoDuration - 30;
        return Math.max(ninetyPercentOfDuration, thirtySecondsBeforeDuration);
    }
    
	playerReadyCallback = (playerID) => {
        console.log('playerID. progress', playerID, this.props.progress);
        
		var self = this;
        let seekDone = false;
        
		const {
			onUpdateProgress = (progress, is_completed = false) => console.log("On Progress Update", progress, is_completed),
            progress = {progress: 0, is_completed: false},
            onVideoLoadComplete
        } = this.props; /*with default props*/
        
        onVideoLoadComplete();

        if(this.props.onStart) this.props.onStart();
		  
		var kdp = document.getElementById(playerID);
		kdp.kBind('playerReady', function(event){
            console.log('event: video ready');
            const totalDuration = kdp.evaluate('{duration}');

            console.log('totalDuration, progress', totalDuration, progress, parseFloat(progress.progress));
            const completionPoint = self.getCompletionPoint(totalDuration);
	
			kdp.kBind('playerPaused', function(event){
				console.log("event: video paused");
				let progress = kdp.evaluate('{video.player.currentTime}');
				let is_completed = kdp.triggeredEndDone;
				onUpdateProgress(progress, totalDuration, is_completed);
			});
	
			kdp.kBind('playerPlayEnd', function(event){
				console.log("event: video end");
				let progress = kdp.evaluate('{video.player.currentTime}');
				onUpdateProgress(progress, totalDuration, true);
			});
	
			kdp.kBind('playerUpdatePlayhead', function(event){
				let currentProgress = kdp.evaluate('{video.player.currentTime}');
				if(!self.state.isVideoCompleted){
					if(currentProgress >= completionPoint){
						self.setState({isVideoCompleted: true});
						console.log('event: marking video complete', progress, completionPoint);
						onUpdateProgress(currentProgress, totalDuration, true);
					}
				}				
			});
		});

        kdp.kBind('mediaReady', function(){
            if(!seekDone){
                if(progress.progress){ // seek video to user's saved progress
                    let seekTime = progress.progress;                    
                    const totalDuration = kdp.evaluate('{duration}');

                    if(seekTime > totalDuration || seekTime < 0){
                        seekTime = 0;
                    }
                    
                    kdp.sendNotification("doSeek", seekTime);
                    seekDone = true;
                }
            }
        });
		
	};

	componentWillUnmount(){
		let kdp = document.getElementById(this.props.videoPlayerTargetId);		
		try{
			console.log('unmount', kdp.evaluate('{video.player.currentTime}'));
            this.props.onUpdateProgress(kdp.evaluate('{video.player.currentTime}'));
            window.kWidget.destroy(this.props.videoPlayerTargetId);
		} catch(error){
			console.log('Error: Could\'t save progress on unmount', error);
		}	
	}

    componentDidMount() {
        if(window.kWidget){
            this.setState({isVideoCompleted: this.props.isVideoCompleted});
            window.kWidget.embed({
            'targetId': this.props.videoPlayerTargetId,
            'wid': '_2413672',
            'uiconf_id': '42816162',
            'entry_id': this.props.config.kalturaEntryId,
            'flashvars': {
                'streamerType': 'hdnetworkmanifest'
            },
            jsTraces: true,
            'readyCallback': this.playerReadyCallback
            });      
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if (nextProps.config.kalturaEntryId == this.props.config.kalturaEntryId) return false;
        return true;
    }

    render() {
        return (
            <div
                id={this.props.videoPlayerTargetId}
                style={{
                    height: this.props.playerHeight,
                    width: this.props.playerWidth
                }}
            >
            </div>
        );
    }
}

@CSSModules(styles, { allowMultiple: true })
class VideoPlayer extends Component {

    constructor(props){
        super(props);
        this.state = {loaded: false};
    }

    renderVideoNotAvailableMessage = () => {
        return <div styleName="loader-container"><VideoPlayerLoader text="This video is not available at the moment. Please check back later."/></div>
    }

    onVideoLoadComplete = () => {
		this.setState({loaded: true})
	}

    render() {
        
        if(!this.props.config.kalturaEntryId){
            return this.renderVideoNotAvailableMessage();
        }

        return <React.Fragment>
            {this.state.loaded ? null : <div styleName="loader-container"><VideoPlayerLoader/></div>}

            <ReactDependentScript
                scripts={[
                    environment.REACT_APP_VIDEO_URL
                ]}
            >
                <div
                    style={{
                        height: this.props.playerHeight,
                        width: this.props.playerWidth
                    }}
                >
                    <VideoPlayerInternal {...this.props} onVideoLoadComplete={this.onVideoLoadComplete}/>
                </div>
            </ReactDependentScript>
        </React.Fragment>
    }
}

VideoPlayer.defaultProps = {
    videoPlayerTargetId: 'video-player-container',
    playerHeight: '952px',
    playerWidth: '480px'
}

export default VideoPlayer;