import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './scoreWithRadarGraphsDevelopment.module.sass';
import RadarGraph from 'commonComponents/graphs/radarGraph';
import InfoWithoutFilterComponent from 'commonComponents/analytics/infoWithoutFilterComponent/InfoWithoutFilterComponent';
import OverlappingBars from 'commonComponents/graphs/overlappingBars';
import LoaderComponent from 'commonComponents/analytics/loaderComponent';
import DropDown from 'commonComponents/analytics/dropDown';
import analyticGroupColors from 'constants/analyticGroupColors';
@CSSModules(styles, { allowMultiple: true })

class ScoreWithRadarGraphsDevelopment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            competencyIdForSubCompetencyGraph: null,
            competencyId: null,
            subCompetencyId: null
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        if(JSON.stringify(nextProps) === JSON.stringify(this.props) && JSON.stringify(nextState) === JSON.stringify(this.state)) {
            return false;
        }
        else{
            return true;
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(state.competencyId){
            return null;
        }
        const allGraphsData = props.allGraphsData.get('development');
        if (allGraphsData.get('isDevelopmentMeasuresFetched')) {
            const measuresData = allGraphsData.get('developmentMeasuresData').toJS();
            return (
                {
                    competencyIdForSubCompetencyGraph: 'selectedCompetencyId' in measuresData ? measuresData.selectedCompetencyId : -1,
                    competencyId: 'selectedCompetencyId' in measuresData ? measuresData.selectedCompetencyId : -1,
                    subCompetencyId: 'selectedSubCompetencyId' in measuresData ? measuresData.selectedSubCompetencyId : -1
                }
            )
        }
        return null;
    }

    componentDidMount() {
        const batchId = this.props.match.params.batchId;
        const userId = this.props.user.get('userID');
        const selectedPhase = this.props.selectedPhase;
        const phaseId = selectedPhase.get('id');
        this.props.fetchDevelopmentMeasuresData(+batchId, +userId, +phaseId);
    }

    getSubCompetencies = (competencyId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        const competencies = allGraphsData.get('developmentMeasuresData').get('competencies').get('data').toJS();
        const metadata = allGraphsData.get('developmentMeasuresData').get('competencies').get('metadata').toJS();
        // const subCompetencies = allGraphsData.get('developmentMeasuresData').get('subCompetencies').toJS();

        let data = [];
        if (competencyId === -1) {
            competencies.map(competency => {
                if ('subCompetencies' in competency) {
                    data.push(competency.subCompetencies.data);
                }
            })
        }
        else {
            const competency = competencies.filter(competency => competency.id === competencyId)[0];
            data = 'subCompetencies' in competency ? competency.subCompetencies.data : null;
        }

        return {
            data: [].concat.apply([], data),
            metadata: metadata
        };
    }

    removeDuplicates = (data) => {
        const uniqueIds = [...new Set(data.map((d) => d.id))];
        return data.filter((obj, index) => (uniqueIds.indexOf(obj.id) >= index));
    }

    getTopics = (competencyId, subCompetencyId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        // const topics = allGraphsData.get('developmentMeasuresData').get('topics').toJS();

        const competencies = allGraphsData.get('developmentMeasuresData').get('competencies').get('data').toJS();
        const metadata = allGraphsData.get('developmentMeasuresData').get('competencies').get('metadata').toJS();
        let data = [];
        if (competencyId === -1) {
            if (subCompetencyId === -1) {
                // data = topics.data;
                competencies.map(competency => {
                    if ('subCompetencies' in competency) {
                        competency.subCompetencies.data.map(subCompetency => {
                            if ('topics' in subCompetency) {
                                data.push(subCompetency.topics.data);
                            }
                        })
                    }
                })
            }
            else {
                // data = topics.data.filter((topic) => topic.subCompetencyId === subCompetencyId);
                competencies.map(competency => {
                    if ('subCompetencies' in competency) {
                        const subCompetency = competency.subCompetencies.data.filter(subCompetency => subCompetency.id === subCompetencyId)[0]
                        if (subCompetency && 'topics' in subCompetency) {
                            data.push(subCompetency.topics.data);
                        }
                    }
                })
            }
        }
        else {
            if (subCompetencyId === -1) {
                // data = topics.data.filter((topic) => topic.competencyId === competencyId);
                const competency = competencies.filter(competency => competency.id === competencyId)[0];
                if (competency && 'subCompetencies' in competency) {
                    competency.subCompetencies.data.map(subCompetency => {
                        if ('topics' in subCompetency) {
                            data.push(subCompetency.topics.data);
                        }
                    })
                }
            }
            else {
                // data = topics.data.filter((topic) => topic.competencyId === competencyId && topic.subCompetencyId === subCompetencyId);
                const competency = competencies.filter(competency => competency.id === competencyId)[0];
                if (competency && 'subCompetencies' in competency) {
                    const subCompetency = competency.subCompetencies.data.filter(subCompetency => subCompetency.id === subCompetencyId)[0]
                    if (subCompetency && 'topics' in subCompetency) {
                        data.push(subCompetency.topics.data);
                    }
                }
            }
        }
        return {
            data: this.removeDuplicates([].concat(...data)),
            metadata: metadata
        };
    }

    onCompetencyFilterChangedForSubCompetencyGraph = (competencyId) => {
        this.setState({
            competencyIdForSubCompetencyGraph: +competencyId
        });
    }

    onCompetencyFilterChanged = (competencyId) => {
        const allGraphsData = this.props.allGraphsData.get('development');
        const graphData = allGraphsData.get('developmentMeasuresData').toJS();
        const competencies = graphData.competencies;
        const competency = competencies.data.filter(competency => competency.id === +competencyId);
        const subCompetenciesForCompetency = 'subCompetencies' in competency ? competency.subCompetencies : [];
        const firstSubCompetencyId = subCompetenciesForCompetency.length > 0 ? subCompetenciesForCompetency[0].id : -1;
        this.setState({
            competencyId: +competencyId,
            subCompetencyId: 'selectedSubCompetencyId' in graphData && graphData.selectedSubCompetencyId !== -1 ? firstSubCompetencyId : -1
        });
    }

    onSubCompetencyFilterChanged = (subCompetencyId) => {
        this.setState({
            subCompetencyId: +subCompetencyId
        });
    }

    renderDevelopmentMeasuresGraphs = () => {
        const allGraphsData = this.props.allGraphsData.get('development');

        if (allGraphsData.get('isDevelopmentMeasuresFetched') && this.state.competencyId) {

            const { competencyIdForSubCompetencyGraph, competencyId, subCompetencyId } = this.state;
            const measureData = allGraphsData.get('developmentMeasuresData');
            const strings = measureData.get('strings');

            const isCompetenciesGraphAvailable = measureData.get('isCompetenciesGraphAvailable');
            const isSubCompetenciesGraphAvailable = measureData.get('isSubCompetenciesGraphAvailable');
            const isTopicsGraphAvailable = measureData.get('isTopicsGraphAvailable');

            const measuresDataGraph = measureData.toJS();
            const competencies = measuresDataGraph.competencies;
            let shouldShowAllOptionForCompetencyInSubCompetencyGraph = true, shouldShowAllOptionForCompetencyGraph = true, shouldShowAllOptionForSubCompetencyGraph = true;
            if ('selectedCompetencyId' in measuresDataGraph) {
                if (measuresDataGraph.selectedCompetencyId !== -1) {
                    shouldShowAllOptionForCompetencyInSubCompetencyGraph = false;
                    shouldShowAllOptionForCompetencyGraph = false;
                }
            }
            if ('selectedSubCompetencyId' in measuresDataGraph) {
                if (measuresDataGraph.selectedSubCompetencyId !== -1) {
                    shouldShowAllOptionForSubCompetencyGraph = false;
                }
            }
            let subCompetenciesForSubCompetencyGraph;
            let subCompetencies;
            let topics;

            if (isSubCompetenciesGraphAvailable) {
                subCompetenciesForSubCompetencyGraph = this.getSubCompetencies(competencyIdForSubCompetencyGraph);
            }
            if (isTopicsGraphAvailable) {
                subCompetencies = this.getSubCompetencies(competencyId);
                topics = this.getTopics(competencyId, subCompetencyId);
            }

            const devCompRadarStyling = {
                fromUser: '#ffce23',
                toUser: '#fea429',
                userStroke: '#feaa28',
                fromUserLegend: '#ffce23',
                toUserLegend: '#fea429',
                // fromGroup: '#ff0264',
                // toGroup: '#ff71ab',
                // groupStroke: '#ff0264',
                // fromGroupLegend: '#ff0264',
                // toGroupLegend: '#ff71ab',
                ...analyticGroupColors,
                gridValue: 5,
                componentName: 'dev-competency-score'
            };
    
            const devSubCompRadarStyling = {
                fromUser: '#26fa96',
                toUser: '#52f9aa',
                userStroke: '#79e071',
                fromUserLegend: '#26fa96',
                toUserLegend: '#52f9aa',
                // fromGroup: '#00faee',
                // toGroup: '#72fbf5',
                // groupStroke: '#71c9e0',
                // fromGroupLegend: '#00faee',
                // toGroupLegend: '#72fbf5',
                ...analyticGroupColors,
                gridValue: 5,
                componentName: 'dev-sub-competency-score'
            };
    
            const devTopicsBarStyling = {
                fromUser: '#ffce23',
                toUser: '#fea429',
                userRotation: 222,
                fromGroup: analyticGroupColors.fromGroup,
                toGroup: analyticGroupColors.toGroup,
                groupRotation: 356,
                gridValue: 9.5,
                componentName: 'dev-topics-score'
            };
    
            const devTopicsGraphFormat = {
                xLabel: strings.get('topicsLabel'),
                yLabel: strings.get('yAxisLabel')
            }
            const graphFormatCompetency = {
                valueName: strings.get('competencyLabel')
            };
    
            const graphFormatSubCompetency = {
                valueName: strings.get('subCompetencyLabel')
            };

            return(
                [
                <div styleName="dev-graph-content" key="course-score-graph">
                    {isCompetenciesGraphAvailable && <div styleName="course-score-container">
                        <InfoWithoutFilterComponent
                            {...this.props}
                            title={strings.get('competencyGraphTitle')}
                            subtitle={strings.get('competencyGraphDesc')}
                        />
                        <RadarGraph
                            graphFormat={graphFormatCompetency}
                            {...this.props}
                            // data={this.props.data.radarData}
                            barStyling={devCompRadarStyling}
                            graphData={competencies}
                            strings={strings.toJS()}
                        />
                    </div>}
                    {isSubCompetenciesGraphAvailable && <div styleName="course-score-container">
                        <div styleName="title-header">
                            <InfoWithoutFilterComponent
                                {...this.props}
                                title={strings.get('subCompetencyGraphTitle')}
                                subtitle={strings.get('subCompetencyGraphDesc')}
                            />
                            <DropDown handleOnClick={this.onCompetencyFilterChangedForSubCompetencyGraph} allLabel={strings.get('allLabel')} showAllOption={shouldShowAllOptionForCompetencyInSubCompetencyGraph} list={competencies.data} />
                        </div>
                        <RadarGraph
                            graphFormat={graphFormatSubCompetency}
                            {...this.props}
                            // data={this.props.data.radarData}
                            barStyling={devSubCompRadarStyling}
                            graphData={subCompetenciesForSubCompetencyGraph}
                            strings={strings.toJS()}
                        />
                    </div>}
                </div>,
                isTopicsGraphAvailable &&  <div styleName="dev-graph-content" key="topic-score-graph">
                    <div styleName="title-header">
                        <InfoWithoutFilterComponent
                            {...this.props}
                            title={strings.get('topicGraphTitle')}
                            subtitle={strings.get('topicGraphDesc')}
                        />
                        <DropDown handleOnClick={this.onCompetencyFilterChanged} allLabel={strings.get('allLabel')} showAllOption={shouldShowAllOptionForCompetencyGraph} list={competencies.data} />
                        <DropDown handleOnClick={this.onSubCompetencyFilterChanged} allLabel={strings.get('allLabel')} showAllOption={shouldShowAllOptionForSubCompetencyGraph} list={subCompetencies.data} />
                    </div>
                    <div styleName="title-header">
                        <OverlappingBars
                            isUserDataAvailable={true}
                            skinGuide={this.props.skinGuide}
                            barStyling={devTopicsBarStyling}
                            // data={this.props.data.timeData}
                            size={this.props.size}
                            hasAverageLines={false}
                            graphFormat={devTopicsGraphFormat}
                            graphData={topics}
                            strings={strings.toJS()}
                        />
                    </div>
                </div>]
            )
        }

        return;
    }

    render() {
        return(
            <div styleName="outer-dev-graph-container">
                <div styleName="dev-graph-container">
                    {this.renderDevelopmentMeasuresGraphs()}
                </div>
            </div>
        )
    }
}
 

export default ScoreWithRadarGraphsDevelopment;