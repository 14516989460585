/**
 * @author: Amul
 * @since: Thu Sep 5 2018 15:20:31 GMT+0530 (IST)
 * @file: ModulesSidebarContainer.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * base container is rendered on initial load of application and mounted only
 * once displayed on every route of the application and all other containers are
 * children of this base container
 *
 **/

import React from 'react';
import { connect } from 'react-redux';
import ModulesSidebar from 'components/modulesSidebar';
import { fetchModules } from 'actions/moduleItems/apiActions';
import { fetchModuleItemsList } from 'actions/courses/apiActions';
import { fetchAnalyticsButtonData } from 'actions/analytics/apiActions';
import { setCourseActiveSection } from 'actions/courses/actionCreators';
import { getActiveBatchDetails } from 'utils/batchUtils';

class ModulesSidebarContainer extends React.Component {

    getSidebarHeaderTitle = () => {
        const { phaseItemData, phase, batches } = this.props;
        const batch = getActiveBatchDetails(batches);

        if (batch && batch.get('totalPhases') == 1) {
            return batch.get('journey').get('name');
        }

        if (phase && phase.get('settings')) {
            if (phase.get('settings').get('developmentItemType') == 'MODULE' || phase.get('settings').get('developmentItemType') == 'MODULE_ITEMS') {
                return phase.get('name');
            }
        }

        return phaseItemData && phaseItemData.get('name') ? phaseItemData.get('name') : ''
    }

    componentDidMount() {
        const { batchId } = this.props.routeParams;
        const userId = this.props.user.get('userID');
        this.props.fetchAnalyticsButtonData(+batchId, +userId);
    }

    render() {
        return <ModulesSidebar {...this.props} sidebarHeaderTitle={this.getSidebarHeaderTitle()} />;
    }

}

const mapStateToProps = (state) => ({
    user: state.get('user'),
    batches: state.get('batches'),
    items: state.get('items'),
    userState: state.get('userState'),
    fetchModulesError: state.get('apiErrors').get('fetchModulesError'),
    coursesModulesList: state.get('coursesModulesList'),
    modulesItemsList: state.get('modulesItemsList'),
    coursesActiveSections: state.get('coursesActiveSections'),
    feedbackStatuses: state.get('feedbackStatuses')
});

const mapDispatchToProps = (dispatch) => ({
    fetchModules: (params) => {
        dispatch(fetchModules(params));
    },
    fetchAnalyticsButtonData: (batchId, userId) => {
        dispatch(fetchAnalyticsButtonData(batchId, userId))
    },
    fetchModuleItemsList: (params) => {
        fetchModuleItemsList(params);
    },
    setCourseActiveSection: (courseId, phaseItemId, moduleId) => {
        dispatch(setCourseActiveSection(courseId, phaseItemId, moduleId));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ModulesSidebarContainer);