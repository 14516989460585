import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './takeQuiz.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import applyLabel from 'utils/LabelProvider';
import * as Survey from "survey-react";
import TakeQuizHeader from './takeQuizHeader';
import "./takeQuiz.css";
import { Serializer } from "survey-core";
import QuizNavigator from './quizNavigator';
import ResetIcon from 'svgComponents/resetIcon';
import ReactDOM from 'react-dom';
import ReactDOMServer from "react-dom/server";
@applySkin
@applyLabel
@CSSModules(styles, { allowMultiple: true })

class TakeQuiz extends Component {

    constructor(props) {
        super(props);
        let model = this.initializeQuiz();
        const responses = props.quizResponseData.get('quizResultData') ? props.quizResponseData.getIn(['quizResultData', 'result']).toJS() : {};

        this.state = {
            survey: model,
            responses
        }
    }

    initializeQuiz = () => {
        const { quizData, quizResponseData, batchId, phaseItemId, updateQuizResponse, skinGuide } = this.props;
        const styles = getSkin(skinGuide);
        let model = new Survey.Model(quizData.get('dataWithoutAnswers').toJS());
        let responses = quizResponseData.get('quizResultData') ? quizResponseData.getIn(['quizResultData', 'result']).toJS() : {};

        model.data = responses;
        model.focusFirstQuestionAutomatic = false;

        model.onValueChanged.add((survey, options) => {
            const payload = {
                result: survey.data,
                quizId: quizData.get('quizId')
            }
            const responseNewObj = this.getResponseInNewObj(payload.result);
            this.setState({
                responses: responseNewObj
            }, () => updateQuizResponse(payload, batchId, phaseItemId));
        });

        model.onUpdateQuestionCssClasses
            .add(function (survey, options) {
                var { cssClasses } = options;

                cssClasses.mainRoot += " sq-qsttn";
                cssClasses.root = "sq-root";
                cssClasses.title += " sq-title" + " " + css(styles.questionText)
                cssClasses.item += " sq-item" + " " + css(styles.optionLabelText);
                cssClasses.label += " sq-option-row";// + " " + css(styles.optionLabelText);
                //cssClasses.controlLabel += " " + css(styles.optionLabelText)

                if (options.question.getType() === "checkbox") {
                    cssClasses.root += " sq-root-cb";
                }
            });
        return model;
    }

    getResponseInNewObj = (responses) => {
        const responseObj = {};
        Object.keys(responses).forEach((key) => {
            if (Array.isArray(responses[key])) {
                responseObj[key] = [...responses[key]];
            }
            else {
                responseObj[key] = responses[key];
            }
        })
        return responseObj;
    }

    componentWillUnmount() {
        clearInterval(this.timerid)
    }

    handleScrollTo = (name, survey) => {
        const id = survey.getQuestionByName(`${name}`).id;
        document.getElementById("appcontainer").scrollTop = document.getElementById(`${id}`).offsetTop - 150;
    }

    getResetSvgIcon = () => {
        let svg = document.createElementNS('http://www.w3.org/2000/svg', "svg");
        let g1 = document.createElementNS('http://www.w3.org/2000/svg', "g");
        let g2 = document.createElementNS('http://www.w3.org/2000/svg', "g");
        let path = document.createElementNS('http://www.w3.org/2000/svg', "path");
        svg.setAttribute("width", "100%");
        svg.setAttribute("height", "100%");
        svg.setAttribute('viewBox', '0 0 19 16');

        g1.setAttribute("stroke", 'none');
        g1.setAttribute("stroke-width", '1');
        g1.setAttribute("fill", 'none');
        g1.setAttribute("fill-rule", 'evenodd');

        g2.setAttribute("transform", 'translate(-12.000000, -545.000000)');
        g2.setAttribute("fill", '#000000');

        path.setAttribute("d", "M18.0409338,553.642785 C18.3563675,556.100614 20.456371,558 23,558 C25.7614237,558 28,555.761424 28,553 C28,550.238576 25.7614237,548 23,548 C22.4617273,548 21.9433208,548.085057 21.4574878,548.242464 L20.0741365,545.55194 C20.9803576,545.195663 21.967329,545 23,545 C27.418278,545 31,548.581722 31,553 C31,557.418278 27.418278,561 23,561 C19.0676427,561 15.7979518,558.162788 15.1263167,554.423755 L12.2900969,555.183717 L14.972171,548.10467 L20.8344389,552.894268 L18.0409338,553.642785 Z")
        g2.appendChild(path);
        g1.appendChild(g2)
        svg.appendChild(g1);
        return svg;
    }

    createQuestionLabelAndScoreElements = () => {
        return ({
            questionLabelAndScore: document.createElement("div"),
            questionLabel: document.createElement("span"),
            resetAnswerIconAndScore: document.createElement("div"),
            resetAnswerIcon: document.createElement("div"),
            score: document.createElement("span")
        })
    }

    setTextForQuestionLabelAndScoreElements = ({ questionLabel, resetAnswerIcon, score, questionCount, questionId }) => {
        const { hashedQuestions, getLabelWithDefaults } = this.props;
        questionLabel.innerHTML = 'Question' + " " + questionCount;
        score.innerHTML = `${hashedQuestions.getIn([questionId, 'score'])} PTS`;
        resetAnswerIcon.title = getLabelWithDefaults('quiz_reset_answer_label', 'Reset Answer');

    }

    setStyleForQuestionLabelAndScoreElements = ({ questionLabelAndScore,
        questionLabel, resetAnswerIconAndScore, resetAnswerIcon, score }) => {
        const styles = getSkin(this.props.skinGuide);
        questionLabelAndScore.style.cssText = 'display:flex;justify-content:space-between';
        questionLabel.className = css(styles.questionNumber);
        score.className = css(styles.questionPoints);
        resetAnswerIconAndScore.style.cssText = 'display:flex;align-items:center';
        resetAnswerIcon.style.cssText = 'width:15px;height:15px;margin-right:10px;cursor:pointer';
    }

    render() {
        let styles = getSkin(this.props.skinGuide);
        let questionCount = 0;
        const { quizData, quizResponseData, hashedQuestions, batchId, phaseItemId, submitQuiz, updateQuizResponse, selectedItem, getLabelWithDefaults } = this.props;
        const { survey, responses } = this.state;
        survey.showQuestionNumbers = "off"
        survey.onAfterRenderQuestion.add((survey, options) => {
            questionCount++;
            const questionId = options.question.name;

            let { questionLabelAndScore, questionLabel,
                resetAnswerIconAndScore, resetAnswerIcon, score } = this.createQuestionLabelAndScoreElements();

            this.setTextForQuestionLabelAndScoreElements({
                questionLabel, resetAnswerIcon,
                score, questionCount, questionId
            });

            this.setStyleForQuestionLabelAndScoreElements({
                questionLabelAndScore, questionLabel,
                resetAnswerIconAndScore, resetAnswerIcon, score
            });

            let resetSvgIcon = this.getResetSvgIcon();
            resetAnswerIcon.appendChild(resetSvgIcon);

            resetAnswerIconAndScore.appendChild(resetAnswerIcon);
            resetAnswerIconAndScore.appendChild(score);

            questionLabelAndScore.appendChild(questionLabel);
            questionLabelAndScore.appendChild(resetAnswerIconAndScore);

            resetAnswerIcon.onclick = () => {
                const { survey } = this.state;
                const { name } = options.question;
                let newRes = {};
                newRes = survey.data;
                delete newRes[name];
                survey.data = newRes;
                const payload = {
                    result: survey.data,
                    quizId: quizData.get('quizId')
                }
                const responseNewObj = this.getResponseInNewObj(survey.data);
                this.setState({
                    responses: responseNewObj
                }, () => updateQuizResponse(payload, batchId, phaseItemId));

            }

            var header = options
                .htmlElement

            if (header)
                header.before(questionLabelAndScore);

        })

        const myCss = {
            navigation: {
                start: "sv-nav-btn",
                complete: "sv-nav-btn"
            },
            row: "sv-row",
            checkbox: {
                itemControl: "sq-cb-input",
                label: "sq-cb-label",
                itemChecked: "sv-checked-option" + " " + css(styles.selectedOptionLabelText)
            },
            radiogroup: {
                itemChecked: "sv-checked-option" + " " + css(styles.selectedOptionLabelText),
                itemControl: "sv-rg-control-item"
            },
            question: {
                mainRoot: "sv-question"
            }
        }
        return (
            <div className={css(styles.quizQuestionsOuterCnt)}>
                <TakeQuizHeader
                    quizData={quizData}
                    quizResponseData={quizResponseData}
                    hashedQuestions={hashedQuestions}
                    answeredQuestion={responses}
                    batchId={batchId}
                    phaseItemId={phaseItemId}
                    submitQuiz={submitQuiz}
                    quizId={quizData.get('quizId')}
                    selectedItem={selectedItem}
                />
                <div styleName='quiz-questions-data' >
                    <div styleName='question-list-cnt' id="appcontainer" className={css(styles.questionListCnt)}>
                        <Survey.Survey model={survey} onComplete={this.onComplete} css={myCss} />
                    </div>
                    <div styleName="question-navigator-cnt">
                        <QuizNavigator
                            questions={quizData.getIn(['dataWithoutAnswers', 'elements'])}
                            answeredQuestion={responses}
                            handleScrollTo={this.handleScrollTo}
                            survey={survey}
                        />
                    </div>

                </div>
            </div>
        )
    }
}

export default TakeQuiz;