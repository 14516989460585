import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './journeyDashboard.module.sass';
import NextActivity from './nextActivity';
import LockBox from './lockBox';
import Content from './content';
import applyLabel from 'utils/LabelProvider';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import {
    getActivityStatusLabel,
    getActivityStat,
    getActivityTitle,
    getActivityActionButtonLabel
} from 'scripts/components/journeyDashboard/nextActivity';
import Footer from 'commonComponents/footer';
import OfflineBar from 'commonComponents/offlineBar';
import Analytics from './analytics';
import { filterObjectFromArray } from 'utils/objectUtils';
import { getActiveBatchDetails, getUserActiveBatchDetails } from 'utils/batchUtils';
import enumConstants from 'constants/enums';
import Congratulations from './congratulations/Congratulations';
import Report from './report/Report';
import CertificateContainer from 'containers/CertificateContainer';
import CertificateSectionContainer from 'containers/CertificateSectionContainer';
import Dialog from 'commonComponents/dialog/Dialog';
import { checkIfPresent, shouldShowAnalytics } from 'utils/utilFunctions';
import ComponentErrorBoundary from 'components/errorBoundaries/componentErrorBoundary';
import { getLockMessage } from 'utils/locks';
import JourneyInformationComponent from './journeyInformationComponent';
import AppLoader from 'commonComponents/appLoader';
import ActiveWebSession from './activeWebSession';
import UpcomingWebSessionsModal from './upcomingWebSessionsModal';
import { findTodaysSessionForBatch, findSessionsFromNowOnwardsForBatch, WEB_SESSION_END_TIMEOUT_BUFFER } from 'utils/webSessions';
import { isMobile } from 'react-device-detect';

@applyLabel
@CSSModules(styles, { allowMultiple: true })
class JourneyDashboard extends React.Component {

    activeSessionTimeout = null;
    setFinishedTimeout = null;

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            dialogMessage: null,
            showUpcomingWebSessionsModal: false,
            upcomingSessionHasBecomeActive: false,
            refreshTime: null
        };
    }

    componentDidMount(){
        if(!this.props.loaders.get('isFetchingBatchDashboardData')){
            this.setWebSessionActiveTimeout();
        }
    }

    componentDidUpdate(prevProps){
        if(prevProps.loaders.get('isFetchingBatchDashboardData') && !this.props.loaders.get('isFetchingBatchDashboardData')){
            if(!this.activeSessionTimeout){
                this.setWebSessionActiveTimeout();
            }
        }
    }

    componentWillUnmount(){
        window.clearTimeout(this.activeSessionTimeout);
        window.clearTimeout(this.setFinishedTimeout);
        this.activeSessionTimeout = null;
        this.setFinishedTimeout = null;
    }

    getBatchUserPhasesList = (phases) => {
        const batchPhasesList = phases.get('phasesList').filter(phase => phase.get('batchId') == this.props.match.params.batchId);
        const batchPhaseIds = batchPhasesList.map(phase => phase.get('id'));
        const batchUserPhasesList = phases.get('userPhasesList').filter(phase => batchPhaseIds.find(id => id == phase.get('id')));
        return batchUserPhasesList;
    }

    getActivePhase = (phases) => {       
        const batchUserPhasesList = this.getBatchUserPhasesList(phases);
        const activePhaseId = phases.get('activePhaseId');
        return filterObjectFromArray(batchUserPhasesList, 'id', activePhaseId);
    };

    getActiveItemId = (activePhase) => {
        return activePhase.get('activeItemId');
    }

    getActiveItem = (items, activeItemId) => {
        const itemsList = items.get('itemsList');
        return filterObjectFromArray(itemsList, 'id', activeItemId);
    }

    shouldRenderAnalyticsSection = (batchDetails) => {
        if(!shouldShowAnalytics()) return false;

        const analyticsEnabledForBatch = checkIfPresent(batchDetails.get('journey'))
        && checkIfPresent(batchDetails.get('journey').get('settings'))
        && checkIfPresent(batchDetails.get('journey').get('settings').get('showAnalytics')) && (batchDetails.get('journey').get('settings').get('showAnalytics') == 'true');

        return analyticsEnabledForBatch && this.props.userState.get('showWidgets')
    }

    renderAnalyticsSection = (batchDetails) => {
        if (this.shouldRenderAnalyticsSection(batchDetails)) {
            return <Analytics
                batchDetails={batchDetails}
                widgetData={this.props.widgetData}
                isWidgetDataFetched={this.props.isWidgetDataFetched}
                match={this.props.match}
                apiErrors={this.props.apiErrors}
                retryFetchWidgetAnalyticsData={this.props.retryFetchWidgetAnalyticsData}
            />;
        }
        return null;
    }

    getHeadingForPhaseBanner = (phaseDetails) => {
        let heading = '';
        if (phaseDetails && phaseDetails.get('name')) {
            heading = this.props.getLabel('journey_welcome_banner_text').replace(
                'JOURNEY_NAME',
                phaseDetails.get('name')
            );
        }
        return ({ heading });
    }

    getHeadingForJourneyBanner = (batchDetails) => {
        let heading = '';
        if (batchDetails && batchDetails.get('isDetailedDataAvailable')) {
            heading = this.props.getLabel('journey_welcome_banner_text').replace(
                'JOURNEY_NAME',
                batchDetails.get('journey').get('name')
            );
        }
        return ({ heading });
    }

    renderOfflineBar = () => {
        if (this.props.uiState.get('showInternetConnectivityBar')) {
            return (
                <OfflineBar
                    uiState={this.props.uiState}
                    setUiState={this.props.setUiState}
                    addToast={this.props.addToast}
                />
            );
        }
        return null;
    }

    getWebSessionDetails = (batch) => {
		const activeSessionDetails = findTodaysSessionForBatch(batch, true);

        console.log('debugws JourneyDashboard: activeSessionDetails', activeSessionDetails);

		if(activeSessionDetails.hasActiveSession){
			return activeSessionDetails;
		}
		const upcomingSessionDetails = findTodaysSessionForBatch(batch, false);
		if(upcomingSessionDetails.hasUpcomingSessionToday){
			return upcomingSessionDetails;
		}
		return {
			hasActiveSession: false,
			hasUpcomingSessionToday: false,
			session: null
		};
	}

    setWebSessionActiveTimeout = () => {
        const { batches, match } = this.props;
        const batchDetails = getActiveBatchDetails(batches, +match.params.batchId);
        const webSessionDetails = this.getWebSessionDetails(batchDetails);
        if(webSessionDetails && webSessionDetails.session){
            const { hasActiveSession, hasUpcomingSessionToday, session } = webSessionDetails;
            if(!hasActiveSession || hasUpcomingSessionToday){
                const timeToGoForTheSession = Math.abs(new Date(session.get('startDate')) - new Date());
                const twoMinutesInMilliseconds = 120000;
                if(timeToGoForTheSession <= twoMinutesInMilliseconds){ //if less than or equal to 2 mins to go
                    console.log('debugws JourneyDashboard set upcomingSessionHasBecomeActive true');
                    this.setState({ upcomingSessionHasBecomeActive: true });
                } else {
                    console.log('debugws JourneyDashboard else cond1');
                    this.activeSessionTimeout = window.setTimeout(() => {
                        console.log('debugws JourneyDashboard setWebSessionActiveTimeout setTimeout executing');
                        this.setState({ upcomingSessionHasBecomeActive: true });
                    }, timeToGoForTheSession - twoMinutesInMilliseconds);
                }
                
            }
            const timeToGoForTheSessionEnd = Math.abs(new Date(session.get('endDate')) - new Date());
			console.log('debugws JourneyDashboard: timeToGoForTheSessionEnd', timeToGoForTheSessionEnd - WEB_SESSION_END_TIMEOUT_BUFFER);
			this.setFinishedTimeout = window.setTimeout(() => {
				console.log('debugws JourneyDashboard session finished timeout executing');
				this.setState({refreshTime: (new Date()).getTime()});
			}, timeToGoForTheSessionEnd  - WEB_SESSION_END_TIMEOUT_BUFFER);
        }
    }

    renderFirstBoxAndNextItem = () => {

        const { location, batches, fetchCourseForNextActivity, courseForNextActivity, unsetCourseForNextActivity, match, setUiState, resetBatchProgressForUser } = this.props;

        const userActiveBatchDetails = getUserActiveBatchDetails(batches, +match.params.batchId);

        const { phases, items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails);

        const batchDetails = getActiveBatchDetails(batches, +match.params.batchId);

        const activityLabel = this.props.getLabel(getActivityStatusLabel(items, phases));
        const activityTitle = this.props.getLabel(getActivityTitle(items, phases));
        const activityStat = getActivityStat(items, phases);
        const activityButtonLabel = this.props.getLabel(getActivityActionButtonLabel(items, phases));
        const activePhase = this.getActivePhase(phases);

        const activeItemId = this.getActiveItemId(activePhase);
        const activeItem = this.getActiveItem(items, activeItemId);

        const lockSection = this.renderLockSection(userActiveBatchDetails);

        const webSessionDetails = this.getWebSessionDetails(batchDetails);

        if (lockSection) {
            return <div style={{ width: '100%' }}>
                {lockSection}
            </div>;
        }

        return (
            <div style={{ width: '100%' }}>
                {lockSection}
                <div
                    style={{
                        width: '100%',
                        display: this.props.userState.get('showStickyHeader') ? 'flex' : 'none',
                        position: this.props.userState.get('showStickyHeader') ? "absolute" : "static",
                        top: this.props.userState.get('showStickyHeader') ? 0 : "auto",
                        left: this.props.userState.get('showStickyHeader') ? 0 : "auto",
                        zIndex: 25,
                        backgroundColor: 'transparent'
                    }}
                >
                    {
                        webSessionDetails.hasActiveSession ? <ActiveWebSession 
                            webSessionDetails={webSessionDetails}
                            onClickOtherSessions={() => this.onClickOtherSessions()}
                        /> :
                    <NextActivity
                        location={location}
                        activityLabel={activityLabel}
                        activityTitle={activityTitle}
                        activityStat={activityStat}
                        activityButtonLabel={activityButtonLabel}
                        activeItemId={activeItemId}
                        setUserState={this.props.setUserState}
                        activePhase={activePhase}
                        activeItem={activeItem}
                        setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
                        setItemWorkflowStatus={this.props.setItemWorkflowStatus}
                        phases={phases}
                        items={items}
                        // nextActivityRef={this.props.userState.get('stickyRef')}
                        showStickyHeader={this.props.userState.get('showStickyHeader')}
                        fetchCourseForNextActivity={fetchCourseForNextActivity}
                        courseForNextActivity={courseForNextActivity}
                        unsetCourseForNextActivity={unsetCourseForNextActivity}
                        routeParams={match.params}
                        toggleDialog={this.toggleDialog}

                        nextActivityModuleItem={this.props.nextActivityModuleItem}
                        fetchNextActivityModuleItem={this.props.fetchNextActivityModuleItem}
                        unsetNextActivityModuleItem={this.props.unsetNextActivityModuleItem}
                        apiErrors={this.props.apiErrors}
                    />}
                </div>
                {
                    lockSection
                        ? null
                        :
                        <React.Fragment>
                            <JourneyInformationComponent
                                setUiState={setUiState}
                                userActiveBatchDetails={userActiveBatchDetails}
                                batchDetails={batchDetails}
                                resetBatchProgressForUser={resetBatchProgressForUser}
                                isResetBatchProgressFailed={batches.get('isResetBatchProgressFailed')}
                                webSessionDetails={webSessionDetails}
                                onClickOtherSessions={() => this.onClickOtherSessions()}
                            />
                        </React.Fragment> 
                }
                
                { webSessionDetails.hasActiveSession ? 

                    <ActiveWebSession 
                        webSessionDetails={webSessionDetails}
                        onClickOtherSessions={() => this.onClickOtherSessions()}    
                    />: 
                    <NextActivity
                        location={location}
                        activityLabel={activityLabel}
                        activityTitle={activityTitle}
                        activityStat={activityStat}
                        activityButtonLabel={activityButtonLabel}
                        activeItemId={activeItemId}
                        setUserState={this.props.setUserState}
                        activePhase={activePhase}
                        activeItem={activeItem}
                        setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
                        setItemWorkflowStatus={this.props.setItemWorkflowStatus}
                        phases={phases}
                        items={items}
                        nextActivityRef={this.props.userState.get('stickyRef')}
                        showStickyHeader={this.props.userState.get('showStickyHeader')}
                        fetchCourseForNextActivity={fetchCourseForNextActivity}
                        courseForNextActivity={courseForNextActivity}
                        unsetCourseForNextActivity={unsetCourseForNextActivity}
                        routeParams={match.params}
                        toggleDialog={this.toggleDialog}

                        nextActivityModuleItem={this.props.nextActivityModuleItem}
                        fetchNextActivityModuleItem={this.props.fetchNextActivityModuleItem}
                        unsetNextActivityModuleItem={this.props.unsetNextActivityModuleItem}
                        apiErrors={this.props.apiErrors}
                    />}
            </div>
        );
    }

    renderCongratulationsAndReportMessage = () => {
        const { setUiState, resetBatchProgressForUser, match } = this.props;
        const batchDetails = getActiveBatchDetails(this.props.batches, +match.params.batchId);
        const userActiveBatchDetails = getUserActiveBatchDetails(this.props.batches, +match.params.batchId);
        const lockSection = this.renderLockSection(userActiveBatchDetails);
        let showCertificateContainer = false;
        if (
            checkIfPresent(batchDetails.get('journey').get('settings'))
            && checkIfPresent(batchDetails.get('journey').get('settings').get('isCertificateAvailable'))
            && batchDetails.get('journey').get('settings').get('isCertificateAvailable') == "true"
        ) {
            showCertificateContainer = true;
        }

        const isLinkedInShareEnabled = batchDetails.getIn(['journey', 'settings', 'showLinkedInShare']) === 'true';

        return (
            <div style={{ width: '100%' }}>
                <JourneyInformationComponent
                    setUiState={setUiState}
                    userActiveBatchDetails={userActiveBatchDetails}
                    batchDetails={batchDetails}
                    resetBatchProgressForUser={resetBatchProgressForUser}
                />
                {lockSection}
                {isMobile ? <>
                    <Congratulations {...this.props} />
                    { showCertificateContainer &&  <CertificateContainer match={match} batchDetails={batchDetails} />}
                </> :
                <CertificateSectionContainer    
                    isCertificateEnabled={showCertificateContainer} 
                    isLinkedInShareEnabled={isLinkedInShareEnabled}               
                    batchId={+match.params.batchId}
                    batchDetails={batchDetails}
                    userActiveBatchDetails={userActiveBatchDetails}
                    match={match}
                />}
                {
                    this.shouldRenderAnalyticsSection(batchDetails)
                        ? this.renderReport()
                        : null
                }
            </div>
        );
    }

    renderReport = () => {
        return <Report {...this.props} />;
    }

    renderLockSection = (userActiveBatchDetails) => {
        const batchLockDetails = userActiveBatchDetails.get('lock_details');
        const lockExplanation = getLockMessage(batchLockDetails, 'long');
        const isBatchLocked = batchLockDetails.get('locked') && (batchLockDetails.get('lock_reason') === 'not_started' || batchLockDetails.get('lock_reason') === 'pre_requisite_not_done');

        if (this.isBatchLocked()) {
            return <div className="batch-message">
                <LockBox
                    heading={isBatchLocked ? "Locked" : "Expired"}
                    subHeading={lockExplanation}
                />
            </div>
        }

        return null;
    }

    isBatchLocked = () => {
        const { batches, match } = this.props;
        const userActiveBatchDetails = getUserActiveBatchDetails(batches, +match.params.batchId);
        const batchLockDetails = userActiveBatchDetails.get('lock_details');
        const isBatchLocked = batchLockDetails.get('locked') && (batchLockDetails.get('lock_reason') === 'not_started' || batchLockDetails.get('lock_reason') === 'pre_requisite_not_done');
        const isBatchExpired = batchLockDetails.get('lock_reason') == 'expired';
        const isBatchCompleted = (userActiveBatchDetails.get('workflowState') == enumConstants.get('COMPLETED'));
        return isBatchLocked || (isBatchExpired && !isBatchCompleted);
    }

    renderTopSection = (userPhasesList, phasesList) => {
        let showCongratulationsAndReportMessage = true;
        userPhasesList.map(phase => {
            if (phase.get('workflowState') !== enumConstants.get('COMPLETED')) {
                showCongratulationsAndReportMessage = false;
            }
        });

        if (!this.isBatchLocked()) {
            if (userPhasesList.size !== 0 && showCongratulationsAndReportMessage) {
                return this.renderCongratulationsAndReportMessage();
            }
        }

        return this.renderFirstBoxAndNextItem();
    }

    toggleDialog = (message = null) => {
        const showDialog = !this.state.showDialog;
        const dialogMessage = showDialog ? message : null;

        this.setState({
            showDialog: !this.state.showDialog,
            dialogMessage: dialogMessage
        });
    }

    renderDialog = () => {
        if (!this.state.showDialog) {
            return null;
        }

        const popupMessage = this.state.dialogMessage || this.props.getLabel('item_unavailable_label');
        const popupOkButtonText = this.props.getLabel('okay_label');;
        return (
            <Dialog
                key="item-status-dialog"
                dialogMessage={popupMessage}
                okAction={this.toggleDialog}
                okButtonText={popupOkButtonText}
            />
        );
    }

    onClickOtherSessions = () => {
        console.log('debugws: onClickOtherSessions');
        this.setState({showUpcomingWebSessionsModal: true});
    }

    onClickCloseUpcomingWebSessionsModal = () => {
        this.setState({showUpcomingWebSessionsModal: false});   
    }

    renderUpcomingWebSessionsModal = () => {
        if(!this.state.showUpcomingWebSessionsModal) return null;
        const { batches, match } = this.props;   
        const batchDetails = getActiveBatchDetails(batches, +match.params.batchId);
        const webSessionDetails = this.getWebSessionDetails(batchDetails);

        const upcomingWebSessions = findSessionsFromNowOnwardsForBatch(batchDetails);
        console.log('debugws: upcomingWebSessions', upcomingWebSessions.toJS())
        return <UpcomingWebSessionsModal
            upcomingWebSessions={upcomingWebSessions}
            batchDetails={batchDetails}
            activeWebSession={webSessionDetails.hasActiveSession && webSessionDetails.session} 
            onClickCloseUpcomingWebSessionsModal={() => this.onClickCloseUpcomingWebSessionsModal()}
        />;
    }

    render() {
        const { location, loaders, batches, fetchDevPhaseItems, fetchModuleItemsForSingleModuleCourse, fetchModulesForDashboard, course, coursesModulesList, modulesItemsList, match, feedbackStatuses } = this.props;        
        
        const userActiveBatchDetails = getUserActiveBatchDetails(batches, +match.params.batchId);
        
        const { phases, items } = setAvailabilityFlags(this.props.phases, this.props.items, userActiveBatchDetails);
        
        const batchDetails = getActiveBatchDetails(batches, +match.params.batchId);
        const activePhase = this.getActivePhase(phases);
        const activeItemId = this.getActiveItemId(activePhase);
        const batchPhasesList = phases.get('phasesList').filter(phase => phase.get('batchId') == +match.params.batchId);
        const batchUserPhasesList = this.getBatchUserPhasesList(phases);

        if (loaders.get('isFetchingBatchDashboardData')) return <AppLoader userState={this.props.userState} />;
        
        if (!loaders.get('isFetchingBatchDashboardData') && batchPhasesList.size < 1) {
            return <div styleName="no-phases">
                <center>
                    {this.props.getLabelWithDefaults('journey_no_phases_label', 'There are no phases in this journey')}
                </center>
            </div>;
        }
        

        return (
            <div styleName="complete-container">
                
                {this.renderOfflineBar()}
                
                <ComponentErrorBoundary>
                    {this.renderTopSection(batchUserPhasesList, batchPhasesList)}
                </ComponentErrorBoundary>
                
                {this.renderAnalyticsSection(batchDetails)}

                <Content
                    location={location}
                    phases={phases}
                    items={items}
                    attachmentsList={this.props.attachmentsList}
                    activeItemId={activeItemId}
                    loaders={loaders}
                    courses={this.props.courses}
                    activePhaseId={phases.get('activePhaseId')}
                    setUserState={this.props.setUserState}
                    fetchDevPhaseItems={fetchDevPhaseItems}
                    match={match}
                    setPhaseWorkflowStatus={this.props.setPhaseWorkflowStatus}
                    setItemWorkflowStatus={this.props.setItemWorkflowStatus}
                    toggleDialog={this.toggleDialog}
                    fetchModuleItemsForSingleModuleCourse={fetchModuleItemsForSingleModuleCourse}
                    fetchModulesForDashboard={fetchModulesForDashboard}
                    entireState={this.props.entireState}
                    course={course}
                    userState={this.props.userState}
                    apiErrors={this.props.apiErrors}
                    fetchBatchDetails={this.props.fetchBatchDetails}
                    unsetCourses={this.props.unsetCourses}
                    phaseCourses={this.props.phaseCourses}
                    coursesModulesList={coursesModulesList}
                    modulesItemsList={modulesItemsList}
                    feedbackStatuses={feedbackStatuses}
                    user={this.props.user}
                />
                
                <Footer />
                
                {this.renderDialog()}
                {this.renderUpcomingWebSessionsModal()}
            </div>
        );
    }
}

export default JourneyDashboard;