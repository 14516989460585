import { StyleSheet } from 'aphrodite';
import { typography } from 'utils/styleUtil';

const getSkin = (skin) => {
	const myTypography = typography(skin);
	return StyleSheet.create({
		headerTitle: {
			...myTypography.body1,
			fontWeight: 500,
			color: '#ffffff'
		},
		iconText: {
			fontFamily: skin.getIn(['fontFamily', 'bodyFamily']),
			fontSize: '8px',
			color: '#ffffff'
		},
		submitBtnText: {
			...myTypography.button,
			fontWeight: 500,
			color: '#4a57b0'
		}
	});
}

export default getSkin;