import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { appendQueryParameters } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import {
	setRequestedUserBatchesData,
	setAvailableJourneys
} from 'actions/userJourneys/actionCreators'

import {
	setAvailableJourneysLoading
} from 'actions/journeys/actionCreators';

class FetchRequestedJourneys extends NetPackWrapper {

	constructor() {
		super("GET");
	}


	prepareUrl = (apiInfoObject) => {
		return appendQueryParameters(
			urls.getIn(['PLATFORM', 'GET_USER_JOURNEY_INSTANCES']),
			apiInfoObject.queryParameters
		);
	}

	successCall = (response, apiInfoObject) => {
		const myResponse = { ...response };

		const userBatchesList = myResponse.journeyInstances;

		delete myResponse['journeyInstances'];

		const userBatchesListAdditionalDetails = myResponse;

		const payload = {
			userBatchesList,
			userBatchesListAdditionalDetails
		};

		if (apiInfoObject.queryParameters.include === 'REQUESTED,REJECTED') {
			this.dispatch(setRequestedUserBatchesData(payload));
		} else {
			this.dispatch(setAvailableJourneys(payload));
		}
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(setAvailableJourneysLoading(false));
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, something went wrong. Please refresh the page!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new FetchRequestedJourneys();
