import Immutable from 'immutable';
import { filterObjectFromArray } from 'utils/objectUtils';
import enumConstants from 'constants/enums';
import {checkIfPresent} from 'utils/utilFunctions';

//TODO: check the completion logic for phases and items
const indexBy = (iterable, searchKey) => {
	return iterable.reduce(
		(lookup, item) => lookup.set(item.get(searchKey), item),
		Immutable.Map()
	);
}

const getActiveItemId = (phases) => {
	const phasesList = phases.get('userPhasesList');
	const activePhaseId = phases.get('activePhaseId');
	const activePhase = filterObjectFromArray(phasesList, 'id', activePhaseId);
	return activePhase.get('activeItemId');
}

const getItemPhaseDetails = (itemPhaseId, phases) => {
	const phasesList = phases.get('phasesList');
	const activePhase = filterObjectFromArray(phasesList, 'id', itemPhaseId);
	return activePhase;
}

const getActivityStatusLabel = (items, phases) => {



	let statusLabel = "";
	let activeItemId = getActiveItemId(phases);
	//let activeItemId = items.get('activeItemId');
	let itemsList = indexBy(items.get('itemsList'), 'id');
	let userItemsList = indexBy(items.get('userItemsList'), 'id');

	if (!activeItemId) {
		return '';
	}
	else if (activeItemId === -1) {
		statusLabel = 'journey_get_started_with';
	}
	else if (userItemsList.size > 0) {
		let activeItem = itemsList.find((item) => {
			return item.get('id') === activeItemId;
		});
		if(!checkIfPresent(activeItem)) {
			return;
		}
		let userActiveItem = userItemsList.find((item) => {
			return item.get('id') === activeItemId;
		});
		if(!checkIfPresent(userActiveItem)) {
			return;
		}
		if (userActiveItem.get('workflowState') == enumConstants.get('STARTED')) {
			statusLabel = 'continue_label';
		}
		else {
			const phaseDetails = getItemPhaseDetails(activeItem.get('phaseId'), phases);
			switch (phaseDetails.get('type')) {
				case enumConstants.get('ASSESSMENT'):
					if (activeItem.get('order') == 1) {
						statusLabel = 'first_assessment_label';
					}
					else {
						statusLabel = 'next_assessment_label'
					}
					break;
				case enumConstants.get('DEVELOPMENT'):
					if (activeItem.get('order') == 1) {
						statusLabel = 'first_course_item_label';
					}
					else {
						statusLabel = 'next_course_item_label'
					}
					break;
				default:
					statusLabel = 'next_activity_label';
			}
		}
	}

	return statusLabel;
}

const getActivityStat = (items, phases) => {
	let activityStat = "";
	activityStat = Math.floor(Math.random() * 100);
	return activityStat + "% of participants loved it.";
}

const getActivityTitle = (items, phases) => {

	let activityTitle = "";
	let activeItemId = getActiveItemId(phases);


	//	let activeItemId = items.get('activeItemId');
	let itemsList = indexBy(items.get('itemsList'), 'id');

	var phasesList = phases.get('phasesList').sortBy(phase => phase.get('order'));
	phasesList = indexBy(phasesList, 'order');

	if (itemsList.size === 0 || phasesList.size === 0) {
		return activityTitle;
	}

	if (activeItemId === -1) {
		let nextPhaseId = phasesList.first().get('id');
		itemsList = itemsList.filter((item) => {
			return (item.get('phaseId') === nextPhaseId);
		});
		itemsList = itemsList.sortBy(item => item.get('order'));

		let nextItem = itemsList.first();
		activityTitle = nextItem.get('name');
	}
	else {
		let activeItem = itemsList.find((item) => {
			return item.get('id') === activeItemId;
		});
		if (activeItem) {
			activityTitle = activeItem.get('name');
		}
	}
	return activityTitle;
}

const getActivityActionButtonLabel = (items, phases) => {
	let buttonLabel = "";
	let activeItemId = getActiveItemId(phases);
	let itemsList = indexBy(items.get('itemsList'), 'id');
	let userItemsList = indexBy(items.get('userItemsList'), 'id');

	if (activeItemId === -1 || activeItemId === null) {
		buttonLabel = 'start_label';
	}
	else if (userItemsList.size > 0) {
		let userActiveItem = userItemsList.find((item) => {
			return item.get('id') === activeItemId;
		});
		if (userActiveItem && userActiveItem.get('workflowState') == 'STARTED') {
			buttonLabel = 'continue_label';
		}
		else {
			buttonLabel = 'start_label';
		}
	}

	return buttonLabel;
}

export {
	getActivityStatusLabel,
	getActivityStat,
	getActivityTitle,
	getActivityActionButtonLabel,
	getItemPhaseDetails
};