/**
 * @author: sindhu
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Dashboard container is responsible for displaying Dashboard pages and
 * displayed on dashboard route of the application
 *
 **/

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    toggleProductLaunchWindow
} from 'actions/items/actionCreators';

import {goBackOnePage} from 'utils/webAppRoutes/updateRoute';


class DummyContainer extends Component {

    componentDidMount() {
        this.props.toggleProductLaunchWindow(false);
        goBackOnePage();
        window.location.reload();
    }
    render() {
        return null;
    }
}



const mapStateToProps = state => {

};

const mapDispatchToProps = dispatch => ({
    toggleProductLaunchWindow: (flag) => {
        dispatch(toggleProductLaunchWindow(flag));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DummyContainer);
