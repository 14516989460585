import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import { appendQueryParameters } from 'utils/utilFunctions';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';
import {
	setRequestedUserBatchesData,
	setAvailableJourneys
} from 'actions/userJourneys/actionCreators'

import {
	setAvailableJourneysLoading
} from 'actions/journeys/actionCreators';
import { setAvailableCorpDeckJourneysLoading } from '../../journeys/actionCreators';
import { setAvailableCorpDeckJourneys } from '../../userJourneys/actionCreators';

class FetchCorpDeckJourneys extends NetPackWrapper {

	constructor() {
		super("GET");
	}


	prepareUrl = (apiInfoObject = {}) => {
		return appendQueryParameters(
		urls.get('PLATFORM').get('GET_CORP_DECK_JOURNEYS'),
		apiInfoObject.queryParameters
	);
}

	successCall = (response, apiInfoObject) => {
		const { journeyList } = response;

		this.dispatch(setAvailableCorpDeckJourneys({ journeyList }));
		
		return response;
	}

	onError = (errorObj) => {
		this.dispatch(setAvailableCorpDeckJourneysLoading(false));
		this.dispatch(addToast({
			id: toastConstants.get('ID_ERROR'),
			type: toastConstants.get('TYPE_ERROR'),
			heading: 'Something went wrong',
			message: 'Sorry, something went wrong. Please refresh the page!',
			isDisplayed: false,
			autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
		}));
	}
}

export default new FetchCorpDeckJourneys();
