import React, { Component } from 'react';
import CSSModules from 'react-css-modules';
import styles from './Feedback.module.sass';
import applySkin from 'utils/SkinProvider';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applyLabel from 'utils/LabelProvider';
import enumConstants from 'constants/enums';
import CompetencyTags from './competencyTags';
import DescriptiveQues from './descriptiveQues';
import NewRating from './newRating';
import AssessmentItemDetailsLoader from 'commonComponents/loaders/assessmentItemDetailsLoader'
import MiniThumbsUpDown from 'components/moduleItemFeedback/miniThumbsUpDown';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class FeedbackQuestions extends Component {

	componentWillUnmount = () => {
		// this.props.resetUserData(false);
	}

	onClickOfNext = () => {
		const { submitFeedback, setShowThankyouToTrue } = this.props;
		const payload = {
			isSubmitted: true
		}
		if (this.props.toggleHideFeedback) {
			this.props.toggleHideFeedback()
		}
		submitFeedback(payload);
		setShowThankyouToTrue();
	}

	renderQuestionType = (question) => {
		const { submitAnswer, setResponseToQuestion, submitFeedback } = this.props;
		switch (question.get('type')) {
			case enumConstants.get('FEEDBACK_THUMBS_UP_DOWN'):
				return <MiniThumbsUpDown
					key={`itemFeedbackQuestionTUD_${question.get('id')}`}
					submitAnswer={submitAnswer}
					submitFeedback={submitFeedback}
					question={question}
				/>
			case enumConstants.get('FEEDBACK_RATING'):
				return <NewRating
					key={`itemFeedbackQuestionTUD_${question.get('id')}`}
					question={question}
					submitAnswer={submitAnswer} />
			case enumConstants.get('FEEDBACK_INPUT_MEDIUM_TEXT'):
				return <div styleName="question">
					<DescriptiveQues
						key={`itemFeedbackQuestionTUD_${question.get('id')}`}
						question={question}
						submitAnswer={submitAnswer} />
				</div>
			case enumConstants.get('FEEDBACK_MAQ'):
				return <div styleName="question">
					<CompetencyTags
						key={`itemFeedbackQuestionTUD_${question.get('id')}`}
						question={question}
						submitAnswer={submitAnswer} />
				</div>
			default:
				return <div></div>;
		}
	}

	renderQuestionsList = () => {
		const { itemFeedback } = this.props;

		let questions = itemFeedback.get('questionsList');
		questions = questions.map((question) => {
			return this.renderQuestionType(question)
		})

		return questions;
	}

	renderNextButton = (myStyles) => {
		const { itemFeedback } = this.props;
		if (itemFeedback.get('isNextButtonVisible')) {
			return <div styleName="button-style">
				<div className={css(myStyles.buttonStyle)} styleName="button-inner-style" onClick={this.onClickOfNext}>
					<div className={css(myStyles.buttonText)} styleName="button-text">{this.props.getLabel('next_label')}</div>
				</div>
			</div>
		}
		return null;
	}

	render() {
		const { itemFeedback } = this.props;
		const isFeedbackQuestionsFetched = itemFeedback.get('isFeedbackQuestionsFetched');

		const myStyles = getSkin(this.props.skinGuide);

		if (isFeedbackQuestionsFetched) {
			return <div styleName="feedback-question-container">
				{this.renderQuestionsList()}
				{/* {this.renderNextButton(myStyles)} */}

			</div>
		} else {
			return <AssessmentItemDetailsLoader />
		}
	}
}


export default FeedbackQuestions;
