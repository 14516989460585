import React, { Component } from 'react';
import styles from './moduleLine.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import routes from 'utils/webAppRoutes/routeConstants';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import CircularProgressBar from 'commonComponents/circularProgress';
import { css } from 'aphrodite/no-important';
import LockIcon from 'svgComponents/lockIcon/LockIcon';
import FeedbackIcon from 'svgComponents/feedbackIcon';
import { getLockMessage } from 'utils/locks';
import LockTooltip from 'commonComponents/lockTooltip';
import CircularLoader from 'svgComponents/circularLoader';
import CompletedFeedbackIcon from 'svgComponents/completedFeedbackIcon';
import { getDurationString, getDurationStringUsingUnits } from 'utils/utilFunctions';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ModuleLine extends Component {

	sendSegmentData = (event, options = {}) => {
		let { course } = this.props;
		let segmentData = {
			referrer: window.location.href,
			pathHash: window.location.hash,
			phaseId: course.getIn(['phaseItemData', 'phaseId']),
			phaseItemId: course.getIn(['phaseItemData', 'id']),
			alCourseId: course.getIn(['phaseItemData', 'toolId']),
			courseName: course.getIn(['phaseItemData', 'name']),
			batchId: course.getIn(['phaseItemData', 'batchId']),
			journeyId: course.getIn(['phaseItemData', 'journeyId']),
			...options
		}
		window.analytics.track(
			event,
			segmentData
		)
	}

	toggleClick = () => {  
		this.sendSegmentData('View More of course clicked', { modulesVisible: !this.props.isViewMoreClicked })
		this.props.toggleViewMoreClicked();
	}

	onModuleLineClick = (entity, entityType) => {
		let moduleId = null, moduleItemId = null;

		if(entityType === 'item'){
			moduleId = entity.get('module_id');
			moduleItemId = entity.get('id');
		} else{
			moduleId = entity.get('id');
			moduleItemId = entity.get('current_item_details') && entity.get('current_item_details').get('current_item_id');
		}


		const { batchID, phaseID, courseID, phaseItemId, course } = this.props
		this.sendSegmentData(
			'Module option clicked',
			{
				moduleId: moduleId,
				moduleName: entity.get('name')
			}
		);

		if (course.get('phaseItemData').get('isLocked') || course.get('phaseItemData').get('isExpired')) {
			return;
		}

		if (entity.get('lock_details') && (
			entity.get('lock_details').get('lock_reason') === 'locked' ||
			entity.get('lock_details').get('lock_reason') === 'not_started' ||
			entity.get('lock_details').get('lock_reason') === 'prerequisites')
		) {
			return;
		}


		if (moduleItemId) {
			const route = routes.get('BATCH_ITEM_MODULE');
			const params = [
				{ 'BATCH_ID': batchID },
				{ '_PHASE_ID_': phaseID },
				{ 'COURSE_ID': courseID },
				{ 'MODULE_ID': moduleId },
				{ '_PHASEITEMID_': phaseItemId },
				{ 'ITEM_ID': moduleItemId }
			]
			const routeObj = { route, params };
			this.props.checkAndSetPhaseAndItemStates(routeObj);
		}
	}

	goToCourseFeedback = () => {
		const { batchID, phaseID, phaseItemId, courseID, course } = this.props;
		const route = routes.get('BATCH_ITEM_COURSE_FEEDBACK');

		if (course.get('phaseItemData').get('isLocked') || course.get('phaseItemData').get('isExpired')) {
			return;
		}

		const params = [
			{ 'BATCH_ID': batchID },
			{ 'COURSE_ID': courseID },
			{ '_PHASE_ID_': phaseID },
			{ '_PHASEITEMID_': phaseItemId }
		];

		const routeObj = { route, params };
		updateRoute(routeObj)
	}

	getEntityCompletionPercentage = (entity, entityType) => {
		if(entityType === 'item'){
			if(entity.getIn(['completion_requirement', 'completed']) === true){
				return 100;
			}
			return 0;
		} else {
			if (entity.get('items_count') !== 0) {

				if (entity.get('required_items_count') === 0) {/*all items in the module are optional */
					return entity.get('optional_items_completed_count') * 100 / entity.get('optional_items_count');
				}
	
				return entity.get('items_completed_count') * 100 / entity.get('required_items_count');
			}
		}
		return 0;
	}

	getIcon = (entity, entityType) => {

		if (entity.get('lock_details').get('locked')) {
			return <div styleName="lock-icon-cnt">
				<LockIcon svgColor="#848793" />
			</div>;
		}

		return <CircularProgressBar percentage={this.getEntityCompletionPercentage(entity, entityType)} />;

	};

	getProgressLabel = (entity, entityType) => {
		
		const { getLabelWithDefaults } = this.props;

		if(entityType === 'item'){
			let moduleItemProgressLabel = '';
			if(entity.getIn(['completion_requirement', 'completed']) === true){
				moduleItemProgressLabel = getLabelWithDefaults('completed_label', 'Completed');
			}
			if(entity.get('duration')){
				const durationLabel = getDurationStringUsingUnits(entity.get('duration'), entity.get('duration_unit'));
				moduleItemProgressLabel =  moduleItemProgressLabel ? `${moduleItemProgressLabel} | ${durationLabel}` : durationLabel;
			}
			return moduleItemProgressLabel;
		} 

		const labels = [];
		let mandatoryItemsCompletedLabel = null;
		let optionalItemsLabel = null;

		if (entity.get('items_count') === 0) {
			return getLabelWithDefaults('module_no_items_label', `This module has no items`);
		}

		if (entity.get('required_items_count') !== 0) {
			mandatoryItemsCompletedLabel = getLabelWithDefaults(
				'count_items_completed_label',
				`${entity.get('items_completed_count')} / ${entity.get('required_items_count')} completed`, {
				completed_count: entity.get('items_completed_count'),
				total_count: entity.get('required_items_count')
			}
			);
			labels.push(mandatoryItemsCompletedLabel);
		}

		if (entity.get('optional_items_count') !== 0) {
			optionalItemsLabel = getLabelWithDefaults(
				'optional_module_items_count',
				`${entity.get('optional_items_count')} optional item(s)`,
				{total_module_items: entity.get('optional_items_count')}
				);
			labels.push(optionalItemsLabel);
		}

		if(entity.get('duration')){
			labels.push(getDurationString(entity.get('duration')));
		}

		if(labels.length > 0){
            return <span>{labels.join(' | ')}</span>
        }	

		return null;
	}

	getModuleLines = () => {

		// if the course has only 1 module, we are showing that module's items directly

		let { modules, areModulesLoaded, modulesItemsList, isSingleModuleCourse, getLabelWithDefaults } = this.props;
		const styles = getSkin(this.props.skinGuide);
		let content = [];
		let lockExplanation = null;
		let list = modules;
		let entityType = 'module';

		if (!areModulesLoaded) return <div styleName="loader-container"><CircularLoader /></div>;

		if(isSingleModuleCourse && modules.size > 0){
			const firstModule = modules.first();
			let moduleItemsDetails = modulesItemsList.find(listItem => listItem.get('id') === firstModule.get('id'));

			if(!(moduleItemsDetails && moduleItemsDetails.get('isModuleItemsListAvailable'))){
				return <div styleName="loader-container"><CircularLoader /></div>;
			}

			list = moduleItemsDetails.get('moduleItemsList');
			entityType = 'item';
		}

		if (list && list.size > 0) {
			content = list.map(entity => {
				lockExplanation = getLockMessage(entity.get('lock_details'));
				return <div key={`module_item_${entity.get('id')}`} styleName="individual-module-line-cnt" onClick={() => this.onModuleLineClick(entity, entityType)}>
					<LockTooltip
						tooltipText={lockExplanation}
						style={{ top: 25, left: 100 }}
						skinGuide={this.props.skinGuide}
					>
						<div styleName="individual-module-line">
							<div styleName="name-cnt">

								{this.getIcon(entity, entityType)}

								<div className={css(styles.moduleName)} styleName="name">
								{entity.get('is_optional') && <span>({getLabelWithDefaults('optional_label', 'Optional')}) </span>} {entityType === 'module' ? entity.get('name'): entity.get('title')}
								</div>
							</div>

							<div className={css(styles.completionStatusText)} styleName="completion-status">
								{
									this.getProgressLabel(entity, entityType)
								}
							</div>
						</div>
					</LockTooltip>
				</div>
			})
		}
		return content
	}

	render() {
		let { course, getLabelWithDefaults, feedbackStatuses, phaseItemId, isViewMoreClicked } = this.props;
		const styles = getSkin(this.props.skinGuide);

		const feedbackStatus = feedbackStatuses.get('phaseItemFeedbackStatuses').find(itemFeedback => itemFeedback.get('id') === phaseItemId)
		return (
			<div>
				{
					isViewMoreClicked ?
						[
							<div styleName="module-lines-cnt" key={course.get('id')}>
								{this.getModuleLines()}
								{
									course.getIn(['phaseItemData', 'settings', 'feedbackScenarioId']) &&
									course.getIn(['phaseItemData', 'settings', 'isFeedbackEnabled']) === 'true' &&
									<div
										key={`course_feedback_line`} styleName="individual-module-line-cnt feedback"
										onClick={this.goToCourseFeedback}
									>
										<div styleName="course-feedback-line">
											<div styleName="feedback-icon-cnt">
												{
													feedbackStatus && feedbackStatus.get('status') ?
														<CompletedFeedbackIcon />
														:
														<FeedbackIcon />
												}
											</div>
											{
												feedbackStatus && feedbackStatus.get('status') ?
													<div
														className={css(styles.likeToHearLabel)
														}
													>
														{this.props.getLabel(
															'thank_you_note_after_feedback'
														)}
													</div>
													:
													<div className={css(styles.likeToHearLabel)}>
														{
															getLabelWithDefaults(
																'like_to_hear_from_you_label',
																'We’d like to hear from you'
															)
														}
													</div>
											}
										</div>
									</div>
								}
							</div>,
							<div className={css(styles.viewActionBtn)} styleName="view-less-btn" onClick={this.toggleClick}>
								{getLabelWithDefaults('course_view_less_label', 'View Less')}
							</div>
						]
						:
						<div className={css(styles.viewActionBtn)} styleName="view-more-btn" onClick={this.toggleClick}>
							{getLabelWithDefaults('course_view_more_label', 'View More')}
						</div>
				}
			</div>
		);
	}
}


export default ModuleLine;
