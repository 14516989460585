/**
* @author: sourabh p
* @since: Tue Nov 27 2018 15:15:46 GMT+0530 (IST)
* @file: BatchValidator.js
*
* @copyright: KNOLSKAPE Solutions Pvt Ltd
**/

import AppValidator from 'utils/validators/AppValidator';
import { resolvePromise, rejectPromise } from 'utils/promise.js';
import setAvailabilityFlags from 'scripts/components/journeyDashboard/lock';
import { filterObjectFromArray, getIndexOfValueInList } from 'utils/objectUtils';
import enumConstants from 'constants/enums';
import { getUserActiveBatchDetails } from 'utils/batchUtils';

class AssessmentMeasuresPageValidator extends AppValidator {

    constructor() {
        super();
        this._showError = true;
    }

    isBatchValid = (batchId) => {
        const batchesList = this.getState().get('batches').get('userBatchesList');
        const isBatchesListFetched = this.getState().get('batches').get('isBatchesListFetched');

        if (isBatchesListFetched) {
            batchesList.map((batch) => {
                if (batch.get('batchId') === batchId) {
                    return resolvePromise();
                }
            })
            return rejectPromise({
                message: 'User does not belong to this batch'
            });
        }
        return resolvePromise();
    }

    isPhaseValid = (phasesList, phaseId) => {
        if (getIndexOfValueInList(phasesList, 'id', +phaseId) === -1) {
            return rejectPromise({
                message: 'phase doesn\'t belongs to batch'
            });
        }
        return resolvePromise();
    }

    isPhaseIsOfTypeAssessment = (phasesList, phaseId) => {
        const phaseDetails = filterObjectFromArray(phasesList, 'id', phaseId);

        if (phaseDetails.get('type') !== enumConstants.get('ASSESSMENT')) {
            return rejectPromise({
                message: 'The phase is not of assessment type.Hence, measures cannot be viewed'
            });
        }
        return resolvePromise();
    }

    checkItemsStatus = (phaseId) => {
        const userActiveBatchDetails = getUserActiveBatchDetails(this.getState().get('batches'));
        const { phases, items } = setAvailabilityFlags(this.getState().get('phases'), this.getState().get('items'), userActiveBatchDetails);

        let showMeasuresPage = true;
        const phaseItemsList = items.get('itemsList').filter(
            item => item.get('phaseId') == phaseId
        );

        phaseItemsList.map(item => {
            const userItemDetails = filterObjectFromArray(items.get('userItemsList'), 'id', item.get('id'))
            if (!item.get('isExpired') && userItemDetails.get('workflowState') !== enumConstants.get('COMPLETED')) {
                showMeasuresPage = false;
            }
        });

        if (showMeasuresPage) {
            return resolvePromise();
        } else {
            return rejectPromise({
                message: 'Atleast one of the items is incomplete'
            });
        }
    }

    postValidationLogic = (validationInfoObject) => {
        const phases = this.getState().get('phases');

        const batchId = validationInfoObject.ids.batchId;
        const phaseId = validationInfoObject.ids.phaseId;

        const phasesList = phases.get('phasesList');

        return this.isBatchValid(batchId)
            .then(response => this.isPhaseValid(phasesList, phaseId))
            .then(response => this.isPhaseIsOfTypeAssessment(phasesList, phaseId))
            .then(response => this.checkItemsStatus(phaseId))

    }

}

export default new AssessmentMeasuresPageValidator;