import React, { Component } from 'react';
import styles from './phaseItem.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import PhaseItemImg from 'images/dashboard/phase-item.png';
import { textTruncate, convertDateWithYear, getTimeDifference } from 'utils/utilFunctions';
import LockIcon from 'svgComponents/lockIcon';
import getDotColor from 'utils/dotColor';
import getTextColor from 'utils/textColor';
import enumConstants from 'constants/enums';
import ModuleItemTypes from 'svgComponents/moduleItemTypes';
import { replaceDatePlaceholderAndFormat } from 'utils/locks';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class PhaseItem extends Component {

    getClassNameForTitle = (myStyles) => {
        const { isSelected, workflowStatus, details } = this.props;
        return css(myStyles.title, getTextColor(
            isSelected,
            details.get('isLocked'),
            workflowStatus,
            details.get('isExpired'),
            this.props.skinGuide
        ));
    }

    getDaysReplacementLabel = (days = 0) => {
        if (days > 1) {
            return `${days} ${this.props.getLabel('days_label')}`;
        }
        return `${days} ${this.props.getLabel('day_label')}`;
    }

    getWeeksReplacementLabel = (weeks = 0) => {
        if (weeks > 1) {
            return `${weeks} ${this.props.getLabel('weeks_label')}`;
        }
        return `${weeks} ${this.props.getLabel('week_label')}`;
    }

    getHoursReplacementLabel = (timeDiff) => {
        return `${timeDiff['hours']} ${this.props.getLabel('hours_label')} 
        ${timeDiff['minutes']} ${this.props.getLabel('minutes_label')} 
        ${timeDiff['seconds']} ${this.props.getLabel('seconds_label')}`;
    }

    getItemDateLabel = (details) => {
        const styles = getSkin(this.props.skinGuide);

        const showLockMessage = details.get('isLocked') || details.get('isExpired')

        if (showLockMessage) {
            const lockExplanation = replaceDatePlaceholderAndFormat(true, details.get('lockExplanation'), details.get('lockDate'), "short");

            if (details.get('isExpired')) {
                return <span className={css(styles.expired)}>{lockExplanation}</span>;
            }
            return lockExplanation;
        }

        if (!details.get('endTimestamp')) {
            return null;
        }


        return (
            <span>
                <span>{this.props.getLabel('item_due_on_label')} </span>
                <span>{convertDateWithYear(new Date(details.get('endTimestamp')))}</span>
            </span>
        );
    }

    getItemStatusComponent = (isLocked, isExpired, isCompleted, isNotStarted, image) => {
        let statusBgClass = '';
        let statusLabel = '';
        const myStyles = getSkin(this.props.skinGuide);

        if (isLocked) {
            if (!image) {
                statusBgClass = myStyles.lockedBackground;
                statusLabel = this.props.getLabel('locked_label');
            }
        } else if (isCompleted) {
            statusBgClass = myStyles.completedLabelBackground;
            statusLabel = this.props.getLabel('completed_label');
        } else if (isExpired) {
            statusBgClass = myStyles.expiredTag;
            statusLabel = this.props.getLabel('expired_label');
        } else if (isNotStarted) {
            // dont add any class and label if it is not started
        } else {
            statusBgClass = myStyles.inProgressBackground;
            statusLabel = this.props.getLabel('in_progress_label');
        }

        return (
            <div
                className={css(statusBgClass, myStyles.progressLabel)}
                styleName="progress-status-content"
            >
                {statusLabel}
            </div>
        )
    }

    getClassNameForProgressBar = (myStyles) => {
        const { isSelected, workflowStatus, details } = this.props;
        return css(getDotColor(
            isSelected,
            details.get('isLocked') || details.get('isExpired'),
            workflowStatus,
            this.props.skinGuide
        ));
    }

    getTypeNameEnglish = (itemDetails, attachmentDetails) => {
        if (attachmentDetails) {
            return attachmentDetails.get('label');
        }
        return itemDetails.get('typeName')
    }

    getTypeNameWithLocale = (item, attachmentDetails) => {
		const type = attachmentDetails ? attachmentDetails.get('label') : item.get('type');
        const typeNameInEnglish = this.getTypeNameEnglish(item, attachmentDetails);
		try{
			return this.props.getLabelWithDefaults(`${type.toLowerCase()}_label`, typeNameInEnglish);
		} catch (e){
			return typeNameInEnglish;
		}
	}

    getPhaseItemIcon = (itemDetails, attachmentDetails) => {
        const { details: item } = this.props;
        if (item.get('type') === 'PAGE') {
            return <ModuleItemTypes itemType={'page'} />
        }
        if (item.get('type') === 'QUIZ') {
            return <ModuleItemTypes itemType={'quiz'} />
        }
        if (attachmentDetails) {
            return <ModuleItemTypes itemType={'attachment'} />
        }
        return <ModuleItemTypes itemType={'simulation'} />;
    }

    render() {
        const { details, attachmentDetails, formLabelForModuleItem, getLabelWithDefaults } = this.props;
        const myStyles = getSkin(this.props.skinGuide);

        let isLocked = false,
            isExpired = false,
            isInProgress = false,
            isNotStarted = false,
            isCompleted = false;

        if (details.get('isLocked')) {
            isLocked = true
        } else if (this.props.workflowStatus === enumConstants.get('COMPLETED')) {
            isCompleted = true
        } else if (details.get('isExpired')) {
            isExpired = true
        } else if (
            this.props.workflowStatus === enumConstants.get('UNOPENED')
            || this.props.workflowStatus === enumConstants.get('OPENED')
            || this.props.workflowStatus === enumConstants.get('NOT_STARTED')
        ) {
            isNotStarted = true
        } else if (this.props.workflowStatus === enumConstants.get('STARTED')) {
            isInProgress = true
        }

        let itemDateLabel = this.getItemDateLabel(details);
        const typeName = this.getTypeNameWithLocale(details, attachmentDetails);

        const imageIcon = details.get('icon');
        return (
            <div className={css(myStyles.individualPhaseItem)} styleName="individual-phase-item">
                <div className={css(myStyles.metaData)} styleName="metadata-cnt">
                    <div styleName="phase-item-icon">
                        {this.getPhaseItemIcon(details, attachmentDetails)}
                    </div>
                    <div styleName="phase-item-sub-metadata">
                        {typeName}
                    </div>
                    <div styleName={`phase-item-sub-metadata ${itemDateLabel ? 'border' : ''}`}>
                        {details.get('duration')} {details.get('durationUnit') && this.props.getLabel(details.get('durationUnit'))}
                    </div>
                    {
                        itemDateLabel ?
                            <div styleName="phase-item-sub-metadata border">
                                {itemDateLabel}
                            </div>
                            : null
                    }
                </div>

                <div styleName="item-details-outer-cnt">
                    <div styleName="item-details-cnt">
                        <div
                            className={this.getClassNameForTitle(myStyles)}
                            styleName="title">{textTruncate(this.props.getLabel(details.get('name'), 170))}
                        </div>
                        <div className={css(myStyles.desc)} styleName="desc">
                            {textTruncate(this.props.getLabel(details.get('description')), 250)}
                        </div>
                    </div>
                    {
                        imageIcon ?
                            <div styleName="item-thumbnail-cnt">
                                <img src={imageIcon} width="100%" height="100%" />
                                {
                                    isLocked ?
                                        <div styleName="locked-icon">
                                            <div style={{ height: '33px', width: '26px' }}>
                                                <LockIcon />
                                            </div>
                                        </div>
                                        : null
                                }
                            </div>
                            : null
                    }
                </div>

                {this.getItemStatusComponent(isLocked, isExpired, isCompleted, isNotStarted, imageIcon)}
            </div>
        );
    }
}

export default PhaseItem;
