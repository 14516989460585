import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';

const getSkin = (theme) => {
	const skin = theme.get('skinHex');
	const myTypography = typography(theme);

	return StyleSheet.create({
		individualPhaseItem: {
			borderRadius: '4px',
			backgroundColor: skin.get('white'),
			boxShadow: `0 4px 16px 0 ${hexToRgbA(skin.get('black'), 0.06)}`,
			border: `solid 1px ${hexToRgbA(skin.get('greyColor4'), 0.27)}`
		},
		title: {
			...myTypography.h6
		},
		desc: {
			...myTypography.caption,
			fontWeight: 'normal',
			color: skin.get('greyColor4')
		},
		completed: {
			color: skin.get('greyColor4')
		},
		pending: {
			color: skin.get('greyColor1')
		},
		started: {
			color: hexToRgbA(skin.get('darkSkyBlue'))
		},
		selected: {
			color: skin.get('secondaryColor')
		},
		completedBackground: {
			backgroundColor: hexToRgbA(skin.get('darkSkyBlue'), 0.5)
		},
		pendingBackground: {
			backgroundColor: skin.get('greyColor4')
		},
		startedBackground: {
			backgroundColor: hexToRgbA(skin.get('darkSkyBlue')),
		},
		selectedBackground: {
			backgroundColor: hexToRgbA(skin.get('secondaryColor'), 0.6)
		},
		outerBar: {
			backgroundColor: skin.get('greyColor5')
		},
		bottomText: {
			...myTypography.button,
			color: hexToRgbA(skin.get('greyColor3'), 0.8)
		},
		completedTag: {
			backgroundColor: skin.get('appleGreen'),
			color: skin.get('white'),
			...myTypography.button
		},
		expiredTag: {
			backgroundColor: skin.get('scarlet'),
			color: skin.get('white'),
			...myTypography.button
		},
		lockedDiv: {
			backgroundImage: `radial-gradient(circle at 50% 45%, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.63))`
		},
		metaData: {
			...myTypography.button,
			fontWeight: 'normal',
			color: hexToRgbA(skin.get('greyColor3'), 0.6)
		},
		expired: {
			color: hexToRgbA(skin.get('scarlet'), 0.6)
		},
		completedLabelBackground: {
			backgroundColor: skin.get('appleGreen')
		},
		lockedBackground: {
			backgroundColor: '#9a9eaf'
		},
		inProgressBackground: {
			backgroundColor: '#f5a623'
		},
		progressLabel: {
			...myTypography.button,
			color: skin.get('white')
		}
	});
}

export default getSkin;