/**
 * @author: Amul
 * @since: Thu Nov 18 2021 18:20:31 GMT+0530 (IST)
 * @file: locales.js
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Exports action related constants
 *
 **/

 import Immutable from 'immutable';

 const locales = Immutable.Map({
    'tr_tr': 'tr',
    'hi': 'hi',
    'en_us': 'en',
    'en_US': 'en'
 })

 export default locales;