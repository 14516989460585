import Immutable from 'immutable';
import actionConstants from 'constants/actions';

const initialState = Immutable.fromJS({});

function selectActiveAttempt(submissions) {
	let activeAttempt = null;
	if (submissions && submissions.length > 0) {
		activeAttempt = submissions.find(sub =>
			sub.workflow_state === "untaken"
		)
	}
	if (activeAttempt) {
		return activeAttempt
	} else {
		return {}
	}
}

const activeAttempt = (state = initialState, action) => {
	switch (action.type) {
		case actionConstants.get('SET_ACTIVE_QUIZ_ATTEMPT'):
			return Immutable.fromJS(selectActiveAttempt(action.quizSubmissions.quiz_submissions));
		default:
			return state
	}
}

export default activeAttempt;