import NetPackWrapper from 'actions/serverCalls/NetPackWrapper';
import urls from 'constants/urls/urls';
import {addUploadedFileToList, setFileUploadInProgress} from 'actions/moduleItems/actionCreators';
import { addToast } from 'actions/toasts/actionCreators';
import toastConstants from 'constants/toasts';

class UploadFile extends NetPackWrapper {
	constructor() {
		super('POST', false);
	}

	beforeCall = apiInfoObject => {
		this.dispatch(setFileUploadInProgress(true));
		return apiInfoObject;
	}

	prepareUrl = apiInfoObject => {
		return urls.get('DEVELOPMENT_PHASE').get('UPLOAD_FILE')
		.replace('_ACCESS_TOKEN_',this.getAccessTokenForDevPhase())
		.replace('_BATCH_ID_', apiInfoObject.batchId)
		.replace('_PHASE_ID_', apiInfoObject.phaseId)
		.replace('_PHASEITEMID_', apiInfoObject.phaseItemId)
	}

	successCall = (response, apiInfoObject) => {
		this.dispatch(addUploadedFileToList({
			id: response.file_id,
			name: apiInfoObject.fileName
		}));
		return response;
    }

    onError = (error) => {
        console.log('error', error);
        this.dispatch(addToast({
            id: toastConstants.get('UPLOAD_FILE_ERROR'),
            type: toastConstants.get('TYPE_ERROR'),
            heading: 'Something went wrong',
            message: 'Sorry, unable to upload files. Please try again!',
            isDisplayed: false,
            autoCloseTime: toastConstants.get('AUTO_CLOSE_TIME')
        }));
    }    
    
}

export default new UploadFile();