import React from 'react';
import CSSModules from 'react-css-modules';
import applySkin from 'utils/SkinProvider';
import { StyleSheet, css } from 'aphrodite/no-important';
import styles from './quizNavigator.module.sass';
import getSkin from './skin.js';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class QuizNavigator extends React.Component {

	scrollToQuestion = (questionId) => {
		let { setCurrentQuestion } = this.props;
		setCurrentQuestion(questionId)
	}

	getQuestionCircles = () => {
		let styles = getSkin(this.props.skinGuide);
		return this.props.questions.map((question, index) => {
			let isAnswererd = -1;
			isAnswererd = this.props.selectedAnswers.findIndex(ans => ans.get('id') == question.get('id') && ans.get('answer'))

			// Adding this condition to remove answered bubble from navigator for MAQs
			if (question.get('question_type') === 'multiple_answers_question' && isAnswererd > -1 && this.props.selectedAnswers.get(isAnswererd).get('answer').size === 0) {
				isAnswererd = -1;
			}

			let bubbleStyles = {};
			if (isAnswererd > -1) {
				bubbleStyles = {
					color: 'blue',
					backgroundColor: 'rgba(34,59,146,0.1)'
				}
			};
			return (
				<div key={`question_${question.get('id')}_navigator`} styleName="individual-question-bubble" style={bubbleStyles} onClick={() => this.scrollToQuestion(question.get('id'))}>
					{index + 1}
					{
						question.get('flagged') &&
						<div styleName="flagged-bubble"></div>
					}
				</div>
			)
		})
	}

	render() {
		let styles = getSkin(this.props.skinGuide);
		return (
			<div styleName="questions-navigator-cnt">
				<div styleName="questions-navigator-header">
					{this.props.getLabelWithDefaults('questions_label', 'QUESTIONS')}
				</div>
				<div styleName="quiz-question-bubbles-cnt">
					{this.getQuestionCircles()}
				</div>
			</div>
		)
	}
}

export default QuizNavigator;