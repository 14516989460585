/**
* @author: sourabh p
* @since: Tue Nov 27 2018 15:15:46 GMT+0530 (IST)
* @file: AppValidator.js
*
* @copyright: KNOLSKAPE Solutions Pvt Ltd
**/

import store from 'store/store';
import { resolvePromise, rejectPromise } from 'utils/promise.js';
import { setInvalid } from 'actions/invalid/actionCreators';
class AppValidator {

	constructor() {
		this._showError = true;
		this.dispatch = store.dispatch;
		this.getState = store.getState;
	}

	showInvalidPage = () => {
		this.dispatch(setInvalid(true));
	}

	shouldValidate = (validationInfoObject) => {
		if (validationInfoObject.shouldValidate && validationInfoObject.shouldValidate === false) {
			return false;
		}
		return true;
	}

	onError = (error) => error

	validateIds = (validationInfoObject) => {
		const idsObject = validationInfoObject.ids;
		for (const key in idsObject) {
			if (idsObject.hasOwnProperty(key)) {
				const value = +idsObject[key];
				if (!value || value < 0) {
					return rejectPromise({
						message: 'invalid id'
					});
				}
			}
		}
		return resolvePromise();
	}

	preValidationLogic = (validationInfoObject) => {
		return this.validateIds(validationInfoObject);
	}

	//Non overridable
	preValidate = (validationInfoObject = {}) => {
		if (!this.shouldValidate(validationInfoObject)) {
			return resolvePromise();
		}

		return this.preValidationLogic(validationInfoObject)

			.then(response => resolvePromise(response))

			.catch(error => {
				if (validationInfoObject.showInvalidPage !== false) {
					this.showInvalidPage();
				}
				
				const errorObj = {error, validationInfoObject};

				this.onError(errorObj);

				return rejectPromise(errorObj);
			});
	}

	postValidationLogic = (validationInfoObject) => {
		return resolvePromise();
	}

	//Non overridable
	postValidate = (validationInfoObject = {}) => {
		if (!this.shouldValidate(validationInfoObject)) {
			return resolvePromise();
		}

		return this.postValidationLogic(validationInfoObject)

			.then(response => resolvePromise(response))

			.catch(error => {
				if (validationInfoObject.showInvalidPage !== false) {
					this.showInvalidPage();
				}

				const errorObj = {error, validationInfoObject};

				this.onError(errorObj);

				return rejectPromise(errorObj);
			});
	}
}

export default AppValidator;