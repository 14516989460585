import React, { Component } from 'react';
import styles from './errorTemplate.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin';
import { css, StyleSheet } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import Footer from 'commonComponents/footer';
import ErrorNotFound from 'svgComponents/errorNotFound';
import ErrorSomethingWrong from 'svgComponents/errorSomethingWrong';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ErrorTemplate extends Component {

	constructor(props) {
		super(props);
	}

	onClickGoToHome = () => {
		const {setInvalid} = this.props;
		setInvalid(false);
		// let route = routes.get('BATCH');
		// let params = [{ BATCH_ID: this.props.userState.get('currentBatchId') }];
		
		// if (!this.props.userState || this.props.userState.get('currentBatchId') == null) {
		// 	route = routes.get('HOME');
		// 	params = []
		// }

		// let route = routes.get('HOME');
		// let params = [];

		// updateRoute({ route, params });

		window.location.href = "/"; //hack to make the button work
	}

	getErrorPageDetails = (type) => {
		switch (type) {
			case "USER_NOT_FOUND":
			case "NO_USERS_FOUND_IN_BATCH":
			case "BATCH_USERS_NOT_FOUND":
			case "USER_NOT_LOGGED_IN":
			case 'BATCH_NOT_FOUND':
			case 'BATCHES_NOT_FOUND':
			case 'NOT_FOUND':
				return {
					heading: "No Course Found",
					descMain: "We could not find any courses assigned to your email ID. Please contact your admin or write to us at info@aktivlearn.com",
					descSub: "",//"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
					imageComponent: ErrorNotFound
				};
			case "USER_NOT_FOUND_IN_BATCH":
				return {
					heading: "No access",
					descMain: "You can't access this course as you aren't enrolled into it.",
					descSub: "",//"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
					imageComponent: ErrorNotFound
				};
			case 'USER_DOES_NOT_EXIST':
				return {
					heading: "Oops!",
					descMain: "You don't have access to Aktivlearn Plus because you aren't enrolled to a course.",
					imageComponent: ErrorNotFound
				};
			case 'USER_SUSPENDED_FROM_THIS_BATCH':
				return {
					heading: "Oops!",
					descMain: "Looks like you have been suspended from this course.",
					descSub: "",//"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
					imageComponent: ErrorNotFound
				};
			default:
				return {
					heading: "Oops!",
					descMain: "Something went wrong.",
					descSub: "",//"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.",
					imageComponent: ErrorSomethingWrong
				};
		}
	}

	render() {
		const myStyles = getSkin(this.props.skinGuide);
		const pageDetails = this.getErrorPageDetails(this.props.type);

		return (
			<div styleName="complete-container">
				<div styleName="error-container">
					<div styleName="error-content">
						<div styleName="error-image-container">
							<div styleName="error-image">
								<pageDetails.imageComponent />
							</div>
						</div>
						<div styleName="error-message-container">
							<div className={css(myStyles.errorHeading)} styleName="error-heading">{pageDetails.heading}</div>
							<div className={css(myStyles.errorDesc)} styleName="error-main-desc">{pageDetails.descMain}</div>
							<div className={css(myStyles.errorDesc)} styleName="error-sub-desc">{pageDetails.descSub}</div>
							<div
								className={css(myStyles.buttonContainer)}
								styleName="button-container"
								onClick={this.onClickGoToHome}
							>
								GO TO HOME PAGE
								</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default ErrorTemplate;
