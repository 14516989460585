/**
 * @author: sindhu
 * @since: Thu Aug 02 2018 18:20:31 GMT+0530 (IST)
 * @file: AppRouter.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 *  react router of the application
 *
 **/

import React from 'react';

import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import DesktopDashboardContainer from 'containers/DesktopDashboardContainer';

const customHistory = createBrowserHistory();

const appRoutes = (
    <div>
       <Route path="/" component={DesktopDashboardContainer} />
    </div>
);

const AppRouter = () => <Router history={customHistory}>
    <Route component={({history}) => {
        window.appHistory = history;
        return appRoutes;
    }}/>
</Router>;

export default AppRouter;
