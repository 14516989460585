import React, { Component } from 'react';
import styles from './scormsIcon.module.sass';
import CSSModules from 'react-css-modules';
import getSkin from './skin.js';
import { css } from 'aphrodite/no-important';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class ScormsIcon extends Component {

	render() {

		// const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="scorm-icon">
				<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 13 13">
					<path fill="#828593" fillRule="evenodd" d="M2.233 4.496a.135.135 0 0 1 .085.23L.546 6.5a.526.526 0 0 0 0 .744l.966.967a.27.27 0 0 0 .42-.048A2.107 2.107 0 1 1 4.84 11.07a.27.27 0 0 0-.047.42l.966.967a.526.526 0 0 0 .744 0l1.773-1.773a.135.135 0 0 1 .23.085 2.097 2.097 0 0 0 3.192 1.625 2.108 2.108 0 0 0 .387-3.286 2.07 2.07 0 0 0-1.315-.601.135.135 0 0 1-.085-.23l1.77-1.777a.526.526 0 0 0 0-.744l-.966-.967a.27.27 0 0 0-.42.048A2.107 2.107 0 1 1 8.16 1.933a.27.27 0 0 0 .047-.42L7.244.544a.527.527 0 0 0-.744 0L4.727 2.318a.135.135 0 0 1-.231-.085A2.097 2.097 0 0 0 1.305.608 2.108 2.108 0 0 0 .92 3.895a2.07 2.07 0 0 0 1.312.6z"/>
				</svg>
			</div>
		);
	}
}

export default ScormsIcon;
