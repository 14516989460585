import { StyleSheet } from 'aphrodite';
import { hexToRgbA, typography } from 'utils/styleUtil';
import Clock from 'images/icons/clock.svg';

const getSkin = (theme) => {
    const skin = theme.get('skinHex');
    const myTypography = typography(theme);

    return StyleSheet.create({
        title: {
            ...myTypography.caption,
            borderBottom: 'solid 1px #d5d8e2',
            color: ' #5b5c61',
            opacity: '0.8',
            fontSize: '14px'
        },

        time: {
            ...myTypography.h6,
            color: '#5b5c61'
        }
    });
}

export default getSkin;