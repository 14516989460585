import React from 'react';
import CSSModules from 'react-css-modules';
import styles from './digitalTool.module.sass';
import AdditionalInformation from '../additionalInformation';
import PrimaryButton from 'commonComponents/buttons/primaryButton';
import applySkin from 'utils/SkinProvider';
import applyLabel from 'utils/LabelProvider';
import { css } from 'aphrodite';
import getSkin from './skin.js';
import ToolFullScreen from 'components/moduleItems/toolFullScreen';
import DigitalToolLoader from './DigitalToolLoader';
import { checkIfPresent } from 'utils/utilFunctions';
import { getAmplitudeSessionDetails } from 'utils/segmentUtil';


@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })

class DigitalTool extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			openToolWithFullScreen: false
		}
	}

	sendSegmentData = (event) => {
		let segmentData = {
			...(this.props.match.params),
			referrer: window.location.href,
			pathHash: window.location.hash
		};
		window.analytics.track(
			event,
			segmentData,
			getAmplitudeSessionDetails()
		);
	}

	toggleOpenToolWithFullScreen = () => {
		if (!this.state.openToolWithFullScreen) {
			this.sendSegmentData('Digital Tool Opened');
		} else {
			this.sendSegmentData('Digital Tool Closed');
		}
		this.setState({
			openToolWithFullScreen: !this.state.openToolWithFullScreen
		})
	}

	onMarkAsDone = (args) => {
		this.setState({
			openToolWithFullScreen: false
		}, () => {
			this.sendSegmentData('Digital Tool Submitted');
			this.props.markModuleItemDone(args)
		});
	}

	getDetails = (isSidebarOpen) => {
		const { skinGuide, toolData, toolUserData } = this.props;
		const myStyles = getSkin(skinGuide);

		return (
			<div
				className={isSidebarOpen ? null : css(myStyles.metadataCnt)}
				styleName={`metadata-cnt${isSidebarOpen ? " sidebar-opened" : " sidebar-closed"}`}
			>
				<div styleName="duration-details">
					<div className={css(myStyles.metadataHeader)} styleName="metadata-header">
						Time
					</div>
					<div className={css(myStyles.metadataContent)} styleName="metadata-content">
						{toolData.details.time}
					</div>
				</div>
				<div styleName="group-size-details">
					<div className={css(myStyles.metadataHeader)} styleName="metadata-header">
						Ideal Group Size
					</div>
					<div className={css(myStyles.metadataContent)} styleName="metadata-content">
						{toolData.details.idealGroupSize}
					</div>
				</div>
				<div styleName="level-details">
					<div className={css(myStyles.metadataHeader)} styleName="metadata-header">
						Level
					</div>
					<div className={css(myStyles.metadataContent)} styleName="metadata-content">
						{toolData.details.level}
					</div>
				</div>
				<div styleName="button-cnt">
					<PrimaryButton
						text={(toolUserData && checkIfPresent(toolUserData.data) ? toolData.labels.go_to_button_text : toolData.labels.create_button_text) || "Button Text"}
						clickFunction={this.toggleOpenToolWithFullScreen}
					/>
				</div>
			</div>
		)
	}

	render() {
		const {
			moduleItem,
			isSidebarOpen,
			toolDataLoaded,
			toolData
		} = this.props;

		const myStyles = getSkin(this.props.skinGuide);
		return (
			<div styleName="content-cnt">
				<div styleName="content-wrapper">
					<div styleName="session-desc-cnt">
						<AdditionalInformation title="Details" body={moduleItem.getIn(['contentDetails', 'description'])} />
					</div>

					{
						toolDataLoaded ?
							<div
								styleName={`content${isSidebarOpen ? ' sidebar-open' : ' sidebar-closed'}`}
							>
								<div styleName="extra-details-cnt">
									{
										toolData.details.imageSrc &&
										<div
											className={css(myStyles.bgTempImage)}
											style={{
												width: '100%',
												height: '438px',
												backgroundImage: `url(${toolData.details.imageSrc})`
											}}
										></div>
									}
									<div
										className={css(myStyles.extraDetailsDesc)}
										styleName="description"
										dangerouslySetInnerHTML={{ __html: toolData.details.additionalInformation }}
									>
									</div>
								</div>
								{
									this.getDetails(isSidebarOpen)
								}
							</div>
							:
							<DigitalToolLoader />
					}
				</div>
				{
					this.state.openToolWithFullScreen &&
					<ToolFullScreen
						{...this.props}
						title="Go back"
						onExit={this.toggleOpenToolWithFullScreen}
						markModuleItemDone={this.onMarkAsDone}
					/>
				}
			</div>
		)
	}
}

export default DigitalTool;