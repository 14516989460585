/**
 * @author: Amul Garg
 * @since: Thu Aug 23 2018 14:41:31 GMT+0530 (IST)
 * @file: index.jsx
 *
 * @copyright: KNOLSKAPE Solutions Pvt Ltd
 **/

/**
 *
 * Component to load and render video player
 *
 **/
import VideoPlayer from './VideoPlayer';

export default VideoPlayer;
