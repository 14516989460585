import React, { Component } from 'react'
import CSSModules from 'react-css-modules';
import { css } from 'aphrodite/no-important';
import styles from './quizScorePieChart.module.sass';
import getSkin from './skin.js';
import applySkin from 'utils/SkinProvider';
import PieChartOnly from 'commonComponents/graphs/pieChartOnly'
import { Legend } from '@vx/legend';
import { scaleOrdinal } from '@vx/scale';
import { GlyphDot } from '@vx/glyph';
import SecondaryButton from 'commonComponents/buttons/secondaryButton';
import updateRoute from 'utils/webAppRoutes/updateRoute';
import routes from 'utils/webAppRoutes/routeConstants';
import applyLabel from 'utils/LabelProvider';

@applyLabel
@applySkin
@CSSModules(styles, { allowMultiple: true })
class QuizScorePieChart extends Component {

    showPieChartLegends = () => {

        const { getLabelWithDefaults } = this.props;
        const rightAnswersLabel = getLabelWithDefaults('quiz_chart_right_answers', 'Right Answers');
        const wrongAnswersLabel = getLabelWithDefaults('quiz_chart_wrong_answers', 'Wrong Answers');
        const unansweredQuestionsLabel = getLabelWithDefaults('quiz_chart_unanswered_questions', 'Unanswered');

        const legendStyling = {
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
            fontFamily: 'Open Sans',
            color: '#979eb7'
        };
        const legendSize = scaleOrdinal({
            domain: [rightAnswersLabel, wrongAnswersLabel, unansweredQuestionsLabel],
            range: [16]
        });
        const legendShape = scaleOrdinal({
            domain: [rightAnswersLabel, wrongAnswersLabel, unansweredQuestionsLabel],
            range: [
                props => {
                    const { width, height, size, fill } = props;
                    return (<GlyphDot
                        r={size / 2}
                        top={width / 2}
                        left={height / 2}
                        fill={fill}
                    />)
                }
            ],
        });
        return (
            <Legend
                direction="column"
                itemDirection="row"
                style={legendStyling}
                labelMargin="0"
                shapeMargin="0 8px 0 0"
                itemMargin="0 0 10px 0"
                scale={legendSize}
                shapeWidth={16}
                shapeHeight={16}
                fill={({ index }) => `url(#pie-only-grad-${index})`}
                fillOpacity={d => 1 / (d.index + 2)}
                shape={props => {
                    return (
                        <svg width={props.width} height={props.height}>
                            {!React.isValidElement(
                                legendShape(props.label.datum),
                            ) &&
                                React.createElement(
                                    legendShape(props.label.datum),
                                    {
                                        ...props,
                                    },
                                )}
                            {React.isValidElement(
                                legendShape(props.label.datum),
                            ) &&
                                React.cloneElement(legendShape(props.label.datum))}
                        </svg>
                    );
                }}
            />
        )
    }

    render() {
        let { getLabelWithDefaults, correctAnsweredCount,
            wrongAnsweredCount,
            unansweredCount } = this.props;

        let dataForPieChart = [
            { label: "Right", count: correctAnsweredCount, fill: '#6cbc14', gradientTo: '#8fdd3a' },
            { label: "Wrong", count: wrongAnsweredCount, fill: '#e92121', gradientTo: '#f15f5f' },
            { label: "Unanswered", count: unansweredCount, fill: '#4c4c4c', gradientTo: '#898989' }
        ];


        return (
            [
                <div key="quiz-score-pie-chart" style={{ minWidth: '250px' }}>
                    <PieChartOnly diameter={171} border={{ width: 10, fill: '#f3f3f3' }} data={dataForPieChart} />
                </div>,
                <div key="quiz-score-pie-chart-legends" styleName="quiz-pie-chart-legends-cnt">
                    {this.showPieChartLegends()}
                </div>
            ]
        )
    }
}

export default QuizScorePieChart;